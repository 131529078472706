import { Form, Radio, Segmented } from "antd";
import styled from "styled-components";

export const ActionButtons = styled.div`
  display: flex;
  margin-bottom: 27px;
  border-bottom: 1px solid rgb(228, 234, 240);
`;

export const TabContainer = styled.div`
  padding: 16px 16px 0px 16px;
  height: 54px;
  box-sizing: border-box;
  border-radius: 6px;
  display: flex;
`;
export const TabButton = styled.button`
  background-color: transparent;
  padding: 9px 8px 29px 8px;
  height: 34px;
  border: none;
  margin-left: ${(props) => props.marginLeft ?? "0"};
  cursor: pointer;
  border-bottom: 2px solid ${(props) => (props.active ? "#FBCF34" : "transparent")};
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: ${(props) => (props.active ? "#000000" : "#757575")};
`;
export const VaccinationTabContainer = styled.div`
  height: 24px;
  width: 274px;
  display: flex;
  box-sizing: border-box;
  background: #f2f6f9;
  border: 1px solid #d8e2e8;
  border-radius: 6px;
  margin-bottom: 20px;
  justify-content: space-between;
`;

export const VaccinationButton = styled.button`
  background-color: ${(props) => (props.active ? "#FFFFFF" : "transparent")};
  padding: 0 16px;
  height: 34px;
  cursor: pointer;
  // width: 123px;
  height: 24px;
  border: ${(props) => (props.active ? "1px solid #d8e2e8" : "none")};
  border-radius: 6px;

  font-weight: 400;
  font-size: 12px;
  line-height: 11px;
  color: ${(props) => (props.active ? "#1B2126" : "#9FA9B2")};

  &:hover {
    background-color: lightblue;
  }
`;

export const SelectContainer = styled.div``;

export const ActionButton = styled.button`
  background-color: #e9bd5a;
  padding: 6px 14px;
  height: 28px;
  border-radius: 4px;
  border: none;
  margin-left: ${(props) => props.marginLeft ?? "0"};
  cursor: pointer;
  align-self: center;

  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  color: #ffffff;

  &:hover {
    background-color: lightblue;
  }
`;

export const TableHeader = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`;

export const FormItem = styled(Form.Item)`
  width: 100%;
`;

export const CustomRadio = styled(Radio.Button)`
  ::before {
    display: none !important;
  }
`;

export const LinearGradientBG = styled.div`
  background: linear-gradient(93.86deg, #c7c3b9 0.64%, #f3f8f9 101.13%, #c8d2d3 101.13%);
  position: absolute;
  width: 100%;
  height: 160px;
  top: 0;
  left: 0;
  z-index: -1;
`;

export const DetailContainer = styled.div`
  display: ${(props) => (props.openWorkQueueDetailModal ? "flex" : "none")};
  padding: 15px;
  border-left: 1px solid #d0d5dd;
  width: 416px;
  font-weight: 500;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
  max-height: 100vh;
  min-height: 100vh;

  &::-webkit-scrollbar {
    width: 0;
  }
`;

export const CustomSegmented = styled(Segmented)`
  .ant-segmented-group {
    gap: 5px;
  }
  .ant-segmented-item {
    width: 50%;
  }
`;

export const InfoBackground = styled.div`
  font-weight: 400;
  padding: 0px 6px;
  background: #f5f5f5;
  border-radius: 21px;
`;

export const Capsule = styled.div`
background-color: ${(props) => props.bgColor || "#EFF1F5"};
color: ${(props) => props.color || "#023047"};
font-family: Helvetica Neue;
font-size: 12px;
font-weight: 500;
line-height: 18px;
text-align: center;
border-radius: 20px;
padding: 5px 10px;
`;
