import { Col, Row, Switch } from "antd";
import React from "react";
import HeadingComponent from "../../../../../components/Heading";
import AntDesignDropDownSelect from "../../../../../components/AntDesignDropDownSelect/AntDesignDropDownSelect";

export const ServiceRequest = () => {
  return (
    <div className="">
      <Row>
        {/*============================= */}
        <Col md={10} className="my-5">
          <HeadingComponent text={"Change /Cancellation Request Type"} fontSize={"16px"} fontWeight={500} />
        </Col>
        <Col md={10} className="my-5">
          <AntDesignDropDownSelect placeholder="Today" options={[]} defaultValue="Select Option" onChange={{}} allowClear />
        </Col>

        {/*============================= */}

        {/*============================= */}
        <Col md={10} className="my-5">
          <HeadingComponent text={"Change Requests limits"} fontSize={"16px"} fontWeight={500} />
        </Col>
        <Col md={10} className="my-5">
          <AntDesignDropDownSelect placeholder="Today" options={[]} defaultValue="Select Option" onChange={{}} allowClear />
        </Col>

        {/*============================= */}
        {/*============================= */}
        <Col md={10} className="my-5">
          <HeadingComponent text={"Cancel Request limits"} fontSize={"16px"} fontWeight={500} />
        </Col>
        <Col md={10} className="my-5">
          <AntDesignDropDownSelect placeholder="Today" options={[]} defaultValue="Select Option" onChange={{}} allowClear />
        </Col>

        {/*============================= */}
        {/*============================= */}
        <Col md={10} className="my-5">
          <HeadingComponent text={"Auto Approve Cancel Requests"} fontSize={"16px"} fontWeight={500} />
        </Col>
        <Col md={10} className="my-5">
          <AntDesignDropDownSelect placeholder="Today" options={[]} defaultValue="Select Option" onChange={{}} allowClear />
        </Col>

        {/*============================= */}

        <Col md={10}>
          <HeadingComponent text={"GPS Tracking of Staff"} fontSize={"16px"} fontWeight={500} />
        </Col>

        <Col md={10}>
          <Switch size="small" />
          <span className="ms-2">Enable</span>
        </Col>
        {/* ======================================================== */}
      </Row>
    </div>
  );
};
