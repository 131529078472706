import axios from "axios";
import { main_api } from "../api/axios_helper";
import { companyAPIsEndPoints, platformAPIsEndPoints } from "../constants/ApiEndPoints";
import axiosInstance from "./AxiosConfig";
import { getUserId } from "../api/auth";
import { baseURL } from "../api/axios_helper";

// Company APIs
export const updateCompanyDetails = (selectedComid, formValues) => {
  return axiosInstance
    .put(platformAPIsEndPoints.PUT_SINGLE_COMPANY_LIST_PETS(selectedComid), formValues)
    .then((data) => {
      return data;
    })
    .catch((error) => {
      throw error;
    });
};

export const enableAuthentication = (data, token) => {
  const config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseURL}modules/two-factor-authentication/enable/2fa`,
    headers: {
      Authorization: `Token ${token}`,
      "Content-Type": "application/json",
    },
    data: data,
  };
  return axios.request(config);
};

export const isAuthenticationEnabled = (token) => {
  const config = {
    method: "get",
    url: `${baseURL}modules/two-factor-authentication/enable/2fa`,
    headers: {
      Authorization: `Token ${token}`,
      "Content-Type": "application/json",
    },
  };
  return axios.request(config);
};

export const verifyCode = (data, token) => {
  const config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseURL}modules/two-factor-authentication/verify/otp`,
    headers: {
      Authorization: `Token ${token}`,
      "Content-Type": "application/json",
    },
    data: data,
  };
  return axios.request(config);
};

export const updateClientInfo = (id, values) => {
  return axiosInstance.put(companyAPIsEndPoints.EDIT_SINGLE_MANAGEMENT_CLIENTS(id), values);
};

export const getServices = (company_id) => {
  return axiosInstance.get(companyAPIsEndPoints.GET_ALL_SERVICES(company_id, "", "selectable_by_client=true"));
};

export const getTimeBlocks = () => {
  return axiosInstance.get(companyAPIsEndPoints.FETCH_TIME_BLOCKS);
};

export const getPetListForClient = (id) => {
  return axiosInstance.get(companyAPIsEndPoints.FETCH_PET_FOR_CLIENT(id));
};

export const getKeysListForClient = (id) => {
  return axiosInstance.get(companyAPIsEndPoints.FETCH_KEYS_FOR_CLIENT(id));
};

export const getWorkQueue = () => {
  return axiosInstance.get(companyAPIsEndPoints.WORK_QUEUE);
};
export const getSingleWorkQueue = (companyId, id) => {
  return axiosInstance.get(companyAPIsEndPoints.FECTH_SINGLE_WORK_QUEUE(companyId, id));
};
export const createWorkQueue = (formValues) => {
  return axiosInstance.post(companyAPIsEndPoints.WORK_QUEUE, formValues);
};

export const editWorkQueue = (id, formValues) => {
  return axiosInstance.put(companyAPIsEndPoints.WORK_QUEUE_WITH_ID(id), formValues);
};

export const deleteWorkQueue = (id) => {
  return axiosInstance.delete(companyAPIsEndPoints.WORK_QUEUE_WITH_ID(id));
};

export const createTask = (formValues) => {
  return axiosInstance.post(companyAPIsEndPoints.TASKS_LIST, formValues);
};

// export const createGroup = (formValues) => {
//   return axiosInstance.post(companyAPIsEndPoints.TASKS_LIST, formValues);
// };

// export const editGroup = (id, values) => {
//   return axiosInstance.put(companyAPIsEndPoints.TASKS_LIST_WITH_ID(id), values);
// };

// export const deleteGroup = (id, values) => {
//   return axiosInstance.delete(companyAPIsEndPoints.TASKS_LIST_WITH_ID(id));
// };

export const editTask = (id, values) => {
  return axiosInstance.put(companyAPIsEndPoints.TASKS_LIST_WITH_ID(id), values);
};

export const deleteTask = (id, values) => {
  return axiosInstance.delete(companyAPIsEndPoints.TASKS_LIST_WITH_ID(id));
};

export const fetchCompanyRoles = () => {
  return axiosInstance.get(`${platformAPIsEndPoints.FETCH_USER_ROLES}?is_company_type=true`);
};
export const changePassword = (values) => {
  return axiosInstance.post(companyAPIsEndPoints.CLIENT_CHANGE_PASSWORD, values);
};

export const fetchSignRoles = (roleId) => {
  return axiosInstance.get(`${platformAPIsEndPoints.FETCH_USER_ROLES}${roleId}/`);
};

//---------------------Settings Service-------------------//
export const createTag = (formValues) => {
  console.log("formValues:", formValues);
  // return axiosInstance
  // .post(companyAPIsEndPoints.TASKS_LIST, formValues);
};

export const editTag = (id, values) => {
  console.log("id:", id, "values:", values);

  // return axiosInstance
  // .put(companyAPIsEndPoints.TASKS_LIST_WITH_ID(id), values);
};

export const deleteTag = (id, values) => {
  console.log("id:", id, "values:", values);
  // return axiosInstance
  // .delete(companyAPIsEndPoints.TASKS_LIST_WITH_ID(id));
};

//---------------------Document Service-------------------//

export const uploadUnsignedDocument = (formValues) => {
  console.log("formValues:", formValues);
  return main_api.post(companyAPIsEndPoints.UPLOAD_UNSIGNED_DOCUMENT, formValues);
};

export const uploadSignedDocument = (id, formValues) => {
  console.log("id:", id, "values:", formValues);

  return main_api.put(companyAPIsEndPoints.UPLOAD_SIGNED_DOCUMENT(id), formValues);
};

export const deleteDocument = (id) => {
  console.log("id:", id);
  return main_api.delete(companyAPIsEndPoints.DELETE_DOCUMENT(id));
};

//---------------------Plateform Onboarding Settings Service-------------------//

// Onboarding Theme Settings

export const editThemeSettings = (id, values) => {
  return main_api
  .put(platformAPIsEndPoints.UPDATE_ONBOARDING_THEME_SETTINGS(id), values);
};

// Onboarding Video Settings

// export const editVideoSettings =  (id, values) => {
//   console.log("id:", id, "values:", values);

//   return main_api
//   .put(platformAPIsEndPoints.UPDATE_ONBOARDING_VIDEO_SETTINGS(id), values);
// };

export const editVideoSettings = async (id, values) => {
  console.log("id:", id, "values:", values);
  try {
    const response = await main_api.put(platformAPIsEndPoints.UPDATE_ONBOARDING_VIDEO_SETTINGS(id), values);
    return response;
  } catch (error) {
    console.error("Error updating video settings:", error);
    throw error;
  }
};

export const createVideoSettings = async (values) => {
  console.log("values:", values);
  try {
    const response = await main_api.post(platformAPIsEndPoints.CREATE_ONBOARDING_VIDEO_SETTINGS, values);
    return response;
  } catch (error) {
    console.error("Error updating video settings:", error);
    throw error;
  }
};

//---------------------Notification Settings Service-------------------//

// Edit Notifications Settings

export const editNotificationSettings = (values) => {
  return main_api
  .post(companyAPIsEndPoints.UPDATE_CUSTOM_NOTIFICATION_SETTINGS, values);
};
