import { DuplicateKeysTableActions, KeyTableActions } from "../KeysTableHeaders/KeyTableActions";
import Chip from "../../components/Form/Chip";
import CopyIcon from "../../assets/rawSvgs/copyIcon.svg";
import EyeIcon from "../../assets/rawSvgs/eye.svg";
import dayjs from "dayjs";
const KeysColumns = ({ handleDelete = "", handleEditRow = "", handleHistory = "", handleShowModal, handleShowDuplicateKeysModal }) => {
  return [
    {
      title: "Key Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <div className="d-flex align-items-center gap-1" onClick={()=>{handleShowDuplicateKeysModal(record)}}>
          <a>{record?.name}</a>
          <img src={CopyIcon} alt="copy" />
        </div>
      ),
    },
    {
      title: "No. of Copies",
      dataIndex: "counter",
      key: "counter",
      render: (text, record) => (
        <div className="d-flex align-items-center gap-1">
          <a>{record?.counter}</a>
        </div>
      ),
    },
    {
      title: "Client",
      dataIndex: "client_name",
      key: "client_name",
    },
    {
      title: "Possession",
      dataIndex: "possession",
      key: "possession",
      render: (text, record) => (
        <>
          <Chip
            text={`${record?.possession === "staff" ? `${record?.staff_name} (${record?.possession})` : record?.possession}`}
            classes="possession-chip text-capitalize"
          />
        </>
      ),
    },

    {
      title: "Type of Key",
      key: "type",
      dataIndex: "type",

      render: (text, record) => (
        <div className="d-flex align-items-center gap-1">
          <a className="text-capitalize">{record.type}</a>
          {record.type === "digital" && (
            <Chip Icon={EyeIcon} text="View" classes="view-chip" onClick={() => handleShowModal(record)} />
          )}
        </div>
      ),
    },
    {
      title: "Updated On",
      dataIndex: "updated_at",
      key: "updated_at",
      render: (text, record) => <span>{dayjs(record.updated_at).format("DD/MM/YYYY • h:mm A")}</span>,
    },
    ...KeyTableActions({ handleDelete, handleEditRow, handleHistory, handleShowModal }),
  ];
};

const DuplicateKeysColumns = ({ handleDelete = "", handleEditRow = "", handleHistory = "" }) => {
  return [
    {
      title: "Key Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <div className="d-flex align-items-center gap-1" >
          <a>{record?.name}</a>
          <img src={CopyIcon} alt="copy" />
        </div>
      ),
    },
    {
      title: "Possession",
      dataIndex: "possession",
      key: "possession",
      render: (text, record) => (
        <>
          <Chip
            text={`${record?.possession === "staff" ? `${record?.staff_name} (${record?.possession})` : record?.possession}`}
            classes="possession-chip text-capitalize"
          />
        </>
      ),
    },
    {
      title: "Updated On",
      dataIndex: "updated_at",
      key: "updated_at",
      render: (text, record) => <span>{dayjs(record.updated_at).format("DD/MM/YYYY • h:mm A")}</span>,
    },
    ...DuplicateKeysTableActions({ handleDelete, handleEditRow, handleHistory }),
  ];
};

export { KeysColumns,DuplicateKeysColumns };
