import React, { useEffect, useState } from "react";
import { NavigationHeading, TableHeader } from "../../company/clients/clientStyles";
import SearchInput from "../../../components/SearchInput";
import CustomBtnWithIcon from "../../../components/buttonWithIcon";
import { AntdesignTable } from "../../../components/antDesignTable/AntdesignTable";

import { Select } from "antd";

import { CompanyListColumns } from "../../../constants/CompanyTableHeader";
import { main_api } from "../../../api/axios_helper";

import { companyAPIsEndPoints, platformAPIsEndPoints } from "../../../constants/ApiEndPoints";
import DeleteConfirmationRowModal from "../../../components/Modals/DeleteConfirmationRowModal";
import { pushNotification } from "../../../util/Notification";
import AddCompanyModal from "../../../components/Modals/CompanyManagementModal/AddCompanyModal";
import EditCompanyModal from "../../../components/Modals/CompanyManagementModal/EditCompanyModal";
import { useDebounce } from "../../../hooks/useDebounce";

const CompanyManagement = (props) => {
  const [search, setSearch] = useState("");
  const [isActive, setIsActive] = useState("");

  const [isLoading, setIsloading] = useState(false);
  const [isAddCompanyOpen, setIsAddCompanyOpen] = useState(false);
  const [isEditCompanyOpen, setIsEditCompanyOpen] = useState(false);
  const [isDeleteCompanyOpen, setIsDeleteCompanyOpen] = useState(false);
  const [editCompanyValues, setEditCompanyValues] = useState({});
  const [deleteCompanyValues, setDeleteCompanyValues] = useState({});
  const debouncedSearch = useDebounce(search, 200);

  useEffect(() => {
    props.loadCompanyList();
  }, []);
  useEffect(() => {
    props.searchCompanyList(debouncedSearch);
  }, [debouncedSearch]);

  const addCompany = (formValues) => {
    setIsloading(true);
    main_api
      .post(platformAPIsEndPoints.POST_COMPANY_LIST_PETS, formValues)
      .then(({ data }) => {
        setIsloading(false);
        if (data?.isSuccess) {
          pushNotification(data?.message, "success");
          setIsAddCompanyOpen(false);
          props.loadCompanyList();
        }
      })
      .catch((error) => {
        if (error?.response?.data?.error === "User can only be a company admin in a single company") {
          pushNotification("User can only be a company admin in a single company!", "error");
          return;
        }
        pushNotification("Something went wrong!", "error");
      });
  };

  const editCompany = (formValues) => {
    setIsloading(true);
    main_api
      .put(`${platformAPIsEndPoints.PUT_SINGLE_COMPANY_LIST_PETS(editCompanyValues.id)}`, formValues)
      .then(({ data }) => {
        setIsloading(false);
        pushNotification(data.message, "success");
        setIsEditCompanyOpen(false);
        props.loadCompanyList();
      })
      .catch((error) => {
        pushNotification("Something went wrong!", "error");
        console.log(error);
      });
  };

  const deleteCompany = () => {
    setIsloading(true);

    main_api
      .delete(platformAPIsEndPoints.DELETE_SINGLE_COMPANY_LIST_PETS(deleteCompanyValues))
      .then(({ data }) => {
        setIsloading(false);
        pushNotification("Deleted Successfully!", "success");
        setIsDeleteCompanyOpen(false);
        props.loadCompanyList();
      })
      .catch((error) => {
        pushNotification("Something went wrong!", "error");
        console.log(error);
      });
  };

  const onStatusFilterChange = (isActive) => {
    setIsActive(isActive);
    props.searchCompanyList(search, isActive);
  };

  const searchBlurHandler = () => {
    props.searchCompanyList(search, isActive);
  };

  const handleEditRow = (record) => {
    if (record) {
      setEditCompanyValues(record);
    }
    setIsEditCompanyOpen(true);
  };

  const handleDelete = (record) => {
    if (record) {
      const { id } = record;
      setDeleteCompanyValues(id);
    }
    setIsDeleteCompanyOpen(true);
  };

  const handleSendForgetPasswordReq = async (values) => {
    setIsloading(true);

    try {
      const { data } = await main_api.put(companyAPIsEndPoints.FORGOT_PASSWORD, values);
      if (data.success) {
        pushNotification("Password Recovery Email Sent", "success");
      }
      setIsloading(false);
    } catch (error) {
      setIsloading(false);
      console.log("error? >>", error);
      if (error) {
        pushNotification(error?.error, "error");
      }
    }
  };

  return (
    <>
      {isAddCompanyOpen && (
        <AddCompanyModal isModalOpen={isAddCompanyOpen} setModalOpen={setIsAddCompanyOpen} onFinish={addCompany} />
      )}
      {isEditCompanyOpen && (
        <EditCompanyModal
          isModalOpen={isEditCompanyOpen}
          setModalOpen={setIsEditCompanyOpen}
          onFinish={editCompany}
          editCompanyValues={editCompanyValues}
        />
      )}
      <DeleteConfirmationRowModal
        onclose={() => setIsDeleteCompanyOpen(false)}
        handleConfirmDeleteRows={deleteCompany}
        openConfimrationDeleteModal={isDeleteCompanyOpen}
      />
      <NavigationHeading>Company Management</NavigationHeading>
      <TableHeader>
        <div className="d-flex align-items-center col">
          <SearchInput onChange={(value) => setSearch(value)} placeholder="Type to search.." />
          <Select
            onChange={(e) => {
              setIsActive(e);
              onStatusFilterChange(e);
            }}
            placeholder="Status"
            className="ms-2"
            style={{ height: "40px", minWidth: "150px" }}
            allowClear
            onClear={() => {
              setIsActive("");
              onStatusFilterChange("");
            }}
            options={[
              { value: true, label: "Active" },
              { value: false, label: "InActive" },
            ]}
          />
          <CustomBtnWithIcon
            btnText={"Add a Company"}
            className="ms-md-auto"
            margin="10px 10px 10px auto"
            onClick={() => setIsAddCompanyOpen(true)}
          />
        </div>
      </TableHeader>
      <div style={{ minWidth: "1100px", overflow: "auto" }}>
        <AntdesignTable
          columns={CompanyListColumns({ handleDelete, handleEditRow, props, handleSendForgetPasswordReq, isLoading })}
          data={props.companyList}
          loading={props.isLoading}
          paginationRightHeadingName="Total Companies"
          allowMultieSelectRows={false}
        />
      </div>
    </>
  );
};

export default CompanyManagement;
