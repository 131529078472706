import Invoicing from "../../pages/company/invoicing/Index";
import { connect } from "react-redux";
import { getAllInvoicingApi } from "../../redux/slices/invoicingSlice";
import { FetchClientsApi, fetchSingleClientsApi } from "../../redux/slices/ClientSlice";

const mapStateToProps = (state) => {
  return {
    permissionState: state.permissionManagement.permissions,
    invoicingState: state.invoicing,
    clientResponse: state.clientManangement.clientResponse,
    loginState: state.auth.loginResponse,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchAllInvoice: (query) => {
    dispatch(getAllInvoicingApi(query));
  },
  getManagementClients: (data) => {
    dispatch(FetchClientsApi());
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(Invoicing);
