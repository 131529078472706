const SortIcon = (
  <>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M22.8257 9.21132C23.5638 9.80183 23.1463 10.9922 22.201 10.9922L2.90254 10.9922C1.95726 10.9922 1.53971 9.80183 2.27784 9.21132L11.9271 1.49194C12.2923 1.19977 12.8112 1.19977 13.1765 1.49194L22.8257 9.21132Z"
      fill="#8B8698"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M23.1528 27.7731C23.891 27.1825 23.4734 25.9922 22.5281 25.9922L3.22969 25.9922C2.28441 25.9922 1.86686 27.1825 2.60499 27.7731L12.2542 35.4924C12.6194 35.7846 13.1384 35.7846 13.5036 35.4924L23.1528 27.7731Z"
      fill="#8B8698"
    />
  </>
)

export default SortIcon
