import React, { useEffect, useState } from "react";
import { Checkbox, Col, Form, Image, Input, Typography, Upload, message } from "antd";
import { ReactComponent as DefaultImageIcon } from "../../assets/images/uploadImage.svg";
import styled from "styled-components";
import CustomBtnWithIcon from "../../components/buttonWithIcon";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { CloseCircleOutlined } from "@ant-design/icons";
import ImageUploader from "../Form/ImageUploader";
import { companyAPIsEndPoints } from "../../constants/ApiEndPoints";
import { main_api } from "../../api/axios_helper";
//
export default function CompanyLogos({ logoData, handleNext, handleBack }) {
  const [companyLogo, setCompanyLogo] = useState(undefined);
  const [mobileAppLogo, setMobileAppLogo] = useState(undefined);
  const [emailLogo, setEmailLogo] = useState(undefined);
  const [mobileAndEmailLogo, setMobileAndEmailLogo] = useState(false);

  useEffect(() => {
    if (logoData?.mobile_app_logo || logoData?.email_logo || logoData?.company_logo) {
      setMobileAndEmailLogo(true);
    } else {
      setMobileAndEmailLogo(false);
    }
  }, [logoData]);
  useEffect(() => {
    if (logoData?.company_logo) {
      setCompanyLogo(logoData?.company_logo_link);
    }
    if (logoData?.email_logo) {
      setEmailLogo(logoData?.email_logo_link);
    }
    if (logoData?.mobile_app_logo) {
      if (logoData?.mobile_logo_link) {
        setMobileAppLogo(logoData?.mobile_logo_link);
      } else {
        setMobileAppLogo(undefined);
      }
    }
  }, [logoData]);

  const [form] = Form.useForm();

  const onFinish = (formValues) => {
    // setJWTToken(state.token);
    // getRolePermissions()
    // formValues['is_seen_onboarding'] = true
    // updateCompanyDetails(state.selectedComid, formValues)
    // .then(({data}) => {
    //   if(data.code == 200) {
    //     navigate("/company/");
    //   }
    // })
    // .catch((error) => {
    //   pushNotification("Something Went Wrong!", "error");
    // })

    formValues.company_logo = companyLogo || logoData?.company_logo_link;
    if (logoData?.mobile_app_logo && logoData?.email_logo === true) {
      formValues.mobile_app_logo = mobileAppLogo;
      formValues.email_logo = emailLogo;
    }

    handleNext(formValues);
  };

  const removeWebappLogo = () => {
    console.log("removeWebappLogo");
    setCompanyLogo("");
  };
  const removeEmailLogo = () => {
    console.log("removeWebappLogo");
    setEmailLogo("");
  };
  const removeMobileLogo = () => {
    setMobileAppLogo("");
    console.log("removeWebappLogo");
  };

  return (
    <>
      <FormHeader>Company Logos</FormHeader>
      <FormDescriptionText>Add your company logos</FormDescriptionText>
      {logoData?.email_logo || logoData?.mobile_app_logo === true ? (
        <span className="d-flex mb-3" style={{ flexDirection: "row", alignItems: "center", marginTop: "4px" }}>
          <Checkbox
            style={{ marginRight: "10px" }}
            checked={mobileAndEmailLogo}
            onChange={(e) => setMobileAndEmailLogo(e.target.checked)}
          />
          <p
            style={{ margin: 0 }}
          >{`Upload different logos for Web app${logoData?.mobile_app_logo && !logoData?.email_logo ? " & mobile app" : ", Mobile app"} ${logoData?.email_logo ? "& Emails" : ""} `}</p>
        </span>
      ) : (
        ""
      )}
      <Form form={form} name="companyLogos" onFinish={onFinish} layout="vertical">
        {mobileAndEmailLogo && (
          <FormItem name="companylogo">
            <ImageUploaderv2
              DefaultImageIcon={DefaultImageIcon}
              label="Company Logo"
              image={companyLogo}
              setImage={setCompanyLogo}
              removeWebappLogo={removeWebappLogo}
            />
          </FormItem>
        )}

        {mobileAndEmailLogo && (
          <>
            <FormItem name="mobileAppLogo" style={{ marginTop: "15px" }}>
              <ImageUploaderv2
                DefaultImageIcon={DefaultImageIcon}
                label="Mobile App Logo"
                image={mobileAppLogo}
                setImage={setMobileAppLogo}
                removeMobileLogo={removeMobileLogo}
              />
            </FormItem>
            <FormItem name="emailLogo" style={{ marginTop: "15px" }}>
              <ImageUploaderv2
                DefaultImageIcon={DefaultImageIcon}
                removeEmailLogo={removeEmailLogo}
                label="Email Logo"
                image={emailLogo}
                setImage={setEmailLogo}
              />
            </FormItem>
          </>
        )}
        <CustomBtnWithIcon btnText={"Next"} type="submit" style={{ width: "100%" }} hideIcon={true} />
        <CustomBtnWithIcon
          btnText={"Back"}
          margin="10px 0px"
          style={{ width: "100%" }}
          noBackground
          hideIcon={true}
          onClick={() => handleBack()}
        />
      </Form>
    </>
  );
}

const FormHeader = styled.h1`
  font-family:
    Helvetica Neue,
    Arial;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #000000;
  margin-top: 24px;
`;

const FormDescriptionText = styled.p`
  font-family: "Helvetica Neue", Arial;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #757575;
`;

const FieldWraper = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
`;

const FormWraper = styled.div`
  height: 556px;
  overflow: auto;

  ::-webkit-scrollbar {
    width: 0px;
  }
`;

const FurtherDetails = styled.div`
  span {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: #757575;
  }
`;

const FormItem = styled(Form.Item)`
  font-family: Helvetica Neue;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #3b3b3b;
  width: 100%;
`;

function ImageUploaderv2({
  label,
  DefaultImageIcon,
  image,
  setImage,
  savedImageUrl,
  updateUserSettingsAllow = false,
  heightMax = "140px",
  removeWebappLogo,
  removeMobileLogo,
  removeEmailLogo,
}) {
  const { Dragger } = Upload;
  const removeImage = () => {
    setImage(null);
    removeWebappLogo?.();
    removeMobileLogo?.();
    removeEmailLogo?.();
  };

  const uploadToS3 = async (file) => {
    const formData = new FormData();
    formData.append("image", file);

    try {
      const response = await main_api.post(companyAPIsEndPoints.UPLOAD_OBJECT, formData);
      if (response.data.isSuccess) {
        const imageUrl = response.data.url;
        setImage(imageUrl);
        message.success("Image uploaded successfully");
      } else {
        message.error("Failed to upload image");
      }
    } catch (error) {
      console.error("Error uploading image to S3", error);
      message.error("Failed to upload image");
    }
  };

  const props = {
    name: "file",
    showUploadList: false,
    beforeUpload(file) {
      const fileType = file.type;
      if (fileType !== "image/png" && fileType !== "image/jpg" && fileType !== "image/jpeg") {
        message.error("File should be of type PNG, JPG, or JPEG");
        return;
      }
      uploadToS3(file);
    },
    onDrop(e) {},
  };

  return (
    <ImageContent>
      <div className="d-flex">
        {label && <Label>{label}</Label>}
        {(image || savedImageUrl) && (
          <CloseCircleOutlined style={{ marginBottom: "5px" }} className="remove-icon ms-auto" onClick={() => removeImage()} />
        )}
      </div>
      {image ? (
        <Image src={image} style={{ maxHeight: heightMax, objectFit: "contain" }} />
      ) : (
        <Dragger style={{ marginTop: "10px" }} {...props} disabled={updateUserSettingsAllow}>
          <>
            <p className="ant-upload-drag-icon">{<DefaultImageIcon />}</p>
            <Typography>Upload image</Typography>
          </>
        </Dragger>
      )}
    </ImageContent>
  );
}

const Label = styled.p`
  margin-bottom: 6px;
  color: #3b3b3b;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
`;

const ImageContent = styled.div`
  .ant-image-mask-info {
    text-align: center;
  }
`;
