import { connect } from "react-redux";
import {
  editPet,
  loadPetList,
  loadVaccinationsList,
  loadVetList,
  searchPetList,
  searchVaccinationList,
  searchVetList,
  singlePetDetails,
} from "../../redux/slices/pets";
import PetManagement from "../../pages/company/pets/PetManagement";
import { loadPetDetail, openDetailModal } from "../../redux/slices/petDetail";
import { FetchClientsApi } from "../../redux/slices/ClientSlice";

const mapStateToProps = (state) => {
  return {
    isLoading: state.pets.isLoading,
    error: state.pets.error,
    petList: state.pets.petList,
    vaccination: state.pets.vaccination,
    vetList: state.pets.vetList,
    clientManagementState: state.clientManangement.clientResponse,
    isClientPortal: state?.auth?.loginResponse?.selectedRoleType === "client_type" ? true : false,
    authState: state.auth?.loginResponse,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadPetList: () => {
    dispatch(loadPetList());
  },
  loadVaccinationsList: () => {
    dispatch(loadVaccinationsList());
  },
  loadVetList: () => {
    dispatch(loadVetList());
  },
  getManagementClients: (data) => {
    dispatch(FetchClientsApi());
  },
  loadPetDetail: (id) => {
    dispatch(loadPetDetail(id));
  },
  searchPetList: (search, date) => {
    dispatch(searchPetList(search, date));
  },
  searchVaccinationList: (search, petName) => {
    dispatch(searchVaccinationList(search, petName));
  },
  searchVetList: (search) => {
    dispatch(searchVetList(search));
  },
  editPet: (values) => {
    dispatch(editPet(values));
  },
  openDetailModal: (isOpen) => {
    dispatch(openDetailModal(isOpen));
  },
  setSinglePetDetails: (data) => {
    dispatch(singlePetDetails(data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PetManagement);
