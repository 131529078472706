// import React from 'react'

import { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Button, Card, Col, Row, Tooltip } from "antd";

import { Elements, CardElement, useStripe, useElements } from "@stripe/react-stripe-js";

import AntDesignModal from "../../../components/Modals/AntDesignModal/AntDesignModal";
import CustomBtnWithIcon from "../../../components/buttonWithIcon";
import { main_api } from "../../../api/axios_helper";
import { companyAPIsEndPoints } from "../../../constants/ApiEndPoints";
import { useSelector } from "react-redux";
import HeadingComponent from "../../../components/Heading";
import { fetchCompDetailsApi } from "../../../redux/slices/auth";
import { useDispatch } from "react-redux";
import { pushNotification } from "../../../util/Notification";
import { DeleteIcon } from "../../../assets/rawSvgs/index";
import DeleteConfirmationRowModal from "../../../components/Modals/DeleteConfirmationRowModal";
import PaymentIcon from "react-payment-icons";
import styled from "styled-components";
import { NOCardsAttach } from "../../client/settings/ClientPaymentMethodsScreen";
//
const stripePromise = loadStripe(
  "pk_test_51PhqxSKc2DI8MXFwRUODIRkKyredFVhafHlUttnFVcb7nRSDyQ5ASM7HhbsgcjrzydD2s7uMdDmKPUDbnAV42p6r008HoGpKRu",
);
// if client attach then we have to give stripeAccount

// {
//   stripeAccount
// }
const PaymentForm = ({ handleCloseModalOnSuccess }) => {
  const [clientSecret, setClientSecret] = useState("");

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  const authInfo = useSelector((state) => state.auth.loginResponse);
  const paymentCompInfo = useSelector((state) => state.auth.companyDetails);
  const compInfo = authInfo?.selectCompany?.[0] || {};

  const platformStripeId = authInfo?.selectCompany?.[0]?.platform_account_id;

  let stripeCusId = paymentCompInfo.stripe_customer_id ? paymentCompInfo.stripe_customer_id : "";

  let url = `/api/v1/stripe/accounts/${platformStripeId}/customers/${stripeCusId}/payment-methods/`;

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (error) {
      setError(error.message);
    } else {
      try {
        const data = await main_api.post(url, {
          payment_method_id: paymentMethod.id,
          action: "attach", //attach/detach
        });

        if (data.status === 200) {
          pushNotification("Card added succesfully!.");
          handleCloseModalOnSuccess();
        }
      } catch (error) {
        setError(error.message);
      }
    }
  };

  const handleConfirm = async () => {
    if (!stripe || !clientSecret) {
      return;
    }

    setLoading(true);

    const result = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
      },
    });

    setLoading(false);

    if (result.error) {
      setError(result.error.message);
    } else {
      setSuccess("Payment successful!");
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="mt-5">
        <CardElement />
      </div>
      <div className="d-flex justify-content-center my-2">
        <CustomBtnWithIcon btnText={"Add Card"} type="submit" className="my-2" hideIcon noBackground width="200px" />
      </div>
      {error && <p style={{ color: "red" }}>{error}</p>}
      {success && <p style={{ color: "green" }}>{success}</p>}
      {clientSecret && <button onClick={handleConfirm}>{loading ? "Confirming payment..." : "Confirm Payment"}</button>}
    </form>
  );
};

const PaymentMethods = () => {
  let dispatch = useDispatch();
  const [paymentMethodModal, setOpenPaymentMethodModal] = useState(false);
  const [loaderInfoCheck, setLoaderInfoCheck] = useState(false);
  const [stripeVerificationLink, setStripeVerificationLink] = useState("");
  const [totalAttachedCards, setTotalAttachedCards] = useState([]);
  const [paymentMethodid, setPaymentMethodid] = useState("");
  const [openConfimrationDeleteModal, setOpenConfimrationDeleteModal] = useState(false);

  //
  const authInfo = useSelector((state) => state.auth.loginResponse);
  const paymentCompInfo = useSelector((state) => state.auth.companyDetails);
  const compStripeDetails = useSelector((state) => state.auth.companyInformation);
  const compInfo = authInfo?.selectCompany?.[0] || {};
  const isDetailSubmitted = paymentCompInfo?.stripe_details_submitted;

  const platformStripeId = authInfo?.selectCompany?.[0]?.platform_account_id;

  useEffect(() => {
    // dispatch(fetchCompStripeDetailsApi());
    dispatch(fetchCompDetailsApi());
    if (isDetailSubmitted) return;
    handleGetFreshLinkUrl();
  }, []);
  useEffect(() => {
    if (paymentCompInfo.stripe_customer_id) {
      handleGetAddedCard();
    }
  }, [paymentCompInfo.stripe_customer_id]);

  const handleGetFreshLinkUrl = async () => {
    try {
      setLoaderInfoCheck(true);
      const { data } = await main_api.post(companyAPIsEndPoints.GET_INFORMATION_URL, {
        account_id: compInfo.stripe_account_id,
      });
      setLoaderInfoCheck(false);

      setStripeVerificationLink(data?.account_link_url);
    } catch (error) {
      setLoaderInfoCheck(false);

      console.log("ERROR ", error);
    }
  };

  let stripeCusId = paymentCompInfo.stripe_customer_id ? paymentCompInfo.stripe_customer_id : "";

  let url = `/api/v1/stripe/accounts/${platformStripeId}/customers/${stripeCusId}/payment-methods/`;

  const handleGetAddedCard = async () => {
    try {
      const { data } = await main_api.get(url);

      setTotalAttachedCards(data);
    } catch (error) {}
  };
  const handleRemoveAddedCard = async (pmId) => {
    try {
      const data = await main_api.post(url, {
        payment_method_id: paymentMethodid,
        action: "detach", //attach/detach
      });

      if (data?.message === "Payment method detached successfully") {
        pushNotification("Card removed successfully!");
      }
      handleGetAddedCard();
    } catch (error) {
      console.log("Detach Error:", error);
    }
  };
  let currentlyDue = compStripeDetails?.requirements?.currently_due?.length === 0;
  let eventually_due = compStripeDetails?.requirements?.eventually_due?.length === 0;
  let checkAllVerificationInComplete = (!currentlyDue && !eventually_due) || !compStripeDetails?.details_submitted;

  return (
    <div>
      <div className="d-flex justify-content-end">
        {!checkAllVerificationInComplete && totalAttachedCards?.length > 0 && (
          <CustomBtnWithIcon btnText={"Add Payment Method"} onClick={() => setOpenPaymentMethodModal(true)} />
        )}
      </div>
      {checkAllVerificationInComplete ? (
        <div>
          <div className="w-50">
            <HeadingComponent
              text="Your verification of Stripe is not complete. Redirected to the Stripe verification process to complete your information. Please click the following
              link to proceed:"
              fontSize="14px"
              fontWeight={400}
              margin={"15px 0px"}
            />

            <Button
              type="primary"
              size="medium"
              style={{ color: "black", fontWeight: 500 }}
              disabled={loaderInfoCheck}
              onClick={() => window.open(stripeVerificationLink, "_blank", "noopener,noreferrer")}
            >
              Stripe Verification
            </Button>
          </div>

          <div>
            <HeadingComponent
              text={`Note: This link is valid for a single use only. If it has expired, please click on the "Get Link" button to receive
              a new verification link.`}
              fontSize="12px"
              fontWeight={500}
              margin={"10px 0px"}
              color="#616161"
            />
          </div>
          <Button
            type="primary"
            size="medium"
            style={{ color: "black", fontWeight: 500 }}
            onClick={handleGetFreshLinkUrl}
            disabled={loaderInfoCheck}
          >
            Get Link
          </Button>
        </div>
      ) : (
        <Row gutter={20} className="mt-3" justify={totalAttachedCards?.length === 0 ? "center" : "start"}>
          {totalAttachedCards?.length === 0 ? (
            <NOCardsAttach openModal={() => setOpenPaymentMethodModal(true)} />
          ) : (
            totalAttachedCards?.map((items, index) => {
              return (
                <Col sm={5} key={index} className="my-2">
                  <PaymentCardsComponent
                    {...items}
                    removeCard={() => {
                      setOpenConfimrationDeleteModal(true);
                      setPaymentMethodid(items.id);
                    }}
                  />
                </Col>
              );
            })
          )}
        </Row>
      )}

      {/* MODAL CARD */}
      <DeleteConfirmationRowModal
        onclose={() => setOpenConfimrationDeleteModal(false)}
        handleConfirmDeleteRows={handleRemoveAddedCard}
        openConfimrationDeleteModal={openConfimrationDeleteModal}
      />

      <AntDesignModal
        title="Payment Methods"
        openModal={paymentMethodModal}
        closeModal={() => setOpenPaymentMethodModal(false)}
        onCancel={() => setOpenPaymentMethodModal(false)}
        onOk={() => setOpenPaymentMethodModal(false)}
        component={
          <Elements stripe={stripePromise}>
            <PaymentForm
              handleCloseModalOnSuccess={() => {
                handleGetAddedCard();
                setOpenPaymentMethodModal(false);
              }}
            />
          </Elements>
        }
        footer={
          <div className="d-flex justify-content-end">
            <CustomBtnWithIcon
              btnText={"Close"}
              hideIcon
              className="me-2"
              width="90px"
              onClick={() => setOpenPaymentMethodModal(false)}
            />
          </div>
        }
      />
    </div>
  );
};

export default PaymentMethods;

export const PaymentCardsComponent = ({ card, removeCard }) => {
  return (
    <Row>
      <PaymentCardComp>
        <Col md={5}>
          <PaymentIcon id={card?.brand?.toLowerCase()} style={{ margin: 10, width: 35 }} className="payment-icon" />
        </Col>
        <Col md={14}>
          <div className="  p-2">
            <HeadingComponent text={`Last 4 Digits: ${card?.last4}`} fontSize="12px" fontWeight={700} color="#616161" />
            <HeadingComponent
              text={`Expiry Date: ${card?.exp_month}/${card?.exp_year}`}
              fontSize="12px"
              fontWeight={700}
              color="#616161"
              margin={"5px 0px"}
            />
          </div>
          <Tooltip title="Remove" className="cursorPointer">
            <button className="d-flex align-items-center " onClick={removeCard}>
              <HeadingComponent text={`Remove`} fontSize="12px" fontWeight={700} color="black" margin={"0px 5px 0px 0px"} />

              <DeleteIcon />
            </button>
          </Tooltip>
        </Col>
      </PaymentCardComp>
    </Row>
  );
};

const PaymentCardComp = styled.div`
  width: 372px;
  border: 1px solid #fbcf34;
  display: flex;
  padding: 16px;
  border-radius: 12px;
`;
