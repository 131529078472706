import InvoiceDetails from "../../pages/client/invoicing/invoiceDetails/InvoiceDetailIndex";
import { connect } from "react-redux";

import { FetchClientsApi } from "../../redux/slices/ClientSlice";

import { fetchSingleInvoiceApi, getAllInvoicingApi } from "../../redux/slices/invoicingSlice";
import { loadPetList } from "../../redux/slices/pets";
import { fetchServiceApi } from "../../redux/slices/serviceSlice";
import { fetchAllStaffApi } from "../../redux/slices/StaffSlice";
//
const mapStateToProps = (state) => {
  return {
    permissionState: state.permissionManagement.permissions,
    invoiceSingleDetail: state.invoicing.invoiceSingleDetails,
    clientResponse: state.clientManangement.clientResponse,
    loginState: state.auth.loginResponse,
    petList: state.pets,
    serviceStateResponseData: state.services.serviceGetResponse,
    clientManagementState: state.clientManangement.clientResponse,
    staffState: state.staffManagement,
    totalServiceDetails: state?.generalApp.dashboardCompanyDetails.responseData.result,
    invoicingState: state.invoicing,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchSingleInvoice: (id) => {
    dispatch(fetchSingleInvoiceApi(id));
  },

  getManagementClients: (data) => {
    dispatch(FetchClientsApi());
  },
  loadPetList: () => {
    dispatch(loadPetList());
  },

  fetchAllServices: (data) => {
    dispatch(fetchServiceApi(data));
  },
  fetchAllStaff: (id) => {
    dispatch(fetchAllStaffApi(id));
  },
  fetchAllInvoice: (query) => {
    dispatch(getAllInvoicingApi(query));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceDetails);
