


import React, { useEffect, useState } from "react";
import { Row, Col, DatePicker, Select, Collapse, Button, Divider, Form, Input, } from "antd";
import dayjs from "dayjs";
import moment from "moment";
import styled from "styled-components";
import { useSelector } from "react-redux";
import * as Yup from "yup";

import { ReactComponent as FirstStep } from "../../../../../assets/rawSvgs/Frame1000005140(2).svg";
import { ReactComponent as FirstStepGreenChecked } from "../../../../../assets/rawSvgs/frame1000005142.svg";
import { ReactComponent as GreaterThanSign } from "../../../../../assets/rawSvgs/greaterThanIcon.svg";
import { ReactComponent as SecondStepChecked } from "../../../../../assets/rawSvgs/Frame1000005140.svg";
import { ReactComponent as SecondStepTransparent } from "../../../../../assets/rawSvgs/Frame000005140Transparnet.svg";
import { ReactComponent as DropDownIcon } from "../../../../../assets/rawSvgs/selectDropDownIcon.svg";

import HeadingComponent from "../../../../../components/Heading";
import CustomBtnWithIcon from "../../../../../components/buttonWithIcon";
import AntDesignModal from "../../../../../components/Modals/AntDesignModal/AntDesignModal";
import FormikTextArea from "../../../../../components/formikInputs/textArea";

import FormikInputFloating from "../../../../../components/formikInputs/TextInput";
import FormikDateMonthInput from "../../../../../components/formikInputs/DateMonth";
import FormikCheckboxInput, { InputTextLabel } from "../../../../../components/formikInputs/Checkbox";
import FormikSelectInput from "../../../../../components/formikInputs/SelectInput";
import { Hours24Time } from "../../../../../util/24HourtimeList";
import { pushNotification } from "../../../../../util/Notification";
import { main_api } from "../../../../../api/axios_helper";
import { companyAPIsEndPoints, staffAPIsEndPoints } from "../../../../../constants/ApiEndPoints";
import { allServices, timeInHours, typeOfTimeShift } from "../../../../../util/addTimeShift";
import { CustomModal } from "../../../../../components/Modals/ModalStyles";
import TextArea from "antd/es/input/TextArea";

const AddMileageTrackingForm = ({
  updateValues,
  addNewEventModal,
  staffId,
  createEntry,
  fetchData,
  filterUpdateService,
  updateForm,
  requiredDropdowns,
  closeAll,
}) => {
  const [addStaffSetps, setAddStaffSetps] = useState(1);
  const [serivicesDropdown, setSerivicesDropdown] = useState(false);
  const [tempFormDetails, setTempFormDetails] = useState({});
  const [submitFormLoader, setSubmitFormLoader] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const [startReading, setStartReading] = useState(0);
  const [endReading, setEndReading] = useState(0);
  const [distance, setDistance] = useState(0);
  const [serviceRequest, setServiceRequest] = useState(null);
  const state = useSelector((state) => state.auth.loginResponse);
  let company_id = state?.selectCompany?.[0]?.company_id || null;
  
  
  const [form] = Form.useForm();

 
  useEffect(()=>{
    setDistance(endReading-startReading);
  },[startReading,endReading]);
  const onFinish =  () => {
    form
      .validateFields()
      .then((formValues)  => {
        const formData = {
          staff:staffId,
          approved:true,
          distance:distance,
          odometer_start_reading: Number(formValues.odometer_start_reading),
          start_recording_date: formValues.start_recording_date.format("YYYY-MM-DD"),
          start_recording_time: formValues.start_recording_time,
          odometer_end_reading: formValues.odometer_end_reading ? Number(formValues.odometer_end_reading) : 0,
          end_recording_date: formValues.end_recording_date ? formValues.end_recording_date.format("YYYY-MM-DD") : "1111-11-11",
          end_recording_time: formValues.end_recording_time ? formValues.end_recording_time : "00:00:00",
          service_request: serviceRequest,
          in_progress: inProgress,
          note: formValues.note,
        };
  
        createEntry(formData)
            .then((data) => {
              if (data.status) {
                form.resetFields();
                fetchData();
                closeAll();
              }
            })
            .catch((error) => {
              console.error(error);
            });
      })
      .catch((error) => {
        console.error("Validation failed:", error);
      });
  };
  
  
  
  
  return (
    <CustomModal
    open={addNewEventModal}
    title="Add Mileage Tracking Record"
    width="600px"
    height="444px"
    onCancel={
        closeAll
    }
    footer={null}
>
    <Form
      name="addMileageRecord"
            form={form}
            onFinish={onFinish}
            layout="vertical"
          >
            <div
              style={{
                overflowY: "auto",
                overflowX: "hidden",
                borderBottom: "0.5px solid rgb(144 144 144 / 43%)",
                padding: " 10px 0px",
              }}
            >
              <Row justify={"space-between"} gutter={15}>
                <Col md={24}>
                  <FormItem
                    label="Odometer Start Reading"
                    name="odometer_start_reading"
                    rules={[
                      { required: true, message: "Start reading is required" },
                    ]}
                  >
                    <Input placeholder="Enter Reading..." onBlur={(e) => setStartReading(e.target.value)}/>
                  </FormItem>
                </Col>
                <Col md={12}>
                  <FormItem
                    label="Start Recording at"
                    name="start_recording_date"
                    rules={[
                      { required: true, message: "Start recording details are required" },
                    ]}
                  >
                    <DatePicker style={{ width: "100%",height:"40px" }} />
                  </FormItem>
                </Col>
                <Col md={12}>
                  <FormItem
                    label="Start Recording Time"
                    name="start_recording_time"
                    rules={[
                      { required: true },
                    ]}
                  >
                    <Select placeholder="Select Time" options={timeInHours} style={{marginBottom:"8px"}}/>
                      
                  </FormItem>
                </Col>

                {!inProgress &&
                  <>
                    <Col md={24}>
                      <FormItem
                        label="Odometer End Reading"
                        name="odometer_end_reading"
                        rules={[
                          { required: true, message: "End reading is required" },
                        ]}
                      >
                        <Input placeholder="Enter Reading..." onBlur={(e) => setEndReading(e.target.value)} />
                      </FormItem>
                    </Col>
                    <Col md={12}>
                      <FormItem
                        label="End Recording at"
                        name="end_recording_date"
                        rules={[
                          { required: !form.getFieldValue('in_progress'), message: "End recording details are required" },
                        ]}
                      >
                        <DatePicker style={{ width: "100%" ,height:"40px"}} />
                      </FormItem>
                    </Col>
                    <Col md={12}>
                      <FormItem
                        label="End Recording Time"
                        name="end_recording_time"
                        rules={[
                          { required: !form.getFieldValue('in_progress') },
                        ]}
                      >
                        <Select placeholder="Select Time" options={timeInHours} style={{marginBottom:"8px"}}/>
                         
                      </FormItem>
                    </Col>
                  </>
                }

                <Col md={24}>
                  <FormItem
                    label="Notes"
                    name="note"
                    rules={[
                      { required: true, message: "Note is required" },
                    ]}
                  >
                    <TextArea placeholder="Type Notes Here..." autoSize={{ minRows: 2 }} />
                  </FormItem>
                </Col>
              </Row>
              <Divider />
              <div className="d-flex justify-content-end">
                <CustomBtnWithIcon
                  btnText={"Cancel"}
                  margin="0px 5px"
                  noBackground
                  hideIcon={true}
                  onClick={()=>{
                    closeAll();
                    form.resetFields();
                  }}
                />
                <CustomBtnWithIcon btnText={"Save Entry"} type="submit" hideIcon={true} />
              </div>
            </div>
          </Form>
</CustomModal>
  );
};

export default AddMileageTrackingForm;

const DateHeading = styled.div`
  font-weight: 700;
  font-size: 16px;
`;
const FormItem = styled(Form.Item)`
    width: 100%;
`;