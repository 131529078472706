//
export const reportDetailTabs = () => {
    return [
      {
        key: "1",
        label: "Schedule"
      },
      {
        key: "2",
        label: "Client & Staff"
      },
      {
        key: "3",
        label: "Time"
      },
      {
        key: "4",
        label: "Financial Reports"
      },
    ];
  };
  