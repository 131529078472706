import styled from "styled-components";

const ServiceWrapper = styled.div`
  width: 100%;
`;

const ServiceSection = styled.div`
  width: ${(props) => props.width ?? "100%"};
`;

export { ServiceWrapper, ServiceSection };
