


// import React, { useState, useCallback, useEffect } from 'react';
// import { Form, Input, Col, Row } from 'antd';
// import { AimOutlined } from '@ant-design/icons';
// import styled from 'styled-components';
// import { CustomModal } from '../Modals/ModalStyles';
// import { useFormikContext } from 'formik';
// import FormikInputFloating from '../formikInputs/TextInput';
// import CustomGoogleMap from '../MapComponent/customGoogleMap';

// const LocationSelectorFormik = ({ setAddressLatAndLong, checked }) => {
//   const [isModalOpen, setModalOpen] = useState(false);
//   const [position, setPosition] = useState({ lat: 31.5438232421875, lng: 74.32034375 }); // Default position
//   const [address, setAddress] = useState('');
//   const [markers, setMarkers] = useState([{ lat: 31.5438232421875, lng: 74.32034375, draggable: true }]);
//   const key = process.env.REACT_APP_GOOGLE_MAP_API_KEY; // Replace with your Google Maps API key
//   const { setFieldValue, validateField } = useFormikContext();

//   useEffect(() => {
//     setFieldValue('address', address);
//   }, [address, setFieldValue]);

//   useEffect(() => {
//     if (checked) {
//       validateField('address');
//     }
//   }, [checked, validateField]);

//   // Geocode
//   const handleGeocode = async () => {
//     try {
//       const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${key}`);
//       const data = await response.json();
//       if (data.results && data.results.length > 0) {
//         const { lat, lng } = data.results[0].geometry.location;
//         setPosition({ lat, lng });
//         setMarkers([{ lat, lng, draggable: true }]);
//         setAddressLatAndLong([lat, lng]);
//       }
//     } catch (error) {
//       console.error("Error in forward geocoding:", error);
//     }
//   };

//   // Reverse Geocode
//   const handleReverseGeocode = async (lat, lon) => {
//     try {
//       const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lon}&key=${key}`);
//       const data = await response.json();
//       if (data.results && data.results.length > 0) {
//         const newAddress = data.results[0].formatted_address;
//         setAddress(newAddress);
//         setAddressLatAndLong([lat, lon]);
//         return newAddress;
//       }
//     } catch (error) {
//       console.error("Error in reverse geocoding:", error);
//       return "Unknown Location";
//     }
//   };

//   // Handle modal close
//   const handleOk = useCallback(() => {
//     setModalOpen(false);
//   }, []);

//   return (
//     <div>
//       <FormikInputFloating
//         label="Address"
//         placeHolder="Enter Address"
//         name="address"
//         type="text"
//         className="w-100 mb-2"
//         autoComplete="new-password"
//         value={address}
//         onChange={(e) => setAddress(e.target.value)}
//         suffix={true}
//         address={address}
//         setAddress={setAddress}
//         handleGeocode={handleGeocode}
//         setModalOpen={setModalOpen}
//         requiredField={checked}
//       />

//       <CustomModal
//         open={isModalOpen}
//         title="Select Location"
//         width="30vw"
//         height="auto"
//         onCancel={handleOk}
//         maskClosable={false}
//         footer={null}
//         centered
//       >
//         <Row>
//           <Col lg={24}>
//             <MapContainer>
//               <CustomGoogleMap
//                 markers={markers}
//                 position={position}
//                 setPosition={(newPosition) => {
//                   setPosition(newPosition);
//                   setMarkers([{ lat: newPosition.lat, lng: newPosition.lng, draggable: true }]);
//                 }}
//                 handleReverseGeocode={handleReverseGeocode}
//                 mapHeight={"400px"}
//                 mapWidth={"100%"}
//               />
//             </MapContainer>
//           </Col>
//         </Row>
//       </CustomModal>
//     </div>
//   );
// };

// const MapContainer = styled.div`
//   width: 100%;
//   height: 400px; /* Adjust the height as needed */
// `;

// export default LocationSelectorFormik;




//==========================



import React, { useState, useCallback, useEffect } from 'react';
import { Col, Row } from 'antd';
import styled from 'styled-components';
import { CustomModal } from '../Modals/ModalStyles';
import { useFormikContext, Field } from 'formik';
import FormikInputFloating from '../formikInputs/TextInput';
import CustomGoogleMap from '../MapComponent/customGoogleMap';

const LocationSelectorFormik = ({ setAddressLatAndLong, checked }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [position, setPosition] = useState({ lat: 31.5438232421875, lng: 74.32034375 });
  const [markers, setMarkers] = useState([{ lat: 31.5438232421875, lng: 74.32034375, draggable: true }]);
  const key = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
  const { setFieldValue, validateField, values } = useFormikContext();

  useEffect(() => {
    setFieldValue('address', values.address);
  }, [values.address, setFieldValue]);

  useEffect(() => {
    if (checked) {
      validateField('address');
    }
  }, [checked, validateField]);

  const handleGeocode = async () => {
    try {
      const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${values.address}&key=${key}`);
      const data = await response.json();
      if (data.results && data.results.length > 0) {
        const { lat, lng } = data.results[0].geometry.location;
        setPosition({ lat, lng });
        setMarkers([{ lat, lng, draggable: true }]);
        setAddressLatAndLong([lat, lng]);
      }
    } catch (error) {
      console.error("Error in forward geocoding:", error);
    }
  };

  const handleReverseGeocode = async (lat, lon) => {
    try {
      const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lon}&key=${key}`);
      const data = await response.json();
      if (data.results && data.results.length > 0) {
        const newAddress = data.results[0].formatted_address;
        setFieldValue('address', newAddress);
        setAddressLatAndLong([lat, lon]);
        return newAddress;
      }
    } catch (error) {
      console.error("Error in reverse geocoding:", error);
      return "Unknown Location";
    }
  };

  const handleOk = useCallback(() => {
    setModalOpen(false);
  }, []);

  return (
    <div>
      <Field name="address">
        {({ field, meta }) => (
          <FormikInputFloating
            label="Address"
            placeHolder="Enter Address"
            type="text"
            className="w-100 mb-2"
            autoComplete="new-password"
            {...field}
            value={field.value}
            setFieldValue={setFieldValue}
            onChange={(e) => {
              field.onChange(e);
              setFieldValue('address', e.target.value); // Update Formik value
            }}
            suffix={true}
            address={field.value}
            handleGeocode={handleGeocode}
            setModalOpen={setModalOpen}
            requiredField={checked}
          />
        )}
      </Field>

      <CustomModal
        open={isModalOpen}
        title="Select Location"
        width="30vw"
        height="auto"
        onCancel={handleOk}
        maskClosable={false}
        footer={null}
        centered
      >
        <Row>
          <Col lg={24}>
            <MapContainer>
              <CustomGoogleMap
                markers={markers}
                position={position}
                setPosition={(newPosition) => {
                  setPosition(newPosition);
                  setMarkers([{ lat: newPosition.lat, lng: newPosition.lng, draggable: true }]);
                }}
                handleReverseGeocode={handleReverseGeocode}
                mapHeight={"400px"}
                mapWidth={"100%"}
              />
            </MapContainer>
          </Col>
        </Row>
      </CustomModal>
    </div>
  );
};

const MapContainer = styled.div`
  width: 100%;
  height: 400px; /* Adjust the height as needed */
`;

export default LocationSelectorFormik;
