import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
// slices

import authReducer from "./slices/auth";
import platformAuthReducer from "./platformSlices/auth";
import petReducer from "./slices/pets";
import clientManangementReducer from "./slices/ClientSlice";
import staffManangementReducer from "./slices/StaffSlice";
import userManagementReducer from "./slices/userManagementSlice";
import permissionManagement from "./slices/permissionSlice";
import keyManagement from "./slices/KeySlice";
import notificationManagement from "./slices/notificationSlice";

import { persistReducer } from "redux-persist";
import petDetailReducer from "./slices/petDetail";
import generalApp from "./slices/GeneralAppSlice/AppSlice";
import companyListReducer from "./platformSlices/CompanySlice";
import companyDetailReducer from "./platformSlices/CompanyDetail";

import serviceSlice from "./slices/serviceSlice";
import usersReducer from "./platformSlices/UserSlice";
import scheduler from "./slices/schedulerSlice";
import TasksManangementReducer from "./slices/tasksSlice";
import CompanyUserReducer from "./slices/companyUserSlice";
import CompanyInternalMsgs from "./slices/InternalChatAppSlice";
import themeReducer from "./slices/themeSlice";
import invoicingReducer from "./slices/invoicingSlice";
// ----------------------------------------------------------------------

// const rootPersistConfig = {
//   key: "root",
//   storage,
//   keyPrefix: "redux-",
//   // whiteList: ["newChatSystem"],
// };

const loginDataPersist = {
  key: "login-persist",
  storage,
  keyPrefix: "redux-",
  whitelist: ["loginResponse", "companyDetails", "companyInformation"],
};

const rootReducer = combineReducers({
  auth: persistReducer(loginDataPersist, authReducer),
  pets: petReducer,
  petDetail: petDetailReducer,
  clientManangement: clientManangementReducer,
  staffManagement: staffManangementReducer,
  tasksManagement: TasksManangementReducer,
  companyUser: CompanyUserReducer,
  userManagement: userManagementReducer,
  //Platform Admin Reducers
  platformAuth: platformAuthReducer,
  company: companyListReducer,
  companyDetail: companyDetailReducer,
  generalApp: generalApp,
  services: serviceSlice,
  users: usersReducer,
  scheduler: scheduler,
  keyManagement: keyManagement,
  permissionManagement: permissionManagement,
  notificationManagement: notificationManagement,
  CompanyInternalMsgs: CompanyInternalMsgs,
  theme: themeReducer,
  invoicing: invoicingReducer,
});

export { rootReducer };
