import React, { useEffect } from "react";

import { Col, Row } from "antd";
import LeftContainer from "./LeftContainer";
import RightContainer from "./RightContainer";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

function OnBoarding() {
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    const clientOnboardingSeen = auth.loginResponse?.selectCompany?.filter((item) => item.client_id !== null)[0]
      .client_onboarding_seen;

    if (clientOnboardingSeen) {
      navigate("/client/scheduler");
    }
  }, []);

  return (
    <Row>
      <Col span={12}>
        <LeftContainer />
      </Col>
      <Col span={12}>
        <RightContainer />
      </Col>
    </Row>
  );
}

export default OnBoarding;
