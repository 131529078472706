import React from "react";
import { Image, Typography, Upload, message } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { main_api } from "../../api/axios_helper";
import { companyAPIsEndPoints } from "../../constants/ApiEndPoints";

function ImageUploader({
  label,
  DefaultImageIcon,
  image,
  setImage,
  savedImageUrl,
  updateUserSettingsAllow = false,
  heightMax = "140px",
}) {
  const { Dragger } = Upload;
  const removeImage = () => {
    setImage(null);
  };

  const uploadToS3 = async (file) => {
    const formData = new FormData();
    formData.append("image", file);

    try {
      const response = await main_api.post(companyAPIsEndPoints.UPLOAD_OBJECT, formData);
      if (response.data.isSuccess) {
        const imageUrl = response.data.url;
        setImage(imageUrl);
        message.success("Image uploaded successfully");
      } else {
        message.error("Failed to upload image");
      }
    } catch (error) {
      console.error("Error uploading image to S3", error);
      message.error("Failed to upload image");
    }
  };

  const props = {
    name: "file",
    showUploadList: false,
    beforeUpload(file) {
      const fileType = file.type;
      if (fileType !== "image/png" && fileType !== "image/jpg" && fileType !== "image/jpeg") {
        message.error("File should be of type PNG, JPG, or JPEG");
        return;
      }
      uploadToS3(file);
    },
    onDrop(e) {},
  };
  return (
    <ImageContent>
      <div className="d-flex">
        {label && <Label>{label}</Label>}
        {(image || savedImageUrl) && (
          <CloseCircleOutlined style={{ marginBottom: "5px" }} className="remove-icon ms-auto" onClick={() => removeImage()} />
        )}
      </div>
      {image != null ? (
        <Image src={image} style={{ maxHeight: heightMax, objectFit: "contain" }} />
      ) : savedImageUrl ? (
        <>
          <Image src={savedImageUrl} style={{ maxHeight: heightMax, objectFit: "contain" }} />
        </>
      ) : (
        <Dragger style={{ marginTop: "10px" }} {...props} disabled={updateUserSettingsAllow}>
          <>
            <p className="ant-upload-drag-icon">{<DefaultImageIcon />}</p>
            <Typography>Upload image</Typography>
          </>
        </Dragger>
      )}
    </ImageContent>
  );
}

export default ImageUploader;
const Label = styled.p`
  margin-bottom: 6px;
  color: #3b3b3b;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
`;

const ImageContent = styled.div`
  .ant-image-mask-info {
    text-align: center;
  }
`;
