import React, { useEffect } from "react";

import { PubNubProvider } from "pubnub-react";

import AdminPortalChat from "../../../../../components/ClientAdminChatComponent/AdminPortalChat";
import usePubNubService from "../../../../../patterns/InitializePubnub";

const ChatPage = ({
  companyInternalChatState,
  selectChat,
  authState,
  fetchAllStaff,
  allStaffs,
  loggedInType,
  unreadCountsStatus,
  clientId,
  fetchChannelsToCheckUnreadCountCleint,
}) => {
  const pubnub = usePubNubService(loggedInType, authState);
  useEffect(() => {
    fetchAllStaff();
    // getManagementClients();
  }, []);

  let pubnubInitUser = "";
  let adminName = authState?.company_roles?.[0].company;
  let onlyCompId = authState?.company_roles?.[0].company_id;
  let isAdminOrUser = loggedInType?.selectedRoleType === "company_user_type" ? "company_user" : "company_admin";

  if (loggedInType?.selectedRoleType === "company_type") {
    pubnubInitUser = `company_admin_${authState?.company_roles?.[0].company_id}`;
  } else if (loggedInType?.selectedRoleType === "company_user_type") {
    pubnubInitUser = `company_user_${loggedInType?.companyUserId}`;
  } else if (loggedInType?.selectedRoleType === "client_type") {
    pubnubInitUser = `client_${authState?.company_roles?.[0]?.client_id}`;
  }

  const PamError = () => {
    return (
      <div className="pubnub-error">
        <h1>Warning! PubNub access manager enabled.</h1>
        <p>
          It looks like you have access manager enabled on your PubNub keyset. This sample app is not adapted to work with PAM by
          default.
        </p>
        <p>
          You can either disable PAM in the <a href="https://dashboard.pubnub.com/">PubNub Admin Portal</a> or add custom code to
          grant all necessary permissions by yourself. Please referer to the{" "}
          <a href="https://www.pubnub.com/docs/chat/components/react/access-manager">Chat Component docs</a> for more information.
        </p>
      </div>
    );
  };

  const KeysError = () => {
    return (
      <div className="pubnub-error">
        <h1>A PubNub account is required.</h1>
        <p>
          Visit the PubNub dashboard to create an account or login:
          <br />
          <a href="https://dashboard.pubnub.com/">https://dashboard.pubnub.com/</a>
          <br />
          Create a new app or locate your app in the dashboard. Enable the Presence, Files, Objects, and Storage features using a
          region of your choosing. For Objects, ensure the following are enabled:
        </p>
        <ul>
          <li>User Metadata Events</li>
          <li>Channel Metadata Events</li>
          <li>Membership Events</li>
        </ul>
        <p>Copy and paste your publish key and subscribe key into: </p>
        <pre>.env</pre>
        <p>before continuing.</p>
        <br />
      </div>
    );
  };

  const stateObj = {
    selectChat,
    companyInternalChatState,
    allStaffs,

    adminId: pubnubInitUser,
    adminName,
    onlyCompId,
    isAdminOrUser,
    clientId,
    unreadCountsStatus,
    fetchChannelsToCheckUnreadCountCleint,
  };

  if (!pubnub) {
    return <>Loading...</>;
  }

  return (
    <div>
      <PubNubProvider client={pubnub}>
        <AdminPortalChat {...stateObj} />
      </PubNubProvider>
    </div>
  );
  // return pubnubKeys.publishKey?.length && pubnubKeys.subscribeKey?.length ? (
  //   pamEnabled ? (
  //     <PamError />
  //   ) : (
  //     <PubNubProvider client={pubnub}>
  //       <ConversationScreen {...stateObj} />
  //     </PubNubProvider>
  //   )
  // ) : (
  //   <KeysError />
  // );
};
export default ChatPage;
