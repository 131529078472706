import { DocumentsTableActions } from "./documentsTableActions";
import dayjs from "dayjs";
const DocumentsTableColumns = ({ handleDownload = "", handleSignature = "", handleShowPDFViewerPage, clientSignAgreement }) => {
  return [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (text, record) => (
        <div
          className="d-flex align-items-center gap-1"
          onClick={() => {
            handleShowPDFViewerPage(record);
          }}
        >
          <a>{record?.title}</a>
        </div>
      ),
    },
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      render: (text, record) => (
        <div className="d-flex align-items-center gap-1">
          <a>{record?.id}</a>
        </div>
      ),
    },
    {
      title: "Updated On",
      dataIndex: "updated_at",
      key: "updated_at",
      render: (text, record) => <span>{dayjs(record.updated_at).format("DD/MM/YYYY • h:mm A")}</span>,
    },
    ...DocumentsTableActions({ handleDownload, handleSignature, clientSignAgreement }),
  ];
};

export { DocumentsTableColumns };
