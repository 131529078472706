// usePubNubService.js (custom hook)

import { useState, useEffect } from "react";
import usePubNubService from "../patterns/InitializePubnub";
import { useDispatch } from "react-redux";
import { setUnreadCounts, setUnreadCountsClient } from "../redux/slices/GeneralAppSlice/AppSlice";

//
const usePubNubServiceHook = ({ loggedInType, authState }) => {
  const pubnub = usePubNubService(loggedInType, authState);
  let dispatch = useDispatch();

  let pubnubInitUser = "";
  let compId = authState?.company_roles?.[0]?.company_id;

  if (loggedInType?.selectedRoleType === "company_type") {
    pubnubInitUser = `company_admin_${compId}`;
  } else if (loggedInType?.selectedRoleType === "company_user_type") {
    pubnubInitUser = `company_user_${loggedInType?.companyUserId}`;
  } else if (loggedInType?.selectedRoleType === "client_type") {
    // console.log("com user");
    let clientId = "";
    authState?.company_roles?.forEach((element) => {
      if (element.client_id) {
        clientId = element.client_id;
      }
    });
    pubnubInitUser = `client_${clientId}`;
  }

  const location = window.location.href;
  const specificPaths = ["/client/scheduler", "/client/services", "/client/staff", "/client/message", "/client/clients"];

  let onlyCompId = loggedInType?.selectCompany?.[0]?.company_id || {};

  useEffect(() => {
    if (!pubnub) {
      return;
    }
    let uuid = pubnub?.getUUID();

    const { pathname } = window.location;

    if (specificPaths.some((path) => pathname.includes(path))) {
      fetchChannelsToCheckUnreadCountCleint(uuid);

      //   console.log("PIUB NUBBB >>>> (", { pubnub, location, pathname });
    }
  }, [location, pubnub, onlyCompId]);

  let channelsArraySub = ["notification_signal"];
  useEffect(() => {
    // if (!channelsArraySub[0]) return;
    if (!pubnub) {
      return;
    }
    pubnub.subscribe({ channels: [...channelsArraySub], withPresence: true });

    return () => {
      // pubnub.unsubscribe({ channels: [currentChannel] });
      pubnub.unsubscribe({ channels: [...channelsArraySub] });
    };
  }, [pubnub]);

  //   FUNCTION
  //
  const fetchChannelsToCheckUnreadCountCleint = async (uuid = pubnub?.getUUID()) => {
    try {
      const response = await pubnub.objects.getAllChannelMetadata({
        include: { customFields: true },
        filter: `id LIKE 'chat.*' && custom.company_id==${compId}`,
      });

      let getChannelList = response?.data?.filter((items) => {
        return items?.custom?.company_id === onlyCompId && items.id.startsWith("chat.");
      });

      const unreadCounts = extractUnreadCounts(getChannelList, pubnub?.getUUID());

      dispatch(setUnreadCountsClient(unreadCounts));
    } catch (error) {
      console.error("Error fetching channels:", error);
    }
  };
  const extractUnreadCounts = (channels, uuid) => {
    const unreadCounts = [];

    channels.forEach((channel) => {
      try {
        const { unreadCountData } = channel?.custom;
        const parsedData = JSON.parse(unreadCountData) || {};

        if (!parsedData) {
          return; // Skip if unreadCountData is not valid JSON
        }

        if (parsedData.recievers) {
          const recievers = parsedData.recievers;

          for (const recieverId in recievers) {
            const { unreadCount } = recievers[recieverId];

            if (recieverId === uuid) {
              // Check if unreadCount is greater than 1
              unreadCounts.push({
                channelId: channel.id,
                unreadCount,
                type: "group",
                category: channel.id.includes("_staff") ? "general" : "private",
                ...channel,
              });
            }
          }
        }
      } catch (error) {
        // console.error(`Error parsing unreadCountData for channel ${error}:`);
      }
    });

    return unreadCounts;
  };

  const checkarond = (uuid) => {
    fetchChannelsToCheckUnreadCountCleint(uuid);
  };
  return [fetchChannelsToCheckUnreadCountCleint, checkarond];
};

export default usePubNubServiceHook;
