import { Divider } from "antd";
import * as Yup from "yup";
import { Formik, Form as FormikForm } from "formik";

import { CustomModal } from "../../../../components/Modals/ModalStyles";
import CustomBtnWithIcon from "../../../../components/buttonWithIcon";

import FormikSelectInput from "../../../../components/formikInputs/SelectInput";
import { main_api } from "../../../../api/axios_helper";
import { companyAPIsEndPoints } from "../../../../constants/ApiEndPoints";
import { pushNotification } from "../../../../util/Notification";
import FormikDateMonthInput from "../../../../components/formikInputs/DateMonth";
import moment from "moment";
import dayjs from "dayjs";

//
const EditInvoiceModal = ({ visible, handleClose, compId, invoiceData, onSuccess, invoiceId }) => {
  const handleUpdateInvoice = async (values, actions) => {
    try {
      const { data } = await main_api.put(companyAPIsEndPoints.UPATE_INVOICE(invoiceId), values);
      if (data.isSuccess) {
        onSuccess();
        pushNotification("Invoice generated successfully!");
        handleClose();
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <CustomModal title={"Edit Invoice"} open={visible} onCancel={handleClose} maskClosable={false} footer={null} isScrollable>
      <Divider />
      <Formik
        initialValues={{
          due_date: invoiceData.due_date,

          company: compId,
        }}
        validationSchema={Yup.object().shape({
          // status: Yup.string().required("Client is required"),
          due_date: Yup.string().required("Client is required"),
        })}
        onSubmit={handleUpdateInvoice}
        enableReinitialize
      >
        {({ setFieldValue, values }) => (
          <FormikForm>
            <FormikDateMonthInput
              className="w-100 mb-3"
              style={{ height: "40px" }}
              allowClear={false}
              name={"due_date"}
              defaultValue={dayjs(values.due_date, "YYYY/MM/DD")}
              label="Due Date"
              onChange={(e, text) => {
                setFieldValue("due_date", text);
              }}
              disabledDate={(current) => {
                let customDate = moment().format("YYYY-MM-DD");
                return current && current < moment(customDate, "YYYY-MM-DD");
              }}
            />

            {/* <FormikSelectInput
              label="Service"
              placeholder="Select Service"
              name="status"
              type="text"
              className="w-100 mb-2"
              autoComplete="new-password"
              defaultValue={values.status}
              options={[
                { value: "draft", label: "Draft" },
                { value: "sent_to_client", label: "Sent to Client" },
                { value: "paid", label: "Paid" },
                { value: "void", label: "Void" },
                { value: "partially_paid", label: "Partially Paid" },
              ]}
              // value={values?.service_requests[0]?.services?.[0]?.service}
              onChange={(e) => {
                setFieldValue("service_requests[0].services[0].service", e);
              }}
              width="100%"
              noBr
              // disabled={!values.extra_pet_enabled}
            /> */}

            <div className="d-flex justify-content-end gap-2">
              <CustomBtnWithIcon btnText={"Cancel"} noBackground hideIcon={true} onClick={handleClose} />
              <CustomBtnWithIcon
                btnText={"Update Invoice"}
                type="submit"
                hideIcon={true}

                // disabled={clientPostState?.isLoading || clientEditState?.isLoading}
              />
            </div>
          </FormikForm>
        )}
      </Formik>
    </CustomModal>
  );
};

export default EditInvoiceModal;
