import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import CustomBtnWithIcon from "../../../components/buttonWithIcon";
import { AntdesignTable } from "../../../components/antDesignTable/AntdesignTable";
import { ClientsColumens } from "../../../constants/CompanyTableHeaders/ClientTableHeaders";
import { NavigationHeading, TableHeader } from "./clientStyles";
import AddNewClientForm from "./AddNewClientForm";

import { DeleteConfirmationDelIcon } from "../../../assets/ColorChangeSvgs/DeleteClientConfirmation";
import { Modal } from "antd";
import HeadingComponent from "../../../components/Heading";
import { pushNotification } from "../../../util/Notification";
import NoDataScreen from "../../../components/NoDataScreen";
import PermissionModal from "../../../components/Modals/PermissionModal";
import { checkPermission } from "../../../util/commonUtil";
import { main_api } from "../../../api/axios_helper";
import { companyAPIsEndPoints } from "../../../constants/ApiEndPoints";
//
const Clients = ({
  getManagementClients,
  resetClientResponse,
  clientManagementState,
  clientPostState,
  postManagementClient,
  deleteSingleClient,
  clientDeleteResponse,
  updateSingleClient,
  clientEditState,
  loginState,
  permissionState,
}) => {
  //
  const [isLoader, setIsloading] = useState(false);
  const [deleteMultipleRows, setDeleteMultipleRows] = useState([]);
  const [addNewClientModal, setAddNewModal] = useState(false);
  const [openConfimrationDeleteModal, setOpenConfimrationDeleteModal] = useState(false);
  const [openEditClientModal, setOpenEditClientModal] = useState(false);
  const [openEditClientValues, setOpenEditClientValues] = useState({});
  const [deleteRowId, setDeleteRowId] = useState("");
  const [permissionPopUp, setPermissionPopUp] = useState(false);

  useEffect(() => {
    if (checkPermission(permissionState, "view_clients")) {
      getManagementClients();
    }
  }, [permissionState]);

  useEffect(() => {
    if (clientDeleteResponse?.responseData?.status === 200) {
      pushNotification(clientDeleteResponse?.responseData?.message, "success");
      getManagementClients();
      setOpenConfimrationDeleteModal(false);
      resetClientResponse();
    }
  }, [clientDeleteResponse]);

  const handleDelete = (row) => {
    if (checkPermission(permissionState, "delete_client")) {
      setDeleteRowId(row?.id);
      setOpenConfimrationDeleteModal(true);
    } else {
      setPermissionPopUp(true);
    }
  };
  const handleEditRow = (rows) => {
    if (rows) {
      setOpenEditClientValues({});
      // const {
      //   address,
      //   city,
      //   id,
      //   phone_number,
      //   name,
      //   user: { email },
      //   custom_fields: { groups },
      // } = rows;
      setOpenEditClientValues({
        address: rows?.address,
        city: rows?.city,
        id: rows?.id,
        phone_number: rows?.phone_number,
        name: rows?.name,
        email: rows?.user.email,
        groups: rows?.custom_fields?.groups,
      });
    }

    if (checkPermission(permissionState, "edit_client")) {
      setOpenEditClientModal(true);
    } else {
      setPermissionPopUp(true);
    }
  };
  const handleSelectDeleteMultiple = (rows) => {
    setDeleteMultipleRows(rows.rowKeys);
  };
  const handleConfirmDeleteRows = () => {
    deleteSingleClient(deleteRowId);
  };

  const addNewClient = () => {
    if (checkPermission(permissionState, "create_client")) {
      setAddNewModal(true);
    } else {
      setPermissionPopUp(true);
    }
  };

  const handleSendForgetPasswordReq = async (values) => {
    setIsloading(true);

    try {
      const { data } = await main_api.put(companyAPIsEndPoints.FORGOT_PASSWORD, values);
      if (data.success) {
        pushNotification("Password Recovery Email Sent", "success");
      }
      setIsloading(false);
    } catch (error) {
      setIsloading(false);

      if (error) {
        pushNotification(error?.error, "error");
      }
    }
  };

  return (
    <>
      {/* <NoItemsToRender /> */}

      <TableHeader>
        <NavigationHeading>Clients Management</NavigationHeading>
        {clientManagementState?.responseData?.result?.length === 0 && (
          <div className="d-flex">
            {deleteMultipleRows?.length > 0 && (
              <CustomBtnWithIcon
                btnText={"Delete Selected Clients"}
                noBackground
                hideIcon={true}
                margin="10px"
                color="#E53939"
                // onClick={() => setOpenConfimrationDeleteModal(true)}
              />
            )}
            {clientManagementState?.responseData?.result?.length !== 0 && (
              <>
                <CustomBtnWithIcon
                  btnText={"Bulk Email Campagin"}
                  noBackground
                  hideIcon={true}
                  margin="10px"
                  // onClick={() => setAddNewModal(true)}
                />
                <CustomBtnWithIcon btnText={"Add new Client"} margin="10px" onClick={() => addNewClient()} />
              </>
            )}
          </div>
        )}
        {clientManagementState?.responseData?.result?.length > 0 ? (
          <CustomBtnWithIcon btnText={"Add new Client"} margin="10px" onClick={() => addNewClient()} />
        ) : (
          ""
        )}
      </TableHeader>
      {clientManagementState?.responseData?.result?.length > 0 ? (
        <AntdesignTable
          columns={ClientsColumens({ handleDelete, handleEditRow, handleSendForgetPasswordReq, isLoader })}
          data={clientManagementState?.responseData.result || []}
          loading={clientManagementState?.isLoading}
          selectedRows={handleSelectDeleteMultiple}
        />
      ) : (
        <NoDataScreen
          heading="You don’t have any clients in your list. Let’s get started by adding a new client"
          btnText={"Add new Client"}
          onClick={() => addNewClient()}
        />
      )}

      {addNewClientModal && (
        <AddNewClientForm
          visible={addNewClientModal}
          clientPostState={clientPostState}
          postManagementClient={postManagementClient}
          handleClose={() => setAddNewModal(false)}
          resetClientResponse={resetClientResponse}
          getManagementClients={getManagementClients}
          companyId={loginState?.selectCompany?.[0]?.company_id || 0}
        />
      )}
      {openEditClientModal && (
        <AddNewClientForm
          visible={openEditClientModal}
          clientPostState={clientPostState}
          postManagementClient={postManagementClient}
          handleClose={() => setOpenEditClientModal(false)}
          resetClientResponse={resetClientResponse}
          getManagementClients={getManagementClients}
          openEditClientValues={openEditClientValues}
          updateClient={true}
          updateSingleClient={updateSingleClient}
          clientEditState={clientEditState}
          companyId={loginState?.selectCompany?.[0]?.company_id || 0}
        />
      )}
      {openConfimrationDeleteModal && (
        <DeleteConfirmationRowModal
          onclose={() => setOpenConfimrationDeleteModal(false)}
          handleConfirmDeleteRows={handleConfirmDeleteRows}
          openConfimrationDeleteModal={openConfimrationDeleteModal}
          clientDeleteResponse={clientDeleteResponse}
        />
      )}
      <PermissionModal onclose={() => setPermissionPopUp(false)} openModal={permissionPopUp} />
    </>
  );
};

export default Clients;

const DeleteConfirmationRowModal = ({ onclose, handleConfirmDeleteRows, openConfimrationDeleteModal, clientDeleteResponse }) => {
  return (
    <Modal open={openConfimrationDeleteModal} onCancel={onclose} footer={null} centered>
      <div
        style={{
          padding: "20px",
          height: "300px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <DeleteConfirmationDelIcon width="220px" height="220px" />

        <HeadingComponent
          text="By deleting this data, you’ll no longer be able to access it"
          fontSize="0.875rem"
          fontWeight={400}
          margin="25px auto 15px auto"
        />
        <div className="d-flex justify-content-center">
          <CustomBtnWithIcon btnText={"No"} padding="10px 30px" hideIcon noBackground margin="10px" />
          <CustomBtnWithIcon
            btnText={"Yes, delete it"}
            margin="10px"
            hideIcon
            disabled={clientDeleteResponse?.isLoading}
            onClick={handleConfirmDeleteRows}
          />
        </div>
      </div>
    </Modal>
  );
};
