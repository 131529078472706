import React, { useState, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
// import "./table.css";
import "./month.css";
import EventDetail from "./EventDetail";
import { useSelector } from "react-redux";

import { SchedulerMonthScheduledIcon, SchedulerMonthUserIcon } from "../../assets/rawSvgs/index";
import { schedulerBackground, schedulerColor } from "./helpers";
import { checkPermission } from "../../util/commonUtil";
import PermissionModal from "../Modals/PermissionModal";

//
const MyCalendarMonth = ({
  companyPortal,
  handlePrevMonth,
  handleNextMonth,
  dateRange,
  setDateRange,
  schedulerState,
  handleDataChange,
  loading,
}) => {
  const localizer = momentLocalizer(moment);

  const [events, setEvents] = useState([]);

  const [selectedEvent, setSelectedEvent] = useState(null);
  const [showAllEvents, setShowAllEvents] = useState(false);
  const [permissionPopUp, setPermissionPopUp] = useState(false);
  const permissionManagement = useSelector((state) => state.permissionManagement);

  useEffect(() => {
    let modifyEvents = [];
    schedulerState &&
      schedulerState.result &&
      schedulerState.result.map((scheduler) => {
        const [year, month, day] = scheduler.date.split("-").map(Number);
        const [startHour, startMinute, startSecond] = scheduler.start_time ? scheduler.start_time.split(":") : [0, 0, 0];
        const [endHour, endMinute, endSecond] = scheduler.end_time ? scheduler.end_time.split(":") : [0, 0, 0];
        modifyEvents.push({
          ...scheduler,
          backgroundColor: schedulerBackground(scheduler),
          color: schedulerColor(scheduler),
          start: new Date(year, month - 1, day, startHour, startMinute),
          end: new Date(year, month - 1, day, endHour, endMinute),
          title: scheduler.service.name || "",
        });
      });
    setEvents(modifyEvents);
  }, [schedulerState]);

  const selectEvent = (event) => {
    if (checkPermission(permissionManagement.permissions, "view_service_request")) {
      setSelectedEvent(event);
    } else {
      setPermissionPopUp(true);
    }
  };

  // Function to handle event creation
  const handleSelect = ({ start, end }) => {
    const title = window.prompt("Enter event title:");
    if (title) {
      const newEvent = {
        id: events.length + 1,
        title,
        start,
        end,
        backgroundColor: "#3174ad", // Background color of the event
        textColor: "#ffffff", // Text color of the event
      };
      setEvents([...events, newEvent]);
    }
  };
  const eventStyleGetter = (event, start, end, isSelected) => {
    return {
      style: {
        backgroundColor: "transparent",
        color: event.textColor,
        cursor: "default", // Disable cursor change on event
      },
    };
  };

  const showAllEvent = () => {
    if (checkPermission(permissionManagement.permissions, "view_service_request")) {
      setShowAllEvents(true);
    } else {
      setPermissionPopUp(true);
    }
  };

  // console.log("");
  return (
    <div id="scheduler-month-calendar" className="mb-5 mt-3">
      <div className={loading ? "filtering" : null}>
        <Calendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          views={["month"]} // Only display the month view
          onSelectSlot={handleSelect} // Call handleSelect when a slot is selected
          style={{ height: 500 }} // Adjust height as needed
          components={{
            event: CustomEvent,
            toolbar: () => null,
          }}
          step={60}
          date={dateRange.start} // Set the visible date range
          onNavigate={() => {}}
          messages={{
            showMore: (target) => (
              <div
                className="ml-2"
                role="presentation"
                onClick={() => {
                  showAllEvent();
                }}
              >
                {console.log("target", target)}+{target} more
              </div>
            ),
          }}
          eventPropGetter={eventStyleGetter}
          onSelectEvent={(event) => selectEvent(event)}
        />
      </div>
      {(selectedEvent || showAllEvents) && (
        <EventDetail
          multipeEvents={showAllEvents ? events : null}
          handleDataChange={handleDataChange}
          event={selectedEvent}
          setIsModalOpen={() => {
            setSelectedEvent(null);
            setShowAllEvents(false);
          }}
          companyPortal={companyPortal}
          isModalOpen={true}
        />
      )}
      <PermissionModal onclose={() => setPermissionPopUp(false)} openModal={permissionPopUp} />
    </div>
  );
};

export default MyCalendarMonth;

const CustomEvent = ({ event }) => {
  return (
    <div>
      <ClientEventComponent event={event} />
    </div>
  );
};

const ClientEventComponent = ({ event }) => {
  return (
    <div
      style={{
        backgroundColor: "#E5F6FF",
        color: "#187BEF",
        borderRadius: "5px",
        cursor: "pointer",
        width: "80%",
        margin: "auto",
        display: "flex",
        justifyContent: "center",
      }}
    >
      {/* {event?.title} */}
      <span style={{ textOverflow: "ellipsis", overflow: "hidden", padding: "2px" }}>{event?.title}</span>
    </div>
  );
};
