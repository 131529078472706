import React, { useEffect, useState } from "react";
import ServiceRequestModal from "./ServiceRequestModal";
import CustomBtnWithIcon from "../../../components/buttonWithIcon";
import { Row, Col } from "antd";
import MyCalendarMonth from "../../../components/SchedulerCalender/SchedulerMonthCalender";
import CalendarWeek from "../../../components/SchedulerCalender/SchedulerWeekCalender";
import CalendarDay from "../../../components/SchedulerCalender/SchedulerDayCalendar";
import Tabs from "../../../components/Tabs";
import SchedulerList from "./SchedulerList";

import styled from "styled-components";

import moment from "moment";
import { ReactComponent as Birthdate } from "../../../assets/rawSvgs/Birthdate.svg";
import { ReactComponent as GreaterThanIcon } from "../../../assets/rawSvgs/greaterThanIcon.svg";
import { ReactComponent as LessThanIcon } from "../../../assets/rawSvgs/lessThanIcon.svg";
import HeadingComponent from "../../../components/Heading";
import { useSelector } from "react-redux";
import PermissionModal from "../../../components/Modals/PermissionModal";
import { checkPermission } from "../../../util/commonUtil";

const ServiceRequest = ({ schedulerState, filterChange }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectTab, setSelectTab] = useState("day");
  const permissionManagement = useSelector((state) => state.permissionManagement);

  const [permissionPopUp, setPermissionPopUp] = useState(false);

  const [dateRange, setDateRange] = useState({
    start: moment().startOf("day").toDate(),
    end: moment().startOf("day").toDate(),
  });

  const handleDataChange = () => {
    filterChange(dateRange.start, dateRange.end);
  };

  const calenderType = {
    day: <CalendarDay handleDataChange={handleDataChange} schedulerState={schedulerState} dateRange={dateRange} />,
    week: <CalendarWeek handleDataChange={handleDataChange} schedulerState={schedulerState} dateRange={dateRange} />,
    month: <MyCalendarMonth handleDataChange={handleDataChange} schedulerState={schedulerState} dateRange={dateRange} />,
  };

  useEffect(() => {
    filterChange(dateRange.start, dateRange.end);
  }, [dateRange]);

  const handleDateNext = () => {
    if (selectTab === "month") {
      handleNextMonth();
    } else if (selectTab === "week") {
      const newStart = moment(dateRange.start).add(7, "days").toDate();
      const newEnd = moment(dateRange.end).add(7, "days").toDate();
      setDateRange({ start: newStart, end: newEnd });
    } else if (selectTab === "day") {
      const newStart = moment(dateRange.start).add(1, "day").toDate();
      const newEnd = moment(dateRange.start).add(1, "day").toDate();
      setDateRange({ start: newStart, end: newEnd });
    }
  };

  useEffect(() => {
    if (selectTab === "month") {
      setDateRange({
        start: moment().startOf("month").toDate(),
        end: moment().endOf("month").toDate(),
      });
    } else if (selectTab === "week") {
      setDateRange({
        start: moment().startOf("isoWeek").toDate(),
        end: moment().endOf("isoWeek").toDate(),
      });
    } else if (selectTab === "day") {
      setDateRange({
        start: moment().startOf("day").toDate(),
        end: moment().startOf("day").toDate(),
      });
    } else if (selectTab === "list") {
      setDateRange({
        start: null,
        end: null,
      });
    }
  }, [selectTab]);

  const handleDatePrev = () => {
    if (selectTab === "month") {
      handlePrevMonth();
    } else if (selectTab === "week") {
      const newStart = moment(dateRange.start).subtract(7, "days").toDate();
      const newEnd = moment(dateRange.end).subtract(7, "days").toDate();
      setDateRange({ start: newStart, end: newEnd });
    } else if (selectTab === "day") {
      const newStart = moment(dateRange.start).subtract(1, "day").toDate();
      const newEnd = moment(dateRange.start).subtract(1, "day").toDate();
      setDateRange({ start: newStart, end: newEnd });
    }
  };

  const handlePrevMonth = () => {
    const newStart = moment(dateRange.start).subtract(1, "month").startOf("month").toDate();
    const newEnd = moment(dateRange.end).subtract(1, "month").endOf("month").toDate();
    setDateRange({ start: newStart, end: newEnd });
  };

  const handleNextMonth = () => {
    const newStart = moment(dateRange.start).add(1, "month").startOf("month").toDate();
    const newEnd = moment(dateRange.end).add(1, "month").endOf("month").toDate();
    setDateRange({ start: newStart, end: newEnd });
  };

  const requestNewService = () => {
    if (checkPermission(permissionManagement.permissions, "create_service_request")) {
      setIsModalOpen(true);
    } else {
      setPermissionPopUp(true);
    }
  };

  const tabs = [
    { name: "day", label: "Day" },
    { name: "week", label: "Week" },
    { name: "month", label: "Month" },
    { name: "list", label: "List" },
  ];
  return (
    <>
      <Row style={{ alignItems: "center" }}>
        <Col>
          <Tabs tabs={tabs} selectTab={selectTab} setSelectTab={(val) => setSelectTab(val)} />
        </Col>
        <Col lg={6} className="">
          <div className="d-flex  align-items-center ms-5">
            {selectTab != "list" && (
              <div className="me-2">
                <Birthdate stroke={"#424242"} />
              </div>
            )}
            {selectTab === "month" && (
              <HeadingComponent
                text={moment(dateRange?.start).format("MMMM, YYYY")}
                fontSize={"14px"}
                color="#757575"
                margin="0px 0px "
                fontWeight={400}
                style={{ alignSelf: "flex-end" }}
              />
            )}
            {selectTab === "week" && (
              <div className="d-flex align-items-end">
                <HeadingComponent
                  text={moment(dateRange?.start).format("MMMM DD, YYYY")}
                  fontSize={"14px"}
                  color="#757575"
                  margin="0px 0px "
                  fontWeight={400}
                  style={{ whiteSpace: "nowrap" }}
                />
                <Seperator> - </Seperator>
                <HeadingComponent
                  text={moment(dateRange?.end).format("MMMM DD, YYYY")}
                  fontSize={"14px"}
                  color="#757575"
                  margin="0px 0px "
                  fontWeight={400}
                  style={{ whiteSpace: "nowrap" }}
                />
              </div>
            )}
            {selectTab === "day" && (
              <HeadingComponent
                text={moment(dateRange?.start).format("MMMM DD, YYYY")}
                fontSize={"14px"}
                color="#757575"
                margin="0px 0px "
                fontWeight={400}
                style={{ alignSelf: "flex-end" }}
              />
            )}
            {selectTab !== "list" && (
              <div className="d-flex ms-2">
                <button onClick={handleDatePrev}>
                  <LessThanIcon />
                </button>
                <button onClick={handleDateNext}>
                  <GreaterThanIcon />
                </button>
              </div>
            )}
          </div>
        </Col>
        <Col style={{ marginLeft: "auto" }}>
          <CustomBtnWithIcon btnText={"Request New Service"} onClick={() => requestNewService()} />
        </Col>
      </Row>
      {selectTab === "list" ? (
        <SchedulerList handleDataChange={handleDataChange} schedulerState={schedulerState} />
      ) : (
        calenderType[selectTab]
      )}
      {isModalOpen && (
        <ServiceRequestModal handleDataChange={handleDataChange} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
      )}
      <PermissionModal onclose={() => setPermissionPopUp(false)} openModal={permissionPopUp} />
    </>
  );
};

export default ServiceRequest;

const Seperator = styled.div`
  margin-left: 5px;
  margin-right: 5px;
`;
