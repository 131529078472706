import React, { useEffect, useState } from "react";
import styled from 'styled-components';
import { useSelector } from "react-redux";
import Analytics from "../../../components/RouteOptimizerComponents/analytics";
import RouteMaps from "../../../components/RouteOptimizerComponents/routeMaps";
import Configurations from "../../../components/RouteOptimizerComponents/configurations";

export default function RouteOptimizer() {
  const auth = useSelector((state) => state.auth);
  const [activeTab, setActiveTab] = useState("analytics");
  const company_id = auth.loginResponse?.selectCompany?.[0]?.company_id || null;

  return (
    <>
    <StickyBar>
      <NavigationHeading>Route Optimization</NavigationHeading>
      <ActionButtons>
        <TabContainer>
          <TabButton onClick={() => setActiveTab("analytics")} active={activeTab === "analytics"}>
            Analytics
          </TabButton>
          <TabButton onClick={() => setActiveTab("route-maps")} active={activeTab === "route-maps"} marginLeft="30px">
            Route Maps
          </TabButton>
          <TabButton onClick={() => setActiveTab("configurations")} active={activeTab === "configurations"} marginLeft="30px">
            Configurations
          </TabButton>
        </TabContainer>
      </ActionButtons>
    </StickyBar>
    <div style={{ marginTop: "100px", marginBottom: "70px" }}>
      {activeTab === "analytics" && <Analytics />}
      {activeTab === "route-maps" && <RouteMaps />}
      {activeTab === "configurations" && <Configurations />}
    </div>
  </>
  )
}


const NavigationHeading = styled.h2`
  display: flex;
  margin: 25px 0px 10px 0px;
  font-size: 20px;
  font-weight: 700;
`;
const ActionButtons = styled.div`
  display: flex;
  // margin-bottom: 27px;
  border-bottom: 1px solid rgb(228, 234, 240);
`;
const TabContainer = styled.div`
  padding: 16px 16px 0px 16px;
  height: 54px;
  box-sizing: border-box;
  border-radius: 6px;
  display: flex;
`;
const TabButton = styled.button`
  background-color: transparent;
  padding: 9px 8px 29px 8px;
  height: 34px;
  border: none;
  margin-left: ${(props) => props.marginLeft ?? "0"};
  cursor: pointer;
  border-bottom: 2px solid ${(props) => (props.active ? "#FBCF34" : "transparent")};
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: ${(props) => (props.active ? "#000000" : "#757575")};
`;
const StickyBar = styled.div`
  position: fixed;
  background-color: white;
  z-index: 1;
  width: 100%;
  margin-top: -17px;
`;