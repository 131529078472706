import { Table } from "antd";
import dayjs from "dayjs";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { main_api } from "../../../../api/axios_helper";
import { AntdesignTable } from "../../../../components/antDesignTable/AntdesignTable";
import DeleteConfirmationRowModal from "../../../../components/Modals/DeleteConfirmationRowModal";
import { dateFormatStartDate } from "../../../../components/SchedulerCalender/weekResourceCalender/WeekResourceCalender";

import {
  scheduleEventsListCalenderTable,
  schedulerListView,
} from "../../../../constants/CompanyTableHeaders/SchedulerTableHeaders";
import { convertTo12HourFormat } from "../../../../util/formatTime";
import { pushNotification } from "../../../../util/Notification";
import { AddNewEventModal } from "../SchedulerEvents";

const ScheduledEventsListTable = ({ AllStates }) => {
  const serviceState = useSelector((state) => state.scheduler?.schedulerResponse?.responseData);
  const [addNewEventSlotModal, setAddNewEventSlotModall] = useState({ details: "", condition: false });
  const [serviceListDetails, setserviceListDetails] = useState({});
  const [confirmDeleteModal, setconfirmDeleteModal] = useState(false);
  const [loader, setloader] = useState(false);

  const filterEmptyServ = serviceState?.result?.filter((items) => items.start_time && items.end_time);

  const handleDelete = (row) => {
    console.log("handle row", row);
    setserviceListDetails(row);
    setconfirmDeleteModal(true);
  };
  const handleDeleteEvent = async (values) => {
    try {
      setloader(true);
      console.log("values", serviceListDetails.id);
      const { data } = await main_api.delete(`/api/v1/service_request/${serviceListDetails.id}/`);

      if (data.isSuccess) {
        pushNotification("Service Request has been deleted successfully!");
        AllStates.fetchAllSchedulerService();
        setconfirmDeleteModal(false);
      }
      setloader(false);
    } catch (error) {
      setloader(false);
    }
  };
  const handleEditRow = (row) => {
    //  const convertedData= dateFormatStartDate(row.start_time,row.date)
    const dateTime = dayjs(row.date);
    const formattedDate = dateTime.format("YYYY-MM-DD");

    setAddNewEventSlotModall({
      details: {
        staffId: row.staff.id,
        slotTime: convertTo12HourFormat(row.start_time),
        date: formattedDate,
        serviceID: row.id,
        title: row.service.name,
      },
      condition: true,
    });
  };

  return (
    <div className="mt-4">
      <Table
        columns={scheduleEventsListCalenderTable(handleDelete, handleEditRow)}
        dataSource={filterEmptyServ || []}
        pagination={false}
      />

      {addNewEventSlotModal.condition && (
        <AddNewEventModal
          clientPetServiceDropdowns={AllStates}
          addNewEventModal={addNewEventSlotModal.condition}
          updateValues={addNewEventSlotModal.details}
          oneDayVerticalFrom={true}
          // updateForm={true}
          closeModal={() => setAddNewEventSlotModall({ ...addNewEventSlotModal, condition: false })}
        />
      )}
      <DeleteConfirmationRowModal
        onclose={() => setconfirmDeleteModal(false)}
        handleConfirmDeleteRows={handleDeleteEvent}
        openConfimrationDeleteModal={confirmDeleteModal}
        BtnloadingState={loader}
      />
    </div>
  );
};

export default ScheduledEventsListTable;
