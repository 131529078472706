import React, { useEffect, useState } from "react";
import moment from "moment";

import { Col, Row } from "antd";
import dayjs from "dayjs";
import { Calendar, momentLocalizer, Views } from "react-big-calendar";

import { ReactComponent as Birthdate } from "../../../../../../assets/rawSvgs/Birthdate.svg";
import { ReactComponent as GreaterThanIcon } from "../../../../../../assets/rawSvgs/greaterThanIcon.svg";
import { ReactComponent as LessThanIcon } from "../../../../../../assets/rawSvgs/lessThanIcon.svg";
import { SchedulerMonthUserIcon, SchedulerMonthScheduledIcon } from "../../../../../../assets/rawSvgs/index";
import HeadingComponent from "../../../../../../components/Heading";
import { schedulerBackground, schedulerColor } from "../../../../../../components/SchedulerCalender/helpers";
import truncateString from "../../../../../../util/truncate";
import { ShowEventModal } from "../../../../../../components/SchedulerCalender/OneDayVertical";
//
const WeekCalender = ({ weekChangeState, calenderTypeDropDown, schedulerState, loading }) => {
  const { dateRangeWeek, setDateRangeWeek } = weekChangeState;
  const [openDetailSlotModal, setOpenDetailSlotModal] = useState({ details: "", condition: false });
  const handleDateNext = () => {
    const newStart = moment(dateRangeWeek.start).add(7, "days").toDate();
    const newEnd = moment(dateRangeWeek.end).add(7, "days").toDate();
    setDateRangeWeek({ start: newStart, end: newEnd });
  };

  const handleDatePrev = () => {
    const newStart = moment(dateRangeWeek.start).subtract(7, "days").toDate();
    const newEnd = moment(dateRangeWeek.end).subtract(7, "days").toDate();
    setDateRangeWeek({ start: newStart, end: newEnd });
  };

  const handleEventClick = (slotInfo) => {
    const dateTime = dayjs(slotInfo.start);
    const formattedDate = dateTime.format("YYYY-MM-DD");

    const formattedTime = slotInfo.start.toLocaleTimeString("en-US", { hour: "numeric", minute: "numeric", hour12: true });

    setOpenDetailSlotModal({
      details: {
        staffId: slotInfo.resourceId,
        slotTime: formattedTime,
        date: formattedDate,
        serviceID: slotInfo.id,
        title: slotInfo.title,
      },
      condition: true,
    });
  };

  return (
    <div>
      <Row>
        <Col sm={10} md={10} lg={5} className="b">
          {(calenderTypeDropDown === "Month" || calenderTypeDropDown === "Week") && (
            <div className="d-flex  align-items-center ms-5">
              <div className="me-2">
                <Birthdate stroke={"#424242"} />
              </div>

              <div className="d-flex align-items-center w-100">
                <HeadingComponent
                  text={moment(dateRangeWeek.start).format("MMMM D")}
                  fontSize={"14px"}
                  color="#757575"
                  margin="0px 0px "
                  fontWeight={400}
                  className="me-2 w-100"
                />
                <div> - </div>
                <HeadingComponent
                  text={moment(dateRangeWeek.end).format("MMMM D")}
                  fontSize={"14px"}
                  color="#757575"
                  margin="0px 0px "
                  fontWeight={400}
                  className="ms-2 w-100"
                />
              </div>

              <div className="d-flex ms-2">
                <button onClick={handleDatePrev}>
                  <LessThanIcon />
                </button>
                <button onClick={handleDateNext}>
                  <GreaterThanIcon />
                </button>
              </div>
            </div>
          )}
        </Col>
      </Row>
      <CalendarWeek
        schedulerState={{
          result: schedulerState,
        }}
        dateRange={dateRangeWeek}
        loading={loading}
        handleEventClick={handleEventClick}
      />
      {openDetailSlotModal.condition && (
        <ShowEventModal
          openDetailSlotModal={openDetailSlotModal.condition}
          handleCancel={() => {
            setOpenDetailSlotModal({ ...openDetailSlotModal, condition: false });
          }}
          eventDetails={openDetailSlotModal.details}
          serviceState={schedulerState}
          // handleEditEvent={handleEditEvent}
          btnsHide={true}
          fetchServices={() => {
            // AllStates.fetchAllSchedulerService();
            setOpenDetailSlotModal({ ...openDetailSlotModal, condition: false });
          }}
        />
      )}
    </div>
  );
};

export default WeekCalender;
const localizer = momentLocalizer(moment);
const CalendarWeek = ({ schedulerState, dateRange, handleDataChange, loading, handleEventClick }) => {
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);

  // const dayFormat = (date, culture, localizer) => localizer.format(date, 'D MMMM YYYY', culture);
  const dayFormat = (date, culture, localizer) => localizer.format(date, "dddd", culture);

  moment.updateLocale("en-gb", {
    week: {
      dow: 1, // Monday is the first day of the week
    },
    // Optionally, if you want to keep the time format to 12-hour, you can set it explicitly
    longDateFormat: {
      LT: "h:mm A", // 12-hour time format
      LTS: "h:mm:ss A", // 12-hour time format with seconds
      // Add other formats if needed
    },
  });

  useEffect(() => {
    let modifyEvents = [];
    schedulerState.result &&
      schedulerState.result.map((scheduler) => {
        const [year, month, day] = scheduler.date.split("-").map(Number);
        const [startHour, startMinute, startSecond] = scheduler.start_time ? scheduler.start_time.split(":") : [0, 0, 0];
        const [endHour, endMinute, endSecond] = scheduler.end_time ? scheduler.end_time.split(":") : [0, 0, 0];
        modifyEvents.push({
          ...scheduler,
          backgroundColor: schedulerBackground(scheduler),
          color: schedulerColor(scheduler),
          start: new Date(year, month - 1, day, startHour, startMinute),
          end: new Date(year, month - 1, day, endHour, endMinute),
          title: scheduler.service.name || "",
          clientName: scheduler.client.name || "",
        });
      });
    setEvents(modifyEvents);
  }, [schedulerState]);

  return (
    <div id="scheduler-week-calendarv2" className="mb-3 mt-3">
      <div className={loading ? "filtering" : null}>
        <Calendar
          localizer={localizer}
          events={events}
          defaultView={Views.WEEK}
          views={["week"]}
          startAccessor="start"
          endAccessor="end"
          date={dateRange.start}
          drilldownView={Views.WEEK}
          formats={{
            dayFormat,
          }}
          components={{
            event: CustomComponents,
            toolbar: () => null,
          }}
          onSelectEvent={handleEventClick}
          eventPropGetter={(event) => ({
            style: {
              backgroundColor: event.backgroundColor,
              color: event.color,
              border: `1px solid ${event.color}`,
              borderRadius: 0,
              outline: "none",
            },
          })}
          dayLayoutAlgorithm="no-overlap"
        />
      </div>
    </div>
  );
};

export const CustomComponents = ({ event, title = "" }) => {
  return (
    <div className="d-flex cursorPointer" style={{ background: "#E5F6FF", borderRadius: "4px" }}>
      <div className="d-flex align-items-center">
        <div className="d-flex flex-column">
          <span>
            <span className="ms-1 me-1">
              <SchedulerMonthScheduledIcon width="12px" height="12px" stroke="#187BEF" />
            </span>
            <span style={{ color: "#187BEF" }}>{truncateString(title, 12)}</span>
            <span className="ms-1 me-1">
              <SchedulerMonthUserIcon width="12px" height="12px" stroke="#187BEF" />
            </span>
            <span style={{ color: "#187BEF" }}>{truncateString(event?.clientName, 12)}</span>
          </span>
        </div>
      </div>
    </div>
  );
};
