import truncateString from "../../util/truncate";
import "./table.css";

export const hours_of_day_12_hour_format = [
  "12 AM",
  "1 AM",
  "2 AM",
  "3 AM",
  "4 AM",
  "5 AM",
  "6 AM",
  "7 AM",
  "8 AM",
  "9 AM",
  "10 AM",
  "11 AM",
  "12 PM",
  "1 PM",
  "2 PM",
  "3 PM",
  "4 PM",
  "5 PM",
  "6 PM",
  "7 PM",
  "8 PM",
  "9 PM",
  "10 PM",
  "11 PM",
];

export const names = [];

export const Tablecolumns = (openModalFunc, addNewEventModal) => {
  return hours_of_day_12_hour_format.map((hour, index) => ({
    title: hour,
    dataIndex: `hourSlot${index}`,
    key: `hour_${index}`,
    className: "tableHeaders",
    ellipsis: true,

    render: (text, record, index) => ({
      children: (
        <div
          className={`d-flex justify-content-${record.halfHourSlot === "first half hour" ? "start" : record.halfHourSlot === "second half hour" ? "end" : ""}`}
          style={{ overflow: "hidden", height: "100%", width: "100%" }}
        >
          <div
            style={{
              backgroundColor: text ? "#E5F6FF" : "",
              border: text ? "1px solid #187BEF" : "",
              color: text ? " #187BEF" : "",
              padding: "8px 0px",
              cursor: "pointer",
              width: "50%",
              overflow: "hidden",
              // borderRight: "1px solid black",s
            }}
            onClick={() => {
              // if (text) {
              //   console.log(" with record", record);
              // } else {
              //   console.log(" withoput > record", record);
              // }
              if (text) {
                openModalFunc(record);
                return;
              }
              // addNewEventModal();
            }}
          >
            {record?.service?.name && text ? truncateString(record?.service?.name, 2) : ""}
            {/* {truncateString(text, 2)} */}
          </div>
        </div>
      ),
    }),
  }));
};

export const dumnydatav2 = [
  {
    hourSlot0: "Bob Alice",
    hourSlot23: "xxBob Alice",
    search: "Bob Alice",
  },
  {
    hourSlot2: "alex",
    searchSlot1: "alex",
    search: "alex",
  },
  {
    hourSlot2: "John carter",
    hourSlot6: "John carter",
    search: "John carter",
  },
];

const verticalData = ["Time Slot", "John carter", " Bob Alice", " Lissa Alice", " Client 1"];

export const dataHeaders = [
  {
    title: "RowHead",
    dataIndex: "key",
    rowScope: "row",
  },
  {
    title: "Name",
    dataIndex: "name",
    render: (text) => <a>{text}</a>,
    onCell: (_, index) => ({
      rowSpan: 3,
    }),
  },
  {
    title: "Age",
    dataIndex: "age",
    // onCell: sharedOnCell,
    onCell: (_, index) => ({
      rowSpan: 3,
    }),
  },
  {
    title: "Home phone",
    colSpan: 2,
    dataIndex: "tel",
  },
  {
    title: "Phone",
    colSpan: 0,
    dataIndex: "phone",
    // onCell: sharedOnCell,
  },
  {
    title: "Address",
    dataIndex: "address",
    // onCell: sharedOnCell,
  },
];

// Generate the table data combining time slots with vertical data
export const tableData = [
  {
    key: "1",
    name: "John Brown",
    age: 32,
    tel: "0571-22098909",
    phone: 18889898989,
    address: "New York No. 1 Lake Park",
  },
  {
    key: "2",
    name: "Jim Green",
    tel: "0571-22098333",
    phone: 18889898888,
    age: 42,
    address: "London No. 1 Lake Park",
  },
  {
    key: "3",
    name: "Joe Black",
    age: 32,
    tel: "0575-22098909",
    phone: 18900010002,
    address: "Sydney No. 1 Lake Park",
  },
  {
    key: "4",
    name: "Jim Red",
    age: 18,
    tel: "0575-22098909",
    phone: 18900010002,
    address: "London No. 2 Lake Park",
  },
  {
    key: "5",
    name: "Jake White",
    age: 18,
    tel: "0575-22098909",
    phone: 18900010002,
    address: "Dublin No. 2 Lake Park",
  },
];
const peopleNames = ["Alice", "Bob", "Charlie", "David", "Eva"];

export const dataSource = hours_of_day_12_hour_format.slice(0, 10).map((time, index) => {
  const rowData = {
    key: index,
    timeSlot: time,
    ellipsis: true,
    width: 100,
  };

  peopleNames.slice(0, 2).forEach((person, i) => {
    if (index === 0) {
      const initials = person.charAt(0) + person.charAt(person.length - 1);
      rowData[`person${i + 1}`] = person;
    } else {
      rowData[`person${i + 1}`] = ""; // Empty data for other rows
    }
  });

  return rowData;
});

const sharedOnCell = (record, index, totalColumns) => {
  if (index === 1) {
    console.log("index", index);
    console.log("record", record);

    // return;
    console.log("ind==================");
    console.log("record", record);
  }

  if (record.person2 === "Bob") {
    return {
      rowSpan: 4,
      colSpan: 2,
    };
  }
};

export const columnsv2 = [
  {
    title: "Time Slot",
    dataIndex: "timeSlot",
    key: "timeSlot",
    ellipsis: true,

    width: 80,
    render: (text) => <div style={{ wordWrap: "break-word", wordBreak: "break-word" }}>{text}</div>,
  },
  ...peopleNames.map((person, index) => ({
    title: person,
    dataIndex: `person${index + 1}`,
    key: `person${index + 1}`,
    ellipsis: true,
    width: 80,
    className: "timeSlotColumn",
    onCell: (record) => sharedOnCell(record, index, peopleNames.length),
    render: (text, record) => {
      return (
        <div
          style={{
            height: "100%", // Set the height of the container div
          }}
        >
          <div
            style={{
              backgroundColor: text ? "#E5F6FF" : "",
              border: text ? "1px solid #187BEF" : "",
              color: text ? "#187BEF" : "",
              padding: "8px 0px",
              cursor: "pointer",
              height: "100%", // Set the height of the content div
              display: "flex",
              alignItems: "center", // Center the content vertically
              textAlign: "center",
              justifyContent: "center",
            }}
          >
            {record[`person${index + 1}`]}
          </div>
        </div>
      );
    },
  })),
  // Add other columns as needed
];

function generateTableHeaders() {
  const headers = [];
  const amPm = ["AM", "PM"];

  for (let i = 1; i <= 12; i++) {
    for (let j = 0; j < 2; j++) {
      headers.push(`${i} ${amPm[j]}`);
    }
  }

  return headers;
}

const tableHeaders = generateTableHeaders();

export const columns = tableHeaders.map((header) => ({
  title: header,
  dataIndex: header.toLowerCase().replace(" ", ""), // Data index can be adjusted based on your data
  key: header.toLowerCase().replace(" ", ""), // Key can be adjusted based on your data
}));

// console.log("*******", columns);

// Function to generate separate array of objects with data for specific slots
function generateDataForSpecificSlots(headers) {
  const dataForSpecificSlots = [];

  // Find index of the headers corresponding to 3 AM and 4 AM
  const index3AM = headers.indexOf("3 AM");
  const index4AM = headers.indexOf("4 AM");

  // Check if 3 AM and 4 AM headers exist in the headers array
  if (index3AM !== -1 && index4AM !== -1) {
    // Create objects for 3 AM and 4 AM slots
    const data3AM = { hour: "3 AM", data: "Data for 3 AM" };
    const data4AM = { hour: "4 AM", data: "Data for 4 AM" };

    // Push the objects to the dataForSpecificSlots array
    dataForSpecificSlots.push(data3AM, data4AM);
  }

  return dataForSpecificSlots;
}

// Example usage
const dataForSpecificSlots = generateDataForSpecificSlots(tableHeaders);
// console.log("******* dataForSpecificSlots", dataForSpecificSlots);
