import React, { useEffect, useState } from "react";

import styled from "styled-components";
import { MainContentTopHeading, VideoPlayer, MainContentNavHeading } from "./ComapnyModalStyles";
import { ReactComponent as GreaterThanIcon } from "../../../assets/rawSvgs/greaterThanIcon.svg";
import { ReactComponent as LessThanIcon } from "../../../assets/rawSvgs/lessThanIcon.svg";
import { ReactComponent as PlayIconButton } from "../../../assets/rawSvgs/playIcon.svg";
import { CaretRightOutlined, PauseOutlined, StepBackwardOutlined, StepForwardOutlined } from "@ant-design/icons";

//
const commonText = `Every pet acre business needs great pet customers! You have already added your first test cleint, review our video below
on how to add a new client to your account`;
let bold = <b>Click to view more </b>;
const subText = ``;
export const InvoicingComponent = ({ activeSidebarNav, handleNext, handlePrev }) => {
  const [isPlaying, setIsPlaying] = React.useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const [progressBar, setProgressBar] = useState(0);
  const videoRef = React.useRef(null);
  const handlePlay = () => {
    setIsPlaying(true);
    videoRef.current.play();
  };
  const handleVideoClick = () => {
    if (isPlaying) {
      setIsPlaying(false);
      videoRef.current.pause();
      return;
    }
    setIsPlaying(true);
    videoRef.current.play();
  };

  const updateProgress = () => {
    const currentTime = videoRef.current.currentTime;
    const duration = videoRef.current.duration;
    const progressBar = (currentTime / duration) * 100;
    setProgressBar(progressBar);
  };

  const skipForward = () => {
    videoRef.current.currentTime += 10;
  };

  const skipBackward = () => {
    videoRef.current.currentTime -= 10;
  };

  return (
    <div>
      <div className="d-flex justify-content-between ">
        <MainContentNavHeading>{activeSidebarNav}</MainContentNavHeading>
        <div className="d-flex">
          <RoundedButton onClick={handlePrev}>
            <LessThanIcon />
          </RoundedButton>
          <RoundedButton margin="0px 8px" onClick={handleNext}>
            <GreaterThanIcon />
          </RoundedButton>
        </div>
      </div>
      <MainContentTopHeading>{commonText}</MainContentTopHeading>
      <MainContentTopHeading>
        {subText} <b>Click to view more </b>
      </MainContentTopHeading>
      <VideoPlayer>
        <div
          style={{ position: "relative", width: "100%", height: "100%" }}
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
        >
          {!isPlaying && (
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                cursor: "pointer",
                borderRadius: "50%",
                backgroundColor: "white",
                padding: "10px",
                height: "48px",
                width: "48px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                zIndex: 1000,
              }}
              onClick={handlePlay}
            >
              <PlayIconButton />
            </div>
          )}

          <div style={{ maxWidth: "90vw", height: "50vh", margin: "0 auto", overflow: "hidden" }}>
            <video
              ref={videoRef}
              autoPlay={isPlaying}
              onPlay={() => setIsPlaying(true)}
              onPause={() => setIsPlaying(false)}
              style={{ width: "100%", height: "100%", objectFit: "contain", backgroundColor: "black" }}
              controls={false}
              onTimeUpdate={updateProgress}
            >
              <source
                src={"https://buds-llc-furgis-cor-34563.s3.amazonaws.com/media/videos/welcomeVideo_PJqN3xQ.mp4"}
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          </div>
          {isHovering && (
            <div style={{ position: "absolute", bottom: "6%", width: "100%" }}>
              <div style={{ width: "100%", height: "5px", background: "#ccc" }}>
                <div style={{ width: `${progressBar}%`, height: "100%", background: "#FBCF34" }} />
              </div>
              <div style={{ display: "flex", justifyContent: "start", alignItems: "center" }}>
                <button onClick={skipBackward} style={{ color: "#fff" }}>
                  <StepBackwardOutlined />
                </button>
                <button onClick={handleVideoClick} style={{ color: "#fff" }}>
                  {isPlaying ? <PauseOutlined /> : <CaretRightOutlined />}
                </button>
                <button onClick={skipForward} style={{ color: "#fff" }}>
                  <StepForwardOutlined />
                </button>
              </div>
            </div>
          )}
        </div>
      </VideoPlayer>
    </div>
  );
};

export const StaffManagementComponent = ({ activeSidebarNav, handlePrev, handleNext }) => {
  const [isPlaying, setIsPlaying] = React.useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const [progressBar, setProgressBar] = useState(0);
  const videoRef = React.useRef(null);
  const handlePlay = () => {
    setIsPlaying(true);
    videoRef.current.play();
  };
  const handleVideoClick = () => {
    if (isPlaying) {
      setIsPlaying(false);
      videoRef.current.pause();
      return;
    }
    setIsPlaying(true);
    videoRef.current.play();
  };

  const updateProgress = () => {
    const currentTime = videoRef.current.currentTime;
    const duration = videoRef.current.duration;
    const progressBar = (currentTime / duration) * 100;
    setProgressBar(progressBar);
  };

  const skipForward = () => {
    videoRef.current.currentTime += 10;
  };

  const skipBackward = () => {
    videoRef.current.currentTime -= 10;
  };

  return (
    <div>
      <div className="d-flex justify-content-between ">
        <MainContentNavHeading>{activeSidebarNav}</MainContentNavHeading>
        <div className="d-flex">
          <RoundedButton onClick={handlePrev}>
            <LessThanIcon />
          </RoundedButton>
          <RoundedButton margin="0px 8px" onClick={handleNext}>
            <GreaterThanIcon />
          </RoundedButton>
        </div>
      </div>
      <MainContentTopHeading>{commonText}</MainContentTopHeading>
      <MainContentTopHeading>{subText}</MainContentTopHeading>
      <VideoPlayer>
        <div
          style={{ position: "relative", width: "100%", height: "100%" }}
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
        >
          {!isPlaying && (
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                cursor: "pointer",
                borderRadius: "50%",
                backgroundColor: "white",
                padding: "10px",
                height: "48px",
                width: "48px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                zIndex: 1000,
              }}
              onClick={handlePlay}
            >
              <PlayIconButton />
            </div>
          )}

          <div style={{ maxWidth: "90vw", height: "50vh", margin: "0 auto", overflow: "hidden" }}>
            <video
              ref={videoRef}
              autoPlay={isPlaying}
              onPlay={() => setIsPlaying(true)}
              onPause={() => setIsPlaying(false)}
              style={{ width: "100%", height: "100%", objectFit: "contain", backgroundColor: "black" }}
              controls={false}
              onTimeUpdate={updateProgress}
            >
              <source
                src={"https://buds-llc-furgis-cor-34563.s3.amazonaws.com/media/videos/welcomeVideo_PJqN3xQ.mp4"}
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          </div>
          {isHovering && (
            <div style={{ position: "absolute", bottom: "6%", width: "100%" }}>
              <div style={{ width: "100%", height: "5px", background: "#ccc" }}>
                <div style={{ width: `${progressBar}%`, height: "100%", background: "#FBCF34" }} />
              </div>
              <div style={{ display: "flex", justifyContent: "start", alignItems: "center" }}>
                <button onClick={skipBackward} style={{ color: "#fff" }}>
                  <StepBackwardOutlined />
                </button>
                <button onClick={handleVideoClick} style={{ color: "#fff" }}>
                  {isPlaying ? <PauseOutlined /> : <CaretRightOutlined />}
                </button>
                <button onClick={skipForward} style={{ color: "#fff" }}>
                  <StepForwardOutlined />
                </button>
              </div>
            </div>
          )}
        </div>
      </VideoPlayer>
    </div>
  );
};

export const TaskManagementComponent = ({ activeSidebarNav, handlePrev, handleNext }) => {
  const [isPlaying, setIsPlaying] = React.useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const [progressBar, setProgressBar] = useState(0);
  const videoRef = React.useRef(null);
  const handlePlay = () => {
    setIsPlaying(true);
    videoRef.current.play();
  };
  const handleVideoClick = () => {
    if (isPlaying) {
      setIsPlaying(false);
      videoRef.current.pause();
      return;
    }
    setIsPlaying(true);
    videoRef.current.play();
  };

  const updateProgress = () => {
    const currentTime = videoRef.current.currentTime;
    const duration = videoRef.current.duration;
    const progressBar = (currentTime / duration) * 100;
    setProgressBar(progressBar);
  };

  const skipForward = () => {
    videoRef.current.currentTime += 10;
  };

  const skipBackward = () => {
    videoRef.current.currentTime -= 10;
  };

  return (
    <div>
      <div className="d-flex justify-content-between ">
        <MainContentNavHeading>{activeSidebarNav}</MainContentNavHeading>
        <div className="d-flex">
          <RoundedButton onClick={handlePrev}>
            <LessThanIcon />
          </RoundedButton>
          <RoundedButton margin="0px 8px" onClick={handleNext}>
            <GreaterThanIcon />
          </RoundedButton>
        </div>
      </div>
      <MainContentTopHeading>{commonText}</MainContentTopHeading>
      <MainContentTopHeading>{subText}</MainContentTopHeading>
      <VideoPlayer>
        <div
          style={{ position: "relative", width: "100%", height: "100%" }}
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
        >
          {!isPlaying && (
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                cursor: "pointer",
                borderRadius: "50%",
                backgroundColor: "white",
                padding: "10px",
                height: "48px",
                width: "48px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                zIndex: 1000,
              }}
              onClick={handlePlay}
            >
              <PlayIconButton />
            </div>
          )}

          <div style={{ maxWidth: "90vw", height: "50vh", margin: "0 auto", overflow: "hidden" }}>
            <video
              ref={videoRef}
              autoPlay={isPlaying}
              onPlay={() => setIsPlaying(true)}
              onPause={() => setIsPlaying(false)}
              style={{ width: "100%", height: "100%", objectFit: "contain", backgroundColor: "black" }}
              controls={false}
              onTimeUpdate={updateProgress}
            >
              <source
                src={"https://buds-llc-furgis-cor-34563.s3.amazonaws.com/media/videos/welcomeVideo_PJqN3xQ.mp4"}
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          </div>
          {isHovering && (
            <div style={{ position: "absolute", bottom: "6%", width: "100%" }}>
              <div style={{ width: "100%", height: "5px", background: "#ccc" }}>
                <div style={{ width: `${progressBar}%`, height: "100%", background: "#FBCF34" }} />
              </div>
              <div style={{ display: "flex", justifyContent: "start", alignItems: "center" }}>
                <button onClick={skipBackward} style={{ color: "#fff" }}>
                  <StepBackwardOutlined />
                </button>
                <button onClick={handleVideoClick} style={{ color: "#fff" }}>
                  {isPlaying ? <PauseOutlined /> : <CaretRightOutlined />}
                </button>
                <button onClick={skipForward} style={{ color: "#fff" }}>
                  <StepForwardOutlined />
                </button>
              </div>
            </div>
          )}
        </div>
      </VideoPlayer>
    </div>
  );
};

export const ManageingClientComponent = ({ activeSidebarNav, handlePrev, handleNext, activeSideNavIndex }) => {
  const [isPlaying, setIsPlaying] = React.useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const [progressBar, setProgressBar] = useState(0);
  const videoRef = React.useRef(null);
  const handlePlay = () => {
    setIsPlaying(true);
    videoRef.current.play();
  };
  const handleVideoClick = () => {
    if (isPlaying) {
      setIsPlaying(false);
      videoRef.current.pause();
      return;
    }
    setIsPlaying(true);
    videoRef.current.play();
  };

  const updateProgress = () => {
    const currentTime = videoRef.current.currentTime;
    const duration = videoRef.current.duration;
    const progressBar = (currentTime / duration) * 100;
    setProgressBar(progressBar);
  };

  const skipForward = () => {
    videoRef.current.currentTime += 10;
  };

  const skipBackward = () => {
    videoRef.current.currentTime -= 10;
  };

  return (
    <div>
      <div className="d-flex justify-content-between ">
        <MainContentNavHeading>{activeSidebarNav}</MainContentNavHeading>
        <div className="d-flex">
          <RoundedButton onClick={handlePrev}>
            <LessThanIcon />
          </RoundedButton>
          <RoundedButton margin="0px 8px" onClick={handleNext}>
            <GreaterThanIcon />
          </RoundedButton>
        </div>
      </div>
      <MainContentTopHeading>{commonText}</MainContentTopHeading>
      <MainContentTopHeading>{subText}</MainContentTopHeading>
      <VideoPlayer>
        <div
          style={{ position: "relative", width: "100%", height: "100%" }}
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
        >
          {!isPlaying && (
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                cursor: "pointer",
                borderRadius: "50%",
                backgroundColor: "white",
                padding: "10px",
                height: "48px",
                width: "48px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                zIndex: 1000,
              }}
              onClick={handlePlay}
            >
              <PlayIconButton />
            </div>
          )}

          <div style={{ maxWidth: "90vw", height: "50vh", margin: "0 auto", overflow: "hidden" }}>
            <video
              ref={videoRef}
              autoPlay={isPlaying}
              onPlay={() => setIsPlaying(true)}
              onPause={() => setIsPlaying(false)}
              style={{ width: "100%", height: "100%", objectFit: "contain", backgroundColor: "black" }}
              controls={false}
              onTimeUpdate={updateProgress}
            >
              <source
                src={"https://buds-llc-furgis-cor-34563.s3.amazonaws.com/media/videos/welcomeVideo_PJqN3xQ.mp4"}
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          </div>
          {isHovering && (
            <div style={{ position: "absolute", bottom: "6%", width: "100%" }}>
              <div style={{ width: "100%", height: "5px", background: "#ccc" }}>
                <div style={{ width: `${progressBar}%`, height: "100%", background: "#FBCF34" }} />
              </div>
              <div style={{ display: "flex", justifyContent: "start", alignItems: "center" }}>
                <button onClick={skipBackward} style={{ color: "#fff" }}>
                  <StepBackwardOutlined />
                </button>
                <button onClick={handleVideoClick} style={{ color: "#fff" }}>
                  {isPlaying ? <PauseOutlined /> : <CaretRightOutlined />}
                </button>
                <button onClick={skipForward} style={{ color: "#fff" }}>
                  <StepForwardOutlined />
                </button>
              </div>
            </div>
          )}
        </div>
      </VideoPlayer>
    </div>
  );
};

export const OnboardingComponent = ({ handlePrev, handleNext, selectedStep }) => {
  const [isPlaying, setIsPlaying] = React.useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const [progressBar, setProgressBar] = useState(0);
  const videoRef = React.useRef(null);

  const handlePlay = () => {
    setIsPlaying(true);
    videoRef.current.play();
  };
  const handleVideoClick = () => {
    if (isPlaying) {
      setIsPlaying(false);
      videoRef.current.pause();
      return;
    }
    setIsPlaying(true);
    videoRef.current.play();
  };

  const updateProgress = () => {
    const currentTime = videoRef.current.currentTime;
    const duration = videoRef.current.duration;
    const progressBar = (currentTime / duration) * 100;
    setProgressBar(progressBar);
  };

  const skipForward = () => {
    videoRef.current.currentTime += 10;
  };

  const skipBackward = () => {
    videoRef.current.currentTime -= 10;
  };

  return (
    <div>
      {selectedStep && (
        <>
          <div className="d-flex justify-content-between ">
            <MainContentNavHeading>{selectedStep.name || ""}</MainContentNavHeading>
            <div className="d-flex">
              <RoundedButton onClick={handlePrev}>
                <LessThanIcon />
              </RoundedButton>
              <RoundedButton margin="0px 8px" onClick={handleNext}>
                <GreaterThanIcon />
              </RoundedButton>
            </div>
          </div>
          <MainContentTopHeading>{selectedStep.description || ""}</MainContentTopHeading>
          {/* <MainContentTopHeading>{subText}</MainContentTopHeading> */}
          <VideoPlayer>
            <div
              style={{ position: "relative", width: "100%", height: "100%" }}
              onMouseEnter={() => setIsHovering(true)}
              onMouseLeave={() => setIsHovering(false)}
            >
              {!isPlaying && (
                <div
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    cursor: "pointer",
                    borderRadius: "50%",
                    backgroundColor: "white",
                    padding: "10px",
                    height: "48px",
                    width: "48px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    zIndex: 1000,
                  }}
                  onClick={handlePlay}
                >
                  <PlayIconButton />
                </div>
              )}
              <div style={{ maxWidth: "90vw", height: "50vh", margin: "0 auto", overflow: "hidden" }}>
                <video
                  key={selectedStep.link}
                  ref={videoRef}
                  autoPlay={isPlaying}
                  onPlay={() => setIsPlaying(true)}
                  onPause={() => setIsPlaying(false)}
                  style={{ width: "100%", height: "100%", objectFit: "contain", backgroundColor: "black" }}
                  controls={false}
                  onTimeUpdate={updateProgress}
                >
                  <source src={selectedStep.link} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
              {isHovering && (
                <div style={{ position: "absolute", bottom: "6%", width: "100%" }}>
                  <div style={{ width: "100%", height: "5px", background: "#ccc" }}>
                    <div style={{ width: `${progressBar}%`, height: "100%", background: "#FBCF34" }} />
                  </div>
                  <div style={{ display: "flex", justifyContent: "start", alignItems: "center" }}>
                    <button onClick={skipBackward} style={{ color: "#fff" }}>
                      <StepBackwardOutlined />
                    </button>
                    <button onClick={handleVideoClick} style={{ color: "#fff" }}>
                      {isPlaying ? <PauseOutlined /> : <CaretRightOutlined />}
                    </button>
                    <button onClick={skipForward} style={{ color: "#fff" }}>
                      <StepForwardOutlined />
                    </button>
                  </div>
                </div>
              )}
            </div>
          </VideoPlayer>
        </>
      )}
    </div>
  );
};

const RoundedButton = styled.button`
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 1px solid #fbcf34;
  background-color: #ffffff;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: ${(props) => props.margin};
  cursor: pointer;
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;
