import StaffDetail from "../../pages/company/staff/staffdetail/index";
import { connect } from "react-redux";

import { fetchSingleStaffApi, fetchSingleStaffMileageTracking, fetchSingleStaffTimeTracking } from "../../redux/slices/StaffSlice";

const mapStateToProps = (state) => {
  return {
    singleStaffresponseData: state.staffManagement.staffSingleDetails.responseData,
    singleStaffstate: state.staffManagement.staffSingleDetails,
    StaffState: state.staffManagement,
    loginState: state.auth.loginResponse,
    permissionState: state.permissionManagement.permissions,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchSingleStaff: (id) => {
    dispatch(fetchSingleStaffApi(id));
  },
  fetchSingleStaffTimeTracking: (id, queryParams='') => {
    dispatch(fetchSingleStaffTimeTracking(id, queryParams));
  },
  fetchSingleStaffMileageTracking: (id, queryParams='') => {
    dispatch(fetchSingleStaffMileageTracking(id, queryParams));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(StaffDetail);
