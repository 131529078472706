import React, {  } from "react";
import { CustomModal } from "../../../components/Modals/ModalStyles";
import dayjs from "dayjs";
import {SelectServicesScreen} from './ServiceRequestModal'


const ChangeRequestModal = ({selectedEvent, isModalOpen, setIsModalOpen, handleDataChange }) => {
    return (
      <CustomModal
        open={isModalOpen}
        title="Request Change to this visit"
        width="800px"
        heigth="583px"
        onCancel={() => {
          setIsModalOpen(false);
        }}
        footer={null}
      >        
        <SelectServicesScreen handleDataChange={handleDataChange} selectedEvent={selectedEvent} startDate={dayjs(selectedEvent.date)} endDate={dayjs(selectedEvent.date)} setIsModalOpen={setIsModalOpen}/>
      </CustomModal>
    );
};

export default ChangeRequestModal;