import React from "react";
import { ReactComponent as EditIcon } from "../../assets/rawSvgs/catIcon.svg";
import { Space, Tag } from "antd";

const AntDesignTagsComponenet = ({ tagText = "Cat", tagIcon = <EditIcon style={{ marginRight: "4px" }} /> }) => (
  <Tag
    icon={tagIcon}
    color="#EEEEEE"
    style={{
      color: "black",
      fontSize: "12px",
      fontWeight: 500,
      display: "inline-flex",
      alignItems: "center",
      lineHeight: "inherit",
      padding: "1px 7px 1px 5px",
    }}
  >
    <span style={{ display: "inline-block", transform: "translateY(1.5px)" }}>{tagText}</span>
  </Tag>
);

export default AntDesignTagsComponenet;
