import React, { useState, useEffect, useCallback } from "react";
import { TableHeader } from "./tasksManagementStyles";
import SearchInput from "../../../components/SearchInput";
import CustomBtnWithIcon from "../../../components/buttonWithIcon";
import { AntdesignTable } from "../../../components/antDesignTable/AntdesignTable";
import { DatePicker, Select, Table } from "antd";
import { TasksListColumns } from "../../../constants/CompanyTableHeaders/TaskManagementHeaders";
import { AssigneeType } from "../../../constants/TasksManagement";
import EditTaskModal from "../../../components/Modals/TasksManagementModal/EditTaskModal";
import { deleteTask } from "../../../util/DataService";
import DeleteConfirmationRowModal from "../../../components/Modals/DeleteConfirmationRowModal";
import { pushNotification } from "../../../util/Notification";
import CreateTaskModal from "../../../components/Modals/TasksManagementModal/CreateTaskModal";
import { useSelector } from "react-redux";
import PermissionModal from "../../../components/Modals/PermissionModal";
import { checkPermission } from "../../../util/commonUtil";
import NoDataScreen from "../../../components/NoDataScreen";

const { RangePicker } = DatePicker;

const TasksList = ({ props }) => {
  const [search, setSearch] = useState("");
  const [type, setType] = useState("");
  const [status, setStatus] = useState("");
  const [assignee, setAssignee] = useState("");
  const [assigneeSelectList, setAssigneeSelectList] = useState([]);
  const [priority, setPriority] = useState("");
  const [editModalValues, setEditModalValues] = useState({});
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteModalValues, setDeleteModalValues] = useState("");
  // const tasksList = props.tasksListResponse.result.map(item => ({label: item.name, value: item.id}));
  const generalList = props.companyUsersListResponse?.result?.map((item) => ({ label: item.name, value: item.id }));
  const clientList = props.clientManagementState?.clientList;
  const staffList = props.allStaffState?.staffList;
  const [permissionPopUp, setPermissionPopUp] = useState(false);
  const { permissionManagement } = useSelector((state) => state);

  useEffect(() => {
    let assigneeList = [];

    if (type === "client") {
      assigneeList = props.clientManagementState.clientList;
    } else if (type === "staff") {
      assigneeList = props.allStaffState.staffList;
    } else if (type === "general") {
      assigneeList = props.companyUsersListResponse?.result.map((item) => ({ value: item.id, label: item.name }));
    }

    setAssigneeSelectList(assigneeList);
    props.loadCompanyUsersListByRole(4);
  }, [search, type, status, assignee, priority]);

  useEffect(() => {
    if (checkPermission(permissionManagement.permissions, "view_staffs")) {
      props.fetchAllStaff();
    }
    if (checkPermission(permissionManagement.permissions, "view_clients")) {
      props.getManagementClients();
    }
    if (checkPermission(permissionManagement.permissions, "view_tasks")) {
      props.loadTasksList(search, type, status, assignee, priority);
    }
  }, [permissionManagement.permissions]);

  const searchBlurHandler = () => {
    props.loadTasksList(search, type, status, assignee, priority);
  };

  const handleEditRow = (record) => {
    if (checkPermission(permissionManagement.permissions, "edit_task")) {
      props.loadTask(record.id);
      setIsEditModalOpen(true);
    } else {
      setPermissionPopUp(true);
    }
  };

  const handleDelete = (id) => {
    if (checkPermission(permissionManagement.permissions, "delete_task")) {
      setDeleteModalValues(id);
      setIsDeleteModalOpen(true);
    } else {
      setPermissionPopUp(true);
    }
  };

  const deleteTaskHandler = () => {
    deleteTask(deleteModalValues).then(({ data }) => {
      pushNotification(data.message, "success");
      setIsDeleteModalOpen(false);
      props.loadTasksList();
    });
  };

  const createTask = () => {
    if (checkPermission(permissionManagement.permissions, "create_task")) {
      setIsAddModalOpen(true);
    } else {
      setPermissionPopUp(true);
    }
  };

  const tasksHeadersParms = {
    handleEditRow,
    handleDelete,
  };
  return (
    <>
      <CreateTaskModal
        isModalOpen={isAddModalOpen}
        setModalOpen={setIsAddModalOpen}
        clientList={clientList}
        staffList={staffList}
        generalList={generalList}
        loadTasksList={props.loadTasksList}
      />
      <EditTaskModal
        isModalOpen={isEditModalOpen}
        setModalOpen={setIsEditModalOpen}
        clientList={clientList}
        staffList={staffList}
        generalList={generalList}
        editModalValues={props.taskResponse?.result}
        loadTasksList={props.loadTasksList}
        isLoading={props?.isLoading}
      />
      <DeleteConfirmationRowModal
        onclose={() => setIsDeleteModalOpen(false)}
        handleConfirmDeleteRows={deleteTaskHandler}
        openConfimrationDeleteModal={isDeleteModalOpen}
      />
      {
        <>
          {checkPermission(permissionManagement.permissions, "view_tasks") && (
            <TableHeader>
              <div className="d-flex align-items-center col">
                <SearchInput
                  search={search}
                  onChange={(value) => setSearch(value)}
                  onBlur={searchBlurHandler}
                  placeholder="Type to search.."
                />
                <Select
                  onChange={(value) => setType(value)}
                  placeholder="Type"
                  className="ms-2"
                  style={{ height: "40px", minWidth: "150px" }}
                  allowClear
                  onClear={() => setType("")}
                  options={AssigneeType}
                />
                <Select
                  onChange={(value) => setStatus(value)}
                  placeholder="Status"
                  className="ms-2"
                  style={{ height: "40px", minWidth: "150px" }}
                  allowClear
                  onClear={() => setStatus("")}
                  options={[
                    { value: "pending", label: "Pending" },
                    { value: "inprogress", label: "In Progress" },
                    { value: "completed", label: "Completed" },
                  ]}
                />
                <Select
                  onChange={(value) => setAssignee(value)}
                  placeholder="Assignee"
                  className="ms-2"
                  style={{ height: "40px", minWidth: "150px" }}
                  allowClear
                  onClear={() => setAssignee("")}
                  options={assigneeSelectList}
                />
                <Select
                  onChange={(value) => setPriority(value)}
                  placeholder="Priority"
                  className="ms-2"
                  style={{ height: "40px", minWidth: "150px" }}
                  allowClear
                  onClear={() => setPriority("")}
                  options={[
                    { value: "low", label: "Low" },
                    { value: "medium", label: "Medium" },
                    { value: "high", label: "High" },
                  ]}
                />
                <CustomBtnWithIcon
                  btnText={"Add New Task"}
                  className="ms-md-auto"
                  margin="10px 10px 10px auto"
                  onClick={() => createTask()}
                />
              </div>
            </TableHeader>
          )}
          {props.tasksListResponse?.result?.length > 0 && checkPermission(permissionManagement.permissions, "view_tasks") ? (
            <AntdesignTable columns={TasksListColumns(tasksHeadersParms)} data={props.tasksListResponse?.result || []} />
          ) : (
            <NoDataScreen
              heading="You don’t have any Task in your list. Let’s get started by adding a new Task"
              btnText={"Create new Task"}
              onClick={() => createTask()}
            />
          )}
        </>
      }
      <PermissionModal onclose={() => setPermissionPopUp(false)} openModal={permissionPopUp} />
    </>
  );
};

export default TasksList;
