import React, { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { AntdesignTable } from "../../../components/antDesignTable/AntdesignTable";
import { holidaysHeaders, dummDataHoliday } from "../../../constants/CompanyTableHeaders/ServiceAndHolidays";
import SearchInput from "../../../components/SearchInput";
import CustomBtnWithIcon from "../../../components/buttonWithIcon";
import AntDesignModal from "../../../components/Modals/AntDesignModal/AntDesignModal";
import { Formik, Form } from "formik";
import FormikInputFloating from "../../../components/formikInputs/TextInput";
import { Col, Row, DatePicker, Segmented } from "antd";
import FormikSelectInput from "../../../components/formikInputs/SelectInput";
import dayjs from "dayjs";

import FormikCheckboxInput from "../../../components/formikInputs/Checkbox";
import HeadingComponent from "../../../components/Heading";

import { ReactComponent as EditIcon } from "../../../assets/rawSvgs/tableEditIcon.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/rawSvgs/tableDeleteIcon.svg";
import { ReactComponent as DisabledIcon } from "../../../assets/rawSvgs/disabledIcon.svg";
import { ReactComponent as EnableService } from "../../../assets/rawSvgs/enableService.svg";

import { main_api } from "../../../api/axios_helper";
import { companyAPIsEndPoints } from "../../../constants/ApiEndPoints";
import { pushNotification } from "../../../util/Notification";

import DeleteConfirmationRowModal from "../../../components/Modals/DeleteConfirmationRowModal";
import styled from "styled-components";
import { useDebounce } from "../../../hooks/useDebounce";
import { checkPermission } from "../../../util/commonUtil";
import {useSelector} from 'react-redux'
import PermissionModal from "../../../components/Modals/PermissionModal";
const { RangePicker } = DatePicker;
//
const Holiday = ({ companyId, fetchAllHolidays, holidayStateResponseData }) => {
  const [openHolidayFormModal, setOpebHolidayModalForm] = useState(false);
  const [SelectTab, setSelectTab] = React.useState("Active Holidays");
  const [serviceDelete, setServiceDelete] = useState(false);
  const [updateServiceFormModal, setUpdateServiceFormModal] = useState(false);
  const [deleteRowId, setDeleteRowId] = useState("");
  const [updateServiceValues, setUpdateServiceValues] = React.useState({});
  const [filteredServices, setFilteredServices] = useState([]);
  const [holidayFormLoader, setHolidayFormLoader] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 200);
  const [permissionPopUp, setPermissionPopUp] = useState(false);
  const { permissionManagement } = useSelector((state) => state);

  useEffect(() => {
    if (holidayStateResponseData) {
      const filtered = holidayStateResponseData?.filter((item) => {
        if (SelectTab === "Active Holidays") {
          return item.active;
        } else if (SelectTab === "Disabled Holidays") {
          return item.active === false;
        }
        return true; // Default to true if SelectTab doesn't match any case
      });
      setFilteredServices(filtered);
    }
  }, [holidayStateResponseData, SelectTab]);

  useEffect(() => {
    if(checkPermission(permissionManagement.permissions, 'view_holidays')){
      fetchAllHolidays(debouncedSearchTerm);
    }
  }, [debouncedSearchTerm, permissionManagement.permissions]);

  const handleSelectDeleteMultiple = (rows) => {
    console.log(rows.rowKeys);
  };

  const handleAddNewHoliday = async (values) => {
    try {
      // const objSend = { ...values, company: companyId, holiday_date: dayjs(values.holiday_date).format("YYYY-MM-DD") };
      setHolidayFormLoader(true);
      Object.keys(values).forEach((key) => {
        if (values[key] === "") {
          delete values[key];
        }
      });
      const renameDateKey = { ...values, dates: values.holiday_date, company: companyId };
      delete renameDateKey["holiday_date"];
      if (updateServiceFormModal) {
        await handleUpdateService(renameDateKey, () => setHolidayFormLoader(false));
        return;
      }

      const { data } = await main_api.post(companyAPIsEndPoints.POST_NEW_HOLIDAYS, renameDateKey);
      setHolidayFormLoader(true);

      if (data.isSuccess) {
        pushNotification("Holiday added successfully", "success");
        fetchAllHolidays();
        setOpebHolidayModalForm(false);
      }
    } catch (error) {
      console.log("errr", error);
    }
  };

  const handleUpdateService = async (values, formloader) => {
    try {
      const { data } = await main_api.put(companyAPIsEndPoints.UPDATE_SPECIFIC_HOLIDAY_(updateServiceValues.id), values);

      if (data.isSuccess) {
        pushNotification(data?.message, "success");
        setUpdateServiceFormModal(false);
        fetchAllHolidays();
      }
      formloader(false);
    } catch (error) {
      formloader(false);

      console.log(error, "error");
    }
  };

  const handleDeleteService = async () => {
    try {
      const response = await main_api.delete(companyAPIsEndPoints.DELETE_SPECIFIC_HOLIDAY_(deleteRowId));

      if (response?.status === 200) {
        pushNotification(response?.data.message, "success");
        setServiceDelete(false);
        fetchAllHolidays();
        return;
      }
      pushNotification(response?.data.message, "error");
    } catch (error) {
      console.log("error:", error);
    }
  };
  const handleDisableRow = async (values) => {
    try {
      const objSend = { ...values, active: values.active ? false : true, company: companyId };
      delete objSend["time_blocks"];
      delete objSend["updated_at"];
      delete objSend["created_at"];

      const { data } = await main_api.put(companyAPIsEndPoints.UPDATE_SPECIFIC_HOLIDAY_(objSend.id), objSend);
      if (data.isSuccess) {
        pushNotification(data?.message, "success");
        setUpdateServiceFormModal(false);
        fetchAllHolidays();
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  const createHoliday = () => {
    if(checkPermission(permissionManagement.permissions, 'create_holiday')){
      setOpebHolidayModalForm(true)
    } else{
      setPermissionPopUp(true)
    }
  }

  const items = [
    {
      label: (
        <div
          style={{ width: "80px", padding: "2px 2px" }}
          onClick={(e) => {
            if(checkPermission(permissionManagement.permissions, 'edit_holiday')){
              setUpdateServiceFormModal(true);
              setUpdateServiceValues(e);
            } else{
              setPermissionPopUp(true)
            }
          }}
        >
          <span className="me-3">
            <EditIcon />
          </span>
          Edit
        </div>
      ),
      key: "0",
    },
    {
      label: (
        <div
          style={{ width: "80px", padding: "2px 0px" }}
          className="d-flex justify-content-evenly"
          onClick={(record) => {
            if (record.id) {
              handleDisableRow(record);
            }
          }}
        >
          <span className="me-3">{SelectTab === "Disabled Holidays" ? <EnableService /> : <DisabledIcon />}</span>
          {SelectTab === "Disabled Holidays" ? "Enable" : "Disable"}
        </div>
      ),
      key: "1",
    },

    {
      label: (
        <div
          style={{ width: "80px", padding: "2px 0px" }}
          className="d-flex justify-content-evenly"
          onClick={(record) => {
            if(checkPermission(permissionManagement.permissions, 'delete_holiday')){
              setDeleteRowId(record?.id);
              setServiceDelete(true);
            } else{
              setPermissionPopUp(true)
            }
          }}
        >
          <span className="me-3">
            <DeleteIcon />
          </span>
          <span>Delete</span>
        </div>
      ),
      key: "3",
    },
  ];

  return (
    <>
      <div className="" style={{ width: "500px" }}>
        <CustomSegmented
          style={{ marginBottom: 8 }}
          onChange={(value) => setSelectTab(value)}
          options={["Active Holidays", "Disabled Holidays"]}
          className="w-100 p-1"
          size="large"
          defaultValue="Active Holidays"
        />
      </div>
      {/* <div>
        <TabsWrapper width="300px">
          <TabPill selected={SelectTab === "activeHolidays"} onClick={() => setSelectTab("activeHolidays")}>
            Active Holidays
          </TabPill>
          <TabPill selected={SelectTab === "disabledHolidays"} onClick={() => setSelectTab("disabledHolidays")}>
            Disabled Holidays
          </TabPill>
        </TabsWrapper>
      </div> */}
      <div className="d-flex justify-content-between">
        <SearchInput
          width="80%"
          placeholder="Type to search..."
          containerBorder={"0"}
          onChange={(value) => setSearchTerm(value)}
        />

        <CustomBtnWithIcon btnText={"Add new Holiday"} onClick={() => createHoliday()} />
      </div>
      <AntdesignTable
        columns={holidaysHeaders({ items })}
        data={filteredServices || []}
        selectedRows={handleSelectDeleteMultiple}
      />
      {openHolidayFormModal && (
        <HolidayFormModal
          closeModal={() => setOpebHolidayModalForm(false)}
          openModal={openHolidayFormModal}
          submitForm={handleAddNewHoliday}
          holidayFormLoader={holidayFormLoader}
        />
      )}
      {updateServiceFormModal && (
        <HolidayFormModal
          closeModal={() => setUpdateServiceFormModal(false)}
          openModal={updateServiceFormModal}
          submitForm={handleAddNewHoliday}
          updateForm={true}
          updateServiceValues={updateServiceValues}
          holidayFormLoader={holidayFormLoader}
        />
      )}

      <DeleteConfirmationRowModal
        onclose={() => setServiceDelete(false)}
        handleConfirmDeleteRows={() => handleDeleteService()}
        openConfimrationDeleteModal={serviceDelete}
        padding="0px"
        // BtnloadingState={() => console.log("")}
      />
      <PermissionModal
        onclose={() => setPermissionPopUp(false)}
        openModal={permissionPopUp}
      />
    </>
  );
};

export default Holiday;

const HolidayFormModal = ({ closeModal, openModal, updateForm, updateServiceValues, submitForm, holidayFormLoader }) => {
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required("Holiday name is required")
      .min(3, "Minimum 3 Characters Required")
      .test("no-empty-spaces", "Name cannot contain only spaces", (value) => {
        const trimmedValue = value?.replace(/^\s+|\s+$/g, "");
        return trimmedValue !== "";
      })
      .max(255, "Maximum 255 Characters You Can Enter"),
    cost: Yup.number().max(100000, "Max holiday cost is 100000"),
  });
  const formikRef = React.useRef();
  const options = [
    { value: 1, label: "%" },
    { value: 2, label: "$" },
  ];
  const howToChargeTypeList = [
    { label: "Per event", value: 1 },
    { label: "Per client per day", value: 2 },
  ];
  const frequencyList = [
    { label: "Same day and month each year", value: 1 },
    { label: "Date varies each year", value: 2 },
  ];

  const dateFormat = "YYYY-MM-DD";
  const currentDate = dayjs().format(dateFormat);
  return (
    <>
      <AntDesignModal
        closeModal={closeModal}
        open={openModal}
        style={{
          top: 10,
          height: "300px",
          padding: "10px 24px",
        }}
        width="700px"
        component={
          <>
            <Formik
              initialValues={{
                name: updateForm ? updateServiceValues.name : "",
                cost: updateForm ? updateServiceValues.cost : "",
                cost_type: updateForm ? updateServiceValues.cost_type : "",
                staff_rate: updateForm ? updateServiceValues.staff_rate : "",
                staff_rate_type: updateForm ? updateServiceValues.staff_rate_type : "",
                frequency: updateForm ? updateServiceValues.frequency : "",
                charge_type: updateForm ? updateServiceValues.charge_type : "",
                active: updateForm ? updateServiceValues.active : true,
                holiday_date: updateForm ? updateServiceValues?.dates : [currentDate, currentDate],
              }}
              innerRef={formikRef}
              validationSchema={validationSchema}
              enableReinitialize
              onSubmit={(values, { setTouched }) => {
                // Handle form submission
                setTouched({
                  selectedOption: true,
                });
                submitForm(values);
              }}
            >
              {({ handleSubmit, setFieldValue, values }) => (
                <Form onSubmit={handleSubmit}>
                  <Row gutter={10}>
                    <Col md={24} className="">
                      <FormikInputFloating
                        label="Holiday Name"
                        placeHolder="Enter Name"
                        name="name"
                        type="text"
                        className="w-100 mb-2"
                        autoComplete="new-password"
                      />
                    </Col>
                    <Col md={12} className="">
                      <FormikInputFloating
                        label="Cost"
                        placeHolder="Enter Cost"
                        name="cost"
                        type="number"
                        className="w-100 mb-2"
                        autoComplete="new-password"
                      />
                    </Col>
                    <Col md={12} className="">
                      <FormikSelectInput
                        label=""
                        placeholder="Cost Type"
                        name="cost_type"
                        type="text"
                        className="w-100 mb-2"
                        autoComplete="new-password"
                        options={options}
                        {...(updateForm
                          ? {
                              value:
                                values.cost_type === 1
                                  ? options[0].label
                                  : values.cost_type === 2
                                    ? options[1].label
                                    : values.cost_type,
                            }
                          : {})}
                        onChange={(e) => {
                          setFieldValue("cost_type", parseInt(e));
                        }}
                        width="100%"
                      />
                    </Col>
                    <Col md={12} className="">
                      <FormikInputFloating
                        label="Staff Rate for Holidays"
                        placeHolder="Enter Staff Cost"
                        name="staff_rate"
                        type="number"
                        className="w-100 mb-2"
                        autoComplete="new-password"
                      />
                    </Col>
                    <Col md={12} className="">
                      <FormikSelectInput
                        label=""
                        placeholder="Cost Type"
                        name="staff_rate_type"
                        type="text"
                        className="w-100 mb-2"
                        autoComplete="new-password"
                        options={options}
                        width="100%"
                        {...(updateForm
                          ? {
                              value:
                                values.staff_rate_type === 1
                                  ? options[0].label
                                  : values.staff_rate_type === 2
                                    ? options[1].label
                                    : values.staff_rate_type,
                            }
                          : {})}
                        onChange={(e) => {
                          setFieldValue("staff_rate_type", parseInt(e));
                        }}
                      />
                    </Col>
                    <Col md={24} className="">
                      <FormikSelectInput
                        label="How To Charge"
                        placeholder="Select Type"
                        name="charge_type"
                        type="text"
                        className="w-100 mb-2"
                        autoComplete="new-password"
                        {...(updateForm
                          ? {
                              value:
                                values.charge_type === 1
                                  ? howToChargeTypeList[0].label
                                  : values.charge_type === 2
                                    ? howToChargeTypeList[1].label
                                    : values.charge_type,
                            }
                          : {})}
                        options={howToChargeTypeList}
                        onChange={(e) => {
                          setFieldValue("charge_type", parseInt(e));
                        }}
                        width="100%"
                      />
                    </Col>

                    <Col md={24}>
                      <FormikSelectInput
                        label="Changes Each Year"
                        placeholder="Select Type"
                        name="frequency"
                        type="text"
                        className="w-100 mb-2"
                        autoComplete="new-password"
                        {...(updateForm
                          ? {
                              value:
                                values.frequency === 1
                                  ? frequencyList[0].label
                                  : values.frequency === 2
                                    ? frequencyList[1].label
                                    : values.frequency,
                            }
                          : {})}
                        options={frequencyList}
                        onChange={(e) => {
                          setFieldValue("frequency", parseInt(e));
                        }}
                        width="100%"
                      />
                    </Col>
                    <Col md={24} className="my-2">
                      <div className="d-flex  align-items-baseline">
                        <FormikCheckboxInput
                          label=""
                          name="active"
                          type="text"
                          className=" mb-2"
                          checked={values.active}
                          onChange={() => {
                            setFieldValue("active", !values.active);
                          }}
                        />
                        <HeadingComponent text={"Enable Holiday"} fontSize="12px" margin="10px" />
                      </div>
                    </Col>
                    <Col md={24} className="my-2">
                      <div className="d-flex  align-items-baseline">
                        <RangePicker
                          onChange={(e, dateString) => {
                            const dayid = dayjs(dateString, dateFormat);
                            if (dayid) {
                              setFieldValue("holiday_date", dateString);
                            }
                          }}
                          format="YYYY-MM-DD"
                          placeholder={["Start Date", "End Date"]}
                          label="When"
                          name="dates"
                          type="text"
                          {...(updateForm
                            ? {
                                defaultValue: [
                                  dayjs(values?.holiday_date?.[0], dateFormat),
                                  dayjs(values?.holiday_date?.[1], dateFormat),
                                ],
                              }
                            : {})}
                          // defaultValue={[dayjs(values?.holiday_date[0], dateFormat), dayjs(values?.holiday_date[1], dateFormat)]}
                          className=" mb-2 w-100"
                          // value={values?.holiday_date}
                        />
                      </div>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </>
        }
        title={updateForm ? "Edit a Holiday" : "Add a new Holiday"}
        footer={
          <>
            <div className="d-flex justify-content-end">
              <CustomBtnWithIcon btnText={"Cancel"} margin="0px 5px" noBackground hideIcon={true} onClick={closeModal} />
              <CustomBtnWithIcon
                btnText={"Save Holiday"}
                type="submit"
                hideIcon={true}
                disabled={holidayFormLoader}
                onClick={() => {
                  if (formikRef?.current) {
                    formikRef?.current?.handleSubmit();
                  }
                }}
              />
            </div>
          </>
        }
      />
    </>
  );
};

const CustomSegmented = styled(Segmented)`
  .ant-segmented-group {
    ${"" /* gap: 5px; */}
  }
  .ant-segmented-item {
    width: 50%;
  }
`;
