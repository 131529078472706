import { connect } from "react-redux";
import { fetchSingleStaffApi, fetchAllStaffApi } from "../../redux/slices/StaffSlice";
import { loadCompanyDetail } from "../../redux/platformSlices/CompanyDetail";
import Payment from "../../pages/payment/index";

const mapStateToProps = (state) => {
  return {
    loginState: state.auth.loginResponse,
    allStaffState: state.staffManagement.staffAllDetails,
    companyDetailResponse: state.companyDetail.companyDetailResponse,
    singleStaffresponseData: state.staffManagement.staffSingleDetails.responseData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchSingleStaff: (id) => {
    dispatch(fetchSingleStaffApi(id));
  },
  fetchAllStaff: (searchterm) => {
    dispatch(fetchAllStaffApi(searchterm));
  },
  loadCompanyDetail: (id) => {
    dispatch(loadCompanyDetail(id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Payment);
