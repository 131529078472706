import ResetPassword from '../../pages/company/auth/ResetPassword';
import { connect } from 'react-redux';

const mapStateToProps = (state) => {
  return {
    total: 10,
  };
};

const mapDispatchToProps = (dispatch) => ({
  increment: () => {
    console.log('u lcicked cponsole');
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
