import { Space, Tag } from "antd";
import { ReactComponent as EditIcon } from "../../assets/rawSvgs/tableEditIcon.svg";
import { ReactComponent as DeleteIcon } from "../../assets/rawSvgs/tableDeleteIcon.svg";
import EditPetModal from "../../components/Modals/PetManagementModal/EditPetModal";
import { CommonActionHeaders } from "../TableActionHeaderColumn";
import dayjs from "dayjs";

const PetListColumns = ({ handleDelete = "", handleEditRow = "", viewPetDetail, props = "" }) => {
  return [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (_, record) => (
        <a
          onClick={() => {
            viewPetDetail(record)
            // props.openDetailModal(true);
            // props.loadPetDetail(record.id);
          }}
        >
          {record.name}
        </a>
      ),
    },
    {
      title: "Birth Date",
      dataIndex: "birth_date",
      key: "birth_date",
      render: (_, record) => (
        <>{dayjs(record.birth_date).format("DD/MM/YYYY")}</>
      )
    },
    {
      title: "Client",
      dataIndex: "client_name",
      key: "client_name",
    },
    ...CommonActionHeaders({ handleDelete, handleEditRow }),
  ];
};

const VaccinationsColumns = ({ handleDelete = "", handleEditRow = "" }) => {
  return [
    {
      title: "Client",
      dataIndex: "client_name",
      key: "client_name",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Pet",
      dataIndex: "pet_name",
      key: "pet_name",
    },
    {
      title: "Vaccination Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Vaccination Date",
      dataIndex: "date",
      key: "date",
      render: (_, record) => (
        <>{dayjs(record.date).format("DD/MM/YYYY")}</>
      )
    },
    {
      title: "Expire Date",
      dataIndex: "expiry_date",
      key: "expiry_date",
      render: (_, record) => (
        <>{dayjs(record.expiry_date).format("DD/MM/YYYY")}</>
      )
    },
    ...CommonActionHeaders({ handleDelete, handleEditRow }),
  ];
};

const VetListColumns = ({ handleDelete = "", handleEditRow = "" }) => {
  return [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Phone No.",
      dataIndex: "primary_phone",
      key: "primary_phone",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
    ...CommonActionHeaders({ handleDelete, handleEditRow }),
  ];
};

export { PetListColumns, VaccinationsColumns, VetListColumns };
