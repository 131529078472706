import React, { useState, useEffect } from "react";
import { AntdesignTable } from "../../../components/antDesignTable/AntdesignTable";
import DeleteConfirmationRowModal from "../../../components/Modals/DeleteConfirmationRowModal";
import { ClientSchedulerListColumens } from "../../../constants/ClientTableHeader/schedulerListHeader";
import ChangeRequestModal from "./ChangeRequestModal";
import { companyAPIsEndPoints } from "../../../constants/ApiEndPoints";
import { pushNotification } from "../../../util/Notification";
import { main_api } from "../../../api/axios_helper";
import { useSelector } from "react-redux";
import { checkPermission } from "../../../util/commonUtil";
import PermissionModal from "../../../components/Modals/PermissionModal";
import { schedulerStatus } from "../../../components/SchedulerCalender/helpers";
import { SchedulerStatus } from "../../../enums/scheduler";
import axiosInstance from "../../../util/AxiosConfig";

const SchedulerList = ({ schedulerState, handleDataChange }) => {
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [deleteEvent, setDeleteEvent] = useState(null);
  const permissionManagement = useSelector((state) => state.permissionManagement);
  const [permissionPopUp, setPermissionPopUp] = useState(false);
  const auth = useSelector((state) => state.auth);

  const handleEditRow = (service) => {
    if (checkPermission(permissionManagement.permissions, "edit_service_request")) {
      setSelectedEvent(service);
    } else {
      setPermissionPopUp(true);
    }
  };

  const handleDelete = (service) => {
    if (checkPermission(permissionManagement.permissions, "delete_service_request")) {
      setDeleteEvent(service);
    } else {
      setPermissionPopUp(true);
    }
  };

  const deleteService = () => {
    const client_id = auth.loginResponse?.selectCompany?.filter((item) => item.client_id !== null)[0].client_id;
    if (deleteEvent.status === SchedulerStatus.SCHEDULED) {
      let data = {
        date: deleteEvent.date,
        id: deleteEvent.id,
        client: client_id,
        one_day_service_request: deleteEvent.one_day_service_request.id,
        change_request_id: deleteEvent.change_request_id,
        key: deleteEvent?.key?.id || null,
        note: deleteEvent.note || "",
        delete_request: true,
        service: deleteEvent?.service?.id || null,
        time_block: deleteEvent?.time_block?.id || null,
      };
      if (deleteEvent.pets) {
        data["pets"] = deleteEvent.pets.map((pet) => pet.id);
      } else {
        data["pets"] = [];
      }
      if (deleteEvent.service_request_task) {
        const taskObjects = deleteEvent.service_request_task.map((task) => {
          return {
            name: task.name,
          };
        });
        data["tasks"] = taskObjects;
      }
      axiosInstance
        .post(companyAPIsEndPoints.CHANGE_SERVICE_REQUEST, data)
        .then(({ data }) => {
          if (data.message) {
            setDeleteEvent(null);
            pushNotification(data.message, "success");
            handleDataChange();
          }
        })
        .catch((error) => {
          console.log(error);
          pushNotification("Something Went Wrong!", "error");
        });
    } else {
      main_api
        .delete(companyAPIsEndPoints.DELETE_SERVICE_REQUEST(deleteEvent.id))
        .then(({ data }) => {
          setDeleteEvent(null);
          handleDataChange();
          pushNotification("Service Deleted Successfully!", "success");
        })
        .catch((error) => {
          setDeleteEvent(null);
          pushNotification("Something went Wrong!", "error");
        });
    }
  };

  return (
    <div className="mb-3 mt-3">
      <AntdesignTable columns={ClientSchedulerListColumens({ handleDelete, handleEditRow })} data={schedulerState.result || []} />
      {selectedEvent && (
        <ChangeRequestModal
          handleDataChange={handleDataChange}
          selectedEvent={selectedEvent}
          isModalOpen={true}
          setIsModalOpen={() => setSelectedEvent(null)}
        />
      )}
      <DeleteConfirmationRowModal
        onclose={() => setDeleteEvent(null)}
        handleConfirmDeleteRows={deleteService}
        openConfimrationDeleteModal={deleteEvent ? true : false}
      />
      <PermissionModal onclose={() => setPermissionPopUp(false)} openModal={permissionPopUp} />
    </div>
  );
};

export default SchedulerList;
