import React, { useState } from "react";

import * as Yup from "yup";

import CustomBtnWithIcon from "../../../components/buttonWithIcon";

import AntDesignModal from "../../../components/Modals/AntDesignModal/AntDesignModal";

import { Form, Formik } from "formik";

import FormikInputFloating from "../../../components/formikInputs/TextInput";

import HeadingComponent from "../../../components/Heading";

import "./service.css";
import styled from "styled-components";
import { pushNotification } from "../../../util/Notification";
import { main_api } from "../../../api/axios_helper";
import DeleteConfirmationRowModal from "../../../components/Modals/DeleteConfirmationRowModal";
import { useSelector } from "react-redux";
import FormikSelectInput from "../../../components/formikInputs/SelectInput";

const AddNewGroupModal = ({
  open,
  close,
  serviceList,
  handleCreateGroup,
  updateGroup,
  groupValues,
  fetchAllServices,
  fetchServiceGroups,
}) => {
  const [serviceDelete, setServiceDelete] = useState(false);
  let compId = useSelector((state) => state?.auth?.loginResponse?.selectCompany?.[0]?.company_id);

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required("Group name is required")
      .test("no-empty-spaces", "Group Name cannot contain only spaces", (value) => {
        const trimmedValue = value?.replace(/^\s+|\s+$/g, "");
        return trimmedValue !== "";
      })
      .min(2, "Group name must be at least 2 characters") // Name must be at least 2 characters long
      .max(50, "Group name must not exceed 50 characters"), // Name must not exceed 50 characters
    services: Yup.array().min(1, "At least one service must be selected").required("At least one service must be selected"),
  });

  const formikRef = React.useRef();
  // Handle form submission
  const onSubmit = (values) => {
    handleCreateGroup(values);
  };
  const defaultServ = groupValues?.serviceList?.map((items) => {
    return {
      label: items.name,
      value: items.id,
    };
  });
  const filtIds = defaultServ?.map((items) => items.value);

  const handleDeleteGroup = async () => {
    try {
      const response = await main_api.delete(`/api/v1/service_group/${groupValues?.groupId}/`);

      if (response?.status === 200) {
        pushNotification(response?.data.message, "success");

        fetchAllServices();
        fetchServiceGroups();
        setServiceDelete(false);
        close();
        return;
      }
      pushNotification(response?.data.message, "error");
    } catch (error) {
      console.log("error:", error);
    }
  };

  return (
    <>
      <AntDesignModal
        closeModal={close}
        open={open}
        title={updateGroup ? "Edit Service Group" : "Add Service Group"}
        component={
          <>
            <HeadingComponent
              text={`${updateGroup ? "Edit a Service Group" : "Add a new service group"}. Groups allow you to organise your service so they are easier to browser from Client app, client portal and Dashboard.`}
              fontSize="14px"
              fontWeight={400}
            />
            <Formik
              initialValues={{
                name: updateGroup ? groupValues?.groupName : "",
                services: updateGroup ? filtIds : "",
                company: compId,
              }}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
              enableReinitialize
              innerRef={formikRef}
            >
              {({ handleSubmit, setFieldValue, values, errors }) => (
                <Form onSubmit={handleSubmit}>
                  <div>
                    <FormikInputFloating
                      label="Group Name"
                      placeHolder=" Group Name"
                      name="name"
                      type="text"
                      className="w-100 my-2"
                      autoComplete="new-password"
                    />
                  </div>
                  <div>
                    <FormikSelectInput
                      label="Service List"
                      mode="multiple"
                      name="services"
                      placeholder="Please select"
                      {...(updateGroup
                        ? {
                            defaultValue: values?.services,
                          }
                        : {})}
                      style={{
                        width: "100%",
                        minHeight: "40px",
                      }}
                      options={serviceList}
                      className="mb-4"
                      onChange={(e) => {
                        setFieldValue("services", e);
                      }}
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </>
        }
        footer={
          <div className="d-flex justify-content-end">
            <CustomBtnWithIcon btnText={"Cancel"} margin="0px 5px" noBackground hideIcon={true} onClick={close} />
            {updateGroup && (
              <CustomBtnWithIcon
                btnText={"Delete Group"}
                type="submit"
                hideIcon={true}
                noBackground
                onClick={() => {
                  setServiceDelete(true);
                }}
              />
            )}
            <CustomBtnWithIcon
              btnText={"Save Group"}
              type="submit"
              hideIcon={true}
              className="mx-2"
              onClick={() => {
                if (formikRef?.current) {
                  formikRef?.current?.handleSubmit();
                }
              }}
            />
          </div>
        }
      />

      <DeleteConfirmationRowModal
        onclose={() => setServiceDelete(false)}
        handleConfirmDeleteRows={() => handleDeleteGroup()}
        openConfimrationDeleteModal={serviceDelete}
        padding="0px"
        // BtnloadingState={() => console.log("")}
      />
    </>
  );
};

export default AddNewGroupModal;

const InputTextLabel = styled.span`
  font-size: 12px;
  color: #424242;
  font-weight: 500;
  line-height: 18px;
  width: 100%;
  margin-bottom: 20px;
`;
