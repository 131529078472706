import React, { useEffect, useState } from "react";
import { Calendar, Views, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";

import { SchedulerMonthScheduledIcon, SchedulerMonthUserIcon, InvoiceIcon, ClientYellow } from "../../assets/rawSvgs/index";

import OneDayResourcesVerticalCalender from "../CompanyOneDayVerticalScheduler/Index";
import AntDesignModal from "../Modals/AntDesignModal/AntDesignModal";
import HeadingComponent from "../Heading";
import { Col, Row } from "antd";
import { StaffIcon, SchedulerServiceIcon, SchedulerWhenDateicon, SchedulerAvatarClient } from "../../assets/rawSvgs";
import CustomBtnWithIcon from "../buttonWithIcon";
import { useSelector } from "react-redux";
import { AddNewEventModal } from "../../pages/company/sheduler/SchedulerEvents";
import { convertTo12HourFormat } from "../../util/formatTime";
import dayjs from "dayjs";
import { main_api } from "../../api/axios_helper";
import DeleteConfirmationRowModal from "../Modals/DeleteConfirmationRowModal";
import { pushNotification } from "../../util/Notification";
import { checkPermission } from "../../util/commonUtil";
import PermissionModal from "../Modals/PermissionModal";
import { calculateCommuteTimes } from "./horizontalCalender/OneDayScheduleTable";

// let events
// let text = true;

const DayViewWithResources = ({ AllStates }) => {
  const permissionManagement = useSelector((state) => state.permissionManagement);
  const [openDetailSlotModal, setOpenDetailSlotModal] = useState({ details: "", condition: false });
  const [addNewEventSlotModal, setAddNewEventSlotModall] = useState({ details: "", condition: false });
  const [permissionPopUp, setPermissionPopUp] = useState(false);
  const [unavaialbleEvents, setUnavaialbleEvents] = useState([]);

  const filteringLoader = useSelector((state) => state.scheduler.schedulerResponse.isLoading);
  const unavaliableSlots = AllStates?.unavaliableSlots?.responseData?.result;

  function convertDataStructure(data) {
    return data?.map((item) => {
      return {
        ...item,
        title: item.name,
        id: item.id,
      };
    });
  }
  const convertedStaffData = convertDataStructure(AllStates?.allStaffState?.responseData);

  const handleSelectSlot = (slotInfo) => {
    if (!slotInfo.unavailableSlot || !slotInfo.commute) {
      if (checkPermission(permissionManagement.permissions, "assign_service_request_staff")) {
        const dateTime = dayjs(slotInfo.start);
        const formattedDate = dateTime.format("YYYY-MM-DD");

        const formattedTime = slotInfo.start.toLocaleTimeString("en-US", { hour: "numeric", minute: "numeric", hour12: true });

        setAddNewEventSlotModall({
          details: {
            // staffId: slotInfo.resourceId,

            date: formattedDate,
          },
          condition: true,
        });
      } else {
        setPermissionPopUp(true);
      }
    }
  };
  const handleEventClick = (slotInfo) => {
    if (!slotInfo.unavailableSlot || !slotInfo.commute) {
      if (checkPermission(permissionManagement.permissions, "view_assign_service_request_staff")) {
        const dateTime = dayjs(slotInfo.start);
        const formattedDate = dateTime.format("YYYY-MM-DD");

        const formattedTime = slotInfo.start.toLocaleTimeString("en-US", { hour: "numeric", minute: "numeric", hour12: true });
        if (slotInfo.commute) return;
        setOpenDetailSlotModal({
          details: {
            staffId: slotInfo.resourceId,
            slotTime: formattedTime,
            date: formattedDate,
            serviceID: slotInfo.id,
            title: slotInfo.title,
            serviceDetails: slotInfo.service,
            timeblock: slotInfo.time_block,
          },
          condition: true,
        });
      } else {
        setPermissionPopUp(true);
      }
    }
  };

  const handleEditEvent = () => {
    if (checkPermission(permissionManagement.permissions, "edit_assign_service_request_staff")) {
      setAddNewEventSlotModall({
        details: {
          staffId: openDetailSlotModal?.details.staffId,
          slotTime: openDetailSlotModal?.details.slotTime,
          date: openDetailSlotModal?.details.date,
          serviceID: openDetailSlotModal?.details.serviceID,
          title: openDetailSlotModal?.details.title,
          serviceDetails: openDetailSlotModal.details.serviceDetails,
          timeblock: openDetailSlotModal.details.timeblock,
        },
        condition: true,
      });
    } else {
      setPermissionPopUp(true);
    }
  };
  const mapSchedStaffdata = AllStates?.schedulerState?.result?.map((items) => {
    return {
      ...items,
      search: items?.staff?.name,
      start_time: items.start_time,
      end_time: items.end_time,
      service_name: items?.service?.name,
    };
  });
  const defaultSlot = [["00:00:00", "23:59:00"]];
  const newUnavailableSlots = { ...unavaliableSlots };

  if (convertedStaffData?.length > 0) {
    convertedStaffData?.forEach((staff) => {
      if (!newUnavailableSlots.hasOwnProperty(staff?.id)) {
        newUnavailableSlots[staff?.id] = defaultSlot;
      }
    });
  }
  useEffect(() => {
    if (newUnavailableSlots) {
      const date = moment(AllStates.dayChangeState.dayRange?.start).format("YYYY-MM-DD");
      const newEvents = Object.keys(newUnavailableSlots).reduce((acc, staffId) => {
        const staffEvents = newUnavailableSlots[staffId].map((slots) => {
          const [startTime, endTime] = slots;
          return {
            start: dateFormatStartDate(startTime, date),
            end: dateFormatEndDate(endTime, date),
            start_time: startTime,
            end_time: endTime,
            unavailableSlot: true,
            resourceId: Number(staffId),
          };
        });
        return acc.concat(staffEvents);
      }, []);
      setUnavaialbleEvents(newEvents);
    }
  }, [AllStates.dayChangeState.dayRange?.start, unavaliableSlots]);

  const filterAddDates = mapSchedStaffdata?.map((items) => {
    return {
      ...items,
      id: items.id,
      title: items?.service_name,
      start: dateFormatStartDate(items?.start_time, items.date),
      end: dateFormatEndDate(items?.end_time, items.date),
      resourceId: items?.staff.id,
      clientName: items?.client.name,
    };
  });

  let commuteDataCalculate = calculateCommuteTimes(AllStates?.schedulerState?.result || []);
  let mapToEventsStructure = commuteDataCalculate?.map((items) => {
    return {
      ...items,
      id: items.id,
      title: items?.service_name,
      start: dateFormatStartDate(items?.start_time, items.date),
      end: dateFormatEndDate(items?.end_time, items.date),
      resourceId: items?.staff.id,
      clientName: items?.client.name,
    };
  });

  let finaEvnets = [...filterAddDates, ...unavaialbleEvents, ...mapToEventsStructure];

  return (
    <div className="mt-3">
      <OneDayResourcesVerticalCalender
        staffResource={convertedStaffData}
        events={finaEvnets}
        handleEventClick={handleEventClick}
        handleSelectSlot={handleSelectSlot}
        isFiltering={filteringLoader}
        // defaultDate={new Date(2024, 4, 1)}
        startAccessor="start"
        endAccessor="end"
        date={AllStates.dayChangeState?.dayRange.start}
      />

      {openDetailSlotModal.condition && (
        <ShowEventModal
          openDetailSlotModal={openDetailSlotModal.condition}
          handleCancel={() => {
            setOpenDetailSlotModal({ ...openDetailSlotModal, condition: false });
          }}
          eventDetails={openDetailSlotModal.details}
          serviceState={AllStates?.schedulerState?.result}
          handleEditEvent={handleEditEvent}
          fetchServices={() => {
            AllStates.fetchAllSchedulerService();
            setOpenDetailSlotModal({ ...openDetailSlotModal, condition: false });
          }}
        />
      )}
      {addNewEventSlotModal.condition && (
        <AddNewEventModal
          clientPetServiceDropdowns={AllStates}
          addNewEventModal={addNewEventSlotModal.condition}
          updateValues={addNewEventSlotModal.details}
          oneDayVerticalFrom={true}
          updateForm={true}
          closeModal={() => setAddNewEventSlotModall({ ...openDetailSlotModal, condition: false })}
        />
      )}
      <PermissionModal onclose={() => setPermissionPopUp(false)} openModal={permissionPopUp} />
    </div>
  );
};

export default DayViewWithResources;

function dateFormatStartDate(start_time, date) {
  const [year, month, day] = date.split("-").map(Number);

  const [startHours, startMinutes, startSeconds] = start_time.split(":").map(Number);

  const startDate = new Date(year, month - 1, day, startHours, startMinutes, startSeconds);

  return startDate;
}

function dateFormatEndDate(end_time, date) {
  const [year, month, day] = date.split("-").map(Number);

  const [endHours, endMinutes, endSeconds] = end_time.split(":").map(Number);

  const endDate = new Date(year, month - 1, day, endHours, endMinutes, endSeconds);

  return endDate;
}

export const ShowEventModal = ({
  openDetailSlotModal,
  handleCancel,
  eventDetails,
  serviceState,
  fetchServices,
  handleEditEvent,
  btnsHide,
}) => {
  const [confirmDeleteModal, setconfirmDeleteModal] = useState(false);
  const permissionManagement = useSelector((state) => state.permissionManagement);
  const [permissionPopUp, setPermissionPopUp] = useState(false);
  const [loader, setloader] = useState(false);
  const filter =
    serviceState?.filter((items) => {
      return items.id === eventDetails?.serviceID;
    })[0] || [];

  const handleDeleteEvent = async () => {
    try {
      setloader(true);
      const { data } = await main_api.delete(`/api/v1/service_request/${eventDetails.serviceID}/`);

      if (data.isSuccess) {
        pushNotification("Service Request has been deleted successfully!");
        fetchServices();
      }
      setloader(false);
    } catch (error) {
      setloader(false);
    }
  };

  const selectDelete = () => {
    if (checkPermission(permissionManagement.permissions, "delete_service_request")) {
      setconfirmDeleteModal(true);
    } else {
      setPermissionPopUp(true);
    }
  };

  return (
    <>
      <AntDesignModal
        openModal={openDetailSlotModal}
        closeModal={handleCancel}
        onCancel={handleCancel}
        onOk={handleCancel}
        width={400}
        title={
          <HeadingComponent
            text={`${convertTo12HourFormat(filter?.start_time)} - ${convertTo12HourFormat(filter?.end_time)}`}
            fontSize="16px"
            fontWeight={700}
            color="#212121"
          />
        }
        component={
          <Row gutter={10}>
            <Col lg={15} className="d-flex align-items-end my-2">
              <InvoiceIcon className="me-3 ms-1" stroke="#FBCF34" />

              <HeadingComponent text={"Invoice: #1234213"} fontSize="14px" fontWeight={500} color="#424242" />
            </Col>
            <Col lg={15} className="d-flex align-items-end my-2">
              <SchedulerServiceIcon className="me-3" stroke="#FBCF34" />

              <HeadingComponent text={`Service: ${eventDetails?.title}`} fontSize="14px" fontWeight={500} color="#424242" />
            </Col>
            <Col lg={15} className="d-flex align-items-end my-2">
              <SchedulerWhenDateicon className="me-3" stroke="#FBCF34" />

              <HeadingComponent text={`When: ${filter?.date}`} fontSize="14px" fontWeight={500} color="#424242" />
            </Col>{" "}
            <Col lg={15} className="d-flex align-items-end my-2">
              <ClientYellow className="me-3 ms-1" stroke="#FBCF34" />

              <HeadingComponent
                text={`Staff: ${filter?.staff?.name}`}
                className="ms-1"
                fontSize="14px"
                fontWeight={500}
                color="#424242"
              />
            </Col>
            <Col lg={15} className="d-flex align-items-end my-2">
              <SchedulerAvatarClient className="me-3" stroke="#FBCF34" />
              <HeadingComponent text={`Client: ${filter?.client?.name}`} fontSize="14px" fontWeight={500} color="#424242" />
            </Col>
            <DeleteConfirmationRowModal
              onclose={() => setconfirmDeleteModal(false)}
              handleConfirmDeleteRows={handleDeleteEvent}
              openConfimrationDeleteModal={confirmDeleteModal}
              BtnloadingState={loader}
            />
            <PermissionModal onclose={() => setPermissionPopUp(false)} openModal={permissionPopUp} />
          </Row>
        }
        footer={
          <div className="d-flex justify-content-end">
            {!btnsHide && (
              <>
                <CustomBtnWithIcon
                  btnText={"Delete"}
                  hideIcon
                  noBackground
                  className="me-2"
                  width="90px"
                  onClick={() => selectDelete()}
                />
                <CustomBtnWithIcon btnText={"Edit"} hideIcon className="me-2" width="90px" onClick={handleEditEvent} />
              </>
            )}
          </div>
        }
      />
    </>
  );
};
