import styled from "styled-components";

import DeleteIcon from "../../../../../assets/icons/DeleteIconNew";
import { ReactComponent as EditIcon } from "../../../../../assets/rawSvgs/editIconPencil.svg";
import CustomBtnWithIcon from "../../../../../components/buttonWithIcon";
import ClientField from "./clientField";
import { useState } from "react";
import AddFieldModal from "./modals/addFieldModal";
import AddGroupModal from "./modals/addGroupModal";
import DeleteConfirmationRowModal from "../../../../../components/Modals/DeleteConfirmationRowModal";
import { updateCompanyGroups } from "../../../../../redux/platformSlices/CompanyDetail";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

const ClientFieldsCard = ({ group, selectedTab, groupIndex }) => {
  const dispatch = useDispatch();

  const [isEditGroupModalShown, setIsEditGroupModalShown] = useState(false);
  const [isEditOrAddFieldModalShown, setIsEditOrAddFieldModalShown] = useState(false);
  const [isDeleteGroupOpen, setIsDeleteGroupOpen] = useState(false);

  const auth = useSelector((state) => state.auth);
  const companyId = auth.loginResponse?.selectCompany?.[0]?.company_id || null;

  const details = useSelector((state) => state.companyDetail.companyDetailResponse);

  const clientGroups = details?.result?.client_custom_fields?.groups || [];
  const petGroups = details?.result?.pet_custom_fields?.groups || [];

  const groups = selectedTab === "ClientField" ? clientGroups : petGroups;

  const deleteGroup = async () => {
    const updatedGroups = [...groups];
    updatedGroups.splice(groupIndex, 1);
    const customFields = {
      groups: [...updatedGroups],
    };
    const data = selectedTab === "ClientField" ? { client_custom_fields: customFields } : { pet_custom_fields: customFields };
    await dispatch(updateCompanyGroups(companyId, { ...data }));
    setIsDeleteGroupOpen(false);
  };

  return (
    <>
      {group.name === null ? (
        <Wrapper key={group.name}>
          {group.fields.map((field, index) => (
            <ClientField
              group={group}
              key={field.id}
              fieldIndex={index}
              selectedTab={selectedTab}
              field={field}
              groupIndex={groupIndex}
            />
          ))}
          <ButtonWrapper>
            <CustomBtnWithIcon
              btnText="Add new Field"
              onClick={() => {
                setIsEditOrAddFieldModalShown(true);
              }}
            />
          </ButtonWrapper>
        </Wrapper>
      ) : (
        <Article>
          <Header>
            <div>
              <Title>{group.name}</Title>
              <button onClick={() => setIsEditGroupModalShown(true)}>
                <EditIcon width={"18px"} height="18px" />
              </button>
            </div>
            <DeleteButton onClick={() => setIsDeleteGroupOpen(true)}>
              <DeleteIcon width={16} height={16} />
            </DeleteButton>
          </Header>
          <Main>
            {group.fields.map((field, index) => (
              <ClientField
                group={group}
                selectedTab={selectedTab}
                field={field}
                key={field.id}
                fieldIndex={index}
                groupIndex={groupIndex}
              />
            ))}
            <ButtonWrapper>
              {group.fields.length > 0 ? (
                <CustomBtnWithIcon
                  btnText="Add more Fields"
                  style={{ border: "none", background: "none" }}
                  noBackground
                  onClick={() => {
                    setIsEditOrAddFieldModalShown(true);
                  }}
                />
              ) : (
                <CustomBtnWithIcon
                  btnText="Add new Field"
                  onClick={() => {
                    setIsEditOrAddFieldModalShown(true);
                  }}
                />
              )}
            </ButtonWrapper>
          </Main>
        </Article>
      )}
      <AddFieldModal
        selectedTab={selectedTab}
        isModalOpen={isEditOrAddFieldModalShown}
        setModalOpen={setIsEditOrAddFieldModalShown}
        group={group}
      />
      <AddGroupModal
        isModalOpen={isEditGroupModalShown}
        setModalOpen={setIsEditGroupModalShown}
        groupIndex={groupIndex}
        group={group}
        selectedTab={selectedTab}
        isEditMode
      />
      <DeleteConfirmationRowModal
        onclose={() => setIsDeleteGroupOpen(false)}
        openConfimrationDeleteModal={isDeleteGroupOpen}
        handleConfirmDeleteRows={deleteGroup}
      />
    </>
  );
};

export default ClientFieldsCard;

const Wrapper = styled.div`
  margin-bottom: 30px;
`;

const ButtonWrapper = styled.div`
  margin: 20px 25px;
`;

const Title = styled.h1`
  font-family: "Helvetica Neue", Arial;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  color: #000000;
  display: inline-block;
  padding: 10px 20px;
`;

const Article = styled.article`
  border: 1px solid #eeeeee;
  border-radius: 8px;
  margin: 20px 5px;
`;

const Header = styled.header`
  background-color: #f9f9f9;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const DeleteButton = styled.button`
  padding: 12px 16px;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  opacity: 0px;
`;

const Main = styled.main``;
