import React, { useEffect, useState } from "react";

import styled from "styled-components";

import { main_api } from "../../../api/axios_helper";

import CustomBtnWithIcon from "../../../components/buttonWithIcon";
import { AntdesignTable } from "../../../components/antDesignTable/AntdesignTable";
import { staffMangementHeaders } from "../../../constants/CompanyTableHeaders/StaffManagementHeaders";

import { pushNotification } from "../../../util/Notification";
import NoDataScreen from "../../../components/NoDataScreen";
import AddNewStaffForm from "./addNewStaffModal";
import SearchInput from "../../../components/SearchInput";
import { useDebounce } from "../../../hooks/useDebounce";
import AntDesignDropDownSelect from "../../../components/AntDesignDropDownSelect/AntDesignDropDownSelect";
import { companyAPIsEndPoints, staffAPIsEndPoints } from "../../../constants/ApiEndPoints";
import DeleteConfirmationRowModal from "../../../components/Modals/DeleteConfirmationRowModal";
import { checkPermission } from "../../../util/commonUtil";
import { useSelector } from "react-redux";
import PermissionModal from "../../../components/Modals/PermissionModal";
import { ConfigureStaffAvailComponent } from "./staffdetail/availabilityComponent/AvailabilityComponent";

//
const StaffManement = ({ fetchAllStaff, allStaffState, loginState, fetchSingleStaff, StaffState }) => {
  const [selectStaffType, setSelectStaffType] = useState("employee");
  const [addNewClientModal, setAddNewModal] = useState(false);
  const [openConfimrationDeleteModal, setOpenConfimrationDeleteModal] = useState(false);
  const [openEditStaffModal, setopenEditStaffModal] = useState(false);
  const [isFilterSelected, setIsFilterSelected] = useState(false);
  const [openEditClientValues, setOpenEditClientValues] = useState({});
  const [deleteRowId, setDeleteRowId] = useState("");
  const [filteredResponseData, setFilteredResponseData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [staffAddloader, setStaffAddloader] = useState(false);
  const debouncedSearchTerm = useDebounce(searchTerm, 300);
  const [permissionPopUp, setPermissionPopUp] = useState(false);
  const permissionManagement = useSelector((state) => state.permissionManagement);
  const [configureModal, setConfigureModal] = useState(false);
  const [singleStaffId, setSingleStaffId] = useState("");

  useEffect(() => {
    fetchAllStaff(debouncedSearchTerm);
  }, [debouncedSearchTerm]);

  useEffect(() => {
    const filteredStaff = allStaffState?.responseData?.filter((staff) =>
      selectStaffType === "all" ? true : staff.type === selectStaffType,
    );
    setFilteredResponseData(filteredStaff);
  }, [allStaffState, selectStaffType]);

  const handleDelete = (row) => {
    if (checkPermission(permissionManagement.permissions, "delete_staff")) {
      setDeleteRowId(row?.id);
      setOpenConfimrationDeleteModal(true);
    } else {
      setPermissionPopUp(true);
    }
  };
  const handleEditRow = (rows) => {
    if (checkPermission(permissionManagement.permissions, "edit_staff")) {
      if (rows) {
        setOpenEditClientValues(rows);
      }
      setopenEditStaffModal(true);
    } else {
      setPermissionPopUp(true);
    }
  };

  const addStaff = () => {
    if (checkPermission(permissionManagement.permissions, "create_staff")) {
      setAddNewModal(true);
    } else {
      setPermissionPopUp(true);
    }
  };

  const handleConfirmDelete = async () => {
    try {
      setStaffAddloader(true);
      const { data } = await main_api.delete(staffAPIsEndPoints.DELETE_EXISTING_STAFF(deleteRowId));

      if (data?.message) {
        pushNotification(data.message, "success");
        fetchAllStaff(debouncedSearchTerm);
        setOpenConfimrationDeleteModal(false);
      }
      setStaffAddloader(false);
    } catch (error) {
      setStaffAddloader(false);

      console.log("Error :", error);
    }
  };
  let options2 = [
    { value: "employee", label: "Employee" },
    { value: "contractor", label: "Contractor" },
  ];

  const handleSendForgetPasswordReq = async (values) => {
    try {
      const { data } = await main_api.put(companyAPIsEndPoints.FORGOT_PASSWORD, values);
      if (data.success) {
        pushNotification("Password Recovery Email Sent", "success");
      }
    } catch (error) {
      if (error) {
        pushNotification(error?.error, "error");
      }
    }
  };

  const configUpdateFormDetails = StaffState?.staffAvailConfig?.responseData?.availabilities || {};

  let singleLoader = StaffState?.staffSingleDetails.isLoading;
  return (
    <div>
      <TableHeader>
        <NavigationHeading>Staff Management</NavigationHeading>
      </TableHeader>

      {isFilterSelected && (
        <div className="d-flex justify-content-between align-items-baseline">
          <div className="d-flex  w-50 align-items-center">
            <SearchInput
              onChange={(value) => setSearchTerm(value)}
              placeholder="Type to search..."
              containerBorder={"0"}
              style={{ marginRight: "10px" }}
            />

            <AntDesignDropDownSelect
              options={options2}
              width="200px"
              defaultValue={selectStaffType}
              placeholder="Type"
              onChange={(e) => {
                setIsFilterSelected(true);
                setSelectStaffType(e);
              }}
            />
          </div>
          <div className="d-flex ">
            <CustomBtnWithIcon btnText={"Add new Staff"} margin="10px" onClick={() => addStaff()} />
          </div>
        </div>
      )}

      {filteredResponseData?.length > 0 && checkPermission(permissionManagement.permissions, "view_staffs") ? (
        <>
          <div className="d-flex justify-content-between align-items-baseline">
            {!isFilterSelected && (
              <>
                <div className="d-flex  w-50 align-items-center">
                  <SearchInput
                    onChange={(value) => setSearchTerm(value)}
                    placeholder="Type to search..."
                    containerBorder={"0"}
                    style={{ marginRight: "10px" }}
                  />

                  <AntDesignDropDownSelect
                    options={options2}
                    width="200px"
                    defaultValue={selectStaffType}
                    placeholder="Type"
                    onChange={(e) => {
                      setIsFilterSelected(true);
                      setSelectStaffType(e);
                    }}
                  />
                </div>
                <div className="d-flex ">
                  <CustomBtnWithIcon btnText={"Add new Staff"} margin="10px" onClick={() => addStaff()} />
                </div>
              </>
            )}
          </div>

          <AntdesignTable
            className="antd-table-custom"
            columns={staffMangementHeaders({
              handleDelete,
              handleEditRow,
              handleSendForgetPasswordReq,
              fetchSingleStaff: (staffId) => {
                setSingleStaffId(staffId);
                fetchSingleStaff(staffId);
                setConfigureModal(true);
              },
            })}
            data={filteredResponseData || []}
            loading={allStaffState.isLoading}
          />
        </>
      ) : (
        <NoDataScreen
          heading="You don’t have any Staffs in your list. Let’s get started by adding a new Staff"
          btnText={"Add new Staff"}
          onClick={() => addStaff()}
        />
      )}
      {addNewClientModal && (
        <AddNewStaffForm
          visible={addNewClientModal}
          handleClose={() => setAddNewModal(false)}
          companyId={loginState?.selectCompany?.[0]?.company_id}
          fetchAllStaff={fetchAllStaff}
        />
      )}
      {openEditStaffModal && (
        <AddNewStaffForm
          visible={openEditStaffModal}
          handleClose={() => setopenEditStaffModal(false)}
          companyId={loginState?.selectCompany?.[0]?.company_id}
          fetchAllStaff={fetchAllStaff}
          editStaffValues={openEditClientValues}
          updateForm={true}
        />
      )}
      <DeleteConfirmationRowModal
        onclose={() => setOpenConfimrationDeleteModal(false)}
        handleConfirmDeleteRows={handleConfirmDelete}
        openConfimrationDeleteModal={openConfimrationDeleteModal}
        BtnloadingState={staffAddloader}
      />
      {!singleLoader && (
        <ConfigureStaffAvailComponent
          open={configureModal}
          close={() => {
            setConfigureModal(false);
          }}
          fetchSingleStaff={fetchSingleStaff}
          updateValues={configUpdateFormDetails}
          fromStaffManagement={true}
          fromStaffManagementStaffId={singleStaffId}
        />
      )}

      <PermissionModal onclose={() => setPermissionPopUp(false)} openModal={permissionPopUp} />
    </div>
  );
};

export default StaffManement;

const TableHeader = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`;

const NavigationHeading = styled.h2`
  display: flex;
  margin: 25px 0px 10px 0px;
  font-size: 20px;
  font-weight: 700;
`;
