import { createSlice } from "@reduxjs/toolkit";
import { main_api } from "../../api/axios_helper";
import { companyAPIsEndPoints } from "../../constants/ApiEndPoints";

const initialState = {
    unreadCount: 0
};

const notificationManagement = createSlice({
  name: "notificationManagement",
  initialState,
  reducers: {
    setNotification(state, action) {
      if (action.payload) {
        state.unreadCount = action.payload.unreadCount;
      }
    },
  },
});

// Reducer
export default notificationManagement.reducer;
export const { setNotification } = notificationManagement.actions;


export const fetchUnreadNotificationCount = (queryParams='') => {
    return async (dispatch, getState) => {
      try {
        dispatch(notificationManagement.actions.setNotification({ unreadCount: 0 }));
        const { data } = await main_api.get(companyAPIsEndPoints.UNREAD_NOTIFICATION(queryParams))
        dispatch(notificationManagement.actions.setNotification({ unreadCount: data.un_read_count }));
      } catch (error) {
        dispatch(notificationManagement.actions.setNotification({ unreadCount: 0 }));
      }
    };
};