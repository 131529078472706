import { Modal } from "antd";
import CustomBtnWithIcon from "../buttonWithIcon";
import HeadingComponent from "../Heading";
import { DeleteConfirmationDelIcon } from "../../assets/ColorChangeSvgs/DeleteClientConfirmation";

const DeleteConfirmationRowModal = ({
  onclose,
  handleConfirmDeleteRows,
  text = "By deleting this data, you’ll no longer be able to access it",
  deleteBtnText = "Yes, delete it",
  openConfimrationDeleteModal,
  clientDeleteResponse,
  BtnloadingState,
  padding = "20px",
}) => {
  return (
    <Modal open={openConfimrationDeleteModal} onCancel={onclose} footer={null} centered>
      <div
        style={{
          padding: padding,
          height: "300px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <DeleteConfirmationDelIcon width="220px" height="220px" />

        <HeadingComponent text={text} fontSize="0.875rem" fontWeight={400} margin="25px auto 15px auto" />
        <div className="d-flex justify-content-center">
          <CustomBtnWithIcon btnText={"No"} padding="10px 30px" hideIcon noBackground margin="10px" onClick={onclose} />
          <CustomBtnWithIcon
            btnText={deleteBtnText}
            margin="10px"
            hideIcon
            disabled={clientDeleteResponse?.isLoading || BtnloadingState}
            onClick={handleConfirmDeleteRows}
          />
        </div>
      </div>
    </Modal>
  );
};

export default DeleteConfirmationRowModal;
