import CompanyDetailPage from "../../pages/company/services/index";
import { connect } from "react-redux";
import { editPet } from "../../redux/slices/pets";
import { fetchServiceApi, fetchHolidaysApi, fetchServiceGroupApi } from "../../redux/slices/serviceSlice";

const mapStateToProps = (state) => {
  return {
    serviceState: state.services,
    serviceStateLoader: state.services.serviceGetResponse.isLoading,
    serviceStateResponseData: state.services.serviceGetResponse.responseData.result,
    loginState: state.auth.loginResponse,
    holidayStateResponseData: state.services.holidayGetResponse.responseData.result,
    holidayStateLoader: state.services.holidayGetResponse.responseData.result,
    serviceGroups: state.services.serviceGroupGetResponse.responseData,
    permissionState: state.permissionManagement.permissions
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchAllServices: (searchTerm = "") => {
    dispatch(fetchServiceApi(searchTerm));
  },
  fetchAllHolidays: (searchTerm) => {
    dispatch(fetchHolidaysApi(searchTerm));
  },
  fetchServiceGrouping: () => {
    dispatch(fetchServiceGroupApi());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CompanyDetailPage);
