import React, { useEffect, useRef, useState } from "react";
import HeadingComponent from "../../../../components/Heading";
import { UserNameWithIconWrapper } from "../../clients/clientdetail/ClientDetailsStyles";
import { ReactComponent as LessThanIcon } from "../../../../assets/rawSvgs/lessThanBlack.svg";
import {
  StaffIcon,
  SchedulerServiceIcon,
  SchedulerWhenDateicon,
  SchedulerAvatarClient,
  TemplateFoldPageIcon,
  PetsPawIcon,
  TemplateTimeIcon,
  SchedulerMonthUserIcon,
} from "../../../../assets/rawSvgs";
import { ReactComponent as EditIcon } from "../../../../assets/rawSvgs/editIconPencil.svg";

import { ReactComponent as HistoryIcon } from "../../../../assets/rawSvgs/editIconPencil.svg";
import { Col, Divider, Row, Table } from "antd";
import "./detailsProject.css";
import styled from "styled-components";
import { convertTo12HourFormat } from "../../../../util/formatTime";
import truncateString from "../../../../util/truncate";
import CustomBtnWithIcon from "../../../../components/buttonWithIcon";
import AddServiceForm from "./AddServiceForm";
import AntDesignModal from "../../../../components/Modals/AntDesignModal/AntDesignModal";
import { main_api } from "../../../../api/axios_helper";
import { companyAPIsEndPoints } from "../../../../constants/ApiEndPoints";
import { pushNotification } from "../../../../util/Notification";
import AddNewTemplateForm from "./addNewTemplateForm";
import { useSelector } from "react-redux";
import PermissionModal from "../../../../components/Modals/PermissionModal";
import { checkPermission } from "../../../../util/commonUtil";
//
const DetailsItem = ({ settemplateStep, details, requiredDropdowns }) => {
  const [clientPets, setClientPets] = useState([]);

  const [loader, setLoader] = useState(false);
  const [addNewTempModal, setAddNewTempModal] = useState(false);
  const [editExistingTempModal, seteditExistingTempModal] = useState(false);
  const [permissionPopUp, setPermissionPopUp] = useState(false);
  const permissionManagement = useSelector((state) => state.permissionManagement);
  const submitFormREf = useRef();

  useEffect(() => {
    if (details) {
      const filterPets =
        requiredDropdowns?.clientAllState?.responseData.result?.filter((items) => items.id === details?.client?.id)[0] || [];
      setClientPets(
        filterPets?.clientpet?.map((items) => {
          return {
            label: items.name,
            value: items.id,
          };
        }),
      );
    }
  }, [details]);

  const handleSubmitTempForm = async (values) => {
    try {
      setLoader(true);
      let objSend = {
        name: details.name,
        billing_frequency: details.billing_frequency,
        first_occurence_start: details.first_occurence_start,
        first_occurence_end: details.first_occurence_end,
        approval_date: details.approval_date,
        invoice_due_date: details.invoice_due_date,
        months_of_projections: details.months_of_projections,
        projection_status: details.projection_status,
        invoice_date: details.invoice_date,
        description: details.description,
        client: details.client,
        how_often: details.how_often,
        approval_method: details.approval_method,
        services: [...replacePetsAndServiceWithIds(details.template_services), ...values.services],
        company: requiredDropdowns?.loginState?.company_id,
        client: details.client?.id,
      };

      const { data } = await main_api.put(companyAPIsEndPoints.UPDATE_EXISTING_TEMPLATE(details.id), objSend);

      if (data.isSuccess) {
        requiredDropdowns?.fetchSchedulerTemplates();
        pushNotification("Service added successfully!.");
        setAddNewTempModal(false);
      }
      setLoader(false);
    } catch (error) {
      setLoader(false);

      console.log("error", error);
    }
  };

  function replacePetsAndServiceWithIds(obj) {
    return obj?.map((obj) => {
      const newObj = { ...obj };

      if (newObj.staff) {
        newObj.staff = newObj.staff.id;
      }

      if (newObj.pets) {
        newObj.pets = newObj.pets.map((pet) => pet.id);
      }

      if (newObj.service) {
        newObj.service = newObj.service.id;
      }

      return newObj;
    });
  }

  return (
    <div className="mt-2">
      <UserNameWithIconWrapper>
        <button
          onClick={() => {
            settemplateStep(0);
          }}
        >
          <div className="d-flex align-items-center">
            <LessThanIcon width="13px" height="13px" />
            <HeadingComponent text={details?.name} fontSize="1rem" fontWeight={700} margin="3px 10px" color="#212121" />
          </div>
        </button>
      </UserNameWithIconWrapper>

      <WeekTable
        tempData={details}
        addNewModal={() => {
          if (checkPermission(permissionManagement.permissions, "create_template")) {
            setAddNewTempModal(true);
          } else {
            setPermissionPopUp(true);
          }
        }}
        editTempmodal={() => {
          if (checkPermission(permissionManagement.permissions, "edit_template")) {
            seteditExistingTempModal(true);
          } else {
            setPermissionPopUp(true);
          }
        }}
      />

      {addNewTempModal && (
        <AntDesignModal
          openModal={addNewTempModal}
          closeModal={() => {
            setAddNewTempModal(false);
          }}
          onCancel={() => {
            setAddNewTempModal(false);
          }}
          style={{ top: 10 }}
          width={800}
          title={<HeadingComponent text={"Add Service to Template"} fontSize="16px" fontWeight={700} color="#212121" />}
          component={
            <div
              style={{
                height: "75vh",
                overflowY: "auto",
                overflowX: "hidden",
                borderBottom: "0.5px solid rgb(144 144 144 / 43%)",
              }}
            >
              <AddServiceForm
                addNewServiceInTemp={handleSubmitTempForm}
                tempFormDetails={details}
                fromTemplate={true}
                fromTemplateRef={submitFormREf}
                requiredDropdowns={requiredDropdowns}
                setLoader={setLoader}
                clientPets={clientPets}
              />
            </div>
          }
          footer={
            <div className="d-flex justify-content-end mt-4">
              <CustomBtnWithIcon
                btnText={"Cancel"}
                hideIcon
                noBackground
                className="me-2"
                width="90px"
                onClick={() => {
                  setAddNewTempModal(false);
                }}
              />

              <CustomBtnWithIcon
                btnText={"Save"}
                hideIcon
                className="me-2"
                width="90px"
                type="submit"
                disabled={loader}
                onClick={() => {
                  if (submitFormREf?.current) {
                    submitFormREf?.current?.handleSubmit();
                  }
                }}
              />
            </div>
          }
        />
      )}

      {editExistingTempModal && (
        <AddNewTemplateForm
          close={() => {
            seteditExistingTempModal(false);
          }}
          open={editExistingTempModal}
          requiredDropdowns={requiredDropdowns}
          updateForm={true}
          updateDetails={details}
        />
      )}
      <PermissionModal onclose={() => setPermissionPopUp(false)} openModal={permissionPopUp} />
    </div>
  );
};

export default DetailsItem;

// ======================================= TABLE =============================================
const getFullDay = (abbrDay) => {
  switch (abbrDay) {
    case "MON":
      return "Monday";
    case "TUE":
      return "Tuesday";
    case "WED":
      return "Wednesday";
    case "THU":
      return "Thursday";
    case "FRI":
      return "Friday";
    case "SAT":
      return "Saturday";
    case "SUN":
      return "Sunday";
    default:
      return "";
  }
};

const WeekTable = ({ tempData, addNewModal, editTempmodal }) => {
  return (
    <>
      <div className="d-flex justify-content-end ">
        <CustomBtnWithIcon
          btnText={
            <span>
              <EditIcon className="me-1" /> Edit
            </span>
          }
          margin="10px"
          hideIcon
          noBackground
          onClick={editTempmodal}
        />
        <CustomBtnWithIcon btnText={"Add New Service"} noIcon margin="10px" onClick={addNewModal} />
      </div>

      <EventMonthComponent data={tempData?.template_services} complData={tempData} />
    </>
  );
};

const EventMonthComponent = ({ data, complData }) => {
  const daysOrder = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

  const [databyWeek, setDataByWeek] = useState({});

  useEffect(() => {
    const newDataByWeek = {};

    data?.forEach((schedule) => {
      schedule?.weeks_of_month?.forEach((week) => {
        // Ensure the week key exists in the newDataByWeek object
        if (!newDataByWeek[week]) {
          newDataByWeek[week] = {};
          daysOrder.forEach((day) => {
            newDataByWeek[week][day] = [];
          });
        }

        // Push the schedule into the corresponding day array for the week
        schedule?.days_of_week?.forEach((abbrDay) => {
          const fullDay = getFullDay(abbrDay);
          if (fullDay !== "") {
            newDataByWeek[week][fullDay].push(schedule);
          }
        });
      });
    });

    setDataByWeek(newDataByWeek);
  }, [data]);

  const weekKeys = Object.keys(databyWeek);

  const sortedWeekArray = weekKeys.sort((a, b) => {
    const weekNumberA = parseInt(a.match(/\d+/)[0]);
    const weekNumberB = parseInt(b.match(/\d+/)[0]);

    return weekNumberA - weekNumberB;
  });

  const howOften = complData?.how_often;
  return (
    <table className="week-table">
      <thead>
        <tr>
          <th> {howOften === "Weekly" ? "Week" : <>Weeks</>}</th>
          {daysOrder?.map((day) => (
            <TableHeaders key={day}>{day}</TableHeaders>
          ))}
        </tr>
      </thead>
      <tbody>
        {sortedWeekArray?.map((week, index) => (
          <tr key={index}>
            <td>{howOften === "Weekly" ? "Weekly" : <>{week} Week</>}</td>

            {daysOrder.map((day) => (
              <td key={`${week}-${day}`}>
                {day && (
                  <div style={{ height: "150px", overflowY: "auto" }}>
                    {databyWeek[week][day].map((items, subIndex) => {
                      return (
                        <div key={subIndex}>
                          <EventDayModule eventData={items} clientName={complData?.client?.name} />
                          {subIndex < databyWeek[week][day].length - 1 && <Divider />}
                        </div>
                      );
                    })}
                  </div>
                )}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const EventDayModule = ({ eventData, clientName }) => {
  const renderPetNames = (pets) => {
    if (!Array.isArray(pets)) return null;

    if (pets?.length <= 2) {
      return pets?.map((pet, index) => <span key={index}>{pet.name}</span>);
    } else {
      return (
        <>
          {pets?.slice(0, 2).map((pet, index) => (
            <span key={index}>{pet.name}</span>
          ))}
          <span className="">{"  "}2 more...</span>
        </>
      );
    }
  };

  return (
    <EventsContainerDiv>
      <Row gutter={10}>
        <Col lg={24} className="d-flex my-1 align-items-center">
          <SchedulerMonthUserIcon className="me-2" stroke="#B45309" width={"14px"} height="14px" />
          <HeadingComponent text={clientName} fontSize="12px" fontWeight={500} color="#424242" />
        </Col>
        <Col lg={24} className="d-flex my-1 align-items-center">
          <TemplateTimeIcon className="me-2 " stroke="#B45309" width={"14px"} height="11px" />
          <HeadingComponent
            text={convertTo12HourFormat(eventData.start_time) + " - " + convertTo12HourFormat(eventData.end_time)}
            fontSize="10px"
            fontWeight={500}
            color="#424242"
          />
        </Col>
        <Col lg={24} className="d-flex my-1 align-items-center">
          <SchedulerServiceIcon className="me-2" stroke="#B45309" width={"13px"} height="14px" />

          <HeadingComponent
            text={truncateString(eventData?.service?.name, 15)}
            fontSize="10px"
            fontWeight={500}
            color="#424242"
          />
        </Col>
        <Col lg={24} className="d-flex my-1 align-items-center">
          {/* staff */}
          <SchedulerWhenDateicon className="me-2" stroke="#B45309" width={"13px"} height="14px" />

          <HeadingComponent
            text={`${truncateString(eventData?.staff?.name, 15)}`}
            fontSize="10px"
            fontWeight={500}
            color="#424242"
          />
        </Col>{" "}
        {/* PETS */}
        <Col lg={24} className="d-flex my-1 align-items-center">
          <PetsPawIcon className="me-2" stroke="#B45309" width={"13px"} height="14px" />
          <HeadingComponent
            text={eventData?.pets?.length === 0 ? "---" : renderPetNames(eventData?.pets || [])}
            fontSize="10px"
            fontWeight={500}
            color="#424242"
          />
        </Col>
        <Col lg={24} className="d-flex my-1 align-items-center">
          <TemplateFoldPageIcon className="me-2" stroke="#B45309" width={"12px"} height="12px" />
          <HeadingComponent
            text={`${truncateString(eventData.note || "---", 15)}`}
            fontSize="10px"
            fontWeight={500}
            color="#424242"
          />
        </Col>
      </Row>
    </EventsContainerDiv>
  );
};

const EventsContainerDiv = styled.div`
  background: #fff6e8;
  padding: 5px;
`;

const TableHeaders = styled.th`
  height: 40px;
`;
const WeekColumnName = styled.td`
  color: #616161 !important;
  font-weight: 400 !important;
`;
