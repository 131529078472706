import React, { useEffect, useState } from "react";
import { AntdesignTable } from "../../components/antDesignTable/AntdesignTable";
import { NavigationHeading, TableHeader } from "../company/clients/clientStyles";
import SearchInput from "../../components/SearchInput";
import { Select } from "antd";
import CustomBtnWithIcon from "../../components/buttonWithIcon";
import { UserListColumns } from "../../constants/CompanyTableHeader";
import PlatformDetailCard from "./PlatformDetailCard";
import { ReactComponent as TotalActiveCompaniesIcon } from "../../assets/rawSvgs/totalActiveCompanies.svg";
import { ReactComponent as TotalInActiveCompaniesIcon } from "../../assets/rawSvgs/totalInActiveCompanies.svg";
import { ReactComponent as TotalUsers } from "../../assets/rawSvgs/totalUsers.svg";
import styled from "styled-components";
import UpdateUserModal from "../../components/Modals/CompanyManagementModal/UpdateUserModal";
import { pushNotification } from "../../util/Notification";
import { main_api } from "../../api/axios_helper";
import { platformAPIsEndPoints } from "../../constants/ApiEndPoints";
import DeleteConfirmationRowModal from "../../components/Modals/DeleteConfirmationRowModal";
import { useDebounce } from "../../hooks/useDebounce"; 

const Dashboard = (props) => {
  const [search, setSearch] = useState("");
  const [role, setRole] = useState("");
  const [company, setCompany] = useState("");
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [editUserValues, setEditUserValues] = useState("");
  const [deleteUserValues, setDeleteUserValues] = useState("");
  const updatedRoles = props.roles.filter((role) => role.type !== "platform_admin").map((role) => ({ label: role.name, value: role.type }));
  const updatedCompany = props.companyList.map((company) => ({ label: company.name, value: company.id }));
  const debouncedSearch = useDebounce(search, 300);
  useEffect(() => {
    props.loadUsersList();
    props.loadUserRoles();
    props.loadCompanyList();
    props.loadDashboardData();
  }, []);

 
  useEffect(() => {
    props.searchUserList(debouncedSearch, role, company);
  }, [debouncedSearch, role, company]);


  const handleEditRow = (record) => {
    if (record) {
      const updatedRecord = {
        id: record.id,
        name: record.name,
        email: record.email,
        phone_number: record.phone_number,
        is_flagged: record.client_flag ?? record.staff_flag,
        company: record.company_id,
        role: record.role_id,
      };
      setEditUserValues(updatedRecord);
    }
    setIsEditModalOpen(true);
  };

  const handleDelete = (record) => {
    if (record) {
      setDeleteUserValues(record.id);
    }
    setIsDeleteModalOpen(true);
  };

  const addUser = (formValues) => {
    main_api
      .post(platformAPIsEndPoints.POST_USER, formValues)
      .then(({ data }) => {
        if (data?.isSuccess) {
          pushNotification(data?.message, "success");
          setIsAddModalOpen(false);
          props.loadUsersList();
          props.loadDashboardData();
        }
      })
      .catch((error) => {
        pushNotification("Something went wrong!", "error");
        // console.log(error);
      });
  };

  const editUser = (formValues) => {
    formValues = { ...formValues, is_flagged: false };
    main_api
      .put(platformAPIsEndPoints.PUT_USER(editUserValues.id), formValues)
      .then(({ data }) => {
        if (data?.isSuccess) {
          pushNotification(data?.message, "success");
          setIsEditModalOpen(false);
          props.loadUsersList();
        }
      })
      .catch((error) => {
        pushNotification("Something went wrong!", "error");
        // console.log(error);
      });
  };

  const deleteCompany = () => {
    main_api
      .delete(platformAPIsEndPoints.DELETE_USER(deleteUserValues))
      .then(({ data }) => {
        pushNotification("Deleted Successfully!", "success");
        setIsDeleteModalOpen(false);
        props.loadUsersList();
        props.loadDashboardData();
      })
      .catch((error) => {
        pushNotification("Something went wrong!", "error");
        console.log(error);
      });
  };

  const onRoleFilterChange = (role) => {
    setRole(role);
    props.searchUserList(search, role, company);
  };

  const onCompanyFilterChange = (company) => {
    setCompany(company);
    props.searchUserList(search, role, company);
  };

  const searchBlurHandler = () => {
    props.searchUserList(search, role, company);
  };

  return (
    <>
      <UpdateUserModal
        isModalOpen={isAddModalOpen}
        title="Add a User"
        setModalOpen={setIsAddModalOpen}
        onFinish={addUser}
        companyList={updatedCompany}
        roles={updatedRoles}
      />
      <UpdateUserModal
        isModalOpen={isEditModalOpen}
        title="Edit User"
        setModalOpen={setIsEditModalOpen}
        onFinish={editUser}
        companyList={updatedCompany}
        roles={updatedRoles}
        editUserValues={editUserValues}
      />
      <DeleteConfirmationRowModal
        onclose={() => setIsDeleteModalOpen(false)}
        handleConfirmDeleteRows={deleteCompany}
        openConfimrationDeleteModal={isDeleteModalOpen}
      />
      <NavigationHeading>Dashboard</NavigationHeading>
      <DetailCardWraper>
        <PlatformDetailCard
          iconComponent={<TotalActiveCompaniesIcon />}
          cardText="Total Active Companies"
          cardValue={props.dashboardData?.total_active_companies}
          backgroundColor="#EEF8EE"
        />
        <PlatformDetailCard
          iconComponent={<TotalInActiveCompaniesIcon />}
          cardText="Total Inactive Companies"
          cardValue={props.dashboardData?.total_inactive_companies}
          backgroundColor="#E5F6FF"
        />
        <PlatformDetailCard
          iconComponent={<TotalUsers />}
          cardText="Total Users"
          cardValue={props.dashboardData?.total_users}
          backgroundColor="#FFF6E8"
        />
      </DetailCardWraper>
      <NavigationHeading>Users List</NavigationHeading>
      <TableHeader>
        <div className="d-flex align-items-center col">
          <SearchInput
            search={search}
            onChange={(value) => setSearch(value)}
            onBlur={searchBlurHandler}
            placeholder="Type to search.."
          />
           <Select
            onChange={(e) => {
              setRole(e);
              onRoleFilterChange(e);
            }}
            placeholder="Roles"
            className="ms-2"
            style={{ height: "40px", minWidth: "150px" }}
            allowClear
            onClear={() => {
              setRole("");
              onRoleFilterChange("");
            }}
            options={updatedRoles}
          />
          <Select
            onChange={(e) => {
              setCompany(e);
              onCompanyFilterChange(e);
            }}
            placeholder="Company"
            className="ms-2"
            style={{ height: "40px", minWidth: "150px" }}
            allowClear
            onClear={() => {
              setCompany("");
              onCompanyFilterChange("");
            }}
            options={updatedCompany}
          /> 

          
          <CustomBtnWithIcon
            btnText={"Add a User"}
            className="ms-md-auto"
            margin="10px 10px 10px auto"
            onClick={() => setIsAddModalOpen(true)}
          />
        </div>
      </TableHeader>
      <AntdesignTable
        allowMultieSelectRows={false}
        columns={UserListColumns({ handleDelete, handleEditRow, props })}
        data={props.usersList}
        paginationRightHeadingName="Total Users"
      />
    </>
  );
};

const DetailCardWraper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`;

export default Dashboard;
