import { useState, useEffect } from "react";

import { useSelector } from "react-redux";
import { main_api } from "../api/axios_helper";
import { companyAPIsEndPoints } from "../constants/ApiEndPoints";

// Custom Hook
const useFetchClientOnBoardSettings = () => {
  const [clientSettings, setClientSettings] = useState(null);
  let compId = useSelector((state) => state.auth?.loginResponse);

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchClientSettings = async () => {
      try {
        setLoading(true);
        const { data } = await main_api.get(companyAPIsEndPoints.GET_CLIENT_SETTINGS(compId?.selectCompany?.[0].company_id));
        setClientSettings(data?.result);
      } catch (error) {
        console.error(error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    if (compId?.selectCompany?.[0].company_id) {
      fetchClientSettings();
    }
  }, [compId?.selectCompany?.[0].company_id]);

  return { clientSettings, error, loading };
};

export default useFetchClientOnBoardSettings;
