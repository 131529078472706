import { main_api } from "../api/axios_helper";
import { companyAPIsEndPoints } from "../constants/ApiEndPoints";

export const typeOfTimeShift = [
    {value:"service_time_shift" , label:"Service Time Shift"},
    {value:"daily_time_shift" , label:"Daily Time Shift"}
];


export const timeInHours = [
    { value: "00:00:00", label: "12:00 AM" },
    { value: "00:30:00", label: "12:30 AM" },
    { value: "1:00:00", label: "1:00 AM" },
    { value: "1:30:00", label: "1:30 AM" },
    { value: "2:00:00", label: "2:00 AM" },
    { value: "2:30:00", label: "2:30 AM" },
    { value: "3:00:00", label: "3:00 AM" },
    { value: "3:30:00", label: "3:30 AM" },
    { value: "4:00:00", label: "4:00 AM" },
    { value: "4:30:00", label: "4:30 AM" },
    { value: "5:00:00", label: "5:00 AM" },
    { value: "5:30:00", label: "5:30 AM" },
    { value: "6:00:00", label: "6:00 AM" },
    { value: "6:30:00", label: "6:30 AM" },
    { value: "7:00:00", label: "7:00 AM" },
    { value: "7:30:00", label: "7:30 AM" },
    { value: "8:00:00", label: "8:00 AM" },
    { value: "8:30:00", label: "8:30 AM" },
    { value: "9:00:00", label: "09:00 AM" },
    { value: "9:30:00", label: "09:30 AM" },
    { value: "10:00:00", label: "10:00 AM" },
    { value: "10:30:00", label: "10:30 AM" },
    { value: "11:00:00", label: "11:00 AM" },
    { value: "11:30:00", label: "11:30 AM" },
    { value: "12:00:00", label: "12:00 PM" },
    { value: "12:30:00", label: "12:30 PM" },
    { value: "13:00:00", label: "1:00 PM" },
    { value: "13:30:00", label: "1:30 PM" },
    { value: "14:00:00", label: "2:00 PM" },
    { value: "14:30:00", label: "2:30 PM" },
    { value: "15:00:00", label: "3:00 PM" },
    { value: "15:30:00", label: "3:30 PM" },
    { value: "16:00:00", label: "4:00 PM" },
    { value: "16:30:00", label: "4:30 PM" },
    { value: "17:00:00", label: "5:00 PM" },
    { value: "17:30:00", label: "5:30 PM" },
    { value: "18:00:00", label: "6:00 PM" },
    { value: "18:30:00", label: "6:30 PM" },
    { value: "19:00:00", label: "7:00 PM" },
    { value: "19:30:00", label: "7:30 PM" },
    { value: "20:00:00", label: "8:00 PM" },
    { value: "20:30:00", label: "8:30 PM" },
    { value: "21:00:00", label: "9:00 PM" },
    { value: "21:30:00", label: "9:30 PM" },
    { value: "22:00:00", label: "10:00 PM" },
    { value: "22:30:00", label: "10:30 PM" },
    { value: "23:00:00", label: "11:00 PM" },
    { value: "23:30:00", label: "11:30 PM" },
  ];
  

const companyId = localStorage.getItem("company");
let services;
export let allServices = []; 
async function serv(companyId) {
    if (companyId) {
        await main_api
            .get(companyAPIsEndPoints.FETCH_SCHEDULER_LIST(companyId))
            .then(({ data }) => {
                services = data.result;
                allServices = services.map(service => ({ value: service.id, label: `${service.client.name}-${service.service.name}-${service.date}` }));
            })
            .catch(error => {
                services = null;
            });
    } 
    return services;
} 

serv(companyId)
  