import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { main_api } from "../../../api/axios_helper";
import CustomInput from "../../../components/CustomInput";
import ClientField from "./settingsScreenComponents/companySettings";
import Flags from "./settingsScreenComponents/clients/client";
import General from "./settingsScreenComponents/staff";
import PetField from "./settingsScreenComponents/keys";
import SavedReplies from "./settingsScreenComponents/invoicing";
import AntDesignNavTabs from "../../../components/antDesignNaviTabs/AntDesignNavTabs";
import CompanySettings from "./settingsScreenComponents/companySettings";
import Client from "./settingsScreenComponents/clients/client";
import Staff from "./settingsScreenComponents/staff";
import Keys from "./settingsScreenComponents/keys";
import Invoicing from "./settingsScreenComponents/invoicing";
import Theme from "./settingsScreenComponents/theme";
import Notifications from "./settingsScreenComponents/notifications";
import { settingsNavTabs } from "../../../constants/CompanyNavTabs/settingsNavTabs";
import { useSelector } from "react-redux";
import { companyAPIsEndPoints } from "../../../constants/ApiEndPoints";
import CommonUtil from "../../../util/commonUtil";
import { pushNotification } from "../../../util/Notification";
import TimeBlocks from "./settingsScreenComponents/timeblocks";

function Settings({
  fetchAllStaff,
  allStaffState,
  loginState,
  loadCompanyDetail,
  companyDetailResponse,
  fetchLoggedInClientInfo,
}) {
  const auth = useSelector((state) => state.auth);
  const [selectedCompany, setSelectedCompany] = useState();
  const [activeTab, setActiveTab] = useState("company_settings");
  const [companyClientSettings, setCompnayclientSettings] = useState({});
  const [territoryZipCodes, setTerritoryZipCodes] = useState([]);
  const handleChangetabs = (val) => {};
  const company_id = auth.loginResponse?.selectCompany?.[0]?.company_id || null;

  let isclient = auth?.loginResponse?.selectedRoleType === "client_type" ? true : false;
  useEffect(() => {
    if (isclient) {
      fetchLoggedInClientInfo();
    }
  }, []);

  useEffect(() => {
    if (company_id) {
      main_api
        .get(companyAPIsEndPoints.FETCH_SINGLE_COMPANY(company_id))
        .then(({ data }) => {
          setSelectedCompany(data.result);
        })
        .catch((error) => {
          console.log(error);
        });
      handleFetchClientOnBoardSettings();
    }
  }, []);

  const handleCompanySettings = (data) => {
    if (data) {
      main_api
        .put(companyAPIsEndPoints.UPDATE_SINGLE_COMPANY(company_id), data)
        .then(({ data }) => {
          if (data.isSuccess) {
            setSelectedCompany({
              ...data.result,
              company_admins: [
                {
                  email:
                    selectedCompany &&
                    selectedCompany.company_admins &&
                    selectedCompany.company_admins[0] &&
                    selectedCompany.company_admins[0].email,
                },
              ],
            });
            pushNotification(data.message, "success");
          }
        })
        .catch((error) => {
          console.log(error);
          pushNotification("Something Went Wrong!", "error");
        });
    }
  };

  const handleFetchClientOnBoardSettings = async () => {
    main_api
      .get(companyAPIsEndPoints.GET_CLIENT_SETTINGS(company_id))
      .then(({ data }) => {
        setCompnayclientSettings(data?.result);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <StickyBar>
        <NavigationHeading>Settings</NavigationHeading>
        <ActionButtons>
          <TabContainer>
            <TabButton onClick={() => setActiveTab("company_settings")} active={activeTab === "company_settings"}>
              Company Settings
            </TabButton>
            <TabButton onClick={() => setActiveTab("client")} active={activeTab === "client"} marginLeft="30px">
              Client
            </TabButton>
            <TabButton onClick={() => setActiveTab("staff")} active={activeTab === "staff"} marginLeft="30px">
              Staff
            </TabButton>
            {/* <TabButton onClick={() => setActiveTab("keys")} active={activeTab === "keys"} marginLeft="30px">
              Keys
            </TabButton>
            <TabButton onClick={() => setActiveTab("invoicing")} active={activeTab === "invoicing"} marginLeft="30px">
              Invoicing
            </TabButton> */}
            <TabButton onClick={() => setActiveTab("theme")} active={activeTab === "theme"} marginLeft="30px">
              Theme
            </TabButton>
            <TabButton onClick={() => setActiveTab("notifications")} active={activeTab === "notifications"} marginLeft="30px">
              Notifications
            </TabButton>
            <TabButton onClick={() => setActiveTab("timeblocks")} active={activeTab === "timeblocks"} marginLeft="30px">
              Time Blocks
            </TabButton>
          </TabContainer>
        </ActionButtons>
      </StickyBar>
      <div style={{ marginTop: "130px", marginBottom: "70px" }}>
        {activeTab === "company_settings" && (
          <CompanySettings
            selectedCompany={selectedCompany}
            handleCompanySettings={handleCompanySettings}
            territoryZipCodes={territoryZipCodes}
            setTerritoryZipCodes={setTerritoryZipCodes}
          />
        )}
        {activeTab === "client" && (
          <Client
            companyClientSettings={companyClientSettings}
            handleFetchClientOnBoardSettings={handleFetchClientOnBoardSettings}
            company_id={company_id}
          />
        )}
        {activeTab === "staff" && <Staff />}
        {/* {activeTab === "keys" && <Keys />}
        {activeTab === "invoicing" && <Invoicing />} */}
        {activeTab === "theme" && <Theme />}
        {activeTab === "notifications" && <Notifications />}
        {activeTab === "timeblocks" && <TimeBlocks />}
      </div>
    </>
  );
}

const NavigationHeading = styled.h2`
  display: flex;
  margin: 25px 0px 10px 0px;
  font-size: 20px;
  font-weight: 700;
`;
const ActionButtons = styled.div`
  display: flex;
  margin-bottom: 27px;
  border-bottom: 1px solid rgb(228, 234, 240);
`;
const TabContainer = styled.div`
  padding: 16px 16px 0px 16px;
  height: 54px;
  box-sizing: border-box;
  border-radius: 6px;
  display: flex;
`;
const TabButton = styled.button`
  background-color: transparent;
  padding: 9px 8px 29px 8px;
  height: 34px;
  border: none;
  margin-left: ${(props) => props.marginLeft ?? "0"};
  cursor: pointer;
  border-bottom: 2px solid ${(props) => (props.active ? "#FBCF34" : "transparent")};
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: ${(props) => (props.active ? "#000000" : "#757575")};
`;
const StickyBar = styled.div`
  position: fixed;
  background-color: white;
  z-index: 1;
  width: 100%;
  margin-top: -17px;
`;

export default Settings;
