//
export const serviceHolidaysTabs = (schEventsComp, schTempComp) => {
  return [
    {
      key: "1",
      label: "Services List",
      children: schEventsComp,
    },
    {
      key: "2",
      label: "Holidays",
      children: schTempComp,
    },
  ];
};
