import { ReactComponent as DeclineIcon } from "../../assets/rawSvgs/declineIcon.svg";
import { ReactComponent as TickIcon } from "../../assets/rawSvgs/tickIcon.svg";
import {replaceDateFormat} from '../../util/formatDate'
import {convertTo12HourFormat} from '../../util/formatTime'
import { Tag } from "antd";
import {convertToDayNames} from "../../util/commonUtil"
import { CommonActionHeaders } from "../TableActionHeaderColumn";
import { StaffTimeOffType } from "../../enums/staff";


const StaffTimeOffColumens = ({activeTab, handleDelete, handleEditRow, handleTimeOff}) => {

  let columns = [
    {
      title: "Time Off Date",
      dataIndex: "timeOffDate",
      key: "timeOffDate",

      render: (text, record) => (
        <>
          {record?.start_date && replaceDateFormat(record?.start_date)} 
          {record?.end_date && record.type !== StaffTimeOffType.SINGLE && <> - {replaceDateFormat(record?.end_date)}</>}
        </>
      ),
    },
    {
      title: "Time Duration",
      dataIndex: "timeDuration",
      key: "timeDuration",

      render: (text, record) => (
        <>
          {record?.start_time && convertTo12HourFormat(record.start_time)} 
          {record?.end_time && <> - {convertTo12HourFormat(record.end_time)}</>}
        </>
      ),
    },
    {
      title: "Recurring",
      dataIndex: "recurring",
      key: "recurring",
      render: (text, record) => (
        <>
          {record.recurring_days && record.recurring_days.length ?
          <>
            {convertToDayNames(record.recurring_days).slice(0, 2).map(day=>{
              return(
              <Tag style={tagStyle}>{day}</Tag>)
            })}
            {record.recurring_days.length > 2 &&
              <Tag style={tagStyle}>{`+${record.recurring_days.length-2}`}</Tag>
            }
          </>
          : <>-</>
          }
        </>
      ),
    }
  ];

  if(activeTab === 'requests'){
    columns.push(
      {
        title: "",
        dataIndex: "action",
        key: "action",
        render: (_, record) => (
          <>
            <div className="d-flex justify-content-evenly">
              <button onClick={()=>handleTimeOff({is_approved: true}, record, true)}>
                <TickIcon /> &nbsp; Accept
              </button>
              <button onClick={()=>handleTimeOff({is_approved: false, is_decline: true}, record, true)}>
                <DeclineIcon />
                &nbsp; Decline
              </button>
            </div>
          </>
        ),
      }
    )
  } else{
    columns.push(...CommonActionHeaders({ handleDelete, handleEditRow  }));
  }

  return columns;
};

export {
    StaffTimeOffColumens,
};

const tagStyle = {
  backgroundColor: "#EFF1F5",
  fontWeight: 500,
  border: "none",
  borderRadius: "28px",
  padding: "2px 8px 2px 6px",
  color: "#023047"
};