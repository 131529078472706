import { RightOutlined } from "@ant-design/icons";
import React from "react";
import styled from "styled-components";

function SkippedButton({onClick}) {
  return (
    <SkipButton onClick={onClick}>
      <span>Skip Onboarding</span>
      <RightOutlined />
    </SkipButton>
  );
}

export default SkippedButton;

const SkipButton = styled.button`
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  color: #424242;
  display: flex;
  align-items: center;
  gap: 10px;
`;
