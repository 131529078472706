export const paymentNavTabs = (subscriptionPlanComp, paymentMethodsComp) => {
  return [
    {
      key: "1",
      label: "Subscription Plans",
      children: subscriptionPlanComp,
    },

    {
      key: "3",
      label: "Payment Methods",
      children: paymentMethodsComp,
    },
  ];
};
