import React, {useState} from "react";
import styled from "styled-components";
import { Input, Tag } from "antd";


export const TagInputs = ({value = [], onChange, placeholder="Add Task Here"}) => {

  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (e) => {
    e.preventDefault();
    setInputValue(e.target.value);
  };

  const handleInputConfirm = (e) => {
    e.preventDefault();
    if (inputValue && value.indexOf(inputValue) === -1) {
      const newTags = [...value, inputValue];
      onChange(newTags);
    }
    setInputValue("");
  };

  const handleClose = (removedTag) => {
    const newTags = value.filter((tag) => tag !== removedTag);
    onChange(newTags);
  };
  
    return (
        <>
        <div style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
            {value.length > 0 && <TagDetail>
                {value.map(tag=>{
                    return (
                    <Tag
                        style={{backgroundColor:'#EAECF0', borderRadius: '12px', whiteSpace: 'wrap'}}
                        closable
                        onClose={() => handleClose(tag)}

                    >
                        {tag}
                    </Tag>
                    )
                })}
            </TagDetail>}

            <Input
              type="text"
              value={inputValue}
              onChange={handleInputChange}
              onBlur={handleInputConfirm}
              onPressEnter={handleInputConfirm}
              placeholder={placeholder}
            />
        </div>
        </>
    )

}


const TagDetail = styled.div`
    background-color: white;
    padding: 6px 0px 6px 14px;
    span{
      margin-top: 5px;
    }
`;