import React, { useEffect, useRef, useState } from 'react';
import { Checkbox, Collapse, Divider, Form } from 'antd';
import { ReactComponent as DragIcon } from "../../assets/rawSvgs/drag_indicator.svg";
import HeadingComponent from '../Heading';
import styled from 'styled-components';
import CustomBtnWithIcon from '../buttonWithIcon';
import PanelForm from './panelForm';
import { pushNotification } from '../../util/Notification';
import { companyAPIsEndPoints, platformAPIsEndPoints } from '../../constants/ApiEndPoints';
import { main_api } from '../../api/axios_helper';
import { createVideoSettings, editVideoSettings } from '../../util/DataService';

const OnboardingVideosSettings = () => {
    const [selectedItems, setSelectedItems] = useState([]);
    const [items, setItems] = useState([]);
    const [values, setValues] = useState({});
    const [form] = Form.useForm();
    useEffect(() => {
        main_api.get(platformAPIsEndPoints.FETCH_ONBOARDING_VIDEO_SETTINGS)
          .then(({ data }) => {
            const transformedItems = data.result.map((item, index) => ({
                id: item.id,
                label: item.name,
                description: item.description,
                link: item.link,
                weightage: item.weightage,
                order:item.order || 1,
                checked: item.checked || false,
                isOpen: false,
            }));
            setItems(transformedItems);
          })
          .catch((error) => {
            pushNotification("Something went wrong!", "error");

            // console.log(error);
          });
      }, []);

    

    const dragPanel = useRef(0);
    const draggedOverPanel = useRef(0);

  

   

 

    // const handleItemSelection = (item, checked) => {
    //     setItems((prevItems) =>
    //         prevItems.map((prevItem) =>
    //             prevItem.id === item.id ? { ...prevItem, checked } : prevItem
    //         )
    //     );
    
    //     if (checked) {
    //         setSelectedItems((prevSelectedItems) => {
    //             // Ensure no duplicates in selectedItems
    //             const isAlreadySelected = prevSelectedItems.some(
    //                 (selectedItem) => selectedItem.id === item.id
    //             );
    //             if (!isAlreadySelected) {
    //                 return [...prevSelectedItems, item];
    //             }
    //             return prevSelectedItems;
    //         });
    //     } else {
    //         setSelectedItems((prevSelectedItems) =>
    //             prevSelectedItems.filter((selectedItem) => selectedItem.id !== item.id)
    //         );
    //     }
    // };

    useEffect(() => {
        const initiallySelectedItems = items.filter(item => item.checked);
        setSelectedItems(initiallySelectedItems);

        
        
        setValues(initiallySelectedItems);
    }, [items]);

    
    const handleItemSelection = (item, checked) => {
        const updatedItem = { ...item, checked };
    
        setItems((prevItems) =>
            prevItems.map((prevItem) =>
                prevItem.id === item.id ? updatedItem : prevItem
            )
        );
    
        if (checked) {
            setSelectedItems((prevSelectedItems) => {
                const isAlreadySelected = prevSelectedItems.some(
                    (selectedItem) => selectedItem.id === item.id
                );
                if (!isAlreadySelected) {
                    return [...prevSelectedItems, updatedItem];
                }
                return prevSelectedItems.map((selectedItem) =>
                    selectedItem.id === item.id ? updatedItem : selectedItem
                );
            });
        } else {
            setSelectedItems((prevSelectedItems) =>
                prevSelectedItems.filter((selectedItem) => selectedItem.id !== item.id)
            );
        }
    };
    
    
    const setVideoForItem = (itemId, videoFile) => {
      
        const updatedItems = selectedItems.map((item) =>
            item.id === itemId ? { ...item, link: videoFile } : item
        );
        setSelectedItems(updatedItems);
    };

    const weightageCalculator = (selectedItems, values) => {
       
        let totalWeightage = selectedItems.reduce((total, item) => {
            const weightage = Number(values[`item_${item.id}_weightage`]) || 0;
            return total + weightage;
        }, 0);
    
     


        if (totalWeightage !== 100) {
            pushNotification("Total weightage must be equal to 100.", "error");
            return null; // Stop further processing and return null
        }
    
        return totalWeightage;
    };
    

   
    
    const handleSortPanels = () => {
        setItems((prevItems) => {
            const itemsClone = [...prevItems];
            const temp = itemsClone[dragPanel.current];
            itemsClone[dragPanel.current] = itemsClone[draggedOverPanel.current];
            itemsClone[draggedOverPanel.current] = temp;
    
            const updatedItems = itemsClone.map((item, index) => ({ ...item, order: index + 1 }));
    
            // Update selectedItems based on the new items array
            const updatedSelectedItems = selectedItems.map(selectedItem => 
                updatedItems.find(item => item.label === selectedItem.label)
            );
    
            setSelectedItems(updatedSelectedItems);
    
            return updatedItems;
        });
    };
    
    const handlePanelChange = (key) => {
        setItems((prevItems) =>
            prevItems.map((item) => ({
                ...item,
                isOpen: item.id === parseInt(key) ? true : false,
            }))
        );
    };

    const handleSubmit = async (values) => {
        const totalWeightage = weightageCalculator(selectedItems, values);
    
        if (totalWeightage !== 100) return;
        // Array of promises
        const promises = selectedItems.map(async (item) => {
            const name = item.label || "---";
            const video = item.link || "";
            const description = values[`item_${item.id}_description`] || "";
            const order = item.order || null;
            const weightage = Number(values[`item_${item.id}_weightage`]) || 10;
            const checked = item.checked
            const id = item.id;
               
            const data = {
                name: name,
                description: description,
                link: video,
                weightage: weightage,
                order: order,
                checked:checked
            }

         
            if (data) {

                return editVideoSettings(id,data);
            }
        });
    
        try {
            // All promises to resolve
            await Promise.all(promises);
            pushNotification("All video settings updated successfully", "success")
        } catch (error) {
            console.error('Error updating video settings:', error);
        }
    };
  

    return (
        <>
            <Form name="onboardingVideosSettings" onFinish={handleSubmit} form={form} layout="vertical">
                <div style={{ margin: "20px 4px 20px 4px" }}>
                    <Heading text={"Customise the onboarding experience for the company admin by customizing the sequence and selecting the categories of product, you want to display on the onboarding feature for the company web app."} fontSize='0.75rem' color='grey' />
                </div>
                {items.map((item, index) => (
                    <div
                        key={item.id}
                        {...(!item.isOpen && {
                            draggable: true,
                            onDragStart: () => (dragPanel.current = index),
                            onDragEnter: () => (draggedOverPanel.current = index),
                            onDragEnd: handleSortPanels,
                            onDragOver: (e) => e.preventDefault(),
                        })}
                        style={{ marginBottom: '16px' }}
                    >
                        <Collapse
                            activeKey={item.isOpen ? [item.id.toString()] : []}
                            expandIconPosition='end'
                            onChange={(key) => handlePanelChange(key)}
                        >
                            <Collapse.Panel
                                header={
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <Checkbox 
                                            style={{ marginRight: '10px' }}
                                            checked={item.checked}
                                            onChange={(e) => {
                                                handleItemSelection(item, e.target.checked);
                                            }} 
                                        />
                                        <DragIcon style={{ marginRight: '10px' }} />
                                        {item.label}
                                    </div>
                                }
                                key={item.id.toString()}
                            >
                               
                                <PanelForm prefix={`item_${item.id}`} form={form} initialWeightage={item.weightage} initialDescription={item.description} initialVideo={item.link} checked={item.checked} itemId={item.id} setVideoForItem={setVideoForItem} values={values} />
                            </Collapse.Panel>
                        </Collapse>
                    </div>
                ))}
                <Divider />
                <SaveContainer className="d-flex justify-content-end">
                    <CustomBtnWithIcon btnText="Save Changes" type="submit" hideIcon={true}/>
                </SaveContainer>
            </Form>
        </>
    );
};

const Heading = ({ text = "", margin, fontSize = "0.75rem", color = "#3B3B3B" }) => {
    return <HeadingComponent text={text} fontSize={fontSize} color={color} fontWeight={700} margin={margin} />;
};

const SaveContainer = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  border-top: 1px solid #E0E0E0;
  background-color: white;
  padding: 10px 20px;
`;

export default OnboardingVideosSettings;
