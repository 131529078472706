import React from "react"
import styled from "styled-components"

function Text({ text, children, type }) {
  return (
    <>
      <Container type={type}>
        {text}
        {children}
      </Container>
    </>
  )
}
const Container = styled.span`
  font-weight: 600;
  font-size: ${props => (props.type === "sub" ? "12px" : "18px")};
  line-height: 25px;
  color: #414d55;
`

export default Text
