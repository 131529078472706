import React, { useEffect, useState } from "react"
import { DatePicker, Input } from 'antd';
import styled from "styled-components";
import { AntdesignTablePagination } from "../../../components/antDesignTable/AntdesignTablePagination";
import {StaffListColumn } from "../../../constants/ReportingTableHeader/ReportingHeader";
import { useSelector } from "react-redux";
import { companyAPIsEndPoints } from "../../../constants/ApiEndPoints";
import { main_api } from "../../../api/axios_helper";
import { pushNotification } from "../../../util/Notification";
import dayjs from "dayjs";
import ReportUpdate from "./ReportUpdate";
import { PaginatedSelect } from "../../../components/PaginationSelect";
import { useDebounce } from "../../../hooks/useDebounce";

function StaffListing({filter, saveReport, setSaveReport, currentReport, setCurrentReport, saveReportCallBack}) {

  const { auth } = useSelector((state) => state);

  const [staffList, setStaffList] = useState([])
  const today = new Date()
  const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
  const [endDate, setEndDate] = useState(dayjs(new Date()).format('YYYY-MM-DD'))
  const [startDate, setStartDate] = useState(dayjs(firstDay).format('YYYY-MM-DD'))
  const [count, setCount] = useState(0)
  const [canSave, setCanSave] = useState(false)
  const [updateReport, setUpdateReport] = useState(false)
  const [selectService, setSelectService] = useState(null)
  const [zipCode, setZipCode] = useState("")
  const debounceZipCode = useDebounce(zipCode, 1000);

  useEffect(()=>{
    fetchStaff()
  },[startDate, endDate, filter, selectService, debounceZipCode])

  const resetFields = () =>{
    setStaffList([])
    setCount(0)
    setStartDate(dayjs(firstDay).format('YYYY-MM-DD'))
    setEndDate(dayjs(new Date()).format('YYYY-MM-DD'))
  }

  useEffect(()=>{
    resetFields()
  },[filter])

  const generateReport = (reportData=currentReport) => {
    const companyId = auth?.loginResponse?.selectCompany?.[0]?.company_id;
    const params = {company: companyId, filterType: filter, name: reportData.name}
    if(reportData.id){
      params['id'] = reportData.id
    }
    if (filter === 'staff_scheduled_in_date_range'){
      params['start_date'] = startDate
      params['end_date'] = endDate
    }
    else if(filter === 'staff_scheduled_on_date_for_service'){
      if (new Date(startDate) > new Date(endDate)){
        pushNotification('start date should be less than end date', 'error')
        resetFields()
        setCanSave(false)
        return
      }
      params['start_date'] = startDate
      params['end_date'] = endDate
      if (selectService){
        params['selected_service'] = selectService
      }
    }
    else if(filter === 'in_zip_code'){
      params['zip_code'] = zipCode
    }

    saveReportCallBack(params)

  }

  useEffect(()=>{
    if(saveReport){
      if(canSave){
        if(currentReport.id){
          setUpdateReport(true)
        }
        else{
          generateReport()
        }
      } else{
        setSaveReport(false)
      }
    } else{
      setSaveReport(false)
    }
  },[saveReport])

  const fetchStaff = (page = 1) => {
    const companyId = auth?.loginResponse?.selectCompany?.[0]?.company_id;
    let params = {company: companyId, filterType: filter }
    if (filter === 'staff_scheduled_in_date_range'){
      if (new Date(startDate) > new Date(endDate)){
        pushNotification('start date should be less than end date', 'error')
        resetFields()
        setCanSave(false)
        return
      }
      params['start_date'] = startDate
      params['end_date'] = endDate
    }
    else if(filter === 'staff_scheduled_on_date_for_service'){
      if (new Date(startDate) > new Date(endDate)){
        pushNotification('start date should be less than end date', 'error')
        resetFields()
        setCanSave(false)
        return
      }
      params['start_date'] = startDate
      params['end_date'] = endDate
      if (selectService){
        params['selected_service'] = selectService
      }
    }
    else if(filter === 'in_zip_code'){
      params['zip_code'] = zipCode
    }
    main_api.post(`${companyAPIsEndPoints.REPORTING_STAFF}?page=${page}`, params)
    .then(({ data }) => {
      setStaffList(data.results)
      setCount(data.count)
      if(data.count){
        setCanSave(true)
      }else{
        setCanSave(false)
      }
    })
    .catch((error) => {
      console.log(error);
    });
  }

  const fetchService = async (page) => {
    const companyId = auth?.loginResponse?.selectCompany?.[0]?.company_id;
    // Example: Fetch data from an API
    const response = await main_api.get(companyAPIsEndPoints.GET_ALL_SERVICES(companyId, "", `pagination=true&page=${page}`));
    const data = response.data.result;

    const serviceData = data.map(item => ({
      label: item.name,
      value: item.id
    }));
  
    return {
      data: serviceData,
      total: response.data.count
    };
  };


  return (
    <>
      {(filter === 'all_staff' || filter === 'not_activated_accounts') && 
        <AntdesignTablePagination
          columns={StaffListColumn({ })}
          data={staffList}
          totalCount={count}
          loadPaginatedData={fetchStaff}
          allowRowSelection={false}
        />
      }

      {filter === 'in_zip_code' &&
        <>
          <div style={{display: 'flex', alignItems: 'center', gap:'10px', marginBottom: '10px'}}>
            <label>Zip Code:</label>
            <span><Input defaultValue={zipCode} onChange={e => setZipCode(e.target.value)} placeholder="Zip Code" /></span>
          </div>
          <AntdesignTablePagination
            columns={StaffListColumn({ })}
            data={staffList}
            totalCount={count}
            loadPaginatedData={fetchStaff}
            allowRowSelection={false}
        />
        </>
      }

      {(filter === 'staff_scheduled_in_date_range') &&
        <>
          <div style={{marginBottom: '10px'}}>
            <label>Date Added:</label>
            <DateContainer><DatePicker value={dayjs(startDate)} allowClear={false} defaultValue={dayjs(new Date(today.getFullYear(), today.getMonth(), 1))} onChange={(e, dateString) => setStartDate(dateString)} placeholder="Start Date"/> - <DatePicker value={dayjs(endDate)} allowClear={false} defaultValue={dayjs(new Date())} onChange={(e, dateString) => setEndDate(dateString)} placeholder="End Date"/></DateContainer>
          </div>
          <AntdesignTablePagination
            columns={StaffListColumn({ })}
            data={staffList}
            totalCount={count}
            loadPaginatedData={fetchStaff}
            allowRowSelection={false}
        />
        </>
      }

      {(filter === 'staff_scheduled_on_date_for_service') &&
        <>
          <div style={{marginBottom: '10px', display: 'flex', alignItems: 'baseline', gap: '5px', flexWrap: 'wrap'}}>
            <div style={{marginBottom: '10px'}}>
              <label>Date Added:</label>
              <DateContainer><DatePicker value={dayjs(startDate)} allowClear={false} defaultValue={dayjs(new Date(today.getFullYear(), today.getMonth(), 1))} onChange={(e, dateString) => setStartDate(dateString)} placeholder="Start Date"/> - <DatePicker value={dayjs(endDate)} allowClear={false} defaultValue={dayjs(new Date())} onChange={(e, dateString) => setEndDate(dateString)} placeholder="End Date"/></DateContainer>
            </div>
            <div style={{width: '20%'}}>
              <PaginatedSelect value={selectService} setValue={setSelectService} fetchOptions={fetchService} placeholder="Select Service" />
            </div>
          </div>
          <AntdesignTablePagination
            columns={StaffListColumn({ })}
            data={staffList}
            totalCount={count}
            loadPaginatedData={fetchStaff}
            allowRowSelection={false}
          />
        </>
      }
      
      {updateReport && <ReportUpdate setSaveReport={setSaveReport} generateReport={generateReport} setUpdateReport={setUpdateReport} currentReport={currentReport} setCurrentReport={setCurrentReport}/>}
    </>
  )
}

export default StaffListing;


const DateContainer = styled.span`
  margin-left: 5px;
`;
