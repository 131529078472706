import React, { useEffect, useState } from "react";
import { Divider, Form, Checkbox, Button } from "antd";
import styled from "styled-components";
import FormItem from 'antd/es/form/FormItem';
import HeadingComponent from "../Heading";
import CustomBtnWithIcon from "../buttonWithIcon";
import { main_api } from "../../api/axios_helper";
import { platformAPIsEndPoints } from "../../constants/ApiEndPoints";
import { useSelector } from "react-redux";
import { editThemeSettings } from "../../util/DataService";
import { pushNotification } from "../../util/Notification";

export default function OnboardingthemeSettings() {
  const [form] = Form.useForm();
  const { auth } = useSelector((state) => state);

  const [initialSettings, setInitialSettings] = useState();
 

  const getThemeSettings = () => {
    const id = 1;
    main_api.get(platformAPIsEndPoints.FETCH_ONBOARDING_THEME_SETTINGS(id))
    .then(({ data }) => {
      setInitialSettings(data.result)
    })
    .catch((error) => {
      pushNotification("Something went wrong!", "error");

      // console.log(error);
    });
  };

  useEffect(() => {
    getThemeSettings();
  },[]);

  const [logoOptions, setLogoOptions] = useState({
    companyLogo: false,
    mobileAppLogo: false,
    emailLogo: false,
  });
  
  const [dashboardColors, setDashboardColors] = useState({
    headerColor: false,
    headerFontColor: false,
    headerTitleColor: false,
    headerSeparatorColor: false,
  });

  const [clientPortalColors, setClientPortalColors] = useState({
    headerBackgroundColor: false,
    headerTextColor: false,
    headerSecondaryTextColor: false,
    footerBackgroundColor: false,
    footerTextColor: false,
  });
  
  const [emailLayoutColors, setEmailLayoutColors] = useState({
    emailHeaderColor: false,
    headerTextColor: false,
    excerptBackground: false,
    excerptSeparator: false,
    emailBackground: false,
    footerLink: false,
  });
  
  useEffect(() => {
    if (initialSettings) {
      setLogoOptions({
        companyLogo: initialSettings.company_logo_enabled,
        mobileAppLogo: initialSettings.mobile_app_logo_enabled,
        emailLogo: initialSettings.email_logo_enabled,
      });

      setDashboardColors({
        dashboardHeaderColor: initialSettings.dashboard_header_enabled,
        headerFontColor: initialSettings.dashboard_header_font_enabled,
        headerTitleColor: initialSettings.dashboard_header_title_enabled,
        headerSeparatorColor: initialSettings.dashboard_header_separator_enabled,
      });

      setClientPortalColors({
        headerBackgroundColor: initialSettings.clientportal_header_background_enabled,
        headerTextColor: initialSettings.clientportal_header_text_enabled,
        headerSecondaryTextColor: initialSettings.clientportal_header_secondary_enabled,
        footerBackgroundColor: initialSettings.clientportal_footer_background_enabled,
        footerTextColor: initialSettings.clientportal_footer_text_enabled,
      });

      setEmailLayoutColors({
        emailHeaderColor: initialSettings.email_header_enabled,
        headerTextColor: initialSettings.email_header_text_enabled,
        excerptBackground: initialSettings.email_excerpt_background_enabled,
        excerptSeparator: initialSettings.email_excerpt_separator_enabled,
        emailBackground: initialSettings.email_background_enabled,
        footerLink: initialSettings.email_footer_link_enabled,
      });

      // Set form values
      form.setFieldsValue({
        companyLogo: initialSettings.company_logo_enabled,
        mobileAppLogo: initialSettings.mobile_app_logo_enabled,
        emailLogo: initialSettings.email_logo_enabled,
        dashboardHeaderColor: initialSettings.dashboard_header_enabled,
        headerFontColor: initialSettings.dashboard_header_font_enabled,
        headerTitleColor: initialSettings.dashboard_header_title_enabled,
        headerSeparatorColor: initialSettings.dashboard_header_separator_enabled,
        headerBackgroundColor: initialSettings.clientportal_header_background_enabled,
        headerTextColor: initialSettings.clientportal_header_text_enabled,
        headerSecondaryTextColor: initialSettings.clientportal_header_secondary_enabled,
        footerBackgroundColor: initialSettings.clientportal_footer_background_enabled,
        footerTextColor: initialSettings.clientportal_footer_text_enabled,
        emailHeaderColor:initialSettings.email_header_enabled,
        excerptBackground: initialSettings.email_excerpt_background_enabled,
        excerptSeparator: initialSettings.email_excerpt_separator_enabled,
        emailBackground: initialSettings.email_background_enabled,
        footerLink: initialSettings.email_footer_link_enabled,
      });
    }
  }, [initialSettings, form]);

  const handleLogoOptionChange = (option) => (e) => {
    setLogoOptions({
      ...logoOptions,
      [option]: e.target.checked,
    });
  };

  const handleDashboardColorChange = (property) => (e) => {
    setDashboardColors({
      ...dashboardColors,
      [property]: e.target.checked,
    });
  };

  const handleClientPortalColorChange = (property) => (e) => {
    setClientPortalColors({
      ...clientPortalColors,
      [property]: e.target.checked,
    });
  };

  const handleEmailLayoutColorChange = (property) => (e) => {
    setEmailLayoutColors({
      ...emailLayoutColors,
      [property]: e.target.checked,
    });
  };

  const onFinish = (formValues) => {
    const data = {
      company_logo_enabled: formValues.companyLogo,
      mobile_app_logo_enabled: formValues.mobileAppLogo,
      email_logo_enabled: formValues.emailLogo,
      dashboard_header_enabled: formValues.dashboardHeaderColor,
      dashboard_header_font_enabled: formValues.headerFontColor,
      dashboard_header_title_enabled: formValues.headerTitleColor,
      dashboard_header_separator_enabled: formValues.headerSeparatorColor,
      clientportal_header_background_enabled: formValues.headerBackgroundColor,
      clientportal_header_text_enabled: formValues.headerTextColor,
      clientportal_header_secondary_enabled: formValues.headerSecondaryTextColor,
      clientportal_footer_background_enabled: formValues.footerBackgroundColor,
      clientportal_footer_text_enabled: formValues.footerTextColor,
      email_header_enabled: formValues.emailHeaderColor,
      email_header_text_enabled: formValues.headerTextColor,
      email_excerpt_background_enabled: formValues.excerptBackground,
      email_excerpt_separator_enabled: formValues.excerptSeparator,
      email_background_enabled: formValues.emailBackground,
      email_footer_link_enabled: formValues.footerLink,
    };

    editThemeSettings(1, data)
    .then(({ data }) => {
      if (data.isSuccess) {
        pushNotification(data.message, "success")
      }
    })
    .catch((error) => {
      pushNotification("Something went wrong!", "error");

      // console.log(error);
    });
  };

  const renderSection = (title, options, handleChange) => (
    <>
      <div className="d-flex flex-row" style={{ marginBottom: '10%' }}>
        <div style={{ width: '60%', marginLeft: "14px" }}>
          <Heading text={title} fontSize="1rem" color="black" />
        </div>
        <div style={{ width: "40%", position: 'relative', top: "2px", left: "350px" }}>
          {Object.keys(options).map((key) => (
            <FormItem
              key={key}
              style={{ marginBottom: '10px' }}
              name={key}
              valuePropName="checked"
              initialValue={options[key]}
            >
              <div className="d-flex align-items-center">
              <CheckboxLabel>

                <Checkbox
                  onChange={handleChange(key)}
                  checked={options[key]}
                >
                  {key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1')}
                </Checkbox>
              </CheckboxLabel>
              </div>
            </FormItem>
          ))}
        </div>
      </div>
      <Divider />
    </>
  );

  return (
    <>
      <MainContainer>
        <Form name="onboardingThemeSettings" onFinish={onFinish} form={form} layout="vertical">
          {renderSection("Logo Options", logoOptions, handleLogoOptionChange)}
          {renderSection("Dashboard Colors", dashboardColors, handleDashboardColorChange)}
          {renderSection("Client Portal Colors", clientPortalColors, handleClientPortalColorChange)}
          {renderSection("Email Layout Colors", emailLayoutColors, handleEmailLayoutColorChange)}

          <SaveContainer className="d-flex justify-content-end">
            <CustomBtnWithIcon btnText="Save Changes" type="submit" hideIcon={true} />
          </SaveContainer>
        </Form>
      </MainContainer>
    </>
  );
}

const Heading = ({ text = "", margin, fontSize = "0.75rem", color = "#3B3B3B" }) => {
  return <HeadingComponent text={text} fontSize={fontSize} color={color} fontWeight={700} margin={margin} />;
};

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
`;

const SaveContainer = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  border-top: 1px solid #E0E0E0;
  background-color: white;
  padding: 10px 20px;
`;

const CheckboxLabel = styled.label`
  white-space: nowrap;
  display: flex;
  align-items: center;
`;