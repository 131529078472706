import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import toaster from "toasted-notes";
import { setJWTToken, setUserId } from "../../../api/auth";
import { basic_api } from "../../../api/axios_helper";
import CustomInput from "../../../components/CustomInput";
import Notification from "../../../components/Notification";
import { Col, Divider, Image, Row } from "antd";
import googleIcon from "../../../assets/images/google-social-icon.svg";
import facebookIcon from "../../../assets/images/facebook-social-icon.svg";
import companyLogo from "../../../assets/images/furgis-logo.svg";
import { useSpring, animated } from "react-spring";
import { Formik } from "formik";
import * as Yup from "yup";
import FormikInputFloating from "../../../components/formikInputs/TextInput";
import axiosInstance from "../../../util/AxiosConfig";
import { companyAPIsEndPoints } from "../../../constants/ApiEndPoints";
import { pushNotification } from "../../../util/Notification";
import { loginFormSchema } from "../../../constants/CompanyFormFieldsSchema/FormSchema";
import AuthButton from "../../../components/authButton";
import AuthPetsScreen from "../../../components/AuthPetsScreen/AuthPetsScreen";

function Signin(props) {
  let navigate = useNavigate();
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    function deleteAllCookies() {
      let cookies = document.cookie.split(";");

      for (let i = 0; i < cookies.length; i++) {
        let cookie = cookies[i];
        let eqPos = cookie.indexOf("=");
        let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
      }
    }

    deleteAllCookies();
  }, []);

  const signin = async (values) => {
    try {
      const updatedValues = {
        is_platform_login: true,
        ...values,
      };
      setIsAnimating(true);
      const { data } = await axiosInstance.post(companyAPIsEndPoints.COMPANY_LOGIN, updatedValues);
      setIsAnimating(false);

      if (data.isSuccess) {
        setJWTToken(data?.result.token);
        setUserId(data?.result?.user?.id);
        let selectedRoleId = null;
        if (data?.result?.user?.platform_role?.id) {
          selectedRoleId = data?.result?.user?.platform_role?.id;
        }
        props.loginUserSlice({
          ...data.result.user,
          selectedRoleId: selectedRoleId,
          selectedRoleType: "platform_type",
        });
        localStorage.setItem("loggedAs", "platform_dashboard");
        navigate(`/platform/dashboard`);
      }
    } catch (error) {
      setIsAnimating(false);

      if (error?.message === "Platform Admin account not found") {
        pushNotification(error?.message, "error");
        return;
      }
      if (error?.message) {
        pushNotification(error?.message, "error");
      }
    }
  };

  const handleForgotPasswordClick = () => {
    props.setIsAnimating(true);
    props.onForgotPasswordClick();
  };

  const userSchema = Yup.object().shape({
    username: Yup.string().required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  return (
    <Row style={{ height: "100vh", background: "#F9F9F9" }}>
      <Col md={12}>
        <AuthPetsScreen />
      </Col>
      <Col md={12} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <FormContainer>
          <FormList>
            <Formik
              initialValues={{
                email: "",
                password: "",
              }}
              onSubmit={(values) => {
                signin(values);
              }}
              validationSchema={loginFormSchema}
              enableReinitialize
              validateOnChange={false}
            >
              {({ handleSubmit, error, values }) => (
                <Form onSubmit={handleSubmit} autoComplete="new-password">
                  <Image src={companyLogo} preview={false} />
                  <FormHeader>Log In</FormHeader>
                  <FormDescriptionText>Welcome Back! Please enter your credentials.</FormDescriptionText>
                  <P20 />
                  <FormikInputFloating
                    label="Email ID"
                    name="email"
                    type="text"
                    className="w-100 mb-2"
                    autoComplete="new-password"
                  />
                  <FormikInputFloating
                    label="Password"
                    name="password"
                    type="password"
                    className="w-100"
                    autoComplete="off"
                    passwordField={true}
                  />
                  <FormSupportingText
                    onClick={handleForgotPasswordClick}
                    style={{ marginLeft: "auto", marginTop: "10px", cursor: "pointer" }}
                  >
                    Forgot password?
                  </FormSupportingText>
                  <Flex flexDirection={"column"}>
                    <FlexedInput>
                      <AuthButton btnText={"Login"} type="submit" disabled={props.isLoading || isAnimating} />
                    </FlexedInput>
                  </Flex>
                </Form>
              )}
            </Formik>
          </FormList>
        </FormContainer>
      </Col>
    </Row>
  );
}

const FormContainer = styled(animated.div)`
  width: 448px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  background: #ffffff;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 48px 24px;
`;

const FormList = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
`;

const Flex = styled.div`
  display: flex;
  flex-direction: ${(props) => props.flexDirection ?? "row"};
  justify-content: space-between;
  width: 100%;
`;

const FlexedInput = styled.div`
  width: 100%;
`;

const P20 = styled.div`
  height: 20px;
`;

const FormHeader = styled.h1`
  font-family:
    Helvetica Neue,
    Arial;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #000000;
  margin-top: 24px;
`;

const FormDescriptionText = styled.p`
  font-family: "Helvetica Neue", Arial;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #212121;
`;

const FormSupportingText = styled.p`
  font-family:
    Helvetica Neue,
    Arial;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  color: #424242;
  letter-spacing: 0em;
  text-align: center;
`;

const Form = styled.form`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export default Signin;
