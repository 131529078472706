import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import toaster from "toasted-notes";
import { setJWTToken, setUserId } from "../../../api/auth";
import { basic_api } from "../../../api/axios_helper";
import CustomInput from "../../../components/CustomInput";
import Notification from "../../../components/Notification";
import { Col, Divider, Image, Row } from "antd";
import googleIcon from "../../../assets/images/google-social-icon.svg";
import facebookIcon from "../../../assets/images/facebook-social-icon.svg";
import companyLogo from "../../../assets/images/furgis-logo.svg";
import ArrowBack from "../../../assets/images/arrow-back.svg";
import { useSpring, animated } from "react-spring";
import FormikInputFloating from "../../../components/formikInputs/TextInput";
import { Formik } from "formik";

import { pushNotification } from "../../../util/Notification";
import axiosInstance from "../../../util/AxiosConfig";
import { forgotPasswordFormSchema } from "../../../constants/CompanyFormFieldsSchema/FormSchema";
import { companyAPIsEndPoints } from "../../../constants/ApiEndPoints";
import AuthButton from "../../../components/authButton";
import AuthPetsScreen from "../../../components/AuthPetsScreen/AuthPetsScreen";

function ForgetPassword(props) {
  let navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsloading] = useState(false);
  // const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    function deleteAllCookies() {
      var cookies = document.cookie.split(";");

      for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        var eqPos = cookie.indexOf("=");
        var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
      }
    }
    deleteAllCookies();
  }, []);

  const forgetPassword = async (values) => {
    setIsloading(true);
    try {
      const { data } = await axiosInstance.put(companyAPIsEndPoints.FORGOT_PASSWORD, values);
      if (data.success) {
        pushNotification("Password Recovery Email Sent", "success");
      }
      setIsloading(false);
    } catch (error) {
      setIsloading(false);
      // console.log("error? >>", error);
      if (error) {
        pushNotification(error?.error, "error");
      }
    }
  };

  const handleBackToLoginClick = () => {
    props.onBackToLoginClick();
  };

  return (
    <Row style={{ height: "100vh", background: "#F9F9F9" }}>
      <Col md={12}>
        <AuthPetsScreen />
      </Col>
      <Col md={12} style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
        <div>
          <h4 onClick={handleBackToLoginClick} className="d-flex align-items-center gap-2" style={{ cursor: "pointer" }}>
            <BackIcon src={ArrowBack} alt="Arrow Down Icon" /> Back to Login
          </h4>
          <br />
          <br />
          <FormContainer style={{ ...props.height, ...props.fade }}>
            <FormList>
              <Formik
                initialValues={{
                  email: "",
                }}
                onSubmit={(values) => {
                  forgetPassword(values);
                }}
                validationSchema={forgotPasswordFormSchema}
                enableReinitialize
                validateOnChange={false}
              >
                {({ handleSubmit }) => (
                  <Form onSubmit={handleSubmit}>
                    <Image src={companyLogo} preview={false} />
                    <FormHeader>Forget password?</FormHeader>
                    <FormSupportingText>Please enter your email ID to recieve reset password link</FormSupportingText>
                    <P20 />
                    <FormikInputFloating label="Email ID" name="email" type="email" className="w-100" />
                    <AuthButton btnText={" Send link"} margin="15px 0px 0px 0px" disabled={isLoading} />
                  </Form>
                )}
              </Formik>
            </FormList>
          </FormContainer>
        </div>
      </Col>
    </Row>
  );
}

const BackIcon = styled.img`
  width: 20px;
  height: 16px;
  cursor: pointer;
`;

const FormContainer = styled(animated.div)`
  width: 471px;
  height: 404px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  background: #ffffff;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 48px 24px;
`;

const FormList = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
`;

const P20 = styled.div`
  height: 20px;
`;

const FormHeader = styled.h1`
  font-family:
    Helvetica Neue,
    Arial;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #000000;
  margin-top: 24px;
`;

const FormSupportingText = styled.p`
  font-family:
    Helvetica Neue,
    Arial;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #212121;
`;

const Form = styled.form`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

const SubmitButton = styled.button`
  height: ${(props) => props.height ?? "65px"};
  width: ${(props) => props.width ?? "200px"};
  background: ${(props) => props.background ?? "#fff"};
  border-radius: 8px;
  margin: ${(props) => props.margin ?? "60px 0px 0px 0px"};
  opacity: ${(props) => (props.disabled ? 0.7 : 1)};
  cursor: pointer;
  font-family: Helvetica Neue, Arial;
  font-style: normal;
  font-weight: 500;
  font-size: ${(props) => props.fontSize ?? "23.8383px"}
  line-height: 32px;
  color: ${(props) => props.color ?? "#000"};
  border: ${(props) => props.border ?? "1px solid #D0D5DD"};
`;

export default ForgetPassword;
