import React, { useEffect, useState } from "react";

import { NavigationHeading, TableHeader } from "./clientStyles";
import MakePaymentModal from "./MakePaymentModal";

import { Col, Row } from "antd";

import PermissionModal from "../../../components/Modals/PermissionModal";
import { checkPermission } from "../../../util/commonUtil";

import { clientInvoicingTaleHeaders } from "../../../constants/CompanyTableHeaders/invoicingTableHeaders";
import SearchInput from "../../../components/SearchInput";
import AntDesignDropDownSelect from "../../../components/AntDesignDropDownSelect/AntDesignDropDownSelect";
import { AntdesignTablePagination } from "../../../components/antDesignTable/AntdesignTablePagination";
import { useDebounce } from "../../../hooks/useDebounce";
import { fetchLoggedInClientApi } from "../../../redux/slices/ClientSlice";
import { useDispatch } from "react-redux";

//
const Invoicing = ({
  invoiceSingleDetail,
  fetchSingleInvoice,
  invoicingState,
  fetchAllInvoice,
  loginState,
  permissionState,
  clientResponse,
}) => {
  //

  const [openPaymentModal, setopenPaymentModal] = useState(false);
  const [permissionPopUp, setPermissionPopUp] = useState(false);
  const [invoiceId, setInvoiceId] = useState("");
  const [selectInvoiceType, setSelectInvoiceType] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [searchQuery, setSeacrhQuery] = useState("");
  const debounceZipCode = useDebounce(searchQuery, 600);
  let dispatch = useDispatch();
  let clientId = loginState?.selectCompany?.[0]?.client_id;

  useEffect(() => {
    dispatch(fetchLoggedInClientApi());

    fetchAllInvoice(
      `client=${loginState?.selectCompany?.[0]?.client_id}&status=${selectInvoiceType}&page=${pageNumber}&search=${searchQuery}`,
    );
  }, [debounceZipCode]);

  useEffect(() => {
    if (checkPermission(permissionState, "view_clients")) {
      fetchAllInvoice(
        `client=${loginState?.selectCompany?.[0]?.client_id}&status=${selectInvoiceType}&page=${pageNumber}&search=${searchQuery}`,
      );
    }
  }, [permissionState, selectInvoiceType, pageNumber]);

  let pageSize = 10;

  return (
    <>
      {/* <NoItemsToRender /> */}

      <TableHeader>
        <NavigationHeading>Invoices </NavigationHeading>
      </TableHeader>
      <Row align={"middle"}>
        <Col md={4}>
          <SearchInput
            placeholder="Type to search.."
            search={searchQuery}
            onChange={(value) => setSeacrhQuery(value)}
            // onBlur={searchBlurHandler}
          />
        </Col>
        <Col md={4} className="ms-3">
          <AntDesignDropDownSelect
            options={[
              { value: "", label: "Open invoices" },
              { value: "paid", label: "Paid" },
            ]}
            className="w-100"
            defaultValue="Select type"
            onChange={(e) => {
              setSelectInvoiceType(e);
            }}
          />
        </Col>
      </Row>
      <AntdesignTablePagination
        columns={clientInvoicingTaleHeaders({
          openPaymentModal: (idinvoice) => {
            setopenPaymentModal(true);
            setInvoiceId(idinvoice);
          },
        })}
        data={invoicingState.allInvoicing?.responseData || []}
        totalCount={invoicingState.allInvoicing?.invoiceRowCount}
        loadPaginatedData={(page) => {
          setPageNumber(page);
          // fetchAllInvoice(`status=${selectInvoiceType}&page=${pageNumber}`);
        }}
        pageSize={pageSize}
        loading={invoicingState?.allInvoicing.isLoading}
      />

      {openPaymentModal && (
        <MakePaymentModal
          openModal={openPaymentModal}
          handleClose={() => {
            setopenPaymentModal(false);
          }}
          clientList={clientResponse.clientList}
          companyId={loginState?.selectCompany?.[0]?.company_id || 0}
          fetchAllInvoice={fetchAllInvoice}
          allInvoicesClientwise={invoicingState.allInvoicing?.responseData}
          fetchSingleInvoice={fetchSingleInvoice}
          invoiceId={invoiceId}
          invoiceSingleDetail={invoiceSingleDetail}
          onSuccess={() => {
            fetchAllInvoice(`client=${loginState?.selectCompany?.[0]?.client_id}&status=${selectInvoiceType}&page=${pageNumber}`);
          }}
          clientId={clientId}
        />
      )}

      <PermissionModal onclose={() => setPermissionPopUp(false)} openModal={permissionPopUp} />
    </>
  );
};

export default Invoicing;
