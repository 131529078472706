import React, { useState } from "react";
import { Layout, Menu } from "antd";

const { Sider } = Layout;

const SideBar = ({ menuItems, handleMenuSelect }) => {
  const [selectedKey, setSelectedKey] = useState("1"); // Set default selected key to '1'

  const onItemClick = (item) => {
    setSelectedKey(item.key);
    handleMenuSelect(item);
  };

  return (
    <Sider
      style={{
        height: "100vh", // Stretch to the end of the page
        width: "340px", // Adjust the width towards the left
      }}
      breakpoint="sm"
      collapsedWidth="0"
      onBreakpoint={(broken) => {
        console.log(broken);
      }}
      onCollapse={(collapsed, type) => {
        console.log(collapsed, type);
      }}
    >
      <Menu
        theme="light"
        mode="inline"
        defaultSelectedKeys={[selectedKey]} // Set default selected key
        selectedKeys={[selectedKey]}
        style={{ color: "rgba(0, 0, 0, 0.85)" }}
      >
        {menuItems.map((item) => (
          <Menu.Item
            key={item.key}
            onClick={() => onItemClick(item)}
            style={{
              borderLeft: selectedKey === item.key ? "4px solid #000000" : "none",
              borderRadius: "0", // Remove rounded edges
            }}
          >
            <span style={{ color: selectedKey === item.key ? "#000000" : "rgba(0, 0, 0, 0.65)" }}>{item.label}</span>
          </Menu.Item>
        ))}
      </Menu>
    </Sider>
  );
};

export default SideBar;
