import React, { useEffect, useState } from "react";
import { Divider, Input, Select } from "antd";
import styled from "styled-components";
import CustomBtnWithIcon from "../../buttonWithIcon";
import { CustomModal } from "../ModalStyles";
import SelectField from "../../Form/Select";
import RadioField from "../../Form/RadioField";
import { Formik, Form } from "formik";
import { pushNotification } from "../../../util/Notification";
import * as Yup from "yup";
import "./styles.css";
import { useSelector } from "react-redux";
import { main_api } from "../../../api/axios_helper";
import { staffAPIsEndPoints } from "../../../constants/ApiEndPoints";

const EditKeyModal = ({
  isLoading,
  openKeyModal,
  setOpenKeyModal,
  openEditKeyValues,
  updateSingleKey,
  getManagementkeys,
  keyEditState,
  onFinish,
  vetList,
  clientList,
  clientId,
  showClientSelect = true,
}) => {
  const [possession, setPossession] = useState("");
  const [staffList, setStaffList] = useState([]);
  const handleSubmit = (values) => {
    // Handle form submission
    updateSingleKey({ id: openEditKeyValues?.id, values: values });
    setOpenKeyModal(false);
    pushNotification("Key updated Successfully.");
    getManagementkeys();
  };
  const auth = useSelector((state) => state.auth);
  useEffect(() => {
    getManagementkeys();
  }, [keyEditState]);

  // Get Staff list
  useEffect(() => {
    // if (openKeyModal) {
    let company_id = auth.loginResponse?.selectCompany?.[0]?.company_id || null;
    if (company_id) {
      main_api
        .get(`${staffAPIsEndPoints.LIST_STAFF(`usercompanyrole__company=${company_id}`)}`)
        .then(({ data }) => {
          setStaffList(data);
        })
        .catch((error) => {
          pushNotification("Something went wrong!", "error");

          // console.log(error);
        });
    }
    // }
  }, [possession]);

  const EditKeyValidation = Yup.object().shape({
    name: Yup.string()
      .min(3, "Name must be between 3 and 50 characters")
      .max(50, "Name must be between 3 and 50 characters")
      .required("Key name is required."),
    client: Yup.string().required("Client name is required."),
    possession: Yup.string().required("Possession is required."),
    type: Yup.string().required("Type is required."),
  });

  return (
    <CustomModal
      open={openKeyModal}
      title="Edit a Key"
      width="748px"
      // heigth="288px"
      onCancel={() => setOpenKeyModal(false)}
      footer={null}
      maskClosable={false}
    >
      <Divider />
      <Formik
        initialValues={{
          client: openEditKeyValues.client,
          name: openEditKeyValues.name,
          possession: openEditKeyValues.possession,
          type: openEditKeyValues.type,
          code: openEditKeyValues.code,
          staff_id: openEditKeyValues.staff_id,
          is_master_key: true,
        }}
        onSubmit={handleSubmit}
        enableReinitialize
        validationSchema={EditKeyValidation}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setValues }) => {
          return (
            <>
              <Form name="addKeyForm" layout="vertical">
                <FormWrapper>
                  <FieldWrapper>
                    <LabelFiled>Client Name</LabelFiled>
                    <Select
                      as="select"
                      placeholder="Select Client"
                      label="Client Name"
                      size="large"
                      name="client"
                      className="w-100"
                      onChange={(value) => {
                        setValues((pre) => ({
                          ...pre,
                          client: value,
                        }));
                      }}
                      value={values.client}
                      options={clientList}
                    />
                    {errors.client && touched.client ? <div className="error-message">{errors.client}</div> : null}
                  </FieldWrapper>
                  <FieldWrapper>
                    <LabelFiled>Key Name</LabelFiled>
                    <Input
                      placeholder="John_123"
                      label="Key Name"
                      size="large"
                      name="name"
                      onChange={handleChange}
                      value={values.name}
                      rules={[
                        {
                          min: 3,
                          max: 50,
                          message: "Please select a value",
                        },
                      ]}
                    />
                    {errors.name && touched.name ? <div className="error-message">{errors.name}</div> : null}
                  </FieldWrapper>

                  <FieldWrapper>
                    <LabelFiled>Possession</LabelFiled>
                    <Select
                      placeholder="Client"
                      label="Possesion"
                      size="large"
                      name="possession"
                      className="w-100"
                      onChange={(value) => {
                        setValues((pre) => ({
                          ...pre,
                          possession: value,
                        }));
                        setPossession(value);
                      }}
                      value={values.possession}
                      options={[
                        { value: "client", label: "Client" },
                        { value: "staff", label: "Staff" },
                        { value: "office", label: "At Office" },
                      ]}
                    />
                    {errors.possession && touched.possession ? <div className="error-message">{errors.possession}</div> : null}
                  </FieldWrapper>
                  {values.possession === "staff" && (
                    <FieldWrapper>
                      <SelectField
                        placeholder="Staff Name"
                        label="Staff Name"
                        size="large"
                        name="staff"
                        onChange={(value) => {
                          setValues((pre) => ({
                            ...pre,
                            staff: value,
                          }));
                        }}
                        value={values.staff_id}
                        options={staffList.map((item, index) => ({
                          value: item.id,
                          label: <span key={index}>{item.name}</span>,
                        }))}
                      />
                    </FieldWrapper>
                  )}
                  <FieldWrapper>
                    <RadioField
                      placeholder="Client"
                      label="Type of Key"
                      size="large"
                      name="type"
                      onChange={handleChange}
                      value={values.type}
                      classes="d-flex flex-column gap-2"
                      options={[
                        { value: "physical", label: "Physical" },
                        { value: "digital", label: "Digital" },
                      ]}
                    />
                    {errors.type && touched.type ? <div className="error-message">{errors.type}</div> : null}
                  </FieldWrapper>
                  {values.type === "digital" && (
                    <FieldWrapper>
                      <LabelFiled>Code</LabelFiled>
                      <Input
                        placeholder="code"
                        label="Code"
                        size="large"
                        name="code"
                        onChange={handleChange}
                        value={values.code}
                        rules={[
                          {
                            min: 3,
                            max: 50,
                            message: "Please select a value",
                          },
                        ]}
                      />
                    </FieldWrapper>
                  )}
                </FormWrapper>

                <Divider />
                <div className="d-flex justify-content-end">
                  <CustomBtnWithIcon
                    btnText="Cancel"
                    margin="0px 5px"
                    noBackground
                    hideIcon
                    onClick={() => setOpenKeyModal(false)}
                  />
                  <CustomBtnWithIcon btnText="Save Key" type="submit" hideIcon />
                </div>
              </Form>
            </>
          );
        }}
      </Formik>
    </CustomModal>
  );
};

const LabelFiled = styled.div`
  margin-bottom: 6px;
  color: rgb(59, 59, 59);
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
`;
const FieldWrapper = styled.div`
  width: 100%;
  margin-bottom: 15px;
`;

const FormWrapper = styled.div`
  // Styles for your form wrapper
`;

export default EditKeyModal;
