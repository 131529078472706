import React, { useEffect, useLayoutEffect, useState } from "react";
import OneDayHorizontalV2 from "./dam1";
import { useWindowWidth } from "@react-hook/window-size";

const ParentHorizontal = ({ eventsData, AllStates }) => {
  const widthHook = useWindowWidth();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const schedulerWidth = widthHook * 0.9;

  useEffect(() => {
    // Update window width state on resize
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function calculateEventPositions(data) {
    const hourSlotWidth = schedulerWidth / 24;

    const updatedEventsData = data.map((resource) => {
      // Sort events by start time to ensure proper rendering order
      resource.events.sort((a, b) => new Date(a.start_time) - new Date(b.start_time));

      // Initialize updatedEvents array to store the updated events
      const updatedEvents = [];

      resource.events.forEach((event, index) => {
        const eventStart = new Date(event.start_time);
        const eventEnd = new Date(event.end_time);
        const eventStartHour = eventStart.getHours();
        const eventStartMinute = eventStart.getMinutes();
        const eventEndHour = eventEnd.getHours();
        const eventEndMinute = eventEnd.getMinutes();
        const eventDurationInMinutes = (eventEndHour - eventStartHour) * 60 + (eventEndMinute - eventStartMinute);

        let calLeftPos = (eventStartHour * 60 + eventStartMinute) * (hourSlotWidth / 60);

        // Ensure events starting at the same time have the same left position
        for (let i = 0; i < index; i++) {
          const prevEvent = updatedEvents[i]; // Use updatedEvents to ensure leftPosition is set

          if (prevEvent.start_time === event.start_time) {
            calLeftPos = prevEvent.leftPosition;
            return;
          }

          if (new Date(prevEvent.end_time) > eventStart) {
            // Adjust left position to the end time of the previous event plus some offset
            calLeftPos = Math.max(calLeftPos, prevEvent.leftPosition + 3);
          }
        }

        let eventWidth = (eventDurationInMinutes / 60) * hourSlotWidth;

        updatedEvents.push({
          ...event,
          leftPosition: calLeftPos,
          eventWidth: eventWidth,
        });
      });

      return {
        ...resource,
        events: updatedEvents,
      };
    });

    return updatedEventsData;
  }

  const [updatedEventsData, setUpdatedEventsData] = useState(calculateEventPositions(eventsData));

  useLayoutEffect(() => {
    const updatedData = calculateEventPositions(eventsData);
    setUpdatedEventsData(updatedData);
  }, [eventsData, schedulerWidth, windowWidth]);

  return (
    <div>
      <OneDayHorizontalV2 realData={updatedEventsData} AllStates={AllStates} />
    </div>
  );
};

export default ParentHorizontal;
