import React, { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import styled, { css } from "styled-components";
import CreateIcon from "../assets/icons/CreateIcon";
import { useSelector } from "react-redux";
import { checkPermission } from "../util/commonUtil";
import PermissionModal from "./Modals/PermissionModal";
import { Badge } from "antd";

function NavItem({
  text,
  icon,
  to = "",
  pathsToMatch,
  hasExtras,
  isExtraHover,
  isHover,
  extraCallback,
  extras = [],
  fontFamily,
  unreadCounts,
}) {
  let navigate = useNavigate();
  const permissionManagement = useSelector((state) => state.permissionManagement);
  const [permissionPopUp, setPermissionPopUp] = useState(false);

  const { pathname } = useLocation();
  var dd = pathname.includes(to);
  const [is_active, setIsActive] = useState(pathname == to || pathname.includes(to));
  useEffect(() => {
    is_active && extraCallback(hasExtras);
  }, []);

  useEffect(() => {
    setIsActive(pathname == to || pathname?.includes(to));
  }, [pathname]);

  const isClientDetails = pathsToMatch?.some((path) => pathname.includes(path));

  const handleItemClick = () => {
    if (to === "/company/scheduler") {
      if (checkPermission(permissionManagement.permissions, "view_scheduler")) {
        navigate(to);
      } else {
        setPermissionPopUp(true);
      }
    } else if (to === "/company/payment") {
      if (checkPermission(permissionManagement.permissions, "view_payment")) {
        navigate(to);
      } else {
        setPermissionPopUp(true);
      }
    } else {
      navigate(to);
    }
  };

  return (
    <>
      <Container
        onClick={() => handleItemClick()}
        isExpanded={!isHover}
        active={pathname === to || isClientDetails}
        type="button"
      >
        {unreadCounts && (text === "Messages" || text === "Messaging") ? (
          <>
            <Badge dot={true}>
              <LogoContainer isHover={isHover}>
                <Logo active={is_active}>{icon}</Logo>
              </LogoContainer>
            </Badge>
          </>
        ) : (
          <LogoContainer isHover={isHover}>
            <Logo active={is_active}>{icon}</Logo>
          </LogoContainer>
        )}
        {isHover && (
          <>
            <LogoText fontFamily={fontFamily} active={pathname === to || isClientDetails}>
              {text}
            </LogoText>
            {hasExtras && (
              <ExtraContainer>
                <Logo active={is_active}>{CreateIcon}</Logo>
              </ExtraContainer>
            )}
          </>
        )}
      </Container>
      {hasExtras && is_active && isExtraHover && (
        <ExtraContent>
          {extras.map((e, i) => (
            <ExtraButton key={i} onClick={() => navigate(to + e.link)} active={pathname.replace(to, "") == e.link}>
              {e.name}
            </ExtraButton>
          ))}
        </ExtraContent>
      )}
      <PermissionModal onclose={() => setPermissionPopUp(false)} openModal={permissionPopUp} />
    </>
  );
}
const Container = styled.button`
  position: relative;
  width: ${(props) => (props.isExpanded ? "55px" : "200px")};
  padding: 10px 15px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  overflow: hidden;

  background: ${(props) => (props.active ? "#FFF6E8" : "none")};
  display: flex;
  align-items: baseline;

  &:hover {
    background: ${(props) => (props.active && !props.disabled ? "lightblue" : "none")};
  }

  ${(props) =>
    props.active &&
    css`
      &::before {
        content: "";
        position: absolute;
        top: 28%;
        left: 0;
        height: 16px;
        width: 5px;
        background-color: #fbcf34;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
      }
    `}
`;
const LogoContainer = styled.div`
  height: 24px;
  width: 24px;
  margin-right: 3px;
`;
const ExtraContainer = styled.div`
  height: 24px;
  width: 24px;
  margin-left: auto;
`;

const Logo = styled.svg`
  height: 100%;
  width: 100%;
  object-fit: contain;
  stroke: ${(props) => (props.active ? "#fff" : "#8B8698")};
  fill: transparent;
`;

const LogoText = styled.span`
  font-weight: ${(props) => (props.active ? 500 : 400)};
  text-wrap: nowrap;
  font-size: 0.875rem;
  text-align: left;
  color: ${(props) => (props.active ? "#000000" : "#424242")};
  font-family: ${(props) => props.fontFamily};
`;

const ExtraContent = styled.div`
  padding-top: 50px;
  width: 140px;
  height: 100%;
  position: absolute;
  left: 77px;
  // left: 100px;
  top: 75px;
  border-left: 1px solid #e4eaf0;
  padding-left: 18px;
`;
const ExtraButton = styled.button`
  width: 135px;
  height: 29px;
  background: ${(props) => (props.active ? "#FFF6E8" : "transparent")};
  border-radius: 5px;
  border: none;
  color: ${(props) => (props.active ? "#fff" : "#8B8698")};
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  margin-top: 10px;
  cursor: pointer;
  &:hover {
    background: lightblue;
  }
`;

export default NavItem;
