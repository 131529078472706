import dayjs from "dayjs";
import * as Yup from "yup";

// different days time valdation
const daySchema = Yup.object().shape({
  start_time: Yup.string().nullable().required("Start time is required"),
  end_time: Yup.string()
    .required("End time is required")
    .nullable()
    .test("is-greater", "End time must be greater than start time", function (end_time) {
      const start_time = this.parent.start_time;

      if (!start_time || !end_time) return true; // Ignore if either field is not provided
      return new Date(`01/01/2000 ${start_time}`) < new Date(`01/01/2000 ${end_time}`);
    })
    .test("is-not-equal", "Should be greater than start time", function (end_time) {
      const start_time = this.parent.start_time;
      if (!start_time || !end_time) return true; // Ignore if either field is not provided
      return start_time !== end_time;
    }),
});
const dayValidationSchema = Yup.object().shape({
  1: Yup.array().of(daySchema),
  2: Yup.array().of(daySchema),
  3: Yup.array().of(daySchema),
  4: Yup.array().of(daySchema),
  5: Yup.array().of(daySchema),
  6: Yup.array().of(daySchema),
  7: Yup.array().of(daySchema),
});
export const validationSchemaDiffDays = Yup.object().shape({
  selectedDays: Yup.array().min(1, "Please select at least one day").required("Please select at least one day"),
  days: Yup.object().when("selectedDays", {
    is: (selectedDays) => selectedDays.length > 0,
    then: dayValidationSchema,
    otherwise: Yup.object().shape({}), // No validation if no days are selected
  }),
  forever: Yup.boolean().required("Availability type is required"),
  start_date: Yup.date().when("forever", {
    is: false,
    then: Yup.date().required("Start date is required"),
    otherwise: Yup.date(),
  }),
  end_date: Yup.date().when("forever", {
    is: false,
    then: Yup.date().required("End date is required"),
    otherwise: Yup.date(),
  }),
});

// ===========================================================
// same day validation

export const validationSchemaSameDay = Yup.object().shape({
  selectedDays: Yup.array().min(1, "Please select at least one day"),
  days_time: Yup.array()
    .of(
      Yup.object().shape({
        start_time: Yup.string().nullable().required("Start time is required"),
        end_time: Yup.string()
          .required("End time is required")
          .nullable()
          .test("is-greater", "End time must be greater than start time", function (end_time) {
            const start_time = this.parent.start_time;

            if (!start_time || !end_time) return true; // Ignore if either field is not provided
            return new Date(`01/01/2000 ${start_time}`) < new Date(`01/01/2000 ${end_time}`);
          })
          .test("is-not-equal", "Should be greater than start time", function (end_time) {
            const start_time = this.parent.start_time;
            if (!start_time || !end_time) return true; // Ignore if either field is not provided
            return start_time !== end_time;
          }),
      }),
    )
    .min(1, "Please provide at least one time range"),
  forever: Yup.boolean().required("Availability type is required"),
  start_date: Yup.date().when("forever", {
    is: false,
    then: Yup.date().required("Start date is required"),
    otherwise: Yup.date(),
  }),
  end_date: Yup.date().when("forever", {
    is: false,
    then: Yup.date().required("End date is required"),
    otherwise: Yup.date(),
  }),
});

// time validation functions

export const validationSchemaSameDayv2 = Yup.object().shape({
  selectedDays: Yup.array().min(1, "Please select at least one day"),
  days_time: Yup.array()
    .of(
      Yup.object().shape({
        start_time: Yup.string().nullable().required("Start time is required"),
        end_time: Yup.string()
          .required("End time is required")
          .nullable()
          .test("is-greater", "End time must be greater than start time", function (end_time) {
            const start_time = this.parent.start_time;

            if (!start_time || !end_time) return true; // Ignore if either field is not provided
            return new Date(`01/01/2000 ${start_time}`) < new Date(`01/01/2000 ${end_time}`);
          })
          .test("is-not-equal", "Should be greater than start time", function (end_time) {
            const start_time = this.parent.start_time;
            if (!start_time || !end_time) return true; // Ignore if either field is not provided
            return start_time !== end_time;
          }),
      }),
    )
    .min(1, "Please provide at least one time range"),
  forever: Yup.boolean().required("Availability type is required"),
  start_date: Yup.date().when("forever", {
    is: false,
    then: Yup.date().required("Start date is required"),
    otherwise: Yup.date(),
  }),
  end_date: Yup.date().when("forever", {
    is: false,
    then: Yup.date().required("End date is required"),
    otherwise: Yup.date(),
  }),
});
