// import React from 'react';
// import './customPopup.css';
// import { ReactComponent as ClientInner } from "../../assets/rawSvgs/client_inner.svg";
// import { ReactComponent as StaffInner } from "../../assets/rawSvgs/staff_inner.svg";
// import { ReactComponent as InvoiceInner } from "../../assets/rawSvgs/invoice_inner.svg";
// import { ReactComponent as CalenderInner } from "../../assets/rawSvgs/calender_inner.svg";
// import { ReactComponent as ServiceInner } from "../../assets/rawSvgs/service_inner.svg";
// import { ReactComponent as LocationInner } from "../../assets/rawSvgs/locationInner.svg";
// import { Badge } from 'antd';
// import moment from 'moment';

// const CustomPopup = ({ data }) => {

//   if (data.address) {
//     return (
//       <div className="custom-popup">
//         <div className="popup-header">
//           <span className='popup-time-block' >{data?.stepType.charAt(0).toUpperCase() + data?.stepType.slice(1)}</span>
//           <span className='popup-time-block' style={{ marginLeft: "4px" }}>
//           {data?.arrival} 
//           </span>
//         </div>
//         <div className="popup-content">
//           <span className='d-flex' style={{flexDirection:"row"}}>
//           <LocationInner style={{marginRight:"4px"  }} />
//           <p style={{marginTop:"24px"  }}><strong>Address:</strong> {data?.address}</p>
//           </span>
//         </div>
        
//       </div>
//     );
//   }else{

//     const startTime = moment(data?.time_block?.start_time, 'HH:mm').format('h:mm A');
//   const endTime = moment(data?.time_block?.end_time, 'HH:mm').format('h:mm A');

//   return (
//     <div className="custom-popup">
//       <div className="popup-header">
//         <span className='popup-time-block' style={{ marginLeft: "4px" }}>
//         {startTime} - {endTime}
//         </span>
//         <span className="popup-status">{data?.status.charAt(0).toUpperCase() + data?.status.slice(1)}</span>
//       </div>
//       <div className="popup-content">
//         <span className='d-flex' style={{flexDirection:"row"}}>
//         <ServiceInner style={{marginRight:"4px"}}/>
//         <p><strong>Service:</strong> {data?.service}</p>
//         </span>
//         <span className='d-flex' style={{flexDirection:"row"}}>
//         <CalenderInner style={{marginRight:"4px"}}/>
//         <p><strong>When:</strong> {data?.when}</p>
//         </span>
//         <span className='d-flex' style={{flexDirection:"row"}}>
//         <StaffInner style={{marginRight:"4px"}}/>
//         <p><strong>Staff:</strong> {data?.staff}</p>
//         </span>
//         <span className='d-flex' style={{flexDirection:"row"}}>
//         <CalenderInner style={{marginRight:"4px"}}/>
//         <p><strong>Client:</strong> {data?.client}</p>
//         </span>
//       </div>
      
//     </div>
//   );
//   }
// };

// export default CustomPopup;



// new logic


import React from 'react';
import './customPopup.css';
import { ReactComponent as ClientInner } from "../../assets/rawSvgs/client_inner.svg";
import { ReactComponent as StaffInner } from "../../assets/rawSvgs/staff_inner.svg";
import { ReactComponent as InvoiceInner } from "../../assets/rawSvgs/invoice_inner.svg";
import { ReactComponent as CalenderInner } from "../../assets/rawSvgs/calender_inner.svg";
import { ReactComponent as ServiceInner } from "../../assets/rawSvgs/service_inner.svg";
import { ReactComponent as LocationInner } from "../../assets/rawSvgs/locationInner.svg";
import { Badge } from 'antd';
import moment from 'moment';

const CustomPopup = ({ data }) => {
  return (
    <div className="custom-popup">
      {data?.map((item, index) => {
        if (item.address) {
          return (
            <div key={index} style={{marginBottom:"10px"}}>
              <div className="popup-header" >
                <span className='popup-time-block'>
                  {item?.stepType === "pickup" ? "Key" : item?.stepType.charAt(0).toUpperCase() + item?.stepType.slice(1)}
                </span>
                <span className='popup-time-block' style={{ marginLeft: "4px" }}>
                  {item?.arrival}
                </span>
              </div>
              <div className="popup-content" style={{marginTop:"-18px"}}>
                <span className='d-flex' style={{ flexDirection: "row" }}>
                  <LocationInner style={{ marginRight: "4px" }} />
                  <p style={{ marginTop: "24px" }}>
                    <strong>Address:</strong> {item?.address}
                  </p>
                </span>
              </div>
            </div>
          );
        } else {
          const startTime = moment(item?.time_block?.start_time, 'HH:mm').format('h:mm A');
          const endTime = moment(item?.time_block?.end_time, 'HH:mm').format('h:mm A');

          return (
            <div key={index} style={{marginBottom:"10px"}}>
              <div className="popup-header">
                <span className='popup-time-block' style={{ marginLeft: "4px" }}>
                  {startTime} - {endTime}
                </span>
                <span className="popup-status">
                  {item?.status.charAt(0).toUpperCase() + item?.status.slice(1)}
                </span>
              </div>
              <div className="popup-content">
                <span className='d-flex' style={{ flexDirection: "row" }}>
                  <ServiceInner style={{ marginRight: "4px" }} />
                  <p><strong>Service:</strong> {item?.service}</p>
                </span>
                <span className='d-flex' style={{ flexDirection: "row" }}>
                  <CalenderInner style={{ marginRight: "4px" }} />
                  <p><strong>When:</strong> {item?.when}</p>
                </span>
                <span className='d-flex' style={{ flexDirection: "row" }}>
                  <StaffInner style={{ marginRight: "4px" }} />
                  <p><strong>Staff:</strong> {item?.staff}</p>
                </span>
                <span className='d-flex' style={{ flexDirection: "row" }}>
                  <ClientInner style={{ marginRight: "4px" }} />
                  <p><strong>Client:</strong> {item?.client}</p>
                </span>
              </div>
            </div>
          );
        }
      })}
    </div>
  );
};

export default CustomPopup;
