import React, { useState } from "react";
import { Select, Checkbox } from "antd";

const { Option } = Select;
const MultiSelectDropdown = ({
  options = [],
  placeholder = "Select",
  onChange,
  selectedItems = [],
  name
}) => {

  return (
    <Select name={name} mode="multiple" placeholder={placeholder} value={selectedItems} onChange={onChange} style={{ width: "100%" }}>
      {options.map((option) => (
        <Option key={option.value} value={option.value}>
          <Checkbox checked={selectedItems.includes(option.value)}>{option.label}</Checkbox>
        </Option>
      ))}
    </Select>
  );
};

export default MultiSelectDropdown;
