import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import ConversationScreen from "./Index";
import { usePubNub } from "pubnub-react";
import { useUserMemberships } from "@pubnub/react-chat-components";
import { generateUUID } from "pubnub";
import SearchInput from "../SearchInput";
import { TabsDiv } from "./styles";
import { Badge, Segmented } from "antd";
import { PinnedMessages, UploadFileModal } from "../../pages/company/messages/Modals";
import CustomBtnWithIcon from "../buttonWithIcon";
import HeadingComponent from "../Heading";
import { ReactComponent as PinMsg } from "../../assets/rawSvgs/pinMsg.svg";
import { checkUnreadCountSendSignal } from "../../pages/company/messages/utilsFunctions";
import { useSelector } from "react-redux";
//
const AdminPortalChat = ({
  adminId,
  adminName,
  onlyCompId,
  isAdminOrUser,
  clientId,
  clientPortalChatHeight,
  fetchChannelsToCheckUnreadCountCleint,
}) => {
  const [messages, setMessages] = useState([]);
  const [inputFieldText, setInputFieldText] = useState("");
  const [uuidMetaData, setUiidMetaData] = useState({});
  const [SelectTab, setSelectTab] = useState("General Messages");
  const [currentChannel, setCurrentChannel] = useState();
  const [fetchMsgLoader, setMsgLoader] = useState(false);
  const [openUploadFileModal, setOpenFileModal] = useState(false);
  const [openEmoji, setOpenEmoji] = useState(false);
  const [pinnedMessagesModal, setPinnedMessagesModal] = useState(false);
  const [userScrolled, setUserScrolled] = useState(false);
  const [allUsers, setAllUsers] = useState([]);
  // HOOKS

  const pickerRef = useRef(null);
  const messagesEndRef = useRef(null);
  const messagesContainerRef = useRef(null);
  const pubnub = usePubNub();
  const uuid = pubnub.getUUID();

  const [joinedChannels, , refetchJoinedChannels] = useUserMemberships({
    include: { channelFields: true, customChannelFields: true },
    filter: `channel.id LIKE 'chat.*' && channel.custom.company_id==${onlyCompId}`,
  });
  const allUsersFilter = allUsers.filter((users) => users.custom?.company_id === onlyCompId);
  let GeneralMessages = "_staff";
  let matchClientId = `client_${clientId}`;

  let notificationchannelName = "notification_signal";

  const filterJoinedChannels = useMemo(() => {
    return (
      joinedChannels?.filter((items) => {
        return items.id.includes(GeneralMessages) && items.id.includes(matchClientId);
      })[0] || {}
    );
  }, [joinedChannels, GeneralMessages, matchClientId]);

  const filterPrivateChannel = useMemo(() => {
    return (
      joinedChannels?.filter((items) => {
        return !items.id.includes(GeneralMessages) && items.id.includes(matchClientId);
      })[0] || {}
    );
  }, [joinedChannels, GeneralMessages, matchClientId]);

  const handleMessage = useCallback((msgEvent) => {
    setMessages((prevMessages) => {
      if (!prevMessages.some((msg) => msg.timetoken === msgEvent.timetoken)) {
        return [...prevMessages, msgEvent];
      }
      return prevMessages;
    });
  }, []);

  useEffect(() => {
    if (SelectTab === "General Messages") {
      setCurrentChannel(filterJoinedChannels?.id);
    } else if (SelectTab === "Private Messages") {
      setCurrentChannel(filterPrivateChannel?.id);
    }
  }, [SelectTab]);

  useEffect(() => {
    setCurrentChannel(filterJoinedChannels?.id);
  }, [filterJoinedChannels]);
  useEffect(() => {
    fetchAllUsers();
    fetchUuidMetaData();
    handleCreateNotificatoinchannel();
  }, []);

  // ADD EVENT LISTNER

  useEffect(() => {
    let channelsArraySub = [currentChannel, "notification_signal"];
    // if (!channelsArraySub[0]) return;

    pubnub.subscribe({ channels: [...channelsArraySub], withPresence: true });

    pubnub.addListener({
      message: handleMessage,
      messageAction: (event) => {
        handleFetchMessages(event.channel);
      },
    });

    return () => {
      // pubnub.unsubscribe({ channels: [currentChannel] });
      pubnub.unsubscribe({ channels: [currentChannel] });

      pubnub.removeListener({ message: handleMessage, messageAction: handleFetchMessages, signal: handleFetchMessages });

      setMessages([]);
      setInputFieldText("");
    };
  }, [pubnub, currentChannel, handleMessage]);

  useEffect(() => {
    const handleSignal = async (signal) => {
      if (signal.message.eventType === "fileSent") {
        handleFetchMessages(signal.channel);
      }
      if (signal.message.eventType === "channel_create") {
        refetchJoinedChannels();
      }
      if (signal.channel === notificationchannelName) {
        fetchChannelsToCheckUnreadCountCleint();
      }
    };

    pubnub.addListener({
      signal: handleSignal,
    });

    return () => {
      pubnub.removeListener({ signal: handleSignal });
    };
  }, [pubnub, joinedChannels]);

  // ===========================
  useEffect(() => {
    handleFetchMessages(currentChannel);

    return () => {
      setMessages([]);
    };
  }, [currentChannel]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (pickerRef.current && !pickerRef.current.contains(event.target)) {
        setOpenEmoji(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    if (!userScrolled) {
      scrollToBottom();
    }
  }, [currentChannel, messages, userScrolled]);

  // EFFFECT END

  const handleCreateNotificatoinchannel = async () => {
    // const membersResponse = await pubnub.objects.getChannelMembers({
    //   channel: notificationchannelName,
    //   include: {
    //     custom: true,
    //     customFields: true,
    //   },
    // });
    // console.log(
    //   "MEMBERS CHANENl",
    //   membersResponse?.data?.filter((items) => items.custom.company_id === onlyCompId),
    // );
    pubnub.objects.getChannelMetadata(
      {
        channel: notificationchannelName,
      },
      async (status, response) => {
        if (!status.error) {
          pubnub.objects.setChannelMembers({ channel: notificationchannelName, uuids: [...allUsersFilter, adminId] });
        }
        if (status.error) {
          await pubnub.objects.setChannelMetadata({
            channel: notificationchannelName,
            data: {
              name: adminName,
              description: "this is for notification only",
              custom: {
                company_id: onlyCompId,
                owner: adminId,
                [adminId]: adminName,
                notification_channel: true,
              },
            },
          });

          pubnub.objects.setChannelMembers({ channel: notificationchannelName, uuids: [...allUsersFilter, adminId] });
        }
      },
    );
  };
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const fetchAllUsers = async () => {
    try {
      const response = await pubnub.objects.getAllUUIDMetadata({
        include: {
          custom: true,
          customFields: true,
        },
      });

      setAllUsers(response?.data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleFetchMessages = (inComChan) => {
    if (inComChan) {
      pubnub.fetchMessages(
        {
          channels: [inComChan],
          includeMessageActions: true,
        },
        (status, response) => {
          if (response) {
            let keys = Object.keys(response.channels);
            let indexFirst = keys[0];
            const fetchedMessages = response?.channels[indexFirst];

            // setMessages((prevMessages) => [...prevMessages, ...fetchedMessages]);

            if (fetchedMessages?.length > 0) {
              setMessages((prevMessages) => {
                // Create a Map to store unique messages based on their timetoken
                const uniqueMessages = new Map(prevMessages?.map((existingMsg) => [existingMsg.timetoken, existingMsg]));

                // Add new messages to the uniqueMessages Map, overwriting any existing ones with the same timetoken
                fetchedMessages.forEach((msg) => {
                  uniqueMessages.set(msg.timetoken, msg);
                });

                // Convert the Map back to an array of messages
                const updatedMessages = Array.from(uniqueMessages.values());

                return updatedMessages;
              });
            }
          }
          setMsgLoader(false);
        },
      );
    }
  };

  const handleSendMessage = () => {
    if (inputFieldText === "" || !inputFieldText) return;
    const message = {
      text: inputFieldText,

      user: {
        _id: adminId,
        name: uuidMetaData?.name,
        type: uuidMetaData?.custom?.role || "admin",
        messageType: SelectTab === "General Messages" ? "general_message" : "private_Message",
      },
      createdAt: new Date(),
      _id: generateUUID(),
    };

    // pubnub.objects.setChannelMembers({ channel: currentChannel, uuids: [`staff_${chatterId}`, `company_id_${onlyCompId}`] });
    pubnub.publish(
      {
        channel: currentChannel,
        message: message,
      },
      function (status, response) {
        setInputFieldText("");
        if (!status.error) {
          checkUnreadCountSendSignal(pubnub, notificationchannelName, currentChannel);
        }

        handleGroupUnreadCount(currentChannel, false, true);
      },
    );
  };
  const fetchUuidMetaData = () => {
    try {
      pubnub.objects.getUUIDMetadata(
        {
          uuid: uuid,
          include: {
            custom: true,
            customFields: true,
            channelFields: true,
          },
        },
        (status, response) => {
          if (status.error) {
          } else {
            setUiidMetaData(response.data);
          }
        },
      );
    } catch (error) {
      console.log("Error:", error);
    }
  };
  const handleEmojiSelect = (emoji) => {
    setInputFieldText((prevValue) => prevValue + emoji.native);
    // setOpenEmoji(false);
  };
  function filterPinMessages(messages) {
    const pinMessages = [];

    messages.forEach((message) => {
      if (message.actions && message.actions.pin) {
        pinMessages.push(message);
      }
    });

    return pinMessages;
  }
  const pinMessages = filterPinMessages(messages);
  //

  const addReactionToMessage = async (emoji, timeToken, actionToken, condition, timeTokexn) => {
    // Retrieve the message with the specified timetoken from the channel's history
    try {
      let actionTimetoken = null;
      let existingReaction = false;

      // Check if the admin's reaction already exists
      if (timeTokexn.actions && timeTokexn.actions.reaction && timeTokexn.actions.reaction[emoji]) {
        const reactions = timeTokexn.actions.reaction[emoji];
        console.log("REACT", { reactions, adminId });
        reactions.forEach((reaction) => {
          if (reaction.uuid === adminId) {
            actionTimetoken = reaction.actionTimetoken;
            existingReaction = true;
          }
        });
      }

      if (existingReaction) {
        pubnub.removeMessageAction(
          {
            channel: currentChannel,
            messageTimetoken: timeToken,
            actionTimetoken: actionToken,
          },
          function (status, response) {
            // handleFetchMessages();
          },
        );
      }

      pubnub.addMessageAction(
        {
          channel: currentChannel,
          messageTimetoken: timeToken,
          action: {
            type: "reaction",
            value: emoji,
          },
        },
        async (status, response) => {
          console.log("response", response, status);
          // handleFetchMessages();
        },
      );
      return;
    } catch (error) {
      console.log("single error", error);
    }
  };

  const handlePinnedMasg = async (incomeMsg) => {
    try {
      const currentUserId = pubnub.getUUID(); // Assuming pubnub.uuid refers to the current user's UUID
      let alreadyPinned = false;
      let actionTimetoken;

      // Check for pin actions and determine if the message is already pinned
      if (incomeMsg.actions && incomeMsg.actions.pin) {
        const pinActions = incomeMsg.actions.pin;
        for (const adminName in pinActions) {
          const actionsArray = pinActions[adminName];
          for (const action of actionsArray) {
            if (action.uuid === currentUserId) {
              alreadyPinned = true;
              actionTimetoken = action.actionTimetoken;
              break;
            }
          }
          if (alreadyPinned) break; // Exit outer loop if already pinned
        }
      }

      if (alreadyPinned) {
        // If the message is already pinned, remove the pin action
        pubnub.removeMessageAction(
          {
            channel: currentChannel,
            messageTimetoken: incomeMsg.timetoken,
            actionTimetoken: actionTimetoken,
          },
          (status, response) => {},
        );
      } else {
        // If the message is not pinned, add a pin action
        pubnub.addMessageAction(
          {
            channel: currentChannel,
            messageTimetoken: incomeMsg.timetoken,
            action: {
              type: "pin",
              value: uuidMetaData?.name || adminName, // Or adminName, depending on your application logic
            },
          },
          (status, response) => {},
        );
      }
    } catch (error) {}
  };

  const handleDeleteMessage = (dataIncoming) => {
    pubnub.deleteMessages(
      {
        channel: dataIncoming.channel,
        start: dataIncoming.timetoken + 1,
        end: dataIncoming.timetoken,
      },
      (status, response) => {
        console.log("Message deleted successfully", status);

        if (status.error) {
          console.log("Error deleting message:", status);
        } else {
          // SIGNAL SEND
          pubnub.signal({
            channel: dataIncoming.channel,
            message: {
              eventType: "delete_message", // Custom event type
            },
            callback: function (signalStatus) {
              console.error("Error sending signal:", signalStatus);
              if (signalStatus.error) {
              } else {
                console.log("Signal sent successfully:", signalStatus);
              }
            },
          });

          console.log("Message deleted successfully111", response);
        }
      },
    );
  };

  const handleGroupUnreadCount = async (_, fromInputField, fromSendMessageFunction) => {
    try {
      const membersResponse = await pubnub.objects.getChannelMembers({
        channel: currentChannel,
        include: {
          custom: true,
          customFields: true,
        },
      });

      let listOfUsers = [];

      membersResponse?.data?.forEach((elem) => {
        allUsersFilter.forEach((nestElem) => {
          if (elem.uuid.id === nestElem.id) {
            listOfUsers.push({ ...nestElem });
          }
        });
      });

      pubnub.objects.getChannelMetadata(
        {
          channel: currentChannel,
          include: {
            customFields: true,
          },
        },
        async (status, response) => {
          if (!status.error) {
            const customData = response?.data;

            let unreadCountData = customData.custom?.unreadCountData
              ? JSON.parse(customData.custom.unreadCountData)
              : { recievers: {} };

            if (fromSendMessageFunction) {
              listOfUsers?.forEach((member) => {
                const memberId = member.id;

                if (memberId !== uuid) {
                  // Ensure the sender's own count is not incremented
                  if (unreadCountData.recievers[memberId]) {
                    unreadCountData.recievers[memberId].unreadCount += 1;
                  } else {
                    unreadCountData.recievers[memberId] = {
                      recieverId: memberId,
                      unreadCount: 1,
                      senderId: uuid,
                    };
                  }

                  console.log(
                    `Unread count for receiver ${memberId} updated to ${unreadCountData.recievers[memberId].unreadCount}`,
                  );
                }
              });
            }

            if (fromInputField) {
              listOfUsers.forEach((member) => {
                const memberId = member.id;

                if (unreadCountData.recievers[memberId] && memberId === uuid) {
                  unreadCountData.recievers[uuid].unreadCount = 0;
                }
              });
            }

            // unreadCountData = { recievers: unreadCountData };

            const customObj = {
              name: customData.name,
              description: "This is a group channel for discussion.",
              custom: {
                ...customData.custom,
                unreadCountData: JSON.stringify(unreadCountData),
              },
            };
            await pubnub.objects.setChannelMetadata({
              channel: currentChannel,
              data: customObj,
            });
            // console.log("submit chanel meta", unreadCountData);
          }
        },
      );
    } catch (error) {
      console.error("Error updating group unread count:", error);
    }
  };

  const checkUnreadCounts = (channels) => {
    let privateUnreadCount = 0;
    let generalUnreadCount = 0;

    channels.forEach((channel) => {
      try {
        const { unreadCountData } = channel?.custom || {};

        if (!unreadCountData) {
          return; // Skip if unreadCountData is not valid JSON
        }
        const parsedData = JSON.parse(unreadCountData) || {};
        if (parsedData.recievers[uuid]?.unreadCount === 0) return;
        if (!parsedData) {
          return; // Skip if unreadCountData is not valid JSON
        }

        if (parsedData.recievers && parsedData.recievers[uuid]) {
          const { unreadCount } = parsedData.recievers[uuid];

          if (channel.id.includes("_staff")) {
            generalUnreadCount += unreadCount;
          } else {
            privateUnreadCount += unreadCount;
          }
        }
      } catch (error) {
        console.error(`Error parsing unreadCountData for channel: ${error}`);
      }
    });

    return {
      privateUnreadCount,
      generalUnreadCount,
    };
  };

  const checkUnreadOfChannels = checkUnreadCounts([filterJoinedChannels, filterPrivateChannel]);
  // console.log("object PROPSR", { joinedChannels, checkUnreadOfChannels, uuid });
  //   adminId,
  //   adminName,
  //   onlyCompId,
  //   isAdminOrUser,
  //   clientId,
  //   currentChannel,
  //   joinedChannels,
  //   filterPrivateChannel,
  //   clientPortalChatHeight,
  //   uuidMetaData,
  // });
  let convoObj = {
    onlyCompId,
    currentChannel,
    uuidMetaData,
    setInputFieldText,
    handleSendMessage,
    messages,
    inputFieldText,
    fetchMsgLoader,
    setOpenFileModal: () => setOpenFileModal(true),
    openEmoji,
    setOpenEmoji,
    pickerRef,
    handleEmojiSelect,
    pubnub,
    adminId,
    clientPortalChatHeight,
    addReactionToMessage,
    handlePinnedMasg: (item) => handlePinnedMasg(item),
    // emojiClick: (emoji, condition, item) => {
    //   if (condition && item?.actions?.reaction?.[emoji][0]?.actionTimetoken) {
    //     addReactionToMessage(
    //       emoji,
    //       item?.timetoken,
    //       item?.actions?.reaction?.[emoji][0]?.actionTimetoken || undefined,
    //       condition,
    //     );
    //   } else {
    //     addReactionToMessage(emoji, item?.timetoken, undefined, condition);
    //   }
    // },
    messagesContainerRef,
    setUserScrolled,
    userScrolled,
    scrollToBottom,
    messagesEndRef,
    handleDeleteMessage: (channel, messageTimetoken) => handleDeleteMessage(channel, messageTimetoken),

    fromClientChatUnreadFunction: () => {
      handleGroupUnreadCount(currentChannel, true, false);
      return;
    },
  };

  const optionsSegmeent = [
    {
      label: (
        <span>
          General Messages
          {checkUnreadOfChannels.generalUnreadCount > 0 && <Badge dot={true} style={{ marginLeft: 8, backgroundColor: "red" }} />}
        </span>
      ),
      value: "General Messages",
    },
    {
      label: (
        <span>
          Private Messages
          {checkUnreadOfChannels.privateUnreadCount > 0 && <Badge dot={true} style={{ marginLeft: 8, backgroundColor: "red" }} />}
        </span>
      ),
      value: "Private Messages",
    },
  ];

  return (
    <div>
      <div className={`d-flex align-items-center ${clientPortalChatHeight && "ps-3"}`}>
        <SearchInput
          placeholder="Type to search.."
          // search={search}
          // onChange={(value) => setSearch(value)}
          // onBlur={searchBlurHandler}
        />
        <TabsDiv>
          <Segmented
            style={{ marginBottom: 8, background: "rgb(238,238,238,1)", minWidth: "400px" }}
            onChange={(value) => {
              setMsgLoader(true);
              setSelectTab(value);
            }}
            options={optionsSegmeent?.map((opt) => ({ label: opt.label, value: opt.value }))}
            className="p-1 ms-3 mt-2"
            defaultValue={SelectTab}
            block
            size="large"
          />
          <CustomBtnWithIcon
            btnText={
              <div className="d-flex ">
                <PinMsg />
                <HeadingComponent
                  text={`${pinMessages?.length} Pinned messages`}
                  fontSize="14px"
                  fontWeight={500}
                  color
                  margin={"0px 5px"}
                />
              </div>
            }
            hideIcon
            noBackground
            width="200px"
            margin="10px 5px"
            onClick={() => setPinnedMessagesModal(true)}
          />
        </TabsDiv>
        {/* <AntDesignDropDownSelect className="mx-3" defaultValue={[]} options={statusArray} width="150px" /> */}
      </div>
      <div className={`${clientPortalChatHeight && "px-0"}`}>
        <ConversationScreen {...convoObj} />
      </div>

      {openUploadFileModal && (
        <UploadFileModal
          openUploadFileModal={openUploadFileModal}
          setOpenFileModal={setOpenFileModal}
          userId={adminId}
          name={adminName}
          channel={currentChannel}
          uuidMetaData={uuidMetaData}
          onSuccess={() => {
            setTimeout(() => {
              console.log("IM TRIGGERD");
              handleFetchMessages(currentChannel);
            }, 1000);
            setOpenFileModal(false);
          }}
        />
      )}
      {pinnedMessagesModal && (
        <PinnedMessages
          pinnedMessagesModal={pinnedMessagesModal}
          setPinnedMessagesModal={setPinnedMessagesModal}
          pinMessages={pinMessages}
          fromPinnedModal={true}
          emojiClick={(emoji, cond, items) => {
            if (cond && items?.actions?.reaction?.[emoji][0]?.actionTimetoken) {
              addReactionToMessage(
                emoji,
                items?.timetoken,
                items?.actions?.reaction?.[emoji][0]?.actionTimetoken || undefined,
                cond,
                items,
              );
            } else {
              addReactionToMessage(emoji, items?.timetoken, undefined, cond, items);
            }
          }}
          {...convoObj}
        />
      )}
    </div>
  );
};

export default AdminPortalChat;
