import HeaderNav from "../../components/HeaderNav";

import { connect } from "react-redux";

const mapStateToProps = (state) => {
  return {
    generalAppStae: state.generalApp,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderNav);
