import React from "react";
import { Checkbox, DatePicker, Divider, Flex, Form, Input, message, Modal, Select, Switch, Typography, Upload } from "antd";
import { ReactComponent as DefaultImageIcon } from "../../../assets/rawSvgs/defaultImage.svg";
import CustomInput from "../../CustomInput";
import styled from "styled-components";
import CustomBtnWithIcon from "../../buttonWithIcon";
const { Dragger } = Upload;

const UpdateUserModal = ({ isLoading, isModalOpen, title, setModalOpen, onFinish, companyList, roles, editUserValues }) => {
  const [form] = Form.useForm();
  editUserValues &&
    form.setFieldsValue({
      name: editUserValues?.name,
      email: editUserValues?.email,
      phone_number: editUserValues?.phone_number,
      company: editUserValues?.company,
      role: editUserValues?.role,
      // is_flagged: editUserValues?.is_flagged,
    });

  return (
    <CustomModal
      open={isModalOpen}
      title={title}
      width="600px"
      heigth="614px"
      onCancel={() => {
        setModalOpen(false);
        form.resetFields();
      }}
      footer={null}
      maskClosable={false}
    >
      <Divider />
      <Form name="addUserForm" onFinish={onFinish} form={form} layout="vertical">
        <FormItem
          name="name"
          label="Full Name"
          rules={[
            {
              required: true,
              message: "Name is required",
            },
            {
              max: 50,
              min: 1,
            },
          ]}
        >
          <Input placeholder="Enter full name" required />
        </FormItem>
        <FormItem
          name="phone_number"
          label="Phone Number"
          rules={[
            {
              message: "Phone Number is required",
            },
            {
              max: 25,
            },
          ]}
        >
          <Input placeholder="Enter phone number" type="number" />
        </FormItem>
        <FormItem
          name="email"
          label="Email"
          rules={[
            {
              required: true,
              message: "Email is required",
            },
            {
              type: "email",
              message: "Please enter a valid email address",
            },
          ]}
        >
          <Input disabled={editUserValues ? true : false} placeholder="Enter email address" />
        </FormItem>
        <FormItem name="company" label="Company">
          <Select disabled={editUserValues ? true : false} placeholder="Select company" options={companyList} />
        </FormItem>
        <FormItem name="role" label="Assign Role">
          <Select disabled={editUserValues ? true : false} placeholder="Select role" options={roles} />
        </FormItem>
        <Divider />
        {/* <FormItem
          className="mark-active-switch"
          name="is_flagged"
          label="Flag User?"
          initialValue={true}
          valuePropName="is_flagged"
        >
          <Switch size="small" />
        </FormItem> */}
        <div className="d-flex justify-content-end">
          <CustomBtnWithIcon
            btnText={"Cancel"}
            margin="0px 5px"
            noBackground
            hideIcon={true}
            onClick={() => setModalOpen(false)}
          />
          <CustomBtnWithIcon btnText={"Save User"} type="submit" hideIcon={true} />
        </div>
      </Form>
    </CustomModal>
  );
};

const FieldWraper = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
`;

const FormItem = styled(Form.Item)`
  width: 100%;
`;

const CustomModal = styled(Modal)`
  .ant-modal-header .ant-modal-title {
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
  }
  .ant-form-item .ant-form-item-label .ant-form-item-required {
    font-size: 12px;
    font-weight: 700;
    color: #383838;
  }
  .ant-form-vertical .ant-form-item-label {
    padding: 0 0 2px;
  }
  .ant-form-item .ant-form-item-label label {
    font-weight: 700;
    color: #212121;
  }
  .welcome-message-checkbox .ant-form-item-row {
    flex-direction: row;
    gap: 10px;
    align-items: baseline;
  }
  .welcome-message-checkbox .ant-form-item-control {
    width: auto;
    order: -1;
    flex: 0;
  }
  .mark-active-switch .ant-form-item-row {
    flex-direction: row;
    align-items: baseline;
  }
  .mark-active-switch .ant-form-item-control {
    width: auto;
  }
`;

export default UpdateUserModal;
