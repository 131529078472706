export const companyAPIsEndPoints = {
  // forgot password End points
  COMPANY_LOGIN: "/api/v1/login/",
  COMPANY_RESET_PASSWORD: "/api/v1/reset-password/",
  PET_LIST: "/api/v1/client_pet/?client__usercompanyrole__company=" + localStorage.getItem("company"),
  PET_LIST_FILTER: (search, date, cid = "") => {
    const params = new URLSearchParams();

    if (localStorage.getItem("company")) params.append("client__usercompanyrole__company", localStorage.getItem("company"));
    if (cid) params.append("cid", cid);
    if (search) params.append("search", search);
    if (date && date[0]) {
      params.append("start_date", date[0]);
      params.append("end_date", date[1]);
    }

    const queryString = params.toString();
    return `/api/v1/client_pet/${queryString ? `?${queryString}` : ""}`;
  },
  FETCH_PET_FOR_CLIENT: (id) => `/api/v1/client_pet/?cid=${id}`,
  FETCH_KEYS_FOR_CLIENT: (id) => `/api/v1/keys/?client=${id}`,
  FETCH_ALL_COPIES_OF_MASTER_KEY: (queryFilter) => `/api/v1/keys/list_copies_of_master_key/?${queryFilter}`,
  FORGOT_PASSWORD: "/api/v1/forget-password/",
  fetchCompanyUSer: "/api/v1/abc",

  NOTIFICATION: (queryFilter) => `/api/v1/notification/?${queryFilter}`,
  UNREAD_NOTIFICATION: (queryFilter) => `/api/v1/notification/un_read_count/?${queryFilter}`,
  UPDATE_NOTIFICATION: (id) => `/api/v1/notification/${id}/`,

  FETCH_SINGLE_COMPANY: (id) => `/api/v1/company/${id}/`,
  UPDATE_SUBSCRIPTION: "/api/v1/stripe/subscription/",
  UPDATE_SINGLE_COMPANY: (id) => `/api/v1/company/${id}/`,
  COMPANY_USER: "/api/v1/company_user/",
  LIST_COMPANY_USER: (queryFilter) => `/api/v1/company_user/?${queryFilter}`,
  SINGLE_COMPANY_USER: (id) => `/api/v1/company_user/${id}/`,

  USER_ONBOARDING: "api/v1/user_onboarding/",
  USER_ONBOARDING_STATUS: "api/v1/user_onboarding/status/",
  ONBOARDING: (queryFilter) => `api/v1/onboarding/?${queryFilter}`,
  ONBOARDING_STATUS: (compId) => `/api/v1/onboarding/status/?company_id=${compId}`,
  UPDATE_ONBOARDING_STATUS: "api/v1/onboarding/update_status/",

  //Notifications End Points
  FECTH_NOTIFICATIONS: "/api/v1/notification/",
  POST_NOTIFICATIONS: "/api/v1/notification/",
  //Notification Types End Points
  FECTH_NOTIFICATION_TYPES: "/api/v1/notification_type/",
  POST_NOTIFICATION_TYPES: "/api/v1/notification_type/",
  //User Notification Settings End Points
  FECTH_USER_NOTIFICATION_SETTINGS: (id) => `/api/v1/notification_setting/?staff_id=${id}`,
  POST_USER_NOTIFICATION_SETTINGS: "/api/v1/notification_setting/",
  UPDATE_USER_NOTIFICATION_SETTINGS: (id) => `/api/v1/notification_setting/${id}/`,
  //Company Notification Settings End Points
  // FECTH_CUSTOM_NOTIFICATION_SETTINGS: (id) => `/api/v1/custom_notification_setting/?company=${id}`,
  FECTH_CUSTOM_NOTIFICATION_SETTINGS: `/api/v1/custom_notification_setting/company/`,
  UPDATE_CUSTOM_NOTIFICATION_SETTINGS: "/api/v1/custom_notification_setting/update-by-company/",

  //clients End points
  FETCH_SINGLE_CLIENT: (id) => `/api/v1/client/${id}/`,
  FETCH_MANAGEMENT_CLIENTS: (compId) => `/api/v1/client/?usercompanyrole__company=${compId}`,
  FETCH_MANAGEMENT_CLIENTS_PAGINATION: (compId, page) =>
    `/api/v1/client/?usercompanyrole__company=${compId}&pagination=true&page=${page}`,
  POST_MANAGEMENT_CLIENTS: "/api/v1/client/",
  FETCH_SINGLE_MANAGEMENT_CLIENTS: (id, company_id) => `/api/v1/client/${id}/?usercompanyrole__company=` + company_id,
  EDIT_SINGLE_MANAGEMENT_CLIENTS: (id) => `/api/v1/client/${id}/`,
  DELETE_SINGLE_MANAGEMENT_CLIENTS: (id) => `/api/v1/client/${id}/`,

  // Upload Object To S3 End Point
  UPLOAD_OBJECT: `/api/v1/upload-object-to-s3/`,

  //Document Management End Points
  FETCH_CLIENT_DOCUMENT_LIST: (id) => `/api/v1/client_document/?client=${id}`,
  UPLOAD_UNSIGNED_DOCUMENT: "/api/v1/client_document/",
  SEND_DOCUMENT_FOR_SIGNATURE: (id) => `api/v1/docusign?document_id=${id}`,
  UPLOAD_SIGNED_DOCUMENT: (id) => `/api/v1/client_document/${id}/`,
  DELETE_DOCUMENT: (id) => `/api/v1/client_document/${id}/`,

  //Key Management End points
  FETCH_MANAGEMENT_KEYS: (id, queryParams = "", search = "") =>
    `/api/v1/keys/?client__usercompanyrole__company=${id}&${queryParams}&search=${search}`,
  POST_MANAGEMENT_KEYS: `/api/v1/keys/`,
  DELETE_SINGLE_KEY: (id) => `/api/v1/keys/${id}/`,
  EDIT_SINGLE_MANAGEMENT_KEYS: (id) => `/api/v1/keys/${id}/`,
  VIEW_KEY_CODE: `/api/v1/view-key-code/`,
  CREATE_COPY_KEY: `/api/v1/keys/create_copy/`,
  SEARCH_MANAGEMENT_KEYS: (id) => `/api/v1/keys/?search=${id}`,
  GET_KEY_BY_CLIENT_ID: (id) => `/api/v1/keys/?client=${id}`,
  // POST_MANAGEMENT_CLIENTS: "/api/v1/client/",
  // EDIT_SINGLE_MANAGEMENT_CLIENTS: (id) => `/api/v1/client/${id}/`,
  // DELETE_SINGLE_MANAGEMENT_CLIENTS: (id) => `/api/v1/client/${id}/`,

  //Pet End points
  FETCH_PET_LIST_PETS: (compId, clientId) => `/api/v1/client_pet/?client__usercompanyrole__company=${compId}&cid=${clientId}`,
  POST_PET_LIST_PETS: "/api/v1/client_pet/",
  FETCH_SINGLE_PET_LIST_PETS: (id) =>
    `/api/v1/client_pet/${id}/?client__usercompanyrole__company=` + localStorage.getItem("company"),
  PUT_SINGLE_PET_LIST_PETS: (id) => `/api/v1/client_pet/${id}/`,
  DELETE_SINGLE_PET_LIST_PETS: (id) => `/api/v1/client_pet/${id}/`,
  FETCH_VACCINATION_PETS: (id) => "/api/v1/vaccination/?pet__client__usercompanyrole__company=" + id,
  VACCINATION_LIST_FILTER: (search, petName) => {
    const params = new URLSearchParams();

    if (localStorage.getItem("company")) params.append("pet__client__usercompanyrole__company", localStorage.getItem("company"));
    if (search) params.append("search", search);
    if (petName) params.append("pet__name", petName);

    const queryString = params.toString();
    return `/api/v1/vaccination/${queryString ? `?${queryString}` : ""}`;
  },
  POST_VACCINATION_PETS: "/api/v1/vaccination/",
  FETCH_SINGLE_VACCINATION_PETS: (id) => `/api/v1/vaccination/${id}/`,
  PUT_SINGLE_VACCINATION_PETS: (id) => `/api/v1/vaccination/${id}/`,
  DELETE_SINGLE_VACCINATION_PETS: (id) => `/api/v1/vaccination/${id}/`,
  FETCH_VET_LIST_PETS: "/api/v1/client_vet/",
  FETCH_VET_LIST_FILTER: (search) => {
    const params = new URLSearchParams();

    if (search) params.append("search", search);

    const queryString = params.toString();
    return `/api/v1/client_vet/${queryString ? `?${queryString}` : ""}`;
  },
  POST_VET_LIST_PETS: "/api/v1/client_vet/",
  FETCH_SINGLE_VET_LIST_PETS: (id) => `/api/v1/client_vet/${id}/`,
  PUT_SINGLE_VET_LIST_PETS: (id) => `/api/v1/client_vet/${id}/`,
  DELETE_SINGLE_VET_LIST_PETS: (id) => `/api/v1/client_vet/${id}/`,

  // SERVICE MODULE END POINTS //

  GET_ALL_SERVICES: (id, searchTerm, query) => `/api/v1/service/?company=${id}&${query}&search=${searchTerm}`,
  POST_NEW_SERVICES: "/api/v1/service/",
  DELETE_SPECIFIC_SERVICE_: (id) => `/api/v1/service/${id}/`,
  UPDATE_SPECIFIC_SERVICE_: (id) => `/api/v1/service/${id}/`,
  DISABLE_SPECIFIC_SERVICE_: "/api/v1/service/",
  ENABLE_SPECIFIC_SERVICE_: "/api/v1/service/",

  // PROMO CODE
  SERVICE_GENERATE_PROMO_CODE: "/api/v1/promo_code/",
  SERVICE_FETCH_SINGLE_PROMO_CODE: (id) => `/api/v1/promo_code/${id}/`,
  UPDATE_SINGLE_PROMO_CODE: (id) => `/api/v1/promo_code/${id}/`,

  // SERVICE HOLIDAYS
  GET_ALL_HOLIDAYS: (searchterm) => `/api/v1/holiday/?search=${searchterm}`,
  POST_NEW_HOLIDAYS: "/api/v1/holiday/",
  DELETE_SPECIFIC_HOLIDAY_: (id) => `/api/v1/holiday/${id}/`,
  UPDATE_SPECIFIC_HOLIDAY_: (id) => `/api/v1/holiday/${id}/`,

  // specific service request
  GET_SPECIFIC_SERVICE_REQUEST: (id) => `/api/v1/service_request/${id}/`,

  // service group

  SERVICE_CREATE_GROUP: "/api/v1/service_group/",
  SERVICE_UPDATE_GROUP: (id) => `/api/v1/service_group/${id}/`,
  DELETE_SERVICE_REQUEST: (id) => `/api/v1/service_request/${id}/`,
  GET_SERVICE_REQUEST: (id) => `/api/v1/service_request/?service__company=${id}`,
  SERVICE_GET_GROUP: (id) => `/api/v1/service_group/?company=${id}`,
  SERVICE_REQUEST_GROUP: "/api/v1/service_request_group/",
  CHANGE_SERVICE_REQUEST: "/api/v1/change-visit-request/",

  // time blocks
  FETCH_TIME_BLOCKS: "/api/v1/time_block/",

  // Fetch Time Blocks per Company in Company Settings
  FETCH_TIME_BLOCKS_PER_COMPANY: (companyid) => `/api/v1/time_block/?company=${companyid}`,
  ADD_TIME_BLOCKS_FOR_COMPANY: "/api/v1/time_block/",
  // Scheduler APIS
  //
  FETCH_SCHEDULER_LIST: (compid, clientQuery = "", statusQuery = "", startDate = "", endDate = "") =>
    `/api/v1/service_request/?service__company=${compid}&${clientQuery}&${statusQuery}&start_date=${startDate}&end_date=${endDate}`,
  POST_SCHEDULER_LIST: "/api/v1/service_request_group/",
  FETCH_TASKS_LIST: (companyId, search, type, status, assignee, priority) => {
    const params = new URLSearchParams();

    if (companyId) params.append("company", companyId);
    if (search) params.append("search", search);
    if (type == "client") params.append("client", assignee);
    if (type == "staff") params.append("staff", assignee);
    if (type == "manager") params.append("manager", assignee);
    if (status) params.append("status", status);
    if (priority) params.append("priority", priority);
    params.append("type", "general");
    params.append("type", "staff");
    params.append("type", "client");

    const queryString = params.toString();
    return `/api/v1/task/${queryString ? `?${queryString}` : ""}`;
  },
  FETCH_COMPANY_USERS: (companyId, role) => `/api/v1/company_user/?usercompanyrole__company=${companyId}&role=${role}`,
  FECTH_WORK_QUEUE: (companyId, search = "") => `/api/v1/work_queue/?work_queue_task__company=${companyId}&search=${search}`,
  FECTH_SINGLE_WORK_QUEUE: (companyId, id) => `/api/v1/work_queue/${id}/?company=${companyId}`,
  FECTH_SINGLE_Task: (companyId, id) => `/api/v1/task/${id}/?company=${companyId}`,
  WORK_QUEUE: `/api/v1/work_queue/`,
  WORK_QUEUE_WITH_ID: (id) => `/api/v1/work_queue/${id}/`,
  TASKS_LIST_WITH_ID: (id) => `/api/v1/task/${id}/`,
  TASKS_LIST: `/api/v1/task/`,

  // Scheduler Pending Request

  FETCH_PENDING_REQUEST_LIST: (id) => `/api/v1/service_request_group/?status=unassigned&company=${id}`,
  ASSIGN_PENDING_REQUEST: "/api/v1/assign-visit-request/",
  CLIENT_CHANGE_PASSWORD: "/api/v1/change-password/",
  UNAVAIABLE_SLOTS: () => `/api/v1/scheduler/unavailable-slots/`,
  // CompanyDashboard
  FETCH_COMPANY_DASHBOARD_DATA: (ID) => `/api/v1/company-dashboard-data/?company=${ID}`,
  APPROVE_PENDING_REQ: "/api/v1/accept-visit-change-request/",
  // SCHEUDLER TEMPLATES

  FETCH_TEMPLATES: (ID) => `/api/v1/scheduler/template/?company=${ID}`,
  POST_NEW_TEMPLATES: "/api/v1/scheduler/template/",
  APPROVE_TEMPLATE: "/api/v1/scheduler/approve-project-schedule-template/",
  DELETE_TEMPLATE: (ID) => `/api/v1/scheduler/template/${ID}/`,
  UPDATE_EXISTING_TEMPLATE: (ID) => `/api/v1/scheduler/template/${ID}/`,
  PROJECT_TEMPLATE: "/api/v1/scheduler/project-schedule-template/",
  FETCH_TEMP_PROJECTIONS: (ID, compId) => `/api/v1/service_request_group/?company=${compId}&template=${ID}`,
  DELETE_PROJECTED_TEMPLATE: `/delete-project-schedule-template/`,

  REPORTING_CLIENT: `/api/v1/reporting/client/`,
  REPORTING_STAFF: `/api/v1/reporting/staff/`,
  REPORTING_SCHEDULE: `/api/v1/reporting/schedule/`,
  REPORTING_CHART: `/api/v1/reporting/chart_points/`,
  REPORTING_TIME: `/api/v1/reporting/time/`,
  REPORTING_REVENUE: `/api/v1/reporting/revenue/`,
  REPORTING: `/api/v1/reporting/`,
  DELETE_REPORTING: (id) => `/api/v1/reporting/${id}/`,
  REPORTING_SAVE: `/api/v1/reporting/save/`,
  PDF_REPORTING_SAVE: `/api/v1/reporting/save_pdf_report/`,

  SUBCSRIPTION: `/api/v1/subscription/`,
  GET_SUBCSRIPTION: (planType) => `/api/v1/subscription/?plan_type=${planType}`,

  //
  // Internal chat end points

  REMINDER_MESSAGE: "/api/v1/pubnub_messages/reminder/",
  SCHEDULE_MESSAGE: "/api/v1/pubnub_messages/scheduled/",

  // INVOICING END PINTs

  CREATE_INVOICE: `/api/v1/invoice/`,
  GET_ALL_INVOICES: (compId, query) => `/api/v1/invoice/?company=${compId}&${query}`,
  GET_SINGLE_INVOICES: (invoiceId) => `/api/v1/invoice/${invoiceId}/detail/`,
  LOCK_INVOICE: (invoiceId) => `/api/v1/invoice/${invoiceId}/`,
  CLAIM_INVOICE: `/api/v1/invoice/claim/`,
  PAY_INVOICE_AMOUNT: (invoiceId) => `/api/v1/invoice/${invoiceId}/pay/`,
  UPATE_INVOICE: (invoiceId) => `/api/v1/invoice/${invoiceId}/`,

  // payment methods striple end point

  CREATE_PAYMENT_INTENT: "/api/v1/stripe/create-payment-intent/",
  GET_INFORMATION_URL: "/api/v1/stripe/create-account-link/",
  GET_CARD_INFO: (accId, custId) => `/api/v1/stripe/accounts/${accId}/customers/${custId}/payment`,
  ADD_CARD_INFO: (accId, custId) => `/api/v1/stripe/accounts/${accId}/customers/${custId}/payment`,
  GET_COMPANY_STRIPE_INFORMATION: (accId) => `/api/v1/stripe/accounts/${accId}/`,

  // COMPANY SETTINGS CLIENT
  ADD_CLIENT_SETTINGS: "/api/v1/client_settings/",
  GET_CLIENT_SETTINGS: (compId) => `/api/v1/client_settings/detail/?company_id=${compId}`,
};

export const staffAPIsEndPoints = {
  STAFF: "/api/v1/staff/",
  LIST_STAFF: (queryString) => {
    return `${staffAPIsEndPoints.STAFF}${queryString ? `?${queryString}` : ""}`;
  },
  FETCH_SINGLE_STAFF: (id, companyId) => `/api/v1/staff/${id}/?usercompanyrole__company=${companyId}`,
  FETCH_ALL_STAFF: "/api/v1/staff/",
  STAFF_TIMEOFF: "/api/v1/staff_timeoff/",
  LIST_STAFF_TIMEOFF: (queryString) => {
    return `${staffAPIsEndPoints.STAFF_TIMEOFF}${queryString ? `?${queryString}` : ""}`;
  },
  DELETE_STAFF_TIMEOFF: (id) => `${staffAPIsEndPoints.STAFF_TIMEOFF}${id}/remove/`,

  // STAFF MILEAGE TRACKING

  LIST_STAFF_MILEAGE_TRACKING: (queryString) => {
    return `/api/v1/mileage_tracking/${queryString ? `?${queryString}` : ""}`;
  },
  GET_STAFF_MILEAGE_TRACK: (id, queryParams = "") => `/api/v1/mileage_tracking/?staff=${id}&${queryParams}`,
  ADD_NEW_STAFF_MILEAGE_TRACK: "/api/v1/mileage_tracking/",
  UPDATE_STAFF_MILEAGE_TRACK: (id) => `/api/v1/mileage_tracking/${id}/`,
  DELETE_EXISTING_STAFF_MILEAGE_TRACK: (id) => `/api/v1/mileage_tracking/${id}/`,

  // COMPANY ADMIN STAFF CRUD

  ADD_NEW_STAFF: "/api/v1/staff/",
  UPDATE_NEW_STAFF: (id) => `/api/v1/staff/${id}/`,
  DELETE_EXISTING_STAFF: (id) => `/api/v1/staff/${id}/`,

  // STAFF TAGS

  GET_STAFF_TAGS: "/api/v1/staff/",
  ADD_STAFF_TAGS: "/api/v1/staff_tags/",
  ASSIGN_TAGS: (id) => `/api/v1/staff_tags/${id}/assignTag/`,
  STAFF_CONFIGURE_AVAIL: "/api/v1/staff_availability/",
  GET_STAFF_CONFIGURE_AVAIL: (id) => `/api/v1/staff_availability/?staff=${id}`,
  GET_STAFF_STATUS: (id) => `/api/v1/activity_status/?staff=${id}`,
  GET_STAFF_TIME_TRACK: (id, queryParams = "") => `/api/v1/time_tracking/?staff=${id}&${queryParams}`,
  UPDATE_TIME_TRACKING: (id) => `/api/v1/time_tracking/${id}/`,

  ADD_NEW_STAFF_STATUS: "/api/v1/activity_status/",
  CREATE_TIME_TRACKING: `/api/v1/time_tracking/`,
};

export const platformAPIsEndPoints = {
  // forgot password End points
  LOGIN: "/api/v1/login/",
  RESET_PASSWORD: "/api/v1/email-verification/",
  COMPANY_LIST: "/api/v1/company/",
  POST_COMPANY_LIST_PETS: "/api/v1/company/",
  FETCH_SINGLE_COMPANY_LIST_PETS: (id) => `/api/v1/company/${id}/`,
  PUT_SINGLE_COMPANY_LIST_PETS: (id) => `/api/v1/company/${id}/`,
  DELETE_SINGLE_COMPANY_LIST_PETS: (id) => `/api/v1/company/${id}/`,
  COMPANY_LIST_FILTER: (search, active) => {
    const params = new URLSearchParams();

    if (search) params.append("search", search);
    if (active !== "") params.append("active", active);

    const queryString = params.toString();
    return `/api/v1/company/${queryString ? `?${queryString}` : ""}`;
  },
  FORGOT_PASSWORD: "/api/v1/forget-password/",
  fetchCompanyUSer: "/api/v1/abc",
  FETCH_USERS_LIST: "/api/v1/user/",
  POST_USER: "/api/v1/user/",
  PUT_USER: (id) => `/api/v1/user/${id}/`,
  DELETE_USER: (id) => `/api/v1/user/${id}/`,
  FETCH_DASHBOARD_DATA: "/api/v1/dashboard-data/",
  USERS_LIST_FILTER: (search, role, company) => {
    const params = new URLSearchParams();

    if (search) params.append("search", search);
    if (role && role !== "") params.append("usercompanyrole__role__type", role);
    if (company && company !== "") params.append("usercompanyrole__company", company);

    const queryString = params.toString();
    return `/api/v1/user/${queryString ? `?${queryString}` : ""}`;
  },
  FETCH_USER_ROLES: "/api/v1/role/",
  FETCH_SINGLE_ROLE: (id) => `/api/v1/role/${id}`,

  // Onboarding theme api endpoints
  FETCH_ONBOARDING_THEME_SETTINGS: (id) => `/api/v1/onboarding_theme_setting/${id}/`,
  UPDATE_ONBOARDING_THEME_SETTINGS: (id) => `/api/v1/onboarding_theme_setting/${id}/`,

  // Onboarding videp api endpoints
  FETCH_ONBOARDING_VIDEO_SETTINGS: "api/v1/onboarding/",
  CREATE_ONBOARDING_VIDEO_SETTINGS: `/api/v1/onboarding/`,
  UPDATE_ONBOARDING_VIDEO_SETTINGS: (id) => `/api/v1/onboarding/${id}/`,
};

// Route Optimizer Api Endpoints

export const routeOptimizerAPIsEndPoints = {
  // Get Staff Routes
  GET_STAFF_ROUTES: (id) => `/api/v1/scheduler/staff_route/?company=${id}`,

  // Route Optimizer Stats
  GET_ROUTE_OPTIMIZER_STATS: `/api/v1/scheduler/route-stats/`,

  // Route Optimizer Config
  POST_ROUTE_OPTIMIZER_CONFIG: `/api/v1/scheduler/route-optimizer-config/`,
  GET_ROUTE_OPTIMIZER_CONFIG: `/api/v1/scheduler/route-optimizer-config/`,
};
