import React, { useCallback, useEffect, useRef, useState } from "react";
import { usePubNub } from "pubnub-react";
import { generateUUID } from "pubnub";
import pickerData from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import { Col, Input, Row, Tooltip } from "antd";
import { ChannelList, ChatList, MessagesContainer } from "./Chat.styled";

import HeadingComponent from "../../../components/Heading";

import { ChatComponent, ChatLeftSide as ChatLeftSideChannels } from "./ChatLeftSideComponents";
import { ChatHeaderComponent, UserMessageComponent } from "./ChatRightSideComponents";
// ICONS
import { ReactComponent as SendMsgIcon } from "../../../assets/rawSvgs/sendMsgIcon.svg";
import { ReactComponent as ChatEmoj } from "../../../assets/rawSvgs/chatEmoj.svg";
import { ReactComponent as PlusSIgn } from "../../../assets/rawSvgs/plusSign.svg";
import { ReactComponent as PinMsg } from "../../../assets/rawSvgs/pinMsg.svg";

// import { ReactComponent as GeneralSchedulerIcon } from "../../../assets/companySvgIcons/sidebarIcons/generalSchedulerIcon.svg";

// import { deleteAllChannels, deleteUser, makeChannelsList } from "./utilsFunctions";
import moment from "moment/moment";

import { NewChatMembersModal, ScheduleMsgModal, UploadFileModal } from "./Modals";
import { useUserMemberships } from "@pubnub/react-chat-components";
import { checkUnreadCountSendSignal, deleteAllChannels, deleteAllMessagesInChannel } from "./utilsFunctions";
import { Formik, Form } from "formik";

//

const ChatPubnub = ({
  companyInternalChatState,

  adminId,
  adminName,
  onlyCompId,
  loggedInUserName,
  fetchChannelsToCheckUnreadCount,
}) => {
  // STATES

  // console.log("authState", authState);
  const [inputFieldText, setInputFieldText] = useState("");
  const [currentChannel, setCurrentChannel] = useState(undefined);
  const [messages, setMessages] = useState([]);
  const [chatterId, setChatterId] = useState("");
  const [openEmoji, setOpenEmoji] = useState(false);
  const [channelsDetails, setChannelDetails] = useState({});

  const [allUsers, setAllUsers] = useState([]);
  const [channelMembers, setChannelMembers] = useState([]);
  const [selectedChannelDetails, setSelectedChannelDetails] = useState({ directMsg: false, name: "" });
  const [fetchingMemberLoader, setFetchingMemberLoader] = useState(false);
  const [openScheduleModal, setOpenScheduleModal] = useState(false);
  const [openUploadFileModal, setOpenFileModal] = useState(false);
  const [uuidMetaData, setUiidMetaData] = useState({});
  const [userScrolled, setUserScrolled] = useState(false);
  const [joinedChannels, setStoredChannels] = useState([]);
  const [newChatModal, setNewChatModal] = useState(false);
  const [fetchSingal, setFetchSignal] = useState(false);
  //

  // STATES END

  // HOOKS START
  const messagesEndRef = useRef(null);
  const messagesContainerRef = useRef(null);
  const pickerRef = useRef(null);
  const pubnub = usePubNub();
  const uuid = pubnub.getUUID();
  const [joinedIncomingChannels, , refetchJoinedChannels] = useUserMemberships({
    include: { channelFields: true, customChannelFields: true },
  });

  let notificationchannelName = "notification_signal";
  // const channels = makeChannelsList(allChannels?.filter((items) => items?.custom?.company_id === onlyCompId));

  // const separateJoinedChannels = makeChannelsList(joinedChannels?.filter((items) => items?.custom?.company_id === onlyCompId));
  // const ChannelsList = channels?.[0];

  let getChannelList = joinedChannels.filter((items) => {
    return items.id.startsWith("internal.") && items?.custom?.company_id === onlyCompId;
  });

  // const dmList = channels?.[1];
  let getOnlyPublicChannels = getChannelList?.filter((items) => items.custom.group_visibility === "public");
  let getOnlyPrivateChannels = getChannelList?.filter((items) => items.custom.group_visibility === "private");
  let getOnlyDms = joinedChannels?.filter(
    (items) =>
      items.id.startsWith("direct.") && items?.custom?.company_id === onlyCompId && !items.id.startsWith("direct.client"),
  );
  let ownSelf =
    joinedChannels?.filter(
      (items) =>
        items.id.startsWith("self.") && items?.custom?.company_id === onlyCompId && !items.id.startsWith("direct.client"),
    )[0] || {};

  // USERS  let filterClients = allUsersFilter?.filter((items) => !items.id.startsWith("client"));
  const allUsersFilter = allUsers?.filter((users) => !users.id.startsWith("client"));
  const channelHeaderUsers = allUsersFilter;

  let mergePrivateAndPublicChannels = [...getOnlyPublicChannels, ...getOnlyPrivateChannels];

  const handleMessage = useCallback((msgEvent) => {
    setMessages((prevMessages) => {
      if (!prevMessages.some((msg) => msg.timetoken === msgEvent.timetoken)) {
        return [...prevMessages, msgEvent];
      }
      return prevMessages;
    });
  }, []);

  const fetchAllUsers = async () => {
    try {
      const response = await pubnub.objects.getAllUUIDMetadata({
        include: {
          custom: true,
          customFields: true,
        },
        filter: `custom.company_id == ${onlyCompId}`,
      });

      setAllUsers(response?.data);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchChannelMembers = async () => {
    try {
      setFetchingMemberLoader(true);
      const response = await pubnub.objects.getChannelMembers({
        channel: currentChannel,
        include: {
          UUIDFields: true,
          customUUIDFields: true,
        },
      });

      setChannelMembers(response.data?.map((items) => items.uuid));
      setFetchingMemberLoader(true);
    } catch (err) {
      console.log(err);
      setFetchingMemberLoader(false);
    } finally {
      setFetchingMemberLoader(false);
    }
  };

  const fetchUuidMetaData = () => {
    try {
      pubnub.objects.getUUIDMetadata(
        {
          uuid: uuid,
          include: {
            custom: true,
            customFields: true,
            channelFields: true,
          },
        },
        (status, response) => {
          if (!status.error) {
            setUiidMetaData(response.data);
          }
        },
      );
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const handleCreateNotificatoinchannel = () => {
    pubnub.objects.getChannelMetadata(
      {
        channel: notificationchannelName,
      },
      async (status, response) => {
        if (!status.error) {
          pubnub.objects.setChannelMembers({ channel: notificationchannelName, uuids: [...allUsersFilter, adminId] });
        }
        if (status.error) {
          await pubnub.objects.setChannelMetadata({
            channel: notificationchannelName,
            data: {
              name: adminName,
              description: "this is for notification only",
              custom: {
                company_id: onlyCompId,
                owner: adminId,
                [adminId]: adminName,
                notification_channel: true,
              },
            },
          });

          pubnub.objects.setChannelMembers({ channel: notificationchannelName, uuids: [...allUsersFilter, adminId] });
        }
      },
    );
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  // EVENT
  // ================================================================================================ //
  //        USE EFFECTS
  // ================================================================================================ //

  useEffect(() => {
    if (joinedIncomingChannels.length > 0) {
      setStoredChannels(joinedIncomingChannels);
    }
  }, [joinedIncomingChannels, refetchJoinedChannels]);

  useEffect(() => {
    if (!userScrolled) {
      scrollToBottom();
    }
  }, [currentChannel, messages, userScrolled]);

  useEffect(() => {
    if (currentChannel) {
      fetchChannelMembers();
    }
  }, [currentChannel]);

  useEffect(() => {
    fetchAllUsers();
    fetchUuidMetaData();
  }, []);

  useEffect(() => {
    handleCreateNotificatoinchannel();
  }, []);

  // AD EVENT LISTER ====================================================
  useEffect(() => {
    let channelsArraySub = [currentChannel, "notification_signal"];
    // if (!channelsArraySub[0]) return;

    pubnub.subscribe({ channels: [...channelsArraySub], withPresence: true });

    pubnub.addListener({
      message: handleMessage,
      messageAction: (event) => {
        handleFetchMessages(event.channel);
      },
    });

    return () => {
      // pubnub.unsubscribe({ channels: [currentChannel] });
      pubnub.unsubscribe({ channels: [currentChannel] });

      pubnub.removeListener({ message: handleMessage, messageAction: handleFetchMessages, signal: handleFetchMessages });

      setMessages([]);
      setInputFieldText("");
    };
  }, [pubnub, currentChannel, handleMessage]);

  useEffect(() => {
    const handleSignal = async (signal) => {
      if (signal.message.eventType === "fileSent") {
        handleFetchMessages(signal.channel);
      }
      if (signal.message.eventType === "channel_create") {
        refetchJoinedChannels();
      }
      if (signal.channel === notificationchannelName) {
        refetchJoinedChannels();
        // console.log("RECIEVED new_message_signal ++++", signal);
        let channelFromSignal = signal?.message?.channelSendTo;
        let filterChannelToMatchIncomeChan =
          mergePrivateAndPublicChannels.filter((items) => {
            return items.id.startsWith(channelFromSignal);
          })[0]?.id || {};
        refetchJoinedChannels();
        fetchChannelsToCheckUnreadCount(uuid);
        setFetchSignal((prevSt) => !prevSt);
        // refetchJoinedChannels();

        if (!filterChannelToMatchIncomeChan) return;
      }
    };

    pubnub.addListener({
      signal: handleSignal,
    });

    return () => {
      pubnub.removeListener({ signal: handleSignal });
    };
  }, [pubnub, fetchSingal, joinedIncomingChannels, refetchJoinedChannels]);

  // FETCH MESG
  // ================================================================================================ //
  // ================================================================================================ //

  useEffect(() => {
    handleFetchMessages(currentChannel);
    // deleteAllMessagesInChannel(pubnub, currentChannel);
    // deleteAllChannels();
    // dmList.data?.forEach((element) => {
    //   deleteAllChannels(element.id);
    // });
    // deleteAllChannels(pubnub, "direct.staff_100-company_admin_1");

    // deleteUser(pubnub, "company_user_25");
    return () => {
      setMessages([]);
    };
  }, [currentChannel]);

  // ================================================================================================ //

  // ================================================================================================ //

  // EMOJI

  const handleEmojiSelect = (emoji) => {
    setInputFieldText((prevValue) => prevValue + emoji.native);
    // setOpenEmoji(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (pickerRef.current && !pickerRef.current.contains(event.target)) {
        setOpenEmoji(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  // DELETE

  const establishStaffAdminChannel = async (id, name, type, items) => {
    setCurrentChannel("");
    setChatterId("");

    setChatterId(id);
    setCurrentChannel(id);
    // pubnub.objects.setChannelMembers({ channel: id, uuids: [id, uuid] });
    return;
    // const userIds = [id, adminId].sort();
    // // Custom sort function to ensure company_user is always first
    // userIds.sort((a, b) => {
    //   if (a.startsWith("company_user_") && b.startsWith("company_admin_")) {
    //     return -1;
    //   } else if (a.startsWith("company_admin_") && b.startsWith("company_user_")) {
    //     return 1;
    //   }
    //   return 0; // keep the original order if both are of the same type
    // });

    // const isStaff = id.startsWith("staff_");

    // let channelName = "";
    // if (type === "channel") {
    //   channelName = id;
    // } else {
    //   if (isStaff) {
    //     channelName = `direct.${id}-${adminId}`;
    //   } else {
    //     channelName = `direct.${userIds[0]}-${userIds[1]}`;
    //   }
    // }
    // console.log("estab", { channelName });
    // setChatterId(id);
    // setCurrentChannel(channelName);
    // pubnub.objects.getChannelMetadata(
    //   {
    //     channel: channelName,
    //   },
    //   async (status, response) => {
    //     const customData = response.data.custom || {};
    //     if (status.error) {
    //       await pubnub.objects.setChannelMetadata({
    //         channel: channelName,
    //         data: {
    //           name: adminName,
    //           description: "admin",
    //           custom: {
    //             ...customData,
    //             company_id: onlyCompId,
    //             owner: adminId,
    //             [adminId]: adminName,
    //           },
    //         },
    //       });

    //       pubnub.objects.setChannelMembers({ channel: channelName, uuids: [id, uuid] });
    //     }
    //   },
    // );
  };

  const handleInputfieldOnChange = (value) => {
    setInputFieldText(value);
  };

  const handleSendMessage = ({ newMemberChannel, newMsg, textMessage }) => {
    let sendMEsgText = newMsg ? inputFieldText : textMessage?.message || textMessage.textMessage || textMessage;

    if (sendMEsgText === "" || !sendMEsgText) return;

    let msgChannel = newMsg ? newMemberChannel : currentChannel;

    const message = {
      text: sendMEsgText,

      user: {
        _id: adminId,
        name: uuidMetaData?.name,
        type: uuidMetaData?.custom?.role || "admin",
      },
      createdAt: new Date(),
      _id: generateUUID(),
    };

    pubnub.publish(
      {
        channel: msgChannel,
        message: message,
      },
      function (status, response) {
        setInputFieldText("");

        if (!status.error) {
          if (newMsg) {
            setCurrentChannel();
            refetchJoinedChannels();
            checkUnreadCountSendSignal(pubnub, notificationchannelName, msgChannel);
            return;
          }
          checkUnreadCountSendSignal(pubnub, notificationchannelName, msgChannel);
          handleReadUnreadCount(chatterId, false, true);
        }
      },
    );
  };

  const handleReadUnreadCount = async (_, fromInputField, fromSendMessageFunction) => {
    try {
      if (currentChannel.startsWith("internal.")) {
        handleGroupUnreadCount(_, fromInputField, fromSendMessageFunction);
        return;
      }

      const response = await pubnub.objects.getChannelMetadata({
        channel: currentChannel,
        include: { customFields: true },
      });
      //
      const inputString = currentChannel;

      // Step 1: Remove the prefix "direct."
      const withoutPrefix = inputString.replace("direct.", "");

      // Step 2: Split the remaining string by "-"
      const resultArray = withoutPrefix.split("-");
      let firstName = resultArray[0] || "";
      let seconName = resultArray[1] || "";

      // Output the result

      let id = "";
      if (firstName === adminId) {
        id = allUsersFilter?.filter((items) => items.id === seconName)[0]?.id || {};
      }
      if (seconName === adminId) {
        id = allUsersFilter?.filter((items) => items.id === firstName)[0]?.id || {};
      }

      const customData = response.data.custom || {};

      let unreadCountData = customData.unreadCountData ? JSON.parse(customData.unreadCountData) : {};
      let recieverData = unreadCountData.reciever || {};

      if (fromSendMessageFunction) {
        // Increment the unread count for the receiver
        if (recieverData.recieverId === id) {
          recieverData.unreadCount = recieverData.unreadCount ? recieverData.unreadCount + 1 : 1;
        } else {
          // Reset unread count for other receivers when sending message
          recieverData = {
            recieverId: id,
            unreadCount: 1,
            senderId: uuid,
          };
        }

        unreadCountData.reciever = recieverData;
        recieverData.senderId = uuid;
        // console.log(`Unread count for receiver ${id} updated to ${recieverData.unreadCount}`);
      }

      // Reset the unread count for the sender's current recipient when focusing on input

      if (fromInputField && recieverData.recieverId !== id) {
        // Reset the unread count for the sender's current recipient when focusing on input

        if (recieverData.recieverId === uuid) {
          recieverData.unreadCount = 0;
          unreadCountData.reciever = recieverData;

          // console.log(`Unread count for receiver ${recieverData.recieverId} reset to 0`);
        }
      }

      // Serialize the unreadCountData as a JSON string
      const updatedCustomData = {
        ...customData,
        unreadCountData: JSON.stringify(unreadCountData),
      };

      // Set the updated metadata
      await pubnub.objects.setChannelMetadata({
        channel: currentChannel,
        data: {
          name: adminName,
          description: "admin",
          custom: updatedCustomData,
        },
      });
    } catch (error) {
      console.error("Error updating unread count:", error);
    }
  };

  const handleGroupUnreadCount = async (_, fromInputField, fromSendMessageFunction) => {
    try {
      const membersResponse = await pubnub.objects.getChannelMembers({
        channel: currentChannel,
        include: {
          custom: true,
          customFields: true,
        },
      });

      let listOfUsers = [];

      membersResponse?.data?.forEach((elem) => {
        allUsersFilter.forEach((nestElem) => {
          if (elem.uuid.id === nestElem.id) {
            listOfUsers.push({ ...nestElem });
          }
        });
      });

      pubnub.objects.getChannelMetadata(
        {
          channel: currentChannel,
          include: {
            customFields: true,
          },
        },
        async (status, response) => {
          if (!status.error) {
            const customData = response.data;
            const members = listOfUsers || [];

            let unreadCountData = customData.custom?.unreadCountData
              ? JSON.parse(customData.custom.unreadCountData)
              : { recievers: {} };

            if (fromSendMessageFunction) {
              listOfUsers.forEach((member) => {
                const memberId = member.id;

                if (memberId !== uuid) {
                  // Ensure the sender's own count is not incremented
                  if (unreadCountData.recievers[memberId]) {
                    unreadCountData.recievers[memberId].unreadCount += 1;
                  } else {
                    unreadCountData.recievers[memberId] = {
                      recieverId: memberId,
                      unreadCount: 1,
                      senderId: uuid,
                    };
                  }

                  console.log(
                    `Unread count for receiver ${memberId} updated to ${unreadCountData.recievers[memberId].unreadCount}`,
                  );
                }
              });
            }

            if (fromInputField) {
              members.forEach((member) => {
                const memberId = member.id;

                if (unreadCountData.recievers[memberId] && memberId === uuid) {
                  unreadCountData.recievers[uuid].unreadCount = 0;
                }
              });
            }

            // unreadCountData = { recievers: unreadCountData };

            const customObj = {
              name: customData.name,
              description: "This is a group channel for discussion.",
              custom: {
                ...customData.custom,
                unreadCountData: JSON.stringify(unreadCountData),
              },
            };

            await pubnub.objects.setChannelMetadata({
              channel: currentChannel,
              data: customObj,
            });
          }
        },
      );
    } catch (error) {
      console.error("Error updating group unread count:", error);
    }
  };

  // GROUP UNREAD COUNT

  const handleDmChatterName = (name) => {
    // Initial string
    if (name?.startsWith("self")) {
      return `${loggedInUserName} You`;
    }
    if (!name) return;
    const inputString = name;

    // Step 1: Remove the prefix "direct."
    const withoutPrefix = inputString.replace("direct.", "");

    // Step 2: Split the remaining string by "-"
    const resultArray = withoutPrefix.split("-");
    let firstName = resultArray[0] || "";
    let seconName = resultArray[1] || "";

    if (firstName === adminId) {
      let AllUsersList1 = allUsersFilter?.filter((items) => items.id === seconName)[0] || {};
      return AllUsersList1.name;
    }
    if (seconName === adminId) {
      let AllUsersList2 = allUsersFilter?.filter((items) => items.id === firstName)[0] || {};

      return AllUsersList2.name;
    }
  };

  const handleFetchMessages = (inComChan) => {
    if (inComChan) {
      pubnub.fetchMessages(
        {
          channels: [inComChan],
          includeMessageActions: true,
        },
        (status, response) => {
          if (response) {
            let keys = Object.keys(response.channels);
            let indexFirst = keys[0];
            const fetchedMessages = response?.channels[indexFirst];

            // setMessages((prevMessages) => [...prevMessages, ...fetchedMessages]);

            if (fetchedMessages?.length > 0) {
              setMessages((prevMessages) => {
                // Create a Map to store unique messages based on their timetoken
                const uniqueMessages = new Map(prevMessages?.map((existingMsg) => [existingMsg.timetoken, existingMsg]));

                // Add new messages to the uniqueMessages Map, overwriting any existing ones with the same timetoken
                fetchedMessages.forEach((msg) => {
                  uniqueMessages.set(msg.timetoken, msg);
                });

                // Convert the Map back to an array of messages
                const updatedMessages = Array.from(uniqueMessages.values());

                return updatedMessages;
              });
            }
          }
        },
      );
    }
  };

  // =====

  const filterDirectChannelCompRelated = mergePrivateAndPublicChannels?.filter(
    (items) => items.custom?.company_id === onlyCompId,
  );

  // const allUsersFilter = allUsers.filter((users) => users.custom?.company_id === onlyCompId && users.id !== adminId);

  function filterPinMessages(messages) {
    const pinMessages = [];

    messages.forEach((message) => {
      if (message.actions && message.actions.pin) {
        pinMessages.push(message);
      }
    });

    return pinMessages;
  }
  const pinMessages = filterPinMessages(messages);

  const addReactionToMessage = async (emoji, timeToken, actionToken, condition, timeTokexn) => {
    // Retrieve the message with the specified timetoken from the channel's history
    try {
      let actionTimetoken = null;
      let existingReaction = false;

      // Check if the admin's reaction already exists
      if (timeTokexn.actions && timeTokexn.actions.reaction && timeTokexn.actions.reaction[emoji]) {
        const reactions = timeTokexn.actions.reaction[emoji];
        reactions.forEach((reaction) => {
          if (reaction.uuid === adminId) {
            actionTimetoken = reaction.actionTimetoken;
            existingReaction = true;
          }
        });
      }

      if (existingReaction) {
        pubnub.removeMessageAction(
          {
            channel: currentChannel,
            messageTimetoken: timeToken,
            actionTimetoken: actionToken,
          },
          function (status, response) {
            // handleFetchMessages();
          },
        );
      }

      pubnub.addMessageAction(
        {
          channel: currentChannel,
          messageTimetoken: timeToken,
          action: {
            type: "reaction",
            value: emoji,
          },
        },
        async (status, response) => {
          // handleFetchMessages();
        },
      );
      return;
    } catch (error) {
      console.log("single error", error);
    }
  };

  const handlePinnedMasg = async (incomeMsg) => {
    try {
      const currentUserId = pubnub.getUUID(); // Assuming pubnub.uuid refers to the current user's UUID
      let alreadyPinned = false;
      let actionTimetoken;

      // Check for pin actions and determine if the message is already pinned
      if (incomeMsg.actions && incomeMsg.actions.pin) {
        const pinActions = incomeMsg.actions.pin;
        for (const adminName in pinActions) {
          const actionsArray = pinActions[adminName];
          for (const action of actionsArray) {
            if (action.uuid === currentUserId) {
              alreadyPinned = true;
              actionTimetoken = action.actionTimetoken;
              break;
            }
          }
          if (alreadyPinned) break; // Exit outer loop if already pinned
        }
      }

      if (alreadyPinned) {
        // If the message is already pinned, remove the pin action
        pubnub.removeMessageAction(
          {
            channel: currentChannel,
            messageTimetoken: incomeMsg.timetoken,
            actionTimetoken: actionTimetoken,
          },
          (status, response) => {},
        );
      } else {
        // If the message is not pinned, add a pin action
        pubnub.addMessageAction(
          {
            channel: currentChannel,
            messageTimetoken: incomeMsg.timetoken,
            action: {
              type: "pin",
              value: uuidMetaData?.name || adminName, // Or adminName, depending on your application logic
            },
          },
          (status, response) => {},
        );
      }
    } catch (error) {}
  };

  const handleCheckPinCondition = (incom) => {
    let alreadyPinned = false;
    let actionTimetoken;

    // Check for pin actions and determine if the message is already pinned
    if (incom.actions && incom.actions.pin) {
      const pinActions = incom.actions.pin;
      for (const adminName in pinActions) {
        const actionsArray = pinActions[adminName];
        for (const action of actionsArray) {
          if (action.uuid === uuid) {
            alreadyPinned = true;
            actionTimetoken = action.actionTimetoken;
            break;
          }
        }
        if (alreadyPinned) break; // Exit outer loop if already pinned
      }
    }
    return alreadyPinned;
  };
  // Extract the message from the response

  // Channels PROPS
  let channelsObjProp = {
    companyInternalChatState: companyInternalChatState,
    selectChat: (items) => {
      establishStaffAdminChannel(items.id, "", "channel");
      setChannelDetails(items);
      setSelectedChannelDetails({ directMsg: false, name: items.name });
    },
    pubnubUuid: uuid,
    channelsList: filterDirectChannelCompRelated,
    pubnub: pubnub,
    onlyCompId: onlyCompId,
    channelsDetails,
    onCompleteSuccess: () => {
      //  channel create/update
      refetchJoinedChannels();
    },
    totalChannelMembers: channelMembers,
    ListOfUsers: allUsersFilter,
    adminId: adminId,
    fetchChannelMembers,
    fetchingMemberLoader,
    handlePinnedMasg: (item) => handlePinnedMasg(item),
    channelHeaderUsers,
    emojiClick: (emoji, condition, item) => {
      if (condition && item?.actions?.reaction?.[emoji][0]?.actionTimetoken) {
        addReactionToMessage(
          emoji,
          item?.timetoken,
          item?.actions?.reaction?.[emoji][0]?.actionTimetoken || undefined,
          condition,
        );
      } else {
        addReactionToMessage(emoji, item?.timetoken, undefined, condition);
      }
    },

    //

    uuidMetaData,

    headerChannelName: handleDmChatterName(currentChannel),
  };

  // console.log("messages", messages);
  // console.log("uuidMetaData", uuidMetaData);
  // console.log("curreent chanel", currentChannel);
  // console.log("pinMessages", pinMessages);
  // console.log("AllUsers", allUsersFilter);
  // console.log("channelsDetails", allChannels);
  // console.log("all dmList", dmList);
  // console.log({
  //   // uuidMetaData,
  //   allUsersFilter,
  //   // channels,
  //   joinedChannels: joinedChannels.filter((items) => items.id.startsWith("direct.")),
  //   // allUsers,
  //   filterDirectChannelCompRelated,
  //   currentChannel,
  //   // messages,
  // });
  // console.log("messages", messages);

  return (
    <div>
      <Row style={{ height: "84vh" }} gutter={12}>
        <Col md={4} style={{ borderRight: "1px solid rgb(228, 234, 240)", height: "90vh", overflow: "auto" }} className="p-0">
          {/* PRIVCATE CHANNELS */}
          <ChannelList className="ps-3 pe-3">
            <div className="pt-3">
              <ChatLeftSideChannels {...channelsObjProp} channelType="Private Channels" typeVisibility={"private"} />
            </div>
          </ChannelList>

          <ChannelList className="ps-3 pe-3">
            <div className="pt-3">
              <ChatLeftSideChannels {...channelsObjProp} channelType="Public Channels" typeVisibility={"public"} />
            </div>
          </ChannelList>

          {/* DMS */}
          <div style={{ borderTop: "1px solid rgb(228, 234, 240)" }} className="px-3 pt-1">
            <ChatList>
              <div className="d-flex justify-content-between align-items-center pt-3">
                <HeadingComponent text={"DMs"} fontSize="0.830rem" fontWeight={700} color="#616161" />

                <Tooltip title="New Chat">
                  <PlusSIgn
                    className="m cursorPointer"
                    onClick={() => {
                      setNewChatModal(true);
                    }}
                  />
                </Tooltip>
              </div>
              <div style={{ height: "40%", overflow: "auto" }}>
                {getOnlyDms?.length > 0 ? (
                  [...getOnlyDms, ownSelf]?.map((items, index) => {
                    return (
                      <div
                        key={index}
                        onClick={() => {
                          establishStaffAdminChannel(items?.id, items?.name, "dm", items);

                          setSelectedChannelDetails({ directMsg: true, name: items.name });
                        }}
                      >
                        <ChatComponent
                          chatterName={() => handleDmChatterName(items?.id) || ""}
                          restItems={items}
                          adminId={adminId}
                        />
                      </div>
                    );
                  })
                ) : (
                  <div className="mt-5">
                    <HeadingComponent text={"No Active Chats"} fontSize="12px" fontWeight={400} color="#616161" />
                  </div>
                )}
              </div>
            </ChatList>
          </div>
        </Col>

        {/* ======================== MAIN CONTENT ============================  */}
        <Col lg={20} className="p-0 pe-0">
          {!currentChannel ? (
            <div className="d-flex align-items-center justify-content-center">
              <HeadingComponent text={"Select Channel to Start Conversation"} color="#0000005c" fontWeight={600} margin="20px " />
            </div>
          ) : (
            <>
              <ChatHeaderComponent
                companyInternalChatState={companyInternalChatState}
                selectedChannelDetail={selectedChannelDetails}
                pinMessages={pinMessages}
                {...channelsObjProp}
              />

              <MessagesContainer
                ref={messagesContainerRef}
                onScroll={() => {
                  const container = messagesContainerRef.current;
                  const threshold = 50; // Adjust this value as needed
                  if (container.scrollTop < container.scrollHeight - container.clientHeight - threshold) {
                    setUserScrolled(true);
                  } else {
                    setUserScrolled(false);
                  }
                }}
              >
                {messages?.map((item, index) => {
                  let pinnedkeys = "";
                  let pinnedBy = "";
                  const handleCheckPinCondition = (incom) => {
                    // Check for pin actions and determine if the message is already pinned
                    if (incom?.actions && incom.actions.pin) {
                      const pinActions = incom.actions.pin;
                      // Check if any pin actions exist for the message
                      return Object.values(pinActions)?.some((actionsArray) => actionsArray.length > 0);
                    }
                    return false;
                  };
                  const isPinned = handleCheckPinCondition(item);
                  if (item?.actions?.pin) {
                    pinnedkeys = Object.keys(item?.actions?.pin);
                    pinnedBy = pinnedkeys?.map((item) => (item === uuidMetaData.name ? "You" : item));
                  }

                  return (
                    <div key={index} className="my-2 p-1">
                      <div className="d-flex align-items-start ms-2">
                        {isPinned && (
                          <>
                            {pinnedkeys?.length > 3 ? (
                              <Tooltip
                                placement="right"
                                title={pinnedkeys?.map((item) => {
                                  return (
                                    <div>
                                      <span>{item === uuidMetaData?.name ? "You" : item}</span>
                                    </div>
                                  );
                                })}
                                className="d-flex"
                              >
                                <PinMsg className="" />
                                <HeadingComponent text={`Pinned by ${pinnedkeys?.length}`} fontSize="14px" margin={"0px 5px"} />
                              </Tooltip>
                            ) : (
                              <>
                                <PinMsg className="" />
                                <HeadingComponent text={`Pinned by ${pinnedBy}`} fontSize="14px" margin={"0px 5px"} />
                              </>
                            )}
                          </>
                        )}
                      </div>
                      <UserMessageComponent
                        chatterName={item?.message?.user?.name || item?.message?.sender?.name}
                        avatar={""}
                        msg={item?.message?.text || ""}
                        timeToken={moment(parseInt(item?.timetoken?.substring(0, 13))).format("h:mm A")}
                        onlyCompId={onlyCompId}
                        currentChannel={currentChannel}
                        openEmoji={openEmoji}
                        setOpenEmoji={setOpenEmoji}
                        pickerRef={pickerRef}
                        pinMessages={pinMessages}
                        adminId={adminId}
                        reactionsData={item?.data?.reaction}
                        fromInternalChat={true}
                        emojiClick={(emoji, condition) => {
                          if (condition && item?.actions?.reaction?.[emoji][0]?.actionTimetoken) {
                            addReactionToMessage(
                              emoji,
                              item?.timetoken,
                              item?.actions?.reaction?.[emoji][0]?.actionTimetoken || undefined,
                              condition,
                              item,
                            );
                          } else {
                            addReactionToMessage(emoji, item?.timetoken, undefined, condition, item);
                          }
                        }}
                        handlePinnedMasg={() => handlePinnedMasg(item)}
                        allStateMsg={item}
                        {...item}
                      />
                    </div>
                  );
                })}
                {userScrolled && (
                  <button
                    style={{
                      position: "fixed",
                      bottom: "90px",
                      right: "20px",
                      background: "rgb(251, 207, 52)",
                      height: "30px",
                      borderRadius: "10px",
                      padding: "0px 10px",
                    }}
                    onClick={scrollToBottom}
                  >
                    Scroll to bottom
                  </button>
                )}
                <div ref={messagesEndRef} />
              </MessagesContainer>
              {/* CHAT INPUT */}
              <div className="ps-1">
                <Row style={{ background: "#f0f3f7", position: "fixed", bottom: 0, width: "79.5%" }} className="p-2" gutter={10}>
                  <Formik
                    initialValues={{ textMessage: "" }}
                    onSubmit={(value, { resetForm }) => {
                      handleSendMessage({ textMessage: value });
                      resetForm();
                    }}
                  >
                    {({ setFieldValue, values, handleSubmit }) => (
                      <Form style={{ width: "100%" }} className="" onSubmit={handleSubmit}>
                        <div className="d-flex">
                          <Col md={1} className=" d-flex align-items-center p-0 ">
                            <div
                              className="cursorPointer d-flex justify-content-evenly  w-100 "
                              onClick={() => {
                                setOpenEmoji(!openEmoji);
                              }}
                            >
                              <ChatEmoj />
                            </div>

                            <Tooltip title="Upload Image/Video">
                              <div
                                className=" ms-1 me-2 cursorPointer"
                                onClick={() => {
                                  setOpenFileModal(true);
                                }}
                              >
                                <PlusSIgn height={"15px"} width="15px" />
                              </div>
                            </Tooltip>
                            {openEmoji && (
                              <div
                                style={{
                                  position: "absolute",
                                  bottom: "100%", // Position above the toggle element
                                  left: 0, // Align to the right edge
                                  zIndex: 1000, // Ensure it appears above other elements
                                }}
                                ref={pickerRef}
                              >
                                <Picker
                                  data={pickerData}
                                  theme={"light"}
                                  onEmojiSelect={(emoji) => {
                                    // handleEmojiSelect();

                                    let emjMsg = values?.textMessage + emoji?.native;
                                    setFieldValue("textMessage", emjMsg);
                                  }}
                                />
                              </div>
                            )}
                          </Col>
                          <Col md={23} className="d-flex">
                            <Input.TextArea
                              type="text"
                              placeholder="Enter Message"
                              className="w-100"
                              value={values?.textMessage}
                              style={{
                                height: "38px",
                                padding: 5, // Remove padding
                                resize: "none", // Disable resizing
                                border: "none", // Remove border
                                outline: "none", // Remove outline
                                boxShadow: "none", // Remove box shadow
                              }}
                              onChange={(e) => setFieldValue("textMessage", e.target.value)}
                              onKeyPress={(e) => {
                                if (e.key === "Enter" && !e.shiftKey) {
                                  e.preventDefault(); // Prevent default behavior of Enter key

                                  handleSendMessage({ textMessage: values?.textMessage });
                                  setFieldValue("textMessage", "");
                                }
                              }}
                              onFocus={() => handleReadUnreadCount(chatterId, true, false)}
                            />
                            <Tooltip title="Send Direct Message">
                              <button
                                style={{
                                  height: 40,
                                  width: 40,
                                  backgroundColor: "rgb(251, 207, 52)",
                                  borderRadius: "10px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  cursor: "pointer",
                                }}
                                className="ms-3"
                                type="submit"
                              >
                                <SendMsgIcon />
                              </button>
                            </Tooltip>
                            <Tooltip title="Schedule a Message">
                              <div
                                style={{
                                  height: 40,
                                  width: 40,
                                  backgroundColor: "rgb(251, 207, 52)",
                                  borderRadius: "10px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  cursor: "pointer",
                                }}
                                className="ms-1"
                                onClick={() => setOpenScheduleModal(true)}
                              >
                                <ScheduleMsg stroke="white" />
                              </div>
                            </Tooltip>
                          </Col>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </Row>
              </div>

              {openScheduleModal && (
                <ScheduleMsgModal
                  setopenPinnedMsgModal={() => setOpenScheduleModal(false)}
                  openPinnedMsgModal={openScheduleModal}
                  onlyCompId={onlyCompId}
                  msg={inputFieldText}
                  currentChannel={currentChannel}
                  allStateMsg={{}}
                  fromSchedule={true}
                  inputField={
                    <>
                      <Col md={24} className="d-flex">
                        <Input.TextArea
                          type="text"
                          placeholder="Enter Message"
                          className="w-100"
                          value={inputFieldText}
                          style={{
                            height: "38px",
                            padding: 5, // Remove padding
                            resize: "none", // Disable resizing
                            border: "none", // Remove border
                            outline: "none", // Remove outline
                            boxShadow: "none", // Remove box shadow
                          }}
                          onChange={(e) => handleInputfieldOnChange(e.target.value)}
                          onKeyPress={(e) => {
                            if (e.key === "Enter" && !e.shiftKey) {
                              e.preventDefault(); // Prevent default behavior of Enter key
                              handleSendMessage(); // Call function to send message
                            }
                          }}
                        />
                      </Col>
                    </>
                  }
                />
              )}

              {openUploadFileModal && (
                <UploadFileModal
                  openUploadFileModal={openUploadFileModal}
                  setOpenFileModal={setOpenFileModal}
                  userId={adminId}
                  name={adminName}
                  channel={currentChannel}
                  uuidMetaData={uuidMetaData}
                  onSuccess={() => {
                    setTimeout(() => {
                      console.log("IM TRIGGERD");
                      handleFetchMessages(currentChannel);
                    }, 1000);
                    setOpenFileModal(false);
                  }}
                />
              )}
            </>
          )}
          {newChatModal && (
            <NewChatMembersModal
              addMemberModal={newChatModal}
              setOpenAddMemberModal={() => setNewChatModal(false)}
              allUsersFilter={allUsersFilter}
              getOnlyDms={getOnlyDms}
              channelMembers={channelMembers}
              adminId={adminId}
              getChatId={(id) => {
                setCurrentChannel(id);
                setChatterId(id);
              }}
              handleSendNewUserMessage={(chnlName) => {
                handleSendMessage({ newMemberChannel: chnlName, newMsg: true });
              }}
              inputFieldText={inputFieldText}
              setInputFieldText={setInputFieldText}
              adminName={adminName}
              onlyCompId={onlyCompId}
              uuid={uuid}
              handleReadUnreadCount={handleReadUnreadCount}
            />
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ChatPubnub;
const ScheduleMsg = () => {
  return (
    <>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M13.333 2.5V5.83333M6.66634 2.5V5.83333M3.33301 9.16667H16.6663M9.16634 12.5H9.99967V15M3.33301 5.83333C3.33301 5.39131 3.5086 4.96738 3.82116 4.65482C4.13372 4.34226 4.55765 4.16667 4.99967 4.16667H14.9997C15.4417 4.16667 15.8656 4.34226 16.1782 4.65482C16.4907 4.96738 16.6663 5.39131 16.6663 5.83333V15.8333C16.6663 16.2754 16.4907 16.6993 16.1782 17.0118C15.8656 17.3244 15.4417 17.5 14.9997 17.5H4.99967C4.55765 17.5 4.13372 17.3244 3.82116 17.0118C3.5086 16.6993 3.33301 16.2754 3.33301 15.8333V5.83333Z"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};
