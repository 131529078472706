import { createSlice } from "@reduxjs/toolkit";
import { main_api } from "../../api/axios_helper";

import { companyAPIsEndPoints } from "../../constants/ApiEndPoints";

const initialState = {
  userManagementList: {
    isLoading: false,
    userList: [],
  }
};

const userManagement = createSlice({
  name: "userManagement",
  initialState,
  reducers: {
    fetchAllUsersSlice(state, action) {
      state.userManagementList.isLoading = action.payload.isLoading;

      if (action.payload?.responseData) {
        state.userManagementList.userList = action.payload?.responseData;
      }
    },
  },
});

// Reducer
export default userManagement.reducer;

export const fetchAllUsersSlice = (searchTerm = "", selectedRole=null) => {
  return async (dispatch, getState) => {
    try {
      const companyId = getState().auth?.loginResponse?.selectCompany?.[0]?.company_id;
      dispatch(userManagement.actions.fetchAllUsersSlice({ isLoading: true }));
      let queryFilter = `usercompanyrole__company=${companyId}&search=${searchTerm}`;
      if(selectedRole){
        queryFilter = `usercompanyrole__company=${companyId}&search=${searchTerm}&usercompanyrole__role=${selectedRole}`;
      }

      const { data } = await main_api.get(companyAPIsEndPoints.LIST_COMPANY_USER(queryFilter));
      dispatch(userManagement.actions.fetchAllUsersSlice({ isLoading: false, responseData: data }));
    } catch (error) {
      dispatch(userManagement.actions.fetchAllUsersSlice({ isLoading: false, responseData: error }));
    }
  };
};
