import { connect } from "react-redux";
import { loadPetDetail, openDetailModal } from "../../redux/slices/petDetail";
import { loadTask, loadTasksList, loadWorkQueue, loadWorkQueueDetail, openWorkQueueDetailModal } from "../../redux/slices/tasksSlice";
import TasksManagement from "../../pages/company/tasks/tasksManagement";
import { fetchAllStaffApi } from "../../redux/slices/StaffSlice";
import { FetchClientsApi } from "../../redux/slices/ClientSlice";
import { loadCompanyUsersListByRole } from "../../redux/slices/companyUserSlice";


const mapStateToProps = (state) => {
  return {
    isLoading: state.tasksManagement.isLoading,
    error: state.tasksManagement.error,
    workQueueResponse: state.tasksManagement.workQueueResponse,
    tasksListResponse: state.tasksManagement.tasksListResponse,
    taskResponse: state.tasksManagement.taskResponse,
    allStaffState: state.staffManagement.staffAllDetails,
    clientManagementState: state.clientManangement.clientResponse,
    companyUsersListResponse: state.companyUser.companyUsersListResponse,
    workQueueDetailResponse: state.tasksManagement.workQueueDetailResponse,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadTask: (id) => {
    dispatch(loadTask(id));
  },
  loadTasksList: (search, type, status, assignee, priority) => {
    dispatch(loadTasksList(search, type, status, assignee, priority));
  },
  fetchAllStaff: () => {
    dispatch(fetchAllStaffApi());
  },
  getManagementClients: () => {
    dispatch(FetchClientsApi());
  },
  loadCompanyUsersListByRole: (roleId) => {
    dispatch(loadCompanyUsersListByRole(roleId));
  },
  loadWorkQueue: (search) => {
    dispatch(loadWorkQueue(search));
  },
  loadWorkQueueDetail : (id) => {
    dispatch(loadWorkQueueDetail(id));
  },
  openWorkQueueDetailModal: (isOpen) => {
    dispatch(openWorkQueueDetailModal(isOpen));
  },
  loadWorkQueueDetail: (id) => {
    dispatch(loadWorkQueueDetail(id));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(TasksManagement);
