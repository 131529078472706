export const DeleteConfirmationDelIcon = (props) => {
  return (
    <svg width="238" height="133" viewBox="0 0 238 133" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_317_47178)">
        <path
          d="M50.0873 132.494H13.7267C13.7267 132.494 12.9897 121.701 17.3504 121.578C21.7111 121.455 21.2197 126.361 26.6862 119.616C32.1527 112.87 38.7858 113.238 39.6457 117.224C40.5055 121.21 37.9875 124.399 42.5938 123.418C47.2002 122.436 53.8338 125.012 50.0873 132.494Z"
          fill="#E6E6E6"
        />
        <path
          d="M31.2928 132.496L31.1699 132.491C31.4562 125.385 32.9466 120.82 34.1468 118.241C35.4497 115.441 36.7052 114.307 36.7179 114.296L36.7996 114.388C36.7872 114.399 35.5464 115.521 34.2539 118.302C33.0604 120.87 31.578 125.417 31.2928 132.496Z"
          fill="white"
        />
        <path
          d="M44.4539 132.511L44.3359 132.476C45.2504 129.465 46.8988 126.727 49.1334 124.507L49.2159 124.598C46.9978 126.802 45.3616 129.521 44.4539 132.511Z"
          fill="white"
        />
        <path
          d="M19.4538 132.502L19.332 132.485C19.7284 129.821 19.309 127.1 18.1287 124.678C17.6448 123.662 17.0043 122.728 16.2305 121.911L16.3156 121.822C17.0998 122.649 17.7492 123.595 18.2399 124.623C19.4308 127.067 19.854 129.814 19.4538 132.502Z"
          fill="white"
        />
        <path
          d="M55.0225 132.658H9.80581L9.79546 132.505C9.7696 132.124 9.19265 123.15 12.1049 119.948C12.3938 119.61 12.7504 119.336 13.1519 119.144C13.5533 118.951 13.9906 118.845 14.4357 118.832C16.3339 118.778 17.5269 119.488 18.4892 120.057C20.3028 121.131 21.5007 121.841 25.857 116.466C30.5758 110.642 35.6676 109.389 38.5945 110.086C40.4858 110.536 41.7892 111.808 42.1706 113.577C42.5327 115.251 42.4168 116.825 42.3242 118.089C42.2252 119.442 42.1471 120.51 42.7036 120.994C43.1691 121.398 44.0952 121.435 45.621 121.11C48.7243 120.449 53.0555 121.014 55.2291 123.819C56.3986 125.328 57.32 128.07 55.068 132.567L55.0225 132.658ZM10.1143 132.329H54.819C56.4971 128.93 56.5496 126.058 54.9692 124.02C52.9751 121.447 48.7915 120.77 45.6895 121.431C44.0316 121.784 43.0439 121.726 42.4877 121.242C41.8073 120.65 41.8908 119.509 41.9963 118.066C42.0873 116.823 42.2003 115.275 41.8492 113.647C41.4954 112.007 40.2812 110.826 38.518 110.406C35.6844 109.731 30.7338 110.968 26.1122 116.673C21.5777 122.269 20.2115 121.46 18.3213 120.339C17.3471 119.762 16.2413 119.108 14.4448 119.159C14.0441 119.172 13.6505 119.269 13.2894 119.442C12.9283 119.616 12.6076 119.864 12.3479 120.169C9.73133 123.047 10.0505 131.116 10.1146 132.329H10.1143Z"
          fill="#212121"
        />
        <path
          d="M202.766 132.84H158.229L158.22 132.591L156.094 71.8652H204.9L202.766 132.84ZM158.728 132.324H202.266L204.365 72.3817H156.629L158.728 132.324Z"
          fill="#212121"
        />
        <path d="M168.852 83.1167H165.465V124.972H168.852V83.1167Z" fill="#212121" />
        <path d="M182.19 83.1167H178.803V124.972H182.19V83.1167Z" fill="#212121" />
        <path d="M195.528 83.1167H192.141V124.972H195.528V83.1167Z" fill="#212121" />
        <path
          d="M233.201 89.6972L232.982 89.5593L177.662 54.6538L182.461 47.0723L182.68 47.2102L238 82.1156L233.201 89.6972ZM178.376 54.4931L233.04 88.9846L237.286 82.2764L182.622 47.7846L178.376 54.4931Z"
          fill="#212121"
        />
        <path
          d="M219.845 51.8405C218.237 50.8244 216.371 50.2903 214.468 50.3014C212.565 50.3126 210.705 50.8686 209.109 51.9035C207.513 52.9383 206.248 54.4086 205.464 56.1398C204.68 57.8711 204.409 59.7906 204.685 61.6706L207.323 63.335C206.66 61.7981 206.507 60.0897 206.887 58.46C207.267 56.8302 208.159 55.3646 209.434 54.2779C210.708 53.1912 212.298 52.5403 213.969 52.4207C215.641 52.3011 217.307 52.719 218.723 53.6131C220.14 54.5073 221.233 55.8308 221.841 57.3898C222.45 58.9488 222.543 60.6615 222.106 62.277C221.67 63.8926 220.727 65.3261 219.415 66.3677C218.103 67.4094 216.492 68.0044 214.817 68.0656L217.455 69.73C219.274 69.1724 220.895 68.1054 222.125 66.6556C223.355 65.2059 224.143 63.4342 224.395 61.5508C224.647 59.6675 224.352 57.7516 223.546 56.0304C222.74 54.3092 221.456 52.8551 219.847 51.8405H219.845Z"
          fill="#212121"
        />
        <path d="M235.077 132.484H0V133H235.077V132.484Z" fill="#212121" />
        <path d="M102.678 128.96H98.9118L97.1191 114.455H102.679L102.678 128.96Z" fill="#FEB8B8" />
        <path
          d="M91.6484 132.451H103.485V127.885H96.1583C94.9622 127.885 93.8151 128.36 92.9694 129.204C92.1236 130.049 91.6484 131.194 91.6484 132.388V132.451Z"
          fill="#212121"
        />
        <path d="M101.355 106.484L103.953 109.207L115.708 100.498L111.874 96.4797L101.355 106.484Z" fill="#FEB8B8" />
        <path
          d="M106.628 110.454L101.573 105.158L98.2617 108.308L106.429 116.865L106.475 116.821C106.904 116.413 107.248 115.925 107.488 115.384C107.727 114.843 107.858 114.261 107.872 113.669C107.887 113.078 107.784 112.49 107.571 111.938C107.357 111.387 107.037 110.882 106.628 110.454Z"
          fill="#212121"
        />
        <path
          d="M75.1394 66.7461C74.901 66.3632 74.7496 65.9328 74.6958 65.4852C74.642 65.0375 74.6872 64.5836 74.8281 64.1553C74.969 63.727 75.2022 63.3347 75.5113 63.0061C75.8205 62.6774 76.198 62.4205 76.6173 62.2533L91.4044 24.6252L97.1683 28.071L80.7419 64.1687C80.9766 64.8908 80.9378 65.6739 80.6329 66.3695C80.328 67.065 79.7781 67.6247 79.0874 67.9424C78.3968 68.2601 77.6134 68.3139 76.8857 68.0934C76.158 67.8729 75.5367 67.3935 75.1394 66.7461Z"
          fill="#FEB8B8"
        />
        <path
          d="M131.267 69.4432C130.816 69.411 130.378 69.2806 129.983 69.0611C129.589 68.8416 129.247 68.5384 128.983 68.1728C128.718 67.8072 128.537 67.3881 128.453 66.9451C128.368 66.5021 128.382 66.046 128.493 65.6088L106.678 31.559L112.856 28.9253L132.468 63.4021C133.192 63.6359 133.805 64.127 134.191 64.7823C134.576 65.4376 134.708 66.2114 134.559 66.9568C134.411 67.7022 133.994 68.3673 133.387 68.8259C132.78 69.2844 132.025 69.5045 131.267 69.4442V69.4432Z"
          fill="#FEB8B8"
        />
        <path
          d="M91.5508 62.9785L95.4121 120.278L104.64 119.466L106.54 77.1911L111.696 95.3468L122.824 96.1596L118.419 60.2686L91.5508 62.9785Z"
          fill="#212121"
        />
        <path
          d="M113.324 92.637L111.695 95.3469L100.297 103.205L108.439 107.541C108.439 107.541 124.18 98.8696 122.823 96.1597L113.324 92.637Z"
          fill="#212121"
        />
        <path
          d="M83.4688 39.7952L86.6809 30.7579C87.3956 28.7471 88.5026 26.8977 89.9378 25.3169C91.373 23.736 93.108 22.455 95.0423 21.5481C99.39 19.9489 104.119 19.6904 108.615 20.8063L109.784 21.096C112.901 21.8503 115.822 23.2587 118.351 25.2273C120.331 26.7854 122.112 28.7581 122.27 30.88C122.271 30.8846 122.272 30.889 122.274 30.8932C122.822 33.2925 123.094 45.7575 123.094 45.7575H118.257L119.022 62.5584L118.961 62.4368C118.961 62.4368 91.068 67.7071 91.068 64.9972V47.6538L90.4962 41.3742L83.4688 39.7952Z"
          fill="#CCCCCC"
        />
        <path
          d="M109.505 18.4572C113.774 18.4572 117.235 15.0019 117.235 10.7396C117.235 6.47726 113.774 3.02197 109.505 3.02197C105.236 3.02197 101.775 6.47726 101.775 10.7396C101.775 15.0019 105.236 18.4572 109.505 18.4572Z"
          fill="#FEB8B8"
        />
        <path
          d="M110.662 7.27854L116.663 7.51868C117.419 7.51635 118.243 7.48976 118.818 7.00226C119.684 6.26662 119.539 4.87849 119.075 3.84152C117.782 0.954229 114.898 -0.0801547 111.731 0.00479609C108.564 0.0897469 105.245 1.16157 103.539 3.82629C101.834 6.491 101.373 9.85599 102.015 12.9524C102.758 11.3779 103.901 10.0255 105.331 9.03003C106.761 8.0346 108.427 7.43104 110.164 7.27958L110.662 7.27854Z"
          fill="#212121"
        />
        <path
          d="M146.301 82.7674L124.578 67.5659C124.376 67.4241 124.238 67.2082 124.196 66.9655C124.153 66.7228 124.208 66.4731 124.35 66.271L141.4 41.9806C141.542 41.7791 141.758 41.642 142.001 41.5993C142.244 41.5566 142.495 41.6118 142.697 41.7529L164.421 56.9544C164.622 57.0962 164.76 57.3121 164.803 57.5548C164.845 57.7975 164.79 58.0472 164.649 58.2492L147.599 82.5391C147.457 82.7408 147.24 82.8781 146.997 82.9209C146.754 82.9637 146.504 82.9085 146.301 82.7674Z"
          fill="#FBCF34"
        />
        <path
          d="M151.187 66.2727L138.44 57.3528C138.292 57.2496 138.166 57.1185 138.07 56.9668C137.973 56.8152 137.907 56.6461 137.876 56.469C137.845 56.292 137.849 56.1106 137.888 55.9351C137.927 55.7597 138 55.5936 138.104 55.4464C138.207 55.2992 138.338 55.1738 138.49 55.0773C138.642 54.9808 138.812 54.9151 138.989 54.884C139.166 54.8528 139.348 54.8569 139.524 54.8959C139.699 54.9348 139.866 55.008 140.013 55.1111L152.76 64.0311C153.058 64.2395 153.26 64.5574 153.323 64.9149C153.386 65.2724 153.305 65.6402 153.096 65.9375C152.887 66.2347 152.569 66.437 152.211 66.4999C151.853 66.5628 151.484 66.4811 151.187 66.2727Z"
          fill="white"
        />
        <path
          d="M148.262 70.4404L135.515 61.5204C135.367 61.4173 135.241 61.2862 135.145 61.1346C135.048 60.9829 134.982 60.8137 134.951 60.6366C134.919 60.4595 134.923 60.278 134.962 60.1024C135.001 59.9269 135.075 59.7607 135.178 59.6134C135.281 59.4662 135.413 59.3407 135.565 59.2441C135.717 59.1476 135.886 59.0819 136.064 59.0509C136.241 59.0198 136.423 59.0239 136.599 59.063C136.774 59.1022 136.941 59.1755 137.088 59.2788L149.835 68.1988C150.133 68.4073 150.335 68.7251 150.398 69.0824C150.461 69.4398 150.379 69.8074 150.17 70.1045C149.962 70.4015 149.643 70.6038 149.286 70.6668C148.928 70.7298 148.56 70.6484 148.262 70.4404Z"
          fill="white"
        />
        <path
          d="M145.304 74.6529L132.557 65.7329C132.409 65.6297 132.284 65.4986 132.187 65.347C132.09 65.1953 132.025 65.0262 131.993 64.8492C131.962 64.6721 131.966 64.4907 132.005 64.3153C132.044 64.1398 132.118 63.9737 132.221 63.8266C132.324 63.6794 132.456 63.5539 132.607 63.4574C132.759 63.3609 132.929 63.2952 133.106 63.2641C133.283 63.233 133.465 63.237 133.641 63.276C133.816 63.315 133.983 63.3881 134.13 63.4913L146.877 72.4113C147.175 72.6196 147.378 72.9375 147.441 73.295C147.504 73.6525 147.422 74.0203 147.213 74.3176C147.004 74.6148 146.686 74.8172 146.328 74.8801C145.97 74.9429 145.602 74.8612 145.304 74.6529Z"
          fill="white"
        />
        <path
          d="M151.151 58.0281L146.214 54.5732C146.066 54.4701 145.941 54.339 145.844 54.1873C145.747 54.0357 145.681 53.8665 145.65 53.6893C145.618 53.5122 145.622 53.3307 145.661 53.1552C145.7 52.9796 145.774 52.8134 145.877 52.6662C145.981 52.5189 146.112 52.3934 146.264 52.2969C146.416 52.2004 146.585 52.1347 146.763 52.1036C146.94 52.0725 147.122 52.0767 147.298 52.1158C147.474 52.1549 147.64 52.2282 147.787 52.3316L152.725 55.7865C153.022 55.9949 153.225 56.3127 153.288 56.6702C153.351 57.0278 153.269 57.3956 153.061 57.6929C152.852 57.9901 152.534 58.1924 152.175 58.2553C151.817 58.3182 151.449 58.2365 151.151 58.0281Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_317_47178">
          <rect width="238" height="133" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
