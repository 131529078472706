import React, { useState } from "react";
import { Form, Input } from "antd";
import { CheckCircleOutlined, EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { changePassword } from "../../../util/DataService";
import styled from "styled-components";
import CustomBtnWithIcon from "../../../components/buttonWithIcon";
import { pushNotification } from "../../../util/Notification";

const PasswordSecurityScreen = () => {
  const [form] = Form.useForm();
  const [newPassword, setNewPassword] = useState("");

  const hasMinimumLength = newPassword.length >= 8;
  const hasUpperCase = /[A-Z]/.test(newPassword);
  const hasLowerCase = /[a-z]/.test(newPassword);
  const hasNumber = /\d/.test(newPassword);

  const onFinish = (formValues) => {
    if (formValues.new_password1 !== formValues.new_password2) {
      pushNotification("Passwords do not match", "error");
      return;
    }

    if (!hasMinimumLength || !hasUpperCase || !hasLowerCase || !hasNumber) {
      pushNotification("Please enter a strong password", "error");
      return;
    }

    changePassword(formValues)
      .then(({ data }) => {
        if (data.code == 200) {
          pushNotification(data.message, "success");
          form.resetFields();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const validatePassword = (rule, value, callback) => {
    const { getFieldValue } = form;

    const password1 = getFieldValue("new_password1");
    const password2 = getFieldValue("new_password2");

    if (password1 && password2) {
      if(password1!==password2){
        callback("New Passwords do not match");
        return;
      }
    }
    callback();
  };


  return (
    <>
      <Form layout="vertical" className="w-50" onFinish={onFinish} form={form}>
        <FormItem
          label="Old Password"
          name="current_password"
          rules={[
            {
              required: true,
              message: "Old Password is required",
            },
          ]}
        >
          <Input.Password
            placeholder="Enter Password"
            iconRender={(visible) => (visible ? <EyeInvisibleOutlined /> : <EyeOutlined />)}
          />
        </FormItem>
        <FormItem
          label="New Password"
          name="new_password1"
          rules={[
            {
              required: true,
              message: "New Password is required",
            },
            { validator: validatePassword }
          ]}
        >
          <Input.Password
            onChange={(e) => {
              setNewPassword(e.target.value);
            }}
            placeholder="Enter Password"
            iconRender={(visible) => (visible ? <EyeInvisibleOutlined /> : <EyeOutlined />)}
          />
        </FormItem>
        <Text style={{ color: hasMinimumLength ? "#424242" : "#757575" }}>
          <CheckCircleOutlined style={{ color: hasMinimumLength ? "#5CB85C" : "initial" }} /> Must be a minimum of 8 characters
        </Text>
        <Text style={{ color: hasUpperCase ? "#424242" : "#757575" }}>
          <CheckCircleOutlined style={{ color: hasUpperCase ? "#5CB85C" : "initial" }} /> Must contain an upper case letter
        </Text>
        <Text style={{ color: hasLowerCase ? "#424242" : "#757575" }}>
          <CheckCircleOutlined style={{ color: hasLowerCase ? "#5CB85C" : "initial" }} /> Must contain lower case letter
        </Text>
        <Text style={{ color: hasNumber ? "#424242" : "#757575" }}>
          <CheckCircleOutlined style={{ color: hasNumber ? "#5CB85C" : "initial" }} /> Must contain at least one number
        </Text>
        <FormItem
          label="Confirm New Password"
          name="new_password2"
          className="mt-4"
          rules={[
            {
              required: true,
              message: "Confirm Password is required",
            },
            { validator: validatePassword }
          ]}
        >
          <Input.Password
            placeholder="Enter Password"
            iconRender={(visible) => (visible ? <EyeInvisibleOutlined /> : <EyeOutlined />)}
          />
        </FormItem>
      </Form>
      <CustomBtnWithIcon
        className="d-flex justify-content-end"
        btnText={"Save Changes"}
        hideIcon={true}
        onClick={() => form.submit()}
      />
    </>
  );
};

const FormItem = styled(Form.Item)`
  font-family: Helvetica Neue;
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
  text-align: left;
  & label {
    color: #3b3b3b !important;
  }
`;

const Text = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
`;

export default PasswordSecurityScreen;
