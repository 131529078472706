import { KeyTableActions } from "../KeysTableHeaders/KeyTableActions";
import Chip from "../../components/Form/Chip";
import CopyIcon from "../../assets/rawSvgs/copyIcon.svg";
import EyeIcon from "../../assets/rawSvgs/eye.svg";
import dayjs from "dayjs";
const ClientKeysColumns = ({ handleDelete = "", handleEditRow = "", handleShowModal, handleShowDuplicateKeysModal }) => {
  return [
    {
      title: "Key Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <div className="d-flex align-items-center gap-1" onClick={()=>{handleShowDuplicateKeysModal(record)}}>
          <a>{record?.name}</a>
          <img src={CopyIcon} alt="copy" />
        </div>
      ),
    },
    {
        title: "Updated On",
        dataIndex: "updated_at",
        key: "updated_at",
        render: (text, record) => <span>{dayjs(record.updated_at).format("DD/MM/YYYY • h:mm A")}</span>,
    },
    {
        title: "Type of Key",
        key: "type",
        dataIndex: "type",
  
        render: (text, record) => (
          <div className="d-flex align-items-center gap-1">
            <a className="text-capitalize">{record.type}</a>
            {record.type === "digital" && (
              <Chip Icon={EyeIcon} text="View" classes="view-chip" onClick={() => handleShowModal(record)} />
            )}
          </div>
        ),
    },
    {
      title: "Possession",
      dataIndex: "possession",
      key: "possession",
      render: (text, record) => (
        <>
          <Chip
            text={`${record?.possession === "staff" ? `${record?.staff_name} (${record?.possession})` : record?.possession}`}
            classes="possession-chip text-capitalize"
          />
        </>
      ),
    },
    ...KeyTableActions({ handleDelete, handleEditRow }),
  ];
};

export { ClientKeysColumns };
