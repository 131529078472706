import React, { useEffect, useState } from 'react'
import styled from "styled-components";
import { ReactComponent as HeaderBellIcon } from "../assets/companySvgIcons/headerBellIcon.svg";
import { ReactComponent as Notification } from "../assets/companySvgIcons/notification.svg";

import { useSelector, useDispatch } from 'react-redux';
import { companyAPIsEndPoints } from '../constants/ApiEndPoints';
import { main_api } from '../api/axios_helper';
import { setNotification } from '../redux/slices/notificationSlice';
import { Badge } from 'antd';
import { useLocation } from 'react-router-dom';
import { pushNotification } from '../util/Notification';


const AppNotification = () => {

  const { auth, notificationManagement } = useSelector((state) => state);
  const themeSettings = useSelector((state) => state.theme.themeSettings);

  const [notificationList, setNotificationList] = useState([])
  const dispatch = useDispatch();
  let pathName = useLocation().pathname;

  const isClientPortal = pathName.includes('/client');

  const commonThemeStructure = {
    logo: '',
    headerBackground: '',
    headerText: '',
    headerSecondary: '',
    footerBackground: '',
    footerText: '',
    headerSeparator: '',
    headerTitle: '',
    emailBackground: '',
    emailExcerptBackground: '',
    emailExcerptSeparator: '',
    emailFooterLink: '',
    emailHeader: '',
    emailHeaderText: '',
    emailLogo: ''
  };
  
  // Dashboard theme
  const dashboardTheme = {
    logo: themeSettings?.company_logo,
    headerBackground: themeSettings?.dashboard_header,
    headerText: themeSettings?.dashboard_header_font,
    headerSeparator: themeSettings?.dashboard_header_separator,
    headerTitle: themeSettings?.dashboard_header_title,
  };
  
  // Client portal theme
  const clientPortalTheme = {
    logo: themeSettings?.company_logo,
    headerBackground: themeSettings?.clientportal_header_background,
    headerText: themeSettings?.clientportal_header_text,
    headerSecondary: themeSettings?.clientportal_header_secondary,
    footerBackground: themeSettings?.clientportal_footer_background,
    footerText: themeSettings?.clientportal_footer_text,
  };
  
  // Email theme
  const emailTheme = {
    emailBackground: themeSettings?.email_background,
    emailExcerptBackground: themeSettings?.email_excerpt_background,
    emailExcerptSeparator: themeSettings?.email_excerpt_separator,
    emailFooterLink: themeSettings?.email_footer_link,
    emailHeader: themeSettings?.email_header,
    emailHeaderText: themeSettings?.email_header_text,
    emailLogo: themeSettings?.email_logo,
  };
  
 
  const theme = {
    ...commonThemeStructure,
    ...(isClientPortal ? clientPortalTheme : dashboardTheme),
    ...emailTheme
  };

  const getNotification = () => {

    if(auth?.loginResponse?.selectedRoleType ==='company_type'){
        const companyId = auth?.loginResponse?.selectCompany?.[0]?.company_id;
        const user_id = auth?.loginResponse?.LoggedInUserData?.id;
        main_api.get(companyAPIsEndPoints.NOTIFICATION(`company_admin_id=${user_id}`))
        .then(({ data }) => {
            setNotificationList(data.result)
        })
        .catch((error) => {
          pushNotification("Something went wrong!", "error");
          // console.log(error);
        });
    } else if(auth?.loginResponse?.selectedRoleType ==='client_type'){
        const client_id = auth.loginResponse?.selectCompany?.filter((item) => item.client_id !== null)[0].client_id;
        main_api.get(companyAPIsEndPoints.NOTIFICATION(`client_id=${client_id}`))
        .then(({ data }) => {
            setNotificationList(data.result)
        })
        .catch((error) => {
          pushNotification("Something went wrong!", "error");
          // console.log(error);
        });
    } else if (auth?.loginResponse?.selectedRoleType ==='company_user_type'){
      const userId = auth?.loginResponse?.companyUserId;
      main_api.get(companyAPIsEndPoints.NOTIFICATION(`company_user_id=${userId}`))
        .then(({ data }) => {
            setNotificationList(data.result)
        })
        .catch((error) => {
          pushNotification("Something went wrong!", "error");
          // console.log(error);
        });
    }

  }

  useEffect(()=>{
    getNotification()
  },[])

  const markasRead = async(notification, index) => {
    if(!notification.read){
      const { data } = await main_api.put(companyAPIsEndPoints.UPDATE_NOTIFICATION(notification.id), {read: true});
      if (data.isSuccess) {
        const updatedList = [...notificationList];
        const updatedItem = { ...updatedList[index] };
        updatedItem["read"] = true;
        updatedList[index] = updatedItem;
        setNotificationList(updatedList);
        dispatch(setNotification({ unreadCount: notificationManagement.unreadCount-1 }));
      }
    }
  }

  return (
    <>
     <div style={{width: '400px'}}>
        <NotificationHeader style={{background:`${theme.headerBackground}`}}>
            <NotificationHeading style={{color:`${theme.headerTitle}`}}>
            Notifications <Badge count={notificationManagement.unreadCount} color="#fbcf34"></Badge>
            </NotificationHeading>
            <MarkasRead style={{color:`${theme.headerTitle}`}}>
            Mark all as read
            </MarkasRead>
        </NotificationHeader>

        {notificationList.length > 0 ?
          <NotificatonList style={{background:`${theme.headerBackground}`}}>
            {notificationList.map((notification,index) => (
                <NotificationItem onClick={()=>markasRead(notification, index)}>
                    <div><Notification /></div>
                    <NotificationDetail>
                      <NotificationTitle style={{color:`${theme.headerText}`}}>
                        {notification.title} {!notification.read && <Badge dot={true} color="#12B76A"></Badge>}
                      </NotificationTitle>
                      <div style={{color:`${theme.headerText}`}}>
                        {notification.message}
                      </div>
                    </NotificationDetail>
                </NotificationItem>
            ))}
          </NotificatonList>
          :
          <EmptyNotificatonList>
            No Notification Found
          </EmptyNotificatonList>
        }

        
    </div>

    </>     
  )
}


export default AppNotification;

const MarkasRead = styled.button`
  font-weight: 500;
  font-size: 14px;
`;

const NotificationHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 15px;
`

const NotificationItem = styled.div`
  display: flex;
  align-items: flex-start;
  margin: 10px 0;
  gap: 10px;
  cursor: pointer;
`

const NotificationDetail = styled.div`
  display: flex;
  flex-direction: column;
`

const NotificationTitle = styled.div`
    font-weight: 500;
    display: flex;
    justify-content: space-between;

`

const NotificationHeading = styled.div`
    font-weight: 700;
`

const NotificatonList = styled.div`
    max-height: 500px;
    overflow-y: scroll;
    padding: 0 15px;
    &::-webkit-scrollbar {
      width: 0;
    }
`

const EmptyNotificatonList = styled.div`
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
`