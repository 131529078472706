import React, { useEffect, useState } from "react";
import { Col, Form, Input, Row } from "antd";
import styled from "styled-components";
import CustomBtnWithIcon from "../../components/buttonWithIcon";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import FontPicker from "../FontPicker/fontPicker";
import ColorPicker from "../ColorPicker/colorpicker";
import { availableFonts, initialColors } from "../../util/colorAndFonts";
export default function EmailTheme({ emailLayoutData, themeData, handleNext, handleBack, finishOnboarding }) {
  const navigate = useNavigate();
  const [selectedEmailHeaderColor, setSelectedEmailHeaderColor] = useState("");
  const [selectedTextBackgroundColor, setSelectedTextBackgroundColor] = useState("");
  const [selectedTextSeparatorColor, setSelectedTextSeparatorColor] = useState("");
  const [selectedFooterLinkColor, setSelectedFooterLinkColor] = useState("");
  const [selectedEmailHeaderTextFontColor, setSelectedEmailHeaderTextFontColor] = useState("");

  const [initialColors, setInitialColors] = useState([
    "#FF5733",
    "#33FF57",
    "#3357FF",
    "#F1C40F",
    "#8E44AD",
    "#2C3E50",
    "#ffffff",
  ]);

  const [form] = Form.useForm();

  const onFinish = (formValues) => {
    // setJWTToken(state.token);
    // getRolePermissions()
    // formValues['is_seen_onboarding'] = true
    // updateCompanyDetails(state.selectedComid, formValues)
    // .then(({data}) => {
    //   if(data.code == 200) {
    //     navigate("/company/");
    //   }
    // })
    // .catch((error) => {
    //   pushNotification("Something Went Wrong!", "error");
    // })
    formValues.emailHeaderColor = selectedEmailHeaderColor || "";
    formValues.emailHeaderTextFontColor = selectedEmailHeaderTextFontColor || "";
    formValues.emailTextBackgroundColor = selectedTextBackgroundColor || "";
    formValues.emailTextSeparatorColor = selectedTextSeparatorColor || "";
    formValues.footerLinkColor = selectedFooterLinkColor || "";
    const data = {
      email_header: formValues.emailHeaderColor,
      email_header_text: formValues.emailHeaderTextFontColor,
      email_excerpt_background: formValues.emailTextBackgroundColor,
      email_excerpt_separator: formValues.emailTextSeparatorColor,
      email_background: formValues.emailTextBackgroundColor,
      email_footer_link: formValues.footerLinkColor,
    };

    finishOnboarding(data);

    // handleNext();
  };

  return (
    <>
      <FormHeader>Email Theme</FormHeader>
      <FormDescriptionText>Set your email theme</FormDescriptionText>
      <Form form={form} name="emailTheme" onFinish={onFinish} layout="vertical">
        {emailLayoutData?.email_header && (
          <Form.Item name="emailHeaderColor" label="Header Background Color">
            <ColorPicker
              initialColors={initialColors}
              setInitialColors={setInitialColors}
              selectedColorData={themeData?.email_header}
              setValue={setSelectedEmailHeaderColor}
              disabled={!emailLayoutData?.email_header}
            />
            <FormDescriptionText>Choose color theme to be shown on the email</FormDescriptionText>
          </Form.Item>
        )}
        {emailLayoutData?.email_header_text && (
          <FormItem name="emailHeaderTextFontColor" label="Header Text Color">
            <ColorPicker
              initialColors={initialColors}
              setInitialColors={setInitialColors}
              selectedColorData={themeData?.email_header_text}
              setValue={setSelectedEmailHeaderTextFontColor}
              disabled={!emailLayoutData?.email_header_text}
            />
            <FormDescriptionText>Choose color theme to be shown on the email</FormDescriptionText>

            {/* <FontPicker fonts={availableFonts} selectedFont={selectedHeaderTextFont} setSelectedFont={setSelectedHeaderTextFont} disabled={!emailLayoutData?.email_header_text}/> */}
          </FormItem>
        )}

        {emailLayoutData?.email_background && (
          <Form.Item name="textBackgroundColor" label="Text Background Color">
            <ColorPicker
              initialColors={initialColors}
              setInitialColors={setInitialColors}
              selectedColorData={themeData?.email_excerpt_background}
              setValue={setSelectedTextBackgroundColor}
              disabled={!emailLayoutData?.email_excerpt_background}
            />

            <FormDescriptionText>Choose color theme to be shown on the email</FormDescriptionText>
          </Form.Item>
        )}

        {emailLayoutData?.email_excerpt_separator && (
          <Form.Item name="textSeparatorColor" label="Text Separator Color">
            <ColorPicker
              initialColors={initialColors}
              setInitialColors={setInitialColors}
              selectedColorData={themeData?.email_excerpt_separator}
              setValue={setSelectedTextSeparatorColor}
              disabled={!emailLayoutData?.email_excerpt_separator}
            />

            <FormDescriptionText>Choose color theme to be shown on the email</FormDescriptionText>
          </Form.Item>
        )}

        {emailLayoutData?.email_footer_link && (
          <Form.Item name="footerLinkColor" label="Footer Link Color">
            <ColorPicker
              initialColors={initialColors}
              setInitialColors={setInitialColors}
              selectedColorData={themeData?.email_footer_link}
              setValue={setSelectedFooterLinkColor}
              disabled={!emailLayoutData?.email_footer_link}
            />

            <FormDescriptionText>Choose color theme to be shown on the email</FormDescriptionText>
          </Form.Item>
        )}

        <CustomBtnWithIcon btnText={"Finish"} type="submit" style={{ width: "100%" }} hideIcon={true} />
        <CustomBtnWithIcon
          btnText={"Back"}
          margin="10px 0px"
          style={{ width: "100%" }}
          noBackground
          hideIcon={true}
          onClick={() => handleBack()}
        />
      </Form>
    </>
  );
}

const FormHeader = styled.h1`
  font-family:
    Helvetica Neue,
    Arial;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #000000;
  margin-top: 24px;
`;

const FormDescriptionText = styled.p`
  font-family: "Helvetica Neue", Arial;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #757575;
`;

const FieldWraper = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
`;

const FormWraper = styled.div`
  height: 556px;
  overflow: auto;

  ::-webkit-scrollbar {
    width: 0px;
  }
`;

const FurtherDetails = styled.div`
  span {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: #757575;
  }
`;

const FormItem = styled(Form.Item)`
  font-family: Helvetica Neue;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #3b3b3b;
  width: 100%;
`;
