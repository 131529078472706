const LockIcon = (
  <>
    <path
      d="M17 10H3C1.89543 10 1 10.8954 1 12V19C1 20.1046 1.89543 21 3 21H17C18.1046 21 19 20.1046 19 19V12C19 10.8954 18.1046 10 17 10Z"
      fill="#D9D9D9"
      stroke="#D9D9D9"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5 10V6C5 4.67392 5.52678 3.40215 6.46447 2.46447C7.40215 1.52678 8.67392 1 10 1C11.3261 1 12.5979 1.52678 13.5355 2.46447C14.4732 3.40215 15 4.67392 15 6V10"
      stroke="#D9D9D9"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </>
)

export default LockIcon
