import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";

import { Col, Row, Tag } from "antd";
import dayjs from "dayjs";
import styled from "styled-components";

import { useNavigate } from "react-router-dom";
import { ReactComponent as LessThanIcon } from "../../../../assets/rawSvgs/lessThanBlack.svg";

import { ReactComponent as TreasureIcon } from "../../../../assets/rawSvgs/treasureIcon.svg";
import { ReactComponent as StatusIcon } from "../../../../assets/rawSvgs/staffIcon.svg";

import { ReactComponent as GeneralSchedulerIcon } from "../../../../assets/companySvgIcons/sidebarIcons/generalSchedulerIcon.svg";
import { ReactComponent as ManagementClientsicon } from "../../../../assets/companySvgIcons/sidebarIcons/managementClientsIcon.svg";
//

import { HeaderContainer, UserNameWithIconWrapper, UserDetailIconTextWrapper } from "./ClientDetailsStyles";

import {
  tagStyle,
  getInvoiceStatusStyles,
  invoicingDetailService,
} from "../../../../constants/CompanyTableHeaders/invoicingTableHeaders";

import HeadingComponent from "../../../../components/Heading";
import { AntdesignTable } from "../../../../components/antDesignTable/AntdesignTable";

import { checkPermission } from "../../../../util/commonUtil";
import SearchInput from "../../../../components/SearchInput";
import NoDataScreen from "../../../../components/NoDataScreen";

//
function ClientsDetail({
  singleClientresponseData,
  permissionState,
  invoiceSingleDetail,
  getManagementClients,
  fetchSingleInvoice,
}) {
  const navigate = useNavigate();
  const [seaerchQuery, setSeaerchQuery] = useState("");
  let { invoiceId: paramid } = useParams();
  let invoiceId = parseInt(paramid);
  useEffect(() => {
    window.scroll(0, 0);
  }, [invoiceId]);

  useEffect(() => {
    fetchSingleInvoice(invoiceId);
    getManagementClients();
    // fetchAllInvoice();
  }, []);

  let invoiceData = invoiceSingleDetail?.responseData?.invoice || invoiceSingleDetail.responseData?.inovice;
  let invoiceDataServices = invoiceSingleDetail?.responseData?.result || [];
  // console.log("invoiceSingleDetail", invoiceSingleDetail.isLoading);
  const filterResults = invoiceDataServices?.filter((item) =>
    item?.single_service_request?.service?.name.toLowerCase().includes(seaerchQuery.toLowerCase()),
  );
  return checkPermission(permissionState, "view_client") ? (
    <>
      {invoiceSingleDetail.isLoading ? (
        "Loading..."
      ) : (
        <>
          <HeaderContainer>
            <UserNameWithIconWrapper>
              <div className="d-flex align-items-end">
                <button onClick={() => navigate(-1)}>
                  <LessThanIcon width="13px" height="13px" />
                </button>
                <HeadingComponent
                  text={"Invoice # " + invoiceData?.id || ""}
                  fontSize="1.25rem"
                  fontWeight={700}
                  margin="0px 15px"
                />
              </div>
            </UserNameWithIconWrapper>
            <UserDetailIconTextWrapper>
              <Row gutter={20}>
                <Col md={12}>
                  <IconWithTextHeaderComponent icon={<ManagementClientsicon />} text={invoiceData?.client?.name || "---"} />
                </Col>
                <Col md={12}>
                  <IconWithTextHeaderComponent
                    icon={<StatusIcon />}
                    text={
                      <Tag className="text-capitalize" style={{ ...tagStyle, ...getInvoiceStatusStyles(invoiceData?.status) }}>
                        {invoiceData?.status === "sent_to_client" ? "Sent to client" : invoiceData?.status || "---"}
                      </Tag>
                    }
                  />
                </Col>
                <Col md={12}>
                  <IconWithTextHeaderComponent
                    icon={<GeneralSchedulerIcon />}
                    text={dayjs(invoiceData?.created_at).format("DD/MM/YYYY") || "---"}
                  />
                </Col>
                <Col md={12}>
                  <IconWithTextHeaderComponent
                    icon={<TreasureIcon />}
                    text={`$ ${singleClientresponseData?.balance || "0.00"} Balance`}
                  />
                </Col>
              </Row>
            </UserDetailIconTextWrapper>
          </HeaderContainer>
          <div>
            <Row style={{ alignItems: "center" }}>
              <Col lg={12}>
                <PetsHeaderSection>
                  <SearchInput
                    placeholder="Type to search.."
                    search={seaerchQuery}
                    onChange={(value) => setSeaerchQuery(value)}
                    // onBlur={searchBlurHandler}
                  />
                </PetsHeaderSection>
              </Col>
            </Row>
          </div>
          <div>
            <AntdesignTable
              columns={invoicingDetailService({})}
              data={filterResults || []}
              // loading={invoicingState?.isLoading}
            />
          </div>
        </>
      )}
    </>
  ) : (
    <>
      <NoDataScreen heading="You don’t have acces to view Client Detail" showBtn={false} />
    </>
  );
}

export const IconWithTextHeaderComponent = ({ icon, text }) => {
  return (
    <div className="d-flex align-items-baseline mt-3 me-4 ms-1">
      <span>{icon}</span>
      <HeadingComponent text={text} fontSize="0.875rem" fontWeight={400} margin="0px 6px" />
    </div>
  );
};

export default React.memo(ClientsDetail);

const PetsHeaderSection = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 10px;
`;
const PetsCardWrapperDiv = styled.div`
  width: 100%;
  margin-top: 15px;
`;
