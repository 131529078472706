import { Checkbox, Col, Drawer, Row, Segmented, Select, Radio, TimePicker, DatePicker } from "antd";
import React, { useState } from "react";
import locale from "antd/es/date-picker/locale/de_DE";
import { Formik, Field, Form, FieldArray, useFormikContext } from "formik";
import dayjs from "dayjs";

//

import HeadingComponent from "../../../../../../components/Heading";

import { ReactComponent as PlusYellowIcon } from "../../../../../../assets/rawSvgs/yelowplus.svg";
import { ReactComponent as RemoveYellowIcon } from "../../../../../../assets/rawSvgs/declineIcon.svg";

import { validationSchemaDiffDays } from "../helpers";
import { convertTo12HourFormat } from "../../../../../../util/formatTime";
import { RequiredSignComponent } from "../../../addNewStaffModal";
import moment from "moment";

const { RangePicker } = DatePicker;

const updateVal = {
  1: [],
  2: [],
  3: [],
  4: [],
  5: [],
  6: [],
  7: [],
};

export const DifferntTimeEachDayForm = React.memo(
  ({ updateForm, updateConfivalues, handleSubmit, innerRef, dateValidationError }) => {
    const daysInitialVales = {
      1: [],
      2: [],
      3: [],
      4: [],
      5: [],
      6: [],
      7: [],
    };
    const dateFormat = "YYYY-MM-DD";

    const { forever, same_time, start_date, end_date, ...rest } = updateConfivalues;

    function getNumericKeysWithNonEmptyArrays(obj) {
      return Object.keys(obj)
        .filter((key) => !isNaN(Number(key)) && Array.isArray(obj[key]) && obj[key].length > 0)
        .map(Number);
    }
    const keysWithNonEmptyArrays = getNumericKeysWithNonEmptyArrays(rest);

    const convertTimeTo12HourFormat = (data) => {
      const convertedTimes = Object?.keys(data)?.reduce((result, key) => {
        const items = data[key];

        const convertedItems = items?.map((item) => ({
          start_time: dayjs(dayjs(convertTo12HourFormat(item?.start_time), "h:mm A"), "ddd, DD MMM YYYY HH:mm:ss [GMT]").format(
            "h:mm a",
          ),
          end_time: dayjs(dayjs(convertTo12HourFormat(item?.end_time), "h:mm A"), "ddd, DD MMM YYYY HH:mm:ss [GMT]").format(
            "h:mm a",
          ),
        }));
        result[key] = convertedItems;
        return result;
      }, {});

      return convertedTimes || [];
    };

    const newData = convertTimeTo12HourFormat(rest || []);
    const is1990 = (date) => dayjs(date).year() === 1990;

    let isForever = start_date && end_date ? is1990(start_date) && is1990(end_date) : true;
    return (
      <div>
        <Formik
          initialValues={{
            days: !updateConfivalues.same_time ? newData : daysInitialVales,
            selectedDays: !updateConfivalues.same_time ? keysWithNonEmptyArrays : [],
            forever: isForever,
            start_date: start_date && !isForever ? start_date : dayjs().format("YYYY-MM-DD"),
            end_date: start_date && !isForever ? end_date : dayjs().format("YYYY-MM-DD"),
          }}
          validationSchema={validationSchemaDiffDays}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
          enableReinitialize
          innerRef={innerRef}
        >
          {({ values, setFieldValue, errors }) => (
            <Form>
              <>
                {daysArray.map((day, index) => {
                  return (
                    <>
                      <div
                        key={index}
                        className="my-1 d-flex align-items-center px-2"
                        style={{ height: "45px", background: "rgb(238, 238, 238)", borderRadius: "7px" }}
                      >
                        <Checkbox
                          defaultChecked={values?.selectedDays.includes(day.value)}
                          onChange={(e) => {
                            let selectedDays = values.selectedDays || [];

                            if (e.target.checked) {
                              setFieldValue(`days.${day.value}`, [
                                ...values.days[day.value],
                                { start_time: null, end_time: null },
                              ]);
                              selectedDays = [...selectedDays, day.value];
                            } else {
                              setFieldValue(`days.${day.value}`, []);

                              selectedDays = selectedDays.filter((value) => value !== day.value);
                            }
                            setFieldValue("selectedDays", selectedDays);
                          }}
                        >
                          {day.label}
                        </Checkbox>
                      </div>

                      <FieldArray name={`days.${day.value}`}>
                        {({ push, remove }) => (
                          <div>
                            {values.days[day.value]?.map((timeSlot, timeIndex) => (
                              <div key={timeIndex}>
                                <>
                                  <Row align={"bottom"}>
                                    <Col lg={10} style={{ position: "relative" }}>
                                      <div className="d-flex">
                                        <RequiredSignComponent />
                                        <HeadingComponent text="Start Time" fontSize={"12px"} margin="7px 0px" />
                                      </div>

                                      <TimePicker
                                        // value={dayjs(timeSlot?.start_time, "h:mm a")}

                                        {...(timeSlot?.start_time !== null
                                          ? {
                                              value: dayjs(timeSlot?.start_time, "h:mm a"),
                                            }
                                          : { defaultOpenValue: moment().hour(0).minute(0) })}
                                        onChange={(value, dateString) =>
                                          setFieldValue(`days.${day.value}.${timeIndex}.start_time`, dateString)
                                        }
                                        format="h:mm a"
                                        className="w-100"
                                        placeholder="Select Start Time"
                                        allowClear={false}
                                      />

                                      {errors.days?.[index + 1]?.[timeIndex]?.start_time && (
                                        <div className="input-error-style" style={{ position: "absolute", top: "100%", left: 0 }}>
                                          {errors.days?.[index + 1]?.[timeIndex]?.start_time}
                                        </div>
                                      )}
                                    </Col>
                                    <Col md={2} className="d-flex justify-content-center">
                                      <span>-</span>
                                    </Col>
                                    <Col
                                      md={10}
                                      className="d-flex justify-content-end flex-column"
                                      style={{ position: "relative" }}
                                    >
                                      <div className="d-flex">
                                        <RequiredSignComponent />
                                        <HeadingComponent text="End Time" fontSize={"12px"} margin="7px 0px" />
                                      </div>
                                      <TimePicker
                                        {...(timeSlot?.end_time !== null
                                          ? {
                                              value: dayjs(timeSlot?.end_time, "h:mm a"),
                                            }
                                          : { defaultOpenValue: moment().hour(0).minute(0) })}
                                        // value={dayjs(timeSlot?.end_time, "h:mm a") || currentTime}
                                        onChange={(value, dateString) =>
                                          setFieldValue(`days.${day.value}.${timeIndex}.end_time`, dateString)
                                        }
                                        format="h:mm a"
                                        className="w-100"
                                        placeholder="Select End Time"
                                        allowClear={false}
                                      />
                                      {errors.days?.[index + 1]?.[timeIndex]?.end_time && (
                                        <div className="input-error-style" style={{ position: "absolute", top: "100%", left: 0 }}>
                                          {errors.days?.[index + 1][timeIndex].end_time}
                                        </div>
                                      )}
                                    </Col>
                                  </Row>

                                  <div className="d-flex align-items-center my-2 mt-4">
                                    {timeIndex === values?.days[day?.value]?.length - 1 && (
                                      <button
                                        type="button"
                                        className="mt-2 fw-bolder d-flex align-items-center"
                                        onClick={() => push({ start_time: null, end_time: null })}
                                        disabled={values.days[index + 1]?.length === 4}
                                      >
                                        <div
                                          style={{
                                            width: "13px",
                                            height: "13px",
                                            background: "rgb(251, 207, 52)",
                                            borderRadius: "50%",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            padding: "2px",
                                          }}
                                        >
                                          <PlusYellowIcon />
                                        </div>
                                        <span style={{ fontSize: "12px" }} className="ms-2">
                                          Add More
                                        </span>
                                      </button>
                                    )}
                                    <button
                                      type="button"
                                      className="mt-2 fw-bolder d-flex align-items-center"
                                      onClick={() => {
                                        remove(timeIndex);
                                      }}
                                      disabled={values.days[index + 1]?.length === 1}
                                    >
                                      <div
                                        style={{
                                          borderRadius: "50%",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          padding: "2px",
                                        }}
                                      >
                                        <RemoveYellowIcon />
                                      </div>
                                      <span style={{ fontSize: "12px" }} className="ms-2">
                                        Remove
                                      </span>
                                    </button>
                                  </div>
                                </>
                              </div>
                            ))}
                          </div>
                        )}
                      </FieldArray>
                    </>
                  );
                })}
              </>
              {errors?.selectedDays && <div className="input-error-style">{errors?.selectedDays}</div>}
              <div className="my-2 mt-3 ">
                <HeadingComponent text="Set this availability for" fontSize={"0.75rem"} margin="5px 0px" />
                <Radio.Group
                  defaultValue={values.forever}
                  onChange={(e) => {
                    if (e.target.value) {
                      setFieldValue("start_date", dayjs().format("YYYY-MM-DD"));
                      setFieldValue("end_date", dayjs().format("YYYY-MM-DD"));
                    }
                    setFieldValue("forever", e.target.value);
                  }}
                >
                  <Radio value={true}>Forever</Radio>
                  <Radio value={false}>Temporary</Radio>
                </Radio.Group>
              </div>

              {!values.forever && (
                <Row align={"bottom"}>
                  <Col md={24}>
                    <RangePicker
                      onChange={(e, dateString) => {
                        const dayid = dayjs(dateString, dateFormat);

                        if (dayid) {
                          setFieldValue("start_date", dateString[0]);
                          setFieldValue("end_date", dateString[1]);
                        }
                      }}
                      format="YYYY-MM-DD"
                      placeholder={["Start Date", "End Date"]}
                      label="When"
                      name="dates"
                      type="text"
                      {...(updateForm
                        ? {
                            defaultValue: [dayjs(values?.start_date, dateFormat), dayjs(values?.end_date, dateFormat)],
                          }
                        : {})}
                      className=" mb-2 w-100"
                      // value={values?.holiday_date}
                    />
                    {(errors?.end_date || errors?.start_date) && (
                      <div className="input-error-style">Please select start and end date</div>
                    )}
                  </Col>

                  {dateValidationError && (
                    <div className="input-error-style">Recurring days must be in the specified date range</div>
                  )}
                </Row>
              )}
            </Form>
          )}
        </Formik>
      </div>
    );
  },
);

const daysArray = [
  {
    value: 1,
    label: "Monday",
  },
  {
    value: 2,
    label: "Tueday",
  },
  {
    value: 3,
    label: "Wednesday",
  },
  {
    value: 4,
    label: "Thursday",
  },
  {
    value: 5,
    label: "Friday",
  },
  {
    value: 6,
    label: "Saturday",
  },
  {
    value: 7,
    label: "Sunday",
  },
];
