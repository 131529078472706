import React, { useEffect, useState } from "react";
import PersonalInformation from "../../AcccountSettings/personalInformation";
import { CustomModal } from "../ModalStyles";
import AntDesignNavTabs from "../../antDesignNaviTabs/AntDesignNavTabs";
import ResetPassword from "../../AcccountSettings/resetPassword";
import styled from "styled-components";
import { Divider } from "antd";
import CompanySettings from "../../../components/AcccountSettings/personalInfoComp";
import { useSelector } from "react-redux";
import { main_api } from "../../../api/axios_helper";
import { companyAPIsEndPoints } from "../../../constants/ApiEndPoints";
import { pushNotification } from "../../../util/Notification";
import CustomBtnWithIcon from "../../buttonWithIcon";
import AccountSettingsScreen from "../../../pages/client/settings/AccountSettingsScreen";
import { DeleteSingleClientsApi, fetchLoggedInClientApi, updateSingleClientsApi } from "../../../redux/slices/ClientSlice";
import { useDispatch } from "react-redux";

export default function AccountSettingsModal({ isModalOpen, setModalOpen }) {
  const [activeTabNavigation, setActiveTabNavigation] = useState(1);
  const [selectedCompany, setSelectedCompany] = useState();
  const [territoryZipCodes, setTerritoryZipCodes] = useState([]);
  const auth = useSelector((state) => state.auth);
  const company_id = auth.loginResponse?.selectCompany?.[0]?.company_id || null;
  let isPlatform = auth.loginResponse.is_platform_admin;
  let isClientPortal = auth?.loginResponse?.selectedRoleType === "client_type" ? true : false;

  let dispatch = useDispatch();
  useEffect(() => {
    if (company_id) {
      main_api
        .get(companyAPIsEndPoints.FETCH_SINGLE_COMPANY(company_id))
        .then(({ data }) => {
          setSelectedCompany(data.result);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  const handleChanetabs = (key) => {
    setActiveTabNavigation(parseInt(key));
  };

  const handleCompanySettings = (data) => {
    if (data) {
      console.log("data sud", data);

      main_api
        .put(companyAPIsEndPoints.UPDATE_SINGLE_COMPANY(company_id), data)
        .then(({ data }) => {
          if (data.isSuccess) {
            setSelectedCompany({
              ...data.result,
              company_admins: [
                {
                  email:
                    selectedCompany &&
                    selectedCompany.company_admins &&
                    selectedCompany.company_admins[0] &&
                    selectedCompany.company_admins[0].email,
                },
              ],
            });
            pushNotification(data.message, "success");
            setModalOpen(false);
          }
        })
        .catch((error) => {
          console.log(error);
          pushNotification("Something Went Wrong!", "error");
        });
    }
  };

  const navigationTabs = (PersonalInformation, ResetPassword) => {
    return [
      {
        key: "1",
        label: "Personal Information",
        children: PersonalInformation,
      },
      {
        key: "2",
        label: "Reset Password",
        children: ResetPassword,
      },
    ];
  };

  const navTabs = navigationTabs(<></>, <></>);

  let objRenderer = {
    1: (
      <TabContainer isPlatform={isPlatform}>
        {!isPlatform ? (
          <CompanySettings
            selectedCompany={selectedCompany}
            handleCompanySettings={handleCompanySettings}
            territoryZipCodes={territoryZipCodes}
            setTerritoryZipCodes={setTerritoryZipCodes}
            hideSaveBtn={true}
          />
        ) : (
          <div style={{ width: "70%" }}>
            <PersonalInformation setModalOpen={setModalOpen} isPlatform={isPlatform} />
          </div>
        )}
      </TabContainer>
    ),
    2: (
      <TabContainer ResetPass={true}>
        <ResetPassword setModalOpen={setModalOpen} />
      </TabContainer>
    ),
  };

  let props = {
    fetchLoggedInClientInfo: () => {
      dispatch(fetchLoggedInClientApi());
    },
    deleteSingleClient: (id) => {
      dispatch(DeleteSingleClientsApi(id));
    },
    updateSingleClient: (data) => {
      dispatch(updateSingleClientsApi(data));
    },
    clientSingleDetails: useSelector((state) => state?.clientManangement.clientSingleDetails),
    clientDeleteResponse: useSelector((state) => state?.clientManangement.clientDeleteResponse),
    clientEditState: useSelector((state) => state?.clientManangement.clientEditResponse),
    fromClientPortal: isClientPortal,
  };
  return (
    <CustomModal
      open={isModalOpen}
      title="Account Settings"
      width="880px"
      heigth="728px"
      onCancel={() => {
        setModalOpen(false);
      }}
      footer={null}
    >
      {isClientPortal ? (
        <AccountSettingsScreen props={props} />
      ) : (
        <>
          <Divider />

          <AntDesignNavTabs navTabs={navTabs} onChange={handleChanetabs} />
          {objRenderer[activeTabNavigation]}
        </>
      )}
    </CustomModal>
  );
}

const TabContainer = styled.div`
  ${"" /* min-height:(props)=> `${props.ResetPass ? "400px" : "550px"}`; */}
  min-height: ${(props) => (props.ResetPass ? "500px" : props.isPlatform ? "410px" : "550px")};
  height: 340px;
  overflow-y: auto;
  margin-bottom: 15px;
`;
