import { Space, Tag } from "antd";
import { ReactComponent as DeleteIcon } from "../../assets/rawSvgs/tableDeleteIcon.svg";
import { ReactComponent as DownloadIcon } from "../../assets/rawSvgs/download.svg";
import { convertTo12HourFormat } from "../../util/formatTime";
import { ReactComponent as DeclineIcon } from "../../assets/rawSvgs/declineIcon.svg";
import { ReactComponent as TickIcon } from "../../assets/rawSvgs/tickIcon.svg";
import dayjs from "dayjs";
import styled from "styled-components";
import { convertToDayNames } from "../../util/commonUtil";

const ClientListColumn = ({ }) => {
    return [
      {
        title: 'Client Name',
        dataIndex: 'client_name',
        key: 'client_name',
        render: (text, record) => (
          <div>{record.name}</div>
        ),
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        render: (text, record) => (
          <div>{record?.user?.email || ''}</div>
        ),
      },
      {
        title: 'Zip Code',
        dataIndex: 'zip_code',
        key: 'zip_code',
        render: (text, record) => (
          <div>{record?.zip || ''}</div>
        ),
      }
          
    ];
};


const StaffListColumn = ({ }) => {
  return [
    {
      title: 'Staff Name',
      dataIndex: 'staff_name',
      key: 'staff_name',
      render: (text, record) => (
        <div>{record.name}</div>
      ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (text, record) => (
        <div>{record.staff_email}</div>
      ),
    },
    {
      title: 'Zip Code',
      dataIndex: 'zip_code',
      key: 'zip_code',
      render: (text, record) => (
        <div>{record?.zip || ''}</div>
      ),
    }
        
  ];
};


const ServiceRequestListColumn = ({ }) => {
    return [
      {
        title: 'Client',
        dataIndex: 'client_name',
        key: 'client_name',
        render: (text, record) => (
          <div>{record?.client?.name || ''}</div>
        ),
      },
      {
        title: 'Service',
        dataIndex: 'service',
        key: 'service',
        render: (text, record) => (
          <div>{record?.service?.name || ''}</div>
        ),
      },
      {
        title: 'Staff',
        dataIndex: 'staff',
        key: 'staff',
        render: (text, record) => (
          <div>{record?.staff?.name || ''}</div>
        ),
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (text, record) => (
          <div>{record?.status || ''}</div>
        ),
      },
      {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
        render: (text, record) => (
          <div>{record?.date || ''}</div>
        ),
      }
          
    ];
};


const TimeReportListColumn = ({ }) => {
  return [
    {
      title: 'Staff',
      dataIndex: 'staff_name',
      key: 'staff_name',
      render: (text, record) => (
        <div>{record?.name || ''}</div>
      ),
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: (text, record) => (
        <>
          {record?.start_date} - {record?.end_date}
        </>
      ),
    },
    {
      title: 'Time',
      dataIndex: 'end_date',
      key: 'end_date',
      render: (text, record) => (
        <>
          {record?.start_time && convertTo12HourFormat(record.start_time)} 
          {record?.end_time && <> - {convertTo12HourFormat(record.end_time)}</>}
        </>
      )
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      render: (text, record) => (
        <div>{record?.type || ''}</div>
      ),
    },
    {
      title: 'Recurring',
      dataIndex: 'recurring',
      key: 'recurring',
      render: (text, record) => (
        <>
         {record.recurring_days && record.recurring_days.length ?
          <>
        
            {convertToDayNames(record.recurring_days).slice(0, 2).map(day=>{
              return(
                <Tag style={tagStyle}>{day}</Tag>)
            })}
            {record.recurring_days.length > 2 &&
              <Tag style={tagStyle}>{`+${record.recurring_days.length-2}`}</Tag>
            }
          </>
          : <>-</>
          }
        </>
      ),
    },
    {
      title: 'Approved',
      dataIndex: 'approved',
      key: 'approved',
      render: (text, record) => (
        <>
          {
            record.is_approved? <TickIcon/>: <DeclineIcon/>
          }
        </>
      ),
    },
    {
      title: 'Decline',
      dataIndex: 'decline',
      key: 'decline',
      render: (text, record) => (
        <>
          {
            record.is_decline? <TickIcon/>: <DeclineIcon/>
          }
           
        </>
      ),
    }
        
  ];
};


const RevenueReportListColumn = ({ }) => {
  return [
    {
      title: 'Service Name',
      dataIndex: 'service_name',
      key: 'service_name',
      render: (text, record) => (
        <div>{record?.single_service_request?.service?.name || ''}</div>
      ),
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: (text, record) => (
        <div>{record?.cost+ record?.holiday_amount}</div>
      ),
    },
    {
      title: 'Client Name',
      dataIndex: 'client_name',
      key: 'client_name',
      render: (text, record) => (
        <div>{record?.single_service_request?.client?.name || ''}</div>
      ),
    }
  ];
};


const ServiceRevenueReportListColumn = ({ }) => {
  return [
    {
      title: 'Service Name',
      dataIndex: 'service_name',
      key: 'service_name',
      render: (text, record) => (
        <div>{record?.service_name || ''}</div>
      ),
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: (text, record) => (
        <div>{record?.total_cost}$</div>
      ),
    }
  ];
};

const ReportListColumn = ({ handleDelete}) => {
  return [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: (text, record) => (
          <div>{record.name}</div>
        ),
      },
      {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
        render: (text, record) => (
          <div>{record.type}</div>
        ),
      },
      {
        title: 'Date Created',
        dataIndex: 'created',
        key: 'created',
        render: (text, record) => (
          <div>{dayjs(record?.created_at).format("DD/MM/YYYY")}</div>
        ),
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (text, record) => (
          <div>{record.status}</div>
        ),
      },
      {
        title: "",
        key: "action",
        render: (_, record) => (
          <Space size="middle">
            <Button
              onClick={(event) => {
                const link = document.createElement('a');
                link.href = record.file_key;
                // const timestamp = new Date().getTime();
                // const randomString = Math.random().toString(36).substring(2, 8);
                // let fileName = `download_${timestamp}_${randomString}.csv`;
                // link.download = fileName;
                link.target = '_blank';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
              }}
            >
              {record.file_key && <DownloadIcon />}
            </Button>

            <Button
              onClick={(event) => {
                handleDelete(record)
                // event.stopPropagation();
                // handleDelete && handleDelete(record);
              }}
            >
              <DeleteIcon />
            </Button>
          </Space>
        ),
      }
  ];
};
  
export { ClientListColumn, ReportListColumn, ServiceRequestListColumn, TimeReportListColumn, StaffListColumn, RevenueReportListColumn, ServiceRevenueReportListColumn };


const Button = styled.button`
  border: none;
  background: none;
`;


const tagStyle = {
  backgroundColor: "#EFF1F5",
  fontWeight: 500,
  border: "none",
  borderRadius: "28px",
  padding: "2px 8px 2px 6px",
  color: "#023047"
};