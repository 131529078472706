import React from "react";
import { Line } from "react-chartjs-2";
function CancelledVisitsChart({componentRef, chartData, label }) {

    const data = {
        labels: chartData && chartData[0]?.labels,
        datasets: [
            {
                label: chartData && chartData[0]?.label,
                data: chartData && chartData[0]?.data,
                fill: false,
                borderColor: 'rgb(75, 192, 192)',
                tension: 0.1
            },
            {
                label: chartData && chartData[1]?.label,
                data: chartData && chartData[1]?.data,
                fill: false,
                borderColor: 'rgb(255, 99, 132)',
                tension: 0.1
            }
        ]
    };
    return (

        <div ref={componentRef} style={{ width: '70%', margin: 'auto' }} id="chart-container">
            <div style={{display: 'flex', justifyContent: 'space-between', marginTop: '20px'}}>
                <h4 style={{ textAlign: "center" }}>Total Events: {chartData && chartData[0]?.totalCount}</h4>
                <h4 style={{ textAlign: "center" }}>Cancelled Events: {chartData && chartData[1]?.totalCount}</h4>
            </div>
            <Line
                data={data}
                options={{
                   stacked: false,
                    plugins: {
                        legend: {
                            display: true
                        }
                    }
                }}
            />
        </div>
    );
}
export default CancelledVisitsChart;