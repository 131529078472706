import React from 'react'
import { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import ProfileSettings from '../../../components/PlateformSettings/profileSettings';
import OnboardingthemeSettings from '../../../components/PlateformSettings/onboardingthemeSettings';
import OnboardingVideosSettings from '../../../components/PlateformSettings/onboardingVideosSettings';

export default function PlateformSettings() {
    const auth = useSelector((state) => state.auth);
    const [activeTab, setActiveTab] = useState("onboarding_videos");
    return (
        <>
        <StickyBar>
            <NavigationHeading>Settings</NavigationHeading>
            <ActionButtons>
            <TabContainer>
                <TabButton onClick={() => setActiveTab("profile_settings")} active={activeTab === "profile_settings"}>
                Profile Settings
                </TabButton>
                <TabButton onClick={() => setActiveTab("onboarding_theme")} active={activeTab === "onboarding_theme"} marginLeft="30px">
                Onboarding Theme
                </TabButton>
                <TabButton onClick={() => setActiveTab("onboarding_videos")} active={activeTab === "onboarding_videos"} marginLeft="30px">
                Onboarding Videos
                </TabButton>
            </TabContainer>
            </ActionButtons> 
        </StickyBar>
        <div style={{marginTop: '130px', marginBottom:'70px'}}>
            {activeTab === 'profile_settings' && <ProfileSettings/> }
            {activeTab === 'onboarding_theme' && <OnboardingthemeSettings/>}
            {activeTab === 'onboarding_videos' && <OnboardingVideosSettings/>}
        </div>
        </>
    
  )
}

const NavigationHeading = styled.h2`
  display: flex;
  margin: 25px 0px 10px 0px;
  font-size: 20px;
  font-weight: 700;
`;
const ActionButtons = styled.div`
  display: flex;
  margin-bottom: 27px;
  border-bottom: 1px solid rgb(228, 234, 240);
`;
const TabContainer = styled.div`
  padding: 16px 16px 0px 16px;
  height: 54px;
  box-sizing: border-box;
  border-radius: 6px;
  display: flex;
`;
const TabButton = styled.button`
  background-color: transparent;
  padding: 9px 8px 29px 8px;
  height: 34px;
  border: none;
  margin-left: ${(props) => props.marginLeft ?? "0"};
  cursor: pointer;
  border-bottom: 2px solid ${(props) => (props.active ? "#FBCF34" : "transparent")};
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: ${(props) => (props.active ? "#000000" : "#757575")};
`;
const StickyBar = styled.div`
  position: fixed;
  background-color: white;
  z-index: 1;
  width: 100%;
  margin-top: -17px;
`;