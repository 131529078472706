import React, { useEffect, useState } from "react";
import { companyAPIsEndPoints } from "../../../constants/ApiEndPoints";
import { useSelector } from "react-redux";
import { ReportListColumn } from "../../../constants/ReportingTableHeader/ReportingHeader";
import { main_api } from "../../../api/axios_helper";
import { AntdesignTablePagination } from "../../../components/antDesignTable/AntdesignTablePagination";
import styled from "styled-components";
import { Divider, Input, Switch, Segmented } from "antd";
import CustomBtnWithIcon from "../../../components/buttonWithIcon";
import AntDesignModal from "../../../components/Modals/AntDesignModal/AntDesignModal";
import ReportDetail from "./ReportDetail";
import { pushNotification } from "../../../util/Notification";
import DeleteConfirmationRowModal from "../../../components/Modals/DeleteConfirmationRowModal";
import SearchInput from "../../../components/SearchInput";
import { checkPermission } from "../../../util/commonUtil";
import PermissionModal from "../../../components/Modals/PermissionModal";

const ReportListing = () => {
  const { auth, permissionManagement } = useSelector((state) => state);
  const [reportList, setReportList] = useState([]);
  const [configureReport, setConfigureReport] = useState(false);
  const [newReport, setNewReport] = useState(false);
  const [count, setCount] = useState(0);
  const [record, setRecord] = useState(null);
  const [search, setSearch] = useState("");
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [currentReport, setCurrentReport] = useState({
    name: "",
    status: "private",
  });
  const [permissionPopUp, setPermissionPopUp] = useState(false);

  const [selectTab, setSelectTab] = React.useState("My Reports");

  useEffect(() => {
    fetchReports();
  }, [newReport, search, selectTab]);

  const fetchReports = (page = 1) => {
    if (checkPermission(permissionManagement.permissions, "view_reports")) {
      let reportStatus = false;
      if (selectTab === "My Reports") {
        reportStatus = true;
      }
      const companyId = auth?.loginResponse?.selectCompany?.[0]?.company_id;
      main_api
        .get(`${companyAPIsEndPoints.REPORTING}?page=${page}&company=${companyId}&search=${search}&private=${reportStatus}`)
        .then(({ data }) => {
          setReportList(data.results);
          setCount(data.count);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleDelete = async (row) => {
    if (checkPermission(permissionManagement.permissions, "delete_report")) {
      setRecord(row);
      setIsDeleteModal(true);
    } else {
      setPermissionPopUp(true);
    }
  };

  const handleDeleteConfirm = async () => {
    const response = await main_api.delete(companyAPIsEndPoints.DELETE_REPORTING(record.id));

    if (response?.status === 200) {
      pushNotification(response?.data.message, "success");
      fetchReports();
    } else {
      pushNotification(response?.data.message, "error");
    }

    setIsDeleteModal(false);
    setRecord(null);
  };

  return (
    <>
      {newReport ? (
        <>
          <ReportDetail currentReport={currentReport} setCurrentReport={setCurrentReport} setNewReport={setNewReport} />
        </>
      ) : (
        <>
          <NavigationHeading>Reports</NavigationHeading>
          <ReportHeader>
            <div>
              <Segmented
                options={["My Reports", "Public Reports"]}
                onChange={(e) => setSelectTab(e)}
                defaultValue={selectTab}
                size="large"
                className="ms-2 p-1"
              />
              <SearchInput
                search={search}
                onChange={(value) => setSearch(value)}
                onBlur={() => {}}
                placeholder="Type to search.."
              />
            </div>
            <CustomBtnWithIcon
              btnText={"Configure New Report"}
              onClick={() => {
                if (checkPermission(permissionManagement.permissions, "create_report")) {
                  setConfigureReport(true);
                  setCurrentReport({
                    name: "",
                    status: "private",
                  });
                } else {
                  setPermissionPopUp(true);
                }
              }}
            />
          </ReportHeader>
          <AntdesignTablePagination
            columns={ReportListColumn({ handleDelete })}
            data={reportList}
            totalCount={count}
            loadPaginatedData={fetchReports}
          />
          <AntDesignModal
            closeModal={() => setConfigureReport(false)}
            open={configureReport}
            title={"Configure New Report"}
            width={"450px"}
            component={
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                }}
              >
                <label>Report Name</label>
                <Input
                  onChange={(e) =>
                    setCurrentReport({
                      ...currentReport,
                      name: e.target.value,
                    })
                  }
                  placeholder="Report Name"
                ></Input>
                <div style={{ marginTop: "5px" }}>
                  <Switch
                    defaultValue={true}
                    onChange={(value) => {
                      if (value) {
                        setCurrentReport({
                          ...currentReport,
                          status: "private",
                        });
                      } else {
                        setCurrentReport({
                          ...currentReport,
                          status: "public",
                        });
                      }
                    }}
                  />
                  <span style={{ marginLeft: "5px" }}>Private Report?</span>
                </div>
              </div>
            }
            footer={
              <>
                <Divider />
                <div className="d-flex justify-content-end ">
                  <div>
                    <CustomBtnWithIcon
                      btnText={"Cancel"}
                      margin="0px 5px"
                      noBackground
                      hideIcon={true}
                      onClick={() => setConfigureReport(false)}
                    />
                  </div>
                  <div className="d-flex">
                    <CustomBtnWithIcon
                      btnText={"Configure"}
                      type="button"
                      hideIcon={true}
                      disabled={!(currentReport && currentReport.name)}
                      onClick={() => {
                        setNewReport(true);
                        setConfigureReport(false);
                      }}
                    />
                  </div>
                </div>
              </>
            }
          />
          <DeleteConfirmationRowModal
            onclose={() => setIsDeleteModal(false)}
            handleConfirmDeleteRows={handleDeleteConfirm}
            openConfimrationDeleteModal={isDeleteModal}
          />
          <PermissionModal onclose={() => setPermissionPopUp(false)} openModal={permissionPopUp} />
        </>
      )}
    </>
  );
};

export default ReportListing;

const NavigationHeading = styled.h2`
  display: flex;
  margin: 25px 0px 10px 0px;
  font-size: 20px;
  font-weight: 700;
`;

const ReportHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;
