import React, { useEffect, useState } from "react";
import { AntdesignTable } from "../../../../../components/antDesignTable/AntdesignTable";
import { staffMileageTrackingHeaders } from "../../../../../constants/CompanyTableHeaders/StaffManagementHeaders";
import { main_api } from "../../../../../api/axios_helper";
import { staffAPIsEndPoints } from "../../../../../constants/ApiEndPoints";
import AddMileageTrackingForm from "./addMileageTrackingForm";
import { pushNotification } from "../../../../../util/Notification";
import { Segmented } from "antd";
import CustomBtnWithIcon from "../../../../../components/buttonWithIcon";
import EditMileageTrackingForm from "./editMileageTrackingForm";
import { useSelector } from "react-redux";
import { checkPermission } from "../../../../../util/commonUtil";
import DeleteConfirmationRowModal from "../../../../../components/Modals/DeleteConfirmationRowModal";

const MileageTracking = ({ staffId, staffMileageTrackingData, fetchSingleStaffMileageTracking}) => {
  const [SelectTab, setSelectTab] = useState("Mileage Tracking");
  const [addMileageTrackingModal, setOpenAddMileageTrackingModal] = useState(false);
  const [editMileageTrackingModal, setOpenEditMileageTrackingModal] = useState(false);
  const [deleteTimeOff, setDeleteTimeOff] = useState(false);
  const [selectedEntry, setSelectedEntry] = useState(null);
  const state = useSelector((state) => state.auth.loginResponse);
  const permissionManagement = useSelector((state) => state.permissionManagement);
  let company_id = state?.selectCompany?.[0]?.company_id || null;
  // useEffect(() => {
  //   fetchSingleStaffMileageTracking(staffId);
  // }, []);

  const fetchData = () => {
    if(SelectTab === 'Mileage Requests'){
      fetchSingleStaffMileageTracking(staffId, 'in_progress=false&approved=false&decline=false&service_request!=null')
    }else{
      fetchSingleStaffMileageTracking(staffId, 'in_progress=false&approved=true&decline=false')
    } 
    
  }
  useEffect(()=>{
    fetchData()
  },[SelectTab])

  const handleUpdate = async (api_data, record) => {
    try {
      var { data } = await main_api.put(staffAPIsEndPoints.UPDATE_STAFF_MILEAGE_TRACK(record.id), api_data);
      if (data.isSuccess) {
        fetchData()
        pushNotification(data.message);
        return data
      } else {
        pushNotification(data.message, "error");
      }
    } catch (error) {
      console.error(error)
    }
  }
  const handleCreateMileageTrackingEntry = async (api_data) => {
    try {
      var  {data}  = await main_api.post(staffAPIsEndPoints.ADD_NEW_STAFF_MILEAGE_TRACK, api_data);
     
      if (data.isSuccess) {
        pushNotification(data.message);
        return data
      } else {
        pushNotification(data.message, "error");
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleConfirmDeleteRows = async () => {
    let api_data = {
      company_id: company_id,
      staff: staffId,
    };
    try {
      var { data } = await main_api.delete(staffAPIsEndPoints.DELETE_EXISTING_STAFF_MILEAGE_TRACK(selectedEntry.id));
      if (data.isSuccess) {
       
        pushNotification(data.message);
      } else {
        pushNotification(data.message, "error");
      }
      setSelectedEntry(null);
      fetchData();
      setDeleteTimeOff(false);
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        pushNotification(error.response.data.message, "error");
      }
      console.error("errr", error);
    }
  };

  const handleDelete = (entry) => {
    // if(checkPermission(permissionManagement.permissions, 'delete_timeoff')){
      setSelectedEntry(entry);
      setDeleteTimeOff(true);
    // } else{
    //   // setPermissionPopUp(true)
    // }
  };

  const handleEditRow = (entry) => {

    // if(checkPermission(permissionManagement.permissions, 'edit_timeoff')){
      setSelectedEntry(entry);
      setOpenEditMileageTrackingModal(true);
    // } else{
    //   // setPermissionPopUp(true)
    // }
  };

  return (
    <div className="mt-2">
      {" "}
      <div className="col-md-4">
        <Segmented
          style={{ marginBottom: 8, background: "rgb(238,238,238,1)" }}
          onChange={(value) => setSelectTab(value)}
          options={["Mileage Tracking", "Mileage Requests"]}
          className=" p-1 "
          defaultValue={SelectTab}
          block
          size="large"
        />
      </div>
      {SelectTab === 'Mileage Tracking' && 
         <div className="d-flex justify-content-end mb-2">
          <CustomBtnWithIcon
            btnText={"Add Mileage Entry"}
            hideIcon
            margin="0px 10px"
            onClick={() => setOpenAddMileageTrackingModal(true)}
          />
       </div>
      }
      <AntdesignTable columns={staffMileageTrackingHeaders({ handleDelete, handleEditRow, SelectTab, handleUpdate })} data={staffMileageTrackingData || []} />
      <AddMileageTrackingForm
        updateValues={{}}
        addNewEventModal={addMileageTrackingModal}
        staffId={staffId}
        createEntry={handleCreateMileageTrackingEntry}
        // filterUpdateService={}
        // updateForm={}
        // requiredDropdowns={}
        closeAll={() => setOpenAddMileageTrackingModal(false)}
      />
      <EditMileageTrackingForm
        updateMileageTrackingModal={editMileageTrackingModal}
        staffId={staffId}
        selectedEntry={selectedEntry}
        updateEntry={handleUpdate}
        closeAll={() => setOpenEditMileageTrackingModal(false)}
      />
      <DeleteConfirmationRowModal
        onclose={() => setDeleteTimeOff(false)}
        handleConfirmDeleteRows={handleConfirmDeleteRows}
        openConfimrationDeleteModal={deleteTimeOff}
      />
    </div>
  );
};

export default MileageTracking;
