import React, { useEffect, useState } from "react";
import styled from "styled-components";
import SearchInput from "../../../components/SearchInput";
import { Select } from "antd";
import CustomBtnWithIcon from "../../../components/buttonWithIcon";
import { AntdesignTable } from "../../../components/antDesignTable/AntdesignTable";
import { UserManagementListColumns } from "../../../constants/userManagementTable/userManagementListHeader";
import { UserManagementRoleColumns } from "../../../constants/userManagementTable/userManagementRoleHeader";
import AddUserModal from "../../../components/Modals/UserManagementModal/AddUserModal";
import { fetchCompanyRoles } from "../../../util/DataService";
import CommonUtil, { checkPermission } from "../../../util/commonUtil";
import CreateTaskModal from "../../../components/Modals/TasksManagementModal/CreateTaskModal";
import { companyAPIsEndPoints } from "../../../constants/ApiEndPoints";
import { main_api } from "../../../api/axios_helper";
import { pushNotification } from "../../../util/Notification";
import DeleteConfirmationRowModal from "../../../components/Modals/DeleteConfirmationRowModal";
import NoDataScreen from "../../../components/NoDataScreen";
import PermissionModal from "../../../components/Modals/PermissionModal";

const UserManagement = ({ allUsers, fetchAllUser, permissionState }) => {
  const [activeTab, setActiveTab] = useState("users");
  const [searchTerm, setSearchTerm] = useState("");
  const [addUser, setAddUser] = useState(false);
  const [taskModal, setTaskModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [roles, setRoles] = useState(false);
  const [selectedRole, setSelectedRole] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [permissionPopUp, setPermissionPopUp] = useState(false);

  const allRoles = [
    {
      name: "Company Admin",
      detail: "Perform all admin activities for an org. Company Admin have full management access.",
    },
    {
      name: "Company Subadmin",
      detail: "Perform all admin activities for an org. Company Subadmin have full management access.",
    },
    {
      name: "Company Manager",
      detail: "Perform specific tasks based on assigned permissions.",
    },
    {
      name: "Company Trainee",
      detail: "Access restricted data based on assigned permissions, with limited viewing capabilities.",
    },
  ];

  useEffect(() => {
    fetchAllUser();
    fetchCompanyRoles();
    fetchCompanyRoles().then(({ data }) => {
      setRoles(CommonUtil.convertObjToSelectOptionsAntd(data) || []);
    });
  }, []);

  useEffect(() => {
    fetchAllUser(searchTerm, selectedRole);
  }, [searchTerm, selectedRole]);

  const handleDelete = async (record) => {
    if (checkPermission(permissionState, "delete_user")) {
      setDeleteModal(true);
      setSelectedUser(record);
    } else {
      setPermissionPopUp(true);
    }
  };

  const deleteUser = async () => {
    const { data } = await main_api.delete(companyAPIsEndPoints.SINGLE_COMPANY_USER(selectedUser.id));
    if (data.isSuccess) {
      pushNotification(data.message);
      setSelectedUser(null);
      setDeleteModal(false);
      fetchAllUser();
    }
  };

  const handleAdd = (record) => {
    if (checkPermission(permissionState, "create_task")) {
      setSelectedUser(record);
      setTaskModal(true);
    } else {
      setPermissionPopUp(true);
    }
  };

  const handleEditRow = (record) => {
    if (checkPermission(permissionState, "edit_user")) {
      setSelectedUser(record);
      setAddUser(true);
    } else {
      setPermissionPopUp(true);
    }
  };

  const handleAddNewUser = () => {
    if (checkPermission(permissionState, "create_user")) {
      setAddUser(true);
      setSelectedUser(null);
    } else {
      setPermissionPopUp(true);
    }
  };

  const handleSendForgetPasswordReq = async (values) => {
    try {
      const { data } = await main_api.put(companyAPIsEndPoints.FORGOT_PASSWORD, { email: values.user.email });
      if (data.success) {
        pushNotification("Password Recovery Email Sent", "success");
      }
    } catch (error) {
      if (error) {
        pushNotification(error?.error, "error");
      }
    }
  };
  return (
    <div>
      <TableHeader>
        <NavigationHeading>User Management</NavigationHeading>
      </TableHeader>
      <ActionButtons>
        <TabContainer>
          <TabButton onClick={() => setActiveTab("users")} active={activeTab === "users"}>
            Users
          </TabButton>
          <TabButton onClick={() => setActiveTab("roles")} active={activeTab === "roles"} marginLeft="30px">
            Roles & Permissions
          </TabButton>
        </TabContainer>
      </ActionButtons>
      <div className="d-flex align-items-center">
        {activeTab === "users" && checkPermission(permissionState, "view_users") && (
          <>
            <SearchInput
              onChange={(value) => setSearchTerm(value)}
              width="90%"
              placeholder="Type to search..."
              containerBorder={"0"}
            />
            <Select
              allowClear
              onChange={(val) => setSelectedRole(val)}
              style={{ width: "10%", height: "40px" }}
              placeholder="Role"
              options={roles}
            />
          </>
        )}
        <CustomBtnWithIcon className="ms-auto mb-3" btnText={"Add new User"} onClick={() => handleAddNewUser()} />
      </div>
      {activeTab === "users" ? (
        <>
          {allUsers?.userList?.result?.length > 0 && checkPermission(permissionState, "view_users") ? (
            <AntdesignTable
              columns={UserManagementListColumns({ handleDelete, handleEditRow, handleAdd, handleSendForgetPasswordReq })}
              data={allUsers?.userList?.result || []}
            />
          ) : (
            <NoDataScreen
              heading="You don’t have any User in your list. Let’s get started by adding a new User"
              btnText={"Add new User"}
              onClick={() => handleAddNewUser()}
            />
          )}
        </>
      ) : (
        <AntdesignTable allowMultieSelectRows={false} columns={UserManagementRoleColumns()} data={allRoles} />
      )}
      <AddUserModal
        selectedUser={selectedUser}
        isModalOpen={addUser}
        setModalOpen={setAddUser}
        fetchAllUser={fetchAllUser}
        roles={roles}
      />
      {selectedUser && (
        <CreateTaskModal
          isModalOpen={taskModal}
          setModalOpen={setTaskModal}
          clientList={[]}
          staffList={[]}
          generalList={[{ label: selectedUser.name, value: selectedUser.id }]}
          loadTasksList={() => {
            setTaskModal(false);
            setSelectedRole(null);
          }}
          assigneeTypeList={[{ label: "General", value: "general" }]}
        />
      )}
      <DeleteConfirmationRowModal
        onclose={() => {
          setDeleteModal(false);
          setSelectedUser(null);
        }}
        handleConfirmDeleteRows={deleteUser}
        openConfimrationDeleteModal={deleteModal}
      />
      <PermissionModal onclose={() => setPermissionPopUp(false)} openModal={permissionPopUp} />
    </div>
  );
};

export default UserManagement;

const TableHeader = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`;

const NavigationHeading = styled.h2`
  display: flex;
  margin: 25px 0px 10px 0px;
  font-size: 20px;
  font-weight: 700;
`;

const ActionButtons = styled.div`
  display: flex;
  margin-bottom: 15px;
  border-bottom: 1px solid rgb(228, 234, 240);
`;

const TabContainer = styled.div`
  padding: 16px 16px 0px 16px;
  height: 54px;
  box-sizing: border-box;
  border-radius: 6px;
  display: flex;
`;

const TabButton = styled.button`
  background-color: transparent;
  padding: 9px 8px 29px 8px;
  height: 34px;
  border: none;
  margin-left: ${(props) => props.marginLeft ?? "0"};
  cursor: pointer;
  border-bottom: 2px solid ${(props) => (props.active ? "#FBCF34" : "transparent")};
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: ${(props) => (props.active ? "#000000" : "#757575")};
`;
