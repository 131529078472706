import React from "react";

const DeleteIcon = ({ fill = "none", fillPath = "#E53939", width = 16, height = 16 }) => (
  <svg width={width} height={height} fill={fill} viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.666016 4.16667H11.3327M4.66602 6.83333V10.8333M7.33268 6.83333V10.8333M1.33268 4.16667L1.99935 12.1667C1.99935 12.5203 2.13982 12.8594 2.38987 13.1095C2.63992 13.3595 2.97906 13.5 3.33268 13.5H8.66602C9.01964 13.5 9.35878 13.3595 9.60882 13.1095C9.85887 12.8594 9.99935 12.5203 9.99935 12.1667L10.666 4.16667M3.99935 4.16667V2.16667C3.99935 1.98986 4.06959 1.82029 4.19461 1.69526C4.31964 1.57024 4.4892 1.5 4.66602 1.5H7.33268C7.50949 1.5 7.67906 1.57024 7.80409 1.69526C7.92911 1.82029 7.99935 1.98986 7.99935 2.16667V4.16667"
      stroke={fillPath}
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default DeleteIcon;
