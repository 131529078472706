import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import CustomBtnWithIcon from "../buttonWithIcon";
import HeadingComponent from "../Heading";
import { Divider, Form, Input } from "antd";
import { useSelector } from "react-redux";
import { CheckCircleOutlined, CloseCircleOutlined, EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import FormikInputFloating from "../formikInputs/TextInput";
import { Formik, useFormikContext } from "formik";
import { changePasswordSchema, resetPasswordSchema } from "../../constants/CompanyFormFieldsSchema/FormSchema";
import { pushNotification } from "../../util/Notification";
import axiosInstance from "../../util/AxiosConfig";
import { companyAPIsEndPoints } from "../../constants/ApiEndPoints";
import * as Yup from "yup";
export default function ResetPassword({ setModalOpen }) {
  const location = useLocation();
  let navigate = useNavigate();

  const handleResetPassword = async (formValues) => {
    const reqObject = {
      ...formValues,
    };

    try {
      const { data } = await axiosInstance.post(companyAPIsEndPoints.CLIENT_CHANGE_PASSWORD, reqObject);
      if (data.isSuccess) {
        pushNotification("Password Reset Successfully!");
        navigate("/company/scheduler");
        setModalOpen(false);
      }
    } catch (error) {
      if (error) {
        pushNotification("Something went wrong please try again", "error");
      }
    }
  };

  return (
    <Formik
      initialValues={{
        current_password: "",
        new_password1: "",
        new_password2: "",
      }}
      validationSchema={changePasswordSchema}
      onSubmit={(values) => {
        handleResetPassword(values);
      }}
    >
      {({ handleSubmit, values, errors, touched, handleChange, handleBlur, setFieldTouched }) => {
        const inputProps = {
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          setFieldTouched,
        };
        const length = Object.keys(values.new_password1).length;
        const hasUppercase = /[A-Z]/.test(values.new_password1);
        const hasLowercase = /[a-z]/.test(values.new_password1);
        const hasNumbers = /[0-9]/.test(values.new_password1);
        const hasSpecialCharachters = /[!@#$%^&*()_+]/.test(values.new_password1);

        return (
          <form onSubmit={handleSubmit}>
            <div>
              <div style={{ marginBottom: "-20px" }}>
                <Heading text="Old Password" />
              </div>
              <RowContainer>
                <FormikInputFloating name="current_password" type="password" className="w-100" passwordField={true} />
              </RowContainer>
            </div>
            <div style={{ marginTop: "24px" }}>
              <div style={{ marginBottom: "-20px" }}>
                <Heading text="New Password" />
              </div>
              <RowContainer>
                <FormikInputFloating name="new_password1" type="password" className="w-100" passwordField={true} />
              </RowContainer>
              <div style={{ marginTop: "8px" }}>
                {/* Validation messages with green checkmarks */}
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span style={{ marginTop: "12px", fontSize: "16px" }}>
                    {length >= 8 ? (
                      <CheckCircleOutlined style={{ color: "green" }} />
                    ) : (
                      <CheckCircleOutlined twoToneColor="#f5222d" />
                    )}
                  </span>
                  <Heading text="Must be a minimum of 8 characters" color="#808080" margin="8px 0px 0px 8px" />
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span style={{ marginTop: "12px", fontSize: "16px" }}>
                    {hasUppercase ? (
                      <CheckCircleOutlined style={{ color: "green" }} />
                    ) : (
                      <CheckCircleOutlined twoToneColor="#f5222d" />
                    )}
                  </span>
                  <Heading text="Must contain an uppercase letter" color="#808080" margin="8px 0px 0px 8px" />
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span style={{ marginTop: "12px", fontSize: "16px" }}>
                    {hasLowercase ? (
                      <CheckCircleOutlined style={{ color: "green" }} />
                    ) : (
                      <CheckCircleOutlined twoToneColor="#f5222d" />
                    )}
                  </span>
                  <Heading text="Must contain lowercase letter" color="#808080" margin="8px 0px 0px 8px" />
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span style={{ marginTop: "12px", fontSize: "16px" }}>
                    {hasNumbers ? (
                      <CheckCircleOutlined style={{ color: "green" }} />
                    ) : (
                      <CheckCircleOutlined twoToneColor="#f5222d" />
                    )}
                  </span>
                  <Heading text="Must contain at least one number" color="#808080" margin="8px 0px 0px 8px" />
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span style={{ marginTop: "12px", fontSize: "16px" }}>
                    {hasSpecialCharachters ? (
                      <CheckCircleOutlined style={{ color: "green" }} />
                    ) : (
                      <CheckCircleOutlined twoToneColor="#f5222d" />
                    )}
                  </span>
                  <Heading text="Must contain at least one special character" color="#808080" margin="8px 0px 0px 8px" />
                </div>
              </div>
            </div>

            <div style={{ marginTop: "14px" }}>
              <div style={{ marginBottom: "-20px" }}>
                <Heading text="Confirm New Password" />
              </div>
              <RowContainer>
                <FormikInputFloating name="new_password2" type="password" className="w-100" passwordField={true} />
              </RowContainer>
            </div>

            <div className="d-flex justify-content-end mt-5">
              <CustomBtnWithIcon
                btnText={"Cancel"}
                margin="0px 5px"
                noBackground
                hideIcon={true}
                onClick={() => setModalOpen(false)}
              />
              <CustomBtnWithIcon btnText="Update Changes" type="submit" hideIcon={true} />
            </div>
          </form>
        );
      }}
    </Formik>
  );
}

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  margin-top: 10px; /* Adjust the top margin */
  margin-bottom: -9px; /* Adjust the bottom margin */
`;

// const SaveContainer = styled.div`
//   position: absolute;
//   bottom: 0;
//   right: 0;
//   width: 100%;
//   border-top: 1px solid #E0E0E0;
//   background-color: white;
//   padding: 10px 0px;
// `

const SaveContainer = styled.div`
  position: absolute;
  top: 522px;
  right: 0;
  width: 100%;
  border-top: 1px solid #e0e0e0;
  background-color: white;
  padding: 10px 0px;
`;
const FormItem = styled(Form.Item)`
  width: 100%;
  Tags {
    display: flex;
  }
`;

const Heading = ({ text = "", margin, fontSize = "0.75rem", color = "#3B3B3B" }) => {
  return <HeadingComponent text={text} fontSize={fontSize} color={color} fontWeight={700} margin={margin} />;
};
