import React, { useCallback, useEffect, useState } from "react";

import { main_api } from "../../../api/axios_helper";

import SearchInput from "../../../components/SearchInput";
import CustomBtnWithIcon from "../../../components/buttonWithIcon";
import { ServiceHeaders } from "../../../constants/CompanyTableHeaders/ServiceAndHolidays";

import { Segmented, Table, Space, Dropdown, Input, Divider } from "antd";

import styled from "styled-components";

import "./service.css";
import { companyAPIsEndPoints } from "../../../constants/ApiEndPoints";

import { pushNotification } from "../../../util/Notification";
import { ReactComponent as EditIcon } from "../../../assets/rawSvgs/tableEditIcon.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/rawSvgs/tableDeleteIcon.svg";
import { ReactComponent as DisabledIcon } from "../../../assets/rawSvgs/disabledIcon.svg";
import { ReactComponent as EnableService } from "../../../assets/rawSvgs/enableService.svg";

import { DropDownDots } from "../../../assets/rawSvgs";

import DeleteConfirmationRowModal from "../../../components/Modals/DeleteConfirmationRowModal";
import AddNewServiceModal from "./AddService";

import ServiceDrawer from "./serviceSidebarDrawer";
import AddNewGroupModal from "./AddNewGroupModalForm";
import { useDebounce } from "../../../hooks/useDebounce";
import { useSelector } from "react-redux";
import { checkPermission } from "../../../util/commonUtil";
import PermissionModal from "../../../components/Modals/PermissionModal";
import NoDataScreen from "../../../components/NoDataScreen";

//
function Service({
  serviceStateLoader,
  fetchServiceGroups,
  companyId,
  serviceResponse,
  fetchAllServices,
  serviceList,
  serviceGroups,
}) {
  const [addGroupModalShow, setAddGroupModalShow] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [addNewServiceModalShow, setAddNewServiceModalShow] = useState(false);
  const [serviceDelete, setServiceDelete] = useState(false);
  const [filterServiceGroupServices, setFilterServiceGroupServices] = useState([]);
  const [serviceFormLoader, setServiceFormLoader] = useState(false);
  const [updateServiceFormModal, setUpdateServiceFormModal] = useState(false);
  const [deleteRowId, setDeleteRowId] = useState("");
  const [SelectTab, setSelectTab] = React.useState("Active Services");
  const [updateServiceValues, setUpdateServiceValues] = React.useState({});
  const [filteredServices, setFilteredServices] = useState([]);
  const [editServiceGrouoValues, setEditServiceGrouoValues] = useState(undefined);
  const [editGroupServiceModal, setEditGroupServiceModal] = useState(false);
  const [sidebarServiceDetailDrawer, setSidebarServiceDetailDrawer] = useState(false);
  const [promoCodeValues, setPromoCodeValues] = useState(null);
  const [blockTimeDropDown, setBlockTimeDropDown] = useState([]);
  const [filteredGroupsFromServices, setFilteredGroupsFromServices] = useState([]);
  const [promoCodeFetchLoader, setPromoCodeFetchLoader] = useState(false);
  const debouncedSearchTerm = useDebounce(searchTerm, 200);
  const [permissionPopUp, setPermissionPopUp] = useState(false);
  const permissionManagement = useSelector((state) => state.permissionManagement);

  useEffect(() => {
    const handleFetchTimeblockList = async () => {
      try {
        const { data } = await main_api.get(companyAPIsEndPoints.FETCH_TIME_BLOCKS_PER_COMPANY(companyId));

        const modifiyList = data?.result?.map((items) => {
          return {
            label: items.name,
            value: items.id,
          };
        });
        setBlockTimeDropDown(modifiyList);
      } catch (error) {
        console.log("Error:", error);
      }
    };

    handleFetchTimeblockList();
  }, []);

  useEffect(() => {
    if (checkPermission(permissionManagement.permissions, "view_services")) {
      fetchAllServices(debouncedSearchTerm);
    }
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (serviceResponse) {
      const filtered = serviceResponse?.filter((item) => {
        if (SelectTab === "Active Services") {
          return item.active;
        } else if (SelectTab === "Disabled Services") {
          return item.active === false;
        } else if (SelectTab === "Deleted Services") {
          return item.is_deleted;
        }
        return [];
      });

      const groups = filtered?.reduce((acc, item) => {
        // Check if service_group exists and has a name
        if (item?.service_group && item?.service_group?.name) {
          // Extract group namea
          const groupName = item?.service_group?.name;
          // Initialize group object if not exists
          if (!acc[groupName]) {
            acc[groupName] = { groupName, groupItems: [], groupId: item?.service_group?.id };
          }
          // Push item details to the corresponding group
          acc[groupName]?.groupItems?.push(item);
        }
        return acc;
      }, {});

      setFilteredGroupsFromServices(groups);

      setFilteredServices(filtered);
    }

    const filterServiceGroup = serviceGroups?.map((items) => {
      return {
        ...items,
        servicegroup_service: items?.servicegroup_service?.filter((item) => {
          if (SelectTab === "Active Services") {
            return item.active;
          } else if (SelectTab === "Disabled Services") {
            return item.active === false;
          } else if (SelectTab === "Deleted Services") {
            return item.is_deleted;
          }
          return [];
        }),
      };
    });
    setFilterServiceGroupServices(filterServiceGroup);
  }, [serviceResponse, SelectTab, serviceGroups]);

  // FUNCTIONS

  const handleCreateService = async (values) => {
    try {
      setServiceFormLoader(true);
      const objSend = { ...values, company: companyId, service_group: values?.service_group?.id || null };
      Object.keys(objSend).forEach((key) => {
        if (objSend[key] === "") {
          delete objSend[key];
        }
      });
      delete objSend["extra_pet_dropDown"];
      delete objSend["tasks"];

      if (updateServiceFormModal) {
        return await handleUpdateService(objSend, () => setServiceFormLoader(false));
      }
      const { data } = await main_api.post(companyAPIsEndPoints.POST_NEW_SERVICES, objSend);

      if (data.isSuccess) {
        pushNotification(data?.message, "success");
        setAddNewServiceModalShow(false);
        fetchAllServices();
      }
      setServiceFormLoader(false);
    } catch (error) {
      setServiceFormLoader(false);

      console.log(error, "error");
    }
  };

  const handleUpdateService = async (values, formloader) => {
    try {
      const objSend = {
        ...values,
      };
      const { data } = await main_api.put(companyAPIsEndPoints.UPDATE_SPECIFIC_SERVICE_(updateServiceValues.id), objSend);
      if (data.isSuccess) {
        pushNotification(data?.message, "success");
        fetchAllServices();
        // fetchServiceGroups();
        setUpdateServiceFormModal(false);
        setSidebarServiceDetailDrawer(false);
      }
      formloader();
    } catch (error) {
      formloader();

      console.log(error, "error");
    }
  };

  const handleDeleteService = async () => {
    try {
      const response = await main_api.delete(companyAPIsEndPoints.DELETE_SPECIFIC_SERVICE_(deleteRowId));

      if (response?.status === 200) {
        pushNotification(response?.data.message, "success");
        setServiceDelete(false);
        setSidebarServiceDetailDrawer(false);
        fetchAllServices();
        fetchServiceGroups();
        return;
      }
      pushNotification(response?.data.message, "error");
    } catch (error) {
      console.log("error:", error);
    }
  };
  const handleDeleteGroup = async () => {
    try {
      const response = await main_api.delete(`/api/v1/service_group/2/`);

      if (response?.status === 200) {
        pushNotification(response?.data.message, "success");
        setServiceDelete(false);
        setSidebarServiceDetailDrawer(false);
        fetchAllServices();
        fetchServiceGroups();
        return;
      }
      pushNotification(response?.data.message, "error");
    } catch (error) {
      console.log("error:", error);
    }
  };
  const handleDisableRow = async (values) => {
    try {
      const objSend = {
        ...values,
        active: values.active ? false : true,
        company: companyId,
        service_group: values?.service_group?.id || null,
      };

      const { data } = await main_api.put(companyAPIsEndPoints.UPDATE_SPECIFIC_SERVICE_(objSend.id), objSend);
      if (data.isSuccess) {
        pushNotification(data?.message, "success");
        setUpdateServiceFormModal(false);
        fetchAllServices();
        fetchServiceGroups();
      }
    } catch (error) {
      if (error?.response.status === 500) {
        pushNotification("An error occurred. Please try again.", "error");
      }
      console.log(error);
    }
  };

  const handleCreateGroup = async (values) => {
    try {
      if (editGroupServiceModal) {
        handleEditGroup(values);
        return;
      }

      const { data } = await main_api.post(companyAPIsEndPoints.SERVICE_CREATE_GROUP, values);

      if (data.isSuccess) {
        fetchServiceGroups();
        fetchAllServices();
        pushNotification(data?.message, "success");
        setAddGroupModalShow(false);
      }
    } catch (error) {
      console.log(error, "error");
    }
  };
  const handleEditGroup = async (values) => {
    try {
      const { data } = await main_api.put(companyAPIsEndPoints.SERVICE_UPDATE_GROUP(editServiceGrouoValues?.groupId), {
        ...values,
        services: values?.services,
      });
      if (data.isSuccess) {
        pushNotification(data?.message, "success");
        setEditGroupServiceModal(false);
        fetchServiceGroups();
        fetchAllServices();
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  const handleFetchPromoCode = async (id) => {
    try {
      setPromoCodeFetchLoader(true);
      if (!id) {
        setPromoCodeValues(null);
        setPromoCodeFetchLoader(false);

        return;
      }
      const { data } = await main_api.get(companyAPIsEndPoints.SERVICE_FETCH_SINGLE_PROMO_CODE(id));

      if (data.isSuccess) {
        setPromoCodeValues(data.result);
      }
      setPromoCodeFetchLoader(false);
    } catch (error) {
      setPromoCodeFetchLoader(false);
    }
  };

  const handleUpdatePromoCode = async (val) => {
    try {
      const { data } = await main_api.put(companyAPIsEndPoints.UPDATE_SINGLE_PROMO_CODE(promoCodeValues.id), {
        ...promoCodeValues,
        status: val,
      });
      if (data.isSuccess) {
        pushNotification(data.message);
      }
    } catch (error) {}
  };

  const addNewService = () => {
    if (checkPermission(permissionManagement.permissions, "create_service")) {
      setAddNewServiceModalShow(true);
    } else {
      setPermissionPopUp(true);
    }
  };

  const editService = (record) => {
    if (checkPermission(permissionManagement.permissions, "edit_service")) {
      setUpdateServiceFormModal(true);
      handleFetchPromoCode(record.promo_code);
      setUpdateServiceValues(record);
    } else {
      setPermissionPopUp(true);
    }
  };

  const openEditServiceDrawer = () => {
    if (checkPermission(permissionManagement.permissions, "edit_service")) {
      setUpdateServiceFormModal(true);
    } else {
      setPermissionPopUp(true);
    }
  };

  const openDeleteServiceDrawer = (id) => {
    if (checkPermission(permissionManagement.permissions, "delete_service")) {
      setDeleteRowId(id);
      setServiceDelete(true);
    } else {
      setPermissionPopUp(true);
    }
  };

  const deleteService = (record) => {
    if (checkPermission(permissionManagement.permissions, "delete_service")) {
      setDeleteRowId(record?.id);
      setServiceDelete(true);
    } else {
      setPermissionPopUp(true);
    }
  };

  const serviceHeadersParms = {
    setUpdateServiceFormModal,
    setUpdateServiceValues,
    handleDisableRow,
    setDeleteRowId,
    handleFetchPromoCode,
    setServiceDelete,
    SelectTab,
    sidebarDrOpen: (rec) => {
      if (checkPermission(permissionManagement.permissions, "view_service")) {
        setUpdateServiceValues(rec);

        handleFetchPromoCode(rec?.promo_code);
        setSidebarServiceDetailDrawer(true);
      } else {
        setPermissionPopUp(true);
      }
    },
    editService,
    deleteService,
  };

  const groups = [...new Set(filteredServices?.map((service) => service?.service_group?.id))]?.filter((items) => items) || [];

  const serviceGroupFromServices = groups?.map((groupId) => {
    const groupServices = filteredServices?.filter((service) => service?.service_group && service?.service_group?.id === groupId);
    return {
      ...groupServices?.[0]?.service_group,
      services: groupServices?.map((service) => service),
    };
  });

  return (
    <>
      <div className="" style={{ width: "500px" }}>
        <CustomSegmented
          style={{ marginBottom: 8 }}
          onChange={(value) => setSelectTab(value)}
          options={["Active Services", "Disabled Services", "Deleted Services"]}
          className="w-100 p-1"
          size="large"
          block
          defaultValue="Active Services"
        />
      </div>
      {checkPermission(permissionManagement.permissions, "view_services") && (
        <div className="d-flex justify-content-between align-items-baseline">
          <SearchInput
            onChange={(value) => setSearchTerm(value)}
            width="90%"
            placeholder="Type to search..."
            containerBorder={"0"}
          />
          <div className="d-flex ">
            <CustomBtnWithIcon
              btnText={"Add a Group"}
              noBackground
              className=" me-2"
              onClick={() => setAddGroupModalShow(true)}
            />
            <CustomBtnWithIcon btnText={"Add new Service"} onClick={() => addNewService()} />
          </div>
        </div>
      )}
      <div className="my-2">
        {filteredServices?.length > 0 && checkPermission(permissionManagement.permissions, "view_services") ? (
          <AntdesignTablev2
            scroll={{
              y: 540,
            }}
            columns={ServiceHeaders({ ...serviceHeadersParms })}
            data={filteredServices || []}
            loading={serviceStateLoader}
          />
        ) : (
          <NoDataScreen
            heading="You don’t have any Service in your list. Let’s get started by adding a new Service"
            btnText={"Add new Service"}
            onClick={() => addNewService()}
          />
        )}
      </div>

      {/* <NavigationHeading>Services By Groups</NavigationHeading> */}
      <Divider />

      {serviceGroupFromServices?.map((items, index) => {
        return (
          <div key={index}>
            <AntdesignTablev2
              scroll={{
                y: 540,
              }}
              columns={customHeaders({
                tableHeaderName: items.name,
                editHeaderOnclick: () => {
                  setEditServiceGrouoValues({
                    serviceList: items.services,
                    groupName: items.name,
                    groupId: items.id,
                  });
                  setEditGroupServiceModal(true);
                },
                ...serviceHeadersParms,
              })}
              loading={serviceStateLoader}
              data={items.services}
            />
          </div>
        );
      })}

      {/* modals */}
      {/* ========== GROUP ========== */}
      {addGroupModalShow && (
        <AddNewGroupModal
          open={addGroupModalShow}
          close={() => setAddGroupModalShow(false)}
          serviceList={serviceList}
          handleCreateGroup={handleCreateGroup}
        />
      )}

      {editGroupServiceModal && (
        <AddNewGroupModal
          open={editGroupServiceModal}
          close={() => setEditGroupServiceModal(false)}
          serviceList={serviceList}
          handleCreateGroup={handleCreateGroup}
          updateGroup={true}
          groupValues={editServiceGrouoValues}
          fetchAllServices={fetchAllServices}
          fetchServiceGroups={fetchServiceGroups}
        />
      )}
      {/* ========= SERVICE =========== */}
      {addNewServiceModalShow && (
        <AddNewServiceModal
          open={addNewServiceModalShow}
          close={() => setAddNewServiceModalShow(false)}
          handleCreateService={handleCreateService}
          blockTimeDropDown={blockTimeDropDown}
          serviceFormLoader={serviceFormLoader}
          promoCodeFetchLoader={promoCodeFetchLoader}
        />
      )}
      {/* ==================== */}
      {/* update modal */}
      {updateServiceFormModal && (
        <AddNewServiceModal
          open={updateServiceFormModal}
          close={() => setUpdateServiceFormModal(false)}
          handleCreateService={handleCreateService}
          updateForm={true}
          updateServiceValues={updateServiceValues}
          promoCodeValues={promoCodeValues}
          blockTimeDropDown={blockTimeDropDown}
          serviceFormLoader={serviceFormLoader}
          promoCodeFetchLoader={promoCodeFetchLoader}
        />
      )}
      {/* ==================== */}
      <DeleteConfirmationRowModal
        onclose={() => setServiceDelete(false)}
        handleConfirmDeleteRows={() => handleDeleteService()}
        openConfimrationDeleteModal={serviceDelete}
        padding="0px"
        // BtnloadingState={() => console.log("")}
      />
      <ServiceDrawer
        closeDrawer={() => setSidebarServiceDetailDrawer(false)}
        openDetailsDrawer={sidebarServiceDetailDrawer}
        onClose={() => setSidebarServiceDetailDrawer(false)}
        serviceValues={updateServiceValues}
        openEditService={() => openEditServiceDrawer()}
        deleteService={(id) => {
          openDeleteServiceDrawer(id);
          // setDeleteRowId(id);
          // setServiceDelete(true);
        }}
        handleUpdatePromoCode={handleUpdatePromoCode}
        promoCodeValues={promoCodeValues}
        setPromoCheckbox={setPromoCodeValues}
      />
      <PermissionModal onclose={() => setPermissionPopUp(false)} openModal={permissionPopUp} />
    </>
  );
}

export default Service;

const customHeaders = ({
  tableHeaderName,

  editHeaderOnclick,
  setUpdateServiceFormModal,
  setUpdateServiceValues,
  handleDisableRow,
  setDeleteRowId,
  setServiceDelete,
  SelectTab,
  sidebarDrOpen,
}) => {
  return [
    {
      title: tableHeaderName,
      dataIndex: "name",
      key: "name",
      width: 260,
      render: (text, record) => <a onClick={() => sidebarDrOpen(record)}>{text}</a>,
    },
    {
      title: "",
      dataIndex: "cost",
    },

    {
      title: "",
      dataIndex: "default_staff_rate",
    },
    {
      title: "",
      dataIndex: "taxable",
      render: (text) => <a>{text ? "Yes" : "No"}</a>,
    },
    {
      title: (
        <div className="cursorPointer" onClick={editHeaderOnclick}>
          <span className="me-2">
            {" "}
            <EditIcon />
          </span>
          Edit
        </div>
      ),
      key: "action",
      align: "center",
      render: (_, record) => (
        <>
          <Dropdown
            menu={{
              items: [
                {
                  label: (
                    <div
                      style={{ width: "80px", padding: "2px 2px" }}
                      onClick={(e) => {
                        setUpdateServiceFormModal(true);

                        setUpdateServiceValues(record);
                      }}
                    >
                      <span className="me-3">
                        <EditIcon />
                      </span>
                      Edit
                    </div>
                  ),
                  key: "0",
                },
                {
                  label: (
                    <div
                      style={{ width: "80px", padding: "2px 0px" }}
                      className="d-flex justify-content-evenly"
                      onClick={() => {
                        if (record.id) {
                          handleDisableRow(record);
                        }
                      }}
                    >
                      <span className="me-3">{SelectTab === "Disabled Services" ? <EnableService /> : <DisabledIcon />}</span>
                      {SelectTab === "Disabled Services" ? "Enable" : "Disable"}
                    </div>
                  ),
                  key: "1",
                },

                {
                  label: (
                    <div
                      style={{ width: "80px", padding: "2px 0px" }}
                      className="d-flex justify-content-evenly"
                      onClick={() => {
                        setDeleteRowId(record?.id);
                        setServiceDelete(true);
                      }}
                    >
                      <span className="me-3">
                        <DeleteIcon />
                      </span>
                      <span>Delete</span>
                    </div>
                  ),
                  key: "3",
                },
              ],
            }}
            trigger={["click"]}
          >
            <a
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              <Space size="middle" className="cursorPointer" style={{ transform: "rotate(270deg)" }}>
                <DropDownDots />
              </Space>
            </a>
          </Dropdown>
        </>
      ),
    },
  ];
};

const AntdesignTablev2 = ({
  selectedRows,
  tableHeight,
  data = [],
  columns = [],
  paginationRightHeadingName = "Total Results",
  ...props
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;

  const onChange = useCallback((rowKeys, SelctRows) => {
    selectedRows({ rowKeys, SelctRows });
  }, []);

  const getCheckboxProps = useCallback(
    (record) => ({
      disabled: record.name === "Disabled User",
      name: record.name,
    }),
    [],
  );

  const rowSelection = {
    onChange,
    getCheckboxProps,
  };

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = Math.min(currentPage * pageSize, data.length);
  return (
    <div style={{ minWidth: "1100px", overflow: "auto" }}>
      <AntdCustomTable
        className="antd-table-custom"
        columns={columns}
        dataSource={data?.slice(startIndex, endIndex)}
        pagination={false}
        {...props}
      />
    </div>
  );
};

const AntdCustomTable = styled(Table)`
  &.antd-table-custom .ant-table-thead .ant-table-cell {
    background-color: #f5f5f5;
  }
  &.ant-table-body {
    min-height: 210px !important;
  }
`;

const CustomSegmented = styled(Segmented)`
  .ant-segmented-group {
    ${"" /* gap: 5px; */}
  }
  .ant-segmented-item {
    width: 50%;
  }
`;

const NavigationHeading = styled.h2`
  display: flex;
  margin: 25px 0px 10px 0px;
  font-size: 20px;
  font-weight: 700;
`;
