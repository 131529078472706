import { Segmented } from "antd";
import React from "react";
import styled from "styled-components";

const SegmentedTabs = ({selectTab, setSelectTab, options, size, classes}) => {
  return (
    <CustomSegmented
      defaultValue={selectTab}
      style={{ marginBottom: 8 }}
      onChange={(value) => setSelectTab(value)}
      options={options}
      className={classes}
      size={size}
    />
  );
};

export default SegmentedTabs;
const CustomSegmented = styled(Segmented)`
  .ant-segmented-group {
    gap: 5px;
  }
  .ant-segmented-item {
    width: fit-content;
  }
`;