import KeyManagement from "../../pages/company/keys/KeyManagement";
import { connect } from "react-redux";
import { FetchKeysApi, PostKeyApi, PostExistingKey, updateSingleKeysApi } from "../../redux/slices/KeySlice";
import { FetchClientsApi } from "../../redux/slices/ClientSlice";
import { fetchAllStaffApi } from "../../redux/slices/StaffSlice";
const mapStateToProps = (state) => {
  return {
    keyManagementState: state.keyManagement.KeyResponse,
    keyPostState: state.keyManagement.keysPostResponse,
    keyEditState: state.keyManagement.keyEditResponse,
    clientManagementState: state.clientManangement.clientResponse,
    clientResponse: state.clientManangement.clientResponse,
    loginState: state.auth.loginResponse,
    permissionState: state.permissionManagement.permissions,
    allStaffList: state.staffManagement.staffAllDetails.staffList,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getManagementkeys: (queryParams = "", search = "") => {
    dispatch(FetchKeysApi(queryParams, search));
  },
  postManagementKey: (data) => {
    dispatch(PostKeyApi(data));
  },
  postExistingKey: (data) => {
    dispatch(PostExistingKey(data));
  },
  updateSingleKey: (data) => {
    dispatch(updateSingleKeysApi(data));
  },

  getManagementClients: (data) => {
    dispatch(FetchClientsApi());
  },
  fetchAllStaff: (id) => {
    dispatch(fetchAllStaffApi(id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(KeyManagement);
