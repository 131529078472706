import { connect } from "react-redux";
import { editPet } from "../../redux/slices/pets";
import { loadCompanyDetail, openCompanyDetailModal, openDetailModal } from "../../redux/platformSlices/CompanyDetail";
import CompanyDetailPage from "../../components/CompanyDetailPage";


const mapStateToProps = (state) => {
  return {
    isLoading: state.companyDetail.isLoading,
    error: state.companyDetail.error,
    companyDetailResponse: state.companyDetail.companyDetailResponse,
    isCompanyDetailModalOpen: state.companyDetail.isCompanyDetailModalOpen,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadCompanyDetail: (id) => {
    dispatch(loadCompanyDetail(id));
  },
  openCompanyDetailModal: (isOpen) => {
    dispatch(openCompanyDetailModal(isOpen));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(CompanyDetailPage);
