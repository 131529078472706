import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";

import { main_api } from "../../../../api/axios_helper";
import { Col, Row, Tag } from "antd";
import dayjs from "dayjs";
import styled from "styled-components";

import { useNavigate } from "react-router-dom";
import { ReactComponent as LessThanIcon } from "../../../../assets/rawSvgs/lessThanBlack.svg";
import { ReactComponent as EditPencilIcon } from "../../../../assets/rawSvgs/editPencilIcon.svg";

import { ReactComponent as TreasureIcon } from "../../../../assets/rawSvgs/treasureIcon.svg";
import { ReactComponent as StatusIcon } from "../../../../assets/rawSvgs/staffIcon.svg";

import { ReactComponent as LocationIcon } from "../../../../assets/rawSvgs/locationIcon.svg";

import { ReactComponent as GeneralSchedulerIcon } from "../../../../assets/companySvgIcons/sidebarIcons/generalSchedulerIcon.svg";
import { ReactComponent as ManagementClientsicon } from "../../../../assets/companySvgIcons/sidebarIcons/managementClientsIcon.svg";
//
import { ReactComponent as LockInvoiceIcon } from "../../../../assets/rawSvgs/unlockicon.svg";
import { ReactComponent as UnlockInvoiceIcon } from "../../../../assets/rawSvgs/lockinvoice.svg";
//
import { ReactComponent as TotalInActiveCompaniesIcon } from "../../../../assets/rawSvgs/totalInActiveCompanies.svg";
import { HeaderContainer, UserNameWithIconWrapper, UserDetailIconTextWrapper } from "./ClientDetailsStyles";

import {
  tagStyle,
  getInvoiceStatusStyles,
  invoicingDetailService,
} from "../../../../constants/CompanyTableHeaders/invoicingTableHeaders";
import { AddNewEventModal } from "../../sheduler/SchedulerEvents";
import HeadingComponent from "../../../../components/Heading";
import { AntdesignTable } from "../../../../components/antDesignTable/AntdesignTable";
import CustomBtnWithIcon from "../../../../components/buttonWithIcon";
import { checkPermission } from "../../../../util/commonUtil";
import SearchInput from "../../../../components/SearchInput";
import NoDataScreen from "../../../../components/NoDataScreen";

import { companyAPIsEndPoints } from "../../../../constants/ApiEndPoints";
import { pushNotification } from "../../../../util/Notification";
import ClaimExistingServiceModal from "./ClaimExistingServiceModal";
import EditInvoiceModal from "./EditInvoiceModal";
import DeleteConfirmationRowModal from "../../../../components/Modals/DeleteConfirmationRowModal";
import PermissionModal from "../../../../components/Modals/PermissionModal";
//
function ClientsDetail({
  singleClientresponseData,
  permissionState,
  invoiceSingleDetail,
  getManagementClients,
  fetchSingleInvoice,
  clientResponse,
  petList,
  serviceStateResponseData,
  staffState,
  totalServiceDetails,
  clientManagementState,
  loadPetList,
  fetchAllServices,
  fetchAllStaff,
  loginState,
  invoicingState,
  fetchAllInvoice,
}) {
  const [openAddServiceModal, setOpenAddServiceModal] = useState(false);
  const [claimExistingServModal, setClaimExistingServModal] = useState(false);
  const [blockTimeDropDown, setBlockTimeDropDown] = useState([]);
  const [editInvoiceModal, setEditInvoiceModal] = useState(false);
  const [openVoidModal, setOpenVoidModal] = useState(false);
  const [timeBlockCompList, setTimeBlockCompList] = useState([]);
  const [seaerchQuery, setSeaerchQuery] = useState("");
  const [permissionPopUp, setPermissionPopUp] = useState(false);

  const navigate = useNavigate();
  let { invoiceId: paramid } = useParams();
  let invoiceId = parseInt(paramid);
  useEffect(() => {
    window.scroll(0, 0);
  }, [invoiceId]);

  useEffect(() => {
    fetchSingleInvoice(invoiceId);
    getManagementClients();
    // fetchAllInvoice();
    loadPetList();
    fetchAllServices();
    fetchAllStaff();
    handleFetchTimeblockList();
  }, []);

  const handleFetchTimeblockList = async () => {
    try {
      const { data } = await main_api.get(companyAPIsEndPoints.FETCH_TIME_BLOCKS);

      const modifiyList = data?.result?.map((items) => {
        return {
          label: items.name,
          value: items.id,
        };
      });
      setTimeBlockCompList(data?.result);
      setBlockTimeDropDown(modifiyList);
    } catch (error) {
      console.log("Error:", error);
    }
  };
  const handleSubmitServiceForm = async (values) => {
    try {
      let objSend = { ...values, invoice_id: invoiceId };
      const { data } = await main_api.post(companyAPIsEndPoints.POST_SCHEDULER_LIST, objSend);

      if (data.isSuccess) {
        pushNotification("Service added Successfully!.", "success");
        setOpenAddServiceModal(false);
        fetchSingleInvoice(invoiceId);
      }
    } catch (error) {
      console.log("error :", error);
      if (parseInt(error.response?.data?.status) === 400) {
        pushNotification("This service cannot be scheduled in this time Block", "error");
      }
      pushNotification("Something went wrong try again", "error");
    }
  };

  const handleLockUnlockInvoice = async (values) => {
    try {
      let objSend = {
        company: loginState?.selectCompany[0]?.company_id,
        is_locked: !invoiceData?.is_locked,
      };
      const { data } = await main_api.put(companyAPIsEndPoints.LOCK_INVOICE(invoiceId), objSend);

      if (data.isSuccess) {
        pushNotification("Invoice updated!.", "success");
        fetchSingleInvoice(invoiceId);
      }
    } catch (error) {
      console.log("error :", error);
    }
  };
  const handleVoidInvoice = async () => {
    try {
      if (invoiceData?.status === "void") return;
      let objSend = {
        company: loginState?.selectCompany[0]?.company_id,
        status: "void",
      };
      const { data } = await main_api.put(companyAPIsEndPoints.LOCK_INVOICE(invoiceId), objSend);
      setOpenVoidModal(false);
      if (data.isSuccess) {
        pushNotification("Invoice updated!.", "success");
        fetchSingleInvoice(invoiceId);
      }
    } catch (error) {
      console.log("error :", error);
    }
  };

  let invoiceData = invoiceSingleDetail?.responseData?.invoice || invoiceSingleDetail.responseData?.inovice;
  let invoiceDataServices = invoiceSingleDetail?.responseData?.result || [];
  // console.log("invoiceSingleDetail", invoiceData);

  let clientPetServiceDropdowns = {
    clientResponse,
    timeBlockCompList: timeBlockCompList,
    totalServiceDetails,
    petListState: petList,
    serviceState: serviceStateResponseData?.serviceList,
    clientState: clientManagementState?.clientList,
    clientAllState: clientManagementState,
    staffState: staffState.staffAllDetails?.staffList,
    allServices: serviceStateResponseData,
    blockTimeDropDown: blockTimeDropDown,
  };

  const results = invoiceDataServices?.filter((item) =>
    item?.single_service_request?.service?.name.toLowerCase().includes(seaerchQuery.toLowerCase()),
  );
  return checkPermission(permissionState, "view_client") ? (
    <>
      {invoiceSingleDetail.isLoading ? (
        "Loading..."
      ) : (
        <>
          <HeaderContainer>
            <UserNameWithIconWrapper>
              <div className="d-flex align-items-end">
                <button onClick={() => navigate(-1)}>
                  <LessThanIcon width="13px" height="13px" />
                </button>
                <HeadingComponent
                  text={"Invoice # " + invoiceData?.id || ""}
                  fontSize="1.25rem"
                  fontWeight={700}
                  margin="0px 15px"
                />
              </div>
              <div className="d-flex align-items-center">
                <CustomBtnWithIcon
                  btnText={
                    <>
                      <TotalInActiveCompaniesIcon height="18px" width="18px" />
                      <span className="ms-2"> Void Invoice</span>
                    </>
                  }
                  hideIcon
                  noBackground
                  // onClick={handleVoidInvoice}
                  onClick={() => setOpenVoidModal(true)}
                  disabled={invoiceData?.is_locked || invoiceData?.status === "void"}
                />
                &nbsp; &nbsp;
                <CustomBtnWithIcon
                  btnText={
                    <>
                      {!invoiceData?.is_locked ? (
                        <>
                          <LockInvoiceIcon /> Lock Invoice
                        </>
                      ) : (
                        <>
                          <UnlockInvoiceIcon /> Unlock Invoice
                        </>
                      )}
                    </>
                  }
                  hideIcon
                  noBackground
                  onClick={handleLockUnlockInvoice}
                />
                &nbsp; &nbsp;
                <button
                  className=""
                  disabled={invoiceData?.is_locked}
                  onClick={() => {
                    setEditInvoiceModal(true);
                  }}
                >
                  <EditPencilIcon />
                </button>
              </div>
            </UserNameWithIconWrapper>
            <UserDetailIconTextWrapper>
              <Row gutter={20}>
                <Col md={12}>
                  <IconWithTextHeaderComponent icon={<ManagementClientsicon />} text={invoiceData?.client?.name || "---"} />
                </Col>
                <Col md={12}>
                  <IconWithTextHeaderComponent
                    icon={<StatusIcon />}
                    text={
                      <Tag className="text-capitalize" style={{ ...tagStyle, ...getInvoiceStatusStyles(invoiceData?.status) }}>
                        {invoiceData?.status === "sent_to_client" ? "Sent to client" : invoiceData?.status || "---"}
                      </Tag>
                    }
                  />
                </Col>
                <Col md={12}>
                  <IconWithTextHeaderComponent
                    icon={<GeneralSchedulerIcon />}
                    text={dayjs(invoiceData?.created_at).format("DD/MM/YYYY") || "---"}
                  />
                </Col>
                {/* <Col md={12}>
                  <IconWithTextHeaderComponent
                    icon={<TreasureIcon />}
                    text={`$ ${singleClientresponseData?.balance || "0.00"} Balance`}
                  />
                </Col> */}
              </Row>
            </UserDetailIconTextWrapper>
          </HeaderContainer>
          <div>
            <Row style={{ alignItems: "center" }}>
              <Col lg={12}>
                <PetsHeaderSection>
                  <SearchInput
                    placeholder="Type to search.."
                    search={seaerchQuery}
                    onChange={(value) => setSeaerchQuery(value)}
                    // onBlur={searchBlurHandler}
                  />
                </PetsHeaderSection>
              </Col>
              <Col lg={12} style={{ display: "flex", justifyContent: "end" }}>
                <CustomBtnWithIcon
                  btnText={"Claim existing service"}
                  margin="0px 10px"
                  noBackground
                  hideIcon
                  onClick={() => {
                    if(checkPermission(permissionState, 'claim_invoice')){
                      setClaimExistingServModal(true);
                    } else {
                      setPermissionPopUp(true);
                    }
                  }}
                  disabled={invoiceData?.is_locked}
                />
                <CustomBtnWithIcon
                  btnText={"Add a service"}
                  onClick={() => {
                    setOpenAddServiceModal(true);
                  }}
                  disabled={invoiceData?.is_locked}
                />
              </Col>
            </Row>
          </div>
          <div>
            <AntdesignTable
              columns={invoicingDetailService({})}
              data={results || []}
              // loading={invoicingState?.isLoading}
              allowMultieSelectRows={false}
            />
          </div>
          {openAddServiceModal && (
            <AddNewEventModal
              addNewEventModal={openAddServiceModal}
              closeModal={() => {
                setOpenAddServiceModal(false);
              }}
              clientPetServiceDropdowns={clientPetServiceDropdowns}
              fromInvoicing={true}
              handleSubmitServiceForm={handleSubmitServiceForm}
              clientFromInvoice={invoiceData?.client?.id}
            />
          )}
          {claimExistingServModal && (
            <ClaimExistingServiceModal
              openModal={claimExistingServModal}
              closeModal={() => setClaimExistingServModal(false)}
              invoiceId={invoiceId}
              fetchAllInvoice={fetchAllInvoice}
              invoicingState={invoicingState?.allInvoicing?.responseData}
              invoicingLoader={invoicingState?.allInvoicing.isLoading}
              compId={loginState?.selectCompany[0]?.company_id}
              clientId={invoiceData?.client?.id}
              fetchSingleInvoice={() => fetchSingleInvoice(invoiceId)}
            />
          )}
          {editInvoiceModal && (
            <EditInvoiceModal
              visible={editInvoiceModal}
              handleClose={() => {
                setEditInvoiceModal(false);
              }}
              compId={loginState?.selectCompany[0]?.company_id}
              invoiceData={invoiceData}
              invoiceId={invoiceId}
              onSuccess={() => {
                fetchSingleInvoice(invoiceId);
              }}
            />
          )}

          <DeleteConfirmationRowModal
            onclose={() => setOpenVoidModal(false)}
            handleConfirmDeleteRows={handleVoidInvoice}
            openConfimrationDeleteModal={openVoidModal}
            deleteBtnText="Confirm"
            text="By voiding this invoice, you’ll no longer be able to unvoid it."
          />
          <PermissionModal onclose={() => setPermissionPopUp(false)} openModal={permissionPopUp} />
        </>
      )}
    </>
  ) : (
    <>
      <NoDataScreen heading="You don’t have acces to view Client Detail" showBtn={false} />
    </>
  );
}

export const IconWithTextHeaderComponent = ({ icon, text }) => {
  return (
    <div className="d-flex align-items-baseline mt-3 me-4 ms-1">
      <span>{icon}</span>
      <HeadingComponent text={text} fontSize="0.875rem" fontWeight={400} margin="0px 6px" />
    </div>
  );
};

export default React.memo(ClientsDetail);

const PetsHeaderSection = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 10px;
`;
const PetsCardWrapperDiv = styled.div`
  width: 100%;
  margin-top: 15px;
`;
