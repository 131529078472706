// import { Space, Tag } from "antd";
import { ReactComponent as EditIcon } from "../assets/rawSvgs/tableEditIcon.svg";
import { ReactComponent as DeleteIcon } from "../assets/rawSvgs/tableDeleteIcon.svg";

import { Space, Tag } from "antd";
import { CommonActionHeaders } from "./TableActionHeaderColumn";

import dayjs from "dayjs";
import styled from "styled-components";
import CustomBtnWithIcon from "../components/buttonWithIcon";

export const CompanyListColumns = ({
  isLoading,
  handleDelete = "",
  handleEditRow = "",
  props = "",
  handleSendForgetPasswordReq,
}) => {
  return [
    {
      title: "Company Name",
      dataIndex: "name",
      key: "name",
      render: (_, record) => (
        <a
          onClick={() => {
            props.openCompanyDetailModal(true);
            props.loadCompanyDetail(record.id);
          }}
        >
          {record.name}
        </a>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 270,
      ellipsis: true,
    },
    {
      title: "Admin Name",
      dataIndex: "admin_name",
      key: "admin_name",
      render: (_, record) => <>{record.admin_name || "---"}</>,
    },
    {
      title: "Status",
      dataIndex: "active",
      key: "active",
      render: (_, record) =>
        record.active ? (
          <Tag bordered={false} color="green">
            Active
          </Tag>
        ) : (
          <Tag bordered={false} color="blue">
            InActive
          </Tag>
        ),
    },
    {
      title: "Registered Date",
      dataIndex: "date_added",
      key: "date_added",
      render: (_, record) => <>{dayjs(record.date_added).format("DD/MM/YYYY")}</>,
    },
    {
      title: "",
      key: "action",
      width: 400,
      ellipsis: true,
      render: (_, record) => (
        <Space size="middle">
          <CustomBtnWithIcon
            btnText={"Send Password Recovery Email"}
            hideIcon
            noBackground
            disabled={isLoading}
            onClick={() => handleSendForgetPasswordReq({ email: record.email })}
          />
          <Button
            onClick={(event) => {
              event.stopPropagation();
              handleEditRow && handleEditRow(record);
            }}
            disabled={isLoading}
          >
            <EditIcon />
          </Button>
          <Button
            onClick={(event) => {
              event.stopPropagation();
              handleDelete && handleDelete(record);
            }}
            disabled={isLoading}
          >
            <DeleteIcon />
          </Button>
        </Space>
      ),
    },
  ];
};
const Button = styled.button`
  border: none;
  background: none;
`;

export const UserListColumns = ({ handleDelete = "", handleEditRow = "", props = "" }) => {
  return [
    {
      title: "User Name",
      dataIndex: "name",
      key: "name",
      render: (_, record) => <>{record.name}</>,
    },
    {
      title: "Company Name",
      dataIndex: "company",
      key: "company",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Roles",
      dataIndex: "role",
      key: "role",
      render: (_, record) => (
        <Tag bordered={false} color="blue">
          {record.role}
        </Tag>
      ),
    },
    {
      title: "Phone Number",
      dataIndex: "company_phone_number",
      key: "company_phone_number",
      render: (_, record) => <>{record.phone_number}</>,
    },
    {
      title: "Registered Date",
      dataIndex: "date_added",
      key: "date_added",
      render: (_, record) => <>{dayjs(record.date_joined).format("DD/MM/YYYY")}</>,
    },
    ...CommonActionHeaders({ handleDelete, handleEditRow }),
  ];
};
