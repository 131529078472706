import React, { useEffect, useState } from "react";
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./week.css";
import ClientEvent from "./ClientEvent";
import EventDetail from "./EventDetail";
import { schedulerBackground, schedulerColor } from "./helpers";
import { checkPermission } from "../../util/commonUtil";
import PermissionModal from "../Modals/PermissionModal";
import { useSelector } from "react-redux";

const localizer = momentLocalizer(moment);

const CalendarWeek = ({ schedulerState, dateRange, handleDataChange, loading }) => {
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [permissionPopUp, setPermissionPopUp] = useState(false);
  const permissionManagement = useSelector((state) => state.permissionManagement);

  // const dayFormat = (date, culture, localizer) => localizer.format(date, 'D MMMM YYYY', culture);
  const dayFormat = (date, culture, localizer) => localizer.format(date, "dddd", culture);

  moment.updateLocale("en-gb", {
    week: {
      dow: 1, // Monday is the first day of the week
    },
    // Optionally, if you want to keep the time format to 12-hour, you can set it explicitly
    longDateFormat: {
      LT: "h:mm A", // 12-hour time format
      LTS: "h:mm:ss A", // 12-hour time format with seconds
      // Add other formats if needed
    },
  });

  useEffect(() => {
    // let eventContainers = document.querySelectorAll('.rbc-events-container');
    // eventContainers.forEach(container => {
    //   container.style.width = '3120px'; // Apply the desired width
    // });
    // eventContainers = document.querySelectorAll('.rbc-day-slot .rbc-timeslot-group');
    // eventContainers.forEach(container => {
    //   container.style.width = '3120px'; // Apply the desired width
    // });
  }, []);

  const selectEvent = (event) => {
    if (checkPermission(permissionManagement.permissions, "view_service_request")) {
      setSelectedEvent(event);
    } else {
      setPermissionPopUp(true);
    }
  };

  useEffect(() => {
    let modifyEvents = [];
    schedulerState.result &&
      schedulerState.result.map((scheduler) => {
        const [year, month, day] = scheduler.date.split("-").map(Number);
        const [startHour, startMinute, startSecond] = scheduler.start_time ? scheduler.start_time.split(":") : [0, 0, 0];
        const [endHour, endMinute, endSecond] = scheduler.end_time ? scheduler.end_time.split(":") : [0, 0, 0];
        modifyEvents.push({
          ...scheduler,
          backgroundColor: schedulerBackground(scheduler),
          color: schedulerColor(scheduler),
          start: new Date(year, month - 1, day, startHour, startMinute),
          end: new Date(year, month - 1, day, endHour, endMinute),
          title: scheduler.service.name || "",
        });
      });
    setEvents(modifyEvents);
  }, [schedulerState]);

  return (
    <div id="scheduler-week-calendar" className="mb-3 mt-3">
      <div className={loading ? "filtering" : null}>
        <Calendar
          localizer={localizer}
          events={events}
          defaultView={Views.WEEK}
          views={["week"]}
          startAccessor="start"
          endAccessor="end"
          date={dateRange.start}
          drilldownView={Views.WEEK}
          formats={{
            dayFormat,
          }}
          components={{
            event: ClientEvent,
            toolbar: () => null,
          }}
          onSelectEvent={(event) => selectEvent(event)}
          eventPropGetter={(event) => ({
            style: {
              backgroundColor: event.backgroundColor,
              color: event.color,
              border: `1px solid ${event.color}`,
              borderRadius: 0,
              outline: "none",
            },
          })}
          dayLayoutAlgorithm="no-overlap"
        />
      </div>
      {selectedEvent && (
        <EventDetail
          handleDataChange={handleDataChange}
          event={selectedEvent}
          setIsModalOpen={() => setSelectedEvent(null)}
          isModalOpen={true}
        />
      )}
      <PermissionModal onclose={() => setPermissionPopUp(false)} openModal={permissionPopUp} />
    </div>
  );
};

export default CalendarWeek;
