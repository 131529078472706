import React, { useEffect, useState } from "react";
import Avatar from "react-avatar";

import { LeftSideHeader } from "./Chat.styled";
import SearchInput from "../../../components/SearchInput";
import HeadingComponent from "../../../components/Heading";
import { ChannelSettingsModal } from "./Modals";
import { ReactComponent as PlusSIgn } from "../../../assets/rawSvgs/plusSign.svg";
import { ReactComponent as PrivateChannelIcon } from "../../../assets/rawSvgs/ResetLock.svg";

import { Divider, Tooltip } from "antd";
import truncateString from "../../../util/truncate";

//

export const ChatLeftSide = ({
  selectChat,
  channelsList,
  pubnubUuid,
  onlyCompId,
  channelsDetails,
  onCompleteSuccess,
  ListOfUsers,
  isAdminOrUser,
  adminId,
  channelType,
  typeVisibility,
}) => {
  const [addNewChannelModal, setAddNewChannelModal] = useState(false);

  let settingModalProp = {
    settingsModal: addNewChannelModal,
    closeModal: () => {
      setAddNewChannelModal(false);
    },
    pubnubUuid,
    onlyCompId,
    channelsDetails,
    onCompleteSuccess,
    ListOfUsers,
    isAdminOrUser,
  };

  let filterChannelVisibility = channelsList?.filter((items) => items.custom?.group_visibility === typeVisibility);
  return (
    <div className="">
      <div className="d-flex justify-content-between ">
        <HeadingComponent text={channelType} fontSize="0.830rem" fontWeight={700} color="#616161" />

        <Tooltip title="Create new channel">
          <PlusSIgn
            className="m cursorPointer"
            onClick={() => {
              setAddNewChannelModal(true);
            }}
          />
        </Tooltip>
      </div>
      <div className="">
        {filterChannelVisibility?.map((items, index) => {
          return (
            <div onClick={() => selectChat(items)} key={index}>
              <ChannelComponent
                channelName={items.name}
                isPrivate={items.custom.group_visibility === "private" ? true : false}
                restItems={{ ...items }}
                adminId={adminId}
              />
            </div>
          );
        })}
      </div>

      {/* MODALS */}
      {addNewChannelModal && <ChannelSettingsModal {...settingModalProp} />}
    </div>
  );
};

export const ChannelComponent = ({ channelName, isPrivate, restItems, adminId }) => {
  const customData = restItems?.custom || {};
  let unreadCountData = customData?.unreadCountData ? JSON.parse(customData.unreadCountData) : {};

  let countRead = unreadCountData?.recievers?.[adminId]?.unreadCount || 0;

  return (
    <div className="d-flex align-items-center justify-content-between my-3 cursorPointer">
      <div className="d-flex">
        <HeadingComponent text={"#"} fontSize="1rem" fontWeight={600} color="#616161" />
        <HeadingComponent text={truncateString(channelName, 20)} fontSize="14px" fontWeight={400} color margin={"0px 5px"} />
      </div>
      <div className="d-flex">
        <div className="me-2">{countRead > 0 && <UnreadCounter countRead={countRead} />}</div>
        <Tooltip title="Private Channel">
          <div>{isPrivate && <PrivateChannelIcon />}</div>
        </Tooltip>
      </div>
    </div>
  );
};

export const ChatComponent = ({ chatterName, restItems, adminId }) => {
  const customData = restItems.custom || {};
  let unreadCountData = customData.unreadCountData ? JSON.parse(customData.unreadCountData) : {};
  let senderId = unreadCountData.reciever?.senderId;
  let countRead = unreadCountData.reciever?.unreadCount || unreadCountData?.unreadCount;

  return (
    <div className="d-flex align-items-center my-3 cursorPointer justify-content-between">
      <div className="d-flex align-items-center">
        <Avatar name={chatterName?.().slice(0, 2)} round={true} size="25" textSizeRatio={1.5} />
        <HeadingComponent
          //  text={truncateString(chatterName, 20)}
          text={chatterName?.()}
          fontSize="0.830rem"
          fontWeight={400}
          color
          margin={"0px 5px"}
        />
      </div>
      <div>{senderId !== adminId && countRead > 0 && <UnreadCounter countRead={countRead} />}</div>
    </div>
  );
};

export const UnreadCounter = ({ countRead }) => {
  return (
    <div
      style={{
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "rgb(251, 207, 52)",
        color: "black",
        borderRadius: "50%",
        width: "20px",
        height: "20px",
        textAlign: "center",
        fontSize: "12px", // Adjust font size to fit the content within 20px
        lineHeight: "20px", // Center text vertically
      }}
    >
      {countRead}
    </div>
  );
};
