import { Divider, DatePicker, TimePicker, InputNumber, Select, Input } from "antd";
import { Field, Formik, Form as FormikForm } from "formik";
import { useEffect, useState } from "react";
import { CustomModal } from "../../../components/Modals/ModalStyles";
import CustomBtnWithIcon from "../../../components/buttonWithIcon";
import FormikInputFloating from "../../../components/formikInputs/TextInput";
import { addClientSchema } from "../../../constants/CompanyFormFieldsSchema/FormSchema";
import { pushNotification } from "../../../util/Notification";
import { clientCreationFormErrorCodes } from "../../../constants/ResponseStatusCodes";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { loadCompanyDetail } from "../../../redux/platformSlices/CompanyDetail";
import moment from "moment";
import FormikSelectInput from "../../../components/formikInputs/SelectInput";
import LocationSelector from "../../../components/CustomAddressInput/locationSelector";
import HeadingComponent from "../../../components/Heading";
import LocationSelectorFormik from "../../../components/CustomAddressInput/locationSelectorFormik";

const AddNewClientForm = ({
  getManagementClients,
  resetClientResponse,
  visible,
  handleClose,
  clientPostState,
  postManagementClient,
  updateSingleClient,
  openEditClientValues,
  updateClient = false,
  clientEditState,
  companyId,
}) => {
  const details = useSelector((state) => state.companyDetail.companyDetailResponse);
  const groups = details?.result?.client_custom_fields?.groups || [];
  const [routeOptimizorChecked, setRouteOptimizorChecked] = useState(true);
  const [addressLatAndLong, setAddressLatAndLong] = useState([]);

  const dispatch = useDispatch();
  // const [form] = Form.useForm();
  useEffect(() => {
    if (!updateClient) {
      dispatch(loadCompanyDetail(companyId));
    }
  }, [dispatch, companyId, updateClient]);

  useEffect(() => {
    if (clientPostState?.responseData?.status === clientCreationFormErrorCodes.COMPANY_NOT_FOUND_ERROR) {
      pushNotification("Company not found associate with this account", "error");
      resetClientResponse();
    }
    // CLIENT_EDIT_CREATED_SUCCESSFULLY

    if (clientPostState?.responseData?.status === clientCreationFormErrorCodes.CLIENT_CREATED_SUCCESSFULLY) {
      pushNotification("Client created successfully!", "success");
      getManagementClients();
      resetClientResponse();
      handleClose();
    }
    if (clientPostState?.responseData?.response?.data?.error === "User is already a member of this company") {
      pushNotification(clientPostState.responseData?.error, "error");
      resetClientResponse();
    }
    if (clientEditState?.responseData?.status === clientCreationFormErrorCodes.CLIENT_UPDATE_SUCCESSFULLY) {
      pushNotification("Client updated successfully!", "success");
      handleClose();
      getManagementClients();
      resetClientResponse();
    }
  }, [clientPostState, clientEditState]);

  const dynamicInitialValues = () => {
    let tempGroups = updateClient ? openEditClientValues.groups : groups;
    return tempGroups?.reduce((acc, group) => {
      group?.fields?.forEach((field) => {
        const fieldName = field.id;
        acc[fieldName] = field.field_value || "";
      });
      return acc;
    }, {});
  };

  const handleSubmit = (values, actions) => {
    let tempGroups = updateClient ? openEditClientValues.groups : groups;
    const customFields = tempGroups?.flatMap((group) =>
      group.fields.map((field) => ({
        id: field.id,
        value: values[field.id] || "",
      })),
    );
    const submissionData = {
      ...values,
      custom_fields: customFields,
      latitude: addressLatAndLong[0],
      longitude: addressLatAndLong[1],
    };

    if (updateClient) {
      updateSingleClient({ id: openEditClientValues.id, values: submissionData });
      return;
    }
    postManagementClient(submissionData);
  };
  const componentMapping = {
    date: ({ field, form, ...props }) => (
      <>
        <label>{props.label}</label>
        <DatePicker
          name={field.name}
          defaultValue={field.value ? moment(field.value) : null}
          onChange={(date, dateString) => form.setFieldValue(field.name, dateString)}
          placeholder={props.placeholder}
          style={{ width: "100%", height: "40px" }}
        />
      </>
    ),
    time: ({ field, form, ...props }) => {
      return (
        <>
          <label>{props.label}</label>
          <TimePicker
            name={field.name}
            hourStep={1}
            format="hh:mm a"
            defaultValue={field.value ? moment(field.value, "hh:mm a") : null}
            onChange={(time, timeString) => form.setFieldValue(field.name, timeString)}
            placeholder={props.placeholder}
            style={{ width: "100%" }}
          />
        </>
      );
    },
    number: ({ field, form, ...props }) => {
      return (
        <>
          <label>{props.label}</label>
          <InputNumber
            placeholder={props.placeholder}
            defaultValue={field.value}
            onChange={(e) => {
              form.setFieldValue(field.name, e);
            }}
            name={field.name}
            style={{ width: "100%" }}
          />
        </>
      );
    },
    text: ({ field, form, ...props }) => {
      return (
        <>
          <FormikInputFloating
            name={field.name}
            value={field.value}
            label={props.label}
            placeholder={props.placeholder}
            onChange={(e) => {
              form.setFieldValue(field.name, e.target.value);
            }}
          />
        </>
      );
    },
    dropdown: ({ field, form, ...props }) => {
      const options = props.options.map((option) => ({ value: option, label: option }));
      return (
        <>
          <FormikSelectInput
            name={field.name}
            label={props.label}
            onChange={(e) => {
              form.setFieldValue(field.name, e);
            }}
            value={field.value || undefined}
            placeholder={props.placeholder}
            style={{ width: "100%", marginBottom: "10px" }}
            options={options}
          />
        </>
      );
    },
  };
  const renderCustomFields = () => {
    let tempGroups = updateClient ? openEditClientValues.groups : groups;

    return tempGroups?.map((group, groupIndex) => (
      <div key={groupIndex}>
        {group.fields.map((field) => {
          const commonProps = {
            label: field.field_name,
            name: field.id,
            options: field.options || [],
            placeholder: field.preview_text,
            required: field.properties.required,
            disabled: updateClient && !field.properties.client_editable,
            key: field.id,
          };

          const FieldComponent = componentMapping[field.field_type];

          return (
            <div key={field.id} className=" w-100 mb-2">
              <Field name={field.field_name} {...commonProps} component={FieldComponent} />
            </div>
          );
        })}
      </div>
    ));
  };

  return (
    <CustomModal
      title={updateClient ? "Edit Client Details" : "Add a new Client"}
      open={visible}
      onCancel={handleClose}
      maskClosable={false}
      footer={null}
      isScrollable
    >
      <Divider />
      <Formik
        initialValues={{
          name: updateClient ? openEditClientValues?.name : "",
          phone_number: updateClient ? openEditClientValues.phone_number : "",
          email: updateClient ? openEditClientValues.email : "",
          address: updateClient ? openEditClientValues.address : "",
          city: updateClient ? openEditClientValues.city : "",
          company: companyId,
          ...dynamicInitialValues(),
        }}
        validationSchema={addClientSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {() => (
          <FormikForm>
            <FormikInputFloating
              label="Email"
              placeHolder="Enter Email"
              name="email"
              type="text"
              className="w-100 mb-2"
              autoComplete="new-password"
              disabled={updateClient}
            />
            <FormikInputFloating
              label="Full Name"
              placeHolder="Enter Full Name"
              name="name"
              type="text"
              className="w-100 mb-2"
              autoComplete="new-password"
            />
            <FormikInputFloating
              label="Phone Number"
              name="phone_number"
              placeHolder="Enter Phone Number"
              type="text"
              className="w-100 mb-2"
              autoComplete="new-password"
            />
            {/* <FormikInputFloating
              label="Address"
              placeHolder="Enter Address"
              name="address"
              type="text"
              className="w-100 mb-2"
              autoComplete="new-password"
            
            /> */}
            {/* <HeadingComponent text={"Address"} fontSize={"12px"} fontWeight={"400"}/>
            <LocationSelector setAddressLatAndLong={setAddressLatAndLong} form={form} checked={routeOptimizorChecked} /> */}
            <LocationSelectorFormik setAddressLatAndLong={setAddressLatAndLong} checked={routeOptimizorChecked} />

            <FormikInputFloating
              label="City"
              placeHolder="Enter City"
              name="city"
              type="text"
              className="w-100 mb-2"
              autoComplete="new-password"
            />
            {renderCustomFields()}
            <div className="d-flex justify-content-end gap-2">
              <CustomBtnWithIcon btnText={"Cancel"} noBackground hideIcon={true} onClick={handleClose} />
              <CustomBtnWithIcon
                btnText={"Save Changes"}
                type="submit"
                hideIcon={true}
                disabled={clientPostState?.isLoading || clientEditState?.isLoading}
              />
            </div>
          </FormikForm>
        )}
      </Formik>
    </CustomModal>
  );
};

export default AddNewClientForm;
