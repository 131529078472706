import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { ReactComponent as CompanyBrandIcon } from "../../assets/companySvgIcons/frame.svg";
const useTheme = () => {
  let pathName = useLocation().pathname;
  const themeSettings = useSelector((state) => state.theme.themeSettings);
  const isClientPortal = pathName.includes('/client');
  const isPlatformPortal = pathName.includes('/platform');

  const commonThemeStructure = {
    logo: '',
    headerBackground: '',
    headerText: '',
    headerSecondary: '',
    footerBackground: '',
    footerText: '',
    headerSeparator: '',
    headerTitle: '',
    emailBackground: '',
    emailExcerptBackground: '',
    emailExcerptSeparator: '',
    emailFooterLink: '',
    emailHeader: '',
    emailHeaderText: '',
    emailLogo: ''
  };

  const dashboardTheme = {
    logo: themeSettings?.company_logo || '',
    headerBackground: themeSettings?.dashboard_header || '#ffffff',
    headerText: themeSettings?.dashboard_header_font || '#000000',
    headerSeparator: themeSettings?.dashboard_header_separator || '#dddddd',
    headerTitle: themeSettings?.dashboard_header_title || '#333333',
  };
  
  const clientPortalTheme = {
    logo: themeSettings?.company_logo || '',
    headerBackground: themeSettings?.clientportal_header_background || '#f8f8f8',
    headerText: themeSettings?.clientportal_header_text || '#000000',
    headerSecondary: themeSettings?.clientportal_header_secondary || '#666666',
    footerBackground: themeSettings?.clientportal_footer_background || '#f1f1f1',
    footerText: themeSettings?.clientportal_footer_text || '#999999',
  };
  
  const emailTheme = {
    emailBackground: themeSettings?.email_background || '#ffffff',
    emailExcerptBackground: themeSettings?.email_excerpt_background || '#f9f9f9',
    emailExcerptSeparator: themeSettings?.email_excerpt_separator || '#e0e0e0',
    emailFooterLink: themeSettings?.email_footer_link || '#0073e6',
    emailHeader: themeSettings?.email_header || '#ffffff',
    emailHeaderText: themeSettings?.email_header_text || '#333333',
    emailLogo: themeSettings?.email_logo || '',
  };
  

  const theme = {
    ...commonThemeStructure,
    ...(isClientPortal ? clientPortalTheme : dashboardTheme),
    ...emailTheme
  };

  if (isPlatformPortal) {
    return { ...commonThemeStructure, ...emailTheme };
  }
  
  return theme;
};

export default useTheme;