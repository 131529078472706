import React, { useState } from "react";
import { CustomModal } from "../ModalStyles";
import { Checkbox, DatePicker, Divider, Form, Input, Radio, Select, Space, Modal } from "antd";
import { CustomRadio, FormItem } from "../../../pages/company/tasks/tasksManagementStyles";
import CustomBtnWithIcon from "../../buttonWithIcon";
import { AssigneeType } from "../../../constants/TasksManagement";
import { createTask } from "../../../util/DataService";
import { useSelector } from "react-redux";
import { pushNotification } from "../../../util/Notification";
import dayjs from "dayjs";

const CreateTaskModal = ({
  isLoading,
  isModalOpen,
  setModalOpen,
  staffList,
  clientList,
  generalList,
  loadWorkQueue,
  tasksList,
  loadTasksList,
  assigneeTypeList = [],
}) => {
  const auth = useSelector((state) => state.auth);
  const company_id = auth.loginResponse?.selectCompany?.[0].company_id;
  const [form] = Form.useForm();
  const [assigneeList, setAssigneeList] = useState();

  const [addClicked, setAddClicked] = useState(false);

  const handleTypeChange = (value) => {
    form.resetFields(["assignee"]);
    switch (value) {
      case "staff":
        setAssigneeList(staffList);
        break;
      case "client":
        setAssigneeList(clientList);
        break;
      case "general":
        setAssigneeList(generalList);
        break;
      default:
        setAssigneeList("");
        break;
    }
  };

  const createNewTask = (formValues) => {
    const formattedDate = formValues?.due_date.format("YYYY-MM-DD");
    const updatedValues = {
      company: company_id,
      assign_to_manager: formValues.assignee,
      assign_to_client: formValues.assignee,
      assign_to_staff: formValues.assignee,
      ...formValues,
      due_date: formattedDate,
    };
    createTask(updatedValues)
      .then(({ data }) => {
        if (data.isSuccess) {
          pushNotification(data.message, "success");
          setModalOpen(false);
          form.resetFields();
          loadTasksList();
          setAddClicked(false);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const onFinish = (formValues) => {
    setAddClicked(true);
    if (formValues.due_date.isBefore(dayjs(), "day")) {
      Modal.confirm({
        title: "Due date has passed.",
        content: "Are you sure you want to continue?",
        onOk: () => {
          createNewTask(formValues);
        },
        onCancel: () => {
          setAddClicked(false);
        },
        okButtonProps: { style: { backgroundColor: "#fbcf34" } },
        cancelButtonProps: {
          style: {
            backgroundColor: "white",
            borderColor: "#e0e0e0",
            color: "black",
          },
        },
      });
    } else {
      createNewTask(formValues);
    }
  };

  return (
    <CustomModal
      open={isModalOpen}
      title="Create New Task"
      width="600px"
      heigth="444px"
      onCancel={() => {
        setModalOpen(false);
        form.resetFields();
      }}
      footer={null}
      maskClosable={false}
    >
      <Divider />
      <Form name="createTask" onFinish={onFinish} form={form} layout="vertical">
        <FormItem
          name="name"
          label="Title"
          rules={[
            {
              required: true,
              message: "Title is required",
            },
          ]}
        >
          <Input placeholder="Enter Title" />
        </FormItem>
        <FormItem
          name="due_date"
          label="Due Date"
          rules={[
            {
              required: true,
              message: "Due Date is required",
            },
          ]}
        >
          <DatePicker style={{ width: "100%" }} type="date" placeholder="Select Due Date" format={"YYYY-MM-DD"} />
        </FormItem>
        <FormItem name="type" label="Type">
          <Select
            placeholder="Select Assignee Type"
            onChange={handleTypeChange}
            options={assigneeTypeList.length ? assigneeTypeList : AssigneeType}
          />
        </FormItem>
        <FormItem
          name="assignee"
          label="Assigned To"
          rules={[
            {
              required: false,
              message: "Assignee is required",
            },
          ]}
        >
          <Select placeholder="Select Assignee" options={assigneeList} allowClear />
        </FormItem>
        <FormItem
          name="priority"
          label="Priority"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Radio.Group className="d-flex gap-2">
            <CustomRadio className="rounded-5 bg-light" value={"low"}>
              <i class="pi pi-angle-double-down"></i>Low
            </CustomRadio>
            <CustomRadio className="rounded-5 bg-light" value={"high"}>
              <i class="pi pi-angle-double-up"></i>high
            </CustomRadio>
          </Radio.Group>
        </FormItem>
        <FormItem
          name="description"
          label="Description"
          rules={[
            {
              required: true,
              message: "Description is required",
            },
          ]}
        >
          <Input.TextArea placeholder="Type description here..." />
        </FormItem>
        <Divider />
        <div className="d-flex justify-content-end">
          <CustomBtnWithIcon
            btnText={"Cancel"}
            margin="0px 5px"
            noBackground
            hideIcon={true}
            onClick={() => {
              setModalOpen(false);
              form.resetFields();
            }}
          />
          <CustomBtnWithIcon btnText={"Save Task"} type="submit" hideIcon={true} disabled={addClicked} />
        </div>
      </Form>
    </CustomModal>
  );
};

export default CreateTaskModal;
