import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import ProtectedRoute from "./components/RoutesWrapper/ProtectedRoute";
import PublicOnlyRoute from "./components/RoutesWrapper/PublicOnlyRoute";
import Clients from "./containers/clientManagement/ClientManagement";
import KeyManagement from "./containers/keyManagement/KeyManagement";
import KeyList from "./pages/company/clients/KeyList";
import PetList from "./pages/company/clients/PetList";
import VetList from "./pages/company/clients/VetList";
import Home from "./pages/company/Home";
import Logout from "./pages/company/Logout";
import Onboarding from "./pages/company/Onboarding";

import Signin from "./containers/auth/SiginContainer";
import Signup from "./containers/auth/SignupContainer";

import Invoicing from "./containers/companyInvoicing/invoicing";
import InvoicingDetails from "./containers/companyInvoicing/invoiceDetails";
import Services from "./containers/services/service";
import AddService from "./pages/company/services/AddService";
import Packages from "./pages/company/services/packages";
import AddPackage from "./pages/company/services/packages/AddPackage";
import NoPage from "./pages/NoPage";
import Terms from "./pages/Terms";
import "bootstrap/dist/css/bootstrap.min.css";
import ClientsDetail from "./containers/clientManagement/ClientDetail";
import StaffDetail from "./containers/staffManagement/StaffDetail";
import Create from "./pages/company/clients/create";
import Staff from "./containers/staffManagement/index";
import UserManagement from "./containers/userManagement/index";
import ChatPage from "./containers/internalChatApp/index";
import ClientChatPage from "./containers/clientChat/index";
import Settings from "./containers/settingsContainer/index";
import Payment from "./containers/paymentContainer/index";
import ReportDetail from "./pages/company/reports/ReportDetail";
import Scheduler from "./containers/scheduler/scheduler";
import ClientScheduler from "./containers/scheduler/clientScheduler";
import ForgetPassword from "./pages/company/auth/ForgetPassword";
import ResetPassword from "./pages/company/auth/ResetPassword";
import ResetPasswordPlatform from "./pages/platform/auth/ResetPassword";
import AnimateAuth from "./containers/auth/SiginContainer";
import PetManagement from "./containers/petsManagement/PetList";
import AnimateAuthAdmin from "./containers/platformContainers/SiginContainer";
import CompanyManagement from "./containers/companyManagement/companyList";
import PlatformPayment from "./pages/platform/payment/Payment";
import PublicOnlyRoutePlatform from "./components/RoutesWrapper/PublicOnlyRoutePlatform";
import ProtectedRoutePlatform from "./components/RoutesWrapper/ProtectedRoutePlatform";
import OnBoarding from "./pages/client/onboarding/OnBoarding";
import Dashboard from "./containers/platformContainers/UsersContainer";
import CompanyOnboarding from "./pages/company/companyOnboarding";
import ClientVerification from "./pages/client/ClientVerification";
import ProtectedRouteClient from "./components/RoutesWrapper/ProtectedRouteClient";
import ClientSettings from "./containers/ClientSettings";
import ClientInvoicing from "./containers/clientInvoice/invoicing";
import ClientSingleDetail from "./containers/clientInvoice/invoiceDetails";
import TasksManagement from "./containers/tasksManagement/tasksManagement";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchSignRoles } from "./util/DataService";
import { fetchSingleRole } from "./redux/slices/permissionSlice";
import { checkPermission } from "./util/commonUtil";
import OneSignal from "react-onesignal";
import { fetchUnreadNotificationCount } from "./redux/slices/notificationSlice";
import { setNotification } from "./redux/slices/notificationSlice";
import { fetchThemeSettings } from "./redux/slices/themeSlice";
import Documents from "./pages/client/documents/documents";
import PlateformSettings from "./pages/platform/settings/plateformSettings";
import ReportListing from "./pages/company/reports/ReportListing";
import Chart from "chart.js/auto";

import { CategoryScale } from "chart.js";

import RouteOptimizer from "./pages/company/routeOptimization";

import usePubNubServiceHook from "./hooks/usePubnubChannelCount";
import usePubNubServiceHookClient from "./hooks/usePubnubChannelCountClients";
import { userHasValidToken } from "./api/auth";

Chart.register(CategoryScale);
let loggedAs = localStorage.getItem("loggedAs");
const OnboardComp = () => {
  return <CompanyOnboarding />;
};

function CompanyRedirect(portalLogged) {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Check for /company path

    const pathname = location?.pathname;

    if (pathname?.includes("/company")) {
      if (loggedAs === "platform_dashboard") {
        navigate("/platform/company-management");
      } else if (loggedAs === "client_dashboard") {
        navigate("/client/scheduler");
      } else if (loggedAs === "company_dashboard") {
        navigate("/company/scheduler");
      }
    }

    // Check for /platform path
    if (pathname?.includes("/platform")) {
      if (loggedAs === "client_dashboard") {
        navigate("/client/scheduler");
      } else if (loggedAs === "company_dashboard") {
        navigate("/company/scheduler");
      } else if (loggedAs === "platform_dashboard") {
        navigate("/platform/company-management");
      }
    }

    // Check for /client path
    if (pathname?.includes("/client")) {
      if (loggedAs === "company_dashboard") {
        navigate("/company/scheduler");
      } else if (loggedAs === "platform_dashboard") {
        navigate("/platform/company-management");
      } else if (loggedAs === "client_dashboard") {
        navigate("/client/scheduler");
      }
    }
  }, [location, navigate, loggedAs]);

  return null;
}
// ============
//  MAIN APP COMPONENT
// ============
const App = () => {
  let oneSingalInitialized = false;
  const hasValidToken = userHasValidToken();
  const { auth, permissionManagement } = useSelector((state) => state);
  const dispatch = useDispatch();

  let usePubnubH = usePubNubServiceHook({ loggedInType: auth?.loginResponse, authState: auth.loginResponse?.LoggedInUserData });
  let usePubNubClientH = usePubNubServiceHookClient({
    loggedInType: auth?.loginResponse,
    authState: auth.loginResponse?.LoggedInUserData,
  });

  useEffect(() => {
    if (auth?.loginResponse?.selectedRoleId) {
      getRolePermissions(auth?.loginResponse?.selectedRoleId);
    }
  }, [auth?.loginResponse?.selectedRoleId]);

  useEffect(() => {
    if (hasValidToken) {
      dispatch(fetchThemeSettings());
    }
  }, [dispatch]);

  const getRolePermissions = (roleId) => {
    fetchSignRoles(roleId).then(({ data }) => {
      dispatch(fetchSingleRole(data));
    });
  };

  const initializeOneSignal = async (id) => {
    if (!oneSingalInitialized && process.env.REACT_APP_ONESIGNAL_APP_ID) {
      oneSingalInitialized = true;
      window.OneSignalDeferred = window.OneSignalDeferred || [];
      window.OneSignalDeferred.push(function (OneSignal) {
        OneSignal.init({
          appId: process.env.REACT_APP_ONESIGNAL_APP_ID,
        });
        OneSignal.Notifications.addEventListener("permissionChange", () => {
          OneSignal.login(id);
        });
        OneSignal.login(id);
      });
    }
  };

  const subscribePusher = (id) => {
    if (window.pusher) {
      var channel = window.pusher.subscribe(id);
      channel.bind("new-notification", function (data) {
        dispatch(setNotification({ unreadCount: data.un_read_count }));
      });
    }
  };

  useEffect(() => {
    if (auth?.loginResponse?.selectedRoleType === "company_type") {
      const companyId = auth?.loginResponse?.selectCompany?.[0]?.company_id;
      const user_id = auth?.loginResponse?.LoggedInUserData?.id;
      initializeOneSignal(`company_admin_id_${user_id}`);
      subscribePusher(`company_admin_id_${user_id}`);
      dispatch(fetchUnreadNotificationCount(`company_admin_id=${user_id}`));
    } else if (auth?.loginResponse?.selectedRoleType === "client_type") {
      const client_id = auth.loginResponse?.selectCompany?.filter((item) => item.client_id !== null)[0].client_id;
      initializeOneSignal(`client_id_${client_id}`);
      subscribePusher(`client_id_${client_id}`);
      dispatch(fetchUnreadNotificationCount(`client_id=${client_id}`));
    } else if (auth?.loginResponse?.selectedRoleType === "company_user_type") {
      const userId = auth?.loginResponse?.companyUserId;
      if (userId) {
        initializeOneSignal(`company_user_id_${userId}`);
        subscribePusher(`company_user_id_${userId}`);
        dispatch(fetchUnreadNotificationCount(`company_user_id=${userId}`));
      }
    }
  }, [auth?.loginResponse?.selectedRoleType]);

  useEffect(() => {
    if (!loggedAs || loggedAs === "") return;
    const pathname = window?.location?.pathname || "";

    if (pathname.includes("/company")) {
      if (loggedAs === "platform_dashboard" && pathname !== "/platform/company-management") {
        window.location.replace("/platform/company-management");
      } else if (loggedAs === "client_dashboard" && pathname !== "/client/scheduler") {
        window.location.replace("/client/scheduler");
      } else if (loggedAs === "company_dashboard" && (pathname === "/company" || pathname === "/company/")) {
        window.location.replace("/company/scheduler");
      }
    }

    if (pathname.includes("/platform")) {
      if (loggedAs === "client_dashboard" && pathname !== "/client/scheduler") {
        window.location.replace("/client/scheduler");
      } else if (loggedAs === "company_dashboard" && pathname !== "/company/scheduler") {
        window.location.replace("/company/scheduler");
      } else if (loggedAs === "platform_dashboard" && (pathname === "/platform" || pathname === "/platform/")) {
        window.location.replace("/platform/company-management");
      }
    }

    if (pathname.includes("/client")) {
      if (loggedAs === "company_dashboard" && pathname !== "/company/scheduler") {
        window.location.replace("/company/scheduler");
      } else if (loggedAs === "platform_dashboard" && pathname !== "/platform/company-management") {
        window.location.replace("/platform/company-management");
      } else if (loggedAs === "client_dashboard" && (pathname === "/client" || pathname === "/client/")) {
        window.location.replace("/client/scheduler");
      }
    }
  }, [loggedAs, auth?.loginResponse?.selectedRoleType]);

  return (
    <Router>
      <Routes>
        <Route index element={<NoPage loggedAs={loggedAs} auth={auth} />} />

        {/* CLIENT ROUTES */}
        <Route path="client">
          <Route path="onboarding">
            <Route index element={<ProtectedRouteClient Page={OnBoarding} />} />
          </Route>

          <Route index element={<ProtectedRouteClient Page={CompanyRedirect} />} />
          <Route path="verification">
            <Route index element={<PublicOnlyRoute Page={ClientVerification} />} />
          </Route>
          {/* {checkPermission(permissionManagement?.permissions, "view_scheduler") && ( */}
          <Route path="scheduler">
            <Route
              index
              element={
                <ProtectedRouteClient
                  Page={checkPermission(permissionManagement?.permissions, "view_scheduler") && ClientScheduler}
                />
              }
            />
          </Route>
          {/* )} */}

          <Route
            index
            element={
              <ProtectedRoute Page={checkPermission(permissionManagement?.permissions, "view_scheduler") && ClientScheduler} />
            }
          />
          <Route path="settings">
            <Route index element={<ProtectedRouteClient Page={ClientSettings} />} />
          </Route>
          <Route path="invoice">
            <Route index element={<ProtectedRouteClient Page={ClientInvoicing} />} />
          </Route>
          <Route path="invoice/invoice-detail/:invoiceId">
            <Route index element={<ProtectedRouteClient Page={ClientSingleDetail} />} />
          </Route>
          <Route path="documents">
            <Route index element={<ProtectedRouteClient Page={Documents} />} />
          </Route>
          <Route path="message">
            <Route index element={<ProtectedRouteClient Page={ClientChatPage} />} />
          </Route>
          {/* <Route path="pets">
            <Route path="pet-management" element={<ProtectedRouteClient fromClient={true} Page={PetManagement} />} />
          </Route> */}
          <Route index element={<ProtectedRouteClient Page={Home} />} />
        </Route>

        {/* CLIENT ROUTES END */}

        {/* COMPANY ROUTES */}
        <Route path="company">
          <Route path="signin">
            <Route index element={<PublicOnlyRoute Page={AnimateAuth} />} />
          </Route>

          <Route index element={<ProtectedRoute Page={CompanyRedirect} />} />
          <Route index element={<ProtectedRoute Page={Home} />} />

          <Route path="clients">
            <Route index element={<ProtectedRoute Page={Clients} />} />
            <Route path="new-client" element={<ProtectedRoute Page={Create} />} />
            <Route path="client-details/:clientId" element={<ProtectedRoute Page={ClientsDetail} />} />
            <Route path="pet-list" element={<ProtectedRoute Page={PetList} />} />
            <Route path="vet-list" element={<ProtectedRoute Page={VetList} />} />
            <Route path="key-list" element={<ProtectedRoute Page={KeyList} />} />
          </Route>

          <Route path="pets">
            <Route path="pet-management" element={<ProtectedRoute Page={PetManagement} />} />
          </Route>
          <Route path="keys">
            <Route path="key-management" element={<ProtectedRoute Page={KeyManagement} />} />
          </Route>
          <Route path="invoicing" element={<ProtectedRoute Page={Invoicing} />} />
          <Route path="invoicing/invoice-detail/:invoiceId" element={<ProtectedRoute Page={InvoicingDetails} />} />
          <Route path="services">
            <Route index element={<ProtectedRoute Page={Services} />} />
            <Route path="add-service">
              <Route index element={<ProtectedRoute Page={AddService} />} />
              <Route path=":id" element={<ProtectedRoute Page={AddService} />} />
            </Route>
            <Route path="packages">
              <Route index element={<ProtectedRoute Page={Packages} />} />

              <Route path="add-package">
                <Route index element={<ProtectedRoute Page={AddPackage} />} />
                <Route path=":id" element={<ProtectedRoute Page={AddPackage} />} />
              </Route>
            </Route>
          </Route>

          {/* {checkPermission(permissionManagement?.permissions, "view_scheduler") && (
            <Route path="scheduler">
              <Route index element={<ProtectedRoute Page={checkPermission(permissionManagement?.permissions, "view_scheduler") && Scheduler} />} />
              <Route path=":tab" element={<ProtectedRoute Page={Scheduler} />} />
            </Route>
          )} */}

          <Route path="scheduler">
            <Route
              index
              element={
                <ProtectedRoute Page={checkPermission(permissionManagement?.permissions, "view_scheduler") && Scheduler} />
              }
            />
            <Route
              path=":tab"
              element={
                <ProtectedRoute Page={checkPermission(permissionManagement?.permissions, "view_scheduler") && Scheduler} />
              }
            />
          </Route>

          <Route path="staff">
            <Route index element={<ProtectedRoute Page={Staff} />} />
            <Route path="staff-details/:staffId" element={<ProtectedRoute Page={StaffDetail} />} />
            {/* <Route path=":id" element={<ProtectedRoute Page={Index} />} /> */}
          </Route>

          <Route path="user-management">
            <Route index element={<ProtectedRoute Page={UserManagement} />} />
          </Route>

          <Route path="message">
            <Route index element={<ProtectedRoute Page={ChatPage} />} />
          </Route>

          <Route path="settings">
            <Route index element={<ProtectedRoute Page={Settings} />} />
          </Route>
          <Route path="route-optimization">
            <Route index element={<ProtectedRoute Page={RouteOptimizer} />} />
          </Route>
          {checkPermission(permissionManagement?.permissions, "view_payment") && (
            <Route path="payment">
              <Route index element={<ProtectedRoute Page={Payment} />} />
            </Route>
          )}
          <Route path="onboarding">
            <Route index element={<ProtectedRoute Page={OnboardComp} />} />
          </Route>
          <Route path="tasks">
            <Route index element={<ProtectedRoute Page={TasksManagement} />} />
          </Route>
          <Route path="reports">
            <Route index element={<ProtectedRoute Page={ReportListing} />} />
          </Route>
        </Route>

        {/* COMPANY ROUTES END */}

        {/* PLATFORM ROUTES */}
        <Route path="platform">
          <Route path="signin">
            <Route index element={<PublicOnlyRoutePlatform Page={AnimateAuthAdmin} />} />
          </Route>
          <Route index element={<ProtectedRoutePlatform Page={CompanyRedirect} />} />

          <Route index element={<ProtectedRoutePlatform Page={Home} />} />
          <Route path="reset-password">
            <Route index element={<PublicOnlyRoutePlatform Page={ResetPasswordPlatform} />} />
          </Route>
          <Route path="settings">
            <Route index element={<ProtectedRoutePlatform Page={PlateformSettings} />} />
          </Route>
          <Route path="dashboard" element={<ProtectedRoutePlatform Page={Dashboard} />} />
          <Route path="company-management" element={<ProtectedRoutePlatform Page={CompanyManagement} />} />
          <Route path="payment" element={<ProtectedRoutePlatform Page={PlatformPayment} />} />
        </Route>
        {/* PLATFORM ROUTES END */}
        <Route path="reset-password" element={<PublicOnlyRoute Page={ResetPassword} />} />
        <Route path="logout" element={<ProtectedRoute Page={Logout} />} />
        <Route path="terms-and-conditions" element={<Terms />} />

        <Route path="*" element={<NoPage loggedAs={loggedAs} auth={auth} />} />
      </Routes>
    </Router>
  );
};

export default App;
