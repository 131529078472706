import React, { useEffect, useState } from "react";
import { NavigationHeading } from "../../company/clients/clientStyles";
import styled from "styled-components";
import CommonUtil, { checkPermission, logOut } from "../../../util/commonUtil";
import AccountSettingsScreen from "./AccountSettingsScreen";
import PasswordSecurityScreen from "./PasswordSecurityScreen";
import NotificationScreen from "./NotificationScreen";
import PaymentMethodsScreen from "./ClientPaymentMethodsScreen";
import DeleteConfirmationRowModal from "../../../components/Modals/DeleteConfirmationRowModal";
import { pushNotification } from "../../../util/Notification";
import { updateClientInfo } from "../../../util/DataService";
import { useSelector } from "react-redux";
import PermissionModal from "../../../components/Modals/PermissionModal";
import { useNavigate } from "react-router-dom";

const Settings = (props) => {
  const [activeTab, setActiveTab] = useState("account_settings");
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [permissionPopUp, setPermissionPopUp] = useState(false);
  const permissionManagement = useSelector((state) => state.permissionManagement);
  let navigate = useNavigate();
  useEffect(() => {
    props.fetchCompDetails();
    props.fetchCompStripeDetails();
  }, []);

  const deleteAccount = () => {
    const clientInfo = props.clientSingleDetails?.responseData;
    updateClientInfo(clientInfo?.id, { is_deleted: true })
      .then(({ data }) => {
        pushNotification(data.message, "success");
        logOut();
        navigate("/company/signin");
      })
      .catch((error) => {
        pushNotification("Something Went Wrong!", "error");
      });
  };

  const deletePermission = () => {
    if (
      checkPermission(permissionManagement.permissions, "edit_client") &&
      checkPermission(permissionManagement.permissions, "delete_client")
    ) {
      setIsDeleteOpen(true);
    } else {
      setPermissionPopUp(true);
    }
  };

  return (
    <>
      <StickyBar>
        <NavigationHeading>Settings</NavigationHeading>
        <ActionButtons>
          <TabContainer>
            <TabButton onClick={() => setActiveTab("account_settings")} active={activeTab === "account_settings"}>
              Account Settings
            </TabButton>
            <TabButton
              onClick={() => setActiveTab("password_security")}
              active={activeTab === "password_security"}
              marginLeft="30px"
            >
              Password & Security
            </TabButton>
            <TabButton onClick={() => setActiveTab("notification")} active={activeTab === "notification"} marginLeft="30px">
              Notification
            </TabButton>
            <TabButton onClick={() => setActiveTab("payment_methods")} active={activeTab === "payment_methods"} marginLeft="30px">
              Payment Methods
            </TabButton>
          </TabContainer>
        </ActionButtons>

        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "baseline" }}>
          {activeTab !== "password_security" && activeTab !== "payment_methods" && (
            <NavigationHeading>{CommonUtil.convertToTitleCaseWithSpaces(activeTab)}</NavigationHeading>
          )}
          {activeTab === "account_settings" && (
            <DeleteButton
              onClick={() => {
                deletePermission();
              }}
            >
              Delete My Account
            </DeleteButton>
          )}
        </div>
      </StickyBar>
      <div
        style={
          activeTab === "password_security"
            ? { marginTop: "141px", marginBottom: "70px" }
            : activeTab === "payment_methods"
              ? { marginTop: "150px", marginBottom: "70px" }
              : { marginTop: "195px", marginBottom: "70px" }
        }
      >
        {activeTab === "account_settings" && <AccountSettingsScreen props={props} />}
        {activeTab === "password_security" && <PasswordSecurityScreen />}
        {activeTab === "notification" && <NotificationScreen />}
        {activeTab === "payment_methods" && <PaymentMethodsScreen />}
      </div>
      <DeleteConfirmationRowModal
        onclose={() => setIsDeleteOpen(false)}
        handleConfirmDeleteRows={deleteAccount}
        openConfimrationDeleteModal={isDeleteOpen}
      />
      <PermissionModal onclose={() => setPermissionPopUp(false)} openModal={permissionPopUp} />
    </>
  );
};

const ActionButtons = styled.div`
  display: flex;
  margin-bottom: 7px;
  border-bottom: 1px solid rgb(228, 234, 240);
`;

const StickyBar = styled.div`
  position: fixed;
  background-color: white;
  z-index: 1;
  width: 92vw;
  margin-top: -17px;
`;

const TabContainer = styled.div`
  padding: 16px 16px 0px 16px;
  height: 54px;
  box-sizing: border-box;
  border-radius: 6px;
  display: flex;
`;
const TabButton = styled.button`
  background-color: transparent;
  padding: 9px 8px 29px 8px;
  height: 34px;
  border: none;
  margin-left: ${(props) => props.marginLeft ?? "0"};
  cursor: pointer;
  border-bottom: 2px solid ${(props) => (props.active ? "#FBCF34" : "transparent")};
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: ${(props) => (props.active ? "#000000" : "#757575")};
`;

const DeleteButton = styled.button`
  border: 1px solid #e0e0e0;
  color: #e53939;
  border-radius: 8px;
  padding: 12px 16px 12px 16px;
`;

export default Settings;
