import { Button, Card, Divider, Image, List, Modal, Segmented, Tag } from "antd";
import React, { useState } from "react";
import styled from "styled-components";
import { ReactComponent as Email } from "../assets/rawSvgs/Email.svg";
import { ReactComponent as PhoneNumber } from "../assets/rawSvgs/PhoneNumber.svg";
import { ReactComponent as Address } from "../assets/rawSvgs/Address.svg";
import { ReactComponent as Website } from "../assets/rawSvgs/Website.svg";
import { ReactComponent as State } from "../assets/rawSvgs/State.svg";
import { ReactComponent as Balance } from "../assets/rawSvgs/Balance.svg";
import { ReactComponent as CrossIcon } from "../assets/rawSvgs/crossIcon.svg";
import { ReactComponent as ResetLock } from "../assets/rawSvgs/ResetLock.svg";
import { ReactComponent as ResetModalLock } from "../assets/rawSvgs/ResetModalLock.svg";
import CustomBtnWithIcon from "./buttonWithIcon";
import ResetInfoModal from "./Modals/ResetInfoModal";
import { main_api } from "../api/axios_helper";
import axiosInstance from "../util/AxiosConfig";
import { companyAPIsEndPoints } from "../constants/ApiEndPoints";
import { pushNotification } from "../util/Notification";

const CompanyDetailPage = (props) => {
  const isActive = props.companyDetailResponse?.result?.active;
  const companyAdmins = props.companyDetailResponse?.result?.company_admins;
  const company = props.companyDetailResponse?.result;

  const [alignValue, setAlignValue] = useState("General Info");
  const [isLoading, setIsLoading] = useState(false);
  const [isResetModalOpen, setIsResetModalOpen] = useState(false);

  const companyInfoActive = [
    {
      icon: <Email />,
      title: "Email",
      description: (companyAdmins && companyAdmins[0].email) || "---",
    },
    {
      icon: <PhoneNumber />,
      title: "Phone Number",
      description: company?.primary_phone_number || "---",
    },
    {
      icon: <Address />,
      title: "Address",
      description: company?.address_1 || "---",
    },
    {
      icon: <Website />,
      title: "Website",
      description: company?.website || "---",
    },
    {
      icon: <State />,
      title: "State",
      description: company?.state || "---",
    },
    {
      icon: <Balance />,
      title: "Balance",
      description: "---",
    },
  ];

  const companyInfoInActive = [
    {
      icon: <Email />,
      title: "Email",
      description: (companyAdmins && companyAdmins[0].email) || "---",
    },
  ];

  const forgetPassword = async (email) => {
    setIsLoading(true);
    try {
      const { data } = await axiosInstance.put(companyAPIsEndPoints.FORGOT_PASSWORD, { email });
      if (data.success) {
        pushNotification("Password Recovery Email Sent ", "success");
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);

      if (error) {
        pushNotification("Something went wrong", "error");
      }
    }
  };

  return (
    <DetailContainer isCompanyDetailModalOpen={props.isCompanyDetailModalOpen}>
      <ResetInfoModal
        isResetModalOpen={isResetModalOpen}
        setIsResetModalOpen={() => {
          setIsResetModalOpen(false);
        }}
      />
      <div>
        <CustomButton className="mt-2" onClick={() => props.openCompanyDetailModal(false)}>
          <CrossIcon />
        </CustomButton>
        <div className="d-flex align-items-baseline mt-2 gap-2">
          <h4>{props.companyDetailResponse?.result?.name || "---"}</h4>
          {isActive ? (
            <Tag bordered={false} color="green">
              Active Company
            </Tag>
          ) : (
            <Tag bordered={false}>InActive Company</Tag>
          )}
        </div>
        <Divider />
        <List
          dataSource={isActive ? companyInfoActive : companyInfoInActive}
          renderItem={(item, index) => (
            <List.Item className="justify-content-start">
              <div className="d-flex gap-2 w-50">
                <div>{item.icon}</div>
                <div>{item.title}</div>
              </div>
              <div>{item.description}</div>
            </List.Item>
          )}
        />
        {companyAdmins &&
          companyAdmins.map((admin) => (
            <Card>
              <h6>Company Admin</h6>
              <small className="text-secondary">Admin Name</small>
              <p>{admin.name || "---"}</p>
              <small className="text-secondary">Email</small>
              <p>{admin.email || "---"}</p>
              <ResetButton
                style={{ width: "100%" }}
                onClick={() => {
                  forgetPassword(admin.email);
                  setIsResetModalOpen(true);
                }}
              >
                <ResetLock /> Reset Administrator Password
              </ResetButton>
            </Card>
          ))}
      </div>
    </DetailContainer>
  );
};

const DetailContainer = styled.div`
  display: ${(props) => (props.isCompanyDetailModalOpen ? "flex" : "none")};
  padding: 15px;
  border-left: 1px solid #d0d5dd;
  width: 416px;
  font-weight: 500;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 60px;
  overflow-y: auto;
  min-height: calc(100vh - 60px);
  max-height: 100vh;

  &::-webkit-scrollbar {
    width: 0;
  }
`;

const CustomSegmented = styled(Segmented)`
  .ant-segmented-group {
    gap: 5px;
  }
  .ant-segmented-item {
    width: 50%;
  }
`;

const ResetButton = styled(Button)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  :hover {
    color: inherit !important;
    border-color: inherit !important;
  }
`;

const CustomButton = styled.button`
  position: absolute;
  background: transparent;
  right: 20px;
`;

export default CompanyDetailPage;
