import React from "react";
import styled from "styled-components";


function CustomButton({ Icon, text, onClick, primary, disabled, type }) {
  return (
    <ButtonStyled primary={primary} onClick={onClick} disabled={disabled} type={type}>
      {Icon && <Icon/>}
      <span>{text}</span>
    </ButtonStyled>
  );
}

export default CustomButton;

const ButtonStyled = styled.button`
  padding: 10px 16px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  min-width:fit-content;
  border: 1px solid ${(props) => ( props.disabled ? "#e0e0e0": props.primary ? "#FBCF34" : "#e0e0e0")};
  color: #000000;
  background: ${(props) => ( props.disabled ? "#E0E0E0" :props.primary ? "#FBCF34" : "white")};
  opacity: ${(props) => (props.disabled ? 0.7 : 1)};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
`;
