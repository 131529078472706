import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { main_api } from "../../api/axios_helper";
import { platformAPIsEndPoints } from "../../constants/ApiEndPoints";

const initialState = {
  isLoading: false,
  error: false,
  companyList: [],
  groups: [],
};

const slice = createSlice({
  name: "company",
  initialState,
  reducers: {
    loadCompanyListSlice(state, action) {
      state.isLoading = action.payload.isLoading;
      state.error = action.payload.error;
      state.companyList = action.payload.companyList;
    },
    searchCompanyListSlice(state, action) {
      state.isLoading = action.payload.isLoading;
      state.error = action.payload.error;
      state.companyList = action.payload.companyList;
    },
    addCompanySlice(state, action) {
      state.isLoading = action.payload.isLoading;
      state.error = action.payload.error;
      state.companyList = action.payload.companyList;
    },
    editCompanySlice(state, action) {
      state.isLoading = action.payload.isLoading;
      state.error = action.payload.error;
      state.companyList = action.payload.companyList;
    },
    updateCompanySlice(state, action) {
      state.isLoading = action.payload.isLoading;
      state.error = action.payload.error;
      state.groups = action.payload.groups;
    },

    deleteCompanySlice(state, action) {
      state.isLoading = action.payload.isLoading;
      state.error = action.payload.error;
      state.companyList = action.payload.companyList;
    },
  },
});

export const {
  loadCompanyListSlice,
  addCompanySlice,
  editCompanySlice,
  updateCompanySlice,
  deleteCompanySlice,
  searchCompanyListSlice,
} = slice.actions;

export default slice.reducer;

export const loadCompanyList = () => async (dispatch) => {
  try {
    dispatch(loadCompanyListSlice({ isLoading: true, error: false, companyList: [] }));
    const { data } = await main_api.get(platformAPIsEndPoints.COMPANY_LIST);
    dispatch(loadCompanyListSlice({ isLoading: false, error: false, companyList: data.result }));
  } catch (error) {
    console.log("error", error);
  }
};

export const searchCompanyList = (search, date) => async (dispatch) => {
  try {
    dispatch(searchCompanyListSlice({ isLoading: true, error: false, companyList: [] }));
    const { data } = await main_api.get(platformAPIsEndPoints.COMPANY_LIST_FILTER(search, date));
    dispatch(searchCompanyListSlice({ isLoading: false, error: false, companyList: data.result }));
  } catch (error) {
    console.log("error", error);
  }
};

export const addCompany = (values) => async (dispatch) => {
  try {
    dispatch(addCompanySlice({ isLoading: true, response: "" }));
    const { data } = await main_api.get(platformAPIsEndPoints.POST_COMPANY_LIST_PETS, values);

    dispatch(addCompanySlice({ isLoading: false, error: false, companyList: data }));
  } catch (error) {
    console.log("error", error);
  }
};

export const editCompany = (values) => async (dispatch) => {
  try {
    dispatch(editCompanySlice({ isLoading: true, error: false, companyList: [] }));
    const { data } = await main_api.get(platformAPIsEndPoints.PUT_SINGLE_COMPANY_LIST_PETS(values.id), values);
    dispatch(editCompanySlice({ isLoading: false, error: false, companyList: data }));
  } catch (error) {
    console.log("error", error);
  }
};

export const updateCompany = (id, values) => async (dispatch) => {
  try {
    dispatch(updateCompanySlice({ isLoading: true, error: false, groups: [] }));
    const { data } = await main_api.put(platformAPIsEndPoints.PUT_SINGLE_COMPANY_LIST_PETS(id), values);
    dispatch(updateCompanySlice({ isLoading: false, error: false, groups: data.result.client_custom_fields.groups }));
  } catch (error) {
    console.log("error", error);
  }
};

export const deleteCompany = (values) => async (dispatch) => {
  try {
    dispatch(deleteCompanySlice({ isLoading: true, error: false, companyList: [] }));
    const { data } = await main_api.get(platformAPIsEndPoints.DELETE_SINGLE_COMPANY_LIST_PETS(values.id));

    dispatch(deleteCompanySlice({ isLoading: false, error: false, companyList: data }));
  } catch (error) {
    console.log("error", error);
  }
};
