import React, { useEffect } from "react";
import { Modal } from "antd";
import "./modal.css";

const AntDesignModal = ({ component, openModal, width, title = "", closeModal, footer, ...props }) => {
  useEffect(() => {
    // Disable scrolling when modal is open
    if (openModal) {
      document.body.style.overflow = "auto";
    } else {
      // Re-enable scrolling when modal is closed
      document.body.style.overflow = "auto";
    }

    // Cleanup function to re-enable scrolling when component unmounts or modal is closed
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [openModal]);

  return (
    <div>
      <Modal
        className={"customModal"}
        title={title}
        width={width}
        open={openModal}
        onOk={closeModal}
        onCancel={closeModal}
        footer={footer}
        {...props}
        maskClosable={false}
      >
        {component}
      </Modal>
    </div>
  );
};

export default AntDesignModal;
