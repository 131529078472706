import React, { useEffect, useState } from "react";
import { AntdesignTable } from "../../../components/antDesignTable/AntdesignTable";
import { DatePicker, Select } from "antd";
import SearchInput from "../../../components/SearchInput";
import { NavigationHeading, TableHeader } from "../clients/clientStyles";
import NoDataScreen from "../../../components/NoDataScreen";
import CustomButton from "../../../components/Form/CustomButton";
import { ReactComponent as PlusIcon } from "../../../assets/rawSvgs/plusSign.svg";
import { ReactComponent as ExportIcon } from "../../../assets/rawSvgs/export.svg";
import SelectField from "../../../components/Form/Select";
import Possession from "./data";
import AddNewKeyModal from "../../../components/Modals/KeyManagementModal/AddNewKeyModal";
import ReassignKeyModal from "../../../components/Modals/KeyManagementModal/ReassignKeyModal";
import ViewKeyModal from "../../../components/Modals/KeyManagementModal/ViewKeyModal";
import MultiSelectDropdown from "../../../components/Form/MultiSelect";
import { KeysColumns } from "../../../constants/KeysTableHeaders/KeyTableHeaders";
import EditKeyModal from "../../../components/Modals/KeyManagementModal/EditKeyModal";
import DeleteConfirmationRowModal from "../../../components/Modals/DeleteConfirmationRowModal";
import { companyAPIsEndPoints } from "../../../constants/ApiEndPoints";
import { main_api } from "../../../api/axios_helper";
import { pushNotification } from "../../../util/Notification";
import { useDebounce } from "../../../hooks/useDebounce";
import { checkPermission } from "../../../util/commonUtil";
import PermissionModal from "../../../components/Modals/PermissionModal";
import DuplicateKeysModal from "../../../components/Modals/DuplicateKeysModal/duplicateKeysModal";
import axiosInstance from "../../../util/AxiosConfig";
import { useSelector } from "react-redux";
function KeyManagement({
  keyManagementState,
  getManagementkeys,
  postManagementKey,
  postExistingKey,
  keyPostState,
  keyEditState,
  updateSingleKey,
  clientResponse,
  getManagementClients,
  allStaffList,
  loginState,
  fetchAllStaff,
  permissionState,
}) {
  const { auth } = useSelector((state) => state);
  const [search, setSearch] = useState("");
  const [openKeyModal, setOpenKeyModal] = useState(false);
  const [openReassignKeyModal, setOpenReassignKeyModal] = useState(false);
  const [openViewKeyModal, setOpenViewKeyModal] = useState(false);
  const [openDuplicateKeysModal, setOpenDuplicateKeysModal] = useState(false);
  const [openEditKeyModal, setOpenEditKeyModal] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [clientNameFilter, setClientNameFilter] = useState("");
  const [isLoading, setIsloading] = useState(false);
  const [deleteKeyValues, setDeleteKeyValues] = useState({});
  const [openEditKeyValues, setOpenEditKeyValues] = useState({});

  const [isDeleteKeyOpen, SetIsDeleteKeyOpen] = useState(false);
  const [viewKeyId, setViewKeyId] = useState(null);
  const debouncedSearchKey = useDebounce(search, 300);
  const [permissionPopUp, setPermissionPopUp] = useState(false);
  const [filteredData, setFilteredData] = useState(keyManagementState?.responseData?.result || []);
  const [client, setClient] = useState("");
  const [possession, setPossession] = useState("");
  const [deleteModalTextCondition, setDeleteModalTextCondition] = useState(false);
  const [selectedKeysRows, setselectedKeysRows] = useState([]);
  const [duplicateKeys, setDuplicateKeys] = useState([]);
  const companyId = auth?.loginResponse?.selectCompany?.[0]?.company_id;
 
  // useEffect(() => {
  //   getManagementkeys(debouncedSearchKey);
  // }, [search]);
  useEffect(() => {
    getManagementClients();
    fetchAllStaff();
  }, []);

  useEffect(() => {
    // Define the filtering function
    const filterData = () => {
      // Filter data based on clientNameFilter and selectedItems
      return keyManagementState?.responseData?.result?.filter((item) => {
        // Check if the item matches the name filter (if provided)
        const nameMatches = clientNameFilter ? item.client_name?.includes(clientNameFilter) : true;

        // Check if the item's possessions array includes any of the selected items (if provided)
        const possessionMatches =
          selectedItems.length > 0 ? selectedItems.some((possession) => item?.possession?.includes(possession)) : true;

        // Return true if both conditions match
        return nameMatches && possessionMatches;
      });
    };

    // Apply filters and update filtered data
    setFilteredData(filterData());
  }, [clientNameFilter, selectedItems]);

  useEffect(() => {
    if (checkPermission(permissionState, "view_keys")) {
      let queryParams = "";
      if (client && client != "") {
        queryParams = `client=${client}`;
      }
      if (possession && possession !== "") {
        queryParams += `&possession=${possession}`;
      }
      getManagementkeys(queryParams, debouncedSearchKey);
    }
  }, [permissionState, client, possession, search]);

  const handleChangePossession = (selectedItems) => {
    setSelectedItems(selectedItems);
  };

  const handleDelete = (record) => {
    if (checkPermission(permissionState, "delete_key")) {
      if (record) {
        const { id, counter } = record;
        if (counter > 1) {
          setDeleteModalTextCondition(true);
        } else {
          setDeleteModalTextCondition(false);
        }
        setDeleteKeyValues(id);
      }
      SetIsDeleteKeyOpen(true);
    } else {
      setPermissionPopUp(true);
    }
  };
  const handleHistory = (row) => {
    // setDeleteRowId(row?.id);
    // setOpenConfimrationDeleteModal(true);
  };
  const handleEditRow = (rows) => {
    if (checkPermission(permissionState, "edit_key")) {
      if (rows) {
        const { id, client, client_name, is_master_key, staff, staff_name, name, type, code, possession } = rows;
      
        setOpenEditKeyValues({
          id,
          client,
          client_name,
          is_master_key,
          staff_id: staff,
          staff_name,
          name,
          type,
          code,
          possession,
        });
      }

      setOpenEditKeyModal(true);
    } else {
      setPermissionPopUp(true);
    }
  };
  const handleShowModal = (record) => {
    setOpenViewKeyModal(true);
    setViewKeyId(record?.id);
  };

  // Filter Duplicate Keys Data
  const filterDuplicateRecords =(records, recordId) => {
    return records.filter(item => {
      return item.type === 'physical' && item.id === recordId;
    });
  }

  // Show Duplicate Keys Modal
  const handleShowDuplicateKeysModal = async (record) => {
    if (record.type === "physical") {
      const reqObj = {key: record.id};
      try {
       
      setOpenDuplicateKeysModal(true);
      const duplicates = await axiosInstance.get(companyAPIsEndPoints.FETCH_ALL_COPIES_OF_MASTER_KEY(`key=${record.id}&companyId=${companyId}`));
        
        setDuplicateKeys(duplicates.data.result);  
      } catch (error) {
        console.error("Error fetching duplicate keys:", error);
      }
    }
  };

  
  

  // delete Key
  const deleteKey = () => {
    setIsloading(true);

    main_api
      .delete(companyAPIsEndPoints.DELETE_SINGLE_KEY(deleteKeyValues))
      .then(({ data }) => {
        setIsloading(false);
        pushNotification(data.message, "success");
        SetIsDeleteKeyOpen(false);
        getManagementkeys();
      })
      .catch((error) => {
        pushNotification("Something went wrong!", "error");
        console.log(error);
      });
  };

  const addNewKey = () => {
    if (checkPermission(permissionState, "create_key")) {
      setOpenKeyModal(true);
    } else {
      setPermissionPopUp(true);
    }
  };

  const assignKey = () => {
    if (checkPermission(permissionState, "assign_key")) {
      setOpenReassignKeyModal(true);
    } else {
      setPermissionPopUp(true);
    }
  };

  return (
    <>
      <div className="d-flex align-items-center justify-content-between">
        <NavigationHeading>Keys Management</NavigationHeading>
        {keyManagementState?.responseData?.result?.length > 0 && checkPermission(permissionState, "view_keys") && (
          <div className="d-flex align-items-center gap-2">
            {selectedKeysRows?.length > 0 && (
              <CustomButton
                text="Bulk Reassign Keys"
                onClick={() => {
                  assignKey();
                }}
              />
            )}
            <CustomButton text="Export key data" Icon={ExportIcon} onClick={() => {}} />
            <CustomButton text="Add new Key" Icon={PlusIcon} onClick={() => addNewKey()} primary />
          </div>
        )}
      </div>
      {checkPermission(permissionState, "view_keys") ? (
        <>
          <TableHeader>
            <div className="d-flex align-items-center gap-2">
              <div style={{ width: "210px" }}>
                <SearchInput
                  search={search}
                  onChange={(value) => setSearch(value)}
                  onBlur={() => {}}
                  placeholder="Type to search.."
                />
              </div>
              <div style={{ width: "142px" }}>
                {/* <SelectField placeholder="Client" size="large" options={clientResponse?.clientList} /> */}
                <Select
                  style={{ height: "40px", minWidth: "142px" }}
                  onChange={(value) => setClient(value)}
                  placeholder="Client"
                  allowClear
                  onClear={() => setClient("")}
                  options={clientResponse?.clientList}
                />
              </div>
              <div style={{ minWidth: "142px" }}>
                <Select
                  style={{ height: "40px", minWidth: "142px" }}
                  onChange={(value) => setPossession(value)}
                  placeholder="Possession"
                  allowClear
                  onClear={() => setPossession("")}
                  options={Possession}
                />
                {/* <MultiSelectDropdown
                  name="Possession"
                  placeholder="Possession"
                  selectedItems={selectedItems}
                  options={Possession}
                  onChange={handleChangePossession}
                  size="small"
                /> */}
              </div>
            </div>
          </TableHeader>
          {keyManagementState?.responseData?.result?.length > 0 ? (
            <AntdesignTable
              columns={KeysColumns({ handleDelete, handleEditRow, handleHistory, handleShowModal, handleShowDuplicateKeysModal })}
              data={keyManagementState?.responseData?.result || []}
              loading={isLoading}
              selectedRows={(rows) => setselectedKeysRows(rows.SelctRows)}
              rowKey="id"
              allowMultieSelectRows={true}
            />
          ) : (
            <NoDataScreen
              heading="You don’t have any Keys in your list. Let’s get started by adding a new Key"
              btnText={"Add new Key"}
              onClick={() => addNewKey()}
            />
          )}
        </>
      ) : (
        <NoDataScreen
          heading="You don’t have any Keys in your list. Let’s get started by adding a new Key"
          btnText={"Add new Key"}
          onClick={() => addNewKey()}
        />
      )}
      <AddNewKeyModal
        openKeyModal={openKeyModal}
        setOpenKeyModal={setOpenKeyModal}
        postManagementKey={postManagementKey}
        keyPostState={keyPostState}
        postExistingKey={postExistingKey}
        clientList={clientResponse?.clientList}
        getManagementkeys={getManagementkeys}
        keyManagementState={keyManagementState}
      />
      <EditKeyModal
        openKeyModal={openEditKeyModal}
        setOpenKeyModal={setOpenEditKeyModal}
        openEditKeyValues={openEditKeyValues}
        updateSingleKey={updateSingleKey}
        keyPostState={keyPostState}
        keyEditState={keyEditState}
        clientList={clientResponse?.clientList}
        getManagementkeys={getManagementkeys}
      />
      
      {openReassignKeyModal && (
        <ReassignKeyModal
          openKeyModal={openReassignKeyModal}
          setOpenKeyModal={setOpenReassignKeyModal}
          keysList={selectedKeysRows}
          allStaffList={allStaffList}
        />
      )}
      <ViewKeyModal
        viewKeyId={viewKeyId}
        loginState={loginState}
        openKeyModal={openViewKeyModal}
        setOpenKeyModal={setOpenViewKeyModal}
        heading="View Digital key Code"
        description="In order to view the key, enter your account password below"
      />
      <DeleteConfirmationRowModal
        onclose={() => SetIsDeleteKeyOpen(false)}
        handleConfirmDeleteRows={deleteKey}
        openConfimrationDeleteModal={isDeleteKeyOpen}
        {...(deleteModalTextCondition
          ? {
              text: "There is more than 1 Key involved, are you sure you want to delete all of them?",
            }
          : {})}
        {...(deleteModalTextCondition
          ? {
              deleteBtnText: "Yes, delete them",
            }
          : {})}
      />
      {openDuplicateKeysModal && (
        <DuplicateKeysModal
          openKeyModal={openDuplicateKeysModal}
          setOpenKeyModal={setOpenDuplicateKeysModal}
          handleEditRow={handleEditRow}
          handleDelete={handleDelete}
          handleHistory={handleHistory}
          duplicateKeys={duplicateKeys}
          isLoading={isLoading}
        />
      )}
      <PermissionModal onclose={() => setPermissionPopUp(false)} openModal={permissionPopUp} />
    </>
  );
}

export default KeyManagement;
