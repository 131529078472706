import ClientDetails from "../../pages/company/clients/clientdetail/index";
import { connect } from "react-redux";

import {
  FetchClientsApi,
  PostClientApi,
  fetchSingleClientApi,
  resetResponseState,
  updateSingleClientsApi,
} from "../../redux/slices/ClientSlice";
import { loadVetList, searchPetList } from "../../redux/slices/pets";
import { FetchKeysApi } from "../../redux/slices/KeySlice";
import { fetchAllStaffApi } from "../../redux/slices/StaffSlice";
import { fetchSchedulerServiceApi } from "../../redux/slices/schedulerSlice";

const mapStateToProps = (state) => {
  return {
    clientSingleDetailsState: state.clientManangement.clientSingleDetails,
    singleClientresponseData: state.clientManangement.clientSingleDetails.responseData,
    vetList: state.pets.vetList,
    permissionState: state.permissionManagement.permissions,
    keyManagementState: state.keyManagement.KeyResponse,
    clientResponse: state.clientManangement.clientResponse,
    //
    companyInternalChatState: state.CompanyInternalMsgs,
    authState: state.auth.loginResponse.LoggedInUserData,
    loggedInType: state.auth.loginResponse,

    allStaffs: state.staffManagement.staffAllDetails.staffList,
    clientManagementState: state.clientManangement.clientResponse,
    clientEditState: state.clientManangement.clientEditResponse,
    clientPostState: state.clientManangement.clientPostResponse,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchSingleClient: (id) => {
    dispatch(fetchSingleClientApi(id));
  },
  loadVetList: () => {
    dispatch(loadVetList());
  },
  searchPetList: (search, dateStrings) => {
    dispatch(searchPetList(search, dateStrings));
  },
  getManagementkeys: (queryParams = "", search = "") => {
    dispatch(FetchKeysApi(queryParams, search));
  },
  getManagementClients: (data) => {
    dispatch(FetchClientsApi());
  },
  fetchAllStaff: () => {
    dispatch(fetchAllStaffApi());
  },
  fetchAllSchedulerService: (clientQuery = "", statusQuery = "", startDate = "", endDate = "") => {
    dispatch(fetchSchedulerServiceApi(clientQuery, statusQuery, startDate, endDate));
  },
  updateSingleClient: (data) => {
    dispatch(updateSingleClientsApi(data));
  },
  postManagementClient: (data) => {
    dispatch(PostClientApi(data));
  },
  resetClientResponse: () => {
    dispatch(resetResponseState());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ClientDetails);
