import React, { useEffect, useState } from "react"
import { ReactSortable } from "react-sortablejs"
import styled from "styled-components"
import { main_api } from "../../../../api/axios_helper"
import EditIcon from "../../../../assets/icons/EditIcon"
import LockIcon from "../../../../assets/icons/LockIcon"
import SortIcon from "../../../../assets/icons/SortIcon"
import Button from "../../../../components/Button"
import CheckButton from "../../../../components/CheckButton"
import CustomInput from "../../../../components/CustomInput"
import Text from "../../../../components/Text"

export default function Keys() {
  const [isLoading, setIsloading] = useState(false)
  const [activeButton, setActiveButton] = useState("all")
  const [isModalOpen, setModalOpen] = useState(false)

  const [list, setList] = useState([
    {
      group_name: "Basic Info",
      fields: [
        {
          label: "Name",
          editable: true,
          visible: true,
          required: true,
          staff_visible: true,
          customer: true,
          type: "input"
        },
        {
          label: "Birth Date",
          editable: true,
          visible: true,
          required: true,
          staff_visible: true,
          customer: true,
          type: "input"
        }
      ]
    }
  ])

  const GetPets = () => {
    setIsloading(true)
    main_api
      .get("/api/v1/client_pet/")
      .then(({ data }) => {
        setIsloading(false)
      })
      .catch(error => {
        console.log(error?.response?.data)
        setIsloading(false)
      })
  }

  useEffect(() => {
    // GetPets()
  }, [])

  const edit = () => {}

  return (
   <>
   <h1>Keys</h1>
   </>
  )
}

const ListItemContainerStyle = styled.div`
  width: 976px;
  height: 163px;
  background: #ffffff;
  border: 1px solid #8b8698;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 40px;
`
const InputContainer = styled.div`
  width: ${props => (props.width ? `${props.width * 14}px` : "0px")};
  display: flex;
  align-items: center;
`
const Icon = styled.svg`
  width: 25.43px;
  height: 35.07px;
  margin-right: 21px;
`
const EditIconSVG = styled.svg`
  width: 14px;
  height: 14px;
  stroke: #0496ff;
  fill: transparent;
  margin-left: 5px;
  cursor: pointer;
`
const LockIconSVG = styled.svg`
  width: 24px;
  height: 26px;
  stroke: #0496ff;
  fill: transparent;
  margin-left: 5px;
`
const Container = styled.div`
  display: flex;
  align-items: center;
  padding: ${props => (props.padding ? props.padding : "0")};
`
const ListSelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 100px;
`
const ListSelectItem = styled.div`
  display: flex;
`

