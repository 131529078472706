import React, { useState, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
// import "./table.css";
// import "../month.css";

import {
  InvoiceIcon,
  ClientYellow,
  SchedulerServiceIcon,
  SchedulerWhenDateicon,
  SchedulerAvatarClient,
} from "../../../assets/rawSvgs/index";

import { Col, Row } from "antd";
import HeadingComponent from "../../Heading";
import AntDesignModal from "../../Modals/AntDesignModal/AntDesignModal";
import { convertTo12HourFormat } from "../../../util/formatTime";
import CustomBtnWithIcon from "../../buttonWithIcon";

const ManyEventsModal = ({ showAllEventsModal, setShowAllEventsModal, handleEditEventFromMany, setDeleteConfirmModal }) => {
  return (
    <div>
      <AntDesignModal
        openModal={showAllEventsModal.condition}
        style={{ top: 20 }}
        closeModal={() =>
          setShowAllEventsModal({
            ...showAllEventsModal,
            condition: false,
          })
        }
        onCancel={() =>
          setShowAllEventsModal({
            ...showAllEventsModal,
            condition: false,
          })
        }
        onOk={() =>
          setShowAllEventsModal({
            ...showAllEventsModal,
            condition: false,
          })
        }
        width={600}
        title={<HeadingComponent text={`Events`} fontSize="16px" fontWeight={700} color="#212121" />}
        component={
          <div style={{ maxHeight: "60vh", overflowY: "auto", overflowX: "hidden" }}>
            <Row gutter={10}>
              {showAllEventsModal?.data?.map((items, index) => {
                return (
                  <Col lg={24} key={index} className="my-3">
                    <HeadingComponent
                      text={`${convertTo12HourFormat(items?.start_time)} - ${convertTo12HourFormat(items?.end_time)}`}
                      fontSize="14px"
                      fontWeight={600}
                      color="#212121"
                    />
                    <Col lg={15} className="d-flex align-items-end my-2">
                      <InvoiceIcon className="me-3 ms-1" stroke="#FBCF34" />

                      <HeadingComponent text={"Invoice: #1234213"} fontSize="14px" fontWeight={500} color="#424242" />
                    </Col>
                    <Col lg={15} className="d-flex align-items-end my-2">
                      <SchedulerServiceIcon className="me-3" stroke="#FBCF34" />

                      <HeadingComponent
                        text={`Service: ${items?.service?.name}`}
                        fontSize="14px"
                        fontWeight={500}
                        color="#424242"
                      />
                    </Col>
                    <Col lg={15} className="d-flex align-items-end my-2">
                      <SchedulerWhenDateicon className="me-3" stroke="#FBCF34" />

                      <HeadingComponent text={`When: ${items?.date}`} fontSize="14px" fontWeight={500} color="#424242" />
                    </Col>{" "}
                    <Col lg={15} className="d-flex align-items-end my-2">
                      <ClientYellow className="me-3 ms-1" stroke="#FBCF34" />

                      <HeadingComponent
                        text={`Staff: ${items?.staff?.name}`}
                        className="ms-1"
                        fontSize="14px"
                        fontWeight={500}
                        color="#424242"
                      />
                    </Col>
                    <Col lg={15} className="d-flex align-items-end my-2">
                      <SchedulerAvatarClient className="me-3" stroke="#FBCF34" />
                      <HeadingComponent
                        text={`Client: ${items?.client?.name}`}
                        fontSize="14px"
                        fontWeight={500}
                        color="#424242"
                      />
                    </Col>
                    <div className="d-flex justify-content-end">
                      <CustomBtnWithIcon
                        btnText={"Delete"}
                        hideIcon
                        noBackground
                        className="me-2"
                        width="90px"
                        onClick={() => setDeleteConfirmModal(items.id)}
                      />
                      <CustomBtnWithIcon
                        btnText={"Edit"}
                        hideIcon
                        className="me-2"
                        width="90px"
                        onClick={() => {
                          handleEditEventFromMany(items);
                        }}
                      />
                    </div>
                  </Col>
                );
              })}
            </Row>
          </div>
        }
        footer={
          <div className="d-flex justify-content-end">
            <CustomBtnWithIcon
              btnText={"Close"}
              hideIcon
              className="me-4"
              width="90px"
              onClick={() =>
                setShowAllEventsModal({
                  ...showAllEventsModal,
                  condition: false,
                })
              }
            />
          </div>
        }
      />
    </div>
  );
};

export default ManyEventsModal;
