import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { main_api } from "../api/axios_helper";
import CreateIcon from "../assets/icons/CreateIcon";
import IconButton from "../components/IconButton";
import Table from "../components/Table";
import CloseIcon from "../assets/icons/CloseIcon";

function CustomModal({
  isLoading,
  isModalOpen,
  setModalOpen,
  title,
  showHeader = true,
  showFooter = true,
  showCancelBtn = true,
  cancelBtnText = 'Cancel',
  onCancel,
  showOkBtn = true,
  okBtnText = 'Save',
  onOk,
  okBtnColor = '#FBCF34',
  width,
  heigth,
  contentHeight,
  overflow,
  children,
}) {
  return (
    <>
      <ModalContainer visible={isModalOpen}>
        <Modal width={width} heigth={heigth}>
          <ModalHeader showHeader={showHeader}>
            {showHeader && <ModalHeaderText>{title}</ModalHeaderText>}
            <ModalClose onClick={() => setModalOpen(false)}>{CloseIcon}</ModalClose>
          </ModalHeader>
          <ModalContent height={contentHeight}>{children}</ModalContent>
          <ModalFooter showFooter={showFooter}>
            { showCancelBtn && <ModalButton onClick={onCancel}>{cancelBtnText}</ModalButton>}
            { showOkBtn && <ModalButton color={okBtnColor} onClick={onOk}>{okBtnText}</ModalButton>}
          </ModalFooter>
        </Modal>
      </ModalContainer>
    </>
  );
}

const ModalContainer = styled.div`
  position: absolute;
  display: ${(props) => (props.visible ? "flex" : "none")};
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(229, 236, 239, 0.4);
  backdrop-filter: blur(1.5px);
  align-items: center;
  justify-content: center;
  z-index: 1;
`;

const Modal = styled.div`
  max-height: 80%;
  background: #ffffff;
  box-shadow: 0px 1px 8px rgba(110, 110, 110, 0.1);
  border-radius: 8px;
  width: ${(props) => (props.width ? props.width : "480px")};
  box-sizing: border-box;
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;

const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.showHeader ? 'space-between' : 'end')};
  padding: 16px;
  border-bottom: ${(props) => (props.showHeader ? '1px solid #dee2e6' : 'none')};
`;

const ModalHeaderText = styled.span`
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
`;

const ModalClose = styled.svg`
  width: 12px;
  height: 12px;
  cursor: pointer;
`;

const ModalContent = styled.div`
  height: ${(props) => (props.height ? props.height : "auto")};
  overflow: auto;
  padding: 16px;
`;

const ModalFooter = styled.div`
  display: ${(props) => (props.showFooter ? 'flex' : 'none')};
  justify-content: end;
  gap: 10px;
  padding: 16px;
  border-top: 1px solid #dee2e6;
`;

const ModalButton = styled.button`
  background-color: ${(props) => (props.color ? props.color : "transparent")};
  padding: 12px 16px;
  font-size: 14px;
  border: 1px solid #E0E0E0;
  border-radius: 8px;
`;

const Action = styled.button`
  width: 72px;
  height: 28px;
  background: #e9bd5a;
  border-radius: 6px;
  border: none;
  margin-left: auto;
  margin-top: 30px;
  cursor: pointer;

  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  color: #ffffff;

  &:hover {
    background: lightblue;
  }
`;

export default CustomModal;
