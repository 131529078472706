import { createSlice } from "@reduxjs/toolkit";
import { main_api } from "../../api/axios_helper";
import { companyAPIsEndPoints, platformAPIsEndPoints } from "../../constants/ApiEndPoints";
import { pushNotification } from "../../util/Notification";

const initialState = {
  isLoading: false,
  error: false,
  isCompanyDetailModalOpen: false,
  companyDetailResponse: {},
};

const slice = createSlice({
  name: "companyDetail",
  initialState,
  reducers: {
    openCompanyDetailModalSlice(state, action) {
      state.isCompanyDetailModalOpen = action.payload.isCompanyDetailModalOpen;
    },
    loadCompanyDetailSlice(state, action) {
      state.isLoading = action.payload.isLoading;
      state.error = action.payload.error;
      state.companyDetailResponse = action.payload.companyDetailResponse;
    },
    updateCompanyGroupsSlice(state, action) {
      state.isLoading = action.payload.isLoading;
      state.error = action.payload.error;
      state.companyDetailResponse = action.payload.companyDetailResponse;
    },
  },
});

export const { openCompanyDetailModalSlice, loadCompanyDetailSlice, updateCompanyGroupsSlice } = slice.actions;

export default slice.reducer;

export const loadCompanyDetail = (id) => async (dispatch) => {
  try {
    dispatch(loadCompanyDetailSlice({ isLoading: true, error: false, companyDetailResponse: [] }));
    const { data } = await main_api.get(platformAPIsEndPoints.FETCH_SINGLE_COMPANY_LIST_PETS(id));
    dispatch(loadCompanyDetailSlice({ isLoading: false, error: false, companyDetailResponse: data }));
  } catch (error) {
    dispatch(loadCompanyDetailSlice({ isLoading: false, error: error }));
  }
};

export const updateCompanyGroups = (id, values) => async (dispatch) => {
  try {
    dispatch(updateCompanyGroupsSlice({ isLoading: true, error: false, companyDetailResponse: [] }));
    const { data } = await main_api.put(companyAPIsEndPoints.UPDATE_SINGLE_COMPANY(id), values);
    dispatch(updateCompanyGroupsSlice({ isLoading: false, error: false, companyDetailResponse: data }));
    pushNotification(data?.message, "success");
  } catch (error) {
    // dispatch(updateCompanyGroupsSlice({ isLoading: false, error: true, errorMessage: error.response.data.message }));
    pushNotification(error.response.data.message, "error");
  }
};

export const openCompanyDetailModal = (isOpen) => (dispatch) => {
  dispatch(openCompanyDetailModalSlice({ isCompanyDetailModalOpen: isOpen }));
};
