import React from 'react'
import styled from 'styled-components'

const PlatformDetailCard = ({iconComponent, cardText, cardValue, backgroundColor=""}) => {
  return (
    <CardWraper backgroundColor={backgroundColor}>
        {iconComponent}
        <div>{cardText}</div>
        <h2>{cardValue}</h2>
    </CardWraper>
  )
}

const CardWraper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    min-width: 350px;
    min-height: 136px;
    padding: 12px 20px 12px 20px;
    background: ${props => props.backgroundColor ?? "transparent"};
    border-radius: 20px;
`;

export default PlatformDetailCard