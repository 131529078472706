import React, { useState, useEffect } from "react";
import { NavigationHeading } from "../../company/clients/clientStyles";
import ServiceRequest from "./ServiceRequest";
import ChangeRequest from "./ChangeRequest";
import styled from "styled-components";
import moment from "moment";
import { useSelector } from "react-redux";
import { checkPermission } from "../../../util/commonUtil";

const Scheduler = ({ fetchAllSchedulerService, schedulerState, permissionState }) => {
  const [activeTab, setActiveTab] = useState("services");
  const auth = useSelector((state) => state.auth);

  const filterChange = (startDate = null, endDate = null, status = "") => {
    const clientId = auth.loginResponse?.selectCompany?.filter((item) => item.client_id !== null)[0].client_id;
    let clientQuery = "";
    if (clientId) {
      clientQuery = `client=${clientId}`;
    }

    if (checkPermission(permissionState, "view_service_requests")) {
      if (startDate && endDate) {
        let start = moment(startDate).format("YYYY-MM-DD");
        let end = moment(endDate).format("YYYY-MM-DD");
        fetchAllSchedulerService(
          clientQuery,
          "status=scheduled&status=in_progress&status=completed&selectable_by_client=true",
          start,
          end,
        );
      } else {
        fetchAllSchedulerService(clientQuery, status, "", "");
      }
    }
  };

  return (
    <>
      <NavigationHeading>Schedule</NavigationHeading>
      <ActionButtons>
        <TabContainer>
          <TabButton onClick={() => setActiveTab("services")} active={activeTab === "services"}>
            Services
          </TabButton>
          <TabButton
            onClick={() => {
              setActiveTab("change_request");
              filterChange(null, null, "status=change_requested");
            }}
            active={activeTab === "change_request"}
            marginLeft="30px"
          >
            Change Request
          </TabButton>
        </TabContainer>
      </ActionButtons>
      {activeTab === "services" && <ServiceRequest schedulerState={schedulerState} filterChange={filterChange} />}
      {activeTab === "change_request" && <ChangeRequest schedulerState={schedulerState} />}
    </>
  );
};

const ActionButtons = styled.div`
  display: flex;
  margin-bottom: 27px;
  border-bottom: 1px solid rgb(228, 234, 240);
`;

const TabContainer = styled.div`
  padding: 16px 16px 0px 16px;
  height: 54px;
  box-sizing: border-box;
  border-radius: 6px;
  display: flex;
`;

const TabButton = styled.button`
  background-color: transparent;
  padding: 9px 8px 29px 8px;
  height: 34px;
  border: none;
  margin-left: ${(props) => props.marginLeft ?? "0"};
  cursor: pointer;
  border-bottom: 2px solid ${(props) => (props.active ? "#FBCF34" : "transparent")};
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: ${(props) => (props.active ? "#000000" : "#757575")};
`;

export default Scheduler;
