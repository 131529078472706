import { replaceDateFormat } from "../../util/formatDate";
import { Tag, Space } from "antd";
import CustomBtnWithIcon from "../../components/buttonWithIcon";
import styled from "styled-components";
import { ReactComponent as DeleteIcon } from "../../assets/rawSvgs/tableDeleteIcon.svg";
import { schedulerBackground, schedulerColor, schedulerStatus } from "../../components/SchedulerCalender/helpers";

const ClientSchedulerListColumens = ({ handleDelete, handleEditRow }) => {
  let columns = [
    {
      title: "Service",
      dataIndex: "service",
      key: "service",

      render: (text, record) => (
        <>{record.service && record.service.name ? <Tag style={tagStyle}>{record.service.name}</Tag> : ""}</>
      ),
    },
    {
      title: "Dates",
      dataIndex: "date",
      key: "date",

      render: (text, record) => <>{record?.date && replaceDateFormat(record?.date)}</>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => (
        <>
          <Tag style={{ ...tagStyle, backgroundColor: schedulerBackground(record), color: schedulerColor(record) }}>
            {schedulerStatus(record)}
          </Tag>
        </>
      ),
    },
    {
      title: "",
      key: "action",
      render: (_, record) => (
        <Space size="middle" className="d-flex justify-content-end">
          <CustomBtnWithIcon onClick={() => handleEditRow(record)} btnText={"Request Change"} hideIcon noBackground />
          <Button
            onClick={(event) => {
              event.stopPropagation();
              handleDelete(record);
            }}
          >
            <DeleteIcon />
          </Button>
        </Space>
      ),
    },
  ];

  return columns;
};

export { ClientSchedulerListColumens };

const tagStyle = {
  backgroundColor: "#EFF1F5",
  fontWeight: 500,
  border: "none",
  borderRadius: "28px",
  padding: "2px 8px 2px 6px",
  color: "#023047",
};

const Button = styled.button`
  border: none;
  background: none;
`;
