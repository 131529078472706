import React from "react";
import { Bar } from "react-chartjs-2";
function TotalAmountBarChart({componentRef, chartData, label }) {

    console.log("cc", chartData)

    const data = {
        labels: chartData && chartData[0]?.labels,
        datasets: [{
            label:  chartData && chartData[0]?.label,
            data: chartData && chartData[0]?.data,
            fill: false,
            borderColor: 'rgb(75, 192, 192)',
            tension: 0.1
        },
        {
            label: chartData && chartData[1]?.label,
            data: chartData && chartData[1]?.data,
            fill: false,
            borderColor: 'rgb(75, 192, 192)',
            backgroundColor: 'rgb(255, 99, 132)',
            tension: 0.1
        },
        {
            label: chartData && chartData[2]?.label,
            data: chartData && chartData[2]?.data,
            fill: false,
            borderColor: 'rgb(75, 192, 192)',
            backgroundColor: 'rgb(255, 205, 86)',
            tension: 0.1
        }
    ]
    };
    return (

        <div ref={componentRef} style={{ width: '70%', margin: 'auto' }} id="chart-container">
            <h2 style={{ textAlign: "center" }}>{label}</h2>
            {/* {chartData && chartData[0]?.totalCount>=0 && <div>Total Pending Requests: {chartData[0].totalCount}</div>} */}
            <Bar
                data={data}
                options={
                    {
                        plugins: {
                            legend: {
                                display: true
                            }
                        },
                        // scales: {
                        //     y: {
                        //       beginAtZero: true
                        //     }
                        // }
                        // scales: {
                        //     x: {
                        //       stacked: true,
                        //     },
                        //     y: {
                        //       stacked: true
                        //     }
                        //   }
                    }
                }
            />
        </div>
    );
}
export default TotalAmountBarChart;