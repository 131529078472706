import React, { useState } from "react";
import { Checkbox, Divider, Form, Input, Modal, Switch } from "antd";
import styled from "styled-components";
import CustomBtnWithIcon from "../../buttonWithIcon";

const AddCompanyModal = ({ isLoading, isModalOpen, setModalOpen, onFinish }) => {
  const [form] = Form.useForm();
  const [welcomeMessageChecked, setWelcomeMessageChecked] = useState(false);

  return (
    <CustomModal
      open={isModalOpen}
      title="Add a Company"
      width="600px"
      height="394px"
      onCancel={() => {
        setModalOpen(false);
        form.resetFields();
        setWelcomeMessageChecked(false);
      }}
      footer={null}
      maskClosable={false}
    >
      <Divider />
      <Form name="addCompanyForm" onFinish={onFinish} form={form} layout="vertical">
        <FormItem
          name="name"
          label="Company Name"
          rules={[
            {
              required: true,
              message: "Company Name is required",
            },
            {
              max: 30,
              min: 1,
            },
          ]}
        >
          <Input placeholder="Enter Company Name" required />
        </FormItem>
        <FormItem
          name="email"
          label="Email"
          rules={[
            {
              required: true,
              message: "Email is required",
            },
            {
              type: "email",
              message: "Please enter a valid email address",
            },
          ]}
        >
          <Input placeholder="Enter Email" />
        </FormItem>
        <FormItem
          name="admin_name"
          label="Company Admin Name"
          rules={[
            {
              required: true,
              message: "Company Admin Name is required",
            },
            {
              max: 30,
              min: 1,
            },
          ]}
        >
          <Input placeholder="Company Admin Name" />
        </FormItem>
        <FormItem className="mark-active-switch" name="active" label="Mark as Active" initialValue={true}>
          <Switch size="small" />
        </FormItem>
        <FormItem className="welcome-message-checkbox" name="welcome_message" valuePropName="checked">
          <Checkbox size="small" onChange={(e) => setWelcomeMessageChecked(e.target.checked)}>
            Send a Welcome message?
          </Checkbox>
        </FormItem>
        {welcomeMessageChecked && (
          <>
            <FormItem
              name="subject"
              label="Subject"
              rules={[
                {
                  required: true,
                  message: "Subject is required",
                },
              ]}
            >
              <Input placeholder="Enter Subject" />
            </FormItem>
            <FormItem
              name="body"
              label="Body"
              rules={[
                {
                  required: true,
                  message: "Body is required",
                },
              ]}
            >
              <Input.TextArea placeholder="Enter Body" />
            </FormItem>
          </>
        )}
        <Divider />
        <div className="d-flex justify-content-end">
          <CustomBtnWithIcon
            btnText={"Cancel"}
            margin="0px 5px"
            noBackground
            hideIcon={true}
            onClick={() => {
              setModalOpen(false);
              form.resetFields();
              setWelcomeMessageChecked(false);
            }}
          />
          <CustomBtnWithIcon btnText={"Add Company"} type="submit" hideIcon={true} />
        </div>
      </Form>
    </CustomModal>
  );
};

const FormItem = styled(Form.Item)`
  width: 100%;
`;

const CustomModal = styled(Modal)`
  .ant-modal-header .ant-modal-title {
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
  }
  .ant-form-item .ant-form-item-label .ant-form-item-required {
    font-size: 12px;
    font-weight: 700;
    color: #383838;
  }
  .ant-form-vertical .ant-form-item-label {
    padding: 0 0 2px;
  }
  .ant-form-item .ant-form-item-label label {
    font-weight: 700;
    color: #212121;
  }
  .welcome-message-checkbox .ant-form-item-row {
    flex-direction: row;
    gap: 10px;
    align-items: baseline;
    width: 400px;
  }
  .welcome-message-checkbox .ant-form-item-control {
    width: auto;
    order: -1;
    flex: 0;
  }
  .mark-active-switch .ant-form-item-row {
    flex-direction: row;
    align-items: baseline;
  }
  .mark-active-switch .ant-form-item-control {
    width: auto;
  }
`;

export default AddCompanyModal;
