import React, { useEffect, useState, useRef } from "react"
import { useSelector } from "../../../redux/store";
import { companyAPIsEndPoints, staffAPIsEndPoints } from "../../../constants/ApiEndPoints";
import dayjs from "dayjs";
import { pushNotification } from "../../../util/Notification";
import { main_api } from "../../../api/axios_helper";
import { AntdesignTablePagination } from "../../../components/antDesignTable/AntdesignTablePagination";
import { DatePicker, Select } from "antd";
import { ServiceRequestListColumn } from "../../../constants/ReportingTableHeader/ReportingHeader";
import ReportUpdate from "./ReportUpdate";
import { PaginatedSelect } from "../../../components/PaginationSelect";
import PendingRequestVolumeChart from "./PendingRequestVolumeChart";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import CancelledVisitsChart from "./CancelledVisitsChart";
import styled from "styled-components";

function ScheduleServiceRequest({filter, saveReport, setSaveReport, currentReport, setCurrentReport, saveReportCallBack}) {
  const { auth } = useSelector((state) => state);
  const [serviceRequestListing, setServiceRequestListing] = useState([])
  const [count, setCount] = useState(0)
  const [canSave, setCanSave] = useState(false)
  const [updateReport, setUpdateReport] = useState(false)
  const today = new Date()
  const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
  const [endDate, setEndDate] = useState(dayjs(new Date()).format('YYYY-MM-DD'))
  const [startDate, setStartDate] = useState(dayjs(firstDay).format('YYYY-MM-DD'))
  const [selectClient, setSelectClient] = useState(null)
  const [selectStaff, setSelectStaff] = useState(null)
  const [selectService, setSelectService] = useState(null)
  const [selectedStatus, setSelectedStatus] = useState(null)
  const [chartData, setChartData] = useState(null)
  const componentRef = useRef();

  const resetFields = () =>{
    setServiceRequestListing([])
    setCount(0)
    setStartDate(dayjs(firstDay).format('YYYY-MM-DD'))
    setEndDate(dayjs(new Date()).format('YYYY-MM-DD'))
  }

  const getReleventData = () =>{
    if(filter === 'pending_requests' || filter === 'visits' || filter === 'overdue_events'){
      fetchServiceRequests()
    } else if(filter === 'pending_requests_volume' || filter === 'cancelled_visits'){
      fetchServiceRequestsGraphData()
    }
  }

  useEffect(()=>{
    getReleventData()
  },[filter, startDate, endDate, selectClient, selectService, selectStaff, selectedStatus])

  useEffect(()=>{
    resetFields()
  },[filter])

  useEffect(()=>{
    if(saveReport){
      if(canSave){
        if(currentReport.id){
          setUpdateReport(true)
        }
        else{
          generateReport()
        }
      } else{
        setSaveReport(false)
      }
    } else{
      setSaveReport(false)
    }
  },[saveReport])

  const generateReport = (reportData=currentReport) => {
    const companyId = auth?.loginResponse?.selectCompany?.[0]?.company_id;
    const params = {company: companyId, filterType: filter, name: reportData.name, status: reportData.status}
    if(reportData.id){
      params['id'] = reportData.id
    }
    if (filter === 'pending_requests'){
      params['start_date'] = startDate
      params['end_date'] = endDate
    } else if(filter === 'visits'){
      if (new Date(startDate) > new Date(endDate)){
        pushNotification('start date should be less than end date', 'error')
        resetFields()
        setCanSave(false)
        return
      }
      params['start_date'] = startDate
      params['end_date'] = endDate
      if (selectClient){
        params['selected_client'] = selectClient
      }
      if (selectStaff){
        params['selected_staff'] = selectStaff
      }
      if (selectService){
        params['selected_service'] = selectService
      }
      if (selectedStatus){
        params['selected_status'] = selectedStatus
      }

    } else if (filter === 'pending_requests_volume' || filter === 'cancelled_visits'){
      generatePdf(params)
      return;
    }

    saveReportCallBack(params)

  }

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const prepareMonthChartData = (graph_data) =>{

    const startDateObj = new Date(startDate);
    const endDateObj = new Date(endDate);

    const dates = [];
    const counts = []

    let current_date = new Date(startDateObj.getFullYear(), startDateObj.getMonth(), 1);

    // Generate first date of every month from start_date to end_date
    while (current_date <= endDateObj) {
        const formattedDate = formatDate(current_date);
        const dataEntry = graph_data.find(entry => entry.month === formattedDate);
        dates.push(formattedDate);
        if (dataEntry) {
            counts.push(dataEntry.count);
        } else {
            counts.push(0);
        }
        current_date.setMonth(current_date.getMonth() + 1);
    }

    return [dates, counts]

  }

  const fetchServiceRequestsGraphData = () =>{
    const companyId = auth?.loginResponse?.selectCompany?.[0]?.company_id;
    let params = {company: companyId, filterType: filter }
    if (filter === 'pending_requests_volume' || filter === 'cancelled_visits'){
      if (new Date(startDate) > new Date(endDate)){
        pushNotification('start date should be less than end date', 'error')
        resetFields()
        setCanSave(false)
        return
      }
      params['start_date'] = startDate
      params['end_date'] = endDate
    }

    main_api.post(`${companyAPIsEndPoints.REPORTING_CHART}`, params)
    .then(({ data }) => {
      if(filter === 'pending_requests_volume'){
        let graph_data = data.results
        const startDateObj = new Date(startDate);
        const endDateObj = new Date(endDate);

        let dates = [];
        let counts = []

        const sameMonth = (startDateObj.getMonth() === endDateObj.getMonth()) && (startDateObj.getFullYear() === endDateObj.getFullYear());
        if(sameMonth){
          let current_date = startDateObj;

          while (current_date <= endDateObj) {
            const formattedDate = formatDate(current_date);
            dates.push(formattedDate);
            const dataEntry = graph_data.find(entry => entry.day === formattedDate);
            if (dataEntry) {
                counts.push(dataEntry.count);
            } else {
                counts.push(0);
            }
            current_date.setDate(current_date.getDate() + 1);
          }
        } else{
          [dates, counts] = prepareMonthChartData(graph_data) 
        }
        setChartData([{
          data: counts,
          labels: dates,
          totalCount: data.count
        }])
      } else if (filter === 'cancelled_visits'){
        let [total_evnets_dates, total_evnets_counts] = prepareMonthChartData(data.total_events_data)
        let [cancelled_events_dates, cancelled_events_counts] = prepareMonthChartData(data.cancelled_events_data)
        setChartData([{
          label: "Total Events",
          data: total_evnets_counts,
          labels: total_evnets_dates,
          totalCount: data.total_events_count
        },{
          label: "Cancelled Events",
          data: cancelled_events_counts,
          labels: cancelled_events_dates,
          totalCount: data.cancelled_events_count
        }])

      }
      setCanSave(true)
    })
    .catch((error) => {
      console.log(error);
    });

  }

  const fetchServiceRequests = (page = 1) => {
    const companyId = auth?.loginResponse?.selectCompany?.[0]?.company_id;
    let params = {company: companyId, filterType: filter }
    if (filter === 'pending_requests'){
      if (new Date(startDate) > new Date(endDate)){
        pushNotification('start date should be less than end date', 'error')
        resetFields()
        setCanSave(false)
        return
      }
      params['start_date'] = startDate
      params['end_date'] = endDate
    } else if(filter === 'visits'){
      if (new Date(startDate) > new Date(endDate)){
        pushNotification('start date should be less than end date', 'error')
        resetFields()
        setCanSave(false)
        return
      }
      params['start_date'] = startDate
      params['end_date'] = endDate
      if (selectClient){
        params['selected_client'] = selectClient
      }
      if (selectStaff){
        params['selected_staff'] = selectStaff
      }
      if (selectService){
        params['selected_service'] = selectService
      }
      if (selectedStatus){
        params['selected_status'] = selectedStatus
      }
    }
    main_api.post(`${companyAPIsEndPoints.REPORTING_SCHEDULE}?page=${page}`, params)
    .then(({ data }) => {
      setServiceRequestListing(data.results)
      setCount(data.count)
      if(data.count){
        setCanSave(true)
      }else{
        setCanSave(false)
      }
    })
    .catch((error) => {
      console.log(error);
    });
  }

  const fetchClients = async (page) => {
    const companyId = auth?.loginResponse?.selectCompany?.[0]?.company_id;

    // Example: Fetch data from an API
    const response = await main_api.get(companyAPIsEndPoints.FETCH_MANAGEMENT_CLIENTS_PAGINATION(companyId, page));
    const data = response.data.result;

    const clientData = data.map(item => ({
      label: item.name,
      value: item.id
    }));
  
    return {
      data: clientData,
      total: response.data.count
    };
  };

  const fetchStaff = async (page) => {
    const companyId = auth?.loginResponse?.selectCompany?.[0]?.company_id;
    // Example: Fetch data from an API
    const response = await main_api.get(staffAPIsEndPoints.LIST_STAFF(`usercompanyrole__company=${companyId}&pagination=true&page=${page}`));
    const data = response.data.result;

    const staffData = data.map(item => ({
      label: item.name,
      value: item.id
    }));
  
    return {
      data: staffData,
      total: response.data.count
    };
  };

  const fetchService = async (page) => {
    const companyId = auth?.loginResponse?.selectCompany?.[0]?.company_id;
    // Example: Fetch data from an API
    const response = await main_api.get(companyAPIsEndPoints.GET_ALL_SERVICES(companyId, "", `pagination=true&page=${page}`));
    const data = response.data.result;

    const serviceData = data.map(item => ({
      label: item.name,
      value: item.id
    }));
  
    return {
      data: serviceData,
      total: response.data.count
    };
  };

  const generatePdf = async (params) => {
    const input = componentRef.current;

    // Convert the component to a canvas
    const canvas = await html2canvas(input);
    const imgData = canvas.toDataURL('image/png');

    // Get the dimensions of the canvas
    const imgWidth = 210; // Width of A4 in mm
    const imgHeight = (canvas.height * imgWidth) / canvas.width; // Maintain aspect ratio
    const pdf = new jsPDF('p', 'mm', 'a4');
    const pageHeight = pdf.internal.pageSize.height; // Height of A4 in mm
    let heightLeft = imgHeight;

    // Initial position on the first page
    let position = 0;

    // Add the image to the PDF
    pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
    heightLeft -= pageHeight;

    // Add additional pages if needed
    while (heightLeft > 0) {
      position = heightLeft - imgHeight;
      pdf.addPage();
      pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
    }

    // Save the PDF
    // pdf.save('document.pdf');
    const pdfData = pdf.output('blob');

    params['report_type'] = 'Schedule Report'

    const formData = new FormData();
    formData.append('pdf_file', pdfData, 'document.pdf');
    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        formData.append(key, params[key]);
      }
    }

    main_api.post(companyAPIsEndPoints.PDF_REPORTING_SAVE, formData)
    .then(({ data }) => {
      setCurrentReport(data.result)
      pushNotification(data.message)
      setSaveReport(false)
    })
    .catch((error) => {
      console.log(error)
      setSaveReport(false)
    });
  };

  return (
    <>
      {(filter === 'pending_requests') &&
        <>
          <div style={{marginBottom: '10px'}}>
            <label>Date Range: </label>
            <DateContainer><DatePicker value={dayjs(startDate)} allowClear={false} defaultValue={dayjs(new Date(today.getFullYear(), today.getMonth(), 1))} onChange={(e, dateString) => setStartDate(dateString)} placeholder="Start Date"/> - <DatePicker value={dayjs(endDate)} allowClear={false} defaultValue={dayjs(new Date())} onChange={(e, dateString) => setEndDate(dateString)} placeholder="End Date"/></DateContainer>
          </div>
          <AntdesignTablePagination
            columns={ServiceRequestListColumn({ })}
            data={serviceRequestListing}
            totalCount={count}
            loadPaginatedData={fetchServiceRequests}
            allowRowSelection={false}
          />
        </>
      }
      {(filter === 'pending_requests_volume') &&
        <>
          <div style={{marginBottom: '10px'}}>
            <label>Date Range: </label>
            <DateContainer><DatePicker value={dayjs(startDate)} allowClear={false} defaultValue={dayjs(new Date(today.getFullYear(), today.getMonth(), 1))} onChange={(e, dateString) => setStartDate(dateString)} placeholder="Start Date"/> - <DatePicker value={dayjs(endDate)} allowClear={false} defaultValue={dayjs(new Date())} onChange={(e, dateString) => setEndDate(dateString)} placeholder="End Date"/></DateContainer>
          </div>
          <PendingRequestVolumeChart label="Pending Request Volume" componentRef={componentRef} chartData={chartData}/>
        </>
      }
      {(filter === 'cancelled_visits') &&
        <>
          <div style={{marginBottom: '10px'}}>
            <label>Date Range: </label>
            <DateContainer><DatePicker value={dayjs(startDate)} allowClear={false} defaultValue={dayjs(new Date(today.getFullYear(), today.getMonth(), 1))} onChange={(e, dateString) => setStartDate(dateString)} placeholder="Start Date"/> - <DatePicker value={dayjs(endDate)} allowClear={false} defaultValue={dayjs(new Date())} onChange={(e, dateString) => setEndDate(dateString)} placeholder="End Date"/></DateContainer>
          </div>
          <CancelledVisitsChart label="Cancelled Visits" componentRef={componentRef} chartData={chartData}/>
        </>
      }
       {(filter === 'overdue_events') &&
        <>
          <AntdesignTablePagination
            columns={ServiceRequestListColumn({ })}
            data={serviceRequestListing}
            totalCount={count}
            loadPaginatedData={fetchServiceRequests}
            allowRowSelection={false}
          />
        </>
      }
      {(filter === 'visits') &&
        <>
          <div style={{marginBottom: '10px', display: 'flex', alignItems: 'baseline', gap: '5px', flexWrap: 'wrap'}}>
            <div>
              <label>Date: </label>
              <DateContainer><DatePicker value={dayjs(startDate)} allowClear={false} defaultValue={dayjs(new Date(today.getFullYear(), today.getMonth(), 1))} onChange={(e, dateString) => setStartDate(dateString)} placeholder="Start Date"/> - <DatePicker value={dayjs(endDate)} allowClear={false} defaultValue={dayjs(new Date())} onChange={(e, dateString) => setEndDate(dateString)} placeholder="End Date"/></DateContainer>
            </div>
            <div style={{width: '20%'}}>
              <PaginatedSelect value={selectClient} setValue={setSelectClient} fetchOptions={fetchClients} placeholder="Select Client" />
            </div>
            <div style={{width: '20%'}}>
              <PaginatedSelect value={selectStaff} setValue={setSelectStaff} fetchOptions={fetchStaff} placeholder="Select Staff" />
            </div>
            <div style={{width: '20%'}}>
              <PaginatedSelect value={selectService} setValue={setSelectService} fetchOptions={fetchService} placeholder="Select Service" />
            </div>
            <div>
              <Select
                placeholder="Status"
                allowClear
                size="large"
                onChange={(value) => setSelectedStatus(value)}
                options={[
                  { value: "change_requested", label: "Change Requested" },
                  { value: "unassigned", label: "Unassigned" },
                  { value: "scheduled", label: "Scheduled" },
                  {value: "in_progress", label: "In Progress" },
                  {value: "cancelled", label: "Cancelled" },
                  {value: "Completed", label: "Completed" },
                  {value: "projected", label: "Projected" },
                ]}
              />
            </div>
          </div>
          <AntdesignTablePagination
            columns={ServiceRequestListColumn({ })}
            data={serviceRequestListing}
            totalCount={count}
            loadPaginatedData={fetchServiceRequests}
            allowRowSelection={false}
          />
        </>
      }
      {updateReport && <ReportUpdate setSaveReport={setSaveReport} generateReport={generateReport} setUpdateReport={setUpdateReport} currentReport={currentReport} setCurrentReport={setCurrentReport}/>}

    </>
  )
}

export default ScheduleServiceRequest;


const DateContainer = styled.span`
  margin-left: 5px;
`;

