import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { StaffTimeOffColumens } from "../../../../constants/StaffTableHeaders/StaffTimeOffTableHeaders";
import { AntdesignTablePagination } from "../../../../components/antDesignTable/AntdesignTablePagination";
import CustomBtnWithIcon from "../../../../components/buttonWithIcon";
import ManageTimeOffModal from "../../../../components/Modals/StaffManagementModal/ManageTimeOffModal";
import { DatePicker, Flex, Button, Segmented } from "antd";
import { staffAPIsEndPoints } from "../../../../constants/ApiEndPoints";
import { main_api } from "../../../../api/axios_helper";
import DeleteConfirmationRowModal from "../../../../components/Modals/DeleteConfirmationRowModal";
import { pushNotification } from "../../../../util/Notification";
import dayjs from "dayjs";
import NoDataScreen from "../../../../components/NoDataScreen";
import {useSelector} from 'react-redux'
import PermissionModal from "../../../../components/Modals/PermissionModal";
import { checkPermission } from "../../../../util/commonUtil";

function StaffTimeOff(props) {
  const [activeTab, setActiveTab] = useState("lists");
  const [staffTimeOff, setStaffTimeOff] = useState([]);
  const [addTimeOff, setAddTimeOff] = useState(false);
  const [deleteTimeOff, setDeleteTimeOff] = useState(false);
  const [selectedTimeOff, setSelectedTimeOff] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedDate, setSelectedDate] = useState(null);
  const [hasNextPage, setHasNextPage] = useState(false);
  const pageSize = 10;
  const { permissionManagement } = useSelector((state) => state);
  const [permissionPopUp, setPermissionPopUp] = useState(false);

  useEffect(() => {
    if(checkPermission(permissionManagement.permissions, 'view_timeoffs')){
      fetchStaffRelatedTimeOff();
    }
  }, [activeTab, selectedDate, permissionManagement.permissions]);

  useEffect(() => {
    if (!addTimeOff) {
      setSelectedTimeOff(null);
    }
  }, [addTimeOff]);

  const fetchStaffRelatedTimeOff = (page = 1) => {
    setStaffTimeOff([]);
    main_api
      .get(
        `${staffAPIsEndPoints.LIST_STAFF_TIMEOFF(`staff=${props.staffId}&company_id=${props.companyId}&page=${page}&is_approved=${activeTab === "lists" ? true : false}&selected_date=${selectedDate}`)}`,
      )
      .then(({ data }) => {
        setStaffTimeOff(data.results);
        setTotalCount(data.count);
        if (data.next) {
          setHasNextPage(true);
        } else {
          setHasNextPage(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDelete = (timeOff) => {
    if(checkPermission(permissionManagement.permissions, 'delete_timeoff')){
      setSelectedTimeOff(timeOff);
      setDeleteTimeOff(true);
    } else{
      setPermissionPopUp(true)
    }
  };

  const handleEditRow = (timeOff) => {
    if(checkPermission(permissionManagement.permissions, 'edit_timeoff')){
      setSelectedTimeOff(timeOff);
      setAddTimeOff(true);
    } else{
      setPermissionPopUp(true)
    }
  };

  const handleConfirmDeleteRows = async () => {
    let api_data = {
      company_id: props.companyId,
      staff: props.staffId,
    };
    try {
      var { data } = await main_api.post(`${staffAPIsEndPoints.DELETE_STAFF_TIMEOFF(selectedTimeOff.id)}`, api_data);
      if (data.isSuccess) {
        if (hasNextPage) {
          fetchStaffRelatedTimeOff();
        } else {
          const updatedStaffTimeOff = staffTimeOff.filter((item) => item.id !== selectedTimeOff.id);
          setStaffTimeOff(updatedStaffTimeOff);
          setTotalCount(totalCount - 1);
        }
        pushNotification(data.message);
      } else {
        pushNotification(data.message, "error");
      }
      setSelectedTimeOff(null);
      setDeleteTimeOff(false);
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        pushNotification(error.response.data.message, "error");
      }
      console.log("errr", error);
    }
  };

  const handleTimeOff = async (api_data, timeOff = null, remove = false) => {
    if(timeOff && !checkPermission(permissionManagement.permissions, 'edit_timeoff')){
      setPermissionPopUp(true)
      return;
    }
    try {
      api_data["company_id"] = props.companyId;
      api_data["staff"] = props.staffId;
      let id = selectedTimeOff && selectedTimeOff.id ? selectedTimeOff.id : timeOff ? timeOff.id : null;
      if (id) {
        var { data } = await main_api.put(`${staffAPIsEndPoints.STAFF_TIMEOFF}${id}/`, api_data);
      } else {
        var { data } = await main_api.post(staffAPIsEndPoints.STAFF_TIMEOFF, api_data);
      }
      if (data.isSuccess) {
        setAddTimeOff(false);
        if (selectedTimeOff) {
          // update existing timeoff
          const updatedStaffTimeOff = staffTimeOff.map((item) => {
            if (item.id === id) {
              return data.result; // updated object
            }
            return item;
          });
          setStaffTimeOff(updatedStaffTimeOff);
        } else if (remove) {
          // remove row
          const updatedStaffTimeOff = staffTimeOff.filter((item) => item.id !== id);
          setStaffTimeOff(updatedStaffTimeOff);
        } else {
          // new timeOff
          if (staffTimeOff.length === pageSize) {
            const updatedStaffTimeOff = [data.result, ...staffTimeOff];
            updatedStaffTimeOff.pop();
            setStaffTimeOff(updatedStaffTimeOff);
          } else {
            setStaffTimeOff([data.result, ...staffTimeOff]);
          }
          if (activeTab !== "lists") {
            setActiveTab("lists");
          }
          setTotalCount(totalCount + 1);
        }
        pushNotification(data.message);
      } else {
        pushNotification(data.message, "error");
      }
      setSelectedTimeOff(null);
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        pushNotification(error.response.data.message, "error");
      }
      console.log("errr", error);
    }
  };

  const createTimeOff = () => {
    if(checkPermission(permissionManagement.permissions, 'create_timeoff')){
      setAddTimeOff(true)
    } else{
      setPermissionPopUp(true)
    }
  }

  return (
    <div className="mt-2">
      <div className="col-md-3">
        <Segmented
          style={{ marginBottom: 8, background: "rgb(238,238,238,1)" }}
          onChange={(value) => setActiveTab(value)}
          options={[
          {
            label: "Time Off Lists",
            value: 'lists'
          },
          {
            label: "Time Off Requests",
            value: 'requests'
          }]}
          className="p-1 "
          defaultValue={activeTab}
          block
          size="large"
        />
      </div>
      <StyledFlexWrapper>
        <Flex flexDirection={"row"} gap={10} wrap="wrap" justify="space-between" align="center">
          {(staffTimeOff.length > 0 || !(staffTimeOff.length === 0 && selectedDate === null)) && (
            <DatePicker
              selected={selectedDate}
              onChange={(val) => setSelectedDate(val ? dayjs(val).format("YYYY-MM-DD") : null)}
            />
          )}
          {activeTab === "lists" && (
            <CustomBtnWithIcon btnText={"Add Time Off"} hideIcon onClick={() => createTimeOff()} className="ms-auto" />
          )}
        </Flex>
      </StyledFlexWrapper>
      {staffTimeOff.length > 0 ? (
        <AntdesignTablePagination
          columns={StaffTimeOffColumens({ activeTab, handleDelete, handleEditRow, handleTimeOff })}
          data={staffTimeOff}
          totalCount={totalCount}
          loadPaginatedData={fetchStaffRelatedTimeOff}
          pageSize={pageSize}
        />
      ) : (
        <NoDataScreen
          heading={
            activeTab === "lists"
              ? "You don’t have any Time Offs in your list. Let’s get started by adding a new Time Off"
              : "You don’t have any Time Offs in your list."
          }
          btnText={"Add new Time Off"}
          onClick={() => createTimeOff()}
          showBtn={activeTab === "lists" ? true : false}
        />
      )}
      <ManageTimeOffModal
        isModalOpen={addTimeOff}
        setModalOpen={setAddTimeOff}
        selectedTimeOff={selectedTimeOff}
        handleTimeOff={handleTimeOff}
      />
      <DeleteConfirmationRowModal
        onclose={() => setDeleteTimeOff(false)}
        handleConfirmDeleteRows={handleConfirmDeleteRows}
        openConfimrationDeleteModal={deleteTimeOff}
      />
      <PermissionModal
        onclose={() => setPermissionPopUp(false)}
        openModal={permissionPopUp}
      />
    </div>
  );
}

export default React.memo(StaffTimeOff);

const StyledFlexWrapper = styled.div`
  margin-bottom: 16px;
`;
