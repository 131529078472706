import React from "react";
import { DatePicker, Divider, Flex, Form, Input, message, Modal, Select, Typography, Upload } from "antd";
import { ReactComponent as DefaultImageIcon } from "../../../assets/rawSvgs/defaultImage.svg";
import CustomInput from "../../CustomInput";
import styled from "styled-components";
import CustomBtnWithIcon from "../../buttonWithIcon";
import { CustomModal } from "../ModalStyles";
const { Dragger } = Upload;

const AddVetModal = ({ isLoading, isModalOpen, setModalOpen, onFinish }) => {
  const [form] = Form.useForm();
  form.resetFields();

  return (
    <CustomModal
      open={isModalOpen}
      title="Add a new Vet"
      width="748px"
      heigth="688px"
      onCancel={() => setModalOpen(false)}
      footer={null}
      maskClosable={false}
    >
      <Divider />
      <Form name="addVetForm" onFinish={onFinish} form={form} layout="vertical">
        <FormItem
          name="name"
          label="Vet Name"
          rules={[
            {
              required: false,
            },
            {
              min: 3,
              max: 50,
              message: "Vet Name must be between 3 and 50 characters",
            },
          ]}
        >
          <Input placeholder="Enter name of Vet" required />
        </FormItem>
        <FieldWraper>
          <FormItem
            name="primary_phone"
            label="Primary Phone"
            rules={[
              {
                min: 6,
                max: 15,
                message: "Primary Phone must be between 6 and 15 characters",
              },
            ]}
          >
            <Input placeholder="Enter Number" type="number" />
          </FormItem>
          <FormItem
            name="email"
            label="Email"
            rules={[
              {
                validator: (_, value) => {
                  if (!value) {
                    return Promise.reject("Please enter your email");
                  }
                  if (value.length < 8 || value.length > 100) {
                    return Promise.reject("Email must be between 8 and 100 characters");
                  }
                  if (!/^\S+@\S+\.\S+$/.test(value)) {
                    return Promise.reject("Please enter a valid email address");
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input placeholder="Enter Email" type="email" />
          </FormItem>
        </FieldWraper>
        <FormItem
          name="address"
          label="Address"
          rules={[
            {
              min: 5,
              max: 255,
              message: "Address must be between 5 and 255 characters",
            },
          ]}
        >
          <Input placeholder="Enter Address" />
        </FormItem>
        <FormItem
          name="note"
          label="Notes"
          rules={[
            {
              min: 10,
              max: 255,
              message: "Notes must be between 10 and 255 characters",
            },
          ]}
        >
          <Input.TextArea placeholder="Type notes here..." />
        </FormItem>
        <Divider />
        <div className="d-flex justify-content-end">
          <CustomBtnWithIcon
            btnText={"Cancel"}
            margin="0px 5px"
            noBackground
            hideIcon={true}
            onClick={() => setModalOpen(false)}
          />
          <CustomBtnWithIcon btnText={"Save Vet"} type="submit" hideIcon={true} />
        </div>
      </Form>
    </CustomModal>
  );
};

const FieldWraper = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
`;

const FormItem = styled(Form.Item)`
  width: 100%;
`;

export default AddVetModal;
