import SidebarNav from "../../components/SidebarNav";

import { connect } from "react-redux";
import { toggleSidebarIcon } from "../../redux/slices/GeneralAppSlice/AppSlice";
const mapStateToProps = (state) => {
  return {
    generalAppStae: state.generalApp,
  };
};

const mapDispatchToProps = (dispatch) => ({
  toggleSidebar: () => {
    dispatch(toggleSidebarIcon());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SidebarNav);
