


import React, { useState, useEffect, useRef, useCallback } from 'react';
import { CustomModal } from '../Modals/ModalStyles';
import { Col, Row } from 'antd';
import { MapContainer, TileLayer, Marker, Popup, useMap, useMapEvents } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L, { Icon } from 'leaflet';
import markerIconPng from 'leaflet/dist/images/marker-icon.png';
import { useDebounce } from '../../hooks/useDebounce';

const customMarkerIcon = new L.Icon({
  iconUrl: markerIconPng,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

function MapModal({ isModalOpen, address, setModalOpen, setAddress, setAddressLatAndLong }) {
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [initialPosition, setInitialPosition] = useState([45.42, -75.69]);
  const mapRef = useRef(null);
  const debouncedSearch = useDebounce(address, 300);

  useEffect(() => {
    if (debouncedSearch) {
      fetch(`https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(address)}`)
        .then(response => response.json())
        .then(data => {
          if (data && data.length > 0) {
            const { lat, lon } = data[0];
            setInitialPosition([parseFloat(lat), parseFloat(lon)]);
            setSelectedLocation({ lat: parseFloat(lat), lng: parseFloat(lon) });
          }
        })
        .catch(error => console.error('Error fetching geocoding data:', error));
    }
  }, [debouncedSearch]);
  const handleOk = useCallback(() => {
    if (selectedLocation) {
      setAddressLatAndLong(`Lat: ${selectedLocation.lat}, Lng: ${selectedLocation.lng}`);
    }
    setModalOpen(false);
  }, [selectedLocation, setAddressLatAndLong, setModalOpen]);

    const ResetCenterView = ({ center }) => {
    const map = useMap();
    useEffect(() => {
      map.setView(center);
    }, [center]);
    return null;
  };
  const handleMarkerDragEnd = useCallback((e) => {
    const marker = e.target;
    const position = marker.getLatLng();
    setSelectedLocation({ lat: position.lat, lng: position.lng });
    setAddressLatAndLong(`Lat: ${position.lat}, Lng: ${position.lng}`);
  }, [setAddressLatAndLong]);

  const CursorMarker = () => {
    const map = useMapEvents({
      click: (e) => {
        const { lat, lng } = e.latlng;
        setSelectedLocation({ lat, lng });
      },
      dragstart: () => {
        map.getContainer().style.cursor = `url(${markerIconPng}) 12 41, auto`;
      },
      dragend: () => {
        map.getContainer().style.cursor = '';
      },
    });

    return null;
  };

  return (
    <CustomModal
      open={isModalOpen}
      title="Select Location"
      width="30vw"
      height="auto"
      onCancel={() => {
        handleOk();
        setModalOpen(false)
      }}
      footer={null}
    >
      <Row>
        <Col lg={24}>
          <div style={{ width: '100%', height: 400 }}>
            <MapContainer
              center={initialPosition}
              zoom={13}
              ref={mapRef}
              scrollWheelZoom={false}
              style={{ width: '100%', height: '100%' }}
             
            >
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                useCache={false}
                crossOrigin={true}
              />
              <CursorMarker />
              <ResetCenterView center={initialPosition} />
              {selectedLocation && (
                <Marker
                  position={[selectedLocation.lat, selectedLocation.lng]}
                  icon={customMarkerIcon}
                  draggable={true}
                  eventHandlers={{
                    dragend: handleMarkerDragEnd,
                  }}
                >
                  <Popup>
                    Selected Location: <br />
                    Latitude: {selectedLocation.lat}, <br />
                    Longitude: {selectedLocation.lng}
                  </Popup>
                </Marker>
              )}
            </MapContainer>
          </div>
        </Col>
      </Row>
    </CustomModal>
  );
}

export default MapModal;
