import dayjs from "dayjs";
import { CommonActionHeaders } from "../TableActionHeaderColumn";
import { Tag } from "antd";
import { Link } from "react-router-dom";
import { convertTo12HourFormat } from "../../util/formatTime";
import { ReactComponent as EditIcon } from "../../assets/rawSvgs/tableEditIcon.svg";
import { ReactComponent as UnlockInvoiceIcon } from "../../assets/rawSvgs/lockinvoice.svg";
import CustomBtnWithIcon from "../../components/buttonWithIcon";
import moment from "moment";

export const invoicingTaleHeaders = ({ handleDelete = "", handleEditRow = "" }) => {
  return [
    {
      title: "Invoice #",
      dataIndex: "id",
      key: "id",
      render: (_, record) => (
        <Link to={"/company/invoicing/invoice-detail/" + record.id} style={{ fontWeight: 500 }}>
          {record?.id} &nbsp;
          {record.is_locked && <UnlockInvoiceIcon height="12px" width="12px" />}
        </Link>
      ),
    },
    {
      title: "Due date",
      dataIndex: "due_date",
      key: "due_date",
      render: (text, record) => (
        <>
          <Link to={"invoice-detail/" + record.id} style={{ fontWeight: 500 }}>
            {dayjs(text).format("DD/MM/YYYY")}
          </Link>
        </>
      ),
    },

    {
      title: "Client",
      dataIndex: "client_name",
      key: "client_name",
      render: (_, record) => <>{record?.client?.name}</>,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (text) => <>$ {text}</>,
    },
    {
      title: "Total Paid",
      dataIndex: "paid",
      key: "paid",
      render: (text) => <>$ {text}</>,
    },

    {
      title: "Balance",
      dataIndex: "amount",
      key: "amount",
      render: (text, record) => <>$ {record.amount - record.paid}</>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => {
        const dueDate = record.due_date;
        const currentDate = moment();
        const formattedDueDate = moment(dueDate, "YYYY-MM-DD");

        let statusText = text.replace(/_/g, " ");
        let statusStyle = getInvoiceStatusStyles(text);

        const isPastDue = text !== "paid" && formattedDueDate.isBefore(currentDate, "day");

        return (
          <>
            {!isPastDue && (
              <Tag className="text-capitalize" style={{ ...tagStyle, ...statusStyle }}>
                {statusText}
              </Tag>
            )}
            {isPastDue && (
              <Tag className="text-capitalize" style={{ ...tagStyle, ...getInvoiceStatusStyles("past") }}>
                due date is passed
              </Tag>
            )}
          </>
        );
      },
    },
    {
      title: "",
      key: "action",
      render: (_, record) => (
        <button
          onClick={(event) => {
            event.stopPropagation();
            handleEditRow && handleEditRow(record);
          }}
        >
          <EditIcon />
        </button>
      ),
    },
  ];
};

export const invoicingDetailService = () => {
  return [
    {
      title: "Service",
      dataIndex: "id",
      key: "id",
      render: (_, record) => <span style={{ fontWeight: 500 }}>{record?.single_service_request?.service?.name}</span>,
    },

    {
      title: "Scheduled On",
      dataIndex: "record?.single_service_request?.date",

      key: "record?.single_service_request?.date",
      render: (_, record) => <>{dayjs(record?.single_service_request?.date).format("DD/MM/YYYY")}</>,
    },
    {
      title: "Start time - End Time",
      dataIndex: "record?.single_service_request?.date",

      key: "record?.single_service_request?.date",
      render: (_, record) => (
        <div>
          {record?.single_service_request?.start_time && record?.single_service_request?.end_time
            ? convertTo12HourFormat(record?.single_service_request?.start_time) +
              " - " +
              convertTo12HourFormat(record?.single_service_request?.end_time)
            : "---"}
        </div>
      ),
    },
    {
      title: "Staff",
      dataIndex: "record?.single_service_request?.staff.name",
      key: "record?.single_service_request?.staff.name",
      render: (_, record) => <>{record?.single_service_request?.staff?.name || "---"}</>,
    },
    {
      title: "Paid/Unpaid",
      dataIndex: "paid",
      key: "paid",
      render: (text, record) => {
        let statusStyle = getInvoiceStatusStyles(text ? "paid" : "unpaid");

        return (
          <Tag className="text-capitalize" style={{ ...tagStyle, ...statusStyle }}>
            {text ? "paid" : "unpaid"}
          </Tag>
        );
      },
    },
  ];
};

export const invoicingClaimHeaders = () => {
  return [
    {
      title: "Service",
      dataIndex: "id",
      key: "id",
      render: (_, record) => (
        <Link to={"invoice-detail/" + record.id} style={{ fontWeight: 500 }}>
          {record?.single_service_request?.service?.name}
        </Link>
      ),
    },

    {
      title: "Scheduled On",
      dataIndex: "record?.single_service_request?.date",

      key: "record?.single_service_request?.date",
      render: (_, record) => <>{dayjs(record?.single_service_request?.date).format("DD/MM/YYYY")}</>,
    },
    {
      title: "Claimed",
      dataIndex: "record?.single_service_request?.date",

      key: "record?.single_service_request?.date",
      render: (_, record) => <>{dayjs(record?.single_service_request?.date).format("DD/MM/YYYY")}</>,
    },
  ];
};

// client header invoice

export const clientInvoicingTaleHeaders = ({ openPaymentModal }) => {
  return [
    {
      title: "Invoice #",
      dataIndex: "id",
      key: "id",
      render: (_, record) => (
        <Link to={"/client/invoice/invoice-detail/" + record.id} style={{ fontWeight: 500 }}>
          {record?.id} &nbsp;
          {record.is_locked && <UnlockInvoiceIcon height="12px" width="12px" />}
        </Link>
      ),
    },
    {
      title: "Due Date",
      dataIndex: "due_date",
      key: "due_date",
      render: (text) => <>{dayjs(text).format("DD/MM/YYYY")}</>,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (text) => <>$ {text}</>,
    },
    {
      title: "Total Paid",
      dataIndex: "paid",
      key: "paid",
      render: (text) => <>$ {text}</>,
    },

    {
      title: "Balance",
      dataIndex: "amount",
      key: "amount",
      render: (text, record) => <>$ {record.amount - record.paid}</>,
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => {
        const dueDate = record.due_date;
        const currentDate = moment();
        const formattedDueDate = moment(dueDate, "YYYY-MM-DD");

        let statusText = text.replace(/_/g, " ");
        let statusStyle = getInvoiceStatusStyles(text);

        const isPastDue = text !== "paid" && formattedDueDate.isBefore(currentDate, "day");

        return (
          <>
            {!isPastDue && (
              <Tag className="text-capitalize" style={{ ...tagStyle, ...statusStyle }}>
                {statusText}
              </Tag>
            )}
            {isPastDue && (
              <Tag className="text-capitalize" style={{ ...tagStyle, ...getInvoiceStatusStyles("past") }}>
                due date is passed
              </Tag>
            )}
          </>
        );
      },
    },
    {
      title: "Staff",
      dataIndex: "record?.single_service_request?.staff.name",
      key: "record?.single_service_request?.staff.name",
      render: (_, record) => <>{record?.single_service_request?.staff?.name || "---"}</>,
    },
    {
      title: "",
      key: "action",
      render: (_, record) => (
        <>
          {record.status !== "paid" && (
            <CustomBtnWithIcon btnText="Make Payment" hideIcon noBackground onClick={() => openPaymentModal(record.id)} />
          )}
        </>
      ),
    },
  ];
};

export function getInvoiceStatusStyles(status) {
  const styles = {
    draft: { backgroundColor: "#f0f0f0", color: "#000000" }, // example colors
    paid: { backgroundColor: "#E7F7E7", color: "#00914B" },
    void: { backgroundColor: "#ff0000", color: "#ffffff" },
    partially_paid: { backgroundColor: "#FDEFDA", color: "#B45309" },
    sent_to_client: { backgroundColor: "#E5F6FF", color: "#187BEF" },
    past: { backgroundColor: "#FBEEED", color: "#E53939" },
  };

  return styles[status] || { color: "#023047" }; // default styles
}

export const tagStyle = {
  backgroundColor: "#EFF1F5",
  fontWeight: 500,
  border: "none",
  borderRadius: "28px",
  padding: "2px 8px 2px 6px",
};
