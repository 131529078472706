import React, { useEffect, useState } from "react"
import { useSelector } from "../../../redux/store";
import { companyAPIsEndPoints } from "../../../constants/ApiEndPoints";
import dayjs from "dayjs";
import { pushNotification } from "../../../util/Notification";
import { main_api } from "../../../api/axios_helper";
import { AntdesignTablePagination } from "../../../components/antDesignTable/AntdesignTablePagination";
import { DatePicker } from "antd";
import { TimeReportListColumn } from "../../../constants/ReportingTableHeader/ReportingHeader";
import ReportUpdate from "./ReportUpdate";
import styled from "styled-components";


function TimeReportListing({filter, saveReport, setSaveReport, currentReport, setCurrentReport, saveReportCallBack}) {
  const { auth } = useSelector((state) => state);
  const [timeListing, setTimeListing] = useState([])
  const [count, setCount] = useState(0)
  const [canSave, setCanSave] = useState(false)
  const [updateReport, setUpdateReport] = useState(false)
  const today = new Date()
  const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
  const [endDate, setEndDate] = useState(dayjs(new Date()).format('YYYY-MM-DD'))
  const [startDate, setStartDate] = useState(dayjs(firstDay).format('YYYY-MM-DD'))

  const resetFields = () =>{
    setTimeListing([])
    setCount(0)
    setStartDate(dayjs(firstDay).format('YYYY-MM-DD'))
    setEndDate(dayjs(new Date()).format('YYYY-MM-DD'))
  }

  useEffect(()=>{
    fetchTimeReport()
  },[startDate, endDate, filter])

  useEffect(()=>{
    resetFields()
  },[filter])

  useEffect(()=>{
    if(saveReport){
      if(canSave){
        if(currentReport.id){
          setUpdateReport(true)
        }
        else{
          generateReport()
        }
      } else{
        setSaveReport(false)
      }
    } else{
      setSaveReport(false)
    }
  },[saveReport])

  const generateReport = (reportData=currentReport) => {
    const companyId = auth?.loginResponse?.selectCompany?.[0]?.company_id;
    const params = {company: companyId, filterType: filter, name: reportData.name}
    if(reportData.id){
      params['id'] = reportData.id
    }
    if (filter === 'time_offs'){
      params['start_date'] = startDate
      params['end_date'] = endDate
    }

    saveReportCallBack(params)

  }

  const fetchTimeReport = (page = 1) => {
    const companyId = auth?.loginResponse?.selectCompany?.[0]?.company_id;
    let params = {company: companyId, filterType: filter }
    if (filter === 'time_offs'){
      if (new Date(startDate) > new Date(endDate)){
        pushNotification('start date should be less than end date', 'error')
        resetFields()
        setCanSave(false)
        return
      }
      params['start_date'] = startDate
      params['end_date'] = endDate
    }
    main_api.post(`${companyAPIsEndPoints.REPORTING_TIME}?page=${page}`, params)
    .then(({ data }) => {
      setTimeListing(data.results)
      setCount(data.count)
      if(data.count){
        setCanSave(true)
      }else{
        setCanSave(false)
      }
    })
    .catch((error) => {
      console.log(error);
    });
  }

  return (
    <>
      {(filter === 'time_offs') &&
        <>
          <div style={{marginBottom: '10px'}}>
            <label>Date Range: </label>
            <DateContainer><DatePicker value={dayjs(startDate)} allowClear={false} defaultValue={dayjs(new Date(today.getFullYear(), today.getMonth(), 1))} onChange={(e, dateString) => setStartDate(dateString)} placeholder="Start Date"/> - <DatePicker value={dayjs(endDate)} allowClear={false} defaultValue={dayjs(new Date())} onChange={(e, dateString) => setEndDate(dateString)} placeholder="End Date"/></DateContainer>
          </div>
          <AntdesignTablePagination
            columns={TimeReportListColumn({ })}
            data={timeListing}
            totalCount={count}
            loadPaginatedData={fetchTimeReport}
            allowRowSelection={false}
          />
        </>
      }
      {updateReport && <ReportUpdate setSaveReport={setSaveReport} generateReport={generateReport} setUpdateReport={setUpdateReport} currentReport={currentReport} setCurrentReport={setCurrentReport}/>}

    </>
  )
}

export default TimeReportListing;


const DateContainer = styled.span`
  margin-left: 5px;
`;

