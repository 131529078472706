import { ReactComponent as FilterIcon } from "./filterUserIcon.svg";
import { ReactComponent as FullScreenIcon } from "./expandZoomIcon.svg";
import { ReactComponent as SortPeopleIcon } from "./filter2.svg";
import { ReactComponent as DropDownIcon } from "./lessThanBlack.svg";
import { ReactComponent as DropDownDots } from "./horizontaldotsIcon.svg";
import { ReactComponent as LogoutIcon } from "./logouticon.svg";
import { ReactComponent as ScheduleClose } from "./closeSchedule.svg";
import { ReactComponent as BlockService } from "./blockService.svg";
import { ReactComponent as CloseIcon } from "./crossIcon.svg";

import { ReactComponent as TimeIcon } from "./time.svg";
import { ReactComponent as Status } from "./status.svg";
import { ReactComponent as BirthDate } from "./Birthdate.svg";
import { ReactComponent as Add } from "./add.svg";
import { ReactComponent as ClientYellow } from "./Vectorclient.svg";

//

const StaffIcon = ({ className, stroke, ...props }) => {
  return (
    <svg
      className={className}
      width="10"
      height="14"
      {...props}
      viewBox="0 0 10 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 13V11.6667C1 10.9594 1.28095 10.2811 1.78105 9.78105C2.28115 9.28095 2.95942 9 3.66667 9H6.33333C7.04058 9 7.71885 9.28095 8.21895 9.78105C8.71905 10.2811 9 10.9594 9 11.6667V13M2.33333 3.66667C2.33333 4.37391 2.61428 5.05219 3.11438 5.55229C3.61448 6.05238 4.29276 6.33333 5 6.33333C5.70724 6.33333 6.38552 6.05238 6.88562 5.55229C7.38572 5.05219 7.66667 4.37391 7.66667 3.66667C7.66667 2.95942 7.38572 2.28115 6.88562 1.78105C6.38552 1.28095 5.70724 1 5 1C4.29276 1 3.61448 1.28095 3.11438 1.78105C2.61428 2.28115 2.33333 2.95942 2.33333 3.66667Z"
        stroke="#FBCF34"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const SchedulerServiceIcon = ({ className, stroke = "#616161", ...props }) => {
  return (
    <svg
      width="20"
      height="20"
      className={className}
      {...props}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.33351 17.3143C7.60819 17.1498 6.91158 16.8776 6.26685 16.5068M11.6668 2.68579C13.3236 3.06418 14.8029 3.99387 15.8624 5.32263C16.9219 6.65139 17.4989 8.3005 17.4989 9.99996C17.4989 11.6994 16.9219 13.3485 15.8624 14.6773C14.8029 16.0061 13.3236 16.9357 11.6668 17.3141M3.81604 14.2442C3.36146 13.5837 3.01621 12.8544 2.79354 12.0842M2.60352 8.75006C2.73685 7.95839 2.99352 7.20839 3.35352 6.52089L3.49435 6.26672M5.75619 3.81579C6.5359 3.27904 7.41062 2.89555 8.33369 2.68579"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
const SchedulerWhenDateicon = ({ className, stroke = "#616161", ...props }) => {
  return (
    <svg
      width="20"
      height="20"
      className={className}
      {...props}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.333 2.5V5.83333M6.66634 2.5V5.83333M3.33301 9.16667H16.6663M9.16634 12.5H9.99967V15M3.33301 5.83333C3.33301 5.39131 3.5086 4.96738 3.82116 4.65482C4.13372 4.34226 4.55765 4.16667 4.99967 4.16667H14.9997C15.4417 4.16667 15.8656 4.34226 16.1782 4.65482C16.4907 4.96738 16.6663 5.39131 16.6663 5.83333V15.8333C16.6663 16.2754 16.4907 16.6993 16.1782 17.0118C15.8656 17.3244 15.4417 17.5 14.9997 17.5H4.99967C4.55765 17.5 4.13372 17.3244 3.82116 17.0118C3.5086 16.6993 3.33301 16.2754 3.33301 15.8333V5.83333Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const SchedulerAvatarClient = ({ className, stroke = "#616161", ...props }) => {
  return (
    <svg
      width="18"
      className={className}
      {...props}
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.13997 14.7075C4.34623 14.021 4.76828 13.4193 5.34351 12.9916C5.91874 12.564 6.61651 12.3331 7.33331 12.3333H10.6666C11.3844 12.3331 12.083 12.5645 12.6586 12.9932C13.2343 13.4218 13.6562 14.0248 13.8616 14.7125M1.5 9C1.5 9.98491 1.69399 10.9602 2.0709 11.8701C2.44781 12.7801 3.00026 13.6069 3.6967 14.3033C4.39314 14.9997 5.21993 15.5522 6.12987 15.9291C7.03982 16.306 8.01509 16.5 9 16.5C9.98491 16.5 10.9602 16.306 11.8701 15.9291C12.7801 15.5522 13.6069 14.9997 14.3033 14.3033C14.9997 13.6069 15.5522 12.7801 15.9291 11.8701C16.306 10.9602 16.5 9.98491 16.5 9C16.5 8.01509 16.306 7.03982 15.9291 6.12987C15.5522 5.21993 14.9997 4.39314 14.3033 3.6967C13.6069 3.00026 12.7801 2.44781 11.8701 2.0709C10.9602 1.69399 9.98491 1.5 9 1.5C8.01509 1.5 7.03982 1.69399 6.12987 2.0709C5.21993 2.44781 4.39314 3.00026 3.6967 3.6967C3.00026 4.39314 2.44781 5.21993 2.0709 6.12987C1.69399 7.03982 1.5 8.01509 1.5 9ZM6.5 7.33333C6.5 7.99637 6.76339 8.63226 7.23223 9.1011C7.70107 9.56994 8.33696 9.83333 9 9.83333C9.66304 9.83333 10.2989 9.56994 10.7678 9.1011C11.2366 8.63226 11.5 7.99637 11.5 7.33333C11.5 6.67029 11.2366 6.03441 10.7678 5.56557C10.2989 5.09673 9.66304 4.83333 9 4.83333C8.33696 4.83333 7.70107 5.09673 7.23223 5.56557C6.76339 6.03441 6.5 6.67029 6.5 7.33333Z"
        stroke={stroke}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
const SchedulerBulkUpdate = ({ className, stroke = "#616161", ...props }) => {
  return (
    <svg
      width="18"
      height="18"
      className={className}
      {...props}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.3332 3.16677L14.8332 5.66677M6.49977 4.89172C5.03793 5.10001 3.70947 5.85493 2.78237 7.00421C1.85527 8.15349 1.39851 9.6116 1.50424 11.0844C1.60997 12.5572 2.27032 13.9352 3.35208 14.9402C4.43383 15.9453 5.8565 16.5027 7.33311 16.5001C8.73594 16.5002 10.0918 15.9948 11.1523 15.0765C12.2128 14.1582 12.9069 12.8885 13.1073 11.5001M8.99984 11.5001L15.9873 4.48759C16.3155 4.15938 16.4999 3.71424 16.4999 3.25009C16.4999 2.78594 16.3155 2.34079 15.9873 2.01259C15.6591 1.68438 15.214 1.5 14.7498 1.5C14.2857 1.5 13.8405 1.68438 13.5123 2.01259L6.49984 9.00009V11.5001H8.99984Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const SchedulerMonthScheduledIcon = ({ className, stroke = "#616161", ...props }) => {
  return (
    <svg
      width="20"
      className={className}
      {...props}
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.33351 17.3143C7.60819 17.1498 6.91158 16.8776 6.26685 16.5068M11.6668 2.68579C13.3236 3.06418 14.8029 3.99387 15.8624 5.32263C16.9219 6.65139 17.4989 8.3005 17.4989 9.99996C17.4989 11.6994 16.9219 13.3485 15.8624 14.6773C14.8029 16.0061 13.3236 16.9357 11.6668 17.3141M3.81604 14.2442C3.36146 13.5837 3.01621 12.8544 2.79354 12.0842M2.60352 8.75006C2.73685 7.95839 2.99352 7.20839 3.35352 6.52089L3.49435 6.26672M5.75619 3.81579C6.5359 3.27904 7.41062 2.89555 8.33369 2.68579"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const SchedulerMonthUserIcon = ({ className, stroke = "#616161", ...props }) => {
  return (
    <svg
      width="20"
      className={className}
      {...props}
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99935 1.66667C10.8234 1.66667 11.629 1.91104 12.3142 2.36888C12.9994 2.82672 13.5335 3.47747 13.8488 4.23882C14.1642 5.00018 14.2467 5.83796 14.086 6.64621C13.9252 7.45447 13.5283 8.1969 12.9456 8.77962C12.3629 9.36234 11.6205 9.75917 10.8122 9.91994C10.004 10.0807 9.16619 9.9982 8.40483 9.68284C7.64348 9.36747 6.99273 8.83342 6.53489 8.14821C6.07705 7.46301 5.83268 6.65743 5.83268 5.83334L5.83685 5.65251C5.88345 4.5798 6.34235 3.56649 7.11787 2.82389C7.89338 2.08129 8.92563 1.66673 9.99935 1.66667Z"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.666 11.6667C12.7711 11.6667 13.8309 12.1057 14.6123 12.8871C15.3937 13.6685 15.8327 14.7283 15.8327 15.8333V16.6667C15.8327 17.1087 15.6571 17.5326 15.3445 17.8452C15.032 18.1577 14.608 18.3333 14.166 18.3333H5.83268C5.39065 18.3333 4.96673 18.1577 4.65417 17.8452C4.34161 17.5326 4.16602 17.1087 4.16602 16.6667V15.8333C4.16602 14.7283 4.605 13.6685 5.3864 12.8871C6.16781 12.1057 7.22761 11.6667 8.33268 11.6667H11.666Z"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
const RefreshDataIcon = ({ className = "", ...props }) => {
  return (
    <svg
      width="16"
      height="16"
      className={className}
      {...props}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.6252 7.16668C14.4145 5.55896 13.625 4.08316 12.4046 3.01553C11.1842 1.9479 9.6166 1.36164 7.99514 1.36648C6.37368 1.37133 4.80956 1.96696 3.59559 3.04187C2.38161 4.11678 1.601 5.59728 1.39989 7.20622C1.19877 8.81517 1.59093 10.4423 2.50296 11.7829C3.41498 13.1236 4.78435 14.0859 6.35473 14.4897C7.92511 14.8936 9.58883 14.7112 11.0345 13.9768C12.4801 13.2425 13.6085 12.0064 14.2085 10.5M14.6252 14.6667V10.5H10.4585"
        stroke="#616161"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const DeleteIcon = ({ className = "", ...props }) => {
  return (
    <svg
      width="14"
      height="16"
      className={className}
      {...props}
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 4.25H13M5.5 7.25V11.75M8.5 7.25V11.75M1.75 4.25L2.5 13.25C2.5 13.6478 2.65804 14.0294 2.93934 14.3107C3.22064 14.592 3.60218 14.75 4 14.75H10C10.3978 14.75 10.7794 14.592 11.0607 14.3107C11.342 14.0294 11.5 13.6478 11.5 13.25L12.25 4.25M4.75 4.25V2C4.75 1.80109 4.82902 1.61032 4.96967 1.46967C5.11032 1.32902 5.30109 1.25 5.5 1.25H8.5C8.69891 1.25 8.88968 1.32902 9.03033 1.46967C9.17098 1.61032 9.25 1.80109 9.25 2V4.25"
        stroke="red"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const PerformActionIcon = ({ className = "", ...props }) => {
  return (
    <svg
      className={className}
      {...props}
      width="119"
      height="120"
      viewBox="0 0 119 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_607_24531)">
        <path
          d="M27.3565 119.839C29.5095 119.839 31.2549 119.185 31.2549 118.379C31.2549 117.572 29.5095 116.918 27.3565 116.918C25.2034 116.918 23.458 117.572 23.458 118.379C23.458 119.185 25.2034 119.839 27.3565 119.839Z"
          fill="#023047"
        />
        <path
          d="M36.7129 119.644C38.866 119.644 40.6113 118.99 40.6113 118.184C40.6113 117.377 38.866 116.724 36.7129 116.724C34.5598 116.724 32.8145 117.377 32.8145 118.184C32.8145 118.99 34.5598 119.644 36.7129 119.644Z"
          fill="#023047"
        />
        <path
          d="M49.1336 95.1001C52.8176 92.9788 55.2635 90.3227 54.5968 89.1676C53.9301 88.0124 50.4032 88.7956 46.7192 90.9169C43.0353 93.0382 40.5893 95.6943 41.256 96.8495C41.9227 98.0046 45.4496 97.2214 49.1336 95.1001Z"
          fill="#023047"
        />
        <path
          d="M45.6752 100.302C47.0375 91.9165 41.3366 84.016 32.9419 82.6553C24.5471 81.2945 16.6375 86.9889 15.2752 95.3741C13.9129 103.759 19.6139 111.66 28.0086 113.02C36.4033 114.381 44.3129 108.687 45.6752 100.302Z"
          fill="#023047"
        />
        <path d="M28.1361 109.909H23.458V118.281H28.1361V109.909Z" fill="#023047" />
        <path d="M37.4926 109.909H32.8145V118.281H37.4926V109.909Z" fill="#023047" />
        <path
          d="M30.8654 99.2007C33.7721 99.2007 36.1283 96.8472 36.1283 93.9439C36.1283 91.0406 33.7721 88.687 30.8654 88.687C27.9588 88.687 25.6025 91.0406 25.6025 93.9439C25.6025 96.8472 27.9588 99.2007 30.8654 99.2007Z"
          fill="white"
        />
        <path
          d="M30.8656 95.6965C31.8345 95.6965 32.6199 94.912 32.6199 93.9442C32.6199 92.9764 31.8345 92.1919 30.8656 92.1919C29.8968 92.1919 29.1113 92.9764 29.1113 93.9442C29.1113 94.912 29.8968 95.6965 30.8656 95.6965Z"
          fill="#023047"
        />
        <path
          d="M15.5382 83.5337C14.2948 77.9717 18.2694 72.3514 24.4157 70.9806C30.562 69.6097 36.5526 73.0074 37.796 78.5694C39.0393 84.1315 34.966 86.1829 28.8196 87.5537C22.6733 88.9246 16.7816 89.0958 15.5382 83.5337Z"
          fill="#EEEEEE"
        />
        <path
          d="M20.0877 93.9968C20.6598 92.7921 18.0089 90.3399 14.1669 88.5198C10.3248 86.6996 6.74651 86.2008 6.17446 87.4055C5.60241 88.6103 8.25325 91.0624 12.0953 92.8826C15.9373 94.7027 19.5157 95.2016 20.0877 93.9968Z"
          fill="#023047"
        />
        <path
          d="M22.9111 103.387C22.9111 104.892 26.7917 107.865 31.0978 107.865C35.4039 107.865 39.4414 103.627 39.4414 102.121C39.4414 100.616 35.4039 102.413 31.0978 102.413C26.7918 102.413 22.9111 101.882 22.9111 103.387Z"
          fill="white"
        />
        <path
          d="M84.1699 119.839C86.323 119.839 88.0684 119.185 88.0684 118.379C88.0684 117.572 86.323 116.918 84.1699 116.918C82.0169 116.918 80.2715 117.572 80.2715 118.379C80.2715 119.185 82.0169 119.839 84.1699 119.839Z"
          fill="#023047"
        />
        <path
          d="M74.8135 119.644C76.9665 119.644 78.7119 118.99 78.7119 118.184C78.7119 117.377 76.9665 116.724 74.8135 116.724C72.6604 116.724 70.915 117.377 70.915 118.184C70.915 118.99 72.6604 119.644 74.8135 119.644Z"
          fill="#023047"
        />
        <path
          d="M70.2706 96.8495C70.9374 95.6944 68.4914 93.0382 64.8074 90.917C61.1234 88.7957 57.5965 88.0125 56.9298 89.1676C56.2631 90.3228 58.7091 92.9789 62.3931 95.1002C66.077 97.2215 69.6039 98.0047 70.2706 96.8495Z"
          fill="#023047"
        />
        <path
          d="M81.0512 113.219C89.5557 113.219 96.45 106.333 96.45 97.8377C96.45 89.3429 89.5557 82.4565 81.0512 82.4565C72.5466 82.4565 65.6523 89.3429 65.6523 97.8377C65.6523 106.333 72.5466 113.219 81.0512 113.219Z"
          fill="#023047"
        />
        <path d="M88.0687 109.909H83.3906V118.281H88.0687V109.909Z" fill="#023047" />
        <path d="M78.7123 109.909H74.0342V118.281H78.7123V109.909Z" fill="#023047" />
        <path
          d="M77.4436 99.2007C80.3502 99.2007 82.7065 96.8472 82.7065 93.9439C82.7065 91.0406 80.3502 88.687 77.4436 88.687C74.5369 88.687 72.1807 91.0406 72.1807 93.9439C72.1807 96.8472 74.5369 99.2007 77.4436 99.2007Z"
          fill="white"
        />
        <path
          d="M76.175 94.4641C76.8624 93.7781 76.8668 92.6694 76.1849 91.9876C75.503 91.3059 74.3929 91.3093 73.7055 91.9952C73.0182 92.6812 73.0138 93.79 73.6957 94.4717C74.3776 95.1535 75.4877 95.1501 76.175 94.4641Z"
          fill="#023047"
        />
        <path
          d="M82.7067 87.5537C76.5604 86.1829 72.487 84.1315 73.7304 78.5694C74.9738 73.0074 80.9643 69.6097 87.1106 70.9806C93.2569 72.3514 97.2315 77.9716 95.9882 83.5337C94.7448 89.0958 88.853 88.9246 82.7067 87.5537Z"
          fill="#FBCF34"
        />
        <path
          d="M97.1543 109.562C98.4444 109.22 98.6055 105.615 97.5139 101.51C96.4223 97.4047 94.4915 94.3544 93.2013 94.6966C91.9111 95.0389 91.7501 98.6442 92.8417 102.749C93.9333 106.854 95.8641 109.905 97.1543 109.562Z"
          fill="#023047"
        />
        <path
          d="M77.5627 102.413C74.7356 102.413 72.085 100.616 72.085 102.121C72.085 103.627 74.7356 107.865 77.5627 107.865C80.3898 107.865 82.9375 104.892 82.9375 103.387C82.9375 101.882 80.3897 102.413 77.5627 102.413Z"
          fill="white"
        />
        <path
          d="M57.6621 72.3355H56.2946C55.0696 72.3342 53.8951 71.8475 53.0289 70.9822C52.1626 70.117 51.6754 68.9439 51.674 67.7202V66.5735C51.674 63.7331 52.2128 61.3548 53.2755 59.5047C54.5633 57.3527 56.0658 55.3363 57.7599 53.4863C58.8846 52.3566 59.9051 51.128 60.809 49.8153C61.4576 48.7955 61.7924 47.6081 61.7721 46.4001C61.7721 43.5508 59.9136 42.1657 56.0903 42.1657C53.9695 42.1896 51.8621 42.5056 49.8278 43.1046C49.0432 43.3283 48.2173 43.367 47.4152 43.2178C46.6131 43.0686 45.8566 42.7354 45.2053 42.2446C44.554 41.7538 44.0256 41.1186 43.6617 40.3892C43.2979 39.6597 43.1085 38.8559 43.1085 38.0409V35.7195C43.104 34.7202 43.3898 33.741 43.9313 32.9006C44.4728 32.0603 45.2467 31.3949 46.1592 30.9851C49.7521 29.4535 53.6269 28.6921 57.5329 28.7502C61.6249 28.7502 65.3188 29.4367 68.5123 30.7905C71.7116 32.1474 74.2392 34.0607 76.0249 36.4776C77.8052 38.8773 78.7515 41.7924 78.7195 44.779C78.7195 47.9862 77.8429 50.7795 76.1141 53.0814C74.4042 55.3599 71.953 57.8543 68.8286 60.4958C67.1824 61.8632 65.6225 63.3311 64.1578 64.891C63.2117 65.9089 62.543 67.1523 62.2158 68.5022C62.0335 69.576 61.476 70.5504 60.6422 71.2523C59.8084 71.9542 58.7525 72.338 57.6621 72.3355Z"
          fill="#FBCF34"
        />
        <path
          d="M56.9017 94.0396H56.7214C54.4511 94.0382 52.2742 93.1363 50.6694 91.5323C49.0646 89.9282 48.1631 87.7533 48.1631 85.4856C48.1631 83.2179 49.0646 81.043 50.6694 79.439C52.2742 77.835 54.4511 76.9331 56.7214 76.9316H56.9017C58.0268 76.9309 59.141 77.1516 60.1806 77.5812C61.2203 78.0107 62.165 78.6407 62.9608 79.4351C63.7566 80.2295 64.3879 81.1727 64.8186 82.2109C65.2493 83.2491 65.4711 84.3618 65.4711 85.4856C65.4711 86.6094 65.2493 87.7222 64.8186 88.7604C64.3879 89.7986 63.7566 90.7418 62.9608 91.5362C62.165 92.3306 61.2203 92.9605 60.1806 93.3901C59.141 93.8196 58.0268 94.0403 56.9017 94.0396Z"
          fill="#FBCF34"
        />
        <path
          d="M112.556 120H1.35755C1.26272 120 1.17178 119.962 1.10473 119.895C1.03768 119.828 1 119.738 1 119.643C1 119.548 1.03768 119.457 1.10473 119.39C1.17178 119.323 1.26272 119.286 1.35755 119.286H112.556C112.651 119.286 112.742 119.323 112.809 119.39C112.876 119.457 112.914 119.548 112.914 119.643C112.914 119.738 112.876 119.828 112.809 119.895C112.742 119.962 112.651 120 112.556 120Z"
          fill="#BDBDBD"
        />
        <path
          d="M77.9318 17.5002H68.6354C68.1614 17.4997 67.707 17.3114 67.3719 16.9766C67.0367 16.6418 66.8482 16.1879 66.8477 15.7145V6.42878C66.8482 5.95535 67.0367 5.50146 67.3719 5.16669C67.707 4.83192 68.1614 4.64361 68.6354 4.64307H77.9318C78.4058 4.64361 78.8602 4.83192 79.1953 5.16669C79.5305 5.50146 79.719 5.95535 79.7195 6.42878V15.7145C79.719 16.1879 79.5305 16.6418 79.1953 16.9766C78.8602 17.3114 78.4058 17.4997 77.9318 17.5002ZM68.6354 5.35735C68.351 5.35766 68.0784 5.47064 67.8773 5.67151C67.6762 5.87237 67.5631 6.14472 67.5628 6.42878V15.7145C67.5631 15.9986 67.6762 16.2709 67.8773 16.4718C68.0784 16.6726 68.351 16.7856 68.6354 16.7859H77.9318C78.2162 16.7856 78.4888 16.6726 78.6899 16.4718C78.891 16.2709 79.0041 15.9986 79.0044 15.7145V6.42878C79.0041 6.14472 78.891 5.87237 78.6899 5.67151C78.4888 5.47064 78.2162 5.35766 77.9318 5.35735H68.6354Z"
          fill="#EEEEEE"
        />
        <path
          d="M72.2111 12.8571H62.9147C62.4407 12.8566 61.9863 12.6683 61.6512 12.3335C61.316 11.9987 61.1275 11.5449 61.127 11.0714V1.78571C61.1275 1.31228 61.316 0.858396 61.6512 0.523627C61.9863 0.188858 62.4407 0.000541626 62.9147 0H72.2111C72.6851 0.000541626 73.1395 0.188858 73.4746 0.523627C73.8098 0.858396 73.9983 1.31228 73.9989 1.78571V11.0714C73.9983 11.5449 73.8098 11.9987 73.4746 12.3335C73.1395 12.6683 72.6851 12.8566 72.2111 12.8571ZM62.9147 0.714286C62.6303 0.714596 62.3577 0.827578 62.1566 1.02844C61.9555 1.22931 61.8424 1.50165 61.8421 1.78571V11.0714C61.8424 11.3555 61.9555 11.6278 62.1566 11.8287C62.3577 12.0296 62.6303 12.1425 62.9147 12.1429H72.2111C72.4955 12.1425 72.7681 12.0296 72.9692 11.8287C73.1703 11.6278 73.2834 11.3555 73.2838 11.0714V1.78571C73.2834 1.50165 73.1703 1.22931 72.9692 1.02844C72.7681 0.827578 72.4955 0.714596 72.2111 0.714286H62.9147Z"
          fill="#EEEEEE"
        />
        <path
          d="M115.853 67.7276C117.13 67.7276 118.166 66.6936 118.166 65.418C118.166 64.1424 117.13 63.1084 115.853 63.1084C114.576 63.1084 113.541 64.1424 113.541 65.418C113.541 66.6936 114.576 67.7276 115.853 67.7276Z"
          fill="#EEEEEE"
        />
        <path
          d="M22.8894 59.5132C24.1664 59.5132 25.2017 58.4792 25.2017 57.2036C25.2017 55.9281 24.1664 54.894 22.8894 54.894C21.6124 54.894 20.5771 55.9281 20.5771 57.2036C20.5771 58.4792 21.6124 59.5132 22.8894 59.5132Z"
          fill="#EEEEEE"
        />
      </g>
      <defs>
        <clipPath id="clip0_607_24531">
          <rect width="118" height="120" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};

const ClientIconSvg = ({ className = "", stroke = "#424242", ...props }) => {
  return (
    <svg
      className={className}
      {...props}
      width="13"
      height="15"
      viewBox="0 0 10 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 13V11.6667C1 10.9594 1.28095 10.2811 1.78105 9.78105C2.28115 9.28095 2.95942 9 3.66667 9H6.33333C7.04058 9 7.71885 9.28095 8.21895 9.78105C8.71905 10.2811 9 10.9594 9 11.6667V13M2.33333 3.66667C2.33333 4.37391 2.61428 5.05219 3.11438 5.55229C3.61448 6.05238 4.29276 6.33333 5 6.33333C5.70724 6.33333 6.38552 6.05238 6.88562 5.55229C7.38572 5.05219 7.66667 4.37391 7.66667 3.66667C7.66667 2.95942 7.38572 2.28115 6.88562 1.78105C6.38552 1.28095 5.70724 1 5 1C4.29276 1 3.61448 1.28095 3.11438 1.78105C2.61428 2.28115 2.33333 2.95942 2.33333 3.66667Z"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const InvoiceIcon = ({ className = "", stroke = "#FBCF34", ...props }) => {
  return (
    <svg
      className={className}
      {...props}
      width="14"
      height="18"
      viewBox="0 0 14 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.49935 3.16667H2.83268C2.39065 3.16667 1.96673 3.34226 1.65417 3.65482C1.34161 3.96738 1.16602 4.39131 1.16602 4.83333V14.8333C1.16602 15.2754 1.34161 15.6993 1.65417 16.0118C1.96673 16.3244 2.39065 16.5 2.83268 16.5H11.166C11.608 16.5 12.032 16.3244 12.3445 16.0118C12.6571 15.6993 12.8327 15.2754 12.8327 14.8333V4.83333C12.8327 4.39131 12.6571 3.96738 12.3445 3.65482C12.032 3.34226 11.608 3.16667 11.166 3.16667H9.49935M4.49935 3.16667C4.49935 2.72464 4.67494 2.30072 4.9875 1.98816C5.30007 1.67559 5.72399 1.5 6.16602 1.5H7.83268C8.27471 1.5 8.69863 1.67559 9.01119 1.98816C9.32375 2.30072 9.49935 2.72464 9.49935 3.16667M4.49935 3.16667C4.49935 3.60869 4.67494 4.03262 4.9875 4.34518C5.30007 4.65774 5.72399 4.83333 6.16602 4.83333H7.83268C8.27471 4.83333 8.69863 4.65774 9.01119 4.34518C9.32375 4.03262 9.49935 3.60869 9.49935 3.16667M4.49935 13.1667V9M6.99935 13.1667V12.3333M9.49935 13.1667V10.6667"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const PlusIcon = ({ className = "", stroke = "rgb(33, 33, 33)", ...props }) => {
  return (
    <svg width="12" className={className} height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.00065 1.33334V10.6667M1.33398 6H10.6673"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
const TemplateFoldPageIcon = ({ className = "", stroke = "#B45309", ...props }) => {
  return (
    <svg
      width="14"
      height="14"
      className={className}
      {...props}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.5835 11.6668L11.6668 7.5835M7.5835 11.6668V8.16683C7.5835 8.01212 7.64495 7.86375 7.75435 7.75435C7.86375 7.64495 8.01212 7.5835 8.16683 7.5835H11.6668M7.5835 11.6668H3.50016C3.19074 11.6668 2.894 11.5439 2.6752 11.3251C2.45641 11.1063 2.3335 10.8096 2.3335 10.5002V3.50016C2.3335 3.19074 2.45641 2.894 2.6752 2.6752C2.894 2.45641 3.19074 2.3335 3.50016 2.3335H10.5002C10.8096 2.3335 11.1063 2.45641 11.3251 2.6752C11.5439 2.894 11.6668 3.19074 11.6668 3.50016V7.5835"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const PetsPawIcon = ({ className = "", stroke = "#424242", ...props }) => {
  return (
    <svg
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <path
        d="M8.79988 7.00033C8.06654 5.66699 7.83921 5.33366 6.99988 5.33366C6.16054 5.33366 5.84254 5.83699 5.10921 7.16499C4.48121 8.30032 3.21188 8.39499 2.89521 9.35899C2.83054 9.53566 2.79854 9.81032 2.79988 10.0003C2.79988 10.7843 3.32454 11.3337 3.99988 11.3337C4.83921 11.3337 5.99988 10.667 6.99988 10.667C7.99988 10.667 9.16054 11.3337 9.99988 11.3337C10.6752 11.3337 11.1999 10.785 11.1999 10.0003C11.1999 9.81032 11.1672 9.53566 11.1025 9.35899C10.7859 8.39166 9.42788 8.13566 8.79988 7.00033Z"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.4585 3.38833C12.3729 3.35212 12.2808 3.33353 12.1879 3.33366H12.1779C11.6879 3.34166 11.1379 3.83366 10.8492 4.57766C10.5032 5.46766 10.6625 6.37766 11.2079 6.61233C11.2939 6.64899 11.3859 6.66699 11.4785 6.66699C11.9712 6.66699 12.5285 6.17233 12.8192 5.42299C13.1632 4.53299 13.0005 3.62299 12.4585 3.38833Z"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.31588 4.00033C5.35254 4.00033 5.38854 4.00033 5.42454 3.99299C6.05388 3.90766 6.44654 3.09566 6.30454 2.17833C6.16921 1.31366 5.60654 0.666992 5.01654 0.666992C4.97987 0.666992 4.94388 0.666992 4.90788 0.674326C4.27854 0.759659 3.88588 1.57166 4.02788 2.48899C4.16388 3.35099 4.72654 4.00033 5.31654 4.00033H5.31588Z"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.97055 2.48899C10.1132 1.57166 9.72055 0.759661 9.09055 0.674327C9.05476 0.669372 9.01867 0.666921 8.98255 0.666994C8.39255 0.666994 7.83055 1.31366 7.69588 2.17833C7.55321 3.09566 7.94588 3.90766 8.57588 3.99299C8.61188 3.99766 8.64788 4.00033 8.68388 4.00033C9.27388 4.00033 9.83721 3.35099 9.97055 2.48899Z"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.79322 6.61233C3.33722 6.37766 3.49588 5.46633 3.15055 4.57766C2.85988 3.82833 2.30322 3.33366 1.81122 3.33366C1.71788 3.33366 1.62655 3.35166 1.53988 3.38833C0.995882 3.62299 0.837216 4.53433 1.18255 5.42299C1.47322 6.17233 2.02988 6.66699 2.52188 6.66699C2.61522 6.66699 2.70655 6.64899 2.79322 6.61233Z"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const TemplateTimeIcon = ({ className = "", stroke = "#B45309", ...props }) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      className={className}
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 3.08333V6L7.75 7.75M0.75 6C0.75 6.68944 0.885795 7.37213 1.14963 8.00909C1.41347 8.64605 1.80018 9.2248 2.28769 9.71231C2.7752 10.1998 3.35395 10.5865 3.99091 10.8504C4.62787 11.1142 5.31056 11.25 6 11.25C6.68944 11.25 7.37213 11.1142 8.00909 10.8504C8.64605 10.5865 9.2248 10.1998 9.71231 9.71231C10.1998 9.2248 10.5865 8.64605 10.8504 8.00909C11.1142 7.37213 11.25 6.68944 11.25 6C11.25 4.60761 10.6969 3.27226 9.71231 2.28769C8.72774 1.30312 7.39239 0.75 6 0.75C4.60761 0.75 3.27226 1.30312 2.28769 2.28769C1.30312 3.27226 0.75 4.60761 0.75 6Z"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

//
export {
  DropDownDots,
  FullScreenIcon,
  SortPeopleIcon,
  DropDownIcon,
  FilterIcon,
  LogoutIcon,
  ScheduleClose,
  BlockService,
  StaffIcon,
  SchedulerServiceIcon,
  SchedulerWhenDateicon,
  SchedulerAvatarClient,
  CloseIcon,
  TimeIcon,
  Status,
  SchedulerBulkUpdate,
  SchedulerMonthScheduledIcon,
  SchedulerMonthUserIcon,
  RefreshDataIcon,
  PerformActionIcon,
  BirthDate,
  DeleteIcon,
  Add,
  ClientIconSvg,
  InvoiceIcon,
  ClientYellow,
  PlusIcon,
  TemplateFoldPageIcon,
  PetsPawIcon,
  TemplateTimeIcon,
};
