import moment from "moment";

import React, { Suspense, useEffect, useState } from "react";

import "./calendar.css";

import HeadingComponent from "../../../components/Heading";
import AntDesignNavTabs from "../../../components/antDesignNaviTabs/AntDesignNavTabs";
import { schedulerNavigationTabs } from "../../../constants/CompanyNavTabs/SchedulerNavTabs";
// import "react-big-calendar/lib/addons/dragAndDrop/styles" // if using DnD
import Schedulerv2Events from "./SchedulerEvents";
import ScheduledTemplates from "./scheduledTemplates/ScheduledTemplates";
import ScheduledRequest from "./pendingRequest/ScheduledRequests";
import ScheduledTimeOff from "./TimeOffs";
import { useSelector } from "react-redux";
import { checkPermission } from "../../../util/commonUtil";
import { main_api } from "../../../api/axios_helper";
import { companyAPIsEndPoints } from "../../../constants/ApiEndPoints";
import { pushNotification } from "../../../util/Notification";
import { ConfigProvider, Tabs } from "antd";

//
const Schedulerv2 = ({
  petList,
  serviceStateResponseData,
  loadPetList,
  staffState,
  getManagementClients,
  clientManagementState,
  fetchAllServices,
  fetchAllStaff,
  fetchUnavaialbleSlots,
  fetchAllSchedulerService,
  schedulerState,
  schedulerLoader,
  fetchDashboardCompany,
  totalServiceDetails,
  permissionState,
  fetchSchedulerTemplates,
  schedulerTemplateState,
  unavaliableSlotsState,
  getManagementkeys,
  keyManagementList,
  loginState,
  fetchCompDetails,
  fetchCompStripeDetails,
}) => {
  const permissionManagement = useSelector((state) => state.permissionManagement);
  const [calenderFiltersState, setCalenderFiltersState] = useState({
    client: "",
    staff: "",
    status: "status=scheduled&status=in_progress&status=completed",
  });
  const [blockTimeDropDown, setBlockTimeDropDownList] = useState([]);
  const [timeBlockCompList, setTimeBlockCompList] = useState([]);
  let clientFilterQueryString = "";
  // `staff=${calenderFiltersState.staff}&client=${calenderFiltersState.client}`;

  if (calenderFiltersState.staff && !calenderFiltersState.client) {
    clientFilterQueryString = `staff=${calenderFiltersState.staff}`;
  } else if (calenderFiltersState.staff && calenderFiltersState.client) {
    clientFilterQueryString = `staff=${calenderFiltersState.staff}&client=${calenderFiltersState.client}`;
  } else if (calenderFiltersState.client && !calenderFiltersState.staff) {
    clientFilterQueryString = `client=${calenderFiltersState.client}`;
  } else {
    clientFilterQueryString = "";
  }

  const [calenderTypeDropDown, setCalenderTypeDropDown] = useState("Day-Horizontal");
  const [dayRange, setDayRange] = useState({
    start: moment().startOf("day").toDate(),
    end: moment().startOf("day").toDate(),
  });

  const [dateRangeWeek, setDateRangeWeek] = useState({
    start: moment().startOf("month").add(1, "day").startOf("week").toDate(),
    end: moment().startOf("month").add(1, "day").startOf("week").add(6, "days").endOf("day").toDate(),
  });

  const [dateRange, setDateRange] = useState({
    start: moment().startOf("month").toDate(),
    end: moment().endOf("month").toDate(),
  });

  const [activeTab, setActiveTab] = useState(1);

  // USE EFFECTS ====
  useEffect(() => {
    const handleFetchTimeblockList = async () => {
      try {
        const { data } = await main_api.get(
          companyAPIsEndPoints.FETCH_TIME_BLOCKS_PER_COMPANY(loginState.selectCompany?.[0]?.company_id),
        );

        const modifiyList = data?.result?.map((items) => {
          return {
            label: items.name,
            value: items.id,
          };
        });
        setTimeBlockCompList(data?.result || []);
        setBlockTimeDropDownList(modifiyList);
      } catch (error) {
        pushNotification("Something went wrong!", "error");
        // console.log("Error:", error);
      }
    };

    handleFetchTimeblockList();
  }, []);

  useEffect(() => {
    if (checkPermission(permissionState, "view_pets")) {
      loadPetList();
    }
    if (checkPermission(permissionState, "view_clients")) {
      getManagementClients();
    }
    if (checkPermission(permissionState, "view_services")) {
      fetchAllServices();
    }
    if (checkPermission(permissionState, "view_staffs")) {
      fetchAllStaff();
    }
    fetchDashboardCompany();
    fetchCompDetails();
    fetchCompStripeDetails();
    if (checkPermission(permissionState, "view_templates")) {
      fetchSchedulerTemplates();
    }
  }, [permissionState]);

  useEffect(() => {
    handleFetchTabWiseData();
  }, [calenderTypeDropDown, dayRange, dateRangeWeek, dateRange]);

  useEffect(() => {
    // Calculate the start of the current week (Monday)
    const startDateOfWeek = moment().startOf("week");

    // Calculate the end of the current week (Sunday)
    const endDateOfWeek = moment(startDateOfWeek).add(6, "days");

    // Set the state with the calculated start and end dates of the current week
    setDateRangeWeek({
      start: startDateOfWeek.toDate(),
      end: endDateOfWeek.toDate(),
    });
  }, [calenderTypeDropDown, setDateRangeWeek]);

  // DATE FORMATES

  const formattedStartDateDay = moment(dayRange.start).format("YYYY-MM-DD");
  const formattedEndDateDay = moment(dayRange.end).format("YYYY-MM-DD");
  const formattedMonthStartDate = moment(dateRange.start).startOf("month").format("YYYY-MM-DD");
  const formattedMonthEndDate = moment(dateRange.end).endOf("month").format("YYYY-MM-DD");
  const formattedWeekStartDate = moment(dateRangeWeek.start).startOf("week").format("YYYY-MM-DD");
  const formattedWeekEndDate = moment(dateRangeWeek.end).endOf("week").format("YYYY-MM-DD");
  //

  const handleApplyFilters = () => {
    // fetchAllSchedulerService(clientFilterQueryString, calenderFiltersState.status, formattedStartDateDay, formattedEndDateDay);
    handleFetchTabWiseData();
  };
  let filterEmptytimeslotEvents = { result: schedulerState?.result?.filter((items) => items.start_time !== null) || [] };
  //  CAUTION !
  // THIS IS ALL VARIOUS MODULE STATE LIST NAME IS NOT ACCORDING TO IT
  // this state object is being used in template, service req, time off req
  // this also includes functions to call apis

  const clientPetServiceDropdowns = {
    petListState: petList,
    serviceState: serviceStateResponseData?.serviceList,
    clientState: clientManagementState?.clientList,
    clientAllState: clientManagementState,
    staffState: staffState.staffAllDetails?.staffList,
    allServices: serviceStateResponseData,
    unavaliableSlots: unavaliableSlotsState,
    schedulerState: filterEmptytimeslotEvents,
    schedulerLoader: schedulerLoader,
    blockTimeDropDown: blockTimeDropDown,
    timeBlockCompList: timeBlockCompList,
    fetchAllSchedulerService: () => {
      handleFetchTabWiseData();
    },
    allStaffState: staffState.staffAllDetails,
    dayChangeState: { dayRange, setDayRange },
    weekChangeState: { dateRangeWeek, setDateRangeWeek },
    monthChangeState: { dateRange, setDateRange },
    calenderTypeDropDownState: { calenderTypeDropDown, setCalenderTypeDropDown },
    monthDeleteEventFunction: () => {
      fetchAllSchedulerService(
        clientFilterQueryString,
        calenderFiltersState.status,
        formattedMonthStartDate,
        formattedMonthEndDate,
      );
    },
    dayFetchAllServices: () => {
      fetchAllSchedulerService(clientFilterQueryString, calenderFiltersState.status, formattedStartDateDay, formattedEndDateDay);
    },

    WeekFetchAllServices: () => {
      fetchAllSchedulerService(
        clientFilterQueryString,
        calenderFiltersState.status,
        formattedWeekStartDate,
        formattedWeekEndDate,
      );
    },
    totalServiceDetails,
    keyManagementList,
    topFiltersState: {
      calenderFiltersState,
      setCalenderFiltersState,
      clientState: clientManagementState?.clientList,
      staffState: staffState.staffAllDetails?.staffList,
      handleApplyFilters,
      btnLoader: schedulerLoader,
    },
    loginState,
    fetchUnavaialbleSlots,
    fetchDashboardCompany,
    fetchAllStaff,
  };

  const handleFetchTabWiseData = () => {
    switch (calenderTypeDropDown) {
      case "Day-Vertical":
      case "Day-Horizontal":
        if (checkPermission(permissionManagement.permissions, "view_service_requests")) {
          fetchAllSchedulerService(
            clientFilterQueryString,
            calenderFiltersState.status,
            formattedStartDateDay,
            formattedEndDateDay,
          );
        }
        break;
      case "Week":
        const formattedWeekStartDate = moment(dateRangeWeek.start).startOf("week").format("YYYY-MM-DD");
        const formattedWeekEndDate = moment(dateRangeWeek.end).endOf("week").format("YYYY-MM-DD");
        if (checkPermission(permissionManagement.permissions, "view_service_requests")) {
          fetchAllSchedulerService(
            clientFilterQueryString,
            calenderFiltersState.status,
            formattedWeekStartDate,
            formattedWeekEndDate,
          );
        }
        break;
      case "Month":
        if (checkPermission(permissionManagement.permissions, "view_service_requests")) {
          fetchAllSchedulerService(
            clientFilterQueryString,
            calenderFiltersState.status,
            formattedMonthStartDate,
            formattedMonthEndDate,
          );
        }
        break;
      default:
        break;
    }
  };

  const navTabs = schedulerNavigationTabs();
  const handleChangeTabs = (tabs) => {
    setActiveTab(tabs);
  };

  const renderComponents = {
    1: <Schedulerv2Events clientPetServiceDropdowns={{ ...clientPetServiceDropdowns }} />,
    2: (
      <ScheduledTemplates
        requiredDropdowns={{
          staff: clientPetServiceDropdowns.staffState,
          client: clientPetServiceDropdowns.clientState,
          serviceList: clientPetServiceDropdowns.serviceState,
          petList: clientPetServiceDropdowns.petListState,
          clientAllState: clientManagementState,
          fetchSchedulerTemplates: fetchSchedulerTemplates,
          schedulerTemplateState,
          keyManagementList,
          loginState: loginState.selectCompany[0] || {},
          allServices: serviceStateResponseData,
          blockTimeDropDown: blockTimeDropDown,
          timeBlockCompList: timeBlockCompList,
          setActiveTab: () => {
            setCalenderTypeDropDown("Month");
          },
        }}
      />
    ),
    3: <ScheduledRequest fetchAllSchedulerService={() => handleFetchTabWiseData} schedulerState={schedulerState} />,
    4: <ScheduledTimeOff />,
  };

  return (
    <div className="mt-4">
      <HeadingComponent text={"Schedule"} fontWeight={700} fontSize="20px" />
      <AntDesignNavTabs navTabs={navTabs} onChange={handleChangeTabs} />

      <div className="mt-3">
        <Suspense fallback={<div>Loading...</div>}>{renderComponents[activeTab]}</Suspense>
      </div>
    </div>
  );
};

export default Schedulerv2;
