import React, { useEffect, useState, useRef } from "react"
import { useSelector } from "../../../redux/store";
import { companyAPIsEndPoints } from "../../../constants/ApiEndPoints";
import dayjs from "dayjs";
import { pushNotification } from "../../../util/Notification";
import { main_api } from "../../../api/axios_helper";
import { AntdesignTablePagination } from "../../../components/antDesignTable/AntdesignTablePagination";
import { DatePicker } from "antd";
import { RevenueReportListColumn, ServiceRevenueReportListColumn } from "../../../constants/ReportingTableHeader/ReportingHeader";
import ReportUpdate from "./ReportUpdate";
import styled from "styled-components";
import RevenueBarChart from "./RevenueBarChart";
import { PaginatedSelect } from "../../../components/PaginationSelect";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import TotalAmountBarChart from "./TotalAmountBarChart";


function FinancialReportListing({filter, saveReport, setSaveReport, currentReport, setCurrentReport, saveReportCallBack}) {
  const { auth } = useSelector((state) => state);
  const [timeListing, setTimeListing] = useState([])
  const [count, setCount] = useState(0)
  const [canSave, setCanSave] = useState(false)
  const [updateReport, setUpdateReport] = useState(false)
  const today = new Date()
  const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
  const [endDate, setEndDate] = useState(dayjs(new Date()).format('YYYY-MM-DD'))
  const [startDate, setStartDate] = useState(dayjs(firstDay).format('YYYY-MM-DD'))
  const [chartData, setChartData] = useState(null)
  const [selectClient, setSelectClient] = useState(null)
  const componentRef = useRef();

  const resetFields = () =>{
    setTimeListing([])
    setCount(0)
    setStartDate(dayjs(firstDay).format('YYYY-MM-DD'))
    setEndDate(dayjs(new Date()).format('YYYY-MM-DD'))
  }

  useEffect(()=>{
    if (filter === 'revenue_over_time' || filter === 'service_revenue'){
        fetchTimeReport()
    }
    else if(filter === 'revenue_per_month' || filter === 'total_amount'){
        fetchRevenueGraphData()
    }
  },[startDate, endDate, filter, selectClient])

  useEffect(()=>{
    resetFields()
  },[filter])

  useEffect(()=>{
    if(saveReport){
      if(canSave){
        if(currentReport.id){
          setUpdateReport(true)
        }
        else{
          generateReport()
        }
      } else{
        setSaveReport(false)
      }
    } else{
      setSaveReport(false)
    }
  },[saveReport])

  const generatePdf = async (params) => {
    const input = componentRef.current;

    // Convert the component to a canvas
    const canvas = await html2canvas(input);
    const imgData = canvas.toDataURL('image/png');

    // Get the dimensions of the canvas
    const imgWidth = 210; // Width of A4 in mm
    const imgHeight = (canvas.height * imgWidth) / canvas.width; // Maintain aspect ratio
    const pdf = new jsPDF('p', 'mm', 'a4');
    const pageHeight = pdf.internal.pageSize.height; // Height of A4 in mm
    let heightLeft = imgHeight;

    // Initial position on the first page
    let position = 0;

    // Add the image to the PDF
    pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
    heightLeft -= pageHeight;

    // Add additional pages if needed
    while (heightLeft > 0) {
      position = heightLeft - imgHeight;
      pdf.addPage();
      pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
    }

    // Save the PDF
    // pdf.save('document.pdf');
    const pdfData = pdf.output('blob');

    params['report_type'] = 'Revenue Report'

    const formData = new FormData();
    formData.append('pdf_file', pdfData, 'document.pdf');
    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        formData.append(key, params[key]);
      }
    }

    main_api.post(companyAPIsEndPoints.PDF_REPORTING_SAVE, formData)
    .then(({ data }) => {
      setCurrentReport(data.result)
      pushNotification(data.message)
      setSaveReport(false)
    })
    .catch((error) => {
      console.log(error)
      setSaveReport(false)
    });
  };


  const generateReport = (reportData=currentReport) => {
    const companyId = auth?.loginResponse?.selectCompany?.[0]?.company_id;
    const params = {company: companyId, filterType: filter, name: reportData.name}
    if(reportData.id){
      params['id'] = reportData.id
    }
    if (filter === 'revenue_over_time' || filter === 'service_revenue'){
      params['start_date'] = startDate
      params['end_date'] = endDate
    } else if(filter === 'revenue_per_month' || filter === 'total_amount'){
        generatePdf(params);
        return;
    }

    saveReportCallBack(params)

  }

  const fetchClients = async (page) => {
    const companyId = auth?.loginResponse?.selectCompany?.[0]?.company_id;

    // Example: Fetch data from an API
    const response = await main_api.get(companyAPIsEndPoints.FETCH_MANAGEMENT_CLIENTS_PAGINATION(companyId, page));
    const data = response.data.result;

    const clientData = data.map(item => ({
      label: item.name,
      value: item.id
    }));
  
    return {
      data: clientData,
      total: response.data.count
    };
  };

  const fetchTimeReport = (page = 1) => {
    const companyId = auth?.loginResponse?.selectCompany?.[0]?.company_id;
    let params = {company: companyId, filterType: filter }
    if (filter === 'revenue_over_time' || filter === 'service_revenue'){
      if (new Date(startDate) > new Date(endDate)){
        pushNotification('start date should be less than end date', 'error')
        resetFields()
        setCanSave(false)
        return
      }
      params['start_date'] = startDate
      params['end_date'] = endDate
    }
    main_api.post(`${companyAPIsEndPoints.REPORTING_REVENUE}?page=${page}`, params)
    .then(({ data }) => {
      setTimeListing(data.results)
      setCount(data.count)
      if(data.count){
        setCanSave(true)
      }else{
        setCanSave(false)
      }
    })
    .catch((error) => {
      console.log(error);
    });
  }

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };


  const convertDateToLabel = (dateString) => {
    const date = new Date(dateString);
    const options = { month: 'long', year: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  }

  const prepareMonthChartData = (graph_data) =>{

    const startDateObj = new Date(startDate);
    const endDateObj = new Date(endDate);

    const dates = [];
    const counts = []

    let current_date = new Date(startDateObj.getFullYear(), startDateObj.getMonth(), 1);

    // Generate first date of every month from start_date to end_date
    while (current_date <= endDateObj) {
        const formattedDate = formatDate(current_date);
        const dataEntry = graph_data.find(entry => entry.month === formattedDate);
        dates.push(convertDateToLabel(formattedDate));
        if (dataEntry) {
            counts.push(dataEntry.total_cost);
        } else {
            counts.push(0);
        }
        current_date.setMonth(current_date.getMonth() + 1);
    }
    return [dates, counts]
  }

  const fetchRevenueGraphData = () =>{
    const companyId = auth?.loginResponse?.selectCompany?.[0]?.company_id;
    let params = {company: companyId, filterType: filter }
    if (filter === 'revenue_per_month' || filter === 'total_amount'){
      if (new Date(startDate) > new Date(endDate)){
        pushNotification('start date should be less than end date', 'error')
        resetFields()
        setCanSave(false)
        return
      }
      params['start_date'] = startDate
      params['end_date'] = endDate
      if (selectClient){
        params['selected_client'] = selectClient
      }
    }

    main_api.post(`${companyAPIsEndPoints.REPORTING_CHART}`, params)
    .then(({ data }) => {
      if(filter === 'revenue_per_month'){
        let graph_data = data.results

        let dates = [];
        let counts = [];

        [dates, counts] = prepareMonthChartData(graph_data) 

        console.log(dates, counts)
        
        setChartData([{
          data: counts,
          labels: dates,
          totalCount: data.count
        }])
      } else if(filter === 'total_amount'){
        // let graph_data = data.total_amount

        let dates = [];
        let counts = [];

        [dates, counts] = prepareMonthChartData(data.total_amount) 

        let dataPoints = []

        dataPoints.push({
            data: counts,
            label: 'Total Amount',
            labels: dates,
        })

        let [paid_dates, paid_cost] = prepareMonthChartData(data.paid_amount) 

        dataPoints.push({
            data: paid_cost,
            label: 'Paid Amount',
            labels: paid_dates,
        })

        let [unpaid_dates, unpaid_cost] = prepareMonthChartData(data.left_amount) 

        dataPoints.push({
            data: unpaid_cost,
            label: 'UnPaid Amount',
            labels: unpaid_dates,
        })

        setChartData(dataPoints)
      }
      setCanSave(true)
    })
    .catch((error) => {
      console.log(error);
    });

  }

  return (
    <>
      {(filter === 'revenue_over_time') &&
        <>
          <div style={{marginBottom: '10px'}}>
            <label>Date Range: </label>
            <DateContainer><DatePicker value={dayjs(startDate)} allowClear={false} defaultValue={dayjs(new Date(today.getFullYear(), today.getMonth(), 1))} onChange={(e, dateString) => setStartDate(dateString)} placeholder="Start Date"/> - <DatePicker value={dayjs(endDate)} allowClear={false} defaultValue={dayjs(new Date())} onChange={(e, dateString) => setEndDate(dateString)} placeholder="End Date"/></DateContainer>
          </div>
          <AntdesignTablePagination
            columns={RevenueReportListColumn({ })}
            data={timeListing}
            totalCount={count}
            loadPaginatedData={fetchTimeReport}
            allowRowSelection={false}
          />
        </>
      }
      {(filter === 'service_revenue') &&
        <>
          <div style={{marginBottom: '10px'}}>
            <label>Date Range: </label>
            <DateContainer><DatePicker value={dayjs(startDate)} allowClear={false} defaultValue={dayjs(new Date(today.getFullYear(), today.getMonth(), 1))} onChange={(e, dateString) => setStartDate(dateString)} placeholder="Start Date"/> - <DatePicker value={dayjs(endDate)} allowClear={false} defaultValue={dayjs(new Date())} onChange={(e, dateString) => setEndDate(dateString)} placeholder="End Date"/></DateContainer>
          </div>
          <AntdesignTablePagination
            columns={ServiceRevenueReportListColumn({ })}
            data={timeListing}
            totalCount={count}
            loadPaginatedData={fetchTimeReport}
            allowRowSelection={false}
          />
        </>
      }
      {(filter === 'revenue_per_month') &&
        <>
            <div  style={{marginBottom: '10px', display: 'flex', alignItems: 'baseline', gap: '5px', flexWrap: 'wrap'}}>
                <div style={{marginBottom: '10px'}}>
                    <label>Date Range: </label>
                    <DateContainer><DatePicker value={dayjs(startDate)} allowClear={false} defaultValue={dayjs(new Date(today.getFullYear(), today.getMonth(), 1))} onChange={(e, dateString) => setStartDate(dateString)} placeholder="Start Date"/> - <DatePicker value={dayjs(endDate)} allowClear={false} defaultValue={dayjs(new Date())} onChange={(e, dateString) => setEndDate(dateString)} placeholder="End Date"/></DateContainer>
                </div>
                <div style={{width: '20%'}}>
                    <PaginatedSelect value={selectClient} setValue={setSelectClient} fetchOptions={fetchClients} placeholder="Select Client" />
                </div>
            </div>
            <RevenueBarChart label="Revenue" componentRef={componentRef} chartData={chartData}/>
        </>
      }
      {(filter === 'total_amount') &&
        <>
            <div style={{marginBottom: '10px', display: 'flex', alignItems: 'baseline', gap: '5px', flexWrap: 'wrap'}}>
                <div style={{marginBottom: '10px'}}>
                    <label>Date Range: </label>
                    <DateContainer><DatePicker value={dayjs(startDate)} allowClear={false} defaultValue={dayjs(new Date(today.getFullYear(), today.getMonth(), 1))} onChange={(e, dateString) => setStartDate(dateString)} placeholder="Start Date"/> - <DatePicker value={dayjs(endDate)} allowClear={false} defaultValue={dayjs(new Date())} onChange={(e, dateString) => setEndDate(dateString)} placeholder="End Date"/></DateContainer>
                </div>
                <div style={{width: '20%'}}>
                    <PaginatedSelect value={selectClient} setValue={setSelectClient} fetchOptions={fetchClients} placeholder="Select Client" />
                </div>
            </div>
            <TotalAmountBarChart label="Revenue" componentRef={componentRef} chartData={chartData}/>
        </>
      }
      {updateReport && <ReportUpdate setSaveReport={setSaveReport} generateReport={generateReport} setUpdateReport={setUpdateReport} currentReport={currentReport} setCurrentReport={setCurrentReport}/>}

    </>
  )
}

export default FinancialReportListing;


const DateContainer = styled.span`
  margin-left: 5px;
`;

