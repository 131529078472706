// import React, { useEffect, useState } from 'react';
// import { Table, Row, Col, Card, Input, Divider, DatePicker } from 'antd';
// import styled from 'styled-components';
// import moment from 'moment';
// import dayjs from 'dayjs';
// // import AntdesignTable from '../antDesignTable/AntdesignTable';
// import SearchInput from '../SearchInput';
// import AntDesignSegment from '../antDesignSegment/Index';
// import HeadingComponent from '../Heading';
// import { AntdesignTable } from '../antDesignTable/AntdesignTable';
// import { CustomSegmented } from '../../pages/company/tasks/tasksManagementStyles';
// import { useSelector } from 'react-redux';
// import { main_api } from '../../api/axios_helper';
// import { companyAPIsEndPoints } from '../../constants/ApiEndPoints';
// import { AntdesignTablePagination } from '../antDesignTable/AntdesignTablePagination';
// const { RangePicker } = DatePicker;
// // import AntDesignSegment from '../antDesignSegment/Index';

// const { Search } = Input;

// const dummyData = [
//   { key: 1, service: 'Pet Walk', staff: 'Chris Brown', date: 'Jan 07, 2024', time: '2:30 AM - 3:30 AM', status: 'Completed', clientName: 'Michael Hussey', pets: 'Suga, Fifo' },
//   { key: 2, service: 'Daycare', staff: 'Andrew Tate', date: 'Jan 07, 2024', time: '3:30 AM - 4:30 AM', status: 'Cancelled', clientName: 'Raymond Parker', pets: 'Oreo' },
//   { key: 3, service: 'Pet Walk', staff: 'Chris Brown', date: 'Jan 07, 2024', time: '5:30 AM - 7:30 AM', status: 'Completed', clientName: 'Jim Bob', pets: 'Shaggy' },
//   // Add more dummy data as needed
// ];

// const columns = [
//   { title: 'Service', dataIndex: 'service', key: 'service' },
//   { title: 'Staff', dataIndex: 'staff', key: 'staff' },
//   { title: 'Date', dataIndex: 'date', key: 'date' },
//   { title: 'Time', dataIndex: 'time', key: 'time' },
//   { title: 'Status', dataIndex: 'status', key: 'status', render: status => {
//       let color;
//       switch(status) {
//         case 'Completed': color = 'green'; break;
//         case 'Cancelled': color = 'red'; break;
//         case 'unassigned': color = 'orange'; break;
//         case 'scheduled': color = 'blue'; break;
//         default: color = 'grey';
//       }
//       return <span style={{ color }}>{status}</span>;
//     } 
//   },
//   { title: 'Client Name', dataIndex: 'clientName', key: 'clientName' },
//   { title: 'Pets', dataIndex: 'pets', key: 'pets' },
// ];

// export default function Analytics() {
//   const { auth } = useSelector((state) => state);
//   const [totalServiceRequests, setTotalServiceRequests] = useState(0);
//   const [totalAcceptedServices, setTotalAcceptedServices] = useState(0);
//   const [totalPendingServices, setTotalPendingServices] = useState(0);
//   const [totalCancelledServices, setTotalCancelledServices] = useState(0);
//   const [filteredPendingServices, setFilteredPendingServices] = useState([])
//   const [filteredScheduledServices, setFilteredScheduledServices] = useState([])
//   const [selectTab, setSelectTab] = useState('Scheduled Services');
//   const [searchText, setSearchText] = useState('');
//   const [filteredData, setFilteredData] = useState(dummyData);
//   const [data, setData] = useState();
//   // const today = moment();
//   const today = dayjs();
//   const startDate = today.clone().subtract(5, 'days');
//   const endDate = today.clone().add(5, 'days');
//   const initialDate = [startDate,endDate];
//   const [dateRange, setDateRange] = useState([startDate, endDate]);
 
//   const handleSearch = (value) => {
//     const filteredData = data.filter(service => {
//       const matchesService = value ? service.service.toLowerCase().includes(value.toLowerCase()) : true;
//       return matchesService;
//     }); 
//     if (selectTab === "Scheduled Services") {
//       const scheduledServices = filteredData.filter(item => item.status === 'scheduled');
      
//       setFilteredScheduledServices(scheduledServices);
//     }else{
//       const pendingServices = filteredData.filter(item => item.status !== 'scheduled');
      
//       setFilteredPendingServices(pendingServices)
//     }
    
//   };

//   const fetchService = async () => {
//     try {
//       const companyId = auth?.loginResponse?.selectCompany?.[0]?.company_id;
//       // Example: Fetch data from an API
//       const response = await main_api.get(companyAPIsEndPoints.GET_SERVICE_REQUEST);
//       const data = response.data.result.map((item, index) => {
//         return {
//           key: item.id, // Or index + 1 if you want sequential keys
//           service: item.service?.name || 'N/A',
//           staff: item.staff?.name || 'N/A',
//           date: new Date(item.date).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' }),
//           time: `${item.start_time} - ${item.end_time}` || '--- - ---',
//           status: item.status || 'N/A',
//           clientName: item.client?.name || 'N/A',
//           pets: item.pets.map(pet => pet.name).join(', ') || 'N/A'
//         };
//       });

//       setData(data);
  
//       const scheduledServices = data.filter(item => item.status === 'scheduled');
//       const pendingServices = data.filter(item => item.status !== 'scheduled');
//       const acceptedServices = data.filter(item => item.status === 'accepted');
//       const cancelledServices = data.filter(item => item.status === 'cancelled');

//       setFilteredScheduledServices(scheduledServices);
//       setFilteredPendingServices(pendingServices);

//       // Counts
//       setTotalServiceRequests(data.length);
//       setTotalAcceptedServices(acceptedServices.length);
//       setTotalPendingServices(pendingServices.length);
//       setTotalCancelledServices(cancelledServices.length);
//     } catch (error) {
//       console.error('Error fetching service data:', error);
      
//     }
//   };
  

//   useEffect(()=>{
//     fetchService();
//   },[]);

//   useEffect(() => {
//     filterDataByDateRange();
//   }, [dateRange]);

  
//   const filterDataByDateRange = () => {
//     const [start, end] = dateRange;
//     const formattedStart = new Date(start).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });
//     const formattedEnd = new Date(end).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });
  
   
  
//     const filteredDataByDate = data?.filter(item => {
//       const isInRange = new Date(item.date) >= new Date(formattedStart) && new Date(item.date) <= new Date(formattedEnd);
//       return isInRange;
//     });
  
//     setData(filteredDataByDate);
//     const scheduledServices = filteredDataByDate?.filter(item => item.status === 'scheduled');
//     const pendingServices = filteredDataByDate?.filter(item => item.status !== 'scheduled');
//     const acceptedServices = filteredDataByDate?.filter(item => item.status === 'accepted');
//     const cancelledServices = filteredDataByDate?.filter(item => item.status === 'cancelled');
  
//     setFilteredScheduledServices(scheduledServices);
//     setFilteredPendingServices(pendingServices);
  
//     // Counts
//     setTotalServiceRequests(filteredDataByDate?.length);
//     setTotalAcceptedServices(acceptedServices?.length);
//     setTotalPendingServices(pendingServices?.length);
//     setTotalCancelledServices(cancelledServices?.length);
//   };
  
//   const disabledDate = (current) => {
//     return current && (current > endDate.endOf('day'));
//   };
  
//   const onDateRangeChange = (dates) => {
//     setDateRange(dates);
//   };

 

//   return (
//     <div style={{ marginTop: '20px' }}>
//     <div style={{marginBlock:"15px"}}>

     

//      <RangePicker style={{marginTop:"20px"}} disabledDate={disabledDate} defaultValue={initialDate} onChange={onDateRangeChange} />
    
//       <Row gutter={16} style={{ marginBottom: '16px', marginTop: '20px' }}>
//         <Col span={6}>
//           <Card style={{ boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)' }}>
//             <Heading text= {totalServiceRequests} fontSize='1.45rem'/>
//             <Heading text='Total Service Requests' color='rgba(0, 0, 0, 0.45)' fontSize='14px'/>
//           </Card>
//         </Col>
//         <Col span={6}>
//           <Card style={{ boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)' }}>
//             <Heading text={totalAcceptedServices} fontSize='1.45rem'/>
//             <Heading text='Total Accepted Services' color='rgba(0, 0, 0, 0.45)' fontSize='14px'/>
//           </Card>
//         </Col>
//         <Col span={6}>
//           <Card style={{ boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)' }}>
//             <Heading text={totalPendingServices} fontSize='1.45rem'/>
//             <Heading text='Total Pending Services' color='rgba(0, 0, 0, 0.45)' fontSize='14px'/>
//           </Card>
//         </Col>
//         <Col span={6}>
//           <Card style={{ boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)' }}>
//             <Heading text={totalCancelledServices} fontSize='1.45rem'/>
//             <Heading text='Total Cancelled Services' color='rgba(0, 0, 0, 0.45)' fontSize='14px'/>
//           </Card>
//         </Col>
//       </Row>
    
//     </div>
//      <div style={{marginBlock:"15px"}}>

//         <Heading text='Services List' fontSize='1.4rem'/>
//      </div>
//       <Card>
//       <div style={{ marginBottom: '16px' }}>

//         <div className="d-flex align-items-center">
//           <div className="w-35 ms-2">
//             <AntDesignSegment
//               options={['Scheduled Services', 'Pending Services']}
//               onChange={(e) => setSelectTab(e)}
//               defaultValue="Scheduled Services"
//               size="large"
//             />
//           </div>
//           <SearchInputWrapper>
//             <SearchInput onChange={(e) => handleSearch(e)} placeholder="Type to search..." />
//           </SearchInputWrapper>
//         </div>
//       </div>
//       <div style={{ marginBottom: '50px', position: 'relative', top: 'auto', bottom: 0 }}>
//          {selectTab === 'Pending Services' ? (
          
//           <AntdesignTable columns={columns} data={filteredPendingServices} />
        
//         ) : (
//           <AntdesignTable columns={columns} data={filteredScheduledServices} />
//         )}
//       </div>
//         </Card>
//     </div>
//   );
// }

// const Heading = ({ text = "", margin, fontSize = "0.75rem", color = "#3B3B3B" }) => {
//   return <HeadingComponent text={text} fontSize={fontSize} color={color} fontWeight={700} margin={margin} />;
// };


// const SearchInputWrapper = styled.div`
//   width: 25%;
//   margin-left: 10px;
// `;




///============================

import React, { useEffect, useState } from 'react';
import { Table, Row, Col, Card, Input, Divider, DatePicker } from 'antd';
import styled from 'styled-components';
import dayjs from 'dayjs';
import SearchInput from '../SearchInput';
import AntDesignSegment from '../antDesignSegment/Index';
import HeadingComponent from '../Heading';
import { AntdesignTable } from '../antDesignTable/AntdesignTable';
import { useSelector } from 'react-redux';
import { main_api } from '../../api/axios_helper';
import { companyAPIsEndPoints } from '../../constants/ApiEndPoints';
import moment from 'moment';

const { RangePicker } = DatePicker;
const { Search } = Input;

const dummyData = [
  { key: 1, service: 'Pet Walk', staff: 'Chris Brown', date: 'Jan 07, 2024', time: '2:30 AM - 3:30 AM', status: 'Completed', clientName: 'Michael Hussey', pets: 'Suga, Fifo' },
  { key: 2, service: 'Daycare', staff: 'Andrew Tate', date: 'Jan 07, 2024', time: '3:30 AM - 4:30 AM', status: 'Cancelled', clientName: 'Raymond Parker', pets: 'Oreo' },
  { key: 3, service: 'Pet Walk', staff: 'Chris Brown', date: 'Jan 07, 2024', time: '5:30 AM - 7:30 AM', status: 'Completed', clientName: 'Jim Bob', pets: 'Shaggy' },
  // Add more dummy data as needed
];

const scheduledServicesColumns = [
  { title: 'Service', dataIndex: 'service', key: 'service' },
  { title: 'Staff', dataIndex: 'staff', key: 'staff' },
  { title: 'Date', dataIndex: 'date', key: 'date' },
  { title: 'Time', dataIndex: 'time', key: 'time' },
  { title: 'Status', dataIndex: 'status', key: 'status', render: status => {
      let color;
      switch(status) {
        case 'Completed': color = 'green'; break;
        case 'Cancelled': color = 'red'; break;
        case 'unassigned': color = 'orange'; break;
        case 'scheduled': color = 'blue'; break;
        default: color = 'grey';
      }
      return <span style={{ color }}>{status}</span>;
    } 
  },
  { title: 'Client Name', dataIndex: 'clientName', key: 'clientName' },
  { title: 'Pets', dataIndex: 'pets', key: 'pets' },
];

// Pending Services Columns

const pendingServicesColumns = [
  { title: 'Service', dataIndex: 'service', key: 'service' },
  { title: 'Staff', dataIndex: 'staff', key: 'staff' },
  { title: 'Date', dataIndex: 'date', key: 'date' },
  { title: 'Time', dataIndex: 'time', key: 'time' },
  { title: 'Status', dataIndex: 'status', key: 'status', render: status => {
      let color;
      switch(status) {
        case 'Completed': color = 'green'; break;
        case 'Cancelled': color = 'red'; break;
        case 'unassigned': color = 'orange'; break;
        case 'scheduled': color = 'blue'; break;
        default: color = 'grey';
      }
      return <span style={{ color }}>{status}</span>;
    } 
  },
  { title: 'Reason', dataIndex: 'reason', key: 'reason' },
  { title: 'Client Name', dataIndex: 'clientName', key: 'clientName' },
  { title: 'Pets', dataIndex: 'pets', key: 'pets' },
];

export default function Analytics() {
  const { auth } = useSelector((state) => state);
  const [totalServiceRequests, setTotalServiceRequests] = useState(0);
  const [totalAcceptedServices, setTotalAcceptedServices] = useState(0);
  const [totalPendingServices, setTotalPendingServices] = useState(0);
  const [totalCancelledServices, setTotalCancelledServices] = useState(0);
  const [filteredPendingServices, setFilteredPendingServices] = useState([]);
  const [filteredScheduledServices, setFilteredScheduledServices] = useState([]);
  const [selectTab, setSelectTab] = useState('Scheduled Services');
  const [searchText, setSearchText] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [data, setData] = useState([]);
  const today = dayjs();
  const startDate = today.clone().subtract(5, 'days');
  const endDate = today.clone().add(5, 'days');
  const [dateRange, setDateRange] = useState([startDate, endDate]);

  useEffect(() => {
    fetchService();
  }, []);

  useEffect(() => {
    filterDataByDateRange();
  }, [data, dateRange]);

     // Time Formater
  function formatTime(timestamp) {
      return moment.unix(timestamp).utc().format('hh:mm A');
  }
  
  function convertTimeFormat(timeStr) {
      return moment(timeStr, "HH:mm:ss").format("hh:mm A");
  }
    
  const fetchService = async () => {
    try {
      const companyId = auth?.loginResponse?.selectCompany?.[0]?.company_id;
      // Example: Fetch data from an API
      const response = await main_api.get(companyAPIsEndPoints.GET_SERVICE_REQUEST(companyId));
      const data = response.data.result.map((item, index) => {
        return {
          key: item.id, // Or index + 1 if you want sequential keys
          service: item.service?.name || 'N/A',
          staff:  item.staff?.name ? item.staff?.name : item.staff?.name === 'N/A' ? " --- " : '--',
          date: dayjs(item.date).format('MMM DD, YYYY'),
          time: item.status === "unassigned" ?  `${convertTimeFormat(item?.time_block?.start_time)} - ${convertTimeFormat(item?.time_block?.end_time)}`: `${convertTimeFormat(item.start_time)} - ${convertTimeFormat(item.end_time)}` ,
          status: item.status || 'N/A',
          reason: item.status === "unassigned" && item.reason !== null ? item.reason : 'N/A',
          clientName: item.client?.name || 'N/A',
          pets: item.pets.map(pet => pet.name).join(', ') || 'N/A'
        };
      });
      setData(data);

      // No need to filter here, it will be done in useEffect by filterDataByDateRange
    } catch (error) {
      console.error('Error fetching service data:', error);
    }
  };

  const filterDataByDateRange = () => {
    const [start, end] = dateRange;
    const filteredDataByDate = data.filter(item => {
      const itemDate = dayjs(item.date);
      return itemDate.isAfter(start) && itemDate.isBefore(end);
    });

    setFilteredData(filteredDataByDate);
    const scheduledServices = filteredDataByDate.filter(item => item.status === 'scheduled');
    const pendingServices = filteredDataByDate.filter(item => item.status === "unassigned");
    const acceptedServices = filteredDataByDate.filter(item => item.status === 'accepted');
    const cancelledServices = filteredDataByDate.filter(item => item.status === 'cancelled');

    setFilteredScheduledServices(scheduledServices);
    setFilteredPendingServices(pendingServices);

    // Counts
    setTotalServiceRequests(filteredDataByDate.length);
    setTotalAcceptedServices(scheduledServices.length);
    setTotalPendingServices(pendingServices.length);
    setTotalCancelledServices(cancelledServices.length);
  };

  const disabledDate = (current) => {
    return current && current > endDate.endOf('day');
  };

  const onDateRangeChange = (dates) => {
    setDateRange(dates);
  };

  const handleSearch = (value) => {
    const filteredData = data.filter(service => {
      const matchesService = value ? service.service.toLowerCase().includes(value.toLowerCase()) : true;
      return matchesService;
    });
    if (selectTab === "Scheduled Services") {
      const scheduledServices = filteredData.filter(item => item.status === 'scheduled');
      setFilteredScheduledServices(scheduledServices);
    } else {
      const pendingServices = filteredData.filter(item => item.status !== 'scheduled');
      setFilteredPendingServices(pendingServices);
    }
  };

  return (
    <div style={{ marginTop: '20px' }}>
      <div style={{ marginBlock: "15px" }}>
        <RangePicker 
          style={{ marginTop: "20px" }} 
          disabledDate={disabledDate}  
          onChange={onDateRangeChange} 
          defaultValue={dateRange}
          value={dateRange}
        />
        <Row gutter={16} style={{ marginBottom: '16px', marginTop: '20px' }}>
          <Col span={6}>
            <Card style={{ boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)' }}>
              <Heading text={totalServiceRequests} fontSize='1.45rem'/>
              <Heading text='Total Service Requests' color='rgba(0, 0, 0, 0.45)' fontSize='14px'/>
            </Card>
          </Col>
          <Col span={6}>
            <Card style={{ boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)' }}>
              <Heading text={totalAcceptedServices} fontSize='1.45rem'/>
              <Heading text='Total Accepted Services' color='rgba(0, 0, 0, 0.45)' fontSize='14px'/>
            </Card>
          </Col>
          <Col span={6}>
            <Card style={{ boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)' }}>
              <Heading text={totalPendingServices} fontSize='1.45rem'/>
              <Heading text='Total Pending Services' color='rgba(0, 0, 0, 0.45)' fontSize='14px'/>
            </Card>
          </Col>
          <Col span={6}>
            <Card style={{ boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)' }}>
              <Heading text={totalCancelledServices} fontSize='1.45rem'/>
              <Heading text='Total Cancelled Services' color='rgba(0, 0, 0, 0.45)' fontSize='14px'/>
            </Card>
          </Col>
        </Row>
      </div>
      <div style={{ marginBlock: "15px" }}>
        <Heading text='Services List' fontSize='1.4rem'/>
      </div>
      <Card style={{ boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)' }}>
        <div style={{ marginBottom: '16px' }}>
          <div className="d-flex align-items-center">
            <div className="w-55 ms-2">
              <AntDesignSegment
                options={['Scheduled Services', 'Pending Services']}
                onChange={(e) => setSelectTab(e)}
                defaultValue="Scheduled Services"
                size="large"
              />
            </div>
            <SearchInputWrapper>
              <SearchInput onChange={(e) => handleSearch(e.target.value)} placeholder="Type to search..." />
            </SearchInputWrapper>
          </div>
        </div>
        <div style={{ marginBottom: '50px', position: 'relative', top: 'auto', bottom: 0 }}>
          {console.log("filteredPendingServices", filteredPendingServices)}
          {selectTab === 'Pending Services' ? (
            <AntdesignTable columns={pendingServicesColumns} data={filteredPendingServices} />
          ) : (
            <AntdesignTable columns={scheduledServicesColumns} data={filteredScheduledServices} />
          )}
        </div>
      </Card>
    </div>
  );
}

const Heading = ({ text = "", margin, fontSize = "0.75rem", color = "#3B3B3B" }) => {
  return <HeadingComponent text={text} fontSize={fontSize} color={color} fontWeight={700} margin={margin} />;
};

const SearchInputWrapper = styled.div`
  width: 25%;
  margin-left: 10px;
`;
