import React, { useEffect, useState } from "react";
import PayStructure from "./payStructure";
import TimeTracking from "./timeTracking";
import StaffTags from "./staffTags";
import StaffApp from "./staffApp";
import styled from "styled-components";
import SideBar from "../../../../components/settingsSideBar/sideBar";
import GeneralNotifications from "./notificationSubComponents/generalNotifications";
import CompanyNotifications from "./notificationSubComponents/companyNotifications";
import { Col, Divider, Row } from "antd";
import StaffNotifications from "./notificationSubComponents/staffNotifications";
import MissingKeyNotifications from "./notificationSubComponents/missingKeyNotifications";
import { main_api } from "../../../../api/axios_helper";
import { companyAPIsEndPoints } from "../../../../constants/ApiEndPoints";
import { useSelector } from "react-redux";

export default function Notifications() {
  const auth = useSelector((state) => state.auth);
  const company_id = auth.loginResponse?.selectCompany?.[0]?.company_id || null;
  const [generalNotificationsComponent, setGeneralNotificationsComponent] = useState(true);
  const [companyNotificationsComponent, setCompanyNotificationsComponent] = useState(false);
  const [staffNotificationsComponent, setStaffNotificationsComponent] = useState(false);
  const [missingKeyNotificationsComponent, setMissingKeyNotificationsComponent] = useState(false);
  const [notificationsData, setNotificationsData] = useState();

  const menuItemsForNotifications = [
    { key: "1", label: "General Notifications" },
    { key: "2", label: "Company Notifications" },
    { key: "3", label: "Staff Notifications" },
    { key: "4", label: "Missing Key Notifications" },
  ];

  const handleNotificationsMenuSelect = (item) => {
    switch (item.key) {
      case "1":
        setGeneralNotificationsComponent(true);
        setCompanyNotificationsComponent(false);
        setStaffNotificationsComponent(false);
        setMissingKeyNotificationsComponent(false);
        break;
      case "2":
        setGeneralNotificationsComponent(false);
        setCompanyNotificationsComponent(true);
        setStaffNotificationsComponent(false);
        setMissingKeyNotificationsComponent(false);
        break;
      case "3":
        setGeneralNotificationsComponent(false);
        setCompanyNotificationsComponent(false);
        setStaffNotificationsComponent(true);
        setMissingKeyNotificationsComponent(false);
        break;
      case "4":
        setGeneralNotificationsComponent(false);
        setCompanyNotificationsComponent(false);
        setStaffNotificationsComponent(false);
        setMissingKeyNotificationsComponent(true);
        break;
      default:
        break;
    }
  };
  const getNotifications = () => {
    main_api
      .get(companyAPIsEndPoints.FECTH_CUSTOM_NOTIFICATION_SETTINGS)
      .then(({ data }) => {
        if (data.isSuccess) {
          setNotificationsData(data.result);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getNotifications();
  }, []);

  return (
    <Row gutter={20}>
      <Col sm={8} md={6} lg={6} xl={4} xxl={3}>
        <SideBar menuItems={menuItemsForNotifications} handleMenuSelect={handleNotificationsMenuSelect} />
      </Col>
      <Col sm={12} md={15} lg={15} xl={20} xxl={21}>
        {generalNotificationsComponent && <GeneralNotifications />}
        {companyNotificationsComponent && (
          <CompanyNotifications
            company_id={company_id}
            notificationsData={notificationsData}
            setNotificationsData={setNotificationsData}
          />
        )}
        {staffNotificationsComponent && (
          <StaffNotifications
            company_id={company_id}
            notificationsData={notificationsData}
            setNotificationsData={setNotificationsData}
          />
        )}
        {missingKeyNotificationsComponent && (
          <MissingKeyNotifications
            company_id={company_id}
            notificationsData={notificationsData}
            setNotificationsData={setNotificationsData}
          />
        )}
      </Col>
    </Row>
  );
}

const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const NavContainer = styled.nav`
  width: 10%;
  margin-right: 2px;
  color: black;
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;
const ComponentContainer = styled.nav`
  width: 88%;
  margin-right: 2px;
`;
