import React, { useEffect, useState } from "react";
import { Checkbox, Select, Row, Col, Radio, TimePicker } from "antd";
import { Formik, Form as FormikForm, FieldArray } from "formik";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import locale from "antd/es/date-picker/locale/de_DE";

import CustomBtnWithIcon from "../../../components/buttonWithIcon";
import FormikInputFloating from "../../../components/formikInputs/TextInput";

import AntDesignModal from "../../../components/Modals/AntDesignModal/AntDesignModal";

import { StepsContainer, RequiredSign } from "./StaffStyles";
import HeadingComponent from "../../../components/Heading";

import { ReactComponent as SecondStepChecked } from "../../../assets/rawSvgs/Frame1000005140.svg";
import { ReactComponent as SecondStepTransparent } from "../../../assets/rawSvgs/Frame000005140Transparnet.svg";
import { ReactComponent as GreaterThanSign } from "../../../assets/rawSvgs/greaterThanIcon.svg";
import { ReactComponent as FirstStep } from "../../../assets/rawSvgs/Frame1000005140(2).svg";
import { ReactComponent as FirstStepGreenChecked } from "../../../assets/rawSvgs/frame1000005142.svg";
import { ReactComponent as PlusYellowIcon } from "../../../assets/rawSvgs/yelowplus.svg";
import { ReactComponent as RemoveYellowIcon } from "../../../assets/rawSvgs/declineIcon.svg";
import "./staff.css";
import { addStaffSchema } from "../../../constants/CompanyFormFieldsSchema/FormSchema";
import { main_api } from "../../../api/axios_helper";
import { staffAPIsEndPoints } from "../../../constants/ApiEndPoints";
import { pushNotification } from "../../../util/Notification";
import { ConfigureStaffAvailComponent } from "./staffdetail/availabilityComponent/SameDayForm";
import LocationSelectorFormik from "../../../components/CustomAddressInput/locationSelectorFormik";
const { Option } = Select;
//

const AddNewStaffForm = ({ visible, handleClose, fetchAllStaff, editStaffValues, updateForm, companyId }) => {
  const [addStaffSetps, setAddStaffSetps] = useState(1);
  const [staffAddloader, setStaffAddloader] = useState(false);
  const [createStaffValues, setCreateStaffValues] = useState({});
  const [submitFormLoader, setSubmitFormLoader] = React.useState(false);
  const [routeOptimizorChecked, setRouteOptimizorChecked] = useState(true);
  const [addressLatAndLong, setAddressLatAndLong] = useState([]);

  const formikRef = React.useRef();
  const formikRefSecondStep = React.useRef();

  const handleSubmit = async (values, actions) => {
    try {
      setStaffAddloader(true);
      values = { ...values, latitude: addressLatAndLong[0], longitude: addressLatAndLong[1] };
      const updatedValues = { ...values };

      // Modify the copied object based on conditions
      if (updatedValues.by_hour === "by the Job") {
        updatedValues.hourly_rate = null;
      }
      if (updatedValues.by_hour === "by_the_hour") {
        updatedValues.standard_rate = null;
      }
      if (!updatedValues.custom_rate) {
        updatedValues.hourly_rate = null;
        updatedValues.standard_rate = null;
      }
      const { by_hour, custom_rate, time_slot, ...rest } = updatedValues;

      if (updateForm) {
        handleUpdateForm(rest);
        return;
      }

      setCreateStaffValues(rest);
      // move to next form
      setAddStaffSetps(2);

      setStaffAddloader(false);
    } catch (error) {
      setStaffAddloader(false);

      console.log("Error :", error);
    }
  };
  const handleUpdateForm = async (values) => {
    try {
      setStaffAddloader(true);
      const { data } = await main_api.put(staffAPIsEndPoints.UPDATE_NEW_STAFF(editStaffValues?.id), values);

      if (data.isSuccess) {
        pushNotification("Staff updated successfully", "success");
        fetchAllStaff();
        handleClose();
      }
      setStaffAddloader(false);
    } catch (error) {
      setStaffAddloader(false);

      console.log("Error :", error);
    }
  };

  return (
    <AntDesignModal
      title={updateForm ? "Edit a Staff" : "Add a new Staff"}
      open={visible}
      onCancel={handleClose}
      style={{ top: 10 }}
      width="550px"
      component={
        <div
          style={{
            maxHeight: "70vh",
            overflowY: "auto",
            minHeight: "54vh",
            overflowX: "hidden",
            borderBottom: "0.5px solid rgb(144 144 144 / 43%)",
            padding: "10px",
          }}
        >
          <StepsContainer className={`${updateForm ? "d-none" : "d-flex"}`}>
            <div className="d-flex align-items-center ms-2 cursorPointer">
              {addStaffSetps === 1 ? <FirstStep /> : <FirstStepGreenChecked />}
              <HeadingComponent text={"Staff Details"} fontSize="14px" color="#616161" margin={"0px 12px"} />
            </div>
            <div className="mx-3">
              <GreaterThanSign />
            </div>
            <div className="d-flex align-items-center">
              {addStaffSetps === 1 ? <SecondStepTransparent /> : <SecondStepChecked />}

              <HeadingComponent text={"Staff Availability"} fontSize="14px" color="#616161" margin={"0px 7px"} />
            </div>
          </StepsContainer>
          <Formik
            initialValues={{
              email: updateForm ? editStaffValues.email : "",
              address: updateForm ? editStaffValues.address : "",
              name: updateForm ? editStaffValues.name : "",
              company: companyId,
              type: updateForm ? editStaffValues.type : "employee",
              terms_and_conditions_viewed: updateForm ? editStaffValues.terms_and_conditions_viewed : false,
              privacy_policy_viewed: updateForm ? editStaffValues.privacy_policy_viewed : false,
              is_flagged: updateForm ? editStaffValues.is_flagged : false,
              logo: updateForm ? editStaffValues.logo : null,
              primary_phone_number: updateForm ? editStaffValues.primary_phone_number : "",
              secondary_phone_number: updateForm ? editStaffValues.secondary_phone_number : "",
              by_hour: updateForm
                ? editStaffValues.hourly_rate && editStaffValues.is_job_rate
                  ? "both"
                  : editStaffValues.hourly_rate
                    ? "by_the_hour"
                    : editStaffValues.is_job_rate
                      ? "by the Job"
                      : ""
                : "",
              hourly_rate: updateForm ? editStaffValues.hourly_rate : "",
              standard_rate: updateForm ? editStaffValues.standard_rate : null,
              time_slot: ["abcd"],
              custom_rate: updateForm ? (editStaffValues.hourly_rate || editStaffValues.is_job_rate ? true : false) : false,
              is_job_rate: false,
            }}
            validationSchema={addStaffSchema}
            // onSubmit={handleSubmit}
            onSubmit={handleSubmit}
            enableReinitialize
            innerRef={formikRef}
          >
            {({ values, setFieldValue, errors }) => (
              <FormikForm>
                <div style={{ display: addStaffSetps === 1 ? "block" : "none" }}>
                  <Row justify={"space-bxetween"} gutter={5}>
                    <Col md={24}>
                      <FormikInputFloating
                        label="Full name"
                        placeHolder="Enter Full Name"
                        name="name"
                        type="text"
                        className="w-100 mb-2"
                        autoComplete="new-password"
                        requiredField
                      />
                    </Col>

                    <Col md={12} className="w-100 my-2">
                      <div className="d-flex">
                        <RequiredSignComponent />
                        <HeadingComponent text={"Primary Phone Number"} fontSize="12px" margin="5px 0px" color="#3b3b3b" />
                      </div>
                      <PhoneInput
                        placeholder="Enter phone number"
                        className="w-100"
                        value={values.primary_phone_number}
                        onChange={(e) => {
                          setFieldValue("primary_phone_number", e);
                        }}
                        country={"us"}
                      />
                      {errors?.primary_phone_number && <div className="input-error-style">{errors?.primary_phone_number}</div>}
                    </Col>
                    <Col md={12} className="w-100 my-2">
                      <HeadingComponent text={"Secondary Phone Number"} margin="5px 0px" fontSize="12px" color="#3b3b3b" />
                      <PhoneInput
                        placeholder="Enter phone number"
                        className="w-100"
                        value={values.secondary_phone_number}
                        def
                        onChange={(e) => {
                          setFieldValue("secondary_phone_number", e);
                        }}
                        country={"us"}
                      />
                    </Col>
                    <Col md={24}>
                      <FormikInputFloating
                        label="Email"
                        placeHolder="Enter Email Address"
                        name="email"
                        type="text"
                        className="w-100 mb-2"
                        autoComplete="new-password"
                        disabled={updateForm ? true : false}
                        requiredField
                      />
                    </Col>
                    <Col md={24}>
                      <LocationSelectorFormik setAddressLatAndLong={setAddressLatAndLong} checked={routeOptimizorChecked} />
                    </Col>
                    <div className="d-flex align-items-center">
                      <Checkbox
                        label=""
                        placeHolder="Enter Address"
                        name="custom_rate"
                        type="checkbox"
                        autoComplete="new-password"
                        checked={values.custom_rate}
                        onChange={(e) => {
                          setFieldValue("custom_rate", e.target.checked);
                        }}
                      />
                      <HeadingComponent text={"Set custom pay rate"} fontSize="12px" color="black" margin={"10px 7px"} />
                    </div>

                    {values.custom_rate && (
                      <>
                        <Col md={24}>
                          <HeadingComponent
                            text={"Select any option for setting rate"}
                            fontWeight={500}
                            fontSize="0.75rem"
                            color="#000000"
                            margin={"15px 7px 10px 0px"}
                          />
                          <Radio.Group
                            onChange={(e) => {
                              setFieldValue("by_hour", e.target.value);
                              if (e.target.value === "by the Job") {
                                setFieldValue("hourly_rate", "");
                                setFieldValue("is_job_rate", true);
                                return;
                              }

                              if (e.target.value === "by_the_hour") {
                                setFieldValue("is_job_rate", false);
                                // setFieldValue("standard_rate", "");
                              }
                              if (e.target.value === "both") {
                                setFieldValue("is_job_rate", true);
                                // setFieldValue("standard_rate", "");
                              }
                            }}
                            className="d-flex align-items-center"
                            value={values.by_hour}
                          >
                            <Radio value="by_the_hour">
                              <HeadingComponent text={"By the Hour"} fontSize="0.875rem" color="#000000" fontWeight={400} />
                            </Radio>
                            <Radio value="by the Job">
                              {" "}
                              <HeadingComponent text={"By the Job"} fontSize="0.875rem" color="#000000" fontWeight={400} />
                            </Radio>
                            <Radio value="both">
                              {" "}
                              <HeadingComponent text={"Both"} fontSize="0.875rem" color="#000000" fontWeight={400} />
                            </Radio>
                          </Radio.Group>
                          {errors?.by_hour && <div className="input-error-style">{errors?.by_hour}</div>}
                        </Col>
                        {(values.by_hour === "by_the_hour" || values.by_hour === "both") && (
                          <Col md={24}>
                            <FormikInputFloating
                              label="Hourly Rate"
                              placeHolder="$ Enter Hourly Rate"
                              name="hourly_rate"
                              type="number"
                              className="w-100 mt-2"
                              autoComplete="new-password"
                              requiredField={values.by_hour === "by_the_hour" || values.by_hour === "both"}
                            />
                          </Col>
                        )}
                      </>
                    )}

                    <Col md={24}>
                      <HeadingComponent
                        text={"Staff Member is?"}
                        fontWeight={500}
                        fontSize="0.75rem"
                        color="#3b3b3b"
                        margin={"15px 7px 10px 0px"}
                      />

                      <Radio.Group
                        onChange={(e) => {
                          setFieldValue("type", e.target.value);
                        }}
                        className="d-flex align-items-center"
                        value={values.type}
                      >
                        <Radio value="employee">
                          {" "}
                          <HeadingComponent text={"Employee"} fontSize="0.875rem" color="#000000" fontWeight={400} />
                        </Radio>
                        <Radio value="contractor">
                          <HeadingComponent text={"Contractor"} fontSize="0.875rem" color="#000000" fontWeight={400} />
                        </Radio>
                      </Radio.Group>
                      {errors?.type && <div className="input-error-style">{errors?.type}</div>}
                    </Col>
                  </Row>
                </div>
              </FormikForm>
            )}
          </Formik>

          <div style={{ display: addStaffSetps === 1 ? "none" : "block" }}>
            <ConfigureStaffAvailComponent
              createStaffValues={createStaffValues}
              innerRef={formikRef}
              fetchAllStaff={fetchAllStaff}
              addStaffSetps={addStaffSetps}
              setAddStaffSetps={setAddStaffSetps}
              formikRefSecondStep={formikRefSecondStep}
              handleCloseModal={() => {
                handleClose();
              }}
              setSubmitFormLoader={setSubmitFormLoader}
            />
          </div>
        </div>
      }
      footer={
        <div className="d-flex justify-content-between mt-4">
          <div>
            {addStaffSetps === 2 && (
              <CustomBtnWithIcon
                btnText={"Back"}
                hideIcon
                noBackground
                className="me-2"
                width="90px"
                onClick={() => {
                  setAddStaffSetps(1);
                }}
              />
            )}
          </div>

          <div className="d-flex">
            <CustomBtnWithIcon btnText={"Cancel"} hideIcon noBackground className="me-2" width="90px" onClick={handleClose} />
            {addStaffSetps === 2 ? (
              <CustomBtnWithIcon
                btnText={"Save"}
                hideIcon
                className="me-2"
                width="90px"
                type="submit"
                disabled={staffAddloader || submitFormLoader}
                onClick={() => {
                  if (formikRefSecondStep?.current) {
                    formikRefSecondStep?.current?.handleSubmit();
                  }
                }}
              />
            ) : (
              <>
                {updateForm ? (
                  <CustomBtnWithIcon
                    btnText={"Save"}
                    hideIcon
                    className="me-2"
                    width="90px"
                    type="submit"
                    disabled={staffAddloader}
                    onClick={() => {
                      if (addStaffSetps === 1) {
                        if (formikRef?.current) {
                          formikRef?.current?.handleSubmit();
                          return;
                        }
                      }
                    }}
                  />
                ) : (
                  <CustomBtnWithIcon
                    btnText={"Next"}
                    hideIcon
                    className="me-2"
                    width="90px"
                    type="submit"
                    onClick={() => {
                      if (formikRef?.current) {
                        formikRef?.current?.handleSubmit();
                      }

                      // setAddStaffSetps(2);
                    }}
                  />
                )}
              </>
            )}
          </div>
        </div>
      }
    />
  );
};

export default AddNewStaffForm;

export const RequiredSignComponent = () => {
  return (
    <div>
      {" "}
      <RequiredSign className="me-1">*</RequiredSign>
    </div>
  );
};
