import { Checkbox, Col, Input, Row, Table } from "antd";
import { useEffect, useState } from "react";

import { DatePicker as DatePickerv2 } from "antd";
import { hours_of_day_12_hour_format } from "../SchedulerCalenderDataFile";
// import "./table.css";
import HeadingComponent from "../../Heading";
import { StaffIcon, SchedulerServiceIcon, SchedulerWhenDateicon, SchedulerAvatarClient } from "../../../assets/rawSvgs";

import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import "react-datepicker/dist/react-datepicker.css";
import CustomBtnWithIcon from "../../buttonWithIcon";
import AntDesignModal from "../../Modals/AntDesignModal/AntDesignModal";
import AntDesignDropDownSelect from "../../AntDesignDropDownSelect/AntDesignDropDownSelect";
import { PetsCheckboxWrapper } from "../SchedulerStyles";

import { ReactComponent as Birthdate } from "../../../assets/rawSvgs/Birthdate.svg";
import dayjs from "dayjs";
import ParentHorizontal from "./example1/ParentHorizontal";
import moment from "moment";

//
const TransposedTable = ({ AllStates, handleSelectSchedulerDropDown }) => {
  const [openDetailSlotModal, setOpenDetailSlotModal] = useState(false);
  const [addNewEventModal, setAddNewEventModal] = useState(false);
  const [openDetailEventmodal, setOpenDetailEventmodal] = useState({});

  const handleCancel = () => {
    setOpenDetailSlotModal(false);
  };

  const unavaliableSlots = AllStates?.unavaliableSlots?.responseData?.result || {};

  const handleAddNewEventModal = () => {
    setAddNewEventModal(true);
    handleSelectSchedulerDropDown("Schedule a Service");
  };

  // Create an object to store events grouped by staff ID
  // Map staff resources from the sidebar data
  const staffObject = AllStates?.allStaffState?.responseData?.map((items) => {
    return { name: items.name, id: items.id, events: [] }; // Initialize events array for each staff
  });
  // console.log("staffObject", staffObject, AllStates?.allStaffState?.responseData);

  // Initialize eventsByStaff object
  const eventsByStaff = {};

  // Fill eventsByStaff with events data
  AllStates?.schedulerState?.result?.forEach((nestedElem) => {
    const staffId = nestedElem?.staff?.id;
    const event = {
      ...nestedElem,
      start_time: createDateTimeObject(nestedElem?.date, nestedElem?.start_time),
      end_time: createDateTimeObject(nestedElem?.date, nestedElem?.end_time),
    };
    if (!eventsByStaff[staffId]) {
      eventsByStaff[staffId] = [];
    }
    eventsByStaff[staffId]?.push(event);
  });

  const defaultSlot = [["00:00:00", "23:59:00"]];
  const newUnavailableSlots = { ...unavaliableSlots };

  if (staffObject?.length > 0) {
    staffObject?.forEach((staff) => {
      if (!newUnavailableSlots.hasOwnProperty(staff?.id)) {
        newUnavailableSlots[staff?.id] = defaultSlot;
      }
    });
  }

  Object.keys(newUnavailableSlots)?.forEach((nestedElemKey) => {
    const staffId = nestedElemKey;
    newUnavailableSlots[nestedElemKey].forEach((slots) => {
      const [startDate, endTime] = slots;
      const date = moment(AllStates.dayChangeState.dayRange?.start).format("YYYY-MM-DD");
      const event = {
        start_time: createDateTimeObject(date, startDate),
        end_time: createDateTimeObject(date, endTime),
        unavailableSlot: true,
      };
      if (!eventsByStaff[staffId]) {
        eventsByStaff[staffId] = [];
      }
      eventsByStaff[staffId]?.push(event);
    });
  });

  // Construct the desired structure
  const eventsDatav2 = staffObject?.map((staffElem) => ({
    name: staffElem.name,
    staffId: staffElem.id,
    events: eventsByStaff[staffElem?.id] || [], // Use events from eventsByStaff or an empty array if no events
  }));

  let commuteDataCalculate = calculateCommuteTimes(AllStates?.schedulerState?.result);

  commuteDataCalculate?.forEach((elem) => {
    const event = {
      start_time: createDateTimeObject(elem.date, elem.start_time),
      end_time: createDateTimeObject(elem.date, elem.end_time),
      commuteEvent: true,
    };

    eventsByStaff[elem?.staff.id]?.push(event);
  });

  return (
    <div className="mb-4">
      <ParentHorizontal eventsData={eventsDatav2 || []} AllStates={AllStates} />

      {/*  */}
      {openDetailSlotModal && (
        <ShowEventModal
          openDetailSlotModal={openDetailSlotModal}
          handleCancel={handleCancel}
          eventDetails={openDetailEventmodal}
          serviceState={AllStates?.schedulerState?.result}
        />
      )}

      {/* <AddNewEventModal addNewEventModal={addNewEventModal} closeModal={() => setAddNewEventModal(false)} /> */}
    </div>
  );
};

export default TransposedTable;

export const ShowEventModal = ({ openDetailSlotModal, handleCancel, eventDetails, serviceState }) => {
  const filter =
    serviceState?.filter((items) => {
      return items.id === eventDetails.id || eventDetails.serviceID;
    })[0] || [];
  // console.log("filter", eventDetails);
  // console.log("filter", filter);

  return (
    <>
      <AntDesignModal
        title={
          <HeadingComponent
            text={`${filter?.start_time} - ${filter?.end_time}`}
            fontSize="16px"
            fontWeight={700}
            color="#212121"
          />
        }
        component={
          <Row gutter={10}>
            <Col lg={15} className="d-flex my-2">
              <StaffIcon className="me-4" stroke="#FBCF34" />
              <HeadingComponent text={"Invoice: #1234213"} fontSize="14px" fontWeight={500} color="#424242" />
            </Col>
            <Col lg={15} className="d-flex my-2">
              <SchedulerServiceIcon className="me-3" stroke="#FBCF34" />

              <HeadingComponent
                text={`Service: ${eventDetails?.service?.name || filter?.service?.name}`}
                fontSize="14px"
                fontWeight={500}
                color="#424242"
              />
            </Col>
            <Col lg={15} className="d-flex my-2">
              <SchedulerWhenDateicon className="me-3" stroke="#FBCF34" />

              <HeadingComponent text={`When: ${eventDetails?.date}`} fontSize="14px" fontWeight={500} color="#424242" />
            </Col>{" "}
            <Col lg={15} className="d-flex my-2">
              <SchedulerWhenDateicon className="me-3" stroke="#FBCF34" />

              <HeadingComponent
                text={`Staff: ${eventDetails?.staff?.name || filter?.staff?.name}`}
                fontSize="14px"
                fontWeight={500}
                color="#424242"
              />
            </Col>
            <Col lg={15} className="d-flex my-2">
              <SchedulerAvatarClient className="me-3" stroke="#FBCF34" />
              <HeadingComponent
                text={`Client: ${eventDetails?.client?.name || filter?.client?.name}`}
                fontSize="14px"
                fontWeight={500}
                color="#424242"
              />
            </Col>
          </Row>
        }
        footer={
          <div className="d-flex justify-content-end">
            <CustomBtnWithIcon btnText={"Delete"} hideIcon noBackground className="me-2" width="90px" onClick={handleCancel} />
            <CustomBtnWithIcon btnText={"Edit"} hideIcon className="me-2" width="90px" onClick={handleCancel} />
          </div>
        }
        openModal={openDetailSlotModal}
        closeModal={handleCancel}
        onCancel={handleCancel}
        onOk={handleCancel}
        width={400}
      />
    </>
  );
};

export function calculateCommuteTimes(events) {
  const results = [];

  events.forEach((event) => {
    if (event.commute_duration !== 0) {
      // Convert start time to Date object
      const startDateTime = new Date(`${event.date}T${event.start_time}`);

      // Subtract commute duration in minutes
      const commuteStartTime = new Date(startDateTime.getTime() - event.commute_duration * 60000);

      // Format times to "HH:MM:SS"
      const formattedCommuteStartTime = commuteStartTime.toTimeString().split(" ")[0];
      const formattedStartTime = startDateTime.toTimeString().split(" ")[0];

      // Create commute object
      const commuteEvent = {
        ...event,
        start_time: formattedCommuteStartTime,
        end_time: formattedStartTime,
        commute: true,
        staff: event.staff,
        commute_duration: event.commute_duration,
      };

      // Push both the original and commute event
      results.push(commuteEvent);
    }
  });

  return results;
}

export const parseTimeString = (timeString) => {
  const [hours, minutes, seconds] = timeString?.split(":")?.map(Number);
  return { hours, minutes, seconds };
};

export const createDateTimeObject = (dateString, timeString) => {
  const { hours, minutes, seconds } = parseTimeString(timeString);
  const [year, month, day] = dateString?.split("-")?.map(Number);
  return new Date(year, month - 1, day, hours, minutes, seconds);
};
