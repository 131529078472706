import { Segmented } from "antd";
import React, { useState, useEffect } from "react";
import { AntdesignTable } from "../../../../../components/antDesignTable/AntdesignTable";
import CustomBtnWithIcon from "../../../../../components/buttonWithIcon";
import { staffTimeTrackingHeaders } from "../../../../../constants/CompanyTableHeaders/StaffManagementHeaders";
import AddTimeTrackingForm from "./AddTimeTrackingForm";
import { staffAPIsEndPoints } from "../../../../../constants/ApiEndPoints";
import { main_api } from "../../../../../api/axios_helper";
import { pushNotification } from "../../../../../util/Notification";

const TimeTracking = ({ staffId, staffTimeTrackingData, fetchSingleStaffTimeTracking  }) => {
  const [SelectTab, setSelectTab] = useState("Check in/out (s)");
  const [addTimeTrackingModal, setOpenAddTimeTrackingModal] = useState(false);

  const handleDelete = () => {};
  const handleEditRow = () => {};

  const fetchData = () => {
    if(SelectTab === 'Check in/out (s)'){
      fetchSingleStaffTimeTracking(staffId, 'in_progress=false&approved=true&decline=false')
    } else{
      fetchSingleStaffTimeTracking(staffId, 'in_progress=false&approved=false&decline=false&is_manual=true')
    }
  }

  useEffect(()=>{
    fetchData()
  },[SelectTab])

  const handleUpdate = async (api_data, record) => {
    try {
      var { data } = await main_api.put(`${staffAPIsEndPoints.UPDATE_TIME_TRACKING(record.id)}`, api_data);
      if (data.isSuccess) {
        fetchData()
        pushNotification(data.message);
      } else {
        pushNotification(data.message, "error");
      }
    } catch (error) {
      console.log(error)
    }
  }
 
  return (
    <div className="mt-2">
      {" "}
      <div className="col-md-3">
        <Segmented
          style={{ marginBottom: 8, background: "rgb(238,238,238,1)" }}
          onChange={(value) => setSelectTab(value)}
          options={["Check in/out (s)", "Manual in/out (s)"]}
          className=" p-1 "
          defaultValue={SelectTab}
          block
          size="large"
        />
      </div>
      {SelectTab === 'Check in/out (s)' && 
         <div className="d-flex justify-content-end mb-2">
          <CustomBtnWithIcon
            btnText={"Add Time Shifts"}
            hideIcon
            margin="0px 10px"
            onClick={() => setOpenAddTimeTrackingModal(true)}
          />
       </div>
      }
      <AntdesignTable columns={staffTimeTrackingHeaders({ handleDelete, handleEditRow, SelectTab, handleUpdate })} data={staffTimeTrackingData || []} />
      <AddTimeTrackingForm
        updateValues={{}}
        addNewEventModal={addTimeTrackingModal}
        staffId={staffId}
        // filterUpdateService={}
        // updateForm={}
        // requiredDropdowns={}
        closeAll={() => setOpenAddTimeTrackingModal(false)}
      />
    </div>
  );
};

export default TimeTracking;
