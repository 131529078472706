import React, { useEffect, useState } from "react";
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./resourceCalander.css";
import { SchedulerMonthScheduledIcon, SchedulerMonthUserIcon } from "../../../assets/rawSvgs/index";
import dayjs from "dayjs";
import truncateString from "../../../util/truncate";
import { ShowEventModal } from "../OneDayVertical";
import { AddNewEventModal } from "../../../pages/company/sheduler/SchedulerEvents";
import { checkPermission } from "../../../util/commonUtil";
import PermissionModal from "../../Modals/PermissionModal";
import { useSelector } from "react-redux";
import { pushNotification } from "../../../util/Notification";
const localizer = momentLocalizer(moment);

const MyCalendar = ({ clientPetServiceStates, AllStates }) => {
  const [openDetailSlotModal, setOpenDetailSlotModal] = useState({ details: "", condition: false });
  const [addNewEventSlotModal, setAddNewEventSlotModall] = useState({ details: "", condition: false });
  const { weekChangeState } = clientPetServiceStates;
  const { permissionManagement } = useSelector((state) => state);
  const [permissionPopUp, setPermissionPopUp] = useState(false);
  const [calenderEvents, setCalenderEvents] = useState([]);

  useEffect(() => {
    if (!AllStates.schedulerLoader) {
      const mapScheddata = clientPetServiceStates?.schedulerState?.result?.map((items) => {
        return {
          ...items,
          search: items?.staff?.name,
          start_time: items.start_time,
          end_time: items.end_time,
          service_name: items?.service?.name,
        };
      });

      const filterAddDates = mapScheddata?.map((items) => {
        return {
          ...items,
          id: items?.id || "",
          title: items?.service_name,
          start: dateFormatStartDate(items?.start_time, items.date),
          end: dateFormatEndDate(items?.end_time, items.date),
          resourceId: items?.staff?.id || "",
          clientName: items?.client.name,
        };
      });
      setCalenderEvents(filterAddDates);
    }
  }, [AllStates.schedulerLoader]);

  // weekChangeState?.dateRangeWeek?.end
  const dayFormat = (date, culture, localizer) => localizer.format(date, "dddd", culture);

  moment.updateLocale("en-gb", {
    week: {
      dow: 1, // Monday is the first day of the week
    },
    // Optionally, if you want to keep the time format to 12-hour, you can set it explicitly
    longDateFormat: {
      LT: "h:mm A", // 12-hour time format
      LTS: "h:mm:ss A", // 12-hour time format with seconds
      // Add other formats if needed
    },
  });

  const handleEventClick = (slotInfo) => {
    if (checkPermission(permissionManagement.permissions, "view_assign_service_request_staff")) {
      const dateTime = dayjs(slotInfo.start);
      const formattedDate = dateTime.format("YYYY-MM-DD");

      const formattedTime = slotInfo.start.toLocaleTimeString("en-US", { hour: "numeric", minute: "numeric", hour12: true });

      setOpenDetailSlotModal({
        details: {
          staffId: slotInfo.resourceId,
          slotTime: formattedTime,
          date: formattedDate,
          serviceID: slotInfo.id,
          title: slotInfo.title,
          serviceDetails: slotInfo.service,
          timeblock: slotInfo.time_block,
        },
        condition: true,
      });
    } else {
      setPermissionPopUp(true);
    }
  };
  const handleSelectSlot = (slotInfo) => {
    if (checkPermission(permissionManagement.permissions, "assign_service_request_staff")) {
      const dateTime = dayjs(slotInfo.start);
      const formattedDate = dateTime.format("YYYY-MM-DD");
      const today = dayjs().startOf("day");

      if (dateTime?.isBefore(today)) {
        // If the selected date is in the past, prevent any action
        pushNotification("You cannot assign a service to a past date.", "error");
        return;
      }
      const formattedTime = slotInfo.start.toLocaleTimeString("en-US", { hour: "numeric", minute: "numeric", hour12: true });

      setAddNewEventSlotModall({
        details: {
          staffId: slotInfo.resourceId,
          // slotTime: formattedTime,
          date: formattedDate,
        },
        condition: true,
      });
    } else {
      setPermissionPopUp(true);
    }
  };

  const handleEditEvent = () => {
    if (checkPermission(permissionManagement.permissions, "edit_assign_service_request_staff")) {
      setAddNewEventSlotModall({
        details: {
          staffId: openDetailSlotModal?.details.staffId,
          slotTime: openDetailSlotModal?.details.slotTime,
          date: openDetailSlotModal?.details.date,
          serviceID: openDetailSlotModal?.details.serviceID,
          title: openDetailSlotModal?.details.title,
          serviceDetails: openDetailSlotModal.details.serviceDetails,
          timeblock: openDetailSlotModal.details.timeblock,
        },
        condition: true,
      });
    } else {
      setPermissionPopUp(true);
    }
  };

  function convertDataStructure(data) {
    return data.map((item) => {
      return {
        ...item,
        title: item.name,
        id: item.id,
      };
    });
  }
  const convertedStaffData = convertDataStructure(clientPetServiceStates.allStaffState?.responseData);

  let staffCalenderData = convertedStaffData?.length > 0 ? convertedStaffData : ["NO STAFF AVAILABLE"];

  return (
    <div id="scheduler-week-calendarv2" className={`${AllStates.schedulerLoader ? "filtering" : null} mt-3 pb-4`}>
      <Calendar
        localizer={localizer}
        events={calenderEvents}
        resources={staffCalenderData}
        resourceIdAccessor="id"
        resourceTitleAccessor="name"
        onSelectEvent={handleEventClick}
        onSelectSlot={handleSelectSlot}
        defaultView="week"
        views={["week"]}
        drilldownView={Views.WEEK}
        date={weekChangeState?.dateRangeWeek?.start}
        startAccessor="start"
        endAccessor="end"
        eventPropGetter={(event, start, end, isSelected) => {
          return {
            style: scheduledEvnetsColor,
          };
        }}
        formats={formats}
        components={{
          toolbar: () => null,
          event: CustomComponents,
        }}
        onSelecting={() => false}
        selectable="ignoreEvents"
        // className="custom-week-calender"
        dayLayoutAlgorithm="no-overlap"
        // timeslots={1}
        // step={15}
      />

      {openDetailSlotModal.condition && (
        <ShowEventModal
          openDetailSlotModal={openDetailSlotModal.condition}
          handleCancel={() => {
            setOpenDetailSlotModal({ ...openDetailSlotModal, condition: false });
          }}
          eventDetails={openDetailSlotModal.details}
          serviceState={AllStates?.schedulerState?.result}
          handleEditEvent={handleEditEvent}
          fetchServices={() => {
            AllStates.WeekFetchAllServices();
            setOpenDetailSlotModal({ ...openDetailSlotModal, condition: false });
          }}
        />
      )}
      {addNewEventSlotModal.condition && (
        <AddNewEventModal
          clientPetServiceDropdowns={AllStates}
          addNewEventModal={addNewEventSlotModal.condition}
          updateValues={addNewEventSlotModal.details}
          oneDayVerticalFrom={true}
          updateForm={true}
          closeModal={() => setAddNewEventSlotModall({ ...openDetailSlotModal, condition: false })}
        />
      )}
      <PermissionModal onclose={() => setPermissionPopUp(false)} openModal={permissionPopUp} />
    </div>
  );
};

export default MyCalendar;

export function dateFormatStartDate(start_time, date) {
  const [year, month, day] = date.split("-").map(Number);

  const [startHours, startMinutes, startSeconds] = start_time.split(":").map(Number);

  const startDate = new Date(year, month - 1, day, startHours, startMinutes, startSeconds);

  return startDate;
}

export function dateFormatEndDate(end_time, date) {
  const [year, month, day] = date.split("-").map(Number);

  const [endHours, endMinutes, endSeconds] = end_time.split(":").map(Number);

  const endDate = new Date(year, month - 1, day, endHours, endMinutes, endSeconds);

  return endDate;
}

const scheduledEvnetsColor = {
  fontColor: "rgb(24, 123, 239)",
  backgroundColor: "rgb(229, 246, 255)",
  border: "1px solid rgb(24, 123, 239)",
  color: "rgb(24, 123, 239)",
};

const formats = {
  eventTimeRangeFormat: () => {
    return "";
  },
};

const CustomComponents = ({ event, title = "" }) => {
  return (
    <div className="d-flex ">
      <div className="d-flex align-items-center">
        <div className="d-flex flex-column">
          <span>
            <span className="ms-1 me-1">
              <SchedulerMonthScheduledIcon width="12px" height="12px" stroke="#187BEF" />
            </span>
            {truncateString(title, 7)}
            <span className="ms-1 me-1">
              <SchedulerMonthUserIcon width="12px" height="12px" stroke="#187BEF" />
            </span>
            {truncateString(event?.clientName, 10)}
          </span>
        </div>
      </div>
    </div>
  );
};
