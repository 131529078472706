import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { main_api } from "../../../../api/axios_helper"
import DeleteIcon from "../../../../assets/icons/DeleteIcon"
import EditIconAlt from "../../../../assets/icons/EditIconAlt"
import EditPenIcon from "../../../../assets/icons/EditPenIcon"
import Button from "../../../../components/Button"
import SearchInput from "../../../../components/SearchInput"
import Text from "../../../../components/Text"

export default function Invoicing() {
  const [isLoading, setIsloading] = useState(false)
  const [activeButton, setActiveButton] = useState("all")
  const [isModalOpen, setModalOpen] = useState(false)

  const [list, setList] = useState([
    {
      title: "Credit Card Declined",
      desc: "Lorem ipsum dolor sit amet consectetur. Amet pellentesque interdum nec odio."
    }
  ])

  const GetPets = () => {
    setIsloading(true)
    main_api
      .get("/api/v1/client_pet/")
      .then(({ data }) => {
        setIsloading(false)
      })
      .catch(error => {
        console.log(error?.response?.data)
        setIsloading(false)
      })
  }

  useEffect(() => {
    // GetPets()
  }, [])

  const edit = () => {}

  return (
   <>
   <h1>Invoicing</h1>
   </>
  )
}

const Container = styled.div`
  display: flex;
  margin-top: 20px;
  align-items: center;
`

const Icon = styled.svg`
  height: 24px;
  width: 24px;
  resize-mode: contain;
  stroke: ${props => props.color ?? "#000"};
  fill: transparent;
  cursor: pointer;
`

const ListContainer = styled.div`
  margin-left: 121px;
  margin-top: 40px;
`

const ListItem = styled.div`
  width: 671.02px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const IconContainer = styled.div`
  display: flex;
`


