import { createSlice } from "@reduxjs/toolkit";
import { main_api } from "../../api/axios_helper";

import { staffAPIsEndPoints } from "../../constants/ApiEndPoints";

const initialState = {
  staffSingleDetails: {
    isLoading: false,
    responseData: null,
    staffList: [],
  },
  staffAllDetails: {
    isLoading: false,
    responseData: null,
    staffList: [],
  },
  staffAvailConfig: {
    isLoading: false,
    responseData: null,
  },
  staffStatusList: {
    statusList: [],
  },
  staffTimeTrack: {
    isLoading: false,
    responseData: [],
  },
  staffMileageTrack: {
    isLoading: false,
    responseData: [],
  },
};

const staffManagement = createSlice({
  name: "staffManagement",
  initialState,
  reducers: {
    fetchSingleStaffSlice(state, action) {
      state.staffSingleDetails.isLoading = action.payload.isLoading;
      if (action.payload?.responseData) {
        state.staffSingleDetails.responseData = action.payload?.responseData;
      }
    },
    fetchAllStaffSlice(state, action) {
      state.staffAllDetails.isLoading = action.payload.isLoading;

      if (action.payload?.responseData) {
        const staffList = action.payload?.responseData?.map((items) => {
          return {
            value: items.id,
            label: items.name,
          };
        });
        state.staffAllDetails.responseData = action.payload?.responseData;
        state.staffAllDetails.staffList = staffList;
      }
    },
    fetchStaffConfigSlice(state, action) {
      state.staffAvailConfig.isLoading = action.payload.isLoading;
      if (action.payload?.responseData) {
        state.staffAvailConfig.responseData = action.payload?.responseData;
      }
    },
    fetchStatusListSlice(state, action) {
      state.staffAvailConfig.isLoading = action.payload.isLoading;
      if (action.payload?.responseData) {
        let maplist = action.payload?.responseData?.map((items) => {
          return {
            value: items.id,
            label: items.emoji + " " + items.status,
          };
        });
        state.staffStatusList.statusList = maplist || [];
      }
    },
    fetchSingleStaffTimeTrack(state, action) {
      state.staffTimeTrack.isLoading = action.payload.isLoading;
      if (action.payload?.responseData) {
        state.staffTimeTrack.responseData = action.payload?.responseData;
      }
    },
    fetchSingleStaffMileageTrack(state, action) {
      state.staffMileageTrack.isLoading = action.payload.isLoading;
      if (action.payload?.responseData) {
        state.staffMileageTrack.responseData = action.payload?.responseData;
      }
    },
  },
});

// Reducer
export default staffManagement.reducer;

export const fetchSingleStaffApi = (id) => {
  return async (dispatch, getState) => {
    try {
      const companyId = getState().auth?.loginResponse?.selectCompany?.[0]?.company_id;
      dispatch(staffManagement.actions.fetchSingleStaffSlice({ isLoading: true }));
      const { data } = await main_api.get(staffAPIsEndPoints.FETCH_SINGLE_STAFF(id, companyId));
      const { data: staffAvailConfgData } = await main_api.get(staffAPIsEndPoints.GET_STAFF_CONFIGURE_AVAIL(id));
      //
      dispatch(staffManagement.actions.fetchStaffConfigSlice({ isLoading: false, responseData: staffAvailConfgData }));
      //
      const { data: staffStaus } = await main_api.get(staffAPIsEndPoints.GET_STAFF_STATUS(id));
      // const { data: staffTimeTrack } = await main_api.get(staffAPIsEndPoints.GET_STAFF_TIME_TRACK(id));
      // dispatch(staffManagement.actions.fetchSingleStaffTimeTrack({ isLoading: false, responseData: staffTimeTrack }));
      dispatch(staffManagement.actions.fetchStatusListSlice({ isLoading: false, responseData: staffStaus?.result }));
      dispatch(staffManagement.actions.fetchSingleStaffSlice({ isLoading: false, responseData: data }));
    } catch (error) {
      dispatch(staffManagement.actions.fetchSingleStaffSlice({ isLoading: false, responseData: null }));
    }
  };
};

export const fetchSingleStaffTimeTracking = (id, queryParams = "") => {
  return async (dispatch, getState) => {
    try {
      const { data: staffTimeTrack } = await main_api.get(staffAPIsEndPoints.GET_STAFF_TIME_TRACK(id, queryParams));
      dispatch(staffManagement.actions.fetchSingleStaffTimeTrack({ isLoading: false, responseData: staffTimeTrack }));
    } catch (error) {
      dispatch(staffManagement.actions.fetchSingleStaffSlice({ isLoading: false, responseData: null }));
    }
  };
};

export const fetchSingleStaffMileageTracking = (id, queryParams = "") => {
  return async (dispatch, getState) => {
    try {
      const { data: staffMileageTrack } = await main_api.get(staffAPIsEndPoints.GET_STAFF_MILEAGE_TRACK(id, queryParams));
      dispatch(staffManagement.actions.fetchSingleStaffMileageTrack({ isLoading: false, responseData: staffMileageTrack }));
    } catch (error) {
      dispatch(staffManagement.actions.fetchSingleStaffSlice({ isLoading: false, responseData: null }));
    }
  };
};

export const fetchAllStaffApi = (searchTerm = "") => {
  return async (dispatch, getState) => {
    try {
      const companyId = getState().auth?.loginResponse?.selectCompany?.[0]?.company_id;
      dispatch(staffManagement.actions.fetchAllStaffSlice({ isLoading: true }));
      let endpoint = `usercompanyrole__company=${companyId}&search=${searchTerm}`;
      if (searchTerm) {
        endpoint = `usercompanyrole__company=${companyId}&search=${searchTerm}`;
      } else {
        endpoint = `usercompanyrole__company=${companyId}`;
      }

      const { data } = await main_api.get(staffAPIsEndPoints.LIST_STAFF(endpoint));

      dispatch(staffManagement.actions.fetchAllStaffSlice({ isLoading: false, responseData: data }));
    } catch (error) {
      dispatch(staffManagement.actions.fetchAllStaffSlice({ isLoading: false, responseData: error }));
    }
  };
};
