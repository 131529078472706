import { connect } from "react-redux";
import { loadWorkQueue, loadWorkQueueDetail, openWorkQueueDetailModal } from "../../redux/slices/tasksSlice";
import WorkQueueDetail from "../../components/WorkQueueDetail";


const mapStateToProps = (state) => {
  return {
    isWorkQueuedetailModalOpen: state.tasksManagement.isWorkQueuedetailModalOpen,
    workQueueDetailResponse: state.tasksManagement.workQueueDetailResponse,
    workQueueResponse: state.tasksManagement.workQueueResponse, 
    allStaffState: state.staffManagement.staffAllDetails,
    clientManagementState: state.clientManangement.clientResponse,
    tasksListResponse: state.tasksManagement.tasksListResponse,
    companyUsersListResponse: state.companyUser.companyUsersListResponse,
  };
};

const mapDispatchToProps = (dispatch) => ({
  openWorkQueueDetailModal: (isOpen) => {
    dispatch(openWorkQueueDetailModal(isOpen));
  },
  loadWorkQueue: (search) => {
    dispatch(loadWorkQueue(search));
  },
  loadWorkQueueDetail: (id) => {
    dispatch(loadWorkQueueDetail(id));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(WorkQueueDetail);
