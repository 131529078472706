import React from "react";
import { DatePicker, Divider, Flex, Form, Input, message, Modal, Select, Typography, Upload } from "antd";
import { ReactComponent as DefaultImageIcon } from "../../../assets/rawSvgs/defaultImage.svg";
import CustomInput from "../../CustomInput";
import styled from "styled-components";
import CustomBtnWithIcon from "../../buttonWithIcon";
import { CustomModal } from "../ModalStyles";
const { Dragger } = Upload;

const EditVetModal = ({ isLoading, isModalOpen, setModalOpen, onFinish, editVetValues }) => {
  const [form] = Form.useForm();
  form.resetFields();
  form.setFieldsValue({
    name: editVetValues?.name,
    primary_phone: editVetValues?.primary_phone,
    email: editVetValues?.email,
    address: editVetValues?.address,
    notes: editVetValues?.note,
  });
  return (
    <CustomModal
      open={isModalOpen}
      title="Edit Vet"
      width="748px"
      heigth="688px"
      onCancel={() => setModalOpen(false)}
      footer={null}
      maskClosable={false}
    >
      <Divider />
      <Form name="addVetForm" onFinish={onFinish} form={form} layout="vertical">
        <FormItem
          name="name"
          label="Vet Name"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input placeholder="Enter name of pet" required />
        </FormItem>
        <FieldWraper>
          <FormItem name="primary_phone" label="Primary Phone">
            <Input placeholder="Enter Number" />
          </FormItem>
          <FormItem name="email" label="Email">
            <Input placeholder="Enter Email" />
          </FormItem>
        </FieldWraper>
        <FormItem name="address" label="Address">
          <Input placeholder="Enter Address" />
        </FormItem>
        <FormItem name="notes" label="Notes">
          <Input.TextArea placeholder="Type notes here..." />
        </FormItem>
        <Divider />
        <div className="d-flex justify-content-end">
          <CustomBtnWithIcon
            btnText={"Cancel"}
            margin="0px 5px"
            noBackground
            hideIcon={true}
            onClick={() => setModalOpen(false)}
          />
          <CustomBtnWithIcon btnText={"Save Changes"} type="submit" hideIcon={true} />
        </div>
      </Form>
    </CustomModal>
  );
};

const FieldWraper = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
`;

const FormItem = styled(Form.Item)`
  width: 100%;
`;

export default EditVetModal;
