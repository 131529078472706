import React, { useEffect, useLayoutEffect, useState } from "react";
import { Progress } from "antd";
import { Button } from "antd";
import {
  ModalContainer,
  Modal,
  ModalHeader,
  ModalHeaderText,
  ModalClose,
  ModalContent,
  ModalContentLeftBar,
  SchedulerTextWrapper,
  SchedulerText,
  GreenCheckIconContainer,
  TextProgressWrapper,
  TextProgress,
  ModalContentRightSide,
  MainContentTopHeading,
  VideoPlayer,
  MainContentNavHeading,
  SkipVideoButton,
} from "./ComapnyModalStyles";
import { ReactComponent as CrossCloseIcon } from "../../../assets/rawSvgs/crossIcon.svg";
import { ReactComponent as CheckGreenTick } from "../../../assets/rawSvgs/checkGreenIcon2.svg";
import {
  InvoicingComponent,
  StaffManagementComponent,
  TaskManagementComponent,
  ManageingClientComponent,
  OnboardingComponent,
} from "./CompanysidebarComponents";
import { progressBarGreenColor } from "../../../constants/commonStyles";
import styled from "styled-components";

import { ReactComponent as GreaterThanIcon } from "../../../assets/rawSvgs/greaterThanIcon.svg";
import { ReactComponent as LessThanIcon } from "../../../assets/rawSvgs/lessThanIcon.svg";
import { ReactComponent as PlayIconButton } from "../../../assets/rawSvgs/playIcon.svg";
import Video1 from "../../../assets/wekcomeVideo.mp4";
import {
  CaretRightOutlined,
  CheckCircleTwoTone,
  PauseOutlined,
  StepBackwardOutlined,
  StepForwardOutlined,
} from "@ant-design/icons";
import { useSelector } from "react-redux";
import { main_api } from "../../../api/axios_helper";
import { companyAPIsEndPoints } from "../../../constants/ApiEndPoints";
import { pushNotification } from "../../../util/Notification";

//
const commonText = `Every pet care business needs great pet customers! You have already added your first test client, review our video below
on how to add a new client to your account`;
//
const subText = ``;
const CompanyWelcomeModal = ({ isLoading, isModalOpen, setModalOpen, title, children, onboardingData, setOnboardingData }) => {
  const stateAuth = useSelector((state) => state.auth);
  const [activeSidebarNav, setActiveSideBarNav] = useState("Managing Clients");
  const [activeSideNavIndex, setActiveSideNavIndex] = useState(0);
  const [progressBar, setProgressBar] = useState(0);
  const [isHovering, setIsHovering] = useState(false);
  const [first, setfirst] = useState();
  const [inc, setInc] = useState(0);
  const sideNavList = ["Managing Clients", "Invoicing", "Staff Management", "Task Management"];
  const [stepList, setStepList] = useState([]);
  const [selectedStep, setSelectedStep] = useState(0);

  const handleNext = (index) => {
    if (selectedStep + 1 <= stepList.length - 1) {
      setSelectedStep(selectedStep + 1);
      if (selectedStep + 1 > onboardingData.current_step) {
        const companyId = stateAuth?.loginResponse?.selectCompany?.[0]?.company_id;
        main_api
          .post(companyAPIsEndPoints.UPDATE_ONBOARDING_STATUS, { company_id: companyId, current_step: selectedStep + 1 })
          .then(({ data }) => {
            setOnboardingData(data.result);
            setSelectedStep(selectedStep + 1);
          })
          .catch((error) => {
            pushNotification("Something went wrong!", "error");
            // console.log(error);
          });
      } else {
        setSelectedStep(selectedStep + 1);
      }
    }
    // setActiveSideNavIndex((prevIndex) => {
    //   const currIndex = prevIndex < sideNavList.length - 1 ? prevIndex + 1 : prevIndex;
    //   setActiveSideBarNav(sideNavList[currIndex]);
    //   return currIndex;
    // });
  };

  const handlePrev = (index) => {
    if (selectedStep - 1 >= 0) {
      setSelectedStep(selectedStep - 1);
    }
    // setActiveSideNavIndex((prevIndex) => {
    //   const currIndex = prevIndex > 0 ? prevIndex - 1 : prevIndex;
    //   setActiveSideBarNav(sideNavList[currIndex]);
    //   return currIndex;
    // });
  };

  useLayoutEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = "hidden";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isModalOpen]);

  useEffect(() => {
    main_api
      .get(companyAPIsEndPoints.ONBOARDING("checked=true"))
      .then(({ data }) => {
        setStepList(data.result);
        if (data.result.length && !data.result[onboardingData.current_step]) {
          setSelectedStep(0);
        } else {
          setSelectedStep(onboardingData.current_step);
        }
      })
      .catch((error) => {
        pushNotification("Something went wrong!", "error");

        // console.log(error);
      });
  }, []);

  useEffect(() => {
    switch (activeSideNavIndex) {
      case 0:
        setfirst(<ManageingClientComponent {...commonProps} />);
        break;
      case 1:
        setfirst(<InvoicingComponent {...commonProps} />);
        break;
      case 2:
        setfirst(<StaffManagementComponent {...commonProps} />);
        break;
      case 3:
        setfirst(<TaskManagementComponent {...commonProps} />);
        break;
      default:
        setfirst(null); // Return null or default component if none of the cases match
        break;
    }
  }, [activeSideNavIndex, activeSidebarNav]);
  const commonProps = {
    handleNext,
    handlePrev,
    activeSidebarNav: activeSidebarNav,
  };

  const handleGetIndexSidebar = (index, navHeading) => {
    setActiveSideNavIndex(index);
    setActiveSideBarNav(navHeading);
  };

  const obj = {
    0: <ManageingClientComponent {...commonProps} />,
    1: <InvoicingComponent {...commonProps} />,
    2: <StaffManagementComponent {...commonProps} />,
    3: <TaskManagementComponent {...commonProps} />,
  };
  const [isPlaying, setIsPlaying] = React.useState(false);
  const videoRef = React.useRef(null);
  const handlePlay = () => {
    setIsPlaying(true);
    videoRef.current.play();
  };
  const handleVideoClick = () => {
    if (isPlaying) {
      setIsPlaying(false);
      videoRef.current.pause();
      return;
    }
    setIsPlaying(true);
    videoRef.current.play();
  };

  const updateProgress = () => {
    const currentTime = videoRef.current.currentTime;
    const duration = videoRef.current.duration;
    const progressBar = (currentTime / duration) * 100;
    setProgressBar(progressBar);
  };

  const skipForward = () => {
    videoRef.current.currentTime += 10;
  };

  const skipBackward = () => {
    videoRef.current.currentTime -= 10;
  };

  const completeOnboarding = () => {
    setModalOpen();
    const companyId = stateAuth?.loginResponse?.selectCompany?.[0]?.company_id;
    main_api
      .post(companyAPIsEndPoints.UPDATE_ONBOARDING_STATUS, { company_id: companyId, is_seen: true })
      .then(({ data }) => {
        setOnboardingData(data.result);
      })
      .catch((error) => {
        pushNotification("Something went wrong!", "error");

        // console.log(error);
      });
  };

  const skipOnboarding = () => {
    setModalOpen();
    const companyId = stateAuth?.loginResponse?.selectCompany?.[0]?.company_id;
    main_api
      .post(companyAPIsEndPoints.UPDATE_ONBOARDING_STATUS, { company_id: companyId, is_seen: false })
      .then(({ data }) => {
        setOnboardingData(data.result);
      })
      .catch((error) => {
        pushNotification("Something went wrong!", "error");

        // console.log(error);
      });
  };
  return (
    <>
      <ModalContainer visible={isModalOpen}>
        <Modal>
          <ModalHeader>
            <ModalHeaderText>{`Hi ${stateAuth?.loginResponse.LoggedInUserData.name || ""} 👋 Welcome to Furgis`}</ModalHeaderText>
            <ModalClose onClick={() => setModalOpen(false)}>
              <CrossCloseIcon />
            </ModalClose>
          </ModalHeader>
          <ModalContent>
            <ModalLeftSideBar
              selectedStep={stepList[selectedStep]}
              stepList={stepList}
              setActiveSideBarNav={setActiveSideBarNav}
              activeSidebarNav={activeSidebarNav}
              handleGetIndexSidebar={handleGetIndexSidebar}
              onboardingData={onboardingData}
            />

            <ModalContentRightSide>
              <div>
                <OnboardingComponent {...commonProps} selectedStep={stepList[selectedStep]} />
              </div>

              {selectedStep === stepList.length - 1 ? (
                <SkipVideoButton onClick={() => completeOnboarding()}> Finish</SkipVideoButton>
              ) : (
                <SkipVideoButton onClick={() => skipOnboarding()}> Skip for now</SkipVideoButton>
              )}
            </ModalContentRightSide>
          </ModalContent>
        </Modal>
      </ModalContainer>
    </>
  );
};

export default CompanyWelcomeModal;

// const TextWithProgressBar = ({ active, progressText, progressBarCompletePerc, onClickTab }) => {
//   return (
//     <TextProgressWrapper onClick={onClickTab}>
//       <TextProgress active={active}>{progressText}</TextProgress>
//       <Progress percent={progressBarCompletePerc} size="small" showInfo={false} strokeColor={progressBarGreenColor} />
//     </TextProgressWrapper>
//   );
// };

const TextWithProgressBar = ({ active, completed, progressText, progressBarCompletePerc, onClickTab }) => {
  return (
    <TextProgressWrapper onClick={onClickTab}>
      {active ? (
        <>
          <TextProgress active={active}>{progressText}</TextProgress>
          <Progress percent={progressBarCompletePerc} size="small" showInfo={false} strokeColor={progressBarGreenColor} />
        </>
      ) : (
        <div className="d-flex" style={{ flexDirection: "row" }}>
          {completed ? (
            <CheckCircleTwoTone style={{ marginRight: "4px" }} twoToneColor="#52c41a" />
          ) : (
            <CheckCircleTwoTone style={{ marginRight: "4px" }} twoToneColor="lightgrey" />
          )}
          <TextProgress active={active}>{progressText}</TextProgress>
        </div>
      )}
    </TextProgressWrapper>
  );
};

const sideNavList = {
  navitem1: "Managing Clients",
  navitem2: "Invoicing",
  navitem3: "Staff Management",
  navitem4: "Task Management",
};
const ModalLeftSideBar = ({
  stepList,
  activeSidebarNav,
  setActiveSideBarNav,
  handleGetIndexSidebar,
  selectedStep,
  onboardingData,
}) => {
  const calculateCumulativeProgress = (index) => {
    let cumulativeProgress = 0;
    for (let i = 0; i <= index; i++) {
      cumulativeProgress += onboardingData.current_step >= i ? stepList[i].weightage : 0;
    }
    return cumulativeProgress;
  };

  return (
    <>
      <ModalContentLeftBar>
        {/* <SchedulerTextWrapper>
          <SchedulerText>Scheduler</SchedulerText>
          <GreenCheckIconContainer>
            <CheckGreenTick />
          </GreenCheckIconContainer>
        </SchedulerTextWrapper> */}
        {/* {stepList.map((step, index) => {
          const cumulativeProgress = calculateCumulativeProgress(index);
          return (
            <TextWithProgressBar
              progressText={step.name}
              progressBarCompletePerc={cumulativeProgress}
              active={selectedStep === step.id}
            />
          );
        })} */}
        {/* <TextWithProgressBar
          progressText={sideNavList.navitem1}
          progressBarCompletePerc={20}
          active={activeSidebarNav === sideNavList.navitem1}
        />
        <TextWithProgressBar
          progressText={sideNavList.navitem2}
          progressBarCompletePerc={30}
          active={activeSidebarNav === sideNavList.navitem2}
        />
        <TextWithProgressBar
          progressText={sideNavList.navitem3}
          progressBarCompletePerc={40}
          active={activeSidebarNav === sideNavList.navitem3}
          // onClickTab={() => {
          //   handleGetIndexSidebar(2, "Staff Management");
          // }}
        />
        <TextWithProgressBar
          progressText={sideNavList.navitem4}
          progressBarCompletePerc={80}
          active={activeSidebarNav === sideNavList.navitem4}
          // onClickTab={() => {
          //   handleGetIndexSidebar(3, "Task Management");
          // }}
        /> */}

        {stepList.map((step, index) => {
          const cumulativeProgress = calculateCumulativeProgress(index);
          const isCompleted = onboardingData.current_step > index;
          const isActive = onboardingData.current_step === index;

          return (
            <TextWithProgressBar
              key={index}
              progressText={step.name}
              progressBarCompletePerc={cumulativeProgress}
              active={isActive}
              completed={isCompleted}
              onClickTab={() => handleGetIndexSidebar(index, step.name)}
            />
          );
        })}
      </ModalContentLeftBar>
    </>
  );
};

const RoundedButton = styled.button`
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 1px solid #fbcf34;
  background-color: #ffffff;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: ${(props) => props.margin};
  cursor: pointer;
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;
