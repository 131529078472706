import styled from "styled-components";
import { ReactComponent as DeleteIcon } from "../../../../../assets/rawSvgs/tableDeleteIcon.svg";
import { ReactComponent as EditIcon } from "../../../../../assets/rawSvgs/editIconPencil.svg";
import CustomInput from "../../../../../components/CustomInput";
import { Checkbox, DatePicker, InputNumber, Select, TimePicker, Upload } from "antd";
import { ReactComponent as DefaultImageIcon } from "../../../../../assets/rawSvgs/defaultImage.svg";
import { useState } from "react";
import AddFieldModal from "./modals/addFieldModal";
import DeleteConfirmationRowModal from "../../../../../components/Modals/DeleteConfirmationRowModal";
import { useSelector } from "react-redux";
import { updateCompanyGroups } from "../../../../../redux/platformSlices/CompanyDetail";
import { useDispatch } from "react-redux";
import { pushNotification } from "../../../../../util/Notification";

const propertiesMapping = {
  staff_visible: "Staff Visible",
  client_visible: "Client Visible",
  client_editable: "Client Editable",
  required: "Required",
};

const Field = ({ field, fieldIndex, groupIndex, selectedTab, isNonCustomField = false, group }) => {
  const dispatch = useDispatch();
  const [isEditFieldModalShown, setIsEditModalShown] = useState(false);
  const [isDeleteFieldOpen, setIsDeleteFieldOpen] = useState(false);

  const auth = useSelector((state) => state.auth);
  const companyId = auth.loginResponse?.selectCompany?.[0]?.company_id || null;

  const details = useSelector((state) => state.companyDetail.companyDetailResponse);
  const clientGroups = details?.result?.client_custom_fields?.groups || [];
  const petGroups = details?.result?.pet_custom_fields?.groups || [];

  const groups = selectedTab === "ClientField" ? clientGroups : petGroups;

  const marginValue = group?.name === undefined || group?.name === null ? "20px 0px" : "20px 20px";

  const deleteField = async () => {
    const updatedGroups = [...groups];
    const updateFields = [...updatedGroups[groupIndex].fields];
    updateFields.splice(fieldIndex, 1);

    updatedGroups[groupIndex] = { ...updatedGroups[groupIndex], fields: updateFields };
    const customFields = {
      groups: [...updatedGroups],
    };
    const data = selectedTab === "ClientField" ? { client_custom_fields: customFields } : { pet_custom_fields: customFields };
    await dispatch(updateCompanyGroups(companyId, { ...data }));
    setIsDeleteFieldOpen(false);
  };

  const { Dragger } = Upload;

  const renderFieldType = () => {
    const componentMapping = {
      image: (
        <Dragger
          {...{
            name: "file",
            multiple: true,
            disabled: true,
          }}
        >
          <p className="ant-upload-drag-icon">
            <DefaultImageIcon />
          </p>
          <p className="ant-upload-text">Upload image of your pet</p>
          <p className="ant-upload-hint">PNG, JPG, JPEG</p>
        </Dragger>
      ),
      date: <DatePicker placeholder={field.preview_text} style={{ width: "100%", height: "40px" }} disabled />,
      time: <TimePicker placeholder={field.preview_text} style={{ width: "100%" }} disabled />,
      number: <InputNumber placeholder={field.preview_text} style={{ width: "100%" }} disabled />,
      text: (
        <CustomInput padding="10px 14px" height="50px" border="1px solid #CAD3DC" readOnly placeholder={field.preview_text} />
      ),
      dropdown: (
        <Select placeholder={field.preview_text} style={{ width: "100%" }} disabled={field.disabled}>
          {field?.options &&
            field?.options.map((option) => (
              <Select.Option key={`option-${option}`} value={option} disabled>
                {option}
              </Select.Option>
            ))}
        </Select>
      ),
    };

    return componentMapping[field.field_type];
  };

  return (
    <>
      <Article margin={marginValue}>
        <Main>
          <Title>{field.field_name}</Title>
          <ActionWrapper>
            <div style={{ width: "100%", marginBottom: "10px 20px 10px 10px" }}>{renderFieldType()}</div>

            {!isNonCustomField ? (
              <>
                <Button onClick={() => setIsDeleteFieldOpen(true)}>
                  <DeleteIcon width={"18px"} height="18px" />
                </Button>
                <Button>
                  <EditIcon onClick={() => setIsEditModalShown(true)} width={"18px"} height="18px" />
                </Button>
              </>
            ) : null}
          </ActionWrapper>

          {field.properties && (
            <FiltersWrapper>
              {Object.keys(field.properties).map((key) => (
                <CheckboxLabel key={`property-${key}`}>
                  <CheckBoxContainer>
                    <Checkbox checked={field.properties[key]} onChange={() => {}} />
                  </CheckBoxContainer>
                  {propertiesMapping[key]}
                </CheckboxLabel>
              ))}
            </FiltersWrapper>
          )}
        </Main>
      </Article>
      <AddFieldModal
        isModalOpen={isEditFieldModalShown}
        setModalOpen={setIsEditModalShown}
        groupIndex={groupIndex}
        isEditMode
        field={field}
        group={group}
        selectedTab={selectedTab}
      />
      <DeleteConfirmationRowModal
        onclose={() => setIsDeleteFieldOpen(false)}
        handleConfirmDeleteRows={deleteField}
        openConfimrationDeleteModal={isDeleteFieldOpen}
      />
    </>
  );
};

export default Field;

const FiltersWrapper = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  margin-bottom: 20px;
`;

const ActionWrapper = styled.div`
  display: flex;
  gap: 20px;
  align-items: baseline;
  margin-bottom: 20px;
`;

const Title = styled.h2`
  font-family: "Helvetica Neue", sans-serif;
  font-weight: bold;
  font-size: 18px;
  line-height: 20px;
  color: #000000;
  text-align: left;
`;

const Article = styled.article`
  border-width: 1px 1px 1px 0px;
  border-style: solid;
  border-color: #e0e0e0;
  background: #ffffff;
  padding: 10px;
  margin: ${({ margin }) => margin};
  border-left: 5px solid #d9d9d9;
  border-radius: 5px;
`;

const Main = styled.div`
  margin: 15px 10px;
`;

const Button = styled.button`
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CheckboxLabel = styled.label`
  font-size: 14px;
  font-weight: 400;
`;

const CheckBoxContainer = styled.span`
  height: 12px;
  width: 12px;
  border-radius: 1px;
  border: 1px solid #a2c4d4;
  margin-right: 10px;
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;
