import React, { useState, useEffect } from 'react';
import { Select, Checkbox } from 'antd';

const { Option } = Select;

const CustomDropdown = ({ options, defaultValue,onChange }) => {
  const [selectedValues, setSelectedValues] = useState([]);

  useEffect(() => {
    if (defaultValue) {
      const defaultIds = defaultValue.map(item => item.id);
      setSelectedValues(defaultIds);
    }
  }, [defaultValue]);

  const handleCheckboxChange = (optionValue, checked) => {
    if (checked) {
      setSelectedValues([...selectedValues, optionValue]);
    } else {
      setSelectedValues(selectedValues.filter(value => value !== optionValue));
    }
  };

  const handleSelectChange = (value) => {
    setSelectedValues(value);
    onChange(value);
  };

  return (
    <Select
      mode="multiple"
      style={{
        width: '100%',
      }}
      placeholder="Select"
      onChange={handleSelectChange}
      value={selectedValues}
      options={options}
    />
      
  );
};



export default CustomDropdown;


