import { connect } from "react-redux";
import { fetchLoggedInClientApi, DeleteSingleClientsApi, updateSingleClientsApi } from "../redux/slices/ClientSlice";
import Settings from "../pages/client/settings/Settings";
import { fetchCompDetailsApi, fetchCompStripeDetailsApi } from "../redux/slices/auth";

const mapStateToProps = (state) => {
  return {
    clientSingleDetails: state.clientManangement.clientSingleDetails,
    clientDeleteResponse: state.clientManangement.clientDeleteResponse,
    clientEditState: state.clientManangement.clientEditResponse,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchLoggedInClientInfo: () => {
    dispatch(fetchLoggedInClientApi());
  },
  deleteSingleClient: (id) => {
    dispatch(DeleteSingleClientsApi(id));
  },
  updateSingleClient: (data) => {
    dispatch(updateSingleClientsApi(data));
  },
  fetchCompDetails: () => {
    dispatch(fetchCompDetailsApi());
  },
  fetchCompStripeDetails: () => {
    dispatch(fetchCompStripeDetailsApi());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
