import { createSlice } from "@reduxjs/toolkit";
import { main_api } from "../../api/axios_helper";
import { companyAPIsEndPoints } from "../../constants/ApiEndPoints";

const initialState = {
  allChannels: [
    {
      channelId: "1",
      channelName: "pet Walk",
      channelMsgs: [
        {
          msg: "hi how are you",
        },
      ],
      channelMembers: [
        {
          userId: 1234,
          username: "added iser 1",
        },
      ],
    },
    {
      channelId: "3",
      channelName: "Dog bath",
      channelMsgs: [
        {
          userId: "oskd12",
          username: "chanel two",
          msg: "hi how are you",
        },
      ],
      channelMembers: [
        {
          userId: "oskd12",
          username: "chanel two",
        },
      ],
    },
  ],
  selectedConversationId: "",

  allChatUsers: "",
  pinnedMsgs: [],
};

const internalChatApp = createSlice({
  name: "internalChatapp",
  initialState,
  reducers: {
    fetchAllChannels(state, action) {
      console.log("SLICE", action.payload);
    },
    selectCurrentChat(state, action) {
      state.selectedConversationId = action.payload;
    },
  },
});

// Reducer
export default internalChatApp.reducer;
export const { fetchAllChannels, selectCurrentChat } = internalChatApp.actions;
