import styled from "styled-components";
import Form from "react-bootstrap/Form";

export const ChatHeaderSection = styled.div`
  border: 1px solid rgb(228, 234, 240);
  box-shadow: rgba(239, 239, 239, 0.5) 0px 2px 6px 0px;
  -webkit-box-shadow: rgba(239, 239, 239, 0.5) 0px 2px 6px 0px;
  -moz-box-shadow: rgba(239, 239, 239, 0.5) 0px 2px 6px 0px;
`;

export const LeftSideHeader = styled.div``;

export const ChatMainSection = styled.div`
  height: 100px;
`;

export const ChannelList = styled.div`
  max-height: 23vw;
  overflow: auto;
`;
export const ChatList = styled.div`
  height: 40%;
`;

export const MessagesContainer = styled.div`
  height: 76vh;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
  padding-bottom: 20px;
  padding-top: 20px;
  ${"" /* border-top: 1px solid rgb(228, 234, 240); */}
`;

//
// NEW CHAT
export const ChatPageContainer = styled.div`
  display: flex;
  height: 100%;
`;
export const ChatSideBar = styled.div`
  width: 243px;
  height: 100%;
  border-right: 1px solid #e4eaf0;
  padding: 37px 16px;
  max-height: calc(100vh - 75px);
  overflow: auto;
  .accordion {
    .accordion-item {
      border-radius: 0;
      border: 0;
      margin-bottom: 14px;
      position: relative;
      &:hover {
        .add-channel-button {
          display: block;
        }
      }
      .accordion-header {
        margin-bottom: 18px;
        button {
          background: transparent;
          color: #000;
          box-shadow: none;
          padding: 0;
          font-size: 15px;
          font-weight: 600;
          text-transform: capitalize;
          &:not(.collapsed)::after {
            background-image: var(--bs-accordion-btn-icon);
          }
        }
      }
      .accordion-body {
        padding: 0;
      }
    }
  }
`;
export const Icon = styled.svg`
  width: ${(props) => props.width ?? "15px"};
  height: ${(props) => props.height ?? "15px"};
  fill: transparent;
  margin-right: ${(props) => props.marginRight};
  cursor: ${(props) => props.cursor};
`;
export const SideNavItem = styled.button`
  border: 0;
  outline: 0;
  padding: 0;
  background: #fff;
  width: 100%;
  padding: 10px 5px;
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 500;
  &.active {
    background: #e9bd5a;
    color: #fff;
    path {
      fill: #fff;
      stroke: #fff;
    }
  }
`;
export const AddChannelIcon = styled.span`
  width: 24px;
  height: 24px;
  background: rgba(233, 189, 90, 0.15);
  border-radius: 5px;
  font-size: 25px;
  line-height: 1;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 8px;
  margin-right: 12px;
`;
export const ChannelTitle = styled.h3`
  font-weight: 600;
  font-size: 24px;
`;
export const TagImage = styled.svg`
  width: 13.52px;
  height: 16px;
  fill: #fff;
  margin-right: 16px;
`;
export const NormalIcon = styled.svg`
  width: 18px;
  height: 18px;
  fill: #fff;
  margin-right: 17.5px;
`;
export const DirectAvatar = styled.div`
  width: 24px;
  height: 24px;
  background: #000000;
  border-radius: 8px;
  margin-right: 12px;
`;
export const ChatContainer = styled.div`
  height: calc(100vh - 75px);
  overflow: hidden;
  flex-grow: 1;
  background: #120b27;
  display: flex;
  flex-direction: column;
  position: relative;
  color: #fff;
  .pn-member-list {
    display: flex;
    align-items: center;
    .pn-member {
      padding: 0;
      &:not(:last-child) {
        margin-right: -15px;
      }
      & > div:not(.pn-member__avatar) {
        display: none;
      }
      .pn-member__avatar {
        margin-right: 0;
        width: 30px;
        height: 30px;
        font-size: 15px;
      }
    }
  }
  .pn-msg-list {
    background: transparent;
    .pn-msg-list-scroller {
      padding: 0 93px;
      .pn-msg {
        margin-bottom: 18px;
        padding: 0;
        &:hover {
          background: rgba(255, 255, 255, 0.1);
        }
        .pn-msg__avatar {
          width: 48px;
          height: 48px;
          margin-right: 16px;
          font-size: 20px;
          text-transform: uppercase;
          background: #292042ab;
        }
        .pn-msg__main {
          min-width: 300px;
          width: 100%;
          background: rgba(255, 255, 255, 0.03);
          border-radius: 8px;
          padding: 17px 16px;
          .pn-msg__title {
            margin-bottom: 8px;
            .pn-msg__author {
              font-size: 15px;
              color: #fff;
              margin-right: 8px;
              text-transform: capitalize;
            }
            .pn-msg__time {
              padding: 0;
              color: rgba(255, 255, 255, 0.5);
              font-size: 16px;
            }
          }
          .pn-msg__bubble {
            font-size: 15px;
            line-height: 20px;
            letter-spacing: 0.25px;
            color: rgba(255, 255, 255, 0.75);
            img {
              max-width: 500px;
              max-height: 500px;
            }
          }
        }
      }
    }
  }
  .pn-msg-input {
    padding: 0 93px;
    background: transparent;
    padding-bottom: 30px;
    .pn-msg-input__wrapper {
      height: 80px;
      background: #ffffff;
      border: 1px solid rgba(27, 27, 27, 0.25);
      box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.05);
      border-radius: 8px;
      align-items: flex-start;
      padding: 16px 16px 5px 16px;
      .pn-msg-input__icons {
        order: 2;
      }
      .pn-msg-input__textarea {
        order: 1;
        height: 100%;
        max-height: 100%;
        overflow: auto;
        border: 0;
        background: transparent;
        margin-right: 20px;
      }
      .pn-msg-input__send {
        margin-top: 3px;
      }
    }
  }
`;
export const ChatContainerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 26px 24px 22px 24px;
  border-bottom: 1px solid #e4eaf0;
`;
export const UsersContainer = styled.div`
  display: flex;
  align-items: center;
`;
export const AddUserButton = styled.button`
  background: #e9bd5a;
  padding: 0;
  outline: 0;
  border: 0;
  width: 30px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 5px;
`;
export const AddUserImg = styled.svg`
  width: 18px;
  height: 14px;
  fill: transparent;
`;
export const UserAvatarContainer = styled.div`
  display: flex;
  margin-right: 5px;
`;
export const UserAvatar = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
  &:not(:first-child) {
    margin-left: -15px;
  }
`;
export const UserCounter = styled.p`
  color: #fff;
  font-weight: 600;
  font-size: 25px;
  margin-bottom: 0;
`;
