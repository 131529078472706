import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { main_api } from "../../../../api/axios_helper";
import CreateIcon from "../../../../assets/icons/CreateIcon";
import RandomIcon from "../../../../assets/icons/RandomIcon";
import CustomInput from "../../../../components/CustomInput";
import IconButton from "../../../../components/IconButton";
import CustomModal from "../../../../components/Modal";
import { checkPermission } from "../../../../util/commonUtil";
import { TableHeader } from "../clientStyles";
import { Select } from "antd";

import { useSelector } from "react-redux";
import SearchInput from "../../../../components/SearchInput";
import NoDataScreen from "../../../../components/NoDataScreen";
import { AntdesignTable } from "../../../../components/antDesignTable/AntdesignTable";
import { ClientKeysColumns } from "../../../../constants/KeysTableHeaders/ClientKeyTableHeader";
import { useDebounce } from "../../../../hooks/useDebounce";
import Possession, { KeyType } from "../../keys/data";
import ViewKeyModal from "../../../../components/Modals/KeyManagementModal/ViewKeyModal";
import EditKeyModal from "../../../../components/Modals/KeyManagementModal/EditKeyModal";
import { companyAPIsEndPoints } from "../../../../constants/ApiEndPoints";
import { pushNotification } from "../../../../util/Notification";
import DeleteConfirmationRowModal from "../../../../components/Modals/DeleteConfirmationRowModal";
import AddNewKeyModal from "../../../../components/Modals/KeyManagementModal/AddNewKeyModal";
import CustomButton from "../../../../components/Form/CustomButton";
import { PlusIcon } from "../../../../assets/rawSvgs";
import PermissionModal from "../../../../components/Modals/PermissionModal";
import DuplicateKeysModal from "../../../../components/Modals/DuplicateKeysModal/duplicateKeysModal";

function Keys({getManagementkeys, keyManagementState, clientResponse, singleClientresponseData}) {
  let { clientId } = useParams();
  const [search, setSearch] = useState("");
  const [type, setType] = useState("");
  const [possession, setPossession] = useState("");
  const permissionManagement = useSelector((state) => state.permissionManagement);
  const debouncedSearchKey = useDebounce(search, 300);
  const [openViewKeyModal, setOpenViewKeyModal] = useState(false);
  const [viewKeyId, setViewKeyId] = useState(null);
  const loginState = useSelector((state) => state.auth.loginResponse);
  const [openEditKeyValues, setOpenEditKeyValues] = useState({});
  const [openEditKeyModal, setOpenEditKeyModal] = useState(false);
  const [permissionPopUp, setPermissionPopUp] = useState(false);
  const [isDeleteKeyOpen, SetIsDeleteKeyOpen] = useState(false);
  const [deleteModalTextCondition, setDeleteModalTextCondition] = useState(false);
  const [deleteKeyValues, setDeleteKeyValues] = useState({});
  const [openKeyModal, setOpenKeyModal] = useState(false);
  const [openDuplicateKeysModal, setOpenDuplicateKeysModal] = useState(false);
  const [filteredDuplicateRecords, setFilteredDuplicateRecords] = useState([]);
  const [isLoading, setIsloading] = useState(false);

  useEffect(() => {
    fetchManagementKeys()
  }, [permissionManagement, type, possession, search]);

  const fetchManagementKeys = () => {
    if (checkPermission(permissionManagement.permissions, "view_keys")) {
      let queryParams =  `client=${clientId}`;
      if (type && type != "") {
        queryParams = `type=${type}`;
      }
      if (possession && possession !== "") {
        queryParams += `&possession=${possession}`;
      }
      getManagementkeys(queryParams, debouncedSearchKey);
    }

  }

  const handleHistory = (row) => {
    // setDeleteRowId(row?.id);
    // setOpenConfimrationDeleteModal(true);
  };
  const addNewKey = () => {
    if (checkPermission(permissionManagement.permissions, "create_key")) {
      setOpenKeyModal(true);
    } else {
      setPermissionPopUp(true);
    }
  };

  const handleShowModal = (record) => {
    setOpenViewKeyModal(true);
    setViewKeyId(record?.id);
  };

  // Filter Duplicate Keys Data
  const filterDuplicateRecords =(records, recordName) => {
    return records.filter(item => {
      return item.type === 'physical' && item.name === recordName;
    });
  }

  // Show Duplicate Keys Modal
  const handleShowDuplicateKeysModal = (record) => {
    if (record.type === "physical") {
      console.log("record:",record);
      const filteredRecords = filterDuplicateRecords(keyManagementState?.responseData?.result, record.name);
      console.log("filteredRecords:",filteredRecords);
      setFilteredDuplicateRecords(filteredRecords);
      setOpenDuplicateKeysModal(true);
    }
  };
  const handleDelete = (record) => {
    if (checkPermission(permissionManagement.permissions, "delete_key")) {
      if (record) {
        const { id, counter } = record;
        if (counter > 1) {
          setDeleteModalTextCondition(true);
        } else {
          setDeleteModalTextCondition(false);
        }
        setDeleteKeyValues(id);
      }
      SetIsDeleteKeyOpen(true);
    } else {
      setPermissionPopUp(true);
    }


  }

  const updateSingleKey = async({ id, values }) => {
    const { data } = await main_api.put(companyAPIsEndPoints.EDIT_SINGLE_MANAGEMENT_KEYS(id), values);
    if (data.isSuccess) {
      pushNotification(data?.message, "success");
      fetchManagementKeys()
    }
  }

  const handleEditRow = (rows) => {
    if (checkPermission(permissionManagement.permissions, "edit_key")) {
      if (rows) {
        const { id, client, client_name, is_master_key, staff, staff_name, name, type, code, possession } = rows;
        setOpenEditKeyValues({
          id,
          client,
          client_name,
          is_master_key,
          staff_id: staff,
          staff_name,
          name,
          type,
          code,
          possession,
        });
      }
      setOpenEditKeyModal(true);
    } else {
      setPermissionPopUp(true);
    }

  }


  const deleteKey = () => {
    main_api
      .delete(companyAPIsEndPoints.DELETE_SINGLE_KEY(deleteKeyValues))
      .then(({ data }) => {
        pushNotification(data.message, "success");
        SetIsDeleteKeyOpen(false);
        fetchManagementKeys();
      })
      .catch((error) => {
        pushNotification("Something went wrong!", "error");
        console.log(error);
      });
  };

  return (
    <>
     {checkPermission(permissionManagement.permissions, "view_keys") ? (
        <>
          <TableHeader>
            <div className="d-flex align-items-center gap-2 w-100">
              <div style={{ width: "210px" }}>
                <SearchInput
                  search={search}
                  onChange={(value) => setSearch(value)}
                  onBlur={() => {}}
                  placeholder="Type to search.."
                />
              </div>
        
              <div style={{ minWidth: "142px" }}>
                <Select
                  style={{ height: "40px", minWidth: "142px" }}
                  onChange={(value) => setPossession(value)}
                  placeholder="Possession"
                  allowClear
                  onClear={() => setPossession("")}
                  options={Possession}
                />
              </div>
              <div style={{ width: "142px" }}>
                <Select
                  style={{ height: "40px", minWidth: "142px" }}
                  onChange={(value) => setType(value)}
                  placeholder="Type"
                  allowClear
                  onClear={() => setType("")}
                  options={KeyType}

                />
              </div>
              
              <div className="ms-auto"><CustomButton text="Add new Key" Icon={PlusIcon} onClick={() => addNewKey()} primary /></div>
            </div>
          </TableHeader>
          {keyManagementState?.responseData?.result?.length > 0 ? (
            <AntdesignTable
              columns={ClientKeysColumns({ handleDelete, handleEditRow, handleShowModal, handleShowDuplicateKeysModal  })}
              data={keyManagementState?.responseData?.result || []}
            />
          ) : (
            <NoDataScreen
              heading="You don’t have any Keys in your list. Let’s get started by adding a new Key"
              btnText={"Add new Key"}
              onClick={() => addNewKey()}
            />
          )}
        </>
      ) : (
        <NoDataScreen
          heading="You don’t have any Keys in your list. Let’s get started by adding a new Key"
          btnText={"Add new Key"}
          onClick={() => addNewKey()}
        />
      )}

      <ViewKeyModal
        viewKeyId={viewKeyId}
        loginState={loginState}
        openKeyModal={openViewKeyModal}
        setOpenKeyModal={setOpenViewKeyModal}
        heading="View Digital key Code"
        description="In order to view the key, enter your account password below"
      />
      <AddNewKeyModal
        openKeyModal={openKeyModal}
        setOpenKeyModal={setOpenKeyModal}
        singleClientresponseData={singleClientresponseData}
        // postManagementKey={postManagementKey}
        // keyPostState={keyPostState}
        // postExistingKey={postExistingKey}
        clientList={clientResponse?.clientList}
        getManagementkeys={fetchManagementKeys}
        keyManagementState={keyManagementState}
      />
      <EditKeyModal
        openKeyModal={openEditKeyModal}
        setOpenKeyModal={setOpenEditKeyModal}
        openEditKeyValues={openEditKeyValues}
        updateSingleKey={updateSingleKey}
        clientList={clientResponse?.clientList}
        getManagementkeys={fetchManagementKeys}
      />
      <DeleteConfirmationRowModal
        onclose={() => SetIsDeleteKeyOpen(false)}
        handleConfirmDeleteRows={deleteKey}
        openConfimrationDeleteModal={isDeleteKeyOpen}
        {...(deleteModalTextCondition
          ? {
              text: "There is more than 1 Key involved, are you sure you want to delete all of them?",
            }
          : {})}
        {...(deleteModalTextCondition
          ? {
              deleteBtnText: "Yes, delete them",
            }
          : {})}
      />
       {openDuplicateKeysModal && (
        <DuplicateKeysModal
          openKeyModal={openDuplicateKeysModal}
          setOpenKeyModal={setOpenDuplicateKeysModal}
          handleEditRow={handleEditRow}
          handleDelete={handleDelete}
          handleHistory={handleHistory}
          filteredDuplicateRecords={filteredDuplicateRecords}
          isLoading={isLoading}
        />
      )}
      <PermissionModal
        onclose={() => setPermissionPopUp(false)}
        openModal={permissionPopUp}
      />

    </>
  );
}
const ActionButtons = styled.div`
  display: flex;
  margin-bottom: 27px;
`;
const KeyContainer = styled.div`
  width: 650px;
  height: 140px;
  margin-bottom: 20px;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;

  background: #ffffff;
  border: 1px solid #e4eaf0;
  box-shadow: 0px 1px 8px rgba(110, 110, 110, 0.1);
  border-radius: 16px;
`;

const KeyLeft = styled.div``;
const KeyRight = styled.div``;
const KeyTitle = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #000000;
  padding: 0;
  margin: 0;
`;
const KeySubTitle = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
  color: #000000;
  padding: 0;
  margin: 0;
  margin-top: 10px;
`;
const KeySubTitle2 = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
  color: #000000;
  padding: 0;
  margin: 0;
  margin-top: 20px;
`;

const Button = styled.button`
  justify-content: center;
  align-items: center;
  width: 73px;
  height: 28px;
  border: none;
  background: #e9bd5a;
  border-radius: 6px;

  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #ffffff;
`;
const RowActionContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Action = styled.button`
  width: 72px;
  height: 28px;
  background: #e9bd5a;
  border-radius: 6px;
  border: none;
  // margin-left: auto;
  margin-top: 30px;
  cursor: pointer;

  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  color: #ffffff;

  &:hover {
    background: lightblue;
  }
`;

const ActionAlt = styled.button`
  width: 72px;
  height: 28px;
  border-radius: 6px;
  border: none;
  // margin-left: auto;
  margin-top: 30px;
  margin-right: 20px;
  background: #ffffff;
  border: 1px solid #000000;
  cursor: pointer;

  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  color: #000000;
`;

const RandomContainer = styled.span`
  margin-top: -20px;
  margin-left: 90px;

  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #e9bd5a;

  cursor: pointer;
`;

const RandomIconContanier = styled.svg`
  width: 17px;
  height: 15px;
  fill: transparent;
  stroke: #e9bd5a;
  margin-right: 5px;
`;

export default Keys;
