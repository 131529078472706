import React from "react"
import styled from "styled-components"

function Button({ title, children, onClick = () => null }) {
  return (
    <Container>
      <Item onClick={onClick}>
        {title}
        {children}
      </Item>
    </Container>
  )
}
const Container = styled.div`
  display: flex;
`
const Item = styled.button`
  box-sizing: border-box;
  height: 39px;
  background: #e9bd5a;
  border-radius: 6px;
  padding: 6px 20px;
  border: none;

  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
`

export default Button
