import React from "react";
import { Row, Col, Space, Table, Badge, Tag } from "antd";
import SearchInput from "../../../../../components/SearchInput";
import { ServiceWrapper, ServiceSection } from "./ServiceRequestStyles";
import CustomBtnWithIcon from "../../../../../components/buttonWithIcon";
import AntDesignDropDownSelect from "../../../../../components/AntDesignDropDownSelect/AntDesignDropDownSelect";
import { AntdesignTable } from "../../../../../components/antDesignTable/AntdesignTable";
import {
  ClientsDetailServiceColumens,
  ClientdataService,
} from "../../../../../constants/CompanyTableHeaders/ClientDetailServiceHeaders";
import { useSelector } from "react-redux";
import { scheduleEventsListCalenderTable } from "../../../../../constants/CompanyTableHeaders/SchedulerTableHeaders";
import { convertTo12HourFormat } from "../../../../../util/formatTime";
import { schedulerBackground, schedulerColor, schedulerStatus } from "../../../../../components/SchedulerCalender/helpers";
//
const ServiceRequest = ({ fetchSerReq }) => {
  const serviceState = useSelector((state) => state.scheduler?.schedulerResponse?.responseData);

  const filterEmptyServ = fetchSerReq?.filter((items) => items.start_time && items.end_time);
  return (
    <ServiceWrapper>
      <Row align="middle" gutter={10} style={{ padding: 0 }}>
        <Col lg={5}>
          <ServiceSection>
            <SearchInput options={[]} placeholder="Type to search" />
          </ServiceSection>
        </Col>
        <Col lg={3}>
          <ServiceSection>
            <AntDesignDropDownSelect options={[]} />
          </ServiceSection>
        </Col>
        <Col lg={3}>
          <ServiceSection>
            <AntDesignDropDownSelect options={[]} />
          </ServiceSection>
        </Col>
      </Row>
      <Table columns={scheduleEventsListCalenderTablev2()} dataSource={filterEmptyServ || []} pagination={false} />
      {/* <ServiceRequestTableComponent /> */}
    </ServiceWrapper>
  );
};

export default ServiceRequest;

const scheduleEventsListCalenderTablev2 = (func1, func2, func3) => {
  return [
    {
      title: "Client Name",
      dataIndex: "clientName",
      key: "clientName",
      align: "center",

      render: (text, record) => {
        return <>{record?.client.name}</>;
      },
    },
    {
      title: "Service",
      dataIndex: "service",
      key: "service",
      align: "center",

      render: (text, record) => {
        return <>{record?.service?.name}</>;
      },
    },

    {
      title: "Pets",
      dataIndex: "pets",
      key: "pets",
      align: "center",

      width: 200,
      render: (text, record) => {
        return (
          <div className="w-100 text-center">
            {record?.pets[0]?.name ?? "---"}
            <>
              {record?.pets?.length - 1 > 0 ? (
                <Badge
                  className="site-badge-count-109 ms-1"
                  count={`+${record?.pets?.length - 1}`}
                  style={{ backgroundColor: "#FBCF34" }}
                />
              ) : (
                <></>
              )}
            </>
          </div>
        );
      },
    },
    {
      title: "Staff",
      dataIndex: "staff",
      key: "staff",
      render: (text, record) => {
        return <>{record.staff?.name}</>;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => {
        return (
          <>
            <Tag style={{ ...tagStyle, backgroundColor: schedulerBackground(record), color: schedulerColor(record) }}>
              {schedulerStatus(record)}
            </Tag>
          </>
        );
      },
    },
    {
      title: "Date",
      dataIndex: "date",
      align: "center",
      key: "date",
      render: (text, record) => {
        return <>{record.date}</>;
      },
    },
    {
      title: "Time",
      dataIndex: "time",
      key: "time",
      align: "center",

      render: (text, record) => {
        return (
          <>
            {convertTo12HourFormat(record.start_time)}
            {"  "} - {"  "}
            {convertTo12HourFormat(record.end_time)}
          </>
        );
      },
    },
  ];
};

export const tagStyle = {
  backgroundColor: "#EFF1F5",
  fontWeight: 500,
  border: "none",
  borderRadius: "28px",
  padding: "2px 8px 2px 6px",
  color: "#023047",
};

export const approvedTagStyle = {
  ...tagStyle,
  backgroundColor: "#EEF8EE",
  color: "#00914B",
};

export const declinedTagStyle = {
  ...tagStyle,
  backgroundColor: "#FBEEED",
  color: "#E53939",
};
