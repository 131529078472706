import React from 'react'
import styled from 'styled-components';

function FormDetail({title, description}) {
  return (
    <FormDescription>
        <p>{title}</p>
        <span>{description}</span>
      </FormDescription>
  )
}

export default FormDetail
const FormDescription = styled.div`
  padding-bottom: 16px;
  p {
    color: #1d2939;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 8px;
  }
  span {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: #757575;
  }
`;
