import React, { useEffect, useState } from "react";
import { TableHeader } from "../clients/clientStyles";
import SearchInput from "../../../components/SearchInput";
import CustomBtnWithIcon from "../../../components/buttonWithIcon";
import { AntdesignTable } from "../../../components/antDesignTable/AntdesignTable";
import dayjs, { Dayjs } from "dayjs";
import { DatePicker, Select } from "antd";
import AddPetModal from "../../../components/Modals/PetManagementModal/AddPetModal";
import EditPetModal from "../../../components/Modals/PetManagementModal/EditPetModal";
import { PetListColumns, VaccinationsColumns } from "../../../constants/CompanyTableHeaders/PetTableHeaders";
import { main_api } from "../../../api/axios_helper";
import moment from "moment";
import AddVaccinationModal from "../../../components/Modals/PetManagementModal/AddVaccinationModal";
import EditVaccinationModal from "../../../components/Modals/PetManagementModal/EditVaccinationModal";
import { companyAPIsEndPoints } from "../../../constants/ApiEndPoints";
import DeleteConfirmationRowModal from "../../../components/Modals/DeleteConfirmationRowModal";
import { pushNotification } from "../../../util/Notification";
import { useSelector } from "react-redux";
import NoDataScreen from "../../../components/NoDataScreen";
import { checkPermission } from "../../../util/commonUtil";
import PermissionModal from "../../../components/Modals/PermissionModal";
const { RangePicker } = DatePicker;

const VaccinationTab = (props) => {
  const [search, setSearch] = useState("");
  const [isLoading, setIsloading] = useState(false);
  const [selectedPetFilter, setSelectedPetFilter] = useState("");
  const [isAddVaccinationOpen, setIsAddVaccinationOpen] = useState(false);
  const [isEditVaccinationOpen, setIsEditVaccinationOpen] = useState(false);
  const [isDeleteVaccinationOpen, SetIsDeleteVaccinationOpen] = useState(false);
  const [editVaccinationValues, setEditVaccinationValues] = useState({});
  const [deleteVaccinationValues, setDeleteVaccinationValues] = useState({});
  const [permissionPopUp, setPermissionPopUp] = useState(false);
  const updatePetList = props.petList?.map((pet) => ({ value: `${pet.id}_${pet.name}`, label: pet.name }));
  const { permissionManagement } = useSelector((state) => state);
  useEffect(() => {
    if (checkPermission(permissionManagement.permissions, "view_pets")) {
      props.loadPetList();
    }
    if (checkPermission(permissionManagement.permissions, "view_vaccinations")) {
      props.loadVaccinationsList();
    }
  }, [permissionManagement.permissions]);

  const addVaccination = (formValues) => {
    const { client, ...modifiedValues } = formValues;

    const formattedVaccinationDate = modifiedValues.date.format("YYYY-MM-DD");
    const formattedExpiryDate = modifiedValues.expiry_date.format("YYYY-MM-DD");

    const updatedValues = {
      ...modifiedValues,
      date: formattedVaccinationDate,
      expiry_date: formattedExpiryDate,
    };

    setIsloading(true);
    main_api
      .post(companyAPIsEndPoints.POST_VACCINATION_PETS, updatedValues)
      .then(({ data }) => {
        setIsloading(false);
        if (data?.isSuccess) {
          pushNotification(data?.message, "success");
          setIsAddVaccinationOpen(false);
          props.loadVaccinationsList();
        }
      })
      .catch((error) => {
        pushNotification("Something went wrong!", "error");
        console.log(error);
      });
  };

  const editVaccination = (formValues) => {
    const formattedDate = formValues.date.format("YYYY-MM-DD");
    const formattedExpiryDate = formValues.expiry_date.format("YYYY-MM-DD");

    const updatedValues = {
      ...formValues,
      date: formattedDate,
      expiry_date: formattedExpiryDate,
    };

    setIsloading(true);
    main_api
      .put(`${companyAPIsEndPoints.PUT_SINGLE_VACCINATION_PETS(editVaccinationValues.id)}`, updatedValues)
      .then(({ data }) => {
        pushNotification("Vaccination Updated Successfully!", "success");
        setIsloading(false);
        setIsEditVaccinationOpen(false);
        props.loadVaccinationsList();
      })
      .catch((error) => {
        pushNotification("Something went wrong!", "error");
        console.log(error);
      });
  };

  const deleteVaccination = () => {
    setIsloading(true);

    main_api
      .delete(companyAPIsEndPoints.DELETE_SINGLE_VACCINATION_PETS(deleteVaccinationValues))
      .then(({ data }) => {
        console.log();
        setIsloading(false);
        pushNotification("Vaccination Deleted Successfully!", "success");
        SetIsDeleteVaccinationOpen(false);
        props.loadVaccinationsList();
        console.log(data);
      })
      .catch((error) => {
        SetIsDeleteVaccinationOpen(false);
        console.log(error);
      });
  };

  const handleEditRow = (record) => {
    if (checkPermission(permissionManagement.permissions, "edit_vaccination")) {
      if (record) {
        main_api
          .get(companyAPIsEndPoints.FETCH_SINGLE_VACCINATION_PETS(record.id))
          .then(({ data }) => {
            setEditVaccinationValues(data.result);
          })
          .catch((error) => {
            console.log(error);
          });
      }
      setIsEditVaccinationOpen(true);
    } else {
      setPermissionPopUp(true);
    }
  };

  const handleDelete = (record) => {
    if (checkPermission(permissionManagement.permissions, "delete_vaccination")) {
      if (record) {
        const { id } = record;
        setDeleteVaccinationValues(id);
      }
      SetIsDeleteVaccinationOpen(true);
    } else {
      setPermissionPopUp(true);
    }
  };

  const searchBlurHandler = () => {
    props.searchVaccinationList(search, selectedPetFilter);
  };

  const onPetSelect = (selected) => {
    setSelectedPetFilter(selected);
    props.searchVaccinationList(search, selected.split("_")[1]);
  };

  const addNewVaccination = () => {
    if (checkPermission(permissionManagement.permissions, "create_vaccination")) {
      setIsAddVaccinationOpen(true);
    } else {
      setPermissionPopUp(true);
    }
  };

  let clientDetails = props?.clientList?.result?.find((item) => item.id === props?.clientId);

  return (
    <>
      <AddVaccinationModal
        isLoading={isLoading}
        isModalOpen={isAddVaccinationOpen}
        petList={props?.petList}
        setModalOpen={setIsAddVaccinationOpen}
        onFinish={addVaccination}
        clientList={props?.clientList?.result}
        clientDetails={clientDetails}
        isClientPortal={props?.isClientPortal}
      />
      <EditVaccinationModal
        isLoading={isLoading}
        isModalOpen={isEditVaccinationOpen}
        petList={props.petList}
        setModalOpen={setIsEditVaccinationOpen}
        onFinish={editVaccination}
        editVaccinationValues={editVaccinationValues}
      />
      <DeleteConfirmationRowModal
        onclose={() => SetIsDeleteVaccinationOpen(false)}
        handleConfirmDeleteRows={deleteVaccination}
        openConfimrationDeleteModal={isDeleteVaccinationOpen}
      />
      {props.vaccination?.length > 0 && (
        <TableHeader>
          <div className="d-flex align-items-center col">
            <SearchInput search={search} onChange={(value) => setSearch(value)} onBlur={searchBlurHandler} />
            <Select
              onChange={onPetSelect}
              placeholder="Pet Name"
              className="ms-2"
              style={{ height: "40px", minWidth: "150px" }}
              allowClear
              onClear={() => onPetSelect("")}
              options={updatePetList}
            />
            <CustomBtnWithIcon
              btnText={"Add Vaccination"}
              className="ms-md-auto"
              margin="10px 10px 10px auto"
              onClick={() => addNewVaccination()}
            />
          </div>
        </TableHeader>
      )}
      {props.vaccination?.length > 0 ? (
        <AntdesignTable columns={VaccinationsColumns({ handleDelete, handleEditRow })} data={props.vaccination} />
      ) : (
        <NoDataScreen
          heading="You don’t have any Vaccination in your list. Let’s get started by adding a new Vaccination"
          btnText={"Add new Vaccination"}
          onClick={() => addNewVaccination()}
        />
      )}
      <PermissionModal onclose={() => setPermissionPopUp(false)} openModal={permissionPopUp} />
    </>
  );
};

export default VaccinationTab;
