import React, { useRef, useEffect, useState } from 'react';
import SignaturePad from 'signature_pad';
import { CustomModal } from '../Modals/ModalStyles'
import CustomBtnWithIcon from '../buttonWithIcon'
import { Divider, Segmented } from 'antd'
import { pushNotification } from '../../util/Notification';
import warning from 'antd/es/_util/warning';

export default function SignatureSelector({isModalOpen,setModalOpen}) {
    // const [SelectTab, setSelectTab] = useState("Manual Signature");
    
    const canvasRef = useRef(null);
    const signaturePadRef = useRef(null);

    useEffect(() => {
        const canvas = canvasRef.current;
        canvas.width = canvas.offsetWidth;
        canvas.height = canvas.offsetHeight;
        signaturePadRef.current = new SignaturePad(canvas);
    }, []);

    const clearSignature = () => {
        signaturePadRef.current.clear();
        setModalOpen(false); 
    };

    const saveSignature = () => {
        if (signaturePadRef.current.isEmpty()) {
            pushNotification("Please provide a signature first.", "warning");
            // alert("Please provide a signature first.");
        } else {
            const dataUrl = signaturePadRef.current.toDataURL();
            pushNotification("Saving Signature...", "success");
            setModalOpen(false); 
        }
    };
    
    const save = ()=>{
        console.log("saving sig...");
    };
    return (
        <>
        <CustomModal
        open={isModalOpen}
        title="Select Your Signature"
        width="383px"
        heigth="450px"
        onCancel={() => {setModalOpen(false)}}
        footer={null}
        >
        <Divider/>
         {/* <Segmented
          style={{ marginBottom: 8, background: "rgb(238,238,238,1)" }}
          onChange={(value) => setSelectTab(value)}
          options={["Manual Signature", "Signature Suggestions"]}
          className=" p-1 "
          defaultValue={SelectTab}
          block
          size="large"
        />
        {SelectTab === 'Manual Signature' && 
            <canvas 
            ref={canvasRef} 
            style={{ border: '1px solid #000', width: '100%', height: '300px' }} 
            />
        } */}
        <canvas 
            ref={canvasRef} 
            style={{ border: '1px solid #000', width: '100%', height: '300px' }} 
        />
        <Divider/>
        
            <div className="d-flex justify-content-end">
            <CustomBtnWithIcon
                btnText={"Discard"}
                margin="0px 5px"
                noBackground
                hideIcon={true}
                onClick={() => clearSignature()}
            />
            <CustomBtnWithIcon btnText={"Save & Sign Document"} type="submit" hideIcon={true} onClick={() => saveSignature()}/>
            </div>
        </CustomModal>
        </>
  )
}
