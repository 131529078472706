import { createSlice } from "@reduxjs/toolkit";
import { main_api } from "../../api/axios_helper";
import { companyAPIsEndPoints } from "../../constants/ApiEndPoints";

const initialState = {
  allInvoicing: {
    isLoading: false,
    responseData: [],
    invoicingList: [],
    invoiceRowCount: 0,
  },

  invoiceSingleDetails: {
    isLoading: false,
    responseData: "",
  },
};

const invoicing = createSlice({
  name: "invoicing",
  initialState,
  reducers: {
    fetchAllInvoiceSlice(state, action) {
      state.allInvoicing.isLoading = action.payload.isLoading;
      if (action.payload?.responseData) {
        const invoicingList = action.payload?.responseData?.result.map((items) => {
          return {
            value: items.id,
            label: items.name,
          };
        });

        state.allInvoicing.invoiceRowCount = action.payload?.responseData.count || 0;
        state.allInvoicing.responseData = action.payload?.responseData.result;
        state.allInvoicing.invoicingList = invoicingList;
      }
    },

    // ===
    fetchSingleInvoiceSlice(state, action) {
      state.invoiceSingleDetails.isLoading = action.payload.isLoading;
      if (action.payload?.responseData) {
        state.invoiceSingleDetails.responseData = action.payload?.responseData;
      }
    },
  },
});

// Reducer
export default invoicing.reducer;
export const { fetchAllChannels, selectCurrentChat } = invoicing.actions;

export const getAllInvoicingApi = (query = "") => {
  return async (dispatch, getState) => {
    try {
      const companyId = getState().auth?.loginResponse?.selectCompany?.[0]?.company_id;
      dispatch(invoicing.actions.fetchAllInvoiceSlice({ isLoading: true }));
      const { data } = await main_api.get(companyAPIsEndPoints.GET_ALL_INVOICES(companyId, query));

      dispatch(invoicing.actions.fetchAllInvoiceSlice({ isLoading: false, responseData: data }));
    } catch (error) {
      dispatch(invoicing.actions.fetchAllInvoiceSlice({ isLoading: false, responseData: error }));
    }
  };
};

export const fetchSingleInvoiceApi = (id) => {
  return async (dispatch, getState) => {
    try {
      const companyId = getState().auth?.loginResponse?.selectCompany?.[0]?.company_id;
      dispatch(invoicing.actions.fetchSingleInvoiceSlice({ isLoading: true }));
      const { data } = await main_api.post(companyAPIsEndPoints.GET_SINGLE_INVOICES(id), {
        company: companyId,
      });

      dispatch(invoicing.actions.fetchSingleInvoiceSlice({ isLoading: false, responseData: data }));
    } catch (error) {
      dispatch(invoicing.actions.fetchSingleInvoiceSlice({ isLoading: false, responseData: error }));
    }
  };
};
