import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { userHasValidToken } from "../../api/auth";

import SidebarNav from "../../containers/layout/Sidebar";
import HeaderNav from "../../containers/layout/HeaderNav";
import DetailPage from "../../containers/petDetail/petDetail";
import { sidebarNavData } from "../../constants/SidebarNavData";
import { useSelector } from "react-redux";

import WorkQueueDetail from "../../containers/tasksManagement/workQueueDetail";

const companyRoute = "";

const routePathsWithoutId = ["/company/clients/client-details/", "/company/message"];
const ProtectedRoute = ({ Page }) => {
  const hasValidToken = userHasValidToken();
  const [isExpanded, setIsExpanded] = useState(false);
  let navigate = useNavigate();
  let pathName = useLocation().pathname;

  const isMatchingPath = routePathsWithoutId.some((routePath) => pathName.startsWith(routePath));

  const generalAppSt = useSelector((state) => state.generalApp);
  const permissionManagment = useSelector((state) => state.permissionManagement);
  const [companyUser, setCompanyUser] = useState(false);

  useEffect(() => {
    if (!hasValidToken) {
      navigate("/company/signin");
    }
  }, []);

  useEffect(() => {
    if (permissionManagment && permissionManagment.role) {
      if (permissionManagment.role.type === "company_admin" || permissionManagment.role.is_company_type) {
        setCompanyUser(true);
      }
    }
  }, [permissionManagment]);
  return hasValidToken && companyUser ? (
    <>
      {pathName.includes("company/onboarding") ? (
        <Page />
      ) : (
        <Container>
          {!pathName.includes("company/onboarding") && <SidebarNav navItems={sidebarNavData} />}
          <Body expanded={isExpanded}>
            {!pathName.includes("company/onboarding") && <HeaderNav />}
            <Container>
              <Content
                height={pathName.includes("company/mesxsage") ? "calc(100% - 75px)" : "auto"}
                marginTop={pathName.includes("company/message") && "0"}
                padding={isMatchingPath && "0px 6px 60px 0px"}
                toggle={generalAppSt.sidebarToggle}
              >
                <Page />
              </Content>
              <DetailPage />
              <WorkQueueDetail />
            </Container>
          </Body>
        </Container>
      )}
    </>
  ) : (
    <></>
  );
};

const Container = styled.div`
  display: flex;
  height: 100%;
  overflow-y: auto;
`;

const Body = styled.div`
  flex: 1;
  /* width: calc(100vw - ${(props) => (props.expanded ? "240px" : "80px")}); */
  overflow: auto;
`;

const Content = styled.div`
  height: ${(props) => (props.height ? props.height : "auto")};
  ${"" /* margin-top: ${props => props.marginTop ? props.marginTop : "20px"}; */}
  padding: ${(props) => (props.padding ? props.padding : "17px 25px")};
  flex: 1;
  overflow: auto;
  margin-left: ${(props) => (props.toggle ? "240px" : "80px")};
  margin-top: 60px;
`;

export default ProtectedRoute;
