import styled from "styled-components";
const UserManagementRoleColumns = () => {

  let columns = [
    {
      title: "Role",
      dataIndex: "role",
      key: "role",

      render: (text, record) => (
        <RoleName>
          {record.name}
        </RoleName>
      ),
    },
    {
      title: "Permissions",
      dataIndex: "permissions",
      key: "permissions",
      render: (text, record) => (
        <>
         {record.detail}
        </>
      ),
    }
  ];

  return columns;
};

export {
  UserManagementRoleColumns,
};

const RoleName =styled.div`
  font-weight: 500;
`