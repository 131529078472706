import React, { useEffect, useState, useRef } from "react";
import PubNub, { generateUUID } from "pubnub";
import { PubNubProvider } from "pubnub-react";

import ChatPubnub from "./ChatPubnub";
import usePubNubService from "../../../patterns/InitializePubnub";
import usePubNubServiceHook from "../../../hooks/usePubnubChannelCount";

const ChatPage = ({
  companyInternalChatState,
  selectChat,
  authState,
  fetchAllStaff,
  allStaffs,

  loggedInType,
  clientManagementState,
  getManagementClients,
}) => {
  const pubnub = usePubNubService(loggedInType, authState);

  let [fetchChannelsToCheckUnreadCount, checkarond] = usePubNubServiceHook({ loggedInType: loggedInType, authState: authState });
  if (!pubnub) {
    return <div>Loading...</div>; // Or any loading indicator
  }

  let pubnubInitUser = "";
  let adminName = authState?.company_roles?.[0].company;
  let onlyCompId = authState?.company_roles?.[0].company_id;
  let isAdminOrUser = loggedInType?.selectedRoleType === "company_user_type" ? "company_user" : "company_admin";
  let loggedInUserName = loggedInType?.LoggedInUserData?.name || "";

  if (loggedInType?.selectedRoleType === "company_type") {
    pubnubInitUser = `company_admin_${authState?.company_roles?.[0]?.company_id}`;
  } else if (loggedInType?.selectedRoleType === "company_user_type") {
    pubnubInitUser = `company_user_${loggedInType?.companyUserId}`;
  } else if (loggedInType?.selectedRoleType === "client_type") {
    // console.log("com user");
    pubnubInitUser = `client_${authState?.company_roles?.[0]?.client_id}`;
  }

  // console.log("loggedInType", loggedInType);
  const pubnubKeys = {
    publishKey: process.env.REACT_APP_PUB_KEY,
    subscribeKey: process.env.REACT_APP_SUB_KEY,
  };

  // const pubnub = new PubNub({
  //   ...pubnubKeys,
  //   uuid: pubnubInitUser, // Pass the converted UUID
  //   fileUploadPublishRetryLimit: 0,
  //   autoNetworkDetection: true,
  //   restore: true,
  // });

  // let pamEnabled = false;
  // pubnub.addListener({
  //   status: function (status) {
  //     if (status.category === "PNAccessDeniedCategory") {
  //       pamEnabled = true;
  //     }
  //   },
  // });

  const PamError = () => {
    return (
      <div className="pubnub-error">
        <h1>Warning! PubNub access manager enabled.</h1>
        <p>
          It looks like you have access manager enabled on your PubNub keyset. This sample app is not adapted to work with PAM by
          default.
        </p>
        <p>
          You can either disable PAM in the <a href="https://dashboard.pubnub.com/">PubNub Admin Portal</a> or add custom code to
          grant all necessary permissions by yourself. Please referer to the{" "}
          <a href="https://www.pubnub.com/docs/chat/components/react/access-manager">Chat Component docs</a> for more information.
        </p>
      </div>
    );
  };

  const KeysError = () => {
    return (
      <div className="pubnub-error">
        <h1>A PubNub account is required.</h1>
        <p>
          Visit the PubNub dashboard to create an account or login:
          <br />
          <a href="https://dashboard.pubnub.com/">https://dashboard.pubnub.com/</a>
          <br />
          Create a new app or locate your app in the dashboard. Enable the Presence, Files, Objects, and Storage features using a
          region of your choosing. For Objects, ensure the following are enabled:
        </p>
        <ul>
          <li>User Metadata Events</li>
          <li>Channel Metadata Events</li>
          <li>Membership Events</li>
        </ul>
        <p>Copy and paste your publish key and subscribe key into: </p>
        <pre>.env</pre>
        <p>before continuing.</p>
        <br />
      </div>
    );
  };

  const stateObj = {
    selectChat,
    companyInternalChatState,
    allStaffs,
    adminId: pubnubInitUser,
    adminName,
    onlyCompId,
    isAdminOrUser,
    fetchChannelsToCheckUnreadCount: (uuid) => {},
    loggedInUserName,
  };

  return pubnubKeys?.publishKey?.length && pubnubKeys.subscribeKey?.length ? (
    pubnub?.pamEnabled ? (
      <PamError />
    ) : (
      <PubNubProvider client={pubnub}>
        <ChatPubnub {...stateObj} />
      </PubNubProvider>
    )
  ) : (
    <KeysError />
  );
};
export default ChatPage;
