import { createSlice } from "@reduxjs/toolkit";
import { main_api } from "../../api/axios_helper";
import { companyAPIsEndPoints } from "../../constants/ApiEndPoints";

const initialState = {
  isWorkQueuedetailModalOpen: false,
  isLoading: false,
  error: false,
  taskResponse: {},
  tasksListResponse: {},
  workQueueResponse: {},
  workQueueDetailResponse: {},
};

const slice = createSlice({
  name: "tasksManagement",
  initialState,
  reducers: {
    openWorkQueueDetailModalSlice(state, action) {
      state.isWorkQueuedetailModalOpen = action.payload.isWorkQueuedetailModalOpen;
    },
    loadTasksListSlice(state, action) {
      state.isLoading = action.payload.isLoading;
      state.error = action.payload.error;
      state.tasksListResponse = action.payload.tasksListResponse;
    },
    loadTaskSlice(state, action) {
      state.isLoading = action.payload.isLoading;
      state.error = action.payload.error;
      state.taskResponse = action.payload.taskResponse;
    },
    loadWorkQueueSlice(state, action) {
      state.isLoading = action.payload.isLoading;
      state.error = action.payload.error;
      state.workQueueResponse = action.payload.workQueueResponse;
    },
    loadWorkQueueDetailSlice(state, action) {
      state.isLoading = action.payload.isLoading;
      state.error = action.payload.error;
      state.workQueueDetailResponse = action.payload.workQueueDetailResponse;
    },
  },
});

export const { loadTasksListSlice, loadTaskSlice, loadWorkQueueSlice, openWorkQueueDetailModalSlice, loadWorkQueueDetailSlice } = slice.actions;

export default slice.reducer;

export const loadTasksList = (search, type, status, assignee, priority) => async (dispatch, getState) => {
  const companyId = getState().auth?.loginResponse?.selectCompany?.[0]?.company_id;
  try {
    dispatch(loadTasksListSlice({ isLoading: true, error: false }));
    const { data } = await main_api.get(
      companyAPIsEndPoints.FETCH_TASKS_LIST(companyId, search, type, status, assignee, priority),
    );
    dispatch(loadTasksListSlice({ isLoading: false, error: false, tasksListResponse: data }));
  } catch (error) {
    dispatch(loadTasksListSlice({ isLoading: false, error: error }));
  }
};

export const loadTask = (id) => async (dispatch, getState) => {
  const companyId = getState().auth?.loginResponse?.selectCompany?.[0]?.company_id;
  try {
    dispatch(loadTaskSlice({ isLoading: true, error: false }));
    const { data } = await main_api.get(
      companyAPIsEndPoints.FECTH_SINGLE_Task(companyId, id),
    );
    dispatch(loadTaskSlice({ isLoading: false, error: false, taskResponse: data }));
  } catch (error) {
    dispatch(loadTaskSlice({ isLoading: false, error: error }));
  }
};

export const loadWorkQueue = (search) => async (dispatch, getState) => {
  const companyId = getState().auth?.loginResponse?.selectCompany?.[0]?.company_id;
  try {
    dispatch(loadWorkQueueSlice({ isLoading: true, error: false }));
    const { data } = await main_api.get(
      companyAPIsEndPoints.FECTH_WORK_QUEUE(companyId, search),
    );
    dispatch(loadWorkQueueSlice({ isLoading: false, error: false, workQueueResponse: data }));
  } catch (error) {
    dispatch(loadWorkQueueSlice({ isLoading: false, error: error }));
  }
};

export const loadWorkQueueDetail = (id) => async (dispatch, getState) => {
  const companyId = getState().auth?.loginResponse?.selectCompany?.[0]?.company_id;
  try {
    dispatch(loadWorkQueueDetailSlice({ isLoading: true, error: false }));
    const { data } = await main_api.get(
      companyAPIsEndPoints.FECTH_SINGLE_WORK_QUEUE(companyId, id),
    );
    dispatch(loadWorkQueueDetailSlice({ isLoading: false, error: false, workQueueDetailResponse: data.result }));
  } catch (error) {
    dispatch(loadWorkQueueDetailSlice({ isLoading: false, error: error }));
  }
};

export const openWorkQueueDetailModal = (isOpen) => (dispatch) => {
  dispatch(openWorkQueueDetailModalSlice({isWorkQueuedetailModalOpen: isOpen}));
};

