import { Badge, Progress, Space, Tag } from "antd";
import { CommonActionHeaders } from "../TableActionHeaderColumn";
import { ReactComponent as PendingIcon } from "../../assets/rawSvgs/pendingServiceIcon.svg";
import { ReactComponent as DeclineIcon } from "../../assets/rawSvgs/declineIcon.svg";
import { ReactComponent as TickIcon } from "../../assets/rawSvgs/tickIcon.svg";
import { ReactComponent as EditIcon } from "../../assets/rawSvgs/tableEditIcon.svg";
import { ReactComponent as DeleteIcon } from "../../assets/rawSvgs/tableDeleteIcon.svg";
import { replaceDateFormat } from "../../util/formatDate";
import { convertTo12HourFormat } from "../../util/formatTime";
import { convertToDayNames } from "../../util/commonUtil";
import styled from "styled-components";
import moment from "moment";
import { schedulerBackground, schedulerColor, schedulerStatus } from "../../components/SchedulerCalender/helpers";
import { StaffTimeOffType } from "../../enums/staff";

const ClientsDetailServiceColumens = ({ handleDelete = "", handleEditRow = "" }) => {
  return [
    {
      title: "Template Name",
      dataIndex: "templateName",
      key: "templateName",
    },
    {
      title: "Client",
      dataIndex: "client",
      key: "client",
    },
    {
      title: "Data Approved",
      dataIndex: "dataApproved",
      key: "address",
    },

    {
      title: "",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <button
            onClick={(event) => {
              event.stopPropagation();
              handleDelete && handleDelete(record);
            }}
          >
            <DeleteIcon />
          </button>
          <button
            onClick={(event) => {
              event.stopPropagation();
              handleEditRow && handleEditRow(record);
            }}
          >
            <EditIcon />
          </button>
        </Space>
      ),
    },
  ];
};

const scheduleRequestColumens = (func1, func2, func3) => {
  return [
    {
      title: "Client Name",
      dataIndex: "clientName",
      key: "templateName",
    },
    {
      title: "Service",
      dataIndex: "client",
      width: 180,
      key: "client",

      render: (_, record) => (
        <>
          <Space className="d-flex">
            <span>{record?.services[0]?.name}</span>
            <>
              {record?.services?.length - 1 > 0 ? (
                <Badge
                  className="site-badge-count-109"
                  count={`+${record?.services?.length - 1}`}
                  style={{ backgroundColor: "#FBCF34" }}
                />
              ) : (
                <></>
              )}
            </>
          </Space>
        </>
      ),
    },
    {
      title: "Unassigned",
      dataIndex: "unassignedCount",
    },
    {
      title: "Scheduled",
      dataIndex: "scheduledCount",

      width: 150,
      // render: (_, record) => {record.scheduledCount},
    },
    {
      title: "Date Requested",
      dataIndex: "created_at",

      render: (_, record) => moment(record.created_at).format("MMMM DD, YYYY"),
    },

    {
      title: "",
      dataIndex: "name",
      key: "name",
      render: (_, record) => (
        <div className="d-flex justify-content-evenly">
          <button onClick={func1}>
            <TickIcon /> &nbsp; Accept
          </button>
          <button onClick={() => func2(record)}>
            <DeclineIcon />
            &nbsp; Decline
          </button>
          {/* <button onClick={func3}>
            <PendingIcon /> &nbsp; Split pending service
          </button> */}
        </div>
      ),
    },
  ];
};

const scheduleTimeOffColumens = ({ activeTab, handleTimeOff }) => {
  let columns = [
    {
      title: "Staff Name",
      dataIndex: "staffName",
      key: "staffName",
      render: (text, record) => <>{record.name ? <StaffName>{record.name}</StaffName> : <>-</>}</>,
    },
    {
      title: "Time Off Date",
      dataIndex: "timeOffDate",
      key: "timeOffDate",

      render: (text, record) => (
        <>
          {record?.start_date && replaceDateFormat(record?.start_date)}
          {record?.end_date && record.type !== StaffTimeOffType.SINGLE && <> - {replaceDateFormat(record?.end_date)}</>}
        </>
      ),
    },

    {
      title: "Time",
      dataIndex: "timeDuration",
      key: "timeDuration",

      render: (text, record) => (
        <>
          {record?.start_time && convertTo12HourFormat(record.start_time)}
          {record?.end_time && <> - {convertTo12HourFormat(record.end_time)}</>}
        </>
      ),
    },
    {
      title: "Recurring",
      dataIndex: "recurring",
      key: "recurring",
      render: (text, record) => (
        <>
          {record.recurring_days?.length ? (
            <>
              {convertToDayNames(record?.recurring_days)
                ?.slice(0, 2)
                .map((day) => {
                  return <Tag style={tagStyle}>{day}</Tag>;
                })}
              {record?.recurring_days?.length > 2 && <Tag style={tagStyle}>{`+${record?.recurring_days?.length - 2}`}</Tag>}
            </>
          ) : (
            <>-</>
          )}
        </>
      ),
    },
  ];

  if (activeTab === "requests") {
    columns.push({
      title: "",
      dataIndex: "action",
      key: "action",
      render: (_, record) => (
        <>
          <div className="d-flex justify-content-evenly">
            <button onClick={() => handleTimeOff({ is_approved: true }, record, true)}>
              <TickIcon /> &nbsp; Accept
            </button>
            <button onClick={() => handleTimeOff({ is_approved: false, is_decline: true }, record, true)}>
              <DeclineIcon />
              &nbsp; Decline
            </button>
          </div>
        </>
      ),
    });
  } else {
    columns.splice(1, 0, {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => (
        <>
          {record.is_approved && <Tag style={approvedTagStyle}>Approved</Tag>}
          {record.is_decline && <Tag style={declinedTagStyle}>Declined</Tag>}
        </>
      ),
    });
  }

  return columns;
};

const scheduleEventsListCalenderTable = (func1, func2, func3) => {
  return [
    {
      title: "Client Name",
      dataIndex: "clientName",
      key: "clientName",
      align: "center",

      render: (text, record) => {
        return <>{record?.client.name}</>;
      },
    },
    {
      title: "Service",
      dataIndex: "service",
      key: "service",
      align: "center",

      render: (text, record) => {
        return <>{record?.service?.name}</>;
      },
    },

    {
      title: "Pets",
      dataIndex: "pets",
      key: "pets",
      align: "center",

      width: 200,
      render: (text, record) => {
        return (
          <div className="w-100 text-center">
            {record?.pets[0]?.name ?? "---"}
            <>
              {record?.pets?.length - 1 > 0 ? (
                <Badge
                  className="site-badge-count-109 ms-1"
                  count={`+${record?.pets?.length - 1}`}
                  style={{ backgroundColor: "#FBCF34" }}
                />
              ) : (
                <></>
              )}
            </>
          </div>
        );
      },
    },
    {
      title: "Staff",
      dataIndex: "staff",
      key: "staff",
      render: (text, record) => {
        return <>{record.staff?.name}</>;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => {
        return (
          <>
            <Tag style={{ ...tagStyle, backgroundColor: schedulerBackground(record), color: schedulerColor(record) }}>
              {schedulerStatus(record)}
            </Tag>
          </>
        );
      },
    },
    {
      title: "Date",
      dataIndex: "date",
      align: "center",
      key: "date",
      render: (text, record) => {
        return <>{record.date}</>;
      },
    },
    {
      title: "Time",
      dataIndex: "time",
      key: "time",
      align: "center",

      render: (text, record) => {
        return (
          <>
            {convertTo12HourFormat(record.start_time)}
            {"  "} - {"  "}
            {convertTo12HourFormat(record.end_time)}
          </>
        );
      },
    },
    {
      title: "",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <button
            onClick={(event) => {
              event.stopPropagation();
              func1 && func1(record);
            }}
          >
            <DeleteIcon />
          </button>
          <button
            onClick={(event) => {
              event.stopPropagation();
              func2 && func2(record);
            }}
          >
            <EditIcon />
          </button>
        </Space>
      ),
    },
  ];
};

const schedulerTableDummyData = [
  {
    key: "412321231",
    templateName: "John Brown",
    dataApproved: "08/02/2024",
    client: "New York No. 1 Lake Park",
    name: "zxc",
  },
  {
    key: "41232131",
    templateName: "John alex",
    dataApproved: "25/02/2024",
    client: "New York No. 1 Lake Park",
  },
];
const schedulerRequest = [
  {
    key: "412321231",
    templateName: "John Brown",
    dataApproved: "08/02/202",
    client: "Pet care",
    name: "zxc",
    timemg: "5:00 PM - 6:00 PM",
  },
  {
    key: "41232131",
    templateName: "Rog al",
    timemg: "4:00 PM - 9:00 PM",
    dataApproved: "25/02/2024",
    client: "Pet walk",
  },
];
export const schedulerListView = [
  {
    key: "1",
    clientName: "John Doe",
    service: "Grooming",
    pets: "Dog",
    staff: "Sarah",
    status: "Scheduled",
    date: "2024-02-25",
    time: "5:00 PM - 6:00 PM",
  },
  {
    key: "2",
    clientName: "Jane Smith",
    service: "Check-up",
    pets: "Cat",
    staff: "Michael",
    status: "Completed",
    date: "2024-02-26",
    time: "1:00 PM - 2:00 PM",
  },
  {
    key: "3",
    clientName: "Alice Johnson",
    service: "Training",
    pets: "Parrot",
    staff: "Emily",
    status: "Pending",
    date: "2024-02-27",
    time: "5:00 AM - 6:00 AM",
  },
  {
    key: "4",
    clientName: "Alice Johnson",
    service: "Training",
    pets: "Parrot",
    staff: "Emily",
    status: "Pending",
    date: "2024-02-27",
    time: "1:00 AM - 2:00 AM",
  },
  {
    key: "5",
    clientName: "Alice Johnson",
    service: "Training",
    pets: "Parrot",
    staff: "Emily",
    status: "Pending",
    date: "2024-02-27",
    time: "7:00 AM - 8:00 AM",
  },

  // Add more dummy data objects as needed
];

// Cancelled
// Completed
// In Progress
// Scheduled

export {
  ClientsDetailServiceColumens,
  scheduleEventsListCalenderTable,
  scheduleRequestColumens,
  scheduleTimeOffColumens,
  schedulerTableDummyData,
  schedulerRequest,
};

const StaffName = styled.div`
  font-weight: 500;
  color: #424242;
`;

export const tagStyle = {
  backgroundColor: "#EFF1F5",
  fontWeight: 500,
  border: "none",
  borderRadius: "28px",
  padding: "2px 8px 2px 6px",
  color: "#023047",
};

export const approvedTagStyle = {
  ...tagStyle,
  backgroundColor: "#EEF8EE",
  color: "#00914B",
};

export const declinedTagStyle = {
  ...tagStyle,
  backgroundColor: "#FBEEED",
  color: "#E53939",
};
