import React, { useEffect, useState } from "react";

import { AntdesignTable } from "../../../../components/antDesignTable/AntdesignTable";
import SearchInput from "../../../../components/SearchInput";

import { ReactComponent as EditIcon } from "../../../../assets/rawSvgs/tableEditIcon.svg";
import { ReactComponent as DeleteIcon } from "../../../../assets/rawSvgs/tableDeleteIcon.svg";
import { ReactComponent as TickIcon } from "../../../../assets/rawSvgs/tickIcon.svg";
import CustomBtnWithIcon from "../../../../components/buttonWithIcon";
import AddNewTemplateForm from "./addNewTemplateForm";
import { Segmented, Space } from "antd";
import DetailsItem from "./DetailsItem";
import { main_api } from "../../../../api/axios_helper";
import { companyAPIsEndPoints } from "../../../../constants/ApiEndPoints";
import { pushNotification } from "../../../../util/Notification";
import DeleteConfirmationRowModal from "../../../../components/Modals/DeleteConfirmationRowModal";

import { useSelector } from "react-redux";
import moment from "moment";
import { checkPermission } from "../../../../util/commonUtil";
import PermissionModal from "../../../../components/Modals/PermissionModal";
import ProjectionsModal from "./ProjectionsModal";
import NoDataScreen from "../../../../components/NoDataScreen";

//
const ScheduledTemplates = ({ requiredDropdowns }) => {
  const [SelectTab, setSelectTab] = React.useState("Pending Templates");
  const [addTemplateModal, setaddTemplateModal] = useState(false);
  const [templateStep, settemplateStep] = useState(0);
  const [singleItemDetails, setSingleItemDetails] = useState({});
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteRowId, setdeleteRowId] = useState("");
  const [delTempState, setdelTempState] = useState(false);
  const [tableApiData, setTableApiData] = useState([]);
  const [permissionPopUp, setPermissionPopUp] = useState(false);
  const permissionManagement = useSelector((state) => state.permissionManagement);
  const compId = useSelector((state) => state.auth?.loginResponse?.selectCompany?.[0]?.company_id);
  const [projectionsTemplateModal, setProjectionTemplatesModal] = useState(false);
  const [fetchprojections, setFetchProjectionTemplate] = useState({ details: [], loader: false });
  const [rowIdSelect, setRowSelectId] = useState("");

  useEffect(() => {
    const filterIdWiseData =
      requiredDropdowns?.schedulerTemplateState?.responseData?.result?.filter((items) => {
        return items.id === singleItemDetails.id;
      })[0] || {};

    setTableApiData(filterIdWiseData);
  }, [requiredDropdowns?.schedulerTemplateState?.responseData?.result, singleItemDetails]);

  const handleDelete = (row) => {
    if (checkPermission(permissionManagement.permissions, "delete_template")) {
      setDeleteModalOpen(true);
      setdeleteRowId(row.id);
    } else {
      setPermissionPopUp(true);
    }
  };
  const handleDeleteConfirm = async () => {
    try {
      setdelTempState(true);
      const { data } = await main_api.delete(companyAPIsEndPoints.DELETE_TEMPLATE(deleteRowId));
      if (data.isSuccess) {
        pushNotification("Template deleted successfully");
        requiredDropdowns?.fetchSchedulerTemplates();
        setDeleteModalOpen(false);
      }
      setdelTempState(false);
    } catch (error) {
      setdelTempState(false);
    }
  };
  const handleEditRow = (row) => {
    if (checkPermission(permissionManagement.permissions, "edit_template")) {
      settemplateStep(1);
      setSingleItemDetails(row);
    } else {
      setPermissionPopUp(true);
    }
  };

  const handleSearch = (rows) => {
    console.log();
  };

  const handleProjectTemplate = async (id) => {
    if (checkPermission(permissionManagement.permissions, "edit_template")) {
      try {
        setdelTempState(true);
        const { data } = await main_api.post(companyAPIsEndPoints.PROJECT_TEMPLATE, { id });
        if (data.isSuccess) {
          pushNotification("Template has been projected");
          requiredDropdowns?.fetchSchedulerTemplates();
          setDeleteModalOpen(false);
        }
        setdelTempState(false);
      } catch (error) {
        setdelTempState(false);
      }
    } else {
      setPermissionPopUp(true);
    }
  };

  const handleApproveProjectedTemplate = async (id) => {
    if (checkPermission(permissionManagement.permissions, "edit_template")) {
      try {
        setdelTempState(true);
        const { data } = await main_api.post(companyAPIsEndPoints.APPROVE_TEMPLATE, { id });
        if (data.isSuccess) {
          pushNotification("Template Approved");
          requiredDropdowns?.fetchSchedulerTemplates();
          setDeleteModalOpen(false);
        }
        setdelTempState(false);
      } catch (error) {
        setdelTempState(false);
      }
    } else {
      setPermissionPopUp(true);
    }
  };

  const filteredDATA = requiredDropdowns?.schedulerTemplateState?.responseData?.result?.filter((item) => {
    if (SelectTab === "Pending Templates") {
      return item.status === "pending";
    } else if (SelectTab === "Projected Templates") {
      return item.status === "projected";
    }
    return [];
  });

  const addNewTemplate = () => {
    if (checkPermission(permissionManagement.permissions, "create_template")) {
      setaddTemplateModal(true);
    } else {
      setPermissionPopUp(true);
    }
  };

  const handleFetchProjections = async (id) => {
    try {
      setFetchProjectionTemplate({ ...fetchprojections, condition: false });

      const { data } = await main_api.get(companyAPIsEndPoints.FETCH_TEMP_PROJECTIONS(id || rowIdSelect, compId));

      setFetchProjectionTemplate({ ...fetchprojections, details: data.result, condition: true });
    } catch (error) {
      setFetchProjectionTemplate({ ...fetchprojections, condition: false });
    }
  };

  let tableObj = {
    handleDelete,
    handleEditRow,
    settemplateStep,
    setSingleItemDetails,
    handleProjectTemplate,
    changeTab: requiredDropdowns.setActiveTab,
    SelectTab,
    handleApproveProjectedTemplate,
    openProjections: (id) => {
      handleFetchProjections(id);
      setRowSelectId(id);
      setProjectionTemplatesModal(true);
    },
  };

  return (
    <div>
      {templateStep === 1 ? (
        <DetailsItem
          settemplateStep={settemplateStep}
          details={tableApiData}
          requiredDropdowns={requiredDropdowns}
          projectedTemp={SelectTab === "Projected Templates" ? true : false}
        />
      ) : (
        <>
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <SearchInput onChange={handleSearch} placeholder="Type to search..." width="220px" />
              <Segmented
                options={["Pending Templates", "Projected Templates"]}
                onChange={(e) => setSelectTab(e)}
                defaultValue={SelectTab}
                size="large"
                className="ms-2 p-1"
              />
            </div>
            <div className="">
              <CustomBtnWithIcon btnText={"Add new Template"} margin="10px" onClick={() => addNewTemplate()} />
            </div>
          </div>

          {filteredDATA?.length === 0 ? (
            <NoDataScreen heading="You don’t have any templates in your list" showBtn={false} />
          ) : (
            <AntdesignTable
              columns={templateTableCols(tableObj)}
              data={filteredDATA || []}
              loading={requiredDropdowns?.schedulerTemplateState.isLoading}
              // selectedRows={handleSelectDeleteMultiple}
              rowKey="id"
            />
          )}
        </>
      )}

      {addTemplateModal && (
        <AddNewTemplateForm
          close={() => {
            setaddTemplateModal(false);
          }}
          open={addTemplateModal}
          requiredDropdowns={requiredDropdowns}
        />
      )}

      <ProjectionsModal
        open={projectionsTemplateModal}
        close={() => {
          setProjectionTemplatesModal(false);
        }}
        projectionDetails={fetchprojections}
        permissionManagement={permissionManagement}
        permissionPopUp={permissionPopUp}
        setPermissionPopUp={setPermissionPopUp}
        requiredDropdowns={requiredDropdowns}
        handleFetchProjections={handleFetchProjections}
      />
      <DeleteConfirmationRowModal
        onclose={() => setDeleteModalOpen(false)}
        handleConfirmDeleteRows={handleDeleteConfirm}
        openConfimrationDeleteModal={isDeleteModalOpen}
        BtnloadingState={delTempState}
      />
      <PermissionModal onclose={() => setPermissionPopUp(false)} openModal={permissionPopUp} />
    </div>
  );
};

export default ScheduledTemplates;

const templateTableCols = ({
  handleDelete = "",
  handleEditRow,
  settemplateStep,
  setSingleItemDetails,
  handleProjectTemplate,
  SelectTab,
  handleApproveProjectedTemplate,
  openProjections,
}) => {
  return [
    {
      title: "Template Name",
      dataIndex: "name",
      key: "name",
      width: 400,
      render: (text, record) => (
        <a
          onClick={() => {
            settemplateStep(1);
            setSingleItemDetails(record);
          }}
        >
          {text}
        </a>
      ),
    },
    {
      title: "Client",
      dataIndex: "client",
      key: "client",
      render: (text, record) => (
        <a
          onClick={() => {
            settemplateStep(1);
            setSingleItemDetails(record);
          }}
        >
          {record?.client?.name}
        </a>
      ),
    },
    {
      title: SelectTab === "Projected Templates" ? "Projected At" : "Created At",
      dataIndex: "Projected Templates" ? "projected_at" : "created_at",
      key: "Projected Templates" ? "projected_at" : "created_at",
      align: "center",
      render: (text, record) => (
        <a>{moment(SelectTab === "Projected Templates" ? record.projected_at : record.created_at).format("YYYY-DD-MM")}</a>
      ),
    },

    {
      title: "",
      key: "action",
      align: "end",
      render: (_, record) => (
        <Space size="middle">
          {record.status === "projected" && (
            <CustomBtnWithIcon noBackground btnText={"View Projections"} hideIcon onClick={() => openProjections(record.id)} />
          )}
          {record.status === "pending" && (
            <button onClick={() => handleProjectTemplate(record.id)}>
              <TickIcon /> &nbsp; Project Template
            </button>
          )}

          <button
            onClick={() => {
              handleEditRow(record);
            }}
          >
            <EditIcon />
          </button>
          <button
            onClick={() => {
              handleDelete(record);
            }}
          >
            <DeleteIcon />
          </button>
        </Space>
      ),
    },
  ];
};
