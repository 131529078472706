// import React from 'react'

import { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Col, Row } from "antd";

import { Elements, CardElement, useStripe, useElements } from "@stripe/react-stripe-js";

import AntDesignModal from "../../../components/Modals/AntDesignModal/AntDesignModal";
import CustomBtnWithIcon from "../../../components/buttonWithIcon";
import { main_api } from "../../../api/axios_helper";

import { useSelector } from "react-redux";

import { fetchCompDetailsApi } from "../../../redux/slices/auth";
import { useDispatch } from "react-redux";
import { pushNotification } from "../../../util/Notification";

import DeleteConfirmationRowModal from "../../../components/Modals/DeleteConfirmationRowModal";
import NoDataScreen from "../../../components/NoDataScreen";
import { PaymentCardsComponent } from "../../payment/paymentScreens/paymentMethods";
import useFetchClientOnBoardSettings from "../../../hooks/useFetchClientOnboardingSettings";
const REACT_APP_STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY;

const PaymentForm = ({ handleCloseModalOnSuccess }) => {
  const [error, setError] = useState(null);
  const [successLoader, setSuccessLoader] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  const authInfo = useSelector((state) => state.auth.loginResponse);
  const clientManagement = useSelector((state) => state?.clientManangement.clientSingleDetails.responseData);

  const compInfo = authInfo?.selectCompany?.[0] || {};

  let stripeCompanyCustomerId = compInfo.stripe_account_id ? compInfo.stripe_account_id : "";

  let url = `/api/v1/stripe/accounts/${stripeCompanyCustomerId}/customers/${clientManagement?.stripe_customer_id}/payment-methods/`;

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }
    setSuccessLoader(true);
    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (error) {
      setError(error.message);
      setSuccessLoader(false);
    } else {
      try {
        const data = await main_api.post(url, {
          payment_method_id: paymentMethod.id,
          action: "attach", //attach/detach
        });

        if (data.status === 200) {
          pushNotification("Card added succesfully!.");
          handleCloseModalOnSuccess();
        }
        setSuccessLoader(false);
      } catch (error) {
        setError(error.message);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="mt-5">
        <CardElement />
      </div>
      <div className="d-flex justify-content-center my-2">
        <CustomBtnWithIcon
          btnText={"Add Card"}
          type="submit"
          className="my-2"
          hideIcon
          noBackground
          width="200px"
          disabled={successLoader}
        />
      </div>
      {error && <p style={{ color: "red" }}>{error}</p>}
    </form>
  );
};

const PaymentMethodsScreen = () => {
  let dispatch = useDispatch();
  const [paymentMethodModal, setOpenPaymentMethodModal] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);

  const [totalAttachedCards, setTotalAttachedCards] = useState([]);
  const [paymentMethodid, setPaymentMethodid] = useState("");
  const [openConfimrationDeleteModal, setOpenConfimrationDeleteModal] = useState(false);
  const [stripePromise, setStripePromise] = useState(null);
  //
  const compStripeDetails = useSelector((state) => state.auth.companyInformation);
  const authInfo = useSelector((state) => state.auth.loginResponse);
  const paymentCompInfo = useSelector((state) => state.auth.companyDetails);
  const compInfo = authInfo?.selectCompany?.[0] || {};
  const isDetailSubmitted = paymentCompInfo?.stripe_details_submitted;

  const clientManagement = useSelector((state) => state?.clientManangement.clientSingleDetails.responseData);
  let stripeCompanyStripeId = compInfo.stripe_account_id ? compInfo.stripe_account_id : "";
  const { clientSettings, loading } = useFetchClientOnBoardSettings();
  let url = `/api/v1/stripe/accounts/${stripeCompanyStripeId}/customers/${clientManagement?.stripe_customer_id}/payment-methods/`;

  useEffect(() => {
    const stripeInstance = loadStripe(REACT_APP_STRIPE_KEY, {
      stripeAccount: stripeCompanyStripeId,
    });
    setStripePromise(stripeInstance);
  }, [compInfo.stripe_account_id]);

  useEffect(() => {
    dispatch(fetchCompDetailsApi());
    if (isDetailSubmitted) return;
  }, []);
  useEffect(() => {
    if (paymentCompInfo.stripe_customer_id) {
      handleGetAddedCard();
    }
  }, [paymentCompInfo.stripe_customer_id]);

  const handleGetAddedCard = async () => {
    try {
      const { data } = await main_api.get(url);

      setTotalAttachedCards(data);
    } catch (error) {}
  };
  const handleRemoveAddedCard = async (pmId) => {
    try {
      setDeleteLoader(true);
      const { data } = await main_api.post(url, {
        payment_method_id: paymentMethodid,
        action: "detach", //attach/detach
      });
      setDeleteLoader(false);

      handleGetAddedCard();
      if (data?.message === "Payment method detached successfully") {
        pushNotification("Card removed successfully!");
        setOpenConfimrationDeleteModal(false);
      }
    } catch (error) {
      setDeleteLoader(false);

      console.log("Detach Error:", error);
    }
  };
  let currentlyDue = compStripeDetails?.requirements?.currently_due?.length === 0;
  let eventually_due = compStripeDetails?.requirements?.eventually_due?.length === 0;
  let checkAllVerificationInComplete = (!currentlyDue && !eventually_due) || !compStripeDetails?.details_submitted;

  let clientAllowPaymentMethod = clientSettings?.payment_method_enabled ? true : false;
  return (
    <div>
      <div className="d-flex justify-content-end">
        {!checkAllVerificationInComplete && totalAttachedCards?.length > 0 && (
          <CustomBtnWithIcon btnText={"Add Payment Method"} onClick={() => setOpenPaymentMethodModal(true)} />
        )}
      </div>

      {clientAllowPaymentMethod ? (
        checkAllVerificationInComplete ? (
          <NoDataScreen
            heading={`Verification of your company is pending. Please contact ${compStripeDetails?.email} support for further assistance.`}
            btnText={"Add Payment Method"}
            showBtn={false}
          />
        ) : (
          <Row gutter={20} className="mt-3" justify={totalAttachedCards?.length === 0 ? "center" : "start"}>
            {totalAttachedCards?.length === 0 ? (
              <NOCardsAttach openModal={() => setOpenPaymentMethodModal(true)} />
            ) : (
              totalAttachedCards?.map((items, index) => {
                return (
                  <Col sm={5} key={index} className="my-2">
                    <PaymentCardsComponent
                      {...items}
                      removeCard={() => {
                        setOpenConfimrationDeleteModal(true);
                        setPaymentMethodid(items.id);
                      }}
                    />
                  </Col>
                );
              })
            )}
          </Row>
        )
      ) : (
        <p>
          {!loading && (
            <span className="text-danger">
              *Note: The admin has disabled the option to add a payment method in the client settings. Please contact the admin
              for assistance.
            </span>
          )}
        </p>
      )}

      {/* MODAL CARD */}
      <DeleteConfirmationRowModal
        onclose={() => setOpenConfimrationDeleteModal(false)}
        handleConfirmDeleteRows={handleRemoveAddedCard}
        openConfimrationDeleteModal={openConfimrationDeleteModal}
        BtnloadingState={deleteLoader}
      />
      {paymentMethodModal && (
        <AntDesignModal
          title="Payment Methods"
          openModal={paymentMethodModal}
          closeModal={() => setOpenPaymentMethodModal(false)}
          onCancel={() => setOpenPaymentMethodModal(false)}
          onOk={() => setOpenPaymentMethodModal(false)}
          component={
            <Elements stripe={stripePromise}>
              <PaymentForm
                handleCloseModalOnSuccess={() => {
                  handleGetAddedCard();
                  setOpenPaymentMethodModal(false);
                }}
              />
            </Elements>
          }
          footer={
            <div className="d-flex justify-content-end">
              <CustomBtnWithIcon
                btnText={"Close"}
                hideIcon
                className="me-2"
                width="90px"
                onClick={() => setOpenPaymentMethodModal(false)}
              />
            </div>
          }
        />
      )}
    </div>
  );
};

export default PaymentMethodsScreen;

export const NOCardsAttach = ({ openModal }) => {
  return (
    <div className="w-100">
      <NoDataScreen
        heading="You don’t have any payment methods. Let’s get started by adding a payment method"
        btnText={"Add Payment Method"}
        onClick={() => openModal()}
      />
    </div>
  );
};
