import React, { useEffect, useState } from "react";
import { Button, Divider, Form, Input, Radio, Select, Space } from "antd";
import { CustomModal } from "../ModalStyles";
import { pushNotification } from "../../../util/Notification";
import { useSelector } from "react-redux";
import { main_api } from "../../../api/axios_helper";
import { companyAPIsEndPoints, staffAPIsEndPoints } from "../../../constants/ApiEndPoints";
import SegmentedTabs from "../../Form/SegmentedTabs";
import styled from "styled-components";
import RoundedPlusIcon from "../../../assets/rawSvgs/roundedPlusIcon.svg";
import CustomBtnWithIcon from "../../buttonWithIcon";
import { ReactComponent as DeleteIcon } from "../../../assets/rawSvgs/tableDeleteIcon.svg";

const AddNewKeyModal = ({
  openKeyModal,
  setOpenKeyModal,
  postManagementKey,
  postExistingKey,
  clientList,
  getManagementkeys,
  keyPostState,
  keyManagementState,
  singleClientresponseData = null,
}) => {
  const [form] = Form.useForm();
  const [SelectTab, setSelectTab] = useState("New Key");

  const [staffList, setStaffList] = useState([]);
  const [keyList, setKeyList] = useState([]);
  const [clientId, setClientID] = useState("");
  const [selectedKey, setSelectedKey] = useState("");
  const auth = useSelector((state) => state.auth);
  const [possession, setPossession] = useState({});
  const [type, setType] = useState("");

  const onFinish = async (values) => {
    if (singleClientresponseData) {
      values = {
        ...values,
        client_id: singleClientresponseData.id,
      };
    }

    const { data } = await main_api.post(companyAPIsEndPoints.POST_MANAGEMENT_KEYS, { ...values, is_master_key: true });
    if (data.isSuccess) {
      setType("");
      getManagementkeys();

      onReset();
      setOpenKeyModal(false);
    }
  };
  const handleSubmitExistingKey = async (values) => {
    let { existing_key, keys } = values;
    keys = keys.map((key) => ({
      ...key,
      is_master_key: false,
      name: selectedKeyName?.label,
      client: clientId,
      type: "physical",
      code: (selectedKeyName.code = ""),
    }));
    let updatedData = { existing_key, keys };
    try {
      const { data } = await main_api.post(companyAPIsEndPoints.CREATE_COPY_KEY, updatedData);
      if (data.isSuccess) {
        pushNotification("Copies of key created successfully.");
  
        onReset();
        setOpenKeyModal(false);
      }
    } catch (error) {
      pushNotification(error.response.data.message,"error");
      setOpenKeyModal(false);
    }
  };

  useEffect(() => {
    getManagementkeys();
  }, []);

  useEffect(() => {
    if (singleClientresponseData) {
      setClientID(singleClientresponseData.id);
    }
  }, [singleClientresponseData]);

  useEffect(() => {
    if (keyPostState && !keyPostState.isLoading) {
      if (keyPostState.responseData && keyPostState.responseData.isSuccess) {
        // pushNotification("Key created successfully.", "success");
      } else if (keyPostState.responseData && !keyPostState.responseData.isSuccess) {
        pushNotification("Cannot create a duplicate master key.", "error");
      }
    }
  }, [keyPostState]);

  // Get Staff list
  useEffect(() => {
    // if (openKeyModal) {
    let company_id = auth.loginResponse?.selectCompany?.[0]?.company_id || null;
    if (company_id) {
      main_api
        .get(`${staffAPIsEndPoints.LIST_STAFF(`usercompanyrole__company=${company_id}`)}`)
        .then(({ data }) => {
          setStaffList(data);
        })
        .catch((error) => {
         pushNotification("Something went wrong!", "error");

          // console.log(error);
        });
    }
    // }
  }, [possession]);

  // Get Keys list
  useEffect(() => {
    if (clientId) {
      main_api
        .get(`${companyAPIsEndPoints.GET_KEY_BY_CLIENT_ID(clientId)}`)
        .then(({ data }) => {
          setKeyList(data);
        })
        .catch((error) => {
          pushNotification("Something went wrong!", "error");

          // console.log(error);
        });
    }
  }, [clientId]);
  const onReset = () => {
    form.resetFields();
  };

  const updatedKeyList = keyList?.result?.map((item) => ({
    value: item.id,
    label: item.name,
    code: item.code,
    staff: item.staff,
  }));
  const selectedKeyName = updatedKeyList?.find((item) => item.value === selectedKey);

  return (
    <CustomModal
      open={openKeyModal}
      maskClosable={false}
      title="Add a Key"
      width="748px"
      onCancel={() => setOpenKeyModal(false)}
      footer={null}
    >
      <Divider />
      <SegmentedTabs
        SelectTab={SelectTab}
        setSelectTab={setSelectTab}
        options={["New Key", "Copy of Existing"]}
        classes="p-1"
        size="large"
      />

      {SelectTab === "New Key" ? (
        <Form form={form} name="addKeyModal" onFinish={onFinish} layout="vertical">
          <Form.Item
            name="name"
            label="Key Name"
            rules={[
              {
                required: true,
                message: "Key name is required",
              },
              { min: 3, max: 50, message: "Name must be between 3 and 50 characters" },
            ]}
          >
            <Input size="large" placeholder="Key Name" />
          </Form.Item>
          <Form.Item
            name="client_id"
            label="Client Name"
            initialValue={singleClientresponseData ? singleClientresponseData?.name : null}
            rules={[
              {
                required: true,
                message: "Client name is required",
              },
            ]}
          >
            {singleClientresponseData ? (
              <Input placeholder="Select client" disabled />
            ) : (
              <Select placeholder="Select client" size="large" allowClear options={clientList} />
            )}
          </Form.Item>

          <Form.Item
            name="possession"
            label="Possession"
            rules={[
              {
                required: true,
                message: "Possession is required",
              },
            ]}
          >
            <Select
              placeholder="Possession"
              allowClear
              size="large"
              onChange={(value) => setPossession(value)}
              options={[
                { value: "client", label: "Client" },
                { value: "staff", label: "Staff" },
                { value: "office", label: "At Office" },
              ]}
            />
          </Form.Item>
          {possession === "staff" && (
            <Form.Item name="staff_id" label="Staff Name">
              <Select
                placeholder="Possession"
                allowClear
                size="large"
                options={staffList.map((item, index) => ({
                  value: item.id,
                  label: <span key={index}>{item.name}</span>,
                }))}
              />
            </Form.Item>
          )}
          <Form.Item
            name="type"
            label="Type of Key"
            rules={[
              {
                required: true,
                message: "Type is required",
              },
            ]}
          >
            <Radio.Group
              size="large"
              className="d-flex flex-column gap-2"
              options={[
                { value: "physical", label: "Physical" },
                { value: "digital", label: "Digital" },
              ]}
              onChange={(e) => setType(e.target.value)}
            />
          </Form.Item>
          {type === "digital" && (
            <Form.Item name="code" label="Code">
              <Input size="large" />
            </Form.Item>
          )}
          <Form.Item>
            <Space className="d-flex justify-content-end mt-4">
              <CustomBtnWithIcon
                type="button"
                btnText="Cancel"
                margin="0px 5px"
                noBackground
                hideIcon
                onClick={() => setOpenKeyModal(false)}
              />
              <CustomBtnWithIcon btnText="Save Key" type="submit" hideIcon />
            </Space>
          </Form.Item>
        </Form>
      ) : (
        <Form form={form} name="addKeyModal" onFinish={handleSubmitExistingKey} layout="vertical">
          <Form.Item
            name="client"
            label="Client Name"
            initialValue={singleClientresponseData ? singleClientresponseData?.name : null}
            rules={[
              {
                required: true,
                message: "Client name is required",
              },
            ]}
          >
            {singleClientresponseData ? (
              <Input placeholder="Select client" disabled />
            ) : (
              <Select
                placeholder="Select client"
                size="large"
                allowClear
                options={clientList}
                onChange={(value) => setClientID(value)}
              />
            )}
          </Form.Item>
          <Form.Item
            name="existing_key"
            label="Select the existing key"
            rules={[
              {
                required: true,
                message: "Key name is required",
              },
            ]}
          >
            <Select
              placeholder="Select Key"
              size="large"
              allowClear
              options={updatedKeyList}
              onChange={(value) => setSelectedKey(value)}
            />
          </Form.Item>

          <CopiesOfKeysBox>
            <DetailBox>Add details of all copies of keys</DetailBox>
            <Form.List name="keys" initialValue={[{}]}>
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, fieldKey, ...restField }, index) => {
                    return (
                      <>
                        <div key={key} className="d-flex align-items-center gap-3" key={index}>
                          <span className="d-flex flex-column w-100" style={{ marginBottom: "24px" }}>
                            <LabelFiled>Key Name</LabelFiled>
                            <Input value={selectedKeyName?.label} disabled size="large" />
                          </span>
                          <Form.Item
                            {...restField}
                            name={[name, "possession"]}
                            fieldKey={[fieldKey, "possession"]}
                            label="Possession"
                            className="w-100"
                            rules={[
                              {
                                required: true,
                                message: "Possession is required",
                              },
                            ]}
                          >
                            <Select
                              placeholder="Select Possession"
                              size="large"
                              width="100"
                              className=""
                              allowClear
                              onChange={(value) => {
                                setPossession((prev) => ({ ...prev, [fieldKey]: value }));
                                // restField.onChange(value);
                              }}
                              options={[
                                { value: "client", label: "Client" },
                                { value: "staff", label: "Staff" },
                                { value: "office", label: "At Office" },
                              ]}
                            />
                          </Form.Item>
                          {possession && possession[fieldKey] === "staff" && (
                            <Form.Item name={[name, "staff"]} label="Staff Name" className="w-100">
                              <Select
                                placeholder="Staff Name"
                                allowClear
                                width="100"
                                size="large"
                                options={staffList.map((item, index) => ({
                                  value: item.id,
                                  label: <span key={index}>{item.name}</span>,
                                }))}
                              />
                            </Form.Item>
                          )}
                          {fields.length > 1 && (
                            <Form.Item>
                              <Button type="danger" onClick={() => remove(name)}>
                                <DeleteIcon />
                              </Button>
                            </Form.Item>
                          )}
                        </div>
                      </>
                    );
                  })}
                  <Form.Item>
                    <CutomBtn onClick={() => add()} type="button">
                      <img src={RoundedPlusIcon} alt="icon" />
                      <span>Add more</span>
                    </CutomBtn>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </CopiesOfKeysBox>

          <Divider />
          <Form.Item>
            <Space className="d-flex justify-content-end">
              <CustomBtnWithIcon
                btnText="Cancel"
                type="button"
                margin="0px 5px"
                noBackground
                hideIcon
                onClick={() => setOpenKeyModal(false)}
              />
              <CustomBtnWithIcon btnText="Save Key" type="submit" hideIcon />
            </Space>
          </Form.Item>
        </Form>
      )}
    </CustomModal>
  );
};
export default AddNewKeyModal;
const CutomBtn = styled.button`
  display: flex;
  align-items: center;
  gap: 6px;
  span {
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
    color: #212121;
  }
`;
const CopiesOfKeysBox = styled.div`
  border-radius: 8px;
  border: 1px solid #eeeeee;
  padding: 10px;
  background: #f9f9f9;
`;
const DetailBox = styled.p`
  color: #616161;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
`;
const LabelFiled = styled.div`
  margin-bottom: 6px;
  color: rgb(59, 59, 59);
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
`;
