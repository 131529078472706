import React, { useEffect, useState } from "react";
import { TableHeader } from "../clients/clientStyles";
import SearchInput from "../../../components/SearchInput";
import CustomBtnWithIcon from "../../../components/buttonWithIcon";
import { AntdesignTable } from "../../../components/antDesignTable/AntdesignTable";
import dayjs, { Dayjs } from "dayjs";
import { DatePicker } from "antd";
import AddPetModal from "../../../components/Modals/PetManagementModal/AddPetModal";
import EditPetModal from "../../../components/Modals/PetManagementModal/EditPetModal";
import { PetListColumns } from "../../../constants/CompanyTableHeaders/PetTableHeaders";
import { main_api } from "../../../api/axios_helper";
import moment from "moment";
import { companyAPIsEndPoints } from "../../../constants/ApiEndPoints";
import DeleteConfirmationRowModal from "../../../components/Modals/DeleteConfirmationRowModal";
import { pushNotification } from "../../../util/Notification";
import { useSelector } from "react-redux";
import NoDataScreen from "../../../components/NoDataScreen";
import PermissionModal from "../../../components/Modals/PermissionModal";
import { checkPermission } from "../../../util/commonUtil";
const { RangePicker } = DatePicker;

//
const PetListTab = (props) => {
  const [search, setSearch] = useState("");
  const [date, setDate] = useState("");
  const [isLoading, setIsloading] = useState(false);
  const [isAddPetOpen, SetIsAddPetOpen] = useState(false);
  const [isEditPetOpen, SetIsEditPetOpen] = useState(false);
  const [isDeletePetOpen, SetIsDeletePetOpen] = useState(false);
  const [imageFromComp, setImageFromComp] = useState(undefined);
  const [editPetValues, setEditPetValues] = useState({});
  const [deletePetValues, setDeletePetValues] = useState({});
  const [permissionPopUp, setPermissionPopUp] = useState(false);
  const permissionManagement = useSelector((state) => state.permissionManagement);

  useEffect(() => {
    if (checkPermission(permissionManagement.permissions, "view_pets")) {
      props.loadPetList();
    }
    if (checkPermission(permissionManagement.permissions, "view_clients")) {
      props.getManagementClients();
    }
    if (checkPermission(permissionManagement.permissions, "view_vets")) {
      props.loadVetList();
    }
  }, [permissionManagement.permissions]);

  const addPet = (formValues) => {
    const formattedDate = formValues.birth_date.format("YYYY-MM-DD");

    const updatedValues = {
      ...formValues,
      birth_date: formattedDate,
    };

    setIsloading(true);
    main_api
      .post(companyAPIsEndPoints.POST_PET_LIST_PETS, updatedValues)
      .then(({ data }) => {
        setIsloading(false);
        if (data?.isSuccess) {
          pushNotification(data?.message, "success");
          SetIsAddPetOpen(false);
          props.loadPetList();
        }
      })
      .catch((error) => {
        pushNotification("Something went wrong!", "error");
        console.log(error);
      });
  };
  const uploadToS3 = async (file, type) => {
    const formData = new FormData();
    formData.append(type, file);
    const actualFile = file.originFileObj || file;

    formData.append(type, actualFile);
    try {
      const response = await main_api.post(companyAPIsEndPoints.UPLOAD_OBJECT, formData);

      if (response.data.isSuccess) {
        return response;
      }
    } catch (error) {
      console.error(`Error uploading ${type} to S3`, error);
    }
  };

  const editPet = async (formValues) => {
    const formattedDate = formValues.birth_date.format("YYYY-MM-DD");

    const customFields = editPetValues?.custom_fields?.groups.flatMap((group) =>
      group.fields.map((field) => ({
        id: field.id,
        value: formValues[field.id] || "",
      })),
    );
    let fileResponse;
    if (imageFromComp) {
      fileResponse = await uploadToS3(imageFromComp?.[0], "image");
    }
    const submissionData = {
      ...formValues,
      custom_fields: customFields,
      logo: fileResponse?.data?.url || editPetValues?.logo,
    };

    const updatedValues = {
      ...submissionData,

      birth_date: formattedDate,
    };

    setIsloading(true);
    main_api
      .put(`${companyAPIsEndPoints.PUT_SINGLE_PET_LIST_PETS(editPetValues.id)}`, updatedValues)
      .then(({ data }) => {
        setIsloading(false);
        pushNotification("Pet Detail Successfully Updated!", "success");
        SetIsEditPetOpen(false);
        props.loadPetList();
      })
      .catch((error) => {
        pushNotification("Something went wrong!", "error");
        console.log(error);
      });
  };

  const deletePet = () => {
    setIsloading(true);

    main_api
      .delete(companyAPIsEndPoints.DELETE_SINGLE_PET_LIST_PETS(deletePetValues))
      .then(({ data }) => {
        setIsloading(false);
        pushNotification(data.message, "success");
        SetIsDeletePetOpen(false);
        props.loadPetList();
      })
      .catch((error) => {
        pushNotification("Something went wrong!", "error");
        console.log(error);
      });
  };

  const onRangeChange = (dates, dateStrings) => {
    setDate(dateStrings);
    props.searchPetList(search, dateStrings);
  };

  const searchBlurHandler = () => {
    props.searchPetList(search, date);
  };

  const rangePresets = [
    {
      label: "Last 7 Days",
      value: [dayjs().add(-7, "d"), dayjs()],
    },
    {
      label: "Last 14 Days",
      value: [dayjs().add(-14, "d"), dayjs()],
    },
    {
      label: "Last 30 Days",
      value: [dayjs().add(-30, "d"), dayjs()],
    },
    {
      label: "Last 90 Days",
      value: [dayjs().add(-90, "d"), dayjs()],
    },
  ];

  const handleEditRow = (record) => {
    if (checkPermission(permissionManagement.permissions, "edit_pet")) {
      if (record) {
        setEditPetValues(props.petList.find((item) => item.id == record.id));
      }
      SetIsEditPetOpen(true);
    } else {
      setPermissionPopUp(true);
    }
  };

  const handleDelete = (record) => {
    if (checkPermission(permissionManagement.permissions, "delete_pet")) {
      if (record) {
        const { id } = record;
        setDeletePetValues(id);
      }
      SetIsDeletePetOpen(true);
    } else {
      setPermissionPopUp(true);
    }
  };

  const addNewPet = () => {
    if (checkPermission(permissionManagement.permissions, "create_pet")) {
      SetIsAddPetOpen(true);
    } else {
      setPermissionPopUp(true);
    }
  };

  const viewPetDetail = (record) => {
    if (checkPermission(permissionManagement.permissions, "view_pet")) {
      props.openDetailModal(true);
      props.loadPetDetail(record.id);
      props.setSinglePetDetails(record);
    } else {
      setPermissionPopUp(true);
    }
  };

  let clientDetails = props?.clientList?.result?.find((item) => item.id === props?.clientId);

  return (
    <>
      {isAddPetOpen && (
        <AddPetModal
          isLoading={isLoading}
          isModalOpen={isAddPetOpen}
          setModalOpen={SetIsAddPetOpen}
          onFinish={addPet}
          vetList={props.vetList}
          clientList={props?.clientList.result}
          clientId={props?.clientId}
          isClientPortal={props?.isClientPortal}
          showClientSelect={!props.isClientPortal}
          clientDetails={clientDetails}
        />
      )}

      {isEditPetOpen && (
        <EditPetModal
          isLoading={isLoading}
          isModalOpen={isEditPetOpen}
          setModalOpen={SetIsEditPetOpen}
          editPetValues={editPetValues}
          onFinish={editPet}
          vetList={props.vetList}
          clientList={props.clientList}
          getImageFromEditPet={setImageFromComp}
        />
      )}
      <DeleteConfirmationRowModal
        onclose={() => SetIsDeletePetOpen(false)}
        handleConfirmDeleteRows={deletePet}
        openConfimrationDeleteModal={isDeletePetOpen}
      />
      {props?.petList.length > 0 && checkPermission(permissionManagement.permissions, "view_pets") ? (
        <>
          <TableHeader>
            <div className="d-flex align-items-center col">
              <SearchInput
                search={search}
                onChange={(value) => setSearch(value)}
                onBlur={searchBlurHandler}
                placeholder="Type to search.."
              />
              <RangePicker className="ms-2" style={{ height: "40px" }} presets={rangePresets} onChange={onRangeChange} />

              <CustomBtnWithIcon
                btnText={"Add new Pet"}
                className="ms-md-auto"
                margin="10px 10px 10px auto"
                onClick={() => addNewPet()}
              />
            </div>
          </TableHeader>
          <AntdesignTable
            columns={PetListColumns({ handleDelete, handleEditRow, viewPetDetail, props })}
            data={props?.petList || []}
          />
        </>
      ) : (
        <NoDataScreen
          heading="You don’t have any Pets in your list. Let’s get started by adding a new Pet"
          btnText={"Add new Pet"}
          onClick={() => addNewPet()}
        />
      )}
      <PermissionModal onclose={() => setPermissionPopUp(false)} openModal={permissionPopUp} />
    </>
  );
};

export default PetListTab;
