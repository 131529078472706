import React, { useEffect, useState } from "react"
import { Tabs, Segmented } from 'antd';
import styled from "styled-components";
import ClientListing from "./ClientListing";
import { ReportingContainer, ReportingItem } from "./ReportDetail";
import StaffListing from "./StaffListing";

function ClientAndStaff({saveReport, setCurrentReport, setSaveReport, currentReport, saveReportCallBack}) {

  const [selectTab, setSelectTab] = React.useState("Client");

  const clientFilters = [
    {label: 'All Active Clients', id: 0, value: 'all_active_clients'},
    {label: 'Date Added to Furgis',  id: 1, value: 'date_added_to_furgis'},
    {label: 'Clients with or without keys',  id: 2, value: 'with_or_without_keys'},
    {label: 'Clients in Zip Code',  id: 3, value: 'in_zip_code'},
    {label: 'Deactived Accounts',  id: 4, value: 'deactivated_accounts'},
    {label: 'Scheduling Between',  id: 5, value: 'scheduling_between'},
    {label: 'Not Scheduling Between',  id: 6, value: 'not_scheduling_between'}
  ]

  const staffFilters = [
    {label: 'All Staff', id: 0, value: 'all_staff'},
    {label: 'Not Activated Accounts', id: 1, value: 'not_activated_accounts'},
    {label: 'Staff Scheduled in Date Range', id: 2, value: 'staff_scheduled_in_date_range'},
    {label: 'Staff scheduled for service(s)', id: 3, value: 'staff_scheduled_on_date_for_service'},
    {label: 'Staff in Zip Code',  id: 4, value: 'in_zip_code'},
  ]

  const [activeTab, setActiveTab] = useState(0);

  const handleChangeTabs = (tabs) => {
    setActiveTab(tabs);
  };

  useEffect(()=>{
    setSaveReport(false)
  }, [])

  return (
    <>
    <ReportingContainer>
      <ReportingItem>
          <TabHeader>Filters</TabHeader>
          <Segmented
              options={["Client", "Staff"]}
              onChange={(e) => {setActiveTab(0);setSelectTab(e)}}
              defaultValue={selectTab}
              size="large"
              className="ms-2 p-1"
          />
          {selectTab =="Client" && <>
            <TabHeader>General</TabHeader>

            <TabItem
                activeKey={activeTab}
                onChange={handleChangeTabs}
                tabPosition="left"
                items={clientFilters.slice(0,5).map((val, i) => {
                    return {
                        label: `${val.label}`,
                        key: val.id,
                    };
                })}
            >
            </TabItem>

            <TabHeader>Scheduling</TabHeader>

            <TabItem
                activeKey={activeTab}
                onChange={handleChangeTabs}
                tabPosition="left"
                items={clientFilters.slice(5,7).map((val, i) => {
                    return {
                        label: `${val.label}`,
                        key: val.id,
                    };
                })}
            >
            </TabItem>
          </>}
          {selectTab =="Staff" && <>
            <TabHeader>General</TabHeader>

            <TabItem
                activeKey={activeTab}
                onChange={handleChangeTabs}
                tabPosition="left"
                items={staffFilters.slice(0,5).map((val, i) => {
                    return {
                        label: `${val.label}`,
                        key: val.id,
                    };
                })}
            >
            </TabItem>
          </>}
      </ReportingItem>
      <FilterDetail>
      {selectTab =="Client" && <ClientListing saveReportCallBack={saveReportCallBack} saveReport={saveReport} setSaveReport={setSaveReport} filter={clientFilters[activeTab].value} currentReport={currentReport} setCurrentReport={setCurrentReport}/>}
      {selectTab =="Staff" && <StaffListing saveReportCallBack={saveReportCallBack} saveReport={saveReport} setSaveReport={setSaveReport} filter={staffFilters[activeTab].value} currentReport={currentReport} setCurrentReport={setCurrentReport}/>}
      </FilterDetail>
    </ReportingContainer>
    </>
  )
}

export default ClientAndStaff;


const TabHeader = styled.div`
  color: #424242;
  font-weight: 700;
  margin-top: 5px;
  margin-bottom: 5px;
`;

const FilterDetail = styled.div`
  width: 100%;
  margin: 0 20px;
`;


const TabItem = styled(Tabs)`
    margin-top: 20px;
    .ant-tabs-tab-active{
        background-color: #EEEEEE;
    }
    .ant-tabs-content-holder{
       border-left: none;
    }
    .ant-tabs-tab-active .ant-tabs-tab-btn{
        color: #424242 !important;
    }
    .ant-tabs-tab {
        color: #424242 !important;
    }
    .ant-tabs-tab-btn{
        color: #424242 !important;
    }
    .ant-tabs-nav-operations {
        display: none !important; 
    }

    .ant-tabs-nav{
      width: 100%;
      min-width: 50px;
    }


`;