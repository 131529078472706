import { format, getTime, formatDistanceToNow } from "date-fns";

// ----------------------------------------------------------------------

export function fDate(date) {
  return format(new Date(date), "dd MMMM yyyy");
}

export function fDateTime(date) {
  return format(new Date(date), "dd MMM yyyy HH:mm");
}

export function fTimestamp(date) {
  return getTime(new Date(date));
}

export function fDateTimeSuffix(date) {
  return format(new Date(date), "dd/MM/yyyy hh:mm p");
}

export function fToNow(date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
  });
}

export function fTimeWithPeriod(date) {
  if (date) {
    return format(new Date(date), "h:mm a");
  }
  return new Date();
}


export function convertTo12HourFormat(timeString) {
  // Create a Date object with a dummy date
  const date = new Date('2000-01-01 ' + timeString);
  
  // Get hours, minutes, and seconds from the Date object
  const hours = date.getHours();
  const minutes = date.getMinutes();

  // Determine AM or PM designation
  const ampm = hours >= 12 ? 'PM' : 'AM';

  // Convert hours to 12-hour format
  const twelveHour = hours % 12 || 12; // Convert 0 to 12 for 12:00 AM
  
  // Pad single-digit minutes with leading zero if necessary
  const paddedMinutes = minutes < 10 ? '0' + minutes : minutes;

  // Format the time as "HH:MM AM/PM"
  const formattedTime = `${twelveHour}:${paddedMinutes} ${ampm}`;

  return formattedTime;
}


export function formatTimefromDate(date) {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12 || 12;
  minutes = minutes < 10 ? '0' + minutes : minutes;
  return `${hours}:${minutes} ${ampm}`;
}
