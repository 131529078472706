import React, { useEffect, useState } from "react";
import { Row, Col, Card, Button, Segmented } from "antd";
import HeadingComponent from "../../../components/Heading";
import MonthlyPlan from "./monthlyPlan";
import YearlyPlan from "./yearlyPlan";
import { main_api } from "../../../api/axios_helper";
import { useSelector } from "react-redux";
import { companyAPIsEndPoints } from "../../../constants/ApiEndPoints";
import { pushNotification } from "../../../util/Notification";
import CustomBtnWithIcon from "../../../components/buttonWithIcon";
import AntDesignModal from "../../../components/Modals/AntDesignModal/AntDesignModal";
import AddPlan from "./AddPlan";
import { ReactComponent as ConfirmIcon } from "../../../assets/rawSvgs/undraw_faq_re_31cw1.svg";
import { NOCardsAttachModal } from "../../client/invoicing/MakePaymentModal";
import AntDesignDropDownSelect from "../../../components/AntDesignDropDownSelect/AntDesignDropDownSelect";
import { loadStripe } from "@stripe/stripe-js";

//
const REACT_APP_STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY;
const stripe = loadStripe(REACT_APP_STRIPE_KEY);
//
const SubscriptionPlan = ({ platform = false }) => {
  const [SelectTab, setSelectTab] = React.useState("Monthly");
  const auth = useSelector((state) => state.auth);
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);
  const [addPlan, setAddPlan] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [companySubscriptionPlan, setCompanySubscriptionPlan] = useState();
  const company_id = auth.loginResponse?.selectCompany?.[0]?.company_id || null;
  //

  const [totalAttachedCards, setTotalAttachedCards] = useState([]);
  const [selectedCardPaymentId, setSelectedCardPaymentId] = useState("");
  const [paymentConfirmModal, setPaymentConfirmModal] = useState(false);
  const [openSubcribeModal, setOpenSubcribeModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [selectedSubsPlan, setSelectedSubsPlan] = useState("");
  const [stripePromise, setStripePromise] = useState(null);
  const [loaderInfoCheck, setLoaderInfoCheck] = useState(false);
  const [stripeVerificationLink, setStripeVerificationLink] = useState("");

  //
  const compStripeDetails = auth?.companyInformation;
  const authInfo = auth?.loginResponse;
  const compInfo = authInfo?.selectCompany?.[0] || {};
  const paymentCompInfo = auth.companyDetails;
  const platformStripeId = authInfo?.selectCompany?.[0]?.platform_account_id;
  let stripeCusId = paymentCompInfo.stripe_customer_id ? paymentCompInfo.stripe_customer_id : "";

  let url = `/api/v1/stripe/accounts/${platformStripeId}/customers/${stripeCusId}/payment-methods/`;
  useEffect(() => {
    handleGetAddedCard();
  }, []);

  useEffect(() => {
    const instStripe = async () => {
      try {
        const stripeInstance = loadStripe(REACT_APP_STRIPE_KEY, {});
        const stripe = await stripeInstance;
        setStripePromise(stripe);
      } catch (error) {
        console.log("ERROR:", error);
      }
    };
    instStripe();
  }, [stripeCusId]);

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const handleGetAddedCard = async () => {
    try {
      setLoader(true);
      const { data } = await main_api.get(url);

      let cardsMap = data?.map((items) => {
        return {
          label: `${capitalizeFirstLetter(items?.card?.brand)} - Last Digits ${items?.card?.last4}`,
          value: items?.id,
        };
      });

      setLoader(false);

      setTotalAttachedCards(cardsMap);
    } catch (error) {
      setLoader(false);
    }
  };

  const loadSubscriptionPlans = () => {
    main_api
      .get(companyAPIsEndPoints.GET_SUBCSRIPTION(SelectTab.toLowerCase()))
      .then(({ data }) => {
        setSubscriptionPlans(data.result);
      })
      .catch((error) => {
        console.log(error?.response?.data);
      });
  };

  const loadSelectedSubscriptionPlan = () => {
    if (company_id) {
      main_api
        .get(companyAPIsEndPoints.FETCH_SINGLE_COMPANY(company_id))
        .then(({ data }) => {
          setCompanySubscriptionPlan(data.result.subscription);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleSelectSubscriptionPlan = (selectedPlan) => {
    if (platform) {
      setSelectedPlan(selectedPlan);
    } else {
      if (company_id && selectedPlan) {
        setOpenSubcribeModal(true);

        setSelectedSubsPlan(selectedPlan);
        return;
        // const updatedData = { subscription_id: selectedPlan.id, company_id: company_id, payment_method_id: "" };
        // main_api
        //   .post(companyAPIsEndPoints.UPDATE_SUBSCRIPTION, updatedData)
        //   .then(({ data }) => {
        //     pushNotification("Company subscription has been updated successfully!", "success");
        //     setCompanySubscriptionPlan(selectedPlan.id);
        //   })
        //   .catch((error) => {
        //     console.log(error);
        //   });
      }
    }
  };

  const handleConfirmSelectPlan = () => {
    if (company_id && selectedSubsPlan) {
      const updatedData = {
        subscription_id: selectedSubsPlan.id,
        company_id: company_id,
        payment_method_id: selectedCardPaymentId,
      };
      setLoader(true);
      main_api
        .post(companyAPIsEndPoints.UPDATE_SUBSCRIPTION, updatedData)
        .then(({ data }) => {
          sendStripeSecret(data);
        })

        .catch((error) => {
          setLoader(false);
          console.log(error);
        });
    }
  };
  const sendStripeSecret = async (data) => {
    try {
      const result = await stripePromise?.confirmCardPayment(data?.client_secret, {
        payment_method: selectedCardPaymentId,
      });
      setLoader(false);
      if (result?.paymentIntent.status === "succeeded") {
        pushNotification("Company subscription has been updated successfully!", "success");
        handleClose();
        setPaymentConfirmModal(false);
      }
      setPaymentConfirmModal(false);
    } catch (error) {
      setLoader(false);
      console.log("error", error);
    }
  };

  useEffect(() => {
    loadSubscriptionPlans();
    loadSelectedSubscriptionPlan();
  }, [SelectTab]);

  const handleClose = () => {
    setOpenSubcribeModal(false);
    setSelectedCardPaymentId("");
    setSelectedSubsPlan("");
    setLoader(false);
  };
  let currentlyDue = compStripeDetails?.requirements?.currently_due?.length === 0;
  let eventually_due = compStripeDetails?.requirements?.eventually_due?.length === 0;
  let checkAllVerificationInComplete = (!currentlyDue && !eventually_due) || !compStripeDetails?.details_submitted;
  useEffect(() => {
    if (!checkAllVerificationInComplete) return;
    handleGetFreshLinkUrl();
  }, []);

  const handleGetFreshLinkUrl = async () => {
    try {
      setLoaderInfoCheck(true);
      const { data } = await main_api.post(companyAPIsEndPoints.GET_INFORMATION_URL, {
        account_id: compInfo?.stripe_account_id,
      });
      setLoaderInfoCheck(false);

      setStripeVerificationLink(data?.account_link_url);
    } catch (error) {
      setLoaderInfoCheck(false);

      console.log("ERROR ", error);
    }
  };

  return (
    <>
      {checkAllVerificationInComplete ? (
        <div>
          <div className="w-50">
            <HeadingComponent
              text="Your verification of Stripe is not complete. Redirected to the Stripe verification process to complete your information. Please click the following
              link to proceed:"
              fontSize="14px"
              fontWeight={400}
              margin={"15px 0px"}
            />

            <Button
              type="primary"
              size="medium"
              style={{ color: "black", fontWeight: 500 }}
              disabled={loaderInfoCheck}
              onClick={() => window.open(stripeVerificationLink, "_blank", "noopener,noreferrer")}
            >
              Stripe Verification
            </Button>
          </div>

          <div>
            <HeadingComponent
              text={`Note: This link is valid for a single use only. If it has expired, please click on the "Get Link" button to receive
              a new verification link.`}
              fontSize="12px"
              fontWeight={500}
              margin={"10px 0px"}
              color="#616161"
            />
          </div>
          <Button
            type="primary"
            size="medium"
            style={{ color: "black", fontWeight: 500 }}
            onClick={handleGetFreshLinkUrl}
            disabled={loaderInfoCheck}
          >
            Get Link
          </Button>
        </div>
      ) : (
        <div>
          <Heading text="Choose your Plan" fontSize="1rem" color="black" />
          <p style={{ fontSize: "18px", color: "grey" }}>You can cancel subscription at any time.</p>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Segmented
              options={["Monthly", "Yearly"]}
              onChange={(e) => {
                setSelectTab(e);
              }}
              defaultValue={SelectTab}
              size="large"
              className="ms-2 p-1"
            />
            {platform && <CustomBtnWithIcon btnText={"Add Plan"} onClick={() => setAddPlan(true)} />}
          </div>

          <div style={{ marginTop: "50px", height: "600px" }}>
            {SelectTab === "Yearly" ? (
              <YearlyPlan
                subscriptionPlans={subscriptionPlans}
                companySubscriptionPlan={companySubscriptionPlan}
                handleSelectSubscriptionPlan={handleSelectSubscriptionPlan}
                platform={platform}
              />
            ) : (
              <MonthlyPlan
                subscriptionPlans={subscriptionPlans}
                companySubscriptionPlan={companySubscriptionPlan}
                handleSelectSubscriptionPlan={handleSelectSubscriptionPlan}
                platform={platform}
              />
            )}
          </div>
          {(addPlan || selectedPlan) && (
            <AddPlan
              setSelectedPlan={setSelectedPlan}
              selectedPlan={selectedPlan}
              loadSubscriptionPlans={loadSubscriptionPlans}
              setAddPlan={setAddPlan}
            />
          )}
        </div>
      )}
      {/* ========== MODALS ====== */}
      {openSubcribeModal && (
        <AntDesignModal
          openModal={openSubcribeModal}
          closeModal={handleClose}
          onCancel={handleClose}
          onOk={handleClose}
          width={550}
          title={"Subscription Plan"}
          component={
            <Row gutter={10}>
              {false ? (
                "Loading"
              ) : false ? (
                <NOCardsAttachModal />
              ) : (
                <>
                  <Col md={24} className=" ">
                    <HeadingComponent
                      text={`Select card and then click on Confirm to proceed with the selected subscription plan. `}
                      fontSize="14px"
                      fontWeight={400}
                      color="#212121"
                      margin={"10px 0px"}
                    />
                    <HeadingComponent
                      text={`Subscription plan: ${selectedSubsPlan?.name}`}
                      fontSize="14px"
                      fontWeight={400}
                      color="#212121"
                    />
                    <HeadingComponent
                      text={`Subscription cost: ${selectedSubsPlan?.cost}${selectedSubsPlan?.cost_unit} `}
                      fontSize="14px"
                      fontWeight={400}
                      color="#212121"
                      margin={"10px 0px"}
                    />
                    <HeadingComponent
                      text={`Plan type: ${selectedSubsPlan?.plan_type} `}
                      fontSize="14px"
                      fontWeight={400}
                      color="#212121"
                    />
                  </Col>
                  <Col md={12} className="mt-4">
                    <AntDesignDropDownSelect
                      placeholder="Select Card"
                      defaultValue="Select Card"
                      onChange={(e) => {
                        setSelectedCardPaymentId(e);
                      }}
                      options={totalAttachedCards || []}
                      // disabled={loader}
                    />
                  </Col>
                </>
              )}
              {paymentConfirmModal && (
                <AntDesignModal
                  openModal={paymentConfirmModal}
                  closeModal={() => setPaymentConfirmModal(false)}
                  onCancel={() => setPaymentConfirmModal(false)}
                  onOk={() => setPaymentConfirmModal(false)}
                  width={550}
                  title={"Subscription Plan"}
                  component={
                    <div className="d-flex justify-content-center flex-column align-items-center">
                      <ConfirmIcon />
                      <HeadingComponent
                        text={"Are you sure?"}
                        fontSize="20px"
                        fontWeight={700}
                        color="#000000"
                        margin="10px 0px"
                      />
                      <HeadingComponent
                        text={"Please review the details carefully before proceeding"}
                        fontSize="14px"
                        fontWeight={400}
                        color="#000000"
                      />
                    </div>
                  }
                  footer={
                    <div className="d-flex justify-content-end mt-5">
                      <CustomBtnWithIcon
                        btnText={"Cancel"}
                        hideIcon
                        noBackground
                        className="me-2"
                        width="90px"
                        onClick={() => setPaymentConfirmModal(false)}
                      />
                      <CustomBtnWithIcon
                        btnText={"Confirm Plan"}
                        hideIcon
                        className="me-2"
                        onClick={handleConfirmSelectPlan}
                        disabled={loader}
                      />
                    </div>
                  }
                />
              )}
            </Row>
          }
          footer={
            <div className="d-flex justify-content-end ">
              <CustomBtnWithIcon btnText={"Cancel"} hideIcon noBackground className="me-2" width="90px" onClick={handleClose} />
              <CustomBtnWithIcon
                btnText={"Confirm"}
                hideIcon
                className="me-2"
                // onClick={handleSubmit}
                disabled={!selectedCardPaymentId}
                onClick={() => setPaymentConfirmModal(true)}
              />
            </div>
          }
        />
      )}
    </>
  );
};

const Heading = ({ text = "", margin, fontSize = "0.75rem", color = "#3B3B3B" }) => {
  return <HeadingComponent text={text} fontSize={fontSize} color={color} fontWeight={700} margin={margin} />;
};

export default SubscriptionPlan;
