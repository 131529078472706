import React, { useState } from "react";
import { editTag } from "../../../util/DataService";
import { pushNotification } from "../../../util/Notification";
import { Divider, Form, Input, Checkbox, Popover, Button } from "antd";
import CustomBtnWithIcon from "../../buttonWithIcon";
import { CustomModal } from "../ModalStyles";
import styled from "styled-components";
import { SketchPicker } from "react-color";
const colors = ["#ff0000", "#00ff00", "#0000ff", "#ffff00", "#ff00ff"];

export default function EditTagModal({ currentRecord, isModalOpen, handleEditTagModal, updateTag, loadTagsList }) {
  const [form] = Form.useForm();
  const [color, setColor] = useState("#DADEDF");
  console.log("tag name", currentRecord.tag_name);
  form.setFieldsValue({
    tag_name: currentRecord && currentRecord.tag_name ? currentRecord.tag_name : "",
    abbreviation: currentRecord && currentRecord.abbreviation ? currentRecord.abbreviation : "",
    description: currentRecord && currentRecord.description ? currentRecord.description : "",
    color: currentRecord && currentRecord.color ? currentRecord.color : color,
  });

  const onFinish = async (formValues) => {
    // console.log("record in on finish", record);
    const updatedValues = {
      tag_name: formValues.name,
      abbreviation: formValues.abbreviation,
      description: formValues.description,
      color: color,
    };
    await updateTag(currentRecord.id, updatedValues);

    await loadTagsList();
    handleEditTagModal();
  };

  return (
    <CustomModal
      open={isModalOpen}
      title="Edit Tag"
      width="600px"
      height="444px"
      onCancel={() => {
        handleEditTagModal();
        form.resetFields();
      }}
      maskClosable={false}
      footer={null}
    >
      <Form name="createTag" onFinish={onFinish} form={form} layout="vertical">
        <FormItem
          name="tag_name"
          label="Tag Name"
          rules={[
            {
              required: true,
              message: "Tag name is required",
            },
          ]}
        >
          <Input placeholder="Enter name" />
        </FormItem>
        <FormItem
          name="abbreviation"
          label="Tag Abbreviation"
          rules={[
            {
              required: true,
              message: "Abbreviation is required",
            },
          ]}
        >
          <Input placeholder="Enter words" />
        </FormItem>
        <FormItem name="color" label="Choose color">
          <Popover trigger="click" content={<SketchPicker color={color} onChange={(color) => setColor(color.hex)} />}>
            <Button style={{ backgroundColor: color, border: "none" }} />
          </Popover>
        </FormItem>
        <FormItem
          name="description"
          label="Description"
          rules={[
            {
              required: true,
              message: "Description is required",
            },
          ]}
        >
          <Input.TextArea placeholder="Type description" />
        </FormItem>
        <Divider />
        <div className="d-flex justify-content-end">
          <CustomBtnWithIcon
            btnText={"Cancel"}
            margin="0px 5px"
            noBackground
            hideIcon={true}
            onClick={() => {
              handleEditTagModal();
              form.resetFields();
            }}
          />
          <CustomBtnWithIcon btnText={"Save Tag"} type="submit" hideIcon={true} onClick={onFinish()} />
        </div>
      </Form>
    </CustomModal>
  );
}

const FormItem = styled(Form.Item)`
  width: 100%;
`;
