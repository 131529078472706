import React from "react";
import "./FormikSelect.css";
import { FloatingLabel, Form } from "react-bootstrap";
import { useField } from "formik";
import styled from "styled-components";

const FormikSelect = ({ icon, label, className, placeHolder, options, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const error = meta.touched && meta.error ? true : false;

  const handleBlur = () => {
    helpers.setTouched(true);
  };

  function getStyles() {
    return {
      border: "1px solid red",
      padding: "5px",
    };
  }

  return (
    <div className={className}>
      <SelectTextLabel>{label}</SelectTextLabel>
      <FloatingLabel controlId={props.Id} className={props.className} name={props.name}>
        <>
          <Select {...field} placeholder={placeHolder} {...props} onBlur={handleBlur} style={{ ...(error ? getStyles() : "") }}>
            <option value="">{placeHolder}</option>
            {options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Select>
        </>
        {meta.touched && meta.error ? <div className="input-error-style">{meta.error}</div> : null}
      </FloatingLabel>
    </div>
  );
};

export default FormikSelect;

const SelectTextLabel = styled.span`
  font-size: 14px;
  color: #424242;
  font-weight: 400;
  color: rgb(0, 0, 0);
  width: 100%;
`;

const Select = styled.select`
  width: ${(props) => props?.width || "100%"};
  height: ${(props) => (props?.height ? props?.height : "40px")};
  border: ${(props) => props.border ?? "1px solid rgb(214, 217, 225)"};
  border-radius: 8px;
  margin: ${(props) => props?.margin ?? "0"};
  padding: 10px;
  box-sizing: border-box;
  outline: none;
  font-family: Helvetica Neue, Arial;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 57px;
  color: #000;

  ::placeholder {
    padding: 10px 0px; /* Adjust placeholder padding */
  }
`;
