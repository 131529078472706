import { Badge } from "antd";

export const navigationTabs = ({
  petsManagement = <>Content of Tab Pane 1</>,
  serviceRequest = <>Content of Tab Pane 2</>,
  keyManagement = <>Content of Tab Pane 3</>,

  invoicing = <>Content of Tab Pane 5</>,

  conversation = <>Content of Tab Pane 8</>,
  showConversationTab = true, // New parameter for the condition
  unreandCount,
}) => {
  const tabs = [
    {
      key: "1",
      label: "Pets",
      children: petsManagement,
    },
    {
      key: "2",
      label: "Service Requests",
      children: serviceRequest,
    },
    {
      key: "3",
      label: "Keys",
      children: keyManagement,
    },

    {
      key: "5",
      label: "Invoices",
      children: invoicing,
    },
  ];

  if (showConversationTab) {
    tabs.push({
      key: "6",
      label: unreandCount ? <Badge dot={true}>Conversation</Badge> : "Conversation",
      children: conversation,
    });
  }

  return tabs;
};
