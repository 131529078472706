import React, { useState } from 'react'
import HeadingComponent from '../../../../components/Heading'
import styled from "styled-components"
import { Col, Divider, Form, Input, Radio, Row, Switch } from 'antd';
import CustomBtnWithIcon from '../../../../components/buttonWithIcon';

export default function StaffApp() {

    const [form] = Form.useForm();
    
    const [selectedValue, setSelectedValue] = useState("");
    
    form.setFieldsValue({
        mark_notes_as_important: true,
       });
    
    
    const handleSwitchChange = (e) =>{
        setSelectedValue(e.target.value);
    };
    const handleSubmit = async (values) => {
        let data = {
          mark_notes_as_important: selectedValue,
        };
        // handlePayStructure(data);
      };

  return (
    <div className='mt-2' style={{ width: '100%' }}>
        <Form name="payStructureForm" onFinish={handleSubmit} form={form} layout="vertical">

        <div className="d-flex flex-row" style={{ marginBottom: '10%' }}>
            <div style={{ width: '20%' }}>

            <Heading text="Mark Notes as Important" fontSize="1rem" color="black"/>
            </div>
                <FormItem
                name="allow_notifications"
                >
                <div style={{ marginLeft: '20%' }}>
                <span className="d-flex">
                <Switch size="small" className="me-2" defaultValue={true} onChange={handleSwitchChange}/>
                <Heading text="Enabled"  />
                </span>
                <span className="mt-10">
                    <label>This will allow Notes to be displayed on staff service page rather than in notes tab</label>
                </span>

                  </div>
                </FormItem>
        </div>

    
      
      <Divider />
      <div className="d-flex justify-content-end">
      <CustomBtnWithIcon btnText="Save Changes" type="submit" hideIcon={true} />
    </div>
        </Form>

    </div>
  )
}


const Heading = ({ text = "", margin, fontSize = "0.75rem", color = "#3B3B3B" }) => {
    return <HeadingComponent text={text} fontSize={fontSize} color={color} fontWeight={700} margin={margin} />;
  };
  
const FormItem = styled(Form.Item)`
  width: 100%;
`;

const MainContainer = styled.div`
  display: flex;

  flex-direction: row;
  justify-content: space-between;
`;