import { connect } from "react-redux";
import { loadPetDetail, openDetailModal } from "../../redux/slices/petDetail";
import DetailPage from "../../components/DetailPage";
import { editPet, loadPetList } from "../../redux/slices/pets";

const mapStateToProps = (state) => {
  return {
    petList: state.pets.petList,
    isLoading: state.petDetail.isLoading,
    error: state.petDetail.error,
    petDetailResponse: state.petDetail.petDetailResponse,
    vaccinationResponse: state.petDetail.vaccinationResponse,
    isdetailModalOpen: state.petDetail.isdetailModalOpen,
    singlePetDetails: state.pets.singlePetDetails,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadPetDetail: (id) => {
    dispatch(loadPetDetail(id));
  },
  loadPetList: () => {
    dispatch(loadPetList());
  },
  openDetailModal: (isOpen) => {
    dispatch(openDetailModal(isOpen));
  },
  editPet: (values) => {
    dispatch(editPet(values));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DetailPage);
