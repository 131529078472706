import InternalChatApp from "../../pages/client/messages/Chat";

import { connect } from "react-redux";
import { selectCurrentChat } from "../../redux/slices/InternalChatAppSlice";
import { fetchAllStaffApi } from "../../redux/slices/StaffSlice";
import { FetchClientsApi } from "../../redux/slices/ClientSlice";
const mapStateToProps = (state) => {
  return {
    companyInternalChatState: state.CompanyInternalMsgs,
    authState: state.auth.loginResponse.LoggedInUserData,
    loggedInType: state.auth.loginResponse,

    allStaffs: state.staffManagement.staffAllDetails.staffList,
    clientManagementState: state.clientManangement.clientResponse,
  };
};

const mapDispatchToProps = (dispatch) => ({
  selectChat: (id) => {
    dispatch(selectCurrentChat(id));
  },
  fetchAllStaff: () => {
    dispatch(fetchAllStaffApi());
  },
  getManagementClients: (data) => {
    dispatch(FetchClientsApi());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(InternalChatApp);
