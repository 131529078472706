import UserManagement from '../../pages/company/user-management/UserManagement'
import { connect } from "react-redux";

import { fetchAllUsersSlice } from "../../redux/slices/userManagementSlice";

const mapStateToProps = (state) => {
  return {
    allUsers: state.userManagement.userManagementList,
    permissionState :state.permissionManagement.permissions
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchAllUser: (searchTerm='', selectedRole=null) => {
    dispatch(fetchAllUsersSlice(searchTerm, selectedRole));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(UserManagement);
