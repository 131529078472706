import styled from "styled-components";
import { Space, Tag } from "antd";
import { ReactComponent as EditIcon } from "../assets/rawSvgs/tableEditIcon.svg";
import { ReactComponent as DeleteIcon } from "../assets/rawSvgs/tableDeleteIcon.svg";
//{ handleDelete = "", handleEditRow = "" }
const CommonActionHeaders = ({ handleDelete = "", handleEditRow = "", props = {} }) => {
  return [
    {
      title: "",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button
            onClick={(event) => {
              props.openWorkQueueDetailModal && props.openWorkQueueDetailModal(false);
              event.stopPropagation();
              handleEditRow && handleEditRow(record);
            }}
          >
            <EditIcon />
          </Button>
          <Button
            onClick={(event) => {
              event.stopPropagation();
              handleDelete && handleDelete(record);
            }}
          >
            <DeleteIcon />
          </Button>
        </Space>
      ),
    },
  ];
};

export { CommonActionHeaders };

const Button = styled.button`
  border: none;
  background: none;
`;
