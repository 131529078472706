import React, { useState } from "react";
import { Input } from "antd";
import styled from "styled-components";
import { CustomModal } from "../ModalStyles";
import { ReactComponent as HiddenKeyIcon } from "../../../assets/rawSvgs/TablerIcons.svg";
import CustomButton from "../../Form/CustomButton";
import { main_api } from "../../../api/axios_helper";
import { companyAPIsEndPoints } from "../../../constants/ApiEndPoints";
import { pushNotification } from "../../../util/Notification";
import { Formik, Form } from "formik";
import HideIocn from "../../../assets/images/hideKey.png";

const ViewKeyModal = ({ openKeyModal, setOpenKeyModal, onFinish, heading, description, viewKeyId, loginState }) => {
  const [isLoading, setIsloading] = useState(false);
  const [code, setCode] = useState(null);
  const [password, setPassword] = useState("");

  const viewKey = (formValues) => {
    setIsloading(true);

    main_api
      .post(`${companyAPIsEndPoints.VIEW_KEY_CODE}`, formValues)
      .then(({ data }) => {
        setIsloading(false);
        pushNotification("Successfull.");
        setCode(data?.result?.code);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          pushNotification("Wrong password", "error");
        } else if (error.response.status === 400) {
          pushNotification("Wrong password", "error");
        } else {
          pushNotification(error.message);
        }
      });
  };
  const handleSubmit = (values) => {
    viewKey(values);
    setPassword("");
  };

  const handleClose = () => {
    setOpenKeyModal(false);
    setPassword("");
    setCode(null);
  };
  return (
    <CustomModal open={openKeyModal} title="" width="558px" onCancel={handleClose} footer={null} maskClosable={false}>
      <Formik
        initialValues={{
          key: viewKeyId,
          email: loginState?.LoggedInUserData?.email,
          password: "",
        }}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setValues }) => (
          <Form
            name="name"
            onFinish={onFinish}
            // form={form}
            layout="vertical"
          >
            <FormWraper>
              <div className="d-flex flex-column justify-content-center align-items-center">
                <KeyContainer>
                  <HiddenKeyIcon />
                </KeyContainer>
                <HeadingBox>{heading}</HeadingBox>
                <DescriptionBox>{description}</DescriptionBox>
              </div>
              <HiddenKeyContainer>
                {code ? (
                  <ShowKeyConatainer>
                    <Box>
                      <HiddenKeyIcon height="20px" width="20px" />
                      <span>{code}</span>
                    </Box>
                  </ShowKeyConatainer>
                ) : (
                  <img src={HideIocn} alt="" />
                )}
              </HiddenKeyContainer>
              <div className="d-flex align-items-center gap-2">
                <Input
                  placeholder="Enter password"
                  label="Key Name/Code"
                  size="large"
                  name="password"
                  type="password"
                  onChange={(e) => {
                    handleChange(e);
                    setPassword(e.target.value);
                  }}
                  value={password}
                  rules={[
                    {
                      min: 3,
                      max: 50,
                      message: "Please select a value",
                    },
                  ]}
                />
                <CustomButton text="Assign key" disabled={!password && "disabled"} type="submit" primary />
              </div>
            </FormWraper>
          </Form>
        )}
      </Formik>
    </CustomModal>
  );
};

const FormWraper = styled.div`
  // height: 356px;
  overflow: auto;

  ::-webkit-scrollbar {
    width: 0px;
  }
`;

const KeyContainer = styled.div`
  background-color: #e3eff5;
  height: 96px;
  width: 96px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0 20px 0;
`;
const HeadingBox = styled.p`
  color: #023047;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  text-align-center;
  margin-bottom:12px;
  `;
const DescriptionBox = styled.p`
  color: #454B54;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align-center;
`;
const HiddenKeyContainer = styled.div`
  dispaly: flex;
  justify-content: center;
  margin-bottom: 20px;
  text-align: center;
`;
const ShowKeyConatainer = styled.div`
  dispaly: flex;
  justify-content: center !important;
  gap: 15px;
  margin-bottom: 20px;
  text-align: center;
`;
const Box = styled.span`
  background: #f5f5f5;
  border-radius: 7px;
  padding: 9px 11px;
  dispaly: flex;
  max-width: fit-content;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  span {
    margin-left: 4px;
  }
`;

export default ViewKeyModal;
