import React, { useMemo } from 'react';
import { AntdesignTable } from '../../../../components/antDesignTable/AntdesignTable'
import TimeTrackingTable from './timeTrackingTable'
import HeadingComponent from '../../../../components/Heading';
import { staffAPIsEndPoints } from '../../../../constants/ApiEndPoints';
import { main_api } from '../../../../api/axios_helper';
import { pushNotification } from '../../../../util/Notification';

export default function TimeTracking({staffData, onDataUpdate}) {
   
    
  const data = useMemo(() => staffData.map((staff, index) => ({
    key: String(index + 1),
    id: staff.id,
    staff_name: staff.name,
    time_tracking: staff.time_tracking_enabled,
    mileage_tracking: staff.mileage_tracking_enabled,
  })), [staffData]);

  const handleUpdateData = async (id, updatedData) => {
    try {
      // Optimistically update the UI
      const updatedStaffData = staffData.map(staff => {
        if (staff.id === id) {
          return { ...staff, ...updatedData };
        }
        return staff;
      });
      onDataUpdate(updatedStaffData); // Update UI optimistically

      // Send the request to update data to the backend
      const response = await main_api.put(`${staffAPIsEndPoints.UPDATE_NEW_STAFF(id)}`, updatedData);
      if (response.data.isSuccess) {
        pushNotification(response.data.message);
      } else {
        pushNotification(response.data.message, "error");
        // Revert UI changes on failure
        onDataUpdate(staffData);
      }
    } catch (error) {
      console.error(error);
      // Revert UI changes on error
      onDataUpdate(staffData);
    }
  };
    

 
    return (
    <div className='mt-2' style={{ width: '100%',marginLeft: '10px', }}>
                <Heading text="Time and Mileage Tracking" fontSize="1rem" color="black"/>
            <span className="mt-10" style={{ marginTop: '20px', }}>
                <label>Time Tracking and Mileage Tracking is only available for companies using hourly payment method.  </label>
            </span>
        <TimeTrackingTable data={data} handleUpdateData={handleUpdateData}/>
    </div>
  )
}

const Heading = ({ text = "", margin, fontSize = "0.75rem", color = "#3B3B3B" }) => {
    return <HeadingComponent text={text} fontSize={fontSize} color={color} fontWeight={700} margin={margin} />;
  };
  