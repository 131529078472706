import { Modal } from "antd";
import React from "react";
import { ReactComponent as ResetModalLock } from "../../assets/rawSvgs/ResetModalLock.svg";

const ResetInfoModal = (props) => {
  return (
    <Modal open={props.isResetModalOpen} footer={null} className="text-center" centered onCancel={props.setIsResetModalOpen}>
      <ResetModalLock />
      <h3 style={{ color: "#023047" }}>
        Reset Admin password Link
        <br /> has been sent!
      </h3>
      <p>Your Password reset link has been sent to your email account.</p>
      <p>Click on the link to reset you password</p>
    </Modal>
  );
};

export default ResetInfoModal;
