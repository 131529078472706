import React, { useState } from "react";
import { Radio } from "antd";
import styled from "styled-components";
const RadioField = ({ label, labelClass, classes, options, onChange, value, name }) => {

  return (
    <>
      {label && <Label className={labelClass}>{label}</Label>}
      <Radio.Group onChange={onChange} value={value} className={classes} name={name}>
        {options.map((item) => {
          return <Radio value={item.value}>{item.label}</Radio>;
        })}
      </Radio.Group>
    </>
  );
};
export default RadioField;
const Label = styled.p`
  margin-bottom: 6px;
  color: #3b3b3b;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
`;
