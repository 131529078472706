import React, { useEffect } from "react";

import { FieldArray, Form, Formik } from "formik";
import { Col, Row, Checkbox } from "antd";
import * as Yup from "yup";

import HeadingComponent from "../../../../components/Heading";

import FormikSelectInput from "../../../../components/formikInputs/SelectInput";
import { DeleteIcon, PlusIcon } from "../../../../assets/rawSvgs/index";

import FormikTextArea from "../../../../components/formikInputs/textArea";

import { main_api } from "../../../../api/axios_helper";
import { companyAPIsEndPoints } from "../../../../constants/ApiEndPoints";
import { pushNotification } from "../../../../util/Notification";
import { Hours24Time } from "../../../../util/24HourtimeList";
import { calculateUpdatedTime } from "../SchedulerEvents";
import { convertTo12HourFormat } from "../../../../util/formatTime";
import { convertTo24HourFormat, generateAvailableTimeSlots } from "../pendingRequest/utilsFunctions";
import { elements } from "chart.js";

//
//
const AddServiceForm = ({
  setLoader,
  tempFormDetails,
  requiredDropdowns,
  formikRefSecondStep,
  fromTemplate,
  fromTemplateRef,
  addNewServiceInTemp,
  updateForm,
  handleCloseModal,
  updateDetails,
  clientPets,
  projectStatus,
}) => {
  const handleSubmitTempForm = async (values) => {
    try {
      setLoader(true);

      if (fromTemplate) {
        addNewServiceInTemp(values);
        return;
      }
      let servicesObj = values.services?.map(({ timeSlotList, timeBlockList, ...rest }) => {
        return rest;
      });
      const objSend = {
        ...tempFormDetails,
        services: servicesObj,
        company: requiredDropdowns?.loginState?.company_id,
      };
      // objSend.services.forEach((elements) => {
      //   delete elements.timeSlotList;
      //   delete elements.timeBlockList;
      // });

      if (updateForm) {
        const { services } = objSend;
        if (objSend.how_often === "Weekly") {
          services.forEach((element) => {
            element.weeks_of_month = ["1ST"];
          });
        }

        const { data } = await main_api.put(companyAPIsEndPoints.UPDATE_EXISTING_TEMPLATE(updateDetails.id), objSend);
        if (data.isSuccess) {
          requiredDropdowns?.fetchSchedulerTemplates();
          pushNotification("Template Updated successfully!.");
          handleCloseModal();
          setLoader(false);

          return;
        }
      }

      const { data } = await main_api.post(companyAPIsEndPoints.POST_NEW_TEMPLATES, objSend);

      if (data.isSuccess) {
        requiredDropdowns?.fetchSchedulerTemplates();
        pushNotification("Template saved successfully!.");
        handleCloseModal();
      }
      setLoader(false);
    } catch (error) {
      setLoader(false);

      if (error.response.data.message) {
        pushNotification("Something went wrong.", "error");
      }
    }
  };

  function replacePetsAndServiceWithIds(obj) {
    return obj?.map((obj) => {
      const newObj = { ...obj, timeBlockList: getTimeBlock(obj.service.id), timeSlotList: makeTimeSlots(obj.time_block) };

      if (newObj.staff) {
        newObj.staff = newObj.staff.id;
      }

      if (newObj.pets) {
        if (updateDetails.client.id !== tempFormDetails.client) {
          newObj.pets = [];
        } else {
          newObj.pets = newObj.pets.map((pet) => pet.id);
        }
      }

      if (newObj.service) {
        newObj.service = newObj.service.id;
      }

      return newObj;
    });
  }

  const howOften = tempFormDetails?.how_often;

  const getTimeBlock = (serviceId) => {
    const filterService =
      requiredDropdowns?.allServices?.responseData?.result?.filter((items) => {
        return items.id === serviceId;
      })[0] || [];
    const timeBlocks = filterService?.time_blocks || [];

    const filteredTimeBlocks =
      requiredDropdowns?.blockTimeDropDown?.filter((item) => {
        return timeBlocks.includes(item.value);
      }) || [];

    return filteredTimeBlocks;
  };

  const makeTimeSlots = (e) => {
    const filterTimeBlock =
      requiredDropdowns.timeBlockCompList?.filter((items) => {
        return items.id === e;
      })[0] || [];

    let startTime = filterTimeBlock?.start_time;
    let endTime = filterTimeBlock?.end_time;

    // TIME SLOT FROM TIME BLOCK
    let slots = generateAvailableTimeSlots(startTime || "", endTime || "");

    return slots;
  };

  return (
    <div>
      <Formik
        initialValues={{
          services: updateForm
            ? [...replacePetsAndServiceWithIds(updateDetails?.template_services)]
            : [
                {
                  days_of_week: [],
                  service: "",
                  pets: [],
                  start_time: "",
                  end_time: "",
                  weeks_of_month: ["1ST"],
                  staff: "",
                  note: "",
                  time_block: "",
                  timeBlockList: [],
                  timeSlotList: [],
                },
              ],
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmitTempForm}
        enableReinitialize
        innerRef={fromTemplate ? fromTemplateRef : formikRefSecondStep}
      >
        {({ values, setFieldValue, errors }) => (
          <Form>
            <div>
              <FieldArray name={`services`}>
                {({ push, remove }) => (
                  <>
                    {values.services?.map((items, index) => {
                      return (
                        <Row
                          justify={"space-between"}
                          className="my-3"
                          gutter={15}
                          key={index}
                          style={{ background: "#F9F9F9", padding: "15px" }}
                        >
                          <Col md={24} style={{ height: "70px" }}>
                            <HeadingComponent text="Select Days of the week" fontSize={"0.75rem"} />

                            <div className="d-flex  my-2">
                              <Checkbox.Group
                                options={daysArrayv22.map((items) => {
                                  return {
                                    label: items.label.slice(0, 3),
                                    value: items.value,
                                  };
                                })}
                                className="ms-2"
                                onChange={(e) => {
                                  setFieldValue(`services.${index}.days_of_week`, e);
                                }}
                                {...(updateForm
                                  ? {
                                      value: items.days_of_week,
                                    }
                                  : {
                                      value: items.days_of_week,
                                    })}
                                disabled={projectStatus}
                              />
                            </div>

                            {errors?.services?.[index]?.days_of_week && (
                              <div className="input-error-style">{errors?.services?.[index]?.days_of_week}</div>
                            )}
                          </Col>

                          {errors?.selectedDays && <div className="input-error-style">{errors?.selectedDays}</div>}

                          {howOften === "Monthly" && (
                            <Col md={24} style={{ height: "70px" }}>
                              <HeadingComponent text="Select Weeks of month" fontSize={"0.75rem"} />

                              <div className="d-flex  my-2">
                                <Checkbox.Group
                                  options={selectWeeksArr?.map((items) => {
                                    return {
                                      label: items.label.slice(0, 3),
                                      value: items.value,
                                    };
                                  })}
                                  className="ms-2"
                                  onChange={(e) => {
                                    setFieldValue(`services.${index}.weeks_of_month`, e);
                                  }}
                                  {...(updateForm
                                    ? {
                                        value: items.weeks_of_month,
                                      }
                                    : {
                                        value: items.weeks_of_month,
                                      })}
                                  disabled={projectStatus}
                                />
                              </div>

                              {errors?.services?.[index]?.weeks_of_month && (
                                <div className="input-error-style">{errors?.services?.[index]?.weeks_of_month}</div>
                              )}
                            </Col>
                          )}

                          {errors?.selectedDays && <div className="input-error-style">{errors?.selectedDays}</div>}
                          {/* TIME Service */}
                          <Col md={12} className=" ">
                            <FormikSelectInput
                              label="Select Service"
                              placeholder="Select Service"
                              options={requiredDropdowns?.serviceList || []}
                              // defaultValue
                              width="100%"
                              {...(updateForm
                                ? {
                                    value: requiredDropdowns?.serviceList?.filter((items) => {
                                      return items.value === values.services[index].service
                                        ? [{ label: items.label, value: items.value }]
                                        : "";
                                    }),
                                  }
                                : {})}
                              name={`services.${index}.service`}
                              onChange={(e) => {
                                setFieldValue(`services.${index}.service`, e);
                                setFieldValue(`services[${index}].timeBlockList`, []);
                                setFieldValue(`services[${index}].timeSlotList`, []);
                                setFieldValue(`services[${index}].end_time`, "");
                                setFieldValue(`services[${index}].time_block`, "");
                                setFieldValue(`services[${index}].start_time`, "");

                                //
                                const filterService =
                                  requiredDropdowns?.allServices?.responseData?.result?.filter((items) => {
                                    return items.id === e;
                                  })[0] || [];
                                const timeBlocks = filterService?.time_blocks || [];

                                const filteredTimeBlocks =
                                  requiredDropdowns?.blockTimeDropDown?.filter((item) => {
                                    return timeBlocks.includes(item.value);
                                  }) || [];

                                setFieldValue(`services[${index}].timeBlockList`, filteredTimeBlocks);

                                const initialTime = values?.services?.[index]?.start_time || "00:00";
                                // =======================================
                                const updatedTime = calculateUpdatedTime(initialTime, filterService?.default_duration);

                                setFieldValue(`services[${index}].end_time`, updatedTime);
                              }}
                              disabled={projectStatus}
                            />
                          </Col>

                          {/* TIME BLOCK */}
                          <Col md={12} className="">
                            <FormikSelectInput
                              label="Time Block"
                              placeholder="Select Time Block"
                              // value={values.services?.[index]?.time_block || "Select time block"}
                              {...(values.services?.[index]?.time_block
                                ? {
                                    value: values.services?.[index]?.time_block,
                                  }
                                : { defaultValue: requiredDropdowns?.time_block?.id || "Select time block" })}
                              name={`services.${index}.time_block`}
                              type="text"
                              className="w-100 mb-2"
                              autoComplete="new-password"
                              allowClear
                              // {...(updateForm
                              //         ? {
                              //             value: convertTo12HourFormat(values.services?.[index]?.time_block),
                              //           }
                              //         : { value: convertTo12HourFormat(values.services?.[index]?.time_block) })}
                              // options={requiredDropdowns?.blockTimeDropDown}
                              options={values.services?.[index]?.timeBlockList}
                              onChange={(e) => {
                                let timeSlots = makeTimeSlots(e);

                                setFieldValue(`services[${index}].time_block`, e);
                                setFieldValue(`services[${index}].timeSlotList`, timeSlots);
                              }}
                              width="100%"
                              noBr
                              disabled={projectStatus}
                              // disabled={!values.extra_pet_enabled}
                            />
                            {/* <span className="input-error-style">
                              {errors?.service_requests?.[0]?.services && errors?.service_requests[0]?.services?.[0]?.time_block}
                            </span> */}
                          </Col>
                          {/* TIME SLOTS */}
                          <Col md={12}>
                            <FormikSelectInput
                              label={"Select Time Slot"}
                              options={values.services?.[index]?.timeSlotList}
                              placeholder="Select Slot"
                              // defaultValue={"Select Slot"}
                              {...(updateForm
                                ? {
                                    value: convertTo12HourFormat(values.services?.[index]?.start_time),
                                  }
                                : { value: convertTo12HourFormat(values.services?.[index]?.start_time) })}
                              // {...(values.services?.[index]?.start_time
                              //   ? {
                              //       value: convertTo12HourFormat(values.services?.[index]?.start_time),
                              //     }
                              //   : { defaultValue: requiredDropdowns?.start_time?.id || "Select time block" })}
                              width="100%"
                              name={`services.${index}.start_time`}
                              onChange={(e, dateString) => {
                                let startTime = convertTo24HourFormat(e);
                                setFieldValue(`services[${index}].start_time`, "");

                                if (values?.services?.[index]?.service) {
                                  const filterService =
                                    requiredDropdowns?.allServices?.responseData?.result?.filter((items) => {
                                      return items.id === values?.services?.[index]?.service;
                                    })[0] || [];

                                  // =======================================
                                  const updatedTime = calculateUpdatedTime(startTime, filterService?.default_duration);

                                  setFieldValue(`services[${index}].end_time`, updatedTime);
                                }

                                setFieldValue(`services[${index}].start_time`, startTime);
                              }}
                              disabled={projectStatus}
                            />
                          </Col>

                          {/* TIME Pets */}
                          <Col md={12} className="">
                            <FormikSelectInput
                              label="Select Pets"
                              mode="multiple"
                              placeholder="Select Pets"
                              value={items.pets}
                              options={clientPets || []}
                              // defaultValue
                              width="100%"
                              {...(updateForm
                                ? {
                                    value: values.services[index]?.pets,
                                    // value: requiredDropdowns.petList.petListValueLabel?.filter((items) => {
                                    //   return items.value === values.services[index]?.pets ? items.label : "";
                                    // }),
                                  }
                                : {
                                    value: values.services[index]?.pets,
                                  })}
                              name={`services.${index}.pets`}
                              onChange={(e) => {
                                setFieldValue(`services.${index}.pets`, e);
                              }}
                              disabled={projectStatus}
                            />
                          </Col>

                          {/* TIME Staff */}

                          <Col md={12} className="">
                            <FormikSelectInput
                              label="Select Staff"
                              placeholder="Select Staff"
                              options={requiredDropdowns?.staff || []}
                              width="100%"
                              {...(updateForm
                                ? {
                                    value: requiredDropdowns?.staff?.filter((items) => {
                                      return items.value === values.services[index]?.staff ? items.label : "";
                                    }),
                                  }
                                : {
                                    value: requiredDropdowns?.staff?.filter((items) => {
                                      return items.value === values.services[index]?.staff ? items.label : "";
                                    }),
                                  })}
                              name={`services.${index}.staff`}
                              onChange={(e) => {
                                setFieldValue(`services.${index}.staff`, e);
                              }}
                              disabled={projectStatus}
                            />
                          </Col>

                          {/* TIME Description */}
                          <Col md={24} className="pb-3">
                            <FormikTextArea
                              label="Description"
                              placeHolder="Type Notes Here..."
                              name={`services.${index}.note`}
                              type="text"
                              className="w-100 mb-2"
                              autoComplete="new-password"
                              {...(updateForm
                                ? {
                                    value: values.services[index]?.note,
                                  }
                                : {
                                    value: values.services[index]?.note,
                                  })}
                              width="100%"
                              onChange={(e) => {
                                setFieldValue(`services.${index}.note`, e);
                              }}
                              disabled={projectStatus}
                            />
                          </Col>
                          <Col md={24} className="my-3 d-flex align-items-end ">
                            <button
                              type="button"
                              className="ms-1"
                              onClick={() =>
                                push({
                                  days_of_week: [],
                                  service: "",
                                  pets: [],
                                  start_time: "",
                                  end_time: "",
                                  weeks_of_month: ["1ST"],
                                  staff: "",
                                  note: "",
                                })
                              }
                              disabled={projectStatus}
                            >
                              <PlusIcon /> Add Another Service
                            </button>
                            {values.services?.length > 1 && (
                              <button type="button" onClick={() => remove(index)}>
                                <DeleteIcon height={"14px"} width="14px" className="mb-1" /> Remove
                              </button>
                            )}
                          </Col>
                        </Row>
                      );
                    })}
                  </>
                )}
              </FieldArray>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AddServiceForm;

const selectWeeksArr = [
  {
    value: "1ST",
    label: "1st",
  },
  {
    value: "2ND",
    label: "2nd",
  },
  {
    value: "3RD",
    label: "3rd",
  },
  {
    value: "4TH",
    label: "4th",
  },
  {
    value: "5TH",
    label: "5th",
  },
];

const validationSchema = Yup.object().shape({
  services: Yup.array().of(
    Yup.object().shape({
      days_of_week: Yup.array().min(1, "At least one day of the week must be selected"),
      service: Yup.string().required("Service is required"),
      // pets: Yup.array().min(1, "At least one pet must be selected"),
      start_time: Yup.string().nullable().required("Start time is required"),
      end_time: Yup.string().required("End time is required").nullable(),
      // .test("is-greater", "End time must be greater than start time", function (end_time) {
      //   const start_time = this.parent.start_time;
      // staff: Yup.string().required("Staff is required"),
      staff: Yup.string()
        .nullable()
        .required("Staff is required")
        .test("is-not-null", "Staff cannot be null", (value) => value !== null),
      time_block: Yup.string().required("Time block is required").typeError("Time block is required"),
    }),
  ),
});

const daysArrayv22 = [
  {
    value: "MON",
    label: "Monday",
  },
  {
    value: "TUE",
    label: "Tueday",
  },
  {
    value: "WED",
    label: "Wednesday",
  },
  {
    value: "THU",
    label: "Thursday",
  },
  {
    value: "FRI",
    label: "Friday",
  },
  {
    value: "SAT",
    label: "Saturday",
  },
  {
    value: "SUN",
    label: "Sunday",
  },
];
