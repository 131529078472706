import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { main_api } from "../../api/axios_helper";
import { companyAPIsEndPoints } from "../../constants/ApiEndPoints";
import axiosInstance from "../../util/AxiosConfig";
import { checkPermission } from "../../util/commonUtil";
// ----------------------------------------------------------------------

const initialState = {
  clientResponse: {
    isLoading: false,
    responseData: [],
    clientList: [],
  },
  clientPostResponse: {
    isLoading: false,
    responseData: "",
  },
  clientDeleteResponse: {
    isLoading: false,
    responseData: "",
  },
  clientEditResponse: {
    isLoading: false,
    responseData: "",
  },
  clientSingleDetails: {
    isLoading: false,
    responseData: "",
  },
};

const clientManagement = createSlice({
  name: "client management",
  initialState,
  reducers: {
    getAllManagementClientsSlice(state, action) {
      state.clientResponse.isLoading = action.payload.isLoading;
      if (action.payload?.responseData) {
        const clientList = action.payload?.responseData?.result.map((items) => {
          return {
            value: items.id,
            label: items.name,
          };
        });
        state.clientResponse.clientList = clientList;
        state.clientResponse.responseData = action.payload?.responseData;
      }
    },
    postClientsSlice(state, action) {
      state.clientPostResponse.isLoading = action.payload.isLoading;
      if (action.payload?.responseData) {
        state.clientPostResponse.responseData = action.payload?.responseData;
      }
    },
    deleteClientSlice(state, action) {
      state.clientDeleteResponse.isLoading = action.payload.isLoading;
      if (action.payload?.responseData) {
        state.clientDeleteResponse.responseData = action.payload?.responseData;
      }
    },
    editClientSlice(state, action) {
      state.clientEditResponse.isLoading = action.payload.isLoading;
      if (action.payload?.responseData) {
        state.clientEditResponse.responseData = action.payload?.responseData;
      }
    },
    fetchSingleClientSlice(state, action) {
      state.clientSingleDetails.isLoading = action.payload.isLoading;
      if (action.payload?.responseData) {
        state.clientSingleDetails.responseData = action.payload?.responseData;
      }
    },
    resetResponseState(state, action) {
      state.clientPostResponse.responseData = "";
      state.clientDeleteResponse.responseData = "";
      state.clientEditResponse.responseData = "";
    },
  },
});

export const { loginUserSlice, resetResponseState } = clientManagement.actions;
// Reducer
export default clientManagement.reducer;

export const FetchClientsApi = (formValues) => {
  return async (dispatch, getState) => {
    try {
      const companyId = getState().auth?.loginResponse?.selectCompany?.[0]?.company_id;
      dispatch(clientManagement.actions.getAllManagementClientsSlice({ isLoading: true }));
      const { data } = await main_api.get(companyAPIsEndPoints.FETCH_MANAGEMENT_CLIENTS(companyId));
      dispatch(clientManagement.actions.getAllManagementClientsSlice({ isLoading: false, responseData: data }));
    } catch (error) {
      dispatch(clientManagement.actions.getAllManagementClientsSlice({ isLoading: false, responseData: [] }));
    }
  };
};

export const PostClientApi = (formValues) => {
  return async (dispatch, getState) => {
    try {
      dispatch(clientManagement.actions.postClientsSlice({ isLoading: true }));
      const { data } = await main_api.post(companyAPIsEndPoints.POST_MANAGEMENT_CLIENTS, formValues);

      dispatch(clientManagement.actions.postClientsSlice({ isLoading: false, responseData: data }));
    } catch (error) {
      dispatch(clientManagement.actions.postClientsSlice({ isLoading: false, responseData: error }));
    }
  };
};

export const fetchSingleClientsApi = (id) => {
  return async (dispatch, getState) => {
    try {
      const companyId = getState().auth?.loginResponse?.selectCompany?.[0]?.company_id;
      dispatch(clientManagement.actions.fetchSingleClientSlice({ isLoading: true }));
      const { data } = await main_api.get(companyAPIsEndPoints.FETCH_SINGLE_MANAGEMENT_CLIENTS(id, companyId));

      dispatch(clientManagement.actions.fetchSingleClientSlice({ isLoading: false, responseData: data }));
    } catch (error) {
      dispatch(clientManagement.actions.fetchSingleClientSlice({ isLoading: false, responseData: error }));
    }
  };
};

export const fetchLoggedInClientApi = () => {
  return async (dispatch, getState) => {
    const client_id = getState().auth?.loginResponse?.selectCompany?.find((item) => item?.client_id != null)?.client_id;
    const companyId = getState().auth?.loginResponse?.selectCompany?.[0]?.company_id;

    try {
      dispatch(clientManagement.actions.fetchSingleClientSlice({ isLoading: true }));
      const { data } = await axiosInstance.get(companyAPIsEndPoints.FETCH_SINGLE_MANAGEMENT_CLIENTS(client_id, companyId));

      dispatch(clientManagement.actions.fetchSingleClientSlice({ isLoading: false, responseData: data }));
    } catch (error) {
      dispatch(clientManagement.actions.fetchSingleClientSlice({ isLoading: false, responseData: error }));
    }
  };
};

export const DeleteSingleClientsApi = (id) => {
  return async (dispatch, getState) => {
    try {
      dispatch(clientManagement.actions.deleteClientSlice({ isLoading: true }));
      const { data } = await main_api.delete(companyAPIsEndPoints.DELETE_SINGLE_MANAGEMENT_CLIENTS(id));

      dispatch(clientManagement.actions.deleteClientSlice({ isLoading: false, responseData: data }));
    } catch (error) {
      dispatch(clientManagement.actions.deleteClientSlice({ isLoading: false, responseData: error }));
    }
  };
};

export const updateSingleClientsApi = ({ id, values }) => {
  return async (dispatch, getState) => {
    try {
      dispatch(clientManagement.actions.editClientSlice({ isLoading: true }));
      const { data } = await main_api.put(companyAPIsEndPoints.EDIT_SINGLE_MANAGEMENT_CLIENTS(id), values);

      dispatch(clientManagement.actions.editClientSlice({ isLoading: false, responseData: data }));
    } catch (error) {
      dispatch(clientManagement.actions.editClientSlice({ isLoading: false, responseData: error }));
    }
  };
};

export const fetchSingleClientApi = (id) => {
  return async (dispatch, getState) => {
    try {
      const companyId = getState().auth?.loginResponse?.selectCompany?.[0]?.company_id;

      dispatch(clientManagement.actions.fetchSingleClientSlice({ isLoading: true }));
      const { data } = await main_api.get(companyAPIsEndPoints.FETCH_SINGLE_MANAGEMENT_CLIENTS(id, companyId));

      dispatch(clientManagement.actions.fetchSingleClientSlice({ isLoading: false, responseData: data }));
    } catch (error) {
      dispatch(clientManagement.actions.fetchSingleClientSlice({ isLoading: false, responseData: error }));
    }
  };
};
