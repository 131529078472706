// import styled from "styled-components";

// const ClientContainer = styled.h2`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   flex-direction: column;
//   height: 400px;
//   background:#FFF6E8;
//   border-radius:16px;
// `;

// const NavigationHeading = styled.h2`
//   display: flex;
//   margin: 25px 0px 10px 0px;
//   font-size: 20px;
//   font-weight: 700;
// `;

// const NoLCientsHeading = styled.h2`
//   color: #212121;
//   font-size: 16px;
//   margin-top: 15px;
// `;
// const TableHeader = styled.div`
//   display: flex;
//   align-items: baseline;
//   justify-content: space-between;
// `;

// export { ClientContainer, NavigationHeading, NoLCientsHeading, TableHeader };






// Updated clientStyles.js
import styled from "styled-components";

const ClientContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: ${(props) => props.height || "400px"};
  background: #FFF6E8;
  border-radius: 16px;
`;

const NavigationHeading = styled.h2`
  display: flex;
  margin: 25px 0px 10px 0px;
  font-size: 20px;
  font-weight: 700;
`;

const NoLCientsHeading = styled.h2`
  color: #212121;
  font-size: 16px;
  margin-top: 15px;
`;

const TableHeader = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`;

export { ClientContainer, NavigationHeading, NoLCientsHeading, TableHeader };
