// import React from 'react';
// import styled from 'styled-components';
// import { DownloadOutlined, LeftOutlined } from '@ant-design/icons';
// import HeadingComponent from '../Heading';
// import CustomBtnWithIcon from '../buttonWithIcon';


// const PDFViewer = ({ record,goBack, handleDownload, handleSignature }) => {
  

//     return (
        
//            <>
//             <div className="d-flex" style={{justifyContent:"space-between", marginBottom:"14px"}}>
//                <div className="d-flex" style={{justifyContent:"center"}}>
//                <div style={{marginRight:"8px"}}>

//                  <LeftOutlined  onClick={() => {
//                         goBack(false);
//                     }}/>
//                </div>
               
//                 <Heading text={record.title} fontSize='1.45rem'/>
//                </div>
//                 <ButtonsContainer className="d-flex justify-content-end">
            
                    
//                 {/* <Button
//                     onClick={(record) => {
//                         handleDownload(record);
//                     }}
//                 >
//                      <DownloadOutlined/> Download
//                 </Button> */}
                
            
//                     <CustomBtnWithIcon
//                         btnText={"Sign Document"}
//                         hideIcon={true}
//                         onClick={(record) => {
//                         handleSignature(record);
//                         }}
//                     />
//                 </ButtonsContainer>
//             </div>
//             <iframe
//               src={record.fileUrl}
//               width="95vw"
//               height="auto"
//               style={{
//                 width: 'calc(100vw - 18px)',
//                 height: 'calc((100vw - 18px) * 1.414)',
//                 maxWidth: '206mm',
//                 maxHeight: '281mm',
//                 border: 'none',
//                 position: "absolute",
//                 bottom: "-316px",
//                 left: "30%",
//               }}>
//             </iframe>
//           </> 
         

        
//     );
// };

// const Heading = ({ text = "", margin, fontSize = "0.75rem", color = "#3B3B3B" }) => {
//     return <HeadingComponent text={text} fontSize={fontSize} color={color} fontWeight={700} margin={margin} />;
//   };

// const Button = styled.button`
//   border: none;
//   background: rgb(224 224 224 / 12%);
//   margin-right: 8px;
//   color: "#000000";
//   height: 40px;
//   display: flex;
//   align-items: center;
//   border: 1px solid #e0e0e0;
//   justify-content: space-evenly;
//   gap: 5px;
//   font-size: 14px;
//   font-weight: 500;
//   padding: "11px 16px 11px 16px";
//   border-radius: 8px;
//   opacity: 1;
//   & > span {
//     margin-right: 5px;
//   }
// `;  
// const ButtonsContainer = styled.div`
//   position: relative;
//   bottom: 17px;
//   right: 0;
//   width: 60%;
//   background-color: white;
//   padding: 10px 0px;
// `;

// export default PDFViewer;


//-------------------->

import React from 'react';
import styled from 'styled-components';
import { DownloadOutlined, LeftOutlined } from '@ant-design/icons';
import HeadingComponent from '../Heading';
import CustomBtnWithIcon from '../buttonWithIcon';
import { CustomModal } from '../Modals/ModalStyles';

const PDFViewer = ({ record, isModalOpen, setModalOpen, handleDownload, handleSignature }) => {

  const url = "https://buds-llc-furgis-cor-34563.s3.amazonaws.com/client_documents/unsigned/2024/05/20/69081c7a-399e-4e1f-9f3a-62f9fa267d5d/TestPDFfile.pdf";

  // Extract filename from the URL
  const filename = url.substring(url.lastIndexOf("/") + 1);

  

  return (
    <CustomModal
      open={isModalOpen}
      title=""
      width="80vw"
      height="auto"
      onCancel={() => { setModalOpen(false); }}
      footer={null}
    >
      <div className="d-flex" style={{ justifyContent: "space-between", marginBottom: "14px" }}>
        <div className="d-flex" style={{ justifyContent: "center" }}>
          <Heading text={record.title} fontSize='1.45rem' />
        </div>
        <ButtonsContainer className="d-flex justify-content-end">
          {/* Add buttons here if needed */}
        </ButtonsContainer>
      </div>
      {record.unsigned_url ? (
        <iframe
          src={record.unsigned_url}
          style={{
            width: '100%',
            height: '80vh',
            border: 'none'
          }}
        ></iframe>
        // <object class="pdf" 
        //   data={record.unsigned_url}
        //   width="800"
        //   height="500">
        // </object>
      ) : (
        
        <div>Loading...</div>  
      )}
    </CustomModal>
  );
};

const Heading = ({ text = "", margin, fontSize = "0.75rem", color = "#3B3B3B" }) => {
  return <HeadingComponent text={text} fontSize={fontSize} color={color} fontWeight={700} margin={margin} />;
};

const Button = styled.button`
  border: none;
  background: rgb(224 224 224 / 12%);
  margin-right: 8px;
  color: "#000000";
  height: 40px;
  display: flex;
  align-items: center;
  border: 1px solid #e0e0e0;
  justify-content: space-evenly;
  gap: 5px;
  font-size: 14px;
  font-weight: 500;
  padding: "11px 16px 11px 16px";
  border-radius: 8px;
  opacity: 1;
  & > span {
    margin-right: 5px;
  }
`;

const ButtonsContainer = styled.div`
  position: relative;
  bottom: 17px;
  right: 0;
  width: 60%;
  background-color: white;
  padding: 10px 0px;
`;

export default PDFViewer;

