import { useState } from "react";
import Signin from "./Signin";
import ForgetPassword from "./ForgetPassword";
import { useSpring } from "react-spring";

const AnimateAuthAdmin = (props) => {
  const [showLogin, setShowLogin] = useState(true);
  const [isAnimating, setIsAnimating] = useState(false);

  const fade = useSpring({
    opacity: showLogin ? 0 : 1,
    onRest: () => setIsAnimating(false),
  });

  const height = useSpring({
    height: showLogin ? 667 : 404,
    onRest: () => setIsAnimating(false),
  });

  return (
    <div>
      {showLogin ? (
        <Signin
          onForgotPasswordClick={() => setShowLogin(false)}
          setIsAnimating={setIsAnimating}
          loginUser={props.loginUser}
          loginUserSlice={props.loginResponse}
          error={props.error}
          isLoading={props.isLoading}
        />
      ) : (
        <ForgetPassword
          onBackToLoginClick={() => setShowLogin(true)}
          height={height}
          fade={fade}
          setIsAnimating={setIsAnimating}
        />
      )}
    </div>
  );
};

export default AnimateAuthAdmin;
