import { setUnreadCounts } from "../../../redux/slices/GeneralAppSlice/AppSlice";

// separate channel category
export const makeChannelsList = (list) => {
  const channels = Object.entries(list).map(([id, rest]) => ({
    id,
    ...rest,
  }));
  const DATA = [
    {
      title: "Channels",
      data: channels
        .filter((item) => {
          return item.id.includes("group") || item.custom?.type == 1;
        })
        .map((obj) => ({ ...obj })),
    },
    {
      title: "Direct Chats",
      data: channels
        .filter((item) => {
          return item.id.includes("direct") || item.custom?.type == 0;
        })
        .map((obj) => ({ ...obj })),
    },
  ];
  return DATA;
};

export const sendMessage = (pubnub, channelId, message) => {
  return new Promise((resolve, reject) => {
    pubnub.publish({ channel: channelId, message: message }, (status, response) => {
      if (status.error) {
        // If there's an error during publishing, reject the promise with the error
        reject(status.error);
      } else {
        // If publishing is successful, resolve the promise with the status and response
        resolve({ status, response });
      }
    });
  });
};

export const emojiDescriptions = {
  "😀": "Happy",
  "❤️": "Heart",
  "🥳": "Celebratory",
  "🤔": "Thinking",
  "😍": "Heart eyes",
  "🥵": "Hot",
  "👍": "Like",
};

// PUB NUB FUNCTIOSSS

export const deleteUser = async (pubnub, userId) => {
  try {
    await pubnub.objects.removeUUIDMetadata({
      uuid: userId,
    });
    console.log(`User ${userId} deleted successfully`);
  } catch (error) {
    console.error("Error deleting user:", error);
  }
};

export const deleteAllChannels = async (pubnub, channelName) => {
  try {
    const res = await pubnub.objects.removeChannelMetadata({
      channel: [channelName],
    });
    console.log("DELET ERES", res);
  } catch (error) {
    console.error("Error removing channels:", error);
  }
};

export const deleteAllMessagesInChannel = (pubnub, channel) => {
  pubnub.deleteMessages({ channel: channel }, (status) => {
    if (status.error) {
      console.error("Error deleting messages:", status);
    } else {
      console.log("All messages deleted successfully in channel:", channel);
    }
  });
};

export const sendChannelModifySignal = (pubnub, channel) => {
  try {
    pubnub.signal({
      channel: channel,
      message: {
        eventType: "channel_create", // Custom event type
      },
      callback: function (signalStatus) {
        console.error("Error sending signal:", signalStatus);
        if (signalStatus.error) {
        } else {
          console.log("Signal sent successfully:", signalStatus);
        }
      },
    });
  } catch (error) {
    console.error("CATCHHl:", error);
  }
};

export const sendFileSignal = (pubnub, channel) => {
  try {
    pubnub.signal({
      channel: channel,
      message: {
        eventType: "fileSent", // Custom event type
      },
      callback: function (signalStatus) {
        console.error("Error sending signal:", signalStatus);
        if (signalStatus.error) {
        } else {
          console.log("Signal sent successfully:", signalStatus);
        }
      },
    });
  } catch (error) {
    console.error("CATCHHl:", error);
  }
};

export const msgSendRecieveSignal = (pubnub, channel) => {
  try {
    pubnub.signal({
      channel: "notification_signal",
      message: {
        eventType: "msg_signal", // Custom event type
      },
      callback: function (signalStatus) {
        console.error("Error sending signal:", signalStatus);
        if (signalStatus.error) {
        } else {
          console.log("Signal sent successfully:", signalStatus);
        }
      },
    });
  } catch (error) {
    console.error("CATCHHl:", error);
  }
};

export const checkUnreadCountSendSignal = (pubnub, channel, currentChannel) => {
  let splitChan = "";
  if (currentChannel.startsWith("internal.")) {
    splitChan = currentChannel.split("-")[0];
  } else {
    splitChan = currentChannel;
  }
  try {
    pubnub.signal({
      channel: channel,
      message: {
        channelSendTo: splitChan,
      },
      callback: function (signalStatus) {
        console.error("Error sending signal:", signalStatus);
        if (signalStatus.error) {
        } else {
          console.log("Signal sent successfully:", signalStatus);
        }
      },
    });
  } catch (error) {
    console.error("CATCHHl:", error);
  }
};

//

export const fetchChannelsToCheckUnreadCountv2 = async (uuid, pubnub, onlyCompId, dispatch) => {
  try {
    const response = await pubnub.objects.getAllChannelMetadata({ include: { customFields: true } });
    let getChannelList = response?.data?.filter((items) => {
      return items?.custom?.company_id === onlyCompId;
    });

    const unreadCounts = extractUnreadCountsForComponents(getChannelList, uuid);

    dispatch(setUnreadCounts(unreadCounts));
  } catch (error) {
    console.error("Error fetching channels:", error);
  }
};
const extractUnreadCountsForComponents = (channels, uuid) => {
  const unreadCounts = [];

  channels.forEach((channel) => {
    try {
      const { unreadCountData } = channel?.custom;
      const parsedData = JSON.parse(unreadCountData) || {};

      if (!parsedData) {
        return; // Skip if unreadCountData is not valid JSON
      }

      // Check for 1-on-1 chat format
      if (parsedData.reciever) {
        const { recieverId, unreadCount } = parsedData.reciever;
        if (recieverId === uuid && unreadCount > 0) {
          unreadCounts.push({
            channelId: channel.id,
            unreadCount,
            type: "1-on-1",
          });
        }
      }

      // Check for group chat format
      if (parsedData.recievers) {
        const recievers = parsedData.recievers;
        for (const recieverId in recievers) {
          const { unreadCount } = recievers[recieverId];
          if (recieverId === uuid && unreadCount > 1) {
            // Check if unreadCount is greater than 1
            unreadCounts.push({
              channelId: channel.id,
              unreadCount,
              type: "group",
            });
          }
        }
      }
    } catch (error) {
      // console.error(`Error parsing unreadCountData for channel ${error}:`);
    }
  });
  return unreadCounts;
};
