import React, { useEffect, useState, useCallback } from "react";
import styled from "styled-components";
import { main_api } from "../../../../api/axios_helper";
import EditIcon from "../../../../assets/icons/EditIcon";
import CustomInput from "../../../../components/CustomInput";
import Table from "../../../../components/Table";
import SidebarNav from "../../../../components/SidebarNav";
import { SideNavItem } from "../../messages/Chat.styled";
import NavItem from "../../../../components/NavItem";
import PayStructure from "./payStructure";
import StaffApp from "./staffApp";
import StaffTags from "./staffTags";
import SideBar from "../../../../components/settingsSideBar/sideBar";
import TimeTracking from "./timeTracking";
import { staffAPIsEndPoints } from "../../../../constants/ApiEndPoints";
import { useSelector } from "react-redux";
import { Col, Row } from "antd";

export default function Staff() {
  const [isLoading, setIsloading] = useState(false);
  const [activeButton, setActiveButton] = useState("all");
  const [payStructureComponent, setPayStructureComponent] = useState(true);
  const [timeTracking, setTimeTracking] = useState(false);
  const [staffTags, setStaffTags] = useState(false);
  const [staffTagsList, setStaffTagsList] = useState([]);
  const [staffApp, setStaffApp] = useState(false);
  const [staffData, setStaffData] = useState([]);
  const { auth } = useSelector((state) => state);

  const GetProfile = useCallback(() => {
    setIsloading(true);

    let company_id = auth.loginResponse?.selectCompany?.[0]?.company_id || null;

    main_api
      .get(staffAPIsEndPoints.LIST_STAFF(`usercompanyrole__company=${company_id}`))
      .then(({ data }) => {
        setStaffData(data);
        setIsloading(false);
      })
      .catch((error) => {
        console.error(error?.response?.data);
        setIsloading(false);
      });
  }, []);

  const loadTagsList = () => {
    main_api
      .get("/api/v1/staff_tags/")
      .then(({ data }) => {
        setStaffTagsList(data.result);
        setIsloading(false);
      })
      .catch((error) => {
        console.error(error?.response?.data);
        setIsloading(false);
      });
  };

  useEffect(() => {
    GetProfile();
  }, [GetProfile]);

  useEffect(() => {
    loadTagsList();
  }, []);

  const handleDataUpdate = useCallback((updatedData) => {
    setStaffData(updatedData);
  }, []);

  const menuItemsForStaff = [
    { key: "1", label: "Pay Structure" },
    { key: "2", label: "Time Tracking" },
    // { key: '3', label: 'Staff Tags' },
    // { key: '4', label: 'Staff App' },
  ];

  const handleStaffMenuSelect = useCallback((item) => {
    switch (item.key) {
      case "1":
        setPayStructureComponent(true);
        setTimeTracking(false);
        // setStaffTags(false);
        // setStaffApp(false);
        break;
      case "2":
        setPayStructureComponent(false);
        setTimeTracking(true);
        // setStaffTags(false);
        // setStaffApp(false);
        break;
      // case "3":
      //   setPayStructureComponent(false);
      //   setTimeTracking(false);
      //   setStaffTags(true);
      //   setStaffApp(false);
      //   break;
      // case "4":
      //   setPayStructureComponent(false);
      //   setTimeTracking(false);
      //   setStaffTags(false);
      //   setStaffApp(true);
      //   break;
      default:
        break;
    }
  }, []);

  return (
    <Row className="row row-grid">
      <Col sm={6} md={6} lg={4} xl={3} xxl={3}>
        <SideBar menuItems={menuItemsForStaff} handleMenuSelect={handleStaffMenuSelect} />
        {/* <SideBar setPayStructureComponent={setPayStructureComponent} setTimeTracking={setTimeTracking} setStaffTags={setStaffTags} setStaffApp={setStaffApp}/> */}
      </Col>

      <Col sm={8} md={14} lg={4} xl={19} xxl={20}>
        {payStructureComponent && <PayStructure staffData={staffData} />}
        {timeTracking && <TimeTracking staffData={staffData} onDataUpdate={handleDataUpdate} />}
      </Col>
    </Row>
  );
}
