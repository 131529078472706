import React, { useEffect, useState } from "react";
import { Row, Col, DatePicker, Select, Collapse } from "antd";
import dayjs from "dayjs";
import moment from "moment";
import styled from "styled-components";
import { Formik, Form } from "formik";
import { useSelector } from "react-redux";
import * as Yup from "yup";

import { ReactComponent as FirstStep } from "../../../../../assets/rawSvgs/Frame1000005140(2).svg";
import { ReactComponent as FirstStepGreenChecked } from "../../../../../assets/rawSvgs/frame1000005142.svg";
import { ReactComponent as GreaterThanSign } from "../../../../../assets/rawSvgs/greaterThanIcon.svg";
import { ReactComponent as SecondStepChecked } from "../../../../../assets/rawSvgs/Frame1000005140.svg";
import { ReactComponent as SecondStepTransparent } from "../../../../../assets/rawSvgs/Frame000005140Transparnet.svg";
import { ReactComponent as DropDownIcon } from "../../../../../assets/rawSvgs/selectDropDownIcon.svg";

import HeadingComponent from "../../../../../components/Heading";
import CustomBtnWithIcon from "../../../../../components/buttonWithIcon";
import AntDesignModal from "../../../../../components/Modals/AntDesignModal/AntDesignModal";
import FormikTextArea from "../../../../../components/formikInputs/textArea";

import FormikInputFloating from "../../../../../components/formikInputs/TextInput";
import FormikDateMonthInput from "../../../../../components/formikInputs/DateMonth";
import FormikCheckboxInput, { InputTextLabel } from "../../../../../components/formikInputs/Checkbox";
import FormikSelectInput from "../../../../../components/formikInputs/SelectInput";
import { Hours24Time } from "../../../../../util/24HourtimeList";
import { pushNotification } from "../../../../../util/Notification";
import { main_api } from "../../../../../api/axios_helper";
import { companyAPIsEndPoints, staffAPIsEndPoints } from "../../../../../constants/ApiEndPoints";
import { allServices, timeInHours, typeOfTimeShift } from "../../../../../util/addTimeShift";

const AddTimeTrackingForm = ({
  updateValues,
  addNewEventModal,
  staffId,
  filterUpdateService,
  updateForm,
  requiredDropdowns,
  closeAll,
}) => {
  const [addStaffSetps, setAddStaffSetps] = useState(1);
  const [serivicesDropdown, setSerivicesDropdown] = useState(false);
  const [tempFormDetails, setTempFormDetails] = useState({});
  const [submitFormLoader, setSubmitFormLoader] = useState(false);
  const state = useSelector((state) => state.auth.loginResponse);
  const clientStatePets = useSelector((state) => state?.clientManangement?.clientResponse.responseData.result);
  let company_id = state?.selectCompany?.[0]?.company_id || null;
  const formikRef = React.useRef();
  const buttonRef = React.useRef();
  const formikRefSecondStep = React.useRef();
  
  const handleSubmit = async (values) => {
    const formData = {staff:staffId,...values}
    if (formData) {
      const response = await main_api.post(staffAPIsEndPoints.CREATE_TIME_TRACKING, formData);
      if (response.status === 200) {
        pushNotification("Time Successfully Added!", "success");
      }
    }
  };

  // const validationSchema = Yup.object().shape({
  //   clock_in_date: Yup.string().required("Clock in date is required"),
  //   clock_in_time: Yup.string().required("Clock in time is required"),
    
  //   clock_out_date: Yup.string().required("Clock out date is required"),
  //   clock_out_time: Yup.string().required("Clock out time is required"),

  //   in_progress: Yup.bool().required("In progress is required"),
  //   service_request: Yup.number().nullable().optional(),
  //   approved: Yup.bool().required("Approved is required"),
  //   // is_manual: Yup.string().required("Is manual is required"),
  //   note: Yup.string().required("Note is required"),
  // });

  const validationSchema = Yup.object().shape({
    clock_in_date: Yup.string().required("Clock in date is required"),
    clock_in_time: Yup.string().required("Clock in time is required"),
    
    clock_out_date: Yup.string().when('in_progress', {
      is: false,
      then: Yup.string().required("Clock out date is required"),
    }),
    clock_out_time: Yup.string().when('in_progress', {
      is: false,
      then: Yup.string().required("Clock out time is required"),
    }),
  
    in_progress: Yup.bool().required("In progress is required"),
    service_request: Yup.number().nullable().optional(),
    approved: Yup.bool().required("Approved is required"),
    // is_manual: Yup.string().required("Is manual is required"),
    note: Yup.string().required("Note is required"),
  });
  
  return (
    <>
      <AntDesignModal
        width={700}
        title={updateValues?.title ? "Update a Scheduled Trip" : "Add Time Shift"}
        style={{ top: 20 }}
        openModal={addNewEventModal}
        closeModal={closeAll}
        component={
          <>
            <div
              style={{
                // height: "75vh",
                overflowY: "auto",
                overflowX: "hidden",
                borderBottom: "0.5px solid rgb(144 144 144 / 43%)",
                padding: " 10px 0px",
              }}
            >
              {/* FORM ======= */}

              <Formik
                initialValues={{
                  clock_out_date: "1111-11-11",
                  clock_out_time: "00:00:00",
                  clock_in_date: "",
                  clock_in_time: "",
                  in_progress: true,
                  service_request: 0,
                  approved: false,
                  is_manual: true,
                  note: "",
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                enableReinitialize
                innerRef={formikRef}
              >
                {({ values, setFieldValue, errors }) => (
                  <Form >
                    <div style={{ display: addStaffSetps === 1 ? "block" : "none" }}>
                      <Row justify={"space-between"} gutter={15}>
                        {/* ====================================== */}

                        <Col md={12} className="">
                          <FormikDateMonthInput
                            format={"YYYY-MM-DD"}
                            // defaultValue={dayjs(dayjs(), "YYYY-MM-DD")}
                            className="w-100"
                            style={{ height: "40px" }}
                            allowClear={false}
                            name={"clock_in_date"}
                            label="Clock In Date"
                            onChange={(e, text) => {
                              setFieldValue("clock_in_date", text);
                            }}
                          />
                        </Col>
                        <Col md={12} className="">
                          <FormikSelectInput
                            label="Clock In Time"
                            placeholder="Select Client"
                            name="clock_in_time"
                            type="text"
                            className="w-100 mb-2"
                            autoComplete="new-password"
                            defaultValue={"Select Out Time"}
                            options={timeInHours}
                            onChange={(e) => {
                              setFieldValue("clock_in_time", e);
                            }}
                            width="100%"
                            noBr
                            // disabled={!values.extra_pet_enabled}
                          />
                        </Col>

                        {/* ====================================== */}

                       {!values.in_progress && 
                          <>
                          <Col md={12} className="my-2">
                            <FormikDateMonthInput
                              format={"YYYY-MM-DD"}
                              // defaultValue={dayjs(dayjs(), "YYYY-MM-DD")}
                              className="w-100"
                              style={{ height: "40px" }}
                              allowClear={false}
                              name={"clock_out_date"}
                              label="Clock Out Date"
                              onChange={(e, text) => {
                                setFieldValue("clock_out_date", text);
                              }}
                            />
                          </Col>
                          <Col md={12} className="my-2">
                            <FormikSelectInput
                              label="Clock Out Time"
                              placeholder="Select Client"
                              name="clock_out_time"
                              type="text"
                              className="w-100 mb-2"
                              autoComplete="new-password"
                              defaultValue={"Select Out Time"}
                              options={timeInHours}
                              disabled={updateValues?.title ? true : false}
                              onChange={(e) => {
                                setFieldValue("clock_out_time", e);
                              }}
                              width="100%"
                              noBr
                              // disabled={!values.extra_pet_enabled}
                            />
                          </Col>
                          </>
                        }
                        <Col md={24} className="">
                          <FormikSelectInput
                            label="Time Shift Type"
                            placeholder="Select Type"
                            name="type_of_time_shift"
                            type="text"
                            className="w-100 mb-2"
                            autoComplete="new-password"
                            defaultValue={"Select Type"}e
                            options={typeOfTimeShift}
                            onChange={(e) => {
                              if (e === "service_time_shift") {
                                setSerivicesDropdown(true);
                              }else{
                                setFieldValue("service_request", null);

                                setSerivicesDropdown(false);
                              }
                            }}
                            width="100%"
                            noBr
                            // disabled={!values.extra_pet_enabled}
                          />
                        </Col>
                        {serivicesDropdown && (
                          <Col md={24} className="">
                            <FormikSelectInput
                              label="All Services"
                              placeholder="Select Service"
                              name="type_of_service"
                              type="text"
                              className="w-100 mb-2"
                              autoComplete="new-password"
                              defaultValue={"Select Service"}
                              options={allServices}
                              onChange={(e) => {
                                setFieldValue("service_request", e);
                              }}
                              width="100%"
                              noBr
                              // disabled={!values.extra_pet_enabled}
                            />
                          </Col>
                        )}
                        <Col md={24} className="">
                          <FormikTextArea
                            label="Notes"
                            placeHolder="Type Notes Here..."
                            name="note"
                            type="text"
                            className="w-100 mb-2"
                            autoComplete="new-password"
                            width="100%"
                            onChange={(e) => {
                              setFieldValue("note", e);
                            }}
                          />
                        </Col>
                      </Row>
                    </div>
                    
                  </Form>
                )}
              </Formik>
            </div>
          </>
        }
        footer={
          <div className="d-flex justify-content-end">
            <CustomBtnWithIcon btnText={"Cancel"} hideIcon noBackground className="me-2" width="90px" onClick={closeAll} />
            <CustomBtnWithIcon
              btnText={"Save"}
              hideIcon
              className="me-2"
              width="90px"
              type="submit"
              // disabled={staffAddloader}
              onClick={() => {
                if (formikRef?.current) {
                  formikRef?.current?.handleSubmit();
                  return;
                }
              }}
            />
          </div>
        }
      />
    </>
  );
};

export default AddTimeTrackingForm;

const DateHeading = styled.div`
  font-weight: 700;
  font-size: 16px;
`;
