import styled from "styled-components";

const ClientContainer = styled.h2`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 400px;
  background: #fff6e8;
  border-radius: 16px;
`;

const NavigationHeading = styled.h2`
  display: flex;
  margin: 25px 0px 10px 0px;
  font-size: 20px;
  font-weight: 700;
`;

const NoLCientsHeading = styled.h2`
  color: #212121;
  font-size: 16px;
  margin-top: 15px;
`;
const TableHeader = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`;

// add staff styles

const StepsContainer = styled.div`
  display: flex;
  height: 55px;
  align-items: center;
  background: rgb(238, 238, 238);
  margin-bottom: 15px;
`;

const StepFirstCircle = styled.div`
  height: 31px;
  width: 31px;
  background: #fbcf34;
  border-radius: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    transform: translateY(2px);
    font-weight: 600;
  }
`;

const RequiredSign = styled.div`
  color: #ff4d4f;
  font-size: 15px;
  transform: translateY(2px);
  font-weight: 400;
`;

export { ClientContainer, NavigationHeading, NoLCientsHeading, TableHeader, StepsContainer, StepFirstCircle, RequiredSign };
