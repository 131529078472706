import React, { useEffect, useRef, useState } from "react";
import { Row, Col, DatePicker as DatePickerv2, Select } from "antd";
import "react-big-calendar/lib/css/react-big-calendar.css";

import styled from "styled-components";
import moment from "moment";
import * as Yup from "yup";
// import "./calendar.css";
import { format } from "date-fns";
import { ReactComponent as Birthdate } from "../../../assets/rawSvgs/Birthdate.svg";

import { ReactComponent as GreaterThanIcon } from "../../../assets/rawSvgs/greaterThanIcon.svg";
import { ReactComponent as LessThanIcon } from "../../../assets/rawSvgs/lessThanIcon.svg";
import { ReactComponent as DropDownIcon } from "../../../assets/rawSvgs/selectDropDownIcon.svg";

import AntDesignDropDownSelect from "../../../components/AntDesignDropDownSelect/AntDesignDropDownSelect";
import HeadingComponent from "../../../components/Heading";

import OneDayScheduleCalender from "../../../components/SchedulerCalender/horizontalCalender/OneDayScheduleTable";
import OndeDayv2 from "../../../components/SchedulerCalender/OneDayVertical";
import SchedulerTopBarFilter from "./schedulerComponents/SchedulerTopBarRightFilterComponents";
import { TabPill, TabsWrapper } from "./pendingRequest/ScheduledRequests";
import ScheduledEventsListTable from "./schedulerComponents/ScheduledEventsListTable";

import WeekCalendar from "../../../components/SchedulerCalender/weekResourceCalender/WeekResourceCalender.jsx";
import dayjs from "dayjs";

import CustomBtnWithIcon from "../../../components/buttonWithIcon";
import AntDesignModal from "../../../components/Modals/AntDesignModal/AntDesignModal";
import FormikSelectInput from "../../../components/formikInputs/SelectInput";

import FormikTextArea from "../../../components/formikInputs/textArea";
import { Formik, Form } from "formik";

import { main_api } from "../../../api/axios_helper";
import { companyAPIsEndPoints } from "../../../constants/ApiEndPoints";
import { pushNotification } from "../../../util/Notification";
import { useSelector } from "react-redux";
import MyCalendarMonthv2 from "../../../components/SchedulerCalender/monthCalenderResource/MonthResourceCalender";
import { Hours24Time } from "../../../util/24HourtimeList";

import ScheduleTemplateTripForm from "./schedulerComponents/ScheduleTripForm";
import { checkPermission } from "../../../util/commonUtil.js";
import PermissionModal from "../../../components/Modals/PermissionModal.jsx";
import ScheduleGroupWalk from "./schedulerComponents/ScheduleTripGroup";
import { convertTo24HourFormat, generateAvailableTimeSlots } from "./pendingRequest/utilsFunctions.js";
import { convertTo12HourFormat } from "../../../util/formatTime.js";
import FormikCheckboxInput from "../../../components/formikInputs/Checkbox.js";

//
const Schedulerv2 = ({ clientPetServiceDropdowns }) => {
  const {
    dayChangeState,
    weekChangeState,
    calenderTypeDropDownState,
    monthChangeState,
    fetchUnavaialbleSlots,
    totalServiceDetails,
  } = clientPetServiceDropdowns;
  const { calenderTypeDropDown, setCalenderTypeDropDown } = calenderTypeDropDownState;
  const { dateRange, setDateRange } = monthChangeState;
  // const [calenderTypeDropDown, setCalenderTypeDropDown] = useState("Day-Horizontal");
  const [selectCalenderView, setSelectCalenderView] = React.useState("calenderView");
  const filteringLoader = useSelector((state) => state.scheduler.schedulerResponse.isLoading);
  const [scheduleServiceModal, setScheduleServiceModal] = useState(false);
  const [scheduleTripModal, setScheduleTripModal] = useState(false);
  const [permissionPopUp, setPermissionPopUp] = useState(false);
  const [scheduleGroupWalk, setScheduleGroupWalk] = useState(false);
  const statePermissions = useSelector((state) => state.permissionManagement.permissions);

  let options = [
    { value: "Day-Horizontal", label: "Day-Horizontal" },
    { value: "Day-Vertical", label: "Day-Vertical" },
    { value: "Week", label: "Week" },
    { value: "Month", label: "Month" },
  ];

  useEffect(() => {
    fetchUnavaialbleSlots({ date: moment(dayChangeState.dayRange?.start).format("YYYY-MM-DD") });
  }, [dayChangeState.dayRange?.start, fetchUnavaialbleSlots]);

  const handleDateNext = () => {
    if (calenderTypeDropDown === "Month") {
      handleNextMonth();
    }
    if (calenderTypeDropDown === "Week") {
      handleNextWeek();
    }
    if (calenderTypeDropDown === "Day-Vertical" || calenderTypeDropDown === "Day-Horizontal") {
      handleNextDay();
    }
  };
  const handleDatePrev = () => {
    if (calenderTypeDropDown === "Month") {
      handlePrevMonth();
    }
    if (calenderTypeDropDown === "Week") {
      handlePrevWeek();
    }
    if (calenderTypeDropDown === "Day-Vertical" || calenderTypeDropDown === "Day-Horizontal") {
      handlePrevDay();
    }
  };

  const handlePrevMonth = () => {
    const newStart = moment(dateRange.start).subtract(1, "month").startOf("month").toDate();
    const newEnd = moment(dateRange.end).subtract(1, "month").endOf("month").toDate();
    setDateRange({ start: newStart, end: newEnd });
  };

  const handleNextMonth = () => {
    const newStart = moment(dateRange.start).add(1, "month").startOf("month").toDate();
    const newEnd = moment(dateRange.end).add(1, "month").endOf("month").toDate();
    setDateRange({ start: newStart, end: newEnd });
  };

  // WEEEK

  const handlePrevWeek = () => {
    const newStart = moment(weekChangeState.dateRangeWeek.start).subtract(7, "days").toDate();
    const newEnd = moment(weekChangeState.dateRangeWeek.end).subtract(7, "days").toDate();
    weekChangeState.setDateRangeWeek({ start: newStart, end: newEnd });
  };

  const handleNextWeek = () => {
    const newStart = moment(weekChangeState.dateRangeWeek.start).add(7, "days").toDate();
    const newEnd = moment(weekChangeState.dateRangeWeek.end).add(7, "days").toDate();
    weekChangeState.setDateRangeWeek({ start: newStart, end: newEnd });
  };
  // DAY

  const handlePrevDay = () => {
    const newStart = moment(dayChangeState.dayRange.start).subtract(1, "day").toDate();
    const newEnd = moment(dayChangeState.dayRange.start).subtract(1, "day").toDate();

    dayChangeState.setDayRange({ start: newStart, end: newEnd });
  };

  const handleNextDay = () => {
    const newStart = moment(dayChangeState.dayRange.start).add(1, "day").toDate();
    const newEnd = moment(dayChangeState.dayRange.start).add(1, "day").toDate();
    dayChangeState.setDayRange({ start: newStart, end: newEnd });
  };

  const handleSelectSchedulerDropDown = (value) => {
    if (value === "Schedule a Service") {
      if (checkPermission(statePermissions, "create_service_request")) {
        setScheduleServiceModal(true);
      } else {
        setPermissionPopUp(true);
      }
      return;
    } else if (value === "Schedule a Trip") {
      if (checkPermission(statePermissions, "create_service_request")) {
        setScheduleTripModal(true);
      } else {
        setPermissionPopUp(true);
      }
    } else if (value === "Schedule a Group Walk") {
      if (checkPermission(statePermissions, "create_service_request")) {
        setScheduleGroupWalk(true);
      } else {
        setPermissionPopUp(true);
      }
    }
  };

  const calenderType = {
    "Day-Horizontal": (
      <OneDayScheduleCalender
        AllStates={clientPetServiceDropdowns}
        handleSelectSchedulerDropDown={handleSelectSchedulerDropDown}
      />
    ),
    "Day-Vertical": <OndeDayv2 AllStates={clientPetServiceDropdowns} />,
    Week: <WeekCalendar clientPetServiceStates={clientPetServiceDropdowns} AllStates={clientPetServiceDropdowns} />,
    Month: (
      <MyCalendarMonthv2
        dateRange={dateRange}
        clientPetServiceStates={clientPetServiceDropdowns}
        schedulerState={clientPetServiceDropdowns.schedulerState}
        companyPortal={true}
        AllStates={clientPetServiceDropdowns}
        loading={filteringLoader}
        handleDataChange={() => {
          clientPetServiceDropdowns?.monthDeleteEventFunction();
        }}
      />
    ),
  };

  return (
    <div>
      <TopSchedulerToolBarWrapper>
        <Row align={"middle"} gutter={5}>
          <Col md={4} className="d-flex  ">
            <TabsWrapper width="400px" padding="5px 5px 6px 5px" height="50px">
              <TabPill
                selected={selectCalenderView === "calenderView"}
                onClick={() => setSelectCalenderView("calenderView")}
                padding="0px"
              >
                <AntDesignDropDownSelect
                  className={selectCalenderView !== "calenderView" && "ant-select-selectorv2"}
                  options={options}
                  defaultValue="Day-Horizontal"
                  // height={28}
                  value={calenderTypeDropDown}
                  onChange={(e) => setCalenderTypeDropDown(e)}
                  width="140px"
                />
              </TabPill>
              <TabPill height="45px" selected={selectCalenderView === "list"} onClick={() => setSelectCalenderView("list")}>
                List
              </TabPill>
            </TabsWrapper>
          </Col>

          {/* ============== MONTH DAY WEEK ======================= */}
          <Col lg={7} className="">
            {selectCalenderView === "calenderView" && (
              <div className="d-flex  align-items-center ms-5">
                <div className="me-2">
                  <Birthdate stroke={"#424242"} />
                </div>

                {calenderTypeDropDown === "Month" && (
                  <HeadingComponent
                    text={moment(dateRange?.start).format("MMMM, YYYY")}
                    fontSize={"14px"}
                    color="#757575"
                    margin="0px 0px "
                    fontWeight={400}
                  />
                )}
                {calenderTypeDropDown === "Week" && (
                  <div className="  d-flex">
                    <HeadingComponent
                      text={moment(weekChangeState?.dateRangeWeek?.start).format("MMMM DD, YYYY")}
                      fontSize={"14px"}
                      color="#757575"
                      margin="0px 0px "
                      fontWeight={400}
                      style={{ whiteSpace: "nowrap" }}
                    />
                    <Seperator> - </Seperator>
                    <HeadingComponent
                      text={moment(weekChangeState?.dateRangeWeek?.end).format("MMMM DD, YYYY")}
                      fontSize={"14px"}
                      color="#757575"
                      margin="0px 0px "
                      fontWeight={400}
                      style={{ whiteSpace: "nowrap" }}
                    />
                  </div>
                )}
                {(calenderTypeDropDown === "Day-Vertical" || calenderTypeDropDown === "Day-Horizontal") && (
                  <HeadingComponent
                    text={moment(dayChangeState.dayRange?.start).format("MMMM DD, YYYY")}
                    fontSize={"14px"}
                    color="#757575"
                    margin="0px 0px "
                    fontWeight={400}
                  />
                )}
                <div className="d-flex ms-2">
                  <button onClick={handleDatePrev}>
                    <LessThanIcon />
                  </button>
                  <button onClick={handleDateNext}>
                    <GreaterThanIcon />
                  </button>
                </div>
              </div>
            )}
          </Col>
          {/* ============== TOP FILTERS ======================= */}

          <Col lg={13} style={{ display: "flex", justifyContent: "flex-end", padding: " 0px 10px" }}>
            <SchedulerTopBarFilter
              selectCalenderView={selectCalenderView}
              handleSelectSchedulerDropDown={handleSelectSchedulerDropDown}
              topFiltersState={clientPetServiceDropdowns.topFiltersState}
            />
          </Col>
        </Row>
      </TopSchedulerToolBarWrapper>

      {/* =============== CALENDERS ====================== */}

      <div>
        <div className="d-flex justify-content-between row row-grid align-items-baseline overflow-auto ">
          <div className="col-lg-12 ">
            {selectCalenderView === "list" ? (
              <ScheduledEventsListTable AllStates={clientPetServiceDropdowns} />
            ) : (
              calenderType[calenderTypeDropDown]
            )}
          </div>
        </div>
      </div>
      {/* =============== FOOTER ====================== */}

      <Footer>
        <SchedulerEventsStatusComponent
          statusColor="#187BEF"
          text="Scheduled"
          eventNumber={totalServiceDetails?.total_scheduled || 0}
        />
        <SchedulerEventsStatusComponent
          statusColor="#12B76A"
          text="Completed"
          eventNumber={totalServiceDetails?.total_completed || 0}
        />
        <SchedulerEventsStatusComponent
          statusColor="#E53939"
          text="Cancelled"
          eventNumber={totalServiceDetails?.total_cancelled || 0}
        />
        <SchedulerEventsStatusComponent
          statusColor="#B45309"
          text="In progress"
          eventNumber={totalServiceDetails?.total_in_progress || 0}
        />
        <SchedulerEventsStatusComponent
          statusColor="#B45309"
          text="Unassigned"
          eventNumber={totalServiceDetails?.total_unassigned || 0}
        />
      </Footer>

      {/* =============== Modals ====================== */}
      {scheduleServiceModal && (
        <AddNewEventModal
          clientPetServiceDropdowns={clientPetServiceDropdowns}
          addNewEventModal={scheduleServiceModal}
          closeModal={() => setScheduleServiceModal(false)}
        />
      )}
      {scheduleTripModal && (
        <ScheduleTemplateTripForm
          // clientPetServiceDropdowns={clientPetServiceDropdowns}
          addNewEventModal={scheduleTripModal}
          requiredDropdowns={clientPetServiceDropdowns}
          setScheduleTripModal={setScheduleTripModal}
          closeAll={() => {
            setScheduleTripModal(false);
          }}
        />
      )}

      {scheduleGroupWalk && (
        <ScheduleGroupWalk
          clientPetServiceDropdowns={clientPetServiceDropdowns}
          addNewEventModal={scheduleGroupWalk}
          requiredDropdowns={clientPetServiceDropdowns}
          setScheduleTripModal={setScheduleGroupWalk}
          closeAll={() => {
            setScheduleGroupWalk(false);
          }}
        />
      )}

      <PermissionModal onclose={() => setPermissionPopUp(false)} openModal={permissionPopUp} />
    </div>
  );
};

export default Schedulerv2;

const SchedulerEventsStatusComponent = ({ text = "Scheduled", statusColor = "green", eventNumber }) => {
  return (
    <div className="d-flex mx-1 ">
      <Dot statusColor={statusColor} />

      <HeadingComponent text={eventNumber + " " + text} margin="0px 5px" fontSize={"14px"} color="#757575" />
    </div>
  );
};

// ========================= //
// NOTE:
// This modal is for the creation of events in all three calenders
// month, week , and both days have same event creation modal which is this
// clientPetServiceDropdowns is mismatch named it contains all states of redux , staff, client, pets etc
// update values are event update vlaue when you want to update existing schedule event
//
//
export const AddNewEventModal = ({
  addNewEventModal,
  closeModal,
  clientPetServiceDropdowns,
  handleSuccessFunc,
  updateValues,
  updateForm,
  fromInvoicing,
  handleSubmitServiceForm,
  clientFromInvoice,

  fromMonthCalender,
}) => {
  const clientStatePets = useSelector((state) => state.clientManangement?.clientResponse.responseData.result);
  const [submitFormLoader, setSubmitFormLoader] = useState(false);
  const [timeBlockAccordingToService, setTimeBlockAccordingToService] = useState([]);
  const [timeSlotsAccordingToServ, setTimeSlotsAccordingToServ] = useState([]);
  const [petsAccordingtoClient, petsAccordingtoClientSetSelectAllPets] = useState([]);
  const [keyList, setKeyList] = useState([]);
  const [keyOptions, setKeyOptions] = useState([]);
  const [eventdate, setEventDate] = useState(
    moment(clientPetServiceDropdowns.dayChangeState.dayRange?.start).format("YYYY-MM-DD"),
  );

  const [unavailableState, setUnavailableState] = useState(undefined);
  let clientPetServiceDropdownsv2 = {
    unavaliableSlots: {
      responseData: {
        result: unavailableState,
      },
    },
  };
  // USE STATAES
  const state = useSelector((state) => state.auth.loginResponse);
  let company_id = state.selectCompany?.[0]?.company_id || null;
  const formikRef = React.useRef();
  let fromCalendeData = fromMonthCalender
    ? moment(updateValues.date).format("YYYY-MM-DD")
    : moment(clientPetServiceDropdowns.dayChangeState.dayRange?.start).format("YYYY-MM-DD");
  const getKeys = async (queryParams = "", search = "") => {
    try {
      const { data } = await main_api.get(companyAPIsEndPoints.FETCH_MANAGEMENT_KEYS(company_id, queryParams, search));

      setKeyList(data.result);
    } catch (error) {
      pushNotification("Something went wrong while getting keys!", "error");
    }
  };
  useEffect(() => {
    getKeys();
    clientPetServiceDropdowns.fetchAllStaff();
    // clientPetServiceDropdowns.fetchUnavaialbleSlots({
    //   date: moment(clientPetServiceDropdowns.dayChangeState.dayRange?.start).format("YYYY-MM-DD"),
    // });

    setEventDate(fromCalendeData);
  }, []);
  useEffect(() => {
    fetchUnavaialbleSlotsApi(fromCalendeData);
  }, []);

  const filterKeysByClient = (clientId) => {
    const filteredKeys = keyList
      .filter((key) => key.client === clientId)
      .map((item) => ({
        label: item.name,
        value: item.id,
      }));
    setKeyOptions(filteredKeys);
  };

  useEffect(() => {
    const filterUpdateService =
      clientPetServiceDropdowns.schedulerState?.result?.filter((items) => {
        return items.id === updateValues?.serviceID;
      })[0] || "";
    if (filterUpdateService?.client?.id) {
      const filterPets = clientStatePets?.filter((items) => {
        return items.id === filterUpdateService?.client?.id;
      });

      petsAccordingtoClientSetSelectAllPets(
        filterPets?.[0].clientpet?.map((items) => {
          return {
            value: items.id,
            label: items.name,
          };
        }),
      );
    }
  }, []);

  useEffect(() => {
    if (updateForm) {
      const serviceFromUpdateValues =
        clientPetServiceDropdowns?.allServices?.responseData?.result?.filter((items) => {
          return items.id === updateValues?.serviceDetails?.id;
        })[0] || [];
      // Assuming filterService.time_blocks is an array of numbers
      const updateValueTimeBlock = serviceFromUpdateValues?.time_blocks || [];

      //  TIME BLOCK FROM SERVICCe
      const filteredTimeBlocks =
        clientPetServiceDropdowns?.blockTimeDropDown?.filter((item) => {
          return updateValueTimeBlock.includes(item.value);
        }) || [];

      const filterTimeBlockwithID =
        clientPetServiceDropdowns.timeBlockCompList?.filter((items) => {
          return items.id === updateValues?.timeblock?.id;
        })[0] || [];

      let startTime = filterTimeBlockwithID?.start_time;
      let endTime = filterTimeBlockwithID?.end_time;

      // ===============================

      // TIME SLOT FROM TIME BLOCK
      let genAvailSlots = generateAvailableTimeSlots(startTime || "", endTime || "");
      if (!unavailableState) {
        let filterUnAvilSlots = filterUnavailableTimeSlots(
          genAvailSlots,
          updateValues.staffId,
          clientPetServiceDropdowns,
          genAvailSlots,
        );

        setTimeSlotsAccordingToServ(filterUnAvilSlots);
        setTimeBlockAccordingToService(filteredTimeBlocks);
      }
    }
  }, [updateForm, updateValues]);

  const handleSubmitForm = async (values) => {
    try {
      let objSend = { ...values };
      if (!values.service_requests[0].services[0].staff || values.service_requests[0].services[0].staff === "") {
        objSend.service_requests[0].services[0].end_time = null;
        objSend.service_requests[0].services[0].start_time = null;
      }

      setSubmitFormLoader(true);
      // invoice module create service along with invoice id
      if (fromInvoicing) {
        await handleSubmitServiceForm(objSend);
        setSubmitFormLoader(false);
        return;
      }

      const { data } = await main_api.post(companyAPIsEndPoints.POST_SCHEDULER_LIST, objSend);

      setSubmitFormLoader(false);
      if (data.isSuccess) {
        pushNotification("Service Scheduled Successfully!.", "success");
        clientPetServiceDropdowns?.fetchAllSchedulerService();
        clientPetServiceDropdowns?.fetchDashboardCompany();
        closeModal();
      }
    } catch (error) {
      setSubmitFormLoader(false);
      if (error.response?.data?.message) {
        pushNotification("Please Fill All Fields", "error");
      }
      if (error.response?.data?.status === 400) {
        pushNotification("This service cannot be scheduled in this time Block", "error");
      }
      pushNotification("Something went wrong try again", "error");
    }
  };
  const handleUpdateForm = async (values) => {
    try {
      setSubmitFormLoader(true);

      const { data } = await main_api.put(
        `/api/v1/service_request/${values.service_requests[0].id}/`,
        values.service_requests[0],
      );

      setSubmitFormLoader(false);
      if (data.isSuccess) {
        pushNotification("Service Updated Successfully!.", "success");
        clientPetServiceDropdowns?.fetchAllSchedulerService();
        clientPetServiceDropdowns?.fetchDashboardCompany();
        closeModal();
        handleSuccessFunc?.();
      }
    } catch (error) {
      setSubmitFormLoader(false);
      if (error.response?.data?.message) {
        pushNotification("Please Fill All Fields", "error");
      }
      console.log("error :", error);
    }
  };

  const validationSchema = Yup.object().shape({
    client: Yup.string().required("Client is required"),
    note: Yup.string(),
    service_requests: Yup.array()
      .of(
        Yup.object().shape({
          date: Yup.string().required("Date is required"),
          services: Yup.array().of(
            Yup.object().shape({
              service: Yup.string().required("Service is required"),
              end_time: Yup.string().required("End time is required"),

              staff: Yup.string().nullable(),
              status: Yup.string().oneOf(["pending", "completed", "canceled"]),
              start_time: Yup.string().when("staff", {
                is: (staff) => staff !== null && staff !== undefined && staff !== "",
                then: Yup.string().required("Start time is required"),
                otherwise: Yup.string(),
              }),
              time_block: Yup.string().required("Time block is required"),
            }),
          ),
        }),
      )
      .required("At least one service request is required"),
  });

  const currentDate = new Date();

  // Format the current date as "YYYY-MM-DD"
  const formattedCurrentDate = format(currentDate, "yyyy-MM-dd");
  const filterUpdateDate =
    Hours24Time?.filter((items) => {
      return items.label === updateValues?.slotTime;
    })[0]?.value || "";

  const filterUpdateStaff =
    clientPetServiceDropdowns?.staffState?.filter((items) => {
      return items.value === updateValues?.staffId;
    })[0]?.value || "";

  const filterUpdateService =
    clientPetServiceDropdowns.schedulerState?.result?.filter((items) => {
      return items.id === updateValues?.serviceID;
    })[0] || "";

  const fetchUnavaialbleSlotsApi = async (dateFetch) => {
    try {
      let payload = { date: dateFetch };
      const { data } = await main_api.post(companyAPIsEndPoints.UNAVAIABLE_SLOTS(), payload);
      setUnavailableState(data?.result);
    } catch (error) {}
  };

  let dateFromCalender = dayjs(eventdate);
  const today = dayjs();
  // Check if the parsed date is in the past
  const resultDate = dateFromCalender.isBefore(today, "day") ? today : dateFromCalender;

  // Format the result date to match your desired format
  const formattedDate = resultDate.format("YYYY-MM-DD");

  return (
    <div>
      <AntDesignModal
        width={550}
        title={updateValues?.title ? "Update a Scheduled Service" : "Schedule a Service"}
        style={{ top: 20 }}
        component={
          <Row gutter={10} className="mt-3">
            <Formik
              initialValues={{
                client: filterUpdateService?.client?.id
                  ? filterUpdateService?.client?.id
                  : clientFromInvoice
                    ? clientFromInvoice
                    : "",
                key_required: false,
                note: "",
                scheduled_by: "company",
                company: company_id,
                service_requests: [
                  {
                    date: updateValues?.date || formattedCurrentDate,
                    services: [
                      {
                        service: filterUpdateService?.service?.id ? filterUpdateService?.service?.id : "",

                        start_time: filterUpdateService?.service?.id ? convertTo12HourFormat(filterUpdateDate || "") : "",

                        end_time: filterUpdateService.end_time || "",
                        pets: filterUpdateService?.pets ? filterUpdateService?.pets?.map((items) => items.id) : [],
                        tasks: filterUpdateService?.service_request_task
                          ? filterUpdateService?.service_request_task?.map((items) => {
                              return {
                                value: items.id,
                                label: items.name,
                              };
                            })
                          : [],
                        staff: filterUpdateStaff ? filterUpdateStaff : null,
                        status: "",
                        time_block: filterUpdateService.time_block?.id ? filterUpdateService?.time_block.id : "",
                      },
                    ],
                  },
                ],
              }}
              innerRef={formikRef}
              enableReinitialize
              onSubmit={(values, { setTouched }) => {
                setTouched({
                  selectedOption: true,
                });
                if (updateValues?.title) {
                  const matchingPets = [];

                  filterUpdateService.pets.forEach((pet) => {
                    values?.service_requests[0]?.services?.[0]?.pets.forEach((string) => {
                      if (pet.name === string) {
                        matchingPets.push(pet.id);
                      }
                    });
                  });
                  const updatedValues = { ...values };

                  const { client, note, service_requests } = updatedValues;
                  const { staff, start_time, end_time, pets, service, tasks, time_block } = service_requests[0].services[0];

                  // Constructing the new object
                  let checkStaff = staff === "" || !staff ? false : true;
                  console.log(tasks, "BEFORE");

                  let tasksModified = [];

                  tasks?.forEach((elements) => {
                    if (elements?.hasOwnProperty("value")) {
                      tasksModified.push({ id: elements.value, name: elements.label, company: company_id });
                    } else {
                      tasksModified.push(elements);
                    }
                  });

                  const newObject = {
                    service_requests: [
                      {
                        id: filterUpdateService.id,
                        service: service, // Assuming you have an 'id' field in your original object
                        staff: staff,
                        start_time: checkStaff ? start_time : null,
                        end_time: checkStaff ? end_time : null,
                        client: client,
                        note: note,
                        pets: pets,
                        date: service_requests[0]?.date,
                        tasks: tasksModified,
                        time_block,
                      },
                    ],
                  };

                  handleUpdateForm(newObject);

                  // console.log("SUBMIT UPDATE : ", newObject);

                  return;
                }

                handleSubmitForm(values);

                // console.log("SUBMIT CREATE : ", values);
              }}
              validationSchema={validationSchema}
            >
              {({ handleSubmit, setFieldValue, values, errors }) => (
                <Form onSubmit={handleSubmit}>
                  <Row gutter={10}>
                    <Col md={12} className="d-flex flex-column mt-1">
                      <InputTextLabel>Date</InputTextLabel>
                      <DatePickerv2
                        defaultValue={updateValues?.date ? dayjs(updateValues?.date) : dayjs(formattedDate)}
                        suffixIcon={<Birthdate stroke={"#424242"} />}
                        format="YYYY-MM-DD"
                        style={{ height: "40px" }}
                        onChange={(e, dateString) => {
                          setFieldValue("service_requests[0].services[0].end_time", "");
                          setFieldValue("service_requests[0].services[0].start_time", "");
                          setFieldValue("service_requests[0].services[0].time_block", "");
                          // setFieldValue("service_requests[0].services[0].staff", "");
                          setFieldValue("service_requests[0].date", dateString);
                          setEventDate(moment(dateString).format("YYYY-MM-DD"));
                          fetchUnavaialbleSlotsApi(moment(dateString).format("YYYY-MM-DD"));
                        }}
                        // disabledDate={(current) => current && current <= dayjs().endOf("day")}
                        disabledDate={(current) => current && current < dayjs().startOf("day")}
                      />
                      <span className="input-error-style">
                        {errors?.service_requests?.[0]?.date && errors?.service_requests?.[0]?.date}
                      </span>
                    </Col>

                    {/* SERVICE */}
                    <Col md={12}>
                      <div className="">
                        <FormikSelectInput
                          label="Service"
                          placeholder="Select Service"
                          name="service_requests[0]?.services?.[0]?.service"
                          type="text"
                          className="w-100 mb-2"
                          autoComplete="new-password"
                          defaultValue={filterUpdateService?.service?.id ? filterUpdateService?.service?.id : "Select Service"}
                          options={clientPetServiceDropdowns?.serviceState}
                          // value={values?.service_requests[0]?.services?.[0]?.service}
                          onChange={(e) => {
                            // REST STATES OF FIELD
                            setFieldValue("service_requests[0].services[0].end_time", "");
                            setFieldValue("service_requests[0].services[0].start_time", "");
                            setFieldValue("service_requests[0].services[0].time_block", "");
                            setTimeSlotsAccordingToServ([]);
                            setTimeBlockAccordingToService([]);

                            const filterService =
                              clientPetServiceDropdowns?.allServices?.responseData?.result?.filter((items) => {
                                return items.id === e;
                              })[0] || [];
                            // Assuming filterService.time_blocks is an array of numbers
                            const timeBlocks = filterService?.time_blocks || [];

                            //  TIME BLOCK FROM SERVICCe
                            const filteredTimeBlocks =
                              clientPetServiceDropdowns?.blockTimeDropDown?.filter((item) => {
                                return timeBlocks.includes(item.value);
                              }) || [];

                            setTimeBlockAccordingToService(filteredTimeBlocks);

                            // SET START AND END TIME OF EVENT

                            const initialTime = values?.service_requests[0]?.services?.[0]?.start_time || "00:00";
                            // =======================================
                            const updatedTime = calculateUpdatedTime(initialTime, filterService?.default_duration);

                            setFieldValue("service_requests[0].services[0].end_time", updatedTime);
                            setFieldValue("service_requests[0].services[0].service", e);
                          }}
                          width="100%"
                          noBr
                          // disabled={!values.extra_pet_enabled}
                        />
                        <span className="input-error-style">
                          {errors?.service_requests?.[0]?.services && errors?.service_requests[0]?.services?.[0]?.service}
                        </span>
                      </div>
                    </Col>
                    {/* STAFF */}
                    <Col md={12} className="my-1">
                      <FormikSelectInput
                        label="Staff"
                        placeholder="Select Staff"
                        // defaultValue={values?.service_requests[0]?.services?.[0]?.staff || "Select Staff"}
                        {...(values.service_requests[0]?.services?.[0]?.staff
                          ? {
                              value: values.service_requests[0]?.services?.[0]?.staff,
                            }
                          : { defaultValue: filterUpdateService?.staff?.id || "Select Staff" })}
                        name="service_requests[0]?.services?.[0]?.staff"
                        type="text"
                        className="w-100 mb-2"
                        autoComplete="new-password"
                        options={clientPetServiceDropdowns?.staffState}
                        onChange={(e) => {
                          setFieldValue("service_requests[0].services[0].start_time", "");
                          setFieldValue("service_requests[0].services[0].staff", "");
                          setTimeSlotsAccordingToServ([]);
                          let timeBlock = values?.service_requests[0]?.services?.[0]?.time_block;

                          const filterTimeBlock =
                            clientPetServiceDropdowns.timeBlockCompList?.filter((items) => {
                              return items.id === timeBlock;
                            })[0] || [];

                          let startTime = filterTimeBlock?.start_time;
                          let endTime = filterTimeBlock?.end_time;

                          // TIME SLOT FROM TIME BLOCK
                          let timeBlockSlots = generateAvailableTimeSlots(startTime || "", endTime || "");

                          if (timeBlock) {
                            let checkv1 = filterUnavailableTimeSlots(
                              timeBlockSlots,
                              e,
                              clientPetServiceDropdownsv2,
                              timeBlockSlots,
                            );

                            setTimeSlotsAccordingToServ(checkv1);
                          }
                          setFieldValue("service_requests[0].services[0].staff", e);
                        }}
                        width="100%"
                        allowClear
                        noBr
                      />
                      <span className="input-error-style">
                        {errors?.service_requests?.[0]?.services && errors?.service_requests[0]?.services?.[0]?.staff}
                      </span>
                    </Col>

                    {/* tags */}
                    <Col md={12} className="d-flex flex-wrap mt-1">
                      <FormikSelectInput
                        mode="tags"
                        label="Tasks"
                        notFoundContent={null}
                        style={{ width: "100%", maxHeight: "50px", overflow: "auto" }}
                        placeholder="Enter tasks"
                        name="service_requests[0]?.services?.[0]?.tasks"
                        defaultValue={
                          filterUpdateService?.service_request_task?.map((items) => {
                            return {
                              value: items.id,
                              label: items.name,
                            };
                          }) || []
                        }
                        onChange={(option) => {
                          const tags = option?.map((items) => {
                            return {
                              name: items,
                              company: company_id,
                            };
                          });

                          if (updateValues?.title) {
                            let arrSend = [];

                            const filteredObjects = filterUpdateService?.service_request_task.filter((obj) =>
                              option.includes(obj.id),
                            );

                            const filteredArray = option?.filter((item) => isNaN(item));
                            const updateModifiedtags = filteredArray?.map((items) => {
                              return {
                                name: items,
                                company: company_id,
                              };
                            });
                            if (updateModifiedtags?.length > 0) {
                              updateModifiedtags?.forEach((elem) => {
                                arrSend.push(elem);
                              });
                            }
                            if (filteredObjects?.length > 0) {
                              filteredObjects?.forEach((elem) => {
                                arrSend.push({ id: elem.id, name: elem.name, company: company_id });
                              });
                            }
                            console.log("triger tag mode after ", arrSend);

                            setFieldValue("service_requests[0].services[0].tasks", arrSend);
                          } else {
                            setFieldValue("service_requests[0].services[0].tasks", tags);
                          }
                        }}
                        // value={values?.service_requests[0]?.services?.[0]?.tasks}
                        className=" w-100"
                      />
                    </Col>

                    {/* TIME BLoCK*/}
                    <Col md={12} className="mb-2">
                      <FormikSelectInput
                        label="Time Block"
                        placeholder="Select Time Block"
                        // defaultValue={filterUpdateService?.time_block?.id || "Select time block"}
                        name="service_requests[0]?.services?.[0]?.time_block"
                        type="text"
                        className="w-100 "
                        autoComplete="new-password"
                        allowClear
                        // value={values.service_requests[0]?.services?.[0]?.time_block}
                        options={timeBlockAccordingToService}
                        {...(values.service_requests[0]?.services?.[0]?.time_block
                          ? {
                              value: values.service_requests[0]?.services?.[0]?.time_block,
                            }
                          : { defaultValue: filterUpdateService?.time_block?.id || "Select time block" })}
                        onChange={(e) => {
                          setFieldValue("service_requests[0].services[0].start_time", "");
                          const filterTimeBlock =
                            clientPetServiceDropdowns.timeBlockCompList?.filter((items) => {
                              return items.id === e;
                            })[0] || [];

                          let startTime = filterTimeBlock?.start_time;
                          let endTime = filterTimeBlock?.end_time;
                          let staffId = values.service_requests[0]?.services?.[0]?.staff;
                          // TIME SLOT FROM TIME BLOCK
                          let slots = generateAvailableTimeSlots(startTime || "", endTime || "");

                          let genAvailSlots = filterUnavailableTimeSlots(slots, staffId, clientPetServiceDropdownsv2, slots);

                          setTimeSlotsAccordingToServ(genAvailSlots);

                          setFieldValue("service_requests[0].services[0].time_block", e);
                        }}
                        width="100%"
                        noBr
                        // disabled={!values.extra_pet_enabled}
                      />

                      <span className="input-error-style">
                        {errors?.service_requests?.[0]?.services && errors?.service_requests[0]?.services?.[0]?.time_block}
                      </span>
                    </Col>
                    {/* time slot */}
                    <Col md={12} className="mb-2">
                      <FormikSelectInput
                        options={timeSlotsAccordingToServ}
                        placeholder="Select Slot"
                        label={"Time Slot"}
                        allowClear={true}
                        className="w-100"
                        width="100%"
                        name="service_requests[0]?.services?.[0]?.start_time"
                        // defaultValue={filterUpdateService?.service?.id ? convertTo12HourFormat(filterUpdateDate) : "Select Slot"}

                        {...(values.service_requests[0]?.services?.[0]?.start_time
                          ? {
                              value: convertTo12HourFormat(values.service_requests[0]?.services?.[0]?.start_time),
                            }
                          : {
                              defaultValue: filterUpdateService?.service?.id
                                ? convertTo12HourFormat(filterUpdateDate)
                                : "Select Slot",
                            })}
                        onChange={(e, dateString) => {
                          setFieldValue("service_requests[0].services[0].start_time", "");
                          if (values?.service_requests[0]?.services?.[0]?.service) {
                            const filterService =
                              clientPetServiceDropdowns?.allServices?.responseData?.result?.filter((items) => {
                                return items.id === values?.service_requests[0]?.services?.[0]?.service;
                              })[0] || [];

                            const initialTime = convertTo24HourFormat(e) || "00:00";
                            // =======================================
                            const updatedTime = calculateUpdatedTime(initialTime, filterService?.default_duration);

                            setFieldValue("service_requests[0].services[0].end_time", updatedTime);
                          }
                          setFieldValue("service_requests[0].services[0].start_time", convertTo24HourFormat(e));
                        }}
                        disabled={
                          values.service_requests[0]?.services?.[0]?.staff === "" ||
                          !values.service_requests[0]?.services?.[0]?.staff
                            ? true
                            : false
                        }
                      />
                      <span className="input-error-style">
                        {errors?.service_requests?.[0]?.services && errors?.service_requests[0]?.services?.[0]?.start_time}
                      </span>
                    </Col>
                    {/* Client */}
                    <Col md={12}>
                      <div className="">
                        <FormikSelectInput
                          label="Client"
                          placeholder="Select Client"
                          name="client"
                          type="text"
                          className="w-100 mb-2"
                          autoComplete="new-password"
                          defaultValue={
                            filterUpdateService?.client?.id
                              ? filterUpdateService?.client?.id
                              : clientFromInvoice
                                ? clientFromInvoice
                                : "Select Client"
                          }
                          options={clientPetServiceDropdowns?.clientState}
                          disabled={updateValues?.title ? true : false || fromInvoicing}
                          onChange={(e) => {
                            setFieldValue("client", e);
                            setFieldValue("service_requests[0].services[0].pets", []);
                            const filterPets = clientStatePets?.filter((items) => {
                              return items.id === e;
                            });

                            let petsGet =
                              filterPets?.map((items) => {
                                return items.clientpet;
                              }) || [];
                            setFieldValue("service_requests[0].services[0].pets", petsGet.pets?.map((items) => items.name) || []);
                            petsAccordingtoClientSetSelectAllPets(
                              petsGet?.[0]?.map((items) => {
                                return {
                                  label: items.name,
                                  value: items.id,
                                };
                              }),
                            );
                            filterKeysByClient(e);
                          }}
                          width="100%"
                          noBr
                        />
                        {/* <span className="input-error-style">
                          {errors?.service_requests[0]?.services?.[0]?.staff && errors?.service_requests[0]?.services?.[0]?.staff}
                        </span> */}
                      </div>
                    </Col>

                    {/* pets */}
                    <Col md={12} className="d-flex flex-wrap">
                      <FormikSelectInput
                        mode="multiple"
                        label={"Pets"}
                        style={{ width: "100%" }}
                        suffixIcon={<DropDownIcon />}
                        placeholder="Select Pets"
                        name="service_requests[0]?.services?.[0]?.pets"
                        defaultValue={
                          filterUpdateService.pets?.map((items) => {
                            return {
                              value: items.id,
                              label: items.name,
                            };
                          }) || []
                        }
                        onChange={(value) => {
                          setFieldValue("service_requests[0].services[0].pets", value);
                        }}
                        value={values?.service_requests[0]?.services?.[0]?.pets}
                        optionLabelProp="label"
                        options={petsAccordingtoClient}
                        // value={values?.service_requests[0]?.services?.[0]?.tasks}
                        className=" w-100"
                      />
                    </Col>
                    <Col md={12} className="mt-2">
                      <div className="d-flex  align-items-center">
                        <FormikCheckboxInput
                          label=""
                          placeholder="One Per Event"
                          name="key_required"
                          type="text"
                          // className=" mb-2"
                          size="small"
                          checked={values.key_required}
                          onChange={(e) => {
                            setFieldValue("key_required", e.target.checked);
                          }}
                        />

                        <HeadingComponent text={"Key Required?"} fontSize="14px" margin="10px" padding="0px" />
                      </div>
                    </Col>
                    <Col md={24} className="my-2">
                      {values.key_required && (
                        <FormikSelectInput
                          label="Keys"
                          placeholder="Select Key"
                          defaultValue={"Select Key"}
                          name="key"
                          type="text"
                          className="w-100 mb-2"
                          autoComplete="new-password"
                          //   value={values?.service_requests[0]?.services?.[0]?.staff}
                          options={keyOptions}
                          onChange={(e) => {
                            setFieldValue("key", e);
                          }}
                          width="100%"
                          noBr
                          // disabled={!values.extra_pet_enabled}
                        />
                      )}
                    </Col>
                    <Col md={24} className="">
                      <FormikTextArea
                        label="Notes"
                        placeHolder="Type Notes Here..."
                        name="note"
                        type="text"
                        className="w-100 mb-2"
                        autoComplete="new-password"
                        width="100%"
                        onChange={(e) => {
                          setFieldValue("note", e);
                        }}
                      />
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </Row>
        }
        footer={
          <div className="d-flex justify-content-end pt-2">
            <CustomBtnWithIcon btnText={"Cancel"} hideIcon noBackground className="me-2" width="90px" onClick={closeModal} />
            <CustomBtnWithIcon
              btnText={updateValues?.title ? "Update" : "Schedule"}
              hideIcon
              className="me-2"
              width="90px"
              type="submit"
              onClick={() => {
                if (formikRef?.current) {
                  formikRef?.current?.handleSubmit();
                }
              }}
              disabled={submitFormLoader}
            />
          </div>
        }
        openModal={addNewEventModal}
        closeModal={closeModal}
        onCancel={closeModal}
        onOk={closeModal}
      />
    </div>
  );
};

const TopSchedulerToolBarWrapper = styled.div``;

const Footer = styled.footer`
  height: "20px";
  text-align: center;
  padding: 7px;
  position: fixed;
  bottom: 0;
  left: 35%;
  right: 50%;
  display: flex;
  width: 580px;
  margin: auto;
  justify-content: space-evenly;
  background: white;
`;
const Dot = styled.span`
  height: 6px; /* Adjust the size of the dot */
  width: 6px; /* Adjust the size of the dot */
  border-radius: 50%; /* Set border-radius to 50% to make it a circle */
  background: ${(props) => props.statusColor}; /* Set the background color of the dot */
  display: inline-block; /* Ensures the dot is displayed inline */
  margin-top: 2.5px;
`;

export const PetsCheckboxWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const InputTextLabel = styled.span`
  font-size: 12px;
  color: #424242;
  font-weight: 500;
  line-height: 18px;
  width: 100%;
`;

const Seperator = styled.div`
  margin-left: 5px;
  margin-right: 5px;
`;

export function calculateUpdatedTime(initialTime, filterService) {
  // Extract hours and minutes from the initialTime string
  const [hoursStr, minutesStr] = initialTime.split(":");
  let hours = parseInt(hoursStr, 10);
  const minutes = parseInt(minutesStr, 10);

  // Convert initialTime to total minutes
  const initialTimeTotalMinutes = hours * 60 + minutes;

  // Add filterService minutes to initialTime
  const updatedTimeTotalMinutes = initialTimeTotalMinutes + filterService;

  // Calculate new hours and minutes
  let updatedHours = Math.floor(updatedTimeTotalMinutes / 60);
  let updatedMinutes = updatedTimeTotalMinutes % 60;

  // Adjust hours if necessary
  updatedHours = updatedHours % 24; // Ensure it stays within 24-hour range

  // Format updated time
  let updatedHoursStr;
  if (updatedHours === 0) {
    updatedHoursStr = "00";
  } else {
    updatedHoursStr = updatedHours < 10 ? String(updatedHours) : String(updatedHours);
  }
  const updatedMinutesStr = String(updatedMinutes).padStart(2, "0");
  const updatedTime = `${updatedHoursStr}:${updatedMinutesStr}`;

  return updatedTime;
}

export const filterUnavailableTimeSlots = (timeSlotsAccordingToServ, staffId, clientPetServiceDropdowns, timeBlockSlots) => {
  // Retrieve unavailable slots for the given staffId
  let unvailSlots = clientPetServiceDropdowns?.unavaliableSlots?.responseData?.result;
  let staffUnavailSlots = unvailSlots[staffId] || undefined;

  if (!staffUnavailSlots) {
    // return timeBlockSlots;
    return [];
  }
  // Convert unavailable slots to 24-hour format
  const unavailableSlotsFormatted = staffUnavailSlots.flatMap((slot) => ({
    start_time: convertTo24HourFormat(slot[0]),
    end_time: convertTo24HourFormat(slot[1]),
  }));

  // Generate available time slots based on the unavailable slots
  let checkAvailSlots = unavailableSlotsFormatted.flatMap((elem) => {
    return generateAvailableTimeSlots(elem.start_time || "", elem.end_time || "");
  });

  // Filter out slots from timeSlotsAccordingToServ that are not available
  const filteredSlots = timeSlotsAccordingToServ.filter((slot) => {
    // Check if slot.value is not in checkAvailSlots
    return !checkAvailSlots.some((availSlot) => availSlot.value === slot.value);
  });

  return filteredSlots;
};
