import React, { useEffect, useState } from 'react';
import { Table, Checkbox, Button, Divider } from 'antd';
import styled from "styled-components"
import CustomBtnWithIcon from '../../../../../components/buttonWithIcon';
import { main_api } from '../../../../../api/axios_helper';
import { companyAPIsEndPoints } from '../../../../../constants/ApiEndPoints';
import { useSelector } from 'react-redux';

export default function GeneralNotifications() {
  const {auth, permissionManagement } = useSelector((state) => state);
  const [notificationList, setNotificationList] = useState([]);
  const [dashboard, setDashboard] = useState(false);
  const [email, setEmail] = useState(false);
  const [app, setApp] = useState(false);
  const userInfo = auth?.loginResponse?.LoggedInUserData;
  const companyId = auth?.loginResponse?.selectCompany?.[0]?.company_id;

  const handleSaveChanges = () => {
    // Send settings to backend
  };

  const columns = [
    {
      title: 'All Notifications',
      dataIndex: 'message',
      key: 'message',
      width: '40%', // Set a fixed width for the column
    },
    {
        title: 'Dashboard',
        dataIndex: 'dashboard',
        align: 'center',
        render: (_, record) => <Checkbox checked={record?.dashboard ? record.dashboard : false} onChange={() => setDashboard(!record.dashboard)} />,
      },
      {
        title: 'Email',
        dataIndex: 'email',
        align: 'center',
        render: (_, record) => <Checkbox checked={record?.email ? record.email : false} onChange={() => setEmail(!record.email)} />,
      },
      {
        title: 'App',
        dataIndex: 'inapp',
        align: 'center',
        render: (_, record) => <Checkbox checked={record?.inapp ? record.inapp : false} onChange={() => setApp(!record.app)} />,
      },
  ];


  function transformData(apiData) {
    
    const transformedData = apiData.map((item, index) => {
      return {
        key: (index + 1).toString(),
        message: item.type.type, 
        dashboard: item.push_enabled, 
        email: item.email_enabled,
        inapp: item.inapp_enabled
      };
    });
    setNotificationList(transformedData); 
  }

  const getNotifications = () => {
    main_api.get(companyAPIsEndPoints.FECTH_USER_NOTIFICATION_SETTINGS(companyId))
    .then(({ data }) => {
      transformData(data.result);
    })
    .catch((error) => {
      console.log(error);
    });
  }
  useEffect(()=>{
    getNotifications()
  },[]);

  
  // const data = [
  //   {
  //     key: '1',
  //     allNotifications: 'Review and manage all the staff notifications visible to the clients and general.',
  //     dashboard: false,
  //     email: true,
  //     app: false,
  //   },
  //   {
  //     key: '2',
  //     allNotifications: 'Receive updates about new features and announcements.',
  //     dashboard: true,
  //     email: false,
  //     app: true,
  //   },
  //   {
  //     key: '3',
  //     allNotifications: 'Get notified about upcoming events and promotions.',
  //     dashboard: false,
  //     email: true,
  //     app: true,
  //   },
  // ];


  
 
  return (
    <div>
    
      <Table
        columns={columns}
        dataSource={notificationList}
        pagination={true}
        bordered
      />
        <Divider />
      <SaveContainer className="d-flex justify-content-end">
         <CustomBtnWithIcon btnText="Save Changes" type="submit" hideIcon={true} />
      </SaveContainer >
     
    </div>
  );
}


const SaveContainer = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  border-top: 1px solid #E0E0E0;
  background-color: white;
  padding: 10px 20px;
`;