import React, { useEffect, useState } from "react";

import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./calender.css";
import {
  SchedulerWhenDateicon,
  SchedulerServiceIcon,
  SchedulerAvatarClient,
  InvoiceIcon,
  ClientYellow,
} from "../../../../assets/rawSvgs/index";

import AntDesignModal from "../../../../components/Modals/AntDesignModal/AntDesignModal";
import { PerformActionIcon } from "../../../../assets/rawSvgs/index";
import HeadingComponent from "../../../../components/Heading";
import CustomBtnWithIcon from "../../../../components/buttonWithIcon";
import { Col, Row } from "antd";
import AntDesignDropDownSelect from "../../../../components/AntDesignDropDownSelect/AntDesignDropDownSelect";
import { convertTo12HourFormat } from "../../../../util/formatTime";
import { All12FormatTimeArray, dateFormatEndDate, dateFormatStartDate, generateAvailableTimeSlots } from "./utilsFunctions";
import { pushNotification } from "../../../../util/Notification";
import OneDayResourcesVerticalCalender from "../../../../components/CompanyOneDayVerticalScheduler/Index";
import { main_api } from "../../../../api/axios_helper";
import { companyAPIsEndPoints } from "../../../../constants/ApiEndPoints";
import { AntdesignTable } from "../../../../components/antDesignTable/AntdesignTable";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { checkPermission } from "../../../../util/commonUtil";
import PermissionModal from "../../../../components/Modals/PermissionModal";
import { useDispatch } from "react-redux";
import { fetchUnavaialbleSlotsApi } from "../../../../redux/slices/schedulerSlice";
import { filterUnavailableTimeSlots } from "../SchedulerEvents";

const DayViewWithResources = ({ staffState, calenderData, handleFetchPendingReq, schedulerState, fetchAllSchedulerService }) => {
  //
  const [openPerformActionModal, setOpenPerformActionModal] = useState({ condition: false, details: "" });
  const [serviceASignedModel, setServiceASignedModel] = useState(false);
  const [selectDataDate, setSelectDataDate] = useState("");
  const [staffIdFromCalender, setStaffIdFromCalender] = useState("");
  const [extractedServices, setextractedServices] = useState([]);
  const [collectServiceFromTable, setCollectServiceFromTable] = useState([]);
  const [apiLoader, setApiLoader] = React.useState(false);
  const [calenderEventsData, setcalenderEventsData] = React.useState([]);
  const [openDetailSlotModal, setOpenDetailSlotModal] = useState({ details: "", condition: false });
  const [permissionPopUp, setPermissionPopUp] = useState(false);
  const [minTimeState, setMinTimeState] = useState("");
  const [maxTimeState, setMaxTimeState] = useState("");
  const [calenderLoader, setCalenderLoader] = useState(false);
  const [scheduledEvents, setScheduledEvents] = useState([]);
  const [checkServiceStatus, setCheckServiceStatus] = useState(false);
  const [checkIfAllServAssign, setCheckIfAlServAssigned] = useState(false);
  const [unavaialbleEvents, setUnavaialbleEvents] = useState([]);

  const dispatch = useDispatch();
  //
  const compId = useSelector((state) => state.auth.loginResponse.selectCompany);
  const permissionManagement = useSelector((state) => state.permissionManagement);
  const [unavaliableSlots, setUnavaliableSlots] = useState(null);

  const staffResource = staffState?.map(({ id, name, ...rest }) => {
    return {
      ...rest,
      id: id,
      title: name,
    };
  });

  const defaultSlot = [["00:00:00", "23:59:00"]];
  const newUnavailableSlots = { ...unavaliableSlots };

  if (staffResource?.length > 0) {
    staffResource?.forEach((staff) => {
      if (!newUnavailableSlots.hasOwnProperty(staff?.id)) {
        newUnavailableSlots[staff?.id] = defaultSlot;
      }
    });
  }

  useEffect(() => {
    try {
      if (newUnavailableSlots) {
        const date = moment(selectDataDate).format("YYYY-MM-DD");
        const newEvents = Object.keys(newUnavailableSlots).reduce((acc, staffId) => {
          if (newUnavailableSlots[staffId]) {
            const staffEvents = newUnavailableSlots[staffId]?.map((slots) => {
              const [startTime, endTime] = slots;
              return {
                start: dateFormatStartDate(startTime, date),
                end: dateFormatEndDate(endTime, date),
                start_time: startTime,
                end_time: endTime,
                unavailableSlot: true,
                resourceId: Number(staffId),
              };
            });
            return acc.concat(staffEvents);
          }
        }, []);
        setUnavaialbleEvents(newEvents);
      }
    } catch (error) {
      console.log("error:", error);
    }
  }, [selectDataDate, unavaliableSlots]);

  useEffect(() => {
    if (selectDataDate !== "") {
      const fetchSlots = async () => {
        try {
          const payload = {
            date: selectDataDate,
          };
          const data = await dispatch(fetchUnavaialbleSlotsApi(payload));
          setUnavaliableSlots(data?.result);
        } catch (error) {
          console.error("Failed to fetch data:", error);
        }
      };
      fetchSlots();
    }
  }, [dispatch, selectDataDate]);

  useEffect(() => {
    const filterdate = calenderData?.groupOneDay?.filter((items) => {
      return items.date === selectDataDate;
    })[0];
    if (filterdate) {
      let extractSercies = filterdate?.one_day_service_request_single_services?.map((items) => {
        return items.service;
      });

      setextractedServices(filterdate?.one_day_service_request_single_services);
      const getAllTimes = filterdate?.one_day_service_request_single_services
        ?.filter((items) => items.status === "unassigned")
        ?.map((items) => {
          return {
            start:
              items.time_block && items.time_block.start_time ? convertTo24HourFormat(items.time_block.start_time) : "00:00:00",
            end: items.time_block && items.time_block.end_time ? convertTo24HourFormat(items.time_block.end_time) : "23:00:00",
          };
        });

      if (getAllTimes?.length === 0) {
        setCheckIfAlServAssigned(true);
      } else {
        setCheckIfAlServAssigned(false);
      }
      checkMinMaxTime(getAllTimes);
      setCalenderLoader(true);
    }
  }, [calenderData, selectDataDate, apiLoader]);

  useEffect(() => {
    if (selectDataDate === "") return;
    handleFetchAllSchedulerServices();
  }, [selectDataDate]);
  function checkMinMaxTime(data) {
    const timeToMinutes = (timeString) => {
      const [hours, minutes] = timeString.split(":").map(Number);
      return hours * 60 + minutes;
    };

    let minTime = Infinity;
    let maxTime = 0;

    data.forEach((event) => {
      const startTime = timeToMinutes(event.start);
      const endTime = timeToMinutes(event.end);

      if (startTime < minTime) {
        minTime = startTime;
      }

      if (endTime > maxTime) {
        maxTime = endTime;
      }
    });

    // Convert minutes back to time strings
    const formatTime = (minutes) => {
      const hours = Math.floor(minutes / 60);
      const mins = minutes % 60;
      return `${hours.toString().padStart(2, "0")}:${mins.toString().padStart(2, "0")}:00`;
    };
    if (minTime !== 0) {
      minTime -= 60; // Subtract 60 minutes (1 hour) from minTime
    }
    if (maxTime !== 1380) {
      maxTime += 60; // Add 60 minutes (1 hour) to maxTime
    }
    // Convert minimum and maximum times back to time strings

    minTime = formatTime(minTime);
    maxTime = formatTime(maxTime);

    setMinTimeState(minTime);
    setMaxTimeState(maxTime);
  }

  //

  const handleFetchAllSchedulerServices = async () => {
    try {
      setApiLoader(true);
      setCalenderLoader(false);
      const { data } = await main_api.get(
        `/api/v1/service_request/?service__company=${compId?.[0]?.company_id}&status=scheduled&status=in_progress&start_date=${selectDataDate}&end_date=${selectDataDate}`,
      );

      // get schee
      const calenderMappedData = data.result
        ?.filter((items) => items.status === "scheduled")
        ?.map((items) => {
          return {
            id: items.id,
            title: items?.service.name,
            start: dateFormatStartDate(items?.start_time, items.date),
            end: dateFormatEndDate(items?.end_time, items.date),
            resourceId: items?.staff.id,
            clientName: items?.client.name,
            status: items.status,
          };
        });

      setcalenderEventsData(calenderMappedData);

      const checkIfAllAssign = data.result?.some((items) => items.status === "unassigned");

      setCheckServiceStatus(checkIfAllAssign);
      const filterSchedulerEvents = data.result?.filter((items) => {
        return items.status === "scheduled";
      });

      setScheduledEvents(filterSchedulerEvents);

      setApiLoader(false);
    } catch (error) {
      setCalenderLoader(true);

      setApiLoader(false);
    }
  };

  const handleSelectSlot = (slotInfo) => {
    if (!slotInfo.unavailableSlot) {
      if (checkPermission(permissionManagement.permissions, "assign_service_request_staff")) {
        if (selectDataDate === "") {
          return pushNotification("Select date first before assigning service", "error");
        }
        setStaffIdFromCalender(slotInfo.resourceId);
        setOpenPerformActionModal({ condition: true, details: slotInfo });
      } else {
        setPermissionPopUp(true);
      }
    }
  };

  const handleEventClick = (slotInfo) => {
    if (!slotInfo.unavailableSlot) {
      const dateTime = dayjs(slotInfo.start);
      const formattedDate = dateTime.format("YYYY-MM-DD");

      const formattedTime = slotInfo.start.toLocaleTimeString("en-US", { hour: "numeric", minute: "numeric", hour12: true });

      setOpenDetailSlotModal({
        details: {
          staffId: slotInfo.resourceId,
          slotTime: formattedTime,
          date: formattedDate,
          serviceID: slotInfo.id,
          title: slotInfo.title,
        },
        condition: true,
      });
    }
  };

  const handleSelectServToAssign = (rowData) => {
    let checkIfNotAddedTime = false;
  };

  const convertTo24HourFormatv2 = (time12h) => {
    const parsedTime = moment(time12h, "h:mm A");

    const time24h = parsedTime.format("HH:mm");

    return time24h;
  };

  const addMinutesToTimev2 = (time24h, minutesToAdd) => {
    const [hours, minutes] = time24h.split(":").map(Number);
    let totalMinutes = hours * 60 + minutes + minutesToAdd;

    // Calculate new hour and minute
    const newHours = Math.floor(totalMinutes / 60) % 24; // Ensure 24-hour cycle
    const newMinutes = totalMinutes % 60;

    // Format the result
    const newTime = `${newHours.toString().padStart(2, "0")}:${newMinutes.toString().padStart(2, "0")}`;

    return newTime;
  };

  const handleAddStartTime = (record, val1) => {
    if (collectServiceFromTable?.length === 0) {
      pushNotification("Select Service first then assign time", "error");
      return;
    }
    setCollectServiceFromTable((prevState) => {
      return prevState?.map((element) => {
        if (element.id === record.id) {
          return {
            ...element,
            start_time: val1,
            checkboxCondition: !element.checkboxCondition,
            end_time: addMinutesToTimev2(convertTo24HourFormatv2(val1), record?.service?.default_duration),
          };
        }
        return { ...element };
      });
    });
  };

  // final object for api send

  // API request service
  const convertTo24HourFormat = (time12h) => {
    // Parse the time using moment
    const parsedTime = moment(time12h, "h:mm A");

    // Format the parsed time in 24-hour format
    const time24h = parsedTime.format("HH:mm");

    return time24h;
  };

  const handleAssignService = async () => {
    try {
      setApiLoader(true);
      const convertedData = collectServiceFromTable?.map((item) => ({
        ...item,
        start_time: convertTo24HourFormat(item?.start_time),
        end_time: convertTo24HourFormat(item?.end_time), // Convert end time as well
      }));

      // final obect

      let incomData = convertedData
        ?.map((elements) => {
          return {
            staff: staffIdFromCalender,
            start_time: elements.start_time,
            end_time: elements.end_time,
            id: elements?.id,
          };
        })
        .filter((items) => items);

      let obj = {
        service_requests: [...incomData],
      };

      const areTimesValid = (services) => {
        function isValidTime(time) {
          return time !== "Invalid Date" && !/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(time);
        }

        for (const service of services) {
          if (isValidTime(service.start_time) || isValidTime(service.end_time)) {
            return false;
          }
        }
        return true;
      };

      if (!areTimesValid(incomData)) {
        pushNotification("Please select valid time before assigning service", "error");
        setApiLoader(false);
        return;
      }

      if (incomData?.length > 0) {
        const { data } = await main_api.post(companyAPIsEndPoints.ASSIGN_PENDING_REQUEST, obj);
        if (data.isSuccess) {
          pushNotification("Visit assign successfully", "success");
          handleFetchAllSchedulerServices();
          setServiceASignedModel(false);
          handleFetchPendingReq();
          fetchAllSchedulerService("", "status=scheduled&status=unassigned&status=completed");
          setCollectServiceFromTable([]);
        }
      }
      setApiLoader(false);
    } catch (error) {
      setApiLoader(false);
      console.log("Error", error);
    }
  };

  const assignServObject = {
    handleAddStartTime: handleAddStartTime,
    serviceData: extractedServices,
    handleAssignService,
    setCollectServiceFromTable,
    collectServiceFromTable,
    apiLoader,
    staffName: staffResource?.filter((items) => items.id === staffIdFromCalender),
    staffId: staffIdFromCalender,
    unavaliableSlots,
  };

  function adjustTimeArray(timeArray) {
    // Parse the hour, minute, and second from the array
    let [hours, minutes, seconds] = timeArray?.map(Number);

    // Adjust hours if it exceeds 23
    if (hours > 23) {
      hours = 23;
    }

    // Adjust minutes if it exceeds 59
    if (minutes > 59) {
      minutes = 59;
    }

    // Return the adjusted time array as strings
    return [hours?.toString()?.padStart(2, "0"), minutes?.toString()?.padStart(2, "0"), seconds?.toString()?.padStart(2, "0")];
  }

  let minTimeSplit = minTimeState?.split(":");
  let maxTimeSplit = maxTimeState?.split(":");
  let dateSplit = selectDataDate?.split("-");

  if (maxTimeSplit) {
    maxTimeSplit = adjustTimeArray(maxTimeSplit);
  }

  return (
    <div>
      <div className=" d-flex justify-content-start mb-3 align-items-center">
        <HeadingComponent text={"Filter Services by Date"} fontSize="0.875rem" fontWeight={400} margin="0px 5px" />
        <AntDesignDropDownSelect
          options={
            calenderData?.dates?.map((items) => {
              return {
                label: items,
                value: items,
              };
            }) || []
          }
          width="200px"
          defaultValue={"Select Date"}
          placeholder="Select Date"
          className="ms-2"
          onChange={(e) => setSelectDataDate(e)}
        />
      </div>
      <>
        {calenderLoader && !checkIfAllServAssign && (
          <OneDayResourcesVerticalCalender
            staffResource={staffResource}
            events={[...calenderEventsData, ...unavaialbleEvents]}
            handleEventClick={handleEventClick}
            handleSelectSlot={handleSelectSlot}
            // isFiltering={apiLoader}
            date={selectDataDate ? moment(selectDataDate).toDate() : new Date()}
            startAccessor="start"
            endAccessor="end"
            resourceIdAccessor="id"
            resourceTitleAccessor="title"
            max={new Date(dateSplit[0], dateSplit[1] - 1, dateSplit[2], maxTimeSplit[0], 59)}
            min={new Date(dateSplit[0], dateSplit[1] - 1, dateSplit[2], minTimeSplit[0], minTimeSplit[1])}
            onNavigate={(date) => {
              console.log("Navigated to:", date);
            }}
            // date={AllStates.dayChangeState?.dayRange.start}
          />
        )}

        {checkIfAllServAssign && calenderLoader && (
          <HeadingComponent
            text={selectDataDate === "" ? "" : "All services assigned in this date..."}
            fontSize="15px"
            margin={"15px 0px"}
            fontWeight={400}
          />
        )}
      </>

      {serviceASignedModel && (
        <ServiceAssignedModal
          handleSelectServToAssign={handleSelectServToAssign}
          open={serviceASignedModel}
          handleClose={() => {
            setCollectServiceFromTable([]);
            setServiceASignedModel(false);
          }}
          {...assignServObject}
        />
      )}

      {/* perform aciton modal */}

      <PerformConfirmModal
        open={openPerformActionModal.condition}
        handleClose={() => setOpenPerformActionModal({ ...openPerformActionModal, condition: false })}
        openAssignModoal={() => {
          setOpenPerformActionModal({ ...openPerformActionModal, condition: false });
          setServiceASignedModel(true);
        }}
      />

      {openDetailSlotModal.condition && (
        <ShowEventModal
          openDetailSlotModal={openDetailSlotModal.condition}
          handleCancel={() => {
            setOpenDetailSlotModal({ ...openDetailSlotModal, condition: false });
          }}
          eventDetails={scheduledEvents?.filter((items) => items.id === openDetailSlotModal.details?.serviceID)[0]}
          serviceState={schedulerState}

          // handleEditEvent={handleEditEvent}
        />
      )}
      <PermissionModal onclose={() => setPermissionPopUp(false)} openModal={permissionPopUp} />
    </div>
  );
};

export default DayViewWithResources;

const ServiceAssignedModal = ({
  open,
  handleClose,
  serviceData,
  handleSelectServToAssign,
  handleAddStartTime,
  handleAssignService,
  setCollectServiceFromTable,
  collectServiceFromTable,
  apiLoader,
  staffName,
  staffId,
  unavaliableSlots,
}) => {
  const handleCollectService = ({ rowKeys, SelctRows }) => {
    setCollectServiceFromTable((prevSt) => {
      // Filter out the rows that are unchecked (removed)
      const updatedRows = prevSt.filter((row) => rowKeys.includes(row.id));

      // Filter out the rows that are already present in the state
      const newRows = SelctRows.filter((newRow) => !updatedRows.some((row) => row.id === newRow.id));

      // Combine the updated rows with the new selected rows
      return [...updatedRows, ...newRows];
    });
  };

  const filterUnAssigendServices = serviceData?.filter((items) => items.status === "unassigned");

  return (
    <div>
      <AntDesignModal
        title={"Assign a Service"}
        open={open}
        style={{ top: 20, overflow: "auto", minWidth: "1200px" }}
        onCancel={handleClose}
        component={
          <div>
            <HeadingComponent
              text={`Select the services you want to assign to ${staffName?.[0].title || "---"}`}
              fontSize="14px"
              fontWeight={400}
              fontColor="#212121"
              margin={"15px 0px"}
            />

            <AntdesignTable
              bordered
              columns={tableCols(
                handleSelectServToAssign,
                handleAddStartTime,
                collectServiceFromTable,
                staffId,
                unavaliableSlots,
              )}
              dataSource={filterUnAssigendServices || []}
              pagination={false}
              rowKey="id"
              selectedRows={({ rowKeys, SelctRows }) => handleCollectService({ rowKeys, SelctRows })}
              allowMultieSelectRows={true}
            />
          </div>
        }
        footer={
          <div>
            <div className="d-flex justify-content-end mt-4">
              <CustomBtnWithIcon
                btnText={"Cancel"}
                margin="0px 5px"
                noBackground
                hideIcon={true}
                width="100px"
                onClick={(e) => {
                  e.preventDefault();
                  handleClose();
                }}
              />
              <CustomBtnWithIcon
                btnText={"Assign"}
                hideIcon={true}
                onClick={(e) => {
                  e.preventDefault();
                  handleAssignService();
                }}
                className="ms-3"
                width="100px"
                // disabled={apiLoader || collectServiceFromTable?.length === 0}
              />
            </div>
          </div>
        }
      />
    </div>
  );
};

export const tableCols = (checkboxSelect, handleAddStartTime, collectServiceFromTable, staffId, unavaliableSlots) => {
  // timeSlotsAccordingToServ, staffId, clientPetServiceDropdowns, timeBlockSlots
  let clientPetServiceDropdowns = {
    unavaliableSlots: {
      responseData: {
        result: unavaliableSlots,
      },
    },
  };

  let removeUnavailSlots = (record) => {
    let slots = generateAvailableTimeSlots(record?.time_block?.start_time || "", record?.time_block?.end_time || "");

    let slotsList = filterUnavailableTimeSlots(slots, staffId, clientPetServiceDropdowns, []);

    return slotsList;
  };
  return [
    {
      title: "Service Name",
      dataIndex: "name",

      render: (text, record) => <a>{record?.service?.name}</a>,
    },
    {
      title: "Requested Date",
      dataIndex: "date",
    },
    {
      title: "Scheduled From",
      dataIndex: "address",
      wdith: 200,
      render: (text, record) => {
        return (
          <>
            {record?.time_block?.name === "Anytime" ? (
              "Any time slot"
            ) : (
              <>
                {record?.time_block?.start_time && record?.time_block?.end_time ? (
                  <>
                    {convertTo12HourFormat(record?.time_block?.start_time)} -{" "}
                    {convertTo12HourFormat(record?.time_block?.end_time)}
                  </>
                ) : (
                  "Not Available"
                )}
              </>
            )}
          </>
        );
      },
    },

    {
      title: "Select Available Slot",
      dataIndex: "address",
      wdith: 200,
      render: (text, record) => {
        return (
          <>
            {record?.time_block?.name === "Anytime" || record?.time_block === null ? (
              <AntDesignDropDownSelect
                onChange={(e, val) => {
                  handleAddStartTime(record, e, val);
                }}
                placeholder="Select Time Slot"
                defaultValue="Select Time Slot"
                options={removeUnavailSlots(record) || []}
                value={
                  collectServiceFromTable?.some((item) => item.id === record.id) &&
                  collectServiceFromTable.find((item) => item.id === record.id).start_time === "Invalid Date"
                    ? "Default Value" // Set your default value here
                    : collectServiceFromTable?.some((item) => item.id === record.id)
                      ? All12FormatTimeArray.find((item) => item.id === record.id)?.value // Set defaultValue based on the filter condition
                      : "Select Time Slot"
                }
                disabled={collectServiceFromTable?.some((item) => item.id === record.id) ? false : true}
              />
            ) : (
              <>
                <AntDesignDropDownSelect
                  onChange={(e, val) => {
                    handleAddStartTime(record, e, val);
                  }}
                  placeholder="Select Time Slot"
                  // defaultValue="Select Time Slot"
                  disabled={collectServiceFromTable?.some((item) => item.id === record.id) ? false : true}
                  value={
                    collectServiceFromTable?.some((item) => item.id === record.id) &&
                    collectServiceFromTable.find((item) => item.id === record.id).start_time === "Invalid Date"
                      ? "Default Value" // Set your default value here
                      : collectServiceFromTable?.some((item) => item.id === record.id)
                        ? All12FormatTimeArray.find((item) => item.id === record.id)?.value // Set defaultValue based on the filter condition
                        : "Select Time Slot"
                  }
                  options={removeUnavailSlots(record) || []}
                />
              </>
            )}
          </>
        );
      },
    },
  ];
};

const PerformConfirmModal = ({ open, handleClose, openAssignModoal }) => {
  return (
    <>
      <AntDesignModal
        title={""}
        open={open}
        onCancel={handleClose}
        width="500px"
        component={
          <div className="d-flex flex-column align-items-center">
            <PerformActionIcon />
            <HeadingComponent
              text={"Are you Sure you want to Perform this action"}
              fontSize="1.25rem"
              fontWeight={700}
              margin="20px 0px"
            />
            <HeadingComponent text={" You’ll not be able to undo it later"} fontSize="0.875rem" fontWeight={400} />
          </div>
        }
        footer={
          <>
            <div className="d-flex justify-content-center mt-4">
              <CustomBtnWithIcon
                btnText={"No"}
                margin="0px 5px"
                noBackground
                hideIcon={true}
                width="100px"
                onClick={(e) => {
                  handleClose();
                }}
              />
              <CustomBtnWithIcon
                btnText={"Yes"}
                hideIcon={true}
                onClick={(e) => {
                  openAssignModoal();
                }}
                className="ms-3"
                width="100px"
              />
            </div>
          </>
        }
      />
    </>
  );
};

const ShowEventModal = ({ openDetailSlotModal, handleCancel, eventDetails, handleEditEvent }) => {
  let filter = eventDetails;
  return (
    <>
      <AntDesignModal
        openModal={openDetailSlotModal}
        closeModal={handleCancel}
        onCancel={handleCancel}
        onOk={handleCancel}
        width={500}
        title={
          <HeadingComponent
            text={`${convertTo12HourFormat(filter?.start_time)} - ${convertTo12HourFormat(filter?.end_time)}`}
            fontSize="16px"
            fontWeight={700}
            color="#212121"
          />
        }
        component={
          <Row gutter={10}>
            <Col lg={15} className="d-flex  align-items-end my-2">
              <InvoiceIcon className="me-3 ms-1" stroke="#FBCF34" />

              <HeadingComponent text={"Invoice: #1234213"} fontSize="14px" fontWeight={500} color="#424242" />
            </Col>
            <Col lg={15} className="d-flex  align-items-end my-2">
              <SchedulerServiceIcon className="me-3" stroke="#FBCF34" />

              <HeadingComponent
                text={`Service: ${filter.service.name || "---"}`}
                fontSize="14px"
                fontWeight={500}
                color="#424242"
              />
            </Col>
            <Col lg={15} className="d-flex  align-items-end my-2">
              <SchedulerWhenDateicon className="me-3" stroke="#FBCF34" />

              <HeadingComponent text={`When: ${filter?.date}`} fontSize="14px" fontWeight={500} color="#424242" />
            </Col>{" "}
            <Col lg={15} className="d-flex  align-items-end my-2">
              <ClientYellow className="me-3 ms-1" stroke="#FBCF34" />

              <HeadingComponent
                text={`Staff: ${filter?.staff?.name}`}
                fontSize="14px"
                className="ms-1"
                fontWeight={500}
                color="#424242"
              />
            </Col>
            <Col lg={15} className="d-flex align-items-end  my-2">
              <SchedulerAvatarClient className="me-3" stroke="#FBCF34" />
              <HeadingComponent text={`Client: ${filter?.client?.name}`} fontSize="14px" fontWeight={500} color="#424242" />
            </Col>
          </Row>
        }
        footer={
          <div className="d-flex justify-content-end">
            <CustomBtnWithIcon btnText={"Close"} hideIcon className="me-2" width="90px" onClick={handleCancel} />
          </div>
        }
      />
    </>
  );
};
// rowSelection object indicates the need for row selection
