import React, { useState, useEffect } from 'react';
import { Select, Pagination, Spin } from 'antd';

const { Option } = Select;

export const PaginatedSelect = ({ fetchOptions, placeholder="Select Option", value, setValue  }) => {
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [total, setTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
  
    // Fetch options when component mounts and when page changes
    useEffect(() => {
      const loadOptions = async () => {
        setLoading(true);
        const { data, total } = await fetchOptions(currentPage);
        setOptions(data);
        setTotal(total);
        setLoading(false);
      };
  
      loadOptions();
    }, [currentPage, fetchOptions]);
  
    // Handle page change
    const handlePageChange = (page) => {
      setCurrentPage(page);
    };
  
    return (
      <div>
        <Select
          allowClear
          style={{ width: '100%' }}
          placeholder={placeholder}
          loading={loading}
          onChange={setValue}
          value={value}
          dropdownRender={menu => (
            <div>
              {menu}
              <div style={{ display: 'flex', justifyContent: 'center', padding: '8px' }}>
                <Pagination
                  current={currentPage}
                  pageSize={10}
                  total={total}
                  onChange={handlePageChange}
                />
              </div>
            </div>
          )}
        >
          {options.map(option => (
            <Option key={option.value} value={option.value}>
              {option.label}
            </Option>
          ))}
        </Select>
        {loading && <Spin />}
      </div>
    );
  };