import { createSlice } from "@reduxjs/toolkit";
import { main_api } from "../../api/axios_helper";
import { companyAPIsEndPoints } from "../../constants/ApiEndPoints";

const initialState = {
  serviceGetResponse: {
    isLoading: false,
    responseData: [],
    serviceList: [],
  },
  holidayGetResponse: {
    isLoading: false,
    responseData: [],
  },
  serviceGroupGetResponse: {
    isLoading: false,
    responseData: [],
  },
};

const serviceSlice = createSlice({
  name: "service module",
  initialState,
  reducers: {
    getAllServicesSlice(state, action) {
      state.serviceGetResponse.isLoading = action.payload.isLoading;
      if (action.payload?.responseData) {
        const servceList = action.payload?.responseData?.result.map((items) => {
          return {
            value: items.id,
            label: items.name,
          };
        });

        state.serviceGetResponse.responseData = action.payload?.responseData;
        state.serviceGetResponse.serviceList = servceList;
      }
    },
    getAllHolidaysSlice(state, action) {
      state.holidayGetResponse.isLoading = action.payload.isLoading;
      if (action.payload?.responseData) {
        state.holidayGetResponse.responseData = action.payload?.responseData;
      }
    },
    getAllServiceGroupSlice(state, action) {
      state.serviceGroupGetResponse.isLoading = action.payload.isLoading;
      if (action.payload?.responseData) {
        state.serviceGroupGetResponse.responseData = action.payload?.responseData;
      }
    },
  },
});

export const { getAllServicesSlice } = serviceSlice.actions;

export default serviceSlice.reducer;

export const fetchServiceApi = (searchTerm = "") => {
  return async (dispatch, getState) => {
    try {
      const companyId = getState().auth?.loginResponse?.selectCompany?.[0]?.company_id;

      dispatch(serviceSlice.actions.getAllServicesSlice({ isLoading: true }));
      const { data } = await main_api.get(companyAPIsEndPoints.GET_ALL_SERVICES(companyId, searchTerm));
      dispatch(serviceSlice.actions.getAllServicesSlice({ isLoading: false, responseData: data }));
    } catch (error) {
      dispatch(serviceSlice.actions.getAllServicesSlice({ isLoading: false, responseData: [] }));
    }
  };
};

export const fetchHolidaysApi = (searchTerm = "") => {
  return async (dispatch, getState) => {
    try {
      dispatch(serviceSlice.actions.getAllHolidaysSlice({ isLoading: true }));
      const { data } = await main_api.get(companyAPIsEndPoints.GET_ALL_HOLIDAYS(searchTerm));
      dispatch(serviceSlice.actions.getAllHolidaysSlice({ isLoading: false, responseData: data }));
    } catch (error) {
      dispatch(serviceSlice.actions.getAllHolidaysSlice({ isLoading: false, responseData: [] }));
    }
  };
};

export const fetchServiceGroupApi = () => {
  return async (dispatch, getState) => {
    try {
      const companyId = getState().auth?.loginResponse?.selectCompany?.[0]?.company_id;
      dispatch(serviceSlice.actions.getAllServiceGroupSlice({ isLoading: true }));
      const { data } = await main_api.get(companyAPIsEndPoints.SERVICE_GET_GROUP(companyId));
      dispatch(serviceSlice.actions.getAllServiceGroupSlice({ isLoading: false, responseData: data }));
    } catch (error) {
      dispatch(serviceSlice.actions.getAllServiceGroupSlice({ isLoading: false, responseData: [] }));
    }
  };
};
