import React, { useEffect, useState } from "react";
import AntDesignModal from "../../../../components/Modals/AntDesignModal/AntDesignModal";
import HeadingComponent from "../../../../components/Heading";
import { Col, Row } from "antd";
import CustomBtnWithIcon from "../../../../components/buttonWithIcon";
import AntDesignDropDownSelect from "../../../../components/AntDesignDropDownSelect/AntDesignDropDownSelect";
import { invoicingClaimHeaders, invoicingDetailService } from "../../../../constants/CompanyTableHeaders/invoicingTableHeaders";
import { AntdesignTable } from "../../../../components/antDesignTable/AntdesignTable";

import { main_api } from "../../../../api/axios_helper";
import { companyAPIsEndPoints } from "../../../../constants/ApiEndPoints";
import { pushNotification } from "../../../../util/Notification";

//
const AddServiceToInvoiceModal = ({
  openModal,
  closeModal,
  invoiceId,
  invoicingState,

  invoicingLoader,
  compId,
  clientId,
  fetchSingleInvoice,
}) => {
  const [getSingleInvoiceData, setGetSingleInvoiceData] = useState([]);
  const [getLoader, setGetLoader] = useState(false);
  const [selectRowsToClaim, setSelectClaimRows] = useState([]);
  const [selectInvoiceId, setselectInvoiceId] = useState([]);
  const [allInvoicesClientwise, setAllInvoicesClientwise] = useState([]);

  useEffect(() => {
    handleFetchAllInvoices();
  }, []);
  const getAllInvoiceIds = allInvoicesClientwise
    ?.filter((item) => item.id !== invoiceId)
    .map((item) => ({
      label: "Invoice# " + item.id,
      value: item.id,
    }));

  const handleFetchAllInvoices = async () => {
    try {
      setGetLoader(true);
      const { data } = await main_api.get(`/api/v1/invoice/?company=${compId}&client=${clientId}`, {
        company: compId,
      });

      setAllInvoicesClientwise(data.result);
      setGetLoader(false);
    } catch (error) {
      setGetLoader(false);

      console.log("data SINGLE", error);
    }
  };

  const handleFetchIdWiseInvoice = async (id) => {
    try {
      setGetLoader(true);
      const { data } = await main_api.post(companyAPIsEndPoints.GET_SINGLE_INVOICES(id), {
        company: compId,
      });

      setGetSingleInvoiceData(data.result);
      setGetLoader(false);
    } catch (error) {
      setGetLoader(false);

      console.log("data SINGLE", error);
    }
  };

  const handleClaimServices = async () => {
    try {
      setGetLoader(true);
      let filterIdsSingleServReq = selectRowsToClaim?.map((items) => items.single_service_request.id);

      let obj = {
        company: compId,
        current_invoice_id: invoiceId,
        claim_invoice_id: parseInt(selectInvoiceId),
        single_service_request_claim_lists: [...filterIdsSingleServReq],
        client: clientId,
      };
      const { data } = await main_api.post(companyAPIsEndPoints.CLAIM_INVOICE, obj);
      setGetLoader(false);
      if (data.isSuccess) {
        pushNotification("Invoice Claimed successfully");
        fetchSingleInvoice();
        handleFetchAllInvoices();
        closeModal();
      }
    } catch (error) {
      setGetLoader(false);
      console.log("data SINGLE", error);
    }
  };
  return (
    <div>
      <AntDesignModal
        openModal={openModal}
        closeModal={closeModal}
        onCancel={closeModal}
        onOk={closeModal}
        width={750}
        title={"Claim Individual Service"}
        component={
          <Row gutter={10}>
            <Col md={24} className=" ">
              <HeadingComponent
                text={
                  "Using this, you can claim previously scheduled services into this invoice. Claiming a service into this invoice will remove it from its previous invoice."
                }
                fontSize="14px"
                fontWeight={400}
                color="#212121"
              />
              <HeadingComponent
                text={`Invoice # ${selectInvoiceId}`}
                fontSize="14px"
                fontWeight={500}
                color="#212121"
                margin={"10px 0px"}
              />
            </Col>
            <Col md={15} className="">
              <AntDesignDropDownSelect
                options={getAllInvoiceIds || []}
                className="w-50"
                defaultValue="Select Invoice"
                onChange={(id) => {
                  setselectInvoiceId(id);
                  handleFetchIdWiseInvoice(id);
                }}
              />
            </Col>
            <Col md={24} className="mt-3">
              <AntdesignTable
                columns={invoicingDetailService({})}
                data={getSingleInvoiceData || []}
                allowMultieSelectRows
                selectedRows={({ SelctRows, rowKeys }) => {
                  setSelectClaimRows(SelctRows);
                }}
                loading={getLoader}
                rowKey="id"
                // loading={invoicingState?.isLoading}
              />
            </Col>
          </Row>
        }
        footer={
          <div className="d-flex justify-content-end">
            <>
              <CustomBtnWithIcon btnText={"Cancel"} hideIcon noBackground className="me-2" width="90px" onClick={closeModal} />
              <CustomBtnWithIcon
                btnText={"Claim Service"}
                hideIcon
                className="me-2"
                disabled={selectRowsToClaim?.length === 0 || getLoader}
                onClick={handleClaimServices}
              />
            </>
          </div>
        }
      />
    </div>
  );
};

export default AddServiceToInvoiceModal;
