import { Checkbox, Col, Drawer, Row, Segmented, Select, Radio, TimePicker, DatePicker, Divider } from "antd";
import React, { useEffect, useState } from "react";
import locale from "antd/es/date-picker/locale/de_DE";
import { Formik, Field, Form, FieldArray, useFormikContext } from "formik";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
//
import { AntdesignTable } from "../../../../../components/antDesignTable/AntdesignTable";
import CustomBtnWithIcon from "../../../../../components/buttonWithIcon";
import SearchInput from "../../../../../components/SearchInput";
import { staffDetailsTableHeaders } from "../../../../../constants/CompanyTableHeaders/StaffManagementHeaders";
import HeadingComponent from "../../../../../components/Heading";
import AntDesignDropDownSelect from "../../../../../components/AntDesignDropDownSelect/AntDesignDropDownSelect";
import AntDesignModal from "../../../../../components/Modals/AntDesignModal/AntDesignModal";
import FormikInputFloating from "../../../../../components/formikInputs/TextInput";
//
import { FilterIcon, CloseIcon, DeleteIcon } from "../../../../../assets/rawSvgs/index";
import { ReactComponent as PlusYellowIcon } from "../../../../../assets/rawSvgs/yelowplus.svg";
import { ReactComponent as RemoveYellowIcon } from "../../../../../assets/rawSvgs/declineIcon.svg";

import { main_api } from "../../../../../api/axios_helper";
import { companyAPIsEndPoints, staffAPIsEndPoints } from "../../../../../constants/ApiEndPoints";
import { pushNotification } from "../../../../../util/Notification";
import { DifferntTimeEachDayForm } from "./DifferentDayForm";
import { useDebounce } from "../../../../../hooks/useDebounce";
import MonthCalender from "./calenders/MonthCalender";
import moment from "moment";
import WeekCalender from "./calenders/WeekCalender";
import ConfigUpdateModal from "./availupdateForms/UpdateConfigAvail";
import { checkPermission } from "../../../../../util/commonUtil";
import { useSelector } from "react-redux";
import PermissionModal from "../../../../../components/Modals/PermissionModal";

//
const AvailabilityComponent = ({ configUpdateFormDetails, companyId, fetchSingleStaff }) => {
  const [SelectTab, setSelectTab] = React.useState("List");
  const [permissionPopUp, setPermissionPopUp] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [DrawerModal, setDrawerModal] = useState(false);
  const [configureModal, setConfigureModal] = useState(false);
  const debouncedSearchTerm = useDebounce(searchTerm, 200);
  const [staffServiceReqData, setStaffServiceReqData] = useState([]);
  const [servGetLoader, setServGetLoader] = useState(false);
  const { permissionManagement } = useSelector((state) => state);
  const [dateRange, setDateRange] = useState({
    start: moment().startOf("month").toDate(),
    end: moment().endOf("month").toDate(),
  });
  const [dateRangeWeek, setDateRangeWeek] = useState({
    start: moment().startOf("week").toDate(),
    end: moment().endOf("week").toDate(),
  });
  useEffect(() => {
    const startOfMonth = moment().startOf("month");

    const startDateOfWeek = moment(startOfMonth).startOf("week");

    const endDateOfWeek = moment(startDateOfWeek).add(6, "days");

    setDateRangeWeek({
      start: startDateOfWeek.toDate(),
      end: endDateOfWeek.toDate(),
    });
  }, [SelectTab]);
  let { staffId } = useParams();

  const formattedMonthStartDate = moment(dateRange.start).startOf("month").format("YYYY-MM-DD");
  const formattedMonthEndDate = moment(dateRange.end).endOf("month").format("YYYY-MM-DD");

  useEffect(() => {
    if (SelectTab === "Month") {
      handleFetchspecificserReq(formattedMonthStartDate, formattedMonthEndDate);
    }
    if (SelectTab === "List") {
      handleFetchspecificserReq();
    }
    if (SelectTab === "Week") {
      handleFetchspecificserReq(moment(dateRangeWeek.start).format("YYYY-MM-DD"), moment(dateRangeWeek.end).format("YYYY-MM-DD"));
    }
  }, [staffId, debouncedSearchTerm, dateRange, dateRangeWeek]);

  const handleFetchspecificserReq = async (startDate = "", endDate = "") => {
    if (checkPermission(permissionManagement?.permissions, "view_service_requests")) {
      try {
        setServGetLoader(true);

        const { data } = await main_api.get(
          `/api/v1/service_request/?service__company=${companyId}&status=scheduled&status=in_progress&status=completed&start_date=${startDate}&end_date=${endDate}&staff=${staffId}&search=${searchTerm}`,
        );
        setServGetLoader(false);

        setStaffServiceReqData(data.result);
      } catch (error) {
        setServGetLoader(false);
      }
    }
  };

  const editStaffAvalibility = () => {
    if (checkPermission(permissionManagement?.permissions, "edit_staff")) {
      setConfigureModal(true);
    } else {
      setPermissionPopUp(true);
    }
  };

  let monthObject = {
    calenderTypeDropDown: SelectTab,
    handleFetchspecificserReq,
    monthDateState: { dateRange, setDateRange },
    weekChangeState: { dateRangeWeek, setDateRangeWeek },
    loading: servGetLoader,
  };

  const handleDelete = () => {};
  const handleEditRow = () => {};
  const availRenderType = {
    List: (
      <AntdesignTable
        loading={servGetLoader}
        columns={staffDetailsTableHeaders({ handleDelete, handleEditRow })}
        data={staffServiceReqData || []}
      />
    ),
    Month: <MonthCalender {...monthObject} schedulerState={staffServiceReqData} />,
    Week: <WeekCalender {...monthObject} schedulerState={staffServiceReqData} />,
  };
  return (
    <div>
      <Row align={"middle"} gutter={10}>
        <Col md={5}>
          <Segmented
            style={{ marginBottom: 8, background: "rgb(238,238,238,1)" }}
            onChange={(value) => setSelectTab(value)}
            options={["List", "Month", "Week"]}
            className=" p-1 "
            defaultValue="List"
            block
            size="large"
          />
        </Col>

        <Col md={4} className="mb-2" style={{ visibility: SelectTab === "List" ? "visible" : "hidden" }}>
          <SearchInput onChange={(value) => setSearchTerm(value)} placeholder="Type to search..." containerBorder={"0"} />
        </Col>

        <Col md={15} className="d-flex justify-content-end">
          <CustomBtnWithIcon
            btnText={
              <div className="d-flex align-content-center ">
                <div className="">
                  <FilterIcon />
                </div>
                <span className="ms-2">More Filters</span>
              </div>
            }
            hideIcon
            noBackground
            margin="0px 10px"
            onClick={() => setDrawerModal(true)}
          />
          <CustomBtnWithIcon
            btnText={"Configure Staff Availability"}
            hideIcon
            noBackground
            onClick={() => editStaffAvalibility()}
          />
        </Col>
      </Row>

      <div className="mt-3">{availRenderType[SelectTab]}</div>
      <FilterMemo close={() => setDrawerModal(false)} DrawerModal={DrawerModal} />
      {configureModal && (
        <ConfigureStaffAvailComponent
          open={configureModal}
          close={() => {
            setConfigureModal(false);
          }}
          fetchSingleStaff={fetchSingleStaff}
          updateValues={configUpdateFormDetails}
        />
      )}
      <PermissionModal onclose={() => setPermissionPopUp(false)} openModal={permissionPopUp} />
    </div>
  );
};

export default AvailabilityComponent;

const MoreFilterComponent = ({ close, DrawerModal }) => {
  const [client, setClient] = useState("");
  const [services, setServices] = useState("");
  const [viewOptions, setViewOptions] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);

  const handleClientChange = (value) => {
    setClient(value);
  };

  const handleServicesChange = (value) => {
    setServices(value);
  };

  const handleViewChange = (checkedValues) => {
    setViewOptions(checkedValues);
  };

  const handleStatusChange = (checkedValues) => {
    setStatusOptions(checkedValues);
  };

  const handleSubmit = () => {
    console.log("Client:", client);
    console.log("Services:", services);
    console.log("View Options:", viewOptions);
    console.log("Status Options:", statusOptions);
  };
  return (
    <Drawer
      placement={"right"}
      title={
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <h5>Filters</h5>
          </div>
          <div className="cursorPointer" onClick={close}>
            <CloseIcon />
          </div>
        </div>
      }
      closable={false}
      onClose={close}
      open={DrawerModal}
      key={"right"}
      header={null}
      footer={
        <div className="d-flex justify-content-end">
          <CustomBtnWithIcon btnText={"Cancel"} hideIcon noBackground className="me-2" onClick={close} />
          <CustomBtnWithIcon btnText={"Schedule"} hideIcon noBackground={false} onClick={handleSubmit} />
        </div>
      }
    >
      <div className="my-2">
        <HeadingComponent text="Client" fontSize={"12px"} margin="10px 0px" />
        <AntDesignDropDownSelect
          placeholder="Select Client"
          options={[]}
          defaultValue="Select Client"
          onChange={handleClientChange}
        />
      </div>
      <div className="my-2">
        <HeadingComponent text="Services" fontSize={"12px"} margin="10px 0px" />
        <AntDesignDropDownSelect
          placeholder="Select Client"
          options={[]}
          defaultValue="Select Client"
          onChange={handleServicesChange}
        />
      </div>
      <div className="d-flex flex-column">
        <HeadingComponent text="View" fontSize={"12px"} margin="10px 0px" />

        <Checkbox className="my-1" onChange={handleViewChange}>
          Available Slots
        </Checkbox>
        <Checkbox className="my-1" onChange={handleViewChange}>
          Scheduled Slots
        </Checkbox>
        <Checkbox className="my-1" onChange={handleViewChange}>
          Booked Slots
        </Checkbox>
      </div>
      <div className="d-flex flex-column">
        <HeadingComponent text="Status" fontSize={"12px"} margin="10px 0px" />

        <Checkbox className="my-1" onChange={handleStatusChange}>
          In Progress
        </Checkbox>
        <Checkbox className="my-1" onChange={handleStatusChange}>
          Completed
        </Checkbox>
        <Checkbox className="my-1" onChange={handleStatusChange}>
          Scheduled
        </Checkbox>
        <Checkbox className="my-1" onChange={handleStatusChange}>
          Cancelled
        </Checkbox>
      </div>
    </Drawer>
  );
};

export const ConfigureStaffAvailComponent = ({ fetchSingleStaff, close, open, updateValues, fromStaffManagementStaffId }) => {
  const [SelectTab, setSelectTab] = React.useState("Same time for each day");
  const [submitFormLoader, setSubmitFormLoader] = React.useState(false);

  let { staffId } = useParams() || fromStaffManagementStaffId;
  const formikRef = React.useRef();
  staffId = staffId !== undefined ? staffId : fromStaffManagementStaffId;
  return (
    <AntDesignModal
      closeModal={close}
      open={open}
      style={{ top: 10 }}
      title={"Configure Staff Availability"}
      width={"550px"}
      component={
        <div
          style={{
            maxHeight: "73vh",
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          <ConfigUpdateModal
            formikRefSecondStep={formikRef}
            updateConfivalues={updateValues}
            staffId={staffId}
            handleCloseModal={() => {
              close();
            }}
            updateForm
            onSuccess={() => fetchSingleStaff(staffId)}
            setSubmitFormLoader={setSubmitFormLoader}
          />
        </div>
      }
      footer={
        <>
          <Divider />

          <div className="d-flex justify-content-between">
            <CustomBtnWithIcon btnText={"Cancel"} margin="0px 5px" noBackground hideIcon={true} onClick={close} />
            <CustomBtnWithIcon
              btnText={"Save"}
              type="submit"
              hideIcon={true}
              disabled={submitFormLoader}
              onClick={() => {
                if (formikRef?.current) {
                  formikRef?.current?.handleSubmit();
                }
              }}
            />
          </div>
        </>
      }
    />
  );
};

const FilterMemo = React.memo(MoreFilterComponent);

export const daysArray = [
  {
    value: 1,
    label: "Monday",
  },
  {
    value: 2,
    label: "Tueday",
  },
  {
    value: 3,
    label: "Wednesday",
  },
  {
    value: 4,
    label: "Thursday",
  },
  {
    value: 5,
    label: "Friday",
  },
  {
    value: 6,
    label: "Saturday",
  },
  {
    value: 7,
    label: "Sunday",
  },
];
