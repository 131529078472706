import React from "react";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import styled from "styled-components";

function DatePickerField({label, placeholder, size, classes, labelClass}) {
  return (
    <>
      {label && <Label className={labelClass}>{label}</Label>}
      <DatePicker
        style={{ width: "100%" }}
        type="date"
        placeholder={placeholder}
        format={"DD/MM/YYYY"}
        size={size}
        disabledDate={(current) => current && current > dayjs().endOf("day")}
      />
    </>
  );
}

export default DatePickerField;
const Label = styled.p`
    margin-bottom:6px;
    color:#3B3B3B;
    font-size:12px;
    font-weight:500;
    line-height:18px;
`
