import Invoicing from "../../pages/client/invoicing/Index";
import { connect } from "react-redux";
import { fetchSingleInvoiceApi, getAllInvoicingApi } from "../../redux/slices/invoicingSlice";
import { FetchClientsApi, fetchSingleClientsApi } from "../../redux/slices/ClientSlice";

const mapStateToProps = (state) => {
  return {
    permissionState: state.permissionManagement.permissions,
    invoicingState: state.invoicing,
    clientResponse: state.clientManangement.clientResponse,
    loginState: state.auth.loginResponse,
    invoiceSingleDetail: state.invoicing.invoiceSingleDetails,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchAllInvoice: (query) => {
    dispatch(getAllInvoicingApi(query));
  },
  getManagementClients: (data) => {
    dispatch(FetchClientsApi());
  },
  fetchSingleInvoice: (id) => {
    dispatch(fetchSingleInvoiceApi(id));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(Invoicing);
