const RandomIcon = (
  <>
    <path
      d="M15.671 1.66528V5.66634H11.6699"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1 12.3347V8.33363H5.00105"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.67377 4.99947C3.01197 4.04375 3.58677 3.18927 4.34452 2.51577C5.10228 1.84227 6.0183 1.3717 7.0071 1.14797C7.99591 0.924237 9.02528 0.954638 9.99916 1.23633C10.973 1.51803 11.8597 2.04184 12.5764 2.75889L15.6705 5.66632M1 8.33368L4.09415 11.2411C4.81083 11.9582 5.69748 12.482 6.67136 12.7637C7.64524 13.0454 8.67461 13.0758 9.66342 12.852C10.6522 12.6283 11.5682 12.1577 12.326 11.4842C13.0838 10.8107 13.6585 9.95625 13.9968 9.00053"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
)

export default RandomIcon
