import React, { useState, useEffect } from "react";
import { Button, Divider, Form, Input, DatePicker as DatePickerv2, Select, Upload } from "antd";
import styled from "styled-components";
import CustomBtnWithIcon from "../../buttonWithIcon";
import { CustomModal } from "../ModalStyles";
import { AssigneeType } from "../../../constants/TasksManagement";
import { createWorkQueue, editWorkQueue } from "../../../util/DataService";
import { pushNotification } from "../../../util/Notification";
import { FormItem } from "../../../pages/company/tasks/tasksManagementStyles";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import CustomDropdown from "../../CustomDropdown/customMultiSelectDropdown";
import dayjs from "dayjs";
import { BirthDate } from "../../../assets/rawSvgs";
import MultiSelectDropdown from "../../Form/MultiSelect";

const { Dragger } = Upload;

const EditWorkQueue = ({
  isLoading,
  isModalOpen,
  setModalOpen,
  editModalValues,
  staffList,
  clientList,
  generalList,
  loadWorkQueue,
  tasksList,
}) => {
  const [form] = Form.useForm();
  // const [assigneeList, setAssigneeList] = useState(editModalValues?.assign_to_staffs?.map(staff => staff.id ));

  const [assigneeList, setAssigneeList] = useState([]);

  useEffect(() => {
    if (editModalValues?.assign_to_staffs) {
      setAssigneeList(editModalValues.assign_to_staffs.map((staff) => staff.id));
    }
  }, [editModalValues]);

  useEffect(() => {
    const initialValues = {
      name: editModalValues?.name,
      description: editModalValues?.description,
      due_date: dayjs(editModalValues?.due_date),
      assign_to_staffs: editModalValues?.assign_to_staffs,
      tasks:
        editModalValues?.work_queue_task?.map((item) => ({
          assign_to_staff: item.assign_to_staffs?.id,
          id: item.id,
        })) || [],
    };

    form.setFieldsValue(initialValues);
  }, [editModalValues, form]);

  const handleChange = (value) => {
    setAssigneeList(value);
  };

  const onFinish = (formValues) => {
    const updatedFormValues = {
      ...formValues,
      assign_to_staffs: assigneeList,
      tasks: formValues?.tasks?.map((task) => ({
        ...task,
        type: "staff",
        due_date: formValues?.due_date.toISOString().split("T")[0],
      })),
    };
    updatedFormValues.due_date = updatedFormValues.due_date.toISOString().split("T")[0];
    editWorkQueue(editModalValues.id, updatedFormValues)
      .then(({ data }) => {
        if (data.status == 200) {
          pushNotification(data.message, "success");
          setModalOpen(false);
          form.resetFields();
          loadWorkQueue();
        }
      })
      .catch((error) => {
        console.error("error ", error);
      });
  };

  return (
    <CustomModal
      open={isModalOpen}
      title="Edit Work Queue"
      width="600px"
      heigth="500px"
      onCancel={() => {
        setModalOpen(false);
        form.resetFields();
      }}
      footer={null}
      maskClosable={false}
    >
      <ModalContentWrapper>
        <Divider />
        <Form name="editWorkQueue" onFinish={onFinish} form={form} layout="vertical">
          <FormItem
            name="name"
            label="Title"
            rules={[
              {
                required: true,
                message: "Title is required",
              },
            ]}
          >
            <Input placeholder="Write title here" />
          </FormItem>
          <FormItem
            name="description"
            label="Description"
            rules={[
              {
                required: true,
                message: "Description is required",
              },
            ]}
          >
            <Input.TextArea placeholder="Type description here..." />
          </FormItem>
          <FormItem
            name="assign_to_staffs"
            label="Assignees"
            rules={[
              {
                required: true,
                message: "Assignees is required",
              },
            ]}
          >
            {/* <div className="d-flex flex-wrap gap-1">
    
            {editModalValues.assign_to_staffs?.map((item) => (
                <Capsule>{item?.name}</Capsule>
            ))}
            </div> */}

            <CustomDropdown defaultValue={editModalValues?.assign_to_staffs} options={staffList} onChange={handleChange} />
          </FormItem>
          <FormItem
            name="due_date"
            label="Due Date"
            rules={[
              {
                required: true,
                message: "Due date is required",
              },
            ]}
          >
            <DatePickerv2
              placeholder="Select Date"
              suffixIcon={<BirthDate stroke={"#424242"} />}
              format="YYYY-MM-DD"
              style={{ height: "40px", width: "100% " }}
              onChange={(e, dateString) => {}}
            />
          </FormItem>
          <Form.List name="tasks">
            {(fields, { add, remove }) => (
              <>
                {fields?.map(({ key, name, ...restField }, index) => (
                  <div className="d-flex gap-3" key={key}>
                    <FormItem
                      name={[name, "id"]}
                      label={"Task"}
                      {...restField}
                      rules={[
                        {
                          required: true,
                          message: "Please select a task",
                        },
                        {
                          validator: (_, value, callback) => {
                            const allTasks = form.getFieldValue("tasks");
                            const duplicateTasks = allTasks.filter((task) => task.id === value);
                            if (duplicateTasks.length > 1) {
                              callback("Task already selected");
                            } else {
                              callback();
                            }
                          },
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select Task"
                        options={tasksList}
                        style={{ marginBottom: "10px" }}
                        onChange={() => form.validateFields(["tasks"])} // Validate on change
                      />
                    </FormItem>
                    {fields.length > 1 && <MinusCircleOutlined onClick={() => remove(name)} />}
                  </div>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={async () => {
                      try {
                        await form.validateFields(["tasks"]);
                        add();
                      } catch (error) {
                        console.log("Validation failed:", error);
                      }
                    }}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add Task
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>

          <Divider />
          <SaveContainer className="d-flex justify-content-end">
            <CustomBtnWithIcon
              btnText={"Cancel"}
              margin="0px 5px"
              noBackground
              hideIcon={true}
              onClick={() => {
                setModalOpen(false);
                form.resetFields();
              }}
            />
            <CustomBtnWithIcon btnText={"Save Work Queue"} type="submit" hideIcon={true} />
          </SaveContainer>
        </Form>
      </ModalContentWrapper>
    </CustomModal>
  );
};

const Capsule = styled.div`
  background-color: ${(props) => props.bgColor || "#EFF1F5"};
  color: ${(props) => props.color || "#023047"};
  font-family: Helvetica Neue;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  text-align: center;
  border-radius: 20px;
  padding: 5px 10px;
`;

const ModalContentWrapper = styled.div`
  min-height: 550px;
  height: 340px;
  overflow-y: auto;
  margin-bottom: 15px;
`;

const SaveContainer = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  border-top: 1px solid #e0e0e0;
  background-color: white;
  padding: 10px 20px;
`;

export default EditWorkQueue;
