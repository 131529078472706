import React, { useState } from "react";

import * as Yup from "yup";

import CustomBtnWithIcon from "../../../components/buttonWithIcon";

import AntDesignModal from "../../../components/Modals/AntDesignModal/AntDesignModal";
import { Col, Row, DatePicker, Table, Space, Tooltip, Select } from "antd";
import { ReactComponent as DropDownIcon } from "../../../assets/rawSvgs/selectDropDownIcon.svg";
import { Form, Formik, useFormikContext } from "formik";

import FormikInputFloating from "../../../components/formikInputs/TextInput";
import FormikSelectInput from "../../../components/formikInputs/SelectInput";
import FormikCheckboxInput from "../../../components/formikInputs/Checkbox";
import HeadingComponent from "../../../components/Heading";

import FormikSwitch from "../../../components/formikInputs/Switch";
import FormikTextArea from "../../../components/formikInputs/textArea";

import { ReactComponent as RefreshDataIconv2 } from "../../../assets/rawSvgs/circlev2.svg";
import "./service.css";
import { main_api } from "../../../api/axios_helper";
import { companyAPIsEndPoints } from "../../../constants/ApiEndPoints";
import dayjs from "dayjs";
import styled from "styled-components";
const { RangePicker } = DatePicker;
//

const AddNewServiceModal = ({
  blockTimeDropDown,
  open,
  close,
  handleCreateService,
  updateForm,
  updateServiceValues,
  promoCodeValues,
  promoCodeFetchLoader,
  serviceFormLoader,
}) => {
  const [isRotating, setIsRotating] = useState(false);
  const [promoCode, setPromoCodeDetails] = useState([dayjs().format("YYYY-MM-DD"), dayjs().format("YYYY-MM-DD")]);
  const [generatedPromoCode, setGeneratedPromoCode] = useState(undefined);
  const formikRef = React.useRef();

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(3, "Minimum 3 characters required")
      .required("Service name is required")
      .test("no-empty-spaces", "Service name cannot contain only spaces", (value) => {
        if (!value) return false; // Check if value is empty
        const trimmedValue = value.trim(); // Trim spaces
        return trimmedValue !== ""; // Check if trimmed value is not empty
      })
      .max(60, "Maximum 60 characters you can enter")
      .required("Service name is required"),
    default_duration: Yup.number().nullable().required("Default duration of service is required"),
    company: Yup.string(),
    two_extra_pet_rate: Yup.number()
      .nullable()
      .when("extra_pet_dropDown", {
        is: (extra_pet_dropDown) => extra_pet_dropDown === "2 extra pet",
        then: Yup.number().nullable().required("Two extra pet rate is required"),
      }),
    one_extra_pet_rate: Yup.number()
      .nullable()
      .when("extra_pet_enabled", {
        is: true,
        then: Yup.number().nullable().required("One extra pet rate is required"),
      }),
    time_blocks: Yup.array().min(1, "Please select at least one option").required("Please select at least one option"),
    cost: Yup.number().required("Service Cost Is Required"),
    private_note: Yup.string().nullable().max(255, "Private note must be at most 255 characters"),
    description: Yup.string().nullable().max(255, "Description must be at most 255 characters"),
  });

  const handleGeneratePromocode = async () => {
    try {
      setIsRotating(true);
      let objSend = {
        start_date: promoCode[0],
        end_date: promoCode[1],
      };

      const { data } = await main_api.post(companyAPIsEndPoints.SERVICE_GENERATE_PROMO_CODE, objSend);

      if (data.isSuccess) {
        // setIsRotating(false);

        return data?.result;
      }

      // setIsRotating(false);
    } catch (error) {
      setIsRotating(false);
      console.log("error:", error);
    }
  };

  const dateFormat = "YYYY-MM-DD";

  return (
    <>
      <AntDesignModal
        closeModal={close}
        open={open}
        title={updateForm ? "Edit Existing Service" : "Add a New Service"}
        width={"700px"}
        style={{ top: 10 }}
        validationSchema={validationSchema}
        component={
          <>
            {promoCodeFetchLoader ? (
              "Loading Details..."
            ) : (
              <div
                className="px-1 pb-2"
                style={{
                  height: "80vh",
                  overflowY: "auto",
                  overflowX: "hidden",
                  borderBottom: "0.5px solid rgb(144 144 144 / 43%)",
                }}
              >
                <Formik
                  initialValues={{
                    name: updateForm ? updateServiceValues?.name : "",
                    default_duration: updateForm ? updateServiceValues?.default_duration : "",
                    selectable_by_client: updateForm ? updateServiceValues?.selectable_by_client : false,
                    gps_route_tracking: updateForm ? updateServiceValues?.gps_route_tracking : false,
                    late_reminder: updateForm ? updateServiceValues?.late_reminder : false,
                    description: updateForm ? updateServiceValues?.description : "",
                    color: updateForm ? updateServiceValues?.color : "",
                    cost: updateForm ? updateServiceValues?.cost : "",
                    default_staff_rate: updateForm ? updateServiceValues?.default_staff_rate : "",
                    extra_pet_enabled: updateForm ? updateServiceValues?.extra_pet_enabled : false,
                    is_group_walk: updateForm ? updateServiceValues?.is_group_walk : false,
                    one_extra_pet_rate: updateForm ? updateServiceValues?.one_extra_pet_rate : "",
                    two_extra_pet_rate: updateForm ? updateServiceValues?.two_extra_pet_rate : null,
                    active: updateForm ? updateServiceValues?.active : true,
                    promo_code: updateForm ? promoCodeValues?.code : "",
                    service_group: updateForm ? updateServiceValues?.service_group : "",
                    time_blocks: updateForm ? updateServiceValues?.time_blocks : "",
                    taxable: updateForm ? updateServiceValues?.taxable : false,
                    tasks: updateForm ? updateServiceValues?.tasks : "",
                    private_note: updateForm ? updateServiceValues?.private_note : "",
                    extra_pet_dropDown: updateForm
                      ? updateServiceValues?.one_extra_pet_rate && updateServiceValues.two_extra_pet_rate
                        ? "2 extra pet"
                        : "1 extra pet"
                      : "",

                    holiday_date: updateForm ? [promoCodeValues?.end_date, promoCodeValues?.start_date] : "",
                  }}
                  innerRef={formikRef}
                  validationSchema={validationSchema}
                  // enableReinitialize
                  onSubmit={(values, { setTouched }) => {
                    // Handle form submission
                    setTouched({
                      selectedOption: true,
                    });
                    if (!values.extra_pet_enabled) {
                      values.one_extra_pet_rate = null;
                      values.two_extra_pet_rate = null;
                    }
                    handleCreateService({ ...values, promo_code: generatedPromoCode?.id });
                  }}
                >
                  {({ handleSubmit, setFieldValue, values, errors }) => (
                    <Form onSubmit={handleSubmit}>
                      <Row gutter={10}>
                        <Col md={24} className="">
                          <FormikInputFloating
                            label="Service Name"
                            placeHolder="Enter Name"
                            name="name"
                            type="text"
                            className="w-100 mb-1s"
                            autoComplete="new-password"
                          />
                        </Col>

                        <Col md={12} className="mt-2">
                          <FormikInputFloating
                            label="Default Duration In Minutes"
                            placeHolder="Enter Duration"
                            name="default_duration"
                            type="number"
                            className="w-100"
                            autoComplete="new-password"
                          />
                        </Col>
                        <Col md={12} className="" style={{ height: "70px", marginTop: "4px" }}>
                          <InputTextLabel>Time Block</InputTextLabel>
                          {/* <HeadingComponent text={"Start Date"} fontSize="12px" margin="0px 10px 0px 0px" fontWeight={400} /> */}
                          <Select
                            label="Group Name"
                            mode="multiple"
                            suffixIcon={<DropDownIcon />}
                            placeholder="Please select"
                            {...(updateForm
                              ? {
                                  defaultValue: values?.time_blocks,
                                }
                              : {})}
                            style={{
                              width: "100%",
                              minHeight: "40px",
                            }}
                            options={blockTimeDropDown}
                            className=""
                            onChange={(e) => {
                              setFieldValue("time_blocks", e);
                            }}
                          />

                          <div className="input-error-style"> {errors?.time_blocks && errors?.time_blocks}</div>
                        </Col>

                        <Col md={12} className="mt-2">
                          <div className="d-flex  align-items-center">
                            <FormikCheckboxInput
                              label=""
                              placeholder="One Per Event"
                              name="selectable_by_client"
                              type="text"
                              // className=" mb-2"
                              size="small"
                              checked={values.selectable_by_client}
                              onChange={(e) => {
                                setFieldValue("selectable_by_client", e.target.checked);
                              }}
                              value="s"
                            />
                            <HeadingComponent text={"Selectable By Clients"} fontSize="14px" margin="10px" padding="0px" />
                          </div>

                          <HeadingComponent
                            text={"If yes, Clients can select this service when requesting service"}
                            color="#909090"
                            fontSize="12px"
                            fontWeight={400}
                          />
                        </Col>
                        <Col md={12} className="mt-2">
                          <div className="d-flex  align-items-center">
                            <FormikSwitch
                              label=""
                              placeholder="One Per Event"
                              name="late_reminder"
                              type="text"
                              // className=" mb-2"
                              size="small"
                              value={values?.late_reminder}
                              onChange={(e) => {
                                setFieldValue("late_reminder", !values.late_reminder);
                              }}
                            />
                            <HeadingComponent text={"Late Reminder"} fontSize="14px" margin="10px" padding="0px" />
                          </div>

                          <HeadingComponent
                            text={"Shows when the service is late."}
                            color="#909090"
                            fontSize="12px"
                            fontWeight={400}
                          />
                        </Col>
                        <Col md={12} className="mt-2">
                          <div className="d-flex  align-items-center">
                            <FormikSwitch
                              label=""
                              placeholder="One Per Event"
                              name="gps_route_tracking"
                              type="text"
                              // className=" mb-2"
                              size="small"
                              value={values?.gps_route_tracking}
                              onChange={() => {
                                setFieldValue("gps_route_tracking", !values.gps_route_tracking);
                              }}
                            />
                            <HeadingComponent text={"GPS Route Tracking"} fontSize="14px" margin="10px" padding="0px" />
                          </div>

                          <HeadingComponent
                            text={"If disabled, GPS coordinates would be recorded at the start and at end of the service only"}
                            color="#909090"
                            fontSize="12px"
                            fontWeight={400}
                          />
                        </Col>
                        <Col md={24} className="mt-2">
                          <FormikTextArea
                            label="Private Note"
                            placeHolder="Type Notes Here..."
                            name="private_note"
                            type="text"
                            className="w-100 mb-2"
                            autoComplete="new-password"
                            width="100%"
                            onChange={(e) => {
                              setFieldValue("private_note", e);
                            }}
                          />
                          <HeadingComponent
                            text={"This is visible to office managers and admin"}
                            color="#909090"
                            fontSize="12px"
                            fontWeight={400}
                          />
                        </Col>
                        {/* <Col md={24} className="">
                      <FormikSelectInput
                        label="Tasks"
                        placeholder="Select Task For Service"
                        name="tasks"
                        type="text"
                        className="w-100 mb-2"
                        autoComplete="new-password"
                        options={[
                          { label: "Once per year", value: "Once per year" },
                          { label: "Once per client per day", value: "Once per client per day" },
                        ]}
                        onChange={(e) => {
                          setFieldValue("tasks", e);
                        }}
                        width="100%"
                      />
                    </Col> */}
                        <Col md={24} className="">
                          <FormikTextArea
                            label="Description"
                            placeHolder="Type Description Here..."
                            name="description"
                            type="text"
                            className="w-100 mb-2"
                            autoComplete="new-password"
                            options={[
                              { label: "Once per year", value: "Once per year" },
                              { label: "Once per client per day", value: "Once per client per day" },
                            ]}
                            width="100%"
                            onChange={(e) => {
                              setFieldValue("description", e);
                            }}
                          />
                          <HeadingComponent
                            text={"This is visible to staff and clients"}
                            color="#909090"
                            fontSize="12px"
                            fontWeight={400}
                          />
                        </Col>
                        <Col md={12} className="mt-2">
                          <FormikInputFloating
                            label="Cost"
                            placeHolder="$ Enter Cost"
                            name="cost"
                            type="number"
                            className="w-100 mb-1s"
                            autoComplete="new-password"
                          />
                        </Col>
                        <Col md={12} className="mt-2">
                          <FormikInputFloating
                            label="Default Staff Rate"
                            placeHolder="Enter Amount"
                            name="default_staff_rate"
                            type="number"
                            className="w-100 mb-2"
                            autoComplete="new-password"
                          />
                        </Col>
                        {/*  */}
                        <Col md={24} className="mb-2">
                          <div className="d-flex  align-items-baseline">
                            <FormikCheckboxInput
                              label=""
                              placeholder="One Per Event"
                              name="taxable"
                              type="text"
                              // className=" mb-2"
                              size="small"
                              checked={values.taxable}
                              onChange={(e) => {
                                setFieldValue("taxable", !values.taxable);
                              }}
                            />
                            <HeadingComponent text={"Service Is Taxable?"} fontSize="14px" margin="10px" />
                          </div>

                          <HeadingComponent
                            text={"Visit your company page to set the tax rate"}
                            color="#909090"
                            fontSize="12px"
                            fontWeight={400}
                          />
                        </Col>
                        <Col md={12} className="mb-2">
                          <div className="d-flex  align-items-baseline">
                            <FormikCheckboxInput
                              label=""
                              placeholder="One Per Event"
                              name="extra_pet_enabled"
                              type="text"
                              size="small"
                              checked={values?.extra_pet_enabled}
                              onChange={(e) => {
                                setFieldValue("extra_pet_enabled", !values.extra_pet_enabled);
                                console.log("Eee", e);
                                if (!values.extra_pet_enabled) {
                                  setFieldValue("extra_pet_dropDown", "");
                                }
                              }}
                            />
                            <HeadingComponent text={"Extra Pet Rates"} fontSize="14px" margin="10px" />
                          </div>
                        </Col>
                        <Col md={12} className="">
                          <div className="d-flex  align-items-baseline">
                            <FormikCheckboxInput
                              label=""
                              name="is_group_walk"
                              type="text"
                              size="small"
                              checked={values?.is_group_walk}
                              onChange={(e) => {
                                setFieldValue("is_group_walk", !values.is_group_walk);
                                console.log("Eee", e);
                                if (!values.is_group_walk) {
                                  setFieldValue("extra_pet_dropDown", "");
                                }
                              }}
                            />
                            <HeadingComponent text={"Group Walk"} fontSize="14px" margin="10px" />
                          </div>
                        </Col>
                        {values.extra_pet_enabled && (
                          <>
                            <Col md={12} className="">
                              <FormikSelectInput
                                label=""
                                placeholder="Select Option"
                                name="extra_pet_dropDown"
                                type="text"
                                className="w-100 mb-2"
                                autoComplete="new-password"
                                defaultValue={
                                  values?.one_extra_pet_rate && values.two_extra_pet_rate ? "2 extra pet" : "1 extra pet"
                                }
                                options={[
                                  { label: "One Extra Pet", value: "1 extra pet" },
                                  { label: "Two Extra Pets", value: "2 extra pet" },
                                ]}
                                onChange={(e) => {
                                  setFieldValue("extra_pet_dropDown", e);
                                }}
                                width="100%"
                                noBr
                                // disabled={!values.extra_pet_enabled}
                              />
                            </Col>

                            <Col md={12} className="">
                              <FormikInputFloating
                                label=""
                                placeHolder="$ Price One Pet"
                                name="one_extra_pet_rate"
                                type="number"
                                className="w-100 mb-2"
                                autoComplete="new-password"
                                noBr
                                // disabled={!values.extra_pet_enabled}
                              />
                            </Col>
                          </>
                        )}

                        {(values.extra_pet_dropDown === "2 extra pet" && values.extra_pet_enabled) ||
                        (values.two_extra_pet_rate !== null &&
                          values.extra_pet_dropDown === "2 extra pet" &&
                          values.extra_pet_enabled) ? (
                          <>
                            <Col md={12} className="">
                              <FormikInputFloating
                                label=""
                                placeHolder="$ Price Two Extra Pet"
                                name="two_extra_pet_rate"
                                type="number"
                                className="w-100 mb-2"
                                autoComplete="new-password"
                                noBr
                                // disabled={!values.extra_pet_enabled || values.extra_pet_dropDown !== "2 extra pet"}
                              />
                            </Col>
                          </>
                        ) : null}

                        <Col md={24} className="">
                          <FormikInputFloating name="promo_code" label="Promo Code" placeHolder="Generate One" width="100%" />
                          <div className="d-flex align-items-center my-2  ">
                            <span
                              className="cursorPointer"
                              onClick={async (e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                const returnFunc = await handleGeneratePromocode();

                                setFieldValue("promo_code", returnFunc.code);
                                setGeneratedPromoCode(returnFunc);
                              }}
                            >
                              <Tooltip title="Click here to Generate" className="d-flex">
                                <HeadingComponent
                                  text={"Generate Promo Code"}
                                  fontSize="12px"
                                  margin="0px 10px 0px 0px"
                                  fontWeight={400}
                                />

                                <RefreshDataIconv2
                                  className={isRotating ? "rotate" : ""}
                                  onAnimationEnd={() => setIsRotating(false)}
                                />
                              </Tooltip>
                            </span>
                          </div>

                          {/*  */}
                          <div>
                            <RangePicker
                              onChange={(e, dateString) => {
                                const dayid = dayjs(dateString, dateFormat);
                                if (dayid) {
                                  setPromoCodeDetails(dateString);
                                }
                              }}
                              format="YYYY-MM-DD"
                              placeholder={["Start Date", "End Date"]}
                              label="When"
                              name="dates"
                              type="text"
                              {...(updateForm && promoCodeValues !== null
                                ? {
                                    defaultValue: [
                                      dayjs(values?.holiday_date?.[0], dateFormat),
                                      dayjs(values?.holiday_date?.[1], dateFormat),
                                    ],
                                  }
                                : {})}
                              className=" mb-2 w-100"
                              // value={values?.holiday_date}
                            />
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </div>
            )}
          </>
        }
        footer={
          <>
            <div className="d-flex justify-content-end">
              <CustomBtnWithIcon
                btnText={"Cancel"}
                margin="0px 5px"
                noBackground
                hideIcon={true}
                onClick={(e) => {
                  e.preventDefault();
                  close();
                }}
              />
              <CustomBtnWithIcon
                btnText={"Save Service"}
                type="submit"
                hideIcon={true}
                disabled={serviceFormLoader}
                onClick={() => {
                  if (formikRef?.current) {
                    formikRef?.current?.handleSubmit();
                  }
                }}
              />
            </div>
          </>
        }
      />
    </>
  );
};

export default AddNewServiceModal;

const InputTextLabel = styled.span`
  font-size: 12px;
  color: #3b3b3b;
  font-weight: 500;
  line-height: 18px;
  width: 100%;
`;
