import React, { useEffect, useState } from "react";
import styled from "styled-components";
import HeadingComponent from "../../../../components/Heading";
import { ReactComponent as DefaultImageIcon } from "../../../../assets/images/uploadImage.svg";
import { Checkbox, Divider, Form, Image, Input, Radio } from "antd";
import CustomBtnWithIcon from "../../../../components/buttonWithIcon";
import ImageUploader from "../../../../components/Form/ImageUploader";

// import { availableFonts, initialColors } from '../../../../util/colorAndFonts';

import FontPicker from "../../../../components/FontPicker/fontPicker";

import ColorPicker from "../../../../components/ColorPicker/colorpicker";
import { main_api } from "../../../../api/axios_helper";
import { platformAPIsEndPoints } from "../../../../constants/ApiEndPoints";
import { editThemeSettings } from "../../../../util/DataService";
import { pushNotification } from "../../../../util/Notification";

//
export default function Theme() {
  const [initialColors, setInitialColors] = useState([
    "#FF5733",
    "#33FF57",
    "#3357FF",
    "#F1C40F",
    "#8E44AD",
    "#2C3E50",
    "#ffffff",
  ]);

  const [form] = Form.useForm();
  // Data from backend
  const [themeData, setThemeData] = useState();

  // Company Logos
  const [companyLogo, setCompanyLogo] = useState();
  const [mobileAppLogo, setMobileAppLogo] = useState();
  const [emailLogo, setEmailLogo] = useState();
  const [mobileAndEmailLogo, setMobileAndEmailLogo] = useState(false);

  // Dashboard Theme
  const [selectedHeaderColor, setSelectedHeaderColor] = useState("");
  const [selectedHeaderSeparatorColor, setSelectedHeaderSeparatorColor] = useState("");
  const [selectedHeaderFontColor, setSelectedHeaderFontColor] = useState("");
  const [selectedHeaderTitleFontColor, setSelectedHeaderTitleFontColor] = useState("");

  // Client Portal Theme
  const [selectedHeaderBackgroundColor, setSelectedHeaderBackgroundColor] = useState("");
  const [selectedFooterBackgroundColor, setSelectedFooterBackgroundColor] = useState("");
  const [selectedHeaderTextColor, setSelectedHeaderTextColor] = useState("");
  const [selectedHeaderSecondaryFontColor, setSelectedHeaderSecondaryFontColor] = useState("");
  const [selectedFooterTextFontColor, setSelectedFooterTextFontColor] = useState("");

  // Email Layout Theme
  const [selectedEmailHeaderColor, setSelectedEmailHeaderColor] = useState("");
  const [selectedTextBackgroundColor, setSelectedTextBackgroundColor] = useState("");
  const [selectedTextSeparatorColor, setSelectedTextSeparatorColor] = useState("");
  const [selectedFooterLinkColor, setSelectedFooterLinkColor] = useState("");
  const [selectedEmailHeaderTextFontColor, setSelectedEmailHeaderTextFontColor] = useState("");
  useEffect(() => {
    getThemeSettings();
  }, []);

  useEffect(() => {
    if (themeData?.mobile_app_logo_enabled || themeData?.email_logo_enabled) {
      setMobileAndEmailLogo(true);
    } else {
      setMobileAndEmailLogo(false);
    }
  }, [themeData]);

  useEffect(() => {
    if (themeData?.company_logo) {
      setCompanyLogo(themeData?.company_logo);
    }
    if (themeData?.email_logo) {
      setEmailLogo(themeData?.email_logo);
    }
    if (themeData?.mobile_app_logo) {
      setMobileAppLogo(themeData?.mobile_app_logo);
    }
  }, [themeData]);

  const getThemeSettings = () => {
    const id = 1;
    main_api
      .get(platformAPIsEndPoints.FETCH_ONBOARDING_THEME_SETTINGS(id))
      .then(({ data }) => {
        setThemeData(data?.result);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const onFinish = (formValues) => {
    // Initialize form values with fallback defaults
    formValues.companylogo = companyLogo || "";
    formValues.mobile_app_logo = "";
    formValues.email_logo = "";

    // Company Logos
    if (mobileAndEmailLogo) {
      if (themeData?.mobile_app_logo_enabled) {
        formValues.mobile_app_logo = mobileAppLogo || "";
      }
      if (themeData?.email_logo_enabled) {
        formValues.email_logo = emailLogo || "";
      }
    } else {
      formValues.mobile_app_logo = mobileAppLogo || "";
      formValues.email_logo = emailLogo || "";
      formValues.companylogo = companyLogo || "";
    }

    // Dashboard Theme
    formValues.headerColor = selectedHeaderColor || "";
    formValues.headerTitleFontColor = selectedHeaderTitleFontColor || "";
    formValues.headerFontColor = selectedHeaderFontColor || "";
    formValues.headerSeparatorColor = selectedHeaderSeparatorColor || "";

    // Client Portal Theme
    formValues.headerBackgroundColor = selectedHeaderBackgroundColor || "";
    formValues.headerTextColor = selectedHeaderTextColor || "";
    formValues.headerSecondaryFontColor = selectedHeaderSecondaryFontColor || "";
    formValues.footerBackgroundColor = selectedFooterBackgroundColor || "";
    formValues.footerTextFontColor = selectedFooterTextFontColor || "";

    // Email Layout Theme
    formValues.emailHeaderColor = selectedEmailHeaderColor || "";
    formValues.emailHeaderTextFontColor = selectedEmailHeaderTextFontColor || "";
    formValues.emailTextBackgroundColor = selectedTextBackgroundColor || "";
    formValues.emailTextSeparatorColor = selectedTextSeparatorColor || "";
    formValues.footerLinkColor = selectedFooterLinkColor || "";

    const data = {
      company_logo: formValues.companylogo,
      mobile_app_logo: formValues.mobile_app_logo,
      email_logo: formValues.email_logo,
      company_logo_enabled: mobileAndEmailLogo ? true : false,
      email_logo_enabled: mobileAndEmailLogo ? true : false,
      mobile_app_logo_enabled: mobileAndEmailLogo ? true : false,

      // Dashboard Theme
      dashboard_header: formValues.headerColor,
      dashboard_header_font: formValues.headerFontColor,
      dashboard_header_title: formValues.headerTitleFontColor,
      dashboard_header_separator: formValues.headerSeparatorColor,

      // Client Portal Theme
      clientportal_header_background: formValues.headerBackgroundColor,
      clientportal_header_text: formValues.headerTextColor,
      clientportal_header_secondary: formValues.headerSecondaryFontColor,
      clientportal_footer_background: formValues.footerBackgroundColor,
      clientportal_footer_text: formValues.footerTextFontColor,

      // Email Layout Theme
      email_header: formValues.emailHeaderColor,
      email_header_text: formValues.emailHeaderTextFontColor,
      email_excerpt_background: formValues.emailTextBackgroundColor,
      email_excerpt_separator: formValues.emailTextSeparatorColor,
      email_background: formValues.emailTextBackgroundColor,
      email_footer_link: formValues.footerLinkColor,
    };

    editThemeSettings(1, data)
      .then(({ data }) => {
        if (data.isSuccess) {
          pushNotification(data.message, "success");
          window.location.reload();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <Form name="themeForm" onFinish={onFinish} form={form} layout="vertical">
        <HeadingComponent text={"Company Logos"} fontSize="1rem" fontWeight={500} className="mt-3 mb-3" />
        <div className="d-flex">
          {/* Company logo */}
          <div className="mx-4" style={{ height: "150px", alignItems: "center" }}>
            <FormItem name="companylogo" style={{ width: "200px", marginRight: "10px" }}>
              <ImageUploader
                DefaultImageIcon={DefaultImageIcon}
                label="Web Logo"
                image={companyLogo}
                setImage={setCompanyLogo}
                style={{ objectFit: "contain", height: "100%", width: "100%" }}
                heightMax="105px"
              />
            </FormItem>
          </div>

          {/* MOBILE APP LOGO */}
          <div className="" style={{ flexDirection: "row", alignItems: "center", height: "150px" }}>
            <FormItem name="mobileAppLogo" style={{ width: "200px", marginRight: "10px" }}>
              <ImageUploader
                DefaultImageIcon={DefaultImageIcon}
                label="Mobile App Logo"
                image={mobileAppLogo}
                setImage={setMobileAppLogo}
                style={{ objectFit: "contain", height: "100%", width: "100%" }}
                heightMax="105px"
              />
            </FormItem>
          </div>

          {/* EMAIL LOGO */}
          <div className=" mx-4" style={{ height: "150px", alignItems: "center" }}>
            <FormItem name="emailLogo" style={{ width: "200px", marginRight: "10px" }}>
              <ImageUploader
                DefaultImageIcon={DefaultImageIcon}
                label="Email Logo"
                image={emailLogo}
                setImage={setEmailLogo}
                style={{ objectFit: "contain", height: "100%", width: "100%" }}
                heightMax="105px"
              />
            </FormItem>
          </div>
        </div>
        <div>
          <CheckboxContainer>
            <Checkbox
              style={{ marginRight: "10px" }}
              checked={mobileAndEmailLogo}
              onChange={(e) => setMobileAndEmailLogo(!mobileAndEmailLogo)}
            />
            <p
              style={{ margin: 10 }}
            >{`Upload different logos for Web app${themeData?.mobile_app_logo_enabled && !themeData?.email_logo_enabled ? "& Mobile app" : ", Mobile app"} ${themeData?.email_logo_enabled ? "& Emails" : ""}`}</p>
          </CheckboxContainer>
        </div>

        <HeadingComponent text={"Dashboard Colors And Styles"} fontSize="1rem" fontWeight={500} margin={"70px 0px 4px 0px"} />

        {themeData?.dashboard_header_enabled && (
          <RowContainer>
            <HeadingComponent text={<>Header Color</>} fontSize="15px" fontWeight={400} />
            <div style={{ width: "39%", marginLeft: "10%", marginRight: "30%" }}>
              <Form.Item name="headerColor">
                <ColorPicker
                  initialColors={initialColors}
                  setInitialColors={setInitialColors}
                  selectedColorData={themeData?.dashboard_header}
                  setValue={setSelectedHeaderColor}
                  disabled={!themeData?.dashboard_header_enabled}
                />
                <FormDescriptionText>Choose color theme to be shown on the company web</FormDescriptionText>
              </Form.Item>
            </div>
          </RowContainer>
        )}

        {themeData?.dashboard_header_font_enabled && (
          <RowContainer>
            <HeadingComponent text={<>Header Font Color</>} fontSize="15px" fontWeight={400} />
            <div style={{ width: "39%", marginLeft: "10%", marginRight: "30%" }}>
              <FormItem name="headerFontColor">
                <ColorPicker
                  initialColors={initialColors}
                  setInitialColors={setInitialColors}
                  selectedColorData={themeData?.dashboard_header_font}
                  setValue={setSelectedHeaderFontColor}
                  disabled={!themeData?.dashboard_header_font_enabled}
                />
                <FormDescriptionText>Choose color theme to be shown on the company web</FormDescriptionText>

                {/* <FontPicker fonts={availableFonts} selectedFont={selectedHeaderFontStyle} setSelectedFont={setSelectedHeaderFontStyle} disabled={!themeData?.dashboard_header_font_enabled}/> */}
              </FormItem>
            </div>
          </RowContainer>
        )}

        {themeData?.dashboard_header_title_enabled && (
          <RowContainer>
            <HeadingComponent text={<>Header Title Color</>} fontSize="15px" fontWeight={400} />
            <div style={{ width: "39%", marginLeft: "10%", marginRight: "30%" }}>
              <FormItem name="headerTitleFontColor">
                <ColorPicker
                  initialColors={initialColors}
                  setInitialColors={setInitialColors}
                  selectedColorData={themeData?.dashboard_header_title}
                  setValue={setSelectedHeaderTitleFontColor}
                  disabled={!themeData?.dashboard_header_title_enabled}
                />

                <FormDescriptionText>Choose color theme to be shown on the company web</FormDescriptionText>
                {/* <FontPicker fonts={availableFonts} selectedFont={selectedHeaderTitleFontColor} setSelectedFont={setSelectedHeaderTitleFontColor} disabled={!themeData?.dashboard_header_title_enabled}/> */}
              </FormItem>
            </div>
          </RowContainer>
        )}
        <Divider />
        {themeData?.dashboard_header_separator_enabled && (
          <RowContainer>
            <HeadingComponent text={<>Header Separator Color</>} fontSize="15px" fontWeight={400} />
            <div style={{ width: "39%", marginLeft: "10%", marginRight: "30%" }}>
              <Form.Item name="headerSeparatorColor">
                <ColorPicker
                  initialColors={initialColors}
                  setInitialColors={setInitialColors}
                  selectedColorData={themeData?.dashboard_header_separator}
                  setValue={setSelectedHeaderSeparatorColor}
                  disabled={!themeData?.dashboard_header_separator_enabled}
                />

                <FormDescriptionText>Choose color theme to be shown on the company web</FormDescriptionText>
              </Form.Item>
            </div>
          </RowContainer>
        )}

        <HeadingComponent text={"Client Portal Colors"} fontSize="1rem" fontWeight={500} margin={"45px 0px 4px 0px"} />

        {themeData?.clientportal_header_background_enabled && (
          <RowContainer>
            <HeadingComponent text={<>Header Background Color</>} fontSize="15px" fontWeight={400} />
            <div style={{ width: "39%", marginLeft: "10%", marginRight: "30%" }}>
              <Form.Item name="headerBackgroundColor">
                <ColorPicker
                  initialColors={initialColors}
                  setInitialColors={setInitialColors}
                  selectedColorData={themeData?.clientportal_header_background}
                  setValue={setSelectedHeaderBackgroundColor}
                  disabled={!themeData?.clientportal_header_background_enabled}
                />
                <FormDescriptionText>Choose color theme to be shown on the client web</FormDescriptionText>
              </Form.Item>
            </div>
          </RowContainer>
        )}
        <Divider />
        {themeData?.clientportal_header_text_enabled && (
          <RowContainer>
            <HeadingComponent text={<>Header Text Color</>} fontSize="15px" fontWeight={400} />
            <div style={{ width: "39%", marginLeft: "10%", marginRight: "30%" }}>
              <FormItem name="headerTextColor">
                <ColorPicker
                  initialColors={initialColors}
                  setInitialColors={setInitialColors}
                  selectedColorData={themeData?.clientportal_header_text}
                  setValue={setSelectedHeaderTextColor}
                  disabled={!themeData?.clientportal_header_text_enabled}
                />
                <FormDescriptionText>Choose color theme to be shown on the client web</FormDescriptionText>

                {/* <FontPicker fonts={availableFonts} selectedFont={selectedHeaderTextFont} setSelectedFont={setSelectedHeaderTextFont} disabled={!themeData?.clientportal_header_text_enabled}/> */}
              </FormItem>
            </div>
          </RowContainer>
        )}
        <Divider />
        {themeData?.clientportal_header_secondary_enabled && (
          <RowContainer>
            <HeadingComponent text={<>Header Secondary Text Color</>} fontSize="15px" fontWeight={400} />
            <div style={{ width: "39%", marginLeft: "10%", marginRight: "30%" }}>
              <FormItem name="headerSecondaryFontColor">
                <ColorPicker
                  initialColors={initialColors}
                  setInitialColors={setInitialColors}
                  selectedColorData={themeData?.clientportal_header_secondary}
                  setValue={setSelectedHeaderSecondaryFontColor}
                  disabled={!themeData?.clientportal_header_secondary_enabled}
                />
                <FormDescriptionText>Choose color theme to be shown on the client web</FormDescriptionText>

                {/* <FontPicker fonts={availableFonts} selectedFont={selectedHeaderSecondaryFont} setSelectedFont={setSelectedHeaderSecondaryFont}disabled={!themeData?.clientportal_header_secondary_enabled}/> */}
              </FormItem>
            </div>
          </RowContainer>
        )}

        {themeData?.clientportal_footer_background_enabled && (
          <RowContainer>
            <HeadingComponent text={<>Footer Background Color</>} fontSize="15px" fontWeight={400} />
            <div style={{ width: "39%", marginLeft: "10%", marginRight: "30%" }}>
              <Form.Item name="footerBackgroundColor">
                <ColorPicker
                  initialColors={initialColors}
                  setInitialColors={setInitialColors}
                  selectedColorData={themeData?.clientportal_footer_background}
                  setValue={setSelectedFooterBackgroundColor}
                  disabled={!themeData?.clientportal_footer_background_enabled}
                />

                <FormDescriptionText>Choose color theme to be shown on the client web</FormDescriptionText>
              </Form.Item>
            </div>
          </RowContainer>
        )}
        <Divider />
        {themeData?.clientportal_footer_text_enabled && (
          <RowContainer>
            <HeadingComponent text={<>Footer Text Color</>} fontSize="15px" fontWeight={400} />
            <div style={{ width: "39%", marginLeft: "10%", marginRight: "30%" }}>
              <FormItem name="footerTextFontColor">
                <ColorPicker
                  initialColors={initialColors}
                  setInitialColors={setInitialColors}
                  selectedColorData={themeData?.clientportal_footer_text}
                  setValue={setSelectedFooterTextFontColor}
                  disabled={!themeData?.clientportal_footer_text_enabled}
                />

                <FormDescriptionText>Choose color theme to be shown on the client web</FormDescriptionText>

                {/* <FontPicker fonts={availableFonts} selectedFont={selectedFooterTextFont} setSelectedFont={setSelectedFooterTextFont} disabled={!themeData?.clientportal_footer_text_enabled}/> */}
              </FormItem>
            </div>
          </RowContainer>
        )}

        <HeadingComponent text={"Email Layout Colors"} fontSize="1rem" fontWeight={500} margin={"45px 0px 4px 0px"} />
        {themeData?.email_header_enabled && (
          <RowContainer>
            <HeadingComponent text={<>Header</>} fontSize="15px" fontWeight={400} />
            <div style={{ width: "39%", marginLeft: "10%", marginRight: "30%" }}>
              <Form.Item name="emailHeaderColor">
                <ColorPicker
                  initialColors={initialColors}
                  setInitialColors={setInitialColors}
                  selectedColorData={themeData?.email_header}
                  setValue={setSelectedEmailHeaderColor}
                  disabled={!themeData?.email_header_enabled}
                />
                <FormDescriptionText>Choose color theme to be shown on the email</FormDescriptionText>
              </Form.Item>
            </div>
          </RowContainer>
        )}
        <Divider />
        {themeData?.email_header_text_enabled && (
          <RowContainer>
            <HeadingComponent text={<>Header Text</>} fontSize="15px" fontWeight={400} />
            <div style={{ width: "39%", marginLeft: "10%", marginRight: "30%" }}>
              <FormItem name="emailHeaderTextFontColor">
                <ColorPicker
                  initialColors={initialColors}
                  setInitialColors={setInitialColors}
                  selectedColorData={themeData?.email_header_text}
                  setValue={setSelectedEmailHeaderTextFontColor}
                  disabled={!themeData?.email_header_text_enabled}
                />
                <FormDescriptionText>Choose color theme to be shown on the email</FormDescriptionText>

                {/* <FontPicker fonts={availableFonts} selectedFont={selectedEmailHeaderTextFont} setSelectedFont={setSelectedEmailHeaderTextFont} disabled={!themeData?.email_header_text_enabled}/> */}
              </FormItem>
            </div>
          </RowContainer>
        )}
        <Divider />
        {themeData?.email_excerpt_separator_enabled && (
          <RowContainer>
            <HeadingComponent text={<>Text Background</>} fontSize="15px" fontWeight={400} />
            <div style={{ width: "39%", marginLeft: "10%", marginRight: "30%" }}>
              <Form.Item name="textBackgroundColor">
                <ColorPicker
                  initialColors={initialColors}
                  setInitialColors={setInitialColors}
                  selectedColorData={themeData?.email_excerpt_background}
                  setValue={setSelectedTextBackgroundColor}
                  disabled={!themeData?.email_excerpt_separator_enabled}
                />

                <FormDescriptionText>Choose color theme to be shown on the email</FormDescriptionText>
              </Form.Item>
            </div>
          </RowContainer>
        )}

        {themeData?.email_excerpt_separator_enabled && (
          <RowContainer>
            <HeadingComponent text={<>Text Separator</>} fontSize="15px" fontWeight={400} />
            <div style={{ width: "39%", marginLeft: "10%", marginRight: "30%" }}>
              <Form.Item name="textSeparatorColor">
                <ColorPicker
                  initialColors={initialColors}
                  setInitialColors={setInitialColors}
                  selectedColorData={themeData?.email_excerpt_separator}
                  setValue={setSelectedTextSeparatorColor}
                  disabled={!themeData?.email_excerpt_separator_enabled}
                />

                <FormDescriptionText>Choose color theme to be shown on the email</FormDescriptionText>
              </Form.Item>
            </div>
          </RowContainer>
        )}

        {themeData?.email_footer_link_enabled && (
          <RowContainer>
            <HeadingComponent text={<>Footer Link</>} fontSize="15px" fontWeight={400} />
            <div style={{ width: "39%", marginLeft: "10%", marginRight: "30%" }}>
              <Form.Item name="footerLinkColor">
                <ColorPicker
                  initialColors={initialColors}
                  setInitialColors={setInitialColors}
                  selectedColorData={themeData?.email_footer_link}
                  setValue={setSelectedFooterLinkColor}
                  disabled={!themeData?.email_footer_link_enabled}
                />

                <FormDescriptionText>Choose color theme to be shown on the email</FormDescriptionText>
              </Form.Item>
            </div>
          </RowContainer>
        )}

        <SaveContainer className="d-flex justify-content-end">
          <CustomBtnWithIcon btnText="Save Changes" type="submit" hideIcon={true} />
        </SaveContainer>
      </Form>
    </div>
  );
}

const FormItem = styled(Form.Item)`
  width: 100%;
  Tags {
    display: flex;
  }
`;
const CheckboxContainer = styled.div`
  display: flex;
  position: absolute;
  top: 395px;
  align-items: center;
  margin: 10px 0;
`;
const FormDescriptionText = styled.p`
  font-family: "Helvetica Neue", Arial;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #757575;
`;

const SaveContainer = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  border-top: 1px solid #e0e0e0;
  background-color: white;
  padding: 10px 20px;
`;
const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  margin-top: 30px; /* Adjust the top margin */
  margin-bottom: -9px; /* Adjust the bottom margin */
`;
const Heading = ({ text = "", margin, fontSize = "0.75rem", color = "#3B3B3B" }) => {
  return <HeadingComponent text={text} fontSize={fontSize} color={color} fontWeight={700} margin={margin} />;
};
