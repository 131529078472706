import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { main_api } from "../../api/axios_helper";
import { companyAPIsEndPoints } from "../../constants/ApiEndPoints";
import axiosInstance from "../../util/AxiosConfig";
import { pushNotification } from "../../util/Notification";
// ----------------------------------------------------------------------

const initialState = {
  KeyResponse: {
    isLoading: false,
    responseData: [],
    error: false,
    keysList: [],
  },
  keysPostResponse: {
    isLoading: false,
    responseData: "",
  },
  keyEditResponse: {
    isLoading: false,
    responseData: "",
  },

  // clientDeleteResponse: {
  //   isLoading: false,
  //   responseData: "",
  // },
  // clientSingleDetails: {
  //   isLoading: false,
  //   responseData: "",
  // },
};

const keyManagement = createSlice({
  name: "key Management",
  initialState,
  reducers: {
    getAllManagementKeySlice(state, action) {
      state.KeyResponse.isLoading = action.payload.isLoading;
      if (action.payload?.responseData) {
        state.KeyResponse.error = action.payload.error;
        state.KeyResponse.responseData = action.payload?.responseData;
        const staffList = action.payload?.responseData?.result.map((items) => {
          return {
            value: items.id,
            label: items.name,
          };
        });

        state.KeyResponse.keysList = staffList;
      }
    },
    postKeysSlice(state, action) {
      state.keysPostResponse.isLoading = action.payload.isLoading;
      if (action.payload?.responseData) {
        state.keysPostResponse.responseData = action.payload?.responseData;
      }
    },

    editKeySlice(state, action) {
      state.keyEditResponse.isLoading = action.payload.isLoading;
      if (action.payload?.responseData) {
        state.keyEditResponse.responseData = action.payload?.responseData;
      }
    },

    // deleteClientSlice(state, action) {
    //   state.clientDeleteResponse.isLoading = action.payload.isLoading;
    //   if (action.payload?.responseData) {
    //     state.clientDeleteResponse.responseData = action.payload?.responseData;
    //   }
    // },
    // fetchSingleClientSlice(state, action) {
    //   state.clientSingleDetails.isLoading = action.payload.isLoading;
    //   if (action.payload?.responseData) {
    //     state.clientSingleDetails.responseData = action.payload?.responseData;
    //   }
    // },
    // resetResponseState(state, action) {
    //   skey.responseData = "";
    //   state.clientDeleteResponse.responseData = "";
    //   state.clientEditResponse.responseData = "";
    // },
  },
});

export const { loginUserSlice, resetResponseState } = keyManagement.actions;
// Reducer
export default keyManagement.reducer;

export const FetchKeysApi = (queryParams='', search='') => {
  return async (dispatch, getState) => {
    try {
      const companyId = getState().auth?.loginResponse?.selectCompany?.[0]?.company_id;

      dispatch(keyManagement.actions.getAllManagementKeySlice({ isLoading: true }));
      const { data } = await main_api.get(companyAPIsEndPoints.FETCH_MANAGEMENT_KEYS(companyId, queryParams, search));
      dispatch(keyManagement.actions.getAllManagementKeySlice({ isLoading: false, responseData: data }));
    } catch (error) {
      dispatch(keyManagement.actions.getAllManagementKeySlice({ isLoading: false }));
    }
  };
};

export const PostKeyApi = (formValues) => {
  return async (dispatch, getState) => {
    try {
      dispatch(keyManagement.actions.postKeysSlice({ isLoading: true }));
      const { data } = await main_api.post(companyAPIsEndPoints.POST_MANAGEMENT_KEYS, formValues);
      dispatch(keyManagement.actions.postKeysSlice({ isLoading: false, responseData: data }));
    } catch (error) {
      dispatch(keyManagement.actions.postKeysSlice({ isLoading: false, responseData: error }));
    }
  };
};
export const PostExistingKey = (formValues) => {
  return async (dispatch, getState) => {
    try {
      dispatch(keyManagement.actions.postKeysSlice({ isLoading: true }));
      const { data } = await main_api.post(companyAPIsEndPoints.CREATE_COPY_KEY, formValues);
      dispatch(keyManagement.actions.postKeysSlice({ isLoading: false, responseData: data }));
    } catch (error) {
      dispatch(keyManagement.actions.postKeysSlice({ isLoading: false, responseData: error }));
    }
  };
};
export const updateSingleKeysApi = ({ id, values }) => {
  return async (dispatch, getState) => {
    try {
      dispatch(keyManagement.actions.editKeySlice({ isLoading: true }));
      const { data } = await main_api.put(companyAPIsEndPoints.EDIT_SINGLE_MANAGEMENT_KEYS(id), values);

      dispatch(keyManagement.actions.editKeySlice({ isLoading: false, responseData: data }));
    } catch (error) {
      dispatch(keyManagement.actions.editKeySlice({ isLoading: false, responseData: error }));
    }
  };
};

// export const fetchSingleClientsApi = (id) => {
//   return async (dispatch, getState) => {
//     try {
//       const companyId = getState().auth?.loginResponse?.selectCompany?.[0]?.company_id;
//       dispatch(keyManagement.actions.fetchSingleClientSlice({ isLoading: true }));
//       const { data } = await main_api.get(companyAPIsEndPoints.FETCH_SINGLE_MANAGEMENT_CLIENTS(id, companyId));

//       dispatch(keyManagement.actions.fetchSingleClientSlice({ isLoading: false, responseData: data }));
//     } catch (error) {
//       dispatch(keyManagement.actions.fetchSingleClientSlice({ isLoading: false, responseData: error }));
//     }
//   };
// };

// export const fetchLoggedInClientApi = () => {
//   return async (dispatch, getState) => {
//     const client_id = getState().auth?.loginResponse?.selectCompany?.find((item) => item.client_id != null).client_id;
//     const companyId = getState().auth?.loginResponse?.selectCompany?.[0]?.company_id;

//     try {
//       dispatch(keyManagement.actions.fetchSingleClientSlice({ isLoading: true }));
//       const { data } = await axiosInstance.get(companyAPIsEndPoints.FETCH_SINGLE_MANAGEMENT_CLIENTS(client_id, companyId));

//       dispatch(keyManagement.actions.fetchSingleClientSlice({ isLoading: false, responseData: data }));
//     } catch (error) {
//       dispatch(keyManagement.actions.fetchSingleClientSlice({ isLoading: false, responseData: error }));
//     }
//   };
// };

// export const DeleteSingleClientsApi = (id) => {
//   return async (dispatch, getState) => {
//     try {
//       dispatch(keyManagement.actions.deleteClientSlice({ isLoading: true }));
//       const { data } = await main_api.delete(companyAPIsEndPoints.DELETE_SINGLE_MANAGEMENT_CLIENTS(id));

//       dispatch(keyManagement.actions.deleteClientSlice({ isLoading: false, responseData: data }));
//     } catch (error) {
//       dispatch(keyManagement.actions.deleteClientSlice({ isLoading: false, responseData: error }));
//     }
//   };
// };

// export const fetchSingleClientApi = (id) => {
//   return async (dispatch, getState) => {
//     try {
//       const companyId = getState().auth?.loginResponse?.selectCompany?.[0]?.company_id;

//       dispatch(keyManagement.actions.fetchSingleClientSlice({ isLoading: true }));
//       const { data } = await main_api.get(companyAPIsEndPoints.FETCH_SINGLE_MANAGEMENT_CLIENTS(id, companyId));

//       dispatch(keyManagement.actions.fetchSingleClientSlice({ isLoading: false, responseData: data }));
//     } catch (error) {
//       dispatch(keyManagement.actions.fetchSingleClientSlice({ isLoading: false, responseData: error }));
//     }
//   };
// };
