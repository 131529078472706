import React, { useEffect, useState } from "react";
import { ReactComponent as Birthdate } from "../../../../../../assets/rawSvgs/Birthdate.svg";

import { ReactComponent as GreaterThanIcon } from "../../../../../../assets/rawSvgs/greaterThanIcon.svg";
import { ReactComponent as LessThanIcon } from "../../../../../../assets/rawSvgs/lessThanIcon.svg";
import {
  InvoiceIcon,
  ClientYellow,
  SchedulerServiceIcon,
  SchedulerWhenDateicon,
  SchedulerAvatarClient,
} from "../../../../../../assets/rawSvgs/index";
import moment from "moment";
import HeadingComponent from "../../../../../../components/Heading";
import { Col, Divider, Row } from "antd";
import { CustomComponents } from "./WeekCalender";
import dayjs from "dayjs";
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import { schedulerBackground, schedulerColor } from "../../../../../../components/SchedulerCalender/helpers";
import { ShowEventModal } from "../../../../../../components/SchedulerCalender/OneDayVertical";
import AntDesignModal from "../../../../../../components/Modals/AntDesignModal/AntDesignModal";
import { convertTo12HourFormat } from "../../../../../../util/formatTime";
import CustomBtnWithIcon from "../../../../../../components/buttonWithIcon";
//

const MonthCalender = ({ schedulerState, calenderTypeDropDown, monthDateState, loading }) => {
  const { dateRange, setDateRange } = monthDateState;
  const [openDetailSlotModal, setOpenDetailSlotModal] = useState({ details: "", condition: false });

  const handleDateNext = () => {
    if (calenderTypeDropDown === "Month") {
      handleNextMonth();
    }
  };
  const handleDatePrev = () => {
    if (calenderTypeDropDown === "Month") {
      handlePrevMonth();
    }
  };

  const handlePrevMonth = () => {
    const newStart = moment(dateRange.start).subtract(1, "month").startOf("month").toDate();
    const newEnd = moment(dateRange.end).subtract(1, "month").endOf("month").toDate();
    setDateRange({ start: newStart, end: newEnd });
  };

  const handleNextMonth = () => {
    const newStart = moment(dateRange.start).add(1, "month").startOf("month").toDate();
    const newEnd = moment(dateRange.end).add(1, "month").endOf("month").toDate();
    setDateRange({ start: newStart, end: newEnd });
  };

  const handleEventClick = (slotInfo) => {
    const dateTime = dayjs(slotInfo.start);
    const formattedDate = dateTime.format("YYYY-MM-DD");

    const formattedTime = slotInfo.start.toLocaleTimeString("en-US", { hour: "numeric", minute: "numeric", hour12: true });

    setOpenDetailSlotModal({
      details: {
        staffId: slotInfo.resourceId,
        slotTime: formattedTime,
        date: formattedDate,
        serviceID: slotInfo.id,
        title: slotInfo.title,
      },
      condition: true,
    });
  };

  return (
    <div>
      <Row>
        <Col lg={7} className="">
          {(calenderTypeDropDown === "Month" || calenderTypeDropDown === "Week") && (
            <div className="d-flex  align-items-center ms-5">
              <div className="me-2">
                <Birthdate stroke={"#424242"} />
              </div>

              {calenderTypeDropDown === "Month" && (
                <HeadingComponent
                  text={moment(dateRange?.start).format("MMMM, YYYY")}
                  fontSize={"14px"}
                  color="#757575"
                  margin="0px 0px "
                  fontWeight={400}
                />
              )}
              {calenderTypeDropDown === "Week" && (
                <div>
                  <HeadingComponent
                    text={dateRange?.start}
                    fontSize={"14px"}
                    color="#757575"
                    margin="0px 0px "
                    fontWeight={400}
                    className="me-2"
                  />
                  <span> </span>
                  <HeadingComponent
                    text={dateRange?.end}
                    fontSize={"14px"}
                    color="#757575"
                    margin="0px 0px "
                    fontWeight={400}
                    className="ms-2"
                  />
                </div>
              )}

              <div className="d-flex ms-2">
                <button onClick={handleDatePrev}>
                  <LessThanIcon />
                </button>
                <button onClick={handleDateNext}>
                  <GreaterThanIcon />
                </button>
              </div>
            </div>
          )}
        </Col>
      </Row>
      <MyCalendarMonthv2
        dateRange={dateRange}
        schedulerState={{
          result: schedulerState,
        }}
        loading={loading}
        companyPortal={true}
        handleEventClick={handleEventClick}
      />

      {openDetailSlotModal.condition && (
        <ShowEventModal
          openDetailSlotModal={openDetailSlotModal.condition}
          handleCancel={() => {
            setOpenDetailSlotModal({ ...openDetailSlotModal, condition: false });
          }}
          eventDetails={openDetailSlotModal.details}
          serviceState={schedulerState}
          // handleEditEvent={handleEditEvent}
          btnsHide={true}
          fetchServices={() => {
            // AllStates.fetchAllSchedulerService();
            setOpenDetailSlotModal({ ...openDetailSlotModal, condition: false });
          }}
        />
      )}
    </div>
  );
};

export default MonthCalender;

const MyCalendarMonthv2 = ({ dateRange, schedulerState, loading, handleEventClick }) => {
  const localizer = momentLocalizer(moment);

  const [events, setEvents] = useState([]);
  const [openDetailSlotModal, setOpenDetailSlotModal] = useState({ details: "", condition: false });
  const [showDateMoreEvents, setshowDateMoreEvents] = useState([]);
  const [showAllEvents, setShowAllEvents] = useState(false);

  useEffect(() => {
    let modifyEvents = [];
    schedulerState &&
      schedulerState?.result &&
      schedulerState?.result?.map((scheduler) => {
        const [year, month, day] = scheduler.date.split("-").map(Number);
        const [startHour, startMinute, startSecond] = scheduler.start_time ? scheduler.start_time.split(":") : [0, 0, 0];
        const [endHour, endMinute, endSecond] = scheduler.end_time ? scheduler.end_time.split(":") : [0, 0, 0];
        modifyEvents.push({
          ...scheduler,
          backgroundColor: schedulerBackground(scheduler),
          color: schedulerColor(scheduler),
          start: new Date(year, month - 1, day, startHour, startMinute),
          end: new Date(year, month - 1, day, endHour, endMinute),
          title: scheduler.service.name || "",
          clientName: scheduler.client.name || "",
        });
      });
    setEvents(modifyEvents);
  }, [schedulerState]);

  // Function to handle event creation
  const handleSelect = ({ start, end }) => {
    const title = window.prompt("Enter event title:");
    if (title) {
      const newEvent = {
        id: events.length + 1,
        title,
        start,
        end,
        backgroundColor: "#3174ad", // Background color of the event
        textColor: "#ffffff", // Text color of the event
      };
      setEvents([...events, newEvent]);
    }
  };
  const eventStyleGetter = (event, start, end, isSelected) => {
    return {
      style: {
        backgroundColor: "transparent",
        color: event.textColor,
        cursor: "default", // Disable cursor change on event
      },
    };
  };

  return (
    <div id="scheduler-month-calendar" className="mb-5 mt-3">
      <div className={loading ? "filtering" : null}>
        <Calendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          onSelectEvent={handleEventClick}
          views={["month"]} // Only display the month view
          // onSelectSlot={handleSelect} // Call handleSelect when a slot is selected
          style={{ height: 500 }} // Adjust height as needed
          components={{
            event: CustomComponents,
            toolbar: () => null,
          }}
          step={60}
          date={dateRange.start} // Set the visible date range
          onNavigate={() => {}}
          messages={{
            showMore: (target, data) => (
              <div
                className="ml-2"
                role="presentation"
                onClick={() => {
                  setShowAllEvents(true);
                  setOpenDetailSlotModal({
                    data,
                    condition: true,
                  });
                }}
              >
                +{target} more
              </div>
            ),
          }}
          eventPropGetter={eventStyleGetter}
          selectable={true}
          // onSelectEvent={(event) => setSelectedEvent(event)}
        />

        <AntDesignModal
          openModal={openDetailSlotModal.condition}
          style={{ top: 20 }}
          closeModal={() =>
            setOpenDetailSlotModal({
              ...openDetailSlotModal,
              condition: false,
            })
          }
          onCancel={() =>
            setOpenDetailSlotModal({
              ...openDetailSlotModal,
              condition: false,
            })
          }
          onOk={() =>
            setOpenDetailSlotModal({
              ...openDetailSlotModal,
              condition: false,
            })
          }
          width={500}
          title={<HeadingComponent text={`Events`} fontSize="16px" fontWeight={700} color="#212121" />}
          component={
            <div style={{ maxHeight: "60vh", overflowY: "auto", overflowX: "hidden" }}>
              <Row gutter={10}>
                {openDetailSlotModal?.data?.map((items, index) => {
                  return (
                    <Col lg={24} key={index} className="my-3">
                      <HeadingComponent
                        text={`${convertTo12HourFormat(items?.start_time)} - ${convertTo12HourFormat(items?.end_time)}`}
                        fontSize="14px"
                        fontWeight={600}
                        color="#212121"
                      />
                      <Col lg={15} className="d-flex align-items-end my-2">
                        <InvoiceIcon className="me-3 ms-1" stroke="#FBCF34" />

                        <HeadingComponent text={"Invoice: #1234213"} fontSize="14px" fontWeight={500} color="#424242" />
                      </Col>
                      <Col lg={15} className="d-flex align-items-end my-2">
                        <SchedulerServiceIcon className="me-3" stroke="#FBCF34" />

                        <HeadingComponent
                          text={`Service: ${items?.service?.name}`}
                          fontSize="14px"
                          fontWeight={500}
                          color="#424242"
                        />
                      </Col>
                      <Col lg={15} className="d-flex align-items-end my-2">
                        <SchedulerWhenDateicon className="me-3" stroke="#FBCF34" />

                        <HeadingComponent text={`When: ${items?.date}`} fontSize="14px" fontWeight={500} color="#424242" />
                      </Col>{" "}
                      <Col lg={15} className="d-flex align-items-end my-2">
                        <ClientYellow className="me-3 ms-1" stroke="#FBCF34" />

                        <HeadingComponent
                          text={`Staff: ${items?.staff?.name}`}
                          className="ms-1"
                          fontSize="14px"
                          fontWeight={500}
                          color="#424242"
                        />
                      </Col>
                      <Col lg={15} className="d-flex align-items-end my-2">
                        <SchedulerAvatarClient className="me-3" stroke="#FBCF34" />
                        <HeadingComponent
                          text={`Client: ${items?.client?.name}`}
                          fontSize="14px"
                          fontWeight={500}
                          color="#424242"
                        />
                      </Col>
                    </Col>
                  );
                })}
              </Row>
            </div>
          }
          footer={
            <div className="d-flex justify-content-end">
              <>
                <CustomBtnWithIcon
                  btnText={"Close"}
                  hideIcon
                  className="me-2"
                  width="90px"
                  onClick={() =>
                    setOpenDetailSlotModal({
                      ...openDetailSlotModal,
                      condition: false,
                    })
                  }
                />
              </>
            </div>
          }
        />
      </div>
    </div>
  );
};
