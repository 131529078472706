import React, { useState } from "react";
import { Typography, Upload, message } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { main_api } from "../../api/axios_helper";
import { companyAPIsEndPoints } from "../../constants/ApiEndPoints";


function VideoUploader({ label, DefaultVideoIcon, video, setVideo, itemId, setVideoForItem, savedVideoUrl }) {
  const { Dragger } = Upload;

  const removeVideo = () => {
    setVideo(null);
    setVideoForItem(itemId, null);
  };
  
  const uploadToS3 = async (file) => {
    const formData = new FormData();
    formData.append("video", file);

    try {
      const response = await main_api.post(companyAPIsEndPoints.UPLOAD_OBJECT, formData);
    
      if (response.data.isSuccess) {

        const videoUrl = response.data.url;
        setVideo(videoUrl);
        setVideoForItem(itemId,videoUrl);
        message.success("Video uploaded successfully");
      } else {
        message.error("Failed to upload video");
      }
    } catch (error) {
      console.error("Error uploading video to S3", error);
      message.error("Failed to upload video");
    }
  };
  const props = {
    name: "file",
    showUploadList: false,
    beforeUpload(file) {
      const fileType = file.type;
      if (fileType !== "video/mp4" && fileType !== "video/webm" && fileType !== "video/ogg") {
        message.error("File should be of type MP4, WEBM, or OGG");
        return false; // Returning false prevents default behavior (uploading the file)
      }
      uploadToS3(file);
      
      return false; // Returning false prevents default behwhat abpoavior (uploading the file)
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    }
  };

  return (
    <VideoContent>
      <div className="d-flex">
        {label && <Label>{label}</Label>}
        {(video || savedVideoUrl) && (
          <CloseCircleOutlined
            style={{ marginBottom: "5px" }}
            className="remove-icon ms-auto"
            onClick={() => removeVideo()}
          />
        )}
      </div>
      {video ? (
        <video controls style={{ maxHeight: "140px", objectFit: "contain" }}>
          <source src={video} />
          Your browser does not support the video tag.
        </video>
      ) : savedVideoUrl ? (
        <video controls style={{ maxHeight: "140px", objectFit: "contain" }}>
          <source src={savedVideoUrl} />
          Your browser does not support the video tag.
        </video>
      ) : (
        <Dragger style={{ marginTop: "10px" }} {...props}>
          <>
            <p className="ant-upload-drag-icon">{<DefaultVideoIcon />}</p>
            <Typography>Upload Video</Typography>
          </>
        </Dragger>
      )}
    </VideoContent>
  );
}


export default VideoUploader;

const Label = styled.p`
  margin-bottom: 6px;
  color: #3b3b3b;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
`;

const VideoContent = styled.div`
  .ant-image-mask-info {
    text-align: center;
  }
`;
