import { connect } from "react-redux";
import { addCompany, editCompany, loadCompanyList, searchCompanyList } from "../../redux/platformSlices/CompanySlice";
import CompanyManagement from "../../pages/platform/companyManagement/CompanyManagement";
import { loadCompanyDetail, openCompanyDetailModal } from "../../redux/platformSlices/CompanyDetail";
import { openDetailModal } from "../../redux/slices/petDetail";

const mapStateToProps = (state) => {
  return {
    isLoading: state.company.isLoading,
    error: state.company.error,
    companyList: state.company.companyList,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadCompanyList: () => {
    dispatch(loadCompanyList());
  },
  searchCompanyList: (search, active) => {
    dispatch(searchCompanyList(search, active));
  },
  addCompany: (values) => {
    dispatch(addCompany(values));
  },
  editCompany: (values) => {
    dispatch(editCompany(values));
  },
  loadCompanyDetail: (id) => {
    dispatch(loadCompanyDetail(id));
  },
  openCompanyDetailModal: (isOpen) => {
    dispatch(openCompanyDetailModal(isOpen));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(CompanyManagement);
