import Scheduler from "../../pages/company/sheduler/indexv2";
import { connect } from "react-redux";
import { loadPetList } from "../../redux/slices/pets";
import { FetchClientsApi } from "../../redux/slices/ClientSlice";
import { fetchServiceApi } from "../../redux/slices/serviceSlice";
import { fetchAllStaffApi } from "../../redux/slices/StaffSlice";
import {
  fetchSchedulerServiceApi,
  fetchSchedulerTemplatesApi,
  fetchUnavaialbleSlotsApi,
} from "../../redux/slices/schedulerSlice";
import { fetchCompanyDashboardDataApi } from "../../redux/slices/GeneralAppSlice/AppSlice";
import { FetchKeysApi } from "../../redux/slices/KeySlice";
import { fetchCompDetailsApi, fetchCompStripeDetailsApi } from "../../redux/slices/auth";

const mapStateToProps = (state) => {
  return {
    petList: state.pets,
    serviceStateResponseData: state.services.serviceGetResponse,
    clientManagementState: state.clientManangement.clientResponse,
    staffState: state.staffManagement,
    schedulerState: state?.scheduler.schedulerResponse?.responseData,
    schedulerLoader: state?.scheduler.schedulerResponse?.isLoading,
    totalServiceDetails: state?.generalApp.dashboardCompanyDetails.responseData.result,
    permissionState: state.permissionManagement.permissions,
    schedulerTemplateState: state?.scheduler.schedulerTemplatesResponse,
    unavaliableSlotsState: state?.scheduler.unavaliableSlotsResponse,
    keyManagementList: state.keyManagement.KeyResponse.keysList,
    loginState: state.auth.loginResponse,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadPetList: () => {
    dispatch(loadPetList());
  },
  fetchAllSchedulerService: (clientQuery = "", statusQuery = "", startDate = "", endDate = "") => {
    dispatch(fetchSchedulerServiceApi(clientQuery, statusQuery, startDate, endDate));
  },
  getManagementClients: (data) => {
    dispatch(FetchClientsApi());
  },
  fetchAllServices: (data) => {
    dispatch(fetchServiceApi(data));
  },
  fetchAllStaff: (id) => {
    dispatch(fetchAllStaffApi(id));
  },
  fetchDashboardCompany: () => {
    dispatch(fetchCompanyDashboardDataApi());
  },
  fetchSchedulerTemplates: () => {
    dispatch(fetchSchedulerTemplatesApi());
  },
  fetchUnavaialbleSlots: (payload) => {
    dispatch(fetchUnavaialbleSlotsApi(payload));
  },
  getManagementkeys: () => {
    dispatch(FetchKeysApi());
  },
  fetchCompDetails: () => {
    dispatch(fetchCompDetailsApi());
  },
  fetchCompStripeDetails: () => {
    dispatch(fetchCompStripeDetailsApi());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Scheduler);
