import React, { useState } from "react";
import {
  DatePicker,
  Divider,
  Flex,
  Form,
  Image,
  Input,
  InputNumber,
  message,
  Modal,
  Select,
  TimePicker,
  Typography,
  Upload,
} from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import styled from "styled-components";
import CustomBtnWithIcon from "../../buttonWithIcon";
import dayjs from "dayjs";
import { CustomModal } from "../ModalStyles";
import { AddImageComponent } from "./AddPetModal";
import Avatar from "react-avatar";
const { Dragger } = Upload;

const getComponent = (field) => {
  const commonProps = {
    placeholder: field.preview_text,
    options: field.options || [],
  };

  const mappedOptions = commonProps.options.map((option) => ({
    value: option,
    label: option,
  }));

  const mapping = {
    text: <Input defaultValue={field?.field_value} placeholder={commonProps.placeholder} />,
    date: (
      <DatePicker
        defaultValue={dayjs(field?.field_value)}
        placeholder={commonProps.placeholder}
        style={{ width: "100%", height: "40px" }}
      />
    ),
    time: (
      <TimePicker
        defaultValue={dayjs(field?.field_value)}
        hourStep={1}
        format="hh:mm a"
        placeholder={commonProps.placeholder}
        style={{ width: "100%" }}
      />
    ),
    number: <InputNumber defaultValue={field?.field_value} placeholder={commonProps.placeholder} style={{ width: "100%" }} />,
    dropdown: (
      <Select defaultValue={field?.field_value} placeholder={commonProps.placeholder}>
        {mappedOptions.map((option) => (
          <Select.Option key={option.value} value={option.value}>
            {option.label}
          </Select.Option>
        ))}
      </Select>
    ),
  };
  return mapping[field.field_type];
};

const EditPetModal = ({
  isLoading,
  isModalOpen,
  setModalOpen,
  onFinish,
  vetList,
  editPetValues,
  clientList,
  getImageFromEditPet,
  petLogo,
}) => {
  const [form] = Form.useForm();
  form.resetFields();
  const dynamicInitialValues = () => {
    return editPetValues?.custom_fields?.groups.reduce((acc, group) => {
      group.fields.forEach((field) => {
        const fieldName = field.id;
        if (field.field_type === "date" || field.field_type === "time") {
          acc[fieldName] = dayjs(field.field_value || "");
        } else {
          acc[fieldName] = field.field_value || "";
        }
      });
      return acc;
    }, {});
  };
  form.setFieldsValue({
    name: editPetValues?.name,
    type: editPetValues?.type,
    sex: editPetValues?.sex,
    color: editPetValues?.color,
    breed: editPetValues?.breed,
    neutered: editPetValues?.neutered,
    vaccination_current: editPetValues?.vaccination_current,
    primary_vet: editPetValues?.primary_vet,
    alternate_vet: editPetValues?.alternate_vet,
    primary_vet_name: editPetValues?.primary_vet_name,
    alternate_vet_name: editPetValues?.alternate_vet_name,
    micro_chip: editPetValues?.micro_chip,
    notes: editPetValues?.notes,
    client_name: editPetValues?.client_name,
    birth_date: dayjs(editPetValues?.birth_date),

    ...dynamicInitialValues(),
  });
  const updateVetList = vetList?.map((vet) => ({ value: vet.id, label: vet.name }));
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState();

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const renderCustomFields = () => {
    return editPetValues?.custom_fields?.groups.map((group, groupIndex) => (
      <div key={groupIndex}>
        {group.fields.map((field) => {
          return (
            <div key={field.id}>
              <FormItem name={field.id} label={field.field_name} className="mb-2">
                {getComponent(field)}
              </FormItem>
            </div>
          );
        })}
      </div>
    ));
  };

  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (url) => {
        setLoading(false);
        setImageUrl(url);
      });
    }
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const uploadButton = (
    <button
      style={{
        border: 0,
        background: "none",
      }}
      type="button"
    >
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );

  return (
    <CustomModal
      open={isModalOpen}
      title="Edit Pet"
      width="748px"
      heigth="688px"
      onCancel={() => setModalOpen(false)}
      footer={null}
      maskClosable={false}
    >
      <Divider />
      <Form name="editPetForm" onFinish={onFinish} form={form} layout="vertical">
        <FormWraper>
          <FormItem>
            <div className="d-flex justify-content-start">
              <div style={{ width: "35%" }}>
                <AddImageComponent uploadToS3Func={(file) => getImageFromEditPet(file)} initialImageUrl={editPetValues?.logo} />
                <p className="ant-upload-text">Upload image of your pet</p>
                <p className="ant-upload-hint">PNG, JPG, JPEG</p>
              </div>
              {editPetValues?.logo && <Image width={200} height={180} src={editPetValues?.logo || petLogo} />}
              {petLogo && !editPetValues?.logo && <Image width={200} height={180} src={petLogo} />}
            </div>
            {/* <Upload
              name="avatar"
              listType="picture-circle"
              className="avatar-uploader"
              showUploadList={false}
              action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
              beforeUpload={beforeUpload}
              onChange={handleChange}
            >
              {imageUrl ? (
                <img
                  src={imageUrl}
                  alt="avatar"
                  style={{
                    width: "100%",
                  }}
                />
              ) : (
                uploadButton
              )}
            </Upload> */}
          </FormItem>
          <FieldWraper>
            <FormItem
              name="name"
              label="Name"
              rules={[
                {
                  required: true,
                  message: "Please enter Pet Name",
                },
                {
                  min: 3,
                  max: 50,
                  message: "Name must be between 3 and 50 characters",
                },
              ]}
            >
              <Input placeholder="Enter Name of Pet" />
            </FormItem>
            <FormItem name="birth_date" label="Birth Date">
              <DatePicker
                style={{ width: "100%" }}
                type="date"
                placeholder="Select Date of Birth"
                format={"DD/MM/YYYY"}
                disabledDate={(current) => current && current > dayjs().endOf("day")}
              />
            </FormItem>
          </FieldWraper>
          <FieldWraper>
            <FormItem name="type" label="Type">
              <Select
                placeholder="Select Type"
                options={[
                  { value: "Cat", label: "Cat" },
                  { value: "Dog", label: "Dog" },
                ]}
              />
            </FormItem>
            <FormItem name="sex" label="Sex">
              <Select
                placeholder="Select Sex"
                options={[
                  { value: "Male", label: "Male" },
                  { value: "Female", label: "Female" },
                ]}
              />
            </FormItem>
          </FieldWraper>
          <FieldWraper>
            <FormItem
              name="color"
              label="Color"
              rules={[
                {
                  min: 3,
                  max: 50,
                  message: "Color must be between 3 and 50 characters",
                },
              ]}
            >
              <Input placeholder="Enter Color" />
            </FormItem>
            <FormItem
              name="breed"
              label="Breed"
              rules={[
                {
                  min: 3,
                  max: 50,
                  message: "Breed must be between 3 and 50 characters",
                },
              ]}
            >
              <Input placeholder="Enter Breed" />
            </FormItem>
          </FieldWraper>
          <FieldWraper>
            <FormItem name="neutered" label="Neutered / Sprayed">
              <Select
                placeholder="Select Option"
                options={[
                  { value: false, label: "No" },
                  { value: true, label: "Yes" },
                ]}
              />
            </FormItem>
            <FormItem name="vaccination_current" label="Vaccinated">
              <Select
                placeholder="Select Option"
                options={[
                  { value: false, label: "No" },
                  { value: true, label: "Yes" },
                ]}
              />
            </FormItem>
          </FieldWraper>
          <FieldWraper>
            <FormItem name="primary_vet_name" label="Primary Vet">
              <Select placeholder="Select Vet" options={updateVetList} />
            </FormItem>
            <FormItem name="alternate_vet_name" label="Alternative Vet">
              <Select placeholder="Select Vet" options={updateVetList} />
            </FormItem>
          </FieldWraper>
          <FieldWraper>
            <FormItem name="micro_chip" label="Microchip ID">
              <Input placeholder="Enter Microchip ID" />
            </FormItem>
            <FormItem
              name="client_name"
              label="Client"
              rules={[
                {
                  message: "Please select a Client",
                },
              ]}
            >
              <Input placeholder="Select Client" disabled />
            </FormItem>
          </FieldWraper>
          <FormItem
            name="notes"
            label="Notes"
            rules={[
              {
                min: 10,
                max: 255,
                message: "Notes must be between 10 and 255 characters",
              },
            ]}
          >
            <Input.TextArea placeholder="Type notes here..." />
          </FormItem>
          {renderCustomFields()}
        </FormWraper>
        <Divider />
        <div className="d-flex justify-content-end">
          <CustomBtnWithIcon
            btnText={"Cancel"}
            margin="0px 5px"
            noBackground
            hideIcon={true}
            type="button"
            onClick={() => {
              setModalOpen(false);
            }}
          />
          <CustomBtnWithIcon btnText={"Save Changes"} type="submit" hideIcon={true} />
        </div>
      </Form>
    </CustomModal>
  );
};

const FormWraper = styled.div`
  height: 556px;
  overflow: auto;

  ::-webkit-scrollbar {
    width: 0px;
  }
`;

const FieldWraper = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
`;

const FormItem = styled(Form.Item)`
  width: 100%;
`;

export default EditPetModal;
