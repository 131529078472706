import { Space } from "antd";
import styled from "styled-components";
import { ReactComponent as Add } from "../../assets/rawSvgs/add.svg";
import { ReactComponent as EditIcon } from "../../assets/rawSvgs/tableEditIcon.svg";
import { ReactComponent as DeleteIcon } from "../../assets/rawSvgs/tableDeleteIcon.svg";
import CustomBtnWithIcon from "../../components/buttonWithIcon";

const UserManagementListColumns = ({ handleDelete, handleEditRow, handleAdd, handleSendForgetPasswordReq }) => {
  let columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 200,
      render: (text, record) => <>{record.name || ""}</>,
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      width: 200,

      render: (text, record) => <>{record?.usercompanyrole?.role?.name || ""}</>,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 200,

      render: (text, record) => <>{record.user.email || ""}</>,
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      render: (text, record) => <>{record.phone_number}</>,
    },
    {
      title: "",
      key: "action",
      align: "middle",
      render: (_, record) => (
        <Space size="middle">
          <CustomBtnWithIcon
            btnText={"Send Password Recovery Email"}
            hideIcon
            noBackground
            onClick={() => handleSendForgetPasswordReq(record)}
          />
          <Button
            onClick={(event) => {
              event.stopPropagation();
              handleDelete && handleAdd(record);
            }}
          >
            <Add />
          </Button>
          <Button
            onClick={(event) => {
              event.stopPropagation();
              handleEditRow && handleEditRow(record);
            }}
          >
            <EditIcon />
          </Button>
          <Button
            onClick={(event) => {
              event.stopPropagation();
              handleDelete && handleDelete(record);
            }}
          >
            <DeleteIcon />
          </Button>
        </Space>
      ),
    },
  ];

  return columns;
};

export { UserManagementListColumns };

const tagStyle = {
  backgroundColor: "#EFF1F5",
  fontWeight: 500,
  border: "none",
  borderRadius: "28px",
  padding: "2px 8px 2px 6px",
  color: "#023047",
};

const Button = styled.button`
  border: none;
  background: none;
`;
