import React, { useState } from "react";
import CustomBtnWithIcon from "../../../../components/buttonWithIcon";

import { Dropdown, Drawer, Space, Checkbox } from "antd";
import {
  DropDownDots,
  FullScreenIcon,
  SortPeopleIcon,
  DropDownIcon,
  FilterIcon,
  LogoutIcon,
  ScheduleClose,
  BlockService,
  CloseIcon,
  SchedulerBulkUpdate,
} from "../../../../assets/rawSvgs/index";
import HeadingComponent from "../../../../components/Heading";
import FilterDrawerForm from "./SchedulerFilterForm";

import { SpaceCompactItemContext } from "antd/es/space/Compact";
import { schedulerDropDownMenu, threeDotsDropdownMenu } from "./DataFile";
import AntDesignDropDownSelect from "../../../../components/AntDesignDropDownSelect/AntDesignDropDownSelect";

//
const SchedulerEventsTopFilters = ({ topFiltersState, selectCalenderView, handleSelectSchedulerDropDown }) => {
  const [openFilterDrawer, setOpenFilterDrawer] = useState(false);
  const showDrawer = () => {
    setOpenFilterDrawer(true);
  };
  const onClose = () => {
    setOpenFilterDrawer(false);
  };
  // console.log("topFiltersState x", topFiltersState.setCalenderFiltersState);
  const applyFilters = () => {
    topFiltersState?.handleApplyFilters();
  };

  const Statusoptions = [
    {
      label: "Pending",
      value: "pending",
    },
    {
      label: "Projected",
      value: "projected",
    },
    {
      label: "Scheduled",
      value: "scheduled",
    },
    {
      label: "In progress",
      value: "in_progress",
    },
    {
      label: "Completed",
      value: "completed",
    },
  ];

  const statusesArray = topFiltersState?.calenderFiltersState?.status?.split("&").map((param) => {
    const [key, value] = param.split("=");
    const option = Statusoptions.find((option) => option.value === value);
    if (option) {
      return { value, label: option.label };
    }
  });

  const handleClientChange = (selectedClient) => {
    const clientValue = selectedClient || ""; // Convert undefined to empty string

    topFiltersState.setCalenderFiltersState((prevState) => ({
      ...prevState,
      client: clientValue,
    }));
  };

  const handleStaffChange = (selectedStaff) => {
    const staffValue = selectedStaff || ""; // Convert undefined to empty string

    topFiltersState.setCalenderFiltersState((prevState) => ({
      ...prevState,
      staff: staffValue,
    }));
  };

  return (
    <>
      <CustomBtnWithIcon
        btnText={
          <div className="d-flex align-content-center ">
            <div className="">
              <FilterIcon />
            </div>
            <span className="ms-2">Filters</span>
          </div>
        }
        hideIcon
        noBackground
        margin="0px 5px"
        padding="10px 8px 10px 13px"
        onClick={showDrawer}
      />

      <DropDownSchedulerThreeDots
        items={schedulerDropDownMenu(handleSelectSchedulerDropDown)}
        btn={
          <CustomBtnWithIcon
            btnText={
              <div className="d-flex align-content-center ">
                <span className="me-2">Schedule</span>
                <div className="">
                  <DropDownIcon stroke="black" style={{ transform: "rotate(269deg)" }} />
                </div>
              </div>
            }
            hideIcon
          />
        }
      />
      <DropDownSchedulerThreeDots items={threeDotsDropdownMenu} />

      <Drawer
        placement={"right"}
        title={
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <h5>Filters</h5>
            </div>
            <div className="cursorPointer" onClick={onClose}>
              <CloseIcon />
            </div>
          </div>
        }
        closable={false}
        onClose={onClose}
        open={openFilterDrawer}
        key={"right"}
        header={null}
        footer={
          <div className="d-flex justify-content-end">
            <CustomBtnWithIcon btnText={"Cancel"} hideIcon noBackground className="me-2" onClick={onClose} />

            <CustomBtnWithIcon
              btnText={"Apply Filters"}
              hideIcon
              noBackground={false}
              onClick={applyFilters}
              disabled={topFiltersState?.btnLoader}
            />
          </div>
        }
      >
        <div className="my-3">
          <HeadingComponent text="Client" fontSize={"12px"} />
          <AntDesignDropDownSelect
            placeholder="Select Client"
            options={topFiltersState?.clientState || []}
            defaultValue="Select Client"
            onChange={(selectedClient) => handleClientChange(selectedClient)}
            allowClear
          />
        </div>
        <div className="my-3">
          <HeadingComponent text="Staff" fontSize={"12px"} />
          <AntDesignDropDownSelect
            placeholder="Select Staff"
            options={topFiltersState?.staffState || []}
            defaultValue="Select Staff"
            onChange={(selectedStaff) => handleStaffChange(selectedStaff)}
            allowClear
          />
        </div>

        <div className="my-3">
          <HeadingComponent text="Status" fontSize={"12px"} />
          <AntDesignDropDownSelect
            placeholder="Select Status"
            mode="multiple"
            style={{ maxHeight: "150px", overflow: "auto", width: "100%" }}
            defaultValue={statusesArray}
            options={Statusoptions}
            onChange={(data) => {
              const queryString = data.map((status) => `status=${status}`).join("&");

              topFiltersState.setCalenderFiltersState({
                ...topFiltersState.calenderFiltersState,
                status: queryString,
              });
            }}
          />
        </div>
      </Drawer>
    </>
  );
};

export default SchedulerEventsTopFilters;

const DropDownSchedulerThreeDots = ({ items, btn }) => (
  <Dropdown
    menu={{
      items,
    }}
    trigger={["click"]}
  >
    <a onClick={(e) => e.preventDefault()}>
      {btn ? (
        btn
      ) : (
        <CustomBtnWithIcon
          btnText={<DropDownDots stroke="black" />}
          hideIcon
          noBackground
          margin="0px 5px"
          padding="10px 8px 10px 13px"
        />
      )}
    </a>
  </Dropdown>
);
