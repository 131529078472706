import React, { useEffect, useState } from "react";

import CustomBtnWithIcon from "../../../components/buttonWithIcon";

import { useLocation, useParams } from "react-router-dom";
import { NavigationHeading, TableHeader } from "./clientStyles";
import AddNewClientForm from "./AddNewInvoiceForm";

import { Col, Modal, Row } from "antd";

import PermissionModal from "../../../components/Modals/PermissionModal";
import { checkPermission } from "../../../util/commonUtil";

import { invoicingTaleHeaders } from "../../../constants/CompanyTableHeaders/invoicingTableHeaders";
import SearchInput from "../../../components/SearchInput";
import AntDesignDropDownSelect from "../../../components/AntDesignDropDownSelect/AntDesignDropDownSelect";
import { AntdesignTablePagination } from "../../../components/antDesignTable/AntdesignTablePagination";
import EditInvoiceModal from "./invoiceDetails/EditInvoiceModal";
import { useDebounce } from "../../../hooks/useDebounce";
//
const Invoicing = ({
  getManagementClients,
  invoicingState,
  fetchAllInvoice,
  loginState,
  permissionState,
  clientResponse,
  fromClientManagement,
  clientId,
}) => {
  //

  const [addNewClientModal, setAddNewModal] = useState(false);

  const [openEditClientModal, setOpenEditClientModal] = useState(false);
  const [openEditClientValues, setOpenEditInvoiceValues] = useState({});
  const [deleteRowId, setDeleteRowId] = useState("");
  const [permissionPopUp, setPermissionPopUp] = useState(false);
  const [invoiceStatus, setSelectInvoiceStatus] = useState("");
  const [searchQuery, setSeacrhQuery] = useState("");

  const [pageNumber, setPageNumber] = useState(1);
  let pageSize = 10;
  const debounceZipCode = useDebounce(searchQuery, 600);

  const fetchInvoices = (query) => {
    if (checkPermission(permissionState, "view_invoices")) {
      fetchAllInvoice(query);
    }
  };

  useEffect(() => {
    if (fromClientManagement) {
      fetchInvoices(`client=${clientId}&status=${invoiceStatus}&page=${pageNumber}&search=${searchQuery}`);
      return;
    }
    fetchInvoices(`status=${invoiceStatus}&page=${pageNumber}&search=${searchQuery}`);
  }, [debounceZipCode]);

  useEffect(() => {
    if (checkPermission(permissionState, "view_clients")) {
      if (fromClientManagement) {
        fetchInvoices(`client=${clientId}&status=${invoiceStatus}&page=${pageNumber}&search=${searchQuery}`);
        return;
      }
      fetchInvoices(`status=${invoiceStatus}&page=${pageNumber}&search=${searchQuery}`);
    }
  }, [permissionState, invoiceStatus, pageNumber]);

  useEffect(() => {
    if (checkPermission(permissionState, "view_clients")) {
      getManagementClients();
    }
  }, [permissionState]);

  const handleDelete = (row) => {
    if (checkPermission(permissionState, "delete_client")) {
      setDeleteRowId(row?.id);
    } else {
      setPermissionPopUp(true);
    }
  };
  const handleEditRow = (rows) => {
    if (rows) {
      // setOpenEditInvoiceValues(rows);
      if (checkPermission(permissionState, "edit_invoice")) {
        setOpenEditInvoiceValues(rows);
        setOpenEditClientModal(true);
      } else {
        setPermissionPopUp(true);
      }
    }

    // if (checkPermission(permissionState, "edit_client")) {
    //   setOpenEditClientModal(true);
    // } else {
    //   setPermissionPopUp(true);
    // }
  };

  const addNewClient = () => {
    if (checkPermission(permissionState, "create_invoice")) {
      setAddNewModal(true);
    } else {
      setPermissionPopUp(true);
    }
  };

  return (
    <>
      {/* <NoItemsToRender /> */}

      <TableHeader>
        <NavigationHeading>Invoice Management</NavigationHeading>

        {invoicingState.allInvoicing?.responseData?.length > 0 ? (
          <CustomBtnWithIcon btnText={"Generate a new invoice"} noBackground margin="10px" onClick={() => addNewClient()} />
        ) : (
          ""
        )}
      </TableHeader>
      <Row align={"middle"}>
        <Col md={4}>
          <SearchInput
            placeholder="Search by ID.."
            search={searchQuery}
            onChange={(value) => setSeacrhQuery(value)}
            // onBlur={searchBlurHandler}
          />
        </Col>
        <Col md={4} className="ms-3">
          <AntDesignDropDownSelect
            options={[
              { value: "", label: "All" },
              { value: "draft", label: "Draft" },
              { value: "sent_to_client", label: "Sent to Client" },
              { value: "paid", label: "Paid" },
              { value: "void", label: "Void" },
              { value: "partially_paid", label: "Partially Paid" },
              { value: "due_date_passed", label: "Due Date Passed" },
            ]}
            className="w-100"
            defaultValue="All"
            onChange={(e) => {
              setSelectInvoiceStatus(e);
              setPageNumber(1);
            }}
          />
        </Col>
      </Row>
      <AntdesignTablePagination
        columns={invoicingTaleHeaders({ handleDelete, handleEditRow, pageNumber, invoiceStatus })}
        data={invoicingState.allInvoicing?.responseData || []}
        totalCount={invoicingState.allInvoicing?.invoiceRowCount}
        loadPaginatedData={(dpage) => {
          setPageNumber(dpage);
        }}
        rowKey="id"
        pageSize={pageSize}
        allowRowSelection={false}
        loading={invoicingState?.allInvoicing.isLoading}
      />

      {addNewClientModal && (
        <AddNewClientForm
          visible={addNewClientModal}
          handleClose={() => setAddNewModal(false)}
          clientList={clientResponse.clientList}
          companyId={loginState?.selectCompany?.[0]?.company_id || 0}
          fetchAllInvoice={fetchInvoices}
          clientId={clientId}
          fromClientManagement={fromClientManagement}
        />
      )}

      {openEditClientModal && (
        <EditInvoiceModal
          visible={openEditClientModal}
          handleClose={() => {
            setOpenEditClientModal(false);
          }}
          compId={loginState?.selectCompany[0]?.company_id}
          invoiceData={openEditClientValues}
          invoiceId={openEditClientValues.id}
          onSuccess={() => {
            fetchInvoices(`status=${invoiceStatus}&page=${pageNumber}`);
          }}
        />
      )}

      <PermissionModal onclose={() => setPermissionPopUp(false)} openModal={permissionPopUp} />
    </>
  );
};

export default Invoicing;
