import { Dropdown, Space } from "antd";
import { DropDownDots } from "../../assets/rawSvgs";
import { CommonActionHeaders } from "../TableActionHeaderColumn";
import BDropdown from "react-bootstrap/Dropdown";
import moment from "moment";
import { ReactComponent as EditIcon } from "../../assets/rawSvgs/tableEditIcon.svg";
import { ReactComponent as DeleteIcon } from "../../assets/rawSvgs/tableDeleteIcon.svg";
import { ReactComponent as DisabledIcon } from "../../assets/rawSvgs/disabledIcon.svg";
import { ReactComponent as EnableService } from "../../assets/rawSvgs/enableService.svg";

const ServiceHeaders = ({
  items,
  setUpdateServiceFormModal,
  setUpdateServiceValues,
  handleDisableRow,
  setDeleteRowId,
  setServiceDelete,
  SelectTab,
  sidebarDrOpen,
  handleFetchPromoCode,
  editService,
  deleteService,
}) => {
  return [
    {
      title: "Service Name",
      dataIndex: "name",
      key: "name",
      width: 260,
      render: (text, record) => <a onClick={() => sidebarDrOpen(record)}>{text}</a>,
    },
    {
      title: "Cost",
      dataIndex: "cost",
    },

    {
      title: "Staff Rate",
      dataIndex: "default_staff_rate",
    },
    {
      title: "Taxable",
      dataIndex: "taxable",
      render: (text) => <a>{text ? "Yes" : "No"}</a>,
    },
    {
      title: "Actions",
      key: "action",
      align: "center",
      render: (_, record) => (
        <>
          <Dropdown
            menu={{
              items: [
                {
                  label: (
                    <div
                      style={{ width: "80px", padding: "2px 2px" }}
                      onClick={(e) => {
                        editService(record);
                        // setUpdateServiceFormModal(true);
                        // handleFetchPromoCode(record.promo_code);
                        // setUpdateServiceValues(record);
                      }}
                    >
                      <span className="me-3">
                        <EditIcon />
                      </span>
                      Edit
                    </div>
                  ),
                  key: "0",
                },
                {
                  label: (
                    <div
                      style={{ width: "80px", padding: "2px 0px" }}
                      className="d-flex justify-content-evenly"
                      onClick={() => {
                        if (record.id) {
                          handleDisableRow(record);
                        }
                      }}
                    >
                      <span className="me-3">{SelectTab === "Disabled Services" ? <EnableService /> : <DisabledIcon />}</span>
                      {SelectTab === "Disabled Services" ? "Enable" : "Disable"}
                    </div>
                  ),
                  key: "1",
                },

                {
                  label: (
                    <div
                      style={{ width: "80px", padding: "2px 0px" }}
                      className="d-flex justify-content-evenly"
                      onClick={() => {
                        deleteService(record);
                        // setDeleteRowId(record?.id);
                        // setServiceDelete(true);
                      }}
                    >
                      <span className="me-3">
                        <DeleteIcon />
                      </span>
                      <span>Delete</span>
                    </div>
                  ),
                  key: "3",
                },
              ],
            }}
            trigger={["click"]}
          >
            <a
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              <Space size="middle" className="cursorPointer" style={{ transform: "rotate(270deg)" }}>
                <DropDownDots />
              </Space>
            </a>
          </Dropdown>
          {/* <BDropdown>
            <BDropdown.Toggle
              variant="success"
              id="dropdown-basic"
              drop="start"
              style={{ background: "none", border: "none", padding: 0, marginRight: "-12px", outline: "none" }}
            >
              <a onClick={(e) => e.preventDefault()}>
                <Space size="middle" className="cursorPointer" style={{ transform: "rotate(270deg)" }}>
                  <DropDownDots />
                </Space>
              </a>
            </BDropdown.Toggle>

            <BDropdown.Menu
              style={{
                border: "1px solid #E0E0E0",
                borderRadius: "10px",
                boxShadow: "rgb(0 0 0 / 41%) 1px 1px 7px -3px",
              }}
            >
              {items?.map((items, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      margin: "8px 0px",
                      // minWidth: "180px",
                      display: "flex",
                      alignItems: "center",
                      padding: "0px 12px",
                      cursor: "pointer",
                    }}
                    onClick={() => items?.label?.props.onClick?.(record)}
                  >
                    {items?.label}
                  </div>
                );
              })}
            </BDropdown.Menu>
          </BDropdown> */}
        </>
      ),
    },
  ];
};
const renderDateRange = (dates) => {
  if (!dates || dates.length === 0) {
    return null;
  } else if (dates.length === 1) {
    return moment(dates[0]).format("DD MMMM YYYY");
  } else if (dates.length === 2) {
    const startDate = moment(dates[0]).format("DD MMMM YYYY");
    const endDate = moment(dates[1]).format("DD MMMM YYYY");
    if (startDate === endDate) {
      return startDate;
    } else {
      return `${startDate} - ${endDate}`;
    }
  }
  return null;
};

const holidaysHeaders = ({ items = [] }) => {
  return [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",

      render: (text) => <a>{text}</a>,
    },
    {
      title: "When",
      dataIndex: "dates",

      render: (text) => <a>{renderDateRange(text)}</a>,
    },

    {
      title: "Rate",
      dataIndex: "cost",
      render: (text, record) => <a>{record.cost_type === 1 ? record.cost + "%" : record.cost + "$" || 0}</a>,
    },

    {
      title: "Actions",
      key: "action",
      align: "center",
      render: (_, record) => (
        <BDropdown>
          <BDropdown.Toggle
            variant="success"
            id="dropdown-basic"
            style={{ background: "none", border: "none", padding: 0, marginRight: "-12px", outline: "none" }}
          >
            <a onClick={(e) => e.preventDefault()}>
              <Space size="middle" className="cursorPointer" style={{ transform: "rotate(270deg)" }}>
                <DropDownDots />
              </Space>
            </a>
          </BDropdown.Toggle>

          <BDropdown.Menu
            style={{
              border: "1px solid #E0E0E0",
              borderRadius: "10px",
              boxShadow: "rgb(0 0 0 / 41%) 1px 1px 7px -3px",
            }}
          >
            {items?.map((items, index) => {
              return (
                <div
                  key={index}
                  style={{
                    margin: "8px 0px",
                    // minWidth: "180px",
                    display: "flex",
                    alignItems: "center",
                    padding: "0px 12px",
                    cursor: "pointer",
                  }}
                  onClick={() => items?.label?.props.onClick?.(record)}
                >
                  {items?.label}
                </div>
              );
            })}
          </BDropdown.Menu>
        </BDropdown>
      ),
    },
  ];
};

export { ServiceHeaders, holidaysHeaders };
