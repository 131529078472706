import React from "react";
import { Select, Space } from "antd";
import "./style.css";
import { ReactComponent as DropDownIcon } from "../../assets/rawSvgs/selectDropDownIcon.svg";
//
const AntDesignDropDownSelect = ({
  onChange,
  defaultValue = "Status",
  placeholder = "Status",
  allowClear,
  options = [
    { value: "jack", label: "Jack" },
    { value: "lucy", label: "Lucy" },
    { value: "Yiminghe", label: "yiminghe" },
    { value: "disabled", label: "Disabled", disabled: true },
  ],
  height = 40,
  width = "100%",
  ...props
}) => {
  return (
    <div>
      <Select
        style={{ width: width, height: height }}
        suffixIcon={<DropDownIcon />}
        onChange={onChange}
        options={options}
        showSearch
        placeholder={placeholder}
        defaultValue={defaultValue}
        {...props}
        {...(allowClear
          ? {
              allowClear: allowClear,
            }
          : {})}
      />
    </div>
  );
};

export default AntDesignDropDownSelect;
