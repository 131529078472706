import React, { useEffect, useRef, useState } from "react";
import ReactAvatar from "react-avatar";
import { Avatar, Tooltip } from "antd";
import { ChatHeaderSection } from "./Chat.styled";

import HeadingComponent from "../../../components/Heading";
import { ReactComponent as PinMsg } from "../../../assets/rawSvgs/pinMsg.svg";
import { ReactComponent as SettingSvg } from "../../../assets/rawSvgs/setttingMsg.svg";
import { ReactComponent as MagnifierIcon } from "../../../assets/rawSvgs/magnifier.svg";
import { ReactComponent as ChatEmoj } from "../../../assets/rawSvgs/chatEmoj.svg";
import { ReactComponent as ReminderIcon } from "../../../assets/rawSvgs/reminderIcon.svg";
import { DeleteIcon } from "../../../assets/rawSvgs/index";

import CustomBtnWithIcon from "../../../components/buttonWithIcon";

import { ChannelSettingsModal, MembersModal, PinnedMessages, ScheduleMsgModal } from "./Modals";

import styled from "styled-components";
import { usePubNub } from "pubnub-react";
import moment from "moment";
import { emojiDescriptions } from "./utilsFunctions";

//
export const ChatHeaderComponent = ({ selectedChannelDetail, headerChannelName, pinMessages, ...rest }) => {
  const [addMemberModal, setOpenAddMemberModal] = useState(false);
  const [settingsModal, setSettingsModal] = useState(false);
  const [pinnedMessagesModal, setPinnedMessagesModal] = useState(false);

  let channelMembers = rest.totalChannelMembers;
  let listOfUsers = rest?.ListOfUsers;

  let membersModalObj = {
    ...rest,
    pubnubUuid: rest?.pubnubUuid,
    channelMembers,
    listOfUsers,
  };

  return (
    <div>
      <ChatHeaderSection className="py-2 px-3">
        {/* HEADER START */}

        <div className="d-flex justify-content-between align-items-center px-1 mt-">
          <div className="d-flex align-items-center mt-">
            {selectedChannelDetail.directMsg ? (
              <HeadingComponent text={headerChannelName} fontSize="20px" fontWeight={600} />
            ) : (
              <>
                <HeadingComponent text={"#"} fontSize="20px" fontWeight={600} />
                <HeadingComponent text={rest?.channelsDetails?.name} fontSize="20px" fontWeight={600} color margin={"0px 5px"} />
              </>
            )}
            <div className="ms-3 ">{/* {!selectedChannelDetail.directMsg && ( */}</div>
            <CustomBtnWithIcon
              btnText={
                <div className="d-flex">
                  <PinMsg />
                  <HeadingComponent
                    text={`${pinMessages?.length} Pinned messages`}
                    fontSize="14px"
                    fontWeight={500}
                    color
                    margin={"0px 5px"}
                  />
                </div>
              }
              hideIcon
              noBackground
              margin="0px 5px"
              onClick={() => setPinnedMessagesModal(true)}
            />
          </div>
          <div className="d-flex">
            {!selectedChannelDetail.directMsg && (
              <>
                <CustomBtnWithIcon
                  btnText={<MagnifierIcon className=" ms-1" />}
                  hideIcon
                  width="50px"
                  noBackground
                  margin="0px 5px"
                />
                <CustomBtnWithIcon
                  btnText={
                    <div className="d-flex">
                      <div className="">
                        <Avatar.Group shape="circle">
                          {channelMembers?.map((items, index) => {
                            return (
                              <div key={index}>
                                <Avatar>{items?.name?.slice(0, 1)}</Avatar>
                              </div>
                            );
                          })}
                        </Avatar.Group>
                      </div>
                    </div>
                  }
                  hideIcon
                  noBackground
                  onClick={() => setOpenAddMemberModal(true)}
                />
                <CustomBtnWithIcon
                  btnText={<SettingSvg className=" ms-1" />}
                  hideIcon
                  width="50px"
                  noBackground
                  margin="0px 5px"
                  // padding="10px 8px 10px 13px"
                  onClick={() => setSettingsModal(true)}
                />
              </>
            )}
          </div>
        </div>
        {/* HEADER END */}

        {/* MEMBERS MODAL */}
        <MembersModal addMemberModal={addMemberModal} setOpenAddMemberModal={setOpenAddMemberModal} {...membersModalObj} />

        {/* SETTINGS MODAL */}
        {settingsModal && (
          <ChannelSettingsModal
            settingsModal={settingsModal}
            setSettingsModal={setSettingsModal}
            settingModal={true}
            {...rest}
            update={true}
            closeModal={() => {
              setSettingsModal(false);
            }}
          />
        )}
        {/* Pinned messages */}
        {pinnedMessagesModal && (
          <PinnedMessages
            pinnedMessagesModal={pinnedMessagesModal}
            setPinnedMessagesModal={setPinnedMessagesModal}
            pinMessages={pinMessages}
            {...membersModalObj}
          />
        )}
        {/* Add new channel */}
      </ChatHeaderSection>
    </div>
  );
};

export const UserMessageComponent = ({
  chatterName,
  avatar,
  msg,
  timeToken,
  onlyCompId,
  currentChannel,
  reactionsData,
  emojiClick,
  fromPinMsgModal,
  pickerRef,
  handlePinnedMasg,
  pinMessages,
  allStateMsg,
  fromPinnedModal,
  handleDeleteMessage,
  fromInternalChat,
  ...props
}) => {
  const [openEmoji, setOpenEmoji] = useState(null); // State to track which message has the emoji picker open
  const [showReactions, setShowReactions] = useState(false);
  const [openPinnedMsgModal, setopenPinnedMsgModal] = useState(false);
  const pubnub = usePubNub();
  const currentUserId = pubnub.getUUID(); // Assuming pubnub.uuid refers to the current user's UUID

  const emojiPickerRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (emojiPickerRef.current && !emojiPickerRef.current.contains(event.target)) {
        setOpenEmoji(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [emojiPickerRef]);
  //
  const handleEmojiButtonClick = (messageId) => {
    setOpenEmoji((prev) => (prev === messageId ? null : messageId)); // Toggle emoji picker for the specific message
  };

  const emojiKeys = Object.keys(reactionsData || {});

  // Check if there are reactions
  const hasReactions = emojiKeys?.length > 0;

  // Render reactions if available

  const handleCheckPinCondition = (incom) => {
    let alreadyPinned = false;
    let actionTimetoken;

    // Check for pin actions and determine if the message is already pinned
    if (incom.actions && incom.actions.pin) {
      const pinActions = incom.actions.pin;
      for (const adminName in pinActions) {
        const actionsArray = pinActions[adminName];
        for (const action of actionsArray) {
          if (action.uuid === currentUserId) {
            alreadyPinned = true;
            actionTimetoken = action.actionTimetoken;
            break;
          }
        }
        if (alreadyPinned) break; // Exit outer loop if already pinned
      }
    }
    return alreadyPinned;
  };

  const renderMessageImage = (id, name) => {
    if (!id || !name) return;
    try {
      let result = pubnub.getFileUrl({
        channel: allStateMsg.channel,
        id: id,
        name: name,
      });
      // console.log("result", result);

      return result;
    } catch (error) {
      console.log("ERROR", error);
    }
  };
  // console.log("MESAGE TYPE", allStateMsg);
  let imageSrc = renderMessageImage(allStateMsg?.message?.file?.id, allStateMsg?.message?.file?.name);
  let ismessageFileType = allStateMsg?.messageType === 4 || allStateMsg?.message?.file;
  let imageOrVideo = allStateMsg?.message?.message?.type === "video" ? true : false;

  return (
    <MessageContainer
      onMouseEnter={() => setShowReactions(true)}
      onMouseLeave={() => setShowReactions(false)}
      className="my-2"
      width={fromPinnedModal && "100%"}
    >
      <div className="d-flex align-items-center ">
        {ismessageFileType ? (
          <>
            <ReactAvatar
              name={allStateMsg?.message?.message?.user?.name}
              round={true}
              size="28"
              textSizeRatio={1.5}
              classNamemt
              src={avatar}
            />

            <HeadingComponent text={allStateMsg?.message?.message?.user?.name} fontSize="14px" margin={"13px 10px"} />
            <HeadingComponent
              text={moment(parseInt(allStateMsg?.timetoken?.substring(0, 13))).format("h:mm A")}
              fontSize="12px"
              fontWeight={400}
              color="#212121"
            />
          </>
        ) : (
          <>
            <ReactAvatar name={chatterName} round={true} size="28" textSizeRatio={1.5} classNamemt src={avatar} />
            <HeadingComponent text={chatterName} fontSize="14px" margin={"13px 10px"} />
            <HeadingComponent text={timeToken} fontSize="12px" fontWeight={400} color="#212121" />
          </>
        )}
      </div>
      <div className="w-75">
        {ismessageFileType ? (
          <>
            {imageOrVideo ? (
              <>
                <video controls src={imageSrc} style={{ maxWidth: "50%", marginTop: 20, maxHeight: "350px" }} />
                {/* <video controls src={imageSrc} style={{ maxWidth: "100%", marginTop: 20 }} /> */}
              </>
            ) : (
              <ImageContainer>{imageSrc && <Image src={imageSrc} alt="Message Image" />}</ImageContainer>
            )}
          </>
        ) : (
          <HeadingComponent text={msg} fontSize="14px" fontWeight={400} color="#212121" margin={"4px"} />
        )}
      </div>
      {hasReactions && (
        <ReactionsContainerv2 top={ismessageFileType && "100%"}>
          <div className="ReactionsContainer mt-1 d-flex">
            {/* Map through emoji keys */}
            {emojiKeys?.map((emoji) => (
              <div key={emoji} className="mx-1">
                {/* Display emoji */}
                <span style={{ fontSize: "12px" }}>{emoji}</span>
                {/* Display length of emoji array */}
                <span style={{ fontSize: "10px" }}> {reactionsData[emoji]?.length}</span>
              </div>
            ))}
          </div>
          {/* This component should show the reactions and allow users to react */}
        </ReactionsContainerv2>
      )}

      {true && (
        <div className="d-flex">
          {allStateMsg?.message?.user?._id === props?.adminId && !fromInternalChat && (
            <Tooltip title="Delete this Message" className="ms-2">
              <EmojiButton onClick={() => handleDeleteMessage(props)} right="130px" top={ismessageFileType ? "2%" : "10%"}>
                <DeleteIcon height={"15px"} width="15px" />
              </EmojiButton>
            </Tooltip>
          )}
          <Tooltip title="Remind me later" onClick={() => setopenPinnedMsgModal(true)}>
            <EmojiButton right="50px" top={ismessageFileType ? "2%" : "10%"}>
              <ReminderIcon height={"15px"} width="15px" />
            </EmojiButton>
          </Tooltip>

          <Tooltip title={handleCheckPinCondition(props) ? "Un-pin this message" : "Pin this message"}>
            <EmojiButton right="90px" onClick={() => handlePinnedMasg(allStateMsg)} top={ismessageFileType ? "2%" : "10%"}>
              <PinMsg height={"15px"} width="15px" />
            </EmojiButton>
          </Tooltip>
          <Tooltip title="React">
            <EmojiButton onClick={() => handleEmojiButtonClick(props?.message?.id)} top={ismessageFileType ? "2%" : "10%"}>
              <ChatEmoj height={"15px"} width="15px" />
            </EmojiButton>
          </Tooltip>
        </div>
      )}
      {openEmoji === props?.message?.id && showReactions && (
        <div
          ref={emojiPickerRef}
          style={{
            position: "absolute",
            bottom: ismessageFileType ? "99%" : "92%", // Position above the message
            right: 20, // Align to the left edge of the message container
            zIndex: 1000, // Ensure it appears above other elements
          }}
        >
          <div
            style={{
              display: "flex",
              background: "rgb(244, 244, 244)",
              border: "1px solid #ccc",
              borderRadius: "8px",

              alignItems: "center",
              height: "30px",
            }}
          >
            {Object.keys(emojiDescriptions).map((emoji, index) => (
              <div
                style={{
                  left: "201px", // Align to the right edge
                  zIndex: 10000, // Ensure it appears above other elements
                  display: "flex",
                  margin: "3px 4px",
                  cursor: "pointer",
                }}
                ref={pickerRef}
                key={index}
                onClick={() => {
                  if (emojiKeys?.includes(emoji)) {
                    emojiClick(emoji, true);

                    return;
                  }
                  emojiClick(emoji);
                  setOpenEmoji(null);
                }}
              >
                {emoji}
              </div>
            ))}
          </div>
        </div>
      )}
      {openPinnedMsgModal && (
        <ScheduleMsgModal
          setopenPinnedMsgModal={() => setopenPinnedMsgModal(false)}
          openPinnedMsgModal={openPinnedMsgModal}
          onlyCompId={onlyCompId}
          msg={msg}
          currentChannel={currentChannel}
          allStateMsg={allStateMsg}
        />
      )}
    </MessageContainer>
  );
};

const MessageContainer = styled.div`
  position: relative;
  padding: 1rem;
  width: ${(props) => props.width || "60%"};
  padding: 2px 1rem;
  margin: 5px 0px;

  background: #fafafa;
  border-radius: 13px;
`;

const EmojiButton = styled.button`
  position: absolute;
  top: ${(props) => props.top || "19%"};
  right: ${(props) => props.right || "10px"};

  border: 1px solid #f0e5e5;
  border-radius: 12px;
  height: 30px;
  width: 33px;
`;

const ReactionsContainerv2 = styled.div`
  position: absolute;
  top: ${(props) => props.top || "98%"};
  left: 10px;
  height: 22px;
  background: rgb(244 244 244);
  padding: 9px 3px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
`;

const ImageContainer = styled.div`
  width: 300px; /* Adjust as needed */
  height: 300px; /* Adjust as needed */
  overflow: hidden;
  margin-top: 10px;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;
