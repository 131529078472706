import React, { useEffect, useState } from "react";
import { Calendar, Views, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./calender.css";
import { SchedulerMonthScheduledIcon, SchedulerMonthUserIcon } from "../../assets/rawSvgs/index";
import { Navigate } from "react-big-calendar";
import truncateString from "../../util/truncate";
import { Tooltip } from "antd";
const localizer = momentLocalizer(moment);
//
const OneDayResourcesVerticalCalender = ({
  isFiltering = false,
  staffResource,
  handleSelectSlot,
  handleEventClick,
  events = [],
  ...props
}) => {
  return (
    <div className={isFiltering ? "filtering" : null}>
      <Calendar
        localizer={localizer}
        events={events}
        views={["day"]}
        defaultView={Views.DAY}
        step={30}
        resources={staffResource}
        resourceIdAccessor="id"
        resourceTitleAccessor="title"
        defaultDate={new Date()}
        className="day-custom-calender"
        eventPropGetter={(event, start, end, isSelected) => {
          if (event.unavailableSlot || event.commute) {
            return {
              style: event.unavailableSlot ? nonAvaialbleSlotStyle : nonAvaialbleSlotStylev2,
            };
          }
          return {
            style: scheduledEvnetsColor,
          };
        }}
        onSelectSlot={handleSelectSlot}
        onSelectEvent={handleEventClick}
        components={{
          toolbar: CustomHeader,
          event: CustomComponents,
        }}
        formats={formats}
        onSelecting={() => false}
        selectable="ignoreEvents"
        {...props}
      />
    </div>
  );
};

export default OneDayResourcesVerticalCalender;

const CustomComponents = ({ event, title = "" }) => {
  if (event.unavailableSlot) {
    return (
      <Tooltip title="This timeslot is blocked">
        <div className="d-flex">
          <div className="d-flex align-items-center">
            <div className="d-flex flex-column"></div>
          </div>
        </div>
      </Tooltip>
    );
  }
  if (event.commute) {
    return (
      <Tooltip title="Commute duration ">
        <div className="d-flex">
          <div className="d-flex align-items-center">
            <div className="d-flex flex-column"></div>
          </div>
        </div>
      </Tooltip>
    );
  }
  return (
    <div className="d-flex">
      <div className="d-flex align-items-center">
        <div className="d-flex flex-column">
          <span>
            <span className="ms-1 me-1">
              <SchedulerMonthScheduledIcon width="12px" height="12px" stroke="#187BEF" />
            </span>
            {truncateString(title, 11)}
            <span className="ms-1 me-1">
              <SchedulerMonthUserIcon width="12px" height="12px" stroke="#187BEF" />
            </span>
            {truncateString(event?.clientName, 12)}
          </span>
        </div>
      </div>
    </div>
  );
};

const scheduledEvnetsColor = {
  fontColor: "rgb(24, 123, 239)",
  backgroundColor: "rgb(229, 246, 255)",
  border: "1px solid rgb(24, 123, 239)",
  color: "rgb(24, 123, 239)",
};

const nonAvaialbleSlotStyle = {
  border: "2px solid #f5f5f5",
  borderRadius: "4px",
  zIndex: 1,
  background:
    "repeating-linear-gradient(45deg, rgb(224, 224, 224), rgb(224, 224, 224) 3px, rgb(245, 245, 245) 3px, rgb(245, 245, 245) 5px)",
};

const nonAvaialbleSlotStylev2 = {
  border: "2px solid #f5f5f5",
  borderRadius: "4px",
  zIndex: 1,
  background:
    "repeating-linear-gradient(317deg, rgb(224, 224, 224), rgb(224, 224, 224) 3px, rgb(245, 245, 245) 3px, rgb(245, 245, 245) 5px)",
};

const formats = {
  eventTimeRangeFormat: () => {
    return "";
  },
};

const CustomHeader = () => {
  return null; // Return null to hide the header
};
