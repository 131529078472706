import React from "react";
import { DatePicker, Divider, Flex, Form, Input, message, Modal, Select, Typography, Upload } from "antd";
import { ReactComponent as DefaultImageIcon } from "../../../assets/rawSvgs/defaultImage.svg";
import CustomInput from "../../CustomInput";
import styled from "styled-components";
import { ErrorMessage, Field, Formik } from "formik";
import { addVaccinationSchema } from "../../../constants/CompanyFormFieldsSchema/FormSchema";
import FormikSelect from "../../formikSelect/FormikSelect";
import CustomBtnWithIcon from "../../buttonWithIcon";
import dayjs from "dayjs";
import { CustomModal } from "../ModalStyles";
const { Dragger } = Upload;

const EditVaccinationModal = ({
  isLoading,
  isModalOpen,
  setModalOpen,
  onFinish,
  EditVaccinationModal,
  petList,
  editVaccinationValues,
  detailPage,
}) => {
  const [form] = Form.useForm();
  form.resetFields();
  form.setFieldsValue({
    type: editVaccinationValues?.type,
    pet: editVaccinationValues?.pet,
    date: dayjs(editVaccinationValues?.date),
    expiry_date: dayjs(editVaccinationValues?.expiry_date),
  });

  const updatePetList = petList.map((pet) => ({ value: pet.id, label: pet.name }));

  return (
    <CustomModal
      open={isModalOpen}
      title="Edit Vaccination type"
      width="600px"
      heigth="310px"
      onCancel={() => setModalOpen(false)}
      footer={null}
      maskClosable={false}
    >
      <Divider />
      <Form name="editVaccinationForm" onFinish={onFinish} form={form} layout="vertical">
        <FormItem name="type" label="Type">
          <Input placeholder="Enter Type" />
        </FormItem>
        <FormItem
          name="date"
          label="Date"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <DatePicker style={{ width: "100%" }} type="date" placeholder="Choose Due-in Date" format={"DD/MM/YYYY"} />
        </FormItem>
        <FormItem name="pet" label="Select Pet">
          <Select placeholder="Select Pet" options={updatePetList} />
        </FormItem>
        <FormItem
          name="expiry_date"
          label="Expiration Date"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <DatePicker style={{ width: "100%" }} type="date" placeholder="Choose date of birth" format={"DD/MM/YYYY"} />
        </FormItem>
        <Divider />
        <div className="d-flex justify-content-end">
          <CustomBtnWithIcon
            btnText={"Cancel"}
            margin="0px 5px"
            noBackground
            hideIcon={true}
            onClick={() => setModalOpen(false)}
          />
          <CustomBtnWithIcon btnText={"Save Changes"} type="submit" hideIcon={true} />
        </div>
      </Form>
    </CustomModal>
  );
};

const FieldWraper = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
`;

const FormItem = styled(Form.Item)`
  width: 100%;
`;

export default EditVaccinationModal;
