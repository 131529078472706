import { SchedulerStatus } from "../../enums/scheduler";
export const schedulerBackground = (event) => {
  if (event.status === SchedulerStatus.SCHEDULED) {
    return "#E5F6FF";
  } else if (event.status === SchedulerStatus.IN_PROGRESS) {
    return "#FFF3D3";
  } else if (event.status === SchedulerStatus.COMPLETED) {
    return "#EEF8EE";
  } else if (event.status === SchedulerStatus.APPROVED) {
    return "#EEF8EE";
  } else if (event.status === SchedulerStatus.REJECTED) {
    return "#FBEEED";
  }
  return "#E5F6FF";
};

export const schedulerColor = (event) => {
  if (event.status === SchedulerStatus.SCHEDULED) {
    return "#197BEF";
  } else if (event.status === SchedulerStatus.IN_PROGRESS) {
    return "#B45309";
  } else if (event.status === SchedulerStatus.COMPLETED) {
    return "#00914B";
  } else if (event.status === SchedulerStatus.APPROVED) {
    return "#00914B";
  } else if (event.status === SchedulerStatus.REJECTED) {
    return "#E53939";
  }
  return "#197BEF";
};

export const schedulerStatus = (event) => {
  if (event.status === SchedulerStatus.SCHEDULED) {
    return "Scheduled";
  } else if (event.status === SchedulerStatus.IN_PROGRESS) {
    return "In Progress";
  } else if (event.status === SchedulerStatus.COMPLETED) {
    return "Completed";
  } else if (event.status === SchedulerStatus.CHANGE_REQUESTED) {
    return "Change Requested";
  } else if (event.status === SchedulerStatus.UNASSIGNED) {
    return "Unassigned";
  } else if (event.status === SchedulerStatus.REQUESTED) {
    return "Requested";
  } else if (event.status === SchedulerStatus.CANCELLED) {
    return "Cancelled";
  }
  return event.status;
};
