import React, { useState } from "react";
import styled from "styled-components";
import { ReactComponent as OrganSelcIcon } from "../../assets/images/orgIcon.svg";
import { ReactComponent as EmailIcon } from "../../assets/rawSvgs/EmailIcon.svg";
import { ReactComponent as TextMessageIcon } from "../../assets/rawSvgs/TextMessageIcon.svg";
import { ReactComponent as GoogleAuthIcon } from "../../assets/rawSvgs/GoogleAuthIcon.svg";
import HeadingComponent from "../../components/Heading";
import { Button, Card, Col, Form, Image, Input, Radio, Row } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { FlexedInput } from "../../components/AuthPetsScreen/AuthPetsScreenStyle";
import AuthButton from "../../components/authButton";
import { pushNotification } from "../../util/Notification";
import companyLogo from "../../assets/images/furgis-logo.svg";
import ArrowBack from "../../assets/images/arrow-back.svg";
import { enableAuthentication, fetchSignRoles, verifyCode } from "../../util/DataService";
import OtpInput from "react-otp-input";
import { setJWTToken } from "../../api/auth";
import { useSelector, useDispatch } from "react-redux";
import { fetchSingleRole } from "../../redux/slices/permissionSlice";

const ClientVerification = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [selectedVerification, setSelectedVerification] = useState("");
  const [show2FaScreen, setShow2FaScreen] = useState(false);

  return (
    <>
      <div className="text-start m-5 cursorPointer" onClick={() => navigate("/company/signin")} style={{ marginRight: "50px" }}>
        <h4 className="text-start" style={{ cursor: "pointer" }}>
          <BackIcon src={ArrowBack} alt="Arrow Down Icon" /> Back to Login
        </h4>
      </div>
      {show2FaScreen ? (
        <TwoFAScreen
          state={state}
          selectedVerification={selectedVerification}
          setSelectedVerification={setSelectedVerification}
          setShow2FaScreen={setShow2FaScreen}
          otp={otp}
          setOtp={setOtp}
        />
      ) : (
        <SelectVerificationScreen
          state={state}
          selectedVerification={selectedVerification}
          setSelectedVerification={setSelectedVerification}
          setShow2FaScreen={setShow2FaScreen}
        />
      )}
    </>
  );
};

const SelectVerificationScreen = ({ state, selectedVerification, setSelectedVerification, setShow2FaScreen }) => {
  const verificationMethods = [
    {
      key: "phone_number",
      icon: <TextMessageIcon />,
      text: "Text Message",
    },
    {
      key: "email",
      icon: <EmailIcon />,
      text: "Email",
    },
    {
      key: "google_authenticator",
      icon: <GoogleAuthIcon />,
      text: "Google Authenticator",
    },
  ];

  return (
    <CutomCard>
      <Image src={companyLogo} preview={false} />
      <FormHeader>Security Verification Method</FormHeader>
      <FormSupportingText>
        Please select a verification method in case you lose your
        <br /> account credentials.
      </FormSupportingText>
      <CompanyCardWrapper>
        {verificationMethods?.map((item, index) => {
          return (
            <WrapperDiv className="cursorPointer" key={index} onClick={() => setSelectedVerification(item.key)}>
              <div className="d-flex align-items-center ">
                {item.icon}
                <HeadingComponent color="#273343" text={item.text} fontSize="14px" fontWeight={400} margin="2px 0px 0px 10px " />
              </div>
              <Radio checked={selectedVerification === item.key} />
            </WrapperDiv>
          );
        })}
      </CompanyCardWrapper>
      <br />
      <FlexedInput>
        <AuthButton
          btnText={"Continue"}
          onClick={(e) => {
            e.preventDefault();
            if (selectedVerification === null) {
              pushNotification("Please select a company to continue", "error");
              return;
            }
            enableAuthentication({ method: selectedVerification }, state.token);
            setShow2FaScreen(true);
          }}
        />
      </FlexedInput>
    </CutomCard>
  );
};

const TwoFAScreen = ({ state, selectedVerification, setSelectedVerification, setShow2FaScreen, otp, setOtp }) => {
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const clientOnboardingSeen = auth.loginResponse?.selectCompany?.filter((item) => item.client_id !== null)[0]
    .client_onboarding_seen;

  const getRolePermissions = () => {
    if (auth?.loginResponse?.selectedRoleId) {
      fetchSignRoles(auth.loginResponse.selectedRoleId).then(({ data }) => {
        dispatch(fetchSingleRole(data));
      });
    }
  };
  return (
    <CutomCard>
      <Image src={companyLogo} preview={false} />
      <FormHeader>Enter Verification Code</FormHeader>
      <FormSupportingText>
        Enter a 6-digit verification code we've sent to your email id
        <br />
        username@gmail.com
      </FormSupportingText>
      <CompanyCardWrapper>
        <OtpInput
          containerStyle="gap-2"
          value={otp}
          onChange={setOtp}
          numInputs={6}
          renderSeparator={<span></span>}
          renderInput={(props) => <CustomOtpInput {...props} className="flex-grow-1" />}
        />
      </CompanyCardWrapper>
      <br />
      <FormSupportingText>
        Didn't recieve code?{" "}
        {
          <a
            onClick={() => {
              enableAuthentication({ method: selectedVerification }, state.token);
            }}
          >
            <strong>Resend Code</strong>
          </a>
        }
      </FormSupportingText>
      <FlexedInput>
        <AuthButton
          btnText={"Continue"}
          onClick={(e) => {
            e.preventDefault();
            verifyCode({ method: selectedVerification, code: otp }, state.token)
              .then(({ data }) => {
                setJWTToken(state.token);
                getRolePermissions();
                clientOnboardingSeen ? navigate("/client/") : navigate("/client/onboarding");
              })
              .catch((error) => {
                pushNotification(error?.response?.data?.error[0], "error");
              });
          }}
        />
      </FlexedInput>
    </CutomCard>
  );
};

const FormHeader = styled.h1`
  font-family:
    Helvetica Neue,
    Arial;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #000000;
  margin-top: 24px;
`;

const FormDescriptionText = styled.p`
  font-family: "Helvetica Neue", Arial;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #212121;
`;

const FormSupportingText = styled.p`
  font-family:
    Helvetica Neue,
    Arial;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  color: #424242;
  letter-spacing: 0em;
  text-align: center;
`;

const CompanyCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
`;

const WrapperDiv = styled.div`
  height: 55px;
  border: 1px solid #eeeeee;
  padding: 15px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  margin: 5px 0px;
`;

const CutomCard = styled(Card)`
  width: 448px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
`;

const BackIcon = styled.img`
  width: 20px;
  height: 16px;
  cursor: pointer;
`;

const CustomOtpInput = styled.input`
  border: 1px solid #d6d9e1;
  width: 48px;
  height: 48px;
  border-radius: 8px;
  text-align: center;
  transition: border-color 0.3s;

  &:focus-visible {
    border-color: #0070f0;
    outline-color: #0070f0;
  }
`;

export default ClientVerification;
