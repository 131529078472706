import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import { Divider, Input, Switch } from 'antd';
import CustomBtnWithIcon from '../../../../../components/buttonWithIcon';
import HeadingComponent from '../../../../../components/Heading';
import { pushNotification } from '../../../../../util/Notification';
import { editNotificationSettings } from '../../../../../util/DataService';

export default function StaffNotifications({ company_id, notificationsData, setNotificationsData }) {
    
    const [pushNotifications, setPushNotifications] = useState(false);
    const [reminder, setReminder] = useState(false);
    const [timeValue, setTimeValue] = useState('');

    useEffect(() => {
        if (notificationsData) {
            setPushNotifications(notificationsData?.staff_push);
            setReminder(notificationsData?.staff_service_alert_reminder);
            setTimeValue(notificationsData?.staff_visit_alert_mins || '');
        }
    }, [notificationsData]);

    const handleNotificationSwitchChange = (checked) => {
        setPushNotifications(checked);
    };

    const handleReminderSwitchChange = (checked) => {
        setReminder(checked);
    };

    const handleTimeValueChange = (e) => {
        setTimeValue(e.target.value);
    }

    const handleSaveChanges = async () => {
        const data = {
            company_id: company_id,
            staff_push: pushNotifications,
            staff_service_alert_reminder: reminder,
            staff_visit_alert_mins: timeValue
        };
        editNotificationSettings(data)
        .then(({ data }) => {
          if (data.isSuccess) {
            setNotificationsData(data.result);
            pushNotification(data.message, "success");
          }
        })
        .catch((error) => {
          console.log("API error:", error);
        });
    };

    return (
        <div className='mt-2' style={{ width: '100%' }}>
            <div className="d-flex flex-row" style={{ marginBottom: '10%' }}>
                <div style={{ width: '20%', marginLeft: "14px" }}>
                    <Heading text="Push Notifications" fontSize="1rem" color="black" />
                </div>
                <div style={{ marginLeft: '20%' }}>
                    <span className="d-flex">
                        <Switch size="small" className="me-2" checked={pushNotifications} onChange={handleNotificationSwitchChange} />
                        <Heading text="Enabled" />
                    </span>
                    <span className="mt-10">
                        <label>This will notify you according to the situation</label>
                    </span>
                </div>
            </div>

            <Divider />

            <div className="d-flex flex-row" style={{ marginBottom: '10%' }}>
                <div style={{ width: '20%', marginLeft: "14px" }}>
                    <Heading text="Time left in a visit alert notification" fontSize="1rem" color="black" />
                </div>
                <div style={{ marginLeft: '20%' }}>
                    <Input
                        placeholder="Enter Time"
                        style={{ height: "40px", width: "400px", marginTop: "10px" }}
                        value={timeValue}
                        onChange={handleTimeValueChange}
                    />
                </div>
            </div>

            <Divider />

            <div className="d-flex flex-row" style={{ marginBottom: '10%' }}>
                <div style={{ width: '20%', marginLeft: "14px" }}>
                    <Heading text="Reminder to start service Alerts" fontSize="1rem" color="black" />
                </div>
                <div style={{ marginLeft: '20%' }}>
                    <span className="d-flex">
                        <Switch size="small" className="me-2" checked={reminder} onChange={handleReminderSwitchChange} />
                        <Heading text="Enabled" />
                    </span>
                </div>
            </div>

            <Divider />

            <SaveContainer className="d-flex justify-content-end">
                <CustomBtnWithIcon btnText="Save Changes" onClick={handleSaveChanges} hideIcon={true} />
            </SaveContainer>
        </div>
    )
}

const Heading = ({ text = "", margin, fontSize = "0.75rem", color = "#3B3B3B" }) => {
    return <HeadingComponent text={text} fontSize={fontSize} color={color} fontWeight={700} margin={margin} />;
};

const SaveContainer = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  border-top: 1px solid #E0E0E0;
  background-color: white;
  padding: 10px 20px;
`;
