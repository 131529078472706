import React, { useEffect, useState } from "react";
import { Form, Input } from "antd";
import styled from "styled-components";
import CustomBtnWithIcon from "../../components/buttonWithIcon";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import MapModal from "../MapComponent/mapModal";
import LocationSelector from "../CustomAddressInput/locationSelector";
import { main_api } from "../../api/axios_helper";
import { companyAPIsEndPoints } from "../../constants/ApiEndPoints";
import { pushNotification } from "../../util/Notification";

export default function CompanyDetails({ handleNext, finishOnboarding = () => {}, themeData }) {
  const auth = useSelector((state) => state.auth);
  const company_id = auth.loginResponse?.selectCompany?.[0]?.company_id || null;

  const [isModalOpen, setModalOpen] = useState(false);
  const [routeOptimizorChecked, setRouteOptimizorChecked] = useState(false);
  const [address, setAddress] = useState("");
  const [addressLatAndLong, setAddressLatAndLong] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState();

  const [form] = Form.useForm();

  useEffect(() => {
    if (company_id) {
      main_api
        .get(companyAPIsEndPoints.FETCH_SINGLE_COMPANY(company_id))
        .then(({ data }) => {
          setSelectedCompany(data.result);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      name: selectedCompany?.name,
      // phone_number: state && state.primary_phone_number ? state.primary_phone_number : "",
      city: selectedCompany && selectedCompany?.city ? selectedCompany?.city : "",
      zip: selectedCompany && selectedCompany?.zip ? selectedCompany?.zip : "",
      primary_phone_number: selectedCompany && selectedCompany?.primary_phone_number ? selectedCompany?.primary_phone_number : "",
      state: selectedCompany && selectedCompany?.state ? selectedCompany?.state : "",
      address_1: selectedCompany && selectedCompany?.address_1 ? selectedCompany?.address_1 : "",
      website: selectedCompany && selectedCompany?.website ? selectedCompany?.website : "",
    });
  }, [selectedCompany]);

  const onFinish = (formValues) => {
    const data = {
      ...formValues,
      latitude: addressLatAndLong[0],
      longitude: addressLatAndLong[1],
    };

    if (data) {
      main_api
        .put(companyAPIsEndPoints.UPDATE_SINGLE_COMPANY(company_id), data)
        .then(({ data }) => {
          if (data.isSuccess) {
            if (themeData) {
              handleNext();
            } else {
              finishOnboarding();
            }
          }
        })
        .catch((error) => {
          pushNotification("Something went wrong!", "error");
        });
    }
  };

  return (
    <>
      <FormHeader themeData={themeData}>Company Details</FormHeader>
      <FormDescriptionText>Provide us with the company Information</FormDescriptionText>
      <Form form={form} name="companyDetails" onFinish={onFinish} layout="vertical">
        <FormItem
          name="name"
          label="Company Name"
          rules={[
            {
              required: true,
              message: "Please Enter Company Name",
            },
            {
              max: 30,
              min: 1,
            },
          ]}
        >
          <Input placeholder="Enter Company Name" />
        </FormItem>
        <FormItem
          name="primary_phone_number"
          label="Phone No."
          rules={[
            {
              max: 50,
            },
          ]}
        >
          <Input placeholder="Enter Number" />
        </FormItem>
        <FormItem
          name="website"
          label="Website"
          rules={[
            {
              max: 100,
            },
          ]}
        >
          <Input placeholder="e.g www.furgis.com" />
        </FormItem>
        <LocationSelector
          label={"Address"}
          address={address}
          setAddress={setAddress}
          setAddressLatAndLong={setAddressLatAndLong}
          form={form}
          checked={routeOptimizorChecked}
        />
        <FormItem
          name="state"
          label="State"
          rules={[
            {
              max: 50,
            },
          ]}
        >
          <Input placeholder="Enter State" />
        </FormItem>
        <FormItem
          name="zip"
          label="Zip"
          rules={[
            {
              message: "Please enter the zip code",
            },
            {
              max: 50,
              message: "Zip code must be between 1-50 characters",
            },
          ]}
        >
          <Input placeholder="Zip Code" style={{ paddingBlock: "6px" }} />
        </FormItem>
        <FormItem
          name="city"
          label="City"
          rules={[
            {
              message: "Please select the city",
            },
            {
              max: 50,
              message: "City must be up to 50 characters",
            },
          ]}
        >
          <Input placeholder="Enter Enter" style={{ paddingBlock: "6px" }} />
        </FormItem>
        {themeData ? (
          <CustomBtnWithIcon btnText={"Next"} type="submit" style={{ width: "100%" }} hideIcon={true} />
        ) : (
          <CustomBtnWithIcon btnText={"Finish"} type="submit" style={{ width: "100%" }} hideIcon={true} />
        )}
      </Form>
      <MapModal
        isModalOpen={isModalOpen}
        setModalOpen={setModalOpen}
        address={address}
        setAddress={setAddress}
        setAddressLatAndLong={setAddressLatAndLong}
      />
    </>
  );
}

// const FormHeader = styled.h1`
// ${(props) =>
//   props.themeData
//     ? `
//   font-family: Helvetica Neue, Arial;
//   font-style: normal;
//   font-weight: 700;
//   font-size: 24px;
//   line-height: 32px;
//   color: #000000;
//   margin-top: 24px;
// `
//     : `
//    font-family: Helvetica Neue, Arial;
//   font-style: normal;
//   font-weight: 700;
//   font-size: 24px;
//   line-height: 32px;
//   color: #000000;
//   margin-top: 24px;
//   align-self: start;
//   margin-left: 208px;
// `}
// `;

const FormHeader = styled.h1`
  font-family:
    Helvetica Neue,
    Arial;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #000000;
  margin-top: 24px;
`;

const FormDescriptionText = styled.p`
  font-family: "Helvetica Neue", Arial;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #757575;
`;

const FormItem = styled(Form.Item)`
  font-family: Helvetica Neue;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #3b3b3b;
  width: 100%;
`;
