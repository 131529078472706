import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { main_api } from "../../api/axios_helper";
import { platformAPIsEndPoints } from "../../constants/ApiEndPoints";

const initialState = {
  isLoading: false,
  error: false,
  usersList: [],
  roles: [],
  dashboardData: {},
};

const slice = createSlice({
  name: "users",
  initialState,
  reducers: {
    loadUsersListSlice(state, action) {
      state.isLoading = action.payload.isLoading;
      state.error = action.payload.error;
      state.usersList = action.payload.usersList;
    },
    loadUserRolesSlice(state, action) {
        state.isLoading = action.payload.isLoading;
        state.error = action.payload.error;
        state.roles = action.payload.roles;
    },
    loadDashboardDataSlice(state, action) {
        state.isLoading = action.payload.isLoading;
        state.error = action.payload.error;
        state.dashboardData = action.payload.dashboardData;
    },
    searchUserListSlice(state, action) {
      state.isLoading = action.payload.isLoading;
      state.error = action.payload.error;
      state.usersList = action.payload.usersList;
    },
    // addCompanySlice(state, action) {
    //   state.isLoading = action.payload.isLoading;
    //   state.error = action.payload.error;
    //   state.companyList = action.payload.companyList;
    // },
    // editCompanySlice(state, action) {
    //   state.isLoading = action.payload.isLoading;
    //   state.error = action.payload.error;
    //   state.companyList = action.payload.companyList;
    // },
    // deleteCompanySlice(state, action) {
    //   state.isLoading = action.payload.isLoading;
    //   state.error = action.payload.error;
    //   state.companyList = action.payload.companyList;
    // },
  },
});

export const {
  loadUsersListSlice,
  loadUserRolesSlice,
  searchUserListSlice,
  loadDashboardDataSlice,
//   addCompanySlice,
//   editCompanySlice,
//   deleteCompanySlice,
//   searchCompanyListSlice,
} = slice.actions;

export default slice.reducer;

export const loadUsersList = () => async (dispatch) => {
  try {
    dispatch(loadUsersListSlice({ isLoading: true, error: false, usersList: [] }));
    const { data } = await main_api.get(platformAPIsEndPoints.FETCH_USERS_LIST);
    dispatch(loadUsersListSlice({ isLoading: false, error: false, usersList: data }));
  } catch (error) {
    console.log("error", error);
  }
};

export const loadUserRoles = () => async (dispatch) => {
    try {
      dispatch(loadUserRolesSlice({ isLoading: true, error: false, roles: [] }));
      const { data } = await main_api.get(platformAPIsEndPoints.FETCH_USER_ROLES);
      dispatch(loadUserRolesSlice({ isLoading: false, error: false, roles: data }));
    } catch (error) {
      console.log("error", error);
    }
};  

export const loadDashboardData = () => async (dispatch) => {
  try {
    dispatch(loadDashboardDataSlice({ isLoading: true, error: false, dashboardData: [] }));
    const { data } = await main_api.get(platformAPIsEndPoints.FETCH_DASHBOARD_DATA);
    dispatch(loadDashboardDataSlice({ isLoading: false, error: false, dashboardData: data.result }));
  } catch (error) {
    console.log("error", error);
  }
};  

export const searchUserList = (search, role, company) => async (dispatch) => {
  try {
    dispatch(searchUserListSlice({ isLoading: true, error: false, usersList: [] }));
    const { data } = await main_api.get(platformAPIsEndPoints.USERS_LIST_FILTER(search, role, company));
    dispatch(searchUserListSlice({ isLoading: false, error: false, usersList: data }));
  } catch (error) {
    console.log("error", error);
  }
};
