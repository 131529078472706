import React, { useEffect, useState } from "react";
import { Button, Col, Form, Input, Popover, Row } from "antd";
import { CheckOutlined, PlusOutlined } from "@ant-design/icons";
import styled from "styled-components";
import CustomBtnWithIcon from "../buttonWithIcon";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { SketchPicker } from "react-color";
import ColorPicker from "../ColorPicker/colorpicker";
import FontPicker from "../FontPicker/fontPicker";
import { availableFonts } from "../../util/colorAndFonts";
export default function DashboardTheme({ dashboardData, themeData, handleNext, handleBack }) {
  const { state } = useLocation();
  const [activeStep, setActiveStep] = useState(1);
  const navigate = useNavigate();

  const [selectedHeaderColor, setSelectedHeaderColor] = useState("");
  const [selectedHeaderSeparatorColor, setSelectedHeaderSeparatorColor] = useState("");
  const [selectedHeaderFontColor, setSelectedHeaderFontColor] = useState("");
  const [selectedHeaderTitleFontColor, setSelectedHeaderTitleFontColor] = useState("");

  const [initialColors, setInitialColors] = useState([
    "#FF5733",
    "#33FF57",
    "#3357FF",
    "#F1C40F",
    "#8E44AD",
    "#2C3E50",
    "#ffffff",
  ]);

  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const [form] = Form.useForm();

  //     const getRolePermissions = () => {
  //     if(auth?.loginResponse?.selectedRoleId){
  //       fetchSignRoles(auth.loginResponse.selectedRoleId).then(
  //         ({ data }) => {
  //           dispatch(fetchSingleRole(data))
  //         }
  //       );
  //     }
  //   }

  const onFinish = (formValues) => {
    formValues.headerColor = selectedHeaderColor || "";
    formValues.headerTitleFontColor = selectedHeaderTitleFontColor || "";
    formValues.headerFontColor = selectedHeaderFontColor || "";
    formValues.headerSeparatorColor = selectedHeaderSeparatorColor || "";

    const data = {
      dashboard_header: formValues.headerColor,
      dashboard_header_font: formValues.headerFontColor,
      dashboard_header_title: formValues.headerTitleFontColor,
      dashboard_header_separator: formValues.headerSeparatorColor,
    };
    handleNext(data);
  };
  return (
    <>
      <FormHeader>Dashboard Theme</FormHeader>
      <FormDescriptionText>Set your dashboard theme</FormDescriptionText>
      <Form form={form} name="companyDashboard" onFinish={onFinish} layout="vertical">
        {dashboardData?.dashboard_header && (
          <Form.Item name="headerColor" label="Header Color">
            <ColorPicker
              initialColors={initialColors}
              setInitialColors={setInitialColors}
              selectedColorData={themeData?.dashboard_header}
              setValue={setSelectedHeaderColor}
              disabled={!dashboardData?.dashboard_header}
            />
            <FormDescriptionText>Choose color theme to be shown on the company web</FormDescriptionText>
          </Form.Item>
        )}

        {dashboardData?.dashboard_header_font && (
          <FormItem name="headerFontColor" label="Header Font Color">
            <ColorPicker
              initialColors={initialColors}
              setInitialColors={setInitialColors}
              selectedColorData={themeData?.dashboard_header_font}
              setValue={setSelectedHeaderFontColor}
              disabled={!dashboardData?.dashboard_header_font}
            />
            <FormDescriptionText>Choose color theme to be shown on the company web</FormDescriptionText>
            {/* <FontPicker fonts={availableFonts} selectedFont={selectedHeaderFontStyle} setSelectedFont={setSelectedHeaderFontStyle} disabled={!dashboardData?.dashboard_header_font}/> */}
          </FormItem>
        )}

        {dashboardData?.dashboard_header_title && (
          <FormItem name="headerTitleFontColor" label="Header Title Color">
            <ColorPicker
              initialColors={initialColors}
              setInitialColors={setInitialColors}
              selectedColorData={themeData?.dashboard_header_title}
              setValue={setSelectedHeaderTitleFontColor}
              disabled={!dashboardData?.dashboard_header_title}
            />

            <FormDescriptionText>Choose color theme to be shown on the company web</FormDescriptionText>

            {/* <FontPicker fonts={availableFonts} selectedFont={selectedHeaderTitleFont} setSelectedFont={setSelectedHeaderTitleFont} disabled={!dashboardData?.dashboard_header_title}/> */}
          </FormItem>
        )}

        {dashboardData?.dashboard_header_separator && (
          <Form.Item name="headerSeparatorColor" label="Header Separator Color">
            <ColorPicker
              initialColors={initialColors}
              setInitialColors={setInitialColors}
              selectedColorData={themeData?.dashboard_header_separator}
              setValue={setSelectedHeaderSeparatorColor}
              disabled={!dashboardData?.dashboard_header_separator}
            />

            <FormDescriptionText>Choose color theme to be shown on the company web</FormDescriptionText>
          </Form.Item>
        )}

        <CustomBtnWithIcon btnText={"Next"} type="submit" style={{ width: "100%" }} hideIcon={true} />
        <CustomBtnWithIcon
          btnText={"Back"}
          margin="10px 0px"
          style={{ width: "100%" }}
          noBackground
          hideIcon={true}
          onClick={() => handleBack()}
        />
      </Form>
    </>
  );
}

const FormHeader = styled.h1`
  font-family:
    Helvetica Neue,
    Arial;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #000000;
  margin-top: 24px;
`;

const FormDescriptionText = styled.p`
  font-family: "Helvetica Neue", Arial;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #757575;
`;

const FieldWraper = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
`;

const FormWraper = styled.div`
  height: 556px;
  overflow: auto;

  ::-webkit-scrollbar {
    width: 0px;
  }
`;

const FurtherDetails = styled.div`
  span {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: #757575;
  }
`;

const FormItem = styled(Form.Item)`
  font-family: Helvetica Neue;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #3b3b3b;
  width: 100%;
`;
