import React, { useEffect, useState } from "react";
import HeadingComponent from "../../../components/Heading";
import DocumentsTable from "../../../components/DocumentSignManagement/documentsTable";
import CustomBtnWithIcon from "../../../components/buttonWithIcon";
import { Button, message, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import axiosInstance from "../../../util/AxiosConfig";
import PDFViewer from "../../../components/DocumentSignManagement/pdfViewer";
import { getDocuments } from "../../../util/DataService";
import { useSelector } from "react-redux";
import { main_api } from "../../../api/axios_helper";
import { companyAPIsEndPoints } from "../../../constants/ApiEndPoints";
import SignatureSelector from "../../../components/DocumentSignManagement/signatureSelector";
import { pushNotification } from "../../../util/Notification";
import { useLocation, useNavigate } from "react-router-dom";
import useFetchClientOnBoardSettings from "../../../hooks/useFetchClientOnboardingSettings";

export default function Documents() {
  const [isPDFViewerPageOpen, setIsPDFViewerPageOpen] = useState(false);
  const [isSignatureSelectorModalOpen, setIsSignatureSelectorModalOpen] = useState(false);
  const { clientSettings } = useFetchClientOnBoardSettings();

  const [record, setRecord] = useState();
  const [showupload, setShowupload] = useState(false);
  const [documents, setDocuments] = useState([]);
  const auth = useSelector((state) => state.auth);
  const selectedRoleId = auth.loginResponse?.selectedRoleId;
  const selectedCompany = auth.loginResponse?.selectCompany?.find((company) => company.role_id === selectedRoleId);
  const clientId = selectedCompany ? selectedCompany.client_id : null;
  let pathName = useLocation().pathname;
  let navigate = useNavigate();

  const getDocuments = () => {
    main_api
      .get(companyAPIsEndPoints.FETCH_CLIENT_DOCUMENT_LIST(clientId))
      .then(({ data }) => {
        setDocuments(data.result);
      })
      .catch((error) => {
        pushNotification("Something went wrong!", "error");

        // console.log(error);
      });
  };

  useEffect(() => {
    getDocuments();
  }, []);
  const handleSignDocument = async () => {
    try {
      // DocuSign API call
      await axiosInstance.post("/api/sign", { documentId: document.id });
      alert("Document signed successfully!");
    } catch (error) {
      console.error("Error signing document:", error);
    }
  };

  const handleUpload = ({ info, file, onSuccess, onError }) => {
    setShowupload(true);

    let title = file.name.split(".").slice(0, -1).join(".");
    let extension = file.name.split(".").pop();

    const formData = new FormData();
    formData.append("document", file);
    formData.append("file_format", extension);
    formData.append("title", title);
    formData.append("client", clientId);

    main_api
      .post(companyAPIsEndPoints.UPLOAD_UNSIGNED_DOCUMENT, formData)
      .then((response) => {
        if (response.data.isSuccess) {
          onSuccess();
          setShowupload(false);
          getDocuments();
          message.success(`${file.name} file uploaded successfully`);
        } else {
          onError();
          message.error(`${file.name} file upload failed.`);
          setShowupload(false);
        }
      })
      .catch((error) => {
        onError();
        message.error(`${file.name} file upload failed.`);
        setShowupload(false);
        console.error("Error uploading file:", error);
      });
  };
  const handleDownload = (record) => {
    const fileUrl = record.unsigned_url;
    const a = document.createElement("a");
    a.href = fileUrl;
    a.target = "_blank";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const handleSignature = (record) => {
    main_api
      .get(companyAPIsEndPoints.SEND_DOCUMENT_FOR_SIGNATURE(record.id))
      .then((response) => {
        if (response.data.isSuccess) {
          pushNotification("Redirecting...", "success");
          window.location.href = response.data.redirect_url;
          // window.open(response.data.redirect_url, '_blank');
          pathName.includes("/client/documents?event=signing_complete");
          navigate("/client/documents");
        }
      })
      .catch((error) => {
        pushNotification(error.message, "error");
      });
    // setIsSignatureSelectorModalOpen(true);
  };
  // const handleSignature = (record) => {
  //   console.log("record for sig:",record);
  //   main_api.get(companyAPIsEndPoints.SEND_DOCUMENT_FOR_SIGNATURE(record.id))
  //   .then(response => {
  //     if (response.data.isSuccess) {
  //       pushNotification("Redirecting...","success")
  //       const redirectUrl = response.redirect_url
  //       const a = document.createElement('a');
  //       a.href = redirectUrl;
  //       a.target = '_blank';
  //       document.body.appendChild(a);
  //       a.click();
  //       document.body.removeChild(a);
  //     }
  //   })
  //   .catch(error => {
  //     pushNotification(error.message, "error")
  //   });
  //   // setIsSignatureSelectorModalOpen(true);
  // };

  const handleShowPDFViewerPage = (record) => {
    setRecord(record);
    setIsPDFViewerPageOpen(true);
  };
  let clientAbleToUploadDoc = clientSettings?.upload_documents_enabled ? false : true;
  let clientUploadDocButton = clientSettings?.upload_documents_button ? false : true;
  let clientSignAgreement = clientSettings?.sign_agreement ? false : true;
  return (
    <>
      {isPDFViewerPageOpen && (
        <PDFViewer
          isModalOpen={isPDFViewerPageOpen}
          record={record}
          setModalOpen={setIsPDFViewerPageOpen}
          handleDownload={handleDownload}
          handleSignature={handleSignature}
        />
      )}
      <DocumentsTable
        documents={documents}
        showupload={showupload}
        handleUpload={handleUpload}
        handleDownload={handleDownload}
        handleSignature={handleSignature}
        handleShowPDFViewerPage={handleShowPDFViewerPage}
        clientAbleToUploadDoc={clientAbleToUploadDoc}
        clientUploadDocButton={clientUploadDocButton}
        clientSignAgreement={clientSignAgreement}
      />

      {isSignatureSelectorModalOpen && (
        <SignatureSelector isModalOpen={isSignatureSelectorModalOpen} setModalOpen={setIsSignatureSelectorModalOpen} />
      )}
    </>
  );
}

const Heading = ({ text = "", margin, fontSize = "0.75rem", color = "#3B3B3B" }) => {
  return <HeadingComponent text={text} fontSize={fontSize} color={color} fontWeight={700} margin={margin} />;
};
