import React, { useState } from 'react'
import { AntdesignTable } from '../../../../components/antDesignTable/AntdesignTable'
import SearchInput from '../../../../components/SearchInput'
import CustomBtnWithIcon from '../../../../components/buttonWithIcon'
import DeleteConfirmationRowModal from '../../../../components/Modals/DeleteConfirmationRowModal'
import CreateTagModal from '../../../../components/Modals/TagManagementModal/createTagModal'
import { createTag, deleteTag } from '../../../../util/DataService'
import { pushNotification } from '../../../../util/Notification'
import EditTagModal from '../../../../components/Modals/TagManagementModal/editTagModal'
import { checkPermission } from '../../../../util/commonUtil'
import styled from 'styled-components'
import NoDataScreen from '../../../../components/NoDataScreen'
import { useSelector } from 'react-redux'
import StaffTagsListTable, { staffTagsListColumns } from '../../../../constants/settingsTablesHeaders/staffTagsTableHeaders'
import { Table } from 'antd'
import StaffTagsTable from './staffTagsTable'
import { main_api } from '../../../../api/axios_helper'

export default function StaffTags({staffTagsList,loadTagsList}) {
    const { auth, permissionManagement } = useSelector((state) => state);
    const [search, setSearch] = useState("");
    const [editModalValues, setEditModalValues] = useState({});
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [doSearch, setDoSearch] = useState(false);
    const [currentRecord, setCurrentRecord] = useState({});
    const createTag = (values) => {
      
      main_api
      .post("/api/v1/staff_tags/",values)
      .then(({ data }) => {
        pushNotification(data.message, "success");
      })
      .catch(error => {
        console.error(error?.response?.data)
      });
    };

    const searchTags = (value) => {
      setDoSearch(true);
      const filteredTags = staffTagsList.filter(tag =>
          tag.tag_name.toLowerCase().includes(value.toLowerCase())
      );
  };
  
    const searchBlurHandler = (e) => {
    searchTags(e.target.value);
    };
    
    const updateTagColor = (id, updatedColor) => {

      main_api
        .put(`/api/v1/staff_tags/${id}/`,updatedColor)
        .then(({ data }) => {
          loadTagsList();
        })
        .catch(error => {
          console.error(error?.response?.data)
        });
    };
    const updateTag = (id, updatedData) => {

      main_api
        .put(`/api/v1/staff_tags/${id}/`,updatedData)
        .then(({ data }) => {
          loadTagsList();
        })
        .catch(error => {
          console.error(error?.response?.data)
        });
    };
    const deleteTag = () => {
      
      main_api
      .delete(`/api/v1/staff_tags/${currentRecord.id}/`)
      .then(({ data }) => {
        pushNotification(data.message, "success");
        loadTagsList();
        setIsDeleteModalOpen(!isDeleteModalOpen);
      })
      .catch(error => {
        console.error(error?.response?.data)
      });
      
      }
    const handleDelete= (record)=>{
      setCurrentRecord(record);
      setIsDeleteModalOpen(true);
      // const id = {id:record.id}
      // deleteTag(record.id);
    }

    const handleEditRow=(record)=>{
      setCurrentRecord(record);
      setIsEditModalOpen(!isEditModalOpen)
      
    }
    const handleCreateTagModal = () => {
      setIsAddModalOpen(!isAddModalOpen);
    }
    const handleEditTagModal = () => {
      setIsEditModalOpen(!isEditModalOpen);
    }
    const handleDeleteTagModal = () => {
      setIsDeleteModalOpen(!isDeleteModalOpen);
    }
     
      
  return (
    <div>
        
       <TableHeader>
            <div className="d-flex align-items-center col">
              <SearchInput
                search={search}
                onChange={(value) => setSearch(value)}
                onBlur={searchBlurHandler}
                placeholder="Type to search.."
              />
            
              <CustomBtnWithIcon
                btnText={"Add a Tag"}
                className="ms-md-auto"
                margin="10px 10px 10px auto"
                onClick={()=>handleCreateTagModal()}
              />
            </div>
          </TableHeader>

       {isAddModalOpen && <CreateTagModal isModalOpen={isAddModalOpen} handleCreateTagModal={handleCreateTagModal} loadTagsList={loadTagsList} createTag={createTag}/>}   
       {isEditModalOpen && <EditTagModal currentRecord={currentRecord} isModalOpen={isEditModalOpen} handleEditTagModal={handleEditTagModal} loadTagsList={loadTagsList} updateTag={updateTag}/>}   
       {/* {isDeleteModalOpen && <DeleteConfirmationRowModal currentRecord={currentRecord} isModalOpen={isDeleteModalOpen} handleDeleteTagModal={handleDeleteTagModal} deleteTag={deleteTag}/>} */}
       {<DeleteConfirmationRowModal
                onclose={() => {setIsDeleteModalOpen(false); setCurrentRecord(null)}}
                handleConfirmDeleteRows={deleteTag}
                openConfimrationDeleteModal={isDeleteModalOpen}
            />}

       {staffTagsList?.length > 0 ?
            
            <StaffTagsTable search={search} data={staffTagsList} handleDelete={handleDelete} handleEditRow={handleEditRow} updateTagColor={updateTagColor}/>:
            <NoDataScreen
              heading="You don’t have any tags in your list. Let’s get started by adding a new staff tags"
              btnText={"Create new Tags"}
              onClick={() => handleCreateTagModal()}
            />
          }
    </div>
  )
}

const TableHeader = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`;