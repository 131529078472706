import React, { useState } from "react";
import HeadingComponent from "../../../../components/Heading";
import styled from "styled-components";
import { Divider, Form, Input, Radio } from "antd";
import CustomBtnWithIcon from "../../../../components/buttonWithIcon";
import { main_api } from "../../../../api/axios_helper";
import { useSelector } from "react-redux";

export default function PayStructure({ staffData }) {
  const [form] = Form.useForm();
  const { auth } = useSelector((state) => state);
  const [selectedValue, setSelectedValue] = useState("by the Hour");

  form.setFieldsValue({
    payment_method: "",
    hourly_rate: staffData && staffData[0] && staffData[0].standard_rate !== undefined ? staffData[0].standard_rate : "",
  });

  const handleRadioChange = (e) => {
    setSelectedValue(e.target.value);
  };
  const handleSubmit = async (values) => {
    let data = {
      hourly_rate: values.hourly_rate,
    };
    // main_api
    //   .post("")
    //   .then(({ data }) => {})
    //   .catch((error) => {
    //     console.error(error?.response?.data);
    //   });
  };

  return (
    <div className="mt-2" style={{ width: "100%" }}>
      <Form name="payStructureForm" onFinish={handleSubmit} form={form} layout="vertical">
        <HeadingComponent text={"Payment Method"} fontSize="1rem" fontWeight={500} />
        <HeadingComponent
          text={
            "Select the payment method for all staff members. Time Tracking and Mileage tracking is only available for companies that are using the hourly payment method."
          }
          color="#757575"
          margin={"20px 0px"}
          fontSize="0.875rem"
        />
        <Heading text="Set these options for rate" margin={"10px 0px"} />

        <div className="d-flex align-items-center">
          <Radio.Group onChange={handleRadioChange} className="d-flex align-items-center" value={selectedValue}>
            <Radio value="by the Hour">
              <HeadingComponent text={"By the Hour"} fontSize="0.875rem" color="#000000" fontWeight={400} />
            </Radio>
            <Radio value="by the Job">
              {" "}
              <HeadingComponent text={"By the Job"} fontSize="0.875rem" color="#000000" fontWeight={400} />
            </Radio>
            <Radio value="Both">
              {" "}
              <HeadingComponent text={"Both"} fontSize="0.875rem" color="#000000" fontWeight={400} />
            </Radio>
          </Radio.Group>
        </div>
        <div className="mt-3">
          <Heading text="Hourly Rate" />
          <FormItem
            name="hourly_rate"
            // rules={[
            //   {
            //     required: true,
            //     message: "Please enter the zip code",
            //   },
            // ]}
          >
            <Input placeholder="$ Enter Rate" style={{ height: "40px", width: "400px", marginTop: "10px" }} />
          </FormItem>
        </div>
        <Divider />
        <SaveContainer className="d-flex justify-content-end">
          <CustomBtnWithIcon btnText="Save Changes" type="submit" hideIcon={true} />
        </SaveContainer>
      </Form>
    </div>
  );
}

const FormItem = styled(Form.Item)`
  width: 100%;
  Tags {
    display: flex;
  }
`;

const SaveContainer = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  border-top: 1px solid #E0E0E0;
  background-color: white;
  padding: 10px 20px;
`;

const Heading = ({ text = "", margin, fontSize = "0.75rem", color = "#3B3B3B" }) => {
  return <HeadingComponent text={text} fontSize={fontSize} color={color} fontWeight={700} margin={margin} />;
};
