import React, { useState } from "react";
import { Space, Tag } from "antd";
import styled from "styled-components";

const Tags = ({ label, classes, tagsData }) => {
  const [selectedTags, setSelectedTags] = useState(["Books"]);
  const handleChange = (tag, checked) => {
    const nextSelectedTags = checked ? [...selectedTags, tag] : selectedTags.filter((t) => t !== tag);
   
    setSelectedTags(nextSelectedTags);
  };
  return (
    <div>
      {label && <Label>{label}</Label>}
      <Space size={[0, 8]} wrap>
        {tagsData.map((tag) => (
          <Tag
            key={tag}
            color="transparent"
            checked={selectedTags.includes(tag)}
            onChange={(checked) => handleChange(tag, checked)}
          >
            <TagsContainer>{tag}</TagsContainer>
          </Tag>
        ))}
      </Space>
    </div>
  );
};
export default Tags;

const Label = styled.p`
  margin-bottom: 6px;
  color: #3b3b3b;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
`;
const TagsContainer = styled.div`
  color: #000000;
  background: #F5F5F5;
  padding:3px 10px;
  border-radius:27px;
`;
