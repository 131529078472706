import { Segmented } from "antd";
import React from "react";
import styled from "styled-components";

const AntDesignSegment = ({
  options = ["Option 1", "Option 2"],
  size = "medium",
  defaultValue = "defaultValue",
  onChange,
  className,
  style = { height: "40px" },
}) => {
  return (
    <div>
      <CustomSegmented
        style={style}
        onChange={onChange}
        options={options}
        className={`w-100 p-1 ${className}`}
        size={size}
        block
        defaultValue={defaultValue}
      />
    </div>
  );
};

export default AntDesignSegment;

const CustomSegmented = styled(Segmented)`
  .ant-segmented-group {
    ${"" /* gap: 5px; */}
  }
  .ant-segmented-item {
    width: 50%;
  }
`;
