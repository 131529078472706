import { Select } from "antd";
import React from "react";
import styled from "styled-components";

function SelectField({ label, size, options, placeholder, labelClass, rules, name, onChange, value }) {
  return (
    <>
      {label && <Label className={labelClass}>{label}</Label>}
      <Select placeholder={placeholder} style={{ width: "100%" }} size={size} options={options} name={name} onChange={onChange} />
    </>
  );
}

export default SelectField;
const Label = styled.p`
  margin-bottom: 6px;
  color: #3b3b3b;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
`;
