import React, { useEffect, useState } from "react";
import { Row, Col, DatePicker, Select, Collapse, TimePicker } from "antd";
import dayjs from "dayjs";
import moment from "moment";

import { Formik, Form } from "formik";
import { useSelector } from "react-redux";
import * as Yup from "yup";

import CustomBtnWithIcon from "../../../../components/buttonWithIcon";
import AntDesignModal from "../../../../components/Modals/AntDesignModal/AntDesignModal";

import { InputTextLabel } from "../../../../components/formikInputs/Checkbox";
import FormikSelectInput from "../../../../components/formikInputs/SelectInput";

import { pushNotification } from "../../../../util/Notification";
import { main_api } from "../../../../api/axios_helper";
import { companyAPIsEndPoints } from "../../../../constants/ApiEndPoints";

import { ReactComponent as Birthdate } from "../../../../assets/rawSvgs/Birthdate.svg";

//
const ScheduleGroupWalk = ({ updateValues, addNewEventModal, updateForm, closeAll, clientPetServiceDropdowns }) => {
  const [formLoader, setFormLoader] = useState(false);
  const [selectGroupDate, setSelectGroupDate] = useState(moment().format("YYYY-MM-DD"));
  const [selectGroupTime, setGroupTime] = useState("");
  const compId = useSelector((state) => state.auth.loginResponse.selectCompany);
  const [groupWalkServices, setGroupWalkServices] = useState([]);
  const [selectTimeblockDateWiseEvents, setSelectTimeblockDateWiseEvents] = useState([]);
  const state = useSelector((state) => state.auth.loginResponse);

  const formikRef = React.useRef();

  let company_id = state?.selectCompany?.[0]?.company_id || null;

  useEffect(() => {
    handleFetchGroupWalkServices();
  }, [selectGroupDate]);

  const handleFetchGroupWalkServices = async () => {
    try {
      const { data } = await main_api.get(
        `/api/v1/service_request/?service__company=${compId?.[0]?.company_id}&status=scheduled&status=in_progress&start_date=${selectGroupDate}&end_date=${selectGroupDate}&is_group_walk=1`,
      );
      let filterGroupWalk = data.result?.filter((items) => items.service.is_group_walk);

      setGroupWalkServices(filterGroupWalk);
    } catch (error) {
      setGroupWalkServices([]);
    }
  };

  const handleAssignGroup = async (values) => {
    try {
      setFormLoader(true);
      let objSend = {
        service_requests: [...values],
      };
      const res = await main_api.post(companyAPIsEndPoints.ASSIGN_PENDING_REQUEST, objSend);

      pushNotification("Group scheduled successfully");
      clientPetServiceDropdowns.fetchAllSchedulerService();
      setFormLoader(false);
      closeAll();
    } catch (error) {
      setFormLoader(false);
    }
  };
  const filterUpdateService =
    clientPetServiceDropdowns?.schedulerState?.result?.filter((items) => {
      return items.id === updateValues?.serviceID;
    })[0] || "";

  const validationSchema = Yup.object().shape({
    staff: Yup.string().required("Staff is required"),
    time_block: Yup.string().required("Time block is required"),
    services: Yup.array().of(Yup.string().required("Service is required")).min(1, "At least one service is required"),
  });

  return (
    <>
      <AntDesignModal
        width={600}
        title={updateValues?.title ? "Update a Group Walk" : "Schedule a Group Walk"}
        openModal={addNewEventModal}
        closeModal={closeAll}
        component={
          <>
            <div>
              <Row gutter={10} className="mt-3">
                <Formik
                  initialValues={{
                    client: filterUpdateService?.client?.id ? filterUpdateService?.client?.id : "",
                    staff: "",
                    time_block: "",
                    services: [],
                    company: company_id,
                  }}
                  innerRef={formikRef}
                  enableReinitialize
                  onSubmit={(values, { setTouched }) => {
                    setTouched({
                      selectedOption: true,
                    });
                    let sortedServices = [];

                    values.services.forEach((elem) => {
                      groupWalkServices.forEach((nestedElem) => {
                        if (elem === nestedElem.id) {
                          sortedServices.push(nestedElem);
                        }
                      });
                    });

                    const modifiedService = sortedServices.map((items) => {
                      return {
                        id: items.id,
                        staff: values.staff,
                        time_block: values.time_block,
                        start_time: items.start_time,
                        end_time: items.end_time,
                      };
                    });

                    handleAssignGroup(modifiedService);
                    // console.log("SUBMIT CREATE : ", values);
                  }}
                  validationSchema={validationSchema}
                >
                  {({ handleSubmit, setFieldValue, values, errors }) => (
                    <Form onSubmit={handleSubmit}>
                      <Row gutter={10}>
                        <Col md={12} className="d-flex flex-column mt-1">
                          <InputTextLabel>Date</InputTextLabel>
                          <DatePicker
                            defaultValue={updateValues?.date ? dayjs(updateValues?.date) : dayjs()}
                            suffixIcon={<Birthdate stroke={"#424242"} />}
                            format="YYYY-MM-DD"
                            style={{ height: "40px" }}
                            onChange={(e, dateString) => {
                              setSelectGroupDate(dateString);
                            }}
                          />
                          <span className="input-error-style">
                            {errors?.service_requests?.[0]?.date && errors?.service_requests?.[0]?.date}
                          </span>
                        </Col>

                        {/* TIME BLOCK */}

                        <Col md={12} className="">
                          <FormikSelectInput
                            label="Time Block"
                            placeholder="Select Time Block"
                            defaultValue={filterUpdateService?.time_block?.id || "Select time block"}
                            name="time_block"
                            type="text"
                            className="w-100 "
                            autoComplete="new-password"
                            allowClear
                            options={clientPetServiceDropdowns?.blockTimeDropDown}
                            onChange={(e) => {
                              let filterTimeblock = clientPetServiceDropdowns?.timeBlockCompList?.filter((items) => {
                                return items.id === e;
                              });
                              let start_time = filterTimeblock[0]?.start_time;
                              let end_time = filterTimeblock[0]?.end_time;

                              // Combine the date with the time to create a proper Date object

                              const filteredEvents = groupWalkServices.filter((event) => {
                                const eventStartTime = moment(event.start_time, "HH:mm:ss");
                                const eventEndTime = moment(event.end_time, "HH:mm:ss");
                                const blockStartTime = moment(start_time, "HH:mm:ss");
                                const blockEndTime = moment(end_time, "HH:mm:ss");

                                return eventStartTime.isSameOrAfter(blockStartTime) && eventEndTime.isSameOrBefore(blockEndTime);
                              });

                              const sortedEvents = filteredEvents.sort((a, b) => {
                                const startA = moment(a.start_time, "HH:mm:ss");
                                const startB = moment(b.start_time, "HH:mm:ss");
                                const endA = moment(a.end_time, "HH:mm:ss");
                                const endB = moment(b.end_time, "HH:mm:ss");

                                if (startA.isBefore(startB)) return -1;
                                if (startA.isAfter(startB)) return 1;
                                if (endA.isBefore(endB)) return -1;
                                if (endA.isAfter(endB)) return 1;
                                return 0;
                              });
                              setSelectTimeblockDateWiseEvents(sortedEvents);

                              setFieldValue("time_block", e);
                            }}
                            width="100%"
                            noBr
                            // disabled={!values.extra_pet_enabled}
                          />
                          <span className="input-error-style">
                            {errors?.service_requests?.[0]?.services && errors?.time_block}
                          </span>
                        </Col>
                        {/* time slot */}
                        {/* 
                        <Col md={12} className="">
                          <InputTextLabel>Time</InputTextLabel>

                          <TimePicker.RangePicker
                            format="h:mm a"
                            use12Hours
                            defaultOpenValue={moment().hour(0).minute(0)}
                            className="w-100 "
                            minuteStep={30}
                            style={{ height: "40px" }}
                            onChange={(e, dateString) => {
                              console.log("dateString", dateString);

                              const times24Hour = dateString.map((time) => {
                                const momentTime = moment(time, ["h:mm a", "HH:mm"]);
                                const formattedTime =
                                  momentTime.hour() < 10 && momentTime.hour() !== 0
                                    ? momentTime.format("H:mm")
                                    : momentTime.format("HH:mm");
                                return formattedTime;
                              });

                              console.log(times24Hour); // Output: ['21:00', '21:05', '3:05', '15:00']
                            }}
                          />
                          <span className="input-error-style">
                            {errors?.service_requests?.[0]?.services && errors?.service_requests[0]?.services?.[0]?.start_time}
                          </span>
                        </Col> */}
                        <Col md={24}>
                          <div className="my-1">
                            <FormikSelectInput
                              label="Services"
                              placeholder="Select Service"
                              name="services"
                              type="text"
                              className="w-100 mb-2"
                              autoComplete="new-password"
                              allowClear
                              options={selectTimeblockDateWiseEvents?.map((items) => {
                                return {
                                  value: items.id,
                                  label: items.service.name + " - " + items.client.name,
                                };
                              })}
                              onChange={(e) => {
                                setFieldValue("services", e);
                              }}
                              mode="multiple"
                              width="100%"
                              noBr
                              // disabled={!values.extra_pet_enabled}
                            />
                            <span className="input-error-style">
                              {errors?.service_requests?.[0]?.services && errors?.services}
                            </span>
                          </div>
                        </Col>

                        <Col md={24} className="my-1">
                          <FormikSelectInput
                            label="Staff"
                            placeholder="Select Staff"
                            defaultValue={values?.staff || "Select Staff"}
                            name="staff"
                            type="text"
                            className="w-100 mb-2"
                            autoComplete="new-password"
                            options={clientPetServiceDropdowns?.staffState}
                            onChange={(e) => {
                              setFieldValue("staff", e);
                            }}
                            width="100%"
                            allowClear
                            noBr
                          />
                          <span className="input-error-style">{errors?.service_requests?.[0]?.services && errors?.staff}</span>
                        </Col>

                        {/* tags */}
                      </Row>
                    </Form>
                  )}
                </Formik>
              </Row>
            </div>
          </>
        }
        footer={
          <div className="d-flex justify-content-end">
            <CustomBtnWithIcon btnText={"Cancel"} hideIcon noBackground className="me-2" width="90px" onClick={closeAll} />

            <CustomBtnWithIcon
              btnText={"Schedule"}
              hideIcon
              className="me-2"
              width="90px"
              type="submit"
              disabled={formLoader}
              onClick={() => {
                if (formikRef?.current) {
                  formikRef?.current?.handleSubmit();
                }
              }}
            />
          </div>
        }
      />
    </>
  );
};

export default ScheduleGroupWalk;

const convertTo24HourFormatv2 = (time12h) => {
  const parsedTime = moment(time12h, "h:mm A");

  const time24h = parsedTime.format("HH:mm");

  return time24h;
};
