import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { Form, Formik } from "formik";
import { Col, Row, Checkbox, DatePicker } from "antd";
import * as Yup from "yup";
import CustomBtnWithIcon from "../../../../components/buttonWithIcon";
import HeadingComponent from "../../../../components/Heading";
import AntDesignModal from "../../../../components/Modals/AntDesignModal/AntDesignModal";
import { ReactComponent as SecondStepChecked } from "../../../../assets/rawSvgs/Frame1000005140.svg";
import { ReactComponent as SecondStepTransparent } from "../../../../assets/rawSvgs/Frame000005140Transparnet.svg";
import { ReactComponent as GreaterThanSign } from "../../../../assets/rawSvgs/greaterThanIcon.svg";
import { ReactComponent as FirstStep } from "../../../../assets/rawSvgs/Frame1000005140(2).svg";
import { ReactComponent as FirstStepGreenChecked } from "../../../../assets/rawSvgs/frame1000005142.svg";
import { StepsContainer } from "../../staff/StaffStyles";
import FormikInputFloating from "../../../../components/formikInputs/TextInput";
import { RequiredSignComponent } from "../../staff/addNewStaffModal";
import FormikSelectInput from "../../../../components/formikInputs/SelectInput";

import FormikDateMonthInput from "../../../../components/formikInputs/DateMonth";
import { InputTextLabel } from "../../../../components/formikInputs/Checkbox";
import FormikTextArea from "../../../../components/formikInputs/textArea";
import AddServiceForm from "./AddServiceForm";
import moment from "moment";
//
const AddNewTemplateForm = ({ close, open, updateForm, companyId, requiredDropdowns, updateDetails, projectedTemp }) => {
  const [addStaffSetps, setAddStaffSetps] = useState(1);
  const [tempFormDetails, setTempFormDetails] = useState({});
  const [clientPets, setClientPets] = useState({});
  const [loader, setLoader] = useState(false);

  const formikRef = React.useRef();
  const formikRefSecondStep = React.useRef();
  const handleSubmit = (values) => {
    setTempFormDetails(values);

    setAddStaffSetps(2);
  };

  useEffect(() => {
    if (updateForm && updateDetails) {
      const filterPets =
        requiredDropdowns?.clientAllState?.responseData.result?.filter((items) => items.id === updateDetails?.client?.id)[0] ||
        [];
      setClientPets(
        filterPets?.clientpet?.map((items) => {
          return {
            label: items.name,
            value: items.id,
          };
        }),
      );
    }
  }, [updateDetails]);
  let projectStatus = updateDetails?.status === "projected" ? true : false;

  return (
    <div>
      <AntDesignModal
        title={
          <HeadingComponent
            text={updateForm ? "Update a Template" : "Add a Template"}
            fontSize="16px"
            fontWeight={700}
            color="#212121"
          />
        }
        openModal={open}
        closeModal={close}
        style={{ top: 10 }}
        width={700}
        component={
          <div
            style={{
              height: "75vh",
              overflowY: "auto",
              overflowX: "hidden",
              borderBottom: "0.5px solid rgb(144 144 144 / 43%)",
              padding: " 0px 5px",
            }}
          >
            <StepsContainer>
              <div className="d-flex align-items-center ms-2 cursorPointer">
                {addStaffSetps === 1 ? <FirstStep /> : <FirstStepGreenChecked />}
                <HeadingComponent text={"Template Details"} fontSize="14px" color="#616161" margin={"0px 12px"} />
              </div>
              <div className="mx-3">
                <GreaterThanSign />
              </div>
              <div className="d-flex align-items-center">
                {addStaffSetps === 1 ? <SecondStepTransparent /> : <SecondStepChecked />}

                <HeadingComponent
                  text={updateForm ? "Update Service" : "Add Service"}
                  fontSize="14px"
                  color="#616161"
                  margin={"0px 7px"}
                />
              </div>
            </StepsContainer>

            {/* FORM ======= */}

            <Formik
              initialValues={{
                name: updateForm ? updateDetails.name : "",
                billing_frequency: updateForm ? updateDetails.billing_frequency : "Weekly",
                first_occurence_start: updateForm ? updateDetails.first_occurence_start : "",
                first_occurence_end: updateForm ? updateDetails.first_occurence_end : "",
                approval_date: updateForm ? updateDetails.approval_date : "",
                invoice_due_date: updateForm ? updateDetails.invoice_due_date : "",
                months_of_projections: updateForm ? updateDetails.months_of_projections : "",
                projection_status: updateForm ? updateDetails.projection_status : false,
                invoice_date: updateForm ? updateDetails.invoice_date : "",
                description: updateForm ? updateDetails.description : "",
                // client
                client: updateForm ? updateDetails.client.id : "",
                //
                how_often: updateForm ? updateDetails.how_often : "Weekly",
                approval_method: updateForm ? updateDetails.approval_method : "",
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              enableReinitialize
              innerRef={formikRef}
            >
              {({ values, setFieldValue, errors }) => (
                <Form>
                  <div style={{ display: addStaffSetps === 1 ? "block" : "none" }}>
                    <Row justify={"space-between"} gutter={15}>
                      {/* ====================================== */}

                      <Col md={12} className="mt-1">
                        <FormikInputFloating
                          label="Template Name"
                          placeHolder="Enter Template Name"
                          name="name"
                          type="text"
                          className="w-100 "
                          autoComplete="new-password"
                          // requiredField
                        />
                      </Col>
                      <Col md={12}>
                        <FormikSelectInput
                          label="Select Client"
                          placeholder="Select Client"
                          options={requiredDropdowns?.client || []}
                          defaultValue={values.client || "Select Client"}
                          width="100%"
                          // {...(updateForm
                          //   ? {
                          //       value: values.client,
                          //     }
                          //   : {})}
                          name="client"
                          onChange={(e) => {
                            setFieldValue("client", e);

                            const filterPets =
                              requiredDropdowns.clientAllState.responseData.result?.filter((items) => items.id === e)[0] || {};
                            // clientPets
                            setClientPets(
                              filterPets?.clientpet?.map((items) => {
                                return {
                                  label: items.name,
                                  value: items.id,
                                };
                              }),
                            );
                          }}
                        />
                      </Col>
                      {/* ====================================== */}

                      <Col md={12} className="my-2">
                        <FormikSelectInput
                          label="Billing Frequency"
                          placeholder="Select Billing Frequency"
                          options={[
                            { label: "Weekly", value: "Weekly" },
                            { label: "Monthly", value: "Monthly" },
                            // { label: "Yearly", value: "Yearly" },
                          ]}
                          defaultValue="Weekly"
                          width="100%"
                          value={values.billing_frequency}
                          name="billing_frequency"
                          onChange={(e) => {
                            // setFieldValue("first_occurence_start", "");
                            // setFieldValue("first_occurence_end", "");
                            setFieldValue("billing_frequency", e);
                          }}
                        />
                      </Col>
                      {/* ====================================== */}

                      <Col md={12} className="my-2">
                        <FormikSelectInput
                          label="How Often"
                          placeholder="Select Option"
                          options={[
                            { label: "Weekly", value: "Weekly" },
                            { label: "Monthly", value: "Monthly" },
                            // { label: "Yearly", value: "Yearly" },
                          ]}
                          // defaultValue
                          width="100%"
                          name="how_often"
                          // {...(updateForm
                          //   ? {
                          //       value: values.how_often,
                          //     }
                          //   : { value: values.how_often })}
                          value={values.how_often}
                          onChange={(e) => {
                            setFieldValue("first_occurence_start", "");
                            setFieldValue("first_occurence_end", "");
                            setFieldValue("how_often", e);
                          }}
                          disabled={projectStatus}
                        />
                      </Col>
                      <Col md={12} style={{ marginTop: "8px" }}>
                        <FormikInputFloating
                          label="Approval Date"
                          placeHolder="Enter Approval Date"
                          name="approval_date"
                          type="number"
                          className="w-100 "
                          autoComplete="new-password"

                          // requiredField
                        />
                        <HeadingComponent
                          text={"Days before template start."}
                          fontSize="11px"
                          fontWeight={400}
                          margin="3px 0px"
                          color="#424242"
                        />
                      </Col>
                      <Col md={12} className="d-flex flex-column my-2">
                        <InputTextLabel>First Occurance</InputTextLabel>
                        {values.how_often === "Weekly" ? (
                          <WeekendsDatePicker
                            onChange={(date) => {
                              setFieldValue("first_occurence_start", date.formattedStartDate);
                              setFieldValue("first_occurence_end", date.formattedEndDate);
                            }}
                            updateForm={updateForm}
                            firstOccurenceStart={values?.first_occurence_start}
                            firstOccurenceEnd={values?.first_occurence_end}
                            disabled={projectStatus}
                          />
                        ) : values.how_often === "Monthly" ? (
                          <MonthYearPicker
                            onChange={(date) => {
                              setFieldValue("first_occurence_start", date.startDate);
                              setFieldValue("first_occurence_end", date.endDate);
                            }}
                            type="month"
                            updateForm={updateForm}
                            firstOccurenceStart={values?.first_occurence_start}
                            firstOccurenceEnd={values?.first_occurence_end}
                            disabled={projectStatus}
                          />
                        ) : null}
                        {errors.first_occurence_start && <div className="input-error-style">{errors?.first_occurence_start}</div>}

                        {/* ====================================== */}
                      </Col>

                      {/* ====================================== */}
                      <Col md={12} className="mt-2">
                        <FormikDateMonthInput
                          format={"YYYY-MM-DD"}
                          className="w-100"
                          style={{ height: "40px" }}
                          allowClear={false}
                          name={"invoice_date"}
                          {...(updateForm
                            ? {
                                defaultValue: dayjs(values.invoice_date, "YYYY/MM/DD"),
                              }
                            : {})}
                          label="Invoice Date"
                          onChange={(e, text) => {
                            setFieldValue("invoice_date", text);
                          }}
                          disabledDate={(current) => {
                            let customDate = moment().format("YYYY-MM-DD");
                            return current && current < moment(customDate, "YYYY-MM-DD");
                          }}
                        />
                      </Col>
                      <Col md={12} style={{ marginTop: "8px" }}>
                        <FormikDateMonthInput
                          format={"YYYY-MM-DD"}
                          className="w-100"
                          style={{ height: "40px" }}
                          allowClear={false}
                          {...(updateForm
                            ? {
                                defaultValue: dayjs(values.invoice_due_date, "YYYY/MM/DD"),
                              }
                            : {})}
                          name={"invoice_due_date"}
                          label="Invoice Due Date"
                          onChange={(e, text) => {
                            setFieldValue("invoice_due_date", text);
                          }}
                          disabledDate={(current) => {
                            let customDate = moment().format("YYYY-MM-DD");
                            return current && current < moment(customDate, "YYYY-MM-DD");
                          }}
                        />
                      </Col>

                      {/* ====================================== */}
                      <Col md={12} className="mt-2">
                        <FormikInputFloating
                          label={`${values.how_often} Projections`}
                          placeHolder="Enter Projections"
                          name="months_of_projections"
                          type="number"
                          className="w-100 "
                          autoComplete="new-password"
                          disabled={values.projection_status || projectStatus}
                          // requiredField
                        />
                      </Col>
                      <Col md={12} className="mt-1">
                        <FormikSelectInput
                          label="Approval Method"
                          placeholder="Select Approval Method"
                          options={[
                            { label: "Manual", value: "manual" },
                            { label: "Auto", value: "auto" },
                          ]}
                          // defaultValue="Weekly"
                          width="100%"
                          name="approval_method"
                          {...(updateForm
                            ? {
                                defaultValue: values.approval_method,
                              }
                            : {})}
                          onChange={(e) => {
                            setFieldValue("approval_method", e);
                          }}
                          disabled={projectStatus}
                        />
                      </Col>
                      {/* ====================================== */}
                      {!projectStatus && (
                        <Col md={12} className="my-3 d-flex align-items-center">
                          <Checkbox
                            name="projection_status"
                            className="me-2"
                            onChange={(e) => {
                              if (e.target.checked) {
                                setFieldValue("months_of_projections", 1);
                              }
                              setFieldValue("projection_status", e.target.checked);
                            }}
                            defaultChecked={values.projection_status}
                          />
                          <InputTextLabel>Disable Projection</InputTextLabel>
                        </Col>
                      )}
                      <Col md={24} className="pb-3 mt-2">
                        <FormikTextArea
                          label="Description"
                          placeHolder="Type Notes Here..."
                          name="description"
                          type="text"
                          className="w-100 mb-2"
                          autoComplete="new-password"
                          width="100%"
                          onChange={(e) => {
                            setFieldValue("description", e);
                          }}
                        />
                      </Col>
                    </Row>
                  </div>
                </Form>
              )}
            </Formik>

            <div style={{ display: addStaffSetps === 1 ? "none" : "block" }}>
              <AddServiceForm
                addStaffSetps={addStaffSetps}
                setAddStaffSetps={setAddStaffSetps}
                formikRefSecondStep={formikRefSecondStep}
                handleCloseModal={() => {
                  close();
                }}
                updateForm={updateForm}
                updateDetails={updateDetails}
                companyId={companyId}
                requiredDropdowns={requiredDropdowns}
                tempFormDetails={tempFormDetails}
                setLoader={setLoader}
                clientPets={clientPets}
                projectStatus={projectStatus}
              />
            </div>
          </div>
        }
        footer={
          <div className="d-flex justify-content-between mt-4">
            <div>
              {addStaffSetps === 2 && (
                <CustomBtnWithIcon
                  btnText={"Back"}
                  hideIcon
                  noBackground
                  className="me-2"
                  width="90px"
                  onClick={() => {
                    // if (formikRef?.current) {
                    //   formikRef?.current?.handleSubmit();
                    // }

                    setAddStaffSetps(1);
                  }}
                />
              )}
            </div>

            <div className="d-flex">
              <CustomBtnWithIcon btnText={"Cancel"} hideIcon noBackground className="me-2" width="90px" onClick={close} />
              {addStaffSetps === 2 ? (
                <CustomBtnWithIcon
                  btnText={"Save"}
                  hideIcon
                  className="me-2"
                  width="90px"
                  type="submit"
                  disabled={loader}
                  onClick={() => {
                    if (formikRefSecondStep?.current) {
                      formikRefSecondStep?.current?.handleSubmit();
                    }
                  }}
                />
              ) : (
                <>
                  <CustomBtnWithIcon
                    btnText={"Next"}
                    hideIcon
                    className="me-2"
                    width="90px"
                    type="submit"
                    onClick={() => {
                      if (formikRef?.current) {
                        formikRef?.current?.handleSubmit();
                      }
                    }}
                  />
                </>
              )}
            </div>
          </div>
        }
      />
    </div>
  );
};

export default AddNewTemplateForm;

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .max(50, "Max name length is 50 characters") // Maximum length constraint
    .required("Name is required"),
  billing_frequency: Yup.string().required("Billing frequency is required"),
  first_occurence_start: Yup.string().required("First occurrence is required"),

  approval_date: Yup.date()

    .required("Approval date is required"),
  invoice_due_date: Yup.date()
    .min(Yup.ref("invoice_date"), "Invoice date must be after invoice date")
    .required("Invoice due date is required"),
  months_of_projections: Yup.number()
    .max(100, "Months of projections cannot exceed 100")
    .when("projection_status", {
      is: true,
      then: Yup.number().nullable(), // Set months_of_projections as nullable if projection_status is true
      otherwise: Yup.number().nullable().min(1, "A minimum of one projection is required").required("Projection is required"),
    }),

  invoice_date: Yup.date()
    // .min(Yup.ref("invoice_due_date"), "Invoice date must be after invoice due date") // Minimum date based on another field
    .required("Invoice date is required"),
  description: Yup.string().max(500, "Max description length is 500 characters"),
  client: Yup.string().required("Client is required"),
  approval_method: Yup.string().required("Approval method is required"),
  // staff: Yup.string().required("Staff is required"),
  how_often: Yup.string().required("This is required"),
});
const { RangePicker } = DatePicker;

const WeekendsDatePicker = ({ onChange, updateForm, firstOccurenceStart, firstOccurenceEnd, disabled }) => {
  const formatDate = (date) => {
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    month = month < 10 ? "0" + month : month;
    let day = date.getDate();
    day = day < 10 ? "0" + day : day;
    return `${year}-${month}-${day}`;
  };

  const disabledPastDates = (current) => {
    const currentDate = moment();
    const startOfWeek = currentDate.clone().startOf("week");

    return current.startOf("week") <= startOfWeek;
  };

  // Parse the dates using dayjs
  const startDate = dayjs(firstOccurenceStart);
  const endDate = dayjs(firstOccurenceEnd);

  // Calculate the week number for the start date
  const weekNumber = startDate.week();

  // Construct the start and end dates of the week
  const startOfWeek = startDate.startOf("week");
  const endOfWeek = startDate.endOf("week");

  // Format the dates in the required format
  const formattedStartDate = startOfWeek.format("YYYY-MM-DD");
  const formattedEndDate = endOfWeek.format("YYYY-MM-DD");

  return (
    <DatePicker
      picker="week"
      style={{ height: "40px" }}
      firstDayOfWeek={1}
      showWeekNumber={false}
      disabledDate={disabledPastDates}
      {...(updateForm && firstOccurenceStart !== "" && firstOccurenceEnd !== ""
        ? {
            defaultValue: startOfWeek,
          }
        : {})}
      onChange={(dates, dateStrings) => {
        if (dateStrings) {
          // Extract the year and week number from the dateString
          const [year, week] = dateStrings.split("-");
          const weekNumber = parseInt(week.replace("th", ""));

          // Calculate the starting date of the selected week (Monday)
          const startDate = new Date(year, 0, 1 + (weekNumber - 1) * 7);
          const dayOfWeek = startDate.getDay();
          const startOfWeek = startDate;
          startOfWeek.setDate(startDate.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1));

          // Calculate the ending date of the selected week (Sunday)
          const endOfWeek = new Date(startOfWeek);
          endOfWeek.setDate(startOfWeek.getDate() + 6);

          const formattedStartDate = formatDate(startOfWeek);
          const formattedEndDate = formatDate(endOfWeek);

          onChange({ formattedStartDate, formattedEndDate });
        }
      }}
      disabled={disabled}
    />
  );
};

function MonthYearPicker({ onChange, type = "month", updateForm, firstOccurenceStart, firstOccurenceEnd, disabled }) {
  //
  const disabledPastDates = (current) => {
    const currentDate = moment();
    // Disable past months
    if (current && current.valueOf() < Date.now()) {
      return true;
    }
    // Disable the current month if some days have passed
    if (current.isSame(currentDate, "month") && currentDate.date() > 1 && currentDate.date() < moment().endOf("month").date()) {
      return true;
    }

    return false;
  };

  const startDate = dayjs(firstOccurenceStart, "YYYY/MM");

  return (
    <DatePicker
      {...(updateForm && firstOccurenceStart !== ""
        ? {
            defaultValue: startDate,
          }
        : {})}
      picker={type}
      showTime={false}
      style={{ height: "40px" }}
      disabledDate={disabledPastDates} // Disable past months
      onChange={(date, dateString) => {
        let datev2 = new Date(date);
        let firstDay = new Date(datev2.getFullYear(), datev2.getMonth(), 1);
        let lastDay = new Date(datev2.getFullYear(), datev2.getMonth() + 1, 0);

        onChange({ startDate: moment(firstDay).format("YYYY-MM-DD"), endDate: moment(lastDay).format("YYYY-MM-DD") });
      }}
      disabled={disabled}
    />
  );
}
