import { DatePicker, Divider, Form, Input, Switch } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import CustomBtnWithIcon from "../../../components/buttonWithIcon";
import styled from "styled-components";
import Dragger from "antd/es/upload/Dragger";
import { ReactComponent as DefaultImageIcon } from "../../../assets/images/uploadImage.svg";
import { getAccountInfo, updateClientInfo } from "../../../util/DataService";
import { pushNotification } from "../../../util/Notification";
import { useSelector } from "react-redux";
import PermissionModal from "../../../components/Modals/PermissionModal";
import { checkPermission, logOut } from "../../../util/commonUtil";
import ImageUploader from "../../../components/Form/ImageUploader";
import { useNavigate } from "react-router-dom";
import LocationSelector from "../../../components/CustomAddressInput/locationSelector";
import useFetchClientOnBoardSettings from "../../../hooks/useFetchClientOnboardingSettings";

const AccountSettingsScreen = ({ props }) => {
  const permissionManagement = useSelector((state) => state.permissionManagement);
  const { clientSettings } = useFetchClientOnBoardSettings();

  const [form] = Form.useForm();
  const [file, setFile] = useState("");
  const [loading, setLoading] = useState(false);
  const [routeOptimizorChecked, setRouteOptimizorChecked] = useState(false);
  const [address, setAddress] = useState("");
  const [addressLatAndLong, setAddressLatAndLong] = useState([]);

  let navigate = useNavigate();
  const clientInfo = props.clientSingleDetails?.responseData;
  // form.setFieldsValue({
  //   name: clientInfo?.name,
  //   username: clientInfo?.username,
  //   dob: clientInfo.dob ? dayjs(clientInfo.dob) : null,
  //   bio: clientInfo?.bio,
  //   email: clientInfo?.email,
  //   address: clientInfo?.address,
  //   state: clientInfo?.state,
  //   city: clientInfo?.city,
  //   zip: clientInfo?.zip,
  //   is_active: false
  // });

  const { Item } = Form;
  const [permissionPopUp, setPermissionPopUp] = useState(false);
  const [image, setImage] = useState(null);

  const [savedImageUrl, setSavedImageUrl] = useState(null);

  useEffect(() => {
    props?.fetchLoggedInClientInfo?.();
  }, []);
  useEffect(() => {
    form.setFieldsValue({
      name: clientInfo?.name,
      username: clientInfo?.username,
      // dob: clientInfo.dob ? dayjs(clientInfo?.dob) : null,
      bio: clientInfo?.bio,
      email: clientInfo?.email,
      address_1: clientInfo?.address,
      state: clientInfo?.state,
      city: clientInfo?.city,
      zip: clientInfo?.zip,
      is_active: false,
    });
    setAddress(clientInfo && clientInfo.address ? clientInfo.address : "");
  }, [clientInfo]);

  useEffect(() => {
    if (!savedImageUrl) {
      setSavedImageUrl(clientInfo?.client_photo_key);
    }
  }, [clientInfo]);

  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      setFile(info.file.originFileObj);

      setLoading(false);
    }
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      pushNotification("You can only upload JPG/PNG file!", "error");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      pushNotification("Image must smaller than 2MB!", "error");
    }
    return isJpgOrPng && isLt2M;
  };

  const removeImage = () => {
    setImage(null);
    setSavedImageUrl(null);
  };

  const updateData = (formValues) => {
    formValues = { ...formValues, latitude: addressLatAndLong[0], longitude: addressLatAndLong[1] };
    updateClientInfo(clientInfo?.id, formValues)
      .then(({ data }) => {
        pushNotification(data.message, "success");
        if (image) {
          setSavedImageUrl(URL.createObjectURL(image));
          setImage(null);
        }
        if (!formValues.is_active) {
          logOut();
          navigate("/company/signin");
        }
      })
      .catch((error) => {
        pushNotification("Something Went Wrong!", "error");
      });
  };

  const onFinish = (formValues) => {
    if (checkPermission(permissionManagement.permissions, "edit_client")) {
      formValues.dob = formValues.dob ? dayjs(formValues.dob).format("YYYY-MM-DD") : formValues.dob;
      formValues["is_active"] = !formValues.is_active;
      if (!image && !savedImageUrl) {
        formValues["client_photo_key"] = "";
      }
      if (image) {
        let body = new FormData();
        body.append("image", image);
        updateClientInfo(clientInfo?.id, body)
          .then(({ data }) => {
            updateData(formValues);
          })
          .catch((error) => {
            pushNotification("Something Went Wrong!", "error");
          });
      } else {
        updateData(formValues);
      }
    } else {
      setPermissionPopUp(true);
    }
  };

  let updateUserSettingsAllow = clientSettings?.update_info ? false : true;
  return (
    <Form name="accountSettingsForm" onFinish={onFinish} form={form}>
      {updateUserSettingsAllow && (
        <p>
          <span className="text-danger">
            *Note: The admin has disabled account settings from being updated. Contact admin for support
          </span>
        </p>
      )}
      {/* <input type="file" accept="image/*" onChange={handleImageUpload} /> */}
      <FormWraper>
        {/* <Item name="client_photo" label="Profile Picture">
          <DragerBox>
            <Dragger
              multiple={false}
              action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
              beforeUpload={beforeUpload}
              onChange={handleChange}
            >
              {file ? (
                <img src={URL.createObjectURL(file)} alt="avatar" style={{ maxWidth: "249px", maxHeight: "140px" }} />
              ) : (
                <>
                  <p className="ant-upload-drag-icon">
                    <DefaultImageIcon />
                  </p>
                  <p className="ant-upload-text">Upload image</p>
                </>
              )}
            </Dragger>
          </DragerBox>
        </Item> */}
        <Item label="Profile Picture">
          <ImageUploader
            removeImage={removeImage}
            DefaultImageIcon={DefaultImageIcon}
            savedImageUrl={savedImageUrl}
            image={image}
            setImage={setImage}
            updateUserSettingsAllow={updateUserSettingsAllow}
          />
        </Item>
        <Divider />
        <Item
          name="name"
          label="Full Name"
          colon={false}
          rules={[
            {
              required: true,
              message: "Please enter name",
            },
            {
              min: 3,
              max: 50,
              message: "Name must be between 3 and 50 characters",
            },
          ]}
        >
          <Input size="large" placeholder="Enter Name" disabled={updateUserSettingsAllow} />
        </Item>
        <Divider />
        <Item
          name="username"
          label="Username"
          colon={false}
          rules={[
            {
              required: true,
              message: "Please select a username",
            },
          ]}
        >
          <Input size="large" placeholder="Enter Username" disabled={updateUserSettingsAllow} />
        </Item>
        <Divider />
        <Item name="dob" label="Date of Birth" colon={false}>
          {/* <span> */}
          <DatePicker
            style={{ width: "100%" }}
            type="date"
            placeholder="Select Date of Birth"
            format={"DD/MM/YYYY"}
            disabledDate={(current) => current && current > dayjs().endOf("day")}
            size="large"
            disabled={updateUserSettingsAllow}
          />
          {/* </span> */}
        </Item>
        <Divider />
        <Item name="bio" label="Bio" colon={false}>
          <Input.TextArea size="large" placeholder="Enter Bio" disabled={updateUserSettingsAllow} />
        </Item>
        <Divider />
        <Item
          name="email"
          label="Email"
          colon={false}
          rules={[
            {
              min: 3,
              max: 50,
              message: "Email must be between 3 and 50 characters",
            },
          ]}
        >
          <Input disabled size="large" placeholder="Enter Email" />
        </Item>
        <Divider />
        {/* <Item
          name="address"
          label="Address"
          colon={false}
          rules={[
            {
              max: 200,
              message: "Address must be less than 200 characters",
            },
          ]}
        >
          <Input size="large" placeholder="Enter Address" />
        </Item> */}
        <Item label="Address" colon={false}>
          <LocationSelector
            address={address}
            setAddress={setAddress}
            setAddressLatAndLong={setAddressLatAndLong}
            form={form}
            checked={routeOptimizorChecked}
            disabled={updateUserSettingsAllow}
          />
        </Item>

        <Divider />
        <Item
          name="state"
          label="State"
          colon={false}
          rules={[
            {
              max: 100,
              message: "State must be less than 100 characters",
            },
          ]}
        >
          <Input size="large" placeholder="Enter State" disabled={updateUserSettingsAllow} />
        </Item>
        <Divider />
        <Item
          name="city"
          label="City"
          colon={false}
          rules={[
            {
              max: 100,
              message: "City must be less than 100 characters",
            },
          ]}
        >
          <Input size="large" placeholder="Enter City" disabled={updateUserSettingsAllow} />
        </Item>
        <Divider />
        <Item
          name="zip"
          label="Zip Code"
          colon={false}
          rules={[
            {
              max: 20,
              message: "Zip Code must be less than 20 characters",
            },
          ]}
        >
          <Input size="large" placeholder="Enter Zip Code" disabled={updateUserSettingsAllow} />
        </Item>
        <Item name="is_active" label="Deactivate your account" colon={false}>
          <Switch defaultValue={false} disabled={updateUserSettingsAllow} />
        </Item>
      </FormWraper>
      {props?.fromClientPortal ? (
        <div className="d-flex justify-content-end">
          <CustomBtnWithIcon btnText={"Save Changes"} type="submit" hideIcon={true} />
        </div>
      ) : (
        <SaveContainer className="d-flex justify-content-end">
          <CustomBtnWithIcon btnText={"Save Changes"} type="submit" hideIcon={true} />
        </SaveContainer>
      )}

      <PermissionModal onclose={() => setPermissionPopUp(false)} openModal={permissionPopUp} />
    </Form>
  );
};

const FormWraper = styled.div`
  overflow: auto;
  label {
    width: 312px;
  }
  input,
  textarea,
  div {
    width: 350px;
  }
  .ant-upload-wrapper {
    width: 249px;
  }

  .ant-form-item-label {
    text-align: start;
    font-weight: 500;
  }

  ::-webkit-scrollbar {
    width: 0px;
  }
`;
const DragerBox = styled.div`
  width: 249px !important;
`;

const SaveContainer = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  border-top: 1px solid #e0e0e0;
  background-color: white;
  padding: 10px 20px;
`;

export default AccountSettingsScreen;
