import { ReactComponent as GeneralSchedulerIcon } from "../assets/companySvgIcons/sidebarIcons/generalSchedulerIcon.svg";
import { ReactComponent as GeneralReportIcon } from "../assets/companySvgIcons/sidebarIcons/generalReporIcon.svg";
import { ReactComponent as GeneralServiceIcon } from "../assets/companySvgIcons/sidebarIcons/generalServicesIcon.svg";
import { ReactComponent as GeneralTaskicon } from "../assets/companySvgIcons/sidebarIcons/generalTasksIcon.svg.svg";
import { ReactComponent as RouteOptimizationIcon } from "../assets/companySvgIcons/sidebarIcons/routeOptimizationIcon.svg";
// management icons
import { ReactComponent as ManagementClientsicon } from "../assets/companySvgIcons/sidebarIcons/managementClientsIcon.svg";
import { ReactComponent as ManagementPetsIcon } from "../assets/companySvgIcons/sidebarIcons/managementPetsIcon.svg";
import { ReactComponent as ManagementKeyIcon } from "../assets/companySvgIcons/sidebarIcons/managementKeymanagementIcon.svg";
import { ReactComponent as ManagementStaffIcon } from "../assets/companySvgIcons/sidebarIcons/managementStaffIcon.svg";
import { ReactComponent as ManagementInvoicing } from "../assets/companySvgIcons/sidebarIcons/managementInvoicingIcon.svg";
// setings icons
import { ReactComponent as SettingsUserManagement } from "../assets/companySvgIcons/sidebarIcons/SettingsUserMng.svg";
import { ReactComponent as SettingsGeatIcon } from "../assets/companySvgIcons/sidebarIcons/SettingsGearIcon.svg";
import { ReactComponent as SettingsPaymentIcon } from "../assets/companySvgIcons/sidebarIcons/SettingsPaymentIcon.svg";

import { ReactComponent as SettingsMsgsIcon } from "../assets/companySvgIcons/sidebarIcons/SettingsMsgsIcon.svg";
import { ReactComponent as Dashboard } from "../assets/platformSvgIcons/Dashboard.svg";
import { ReactComponent as CompanyManagement } from "../assets/platformSvgIcons/CompanyManagement.svg";
import { ReactComponent as PaymentPlans } from "../assets/platformSvgIcons/PaymentPlans.svg";
import { ReactComponent as Settings } from "../assets/platformSvgIcons/Settings.svg";
import { ReactComponent as DocumentsIcon } from "../assets/rawSvgs/DocumentsIcon.svg";

export const sidebarNavData = [
  {
    heading: "General",
    itemList: [
      {
        itemName: "Scheduler",
        itemIcon: <GeneralSchedulerIcon />,
        navigateTo: "/company/scheduler",
      },
      {
        itemName: "Reports",
        navigateTo: "/company/reports",
        itemIcon: <GeneralReportIcon />,
      },
      {
        itemName: "Services & Holidays",
        navigateTo: "/company/services",
        itemIcon: <GeneralServiceIcon />,
      },
      {
        itemName: "Tasks",
        itemIcon: <GeneralTaskicon />,
        navigateTo: "/company/tasks",
      },
      {
        itemName: "Route Optimization",
        itemIcon: <RouteOptimizationIcon />,
        navigateTo: "/company/route-optimization",
      },
      {
        itemName: "Messages",
        navigateTo: "/company/message",
        itemIcon: <SettingsMsgsIcon />,
      },
    ],
  },
  {
    heading: "Management",
    itemList: [
      {
        itemName: "Clients Management",
        itemIcon: <ManagementClientsicon />,
        active: true,
        navigateTo: "/company/clients",
        pathsToMatch: ["/company/clients", "/company/clients/client-details/"],
      },
      {
        itemName: "Pets Management",
        itemIcon: <ManagementPetsIcon />,
        navigateTo: "/company/pets/pet-management",
      },
      {
        itemName: "Key Management",
        itemIcon: <ManagementKeyIcon />,
        navigateTo: "/company/keys/key-management",
      },
      {
        itemName: "Staff Management",
        itemIcon: <ManagementStaffIcon />,
        active: true,
        navigateTo: "/company/staff",
        pathsToMatch: ["/company/staff/staff-details/:id", "/company/staff"],
      },
      {
        itemName: "User Management",
        itemIcon: <SettingsUserManagement />,
        active: true,
        navigateTo: "/company/user-management",
      },
    ],
  },

  {
    heading: "Settings",
    itemList: [
      {
        itemName: "Invoicing",
        itemIcon: <ManagementInvoicing />,
        navigateTo: "/company/invoicing",
      },
      {
        itemName: "Settings",
        itemIcon: <SettingsGeatIcon />,
        navigateTo: "/company/settings",
      },
      {
        itemName: "Payment",
        itemIcon: <SettingsPaymentIcon />,
        navigateTo: "/company/payment",
      },
      // {
      //   itemName: "Messages",
      //   navigateTo: "/company/message",
      //   itemIcon: <SettingsMsgsIcon />,
      // },
    ],
  },
];

export const platformSidebarNavData = [
  {
    itemList: [
      {
        itemName: "Dashboard",
        itemIcon: <Dashboard />,
        navigateTo: "/platform/dashboard",
      },
      {
        itemName: "Company Management",
        itemIcon: <CompanyManagement />,
        navigateTo: "/platform/company-management",
        active: true,
      },
      {
        itemName: "Payment Plans",
        itemIcon: <PaymentPlans />,
        navigateTo: "/platform/payment",
        active: true,
      },
      {
        itemName: "Settings",
        itemIcon: <Settings />,
        navigateTo: "/platform/settings",
      },
    ],
  },
];

export const clientSidebarNavData = [
  {
    itemList: [
      {
        itemName: "Scheduler",
        itemIcon: <GeneralSchedulerIcon />,
        navigateTo: "/client/scheduler",
      },
      {
        itemName: "Pets",
        itemIcon: <ManagementPetsIcon />,
        navigateTo: "",
        // navigateTo: "/client/pets/pet-management",
        active: true,
      },
      {
        itemName: "Keys",
        itemIcon: <ManagementKeyIcon />,
      },
      {
        itemName: "Invoices",
        itemIcon: <ManagementInvoicing />,
        navigateTo: "/client/invoice",
      },
      {
        itemName: "Documents",
        itemIcon: <DocumentsIcon />,
        navigateTo: "/client/documents",
      },
      {
        itemName: "Messaging",
        itemIcon: <SettingsMsgsIcon />,
        navigateTo: "/client/message",
      },
      {
        itemName: "Settings",
        itemIcon: <SettingsGeatIcon />,
        navigateTo: "/client/settings",
      },
    ],
  },
];
