import React from "react";
import { CustomModal } from "../ModalStyles";
import HeadingComponent from "../../Heading";
import { AntdesignTable } from "../../antDesignTable/AntdesignTable";
import { DuplicateKeysColumns } from "../../../constants/KeysTableHeaders/KeyTableHeaders";

export default function DuplicateKeysModal({
  openKeyModal,
  setOpenKeyModal,
  handleEditRow,
  handleDelete,
  handleHistory,
  duplicateKeys,
  isLoading,
}) {
  // const handleEditRow=()=>{}
  // const handleDelete=()=>{}
  // const handleHistory=()=>{}

  return (
    <div>
      <CustomModal
        open={openKeyModal}
        title="Duplicate Physical Keys"
        width="1200px"
        heigth="728px"
        onCancel={() => {
          setOpenKeyModal(false);
        }}
        footer={null}
        maskClosable={false}
      >
        <AntdesignTable
          columns={DuplicateKeysColumns({ handleDelete, handleEditRow, handleHistory })}
          data={duplicateKeys || []}
          loading={isLoading}
          // selectedRows={(rows) => setselectedKeysRows(rows.SelctRows)}
          rowKey="id"
          allowMultieSelectRows={true}
        />
      </CustomModal>
    </div>
  );
}

const Heading = ({ text = "", margin, fontSize = "0.75rem", color = "#3B3B3B" }) => {
  return <HeadingComponent text={text} fontSize={fontSize} color={color} fontWeight={700} margin={margin} />;
};
