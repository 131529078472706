import styled from "styled-components";
import { CustomModal } from "../../components/Modals/ModalStyles";
import { Tag, Flex, Collapse, Panel } from "antd";
import { SchedulerMonthUserIcon, TimeIcon, BirthDate } from "../../assets/rawSvgs/index";
import { convertTo12HourFormat, formatTimefromDate } from "../../util/formatTime";
import CustomBtnWithIcon from "../../components/buttonWithIcon";
import React, { useEffect, useState } from "react";

import dayjs from "dayjs";
import ChangeRequestModal from "../../pages/client/scheduler/ChangeRequestModal";
import DeleteConfirmationRowModal from "../../components/Modals/DeleteConfirmationRowModal";
import { main_api } from "../../api/axios_helper";
import { companyAPIsEndPoints } from "../../constants/ApiEndPoints";
import { pushNotification } from "../../util/Notification";
import { schedulerStatus, schedulerBackground, schedulerColor } from "./helpers";
import { useSelector } from "react-redux";
import { checkPermission } from "../../util/commonUtil";
import PermissionModal from "../Modals/PermissionModal";
import axiosInstance from "../../util/AxiosConfig";
import { SchedulerStatus } from "../../enums/scheduler";

const EventDetail = ({ companyPortal = false, multipeEvents = null, event, isModalOpen, setIsModalOpen, handleDataChange }) => {
  const [editEvent, setEditEvent] = useState(null);
  const [deleteEvent, setDeleteEvent] = useState(null);
  const [permissionPopUp, setPermissionPopUp] = useState(false);

  const [multipleItems, setMultipleItems] = useState([]);
  const { permissionManagement, auth } = useSelector((state) => state);

  const deleteService = async () => {
    const client_id = auth.loginResponse?.selectCompany?.filter((item) => item.client_id !== null)[0].client_id;
    if (event.status === SchedulerStatus.SCHEDULED) {
      let data = {
        date: event.date,
        id: event.id,
        client: client_id,
        one_day_service_request: event.one_day_service_request.id,
        change_request_id: event.change_request_id,
        key: event?.key?.id || null,
        note: event.note || "",
        delete_request: true,
        service: event?.service?.id || null,
        time_block: event?.time_block?.id || null,
      };
      if (event.pets) {
        data["pets"] = event.pets.map((pet) => pet.id);
      } else {
        data["pets"] = [];
      }
      if (event.service_request_task) {
        const taskObjects = event.service_request_task.map((task) => {
          return {
            name: task.name,
          };
        });
        data["tasks"] = taskObjects;
      }

      axiosInstance
        .post(companyAPIsEndPoints.CHANGE_SERVICE_REQUEST, data)
        .then(({ data }) => {
          if (data.message) {
            pushNotification(data.message, "success");
            setDeleteEvent(false);
            handleDataChange();
            setIsModalOpen(false);
          }
        })
        .catch((error) => {
          // console.log(error);
          pushNotification("Something Went Wrong!", "error");
        });
    } else {
      try {
        const { data } = await main_api.delete(companyAPIsEndPoints.DELETE_SERVICE_REQUEST(deleteEvent.id));
        if (data.isSuccess) {
          pushNotification("Service Deleted Successfully!", "success");
        }
        setDeleteEvent(false);
        handleDataChange();
        setIsModalOpen(false);
      } catch (error) {
        setDeleteEvent(null);
        // console.log("error", error);
        pushNotification("Something went Wrong!", "error");
      }
    }
  };

  const [activeItem, setActiveItem] = useState(null);

  const handleCollapseChange = (keys) => {
    setActiveItem(keys.slice(-1));
  };

  const editSingleEvent = (event) => {
    if (checkPermission(permissionManagement.permissions, "edit_service_request")) {
      setEditEvent(event);
    } else {
      setPermissionPopUp(true);
    }
  };

  const deleteSingleEvent = (event) => {
    if (checkPermission(permissionManagement.permissions, "delete_service_request")) {
      setDeleteEvent(event);
    } else {
      setPermissionPopUp(true);
    }
  };

  useEffect(() => {
    let items = [];
    multipeEvents &&
      multipeEvents.map((item, index) => {
        items.push({
          key: index,
          label: `${item.title}`,
          children: (
            <SignleEvent
              event={item}
              setDeleteEvent={(event) => deleteSingleEvent(event)}
              setEditEvent={(event) => editSingleEvent(event)}
            />
          ),
        });
      });
    setMultipleItems(items);
  }, [multipeEvents]);

  return (
    <>
      <CustomModal
        open={isModalOpen}
        title={multipeEvents ? "Events" : ""}
        width="800px"
        heigth="200px"
        onCancel={() => {
          setIsModalOpen(false);
        }}
        footer={null}
      >
        {multipeEvents ? (
          <div style={{ marginTop: "30px" }}>
            <Collapse
              onChange={handleCollapseChange}
              activeKey={activeItem}
              style={{ backgroundColor: "#EEEEEE" }}
              items={multipleItems}
            />
          </div>
        ) : (
          <SignleEvent
            event={event}
            setDeleteEvent={(event) => deleteSingleEvent(event)}
            setEditEvent={(event) => editSingleEvent(event)}
            companyPortal={companyPortal}
            onCloseBtn={() => {
              setIsModalOpen(false);
            }}
          />
        )}
      </CustomModal>
      {editEvent && (
        <ChangeRequestModal
          handleDataChange={handleDataChange}
          selectedEvent={editEvent}
          isModalOpen={true}
          setIsModalOpen={() => setEditEvent(null)}
        />
      )}
      <DeleteConfirmationRowModal
        onclose={() => setDeleteEvent(null)}
        handleConfirmDeleteRows={deleteService}
        openConfimrationDeleteModal={deleteEvent ? true : false}
      />
      <PermissionModal onclose={() => setPermissionPopUp(false)} openModal={permissionPopUp} />
    </>
  );
};

export default EventDetail;

const SignleEvent = ({ companyPortal, event, setDeleteEvent, setEditEvent, onCloseBtn }) => {
  console.log("event", event);
  return (
    <>
      <div style={{ gap: "5px" }} className="d-flex align-center">
        <EventHeader>{event.title}</EventHeader>
        <Tag
          style={{
            ...tagStyle,
            backgroundColor: schedulerBackground(event),
            color: schedulerColor(event),
          }}
        >
          {schedulerStatus(event)}
        </Tag>
      </div>
      <FlexItem>
        <span>
          <BirthDate width="16px" height="16px" stroke={"#FBCF34"} />
        </span>
        <span>{dayjs(event.date, "YYYY-MM-DD").format("DD MMMM, YYYY")}</span>
      </FlexItem>
      {event.staff && event.staff.name && (
        <FlexItem>
          <span>
            <SchedulerMonthUserIcon width="16px" height="16px" stroke={"#FBCF34"} />
          </span>
          <span>{(event?.staff && event.staff?.name) || ""}</span>
        </FlexItem>
      )}
      <FlexItem>
        <span>
          <TimeIcon width="16px" height="16px" stroke={"#FBCF34"} />
        </span>
        <span>
          {convertTo12HourFormat(event?.time_block?.start_time)} - {convertTo12HourFormat(event?.time_block?.end_time)}{" "}
        </span>
      </FlexItem>
      <Flex justify="end">
        <Flex gap="middle">
          {false ? (
            <CustomBtnWithIcon width="86px" noBackground={true} hideIcon={true} btnText={"Close"} onClick={onCloseBtn} />
          ) : (
            <>
              <CustomBtnWithIcon
                width="86px"
                noBackground={true}
                hideIcon={true}
                btnText={"Delete"}
                onClick={() => setDeleteEvent(event)}
              />
              <CustomBtnWithIcon hideIcon={true} btnText={"Edit"} onClick={() => setEditEvent(event)} />
            </>
          )}
        </Flex>
      </Flex>
    </>
  );
};

const EventHeader = styled.div`
  font-weight: 700;
  font-size: 16px;
`;

const tagStyle = {
  backgroundColor: "#E5F6FF",
  fontWeight: 500,
  border: "none",
  borderRadius: "28px",
  padding: "2px 8px 2px 6px",
  color: "#023047",
};

const FlexItem = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
`;
