import { useEffect, useState } from "react";
import PubNub from "pubnub";

// SINGLETON TO INITIALIZE PUBNUB ONCE

let pubnubInstance = null;

const initializePubNub = (authState, loggedInType) => {
  if (!pubnubInstance) {
    let pubnubInitUser = "";
    if (loggedInType?.selectedRoleType === "company_type") {
      pubnubInitUser = `company_admin_${authState.company_roles?.[0].company_id}`;
    } else if (loggedInType?.selectedRoleType === "company_user_type") {
      pubnubInitUser = `company_user_${loggedInType?.companyUserId}`;
    } else if (loggedInType?.selectedRoleType === "client_type") {
      // console.log("com user");
      let clientId = "";
      authState?.company_roles?.forEach((element) => {
        if (element.client_id) {
          clientId = element.client_id;
        }
      });
      pubnubInitUser = `client_${clientId}`;
    }

    // console.log("IINIT PUNB", { authState, loggedInType, pubnubInitUser });

    const pubnubKeys = {
      publishKey: process.env.REACT_APP_PUB_KEY,
      subscribeKey: process.env.REACT_APP_SUB_KEY,
    };
    if (!pubnubInitUser) return;
    pubnubInstance = new PubNub({
      ...pubnubKeys,
      uuid: pubnubInitUser,
      fileUploadPublishRetryLimit: 0,
      autoNetworkDetection: true,
      restore: true,
    });

    pubnubInstance.pamEnabled = false;
    pubnubInstance.addListener({
      status: (status) => {
        if (status.category === "PNAccessDeniedCategory") {
          pubnubInstance.pamEnabled = true;
        }
      },
    });
  }
  // console.log("pubnubInstance", pubnubInstance);
  return pubnubInstance;
};

const usePubNubService = (loggedInType, authState) => {
  const [pubnub, setPubnub] = useState(getPubNubInstance());

  useEffect(() => {
    if (!pubnub) {
      const instance = initializePubNub(authState, loggedInType);
      setPubnub(instance);
    }
  }, [authState, loggedInType, pubnub]);

  return pubnub;
};

export default usePubNubService;

export const getPubNubInstance = () => pubnubInstance;

export const logoutPubNub = () => {
  if (pubnubInstance) {
    pubnubInstance.stop(); // Stops all network activity
    pubnubInstance = null;
  }
};
