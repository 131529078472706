import React, { useState, useEffect } from "react";
// import CustomModal from '../../../components/Modal'
import { Divider, Form, Input, Radio, Space } from "antd";
import styled from "styled-components";
import CustomBtnWithIcon from "../../../components/buttonWithIcon";
import { CustomModal } from "../../../components/Modals/ModalStyles";
import { TagInputs } from "../../../components/TagInputs";
import { companyAPIsEndPoints } from "../../../constants/ApiEndPoints";
import { main_api } from "../../../api/axios_helper";

const { TextArea } = Input;

export default function AddPlan({ selectedPlan, setSelectedPlan, setAddPlan, loadSubscriptionPlans }) {
  const [form] = Form.useForm();
  const [planType, setPlanType] = useState("monthly");

  useEffect(() => {
    if (selectedPlan) {
      setPlanType(selectedPlan.plan_type);
      form.setFieldsValue({
        plan_type: selectedPlan ? selectedPlan.plan_type : "monthly",
        name: selectedPlan ? selectedPlan.name : "",
        description: selectedPlan ? selectedPlan.description : "",
        features: selectedPlan ? selectedPlan.features : [],
        cost: selectedPlan ? selectedPlan.cost : "",
      });
    }
  }, [selectedPlan]);

  const handleSubmit = async (values) => {
    console.log(values);
    if (selectedPlan) {
      main_api
        .put(`${companyAPIsEndPoints.SUBCSRIPTION}${selectedPlan.id}/`, { ...values, plan_type: planType })
        .then(({ data }) => {
          setAddPlan(false);
          setSelectedPlan(null);
          loadSubscriptionPlans();
          // setSubscriptionPlans(data.result);
        })
        .catch((error) => {
          console.log(error?.response?.data);
        });
    } else {
      main_api
        .post(companyAPIsEndPoints.SUBCSRIPTION, { ...values, plan_type: planType })
        .then(({ data }) => {
          setAddPlan(false);
          setSelectedPlan(null);
          loadSubscriptionPlans();
          // setSubscriptionPlans(data.result);
        })
        .catch((error) => {
          console.log(error?.response?.data);
        });
    }
  };

  const onChange = (e) => {
    setPlanType(e.target.value);
  };

  const onCancel = () => {
    setAddPlan(false);
    setSelectedPlan(null);
  };

  return (
    <CustomModal
      open={true}
      title={selectedPlan ? "Edit Plan" : "Add a Plan"}
      onCancel={onCancel}
      footer={null}
      maskClosable={false}
    >
      <div>
        <Form name="addPlanModal" onFinish={handleSubmit} form={form} layout="vertical">
          <FormItem
            label="Plan Name"
            name="name"
            rules={[
              {
                required: true,
                message: "Please Enter Plan Name",
              },
              {
                max: 50,
              },
            ]}
          >
            <Input placeholder="Enter Name" style={{ paddingBlock: "6px" }} />
          </FormItem>
          <FormItem required label="Subscription Type">
            <Radio.Group defaultValue="monthly" value={planType} onChange={onChange}>
              <Space direction="vertical">
                <Radio value="monthly">Monthly</Radio>
                <Radio value="yearly">Yearly</Radio>
              </Space>
            </Radio.Group>
          </FormItem>
          <FormItem
            label="Price"
            name="cost"
            rules={[
              {
                required: true,
                message: "Enter Plan Price",
              },
              {
                max: 25,
              },
            ]}
          >
            <Input placeholder="Enter Plan Price" style={{ paddingBlock: "6px" }} type="number" />
          </FormItem>
          <FormItem label="Description" name="description">
            <TextArea rows={3} placeholder="Type descripton here..." />
          </FormItem>
          <FormItem label="Features" style={{ width: "100%" }} name="features">
            <TagInputs onChange={() => {}} placeholder="Add Feature here" />
          </FormItem>
          <Divider />
          <div className="d-flex justify-content-end">
            <CustomBtnWithIcon
              btnText={"Cancel"}
              type="button"
              margin="0px 5px"
              noBackground
              hideIcon={true}
              onClick={onCancel}
            />
            <CustomBtnWithIcon btnText={"Save Plan"} type="submit" hideIcon={true} />
          </div>
        </Form>
      </div>
    </CustomModal>
  );
}

const FormItem = styled(Form.Item)`
  width: 100%;
`;
