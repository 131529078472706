import React, { useEffect } from "react";

import { PubNubProvider } from "pubnub-react";

import usePubNubService from "../../../patterns/InitializePubnub";
import AdminPortalChat from "../../../components/ClientAdminChatComponent/AdminPortalChat";
import Pubnub from "pubnub";
import usePubNubServiceHook from "../../../hooks/usePubnubChannelCountClients";
import { useSelector } from "react-redux";

//
const ChatPage = ({
  companyInternalChatState,
  selectChat,
  authState,
  fetchAllStaff,
  allStaffs,

  loggedInType,
  clientManagementState,
  getManagementClients,
}) => {
  useEffect(() => {
    fetchAllStaff();
    // getManagementClients();
  }, []);
  const pubnub = usePubNubService(loggedInType, authState);
  let unreadCounts = useSelector((state) => state.generalApp.unreadCountsClient);
  let [fetchChannelsToCheckUnreadCountCleint] = usePubNubServiceHook({ loggedInType: loggedInType, authState: authState });

  let pubnubInitUser = "";
  let adminName = authState.company_roles?.[0].company;
  let onlyCompId = authState.company_roles?.[0].company_id;
  let clientId = "";
  let isAdminOrUser =
    loggedInType?.selectedRoleType === "company_user_type"
      ? "company_user"
      : loggedInType?.selectedRoleType === "client_type"
        ? "company_client"
        : "company_admin";
  authState?.company_roles?.forEach((element) => {
    if (element.client_id) {
      clientId = element.client_id;
    }
  });

  if (loggedInType?.selectedRoleType === "company_type") {
    pubnubInitUser = `company_admin_${authState.company_roles?.[0].company_id}`;
  } else if (loggedInType?.selectedRoleType === "company_user_type") {
    pubnubInitUser = `company_user_${loggedInType.companyUserId}`;
  } else if (loggedInType?.selectedRoleType === "client_type") {
    pubnubInitUser = `client_${clientId}`;
  }

  // // console.log("loggedInType", loggedInType);
  // const pubnubKeys = {
  //   publishKey: process.env.REACT_APP_PUB_KEY,
  //   subscribeKey: process.env.REACT_APP_SUB_KEY,
  // };
  // // console.log({ pubnubInitUser, adminName, onlyCompId, isAdminOrUser });

  // const pubnub = new Pubnub({
  //   ...pubnubKeys,
  //   uuid: pubnubInitUser, // Pass the converted UUID
  //   fileUploadPublishRetryLimit: 0,
  //   autoNetworkDetection: true,
  //   restore: true,
  // });

  // let pamEnabled = false;
  // pubnub.addListener({
  //   status: function (status) {
  //     if (status.category === "PNAccessDeniedCategory") {
  //       pamEnabled = true;
  //     }
  //   },
  // });

  // const PamError = () => {
  //   return (
  //     <div className="pubnub-error">
  //       <h1>Warning! PubNub access manager enabled.</h1>
  //       <p>
  //         It looks like you have access manager enabled on your PubNub keyset. This sample app is not adapted to work with PAM by
  //         default.
  //       </p>
  //       <p>
  //         You can either disable PAM in the <a href="https://dashboard.pubnub.com/">PubNub Admin Portal</a> or add custom code to
  //         grant all necessary permissions by yourself. Please referer to the{" "}
  //         <a href="https://www.pubnub.com/docs/chat/components/react/access-manager">Chat Component docs</a> for more information.
  //       </p>
  //     </div>
  //   );
  // };

  // const KeysError = () => {
  //   return (
  //     <div className="pubnub-error">
  //       <h1>A PubNub account is required.</h1>
  //       <p>
  //         Visit the PubNub dashboard to create an account or login:
  //         <br />
  //         <a href="https://dashboard.pubnub.com/">https://dashboard.pubnub.com/</a>
  //         <br />
  //         Create a new app or locate your app in the dashboard. Enable the Presence, Files, Objects, and Storage features using a
  //         region of your choosing. For Objects, ensure the following are enabled:
  //       </p>
  //       <ul>
  //         <li>User Metadata Events</li>
  //         <li>Channel Metadata Events</li>
  //         <li>Membership Events</li>
  //       </ul>
  //       <p>Copy and paste your publish key and subscribe key into: </p>
  //       <pre>.env</pre>
  //       <p>before continuing.</p>
  //       <br />
  //     </div>
  //   );
  // };

  const stateObj = {
    selectChat,
    companyInternalChatState,
    adminId: pubnubInitUser,
    adminName,
    onlyCompId,
    isAdminOrUser,
    clientId,
    fetchChannelsToCheckUnreadCountCleint,
  };
  if (!pubnub) {
    return <>Loading...</>;
  }

  return (
    <div className="mt-4">
      <PubNubProvider client={pubnub}>
        <AdminPortalChat clientPortalChatHeight={true} {...stateObj} />
      </PubNubProvider>
    </div>
  );
  // return pubnubKeys.publishKey?.length && pubnubKeys.subscribeKey?.length ? (
  //   pamEnabled ? (
  //     <PamError />
  //   ) : (
  //     <PubNubProvider client={pubnub}>
  //       {/* <ModeratedChat {...stateObj} pubnubOrig={pubnub} /> */}
  //       <ChatPubnub {...stateObj} />
  //     </PubNubProvider>
  //   )
  // ) : (
  //   <KeysError />
  // );
};
export default ChatPage;
