import { Col, Input, Row } from "antd";
import React from "react";

import { ChatContainer } from "./styles";
import { ReactComponent as ChatEmoj } from "../../assets/rawSvgs/chatEmoj.svg";
import { ReactComponent as PlusSIgn } from "../../assets/rawSvgs/plusSign.svg";
import { ReactComponent as SendMsgIcon } from "../../assets/rawSvgs/sendMsgIcon.svg";
import { Tooltip } from "antd";
import { UserMessageComponent } from "../../pages/company/messages/ChatRightSideComponents";
import moment from "moment";
import HeadingComponent from "../Heading";
import pickerData from "@emoji-mart/data";
import Picker from "@emoji-mart/react";

//
const ConversationScreen = ({
  clientPortalChatHeight,
  setInputFieldText,
  inputFieldText,
  handleSendMessage,
  messages,
  onlyCompId,
  currentChannel,
  uuidMetaData,
  fetchMsgLoader,
  setOpenFileModal,
  setOpenEmoji,
  openEmoji,
  pickerRef,
  handleEmojiSelect,
  pubnub,
  adminId,
  adminName,
  pinMessages,
  handlePinnedMasg,
  addReactionToMessage,
  messagesContainerRef,
  setUserScrolled,
  userScrolled,
  scrollToBottom,
  messagesEndRef,
  handleDeleteMessage,
  fromClientChatUnreadFunction,
}) => {
  // HOOKS
  // console.log("object", clientPortalChatHeight);

  return (
    <div>
      {/* CHAT DIV */}

      <ChatContainer
        height={clientPortalChatHeight}
        ref={messagesContainerRef}
        onScroll={() => {
          const container = messagesContainerRef.current;
          const threshold = 50; // Adjust this value as needed
          if (container.scrollTop < container.scrollHeight - container.clientHeight - threshold) {
            setUserScrolled(true);
          } else {
            setUserScrolled(false);
          }
        }}
      >
        {fetchMsgLoader ? (
          "Loading..."
        ) : (
          <>
            {messages?.map((item, index) => {
              let pinnedkeys = "";
              let pinnedBy = "";
              const handleCheckPinCondition = (incom) => {
                // Check for pin actions and determine if the message is already pinned
                if (incom.actions && incom.actions.pin) {
                  const pinActions = incom.actions.pin;
                  // Check if any pin actions exist for the message
                  return Object.values(pinActions).some((actionsArray) => actionsArray.length > 0);
                }
                return false;
              };
              const isPinned = handleCheckPinCondition(item);
              if (item?.actions?.pin) {
                pinnedkeys = Object.keys(item?.actions?.pin);
                pinnedBy = pinnedkeys?.map((item) => (item === uuidMetaData.name ? "You" : item));
              }

              return (
                <div key={index} className="my-2 p-1">
                  <div className="d-flex align-items-start ms-2">
                    {isPinned && (
                      <>
                        {pinnedkeys?.length > 3 ? (
                          <Tooltip
                            placement="right"
                            title={pinnedkeys?.map((item) => {
                              return (
                                <div>
                                  <span>{item === uuidMetaData.name ? "You" : item}</span>
                                </div>
                              );
                            })}
                            className="d-flex"
                          >
                            {/* <PinMsg className="" /> */}
                            <HeadingComponent text={`Pinned by ${pinnedkeys?.length}`} fontSize="14px" margin={"0px 5px"} />
                          </Tooltip>
                        ) : (
                          <>
                            {/* <PinMsg className="" /> */}
                            <HeadingComponent text={`Pinned by ${pinnedBy}`} fontSize="14px" margin={"0px 5px"} />
                          </>
                        )}
                      </>
                    )}
                  </div>

                  <UserMessageComponent
                    chatterName={item?.message?.user?.name || item?.message?.sender?.name}
                    avatar={""}
                    msg={item?.message?.text || ""}
                    timeToken={moment(parseInt(item?.timetoken?.substring(0, 13))).format("h:mm A")}
                    onlyCompId={onlyCompId}
                    currentChannel={currentChannel}
                    openEmoji={openEmoji}
                    setOpenEmoji={setOpenEmoji}
                    pickerRef={pickerRef}
                    pinMessages={pinMessages}
                    reactionsData={item?.data?.reaction}
                    handleDeleteMessage={handleDeleteMessage}
                    adminId={adminId}
                    emojiClick={(emoji, condition) => {
                      if (condition && item?.actions?.reaction?.[emoji][0]?.actionTimetoken) {
                        addReactionToMessage(
                          emoji,
                          item?.timetoken,
                          item?.actions?.reaction?.[emoji][0]?.actionTimetoken || undefined,
                          condition,
                          item,
                        );
                      } else {
                        addReactionToMessage(emoji, item?.timetoken, undefined, condition, item);
                      }
                    }}
                    handlePinnedMasg={() => handlePinnedMasg(item)}
                    allStateMsg={item}
                    {...item}
                  />
                </div>
              );
            })}
          </>
        )}
        {userScrolled && (
          <button
            style={{
              position: "fixed",
              bottom: "90px",
              right: "60px",
              background: "rgb(251, 207, 52)",
              height: "30px",
              borderRadius: "10px",
              padding: "0px 10px",
            }}
            onClick={scrollToBottom}
          >
            Scroll to bottom
          </button>
        )}
        <div ref={messagesEndRef} />
        {/* <MessageComponent /> */}
      </ChatContainer>

      <div className="ps-1">
        <Row
          style={{ background: "#f0f3f7", position: "fixed", bottom: 0, width: "97%", left: "70px" }}
          className="p-2"
          gutter={10}
        >
          <Col md={1} className=" d-flex align-items-center p-0 ">
            <div
              className="cursorPointer d-flex justify-content-evenly  w-100 "
              onClick={() => {
                setOpenEmoji(!openEmoji);
              }}
            >
              <ChatEmoj />
            </div>

            <Tooltip title="Upload Image/Video">
              <div
                className=" ms-1 me-2 cursorPointer"
                onClick={() => {
                  setOpenFileModal(true);
                }}
              >
                <PlusSIgn height={"15px"} width="15px" />
              </div>
            </Tooltip>
            {openEmoji && (
              <div
                style={{
                  position: "absolute",
                  bottom: "100%", // Position above the toggle element
                  left: 0, // Align to the right edge
                  zIndex: 1000, // Ensure it appears above other elements
                }}
                ref={pickerRef}
              >
                <Picker data={pickerData} theme={"light"} onEmojiSelect={handleEmojiSelect} />
              </div>
            )}
          </Col>
          <Col md={22} className="d-flex">
            <Input.TextArea
              type="text"
              placeholder="Enter Message"
              className="w-100"
              value={inputFieldText}
              style={{
                height: "38px",
                padding: 5, // Remove padding
                resize: "none", // Disable resizing
                border: "none", // Remove border
                outline: "none", // Remove outline
                boxShadow: "none", // Remove box shadow
              }}
              onChange={(e) => setInputFieldText(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter" && !e.shiftKey) {
                  e.preventDefault(); // Prevent default behavior of Enter key
                  handleSendMessage(); // Call function to send message
                }
              }}
              onFocus={fromClientChatUnreadFunction}
            />
          </Col>
          <Col md={1} className="d-flex">
            <Tooltip title="Send Direct Message">
              <div
                style={{
                  height: 40,
                  width: 40,
                  backgroundColor: "rgb(251, 207, 52)",
                  borderRadius: "10px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
                className="ms-1"
                onClick={handleSendMessage}
              >
                <SendMsgIcon />
              </div>
            </Tooltip>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ConversationScreen;
