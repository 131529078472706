import * as Yup from "yup";

export const validationSchema = Yup.object({
  field_name: Yup.string().required("name is required"),
  preview_text: Yup.string().required("preview text is required"),
  field_type: Yup.string().required("Type is required"),
  properties: Yup.array().of(Yup.string()).required("Properties are required"),
  addToGroup: Yup.boolean(),
  group: Yup.string().when("addToGroup", {
    is: true,
    then: Yup.string().required("Group is required").nullable(),
    otherwise: Yup.string().nullable(),
  }),
});

export const typesOptions = [
  { value: "text", label: "text" },
  { value: "number", label: "number" },
  { value: "dropdown", label: "dropdown" },
  { value: "date", label: "date" },
  { value: "time", label: "time" },
];

export const propertiesOptions = [
  { value: "client_editable", label: "editable" },
  { value: "client_visible", label: "client visible" },
  { value: "staff_visible", label: "staff visible" },
  { value: "required", label: "required" },
];

export const initialValues = {
  field_name: "",
  preview_text: "",
  addToGroup: false,
  group: null,
  field_type: "text",
  options: [],
  properties: ["client_editable"],
};
