import React from "react";
import styled from "styled-components";

function Tabs({ tabs, selectTab, setSelectTab }) {
  return (
    <ActionButtons>
      <TabContainer>
        {tabs.map((tab) => (
          <TabButton key={tab.name} onClick={() => setSelectTab(tab.name)} active={selectTab === tab.name}>
            {tab.label}
          </TabButton>
        ))}
      </TabContainer>
    </ActionButtons>
  );
}

const ActionButtons = styled.div`
  display: flex;
  background-color: #eeeeee;
  max-width: fit-content;
  border-radius: 8px;
`;

const TabContainer = styled.div`
  padding: 4px;
  box-sizing: border-box;
  box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
  display: flex;
  gap: 10px;
`;
const TabButton = styled.button`
  background-color: ${(props) => (props.active ? "white" : "")};
  padding: 8px 16px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
`;

export default Tabs;
