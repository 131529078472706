import styled from "styled-components";

const HeaderContainer = styled.div`
  display: flex;
  min-height: 132px;
  background: #eeeeee;
  padding: 16px 24px;
  width: 100%;
  flex-direction: column;
`;
const UserNameWithIconWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 25px;
  ${"" /* padding: 5px; */}
  width: 100%;
  justify-content: space-between;
`;

const UserDetailIconTextWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${"" /* width: 70%; */}
`;
const NavigationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  ${"" /* padding: 0px 30px; */}
`;

const PillsWrapper = styled.div`
  min-width: 130px;
  height: 25px;
  background: #e3dbf5;
  border-radius: 12px;
  ${"" /* padding: 10px; */}
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TagButton = styled.button`
  min-width: 100px;
  height: 32px;
  background: transparent;
  border-radius: 8px;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border: 1px solid #e0e0e0;
`;

const StatusButton = styled.button`
  min-width: 103px;
  height: 25px;
  border-radius: 26px;
  border: 1px solid #187bef;
  padding: 0px 10px;
  color: #424242;
  font-weight: 500;
  font-size: 12px;
`;

export {
  HeaderContainer,
  UserNameWithIconWrapper,
  UserDetailIconTextWrapper,
  NavigationWrapper,
  PillsWrapper,
  TagButton,
  StatusButton,
};
