import React, { useState } from "react";
import { Input, Button, Space, Tag } from "antd";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import styled from "styled-components";
import HeadingComponent from "../Heading";

export default function InputWithListing ({ label, placeholder, onChange, inputSize, name }) {
  const [inputValue, setInputValue] = useState("");
  const [tags, setTags] = useState([]);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleAddTag = () => {
    if (inputValue.trim() !== "") {
      setTags([...tags, inputValue]);
      setInputValue("");
    }
  };

  const handleDeleteTag = (tagToDelete) => {
    setTags(tags.filter((tag) => tag !== tagToDelete));
  };

  return (
    <div>
      {label && <Label>{label}</Label>}
      <InputContainer>
        <Input
          placeholder={placeholder}
          size={inputSize}
          value={inputValue}
          onChange={handleInputChange}
          name={name}
        />
      </InputContainer>
        <ButtonContainer>
          <Button type="primary" shape="circle" size="small" onClick={handleAddTag} icon={<PlusOutlined />} />
          <Heading text="Add More" fontSize="0.75rem" color="#3B3B3B" fontWeight={700} margin="0 0 0 5px" />
        </ButtonContainer>
      <TagsContainer>
        {tags.map((tag, index) => (
          <StyledTag key={index} closable onClose={() => handleDeleteTag(tag)}>
            {tag} 
          </StyledTag>
        ))}
      </TagsContainer>
    </div>
  );
};

const Heading = ({ text = "", margin, fontSize = "0.75rem", color = "#3B3B3B" }) => {
  return <HeadingComponent text={text} fontSize={fontSize} color={color} fontWeight={700} margin={margin} />;
};
const Label = styled.p`
  margin-bottom: 6px;
  color: #3b3b3b;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;

  .ant-input {
    flex: 1;
    margin-right: 8px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

const TagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 8px;
`;

const StyledTag = styled(Tag)`
  border: none;
  background-color: #f5f5f5;
  border-radius: 4px 12px;
  padding: 0 8px;
  display: flex;
  align-items: center;
  margin-right: 8px;
  margin-bottom: 8px;

  .anticon {
    margin-left: 4px;
    cursor: pointer;
  }
`;
