import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";

import { Col, Row, Avatar, Select, Radio, Button } from "antd";
import { useNavigate } from "react-router-dom";
import HeadingComponent from "../../../../components/Heading";
import * as Yup from "yup";
import { navigationTabs } from "../../../../constants/CompanyNavTabs/staffDetailNavigationTabs";
import { Formik, Field, Form as FormikForm } from "formik";
import {
  HeaderContainer,
  UserNameWithIconWrapper,
  UserDetailIconTextWrapper,
  NavigationWrapper,
  PillsWrapper,
  TagButton,
  StatusButton,
} from "./StaffDetailsStyles";
import { ReactComponent as LessThanIcon } from "../../../../assets/rawSvgs/lessThanBlack.svg";
import { ReactComponent as MessageIcon } from "../../../../assets/rawSvgs/messageIcon.svg";
import { ReactComponent as EmployeTypeIcon } from "../../../../assets/rawSvgs/staffwizard.svg";
import { ReactComponent as StaffIcon } from "../../../../assets/rawSvgs/staffIcon.svg";
import { ReactComponent as PhoneIcon } from "../../../../assets/rawSvgs/phoneIcon.svg";
import { ReactComponent as TagsIcon } from "../../../../assets/rawSvgs/tagsIcon.svg";
import { ReactComponent as DefaultTagsIcon } from "../../../../assets/rawSvgs/defaultTagIcon.svg";
import { ReactComponent as DropDownIcon } from "../../../../assets/rawSvgs/selectDropDownIcon.svg";
import { ReactComponent as PlusIcon } from "../../../../assets/rawSvgs/plusSign.svg";
import { ReactComponent as EditPencilIcon } from "../../../../assets/rawSvgs/editPencilIcon.svg";
//
import AntDesignNavTabs from "../../../../components/antDesignNaviTabs/AntDesignNavTabs";
import StaffTimeOff from "./StaffTimeOff";
import { AntdesignTable } from "../../../../components/antDesignTable/AntdesignTable";
import { staffDetailsTableHeaders } from "../../../../constants/CompanyTableHeaders/StaffManagementHeaders";
import AvailabilityComponent from "./availabilityComponent/AvailabilityComponent";
import TimeTracking from "./timeTracking/Index.jsx";
import MileageTracking from "./mileageTracking/Index";
import Configurations from "./configurations/Index";
import AntDesignModal from "../../../../components/Modals/AntDesignModal/AntDesignModal";
import CustomBtnWithIcon from "../../../../components/buttonWithIcon";
import AntDesignDropDownSelect from "../../../../components/AntDesignDropDownSelect/AntDesignDropDownSelect";
import FormikInputFloating from "../../../../components/formikInputs/TextInput";
import { main_api } from "../../../../api/axios_helper";
import { companyAPIsEndPoints, staffAPIsEndPoints } from "../../../../constants/ApiEndPoints";
import { pushNotification } from "../../../../util/Notification";
import AddNewStaffForm from "../addNewStaffModal";
import { checkPermission } from "../../../../util/commonUtil";
import NoDataScreen from "../../../../components/NoDataScreen";
import PermissionModal from "../../../../components/Modals/PermissionModal.jsx";
import { useSelector } from "react-redux";
// "
function StaffDetail({
  fetchSingleStaff,
  singleStaffresponseData,
  loginState,
  StaffState,
  permissionState,
  fetchSingleStaffTimeTracking,
  fetchSingleStaffMileageTracking,
}) {
  let { staffId } = useParams();
  const navigate = useNavigate();
  const [openTagsModal, setOpenTagsModal] = useState(false);
  const [activityStatusModal, setActivityStatusModal] = useState(false);
  const [activityStatusres, setActivityStatusres] = useState(false);
  const [editStaffModal, seteditStaffModal] = useState(false);
  const [timeTracking, setTimeTracking] = useState(true);
  const [mileageTracking, setMileageTracking] = useState(true);
  const [jobRateFlag, setJobRateFlag] = useState(false);
  const [hourlyRate, setHourlyRate] = useState(0);
  const [permissionPopUp, setPermissionPopUp] = useState(false);

  let singleStaffstate = StaffState?.staffSingleDetails?.responseData?.hourly_rate || "";

  // useEffect(() => {
  //   const data = {
  //     time_tracking_enabled : timeTracking,
  //     mileage_tracking_enabled : mileageTracking,
  //     hourly_rate: hourlyRate,
  //     is_job_rate: jobRateFlag,
  //   };
  //   const updateData = async () => {
  //     try {
  //       await main_api.put(staffAPIsEndPoints.UPDATE_NEW_STAFF(staffId), data);
  //     } catch (error) {
  //       console.error("Error updating data:", error);
  //     }
  //   };
  //   updateData();
  // }, [timeTracking,mileageTracking]);

  useEffect(() => {
    fetchSingleStaff(staffId);
    handleFetchStatus();
  }, []);

  const handleFetchStatus = async () => {
    try {
      const { data } = await main_api.get(`/api/v1/staff_activity_status/?staff=${staffId}`);
      setActivityStatusres(data[0] || "");
    } catch (error) {
      setActivityStatusres([]);
    }
  };

  const addNewTags = () => {
    if (checkPermission(permissionState, "create_tag")) {
      setOpenTagsModal(true);
    } else {
      setPermissionPopUp(true);
    }
  };

  const handleChanetabs = (key) => {
    // console.log(parseInt(key));
  };
  // console.log("StaffState?.staffMileageTrack:", StaffState?.staffMileageTrack);
  // const configUpdateFormDetails = singleStaffstate?.staffAvailConfig?.responseData?.availabilities || {};
  const configUpdateFormDetails = StaffState.staffAvailConfig.responseData?.availabilities || {};

  const navTabs = navigationTabs(
    <AvailabilityComponent
      configUpdateFormDetails={configUpdateFormDetails}
      companyId={loginState?.selectCompany?.[0]?.company_id}
      fetchSingleStaff={fetchSingleStaff}
    />,
    <StaffTimeOff staffId={staffId} companyId={loginState?.selectCompany?.[0]?.company_id} />,
    <TimeTracking
      staffId={staffId}
      staffTimeTrackingData={StaffState?.staffTimeTrack?.responseData?.result || []}
      fetchSingleStaffTimeTracking={fetchSingleStaffTimeTracking}
    />,
    <MileageTracking
      staffId={staffId}
      staffMileageTrackingData={StaffState?.staffMileageTrack?.responseData?.result || []}
      fetchSingleStaffMileageTracking={fetchSingleStaffMileageTracking}
    />,
    <Configurations
      staffId={staffId}
      singleStaffState={StaffState?.staffSingleDetails?.responseData}
      timeTracking={timeTracking}
      setTimeTracking={setTimeTracking}
      mileageTracking={mileageTracking}
      setMileageTracking={setMileageTracking}
      handleFetchStaff={() => fetchSingleStaff(staffId)}
    />,
  );

  const staffTags = singleStaffresponseData?.tags?.map((items) => {
    return items.tag_name;
  });

  return (
    <div className="">
      {/* singleStaffstate?.staffSingleDetails?.isLoading */}
      {StaffState.staffSingleDetails?.isLoading ? (
        "Loading Details..."
      ) : (
        <>
          {checkPermission(permissionState, "view_staff") ? (
            <>
              <HeaderContainer>
                <UserNameWithIconWrapper>
                  <div className="d-flex justify-content-between w-100">
                    <div className="d-flex align-items-center">
                      <button onClick={() => navigate(-1)}>
                        <LessThanIcon width="13px" height="13px" />
                      </button>
                      <HeadingComponent
                        text={singleStaffresponseData?.name ?? "Details Not Available"}
                        fontSize="1.25rem"
                        fontWeight={700}
                        margin="4px 15px 0px 10px"
                      />
                      <StatusButton onClick={() => setActivityStatusModal(true)}>
                        {activityStatusres?.status_emoji ? activityStatusres?.status_emoji : "---"}
                      </StatusButton>
                    </div>
                    <div className="d-flex align-items-center">
                      <TagButton onClick={() => addNewTags()}>
                        <span>
                          <DefaultTagsIcon />
                        </span>
                        <span className="ms-1">Add Tags</span>
                      </TagButton>
                      <div
                        className="ms-3 cursorPointer"
                        onClick={() => {
                          if (checkPermission(permissionState, "edit_staff")) {
                            seteditStaffModal(true);
                          } else {
                            setPermissionPopUp(true);
                          }
                        }}
                      >
                        <EditPencilIcon />
                      </div>
                    </div>
                  </div>
                </UserNameWithIconWrapper>
                <UserDetailIconTextWrapper>
                  <Row className="w-75" align={"middle"}>
                    <Col sm={11} md={9} className="my-2">
                      <IconWithTextHeaderComponent icon={<MessageIcon />} text={singleStaffresponseData?.email || "---"} />
                    </Col>
                    <Col sm={11} md={7} className="my-2">
                      <IconWithTextHeaderComponent icon={<EmployeTypeIcon />} text={singleStaffresponseData?.type || "---"} />
                    </Col>
                    <Col sm={11} md={7} className="my-2">
                      <IconWithTextHeaderComponent icon={<StaffIcon />} text={singleStaffresponseData?.role || "---"} />
                    </Col>
                    <Col sm={11} md={9} className="my-2 ">
                      <IconWithTextHeaderComponent
                        icon={<PhoneIcon />}
                        text={singleStaffresponseData?.primary_phone_number || "---"}
                      />
                    </Col>
                    <Col sm={11} md={7} className="my-2 ">
                      <IconWithTextHeaderComponent
                        icon={<PhoneIcon />}
                        text={singleStaffresponseData?.secondary_phone_number || "---"}
                      />
                    </Col>
                    {checkPermission(permissionState, "view_tags") && (
                      <Col md={5} className="mb-1">
                        <IconWithTextHeaderComponent
                          icon={<TagsIcon />}
                          text={
                            <TagsPillsComponent pillList={staffTags} /> || (
                              <TagsPillsComponent pillList={["No Large Dogs", "Suga", "No Weekends", "No Weekends"]} />
                            )
                          }
                        />
                      </Col>
                    )}
                  </Row>
                </UserDetailIconTextWrapper>
              </HeaderContainer>
              <NavigationWrapper>
                <AntDesignNavTabs navTabs={navTabs} onChange={handleChanetabs} />
              </NavigationWrapper>
            </>
          ) : (
            <NoDataScreen heading="You don’t have acces to view Staff Detail" showBtn={false} />
          )}
        </>
      )}
      {openTagsModal && (
        <AddTagsModalComponent
          close={() => setOpenTagsModal(false)}
          open={openTagsModal}
          staffId={staffId}
          handleFetchStaffStatus={() => fetchSingleStaff(staffId)}
          tagsFromStaffRes={singleStaffresponseData?.tags}
        />
      )}
      {activityStatusModal && (
        <ActivityStatusComponent
          close={() => setActivityStatusModal(false)}
          open={activityStatusModal}
          handleFetchStaffStatus={() => {
            handleFetchStatus();
            // fetchSingleStaff(staffId);
          }}
          statusList={singleStaffstate?.staffStatusList.statusList || []}
          staffId={staffId}
          activityStatusres={activityStatusres}
        />
      )}

      {editStaffModal && (
        <AddNewStaffForm
          visible={editStaffModal}
          handleClose={() => seteditStaffModal(false)}
          companyId={loginState?.selectCompany?.[0]?.company_id}
          fetchAllStaff={() => fetchSingleStaff(staffId)}
          editStaffValues={singleStaffresponseData}
          updateForm={true}
        />
      )}
      <PermissionModal onclose={() => setPermissionPopUp(false)} openModal={permissionPopUp} />
    </div>
  );
}

export const IconWithTextHeaderComponent = ({ icon, text }) => {
  return (
    <div className="d-flex align-items-baseline mt-3 me-4 ms-1">
      <span>{icon}</span>
      <HeadingComponent text={text} fontSize="0.875rem" fontWeight={400} margin="0px 6px" />
    </div>
  );
};

export default React.memo(StaffDetail);

//
const TagsPillsComponent = React.memo(({ tagText = "No Large Dogs", pillList }) => {
  return (
    <div className="d-flex align-items-center">
      <Avatar.Group
        maxCount={2}
        maxStyle={{
          color: "#f56a00",
          backgroundColor: "#fde3cf",
        }}
      >
        {pillList?.map((items, index) => {
          return (
            <span className="mx-1 mt-1" key={index}>
              <PillsWrapper>
                <HeadingComponent text={items} fontSize="0.75rem" fontWeight={500} margin="0px 6px" color="#391291" />
              </PillsWrapper>
            </span>
          );
        })}
      </Avatar.Group>
    </div>
  );
});

const ActivityStatusComponent = React.memo(
  ({ close, open, openEditClientValues, updateForm, handleFetchStaffStatus, statusList, staffId, activityStatusres }) => {
    const [selectedOption, setSelectedOption] = useState(null);
    const [newStatusModalModal, setNewStatusModalModal] = useState(false);
    const [submitFormLoader, setSubmitFormLoader] = useState(false);

    const formikRef = React.useRef();
    const assignStatusRef = React.useRef();

    const handleSelectChange = (value) => {
      setSelectedOption(value);
      if (value && value === "add new status") {
        setNewStatusModalModal(true);
        setSelectedOption(null); // Reset the selected option
      }
    };

    // ADD_NEW_STAFF_STATUS

    const handleAddNewStatusModal = async (values) => {
      try {
        setSubmitFormLoader(true);
        const { data } = await main_api.post(staffAPIsEndPoints.ADD_NEW_STAFF_STATUS, values);

        if (data.isSuccess) {
          pushNotification("Activity status created successfully!");
          handleFetchStaffStatus();
          setNewStatusModalModal(false);
          close();
        }

        setSubmitFormLoader(false);
      } catch (error) {
        setSubmitFormLoader(false);

        console.error("Error", error);
      }
    };

    const handleAssignStatus = async (values) => {
      try {
        setSubmitFormLoader(true);

        if (activityStatusres) {
          const { data } = await main_api.put(`/api/v1/staff_activity_status/${activityStatusres.id}/`, values);

          if (data.isSuccess) {
            pushNotification(data.message);
            setNewStatusModalModal(false);
            handleFetchStaffStatus();
            close();
          }
          setSubmitFormLoader(true);
          return;
        }
        const { data } = await main_api.post("/api/v1/staff_activity_status/", values);

        if (data.isSuccess) {
          pushNotification(data.message);
          setNewStatusModalModal(false);
          handleFetchStaffStatus();
          close();
        }
        setSubmitFormLoader(false);
      } catch (error) {
        setSubmitFormLoader(false);
        pushNotification("Something went wrong", "error");
      }
    };
    const handleRemoveAssignStatus = async () => {
      try {
        const { data } = await main_api.delete(`/api/v1/staff_activity_status/${activityStatusres.id}/`);

        if (data.isSuccess) {
          pushNotification(data.message);
          handleFetchStaffStatus();
          setNewStatusModalModal(false);
          close();
        }
      } catch (error) {}
    };

    const emojis = ["🌴", "🤒", "😊", "🚀"];

    let statusListConcate = [
      ...statusList,
      {
        value: "add new status",
        label: (
          <div className="d-flex align-items-baseline pt-1 w-100">
            <div className="me-2">
              <PlusIcon />
            </div>
            <span>Add New Status</span>
          </div>
        ),
      },
    ];
    const validationSchema = Yup.object().shape({
      activity_status: Yup.number().required("Activity status is required"),

      max_duration: Yup.number()
        .typeError("Max duration must be a number")
        .positive("Max duration must be a positive number")
        .min(0, "Max duration must be at least 0")
        .max(50, "Max duration must be at most 50")
        .required("Max duration is required"),
      activity_increment: Yup.number()
        .typeError("Activity increment must be a number")

        .required("Activity increment is required"),
    });

    return (
      <>
        <AntDesignModal
          closeModal={close}
          open={open}
          title={"Set an Activity Status"}
          width={"500px"}
          component={
            <>
              <Formik
                initialValues={{
                  staff: staffId,
                  activity_status: updateForm ? openEditClientValues.status : "",
                  max_duration: updateForm ? openEditClientValues.max_duration : "",
                  activity_increment: updateForm ? openEditClientValues.activity_increment : "",
                }}
                validationSchema={validationSchema}
                onSubmit={handleAssignStatus}
                enableReinitialize
                innerRef={assignStatusRef}
              >
                {({ setFieldValue, touched, errors }) => (
                  <FormikForm>
                    <div className="my-2">
                      <HeadingComponent text="Add Status" fontSize={"0.75rem"} margin="5px 0px" />

                      <AntDesignDropDownSelect
                        options={statusListConcate}
                        onChange={(val) => {
                          handleSelectChange(val);
                          if (val === "add new status") return;
                          setFieldValue("activity_status", val);
                        }}
                        value={selectedOption}
                      />
                      {errors.activity_status && touched.activity_status && (
                        <div className="input-error-style">Status is required</div>
                      )}
                    </div>
                    <FormikInputFloating
                      label="Max Hour Duration"
                      placeHolder="Enter Hour"
                      name="max_duration"
                      type="number"
                      className="w-100 mb-2"
                      autoComplete="new-password"
                    />
                    <div className="my-2 ">
                      <HeadingComponent text="Select Increments" fontSize={"0.75rem"} margin="5px 0px" />
                      <Radio.Group
                        onChange={(e) => {
                          setFieldValue("activity_increment", e.target.value);
                        }}
                      >
                        <Radio value={15}>15 min</Radio>
                        <Radio value={30}>30 min</Radio>
                        <Radio value={60}>1 hour</Radio>
                      </Radio.Group>
                      {errors.activity_increment && touched.activity_increment && (
                        <div className="input-error-style">Increment is required</div>
                      )}
                    </div>
                  </FormikForm>
                )}
              </Formik>
            </>
          }
          footer={
            <>
              <div className="d-flex justify-content-between mt-5">
                <div>
                  <CustomBtnWithIcon btnText={"Cancel"} margin="0px 5px" noBackground hideIcon={true} onClick={close} />
                </div>
                <div className="d-flex">
                  {activityStatusres.id && (
                    <CustomBtnWithIcon
                      btnText={<HeadingComponent color="red" text="Remove Status" fontSize={"0.75rem"} />}
                      margin="0px 5px"
                      noBackground
                      hideIcon={true}
                      onClick={() => handleRemoveAssignStatus()}
                    />
                  )}
                  <CustomBtnWithIcon
                    btnText={"Update Status"}
                    type="submit"
                    hideIcon={true}
                    disabled={submitFormLoader}
                    onClick={() => {
                      if (assignStatusRef?.current) {
                        assignStatusRef?.current?.handleSubmit();
                      }
                    }}
                  />
                </div>
              </div>
            </>
          }
        />

        {/* create staus modal */}
        <AntDesignModal
          closeModal={() => setNewStatusModalModal(false)}
          open={newStatusModalModal}
          title={"Set an Activity Status"}
          width={"430px"}
          component={
            <>
              <Formik
                initialValues={{
                  status: updateForm ? openEditClientValues?.name : "",
                  emoji: "",
                  // staff: staffId,
                }}
                innerRef={formikRef}
                onSubmit={handleAddNewStatusModal}
                enableReinitialize
              >
                {({ setFieldValue, values }) => (
                  <FormikForm>
                    <FormikInputFloating
                      label="Status"
                      placeHolder="Enter New status"
                      name="status"
                      type="text"
                      className="w-100 mb-2"
                      autoComplete="new-password"
                    />
                    <div className="mt-4 mb-3 ">
                      <HeadingComponent text="Choose Emoji" fontSize={"0.75rem"} margin="10px 0px" />

                      {emojis.map((emoji, index) => (
                        <Button
                          key={index}
                          style={{
                            background: "transparent",
                            border: values.emoji === emoji ? "1.5px solid rgb(251, 207, 52)" : "1.5px solid #D6D9E1",
                            color: "inherit",
                            height: "38px",
                            borderRadius: "12px",
                          }}
                          className="mx-1"
                          onClick={() => {
                            setFieldValue("emoji", emoji);
                          }}
                          ripple={false}
                        >
                          {emoji}
                        </Button>
                      ))}
                    </div>
                  </FormikForm>
                )}
              </Formik>
            </>
          }
          footer={
            <>
              <div className="d-flex justify-content-end mt-4">
                <CustomBtnWithIcon
                  btnText={"Cancel"}
                  margin="0px 5px"
                  noBackground
                  hideIcon={true}
                  onClick={() => setNewStatusModalModal(false)}
                />
                <CustomBtnWithIcon
                  btnText={"Save Status"}
                  type="submit"
                  hideIcon={true}
                  disabled={submitFormLoader}
                  onClick={() => {
                    if (formikRef?.current) {
                      formikRef?.current?.handleSubmit();
                    }
                  }}
                />
              </div>
            </>
          }
        />
      </>
    );
  },
);

const AddTagsModalComponent = React.memo(({ close, open, staffId, handleFetchStaffStatus, tagsFromStaffRes }) => {
  const [staffTags, setAlltags] = useState([]);
  const [tagloader, settagloader] = useState(false);
  const [tagsFromRes, settagsFromRes] = useState([]);
  const [newTagModal, setnewTagModal] = useState(false);
  const formikRef = React.useRef();
  const { permissionManagement } = useSelector((state) => state);
  const [permissionPopUp, setPermissionPopUp] = useState(false);
  useEffect(() => {
    const fetchTags = () => {
      main_api.get("/api/v1/staff_tags/").then((res) => {
        settagsFromRes(
          res?.data?.result?.map((items) => {
            return {
              value: items.id,
              label: items.tag_name,
            };
          }),
        );
      });
    };
    fetchTags();
  }, []);

  const handleAssignTags = async (values) => {
    if (checkPermission(permissionManagement?.permissions, "edit_tag")) {
      try {
        settagloader(true);
        const res = await main_api.post(`/api/v1/staff_tags/assign_tag/`, { tags: staffTags, staff_id: staffId });

        if (res.data) {
          pushNotification("Tags assigned successfully");
          handleFetchStaffStatus();
          close();
        }
        settagloader(false);
      } catch (error) {
        settagloader(false);

        console.error("Error:", staffTags);
      }
    } else {
      setPermissionPopUp(true);
    }
  };

  const validationSchema = Yup.object().shape({
    tag_name: Yup.string().max(12, "Tag name must be at most 12 characters long"),
  });

  const handleAddNewTag = async (value) => {
    try {
      settagloader(true);
      const res = await main_api.post(`/api/v1/staff_tags/`, value);

      if (res.data) {
        pushNotification("Tag Created successfully");
        handleFetchStaffStatus();
        close();
      }
      settagloader(false);
    } catch (error) {
      settagloader(false);
    }
  };

  const addNewTag = () => {
    if (checkPermission(permissionManagement?.permissions, "create_tag")) {
      setnewTagModal(true);
    } else {
      setPermissionPopUp(true);
    }
  };
  return (
    <>
      <AntDesignModal
        closeModal={close}
        open={open}
        title={"Add Tags to Staff Member"}
        width={"450px"}
        footer={
          <>
            <div className="d-flex justify-content-end">
              <CustomBtnWithIcon btnText={"Cancel"} margin="0px 5px" noBackground hideIcon={true} onClick={close} />
              <CustomBtnWithIcon
                btnText={"Add New Tag"}
                margin="0px 5px"
                noBackground
                hideIcon={true}
                onClick={() => addNewTag()}
              />
              <CustomBtnWithIcon
                btnText={"Update Staff Tags"}
                type="submit"
                disabled={tagloader}
                hideIcon={true}
                onClick={handleAssignTags}
              />
            </div>
          </>
        }
        component={
          <>
            <HeadingComponent text={"Tag"} fontWeight={700} fontSize="0.75rem" color="#3B3B3B" margin="10px 0px" />

            <AntDesignDropDownSelect
              options={tagsFromRes || []}
              defaultValue={
                tagsFromStaffRes?.map((items) => {
                  return {
                    value: items.id,
                    label: items.tag_name,
                  };
                }) || []
              }
              placeholder="Select Date"
              className="ms-2"
              mode="multiple"
              onChange={(e) => {
                setAlltags(e);
              }}
            />
          </>
        }
      />

      {/*  new tag modal */}
      {newTagModal && (
        <AntDesignModal
          closeModal={() => setnewTagModal(false)}
          open={newTagModal}
          title={"Add New Tag"}
          width={"430px"}
          component={
            <>
              <Formik
                initialValues={{
                  tag_name: "",
                }}
                innerRef={formikRef}
                onSubmit={handleAddNewTag}
                validationSchema={validationSchema}
                enableReinitialize
              >
                {() => (
                  <FormikForm>
                    <FormikInputFloating
                      label="Tag Name"
                      placeHolder="Enter Tag Name"
                      name="tag_name"
                      type="text"
                      className="w-100 mb-2"
                      autoComplete="new-password"
                    />
                  </FormikForm>
                )}
              </Formik>
            </>
          }
          footer={
            <>
              <div className="d-flex justify-content-end mt-4">
                <CustomBtnWithIcon
                  btnText={"Cancel"}
                  margin="0px 5px"
                  noBackground
                  hideIcon={true}
                  onClick={() => setnewTagModal(false)}
                />
                <CustomBtnWithIcon
                  btnText={"Create Tag"}
                  type="submit"
                  hideIcon={true}
                  disabled={tagloader}
                  onClick={() => {
                    if (formikRef?.current) {
                      formikRef?.current?.handleSubmit();
                    }
                  }}
                />
              </div>
            </>
          }
        />
      )}
      <PermissionModal onclose={() => setPermissionPopUp(false)} openModal={permissionPopUp} />
    </>
  );
});
