import React, { useEffect, useState } from "react";
import { NavigationHeading } from "../clients/clientStyles";
import { ActionButtons, TabButton, TabContainer } from "./tasksManagementStyles";
import TasksList from "./tasksList";
import WorkQueue from "./workQueue";

const TasksManagement = (props) => {
  const [activeTab, setActiveTab] = useState("tasks-list");

  useEffect(() => {
    return () => {
      // props.openDetailModal(false);
    };
  }, []);

  return (
    <>
      <NavigationHeading>Tasks</NavigationHeading>
      <ActionButtons>
        <TabContainer>
          <TabButton onClick={() => setActiveTab("tasks-list")} active={activeTab === "tasks-list"}>
            Tasks List
          </TabButton>
          <TabButton onClick={() => setActiveTab("work-queue")} active={activeTab === "work-queue"} marginLeft="30px">
            Work Queue
          </TabButton>
        </TabContainer>
      </ActionButtons>
      {activeTab == "tasks-list" && (
        <TasksList props={props}/>
      )}
      {activeTab == "work-queue" && (
        <WorkQueue props={props}/>
      )}
    </>
  );
};

export default TasksManagement;
