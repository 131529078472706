// import React, { useEffect, useState } from 'react';
// import { Form, Input } from 'antd';
// import VideoUploader from '../Form/VideoUploader';
// import { ReactComponent as UploadIcon } from "../../assets/rawSvgs/Upload.svg";
// import Form.Item from 'antd/es/form/Form.Item';
// import HeadingComponent from '../Heading';
// const { TextArea } = Input;

// const PanelForm = ({ prefix = "", initialWeightage, initialDescription, initialVideo, checked, itemId, setVideoForItem, values, savedVideoUrl = "" }) => {
//   const [video, setVideo] = useState( initialVideo || null);
//   const [description, setDescription] = useState( initialDescription || '' );
//   const [weightage, setWeightage] = useState( initialWeightage || 1);
//   const [selectedDescription, setSelectedDescription] = useState( );
//   const [selectedWeightage, setSelectedWeightage] = useState( );
//   useEffect(() => {
//     setDescription(values.description || '');
//     setWeightage(values.weightage || 1);
//   }, [initialDescription, initialWeightage]);
//   useEffect(() => {
//     if (itemId === values.id) {
      
//       setSelectedDescription(initialDescription || '');
//       setSelectedWeightage(initialWeightage || 1);
//     }
//   }, [initialDescription, initialWeightage]);
//   const removeVideo = () => {
//     setVideo(null);
//   };

//   return (
//     <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', gap: '10px', width: '100%', marginLeft: "20px" }}>
//       {
//         checked ? 
//         <>
//         <VideoUploader
//           label=""
//           DefaultVideoIcon={UploadIcon}
//           video={video}
//           setVideo={setVideo}
//           setVideoForItem={setVideoForItem}
//           itemId={itemId}
//           savedVideoUrl={savedVideoUrl} // Add the URL of a saved video if any
//           removeVideo={removeVideo}
//         />
//       <Heading text={"Description"} fontSize='0.8rem' />
//       <Form.Item
//         name={`${prefix}_description`}
//         rules={[
//           {
//             required: true,
//             message: "Please enter description",
//           },
//         ]}
//         style={{ width: '90%' }}
//       >
//         <TextArea
//           value={selectedDescription}
//           onChange={(e) => {
//             setSelectedDescription(e.target.value)
//           }}
//           placeholder="Enter Description"
//           autoSize={{ minRows: 3, maxRows: 5 }}
//         />
//       </Form.Item>

//       <Heading text={"Weightage"} fontSize='0.8rem' />
//       <Form.Item
//         name={`${prefix}_weightage`}
//         rules={[
//           {
//             required: true,
//             message: "Please enter weightage",
//           },
//           ({ getFieldValue }) => ({
//             validator(_, value) {
//               if (!value || (value > 0 && value <= 100)) {
//                 return Promise.resolve(); // Validation passes
//               }
//               return Promise.reject(new Error('Weightage must be greater than 0 and less than or equal to 100'));
//             },
//           }),
//         ]}
//         style={{ width: '90%' }}
//       >
//         <Input placeholder="Enter Weightage" value={selectedWeightage} onChange={(e) => {
//           setSelectedWeightage(e.target.value)
//           }} />
//       </Form.Item>
//         </>
//         :
//         <>
        
//         <VideoUploader
//           label=""
//           DefaultVideoIcon={UploadIcon}
//           video={video}
//           setVideo={setVideo}
//           savedVideoUrl={savedVideoUrl} // Add the URL of a saved video if any
//           removeVideo={removeVideo}
//         />
     
//       <Heading text={"Description"} fontSize='0.8rem' />
     
//         <TextArea
//           value={description}
//           onChange={(e) => setDescription(e.target.value)}
//           placeholder="Enter Description"
//           autoSize={{ minRows: 3, maxRows: 5 }}
//         />
      

//       <Heading text={"Weightage"} fontSize='0.8rem' />
      
//         <Input placeholder="Enter Weightage" value={weightage} onChange={(e) => setWeightage(e.target.value)} />
     
//         </>
//       }
//     </div>
//   );
// };

// const Heading = ({ text = "", margin, fontSize = "0.75rem", color = "#3B3B3B" }) => {
//   return <HeadingComponent text={text} fontSize={fontSize} color={color} fontWeight={700} margin={margin} />;
// };

// export default PanelForm;






import React, { useEffect, useState } from 'react';
import { Form, Input } from 'antd';
import VideoUploader from '../Form/VideoUploader';
import { ReactComponent as UploadIcon } from "../../assets/rawSvgs/Upload.svg";
// import Form.Item from 'antd/es/form/Form.Item';
import HeadingComponent from '../Heading';
const { TextArea } = Input;

const PanelForm = ({ prefix = "", form, initialWeightage, initialDescription, initialVideo, checked, itemId, setVideoForItem, values, savedVideoUrl = "" }) => {
  const [video, setVideo] = useState(initialVideo || null);
  const item_description = `${prefix}_description`
  const item_weightage = `${prefix}_weightage`
  
  useEffect(() => {
  
     
    form.setFieldsValue({
        [item_description]: initialDescription,
        [item_weightage]: initialWeightage,

      })
    
  }, []);





  const removeVideo = () => {
    setVideo(null);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', gap: '10px', width: '100%', marginLeft: "20px" }}>
      
        <>
          <VideoUploader
            label=""
            DefaultVideoIcon={UploadIcon}
            video={video}
            setVideo={setVideo}
            setVideoForItem={setVideoForItem}
            itemId={itemId}
            savedVideoUrl={savedVideoUrl}
            removeVideo={removeVideo}
          />
          <Heading text={"Description"} fontSize='0.8rem' />
          <Form.Item
            name={item_description}
            rules={checked ? [
              {
                required: true,
                message: "Please enter description",
              },
            ]
            : 
            [
              {
                required: false,
                message: "Please enter description",
              },
            ]
          }
            style={{ width: '90%' }}
          >
          
            <TextArea
              placeholder="Enter Description"
              autoSize={{ minRows: 3, maxRows: 5 }}
            />
          </Form.Item>
          <Heading text={"Weightage"} fontSize='0.8rem' />
          <Form.Item
            name={item_weightage}
            rules={checked ? [
              {
                required: true,
                message: "Please enter weightage",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || (Number.isInteger(Number(value)) && value > 0 && value <= 100)) {
                    return Promise.resolve(); // Validation passes
                  }
                  return Promise.reject(new Error('Weightage must be an integer greater than 0 and less than or equal to 100'));
                },
              }),
            ]            
            :
            [
            {
              required: false,
              message: "Please enter weightage",
            },
          ]
          }
            style={{ width: '90%' }}
          >
            <Input
              placeholder="Enter Weightage"
            />
          </Form.Item>
        </>
     
    </div>
  );
};

const Heading = ({ text = "", margin, fontSize = "0.75rem", color = "#3B3B3B" }) => {
  return <HeadingComponent text={text} fontSize={fontSize} color={color} fontWeight={700} margin={margin} />;
};
export default PanelForm;
