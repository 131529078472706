import styled from "styled-components";
import { Space, Tag } from "antd";
import { ReactComponent as EditIcon } from "../../assets/rawSvgs/tableEditIcon.svg";
import { ReactComponent as DeleteIcon } from "../../assets/rawSvgs/tableDeleteIcon.svg";
import { ReactComponent as HistoryIcon } from "../../assets/rawSvgs/HistoryAction.svg";
import { MoreOutlined } from "@ant-design/icons";
//{ handleDelete = "", handleEditRow = "" }
const KeyTableActions = ({ handleDelete = "", handleEditRow = "", handleShowModal=""}) => {
  return [
    {
      title: "",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button
            onClick={(event) => {
            }}
          >
            <HistoryIcon />
          </Button>
          <Button
            onClick={(event) => {
              event.stopPropagation();
              handleEditRow && handleEditRow(record);
            }}
          >
            <EditIcon />
          </Button>
          <Button
            onClick={(event) => {
              event.stopPropagation();
              handleDelete && handleDelete(record);
            }}
          >
            <DeleteIcon />
          </Button>
        </Space>
      ),
    },
  ];
};
const DuplicateKeysTableActions = ({ handleDelete = "", handleEditRow = ""}) => {
  return [
    {
      title: "",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button
            onClick={(event) => {
            }}
          >
            <HistoryIcon />
          </Button>
          <Button
            onClick={(event) => {
              event.stopPropagation();
              handleEditRow && handleEditRow(record);
            }}
          >
            <EditIcon />
          </Button>
          <Button
            onClick={(event) => {
              event.stopPropagation();
              handleDelete && handleDelete(record);
            }}
          >
            <DeleteIcon />
          </Button>
        </Space>
      ),
    },
  ];
};

export { KeyTableActions, DuplicateKeysTableActions };

const Button = styled.button`
  border: none;
  background: none;
`;
