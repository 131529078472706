import { createSlice } from "@reduxjs/toolkit";
import { getlocalStorage, setlocalStorage } from "../../../api/auth";
import { main_api } from "../../../api/axios_helper";
import { companyAPIsEndPoints } from "../../../constants/ApiEndPoints";

const initialState = {
  sidebarToggle: getlocalStorage("sidebarToggle") ? JSON.parse(getlocalStorage("sidebarToggle")) : false,
  dashboardCompanyDetails: {
    isLoading: false,
    responseData: "",
  },
  // messages count
  unreadCounts: [],
  hasUnreadMessages: false,

  // client
  unreadCountsClient: [],
  hasUnreadMessagesClient: false,
};

const generalApp = createSlice({
  name: "Gen app",
  initialState,
  reducers: {
    toggleSidebarIcon(state) {
      state.sidebarToggle = !state.sidebarToggle;
      setlocalStorage("sidebarToggle", state.sidebarToggle);
    },
    fetchCompanyDashboardDataSlice(state, action) {
      state.dashboardCompanyDetails.isLoading = action.payload.isLoading;
      if (action.payload?.responseData) {
        state.dashboardCompanyDetails.responseData = action.payload?.responseData;
      }
    },

    setUnreadCounts(state, action) {
      state.unreadCounts = action.payload;
      state.hasUnreadMessages = action.payload.some((count) => count.unreadCount > 0);
    },
    setUnreadCountsClient(state, action) {
      state.unreadCountsClient = action.payload;
      state.hasUnreadMessagesClient = action.payload.some((count) => count.unreadCount > 0);
    },
  },
});

export const { toggleSidebarIcon, fetchCompanyDashboardDataSlice, setUnreadCounts, setUnreadCountsClient } = generalApp.actions;

export default generalApp.reducer;

export const fetchCompanyDashboardDataApi = () => {
  return async (dispatch, getState) => {
    try {
      const companyId = getState().auth?.loginResponse?.selectCompany?.[0]?.company_id;

      dispatch(fetchCompanyDashboardDataSlice({ isLoading: true }));
      const { data } = await main_api.get(companyAPIsEndPoints.FETCH_COMPANY_DASHBOARD_DATA(companyId));

      dispatch(fetchCompanyDashboardDataSlice({ isLoading: false, responseData: data }));
    } catch (error) {
      dispatch(fetchCompanyDashboardDataSlice({ isLoading: false, responseData: error }));
    }
  };
};
