import React from "react";
import { TableHeader } from "./tasksManagementStyles";
import SearchInput from "../../../components/SearchInput";
import { AntdesignTable } from "../../../components/antDesignTable/AntdesignTable";
import { WorkQueueColumns } from "../../../constants/CompanyTableHeaders/TaskManagementHeaders";
import { useState } from "react";
import { useEffect } from "react";
import CustomBtnWithIcon from "../../../components/buttonWithIcon";
import CreateWorkQueue from "../../../components/Modals/TasksManagementModal/CreateWorkQueue";
import DeleteConfirmationRowModal from "../../../components/Modals/DeleteConfirmationRowModal";
import { deleteWorkQueue, getSingleWorkQueue, getWorkQueue } from "../../../util/DataService";
import { pushNotification } from "../../../util/Notification";
import EditWorkQueue from "../../../components/Modals/TasksManagementModal/EditWorkQueue";
import { useSelector } from "react-redux";
import PermissionModal from "../../../components/Modals/PermissionModal";
import { checkPermission } from "../../../util/commonUtil";

const WorkQueue = ({ props }) => {
  const [search, setSearch] = useState("");
  const [workQueue, setWorkQueue] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [editModalValues, setEditModalValues] = useState("");
  const [workQueueID, setWorkQueueID] = useState("");
  const tasksList = props.tasksListResponse?.result?.map((item) => ({ label: item.name, value: item.id }));
  const generalList = props.companyUsersListResponse?.result?.map((item) => ({ label: item.name, value: item.id }));
  const clientList = props.clientManagementState.clientList;
  const staffList = props.allStaffState.staffList;
  const [permissionPopUp, setPermissionPopUp] = useState(false);
  const { auth, permissionManagement } = useSelector((state) => state);

  useEffect(() => {
    if (checkPermission(permissionManagement.permissions, "view_work_queues")) {
      props.loadWorkQueue(search);
    }
  }, [permissionManagement.permissions]);

  // useEffect(() => {
  //   if (checkPermission(permissionManagement.permissions, 'view_work_queues')) {
  //     getWorkQueue()
  //       .then(({data}) => {
  //       console.log("data = ", data);

  //        setWorkQueue(data.result);
  //       })
  //       .catch(error => {
  //         console.error('Error fetching work queue:', error);
  //       });
  //   }
  // console.log("workQueue = ", workQueue);

  // }, [permissionManagement.permissions]);

  // useEffect(() => {
  //   if (checkPermission(permissionManagement.permissions, 'view_work_queues')) {
  //     const companyId = auth?.loginResponse?.selectCompany?.[0]?.company_id;
  //     const user_id = auth?.loginResponse?.LoggedInUserData?.id;

  //     getSingleWorkQueue(companyId,user_id)
  //       .then(({data}) => {
  //       console.log("data = ", data);

  //       //  setWorkQueue(data.result);
  //       })
  //       .catch(error => {
  //         console.error('Error fetching work queue:', error);
  //       });
  //   }

  // }, [permissionManagement.permissions]);

  const searchBlurHandler = () => {
    props.loadWorkQueue(search);
  };

  const handleEditRow = (record) => {
    if (checkPermission(permissionManagement.permissions, "edit_work_queue")) {
      if (record) {
        props.loadWorkQueueDetail(record.id);
      }
      setEditModalOpen(true);
    } else {
      setPermissionPopUp(true);
    }
  };

  const handleDelete = (record) => {
    if (checkPermission(permissionManagement.permissions, "delete_work_queue")) {
      if (record) {
        const { id } = record;
        setWorkQueueID(id);
      }
      setDeleteModalOpen(true);
    } else {
      setPermissionPopUp(true);
    }
  };

  const deleteWorkQueueHandler = () => {
    deleteWorkQueue(workQueueID).then(({ data }) => {
      if (data.status == 200) {
        pushNotification("WorkQueue has been deleted successfully!");
        getWorkQueue();
        setDeleteModalOpen(false);
        props.loadWorkQueue();
      }
    });
  };

  const createNewWorkQueue = () => {
    if (checkPermission(permissionManagement.permissions, "create_work_queue")) {
      setModalOpen(true);
    } else {
      setPermissionPopUp(true);
    }
  };

  let taskResponse = props.workQueueResponse?.result;

  let workQueueTaskIds = [];
  taskResponse?.forEach((elem) => {
    elem?.work_queue_task.forEach((task) => {
      workQueueTaskIds?.push(task.id);
    });
  });

  // Filter tasksList to exclude tasks with IDs in workQueueTaskIds
  let filteredTasksList = tasksList?.filter((task) => !workQueueTaskIds.includes(task.value));

  return (
    <>
      <CreateWorkQueue
        isLoading={false}
        isModalOpen={isModalOpen}
        setModalOpen={setModalOpen}
        staffList={staffList}
        clientList={clientList}
        generalList={generalList}
        tasksList={filteredTasksList || []}
        loadWorkQueue={props.loadWorkQueue}
        onFinish={() => {}}
      />

      <EditWorkQueue
        isLoading={false}
        isModalOpen={isEditModalOpen}
        setModalOpen={setEditModalOpen}
        staffList={staffList}
        clientList={clientList}
        generalList={generalList}
        tasksList={tasksList}
        editModalValues={props.workQueueDetailResponse}
        loadWorkQueue={props.loadWorkQueue}
        onFinish={() => {}}
        updateQue={true}
        workQueueResponse={taskResponse}
      />
      <DeleteConfirmationRowModal
        onclose={() => setDeleteModalOpen(false)}
        handleConfirmDeleteRows={deleteWorkQueueHandler}
        openConfimrationDeleteModal={isDeleteModalOpen}
      />
      <TableHeader>
        <div className="d-flex align-items-center col">
          <SearchInput
            search={search}
            onChange={(value) => setSearch(value)}
            onBlur={searchBlurHandler}
            placeholder="Type to search.."
          />
          <CustomBtnWithIcon
            btnText={"Add New Work Queue"}
            className="ms-md-auto"
            margin="10px 10px 10px auto"
            onClick={() => createNewWorkQueue()}
          />
        </div>
      </TableHeader>
      <AntdesignTable
        columns={WorkQueueColumns({ handleDelete, handleEditRow, props })}
        data={props.workQueueResponse?.result || []}
      />
      <PermissionModal onclose={() => setPermissionPopUp(false)} openModal={permissionPopUp} />
    </>
  );
};

export default WorkQueue;
