import styled from "styled-components";

export const ContainerWrapper = styled.div``;

export const ContainerHoursTime = styled.div`
  display: flex;
  flex-wrap: nowrap;
  /* Horizontal overflow */
  align-items: center;
`;

export const TimerWrapper = styled.div`
  display: flex;

  margin: 0px 13px;
`;

export const PetsCheckboxWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
