import React, { useState } from "react";
import { Divider, Form } from "antd";
import styled from "styled-components";
import CustomBtnWithIcon from "../../buttonWithIcon";
import { CustomModal } from "../ModalStyles";
import SelectField from "../../Form/Select";
import AntDesignDropDownSelect from "../../AntDesignDropDownSelect/AntDesignDropDownSelect";
import HeadingComponent from "../../Heading";

const ReassignKeyModal = ({
  isLoading,
  openKeyModal,
  setOpenKeyModal,
  // onFinish,
  vetList,
  clientList,
  clientId,
  keysList,
  allStaffList,
  showClientSelect = true,
}) => {
  const [form] = Form.useForm();
  form.resetFields();

  const [value, setValue] = useState(1);
  const [possession, setPossession] = useState({});
  const [SelectTab, setSelectTab] = React.useState("newKey");
  const handleChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <CustomModal
      open={openKeyModal}
      title="Bulk Reassign Keys"
      width="600px"
      // heigth="288px"
      onCancel={() => setOpenKeyModal(false)}
      footer={null}
      maskClosable={false}
    >
      <Divider />
      <Form
        name="name"
        // onFinish={onFinish} form={form}
        layout="vertical"
      >
        <FormWraper>
          <FieldWraper>
            <HeadingComponent text="Keys" fontSize={"12px"} />
            <AntDesignDropDownSelect
              mode="multiple"
              defaultValue={keysList?.map((items) => {
                return {
                  value: items.id,
                  label: items.name,
                };
              })}
              className="mt-2"
              options={keysList?.map((items) => {
                return {
                  value: items.id,
                  label: items.name,
                };
              })}
              style={{ maxHeight: "100px", overflow: "auto" }}
            />
          </FieldWraper>
          <FieldWraper>
            <HeadingComponent text="Change to" fontSize={"12px"} />
            <AntDesignDropDownSelect
              className="mt-2"
              defaultValue={[]}
              options={[
                // { value: "client", label: "Client" },
                { value: "staff", label: "Staff" },
                { value: "office", label: "At Office" },
              ]}
              onChange={(staff) => {
                console.log("staff", staff);
                setPossession(staff);
              }}
            />
          </FieldWraper>

          {possession === "staff" && (
            <AntDesignDropDownSelect
              className="mt-2"
              defaultValue={[]}
              mode="multiple"
              options={allStaffList}
              onChange={(staff) => {
                console.log("staff", staff);
                // setPossession(staff);
              }}
            />
          )}
        </FormWraper>
        <Divider />
        <div className="d-flex justify-content-end">
          <CustomBtnWithIcon
            btnText={"Cancel"}
            margin="0px 5px"
            noBackground
            hideIcon={true}
            type="button"
            onClick={() => {
              setOpenKeyModal(false);
            }}
          />
          <CustomBtnWithIcon
            btnText={"Save Key"}
            type="submit"
            hideIcon={true}
            onClick={() => {
              setOpenKeyModal(false);
            }}
          />
        </div>
      </Form>
    </CustomModal>
  );
};

const FormWraper = styled.div`
  // height: 356px;
  overflow: auto;

  ::-webkit-scrollbar {
    width: 0px;
  }
`;

const FieldWraper = styled(Form.Item)`
  width: 100%;
  margin-bottom: 15px;
`;

export default ReassignKeyModal;
