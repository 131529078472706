import React, { useEffect, useState } from "react";
import {
  DatePicker,
  Divider,
  Flex,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Select,
  TimePicker,
  Typography,
  Image,
  Upload,
} from "antd";
import { ReactComponent as DefaultImageIcon } from "../../../assets/rawSvgs/defaultImage.svg";
import CustomInput from "../../CustomInput";
import styled from "styled-components";
import CustomBtnWithIcon from "../../buttonWithIcon";
import { useSelector } from "react-redux";

import { CustomModal } from "../ModalStyles";
import { CustomDivider } from "../../CustomDivider";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import { loadCompanyDetail } from "../../../redux/platformSlices/CompanyDetail";
import { main_api } from "../../../api/axios_helper";
import { companyAPIsEndPoints } from "../../../constants/ApiEndPoints";

const { Dragger } = Upload;

const getComponent = (field) => {
  const commonProps = {
    placeholder: field.preview_text,
    options: field.options || [],
  };

  const mappedOptions = commonProps.options.map((option) => ({
    value: option,
    label: option,
  }));

  const mapping = {
    text: <Input placeholder={commonProps.placeholder} />,
    date: <DatePicker placeholder={commonProps.placeholder} style={{ width: "100%", height: "40px" }} />,
    time: <TimePicker hourStep={1} format="hh:mm a" placeholder={commonProps.placeholder} style={{ width: "100%" }} />,
    number: <InputNumber placeholder={commonProps.placeholder} style={{ width: "100%" }} />,
    dropdown: (
      <Select placeholder={commonProps.placeholder}>
        {mappedOptions.map((option) => (
          <Select.Option key={option.value} value={option.value}>
            {option.label}
          </Select.Option>
        ))}
      </Select>
    ),
  };
  return mapping[field.field_type];
};

const AddPetModal = ({
  isLoading,
  isModalOpen,
  setModalOpen,
  onFinish,
  vetList,
  clientList,
  clientId,
  showClientSelect = true,
  singleClientresponseData,
  clientDetails,
  isClientPortal,
}) => {
  const [form] = Form.useForm();

  // form.resetFields();
  const updateVetList = vetList?.map((vet) => ({ value: vet.id, label: vet.name })) || [{}];
  const updatedClientList = clientList?.map((client) => ({ value: client.id, label: client.name }));
  const [imageFromComp, setImageFromComp] = useState("");
  const dispatch = useDispatch();

  const auth = useSelector((state) => state.auth);
  const companyId = auth.loginResponse?.selectCompany?.[0]?.company_id || null;

  const details = useSelector((state) => state.companyDetail.companyDetailResponse);
  const groups = details?.result?.pet_custom_fields?.groups || [];

  if (isClientPortal) {
    form.setFieldsValue({
      client: clientDetails?.id,
    });
  }

  useEffect(() => {
    dispatch(loadCompanyDetail(companyId));
  }, [dispatch, companyId]);

  const handleSubmit = async (values) => {
    try {
      const customFields = groups.flatMap((group) =>
        group.fields.map((field) => ({
          id: field.id,
          value: values[field.id] || "",
        })),
      );
      let fileResponse;
      if (imageFromComp?.[0]) {
        fileResponse = await uploadToS3(imageFromComp?.[0], "image");
      }

      const formValues = {
        ...values,
        logo: fileResponse?.data?.url || "",
        custom_fields: customFields,
      };

      onFinish(formValues);
    } catch (error) {
      console.log("Pet add error:", error);
    }
  };

  const uploadToS3 = async (file, type) => {
    const formData = new FormData();
    formData.append(type, file);
    const actualFile = file.originFileObj || file;

    formData.append(type, actualFile);
    try {
      const response = await main_api.post(companyAPIsEndPoints.UPLOAD_OBJECT, formData);

      if (response.data.isSuccess) {
        return response;
      }
    } catch (error) {
      console.error(`Error uploading ${type} to S3`, error);
      message.error(`Failed to upload ${type}`);
    }
  };

  const props = {
    name: "file",
    multiple: false, // Set to true if you want to allow multiple file uploads
    beforeUpload: (file) => {
      // Check if the file is an image
      const isImage = file.type.startsWith("image/");
      if (!isImage) {
        message.error("You can only upload image files!");
      }
      return isImage; // Prevent upload if the file is not an image
    },
    customRequest: async ({ file, onSuccess, onError }) => {
      try {
        // await uploadToS3(file, "image");
        onSuccess();
      } catch (error) {
        onError(error);
      }
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        // console.log(info.file, info.fileList);
      }
      // if (status === "error") {
      //   message.error(`file upload failed. Please try again`);
      // }

      // Show image preview
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const renderCustomFields = () => {
    return groups.map((group, groupIndex) => (
      <div key={groupIndex}>
        {group.fields.map((field) => {
          return (
            <FormItem key={field.id} name={field.id} label={field.field_name} className="mb-3">
              {getComponent(field)}
            </FormItem>
          );
        })}
      </div>
    ));
  };

  return (
    <CustomModal
      open={isModalOpen}
      title="Add a new Pet"
      width="748px"
      heigth="288px"
      onCancel={() => setModalOpen(false)}
      footer={null}
      maskClosable={false}
    >
      <Divider />

      <Form name="addPetForm" onFinish={handleSubmit} form={form} layout="vertical">
        <FormWraper>
          <FormItem>
            <div className="m-auto text-center">
              <AddImageComponent uploadToS3Func={(file) => setImageFromComp(file)} />
              <p className="ant-upload-text">Upload image of your pet</p>
              <p className="ant-upload-hint">PNG, JPG, JPEG</p>
            </div>
            <div>
              {/* <Dragger {...props} multiple={false} style={{ width: "50%" }}>
              <p className="ant-upload-drag-icon">
                <DefaultImageIcon />
              </p>
              <p className="ant-upload-text">Upload image of your pet</p>
              <p className="ant-upload-hint">PNG, JPG, JPEG</p>
            </Dragger> */}
            </div>
          </FormItem>
          <FieldWraper>
            <FormItem
              name="name"
              label="Name"
              rules={[
                {
                  required: true,
                  message: "Please enter Pet Name",
                },
                {
                  min: 3,
                  max: 50,
                  message: "Name must be between 3 and 50 characters",
                },
              ]}
            >
              <Input placeholder="Enter Name of Pet" style={{ width: "100%", height: "40px" }} />
            </FormItem>
            <FormItem
              name="birth_date"
              label="Birth Date"
              rules={[
                {
                  required: true,
                  message: "Please select a Birth Date",
                },
              ]}
            >
              <DatePicker
                style={{ width: "100%", height: "40px" }}
                type="date"
                placeholder="Select Date of Birth"
                format={"DD/MM/YYYY"}
                disabledDate={(current) => current && current > dayjs().endOf("day")}
              />
            </FormItem>
          </FieldWraper>
          <FieldWraper>
            <FormItem name="type" label="Type">
              <Select
                placeholder="Select Type"
                options={[
                  { value: "Cat", label: "Cat" },
                  { value: "Dog", label: "Dog" },
                ]}
              />
            </FormItem>
            <FormItem name="sex" label="Sex">
              <Select
                placeholder="Select Sex"
                options={[
                  { value: "Male", label: "Male" },
                  { value: "Female", label: "Female" },
                ]}
              />
            </FormItem>
          </FieldWraper>
          <FieldWraper>
            <FormItem
              name="color"
              label="Color"
              rules={[
                {
                  min: 3,
                  max: 50,
                  message: "Color must be between 3 and 50 characters",
                },
              ]}
            >
              <Input placeholder="Enter Color" style={{ width: "100%", height: "40px" }} />
            </FormItem>
            <FormItem
              name="breed"
              label="Breed"
              rules={[
                {
                  min: 3,
                  max: 50,
                  message: "Breed must be between 3 and 50 characters",
                },
              ]}
            >
              <Input placeholder="Enter Breed" style={{ width: "100%", height: "40px" }} />
            </FormItem>
          </FieldWraper>
          <FieldWraper>
            <FormItem name="neutered" label="Neutered / Sprayed">
              <Select
                placeholder="Select Option"
                options={[
                  { value: 0, label: "No" },
                  { value: 1, label: "Yes" },
                ]}
              />
            </FormItem>
            <FormItem name="vaccination_current" label="Vaccinated">
              <Select
                placeholder="Select Option"
                options={[
                  { value: 0, label: "No" },
                  { value: 1, label: "Yes" },
                ]}
              />
            </FormItem>
          </FieldWraper>
          <FieldWraper>
            <FormItem name="primary_vet" label="Primary Vet">
              <Select placeholder="Select Vet" options={updateVetList} />
            </FormItem>
            <FormItem name="alternate_vet" label="Alternative Vet">
              <Select placeholder="Select Vet" options={updateVetList} />
            </FormItem>
          </FieldWraper>
          <FieldWraper>
            <FormItem name="micro_chip" label="Microchip ID" className="mt-1">
              <Input placeholder="Enter Microchip ID" style={{ width: "100%", height: "40px" }} />
            </FormItem>
            <FormItem
              name="client"
              label="Client"
              rules={[
                {
                  message: "Please select a Client",
                  required: showClientSelect,
                },
              ]}
            >
              {showClientSelect ? (
                <Select
                  placeholder="Select Client"
                  options={updatedClientList}
                  onChange={(e) => {
                    form?.setFieldsValue({ client: e });
                  }}
                />
              ) : (
                <Select
                  placeholder="Select Client"
                  options={updatedClientList}
                  defaultValue={singleClientresponseData?.id}
                  onChange={(e) => {
                    form?.setFieldsValue({ client: e });
                  }}
                  disabled
                />
              )}
            </FormItem>
          </FieldWraper>
          <FormItem
            name="notes"
            label="Notes"
            rules={[
              {
                min: 10,
                max: 255,
                message: "Notes must be between 10 and 255 characters",
              },
            ]}
          >
            <Input.TextArea placeholder="Type notes here..." />
          </FormItem>
          {renderCustomFields()}
        </FormWraper>
        <Divider />
        <div className="d-flex justify-content-end">
          <CustomBtnWithIcon
            btnText={"Cancel"}
            margin="0px 5px"
            noBackground
            hideIcon={true}
            type="button"
            onClick={() => {
              setModalOpen(false);
            }}
          />
          <CustomBtnWithIcon btnText={"Add Pet"} type="submit" hideIcon={true} />
        </div>
      </Form>
    </CustomModal>
  );
};

const FormWraper = styled.div`
  height: 556px;
  overflow: auto;

  ::-webkit-scrollbar {
    width: 0px;
  }
`;

const FieldWraper = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
`;

const FormItem = styled(Form.Item)`
  width: 100%;
`;

export default AddPetModal;

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const AddImageComponent = ({ uploadToS3Func }) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [fileList, setFileList] = useState([]);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      const reader = new FileReader();
      reader.readAsDataURL(file.originFileObj);
      reader.onload = () => {
        file.preview = reader.result;
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
      };
    } else {
      setPreviewImage(file.url || file.preview);
      setPreviewOpen(true);
    }
  };

  const handleChange = ({ fileList: newFileList }) => {
    uploadToS3Func(newFileList);
    setFileList(newFileList);
  };

  const uploadButton = (
    <button
      style={{
        border: 0,
        background: "none",
        fontSize: 40, // Further increase font size for button
        padding: "30px", // Add more padding for larger button size
        width: "200px", // Further increase width of the button
        height: "200px", // Further increase height of the button
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
      type="button"
    >
      <div
        style={{
          marginTop: 12,
          fontSize: 18, // Further increase text size
        }}
      >
        Upload
      </div>
    </button>
  );

  return (
    <>
      <Upload
        // action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
        listType="picture-card"
        fileList={fileList}
        onPreview={handlePreview}
        onChange={handleChange}
        multiple={false} // Disable multiple file uploads
        beforeUpload={(file) => {
          const isImage = file.type.startsWith("image/");
          if (!isImage) {
            message.error("You can only upload image files.");
            return Upload.LIST_IGNORE;
          }
          return isImage;
        }}
      >
        {fileList.length >= 1 ? null : uploadButton}
      </Upload>
      {previewImage && (
        <Image
          wrapperStyle={{
            display: "block",
            maxWidth: "100%", // Ensure image is responsive
            width: "400px", // Further increase width for preview image
            height: "400px", // Further increase height for preview image
            objectFit: "contain", // Ensure image fits within the specified size
          }}
          preview={{
            visible: previewOpen,
            onVisibleChange: (visible) => setPreviewOpen(visible),
            afterOpenChange: (visible) => !visible && setPreviewImage(""),
          }}
          src={previewImage}
        />
      )}
    </>
  );
};
