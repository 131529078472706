import React from "react";
import { Space } from "antd";
import { ReactComponent as DeleteIcon } from "../../assets/rawSvgs/tableDeleteIcon.svg";
import { ReactComponent as EditIcon } from "../../assets/rawSvgs/editIconPencil.svg";
import { ReactComponent as CatIcon } from "../../assets/rawSvgs/catIcon.svg";
import { ReactComponent as VaccinatedIcon } from "../../assets/rawSvgs/vaccinatedIcon.svg";
import Avatar from "react-avatar";

import { ReactComponent as GenderIcon } from "../../assets/rawSvgs/genderIcon.svg";

import CatImage from "../../assets/images/dog6.png";

import { WrapperCard, AvatarHeaderWrapper, HashAvatarNumber, NameTagsWrapper, ClientName, PillsWrapper } from "./PetCardStyles";
import AntDesignTagsComponenet from "../AntDesignTags/AntdesignTags";
import HeadingComponent from "../Heading";
//
const PetCardComponent = ({
  clientName,
  tagNumber,
  chipsArray,
  paraGraph,
  clientAvatar = CatImage,
  AvatarName = clientName,
  vaccinated,
  petType,
  sex,
  EditOnClick,
  DeleteOnClick,
  petImg,
}) => {
  return (
    <WrapperCard>
      <AvatarHeaderWrapper>
        {/* <Avatar name={"P A"} round={true} size="80" textSizeRatio={1.5} /> */}
        <Avatar name={clientName} src={petImg} round={true} size="80" textSizeRatio={1.5} />
        <Space size={7} style={{ marginTop: "5px" }}>
          <button onClick={EditOnClick}>
            <EditIcon width={"18px"} height="18px" />
          </button>
          <button onClick={DeleteOnClick}>
            <DeleteIcon width={"18px"} height="18px" />
          </button>
        </Space>
      </AvatarHeaderWrapper>

      <HashAvatarNumber className="mt-2">{tagNumber && `# ${tagNumber}`}</HashAvatarNumber>
      <PillComponent clientName={clientName} petType={petType} vaccinated={vaccinated} sex={sex} />
      <PetPhyicalDetailComponent items={chipsArray} />
      <HeadingComponent text={paraGraph} fontSize="12px" fontWeight={400} margin="10px auto auto" />
    </WrapperCard>
  );
};

export default PetCardComponent;

export const PillComponent = ({ clientName, petType, vaccinated, sex }) => {
  return (
    <NameTagsWrapper>
      <Space size={2} align="baseline" wrap>
        <ClientName>{clientName}</ClientName>
        {petType && <AntDesignTagsComponenet tagText={petType} tagIcon={<CatIcon style={{ marginRight: "4px" }} />} />}
        {vaccinated && (
          <AntDesignTagsComponenet
            tagText={vaccinated ? "Vaccinated" : "Not Vaccinated"}
            tagIcon={<VaccinatedIcon style={{ marginRight: "4px" }} />}
          />
        )}
        {sex && <AntDesignTagsComponenet tagText={sex} tagIcon={<GenderIcon style={{ marginRight: "4px" }} />} />}
      </Space>
    </NameTagsWrapper>
  );
};
const PetPhyicalDetailComponent = ({ items }) => {
  const renderedItems = items.map((item, index) => (
    <HeadingComponent
      key={index}
      text={
        <>
          {item}
          {item && index > -1 && index < items.length - 1 && " |"}
        </>
      }
      fontSize="14px"
      fontWeight={400}
    />
  ));

  return <Space>{renderedItems}</Space>;
};
