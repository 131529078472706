import React from "react";
import { ReactComponent as NoclientDataIcon } from "../assets/rawSvgs/clientsIcons.svg";
import { ClientContainer, NoLCientsHeading } from "../pages/company/clients/clientStyles";
import CustomBtnWithIcon from "./buttonWithIcon";

const NoDataScreen = ({heading, btnText, onClick, showBtn=true, height = "400px" }) => {
  return (
    <ClientContainer height={height}>
      <NoclientDataIcon />
      <NoLCientsHeading>{heading}</NoLCientsHeading>
      {showBtn && <CustomBtnWithIcon btnText={btnText} margin="10px" onClick={onClick} />}
    </ClientContainer>
  );
};

export default NoDataScreen;
