import React from "react";
// import dog1 from "../../assets/rawSvgs/dog1.svg";
// import dog2 from "../../assets/rawSvgs/dog2.svg";
// import dog3 from "../../assets/rawSvgs/dog3.svg";
// import dog4 from "../../assets/rawSvgs/dog4.svg";
// import dog5 from "../../assets/rawSvgs/dog5.svg";
// import dog6 from "../../assets/rawSvgs/dog6.svg";
// import dog7 from "../../assets/rawSvgs/dog7.svg";
// WEBP
import dog1copy from "../../assets/rawSvgs/authpageimages/dog1.webp";
import dog2copy from "../../assets/rawSvgs/authpageimages//dog2.webp";
import dog3copy from "../../assets/rawSvgs/authpageimages//dog3.webp";
import dog4copy from "../../assets/rawSvgs/authpageimages//dog4.webp";
import dog5copy from "../../assets/rawSvgs/authpageimages//dog5.webp";
import dog6copy from "../../assets/rawSvgs/authpageimages//dog6.webp";
import dog7copy from "../../assets/rawSvgs/authpageimages//dog7.webp";
import { CircularImage, HorizontalImage, ImageBackground, ImageContainer, VerticalImage } from "./AuthPetsScreenStyle";
import { Image } from "antd";

const AuthPetsScreen = () => {
  return (
    <ImageBackground>
      <ImageContainer>
        <CircularImage>
          <Image src={dog1copy} preview={false} />
        </CircularImage>
        <HorizontalImage style={{ gridColumn: "2/4" }}>
          <Image src={dog2copy} preview={false} />
        </HorizontalImage>
        <VerticalImage>
          <Image src={dog3copy} preview={false} />
        </VerticalImage>
        <VerticalImage>
          <Image src={dog4copy} preview={false} />
        </VerticalImage>
        <div
          style={{
            gridColumn: "3/4",
            gridRow: "2/4",
            gap: "20px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <CircularImage>
            <Image src={dog5copy} preview={false} />
          </CircularImage>
          <VerticalImage style={{ height: "287px" }}>
            <Image src={dog7copy} preview={false} />
          </VerticalImage>
        </div>
        <HorizontalImage style={{ gridColumn: "1/3" }}>
          <Image src={dog6copy} preview={false} />
        </HorizontalImage>
      </ImageContainer>
    </ImageBackground>
  );
};

export default AuthPetsScreen;
