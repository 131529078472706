import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { userHasValidToken } from "../../api/auth";
import HeaderNav from "../HeaderNav";
import SidebarNav from "../../containers/layout/Sidebar";
import { platformSidebarNavData } from "../../constants/SidebarNavData";
import CompanyDetailPage from "../../containers/companyDetail/companyDetail";
import { useSelector } from "react-redux";

const companyRoute = "";

const routePathsWithoutId = ["/company/clients/client-details/"];
const ProtectedRoutePlatform = ({ Page }) => {
  const hasValidToken = userHasValidToken();
  const [isExpanded, setIsExpanded] = useState(false);
  let navigate = useNavigate();
  let pathName = useLocation().pathname;

  const isMatchingPath = routePathsWithoutId.some((routePath) => pathName.startsWith(routePath));
  const generalAppSt = useSelector((state) => state.generalApp);
  const auth = useSelector((state) => state.auth);
  const [platformAdmin, setPlatformAdmin] = useState(false);

  useEffect(() => {
    if (!hasValidToken) {
      navigate("/platform/signin");
    }
  }, []);

  useEffect(() => {
    if (auth.loginResponse && auth.loginResponse.is_platform_admin) {
      setPlatformAdmin(true)
    }
  }, [auth]);
  return hasValidToken && platformAdmin ? (
    <Container>
      <SidebarNav navItems={platformSidebarNavData} />
      <Body expanded={isExpanded}>
        <HeaderNav />
        <Container>
          <Content
            height={pathName.includes("company/message") ? "calc(100% - 75px)" : "auto"}
            marginTop={pathName.includes("company/message") && "0"}
            padding={isMatchingPath && "0"}
            toggle={generalAppSt.sidebarToggle}
          >
            <Page />
          </Content>
          <CompanyDetailPage />
        </Container>
      </Body>
    </Container>
  ) : (
    <></>
  );
};

const Container = styled.div`
  display: flex;
  height: 100%;
  overflow-y: auto;
`;

const Body = styled.div`
  flex: 1;
  /* width: calc(100vw - ${(props) => (props.expanded ? "240px" : "80px")}); */
  overflow: hidden;
`;

const Content = styled.div`
  height: ${(props) => (props.height ? props.height : "auto")};
  ${"" /* margin-top: ${props => props.marginTop ? props.marginTop : "20px"}; */}
  padding: ${(props) => (props.padding ? props.padding : "17px 25px")};
  flex: 1;
  margin-left: ${(props) => (props.toggle ? "240px" : "80px")};
  margin-top: 60px;
`;

export default ProtectedRoutePlatform;
