import React, { useState } from 'react';
import { Table, Switch, Divider } from 'antd';
import CustomBtnWithIcon from '../../../../components/buttonWithIcon';

const TimeTrackingTable = ({ data,handleUpdateData }) => {
 

  const handleTimeTrackingSwitchChange = (record) => (checked) => {
    const updatedData = { time_tracking_enabled: checked };
    handleUpdateData(record.id, updatedData); 
   
  };
  
  const handleMileageTrackingSwitchChange = (record) => (checked) => {
    const updatedData = { mileage_tracking_enabled: checked };
    handleUpdateData(record.id, updatedData); 

  };
  

  const handleSaveChanges = () => {
  
  };

  // Columns configuration for the table
  const columns = [
    {
      title: 'Staff Name',
      dataIndex: 'staff_name',
      key: 'staff_name',
    },
    {
      title: 'Time Tracking',
      dataIndex: 'time_tracking',
      key: 'time_tracking',
      render: (text, record) => (
        <Switch
          checked={record.time_tracking}
          onChange={handleTimeTrackingSwitchChange(record)}
        />
      ),
    },
    {
      title: 'Mileage Tracking',
      dataIndex: 'mileage_tracking',
      key: 'mileage_tracking',
      render: (text, record) => (
        <Switch
          checked={record.mileage_tracking}
          onChange={handleMileageTrackingSwitchChange(record)}
        />
      ),
    },
  ];

  return (
    <>
      <Table dataSource={data} columns={columns} />
      <Divider />
      <div className="d-flex justify-content-end">
        <CustomBtnWithIcon btnText="Save Changes" type="submit" hideIcon={true} onClick={handleSaveChanges} />
      </div>
    </>
  );
};

export default TimeTrackingTable;
