import React, { useCallback, useEffect, useState } from "react";
import ReactAvatar from "react-avatar";
import { Avatar, Button, DatePicker, Form, Input, Radio, Space, TimePicker, Tooltip, Upload } from "antd";
import { usePubNub, gene } from "pubnub-react";
import PubNub, { generateUUID } from "pubnub";
import { ChannelList, ChatHeaderSection, ChatList, ChatMainSection, LeftSideHeader } from "./Chat.styled";
import SearchInput from "../../../components/SearchInput";
import HeadingComponent from "../../../components/Heading";
import { ReactComponent as SendMsgIcon } from "../../../assets/rawSvgs/sendMsgIcon.svg";
//
import { ReactComponent as PinMsg } from "../../../assets/rawSvgs/pinMsg.svg";

import { ReactComponent as PlusSIgn } from "../../../assets/rawSvgs/plusSign.svg";

//
import CustomBtnWithIcon from "../../../components/buttonWithIcon";
import AntDesignModal from "../../../components/Modals/AntDesignModal/AntDesignModal";
import AntDesignNavTabs from "../../../components/antDesignNaviTabs/AntDesignNavTabs";
import { UserMessageComponent } from "./ChatRightSideComponents";

import { pushNotification } from "../../../util/Notification";
import { InputTextLabel } from "../../../components/formikInputs/Checkbox";
import moment from "moment";
import { companyAPIsEndPoints } from "../../../constants/ApiEndPoints";
import { main_api } from "../../../api/axios_helper";
import { sendChannelModifySignal, sendFileSignal } from "./utilsFunctions";

export const ChannelSettingsModal = ({ settingsModal, settingModal, onlyCompId, update, isAdminOrUser, ...rest }) => {
  const [channelNameInput, setchannelNameInput] = useState(update ? rest?.channelsDetails?.name : "");
  const [channelVisibility, setChannelVisibility] = useState(
    update ? rest?.channelsDetails?.custom?.group_visibility : "private",
  );
  const [loader, setLoader] = useState(false);

  let closeModal = rest.closeModal;
  const pubnub = usePubNub();
  const uuid = pubnub.getUUID();
  const navTabs = [
    {
      key: "1",
      label: "About",
      children: (
        <>
          <HeadingComponent text={"Channel Name"} color="#3B3B3B" fontSize={"12px"} margin="5px 0px" />
          <Input
            placeholder="Enter Channel Name"
            required
            style={{ height: "40px" }}
            onChange={(e) => {
              setchannelNameInput(e.target.value);
            }}
            value={channelNameInput}
          />

          <div className="mt-3">
            <HeadingComponent text={"Visibility"} color="#3B3B3B" fontSize={"12px"} margin="5px 0px" />
            <Radio.Group
              size="large"
              className="d-flex flex-column gap-2"
              options={[
                { value: "private", label: "Private" },
                { value: "public", label: "Public" },
              ]}
              onChange={(e) => setChannelVisibility(e.target.value)}
              defaultValue={channelVisibility}
            />
            <HeadingComponent
              text={"Only people assigned to it have the access to channel's content."}
              color="#616161"
              fontSize={"12px"}
              margin="5px 0px"
              className="mt-3"
            />
          </div>
        </>
      ),
    },
  ];

  const handleCreateChannel = () => {
    if (update) {
      handleUpdateChannelName();
      return;
    }

    try {
      if (channelNameInput === "" || !channelNameInput || !channelVisibility) return;
      let channelName = `internal.${generateUUID()}`;
      const metadata = {
        name: channelNameInput,
        description: "This is a group channel for discussion.",
        custom: {
          company_id: onlyCompId,
          created_by: isAdminOrUser,
          owner: uuid,
          group_visibility: channelVisibility,
        },
      };
      setLoader(true);
      pubnub.objects
        .setChannelMetadata({
          channel: channelName,
          data: metadata,
        })
        .then((response) => {
          //  Subscribe to it
          setLoader(false);
          pubnub.subscribe({
            channels: [channelName],
          });
          // SET MEMBERS
          pubnub.objects
            .setChannelMembers({
              channel: channelName,
              uuids: [uuid],
            })
            .then((response) => {
              rest.onCompleteSuccess();
              sendChannelModifySignal(pubnub, channelName);
              pushNotification("Channel added successfully");
              setLoader(false);
              closeModal();
            })
            .catch((error) => {
              console.error("Error adding members:", error);
              setLoader(false);
            });
        })
        .catch((error) => {
          console.error("Error setting channel metadata:", error);
          setLoader(false);
        });
    } catch (error) {
      setLoader(false);
      console.log("error", error);
    }
  };

  const handleUpdateChannelName = () => {
    const metadata = {
      name: channelNameInput,
      description: "This is a group channel for discussion.",
      custom: {
        company_id: onlyCompId,
        created_by: "admin",
        group_visibility: channelVisibility,
      },
    };

    try {
      setLoader(true);
      pubnub.objects
        .setChannelMetadata({
          channel: rest?.channelsDetails.id,
          data: metadata,
        })
        .then((response) => {
          rest.onCompleteSuccess();
          sendChannelModifySignal(pubnub, rest?.channelsDetails.id);
          pushNotification("Channel updated successfully");

          closeModal();
        })
        .catch((error) => {
          console.error("Error updating channel metadata:", error);
        });
      setLoader(false);
    } catch (error) {
      setLoader(false);
    }
  };

  const enterFieldCompo = () => {
    return (
      <>
        <HeadingComponent text={"Channel Name"} color="#3B3B3B" fontSize={"12px"} margin="5px 0px" />
        <Input
          placeholder="Enter Channel Name"
          required
          style={{ height: "40px" }}
          onChange={(e) => {
            setchannelNameInput(e.target.value);
          }}
        />

        <div className="mt-3">
          <HeadingComponent text={"Visibility"} color="#3B3B3B" fontSize={"12px"} margin="5px 0px" />
          <Radio.Group
            size="large"
            className="d-flex flex-column gap-2"
            options={[
              { value: "private", label: "Private" },
              { value: "public", label: "Public" },
            ]}
            onChange={(e) => setChannelVisibility(e.target.value)}
            defaultValue={channelVisibility}
          />
          <HeadingComponent
            text={"Only people assigned to it have the access to channel's content."}
            color="#616161"
            fontSize={"12px"}
            margin="5px 0px"
            className="mt-3"
          />
        </div>
      </>
    );
  };

  return (
    <div>
      <AntDesignModal
        openModal={settingsModal}
        closeModal={closeModal}
        onCancel={closeModal}
        onOk={closeModal}
        width={600}
        title={
          <HeadingComponent
            text={settingModal ? "Settings" : `Add new channel`}
            fontSize="16px"
            fontWeight={700}
            color="#212121"
          />
        }
        component={<>{settingModal ? <AntDesignNavTabs navTabs={navTabs} /> : enterFieldCompo()}</>}
        footer={
          <div className="d-flex justify-content-end">
            <CustomBtnWithIcon btnText={"Cancel"} hideIcon noBackground margin="0px 5px" onClick={() => closeModal()} />
            <CustomBtnWithIcon
              btnText={settingModal ? "Save Changes" : `Create Channel`}
              hideIcon
              margin="0px 5px"
              disabled={loader}
              onClick={handleCreateChannel}
            />
          </div>
        }
      />
    </div>
  );
};

export const MembersModal = ({
  addMemberModal,
  setOpenAddMemberModal,
  listOfUsers,
  channelMembers,
  channelsDetails,
  fetchingMemberLoader,
  fetchChannelMembers,
  channelHeaderUsers,
  pubnubUuid,
}) => {
  const [seaerchQuery, setSeaerchQuery] = useState("");

  let pubnub = usePubNub();

  let channelId = channelsDetails.id;

  const addMember = async (userId) => {
    try {
      await pubnub.objects.setChannelMembers({
        channel: channelId,
        uuids: [userId],
      });
      pushNotification("Member added to this channel");
      // pubnub.signal({
      //   channel: channelId,
      //   message: {
      //     eventType: "channel_create", // Custom event type
      //   },
      //   callback: function (signalStatus) {
      //     console.error("Error sending signal:", signalStatus);
      //   },
      // });

      try {
        pubnub.signal({
          message: {
            eventType: "channel_create", // Custom event type
          },
          callback: function (signalStatus) {
            if (signalStatus.error) {
              console.error("Error sending signal:", signalStatus.errorData.message);
            } else {
              console.log("Signal sent successfully:", signalStatus);
            }
          },
        });
      } catch (error) {
        console.error("Error sending signal:", error);
      }
      fetchChannelMembers();

      // fetchMembers();
    } catch (error) {
      console.error("Error adding member:", error);
    }
  };

  const removeMember = async (userId) => {
    try {
      await pubnub.objects.removeChannelMembers({
        channel: channelId,
        uuids: [userId],
      });
      fetchChannelMembers();
      pushNotification("Member removed from this channel", "error");
      // try {
      //   pubnub.signal({
      //     channel: channelId,
      //     message: {
      //       eventType: "channel_create", // Custom event type
      //     },
      //     callback: function (signalStatus) {
      //       console.error("Error sending signal:", signalStatus);
      //       if (signalStatus.error) {
      //       } else {
      //         console.log("Signal sent successfully:", signalStatus);
      //       }
      //     },
      //   });
      // } catch (error) {
      //   console.error("CATCHHl:", error);
      // }
    } catch (error) {
      console.error("Error removing member:", error);
    }
  };
  let userList = [];

  channelHeaderUsers?.forEach((element) => {
    const isMember = channelMembers?.some((nestElem) => element.id === nestElem.id);
    let role = "";

    if (element.id.startsWith("client")) {
      role = "Client";
    } else if (element.id.startsWith("staff")) {
      role = "Staff";
    } else if (element.id.startsWith("company")) {
      role = "Company";
      if (element.custom?.role === "company_manager") {
        role = "Company manager";
      }
    }
    userList.push({ ...element, channel_member: isMember, role: role });
  });

  const filterUserBySearch = userList?.filter((item) => item?.name.toLowerCase().includes(seaerchQuery.toLowerCase()));

  return (
    <div>
      <AntDesignModal
        openModal={addMemberModal}
        closeModal={() => setOpenAddMemberModal(false)}
        onCancel={() => setOpenAddMemberModal(false)}
        onOk={() => setOpenAddMemberModal(false)}
        width={600}
        title={<HeadingComponent text={`${channelMembers?.length} members`} fontSize="18px" fontWeight={700} color="#212121" />}
        component={
          <>
            <SearchInput placeholder="Type to search" search={seaerchQuery} onChange={(value) => setSeaerchQuery(value)} />
            <div style={{ height: "50vh", overflow: "auto" }}>
              {filterUserBySearch?.map((items, index) => {
                return (
                  <div className="my-3" key={index}>
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="d-flex align-items-center ">
                        <Avatar>{items.name?.slice(0, 2)}</Avatar>
                        <HeadingComponent
                          text={`${items.name} `}
                          fontSize="16px"
                          fontWeight={500}
                          color="#212121"
                          margin="0px 10px"
                        />
                        <HeadingComponent
                          text={`( ${items.role} )`}
                          fontSize="14px"
                          fontWeight={400}
                          color="#212121"
                          margin="0px 10px"
                        />
                      </div>
                      <div>
                        {items.channel_member ? (
                          <CustomBtnWithIcon
                            btnText={pubnubUuid === items.id ? "Leave channel" : "Remove from channel"}
                            color="#E53939"
                            noBackground
                            hideIcon
                            className="ml-auto"
                            onClick={() => removeMember(items.id)}
                            no
                            disabled={fetchingMemberLoader}
                          />
                        ) : (
                          <CustomBtnWithIcon
                            btnText={pubnubUuid === items.id ? "Join channel" : "Add to channel"}
                            color="#4CAF50"
                            noBackground
                            hideIcon
                            className="ml-auto"
                            onClick={() => addMember(items.id)}
                            disabled={fetchingMemberLoader}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        }
        footer={null}
      />
    </div>
  );
};

export const PinnedMessages = ({
  uuidMetaData,
  pinnedMessagesModal,
  setPinnedMessagesModal,
  pinMessages,
  handlePinnedMasg,
  emojiClick,
}) => {
  return (
    <div>
      <AntDesignModal
        openModal={pinnedMessagesModal}
        closeModal={() => setPinnedMessagesModal(false)}
        onCancel={() => setPinnedMessagesModal(false)}
        onOk={() => setPinnedMessagesModal(false)}
        width={600}
        title={pinMessages?.length + " " + "Pinned Messages"}
        component={
          <div className="p-2" style={{ maxHeight: "70vh", overflow: "auto" }}>
            {pinMessages?.map((items, index) => {
              let pinnedkeys = Object.keys(items?.actions.pin);
              let pinnedBy = pinnedkeys?.map((items) => (items === uuidMetaData.name ? "You" : items));

              return (
                <div key={index}>
                  <div className="d-flex align-items-start mt-5">
                    {pinnedkeys?.length > 3 ? (
                      <Tooltip
                        placement="right"
                        title={pinnedkeys?.map((item) => {
                          return (
                            <div>
                              <span>{item === uuidMetaData.name ? "You" : item}</span>
                            </div>
                          );
                        })}
                        className="d-flex"
                      >
                        <PinMsg className="" />
                        <HeadingComponent text={`Pinned by ${pinnedkeys?.length}`} fontSize="14px" margin={"0px 5px"} />
                      </Tooltip>
                    ) : (
                      <>
                        <PinMsg className="" />
                        <HeadingComponent text={`Pinned by ${pinnedBy}`} fontSize="14px" margin={"0px 5px"} />
                      </>
                    )}
                  </div>
                  <UserMessageComponent
                    chatterName={items?.message?.user?.name || items?.message?.sender?.name}
                    msg={items?.message?.text || ""}
                    timeToken={moment(parseInt(items?.timetoken?.substring(0, 13))).format("h:mm A")}
                    {...items}
                    fromPinMsgModal={true}
                    allStateMsg={items}
                    handlePinnedMasg={(item) => handlePinnedMasg(item)}
                    emojiClick={(emoji, cond) => {
                      // console.log("emoji", emoji, cond);
                      emojiClick(emoji, cond, items);
                    }}
                    fromPinnedModal={true}
                    reactionsData={items?.data?.reaction}
                  />
                </div>
              );
            })}
          </div>
        }
        footer={null}
      />
    </div>
  );
};

export const ScheduleMsgModal = ({
  openPinnedMsgModal,
  setopenPinnedMsgModal,
  onlyCompId,
  currentChannel,
  msg,
  allStateMsg,
  fromSchedule,
  inputField,
}) => {
  let pubnub = usePubNub();
  let uuid = pubnub.getUUID();
  const [selectedTime, setSelectedTime] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [loader, setLoader] = useState(false);

  function convertToUTC(selectedTimev2) {
    // Step 1: Parse the time string (e.g., "03:06 pm")
    if (!selectedTimev2) return;
    const [time, modifier] = selectedTimev2.split(" ");
    let [hours, minutes] = time.split(":").map(Number);

    // Step 2: Convert to 24-hour format
    if (modifier.toLowerCase() === "pm" && hours !== 12) {
      hours += 12;
    } else if (modifier.toLowerCase() === "am" && hours === 12) {
      hours = 0;
    }

    // Step 3: Create a Date object with today's date and the parsed time
    const date = new Date();
    date.setHours(hours, minutes, 0, 0);

    // Step 4: Convert the Date object to UTC
    const utcHours = date.getUTCHours();
    const utcMinutes = date.getUTCMinutes();

    // Step 5: Format the UTC time as HH:mm
    const result = `${String(utcHours).padStart(2, "0")}:${String(utcMinutes).padStart(2, "0")}`;

    return result;
  }

  // Example usage:

  const handleSubmit = async () => {
    try {
      if (selectedTime || selectedDate) {
        if (fromSchedule) {
          handleScheduleMessage();
          return;
        }
        setLoader(true);
        const adjustedTime = convertToUTC(selectedTime);
        let obj2 = {
          company: onlyCompId,
          channel_id: currentChannel,
          pubnub_user_id: uuid,
          msg_json: {
            text: msg,
          },
          date: selectedDate,
          time: adjustedTime,
        };

        const { data } = await main_api.post(companyAPIsEndPoints.REMINDER_MESSAGE, obj2);
        if (data.isSuccess) {
          pushNotification("Message reminder created Succesfully! ");

          setopenPinnedMsgModal(false);
        }
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
    }
  };

  const handleScheduleMessage = async () => {
    try {
      if (msg === "" || !msg) {
        pushNotification("Type message first", "error");
        return;
      }
      setLoader(true);
      const adjustedTime = convertToUTC(selectedTime);

      let obj = {
        company: onlyCompId,
        channel_id: currentChannel,
        pubnub_user_id: uuid,
        msg: msg,
        date: selectedDate,
        time: adjustedTime,
      };

      const { data } = await main_api.post(companyAPIsEndPoints.SCHEDULE_MESSAGE, obj);
      if (data.isSuccess) {
        pushNotification("Message scheduled Succesfully! ");
        setopenPinnedMsgModal(false);
      }
      setLoader(false);
    } catch (error) {
      console.log("error:", error);

      setLoader(false);
    }
  };

  return (
    <div>
      <AntDesignModal
        openModal={openPinnedMsgModal}
        closeModal={() => setopenPinnedMsgModal(false)}
        onCancel={() => setopenPinnedMsgModal(false)}
        onOk={() => setopenPinnedMsgModal(false)}
        width={450}
        title={fromSchedule ? "Schedule a message" : "Remind me Later"}
        component={
          <div className="p-2">
            <HeadingComponent
              text={
                fromSchedule
                  ? "Scheduling a message allows you to set a specific date and time for your message to be sent automatically"
                  : "Set the date and time when you would like to receive a reminder for this message"
              }
              color="#212121"
              fontSize={"14px"}
              fontWeight={400}
            />
            <div className=" d-flex flex-column  mt-3">
              <InputTextLabel>Date & Time</InputTextLabel>
              <DatePicker
                onChange={(time, timeString) => {
                  setSelectedDate(timeString);
                }}
                disabledDate={(current) => current && current < moment().startOf("day")}
                style={{ height: "40px" }}
              />
            </div>
            <div className=" d-flex flex-column  mt-3">
              <InputTextLabel>Start Time</InputTextLabel>
              <TimePicker
                format="hh:mm a"
                use12Hours
                onChange={(time, timeString) => {
                  setSelectedTime(timeString);
                }}
                style={{ height: "40px" }}
              />
            </div>

            {fromSchedule && <div className="border mt-3 round-all">{inputField}</div>}
            <div className="d-flex justify-content-end mt-3">
              <CustomBtnWithIcon
                btnText={fromSchedule ? "Schedule" : "Save Reminder"}
                onClick={handleSubmit}
                hideIcon
                disabled={loader || !msg || !selectedTime || !selectedDate}
              />
            </div>
          </div>
        }
        footer={null}
      />
    </div>
  );
};
const allowedFileTypes = ["image/jpeg", "image/png"];

export const UploadFileModal = ({ openUploadFileModal, onSuccess, setOpenFileModal, userId, name, channel, uuidMetaData }) => {
  const [fileList, setFileList] = useState([]);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [sendmediaSentLoader, setSendMediaLoader] = useState(false);
  const pubnub = usePubNub();

  const beforeUpload = (file) => {
    const isFileTypeValid = allowedFileTypes.includes(file.type);
    if (!isFileTypeValid) {
      console.error("You can only upload JPEG and PNG files!");
    }
    return true;
  };

  const handleChange = ({ fileList }) => {
    setFileList(fileList);
    setImageLoaded(false); // Reset imageLoaded state when file changes
  };

  const handleImageLoad = () => {
    setImageLoaded(true); // Set imageLoaded to true when image is loaded
  };

  const pickImage = () => {
    if (fileList.length === 0) {
      alert("Please select an image to upload.");
      return;
    }

    const file = fileList[0].originFileObj;

    // Check file size
    if (file.size > 7 * 1024 * 1024) {
      pushNotification("File size must be less than 7 MB.", "error");
      return;
    }
    let type;
    if (file.type.startsWith("image/")) {
      type = "image";
    } else if (file.type.startsWith("video/")) {
      type = "video";
    } else {
      pushNotification("Invalid file type. Please upload an image or video file.", "error");
      return;
    }

    const message = {
      createdAt: new Date(),
      _id: generateUUID(),
      user: {
        _id: userId,
        name: uuidMetaData.name,
      },
      type: type,
    };

    setSendMediaLoader(true);
    // Send the file using PubNub's sendFile method

    pubnub.sendFile(
      {
        channel: channel,
        message: message,

        file: {
          data: file, // Use the File object directly
          name: file.name,
          mimeType: file.type,
        },
      },
      (status, response) => {
        if (response) {
          onSuccess();
          console.log("Image sent successfully:", response);

          sendFileSignal(pubnub, channel);
          // SEND SIGNAL

          setSendMediaLoader(false);
        } else {
          console.error("Error sending image:", status);
          setSendMediaLoader(false);
        }
      },
    );
  };

  return (
    <>
      <AntDesignModal
        openModal={openUploadFileModal}
        closeModal={() => setOpenFileModal(false)}
        onCancel={() => setOpenFileModal(false)}
        onOk={() => setOpenFileModal(false)}
        width={450}
        title={"Media Upload"}
        style={{ top: 20 }}
        component={
          <div className="p-2">
            <Upload
              fileList={fileList}
              beforeUpload={beforeUpload}
              onChange={handleChange}
              maxCount={1}
              // accept=".jpg,.jpeg,.png"
              accept=".jpg,.jpeg,.png,.mp4,.mov,.avi"
              showUploadList={{ showPreviewIcon: true, showDownloadIcon: false }}
            >
              <Button icon={<PlusSIgn />}>Select File</Button>
            </Upload>
            {fileList.length > 0 && (
              <>
                {fileList[0].type.startsWith("image/") ? (
                  <img
                    src={URL.createObjectURL(fileList[0].originFileObj)}
                    alt="Preview"
                    style={{ maxWidth: "100%", marginTop: 20 }}
                  />
                ) : (
                  <div className="d-flex justify-content-center">
                    <video
                      controls
                      src={URL.createObjectURL(fileList[0].originFileObj)}
                      style={{ maxWidth: "90%", marginTop: 20, height: "400px" }}
                    />
                  </div>
                )}
              </>
            )}

            {/* Disable the button until the image is loaded */}
            <div>
              <Button
                onClick={pickImage}
                className="mt-3 d-flex justify-content-end m-auto"
                disabled={imageLoaded || fileList?.length === 0 || sendmediaSentLoader}
              >
                {sendmediaSentLoader ? "Uploading..." : "Upload"}
              </Button>
            </div>
          </div>
        }
        footer={null}
      />
    </>
  );
};

// NEW CHAT MODAALLL

export const NewChatMembersModal = ({
  addMemberModal,
  setOpenAddMemberModal,
  fetchingMemberLoader,
  getOnlyDms,
  allUsersFilter,
  getChatId,
  channelMembers,
  adminId,
  handleSendNewUserMessage,
  setInputFieldText,
  inputFieldText,
  adminName,
  onlyCompId,
  uuid,
  handleReadUnreadCount,
}) => {
  const [seaerchQuery, setSeaerchQuery] = useState("");
  const [selectedChatId, setSelectChatId] = useState("");
  const [chatState, setChatState] = useState(0);

  const [filteredUsers, setFilteredUsers] = useState([]);

  let pubnub = usePubNub();

  useEffect(() => {
    // Extract user IDs from getOnlyDms
    const extractUserIds = () => {
      return getOnlyDms
        ?.map((channel) => {
          const parts = channel.id.split(".");
          if (parts.length > 1 && parts[1].includes("_")) {
            return parts[1].split("-")[0]; // Extract the user ID part
          }
          return null;
        })
        .filter((id) => id !== null); // Filter out any null values
    };

    const dmUserIds = extractUserIds();

    // Filter users not in getOnlyDms
    const filterUsers = (allUsers, dmUserIds) => {
      return allUsers.filter((user) => !dmUserIds.includes(user.id) && user.id !== adminId);
    };

    const filtered = filterUsers(allUsersFilter, dmUserIds);
    setFilteredUsers(filtered);
  }, [getOnlyDms, allUsersFilter]);

  // let channelId = channelsDetails.id;

  // const addMember = async (userId) => {
  //   try {
  //     await pubnub.objects.setChannelMembers({
  //       channel: channelId,
  //       uuids: [userId],
  //     });
  //     pushNotification("Member added to this channel");
  //     // pubnub.signal({
  //     //   channel: channelId,
  //     //   message: {
  //     //     eventType: "channel_create", // Custom event type
  //     //   },
  //     //   callback: function (signalStatus) {
  //     //     console.error("Error sending signal:", signalStatus);
  //     //   },
  //     // });

  //     try {
  //       pubnub.signal({
  //         message: {
  //           eventType: "channel_create", // Custom event type
  //         },
  //         callback: function (signalStatus) {
  //           if (signalStatus.error) {
  //             console.error("Error sending signal:", signalStatus.errorData.message);
  //           } else {
  //             console.log("Signal sent successfully:", signalStatus);
  //           }
  //         },
  //       });
  //     } catch (error) {
  //       console.error("Error sending signal:", error);
  //     }
  //     fetchChannelMembers();

  //     // fetchMembers();
  //   } catch (error) {
  //     console.error("Error adding member:", error);
  //   }
  // };

  // const removeMember = async (userId) => {
  //   try {
  //     await pubnub.objects.removeChannelMembers({
  //       channel: channelId,
  //       uuids: [userId],
  //     });
  //     fetchChannelMembers();
  //     pushNotification("Member removed from this channel", "error");
  //     // try {
  //     //   pubnub.signal({
  //     //     channel: channelId,
  //     //     message: {
  //     //       eventType: "channel_create", // Custom event type
  //     //     },
  //     //     callback: function (signalStatus) {
  //     //       console.error("Error sending signal:", signalStatus);
  //     //       if (signalStatus.error) {
  //     //       } else {
  //     //         console.log("Signal sent successfully:", signalStatus);
  //     //       }
  //     //     },
  //     //   });
  //     // } catch (error) {
  //     //   console.error("CATCHHl:", error);
  //     // }
  //   } catch (error) {
  //     console.error("Error removing member:", error);
  //   }
  // };

  let userList = [];

  filteredUsers?.forEach((element) => {
    const isMember = channelMembers.some((nestElem) => element.id === nestElem.id);
    let role = "";

    if (element.id.startsWith("client")) {
      role = "Client";
    } else if (element.id.startsWith("staff")) {
      role = "Staff";
    } else if (element.id.startsWith("company")) {
      role = "Company Admin";
      if (element.custom?.role === "company_manager") {
        role = "Company manager";
      }
    }
    userList.push({ ...element, channel_member: isMember, role: role });
  });
  const filterUserBySearch = userList?.filter((item) => item?.name.toLowerCase().includes(seaerchQuery.toLowerCase()));

  const establishStaffAdminChannel = async () => {
    try {
      const userIds = [selectedChatId, adminId].sort();

      userIds.sort((a, b) => {
        if (a.startsWith("company_user_") && b.startsWith("company_admin_")) {
          return -1;
        } else if (a.startsWith("company_admin_") && b.startsWith("company_user_")) {
          return 1;
        }
        return 0;
      });

      const isStaff = selectedChatId.startsWith("staff_");

      let channelName = "";

      if (isStaff) {
        channelName = `direct.${selectedChatId}-${adminId}`;
      } else {
        channelName = `direct.${userIds[0]}-${userIds[1]}`;
      }

      const recieverData = {
        recieverId: selectedChatId,
        unreadCount: 1,
        senderId: adminId,
      };

      const updatedCustomData = {
        company_id: onlyCompId,
        owner: adminId,
        [adminId]: adminName,
        unreadCountData: JSON.stringify({
          reciever: {
            recieverId: selectedChatId,
            unreadCount: 1,
            senderId: adminId,
          },
        }),
      };

      await pubnub.objects.setChannelMetadata({
        channel: channelName,
        data: {
          name: adminName,
          description: "admin",
          custom: updatedCustomData,
        },
      });
      await pubnub.objects.setChannelMembers({ channel: channelName, uuids: [selectedChatId, uuid] });
      handleSendNewUserMessage(channelName);
      setOpenAddMemberModal();
    } catch (error) {
      console.log("NEW CHAT ERROR", error);
    }
  };
  return (
    <div>
      <AntDesignModal
        openModal={addMemberModal}
        closeModal={setOpenAddMemberModal}
        onCancel={setOpenAddMemberModal}
        onOk={setOpenAddMemberModal}
        width={600}
        title={<HeadingComponent text={"New Chat"} fontSize="18px" fontWeight={700} color="#212121" />}
        component={
          <>
            <div style={{ height: chatState === 0 ? "50vh" : "15vh", overflow: "auto" }}>
              {chatState === 0 ? (
                <>
                  <SearchInput placeholder="Type to search" search={seaerchQuery} onChange={(value) => setSeaerchQuery(value)} />
                  {filterUserBySearch?.map((items, index) => {
                    return (
                      <div className="my-3" key={index}>
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="d-flex align-items-center ">
                            <Avatar>{items.name?.slice(0, 2)}</Avatar>
                            <HeadingComponent
                              text={`${items.name} `}
                              fontSize="16px"
                              fontWeight={500}
                              color="#212121"
                              margin="0px 10px"
                            />
                            <HeadingComponent
                              text={`( ${items.role} )`}
                              fontSize="14px"
                              fontWeight={400}
                              color="#212121"
                              margin="0px 10px"
                            />
                          </div>
                          <div>
                            <CustomBtnWithIcon
                              btnText={"Chat "}
                              color="#E53939"
                              noBackground
                              hideIcon
                              className="ml-auto"
                              onClick={() => {
                                setSelectChatId(items.id);

                                setChatState(1);
                              }}
                              no
                              disabled={fetchingMemberLoader}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </>
              ) : (
                <>
                  <div style={{ marginBottom: "10px" }}>
                    <HeadingComponent
                      fontSize={"14px"}
                      text={" Start a chat with this member by sending a message below."}
                      fontWeight={400}
                    />
                  </div>
                  <div className="d-flex mt-5 ">
                    <Input.TextArea
                      type="text"
                      placeholder="Enter Message"
                      value={inputFieldText}
                      style={{
                        height: "38px",
                        padding: 5, // Remove padding
                        resize: "none", // Disable resizing
                        border: "none", // Remove border
                        outline: "none", // Remove outline
                        boxShadow: "none", // Remove box shadow
                      }}
                      onChange={(e) => setInputFieldText(e.target.value)}
                      onKeyPress={(e) => {
                        if (e.key === "Enter" && !e.shiftKey) {
                          e.preventDefault(); // Prevent default behavior of Enter key
                          establishStaffAdminChannel();
                        }
                      }}
                      // onFocus={() => handleReadUnreadCount(chatterId, true, false)}
                    />
                    <Tooltip title="Send Direct Message">
                      <div
                        style={{
                          height: 40,
                          width: 40,
                          backgroundColor: "rgb(251, 207, 52)",
                          borderRadius: "10px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          cursor: "pointer",
                        }}
                        className="ms-1"
                        onClick={establishStaffAdminChannel}
                      >
                        <SendMsgIcon />
                      </div>
                    </Tooltip>
                  </div>
                </>
              )}
            </div>
            {chatState === 1 && (
              <div className="d-flex justify-content-end">
                <CustomBtnWithIcon
                  btnText={"Back"}
                  hideIcon
                  onClick={() => {
                    setSelectChatId("");
                    setChatState(0);
                  }}
                />
              </div>
            )}
          </>
        }
        footer={null}
      />
    </div>
  );
};
