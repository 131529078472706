import React, { useState } from "react";
import { CustomModal, FormItem, FieldWraper, ButtonWrapper } from "./modal.style";
import { Checkbox, Divider, Form, Select } from "antd";

import { Field, Formik } from "formik";
import CustomBtnWithIcon from "../../../../../../components/buttonWithIcon";
import { initialValues, propertiesOptions, typesOptions, validationSchema } from "./modal.utils";
import FormikInputFloating from "../../../../../../components/formikInputs/TextInput";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { updateCompanyGroups } from "../../../../../../redux/platformSlices/CompanyDetail";
import FormikSelectInput from "../../../../../../components/formikInputs/SelectInput";

const CustomSelect = ({ field, form, options, label, ...props }) => {
  return (
    <FormItem
      label={label}
      validateStatus={form.errors[field.name] && form.touched[field.name] ? "error" : ""}
      help={form.errors[field.name] && form.touched[field.name] ? form.errors[field.name] : null}
      className="mb-2"
    >
      <Select
        {...field}
        style={{ marginBottom: "5px" }}
        {...props}
        onChange={(value) => form.setFieldValue(field.name, value)}
        options={options}
      />
    </FormItem>
  );
};

const CustomCheckbox = ({ label, form, field, ...props }) => {
  return (
    <FormItem className="mb-2 mt-2">
      <Checkbox
        {...field}
        checked={field.value}
        onChange={(e) => {
          form.setFieldValue(field.name, e.target.checked);
          if (e.target.checked === false) {
            form.setFieldValue("group", null);
          }
        }}
        {...props}
      >
        {label}
      </Checkbox>
    </FormItem>
  );
};

const AddFieldModal = ({ selectedTab, isModalOpen, setModalOpen, isEditMode = false, groupIndex, field = {}, group = {} }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [options, setOptions] = useState([]);
  const details = useSelector((state) => state.companyDetail.companyDetailResponse);

  const defaultProperties = { client_editable: false, client_visible: false, staff_visible: false, required: false };

  const clientGroups =
    details?.result?.client_custom_fields?.groups?.length > 0
      ? details.result.client_custom_fields.groups
      : [{ name: null, fields: [] }];

  const petsGroups =
    details?.result?.pet_custom_fields?.groups?.length > 0
      ? details.result.pet_custom_fields.groups
      : [{ name: null, fields: [] }];

  const groups = selectedTab === "ClientField" ? clientGroups : petsGroups;

  const groupOptions = groups.map((group) => ({ value: group.name, label: group.name })).filter((group) => group.value !== null);
  const auth = useSelector((state) => state.auth);
  const companyId = auth.loginResponse?.selectCompany?.[0]?.company_id || null;

  const addNewField = async (formValues, resetForm) => {
    const updatedProperties = { ...defaultProperties };
    formValues.properties.forEach((property) => {
      if (property in updatedProperties) {
        updatedProperties[property] = true;
      }
    });

    const newField = {
      properties: updatedProperties,
      field_name: formValues.field_name,
      field_type: formValues.field_type,
      preview_text: formValues.preview_text,
      options,
    };

    const updatedGroups = groups.map((group) => ({
      ...group,
      fields: group.fields.map((field) => ({ ...field })),
    }));

    const groupName = formValues.addToGroup ? formValues.group : null;
    const updatedGroupIndex = updatedGroups.findIndex((group) => group.name === groupName);
    if (updatedGroupIndex > -1) {
      const fieldIndex = updatedGroups[updatedGroupIndex].fields.findIndex((gField) => gField.id === field.id);
      if (fieldIndex > -1) {
        updatedGroups[groupIndex].fields[fieldIndex] = {
          ...updatedGroups[groupIndex].fields[fieldIndex],
          ...newField,
        };
      } else {
        if (groupIndex > -1) {
          const fieldIndex = updatedGroups[groupIndex].fields.findIndex((gField) => gField.id === field.id);
          updatedGroups[groupIndex].fields.splice(fieldIndex, 1);
        }
        updatedGroups[updatedGroupIndex].fields.push(newField);
      }
    }

    const customFields = {
      groups: updatedGroups,
    };

    const data = selectedTab === "ClientField" ? { client_custom_fields: customFields } : { pet_custom_fields: customFields };
    await dispatch(updateCompanyGroups(companyId, data));
    setModalOpen(false);
    resetForm();
  };

  const editFieldProperties = field?.properties ? Object.keys(field?.properties)?.filter((key) => field?.properties[key]) : [];
  const editFields = {
    ...field,
    properties: editFieldProperties,
  };

  return (
    <CustomModal
      open={isModalOpen}
      title={isEditMode ? "Edit a Field" : "Add a new Field"}
      width="600px"
      onCancel={() => {
        setModalOpen(false);
      }}
      footer={null}
      maskClosable={false}
    >
      <Divider />

      <Formik
        initialValues={
          isEditMode
            ? { ...editFields, addToGroup: group?.name ? true : false, group: group?.name }
            : { ...initialValues, addToGroup: group?.name ? true : false, group: group?.name }
        }
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => {
          addNewField(values, resetForm);
        }}
      >
        {({ handleSubmit, values, resetForm }) => (
          <Form onFinish={handleSubmit} layout="vertical">
            <FormikInputFloating label="Name" placeHolder="Enter Name" name="field_name" type="text" className="w-100 my-2" />
            <FieldWraper>
              <Field name="field_type" label="Type" component={CustomSelect} options={typesOptions} placeholder="Select Type" />
              {values.field_type === "dropdown" && (
                <FormikSelectInput
                  mode="tags"
                  label="Options"
                  style={{ width: "100%", maxHeight: "50px", overflow: "auto" }}
                  placeholder="Enter Options"
                  name="options"
                  defaultValue={
                    options.map((option) => {
                      return {
                        value: option,
                        label: option,
                      };
                    }) || []
                  }
                  notFoundContent={null}
                  onChange={(option) => {
                    setOptions(option);
                  }}
                  value={options}
                  className=" w-100"
                />
              )}
            </FieldWraper>
            <FormikInputFloating
              label="Preview Text"
              placeHolder="Type any hint text"
              name="preview_text"
              type="text"
              className="w-100 my-2"
            />

            <Field
              name="properties"
              label="Properties"
              component={CustomSelect}
              placeholder="Select Multiple"
              options={propertiesOptions}
              mode="multiple"
            />

            <Field name="addToGroup" label="Add to a group ?" component={CustomCheckbox} placeholder="Select Multiple" />

            {values.addToGroup && (
              <Field name="group" label="Group" component={CustomSelect} options={groupOptions} placeholder="Select Group" />
            )}

            <Divider className="mb-2" />
            <ButtonWrapper>
              <CustomBtnWithIcon
                btnText="Cancel"
                margin="0px 5px"
                noBackground
                hideIcon={true}
                type="button"
                onClick={() => {
                  setModalOpen(false);
                  resetForm();
                }}
              />
              <CustomBtnWithIcon btnText="Save Changes" type="submit" hideIcon={true} />
            </ButtonWrapper>
          </Form>
        )}
      </Formik>
    </CustomModal>
  );
};

export default AddFieldModal;
