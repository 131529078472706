import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import axiosInstance from "../../util/AxiosConfig";
import { companyAPIsEndPoints } from "../../constants/ApiEndPoints";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: "",
  loginResponse: {},
};

const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginUserSlice(state, action) {
      state.isLoading = action.payload.isLoading;
      state.error = action.payload.error;
      state.loginResponse = action.payload;
    },
  },
});

export const { loginUserSlice } = slice.actions;
// Reducer
export default slice.reducer;

export function loginUser(formValues) {
  return async (dispatch, getState) => {
    try {
      dispatch(slice.actions.loginUserSlice({ isLoading: true, error: "", loginResponse: {} }));
      const { data } = await axiosInstance.post(companyAPIsEndPoints.COMPANY_LOGIN, formValues);
      dispatch(slice.actions.loginUserSlice({ isLoading: false, error: "", loginResponse: data.result }));
    } catch (error) {
      dispatch(slice.actions.loginUserSlice({ isLoading: false, error: error.message, loginResponse: {} }));
    }
  };
}
