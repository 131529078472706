import {replaceDateFormat} from '../../util/formatDate'
import { Tag } from "antd";

const ChangeRequestColumens = ({}) => {

  let columns = [
    {
      title: "Service",
      dataIndex: "service",
      key: "service",

      render: (text, record) => (
        <>
          {record.service && record.service.name ? <Tag style={tagStyle}>{record.service.name}</Tag>  : ''}
        </>
      ),
    },
    {
      title: "Dates",
      dataIndex: "date",
      key: "date",

      render: (text, record) => (
        <>
          {record?.date && replaceDateFormat(record?.date)} 
        </>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => (
        <>
          <Tag style={{...tagStyle, backgroundColor: '#FFF3D3', color: '#B45309'}}>Pending Approval</Tag>
        </>
      ),
    },
  ];

  return columns;
};

export {
    ChangeRequestColumens,
};

const tagStyle = {
  backgroundColor: "#EFF1F5",
  fontWeight: 500,
  border: "none",
  borderRadius: "28px",
  padding: "2px 8px 2px 6px",
  color: "#023047"
};