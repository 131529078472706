import { createSlice } from "@reduxjs/toolkit";
import { main_api } from "../../api/axios_helper";
import { companyAPIsEndPoints } from "../../constants/ApiEndPoints";

const initialState = {
  schedulerResponse: {
    isLoading: false,
    responseData: [],
  },
  schedulerPendingRequestResponse: {
    isLoading: false,
    responseData: [],
    nestedLevelObject: [],
  },
  schedulerTemplatesResponse: {
    isLoading: false,
    responseData: [],
    nestedLevelObject: [],
  },
  unavaliableSlotsResponse: {
    isLoading: false,
    responseData: [],
  },
};

const slice = createSlice({
  name: "scheduler",
  initialState,
  reducers: {
    fetchSchedulerServicces(state, action) {
      state.schedulerResponse.isLoading = action.payload.isLoading;
      if (action.payload?.responseData) {
        state.schedulerResponse.responseData = action.payload?.responseData;
      }
    },
    fetchSchedulerPendingRequests(state, action) {
      state.schedulerPendingRequestResponse.isLoading = action.payload.isLoading;
      if (action.payload?.responseData) {
        const getInsideObject = action.payload?.responseData?.result
          ?.flatMap(({ service_request_group_one_day_requests }) => {
            return service_request_group_one_day_requests?.flatMap(({ one_day_service_request_single_services }) => {
              return one_day_service_request_single_services || [];
            });
          })
          .filter(Boolean);
        state.schedulerPendingRequestResponse.responseData = action.payload?.responseData || [];
        state.schedulerPendingRequestResponse.nestedLevelObject = getInsideObject || [];
      }
    },
    fetchSchedulerTemplatesSlice(state, action) {
      state.schedulerTemplatesResponse.isLoading = action.payload.isLoading;
      if (action.payload?.responseData) {
        state.schedulerTemplatesResponse.responseData = action.payload?.responseData;
      }
    },
    fetchUnavailableSlotsSlice(state, action) {
      state.unavaliableSlotsResponse.isLoading = action.payload.isLoading;
      if (action.payload?.responseData) {
        state.unavaliableSlotsResponse.responseData = action.payload?.responseData;
      }
    },
  },
});

export const {
  fetchSchedulerServicces,
  fetchSchedulerPendingRequests,
  fetchSchedulerTemplatesSlice,
  fetchUnavailableSlotsSlice,
} = slice.actions;

export default slice.reducer;

export const fetchSchedulerServiceApi =
  (clientQuery = "", statusQuery = "", startDate = "", endDate = "") =>
  async (dispatch, getState) => {
    try {
      const companyId = getState().auth?.loginResponse?.selectCompany?.[0]?.company_id;
      dispatch(fetchSchedulerServicces({ isLoading: true }));
      const { data } = await main_api.get(
        companyAPIsEndPoints.FETCH_SCHEDULER_LIST(companyId, clientQuery, statusQuery, startDate, endDate),
      );
      dispatch(fetchSchedulerServicces({ isLoading: false, error: false, responseData: data }));
    } catch (error) {
      dispatch(fetchSchedulerServicces({ isLoading: false, error: error }));
    }
  };

export const fetchSchedulerPendingREquestApi = () => async (dispatch, getState) => {
  try {
    const companyId = getState().auth?.loginResponse?.selectCompany?.[0]?.company_id;
    dispatch(fetchSchedulerPendingRequests({ isLoading: true }));
    const { data } = await main_api.get(companyAPIsEndPoints.FETCH_PENDING_REQUEST_LIST(companyId));

    dispatch(fetchSchedulerPendingRequests({ isLoading: false, error: false, responseData: data }));
  } catch (error) {
    dispatch(fetchSchedulerPendingRequests({ isLoading: false, error: error }));
  }
};

export const fetchSchedulerTemplatesApi = () => async (dispatch, getState) => {
  try {
    const companyId = getState().auth?.loginResponse?.selectCompany?.[0]?.company_id;
    dispatch(fetchSchedulerTemplatesSlice({ isLoading: true }));
    const { data } = await main_api.get(companyAPIsEndPoints.FETCH_TEMPLATES(companyId));

    dispatch(fetchSchedulerTemplatesSlice({ isLoading: false, error: false, responseData: data }));
  } catch (error) {
    dispatch(fetchSchedulerTemplatesSlice({ isLoading: false, error: error }));
  }
};

export const fetchUnavaialbleSlotsApi = (payload) => async (dispatch) => {
  try {
    dispatch(fetchUnavailableSlotsSlice({ isLoading: true }));
    const { data } = await main_api.post(companyAPIsEndPoints.UNAVAIABLE_SLOTS(), payload);
    dispatch(fetchUnavailableSlotsSlice({ isLoading: false, error: false, responseData: data }));
    return data;
  } catch (error) {
    dispatch(fetchUnavailableSlotsSlice({ isLoading: false, error: error }));
  }
};
