const Possession = [
  {
    value: "client",
    label: "Client",
  },
  {
    value: "staff",
    label: "Staff",
  },
  {
    value: "office",
    label: "At Office",
  },
];

export default Possession;



export const KeyType = [
  {
    value: "digital",
    label: "Digital",
  },
  {
    value: "physical",
    label: "Physical",
  }
];

