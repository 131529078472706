import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  permissions: [],
  role: null
};

const permissionManagement = createSlice({
  name: "permissionManagement",
  initialState,
  reducers: {
    fetchSingleRole(state, action) {
      if (action.payload) {
        state.permissions = action.payload.permissions;
        delete action.payload.permissions;
        state.role = action.payload;
      } else{
        state.permissions = [];
        state.role = null
      }
    },
  },
});

// Reducer
export default permissionManagement.reducer;
export const { fetchSingleRole } = permissionManagement.actions;