import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
    company_name: Yup.string().required("Company name is required"),
    company_email: Yup.string().email("Invalid email format").required("Company email is required"),
    company_website: Yup.string().required("Company website is required"),
    address: Yup.string().required("Address is required"),
    zip_code: Yup.string().required("Zip code is required"),
    phone_number: Yup.string().required("Phone number is required"),
    state: Yup.string().required("State is required"),
});
