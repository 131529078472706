import React, { useEffect } from "react";
import styled from "styled-components";
import Service from "./Service";
import Holiday from "./Holiday";
import AntDesignNavTabs from "../../../components/antDesignNaviTabs/AntDesignNavTabs";
import { serviceHolidaysTabs } from "../../../constants/CompanyNavTabs/ServiceHolidaysTabs";

import { checkPermission } from "../../../util/commonUtil";

//
function Services({
  fetchAllServices,
  serviceState,
  serviceStateLoader,
  serviceStateResponseData,
  loginState,
  holidayStateResponseData,
  holidayStateLoader,
  fetchAllHolidays,
  fetchServiceGrouping,
  serviceGroups,
  permissionState,
}) {
  // const [activeTabItem, setActiveTabItem] = useState(tabItems[0]);

  useEffect(() => {
    fetchServiceGrouping();
  }, []);

  useEffect(() => {
    if (checkPermission(permissionState, "view_services")) {
      fetchAllServices();
    }
    if (checkPermission(permissionState, "view_holidays")) {
      fetchAllHolidays("");
    }
  }, [permissionState]);

  const handleChangetabs = (val) => {};
  const tabItems = serviceHolidaysTabs(
    <Service
      fetchAllServices={fetchAllServices}
      serviceResponse={serviceStateResponseData}
      companyId={loginState?.selectCompany?.[0]?.company_id}
      serviceList={serviceState?.serviceGetResponse?.serviceList}
      serviceGroups={serviceGroups?.result}
      fetchServiceGroups={fetchServiceGrouping}
      serviceStateLoader={serviceStateLoader}
    />,
    <Holiday
      companyId={loginState?.selectCompany?.[0]?.company_id}
      fetchAllHolidays={fetchAllHolidays}
      holidayStateResponseData={holidayStateResponseData}
    />,
  );
  const activeTabs = {};
  return (
    <>
      <NavigationHeading>Services</NavigationHeading>

      <AntDesignNavTabs navTabs={tabItems} onChange={handleChangetabs} />
    </>
  );
}

const NavigationHeading = styled.h2`
  display: flex;
  margin: 25px 0px 10px 0px;
  font-size: 20px;
  font-weight: 700;
`;
export default Services;
