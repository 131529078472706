import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import Dropdown from "react-bootstrap/Dropdown";
// import MessageIcon from "../assets/icons/MessageIcon";

import { ReactComponent as HeaderBellIcon } from "../assets/companySvgIcons/headerBellIcon.svg";
import { ReactComponent as HeaderMsgIcon } from "../assets/companySvgIcons/headertopNavMsgIcon.svg";
import { ReactComponent as HeaderQuestionMarkIcon } from "../assets/companySvgIcons/headerQuestionMarkIco.svg";
import { ReactComponent as LogoutIcon } from "../assets/rawSvgs/logouticon.svg";

import { ReactComponent as LegalAgreeIcon } from "../assets/rawSvgs/legalAgreIcon.svg";

import { Badge } from "antd";
import Avatar from "react-avatar";
import { ReactComponent as SettingsIcon } from "../assets/companySvgIcons/sidebarIcons/SettingsGearIcon.svg";

import HeadingComponent from "./Heading";

import CompanyWelcomeModal from "./Modals/ComapnyWelcomeModal/CompanyWelcomeModal";
import LegalAgreementModal from "./Modals/LegalAgreementModal";
import { main_api } from "../api/axios_helper";
import { companyAPIsEndPoints, platformAPIsEndPoints } from "../constants/ApiEndPoints";
import { useSelector } from "react-redux";
import AppNotification from "./AppNotification";
import { logOut } from "../util/commonUtil";
import AccountSettingsModal from "./Modals/AccountSettingsModal/accountSettingsModal";
import useTheme from "./Theme/useTheme";
import { logoutPubNub } from "../patterns/InitializePubnub";
import { pushNotification } from "../util/Notification";
import { useDispatch } from "react-redux";
import { setUnreadCounts, setUnreadCountsClient } from "../redux/slices/GeneralAppSlice/AppSlice";

function HeaderNav() {
  let navigate = useNavigate();
  let dispatch = useDispatch();
  const [isLegalAgreementModalOpen, setIsLegalAgreementModalOpen] = useState(false);
  const [isAccountSettingsModalOpen, setIsAccountSettingsModalOpen] = useState(false);
  const dropdownRef = useRef(null);
  const notificationDropdownRef = useRef(null);
  let pathName = useLocation().pathname;
  const notificationManagement = useSelector((state) => state.notificationManagement);
  const auth = useSelector((state) => state.auth);
  const [onboardingData, setOnboardingData] = useState(null);
  const [showNotification, setShowNotification] = useState(false);
  const [compThemeSettings, setCompThemeSettings] = useState(undefined);
  const theme = useTheme();

  let isPlatformAdmin = auth?.loginResponse?.is_platform_admin || false;
  let isClientPortal = auth?.loginResponse?.selectedRoleType === "client_type" ? true : false;

  const profileMenus = [
    {
      icon: <SettingsIcon />,
      name: "Account Settings",
      NavTo: "",
      onClick: () => setIsAccountSettingsModalOpen(true),
    },
    // {
    //   icon: <MessageIcon />,
    //   name: "FAQs",
    //   NavTo: "",
    // },
    // {
    //   icon: <ContactusIcon />,
    //   name: "Contact Us",
    //   NavTo: "",
    // },
    {
      icon: <LegalAgreeIcon />,
      name: "Legal Agreement",
      NavTo: "",
      onClick: () => setIsLegalAgreementModalOpen(true),
    },
    // {
    //   icon: <SupportIcon />,
    //   name: "Support/Feedback",
    //   NavTo: "",
    // },
  ];

  const closeModal = () => {
    setIsOnboardingModalVisible(false);
  };
  const [isOnboardingModalVisible, setIsOnboardingModalVisible] = useState(false);

  const getThemeSettings = () => {
    const id = 1;
    main_api
      .get(platformAPIsEndPoints.FETCH_ONBOARDING_THEME_SETTINGS(id))
      .then(({ data }) => {
        setCompThemeSettings(data?.result);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getThemeSettings();
  }, []);

  useEffect(() => {
    if (
      auth?.loginResponse?.selectedRoleType === "company_type" ||
      auth?.loginResponse?.selectedRoleType === "company_user_type"
    ) {
      const companyId = auth?.loginResponse?.selectCompany?.[0]?.company_id;
      main_api
        .get(companyAPIsEndPoints.ONBOARDING_STATUS(companyId))
        .then(({ data }) => {
          setIsOnboardingModalVisible(!data.result.is_seen);
          setOnboardingData(data.result);
        })
        .catch((error) => {
          pushNotification("Something went wrong!", "error");
          // console.log(error);
        });
    }
  }, []);
  const handleDropdownItemClick = (event) => {
    event.preventDefault();
    if (dropdownRef.current) {
      dropdownRef.current.blur();
    }
  };

  const onToggle = (toggle) => {
    setShowNotification(toggle);
  };

  useEffect(() => {
    // Add event listener to handle outside clicks
    const handleClickOutside = (event) => {
      if (notificationDropdownRef.current && !notificationDropdownRef.current.contains(event.target)) {
        // Clicked outside of the notification dropdown, close the notification
        setShowNotification(false);
      }
    };

    // Attach the event listener when the component mounts
    document.addEventListener("click", handleClickOutside);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  let isCommp = auth?.loginResponse?.selectCompany?.[0]?.role;
  let clientName = auth?.loginResponse?.LoggedInUserData?.name;

  return (
    <>
      {isLegalAgreementModalOpen && (
        <LegalAgreementModal isModalOpen={isLegalAgreementModalOpen} setModalOpen={setIsLegalAgreementModalOpen} />
      )}
      {isAccountSettingsModalOpen && (
        <AccountSettingsModal isModalOpen={isAccountSettingsModalOpen} setModalOpen={setIsAccountSettingsModalOpen} />
      )}
      <HeaderContainer style={{ background: `${theme.headerBackground && isClientPortal ? theme.headerBackground : "white"}` }}>
        {isOnboardingModalVisible && (
          <CompanyWelcomeModal
            setOnboardingData={setOnboardingData}
            onboardingData={onboardingData}
            title={"Add Flag"}
            isModalOpen={isOnboardingModalVisible}
            setModalOpen={closeModal}
          />
        )}

        <PageTitle style={{ color: `${theme.headerTitle ? theme.headerTitle : "black"}` }}>
          {pathName.includes("invoicxing")
            ? "Invoice"
            : pathName.includes("serxvices")
              ? "Services and Holidays"
              : pathName.includes("company/mexssage")
                ? "Messages"
                : ""}
        </PageTitle>
        <HeaderRightContainer>
          {/* <SearchContainer>
          <LogoSearch>{SearchIcon}</LogoSearch>
          <Search placeholder="Search anything here" />
        </SearchContainer> */}
          {/* <NotificationContainer>
          <Logo>{TaskIcon}</Logo>
        </NotificationContainer> */}
          {/* <NotificationContainer>
            <HeaderBellIcon />

          </NotificationContainer> */}
          <Dropdown ref={notificationDropdownRef} onToggle={onToggle}>
            <Dropdown.Toggle
              variant="success"
              id="dropdown-basic-1"
              style={{
                background: "none",
                border: "none",
                padding: 0,
                marginRight: "-12px",
                outline: "none",
                position: "relative",
              }}
            >
              <Badge count={notificationManagement.unreadCount} offset={[-5, 10]}>
                <HeaderBellIcon />
              </Badge>
            </Dropdown.Toggle>
            <div
              style={{
                border: "1px solid #E0E0E0",
                borderRadius: "10px",
                boxShadow: "rgb(0 0 0 / 41%) 1px 1px 7px -3px",
                position: "absolute",
                right: "0",
                backgroundColor: "white",
              }}
            >
              {showNotification && <AppNotification />}
            </div>
          </Dropdown>
          {!isPlatformAdmin && (
            <NotificationContainer
              onClick={() => {
                if (isClientPortal) {
                  navigate("/client/message");
                  return;
                }
                navigate("/company/message");
              }}
            >
              <HeaderMsgIcon />
            </NotificationContainer>
          )}
          <NotificationContainer>
            <HeaderQuestionMarkIcon />
          </NotificationContainer>

          <Dropdown ref={dropdownRef}>
            <Dropdown.Toggle
              variant="success"
              id="dropdown-basic"
              style={{ background: "none", border: "none", padding: 0, marginRight: "-12px", outline: "none" }}
            >
              {/* <UserAvatar src={require("../assets/avatar/avatar-1.png")} alt="User Avatar" /> */}
              <Avatar
                name={isCommp === "Client" ? clientName : auth?.loginResponse?.selectCompany?.[0]?.company || "P A"}
                round={true}
                src={compThemeSettings?.company_logo}
                size="33"
                textSizeRatio={1.5}
              />
            </Dropdown.Toggle>

            <Dropdown.Menu
              style={{
                border: "1px solid #E0E0E0",
                borderRadius: "10px",
                boxShadow: "rgb(0 0 0 / 41%) 1px 1px 7px -3px",
                position: "fixed",
                background: `${theme.headerBackground ? theme.headerBackground : "white"}`,
              }}
            >
              {profileMenus?.map((items, index) => {
                const isAccountSettings = items.name === "Account Settings";
                return (
                  <div
                    onClick={(event) => {
                      handleDropdownItemClick(event);
                      items.onClick && items.onClick();
                    }}
                    style={{
                      margin: isAccountSettings ? "13px -2px" : "13px 0px",
                      minWidth: "220px",
                      display: "flex",
                      alignItems: "center",
                      padding: "0px 12px",
                      cursor: "pointer",
                    }}
                    key={index}
                  >
                    <HeadingComponent
                      text={
                        <>
                          {items.icon}
                          &nbsp;&nbsp;
                          {items.name}
                        </>
                      }
                      fontSize="14px"
                      fontWeight={400}
                      color={`${theme.headerTitle ? theme.headerTitle : "black"}`}
                    />
                  </div>
                );
              })}

              <div
                style={{ borderTop: "1px solid #E0E0E0", padding: "10px 12px 0px 12px", cursor: "pointer" }}
                onClick={() => {
                  logOut();
                  logoutPubNub();
                  dispatch(setUnreadCounts([]));
                  dispatch(setUnreadCountsClient([]));
                  if (isPlatformAdmin) {
                    navigate("/platform/signin");
                    return;
                  }
                  navigate("/company/signin");
                  // window.location.reload();
                }}
              >
                <HeadingComponent
                  text={
                    <>
                      <LogoutIcon style={{ color: "red" }} />
                      &nbsp;&nbsp; Logout
                    </>
                  }
                  color="red"
                  fontSize="14px"
                  margin={"3px 0px 3px 0px"}
                  fontWeight={400}
                />
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </HeaderRightContainer>
      </HeaderContainer>
    </>
  );
}

const HeaderContainer = styled.div`
  width: 100%;
  height: 60px;
  padding: 5px 32px;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  border-bottom: 1px solid #e4eaf0;
  position: fixed;
  background-color: white;
  z-index: 100;
`;

const HeaderRightContainer = styled.div`
  display: flex;
  height: 44px;

  align-items: center;
`;

const NotificationContainer = styled.div`
  height: 100%;
  width: 48px;
  border-radius: 8px;
  box-sizing: border-box;
  margin-right: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const PageTitle = styled.h1`
  font-size: 20px;
  font-weight: 700;
  color: #414d55;
`;

export default HeaderNav;
