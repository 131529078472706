import React from 'react';
import styled from 'styled-components';
import SubscriptionPlan from '../../payment/paymentScreens/subscriptionPlan';

const PlatformPayment = () => {

  return (
    <>
      <NavigationHeading>Subscription Plans</NavigationHeading>
      <SubscriptionPlan platform={true}/>
    </>
  );
};

const NavigationHeading = styled.h2`
  display: flex;
  margin: 25px 0px 10px 0px;
  font-size: 20px;
  font-weight: 700;
`;

export default PlatformPayment;
