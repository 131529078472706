import React, { useEffect, useState } from "react"
import { Tabs } from 'antd';
import styled from "styled-components";
import ScheduleServiceRequest from "./ScheduleServiceRequest";
import { ReportingContainer, ReportingItem } from "./ReportDetail";

function ScheduleReport({saveReport, setCurrentReport, setSaveReport, currentReport, saveReportCallBack}) {

  const scheduleFilters = [
    {label: 'Pending Requests', id: 0,  value: 'pending_requests'}, 
    {label: 'Pending Request Volume',  id: 1, value: 'pending_requests_volume'}, 
    {label: 'Cancelled Visits',  id: 2, value: 'cancelled_visits'},
    {label: 'Overdue Events',  id: 3, value: 'overdue_events'},
    {label: 'Visits',  id: 4, value: 'visits'}
  ]

  const [activeTab, setActiveTab] = useState(0);

  const handleChangeTabs = (tabs) => {
    setActiveTab(tabs);
  };

  return (
    
    <>
    <ReportingContainer>
        <ReportingItem>
            <TabHeader>Pending Requests</TabHeader>
            <TabItem
                activeKey={activeTab}
                onChange={handleChangeTabs}
                tabPosition="left"
                items={scheduleFilters.slice(0,2).map((val, i) => {
                    return {
                        label: `${val.label}`,
                        key: val.id,
                    };
                })}
            >
            </TabItem>
            <TabHeader>Scheduled Visits</TabHeader>
            <TabItem
                activeKey={activeTab}
                onChange={handleChangeTabs}
                tabPosition="left"
                items={scheduleFilters.slice(2,5).map((val, i) => {
                    return {
                        label: `${val.label}`,
                        key: val.id,
                    };
                })}
            >
            </TabItem>
        </ReportingItem>
        <FilterDetail>
          <ScheduleServiceRequest saveReportCallBack={saveReportCallBack} saveReport={saveReport} setSaveReport={setSaveReport} filter={scheduleFilters[activeTab].value} currentReport={currentReport} setCurrentReport={setCurrentReport}/>,
        </FilterDetail>    
    </ReportingContainer>
    </>
  )
}

export default ScheduleReport;

const TabHeader = styled.div`
    color: #424242;
    font-weight: 700;
    margin-top: 5px;
`;

const TabItem = styled(Tabs)`
    margin-top: 20px;
    .ant-tabs-tab-active{
        background-color: #EEEEEE;
    }
    .ant-tabs-content-holder{
       border-left: none;
    }
    .ant-tabs-tab-active .ant-tabs-tab-btn{
        color: #424242 !important;
    }
    .ant-tabs-tab {
        color: #424242 !important;
    }
    .ant-tabs-tab-btn{
        color: #424242 !important;
    }
    .ant-tabs-nav-operations {
        display: none !important; 
    }

    .ant-tabs-nav{
      width: 100%;
      min-width: 50px;
    }
`;

const FilterDetail = styled.div`
  width: 100%;
  margin: 0 20px;
`;
