import styled from "styled-components";

export const ImageBackground = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  background: linear-gradient(248.64deg, #023047 26.05%, #000000 97.94%);
`;

export const ImageContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
  max-width: 480px;
  max-height: 636px;
  margin: auto;
`;

export const CircularImage = styled.div`
  position: relative;
  overflow: hidden;
  z-index: 1;
  border-radius: 100px;
  width: 140px;
  height: 140px;
`;

export const HorizontalImage = styled.div`
  position: relative;
  overflow: hidden;
  z-index: 1;
  border-radius: 100px;
  width: 306px;
  height: 144px;
`;

export const VerticalImage = styled.div`
  position: relative;
  overflow: hidden;
  z-index: 1;
  border-radius: 100px;
  width: 140px;
  height: 296px;
`;

export const Flex = styled.div`
  display: flex;
  flex-direction: ${(props) => props.flexDirection ?? "row"};
  justify-content: space-between;
  width: 100%;
`;

export const FlexedInput = styled.div`
  width: 100%;
`;
