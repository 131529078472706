import ClientScheduler from "../../pages/client/scheduler/Scheduler";
import { connect } from "react-redux";
import { fetchSchedulerServiceApi } from "../../redux/slices/schedulerSlice";

const mapStateToProps = (state) => {
  return {
    schedulerState: state?.scheduler.schedulerResponse?.responseData,
    permissionState: state.permissionManagement.permissions
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchAllSchedulerService: (clientQuery='', statusQuery='', startDate='', endDate='') => {
    dispatch(fetchSchedulerServiceApi(clientQuery, statusQuery, startDate, endDate));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(ClientScheduler);
