import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { main_api } from "../../../../api/axios_helper";
import { companyAPIsEndPoints } from "../../../../constants/ApiEndPoints";
import { useSelector } from "react-redux";

import { ReactComponent as EditIcon } from "../../../../assets/rawSvgs/tableEditIcon.svg";
import { ReactComponent as DeleteIcon } from "../../../../assets/rawSvgs/tableDeleteIcon.svg";

import NoDataScreen from "../../../../components/NoDataScreen";
import CustomBtnWithIcon from "../../../../components/buttonWithIcon";
import { checkPermission } from "../../../../util/commonUtil";
import { AntdesignTablePagination } from "../../../../components/antDesignTable/AntdesignTablePagination";
import SearchInput from "../../../../components/SearchInput";
import { Button, Form, Input, Space, TimePicker } from "antd";
import dayjs from "dayjs";
import { CustomModal } from "../../../../components/Modals/ModalStyles";
// import { convertTo12HourFormat } from "../../../../util/formatTime";
import { convertTo24HourFormat } from "../../sheduler/pendingRequest/utilsFunctions";
import DeleteConfirmationRowModal from "../../../../components/Modals/DeleteConfirmationRowModal";
import { pushNotification } from "../../../../util/Notification";

export default function TimeBlocks() {
  const auth = useSelector((state) => state.auth);
  const company_id = auth.loginResponse?.selectCompany?.[0]?.company_id || null;
  const [addNewTimeBlockModal, setAddTimeBlockModal] = useState(false);
  const [timeBlocksData, setTimeBlocksData] = useState();
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState("");
  const [loading, setLoading] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  // const [permissionPopUp, setPermissionPopUp] = useState(false);
  const [search, setSearch] = useState("");
  const { permissionManagement } = useSelector((state) => state);
  const [form] = Form.useForm();

  const handleDelete = async (row) => {
    setIsDeleteModal(true);
    setSelectedRowId(row.id);
  };

  const handleDeleteConfirm = async () => {
    setLoading(true);
    const { data } = await main_api.delete(`${companyAPIsEndPoints.ADD_TIME_BLOCKS_FOR_COMPANY}${selectedRowId}/`);
    if (data.isSuccess) {
      getTimeBlocks();
      setLoading(false);
      setIsDeleteModal(false);
    }
  };

  const handleEditRow = (row) => {
    if (row) {
      setAddTimeBlockModal(true);
      setIsEditMode(true);
      setSelectedRowId(row.id);
      const { start_time, end_time, name } = row;
      form.setFieldsValue({
        name,
        start_time: dayjs(start_time, "HH:mm"),
        end_time: dayjs(end_time, "HH:mm"),
      });
    }
  };

  const tableColumns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (_, record) => <div className="d-flex align-items-center gap-1">{record?.name}</div>,
    },
    {
      title: "Start Time",
      dataIndex: "start_time",
      key: "start_time",
      render: (_, record) => (
        <div className="d-flex align-items-center gap-1">{dayjs(record?.start_time, "HH:mm:ss").format("h:mm a")}</div>
      ),
    },
    {
      title: "End Time",
      dataIndex: "end_time",
      key: "end_time",
      render: (_, record) => (
        <div className="d-flex align-items-center gap-1">{dayjs(record?.end_time, "HH:mm:ss").format("h:mm a")}</div>
      ),
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      width: 400,
      ellipsis: true,
      render: (_, record) => (
        <Space size="middle">
          <Button
            onClick={(event) => {
              event.stopPropagation();
              handleEditRow(record);
            }}
          >
            <EditIcon />
          </Button>
          <Button
            onClick={(event) => {
              event.stopPropagation();
              handleDelete(record);
            }}
          >
            <DeleteIcon />
          </Button>
        </Space>
      ),
    },
  ];

  const addNewTimeBlock = () => {
    if (checkPermission(permissionManagement.permissions, "create_client")) {
      setAddTimeBlockModal(true);
      setIsEditMode(false);
    } else {
      // setPermissionPopUp(true);
    }
  };

  // Name Validator Function *****************
  function validateName(rule, value, callback) {
    if (!value || value.trim() === "") {
      return callback("Please enter a name for the time block!");
    }
    const minLength = 3;
    const maxLength = 50;
    const allowedChars = /^[a-zA-Z0-9\s]+$/;

    if (value.length < minLength) {
      return callback(`Name must be at least ${minLength} characters long.`);
    }

    if (value.length > maxLength) {
      return callback(`Name must be less than ${maxLength + 1} characters long.`);
    }

    if (!allowedChars.test(value)) {
      return callback("Name can only contain letters, numbers, and spaces.");
    }

    callback();
  }
  // Custom validation function for start time
  function validateStartTime(rule, value, callback) {
    const endTime = form.getFieldValue("end_time");
    if (value && endTime && !dayjs(value).isBefore(dayjs(endTime))) {
      return callback("Start time must be before end time.");
    }
    callback();
  }

  // Custom validation function for end time
  function validateEndTime(rule, value, callback) {
    const startTime = form.getFieldValue("start_time");
    if (value && startTime && !dayjs(value).isAfter(dayjs(startTime))) {
      return callback("End time must be after start time.");
    }
    callback();
  }

  const getTimeBlocks = useCallback(() => {
    setLoading(true);
    main_api
      .get(companyAPIsEndPoints.FETCH_TIME_BLOCKS_PER_COMPANY(company_id))
      .then(({ data }) => {
        if (data.isSuccess) {
          setTimeBlocksData(data.result);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [company_id]);

  useEffect(() => {
    //TODO to be intregrate form redux time block slice
    getTimeBlocks();
  }, [getTimeBlocks]);

  const handleSubmit = async (values) => {
    try {
      const startTime = dayjs(values.start_time).format("h:mm a");
      const endTime = dayjs(values.end_time).format("h:mm a");
      const updatedStartTime = convertTo24HourFormat(startTime);
      const updatedEndTime = convertTo24HourFormat(endTime);
      const timeBlockData = {
        ...values,
        start_time: updatedStartTime,
        end_time: updatedEndTime,
        company: company_id,
      };

      const { data } = isEditMode
        ? await main_api.put(`${companyAPIsEndPoints.ADD_TIME_BLOCKS_FOR_COMPANY}${selectedRowId}/`, timeBlockData)
        : await main_api.post(`${companyAPIsEndPoints.ADD_TIME_BLOCKS_FOR_COMPANY}`, timeBlockData);

      if (data.isSuccess) {
        getTimeBlocks();
        setAddTimeBlockModal(false);
        form.resetFields();
      }
    } catch (error) {
      console.error("Error submitting time block:", error);

      if (error?.response?.data?.message === "Unable to create TimeBlock. Time interval overlaps with another time block.") {
        pushNotification("Time interval overlaps with another time block", "error");
        return;
      }
      pushNotification("Something went wrong!.", "error");

      // Handle the error, e.g., display an error message to the user
    }
  };

  return (
    <>
      <ReportHeader>
        <div className="d-flex" flexDirection="row" align-item="center">
          <SearchInput search={search} onChange={(value) => setSearch(value)} onBlur={() => {}} placeholder="Type to search.." />
        </div>
        <div>
          <CustomBtnWithIcon btnText="Add new Time Block" margin="10px" onClick={addNewTimeBlock} />
        </div>
      </ReportHeader>
      {timeBlocksData?.length > 0 ? (
        <AntdesignTablePagination
          loading={loading}
          allowRowSelection={false}
          columns={tableColumns}
          data={timeBlocksData || []}
          totalCount={timeBlocksData?.length}
        />
      ) : (
        <NoDataScreen
          heading="You don’t have any Time Blocks in your list. Let’s get started by adding a new Time Block"
          btnText={"Add new Time Block"}
          onClick={addNewTimeBlock}
        />
      )}

      <CustomModal
        title={isEditMode ? "Edit Time Block" : "Add New Time Block"}
        onCancel={() => {
          setAddTimeBlockModal(false);
          form.resetFields();
        }}
        open={addNewTimeBlockModal}
        maskClosable={false}
        footer={false}
      >
        <Form
          layout="vertical"
          initialValues={{ name: "", start_time: "", end_time: "" }}
          form={form}
          name="addTimeBlockModal"
          onFinish={handleSubmit}
        >
          <StyledFormItem
            label="Name"
            name="name"
            style={{ marginBottom: "15px" }}
            className="w-100"
            rules={[{ validator: validateName }]}
          >
            <Input placeholder="Enter Name for Time Block" />
          </StyledFormItem>
          <StyledFormItem
            label="Start Time"
            name="start_time"
            style={{ marginBottom: "15px" }}
            className="w-100"
            rules={[{ required: true, message: "Please select a start time!" }, { validator: validateStartTime }]}
          >
            <TimePicker format="h:mm a" className="w-100" placeholder="Select Start Time" allowClear={false} />
          </StyledFormItem>
          <StyledFormItem
            label="End Time"
            name="end_time"
            className="w-100"
            style={{ marginBottom: "15px" }}
            rules={[{ required: true, message: "Please select an end time!" }, { validator: validateEndTime }]}
          >
            <TimePicker format="h:mm a" className="w-100" placeholder="Select End Time" allowClear={false} />
          </StyledFormItem>
          <Footer>
            <CustomBtnWithIcon
              btnText={"Cancel"}
              type="button"
              noBackground
              hideIcon={true}
              onClick={() => {
                setAddTimeBlockModal(false);
                form.resetFields();
              }}
            ></CustomBtnWithIcon>
            <CustomBtnWithIcon hideIcon={true} btnText={isEditMode ? "Update Time Block" : "Add Time Block"} type="submit" />
          </Footer>
        </Form>
      </CustomModal>
      <DeleteConfirmationRowModal
        onclose={() => setIsDeleteModal(false)}
        handleConfirmDeleteRows={handleDeleteConfirm}
        openConfimrationDeleteModal={isDeleteModal}
      />
    </>
  );
}

const ReportHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const StyledFormItem = styled(Form.Item)`
  & .ant-form-item-control-input {
    width: 100%;
  }
`;

const Footer = styled.footer`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: flex-end;
  margin-top: 30px;
`;
