import React, { useEffect, useState } from "react";
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import moment from "moment";
import styled from "styled-components";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./day.css";
import ClientEvent from "./ClientEvent";
import EventDetail from "./EventDetail";
import { schedulerBackground, schedulerColor } from "./helpers";
import { useSelector } from "react-redux";
import { checkPermission } from "../../util/commonUtil";
import PermissionModal from "../Modals/PermissionModal";

const CalendarDay = ({ schedulerState, dateRange, handleDataChange }) => {
  const permissionManagement = useSelector((state) => state.permissionManagement);
  const localizer = momentLocalizer(moment);
  const [permissionPopUp, setPermissionPopUp] = useState(false);

  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);

  const calculateWidth = (maxEventsPerDay = 1) => {
    let width = 120 * maxEventsPerDay;
    // min-width is 120 of each event

    let eventContainers = document.querySelectorAll(".rbc-events-container");
    eventContainers.forEach((container) => {
      const currentWidth = container.offsetWidth;
      if (width > currentWidth) {
        container.style.width = `${width}px`; // Apply the desired width
      } else {
        container.style.width = "100%";
      }
    });
    eventContainers = document.querySelectorAll(".rbc-day-slot .rbc-timeslot-group");
    eventContainers.forEach((container) => {
      const currentWidth = container.offsetWidth;
      if (width > currentWidth) {
        container.style.width = `${width}px`; // Apply the desired width
      } else {
        container.style.width = "100%";
      }
    });
  };

  useEffect(() => {
    let maxEventsPerDay = 1;
    let eventsCountPerDay = {};
    let modifyEvents = [];
    schedulerState.result &&
      schedulerState.result.map((scheduler) => {
        const [year, month, day] = scheduler.date.split("-").map(Number);
        const [startHour, startMinute, startSecond] = scheduler.start_time ? scheduler.start_time.split(":") : [0, 0, 0];
        const [endHour, endMinute, endSecond] = scheduler.end_time ? scheduler.end_time.split(":") : [0, 0, 0];
        const eventDate = new Date(year, month - 1, day).toDateString();
        eventsCountPerDay[eventDate] = (eventsCountPerDay[eventDate] || 0) + 1;
        if (eventsCountPerDay[eventDate] > maxEventsPerDay) {
          maxEventsPerDay = eventsCountPerDay[eventDate];
        }
        modifyEvents.push({
          ...scheduler,
          backgroundColor: schedulerBackground(scheduler),
          color: schedulerColor(scheduler),
          start: new Date(year, month - 1, day, startHour, startMinute),
          end: new Date(year, month - 1, day, endHour, endMinute),
          title: scheduler.service.name || "",
        });
      });
    setEvents(modifyEvents);
    calculateWidth(maxEventsPerDay);
  }, [schedulerState]);

  const selectEvent = (event) => {
    if (checkPermission(permissionManagement.permissions, "view_service_request")) {
      setSelectedEvent(event);
    } else {
      setPermissionPopUp(true);
    }
  };

  return (
    <div id="scheduler-day-calendar" className="mb-3 mt-3">
      <DayHeader>{moment(dateRange.start).format("dddd")}</DayHeader>
      <Calendar
        localizer={localizer}
        views={["day"]}
        defaultView={Views.DAY}
        events={events}
        components={{
          event: ClientEvent,
          toolbar: () => null,
        }}
        date={dateRange.start}
        onSelectEvent={(event) => selectEvent(event)}
        eventPropGetter={(event) => ({
          style: {
            backgroundColor: `${event.backgroundColor}`,
            color: `${event.color}`,
            border: `1px solid ${event.color}`,
            borderRadius: 0,
            outline: "none",
          },
        })}
        dayLayoutAlgorithm="no-overlap"
      />
      {selectedEvent && (
        <EventDetail
          handleDataChange={handleDataChange}
          event={selectedEvent}
          setIsModalOpen={() => setSelectedEvent(null)}
          isModalOpen={true}
        />
      )}
      <PermissionModal onclose={() => setPermissionPopUp(false)} openModal={permissionPopUp} />
    </div>
  );
};

export default CalendarDay;

const DayHeader = styled.div`
  background-color: rgb(238, 238, 238);
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
