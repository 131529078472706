import React from 'react'
import {
  ChangeRequestColumens
} from "../../../constants/ClientTableHeader/changeRequestHeader";
import { AntdesignTable } from "../../../components/antDesignTable/AntdesignTable";

const ChangeRequest = ({schedulerState}) => {

  return (
    <>
      <AntdesignTable columns={ChangeRequestColumens({})} data={schedulerState.result || []} />
    </>
  )
}

export default ChangeRequest