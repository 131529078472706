import { Input } from "antd";
import React from "react";
import styled from "styled-components";

function InputField({ label, placeholder, classes, labelClass, onChange, inputSize, rules, type, name }) {
  return (
    <>
      {label && <Label className={labelClass}>{label}</Label>}
      <Input placeholder={placeholder} size={inputSize} type={type} onChange={onChange} name={name}/>
    </>
  );
}

export default InputField;
const Label = styled.p`
    margin-bottom:6px;
    color:#3B3B3B;
    font-size:12px;
    font-weight:500;
    line-height:18px;
`