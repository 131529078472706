import React, { useState, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
// import "./table.css";
// import "../month.css";

import {
  InvoiceIcon,
  ClientYellow,
  SchedulerServiceIcon,
  SchedulerWhenDateicon,
  SchedulerAvatarClient,
} from "../../../assets/rawSvgs/index";
import { schedulerBackground, schedulerColor } from "../helpers";
import dayjs from "dayjs";
import { ShowEventModal } from "../OneDayVertical";
import { AddNewEventModal } from "../../../pages/company/sheduler/SchedulerEvents";
import { Col, Row } from "antd";
import HeadingComponent from "../../Heading";
import AntDesignModal from "../../Modals/AntDesignModal/AntDesignModal";
import { convertTo12HourFormat } from "../../../util/formatTime";
import CustomBtnWithIcon from "../../buttonWithIcon";
import DeleteConfirmationRowModal from "../../Modals/DeleteConfirmationRowModal";
import ManyEventsModal from "./ManyEventsModal";
import { main_api } from "../../../api/axios_helper";
import { pushNotification } from "../../../util/Notification";
import { useSelector } from "react-redux";
import { checkPermission } from "../../../util/commonUtil";
import PermissionModal from "../../Modals/PermissionModal";

//
const MyCalendarMonth = ({ AllStates, schedulerState, handleDataChange, loading }) => {
  const { permissionManagement } = useSelector((state) => state);
  const [permissionPopUp, setPermissionPopUp] = useState(false);
  const localizer = momentLocalizer(moment);
  const { dateRange, setDateRange } = AllStates?.monthChangeState;
  const [events, setEvents] = useState([]);
  const [openDetailSlotModal, setOpenDetailSlotModal] = useState({ details: "", condition: false });
  const [addNewEventSlotModal, setAddNewEventSlotModall] = useState({ details: "", condition: false });
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [showAllEventsModal, setShowAllEventsModal] = useState({ details: "", condition: false });
  const [isDeletePetOpen, setDeleteConfirmModal] = useState(false);
  const [loader, setloader] = useState(false);

  useEffect(() => {
    let modifyEvents = [];
    schedulerState &&
      schedulerState.result &&
      schedulerState.result.map((scheduler) => {
        const [year, month, day] = scheduler.date.split("-").map(Number);
        const [startHour, startMinute, startSecond] = scheduler.start_time ? scheduler.start_time.split(":") : [0, 0, 0];
        const [endHour, endMinute, endSecond] = scheduler.end_time ? scheduler.end_time.split(":") : [0, 0, 0];
        modifyEvents.push({
          ...scheduler,
          backgroundColor: schedulerBackground(scheduler),
          color: schedulerColor(scheduler),
          start: new Date(year, month - 1, day, startHour, startMinute),
          end: new Date(year, month - 1, day, endHour, endMinute),
          title: scheduler.service.name || "",
        });
      });
    setEvents(modifyEvents);
  }, [schedulerState]);

  // Function to handle event creation

  const eventStyleGetter = (event, start, end, isSelected) => {
    return {
      style: {
        backgroundColor: "transparent",
        color: event.textColor,
        cursor: "default", // Disable cursor change on event
      },
    };
  };

  const handleSelectSlot = (slotInfo) => {
    if (checkPermission(permissionManagement.permissions, "assign_service_request_staff")) {
      const dateTime = dayjs(slotInfo.start);
      const formattedDate = dateTime.format("YYYY-MM-DD");
      const today = dayjs().startOf("day");

      if (dateTime?.isBefore(today)) {
        // If the selected date is in the past, prevent any action
        pushNotification("You cannot assign a service to a past date.", "error");
        return;
      }
      const formattedTime = slotInfo.start.toLocaleTimeString("en-US", { hour: "numeric", minute: "numeric", hour12: true });

      setAddNewEventSlotModall({
        details: {
          staffId: slotInfo.resourceId,
          slotTime: formattedTime,
          date: formattedDate,
        },
        condition: true,
      });
    } else {
      setPermissionPopUp(true);
    }
  };
  const handleEventClick = (slotInfo) => {
    if (checkPermission(permissionManagement.permissions, "view_assign_service_request_staff")) {
      const dateTime = dayjs(slotInfo.start);
      const formattedDate = dateTime.format("YYYY-MM-DD");

      const formattedTime = slotInfo.start.toLocaleTimeString("en-US", { hour: "numeric", minute: "numeric", hour12: true });

      setOpenDetailSlotModal({
        details: {
          staffId: slotInfo.staff.id,
          slotTime: formattedTime,
          date: formattedDate,
          serviceID: slotInfo.id,
          title: slotInfo.title,
          serviceDetails: slotInfo.service,
          timeblock: slotInfo.time_block,
        },
        condition: true,
      });
    } else {
      setPermissionPopUp(true);
    }
  };
  const handleEditEvent = (values) => {
    if (checkPermission(permissionManagement.permissions, "edit_assign_service_request_staff")) {
      setAddNewEventSlotModall({
        details: {
          staffId: openDetailSlotModal?.details.staffId,
          slotTime: openDetailSlotModal?.details.slotTime,
          date: openDetailSlotModal?.details.date,
          serviceID: openDetailSlotModal?.details.serviceID,
          title: openDetailSlotModal?.details.title,
          serviceDetails: openDetailSlotModal.details.serviceDetails,
          timeblock: openDetailSlotModal.details.timeblock,
        },
        condition: true,
      });
    } else {
      setPermissionPopUp(true);
    }
  };
  const handleEditEventFromMany = (values) => {
    const dateTime = dayjs(values.start);
    const formattedDate = dateTime.format("YYYY-MM-DD");

    const formattedTime = values.start.toLocaleTimeString("en-US", { hour: "numeric", minute: "numeric", hour12: true });

    setAddNewEventSlotModall({
      details: {
        staffId: values.staff.id,
        slotTime: formattedTime,
        date: formattedDate,
        serviceID: values.id,
        title: values.service.name,
      },
      condition: true,
    });
  };
  const handleDeleteEvent = async () => {
    try {
      setloader(true);
      const { data } = await main_api.delete(`/api/v1/service_request/${isDeletePetOpen}/`);

      if (data.isSuccess) {
        pushNotification("Service Request has been deleted successfully!");
        handleDataChange();
        setDeleteConfirmModal(false);
        setShowAllEventsModal({
          ...showAllEventsModal,
          condition: false,
        });
      }
      setloader(false);
    } catch (error) {
      setloader(false);
    }
  };

  return (
    <div id="scheduler-month-calendar" className="mb-5 mt-3">
      <div className={loading ? "filtering" : null}>
        <Calendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          views={["month"]} // Only display the month view
          onSelectSlot={handleSelectSlot}
          onSelectEvent={handleEventClick}
          style={{ height: 500 }} // Adjust height as needed
          components={{
            event: CustomEvent,
            toolbar: () => null,
          }}
          step={60}
          date={dateRange?.start}
          onNavigate={() => {}}
          messages={{
            showMore: (target, data) => (
              <div
                className="ml-2"
                role="presentation"
                onClick={() => {
                  setShowAllEventsModal({ condition: true, data });
                }}
              >
                +{target} more
              </div>
            ),
          }}
          eventPropGetter={eventStyleGetter}
          selectable={true}
        />
      </div>
      {openDetailSlotModal.condition && (
        <ShowEventModal
          openDetailSlotModal={openDetailSlotModal.condition}
          handleCancel={() => {
            setOpenDetailSlotModal({ ...openDetailSlotModal, condition: false });
          }}
          eventDetails={openDetailSlotModal.details}
          serviceState={AllStates?.schedulerState?.result}
          handleEditEvent={handleEditEvent}
          fetchServices={() => {
            AllStates.monthDeleteEventFunction();
            setOpenDetailSlotModal({ ...openDetailSlotModal, condition: false });
            setAddNewEventSlotModall({ ...openDetailSlotModal, condition: false });
          }}
        />
      )}
      {addNewEventSlotModal.condition && (
        <AddNewEventModal
          clientPetServiceDropdowns={AllStates}
          addNewEventModal={addNewEventSlotModal.condition}
          updateValues={addNewEventSlotModal.details}
          handleSuccessFunc={() => {
            setOpenDetailSlotModal({ ...openDetailSlotModal, condition: false });
          }}
          oneDayVerticalFrom={true}
          updateForm={true}
          fromMonthCalender={true}
          closeModal={() => setAddNewEventSlotModall({ ...openDetailSlotModal, condition: false })}
        />
      )}

      <ManyEventsModal
        showAllEventsModal={showAllEventsModal}
        setShowAllEventsModal={setShowAllEventsModal}
        handleEditEventFromMany={handleEditEventFromMany}
        setDeleteConfirmModal={setDeleteConfirmModal}
      />

      <DeleteConfirmationRowModal
        onclose={() => setDeleteConfirmModal(false)}
        handleConfirmDeleteRows={handleDeleteEvent}
        openConfimrationDeleteModal={isDeletePetOpen}
        BtnloadingState={loader}
      />
      <PermissionModal onclose={() => setPermissionPopUp(false)} openModal={permissionPopUp} />
    </div>
  );
};

export default MyCalendarMonth;

const CustomEvent = ({ event }) => {
  return (
    <div>
      <ClientEventComponent event={event} />
    </div>
  );
};

const ClientEventComponent = ({ event }) => {
  return (
    <div
      style={{
        backgroundColor: "#E5F6FF",
        color: "#187BEF",
        borderRadius: "5px",
        cursor: "pointer",
        width: "80%",
        margin: "auto",
        display: "flex",
        justifyContent: "center",
      }}
    >
      {/* {event?.title} */}
      <span style={{ textOverflow: "ellipsis", overflow: "hidden", padding: "2px" }}>{event?.title}</span>
    </div>
  );
};
