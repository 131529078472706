import React from "react";
import styled from "styled-components";
import OnBoardingImage from "../../../assets/images/onboarding.svg";

function LeftContainer() {
  return (
    <ContainerLeft>
      <OnBoargindImageContainer>
        <img src={OnBoardingImage} alt="" height="100%" width="100%" />
      </OnBoargindImageContainer>
      <Textcontainer>
        Embark on a Tail-Wagging Journey with <br /> Pawsome Pet Care Services
      </Textcontainer>
    </ContainerLeft>
  );
}

export default LeftContainer;
const ContainerLeft = styled.div`
  background: #f9f9f9;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
`;
const OnBoargindImageContainer = styled.div`
  height: 450px;
  width: 509px;
`;
const Textcontainer = styled.p`
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
`;
