import React, { useEffect, useState } from "react";
import { DetailContainer } from "../pages/company/tasks/tasksManagementStyles";
import { Button, Divider } from "antd";
import styled from "styled-components";
import { ReactComponent as CrossIcon } from "../assets/rawSvgs/crossIcon.svg";
import { ReactComponent as CalenderIcon } from "../assets/rawSvgs/CalenderIcon.svg";
import { ReactComponent as BagIcon } from "../assets/rawSvgs/BagIcon.svg";
import { ReactComponent as FileIcon } from "../assets/rawSvgs/FileIcon.svg";
import { ReactComponent as IdCardIcon } from "../assets/rawSvgs/IdCardIcon.svg";
import EditWorkQueue from "./Modals/TasksManagementModal/EditWorkQueue";
import DeleteConfirmationRowModal from "./Modals/DeleteConfirmationRowModal";
import { deleteWorkQueue, editWorkQueue } from "../util/DataService";
import { pushNotification } from "../util/Notification";
import CommonUtil from "../util/commonUtil";
import moment from "moment";
import { CloseCircleOutlined } from "@ant-design/icons";

const WorkQueueDetail = (props) => {
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const tasksList = props.tasksListResponse?.result?.map((item) => ({ label: item.name, value: item.id }));
  const generalList = props.companyUsersListResponse?.result?.map((item) => ({ label: item.name, value: item.id }));
  const clientList = props.clientManagementState.clientList;
  const staffList = props.allStaffState.staffList;
  const [tasks, setTasks] = useState([]);


  const removeTask = (index) => {
    const updatedTasks = [...tasks];
    updatedTasks.splice(index, 1);
    const updatedFormValues = {
      ...props?.workQueueDetailResponse,
      work_queue_task: updatedTasks,
    };
  
    editWorkQueue(props?.workQueueDetailResponse?.id, updatedFormValues)
      .then(({ data }) => {
        if (data.status == 200) {
          pushNotification(data.message, "success");
          props.loadWorkQueue();
        }
      })
      .catch((error) => {
        console.error("error ", error);
      });
    setTasks(updatedTasks);
  };

  useEffect(() => {
    setTasks(props?.workQueueDetailResponse?.work_queue_task ?? []);
  }, [props?.workQueueDetailResponse?.work_queue_task]);

  const deleteWorkQueueHandler = () => {
    deleteWorkQueue(props.workQueueDetailResponse?.id).then(({ data }) => {
      if (data.code == 200) {
        pushNotification("WorkQueue has been deleted successfully!");
        setDeleteModalOpen(false);
        props.loadWorkQueue();
      }
    });
  };
  return (
    <>
   
      <EditWorkQueue
        isLoading={false}
        isModalOpen={isEditModalOpen}
        setModalOpen={setEditModalOpen}
        staffList={staffList}
        clientList={clientList}
        generalList={generalList}
        tasksList={tasksList}
        editModalValues={props.workQueueDetailResponse}
        loadWorkQueue={props.loadWorkQueue}
        onFinish={() => {}}
      />
      <DeleteConfirmationRowModal
        onclose={() => setDeleteModalOpen(false)}
        handleConfirmDeleteRows={deleteWorkQueueHandler}
        openConfimrationDeleteModal={isDeleteModalOpen}
      />
      <DetailContainer openWorkQueueDetailModal={props.isWorkQueuedetailModalOpen}>
        <div className="mt-5 pt-3">
          <div className="d-flex justify-content-between align-items-center">
            <div className="fs-5">Work Queue Detail</div>
            <Button className="border-0" onClick={() => props.openWorkQueueDetailModal(false)}>
              <CrossIcon />
            </Button>
          </div>
          <Divider />
          <div >
            <div className="d-flex justify-content-start my-3 gap-2">
              <div style={{width:"28%"}}>

              <DefaultFontText className="w-100">
                <TitleWithIcon>
                  <IdCardIcon /> Title
                </TitleWithIcon>
              </DefaultFontText>{" "}
              </div>
              <DefaultFontText className="w-50">{props.workQueueDetailResponse?.name}</DefaultFontText>
              
            </div>
            <div className="d-flex justify-content-start my-3 gap-2">
            <div style={{width:"28%"}}>

              <DefaultFontText className="w-100">
                <TitleWithIcon>
                  <FileIcon />
                  Description
                </TitleWithIcon>
              </DefaultFontText>{" "}
            </div>
              <DefaultFontText className="w-50">{props.workQueueDetailResponse?.description}</DefaultFontText>
            </div>
            <div className="d-flex justify-content-start my-3 gap-2">
            <div style={{width:"27%"}}>

                <div style={{marginTop:"7px"}}>
              <DefaultFontText className="w-100">
                <TitleWithIcon>
                  <BagIcon />
                  Assigned to
                </TitleWithIcon>
                  
              </DefaultFontText>{" "}
                </div>
            </div>
            <div style={{width:"70%"}}>

              <div className="d-flex flex-wrap gap-1">
                {props.workQueueDetailResponse?.assign_to_staffs?.map((item) => (
                  <Capsule>{item?.name}</Capsule>
                ))}
              </div>
            </div>
            </div>
          </div>
          <div>
            {/* <div className="mt-4 mb-3">Tasks List</div>
              {tasks.map((item,index) => (
                <TaskListCard key={index} className="mb-3">
                  <div className="d-flex justify-content-between">
                    <div className="d-flex gap-2">
                    <DefaultFontText className="w-50">Status</DefaultFontText>
                      {
                        item?.status == "pending" ?
                        <Capsule className="d-inline">{CommonUtil.capitalizeFirstLetter(item?.status)}</Capsule>: 
                        item?.status == "inprogress" ?
                        <Capsule className="d-inline" bgColor="#FEF3F2" color="#D92D20">
                          {CommonUtil.capitalizeFirstLetter(item?.status)}
                        </Capsule>
                        :
                        <Capsule className="d-inline" bgColor="#EEF8EE" color="#00914B">
                          <i class="pi pi-angle-double-up"></i> {CommonUtil.capitalizeFirstLetter(item?.status)}
                        </Capsule>
                      }
                      <DefaultFontText className="w-50">Priority</DefaultFontText>
                      {item?.priority == "high" && (
                        <Capsule bgColor="#FEF3F2" color="#D92D20">
                          <i class="pi pi-angle-double-up"></i> High
                        </Capsule>
                      )}
                      {item?.priority == "low" && (
                        <Capsule bgColor="#F0F9FF" color="#026AA2" className="d-inline">
                          <i class="pi pi-angle-double-down"></i> Low
                        </Capsule>
                      )}
                    </div>

                      <DefaultFontText className="w-50">Due Date</DefaultFontText>
                    <DefaultFontText><TitleWithIcon><CalenderIcon/>{moment(item?.due_date).format("DD/MM/YYYY")}</TitleWithIcon></DefaultFontText>
                  </div>
                      <DefaultFontText className="w-50">Description</DefaultFontText>
                  <DefaultFontText>{item?.description}</DefaultFontText>
                  <CloseCircleOutlined onClick={() => removeTask(index)} style={{marginLeft:"5px"}}/>
                </TaskListCard>
              ))}
            </div> */}
            <div className="mt-4 mb-3">Tasks List</div>
          
              {tasks.map((item, index) => (
               
                <TaskListCard key={index} className="mb-3">
  <TaskDetailRow>
    <TaskDetailLabel>Status</TaskDetailLabel>
    <TaskDetailValue>
      {item.status === "pending" ? (
        <Capsule className="d-inline">{CommonUtil.capitalizeFirstLetter(item.status)}</Capsule>
      ) : item.status === "inprogress" ? (
        <Capsule className="d-inline" bgColor="#FEF3F2" color="#D92D20">
          {CommonUtil.capitalizeFirstLetter(item.status)}
        </Capsule>
      ) : (
        <Capsule className="d-inline" bgColor="#EEF8EE" color="#00914B">
          {CommonUtil.capitalizeFirstLetter(item.status)}
        </Capsule>
      )}
    </TaskDetailValue>
  </TaskDetailRow>
  <TaskDetailRow>
    <TaskDetailLabel>Priority</TaskDetailLabel>
    <TaskDetailValue>
      {item.priority === "high" && (
        <Capsule bgColor="#FEF3F2" color="#D92D20" className="d-inline">
          <i className="pi pi-angle-double-up"></i> High
        </Capsule>
      )}
      {item.priority === "low" && (
        <Capsule bgColor="#F0F9FF" color="#026AA2" className="d-inline">
          <i className="pi pi-angle-double-down"></i> Low
        </Capsule>
      )}
    </TaskDetailValue>
  </TaskDetailRow>
  <TaskDetailRow>
    <TaskDetailLabel>Due Date</TaskDetailLabel>
    <TaskDetailValue>
      <TitleWithIcon>
        <CalenderIcon />
        {moment(item.due_date).format("DD/MM/YYYY")}
      </TitleWithIcon>
    </TaskDetailValue>
  </TaskDetailRow>
  <TaskDetailRow>
    <TaskDetailLabel>Description</TaskDetailLabel>
    <TaskDetailValue>{item.description}</TaskDetailValue>
  </TaskDetailRow>
    <DeleteButtonContainer>
        <CloseCircleOutlined onClick={() => removeTask(index)} style={{marginLeft:"5px"}}/>
    </DeleteButtonContainer>
  </TaskListCard>
              ))}
            </div>

        </div>
        <div className="d-flex w-100 gap-2" style={{ marginTop: "90px" }}>
          <Button className="w-100" onClick={() => {
            props.openWorkQueueDetailModal(false)
            setEditModalOpen(true)
            }}>
            Edit
          </Button>
          <Button className="w-100" onClick={(e) => setDeleteModalOpen(true)}>
            Delete
          </Button>
        </div>
      </DetailContainer>
    </>
  );
};

const Capsule = styled.div`
  background-color: ${(props) => props.bgColor || "#EFF1F5"};
  color: ${(props) => props.color || "#023047"};
  font-family: Helvetica Neue;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  text-align: center;
  border-radius: 20px;
  padding: 8px 14px;
`;

// const TaskListCard = styled.div`
//   background-color: #dcdcdc;
//   padding: 20px;
//   border-radius: 20px;
// `;

const TaskListCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #dcdcdc;
  padding: 20px;
  border-radius: 20px;
  margin-bottom: 20px; /* Add margin bottom for spacing */
`;
const TaskDetailRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 14px;
`;

const TaskDetailLabel = styled.div`
  flex: 0 0 120px; /* Adjust the width as needed */
  font-family: Helvetica Neue;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #424242;
`;

const DeleteButtonContainer = styled.div`
  position: absolute;
  top: 50%;
  right: 30px;
  transform: translateY(-50%);
`;

const TaskDetailValue = styled.div`
  flex: 1;
  font-family: Helvetica Neue;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #424242;
`;

const DefaultFontText = styled.div`
  font-family: Helvetica Neue;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #424242;
`;

const TitleWithIcon = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;



export default WorkQueueDetail;
