import { Link } from "react-router-dom";
import { CommonActionHeaders } from "../TableActionHeaderColumn";
import { Space, Tag } from "antd";
import { schedulerBackground, schedulerColor, schedulerStatus } from "../../components/SchedulerCalender/helpers";
import { convertTo12HourFormat } from "../../util/formatTime";
import { ReactComponent as DeclineIcon } from "../../assets/rawSvgs/declineIcon.svg";
import { ReactComponent as TickIcon } from "../../assets/rawSvgs/tickIcon.svg";
import dayjs from "dayjs";
import CustomBtnWithIcon from "../../components/buttonWithIcon";
import { ReactComponent as EditIcon } from "../../assets/rawSvgs/tableEditIcon.svg";
import { ReactComponent as DeleteIcon } from "../../assets/rawSvgs/tableDeleteIcon.svg";

const staffMangementHeaders = ({ handleDelete = "", handleEditRow = "", handleSendForgetPasswordReq, fetchSingleStaff }) => {
  return [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 230,

      render: (text, record) => (
        <Link to={"/company/staff/staff-details/" + record.id} style={{ fontWeight: 500 }}>
          {record?.name}
        </Link>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 200,
    },

    {
      title: "Primary Phone No.",
      key: "primary_phone_number",
      dataIndex: "primary_phone_number",
      width: 200,

      render: (text, record) => <a>{record?.primary_phone_number ? "+" + record?.primary_phone_number : "---"}</a>,
    },
    {
      title: "Secondary Phone No.",
      key: "secondary_phone_number",
      width: 200,

      dataIndex: "secondary_phone_number",
      render: (text, record) => <a>{record?.secondary_phone_number ? "+" + record?.secondary_phone_number : "---"}</a>,
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",

      width: 200,
      render: (text) => (
        <Tag style={{ ...tagStyle }} className="text-capitalize">
          {text}
        </Tag>
      ),
    },
    {
      title: "",
      key: "action",
      width: 600,
      ellipsis: true,
      render: (_, record) => (
        <Space size="middle">
          <CustomBtnWithIcon
            btnText={"Configure Staff Availability"}
            hideIcon
            width="200px"
            noBackground
            onClick={() => fetchSingleStaff(record.id)}
          />
          <CustomBtnWithIcon
            btnText={"Send Password Recovery Email"}
            hideIcon
            noBackground
            onClick={() => handleSendForgetPasswordReq({ email: record.email })}
          />
          <button
            onClick={(event) => {
              event.stopPropagation();
              handleEditRow && handleEditRow(record);
            }}
          >
            <EditIcon />
          </button>
          <button
            onClick={(event) => {
              event.stopPropagation();
              handleDelete && handleDelete(record);
            }}
          >
            <DeleteIcon />
          </button>
        </Space>
      ),
    },
  ];
};

const staffDetailsTableHeaders = ({ handleDelete = "", handleEditRow = "" }) => {
  return [
    {
      title: "Service Name",
      dataIndex: "service",
      key: "service",
      render: (text, record) => {
        return <>{record?.service?.name}</>;
      },
    },
    {
      title: "Duration",
      dataIndex: "duration",
      key: "status",
      render: (text, record) => {
        return <>{record.start_time && record.end_time ? calculateDuration(record?.start_time, record?.end_time) : "-"}</>;
      },
    },
    {
      title: "Client",
      dataIndex: "clientName",
      key: "clientName",
      render: (text, record) => {
        return <>{record?.client.name}</>;
      },
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => {
        return (
          <>
            <Tag style={{ ...tagStyle, backgroundColor: schedulerBackground(record), color: schedulerColor(record) }}>
              {schedulerStatus(record)}
            </Tag>
          </>
        );
      },
    },

    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (text, record) => {
        return <>{record.date}</>;
      },
    },
    {
      title: "Time",
      dataIndex: "time",
      key: "time",
      render: (text, record) => {
        return (
          <>
            {record.start_time && record.end_time ? (
              <>
                {convertTo12HourFormat(record.start_time)}
                {"  "} - {"  "}
                {record.end_time && convertTo12HourFormat(record.end_time)}
              </>
            ) : (
              <>-</>
            )}
          </>
        );
      },
    },
    // ...CommonActionHeaders({ handleDelete, handleEditRow }),
  ];
};

const staffTimeTrackingHeaders = ({ handleDelete = "", handleEditRow = "", SelectTab, handleUpdate }) => {
  let columns = [
    {
      title: "Duration",
      dataIndex: "duration",
      key: "duration",
      render: (text, record) => <div>{text || "---"}</div>,
    },
    {
      title: "Clock In",
      dataIndex: "clock_in_time",
      key: "clock_in_time",
      render: (text, record) => (
        <div>
          <span className="me-1">{record.clock_in_date}</span>•
          <span className="ms-"> {dayjs(record.clock_in_time, "HH:mm:ss").format("hh:mm A")}</span>
        </div>
      ),
    },
    {
      title: "Clock Out",
      dataIndex: "clock_out_time",
      key: "clock_out_time",
      render: (text, record) => (
        <div>
          <span className="me-1">{record.clock_out_date || "---"}</span>•
          <span className="ms-">
            {" "}
            {record.clock_out_time ? dayjs(record.clock_out_time, "HH:mm:ss").format("hh:mm A") : "---"}
          </span>
        </div>
      ),
    },
    {
      title: "Notes",
      key: "notes",
      dataIndex: "notes",
      render: (text, record) => <div>{text || "---"}</div>,
    },
  ];

  if (SelectTab === "Manual in/out (s)") {
    columns.push({
      title: "",
      dataIndex: "action",
      key: "action",
      render: (_, record) => (
        <>
          <div className="d-flex justify-content-evenly">
            <button onClick={() => handleUpdate({ approved: true }, record)}>
              <TickIcon /> &nbsp; Accept
            </button>
            <button onClick={() => handleUpdate({ approved: false, decline: true }, record)}>
              <DeclineIcon />
              &nbsp; Decline
            </button>
          </div>
        </>
      ),
    });
  } else {
    columns.push(...CommonActionHeaders({ handleDelete, handleEditRow }));
  }
  return columns;
};
const staffMileageTrackingHeaders = ({ handleDelete = "", handleEditRow = "", SelectTab, handleUpdate }) => {
  let columns = [
    {
      title: "Distance",
      dataIndex: "distance",
      key: "distance",
      render: (text, record) => (
        <div>
          <span className="me-1">{Math.round(record.distance)} miles</span>
        </div>
      ),
    },
    {
      title: "Readings",
      dataIndex: "readings",
      key: "readings",

      render: (text, record) => (
        <div>
          <span className="me-1">{record.odometer_start_reading}</span> -
          <span className="ms-"> {record.odometer_end_reading} miles</span>
        </div>
      ),
    },
    {
      title: "Started Recording At",
      dataIndex: "start_time",
      key: "start_time",
      render: (text, record) => (
        <div>
          <span className="me-1">{record.start_recording_date}</span>•
          <span className="ms-"> {dayjs(record.start_recording_time, "HH:mm:ss").format("hh:mm A")}</span>
        </div>
      ),
    },
    {
      title: "Ended Recording At",
      dataIndex: "end_time",
      key: "end_time",
      render: (text, record) => (
        <div>
          <span className="me-1">{record.end_recording_date}</span>•
          <span className="ms-"> {dayjs(record.end_recording_time, "HH:mm:ss").format("hh:mm A")}</span>
        </div>
      ),
    },
    {
      title: "Notes",
      dataIndex: "notes",
      key: "notes",
      render: (text, record) => <div>{text || "---"}</div>,
    },
  ];
  if (SelectTab === "Mileage Requests") {
    columns.push({
      title: "",
      dataIndex: "action",
      key: "action",
      render: (_, record) => (
        <>
          <div className="d-flex justify-content-evenly">
            <button onClick={() => handleUpdate({ approved: true }, record)}>
              <TickIcon /> &nbsp; Accept
            </button>
            <button onClick={() => handleUpdate({ approved: false, decline: true }, record)}>
              <DeclineIcon />
              &nbsp; Decline
            </button>
          </div>
        </>
      ),
    });
  } else {
    columns.push(...CommonActionHeaders({ handleDelete, handleEditRow }));
  }
  return columns;
};

export { staffMangementHeaders, staffDetailsTableHeaders, staffTimeTrackingHeaders, staffMileageTrackingHeaders };

const tagStyle = {
  backgroundColor: "#EFF1F5",
  fontWeight: 500,
  border: "none",
  borderRadius: "28px",
  padding: "2px 8px 2px 6px",
  color: "#023047",
};

function calculateDuration(startTime, endTime) {
  const [startHours, startMinutes, startSeconds] = startTime.split(":").map(Number);
  const [endHours, endMinutes, endSeconds] = endTime.split(":").map(Number);

  const startTotalMinutes = startHours * 60 + startMinutes + startSeconds / 60;
  const endTotalMinutes = endHours * 60 + endMinutes + endSeconds / 60;

  return endTotalMinutes - startTotalMinutes + " " + "Minutes";
}

// Example usage:
const startTime = "00:00:00";
const endTime = "00:29:00";

// const duration = calculateDuration(startTime, endTime);
