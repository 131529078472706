import { createSlice } from "@reduxjs/toolkit";
import { main_api } from "../../api/axios_helper";
import { companyAPIsEndPoints } from "../../constants/ApiEndPoints";

const initialState = {
  isLoading: false,
  error: false,
  isdetailModalOpen: false,
  petDetailResponse: {},
  vaccinationResponse: {}
};

const slice = createSlice({
  name: "petDetail",
  initialState,
  reducers: {
    openDetailModalSlice(state, action) {
      state.isdetailModalOpen = action.payload.isdetailModalOpen;
    },
    loadPetDetailSlice(state, action) {
      state.isLoading = action.payload.isLoading;
      state.error = action.payload.error;
      state.petDetailResponse = action.payload.petDetailResponse;
    },
  },
});

export const { openDetailModalSlice, loadPetDetailSlice } = slice.actions;

export default slice.reducer;

export const loadPetDetail = (id) => async (dispatch) => {
  try {
    dispatch(loadPetDetailSlice({ isLoading: true, error: false, petDetailResponse: [] }));
    const { data } = await main_api.get(companyAPIsEndPoints.FETCH_SINGLE_PET_LIST_PETS(id));
    dispatch(loadPetDetailSlice({ isLoading: false, error: false, petDetailResponse: data }));
  } catch (error) {
    dispatch(loadPetDetailSlice({ isLoading: false, error: error }));
  }
};
export const openDetailModal = (isOpen) => (dispatch) => {
  dispatch(openDetailModalSlice({isdetailModalOpen: isOpen}));
};
