import React, { useEffect, useState } from "react";
import { reportDetailTabs } from "../../../constants/CompanyNavTabs/reportDetailTab";
import AntDesignNavTabs from "../../../components/antDesignNaviTabs/AntDesignNavTabs";
import ScheduleReport from "./ScheduleReport";
import ClientAndStaff from "./ClientAndStaff";
import styled from "styled-components";
import { ReactComponent as LessThanIcon } from "../../../assets/rawSvgs/lessThanIcon.svg";
import CustomBtnWithIcon from "../../../components/buttonWithIcon";
import TimeReport from "./TimeReport";
import { companyAPIsEndPoints } from "../../../constants/ApiEndPoints";
import { main_api } from "../../../api/axios_helper";
import { pushNotification } from "../../../util/Notification";
import FinancialReport from "./FinancialReport";

function ReportDetail({ currentReport, setCurrentReport, setNewReport }) {
  const navTabs = reportDetailTabs();
  const [saveReport, setSaveReport] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const handleChangeTabs = (tabs) => {
    setActiveTab(tabs);
  };

  const exportReport = () => {
    const link = document.createElement("a");
    link.href = currentReport.file_key;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const saveReportCallBack = (params) => {
    let objSend = { ...params };
    objSend.status = currentReport?.status;

    main_api
      .post(`${companyAPIsEndPoints.REPORTING_SAVE}`, objSend)
      .then(({ data }) => {
        setCurrentReport(data.result);
        pushNotification(data.message);
        setSaveReport(false);
      })
      .catch((error) => {
        console.log(error);
        setSaveReport(false);
      });
  };

  const renderComponents = {
    1: (
      <ScheduleReport
        saveReportCallBack={saveReportCallBack}
        saveReport={saveReport}
        setSaveReport={setSaveReport}
        setCurrentReport={setCurrentReport}
        currentReport={currentReport}
      />
    ),
    2: (
      <ClientAndStaff
        saveReportCallBack={saveReportCallBack}
        saveReport={saveReport}
        setSaveReport={setSaveReport}
        setCurrentReport={setCurrentReport}
        currentReport={currentReport}
      />
    ),
    3: (
      <TimeReport
        saveReportCallBack={saveReportCallBack}
        saveReport={saveReport}
        setSaveReport={setSaveReport}
        setCurrentReport={setCurrentReport}
        currentReport={currentReport}
      />
    ),
    4: (
      <FinancialReport
        saveReportCallBack={saveReportCallBack}
        saveReport={saveReport}
        setSaveReport={setSaveReport}
        setCurrentReport={setCurrentReport}
        currentReport={currentReport}
      />
    ),
  };

  return (
    <div>
      <ReportHeader>
        <div>
          <button
            onClick={() => {
              setCurrentReport(null);
              setNewReport(false);
            }}
          >
            <LessThanIcon width="13px" height="13px" />
          </button>
          {currentReport.name}
        </div>
        <div style={{ display: "flex", gap: "10px" }}>
          {currentReport && currentReport.file_key && (
            <CustomBtnWithIcon noBackground onClick={exportReport} hideIcon={true} btnText="Export Report" />
          )}
          <CustomBtnWithIcon disabled={saveReport} onClick={() => setSaveReport(true)} hideIcon={true} btnText="Save" />
        </div>
      </ReportHeader>
      <AntDesignNavTabs navTabs={navTabs} onChange={handleChangeTabs} />
      {renderComponents[activeTab]}
    </div>
  );
}

export default ReportDetail;

const ReportHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ReportingContainer = styled.div`
  display: flex;
  min-height: 80vh;
`;

export const ReportingItem = styled.div`
  border-right: 1px solid #eeeeee;
  min-width: 15%;
`;
