import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Steper from "./Steper";
import SkippedButton from "../../../components/skippedButton/SkippedButton";
import GeneralInfo from "./GeneralInfo";
import AddressDetail from "./AddressDetail";
import PetInfo from "./PetInfo";
import { Form, Input } from "antd";
import CustomBtnWithIcon from "../../../components/buttonWithIcon";
import axiosInstance from "../../../util/AxiosConfig";
import { companyAPIsEndPoints } from "../../../constants/ApiEndPoints";
import { getUserId } from "../../../api/auth";
import { pushNotification } from "../../../util/Notification";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { main_api } from "../../../api/axios_helper";

function RightContainer() {
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(1);
  const [routeOptimizorChecked, setRouteOptimizorChecked] = useState(false);
  const [address, setAddress] = useState('');
  const [addressLatAndLong, setAddressLatAndLong] = useState([]);
  const { auth } = useSelector((state) => state);
  const [clientData, setClientData] = useState(null);
  const [image, setImage] = useState(null);
  const [form] = Form.useForm();

  useEffect(()=>{

    let company_id = auth.loginResponse?.selectCompany?.[0]?.company_id || null
    const client_id = auth.loginResponse?.selectCompany?.filter((item) => item.client_id !== null)[0].client_id;
    main_api
    .get(companyAPIsEndPoints.FETCH_SINGLE_MANAGEMENT_CLIENTS(client_id, company_id))
    .then(({ data }) => {
      setClientData(data)
      form.setFieldsValue({
        username: data.username || '',
        dob: data.dob ? dayjs(data.dob) : null,
        gender: data.gender || null,
        bio: data.bio || '',
        address_1: data.address || '',
        city: data.city || '',
        zip: data.zip || '',
        state: data.state || '',
        country: data.country || ''
      })
      setAddress(data.address || '')
    })
    .catch((error) => {
      console.log(error);
    });

    main_api
    .post(companyAPIsEndPoints.USER_ONBOARDING_STATUS, {client: client_id})
    .then(({ data }) => {
      setActiveStep(data.result.current_step || 1)
    })
    .catch((error) => {
      console.log(error);
    });

  },[])

  const skipOnboarding = () =>{
    const clientId = auth.loginResponse?.selectCompany?.filter((item) => item.client_id !== null)[0].client_id;
    main_api
      .post(companyAPIsEndPoints.USER_ONBOARDING, {client:clientId, is_seen_onboarding: true})
      .then(({ data }) => {})
      .catch((error) => {
        pushNotification("Something went wrong!", "error");
        console.log(error);
      });
    navigate("/client/scheduler");
  }

  const addPet = (formValues) => {
    const clientId = auth.loginResponse?.selectCompany?.filter((item) => item.client_id !== null)[0].client_id;
    if(formValues.birth_date){
      formValues['birth_date'] = dayjs(formValues.birth_date).format("YYYY-MM-DD")
    }
  
    const updatedValues = {
      ...formValues,
      client: clientId
    };
    main_api
      .post(companyAPIsEndPoints.POST_PET_LIST_PETS, prepareFormBody(updatedValues))
      .then(({ data }) => {
        if (data?.isSuccess) {
          pushNotification(data.message, "success");
          skipOnboarding()
          // localStorage.setItem("clientOnboarding", false);
          // navigate("/client/scheduler");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updateOnboardingStep = (step) => {
    const clientId = auth.loginResponse?.selectCompany?.filter((item) => item.client_id !== null)[0].client_id;
    main_api
    .post(companyAPIsEndPoints.USER_ONBOARDING, {client:clientId, current_step: step})
    .then(({ data }) => {})
    .catch((error) => {
      pushNotification("Something went wrong!", "error");
      console.log(error);
    });

  }

  const prepareFormBody = (formValues) => {
    let body = new FormData();
  
    Object.keys(formValues).forEach((key) => {
      if (key === "client_photo" && image) return;
      if (key === "pet_photo" && image) return;
  
      body.append(key, formValues[key]);
    });

    if (image && activeStep == 1) {
      body.append("client_photo", image);
    }
    if (image && activeStep == 3) {
      body.append("pet_photo", image);
    }
    return body;
  };  

  const onFinish = (formValues) => {
    console.log("formValues",formValues);
    if(addressLatAndLong[0] && addressLatAndLong[1] ){
      formValues = {...formValues, 
        latitude:addressLatAndLong[0],
        longitude:addressLatAndLong[1],
      }
    }
    if(formValues.dob){
      formValues['dob'] = dayjs(formValues.dob).format("YYYY-MM-DD")
    }
    if(activeStep === 3){
      addPet(formValues)
    } else{
      const client_id = auth.loginResponse?.selectCompany?.filter((item) => item.client_id !== null)[0].client_id;
      axiosInstance
      .put(companyAPIsEndPoints.EDIT_SINGLE_MANAGEMENT_CLIENTS(client_id), prepareFormBody(formValues))
      .then(({data}) => {
        if(data.isSuccess) {
          // if(activeStep == 3) {
          //   pushNotification(data.message, "success");
          //   localStorage.setItem("clientOnboarding", false);
          //   navigate("/client/scheduler");
          // }
          setActiveStep(activeStep + 1);
          updateOnboardingStep(activeStep + 1)
        }
      })
      .catch(error => {
        pushNotification(error, "error");
      })
    }

  }

  return (
    <StepsContainer>
      <div className="d-flex justify-content-end">
        <SkippedButton onClick={() => skipOnboarding()}/>
      </div>
      <SteperContainer>
        <Steper steps={[1, 2, 3]} activeStep={activeStep} setActiveStep={setActiveStep} />
      </SteperContainer>
      <Form form={form} name="clientOnboardingForm" onFinish={onFinish} layout="vertical">
        <FormContainer>
          {activeStep === 1 && <GeneralInfo image={image} setImage={setImage}/>}
          {activeStep === 2 && <AddressDetail address={address} setAddress={setAddress} setAddressLatAndLong={setAddressLatAndLong} form={form} routeOptimizorChecked={routeOptimizorChecked}/>}
          {activeStep >= 3 && <PetInfo image={image} setImage={setImage}/>}
          <div className="d-flex gap-2">
            {activeStep > 1 && (
              <CustomBtnWithIcon
                type="button"
                btnText={"Back"}
                margin="0"
                noBackground
                hideIcon={true}
                onClick={() => setActiveStep(activeStep - 1)}
              />
            )}
            <CustomBtnWithIcon
              btnText={"Save and Continue"}
              type="submit"
              hideIcon={true}
            />
          </div>
        </FormContainer>
      </Form>
    </StepsContainer>
  );
}

export default RightContainer;

const FormContainer = styled.div`
  width: 330px;
  margin: 0 auto;
  height: calc(100vh - 212px);
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;

  ::-webkit-scrollbar {
    width: 0px;
  }
`;
const StepsContainer = styled.div`
  padding: 46px 64px;
`;

const SteperContainer = styled.div`
  padding: 59px 0 36px 0;
  width: 330px;
  margin: 0 auto;
`;
