import React from "react";
import { CustomModal, ButtonWrapper } from "./modal.style";
import { Divider, Form } from "antd";

import { Formik } from "formik";
import CustomBtnWithIcon from "../../../../../../components/buttonWithIcon";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import FormikInputFloating from "../../../../../../components/formikInputs/TextInput";
import { updateCompanyGroups } from "../../../../../../redux/platformSlices/CompanyDetail";

const validationSchema = Yup.object({
  name: Yup.string().required("name is required"),
});

const AddGroupModal = ({ selectedTab, isModalOpen, groupIndex, group = {}, setModalOpen, isEditMode = false }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const details = useSelector((state) => state.companyDetail.companyDetailResponse);
  const clientGroups = details?.result?.client_custom_fields?.groups;
  const petsGroups = details?.result?.pet_custom_fields?.groups;

  const groups = selectedTab === "ClientField" ? clientGroups : petsGroups;

  const auth = useSelector((state) => state.auth);
  const companyId = auth.loginResponse?.selectCompany?.[0]?.company_id || null;

  const addNewGroup = async (formValues, resetForm) => {
    const customFields = {
      groups: [
        ...groups,
        {
          name: formValues.name,
          fields: [],
        },
      ],
    };

    const data = selectedTab === "ClientField" ? { client_custom_fields: customFields } : { pet_custom_fields: customFields };

    if (data) {
      if (isEditMode) {
        const groupsClone = groups.map((group) => ({ ...group, fields: [...group.fields] }));
        const groupToUpdate = { ...groupsClone[groupIndex] };
        groupToUpdate.name = formValues.name;
        groupsClone[groupIndex] = groupToUpdate;
        await dispatch(updateCompanyGroups(companyId, { client_custom_fields: { groups: groupsClone } }));
      } else {
        await dispatch(updateCompanyGroups(companyId, { ...data }));
      }
      setModalOpen(false);
      resetForm();
    }
  };

  return (
    <CustomModal
      open={isModalOpen}
      title={isEditMode ? "Edit a Group" : "Create a new Group"}
      width="600px"
      heigth="444px"
      onCancel={() => {
        setModalOpen(false);
        form.resetFields();
      }}
      footer={null}
      maskClosable={false}
    >
      <Divider />

      <Formik
        initialValues={{ name: isEditMode ? group.name : "" }}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => {
          addNewGroup(values, resetForm);
        }}
      >
        {({ handleSubmit, values }) => {
          return (
            <Form onFinish={handleSubmit} layout="vertical">
              <FormikInputFloating label="Name" placeHolder="Enter Name" name="name" type="text" className="w-100 my-2" />
              <Divider />
              <ButtonWrapper>
                <CustomBtnWithIcon
                  btnText="Cancel"
                  margin="0px 5px"
                  noBackground
                  hideIcon={true}
                  type="button"
                  onClick={() => setModalOpen(false)}
                />
                <CustomBtnWithIcon btnText="Save Changes" type="submit" hideIcon={true} />
              </ButtonWrapper>
            </Form>
          );
        }}
      </Formik>
    </CustomModal>
  );
};

export default AddGroupModal;
