import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { main_api } from "../../api/axios_helper";
import { companyAPIsEndPoints } from "../../constants/ApiEndPoints";

const initialState = {
  isLoading: false,
  error: false,
  petList: [],
  vaccination: [],
  vetList: [],
  petListValueLabel: [],
  singlePetDetails: {},
};

const slice = createSlice({
  name: "pets",
  initialState,
  reducers: {
    loadPetListSlice(state, action) {
      state.isLoading = action.payload.isLoading;
      const petList = action.payload.petList?.map((items) => {
        return {
          value: items.id,
          label: items.name,
        };
      });
      state.error = action.payload.error;
      state.petListValueLabel = petList;
      state.petList = action.payload.petList;
    },
    loadVaccinationsSlice(state, action) {
      state.isLoading = action.payload.isLoading;
      state.error = action.payload.error;
      state.vaccination = action.payload.vaccination;
    },
    loadVetListSlice(state, action) {
      state.isLoading = action.payload.isLoading;
      state.error = action.payload.error;
      state.vetList = action.payload.vetList;
    },
    searchPetListSlice(state, action) {
      state.isLoading = action.payload.isLoading;
      state.error = action.payload.error;
      state.petList = action.payload.petList;
    },
    searchVaccinationListSlice(state, action) {
      state.isLoading = action.payload.isLoading;
      state.error = action.payload.error;
      state.vaccination = action.payload.vaccination;
    },
    searchVetListSlice(state, action) {
      state.isLoading = action.payload.isLoading;
      state.error = action.payload.error;
      state.vetList = action.payload.vetList;
    },
    addPetSlice(state, action) {
      state.isLoading = action.payload.isLoading;
      state.error = action.payload.error;
      state.vetList = action.payload.vetList;
    },
    editPetSlice(state, action) {
      state.isLoading = action.payload.isLoading;
      state.error = action.payload.error;
      state.vetList = action.payload.vetList;
    },
    deletePetSlice(state, action) {
      state.isLoading = action.payload.isLoading;
      state.error = action.payload.error;
      state.vetList = action.payload.vetList;
    },
    singlePetDetails(state, action) {
      state.singlePetDetails = action.payload;
    },
  },
});

export const {
  loadPetListSlice,
  loadVaccinationsSlice,
  loadVetListSlice,
  addPetSlice,
  editPetSlice,
  deletePetSlice,
  searchPetListSlice,
  searchVaccinationListSlice,
  searchVetListSlice,
  singlePetDetails,
} = slice.actions;

export default slice.reducer;

export const loadPetList = () => async (dispatch, getState) => {
  try {
    const companyId = getState().auth?.loginResponse?.selectCompany?.[0]?.company_id;
    const authState = getState().auth?.loginResponse;
    let isClientPortal = authState?.selectedRoleType === "client_type" ? true : false;

    let clientId = "";

    if (isClientPortal) {
      authState?.selectCompany?.forEach((element) => {
        if (element?.client_id) {
          clientId = element?.client_id;
        }
      });
    }

    dispatch(loadPetListSlice({ isLoading: true, error: false, petList: [] }));

    const { data } = await main_api.get(companyAPIsEndPoints.FETCH_PET_LIST_PETS(companyId, clientId));

    dispatch(loadPetListSlice({ isLoading: false, error: false, petList: data.result }));
  } catch (error) {
    console.log("error", error);
  }
};

export const loadVaccinationsList = () => async (dispatch, getState) => {
  try {
    const companyId = getState().auth?.loginResponse?.selectCompany?.[0]?.company_id;
    dispatch(loadVaccinationsSlice({ isLoading: true, error: false, vaccination: [] }));
    const { data } = await main_api.get(companyAPIsEndPoints.FETCH_VACCINATION_PETS(companyId));
    dispatch(loadVaccinationsSlice({ isLoading: false, error: false, vaccination: data.result }));
  } catch (error) {
    console.log("error", error);
  }
};

export const loadVetList = () => async (dispatch) => {
  try {
    dispatch(loadVetListSlice({ isLoading: true, error: false, vetList: [] }));
    const { data } = await main_api.get(companyAPIsEndPoints.FETCH_VET_LIST_PETS);
    dispatch(loadVetListSlice({ isLoading: false, error: false, vetList: data.result }));
  } catch (error) {
    console.log("error", error);
  }
};

export const searchPetList = (search, date) => async (dispatch) => {
  try {
    dispatch(searchPetListSlice({ isLoading: true, error: false, petList: [] }));
    const { data } = await main_api.get(companyAPIsEndPoints.PET_LIST_FILTER(search, date));
    dispatch(searchPetListSlice({ isLoading: false, error: false, petList: data.result }));
  } catch (error) {
    console.log("error", error);
  }
};

export const searchVaccinationList = (search, petName) => async (dispatch) => {
  try {
    dispatch(searchVaccinationListSlice({ isLoading: true, error: false, vaccination: [] }));
    const { data } = await main_api.get(companyAPIsEndPoints.VACCINATION_LIST_FILTER(search, petName));
    dispatch(searchVaccinationListSlice({ isLoading: false, error: false, vaccination: data.result }));
  } catch (error) {
    console.log("error", error);
  }
};

export const searchVetList = (search) => async (dispatch) => {
  try {
    dispatch(searchVetListSlice({ isLoading: true, error: false, vetList: [] }));
    const { data } = await main_api.get(companyAPIsEndPoints.FETCH_VET_LIST_FILTER(search));
    dispatch(searchVetListSlice({ isLoading: false, error: false, vetList: data }));
  } catch (error) {
    console.log("error", error);
  }
};

export const addPet = (values) => async (dispatch) => {
  try {
    dispatch(addPetSlice({ isLoading: true, response: "" }));
    const { data } = await main_api.get(companyAPIsEndPoints.POST_PET_LIST_PETS, values);

    dispatch(addPetSlice({ isLoading: false, error: false, vetList: data }));
  } catch (error) {
    console.log("error", error);
  }
};

export const editPet = (values) => async (dispatch) => {
  try {
    dispatch(editPetSlice({ isLoading: true, error: false, vetList: [] }));
    const { data } = await main_api.get(companyAPIsEndPoints.PUT_SINGLE_PET_LIST_PETS(values.id), values);
    dispatch(editPetSlice({ isLoading: false, error: false, vetList: data }));
  } catch (error) {
    console.log("error", error);
  }
};

export const deletePet = (values) => async (dispatch) => {
  try {
    dispatch(deletePetSlice({ isLoading: true, error: false, vetList: [] }));
    const { data } = await main_api.get(companyAPIsEndPoints.DELETE_SINGLE_PET_LIST_PETS(values.id));

    dispatch(deletePetSlice({ isLoading: false, error: false, vetList: data }));
  } catch (error) {
    console.log("error", error);
  }
};
