import React, { useEffect, useState } from "react";
import { AntdesignTable } from "../../../../components/antDesignTable/AntdesignTable";
import CustomBtnWithIcon from "../../../../components/buttonWithIcon";
import HeadingComponent from "../../../../components/Heading";
import AntDesignModal from "../../../../components/Modals/AntDesignModal/AntDesignModal";

import { ReactComponent as DeclineIcon } from "../../../../assets/rawSvgs/declineIcon.svg";
import { ReactComponent as TickIcon } from "../../../../assets/rawSvgs/tickIcon.svg";
import moment from "moment";
import { Space, Tag } from "antd";
import { main_api } from "../../../../api/axios_helper";
import { companyAPIsEndPoints } from "../../../../constants/ApiEndPoints";
import { checkPermission } from "../../../../util/commonUtil";
import { pushNotification } from "../../../../util/Notification";
import { schedulerBackground, schedulerColor, schedulerStatus } from "../../../../components/SchedulerCalender/helpers";
import { tagStyle } from "../../../../constants/CompanyTableHeaders/SchedulerTableHeaders";

//
const ProjectionsModal = ({
  open,
  close,
  projectionDetails,
  permissionManagement,
  requiredDropdowns,

  setPermissionPopUp,
  handleFetchProjections,
}) => {
  const [projectionsDetails, setProjectionsDetails] = useState([]);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setProjectionsDetails(projectionDetails?.details);
  }, [projectionDetails?.condition]);

  const handleApproveProjection = async (id, type) => {
    if (checkPermission(permissionManagement.permissions, "edit_template")) {
      try {
        setLoader(true);
        const { data } = await main_api.post(companyAPIsEndPoints.APPROVE_TEMPLATE, { id, status: type });
        if (data.isSuccess) {
          if (type === "approved") {
            pushNotification("Template Approved successfully!.");
          }
          if (type === "rejected") {
            pushNotification("Template Rejected successfully!.");
          }
          requiredDropdowns?.fetchSchedulerTemplates();
          handleFetchProjections();
        }
        setLoader(false);
      } catch (error) {
        setLoader(false);
      }
    } else {
      setPermissionPopUp(true);
    }
  };

  return (
    <div>
      <AntDesignModal
        title={<HeadingComponent text={`Projections`} fontSize="16px" fontWeight={700} color="#212121" />}
        openModal={open}
        closeModal={close}
        // style={{ top: 10 }}
        // width={1200}
        style={{ top: 20, overflow: "auto", minWidth: "1200px" }}
        component={
          <div>
            {!projectionDetails?.condition ? (
              <HeadingComponent text={`Loading...`} fontSize="16px" fontWeight={400} color="#212121" />
            ) : (
              <AntdesignTable
                data={projectionsDetails || []}
                columns={templateTableCols({ handleApproveProjection, loader })}
                allowMultieSelectRows={false}
              />
            )}
          </div>
        }
        footer={
          <div className="d-flex justify-content-end mt-4">
            <CustomBtnWithIcon btnText={"Close"} hideIcon noBackground className="me-2" width="90px" onClick={close} />
          </div>
        }
      />
    </div>
  );
};

export default ProjectionsModal;

const templateTableCols = ({ handleApproveProjection, loader }) => {
  return [
    {
      title: "Start Date",
      dataIndex: "projection_start_date",
      key: "projection_start_date",
    },
    {
      title: "End Date",
      dataIndex: "projection_end_date",
      key: "projection_end_date",
    },
    {
      title: "Approval Date",
      dataIndex: "projection_approval_date",
      key: "projection_approval_date",
      align: "center",
      render: (text) => <a className="text-center">{text || "---"}</a>,
    },
    {
      title: "Status",
      dataIndex: "projection_status",
      key: "projection_status",
      with: 200,
      render: (text, record) => {
        return (
          <>
            <Tag
              style={{
                ...tagStyle,
                backgroundColor: schedulerBackground({ status: text }),
                color: schedulerColor({ status: text }),
              }}
              className="text-capitalize"
            >
              {text}
            </Tag>
          </>
        );
      },
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      align: "center",
      with: 200,
      render: (text) => <a>{moment(text).format("YYYY-DD-MM")}</a>,
    },
    {
      title: "",
      key: "action",

      with: 300,

      render: (_, record) => (
        <Space size="middle">
          {record.projection_status === "pending" && (
            <button onClick={() => handleApproveProjection(record.id, "approved")}>
              <TickIcon /> &nbsp; Approve
            </button>
          )}
          {record.projection_status === "pending" && (
            <button
              className="color-danger d-flex align-items-baseline"
              onClick={() => handleApproveProjection(record.id, "rejected")}
            >
              <DeclineIcon />
              <HeadingComponent text={"Reject"} fontSize={"14px"} margin="0px 5px" fontWeight={400} />
            </button>
          )}
        </Space>
      ),
    },
  ];
};
