import { Link } from "react-router-dom";
import { CommonActionHeaders } from "../TableActionHeaderColumn";
import { Badge, Space, Tag } from "antd";
import CustomBtnWithIcon from "../../components/buttonWithIcon";
import { ReactComponent as EditIcon } from "../../assets/rawSvgs/tableEditIcon.svg";
import { ReactComponent as DeleteIcon } from "../../assets/rawSvgs/tableDeleteIcon.svg";
import styled from "styled-components";

//
const ClientsColumens = ({ handleDelete = "", handleEditRow = "", handleSendForgetPasswordReq, isLoader }) => {
  return [
    {
      title: "Full Name",
      dataIndex: "name",
      key: "name",
      width: 150,

      render: (text, record) => (
        <Link to={"/company/clients/client-details/" + record.id} style={{ fontWeight: 500 }}>
          {record?.name}
        </Link>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 270,
      render: (text, record) => <Link to={"/company/clients/client-details/" + record.id}>{record?.user?.email}</Link>,
    },
    {
      title: "Phone No.",
      dataIndex: "phone_number",
      key: "phone_number",
      width: 220,
      render: (text, record) => <Link to={"/company/clients/client-details/" + record.id}>{record?.phone_number}</Link>,
    },
    {
      title: "Pets",
      key: "clientpet",
      dataIndex: "clientpet",
      width: 170,

      render: (_, record) => (
        <>
          <Space className="d-flex">
            <span>{record?.clientpet[0]?.name}</span>
            <>
              {record?.clientpet.length - 1 > 0 ? (
                <Badge
                  className="site-badge-count-109"
                  count={`+${record?.clientpet.length - 1}`}
                  style={{ backgroundColor: "#FBCF34" }}
                />
              ) : (
                <></>
              )}
            </>
          </Space>
        </>
      ),
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      width: 180,
      ellipsis: true,
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      width: 400,
      ellipsis: true,
      render: (_, record) => (
        <Space size="middle">
          <CustomBtnWithIcon
            btnText={"Send Password Recovery Email"}
            hideIcon
            noBackground
            onClick={() => handleSendForgetPasswordReq({ email: record?.user.email })}
            disabled={isLoader}
          />
          <Button
            onClick={(event) => {
              event.stopPropagation();
              handleEditRow && handleEditRow(record);
            }}
            disabled={isLoader}
          >
            <EditIcon />
          </Button>
          <Button
            onClick={(event) => {
              event.stopPropagation();
              handleDelete && handleDelete(record);
            }}
            disabled={isLoader}
          >
            <DeleteIcon />
          </Button>
        </Space>
      ),
    },
  ];
};

const ClientsDetailServiceColumens = ({ handleDelete = "", handleEditRow = "" }) => {
  return [
    {
      title: "Service Name",
      dataIndex: "name",
      key: "name",
      width: 150,

      render: (text, record) => (
        <Link to={""} style={{ fontWeight: 600 }}>
          {record?.user?.name}
        </Link>
      ),
    },
    {
      title: "Requested on",
      dataIndex: "age",
      key: "age",
      width: 200,
      render: (text, record) => <Link to={"/company/clients/client-details/" + record.id}>{record?.user?.email}</Link>,
    },
    {
      title: "Time block",
      dataIndex: "address",
      key: "address",
      width: 220,
    },
    {
      title: "Pets",
      key: "clientpet",
      dataIndex: "clientpet",
      width: 180,
    },
    {
      title: "Invoice",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Note",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Status",
      dataIndex: "address",
      key: "address",
    },
    ...CommonActionHeaders({ handleDelete, handleEditRow }),
  ];
};

export { ClientsColumens, ClientsDetailServiceColumens };

const Button = styled.button`
  border: none;
  background: none;
`;
