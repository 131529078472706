// usePubNubService.js (custom hook)

import { useState, useEffect } from "react";
import usePubNubService from "../patterns/InitializePubnub";
import { useDispatch } from "react-redux";
import { setUnreadCounts } from "../redux/slices/GeneralAppSlice/AppSlice";

//
const usePubNubServiceHook = ({ loggedInType, authState }) => {
  const pubnub = usePubNubService(loggedInType, authState);
  let dispatch = useDispatch();
  const location = window.location.href;

  const specificPaths = ["/company/scheduler", "/company/services", "/company/staff", "/company/message", "/company/clients"];

  let onlyCompId = loggedInType?.selectCompany?.[0]?.company_id || {};

  useEffect(() => {
    if (!pubnub) {
      return;
    }
    let uuid = pubnub?.getUUID();

    const { pathname } = window.location;

    if (specificPaths.some((path) => pathname.includes(path))) {
      fetchChannelsToCheckUnreadCount(uuid);
      //   console.log("Fetching channels at path: ", pathname);
      //   console.log("PIUB NUBBB >>>> (", { pubnub, location, pathname });
    }
  }, [location, pubnub, onlyCompId]);

  //   FUNCTION
  //
  const fetchChannelsToCheckUnreadCount = async (uuid) => {
    try {
      const response = await pubnub.objects.getAllChannelMetadata({ include: { customFields: true } });
      let getChannelList = response?.data?.filter((items) => {
        return items?.custom?.company_id === onlyCompId && !items.id.startsWith("chat.");
      });

      const unreadCounts = extractUnreadCounts(getChannelList, uuid);

      dispatch(setUnreadCounts(unreadCounts));
    } catch (error) {
      console.error("Error fetching channels:", error);
    }
  };
  const extractUnreadCounts = (channels, uuid) => {
    const unreadCounts = [];

    channels.forEach((channel) => {
      try {
        const { unreadCountData } = channel?.custom;
        const parsedData = JSON.parse(unreadCountData) || {};

        if (!parsedData) {
          return; // Skip if unreadCountData is not valid JSON
        }

        // Check for 1-on-1 chat format
        if (parsedData.reciever) {
          const { recieverId, unreadCount } = parsedData.reciever;
          if (recieverId === uuid && unreadCount > 0) {
            unreadCounts.push({
              channelId: channel.id,
              unreadCount,
              type: "1-on-1",
            });
          }
        }

        // Check for group chat format
        if (parsedData.recievers) {
          const recievers = parsedData.recievers;
          for (const recieverId in recievers) {
            const { unreadCount } = recievers[recieverId];
            if (recieverId === uuid && unreadCount > 1) {
              // Check if unreadCount is greater than 1
              unreadCounts.push({
                channelId: channel.id,
                unreadCount,
                type: "group",
              });
            }
          }
        }
      } catch (error) {
        // console.error(`Error parsing unreadCountData for channel ${error}:`);
      }
    });
    return unreadCounts;
  };

  const checkarond = (uuid) => {
    fetchChannelsToCheckUnreadCount(uuid);
  };
  return [fetchChannelsToCheckUnreadCount, checkarond];
};

export default usePubNubServiceHook;
