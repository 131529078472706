import { useNavigate } from "react-router-dom";
import HeadingComponent from "../components/Heading";
import CustomBtnWithIcon from "../components/buttonWithIcon";
import { useEffect } from "react";
import NoDataScreen from "../components/NoDataScreen";

function NoPage({ loggedAs }) {
  const navigate = useNavigate();
  useEffect(() => {
    handleDashboardClick();
  }, []);

  const handleDashboardClick = () => {
    if (loggedAs === "platform_dashboard") {
      navigate("/platform/dashboard");
    } else if (loggedAs === "client_dashboard") {
      navigate("/client/scheduler");
    } else if (loggedAs === "company_dashboard") {
      navigate("/company/scheduler");
    } else {
      navigate("/company");
    }
  };
  return (
    <div className="App d-flex justify-content-center align-items-center flex-column w-100" style={{ height: "80vh" }}>
      <div className="w-100 mb-3">
        <NoDataScreen
          heading={
            <div className="text-center">
              <HeadingComponent
                text="Sorry, we could not find this page"
                fontSize={"2.625rem"}
                color="#3b3b3b"
                fontWeight={500}
              />
              <HeadingComponent
                text="This is not the web page you are looking for."
                fontSize={"1.5rem"}
                color="#3b3b3b"
                fontWeight={400}
                className="my-3"
              />
            </div>
          }
          btnText={"Add new Client"}
          showBtn={false}
          // onClick={() => addNewClient()}
        />
      </div>
    </div>
  );
}

export default NoPage;
