import { Button, Divider, Form, Input, Popover } from "antd";
import React, { useState } from "react";
import styled from "styled-components";
import { CustomModal } from "../ModalStyles";
import { pushNotification } from "../../../util/Notification";
import CustomBtnWithIcon from "../../buttonWithIcon";
import { SketchPicker } from "react-color";

export default function CreateTagModal({ isModalOpen, handleCreateTagModal, loadTagsList, createTag }) {
  const [form] = Form.useForm();
  const [color, setColor] = useState("#DADEDF");

  const onFinish = () => {
    form
      .validateFields()
      .then((formValues) => {
        const updatedValues = {
          tag_name: formValues.name,
          abbreviation: formValues.abbreviation,
          description: formValues.description,
          color: color,
        };

        createTag(updatedValues)
          .then(({ data }) => {
            if (data.code) {
              form.resetFields();
              loadTagsList();
              handleCreateTagModal();
            }
          })
          .catch((error) => {
           pushNotification("Something went wrong!", "error");

            // console.log(error);
          });
      })
      .catch((error) => {
        pushNotification("Something went wrong!", "error");

        // console.log("Validation failed:", error);
      });
  };

  return (
    <CustomModal
      open={isModalOpen}
      title="Add a New Tag"
      width="600px"
      height="444px"
      onCancel={() => {
        handleCreateTagModal();
        form.resetFields();
      }}
      footer={null}
      maskClosable={false}
    >
      <Form name="createTag" onFinish={onFinish} form={form} layout="vertical">
        <FormItem
          name="name"
          label="Tag Name"
          rules={[
            {
              required: true,
              message: "Tag name is required",
            },
          ]}
        >
          <Input placeholder="Enter name" />
        </FormItem>
        <FormItem
          name="abbreviation"
          label="Tag Abbreviation"
          rules={[
            {
              required: true,
              message: "Abbreviation is required",
            },
          ]}
        >
          <Input placeholder="Enter words" />
        </FormItem>
        <FormItem name="color" label="Choose color">
          <Popover trigger="click" content={<SketchPicker color={color} onChange={(color) => setColor(color.hex)} />}>
            <Button style={{ backgroundColor: color, border: "none" }} />
          </Popover>
        </FormItem>
        <FormItem
          name="description"
          label="Description"
          rules={[
            {
              required: true,
              message: "Description is required",
            },
          ]}
        >
          <Input.TextArea placeholder="Type description" />
        </FormItem>
        <Divider />
        <div className="d-flex justify-content-end">
          <CustomBtnWithIcon
            btnText={"Cancel"}
            margin="0px 5px"
            noBackground
            hideIcon={true}
            onClick={() => {
              handleCreateTagModal();
              form.resetFields();
            }}
          />
          <CustomBtnWithIcon btnText={"Save Tag"} type="submit" hideIcon={true} />
        </div>
      </Form>
    </CustomModal>
  );
}

const FormItem = styled(Form.Item)`
  width: 100%;
`;
