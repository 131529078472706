export const SchedulerStatus = {
  SCHEDULED: "scheduled",
  IN_PROGRESS: "in_progress",
  COMPLETED: "completed",
  CHANGE_REQUESTED: "change_requested",
  UNASSIGNED: "unassigned",
  REQUESTED: "requested",
  CANCELLED: "cancelled",
  APPROVED: "approved",
  REJECTED: "rejected",
};
