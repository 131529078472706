import React from "react";
import { Line, Bar } from "react-chartjs-2";
function RevenueBarChart({componentRef, chartData, label }) {

    const data = {
        labels: chartData && chartData[0]?.labels,
        datasets: [{
            label: label,
            data: chartData && chartData[0]?.data,
            fill: false,
            borderColor: 'rgb(75, 192, 192)',
            tension: 0.1
        }]
    };
    return (

        <div ref={componentRef} style={{ width: '70%', margin: 'auto' }} id="chart-container">
            <h2 style={{ textAlign: "center" }}>{label}</h2>
            {/* {chartData && chartData[0]?.totalCount>=0 && <div>Total Pending Requests: {chartData[0].totalCount}</div>} */}
            <Bar
                data={data}
                options={
                    {
                        plugins: {
                            legend: {
                                display: false
                            }
                        },
                        scales: {
                            y: {
                              beginAtZero: true
                            }
                        }
                    }
                }
            />
        </div>
    );
}
export default RevenueBarChart;