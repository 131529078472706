import { Switch, Divider, Input, Radio, Row, Col } from "antd";
import React, { useState, useEffect } from "react";
import HeadingComponent from "../../../../../components/Heading";
import { staffDetailsTableHeaders } from "../../../../../constants/CompanyTableHeaders/StaffManagementHeaders";
import { useSelector } from "react-redux";
import { checkPermission } from "../../../../../util/commonUtil";
import PermissionModal from "../../../../../components/Modals/PermissionModal";
import { main_api } from "../../../../../api/axios_helper";
import { staffAPIsEndPoints } from "../../../../../constants/ApiEndPoints";
import { useParams } from "react-router-dom";
import CustomBtnWithIcon from "../../../../../components/buttonWithIcon";
import { pushNotification } from "../../../../../util/Notification";

const Configurations = ({ singleStaffState, handleFetchStaff }) => {
  const [selectedValue, setSelectedValue] = useState(
    singleStaffState?.hourly_rate && !singleStaffState?.is_job_rate
      ? "by the Hour"
      : singleStaffState?.is_job_rate && !singleStaffState?.hourly_rate
        ? "by the Job"
        : singleStaffState?.hourly_rate && singleStaffState?.is_job_rate
          ? "Both"
          : "",
  );
  const [permissionPopUp, setPermissionPopUp] = useState(false);
  const permissionManagement = useSelector((state) => state.permissionManagement);
  const [timeTracking, setTracking] = useState(singleStaffState.time_tracking_enabled);
  const [mileage, setMileage] = useState(singleStaffState.mileage_tracking_enabled);
  const [jobRateFlag, setJobRateFlag] = useState(singleStaffState?.is_job_rate);
  const [hourlyRate, setHourlyRate] = useState(parseInt(singleStaffState?.hourly_rate) || null);
  const [loader, setloader] = useState(false);
  let { staffId } = useParams();

  console.log(">>>", { singleStaffState, jobRateFlag });
  useEffect(() => {
    setTracking(singleStaffState.time_tracking_enabled);
    setMileage(singleStaffState.mileage_tracking_enabled);
  }, [singleStaffState]);

  const updateStatus = (tracking, mileage) => {
    const Objdata = {
      time_tracking_enabled: tracking,
      mileage_tracking_enabled: mileage,
      hourly_rate: !hourlyRate ? null : hourlyRate,
      is_job_rate: jobRateFlag,
    };

    const updateData = async () => {
      try {
        if (selectedValue === "Both") {
          if (!hourlyRate || hourlyRate < 1) {
            pushNotification("Hourly rate of staff is required when rate option is both or by hour", "error");
            return;
          }
        }
        setloader(true);

        let { data } = await main_api.put(staffAPIsEndPoints.UPDATE_NEW_STAFF(staffId), Objdata);
        setloader(false);
        if (data?.isSuccess) {
          pushNotification("Config saved successfully");
        }
      } catch (error) {
        setloader(false);
        console.error("Error updating data:", error);
      }
    };
    updateData();
  };

  console.log("hourlyRate", { hourlyRate, selectedValue });

  const handleRadioChange = (e) => {
    setSelectedValue(e.target.value);
    if (e.target.value === "by the Hour") {
      setJobRateFlag(false);
    } else if (e.target.value === "by the Job") {
      setJobRateFlag(true);
      setHourlyRate(null);
    } else if (e.target.value === "Both") {
      setJobRateFlag(true);
    } else {
      setJobRateFlag(true);
    }
  };

  const handleTimeTracking = (event) => {
    if (checkPermission(permissionManagement.permissions, "edit_staff")) {
      setTracking(event);
      updateStatus(event, mileage);
    } else {
      setPermissionPopUp(true);
    }
  };

  const handleMileageTracking = (e) => {
    if (checkPermission(permissionManagement.permissions, "edit_staff")) {
      setMileage(e);
      updateStatus(timeTracking, e);
    } else {
      setPermissionPopUp(true);
    }
  };

  const handleUpdateRate = () => {
    updateStatus(timeTracking, mileage);
  };

  return (
    <div className="mt-2">
      <HeadingComponent text={"Payment Method"} fontSize="1rem" fontWeight={500} />
      <HeadingComponent
        text={
          "Select the payment method for all staff members. Time Tracking and Mileage tracking is only available for companies that are using the hourly payment method."
        }
        color="#757575"
        margin={"20px 0px"}
        fontSize="0.875rem"
      />
      <Heading text="Set these options for rate" margin={"10px 0px"} />

      <div className="d-flex align-items-center">
        <Radio.Group onChange={handleRadioChange} className="d-flex align-items-center" value={selectedValue}>
          <Radio value="by the Hour">
            <HeadingComponent text={"By the Hour"} fontSize="0.875rem" color="#000000" fontWeight={400} />
          </Radio>

          <Radio value="by the Job">
            <HeadingComponent text={"By the Job"} fontSize="0.875rem" color="#000000" fontWeight={400} />
          </Radio>
          <Radio value="Both">
            <HeadingComponent text={"Both"} fontSize="0.875rem" color="#000000" fontWeight={400} />
          </Radio>
        </Radio.Group>
      </div>
      <div className="mt-3 mb-3">
        {!jobRateFlag && (
          <>
            <Heading text={selectedValue.toLocaleUpperCase()} />
            <Input
              placeholder="$ Enter Rate"
              style={{ height: "40px", width: "400px", marginTop: "10px" }}
              value={hourlyRate}
              type="number"
              onChange={(e) => {
                let amount = parseInt(e.target.value);
                if (amount >= 100000) return;

                setHourlyRate(amount);
              }}
              max={1000}
            />
          </>
        )}

        {selectedValue === "Both" && (
          <>
            <Heading text={selectedValue.toLocaleUpperCase()} />
            <Input
              placeholder="$ Enter Rate"
              style={{ height: "40px", width: "400px", marginTop: "10px" }}
              value={hourlyRate}
              type="number"
              onChange={(e) => {
                let amount = parseInt(e.target.value);
                if (amount >= 100000) return;

                setHourlyRate(amount);
              }}
              max={1000}
            />
          </>
        )}
      </div>
      <CustomBtnWithIcon btnText={"Update"} hideIcon onClick={handleUpdateRate} disabled={loader} />
      <Divider />
      <div className="mt-3 ">
        <Row>
          <Col md={10}>
            <Heading text="Time Tracking" fontSize="1rem" color="black" />
          </Col>
          <Col md={10}>
            <div className="d-flex flex-column">
              <span className="d-flex">
                <Switch
                  size="small"
                  className="me-2"
                  // defaultValue={singleStaffState.time_tracking_enabled}
                  checked={timeTracking}
                  onChange={handleTimeTracking}
                  disabled={loader}
                />
                <Heading text="Enabled" />
              </span>
              <span className="mt-1">
                <label>This will Track of staff member</label>
              </span>
            </div>
          </Col>
          <Divider />
          {/* Mileage tracking */}
          <Col md={10}>
            <Heading text="Mileage Tracking" fontSize="1rem" color="black" />
          </Col>
          <Col md={10}>
            <div className="d-flex flex-column">
              <span className="d-flex">
                <Switch size="small" className="me-2" checked={mileage} onChange={handleMileageTracking} disabled={loader} />
                <Heading text="Enabled" />
              </span>
              <span className="mt-1">
                <label>This will Track of staff service duration</label>
              </span>
            </div>
          </Col>
          <Divider />
        </Row>
      </div>
      <PermissionModal onclose={() => setPermissionPopUp(false)} openModal={permissionPopUp} />
    </div>
  );
};

export default Configurations;

const Heading = ({ text = "", margin, fontSize = "0.75rem", color = "#3B3B3B" }) => {
  return <HeadingComponent text={text} fontSize={fontSize} color={color} fontWeight={700} margin={margin} />;
};
