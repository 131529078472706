import React, { useEffect, useState } from "react";
import { TableHeader } from "../clients/clientStyles";
import SearchInput from "../../../components/SearchInput";
import CustomBtnWithIcon from "../../../components/buttonWithIcon";
import { AntdesignTable } from "../../../components/antDesignTable/AntdesignTable";
import dayjs, { Dayjs } from "dayjs";
import { DatePicker, Select } from "antd";
import AddPetModal from "../../../components/Modals/PetManagementModal/AddPetModal";
import EditPetModal from "../../../components/Modals/PetManagementModal/EditPetModal";
import { PetListColumns, VaccinationsColumns, VetListColumns } from "../../../constants/CompanyTableHeaders/PetTableHeaders";
import { main_api } from "../../../api/axios_helper";
import moment from "moment";
import AddVaccinationModal from "../../../components/Modals/PetManagementModal/AddVaccinationModal";
import AddVetModal from "../../../components/Modals/PetManagementModal/AddVetModal";
import EditVetModal from "../../../components/Modals/PetManagementModal/EditVetModal";
import { companyAPIsEndPoints } from "../../../constants/ApiEndPoints";
import DeleteConfirmationRowModal from "../../../components/Modals/DeleteConfirmationRowModal";
import { pushNotification } from "../../../util/Notification";
import NoDataScreen from "../../../components/NoDataScreen";
import { checkPermission } from "../../../util/commonUtil";
import PermissionModal from "../../../components/Modals/PermissionModal";
import { useSelector } from "react-redux";
const { RangePicker } = DatePicker;

const VetListTab = (props) => {
  const [search, setSearch] = useState("");
  const [isLoading, setIsloading] = useState(false);
  const [isAddVetOpen, setIsAddVetOpen] = useState(false);
  const [isEditVetOpen, setIsEditVetOpen] = useState(false);
  const [isDeleteVetOpen, setIsDeleteVetOpen] = useState(false);
  const [editVetValues, setEditVetValues] = useState({});
  const [deleteVetValues, setDeleteVetValues] = useState({});
  const { permissionManagement } = useSelector((state) => state);
  const [permissionPopUp, setPermissionPopUp] = useState(false);

  useEffect(() => {
    if (checkPermission(permissionManagement.permissions, "view_vets")) {
      props.loadVetList();
    }
  }, [permissionManagement.permissions]);

  const addVet = (formValues) => {
    setIsloading(true);
    main_api
      .post(companyAPIsEndPoints.POST_VET_LIST_PETS, formValues)
      .then(({ data }) => {
        setIsAddVetOpen(false);
        props.loadVetList();
        setIsloading(false);
      })
      .catch((error) => {
        if (error.response.data.result == "'Vet' object has no attribute 'user'") {
          pushNotification("Vet Added Successfully!", "success");
          props.loadVetList();
          setIsAddVetOpen(false);
          return;
        }
        pushNotification("Email Already Exist!", "error");
      });
  };

  const editVet = (formValues) => {
    setIsloading(true);
    main_api
      .put(`${companyAPIsEndPoints.PUT_SINGLE_VET_LIST_PETS(editVetValues.id)}`, formValues)
      .then(({ data }) => {
        setIsloading(false);
        pushNotification("Vet Updated Successfully!", "success");
        setIsEditVetOpen(false);
        props.loadVetList();
      })
      .catch((error) => {
        pushNotification("Something went Wrong!", "error");
        setIsEditVetOpen(false);
        console.log(error);
      });
  };

  const deleteVet = () => {
    setIsloading(true);

    main_api
      .delete(companyAPIsEndPoints.DELETE_SINGLE_Vet_PETS(deleteVetValues))
      .then(({ data }) => {
        setIsloading(false);
        pushNotification("Vet Deleted Successfully!", "success");
        setIsDeleteVetOpen(false);
        console.log(data);
      })
      .catch((error) => {
        pushNotification("Something went Wrong!", "error");
        setIsDeleteVetOpen(false);
        console.log(error);
      });
  };

  const handleEditRow = (record) => {
    if (checkPermission(permissionManagement.permissions, "edit_vet")) {
      if (record) {
        const { id } = record;
        setEditVetValues(record);
      }
      setIsEditVetOpen(true);
    } else {
      setPermissionPopUp(true);
    }
  };

  const handleDelete = (record) => {
    if (checkPermission(permissionManagement.permissions, "delete_vet")) {
      if (record) {
        const { id } = record;
        setDeleteVetValues(id);
      }
      setIsDeleteVetOpen(true);
    } else {
      setPermissionPopUp(true);
    }
  };

  const onBlurHandler = () => {
    props.searchVetList(search);
  };

  const addNewVet = () => {
    if (checkPermission(permissionManagement.permissions, "create_vet")) {
      setIsAddVetOpen(true);
    } else {
      setPermissionPopUp(true);
    }
  };

  return (
    <>
      <AddVetModal isLoading={isLoading} isModalOpen={isAddVetOpen} setModalOpen={setIsAddVetOpen} onFinish={addVet} />
      <EditVetModal
        isLoading={isLoading}
        isModalOpen={isEditVetOpen}
        setModalOpen={setIsEditVetOpen}
        onFinish={editVet}
        editVetValues={editVetValues}
      />
      <DeleteConfirmationRowModal
        onclose={() => setIsDeleteVetOpen(false)}
        handleConfirmDeleteRows={deleteVet}
        openConfimrationDeleteModal={isDeleteVetOpen}
      />
      {props.vetList?.length > 0 && (
        <TableHeader>
          <div className="d-flex align-items-center col">
            <SearchInput search={search} onChange={(value) => setSearch(value)} onBlur={onBlurHandler} />
            <CustomBtnWithIcon
              btnText={"Add new Vet"}
              className="ms-md-auto"
              margin="10px 10px 10px auto"
              onClick={() => addNewVet()}
            />
          </div>
        </TableHeader>
      )}
      {props.vetList?.length > 0 ? (
        <AntdesignTable columns={VetListColumns({ handleDelete, handleEditRow })} data={props.vetList} />
      ) : (
        <NoDataScreen
          heading="You don’t have any Vets in your list. Let’s get started by adding a new Vet"
          btnText={"Add new Vet"}
          onClick={() => addNewVet()}
        />
      )}
      <PermissionModal onclose={() => setPermissionPopUp(false)} openModal={permissionPopUp} />
    </>
  );
};

export default VetListTab;
