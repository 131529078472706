import React from "react";
import { ConfigProvider, Tabs } from "antd";

//
const AntDesignNavTabs = ({ defaultActiveKey = "1", navTabs, onChange, tabBarGutter = 55, className = "" }) => {
  return (
    <div style={{ width: "100%" }}>
      <ConfigProvider
        theme={{
          components: {
            Tabs: {
              itemActiveColor: "rgba(0, 0, 0, 0.8)",
              itemColor: "#757575",
              itemSelectedColor: "rgba(0, 0, 0, 0.8)",
              inkBarColor: "#FBCF34",
              fontWeight: "bold",
            },
          },
        }}
      >
        <Tabs
          defaultActiveKey={defaultActiveKey}
          items={navTabs}
          onChange={onChange}
          tabBarGutter={tabBarGutter}
          tabBarStyle={{ fontWeight: 500, width: "100%", fontFamily: "'Helvetica Neue', sans-serif" }}
          className={className}
        />
      </ConfigProvider>
    </div>
  );
};

export default AntDesignNavTabs;
