import React from "react";
import { DatePicker, Divider, Flex, Form, Input, message, Modal, Select, Switch, Typography, Upload } from "antd";
import { ReactComponent as DefaultImageIcon } from "../../../assets/rawSvgs/defaultImage.svg";
import CustomInput from "../../CustomInput";
import styled from "styled-components";
import CustomBtnWithIcon from "../../buttonWithIcon";
import dayjs from "dayjs";
const { Dragger } = Upload;

const EditCompanyModal = ({ isLoading, isModalOpen, setModalOpen, onFinish, editCompanyValues }) => {
  const [form] = Form.useForm();
  form.setFieldsValue({
    name: editCompanyValues?.name,
    admin_name: editCompanyValues?.admin_name,
    email: editCompanyValues?.email,
    date_added: editCompanyValues?.date_added,
  });
  return (
    <CustomModal
      open={isModalOpen}
      title="Edit a Company"
      width="600px"
      heigth="394px"
      onCancel={() => {
        setModalOpen(false);
        form.resetFields();
      }}
      footer={null}
      maskClosable={false}
    >
      <Divider />
      <Form name="editCompanyForm" onFinish={onFinish} form={form} layout="vertical">
        <FormItem
          name="name"
          label="Company Name"
          rules={[
            {
              required: true,
              message: "Company Name is required",
            },
            {
              max: 30,
              min: 1,
            },
          ]}
        >
          <Input placeholder="Enter Company Name" required />
        </FormItem>
        <FormItem
          name="email"
          label="Email"
          rules={[
            {
              required: true,
              message: "Email is required",
            },
            {
              type: "email",
              message: "Please enter a valid email address",
            },
          ]}
        >
          <Input disabled placeholder="Enter Email" />
        </FormItem>
        <FormItem
          name="admin_name"
          label="Company Admin Name"
          rules={[
            {
              required: true,
              message: "Company Admin Name is required",
            },
            {
              max: 30,
              min: 1,
            },
          ]}
        >
          <Input placeholder="Company Admin Name" />
        </FormItem>
        <FormItem className="registered-on-date" name="date" label="Registered On">
          <DatePicker
            style={{ width: "100%" }}
            type="date"
            format={"DD/MM/YYYY"}
            disabled={true}
            defaultValue={dayjs(editCompanyValues?.date_added)}
          />
        </FormItem>
        <Divider />
        <div className="d-flex justify-content-end">
          <CustomBtnWithIcon
            btnText={"Cancel"}
            margin="0px 5px"
            noBackground
            hideIcon={true}
            onClick={() => setModalOpen(false)}
          />
          <CustomBtnWithIcon btnText={"Save Changes"} type="submit" hideIcon={true} />
        </div>
      </Form>
    </CustomModal>
  );
};

const FieldWraper = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
`;

const FormItem = styled(Form.Item)`
  width: 100%;
`;

const CustomModal = styled(Modal)`
  .ant-modal-header .ant-modal-title {
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
  }
  .ant-form-item .ant-form-item-label .ant-form-item-required {
    font-size: 12px;
    font-weight: 700;
    color: #383838;
  }
  .ant-form-vertical .ant-form-item-label {
    padding: 0 0 2px;
  }
  .registered-on-date .ant-form-item-label label {
    font-size: 12px;
    font-weight: 700;
    color: #212121;
  }
  .welcome-message-checkbox .ant-form-item-row {
    flex-direction: row;
    gap: 10px;
    align-items: baseline;
  }
  .welcome-message-checkbox .ant-form-item-control {
    width: auto;
    order: -1;
    flex: 0;
  }
  .mark-active-switch .ant-form-item-row {
    flex-direction: row;
    align-items: baseline;
  }
  .mark-active-switch .ant-form-item-control {
    width: auto;
  }
`;

export default EditCompanyModal;
