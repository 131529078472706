import { createSlice } from "@reduxjs/toolkit";
import { main_api } from "../../api/axios_helper";
import { companyAPIsEndPoints } from "../../constants/ApiEndPoints";

const initialState = {
  isLoading: false,
  error: false,
  companyUsersListResponse: {},
};

const slice = createSlice({
  name: "companyUsers",
  initialState,
  reducers: {
    loadCompanyUsersListByRoleSlice(state, action) {
      state.isLoading = action.payload.isLoading;
      state.error = action.payload.error;
      state.companyUsersListResponse = action.payload.companyUsersListResponse;
    },
  },
});

export const { loadCompanyUsersListByRoleSlice } = slice.actions;

export default slice.reducer;

export const loadCompanyUsersListByRole = (roleId) => async (dispatch, getState) => {
  const companyId = getState().auth?.loginResponse?.selectCompany?.[0]?.company_id;
  try {
    dispatch(loadCompanyUsersListByRoleSlice({ isLoading: true, error: false }));
    const { data } = await main_api.get(
      companyAPIsEndPoints.FETCH_COMPANY_USERS(companyId, roleId),
    );
    dispatch(loadCompanyUsersListByRoleSlice({ isLoading: false, error: false, companyUsersListResponse: data }));
  } catch (error) {
    dispatch(loadCompanyUsersListByRoleSlice({ isLoading: false, error: error }));
  }
};
// export const openDetailModal = (isOpen) => (dispatch) => {
//   dispatch(openDetailModalSlice({isdetailModalOpen: isOpen}));
// };

