import React from 'react';
import { Select } from 'antd';

const { Option } = Select;

export default function FontPicker ({ fonts, selectedFont, setSelectedFont, disabled}) {
  return (
    <Select
      disabled={disabled}
      value={selectedFont}
      onChange={value => setSelectedFont(value)}
      style={{ width: "100%" }}
    >
      {fonts.map((font, index) => (
        <Option key={index} value={font} style={{ fontFamily: font }}>
          {font}
        </Option>
      ))}
    </Select>
  );
};


