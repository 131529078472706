import React from 'react';
import { Row, Col, Card, Button } from 'antd';

export default function MonthlyPlan({ subscriptionPlans, companySubscriptionPlan, handleSelectSubscriptionPlan, platform=false }) {
    const handleSelectPlan = (plan) => {
        handleSelectSubscriptionPlan(plan);
    };

    const monthlyPlans = subscriptionPlans.filter(p => p.plan_type === "monthly");
    const sortedSubscriptionPlans = monthlyPlans.sort((a, b) => {
        if (a.id < b.id) return -1;
        if (a.id > b.id) return 1;
        return 0;
    });

    // Dummy feature list
    const dummyFeatures = [
        "Feature 1",
        "Feature 2",
        "Feature 3",
    ];

    return (
        <div style={{ padding: '20px', flexDirection:"row"}}>
            <Row gutter={[16, 16]}>
                {sortedSubscriptionPlans.map((plan, index) => (
                    <Col span={8} key={index}>
                            <Card
                                title={<CardTitle title={plan.name} price={`${plan.cost_unit}${plan.cost}`} type={plan.plan_type} description={plan.description} />}
                                bordered={companySubscriptionPlan === plan.id}
                                style={{
                                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                    borderTop: '14px solid rgb(255, 196, 96)',
                                    width: '90%',
                                    border: companySubscriptionPlan === plan.id ? '2px solid #fad149' : 'none'
                                }}
                                extra={companySubscriptionPlan === plan.id && (
                                    <div className="current-plan" style={{
                                        position: "absolute",
                                        top: "60px",
                                        right: "-3px",
                                        borderTopLeftRadius: "70px",
                                        borderBottomRightRadius: "70px",
                                        backgroundColor: "#fad149",
                                        color: "black",
                                        padding: "5px 20px",
                                        transform: "rotate(+45deg)",
                                        transformOrigin: "top right",
                                    }}>Current Plan</div>
                                )}
                                cover={companySubscriptionPlan !== plan.id && (<div style={{ position: "absolute",
                                top: "-6px",
                                right: "-1px",
                                borderTopLeftRadius:"14px",
                                borderTopRightRadius:"14px",
                                backgroundColor: 'rgb(255, 196, 96)', 
                                height: '20px',
                                width:"100%" }} />)}
                            >
                                <div>
                                    <h3 style={{ marginTop: '20px', textAlign: 'center', fontSize:"20px"  }}>Features</h3>
                                    <ul>
                                        {plan.features.map((feature, index) => (
                                            <li key={index}>{feature}</li>
                                        ))}
                                    </ul>
                                </div>
                                <div style={{ textAlign: 'center' }}>
                                    <Button type="primary" style={{ width: '80%', marginTop: '20px' }} onClick={() => handleSelectPlan(plan)}>{platform? 'Edit': 'Select'}</Button>
                                </div>
                            </Card>
                      
                    </Col>
                ))}
            </Row>
        </div>
    );
}

const CardTitle = ({ title, price, type, description }) => (
    <div style={{ textAlign: 'center', marginTop: "28px" }}>
        {title}
        <span className='d-flex' style={{ justifyContent: 'center' }}>
            <p style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '38px', marginRight: '5px' }}>{price}</p>
            <p style={{ textAlign: 'center', fontSize: '18px', color: "grey", alignSelf: 'center' }}>/ {type}</p>
        </span>
        <p style={{ textAlign: 'center', fontSize: '12px', color: "grey", alignSelf: 'center' }}>{description}</p>
    </div>
);

