import React from "react";
import styled from "styled-components";
import { ReactComponent as DefaultImageIcon } from "../../../assets/images/uploadImage.svg";
import InputField from "../../../components/Form/Input";
import DatePickerField from "../../../components/Form/DatePicker";
import SelectField from "../../../components/Form/Select";
import TextareaField from "../../../components/Form/Textarea";
import ImageUploader from "../../../components/Form/ImageUploader";
import Tags from "../../../components/Form/Tags";
import FormDetail from "./FormDetail";
import { Form, Input, Select, DatePicker } from "antd";

const { TextArea } = Input;

function GeneralInfo({image, setImage}) {
  const genderOptions = [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
  ];
  const tagsData = ["Bad_karma", "coldplay", "Jack_harlow", "Cute_cat"];
  return (
    <>
      <FormDetail title="General Information" description="We will take some info to customize your setup experience" />
      <UploadWraper>
        <Form.Item>
          <ImageUploader DefaultImageIcon={DefaultImageIcon} label="Profile Picture" image={image} setImage={setImage} />
        </Form.Item>
      </UploadWraper>
      <FieldWraper>
        <Form.Item label="Username" name="username" className="w-100 mb-3">
          <Input
            placeholder="Enter Username"
          />
        </Form.Item>
      </FieldWraper>
      {/* <FieldWraper>
        <FormItem name="micro_chip" label="Microchip ID">
          <Tags label="Suggestions:" classes="d-flex" tagsData={tagsData} />
        </FormItem>
      </FieldWraper> */}
      <FieldWraper>
        <Form.Item
          className="w-100 mb-3"
          name="dob"
          label="Birth Date"
          rules={[
            {
              required: true,
              message: "Please select a Birth Date",
            },
          ]}
        >
          <DatePicker className="w-100" label="Date of Birth" placeholder="Select Date of Birth" type="date" />
        </Form.Item>
      </FieldWraper>
      <FieldWraper>
        <Form.Item label="Gender" className="w-100 mb-3" name="gender">
          <Select placeholder="choose your Gender" options={genderOptions} />
        </Form.Item>
      </FieldWraper>
      <FieldWraper>
        <Form.Item className="w-100 mb-3" label="Bio" name="bio">
          <TextArea placeholder="Add Bio to your Profile" size="large" />
        </Form.Item>
      </FieldWraper>
    </>
  );
}

export default GeneralInfo;


const FieldWraper = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
`;
const UploadWraper = styled.div`
  display: inline-block;
  gap: 16px;
  width: 100%;
`;

const FormItem = styled.div`
  width: 100%;
  Tags {
    display: flex;
  }
`;
