// import React, { useState } from 'react';
// import { Collapse } from 'antd';
// import moment from 'moment';
// import styled from 'styled-components';
// import { ReactComponent as RouteInnerIcon } from "../../assets/companySvgIcons/sidebarIcons/routeInner.svg";

// const StaffRoutes = ({routeData, handleReverseGeocode, onSelectStaff, isPanelOpen, setIsPanelOpen}) => {
//     const [activeKey, setActiveKey] = useState([]);
  

//     // Time Formater
//     function formatTime(timestamp) {
//         return moment.unix(timestamp).format('hh:mm A');
//     }
//     // Panel Colaps Handler
//     const handleCollapseChange = key => {
//         setActiveKey(key);
//         setIsPanelOpen(!isPanelOpen);
//     };


//     // Function to handle selection of staff
//     const handleStaffSelection = (staffId) => {
//         // setSelectedStaff(staffId);
    
//         onSelectStaff(staffId);
//     };

//     return (
//         <>
        
//         {routeData.map(route => (
//             <div key={route.id} style={{ marginBottom: '16px' }}>
//                 <Collapse
//                     activeKey={activeKey}
//                     onChange={handleCollapseChange}
//                     expandIconPosition='end'
//                 >
//                     <Collapse.Panel
//                         header={
//                             <div style={{ display: 'flex', alignItems: 'center' }} onClick={() => handleStaffSelection(route.staff)}>
//                                 {route?.staff?.name}
//                             </div>
//                         }
//                         key={route.id.toString()}
//                     >
//                         <div>
//                             {route.route.steps.map((step, index) => {
//                                 const nextStep = route.route.steps[index + 1];
//                                 if (nextStep) {
//                                     return (
//                                         <div key={index}>
//                                             {step.detail && nextStep.detail && (
//                                                 <>
//                                                     <span className='d-flex' style={{ flexDirection: "row" }}>
//                                                         <p><RouteInnerIcon /></p>
//                                                         <p style={{ marginLeft: "10px" }}>
//                                                             {step.detail.address} - {nextStep.detail.address}
//                                                         </p>
//                                                     </span>
//                                                     <span className='d-flex' style={{ flexDirection: "row", marginLeft: "25px" }}>
//                                                         <p style={{ marginRight: "8px" }}>{route.date}</p>
//                                                         <p style={{ marginLeft: "8px" }}>{formatTime(step.arrival)}</p>
//                                                         <hr />
//                                                     </span>
//                                                 </>
//                                             )}
//                                         </div>
//                                     );
//                                 }
//                                 return null;
//                             })}
//                         </div>
//                     </Collapse.Panel>
//                 </Collapse>
//             </div>
//         ))}

//         </>
//     );
// };



// export default StaffRoutes;




/// New Logic




import React, { useState } from 'react';
import { Collapse } from 'antd';
import moment from 'moment';
import styled from 'styled-components';
import { ReactComponent as RouteInnerIcon } from "../../assets/companySvgIcons/sidebarIcons/routeInner.svg";

const StaffRoutes = ({routeData, handleReverseGeocode, onSelectStaff, setObjectId, isPanelOpen, setIsPanelOpen}) => {
    const [activeKey, setActiveKey] = useState([]);


    // Time Formater
    function formatTime(timestamp) {
        return moment.unix(timestamp).utc().format('hh:mm A');
    }
    
    function convertTimeFormat(timeStr) {
        return moment(timeStr, "HH:mm:ss").format("hh:mm A");
    }
      
    // Panel Colaps Handler
    const handleCollapseChange = key => {
        setActiveKey(key);
        setIsPanelOpen(!isPanelOpen);
    };


    // Function to handle selection of staff
    const handleStaffSelection = (staffId, objectId) => {
        // setSelectedStaff(staffId);
        setObjectId(objectId);
        onSelectStaff(staffId);
    };

    return (
        <>
        
        {routeData.map(route => (
            
            <div key={route.id} style={{ marginBottom: '16px' }}>
                <Collapse
                    activeKey={activeKey}
                    onChange={handleCollapseChange}
                    expandIconPosition='end'
                >
                    
                    <Collapse.Panel
                        header={
                            <div style={{ display: 'flex', alignItems: 'center' }} onClick={() => handleStaffSelection(route.staff, route.id)}>
                                {route?.staff?.name}
                            </div>
                        }
                        key={route.id.toString()}
                    >
                        <div>
                            {route.route.steps.map((step, index) => {
                                const stepType = step.type;
                                let lastStep = null;

                                // Find the last job step before the current step
                                for (let i = 0; i < route.route.steps.length; i++) {
                                    if (route.route.steps[i].type === "job" || route.route.steps[i].type === "delivery") {
                                        lastStep = route.route.steps[i];
                                    }
                                    if (route.route.steps[i].type === "end") {
                                        break;
                                    }
                                }
                                if (stepType === "end") {
                                   
                                    return (
                                        <div key={index}>
                                          
                                                <>
                                                    <span className='d-flex' style={{ flexDirection: "row" }}>
                                                        <p><RouteInnerIcon /></p>
                                                        <p style={{ marginLeft: "6px" }}>{
                                                        step?.type === "pickup" ? "Key" : step?.type === "delivery" ? "Job" : step?.type.charAt(0).toUpperCase() + step?.type.slice(1)
                                                        }:</p>
                                                        <p style={{ marginLeft: "10px" }}>
                                                            {lastStep?.detail?.address}
                                                        </p>
                                                    </span>
                                                    <span className='d-flex' style={{ flexDirection: "row", marginLeft: "65px" }}>
                                                        <p style={{ marginRight: "8px" }}>{route.date}</p>
                                                        <p style={{ marginLeft: "8px" }}>{convertTimeFormat(lastStep?.detail?.end_time)}</p>
                                                        <hr />
                                                    </span>
                                                    {/* <span >
                                                        <p style={{ marginLeft: "75px" }}>.</p>
                                                        <p style={{ marginLeft: "75px" }}>.</p>
                                                        <p style={{ marginLeft: "75px" }}>.</p>
                                                    </span> */}
                                                </>
                                       
                                        </div>
                                    );
                                }else {
                                    return (
                                        <div key={index}>
                                            {step.detail && (
                                                <>
                                                    <span className='d-flex' style={{ flexDirection: "row" }}>
                                                        <p><RouteInnerIcon /></p>
                                                        <p style={{ marginLeft: "6px" }}>{
                                                            step?.type === "pickup" ? "Key" : step?.type === "delivery" ? "Job" : step?.type.charAt(0).toUpperCase() + step?.type.slice(1)

                                                        }:</p>
                                                        <p style={{ marginLeft: "10px" }}>
                                                            {step?.detail?.address}
                                                        </p>
                                                    </span>
                                                    <span className='d-flex' style={{ flexDirection: "row", marginLeft: "65px" }}>
                                                        <p style={{ marginRight: "8px" }}>{route.date}</p>
                                                        <p style={{ marginLeft: "8px" }}>
                                                            {
                                                                step?.type === "start" || step?.type === "pickup" ? step?.detail?.arrival :
                                                               <>
                                                               
                                                               {convertTimeFormat(step?.detail?.start_time)} - {convertTimeFormat(step?.detail?.end_time)}
                                                               </> 
                                                            }
                                                            </p>
                                                        <hr />
                                                    </span>
                                                    {/* <span >
                                                        <p style={{ marginLeft: "75px" }}>.</p>
                                                        <p style={{ marginLeft: "75px" }}>.</p>
                                                        <p style={{ marginLeft: "75px" }}>.</p>
                                                    </span> */}
                                                </>
                                            )}
                                        </div>
                                    );
                                }
                                return null;
                            })}
                        </div>
                    </Collapse.Panel>
                </Collapse>
            </div>
        ))}

        </>
    );
};



export default StaffRoutes;





