import React, { useEffect, useState } from "react"
import { Tabs } from 'antd';
import styled from "styled-components";
import { ReportingContainer, ReportingItem } from "./ReportDetail";
import FinancialReportListing from "./FinancialReportListing";

function FinancialReport({saveReport, setCurrentReport, setSaveReport, currentReport, saveReportCallBack}) {

  const timeFilter = [
    {label: 'Revenue Over Time', id: 0,  value: 'revenue_over_time'},
    {label: 'Service Revenue', id: 1,  value: 'service_revenue'},
    {label: 'Revenue Per Month', id: 2,  value: 'revenue_per_month'},
    {label: 'Total Amount', id: 3,  value: 'total_amount'},
  ]

  const [activeTab, setActiveTab] = useState(0);

  const handleChangeTabs = (tabs) => {
    setActiveTab(tabs);
  };

  return (
    
    <>
    <ReportingContainer>
        <ReportingItem>
            <TabHeader>Revenue</TabHeader>
            <TabItem
                activeKey={activeTab}
                onChange={handleChangeTabs}
                tabPosition="left"
                items={timeFilter.slice(0,4).map((val, i) => {
                    return {
                        label: `${val.label}`,
                        key: val.id,
                    };
                })}
            >
            </TabItem>
        </ReportingItem>
        <FilterDetail>
          <FinancialReportListing saveReportCallBack={saveReportCallBack} saveReport={saveReport} setSaveReport={setSaveReport} filter={timeFilter[activeTab].value} currentReport={currentReport} setCurrentReport={setCurrentReport}/>
        </FilterDetail>
        
    </ReportingContainer>
    </>
  )
}

export default FinancialReport;

const TabHeader = styled.div`
    color: #424242;
    font-weight: 700;
`;

const TabItem = styled(Tabs)`
    margin-top: 20px;
    .ant-tabs-tab-active{
        background-color: #EEEEEE;
    }
    .ant-tabs-content-holder{
      border-left: none;
    }
    .ant-tabs-tab-active .ant-tabs-tab-btn{
        color: #424242 !important;
    }
    .ant-tabs-tab {
        color: #424242 !important;
    }
    .ant-tabs-tab-btn{
        color: #424242 !important;
    }
    .ant-tabs-nav-operations {
        display: none !important; 
    }

    .ant-tabs-nav{
      width: 100%;
      min-width: 50px;
    }
`;

const FilterDetail = styled.div`
  width: 100%;
  margin: 0 20px;
`;
