import React, { useEffect, useState } from "react";
import { Input, Divider, Form, Select } from "antd";
import styled from "styled-components";
import { CustomModal } from "../ModalStyles";
import CustomBtnWithIcon from "../../buttonWithIcon";
import { companyAPIsEndPoints } from "../../../constants/ApiEndPoints";
import { pushNotification } from "../../../util/Notification";
import { main_api } from "../../../api/axios_helper";
import { useSelector } from "react-redux";
import LocationSelector from "../../CustomAddressInput/locationSelector";

const AddUserModal = ({ selectedUser, isModalOpen, setModalOpen, roles, fetchAllUser }) => {
  const [form] = Form.useForm();
  const auth = useSelector((state) => state.auth);
  const [routeOptimizorChecked, setRouteOptimizorChecked] = useState(true);
  const [address, setAddress] = useState("");
  const [addressLatAndLong, setAddressLatAndLong] = useState([]);

  useEffect(() => {
    form.setFieldsValue({
      name: selectedUser ? selectedUser.name : null,
      email: selectedUser ? selectedUser.user.email : null,
      address_1: selectedUser ? selectedUser.user.address : "",
      phone_number: selectedUser ? selectedUser.phone_number : null,
      role: selectedUser ? selectedUser.usercompanyrole.role.id : null,
    });
    setAddress(selectedUser ? selectedUser.user.address : "");
  }, [selectedUser]);

  const handleSubmit = async (formValues) => {
    try {
      let companyId = auth.loginResponse?.selectCompany?.[0]?.company_id || null;
      formValues["company"] = companyId;
      formValues = { ...formValues, latitude: addressLatAndLong[0], longitude: addressLatAndLong[1] };

      if (selectedUser) {
        const { data } = await main_api.put(companyAPIsEndPoints.SINGLE_COMPANY_USER(selectedUser.id), formValues);
        if (data.isSuccess) {
          pushNotification("User updated successfully!");
          fetchAllUser();
          setModalOpen(false);
        } else {
          pushNotification(data.message);
        }
      } else {
        const { data } = await main_api.post(companyAPIsEndPoints.COMPANY_USER, formValues);
        if (data.isSuccess) {
          pushNotification("User created successfully!");
          fetchAllUser();
          setModalOpen(false);
        } else {
          pushNotification(data.message, "error");
        }
      }
    } catch (error) {
      pushNotification("An error occurred while processing your request.", "error");
    }
  };

  return (
    <CustomModal
      open={isModalOpen}
      title={selectedUser ? "Edit User" : "Add a New User"}
      onCancel={() => setModalOpen()}
      footer={null}
      maskClosable={false}
    >
      <Divider style={{ margin: "12px 0" }} />
      <Form name="addUserForm" onFinish={handleSubmit} form={form} layout="vertical">
        <FormItem
          name="name"
          label="User Name"
          rules={[
            {
              required: true,
              message: "Username is required",
            },
            {
              max: 50,
            },
          ]}
        >
          <Input size="large" placeholder="Enter User Name" />
        </FormItem>
        <FormItem
          name="email"
          label="Email ID"
          rules={[
            {
              required: true,
              message: "Email is required",
            },
          ]}
        >
          <Input size="large" placeholder="Enter Email Address" disabled={selectedUser ? true : false} />
        </FormItem>
        <LocationSelector
          label={"Address"}
          address={address}
          setAddress={setAddress}
          setAddressLatAndLong={setAddressLatAndLong}
          form={form}
          checked={routeOptimizorChecked}
        />

        <FormItem
          name="phone_number"
          label="Phone Number"
          rules={[
            {
              max: 50,
            },
          ]}
        >
          <Input size="large" placeholder="Enter Phone Number" type="number" />
        </FormItem>
        <FormItem
          name="role"
          label="Role"
          rules={[
            {
              required: true,
              message: <div style={{ marginTop: "10px" }}>Role is required</div>,
            },
          ]}
        >
          <Select options={roles} placeholder="Select Role" />
        </FormItem>
        {/* <FormItem
            name="department"
            label="Department"
        >
            <Select/>
        </FormItem> */}
        <Divider />
        <div className="d-flex justify-content-end">
          <CustomBtnWithIcon
            btnText={"Cancel"}
            type="button"
            margin="0px 5px"
            noBackground
            hideIcon={true}
            onClick={() => setModalOpen()}
          />
          <CustomBtnWithIcon btnText={"Save User"} type="submit" hideIcon={true} />
        </div>
      </Form>
    </CustomModal>
  );
};

export default AddUserModal;

const FormItem = styled(Form.Item)`
  margin-bottom: 18px;
`;
