import { SchedulerMonthScheduledIcon, TimeIcon, Status, SchedulerMonthUserIcon } from "../../assets/rawSvgs/index";
import styled from "styled-components";
import { formatTimefromDate  } from "../../util/formatTime";
import {schedulerStatus} from './helpers'

const ClientEvent = ({ event }) => {
    return (
      <div style={{gap: '10px'}} className="ms-1 d-flex flex-column"
      >
        <FlexItem className="d-flex align-items-center">
            <span>
              <SchedulerMonthScheduledIcon width="16px" height="16px" stroke={event.color} />
            </span>
            <span className="rbc-text">{event.title}</span>
        </FlexItem>
        <FlexItem className="d-flex align-items-center">
          <span>
            <SchedulerMonthUserIcon width="16px" height="16px"  stroke={event.color} />
          </span>
          <span  className="rbc-text">{(event.staff && event.staff.name) || ''}</span>
        </FlexItem>
        <FlexItem className="d-flex align-items-center">
            <span>
              <TimeIcon width="16px" height="16px" stroke={event.color} />
            </span>
            <span  className="rbc-text">{formatTimefromDate(event.start)} - {formatTimefromDate(event.end)} </span>
        </FlexItem>
        <FlexItem className="d-flex align-items-center">
            <span>
              <Status width="16px" height="16px" stroke={event.color} />
            </span>
            <span  className="rbc-text">{schedulerStatus(event) || ''}</span>
        </FlexItem>
      </div>
    );
  };

  export default ClientEvent;


const FlexItem = styled.div`
  gap: 10px;
  width: calc(100% + 4px)
`;
  