import StaffDetailManagement from "../../pages/company/staff/index";
import { connect } from "react-redux";

import { fetchSingleStaffApi, fetchAllStaffApi } from "../../redux/slices/StaffSlice";

const mapStateToProps = (state) => {
  return {
    singleStaffresponseData: state.staffManagement.staffSingleDetails.responseData,
    loginState: state.auth.loginResponse,
    allStaffState: state.staffManagement.staffAllDetails,
    singleStaffstate: state.staffManagement.staffSingleDetails,
    StaffState: state.staffManagement,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchSingleStaff: (id) => {
    dispatch(fetchSingleStaffApi(id));
  },
  fetchAllStaff: (searchterm) => {
    dispatch(fetchAllStaffApi(searchterm));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(StaffDetailManagement);
