import { Col, Row } from "antd";

import CustomBtnWithIcon from "../../../components/buttonWithIcon";

import { main_api } from "../../../api/axios_helper";

import { pushNotification } from "../../../util/Notification";
import AntDesignModal from "../../../components/Modals/AntDesignModal/AntDesignModal";
import HeadingComponent from "../../../components/Heading";
import { ReactComponent as ConfirmIcon } from "../../../assets/rawSvgs/undraw_faq_re_31cw1.svg";
import { useEffect, useState } from "react";
import AntDesignDropDownSelect from "../../../components/AntDesignDropDownSelect/AntDesignDropDownSelect";
import { useSelector } from "react-redux";

import { loadStripe } from "@stripe/stripe-js";

const REACT_APP_STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY;

const AddNewClientForm = ({
  openModal,
  handleClose,
  onSuccess,
  companyId,
  invoiceId,
  fetchSingleInvoice,
  invoiceSingleDetail,
  clientId,
  invoicingState,
}) => {
  const [totalAttachedCards, setTotalAttachedCards] = useState([]);
  const [loader, setLoader] = useState(false);
  const [selectedCardPaymentId, setSelectedCardPaymentId] = useState("");
  const [paymentLoader, setPaymentLoader] = useState(false);
  const [paymentConfirmModal, setPaymentConfirmModal] = useState(false);
  const authInfo = useSelector((state) => state.auth.loginResponse);

  const compInfo = authInfo?.selectCompany?.[0] || {};

  const clientManagement = useSelector((state) => state?.clientManangement.clientSingleDetails.responseData);
  let stripeCompanyStripeId = compInfo.stripe_account_id ? compInfo.stripe_account_id : "";
  const [stripePromise, setStripePromise] = useState(null);
  let url = `/api/v1/stripe/accounts/${stripeCompanyStripeId}/customers/${clientManagement?.stripe_customer_id}/payment-methods/`;

  useEffect(() => {
    const instStripe = async () => {
      try {
        const stripeInstance = loadStripe(REACT_APP_STRIPE_KEY, {
          stripeAccount: stripeCompanyStripeId,
        });
        const stripe = await stripeInstance;
        setStripePromise(stripe);
      } catch (error) {
        console.log("ERROR:", error);
      }
    };
    instStripe();
  }, [compInfo.stripe_account_id]);
  useEffect(() => {
    if (invoiceId) {
      fetchSingleInvoice(invoiceId);
    }
  }, [invoiceId]);

  useEffect(() => {
    handleGetAddedCard();
  }, []);

  // =====
  const stripe = stripePromise;
  const handleSubmit = async () => {
    try {
      setPaymentLoader(true);
      const { data } = await main_api.post(`/api/v1/stripe/create-payment-intent/`, {
        client_id: clientId,
        invoice_id: invoiceId,
        payment_method_id: selectedCardPaymentId,
      });

      const result = await stripe?.confirmCardPayment(data?.client_secret, {
        payment_method: selectedCardPaymentId, // Replace with your actual payment method ID
      });

      if (result?.paymentIntent?.status === "succeeded") {
        pushNotification("Payment succeeded!");
        setPaymentConfirmModal(false);
        onSuccess();
        handleClose();
      }

      setPaymentLoader(false);
    } catch (error) {
      setPaymentLoader(false);
      pushNotification("Something went wrong. Contact support to resolve this issue", "error");

      console.log("error", error);
    }
  };
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  const handleGetAddedCard = async () => {
    try {
      setLoader(true);
      const { data } = await main_api.get(url);

      let cardsMap = data?.map((items) => {
        return {
          label: `${capitalizeFirstLetter(items?.card?.brand)} - Last Digits ${items?.card?.last4}`,
          value: items?.id,
        };
      });

      setLoader(false);

      setTotalAttachedCards(cardsMap);
    } catch (error) {
      setLoader(false);
    }
  };

  let invoiceDataServices = invoiceSingleDetail?.responseData?.result || [];

  const allPaid = invoiceDataServices?.every((service) => service.paid === true);

  return (
    <AntDesignModal
      openModal={openModal}
      closeModal={handleClose}
      onCancel={handleClose}
      onOk={handleClose}
      width={550}
      title={"Make a Payment"}
      component={
        <Row gutter={10}>
          {invoiceSingleDetail?.isLoading && loader ? (
            "Loading"
          ) : !loader && totalAttachedCards?.length === 0 ? (
            <NOCardsAttachModal />
          ) : (
            <>
              <Col md={24} className=" ">
                <HeadingComponent
                  text={`Select card and then click on Confirm Payment to proceed with the payment for the #${invoiceId} invoice. `}
                  fontSize="14px"
                  fontWeight={400}
                  color="#212121"
                />
              </Col>
              <Col md={12} className="mt-4">
                <AntDesignDropDownSelect
                  placeholder="Select Card"
                  defaultValue="Select Card"
                  onChange={(e) => {
                    setSelectedCardPaymentId(e);
                  }}
                  options={totalAttachedCards || []}
                  disabled={loader}
                />
              </Col>

              <Col md={15} className=" my-2">
                <HeadingComponent
                  text={allPaid ? "All services are paid of this invoice " : ""}
                  fontSize="14px"
                  fontWeight={400}
                  color="#212121"
                />
              </Col>
            </>
          )}
          <AntDesignModal
            openModal={paymentConfirmModal}
            closeModal={() => setPaymentConfirmModal(false)}
            onCancel={() => setPaymentConfirmModal(false)}
            onOk={() => setPaymentConfirmModal(false)}
            width={550}
            title={"Make a Payment"}
            component={
              <div className="d-flex justify-content-center flex-column align-items-center">
                <ConfirmIcon />
                <HeadingComponent text={"Are you sure?"} fontSize="20px" fontWeight={700} color="#000000" margin="10px 0px" />
                <HeadingComponent
                  text={"Please review the details carefully before proceeding"}
                  fontSize="14px"
                  fontWeight={400}
                  color="#000000"
                />
              </div>
            }
            footer={
              <div className="d-flex justify-content-end mt-5">
                <CustomBtnWithIcon
                  btnText={"Cancel"}
                  hideIcon
                  noBackground
                  className="me-2"
                  width="90px"
                  onClick={() => setPaymentConfirmModal(false)}
                />
                <CustomBtnWithIcon
                  btnText={"Confirm Payment"}
                  hideIcon
                  className="me-2"
                  onClick={handleSubmit}
                  // onClick={() => setPaymentConfirmModal(false)}
                  disabled={paymentLoader}
                />
              </div>
            }
          />
        </Row>
      }
      footer={
        invoiceSingleDetail?.isLoading && totalAttachedCards?.length === 0 ? null : (
          <div className="d-flex justify-content-end ">
            <CustomBtnWithIcon btnText={"Cancel"} hideIcon noBackground className="me-2" width="90px" onClick={handleClose} />
            <CustomBtnWithIcon
              btnText={"Confirm"}
              hideIcon
              className="me-2"
              // onClick={handleSubmit}
              disabled={!selectedCardPaymentId || allPaid}
              onClick={() => setPaymentConfirmModal(true)}
            />
          </div>
        )
      }
    />
  );
};

export default AddNewClientForm;

export const NOCardsAttachModal = () => {
  return (
    <div>
      <HeadingComponent
        text="No cards are currently attached for payment."
        fontSize="14px"
        fontWeight={400}
        color="#212121"
        margin="10px 0"
      />
      <HeadingComponent
        text="Please navigate to Settings > Payment Methods to add a card."
        fontSize="14px"
        fontWeight={400}
        color="#212121"
        margin="10px 0"
      />
    </div>
  );
};
