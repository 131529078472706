import styled from "styled-components";
import { navBorder } from "../../../constants/commonStyles";

const ModalContainer = styled.div`
  position: absolute;
  display: ${(props) => (props.visible ? "flex" : "none")};
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background: rgba(229, 236, 239, 0.4);
  backdrop-filter: blur(1.5px);
  align-items: center;
  justify-content: center;
  z-index: 10000;
`;

const Modal = styled.div`
  border: ${navBorder};
  overflow: scroll;
  padding: 2px 0px;
  background: #ffffff;
  box-shadow: 0px 1px 8px rgba(110, 110, 110, 0.1);
  border-radius: 16px;
  width: 78%;
  box-sizing: border-box;
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;

const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 10px;
  border-bottom: ${navBorder};
`;

const ModalHeaderText = styled.span`
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 20px;
  letter-spacing: 0.01px;
  color: #212121;
`;

const ModalClose = styled.svg`
  width: 30px;
  height: 30px;
  cursor: pointer;
  stroke: #414d55;
`;

const Action = styled.button`
  width: 72px;
  height: 28px;
  background: #e9bd5a;
  border-radius: 6px;
  border: none;
  margin-left: auto;
  margin-top: 30px;
  cursor: pointer;

  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  color: #ffffff;

  &:hover {
    background: lightblue;
  }
`;

const ModalContent = styled.div`
  display: flex;
`;
// modal left side

const ModalContentLeftBar = styled.div`
  width: 25%;
  border-right: ${navBorder};
  padding: 20px;
`;
const SchedulerTextWrapper = styled.div`
  display: flex;
  height: 65px;
`;

const SchedulerText = styled.h2`
  font-size: 0.875rem;
  font-weight: 500;
`;

const GreenCheckIconContainer = styled.div`
  background: #04aa5a;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  margin-left: 10px;
`;

const TextProgressWrapper = styled.div`
  height: 70px;
  cursor: pointer;
`;
const TextProgress = styled.h2`
  font-size: 0.875rem;
  margin-bottom: 0px;
  font-weight: ${(props) => (props.active ? 500 : 400)};
`;

//

// modal right side

const ModalContentRightSide = styled.div`
  width: 75%;
  padding: 16px;
`;
const MainContentTopHeading = styled.p`
  color: #616161;
  font-size: 16px;
  font-weight: 500;
`;
const VideoPlayer = styled.div`
  height: 54vh;
`;
const MainContentNavHeading = styled.h2`
  font-size: 1.5rem;
  font-weight: 700;
  color: #212121;
`;
const SkipVideoButton = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: center;
  padding: 13px 0px;

  height: 44px;
  width: 114px;
  border: 1px solid #fbcf34; /* Outline color */
  color: black; /* Inner text color */
  cursor: pointer; /* Change cursor on hover */
  margin-left: auto;
  color: #000000;
  font-weight: 500;
  font-size: 0.875rem;
  border-radius: 8px;
`;

const RoundedButton = styled.button`
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 1px solid #EAEAEE;
  background-color: #ffffff; 
  color: #fff; 
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin:${(props) => props.margin};
  box-shadow:width: 36px;
    cursor: pointer;
    box-shadow:rgb(0 0 0 / 11%) 0px 2px 4px; ;
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

//
export {
  ModalContainer,
  Modal,
  ModalHeader,
  ModalHeaderText,
  ModalClose,
  Action,
  ModalContent,
  ModalContentLeftBar,
  SchedulerTextWrapper,
  GreenCheckIconContainer,
  TextProgressWrapper,
  TextProgress,
  SchedulerText,
  ModalContentRightSide,
  MainContentTopHeading,
  VideoPlayer,
  MainContentNavHeading,
  SkipVideoButton,
  RoundedButton,
};
