import React, { useState } from "react";
// import CustomBtnWithIcon from '../buttonWithIcon';
import axiosInstance from "../../util/AxiosConfig";
import styled from "styled-components";
// import PDFViewer from './pdfViewer';
import { AntdesignTable } from "../antDesignTable/AntdesignTable";
import { DocumentsTableColumns } from "../../constants/DocumentTableHeaders/documentsTableHeaders";
import HeadingComponent from "../Heading";
// import CustomBtnWithIcon from '../../../components/buttonWithIcon';
import { Button, message, Tooltip, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { companyAPIsEndPoints } from "../../constants/ApiEndPoints";
import { ReactComponent as UploadIcon } from "../../assets/rawSvgs/Upload.svg";
import { main_api } from "../../api/axios_helper";

export default function DocumentsTable({
  documents,
  showupload,
  handleUpload,
  handleDownload,
  handleSignature,
  handleShowPDFViewerPage,
  clientAbleToUploadDoc,
  clientUploadDocButton,
  clientSignAgreement,
}) {
  return (
    <>
      <div className="d-flex" style={{ justifyContent: "space-between", marginBottom: "14px" }}>
        <Heading text="Documents" fontSize="1.45rem" />
        {clientUploadDocButton && clientAbleToUploadDoc ? (
          <Tooltip title={"You are not allowed to upload. Contact admin for support"}>
            <Upload customRequest={handleUpload} showUploadList={showupload}>
              <Button icon={<UploadIcon />} disabled={clientAbleToUploadDoc}>
                Upload file
              </Button>
            </Upload>
          </Tooltip>
        ) : (
          <Upload customRequest={handleUpload} showUploadList={showupload}>
            <Button icon={<UploadIcon />} disabled={clientAbleToUploadDoc}>
              Upload file
            </Button>
          </Upload>
        )}
      </div>
      <AntdesignTable
        columns={DocumentsTableColumns({ handleDownload, handleSignature, handleShowPDFViewerPage, clientSignAgreement })}
        data={documents || []}
        // loading={isLoading}
        // selectedRows={(rows) => setselectedKeysRows(rows.SelctRows)}
        rowKey="id"
      />
    </>
  );
}

const ButtonContainer = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  border-top: 1px solid #e0e0e0;
  background-color: white;
  padding: 10px 20px;
`;

const Heading = ({ text = "", margin, fontSize = "0.75rem", color = "#3B3B3B" }) => {
  return <HeadingComponent text={text} fontSize={fontSize} color={color} fontWeight={700} margin={margin} />;
};
