import { Modal } from "antd";
import CustomBtnWithIcon from "../buttonWithIcon";
import HeadingComponent from "../Heading";
import { PerformActionIcon } from "../../assets/rawSvgs/index";

const PermissionModal = ({
  onclose,
  openModal,
  padding = "20px",
}) => {
  return (
    <Modal open={openModal} onCancel={onclose} footer={null} centered>
      <div
        style={{
          padding: padding,
          height: "300px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <PerformActionIcon width="220px" height="220px" />

        <HeadingComponent
          text="You don't have access to perform this action."
          fontSize="0.875rem"
          fontWeight={400}
          margin="25px auto 15px auto"
        />
        <div className="d-flex justify-content-center">
          <CustomBtnWithIcon btnText={"Got It"} padding="10px 30px" hideIcon margin="10px" onClick={onclose} />
        </div>
      </div>
    </Modal>
  );
};

export default PermissionModal;
