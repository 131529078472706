import React, { useEffect } from "react";

import styled from "styled-components";
import { paymentNavTabs } from "../../constants/CompanyNavTabs/paymentNavTabs";
import SubscriptionPlan from "./paymentScreens/subscriptionPlan";
import BillingInvoices from "./paymentScreens/billingInvoices";
import PaymentMethods from "./paymentScreens/paymentMethods";
import AntDesignNavTabs from "../../components/antDesignNaviTabs/AntDesignNavTabs";

import { fetchCompStripeDetailsApi } from "../../redux/slices/auth";
import { useDispatch } from "react-redux";

const Payment = () => {
  let dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchCompStripeDetailsApi());
  }, []);

  const handleChangetabs = (val) => {};

  const tabItems = paymentNavTabs(<SubscriptionPlan />, <PaymentMethods />);
  return (
    <>
      <NavigationHeading>Payments</NavigationHeading>
      <AntDesignNavTabs navTabs={tabItems} onChange={handleChangetabs} />
    </>
  );
};

const NavigationHeading = styled.h2`
  display: flex;
  margin: 25px 0px 10px 0px;
  font-size: 20px;
  font-weight: 700;
`;

export default Payment;
