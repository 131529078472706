import { Dropdown, Space, Tag } from "antd";
import { ReactComponent as EditIcon } from "../../assets/rawSvgs/tableEditIcon.svg";
import { ReactComponent as DeleteIcon } from "../../assets/rawSvgs/tableDeleteIcon.svg";
import EditPetModal from "../../components/Modals/PetManagementModal/EditPetModal";
import { CommonActionHeaders } from "../TableActionHeaderColumn";
import dayjs from "dayjs";
import CommonUtil from "../../util/commonUtil";
import { DropDownDots } from "../../assets/rawSvgs";
import { Capsule } from "../../pages/company/tasks/tasksManagementStyles";

const TasksListColumns = ({ handleDelete = "", handleEditRow = "", props = "" }) => {
  return [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Priority",
      dataIndex: "priority",
      key: "priority",
      render: (_, record) => {
        return record.priority == "high" ? 
          <Capsule bgColor="#FEF3F2" color="#D92D20" className="d-inline">
            <i class="pi pi-angle-double-up"></i> High
          </Capsule>        
          :
          <Capsule bgColor="#F0F9FF" color="#026AA2" className="d-inline">
            <i class="pi pi-angle-double-up"></i> Low
          </Capsule>
      }
    },
    {
      title: "Due Date",
      dataIndex: "due_date",
      key: "due_date",
      render: (_, record) => (
        <>{dayjs(record.due_date).format("DD/MM/YYYY")}</>
      )
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, record) => {
        return record.status == "pending" ? 
        <Capsule className="d-inline">
          {CommonUtil.capitalizeFirstLetter(record.status)}
        </Capsule>
        : record.status == "inprogress" ?
        <Capsule className="d-inline" bgColor="#FEF3F2" color="#D92D20">
          {CommonUtil.capitalizeFirstLetter(record.status)}
        </Capsule>
        :
        <Capsule className="d-inline" bgColor="#EEF8EE" color="#00914B">
          {CommonUtil.capitalizeFirstLetter(record.status)}
        </Capsule>
      }
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (_, record) => (
        <>{record.type ? record.type.charAt(0).toUpperCase() + record.type.slice(1) : ''}</>
      )
    },
    {
      title: "Assignee",
      dataIndex: "assignee",
      key: "assignee",
      render: (_, record) => (
        <>{record.assign_to_client?.name || record.assign_to_staff?.name || record.assign_to_manager?.name}</>
      )
    },
    {
      title: "Actions",
      key: "action",
      align: "center",
      render: (_, record) => (
        <>
          <Dropdown
            menu={{
              items: [
                {
                  label: (
                    <div
                      style={{ width: "80px", padding: "2px 2px" }}
                      className="d-flex justify-content-start"
                      onClick={(e) => {handleEditRow(record)}}
                    >
                      <span className="me-3">
                        <EditIcon />
                      </span>
                      Edit
                    </div>
                  ),
                  key: "0",
                },
                // {
                //   label: (
                //     <div
                //       style={{ width: "80px", padding: "2px 0px" }}
                //       className="d-flex justify-content-start"
                //       onClick={() => {
                //         if (record.id) {
                //           // handleDisableRow(record);
                //         }
                //       }}
                //     >
                //     </div>
                //   ),
                //   key: "1",
                // },
                {
                  label: (
                    <div
                      style={{ width: "80px", padding: "2px 0px" }}
                      className="d-flex justify-content-start"
                      onClick={() => {
                        handleDelete(record.id);
                      }}
                    >
                      <span className="me-3">
                        <DeleteIcon />
                      </span>
                      <span>Delete</span>
                    </div>
                  ),
                  key: "3",
                },
              ],
            }}
            trigger={["click"]}
          >
            <a
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              <Space size="middle" className="cursorPointer" style={{ transform: "rotate(270deg)" }}>
                <DropDownDots />
              </Space>
            </a>
          </Dropdown>
        </>
      ),
    },
  ];
};

const WorkQueueColumns = ({ handleDelete = "", handleEditRow = "", props = "" }) => {
  return [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (_, record) => (
        <a
          onClick={() => {
            props.openWorkQueueDetailModal(true);
            props.loadWorkQueueDetail(record.id);
          }}
        >
          {record.name}
        </a>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Due Date",
      dataIndex: "due_date",
      key: "due_date",
      render: (_, record) => (
        <>{dayjs(record.due_date).format("DD/MM/YYYY")}</>
      )
    },
    {
      title: "Total Tasks",
      dataIndex: "work_queue_task",
      key: "work_queue_task",
      render: (_, record) => {
        return record.work_queue_task.length ?
        <Capsule className="d-inline">
          {record.work_queue_task.length} Tasks
        </Capsule> : ""
      }
    },
    ...CommonActionHeaders({ handleDelete, handleEditRow ,props}),
  ];
};

export { TasksListColumns, WorkQueueColumns };
