import React, { useEffect, useState } from 'react';
import { AntdesignTable } from '../antDesignTable/AntdesignTable';
import {  Switch } from 'antd';
import { main_api } from '../../api/axios_helper';
import { routeOptimizerAPIsEndPoints } from '../../constants/ApiEndPoints';
import { useSelector } from 'react-redux';
// import 'antd/dist/antd.css';


export default function Configurations() {
  
  const data = [
    { key: '1', title: 'Item 1', description: 'Description of item 1', enabled: true },
    { key: '2', title: 'Item 2', description: 'Description of item 2', enabled: false },
    { key: '3', title: 'Item 3', description: 'Description of item 3', enabled: true },
  ];
  const { auth } = useSelector((state) => state);
  const [tableData, setTableData] = useState(data);
  
  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Enable',
      dataIndex: 'enabled',
      key: 'enabled',
      render: (enabled, record) => (
        <Switch
        checked={enabled}
        onChange={(checked) => handleEnableChange(checked, record.key)}
        />
        ),
     },
  ];
        
        
  
  const fetchConfigurations = async () => {
    try {
      const companyId = auth?.loginResponse?.selectCompany?.[0]?.company_id;
      // Example: Fetch data from an API
      const response = await main_api.get(routeOptimizerAPIsEndPoints.GET_ROUTE_OPTIMIZER_CONFIG);
      
      const data = response.data.result.map((item, index) => {
        return {
          key: index, // Or index + 1 if you want sequential keys
          title: item?.title || 'N/A',
          description: item?.description || 'N/A',
          enabled: item?.is_active,
        };
      });
  
     
      setTableData(data);
    } catch (error) {
      console.error('Error fetching service data:', error);
      // Handle error appropriately
    }
  };

  useEffect(()=>{
    fetchConfigurations();
  },[])

  const handleEnableChange = (checked, key) => {
    const newData = tableData.map(item => {
      if (item.key === key) {
        return { ...item, enabled: checked };
      }
      return item;
    });
    setTableData(newData);
  };

  return <AntdesignTable columns={columns} dataSource={tableData} />;
}




