import { ScheduleClose, BlockService, SchedulerBulkUpdate } from "../../../../assets/rawSvgs/index";

//
export const threeDotsDropdownMenu = [
  {
    label: (
      <div>
        <span className="me-2">
          <BlockService />
        </span>
        Block Service{" "}
      </div>
    ),
    key: "0",
  },
  {
    label: (
      <div>
        <span className="me-2">
          <SchedulerBulkUpdate />
        </span>
        Bulk Update{" "}
      </div>
    ),
    key: "1",
  },
  {
    label: (
      <div>
        <span className="me-2">
          <ScheduleClose />
        </span>
        Close Schedule{" "}
      </div>
    ),
    key: "2",
  },
];

export const schedulerDropDownMenu = (handleSelectSchedulerDropDown) => {
  return [
    {
      label: <div onClick={() => handleSelectSchedulerDropDown("Schedule a Service")}>Schedule a Service</div>,
      key: "0",
    },
    {
      label: <div onClick={() => handleSelectSchedulerDropDown("Schedule a Trip")}>Schedule a Trip</div>,
      key: "1",
    },
    {
      label: <div onClick={() => handleSelectSchedulerDropDown("Schedule a Group Walk")}>Schedule a Group Walk</div>,
      key: "2",
    },
  ];
};

export const schedulerListACtions = [
  {
    label: <div>Completed</div>,
    key: "0",
  },
  {
    label: <div>Delete Visits</div>,
    key: "1",
  },
  {
    label: <div>Reassgin Visits</div>,
    key: "2",
  },
  {
    label: <div>Update Rates</div>,
    key: "3",
  },
  {
    label: <div>Update Holiday Rates</div>,
    key: "4",
  },
  {
    label: <div>Bulk Edit</div>,
    key: "5",
  },
];
