//
export const schedulerNavigationTabs = (schEventsComp, schTempComp, schReqComp, schTimeOff, routeOpt) => {
  return [
    {
      key: "1",
      label: "Scheduled Events",
      children: schEventsComp,
    },
    {
      key: "2",
      label: "Templates",
      children: schTempComp,
    },
    {
      key: "3",
      label: "Service Requests",
      children: schReqComp,
    },
    {
      key: "4",
      label: "Time Off Requests",
      children: schTimeOff,
    },
  ];
};
