import React from "react";
import styled from "styled-components";

const Steper = ({ steps,activeStep, setActiveStep}) => {

  return (
    <div className="d-flex gap-2">
      {steps.map((step, i) => (
        <Step
          key={i + 1}
          active={i + 1 === activeStep}
          onClick={() => {
            setActiveStep(i + 1);
          }}
        ></Step>
      ))}
    </div>
  );
};

const Step = styled.div`
  height: 4.72px;
  width: 118px;
  background-color: ${(props) => (props.active ? "#FBCF34" : "#e0e0e0")};
  border-radius: 6px;
`;

export default Steper;
