import React from "react";
import PersonalInformation from "../AcccountSettings/personalInformation";

export default function ProfileSettings() {
  return (
    <div style={{ width: "50%" }}>
      <PersonalInformation fromProfileSetitngs={true} />
    </div>
  );
}
