import styled, { css } from "styled-components";

export const TabsDiv = styled.div`
  width: 30%;
  display: flex;
`;
export const ChatContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  padding-bottom: 15px;
  overflow: auto;

  max-height: ${(props) => (props.height ? "78vh" : " 53vh")};

  ${(props) =>
    !props.height
      ? css`
          /* Set the height according to screen resolution using media queries */

          @media (min-height: 790px) and (max-height: 850px) {
            max-height: 47vh; /* Larger screens */
          }
          @media (min-height: 900px) and (max-height: 1040px) {
            max-height: 54vh; /* Larger screens */
          }
          @media (min-height: 990px) and (max-height: 1060px) {
            max-height: 57vh; /* Larger screens */
          }
        `
      : css`
          /* Set the height according to screen resolution using media queries for false height */

          @media (min-height: 790px) and (max-height: 890px) {
            max-height: 73vh; /* Larger screens */
          }
          @media (min-height: 891px) and (max-height: 1050px) {
            max-height: 76vh; /* Larger screens */
          }
        `}
`;

export const MessageComponentContainer = styled.div`
  border: 1px solid #eeeeee;
  margin: 6px 0px;
  padding: 10px;
  width: 99%;
  min-height: 120px;
  border-radius: 12px;
`;

export const IconSendWrapper = styled.div`
  height: 40px;
  min-width: 40px;
  background-color: rgb(251, 207, 52);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
