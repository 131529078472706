import { Col, Row, Switch } from "antd";
import React from "react";
import HeadingComponent from "../../../../../components/Heading";

export const Messaging = () => {
  return (
    <div className="">
      <Row>
        <Col md={10}>
          <HeadingComponent text={"Private Messaging Feature"} fontSize={"16px"} fontWeight={500} />
        </Col>
        <Col md={10}>
          <Switch size="small" />
          <span className="ms-2">Enable</span>
          <HeadingComponent
            text={"This will allows clients to make a private chat"}
            fontSize={"14px"}
            fontWeight={400}
            color="#757575"
            margin={"10px 0px"}
          />
        </Col>
        {/* ======================================================== */}

        <Col md={10} className="my-5">
          <HeadingComponent text={"Enabling Flagging In Chat"} fontSize={"16px"} fontWeight={500} />
        </Col>
        <Col md={10} className="my-5">
          <Switch size="small">Enable</Switch>
          <span className="ms-2 mb-5">Enable</span>
        </Col>

        {/* =================================== */}
      </Row>
    </div>
  );
};
