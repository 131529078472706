// Function to format date to dd/mm/yyyy

import moment from "moment";
import uuidv4 from "../../../../util/uuidv4";

function formatDate(dateString) {
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear().toString();
  return `${day}/${month}/${year}`;
}

// Function to perform grouping and filtering
export function groupAndFilter(data) {
  const result = {};

  data.forEach((item) => {
    const clientName = item.client.name;
    const formattedDate = formatDate(item.created_at);
    if (!result[clientName]) {
      result[clientName] = {};
    }
    if (!result[clientName][formattedDate]) {
      result[clientName][formattedDate] = [];
    }
    result[clientName][formattedDate].push(item);
  });

  return result;
}

// separate objects and make new objects date wise
export function separateObjects(groupedData) {
  const separatedObjects = [];
  Object.keys(groupedData).forEach((clientName) => {
    const clientData = groupedData[clientName];
    Object.keys(clientData).forEach((createdDate) => {
      // extract services
      let extrcServie = extractServices(clientData[createdDate]);
      let extrctObjdates = extractDates(clientData[createdDate]);
      const clientObject = {
        clientName: clientName,
        createdAt: createdDate,
        nestedObject: clientData[createdDate],
        services: extrcServie,
        nestedObjDates: extrctObjdates,
        id: uuidv4(),
      };

      separatedObjects.push(clientObject);
    });
  });
  return separatedObjects;
}

// extract service and make another object
function extractServices(data) {
  let extrcServie = [];
  data?.forEach((nest) => {
    extrcServie.push({
      serviceName: nest?.service?.name || "",
      serviceId: nest?.service?.id || "",

      default_duration: nest?.default_duration || "",
    });
  });
  return extrcServie;
}

// extract dates and make object of dates
function extractDates(data) {
  const uniqueDates = new Set();

  // Iterate through the data array and add unique dates to the Set
  data?.forEach((items) => {
    uniqueDates.add(items.date);
  });

  // Convert Set back to an array of objects with label and value properties
  return [...uniqueDates].map((date) => ({ label: date, value: date }));
}

// Generate 12 hours slots to select service and assign

export function generateAvailableTimeSlots(startTime24, endTime24) {
  const availableTimeSlots = [];

  const [startHours, startMinutes] = startTime24?.split(":")?.map(Number);
  const [endHours, endMinutes] = endTime24?.split(":")?.map(Number);

  // Loop through each hour and minute between start and end times
  for (let hours = startHours; hours <= endHours; hours++) {
    for (let minutes = 0; minutes < 60; minutes += 30) {
      if ((hours === startHours && minutes < startMinutes) || (hours === endHours && minutes >= endMinutes)) {
        continue; // Skip if before start time or after end time
      }

      // Format the time slot
      const timeString = `${hours?.toString().padStart(2, "0")}:${minutes?.toString().padStart(2, "0")}`;
      availableTimeSlots.push({ value: convertTo12HourFormat(timeString), label: convertTo12HourFormat(timeString) });
    }
  }

  return availableTimeSlots;
}

function convertTo12HourFormat(timeString) {
  // Create a Date object with a dummy date
  const date = new Date("2000-01-01 " + timeString);

  // Get hours, minutes, and seconds from the Date object
  const hours = date.getHours();
  const minutes = date.getMinutes();

  // Determine AM or PM designation
  const ampm = hours >= 12 ? "PM" : "AM";

  // Convert hours to 12-hour format
  const twelveHour = hours % 12 || 12; // Convert 0 to 12 for 12:00 AM

  // Pad single-digit minutes with leading zero if necessary
  const paddedMinutes = minutes < 10 ? "0" + minutes : minutes;

  // Format the time as "HH:MM AM/PM"
  return `${twelveHour}:${paddedMinutes} ${ampm}`;
}

export const All12FormatTimeArray = [
  {
    value: "12:00 AM",
    label: "12:00 AM",
  },
  {
    value: "12:30 AM",
    label: "12:30 AM",
  },
  {
    value: "1:00 AM",
    label: "1:00 AM",
  },
  {
    value: "1:30 AM",
    label: "1:30 AM",
  },
  {
    value: "2:00 AM",
    label: "2:00 AM",
  },
  {
    value: "2:30 AM",
    label: "2:30 AM",
  },
  {
    value: "3:00 AM",
    label: "3:00 AM",
  },
  {
    value: "3:30 AM",
    label: "3:30 AM",
  },
  {
    value: "4:00 AM",
    label: "4:00 AM",
  },
  {
    value: "4:30 AM",
    label: "4:30 AM",
  },
  {
    value: "5:00 AM",
    label: "5:00 AM",
  },
  {
    value: "5:30 AM",
    label: "5:30 AM",
  },
  {
    value: "6:00 AM",
    label: "6:00 AM",
  },
  {
    value: "6:30 AM",
    label: "6:30 AM",
  },
  {
    value: "7:00 AM",
    label: "7:00 AM",
  },
  {
    value: "7:30 AM",
    label: "7:30 AM",
  },
  {
    value: "8:00 AM",
    label: "8:00 AM",
  },
  {
    value: "8:30 AM",
    label: "8:30 AM",
  },
  {
    value: "9:00 AM",
    label: "9:00 AM",
  },
  {
    value: "9:30 AM",
    label: "9:30 AM",
  },
  {
    value: "10:00 AM",
    label: "10:00 AM",
  },
  {
    value: "10:30 AM",
    label: "10:30 AM",
  },
  {
    value: "11:00 AM",
    label: "11:00 AM",
  },
  {
    value: "11:30 AM",
    label: "11:30 AM",
  },
  {
    value: "12:00 PM",
    label: "12:00 PM",
  },
  {
    value: "12:30 PM",
    label: "12:30 PM",
  },
  {
    value: "1:00 PM",
    label: "1:00 PM",
  },
  {
    value: "1:30 PM",
    label: "1:30 PM",
  },
  {
    value: "2:00 PM",
    label: "2:00 PM",
  },
  {
    value: "2:30 PM",
    label: "2:30 PM",
  },
  {
    value: "3:00 PM",
    label: "3:00 PM",
  },
  {
    value: "3:30 PM",
    label: "3:30 PM",
  },
  {
    value: "4:00 PM",
    label: "4:00 PM",
  },
  {
    value: "4:30 PM",
    label: "4:30 PM",
  },
  {
    value: "5:00 PM",
    label: "5:00 PM",
  },
  {
    value: "5:30 PM",
    label: "5:30 PM",
  },
  {
    value: "6:00 PM",
    label: "6:00 PM",
  },
  {
    value: "6:30 PM",
    label: "6:30 PM",
  },
  {
    value: "7:00 PM",
    label: "7:00 PM",
  },
  {
    value: "7:30 PM",
    label: "7:30 PM",
  },
  {
    value: "8:00 PM",
    label: "8:00 PM",
  },
  {
    value: "8:30 PM",
    label: "8:30 PM",
  },
  {
    value: "9:00 PM",
    label: "9:00 PM",
  },
  {
    value: "9:30 PM",
    label: "9:30 PM",
  },
  {
    value: "10:00 PM",
    label: "10:00 PM",
  },
  {
    value: "10:30 PM",
    label: "10:30 PM",
  },
  {
    value: "11:00 PM",
    label: "11:00 PM",
  },
  {
    value: "11:30 PM",
    label: "11:30 PM",
  },
];

//  adding default duration to end time getting time from start_time

export function addMinutesToTime(startTime, minutes) {
  // Parse start time
  const [hoursStr, minutesStr, period] = startTime.split(":").map((str) => parseInt(str));
  let hours = hoursStr;
  let totalMinutes = hours * 60 + minutesStr;

  // Add given minutes
  totalMinutes += minutes;

  // Handle overflow
  hours = Math.floor(totalMinutes / 60) % 12;
  const remainingMinutes = totalMinutes % 60;

  // Handle midnight (12:00 AM)
  if (hours === 0) hours = 12;

  // Determine period (AM/PM)
  const newPeriod = totalMinutes < 720 ? "AM" : "PM";

  // Format the result
  const newHoursStr = hours < 10 ? "0" + hours : hours.toString();
  const newMinutesStr = remainingMinutes < 10 ? "0" + remainingMinutes : remainingMinutes.toString();

  return `${newHoursStr}:${newMinutesStr} ${newPeriod}`;
}

export function dateFormatStartDate(start_time, date) {
  const [year, month, day] = date.split("-").map(Number);

  const [startHours, startMinutes, startSeconds] = start_time.split(":").map(Number);

  const startDate = new Date(year, month - 1, day, startHours, startMinutes, startSeconds);

  return startDate;
}

export function dateFormatEndDate(end_time, date) {
  const [year, month, day] = date.split("-").map(Number);

  const [endHours, endMinutes, endSeconds] = end_time.split(":").map(Number);

  const endDate = new Date(year, month - 1, day, endHours, endMinutes, endSeconds);

  return endDate;
}

export const convertTo24HourFormat = (time12h) => {
  // Parse the time using moment
  const parsedTime = moment(time12h, "h:mm A");

  // Format the parsed time in 24-hour format
  const time24h = parsedTime.format("HH:mm");

  return time24h;
};
