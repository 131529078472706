import React, { useEffect, useState } from "react";

import styled from "styled-components";
import NavItem from "./NavItem";

import { ReactComponent as CompanyBrandIcon } from "../assets/companySvgIcons/frame.svg";
import { ReactComponent as NavbarHambuergerIcon } from "../assets/companySvgIcons/navbarHamburger.svg";
import { Tooltip } from "antd";
import { useSelector } from "react-redux";
import useTheme from "./Theme/useTheme";
// import { sidebarNavData } from "../constants/SidebarNavData";

function SidebarNav({ toggleSidebar, generalAppStae, navItems }) {
  const [isHover, setIsHover] = useState(generalAppStae?.sidebarToggle || false);
  let unreadCounts = useSelector((state) => state.generalApp.unreadCounts?.length);
  const roleType = useSelector((state) => state.auth?.loginResponse?.selectedRoleType);
  let clientUnread = useSelector((state) => state.generalApp.unreadCountsClient);
  const auth = useSelector((state) => state.auth);

  let isPlatformAdmin = auth?.loginResponse?.is_platform_admin || false;
  let isClientPortal = auth?.loginResponse?.selectedRoleType === "client_type" ? true : false;
  let compId = "";
  if (!isPlatformAdmin) {
    compId = auth?.loginResponse?.selectCompany?.[0]?.company_id;
  }

  let clientId = "";
  if (auth?.loginResponse?.selectedRoleType === "client_type") {
    auth?.loginResponse?.LoggedInUserData?.company_roles?.forEach((element) => {
      if (element.client_id) {
        clientId = element.client_id;
      }
    });
  }

  const hasUnreadCount = (channels) => {
    for (const channel of channels) {
      try {
        const { unreadCountData } = channel.custom || {};
        const parsedData = JSON.parse(unreadCountData) || {};

        if (!parsedData) {
          continue; // Skip if unreadCountData is not valid JSON
        }

        // Check for receivers
        if (parsedData.recievers) {
          for (const receiverId in parsedData.recievers) {
            if (receiverId === `client_${clientId}`) {
              const { unreadCount } = parsedData.recievers[receiverId];

              if (unreadCount > 0) {
                return true;
              }
            }
          }
        }
      } catch (error) {
        console.error(`Error parsing unreadCountData for channel: ${error}`);
      }
    }

    return false;
  };

  const resultUnread = hasUnreadCount(clientUnread, compId);

  const [selectedHasExtra, setSelectedHasExtra] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    if (selectedHasExtra) {
      setIsHover(false);
    }
  }, [selectedHasExtra]);

  const handleToggleSidebar = () => {
    toggleSidebar();
  };
  return (
    <Container isExpaned={generalAppStae?.sidebarToggle}>
      <LogoContainer>
        <div className="cursorPointer" onClick={handleToggleSidebar}>
          <NavbarHambuergerIcon />
        </div>
        <CompanyBrandIcon width={100} style={{ position: "absolute", left: "56px" }} />
      </LogoContainer>
      <SideMainContainer
        style={{ background: `${theme?.headerBackground && isClientPortal ? theme?.headerBackground : "white"}` }}
      >
        <NavContainer>
          <NavList>
            {navItems?.map((items, index) => {
              return (
                <div key={index}>
                  <SidebarTypesHeading style={{ color: `${theme.headerTitle ? theme.headerTitle : "black"}` }}>
                    {generalAppStae?.sidebarToggle && items.heading}
                  </SidebarTypesHeading>
                  {items?.itemList?.map((subItems, subIndex) => {
                    return (
                      <div key={subIndex}>
                        {!generalAppStae?.sidebarToggle ? (
                          <Tooltip placement="right" title={subItems.itemName}>
                            <NavListItem>
                              <NavItem
                                extraCallback={setSelectedHasExtra}
                                isHover={generalAppStae?.sidebarToggle}
                                text={subItems.itemName}
                                icon={subItems.itemIcon}
                                to={subItems.navigateTo}
                                active={subItems.active}
                                pathsToMatch={subItems.pathsToMatch}
                                unreadCounts={roleType === "client_type" ? resultUnread : unreadCounts > 0 ? true : false}
                                color={`${theme.headerTitle ? theme.headerTitle : "black"}`}
                              />
                            </NavListItem>
                          </Tooltip>
                        ) : (
                          <>
                            <NavListItem>
                              <NavItem
                                extraCallback={setSelectedHasExtra}
                                isHover={generalAppStae?.sidebarToggle}
                                text={subItems.itemName}
                                icon={subItems.itemIcon}
                                to={subItems.navigateTo}
                                active={subItems.active}
                                pathsToMatch={subItems.pathsToMatch}
                                unreadCounts={
                                  roleType === "client_type"
                                    ? clientUnread?.length > 0
                                      ? true
                                      : false
                                    : unreadCounts > 0
                                      ? true
                                      : false
                                }
                              />
                            </NavListItem>
                          </>
                        )}
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </NavList>
        </NavContainer>
      </SideMainContainer>
    </Container>
  );
}
const Container = styled.div`
  width: 80px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  min-width: ${(props) => (props.isExpaned ? "240px" : "")};
  position: fixed;
  z-index: 1000;
`;
const LogoContainer = styled.div`
  width: 100%;
  height: 60px;
  display: flex;

  align-items: center;
  border-bottom: 1px solid #e4e7ec;
  padding: 5px 20px;
`;
const SideMainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  border-right: 1px solid #e4eaf0;
  background-color: white;
`;
const Logo = styled.img`
  height: 55.52220916748047px;
  width: 165.99986267089844px;
  object-fit: contain;
`;

const NavContainer = styled.nav`
  width: 100%;
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;

const NavList = styled.ul`
  width: 100%;
  margin: 0;
  padding: 0;
  height: calc(100vh - 60px);
  padding: 30px 0;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 0px;
  }
`;

const NavListItem = styled.li`
  list-style-type: none;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LogoutContainer = styled.div`
  width: 100%;
  margin-bottom: 50px;
  display: flex;
  align-items: flex-start;

  margin-left: 12px;
`;
const SidebarTypesHeading = styled.h2`
  font-size: 0.875rem;
  color: rgba(0, 0, 0, 38%);
  font-weight: 700;
  margin-left: 12px;
`;

export default SidebarNav;
