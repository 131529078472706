import { connect } from "react-redux";
import { loadDashboardData, loadUserRoles, loadUsersList, searchUserList } from "../../redux/platformSlices/UserSlice";
import Dashboard from "../../pages/platform/Dashboard";
import { loadCompanyList } from "../../redux/platformSlices/CompanySlice";

const mapStateToProps = (state) => {
  return {
    isLoading: state.users.isLoading,
    error: state.users.error,
    usersList: state.users.usersList,
    roles: state.users.roles,
    companyList: state.company.companyList,
    dashboardData: state.users.dashboardData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadUsersList: () => {
    dispatch(loadUsersList());
  },
  loadUserRoles: () => {
    dispatch(loadUserRoles());
  },
  searchUserList: (search, role, company) => {
    dispatch(searchUserList(search, role, company));
  },
  loadCompanyList: () => {
    dispatch(loadCompanyList());
  },
  loadDashboardData: () => {
    dispatch(loadDashboardData());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
