import React, { useEffect, useState } from "react";
import styled from "styled-components";
import dayjs from "dayjs";
import {
  DatePicker,
  TimePicker,
  Avatar,
  Badge,
  Divider,
  Checkbox,
  Form,
  Radio,
  Space,
  Col,
  Row,
  Select,
  Switch,
  Input,
} from "antd";
import { EditOutlined, UserOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import HeadingComponent from "../Heading";
import CustomBtnWithIcon from "../buttonWithIcon";
import TextArea from "antd/es/input/TextArea";
import LocationSelector from "../CustomAddressInput/locationSelector";
import { main_api } from "../../api/axios_helper";
import { platformAPIsEndPoints } from "../../constants/ApiEndPoints";
import { pushNotification } from "../../util/Notification";
import { loginUserSlice } from "../../redux/platformSlices/auth";

//
export default function PersonalInformation({ setModalOpen, fromProfileSetitngs, isPlatform }) {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const userInfo = auth?.loginResponse;
  const companyId = auth?.loginResponse?.selectCompany?.[0]?.company_id;
  const [isActive, setIsActive] = useState(userInfo?.is_active ?? false);
  const [routeOptimizorChecked, setRouteOptimizorChecked] = useState(false);
  const [address, setAddress] = useState("");
  const [addressLatAndLong, setAddressLatAndLong] = useState([]);

  useEffect(() => {
    form.setFieldsValue({
      first_name: userInfo?.name,
      // username: userInfo?.username,
      // dob: userInfo.dob ? dayjs(userInfo.dob) : null,
      bio: userInfo?.bio || "",
      phone_number: userInfo?.phone_number || "",
      email: userInfo?.email || "",
      address_1: userInfo?.address || "",
      state: userInfo?.state || "",
      city: userInfo?.city || "",
      zip: userInfo?.zip || "",
      is_active: isActive,
    });
    setAddress(userInfo && userInfo.address ? userInfo.address : "");
  }, [userInfo]);

  const handleUserSettings = (data) => {
    const id = auth?.loginResponse?.id;
    let apiData = { ...data, company: companyId, role: auth?.loginResponse?.platform_role?.id };

    if (apiData) {
      main_api
        .put(platformAPIsEndPoints.PUT_USER(id), apiData)
        .then(({ data }) => {
          if (data.isSuccess) {
            pushNotification(data.message, "success");
            dispatch(
              loginUserSlice({
                ...userInfo,
                name: apiData.name,
              }),
            );
            if (setModalOpen) {
              setModalOpen(false);
            }
          }
        })
        .catch((error) => {
          //   console.log(error);
          pushNotification("Something went wrong!", "error");
        });
    }
  };
  const onFinish = async (values) => {
    let data = {
      name: values.first_name,
      //   role: auth?.loginResponse?.platform_role?.id,
      //   dob: values.dob ? dayjs(values.dob) : null,
      //   bio: values.bio || "",
      // phone_number: values.phone_number || "",
      email: values.email || "",
      // address_1: values.address_1 || "",
      //   latitude: addressLatAndLong[0],
      //   longitude: addressLatAndLong[1],
      //   state: values.state || "",
      //   city: values.city || "",
      //   zip: values.zip || "",
      is_active: values.is_active || false,
    };

    handleUserSettings(data);
  };

  const handleDeleteAccount = () => {
    // console.log("Clicked");
  };
  return (
    <div>
      <Form name="accountSettingsForm" onFinish={onFinish} form={form} layout="vertical">
        <Heading text="Profile Picture" />
        <RowContainer>
          <Badge count={<EditOutlined style={{ color: "#808080" }} />}>
            <Avatar size={64} icon={<UserOutlined />} />
          </Badge>
        </RowContainer>
        <div style={{ marginTop: "24px" }}>
          <div style={{ marginBottom: "-20px" }}>
            <Heading text="Full Name" />
          </div>

          <RowContainer>
            <div style={{ width: "100%", marginLeft: isPlatform ? "0px" : "5px" }}>
              <FormItem
                name="first_name"
                rules={[
                  {
                    required: true,
                    message: "Please enter full name",
                  },
                  {
                    max: 30,
                    min: 1,
                  },
                ]}
              >
                <Input placeholder="Enter Full Name" style={{ paddingBlock: "6px" }} />
              </FormItem>
            </div>
          </RowContainer>
        </div>
        <div style={{ marginTop: "-38px" }}>
          <div style={{ marginBottom: "-20px" }}>
            <RowContainer>
              <div style={{ width: "48%", marginRight: "10px" }}>
                <Heading text="Email" />
              </div>
            </RowContainer>
          </div>

          <RowContainer>
            <div style={{ width: "100%", marginRight: "5px" }}>
              <FormItem name="email">
                <Input disabled={true} placeholder="Company Email" style={{ paddingBlock: "6px" }} />
              </FormItem>
            </div>
          </RowContainer>
        </div>
        {/* <div style={{ marginTop: "-38px" }}>
          <div style={{ marginBottom: "-20px" }}>
            <RowContainer>
              <div style={{ width: "48%" }}>
                <Heading text="City" />
              </div>
              <div style={{ width: "48%", marginRight: "10px" }}>
                <Heading text="State" />
              </div>
            </RowContainer>
          </div>

          <RowContainer>
            <div style={{ width: "48%", marginLeft: "5px" }}>
              <FormItem
                name="city"
                rules={[
                  {
                    required: true,
                    message: "Please select the city",
                  },
                ]}
              >
                <Input placeholder="Enter City" style={{ paddingBlock: "6px" }} />
              
              </FormItem>
            </div>

            <div style={{ width: "48%", marginRight: "5px" }}>
              <FormItem
                name="state"
                rules={[
                  {
                    required: true,
                    message: "Please select the state",
                  },
                ]}
              >
                <Input placeholder="Enter State" style={{ paddingBlock: "6px" }} />
               
              </FormItem>
            </div>
          </RowContainer>
        </div> */}
        {/* <div style={{ marginTop: "-12px" }}>
          <div style={{ marginBottom: "-10px" }}>
            <Heading text="Address" />
          </div>

          <RowContainer>
            <div style={{ width: "100%", marginLeft: "4px", marginRight: "4px", marginTop: "-12px", marginBottom: "-12px" }}>
              {/* <FormItem
                    name="address"
                    rules={[
                        {
                            required: true,
                            message: "Please enter the company address",
                        },
                    ]}
                >
                    <Input placeholder="Address" style={{ paddingBlock: "6px" }} />
                </FormItem> */}
        {/* <LocationSelector
                label={""}
                address={address}
                setAddress={setAddress}
                setAddressLatAndLong={setAddressLatAndLong}
                form={form}
                checked={routeOptimizorChecked}
              />
            </div>
          </RowContainer> 
        </div>
          */}
        {/* <div style={{ marginTop: "8px" }}>
          <div style={{ marginBottom: "-10px" }}>
            <Heading text="Zip Code" />
          </div>
          <RowContainer>
            <div style={{ width: "100%", marginLeft: "4px", marginRight: "4px", marginTop: "-12px", marginBottom: "-12px" }}>
              <FormItem
                name="zip"
                rules={[
                  {
                    required: true,
                    message: "Please enter the zip code",
                  },
                ]}
              >
                <Input placeholder="Zip Code" style={{ paddingBlock: "6px" }} />
              </FormItem>
            </div>
          </RowContainer>
        </div>{" "}
        <RowContainer>
          <div style={{ width: "39%", marginLeft: "5px", marginRight: "30%" }}>
            <FormItem name="is_active" colon={false}>
              <span className="d-flex">
                <Switch defaultValue={false} style={{ marginTop: "-4px", marginRight: "5px" }} />
                <Heading text="Deactivate your account" />
              </span>
              <Heading text="If enabled your account will be temporarily deactivated" color="#808080" margin="8px 0px 0px 0px" />
            </FormItem>
          </div>
        </RowContainer> */}
        <Divider />
        {!fromProfileSetitngs ? (
          <SaveContainer className="d-flex justify-content-end" fromProfileSetitngs={fromProfileSetitngs}>
            <CustomBtnWithIcon
              btnText={"Cancel"}
              margin="0px 5px"
              noBackground
              hideIcon={true}
              onClick={() => setModalOpen(false)}
            />
            <CustomBtnWithIcon btnText="Update Changes" type="submit" hideIcon={true} margin="0px 20px 0px 0px" />
          </SaveContainer>
        ) : (
          <CustomBtnWithIcon btnText="Update Changes" type="submit" hideIcon={true} />
        )}
      </Form>
    </div>
  );
}

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  margin-top: 30px; /* Adjust the top margin */
  margin-bottom: -9px; /* Adjust the bottom margin */
`;

// const SaveContainer = styled.div`
//   position: absolute;
//   bottom: 0;
//   right: 0;
//   width: 100%;
//   border-top: 1px solid #E0E0E0;
//   background-color: white;
//   padding: 10px 0px;
// `

const SaveContainer = styled.div`
  position: absolute;
  top: 522px;
  ${"" /* right: (prop)=>{`${prop.fromProfileSetitngs}`} */}
  right:  ${(props) => (props.fromProfileSetitngs ? "930px" : "0px")};

  width: 100%;
  border-top: 1px solid #e0e0e0;
  background-color: white;
  padding: 10px 0px;
`;
const FormItem = styled(Form.Item)`
  width: 100%;
  Tags {
    display: flex;
  }
`;

const Heading = ({ text = "", margin, fontSize = "0.75rem", color = "#3B3B3B" }) => {
  return <HeadingComponent text={text} fontSize={fontSize} color={color} fontWeight={700} margin={margin} />;
};
