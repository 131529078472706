import React, { useEffect, useState } from "react";

import styled from "styled-components";
import { main_api } from "../../api/axios_helper";

import { Divider, Form, Switch, Input } from "antd";

// import Input from "../../../../components/Form/Input"
import HeadingComponent from "../../components/Heading";
import CustomBtnWithIcon from "../../components/buttonWithIcon";

import LocationSelector from "../../components/CustomAddressInput/locationSelector";

export default function CompanySettingsSide({ selectedCompany, handleCompanySettings, territoryZipCodes, setTerritoryZipCodes }) {
  const [form] = Form.useForm();
  const [notificationsEnabled, setNotificationsEnabled] = useState(true);
  const [address, setAddress] = useState("");
  const [addressLatAndLong, setAddressLatAndLong] = useState([]);
  const [routeOptimizorChecked, setRouteOptimizorChecked] = useState(true);

  useEffect(() => {
    form.setFieldsValue({
      company_name: selectedCompany && selectedCompany.name ? selectedCompany.name : "",
      company_email:
        selectedCompany &&
        selectedCompany.company_admins &&
        selectedCompany.company_admins[0] &&
        selectedCompany.company_admins[0].email
          ? selectedCompany.company_admins[0].email
          : "",
      company_website: selectedCompany && selectedCompany.website ? selectedCompany.website : "",
      address_1: selectedCompany && selectedCompany.address_1 ? selectedCompany.address_1 : "",
      city: selectedCompany && selectedCompany.city ? selectedCompany.city : "",
      zip_code: selectedCompany && selectedCompany.zip ? selectedCompany.zip : "",
      // company_teritories_zip_codes: selectedCompany && selectedCompany.company_teritories_zip_codes ? selectedCompany.company_teritories_zip_codes : "",
      phone_number: selectedCompany && selectedCompany.primary_phone_number ? selectedCompany.primary_phone_number : "",
      state: selectedCompany && selectedCompany.state ? selectedCompany.state : "",
      allow_notifications:
        selectedCompany && selectedCompany.allow_notifications ? selectedCompany.allow_notifications : notificationsEnabled,
    });
    setAddress(selectedCompany && selectedCompany.address_1 ? selectedCompany.address_1 : "");
  }, [selectedCompany]);

  useEffect(() => {
    // getCompanySettings();
  }, []);

  const handleSubmit = async (values) => {
    let data = {
      name: values.company_name,
      "company_admins[0].email": values.company_email,
      website: values.company_website,
      address_1: values.address_1,
      latitude: addressLatAndLong[0],
      longitude: addressLatAndLong[1],
      city: values.city,
      zip: values.zip_code,
      // company_teritories_zip_codes: values.company_teritories_zip_codes,
      primary_phone_number: values.phone_number,
      state: values.state,
      allow_notifications: values.allow_notifications,
    };

    handleCompanySettings(data);
  };

  return (
    <>
      <Form name="companySettingsForm" onFinish={handleSubmit} form={form} layout="vertical">
        <div>
          <RowContainer>
            <HeadingComponent text={<>Company Name</>} fontSize="15px" fontWeight={400} />
            <div style={{ width: "39%", marginLeft: "10%", marginRight: "40%" }}>
              <FormItem
                name="company_name"
                rules={[
                  {
                    required: true,
                    message: "Please enter the company name",
                  },
                ]}
              >
                <Input placeholder="Company Name" style={{ paddingBlock: "6px" }} />
              </FormItem>
            </div>
          </RowContainer>
        </div>

        <Divider />
        <RowContainer>
          <HeadingComponent text={<>Company Email</>} fontSize="15px" fontWeight={400} />

          <div style={{ width: "39%", marginLeft: "10%", marginRight: "40%" }}>
            <FormItem name="company_email">
              <Input disabled={true} placeholder="Company Email" style={{ paddingBlock: "6px" }} />
            </FormItem>
          </div>
        </RowContainer>

        <Divider />

        <RowContainer>
          <HeadingComponent text={<>Company Website</>} fontSize="15px" fontWeight={400} />
          <div style={{ width: "39%", marginLeft: "10%", marginRight: "40%" }}>
            <FormItem
              name="company_website"
              rules={[
                {
                  required: true,
                  message: "Please enter the company website",
                },
              ]}
            >
              <Input placeholder="Comapny Website" style={{ paddingBlock: "6px" }} />
            </FormItem>
          </div>
        </RowContainer>

        <Divider />

        <RowContainer>
          <HeadingComponent text={<>Address</>} fontSize="15px" fontWeight={400} />
          <div style={{ width: "39%", marginLeft: "10%", marginRight: "40%" }}>
            {/* <FormItem
              name="address_1"
              rules={[
                {
                  required: false,
                  message: "Please enter the company address",
                },
              ]}
            > */}
            {/* <Input placeholder="Address" style={{ paddingBlock: "6px" }} /> */}
            {/* <LocationSelector setAddressLatAndLong={setAddressLatAndLong} /> */}
            <LocationSelector
              address={address}
              setAddress={setAddress}
              setAddressLatAndLong={setAddressLatAndLong}
              form={form}
              checked={false}
            />

            {/* </FormItem> */}
          </div>
        </RowContainer>

        <Divider />

        <RowContainer>
          <HeadingComponent text={<>Zip Code</>} fontSize="15px" fontWeight={400} />

          <div style={{ width: "39%", marginLeft: "10%", marginRight: "40%" }}>
            <FormItem
              name="zip_code"
              rules={[
                {
                  required: true,
                  message: "Please enter the zip code",
                },
              ]}
            >
              <Input placeholder="Zip Code" style={{ paddingBlock: "6px" }} />
            </FormItem>
          </div>
        </RowContainer>

        <Divider />

        <RowContainer>
          <HeadingComponent text={<>Phone Number</>} fontSize="15px" fontWeight={400} />
          <div style={{ width: "39%", marginLeft: "10%", marginRight: "40%" }}>
            <FormItem
              name="phone_number"
              rules={[
                {
                  required: true,
                  message: "Please enter the phone number",
                },
              ]}
            >
              <Input placeholder="Phone No" style={{ paddingBlock: "6px" }} />
            </FormItem>
          </div>
        </RowContainer>
        <Divider />
        <RowContainer>
          <HeadingComponent text={<>State</>} fontSize="15px" fontWeight={400} />

          <div style={{ width: "39%", marginLeft: "10%", marginRight: "40%" }}>
            <FormItem
              name="state"
              rules={[
                {
                  required: true,
                  message: "Please select the state",
                },
              ]}
            >
              <Input placeholder="Enter State" style={{ paddingBlock: "6px" }} />
              {/* <Select
            placeholder="Select State"
            defaultValue="Texas"
            onChange={handleChange}
            style={{ width: '100%' }}
            options={states}
          /> */}
            </FormItem>
          </div>
        </RowContainer>
        <Divider />

        <RowContainer>
          <HeadingComponent text={<>City</>} fontSize="15px" fontWeight={400} />
          <div style={{ width: "39%", marginLeft: "10%", marginRight: "40%" }}>
            <FormItem
              name="city"
              rules={[
                {
                  required: true,
                  message: "Please select the city",
                },
              ]}
            >
              <Input placeholder="Enter State" style={{ paddingBlock: "6px" }} />
              {/* <Select
            placeholder="Select City"
            defaultValue="New York City"
            onChange={handleChange}
            style={{ width: '100%' }}
            options={cities}
          /> */}
            </FormItem>
          </div>
        </RowContainer>

        <Divider />
        {/* <RowContainer>
      
        <HeadingComponent
            text={
                <>
                  Company Territories Zip Codes
                </>
              }
            fontSize="18px"
            fontWeight={400}
        />
       <div style={{width:"50%", marginLeft:"20%", marginRight:"10%"}}>

          <FormItem
            name="company_teritories_zip_codes"
           
          >
          <InputWithListing placeholder="Enter Zip Code"/>
          </FormItem>
       </div>
        
        
      </RowContainer>
    <Divider /> */}
        <RowContainer>
          <HeadingComponent text={<>Allow Notifications</>} fontSize="18px" fontWeight={400} />

          <div style={{ width: "39%", marginLeft: "10%", marginRight: "40%" }}>
            <FormItem name="allow_notifications">
              <span className="d-flex">
                <Switch size="small" className="me-2" checked={notificationsEnabled} onChange={setNotificationsEnabled} />
                <Heading text="Enabled" />
              </span>
            </FormItem>
          </div>
        </RowContainer>
        <Divider />
        <div className="d-flex justify-content-end px-3">
          <CustomBtnWithIcon btnText="Save Changes" type="submit" hideIcon={true} />
        </div>
      </Form>
    </>
  );
}

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  margin-top: 30px; /* Adjust the top margin */
  margin-bottom: -9px; /* Adjust the bottom margin */
`;
const SaveContainer = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  border-top: 1px solid #e0e0e0;
  background-color: white;
  padding: 30px 20px;
`;

const FormItem = styled(Form.Item)`
  width: 100%;
  Tags {
    display: flex;
  }
`;

const Heading = ({ text = "", margin, fontSize = "0.75rem", color = "#3B3B3B" }) => {
  return <HeadingComponent text={text} fontSize={fontSize} color={color} fontWeight={700} margin={margin} />;
};
