import React, { useState } from "react";
import { DatePicker, Divider, Flex, Form, Input, message, Modal, Select, Typography, Upload } from "antd";
import { ReactComponent as DefaultImageIcon } from "../../../assets/rawSvgs/defaultImage.svg";
import CustomInput from "../../CustomInput";
import styled from "styled-components";
import { ErrorMessage, Field, Formik } from "formik";
import { addVaccinationSchema } from "../../../constants/CompanyFormFieldsSchema/FormSchema";
import FormikSelect from "../../formikSelect/FormikSelect";
import CustomBtnWithIcon from "../../buttonWithIcon";
import { CustomModal } from "../ModalStyles";
import { main_api } from "../../../api/axios_helper";
import { companyAPIsEndPoints } from "../../../constants/ApiEndPoints";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { pushNotification } from "../../../util/Notification";
const { Dragger } = Upload;

const AddVaccinationModal = ({
  isLoading,
  isModalOpen,
  setModalOpen,
  onFinish,
  petList,
  clientList,
  detailPage = false,
  clientDetails,
  isClientPortal,
}) => {
  const [form] = Form.useForm();
  const updatedClientList = clientList && clientList?.map((client) => ({ value: client.id, label: client.name }));
  const [clientPetList, setClientPetList] = useState([]);

  if (isClientPortal) {
    form.setFieldValue("client", clientDetails.id);
  }
  detailPage && form.setFieldValue("client", detailPage.clientList[0].value);
  detailPage && form.setFieldValue("pets", [detailPage.petList[0].value]);

  const auth = useSelector((state) => state.auth);

  const clientChangeHandler = (clientId) => {
    let company_id = auth.loginResponse?.selectCompany?.[0]?.company_id || null;

    main_api
      .get(companyAPIsEndPoints.FETCH_SINGLE_MANAGEMENT_CLIENTS(clientId, company_id))
      .then(({ data }) => {
        const updatePetList = data.pets?.map((pet) => ({ value: pet.id, label: pet.name }));
        setClientPetList(updatePetList);
        form.setFieldsValue({
          pets: null,
        });
      })
      .catch((error) => {
        pushNotification("Something went wrong!", "error");

        // console.log(error);
      });
  };

  const closeModalHandler = () => {
    form.resetFields();
    setModalOpen(false);
  };
  const validateVaccinationDate = (rule, value, callback) => {
    const { getFieldValue } = form;

    const startDate = getFieldValue("date");
    const endDate = getFieldValue("expiry_date");

    if (startDate && endDate) {
      if (dayjs(endDate).isBefore(startDate)) {
        callback("Vaccination Date can not be after the Expiry Date");
        return;
      }
      form.setFields([
        {
          name: "date",
          errors: [],
        },
        {
          name: "expiry_date",
          errors: [],
        },
      ]);
    }
    callback();
  };
  const validateExpiryDate = (rule, value, callback) => {
    const { getFieldValue } = form;

    const startDate = getFieldValue("date");
    const endDate = getFieldValue("expiry_date");

    if (startDate && endDate) {
      if (dayjs(endDate).isBefore(startDate)) {
        callback("Expiry Date can not be before the Vaccination Date");
        return;
      }
      form.setFields([
        {
          name: "date",
          errors: [],
        },
        {
          name: "expiry_date",
          errors: [],
        },
      ]);
    }
    callback();
  };

  return (
    <CustomModal
      open={isModalOpen}
      title="Add a new Vaccination type"
      width="600px"
      heigth="434px"
      onCancel={closeModalHandler}
      footer={null}
      maskClosable={false}
    >
      <Divider />
      <Form name="addVaccinationForm" onFinish={onFinish} form={form} layout="vertical">
        <FormItem name="client" label="Client">
          {detailPage ? (
            <Select placeholder="Select Client" options={detailPage.clientList} disabled />
          ) : (
            <Select
              placeholder="Select Client"
              options={updatedClientList}
              onChange={clientChangeHandler}
              disabled={isClientPortal}
            />
          )}
        </FormItem>
        <FormItem name="pets" label="Select Pets">
          {detailPage ? (
            <Select mode="multiple" placeholder="Select Pets" options={detailPage.petList} disabled />
          ) : (
            <Select mode="multiple" placeholder="Select Pets" options={clientPetList} />
          )}
        </FormItem>
        <FormItem
          name="type"
          label="Type"
          rules={[
            {
              min: 3,
              max: 50,
              message: "Type must be between 3 and 50 characters",
            },
          ]}
        >
          <Input placeholder="Enter Type" />
        </FormItem>
        <FormItem
          name="date"
          label="Vaccination Date"
          rules={[
            {
              required: false,
            },
            { validator: validateVaccinationDate },
          ]}
        >
          <DatePicker style={{ width: "100%" }} type="date" placeholder="Choose date of vaccination" format={"YYYY/MM/DD"} />
        </FormItem>
        <FormItem
          name="expiry_date"
          label="Expiration Date"
          rules={[
            {
              required: false,
            },
            { validator: validateExpiryDate },
          ]}
        >
          <DatePicker style={{ width: "100%" }} type="date" placeholder="Choose expiration date" format={"YYYY/MM/DD"} />
        </FormItem>
        <Divider />
        <div className="d-flex justify-content-end">
          <CustomBtnWithIcon btnText={"Cancel"} margin="0px 5px" noBackground hideIcon={true} onClick={closeModalHandler} />
          <CustomBtnWithIcon btnText={"Save Changes"} type="submit" hideIcon={true} />
        </div>
      </Form>
    </CustomModal>
  );
};

const FieldWraper = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
`;

const FormItem = styled(Form.Item)`
  width: 100%;
`;

export default AddVaccinationModal;
