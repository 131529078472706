import Client from "../../pages/company/clients/Index";
import { connect } from "react-redux";
import { loginUserSlice } from "../../redux/slices/auth";
import { FetchClientsApi, PostClientApi, DeleteSingleClientsApi, updateSingleClientsApi } from "../../redux/slices/ClientSlice";
import { resetResponseState } from "../../redux/slices/ClientSlice";
const mapStateToProps = (state) => {
  return {
    clientManagementState: state.clientManangement.clientResponse,
    clientPostState: state.clientManangement.clientPostResponse,
    clientDeleteResponse: state.clientManangement.clientDeleteResponse,
    clientEditState: state.clientManangement.clientEditResponse,
    loginState: state.auth.loginResponse,
    permissionState: state.permissionManagement.permissions
  };
};

const mapDispatchToProps = (dispatch) => ({
  getManagementClients: (data) => {
    dispatch(FetchClientsApi());
  },
  postManagementClient: (data) => {
    dispatch(PostClientApi(data));
  },
  deleteSingleClient: (id) => {
    dispatch(DeleteSingleClientsApi(id));
  },
  updateSingleClient: (data) => {
    dispatch(updateSingleClientsApi(data));
  },
  resetClientResponse: () => {
    dispatch(resetResponseState());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Client);
