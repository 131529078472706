import { Button, Image, List, Segmented } from "antd";
import React, { useState } from "react";
import styled from "styled-components";
import { ReactComponent as ID } from "../assets/rawSvgs/ID.svg";
import { ReactComponent as Birthdate } from "../assets/rawSvgs/Birthdate.svg";
import { ReactComponent as Breed } from "../assets/rawSvgs/Breed.svg";
import { ReactComponent as Color } from "../assets/rawSvgs/Color.svg";
import { ReactComponent as Microchip } from "../assets/rawSvgs/Microchip.svg";
import { ReactComponent as Client } from "../assets/rawSvgs/Client.svg";
import { ReactComponent as Neutered } from "../assets/rawSvgs/Neutered.svg";
import { ReactComponent as Vaccination } from "../assets/rawSvgs/Vaccination.svg";
import { ReactComponent as PrimaryVet } from "../assets/rawSvgs/PrimaryVet.svg";
import { ReactComponent as AlternativeVet } from "../assets/rawSvgs/AlternativeVet.svg";
import { ReactComponent as Notes } from "../assets/rawSvgs/Notes.svg";
import { ReactComponent as DefaultDog } from "../assets/rawSvgs/defaultDog.svg";
import { ReactComponent as CrossIcon } from "../assets/rawSvgs/crossIcon.svg";
import CustomBtnWithIcon from "./buttonWithIcon";
import AddPetModal from "./Modals/PetManagementModal/AddPetModal";
import EditPetModal from "./Modals/PetManagementModal/EditPetModal";
import AddVaccinationModal from "./Modals/PetManagementModal/AddVaccinationModal";
import moment from "moment";
import { main_api } from "../api/axios_helper";
import { companyAPIsEndPoints } from "../constants/ApiEndPoints";
import { pushNotification } from "../util/Notification";
import DeleteConfirmationRowModal from "./Modals/DeleteConfirmationRowModal";
import Avatar from "react-avatar";
import EditVaccinationModal from "./Modals/PetManagementModal/EditVaccinationModal";
import dayjs from "dayjs";
import { checkPermission } from "../util/commonUtil";
import { useSelector } from "react-redux";
import PermissionModal from "./Modals/PermissionModal";

const DetailPage = (props) => {
  const petDetailResponse = props?.petDetailResponse?.result;
  const [alignValue, setAlignValue] = useState("General Info");
  const [isLoading, setIsLoading] = useState(false);
  const [isEditPetOpen, setIsEditPetOpen] = useState(false);
  const [isDeletePetOpen, setIsDeletePetOpen] = useState(false);
  const [isAddVaccinationOpen, setIsAddVaccinationOpen] = useState(false);
  const [isEditVaccinationOpen, setIsEditVaccinationOpen] = useState(false);
  const [permissionPopUp, setPermissionPopUp] = useState(false);
  const permissionManagement = useSelector((state) => state.permissionManagement);
  const [imageFromComp, setImageFromComp] = useState(undefined);
  // const permissionManagement = useSelector((state) => state.permissionManagement);
  const generalInfo = petDetailResponse && [
    {
      icon: <ID />,
      title: "ID #",
      description: petDetailResponse[0]?.id,
    },
    {
      icon: <Birthdate stroke={"#424242"} />,
      title: "Birth date",
      description: moment(petDetailResponse[0]?.birth_date).format("MMMM DD, YYYY"),
    },
    {
      icon: <Breed />,
      title: "Breed",
      description: petDetailResponse[0]?.breed,
    },
    {
      icon: <Color />,
      title: "Color",
      description: petDetailResponse[0]?.color,
    },
    {
      icon: <Microchip />,
      title: "Microchip",
      description: petDetailResponse[0]?.micro_chip,
    },
    {
      icon: <Client />,
      title: "Client",
      description: petDetailResponse[0]?.client_name || "---",
    },
    {
      icon: <Neutered />,
      title: "Spayed/Neutered",
      description: petDetailResponse[0]?.neutered ? "Yes" : "No",
    },
    {
      icon: <Vaccination />,
      title: "Vaccination",
      description: petDetailResponse[0]?.vaccination_current ? "Yes" : "No",
    },
    {
      icon: <PrimaryVet />,
      title: "Primary Vet",
      description: petDetailResponse[0]?.primary_vet_name || "---",
    },
    {
      icon: <AlternativeVet />,
      title: "Alternate Vet",
      description: petDetailResponse[0]?.alternate_vet_name || "---",
    },
    {
      icon: <Notes />,
      title: "Notes",
      description: petDetailResponse[0]?.notes || "---",
    },
  ];

  const vaccination = petDetailResponse &&
    petDetailResponse[0].vaccinations && [
      {
        icon: <Vaccination />,
        title: "Type",
        description: petDetailResponse[0].vaccinations[0]?.type || "---",
      },
      {
        icon: <Birthdate stroke={"#424242"} />,
        title: "Expiry",
        description: petDetailResponse[0].vaccinations[0]?.expiry_date || "---",
      },
      {
        icon: <Microchip />,
        title: "Due In",
        description: petDetailResponse[0].vaccinations[0]?.date || "---",
      },
    ];
  const uploadToS3 = async (file, type) => {
    const formData = new FormData();
    formData.append(type, file);
    const actualFile = file.originFileObj || file;

    formData.append(type, actualFile);
    try {
      const response = await main_api.post(companyAPIsEndPoints.UPLOAD_OBJECT, formData);

      if (response.data.isSuccess) {
        return response;
      }
    } catch (error) {
      console.error(`Error uploading ${type} to S3`, error);
    }
  };

  const editPet = async (formValues) => {
    const formattedDate = formValues.birth_date.format("YYYY-MM-DD");
    let fileResponse;
    if (imageFromComp) {
      fileResponse = await uploadToS3(imageFromComp?.[0], "image");
    }
    const updatedValues = {
      ...formValues,
      birth_date: formattedDate,
      logo: fileResponse?.data?.url || props.singlePetDetails?.logo,
    };

    setIsLoading(true);

    main_api
      .put(`${companyAPIsEndPoints.PUT_SINGLE_PET_LIST_PETS(petDetailResponse && petDetailResponse[0].id)}`, updatedValues)
      .then(({ data }) => {
        setIsLoading(false);
        pushNotification("Pet Detail Successfully Updated!", "success");
        setIsEditPetOpen(false);
        props.loadPetList();
        props.openDetailModal(false);
      })
      .catch((error) => {
        pushNotification("Something went wrong!", "error");
        // console.log(error);
      });
  };

  const addVaccination = (formValues) => {
    const { client, ...modifiedValues } = formValues;

    const formattedVaccinationDate = modifiedValues.date.format("YYYY-MM-DD");
    const formattedExpiryDate = modifiedValues.expiry_date.format("YYYY-MM-DD");

    const updatedValues = {
      ...modifiedValues,
      date: formattedVaccinationDate,
      expiry_date: formattedExpiryDate,
    };

    setIsLoading(true);
    main_api
      .post(companyAPIsEndPoints.POST_VACCINATION_PETS, updatedValues)
      .then(({ data }) => {
        setIsLoading(false);
        if (data?.isSuccess) {
          pushNotification(data?.message, "success");
          setIsAddVaccinationOpen(false);
          props.openDetailModal(false);
        }
      })
      .catch((error) => {
        pushNotification("Something went wrong!", "error");
        // console.log(error);
      });
  };

  const editVaccination = (formValues) => {
    const formattedExpiryDate = moment(formValues.expiry_date).format("YYYY-MM-DD");
    const formattedDate = moment(formValues.date).format("YYYY-MM-DD");

    const updatedValues = {
      ...formValues,
      date: formattedDate,
      expiry_date: formattedExpiryDate,
    };

    setIsLoading(true);
    main_api
      .put(`${companyAPIsEndPoints.PUT_SINGLE_VACCINATION_PETS(petDetailResponse[0].vaccinations[0]?.id)}`, updatedValues)
      .then(({ data }) => {
        pushNotification("Vaccination Updated Successfully!", "success");
        setIsLoading(false);
        setIsEditVaccinationOpen(false);
        props.openDetailModal(false);
      })
      .catch((error) => {
        pushNotification("Something went wrong!", "error");
        // console.log(error);
      });
  };

  const deletePet = () => {
    setIsLoading(true);

    main_api
      .delete(companyAPIsEndPoints.DELETE_SINGLE_PET_LIST_PETS(petDetailResponse[0]?.id))
      .then(({ data }) => {
        setIsLoading(false);
        pushNotification("Pet Deleted Successfully!", "success");
        setIsDeletePetOpen(false);
        props.openDetailModal(false);
        props.loadPetList();
      })
      .catch((error) => {
        pushNotification("Something went wrong!", "error");
        // console.log(error);
      });
  };

  const deleteVaccination = () => {
    setIsLoading(true);

    main_api
      .delete(companyAPIsEndPoints.DELETE_SINGLE_VACCINATION_PETS(petDetailResponse[0].vaccinations[0]?.id))
      .then(({ data }) => {
        setIsLoading(false);
        pushNotification("Vaccination Deleted Successfully!", "success");
        setIsDeletePetOpen(false);
        props.openDetailModal(false);
      })
      .catch((error) => {
        pushNotification("Something went wrong!", "error");
        // console.log(error);
      });
  };

  const openEditPet = () => {
    if (checkPermission(permissionManagement.permissions, "edit_pet")) {
      setIsEditPetOpen(true);
    } else {
      setPermissionPopUp(true);
    }
  };

  const onDelete = () => {
    if (alignValue == "General Info") {
      if (checkPermission(permissionManagement.permissions, "delete_pet")) {
        setIsDeletePetOpen(true);
      } else {
        setPermissionPopUp(true);
      }
    } else {
      setIsDeletePetOpen(true);
    }
  };
  let petLogo = props.singlePetDetails?.logo;

  return (
    <DetailContainer isdetailModalOpen={props.isdetailModalOpen}>
      {isEditPetOpen && (
        <EditPetModal
          isLoading={isLoading}
          isModalOpen={isEditPetOpen}
          setModalOpen={setIsEditPetOpen}
          editPetValues={petDetailResponse && petDetailResponse[0]}
          onFinish={editPet}
          petLogo={petLogo}
          getImageFromEditPet={setImageFromComp}
        />
      )}
      <AddVaccinationModal
        isModalOpen={isAddVaccinationOpen}
        setModalOpen={setIsAddVaccinationOpen}
        onFinish={addVaccination}
        petList={props.petList}
        detailPage={{
          clientList: [
            {
              label: petDetailResponse && petDetailResponse[0]?.client_name,
              value: petDetailResponse && petDetailResponse[0]?.client_Id,
            },
          ],
          petList: [
            {
              label: petDetailResponse && petDetailResponse[0]?.name,
              value: petDetailResponse && petDetailResponse[0]?.id,
            },
          ],
        }}
      />
      <EditVaccinationModal
        isLoading={isLoading}
        isModalOpen={isEditVaccinationOpen}
        petList={props.petList}
        setModalOpen={setIsEditVaccinationOpen}
        onFinish={editVaccination}
        editVaccinationValues={{
          type: petDetailResponse && petDetailResponse[0]?.vaccinations[0]?.type,
          pet: petDetailResponse && petDetailResponse[0]?.id,
          date: petDetailResponse && petDetailResponse[0]?.vaccinations[0]?.date,
          expiry_date: petDetailResponse && petDetailResponse[0]?.vaccinations[0]?.expiry_date,
        }}
      />
      <DeleteConfirmationRowModal
        onclose={() => setIsDeletePetOpen(false)}
        handleConfirmDeleteRows={alignValue == "General Info" ? deletePet : deleteVaccination}
        openConfimrationDeleteModal={isDeletePetOpen}
      />
      <div>
        <CustomButton onClick={() => props.openDetailModal(false)} style={{ marginTop: "55px", marginLeft: "15px" }}>
          <CrossIcon />
        </CustomButton>
        <LinearGradientBG />
        <div className="d-flex align-items-end mb-4 gap-3" style={{ marginTop: "100px" }}>
          {/* <DefaultDog  style={{ "z-index": 1 }}/> */}
          <Avatar
            name={petLogo || petDetailResponse?.[0]?.name}
            src={petLogo}
            round={true}
            style={{ "z-index": 1 }}
            size="120px"
          />

          <div>
            <h4>{petDetailResponse && petDetailResponse[0]?.name}</h4>
            <div className="d-flex gap-3">
              <InfoBackground>{(petDetailResponse && petDetailResponse[0]?.type) || "---"}</InfoBackground>
              <InfoBackground>{(petDetailResponse && petDetailResponse[0]?.sex) || "---"}</InfoBackground>
            </div>
          </div>
        </div>
        <CustomSegmented
          defaultValue="General Info"
          style={{ marginBottom: 8 }}
          onChange={(value) => setAlignValue(value)}
          options={["General Info", "Vaccinations"]}
          className="w-100 p-1"
          size="large"
        />
        {alignValue == "General Info" && (
          <List
            dataSource={generalInfo}
            renderItem={(item, index) => (
              <List.Item className="justify-content-start">
                <div className="d-flex gap-2 w-50">
                  <div>{item.icon}</div>
                  <div>{item.title}</div>
                </div>
                <div>{item.description}</div>
              </List.Item>
            )}
          />
        )}
        {alignValue == "Vaccinations" && (
          <List
            dataSource={vaccination}
            renderItem={(item, index) => (
              <List.Item className="justify-content-start">
                <div className="d-flex gap-2 w-50">
                  <div>{item.icon}</div>
                  <div>{item.title}</div>
                </div>
                <div>{item.description}</div>
              </List.Item>
            )}
          />
        )}
      </div>
      <div className="d-flex w-100 gap-2" style={{ marginTop: "90px" }}>
        {/* <Button className="w-100" onClick={(e) => props?.openDetailModal(false)}>
          Close
        </Button> */}
        <Button
          className="w-100"
          onClick={() =>
            alignValue == "General Info"
              ? openEditPet()
              : petDetailResponse[0].vaccinations.length
                ? setIsEditVaccinationOpen(true)
                : setIsAddVaccinationOpen(true)
          }
        >
          {alignValue == "Vaccinations" && petDetailResponse && petDetailResponse[0].vaccinations.length == 0 ? "Add" : "Edit"}
        </Button>
        <Button className="w-100" onClick={(e) => onDelete()}>
          Delete
        </Button>
      </div>
      <PermissionModal onclose={() => setPermissionPopUp(false)} openModal={permissionPopUp} />
    </DetailContainer>
  );
};

const LinearGradientBG = styled.div`
  background: linear-gradient(93.86deg, #c7c3b9 0.64%, #f3f8f9 101.13%, #c8d2d3 101.13%);
  position: absolute;
  width: 100%;
  height: 160px;
  top: 0;
  left: 0;
  z-index: -1;
`;

const DetailContainer = styled.div`
  display: ${(props) => (props.isdetailModalOpen ? "flex" : "none")};
  padding: 15px;
  border-left: 1px solid #d0d5dd;
  width: 416px;
  font-weight: 500;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
  max-height: 100vh;
  min-height: 100vh;

  &::-webkit-scrollbar {
    width: 0;
  }
`;

const CustomSegmented = styled(Segmented)`
  .ant-segmented-group {
    gap: 5px;
  }
  .ant-segmented-item {
    width: 50%;
  }
`;

const InfoBackground = styled.div`
  font-weight: 400;
  padding: 0px 6px;
  background: #f5f5f5;
  border-radius: 21px;
`;

const CustomButton = styled.button`
  position: absolute;
  background: transparent;
  right: 20px;
`;

export default DetailPage;
