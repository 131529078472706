import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";

import { Col, Row, Tooltip } from "antd";
import { useNavigate } from "react-router-dom";
import { ReactComponent as LessThanIcon } from "../../../../assets/rawSvgs/lessThanBlack.svg";
import { ReactComponent as EditPencilIcon } from "../../../../assets/rawSvgs/editPencilIcon.svg";

import { ReactComponent as PhoneIcon } from "../../../../assets/rawSvgs/phoneIcon.svg";
import { ReactComponent as LocationIcon } from "../../../../assets/rawSvgs/locationIcon.svg";
import { ReactComponent as MessageIcon } from "../../../../assets/rawSvgs/messageIcon.svg";

import { HeaderContainer, UserNameWithIconWrapper, UserDetailIconTextWrapper, NavigationWrapper } from "./ClientDetailsStyles";

import HeadingComponent from "../../../../components/Heading";
import { navigationTabs } from "../../../../constants/CompanyNavTabs/clientDetailsNavigationTabs";
import AntDesignNavTabs from "../../../../components/antDesignNaviTabs/AntDesignNavTabs";
import PetsComponent from "./pets/Pets";
import ServiceRequest from "./ServiceRequest/ServiceRequest";
import KeysComponent from "./Keys";

import { checkPermission } from "../../../../util/commonUtil";
import NoDataScreen from "../../../../components/NoDataScreen";
import Invoicing from "../../../../containers/companyInvoicing/invoicing";
import ConversationScreen from "./conversation/Index";
import usePubNubServiceHook from "../../../../hooks/usePubnubChannelCountClients";
import { useSelector } from "react-redux";

import { main_api } from "../../../../api/axios_helper";
import truncateString from "../../../../util/truncate";
import AddNewClientForm from "../AddNewClientForm";

//
function ClientsDetail({
  fetchSingleClient,
  singleClientresponseData,
  loadVetList,
  vetList,
  searchPetList,
  permissionState,
  getManagementkeys,
  keyManagementState,
  clientResponse,
  getManagementClients,
  //
  companyInternalChatState,
  authState,
  loggedInType,
  allStaffs,
  fetchAllStaff,
  selectChat,
  fetchAllSchedulerService,
  clientSingleDetailsState,
  clientEditState,
  clientPostState,
  updateSingleClient,
  postManagementClient,
  resetClientResponse,
}) {
  let { clientId } = useParams();
  const auth = useSelector((state) => state.auth);
  let unreadCounts = useSelector((state) => state.generalApp.unreadCountsClient);
  const [openEditClientModal, setOpenEditClientModal] = useState(false);
  const [openEditClientValues, setOpenEditClientValues] = useState({});
  let compId = auth?.loginResponse?.selectCompany?.[0]?.company_id;
  const [fetchSerReq, setfetchSerReq] = useState([]);

  let filterclientWise = unreadCounts.filter((items) => {
    return items.custom?.client_id === parseInt(clientId);
  });
  const checkUnreadCounts = (unreadCounts) => {
    const privateUnreadCounts = unreadCounts.filter((count) => count.category === "private");
    const generalUnreadCounts = unreadCounts.filter((count) => count.category === "general");

    return {
      privateUnread: privateUnreadCounts.length > 0,
      generalUnread: generalUnreadCounts.length > 0,
    };
  };

  const unreadCountsStatus = checkUnreadCounts(unreadCounts);

  const hasUnreadCount = (channels) => {
    for (const channel of channels) {
      try {
        const { unreadCountData } = channel.custom || {};
        const parsedData = JSON.parse(unreadCountData) || {};

        if (!parsedData) {
          continue; // Skip if unreadCountData is not valid JSON
        }

        // Check for receivers
        if (parsedData.recievers) {
          for (const receiverId in parsedData.recievers) {
            if (receiverId === `company_admin_${compId}`) {
              const { unreadCount } = parsedData.recievers[receiverId];

              if (unreadCount > 0) {
                return true;
              }
            }
          }
        }
      } catch (error) {
        console.error(`Error parsing unreadCountData for channel: ${error}`);
      }
    }

    return false;
  };

  const resultUnread = hasUnreadCount(filterclientWise, compId);

  let [fetchChannelsToCheckUnreadCountCleint] = usePubNubServiceHook({
    loggedInType: auth?.loginResponse,
    authState: auth.loginResponse?.LoggedInUserData,
  });
  const navigate = useNavigate();

  const [activeTabNavigation, setActiveTabNavigation] = useState("");

  useEffect(() => {
    window.scroll(0, 0);
  }, [clientId]);

  const handleFetchAllSchedulerServices = async () => {
    try {
      const { data } = await main_api.get(
        `/api/v1/service_request/?service__company=${auth?.loginResponse?.selectCompany?.[0]?.company_id}&status=scheduled&status=in_progress&client=${clientId}`,
      );

      // get schee
      setfetchSerReq(data.result);
    } catch (error) {}
  };

  useEffect(() => {
    fetchSingleClient(clientId);
    getManagementClients();
    fetchChannelsToCheckUnreadCountCleint();
    // fetchAllSchedulerService();
    handleFetchAllSchedulerServices();
  }, []);

  const handleChanetabs = (key) => {
    setActiveTabNavigation(parseInt(key));
  };
  let conversationPropObject = {
    companyInternalChatState,
    selectChat,
    authState,
    fetchAllStaff,
    allStaffs,
    clientId,
    loggedInType,
    unreadCountsStatus,
    fetchChannelsToCheckUnreadCountCleint,
  };
  let showConversationTab = loggedInType?.selectedRoleType === "company_type" ? true : false;

  const navTabs = navigationTabs({
    petsManagement: (
      <PetsComponent
        vetList={vetList}
        loadVetList={loadVetList}
        searchPetList={searchPetList}
        singleClientresponseData={singleClientresponseData}
      />
    ),
    serviceRequest: <ServiceRequest fetchSerReq={fetchSerReq} />,
    keyManagement: (
      <KeysComponent
        getManagementkeys={getManagementkeys}
        keyManagementState={keyManagementState}
        clientResponse={clientResponse}
        singleClientresponseData={singleClientresponseData}
      />
    ),
    invoicing: (
      <>
        <Invoicing fromClientManagement={true} clientId={clientId} />
      </>
    ),

    conversation: <ConversationScreen {...conversationPropObject} />,
    showConversationTab: showConversationTab,
    unreandCount: resultUnread,
  });

  const handleEditClient = () => {
    setOpenEditClientModal(true);
    setOpenEditClientValues({
      address: singleClientresponseData?.address,
      city: singleClientresponseData?.city,
      id: singleClientresponseData?.id,
      phone_number: singleClientresponseData?.phone_number,
      name: singleClientresponseData?.name,
      email: singleClientresponseData?.email,
      groups: singleClientresponseData?.custom_fields?.groups || [],
    });
  };

  return checkPermission(permissionState, "view_client") ? (
    <>
      {clientSingleDetailsState?.isLoading ? (
        "Loading..."
      ) : (
        <>
          <HeaderContainer>
            <UserNameWithIconWrapper>
              <div className="d-flex align-items-end">
                <button onClick={() => navigate(-1)}>
                  <LessThanIcon width="13px" height="13px" />
                </button>
                <HeadingComponent text={singleClientresponseData?.name} fontSize="1.25rem" fontWeight={700} margin="0px 15px" />
              </div>
              <div className="d-flex align-items-center">
                {/* <button className=" hover">
              <ConversationIcon />
            </button> */}
                &nbsp; &nbsp;
                <button className="" onClick={handleEditClient}>
                  <EditPencilIcon />
                </button>
              </div>
            </UserNameWithIconWrapper>
            <UserDetailIconTextWrapper className="py-3">
              <Row gutter={20}>
                <Tooltip title={singleClientresponseData?.email} placement="topLeft">
                  <Col md={12}>
                    <IconWithTextHeaderComponent
                      icon={<MessageIcon />}
                      text={truncateString(singleClientresponseData?.email, 40) || "---"}
                    />
                  </Col>
                </Tooltip>
                <Tooltip title={singleClientresponseData?.address} placement="topLeft">
                  <Col md={10} className="mx-3">
                    <IconWithTextHeaderComponent
                      icon={<LocationIcon />}
                      text={truncateString(singleClientresponseData?.address, 70) || "---"}
                    />
                  </Col>
                </Tooltip>
                <Tooltip title={singleClientresponseData?.phone_number} placement="topLeft">
                  <Col md={12}>
                    <IconWithTextHeaderComponent icon={<PhoneIcon />} text={singleClientresponseData?.phone_number || "---"} />
                  </Col>
                </Tooltip>
              </Row>
            </UserDetailIconTextWrapper>
          </HeaderContainer>
          <NavigationWrapper>
            <AntDesignNavTabs navTabs={navTabs} onChange={handleChanetabs} className="ps-3" />
          </NavigationWrapper>

          {openEditClientModal && (
            <AddNewClientForm
              visible={openEditClientModal}
              clientPostState={clientPostState}
              postManagementClient={postManagementClient}
              handleClose={() => {
                fetchSingleClient(clientId);
                setOpenEditClientModal(false);
              }}
              resetClientResponse={resetClientResponse}
              getManagementClients={getManagementClients}
              openEditClientValues={openEditClientValues}
              updateClient={true}
              updateSingleClient={updateSingleClient}
              clientEditState={clientEditState}
              companyId={compId}
            />
          )}
        </>
      )}
    </>
  ) : (
    <>
      <NoDataScreen heading="You don’t have acces to view Client Detail" showBtn={false} />
    </>
  );
}

export const IconWithTextHeaderComponent = ({ icon, text }) => {
  return (
    <div className="d-flex align-items-baseline mt-3 me-4 ms-1">
      <span>{icon}</span>
      <HeadingComponent text={text} fontSize="0.875rem" fontWeight={400} margin="0px 6px" />
    </div>
  );
};

export default React.memo(ClientsDetail);
