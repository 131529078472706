import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { main_api } from "../../../api/axios_helper";
import CreateIcon from "../../../assets/icons/CreateIcon";
import ExportIcon from "../../../assets/icons/ExportIcon";
import CustomInput from "../../../components/CustomInput";
import IconButton from "../../../components/IconButton";
import Table from "../../../components/Table";
import Dayjs from "dayjs";
import CustomModal from "../../../components/Modal";
import { AntdesignTable } from "../../../components/antDesignTable/AntdesignTable";
import { PetListColumns, VaccinationsColumns, VetListColumns } from "../../../constants/CompanyTableHeaders/PetTableHeaders";
import { Button, DatePicker, Select } from "antd";
import { NavigationHeading, TableHeader } from "../clients/clientStyles";
import CustomBtnWithIcon from "../../../components/buttonWithIcon";
import SearchInput from "../../../components/SearchInput";
import AddPetModal from "../../../components/Modals/PetManagementModal/AddPetModal";
import dayjs from "dayjs";
import EditPetModal from "../../../components/Modals/PetManagementModal/EditPetModal";
import moment from "moment";
import AddVaccinationModal from "../../../components/Modals/PetManagementModal/AddVaccinationModal";
import PetListTab from "./PetListTab";
import VaccinationTab from "./VaccinationTab";
import VetListTab from "./VetListTab";
import AntDesignNavTabs from "../../../components/antDesignNaviTabs/AntDesignNavTabs";
const { RangePicker } = DatePicker;

function PetManagement(props) {
  const [activeTab, setActiveTab] = useState("1");
  const clientList = props.clientManagementState?.responseData;
  useEffect(() => {
    return () => {
      props.openDetailModal(false);
    };
  }, []);

  let isClientPortal = props.isClientPortal;

  // when client portal is logged in same component is using in comp and client portal

  let clientId = "";
  if (isClientPortal) {
    props?.authState?.selectCompany?.forEach((element) => {
      if (element?.client_id) {
        clientId = element?.client_id;
      }
    });
  }
  const tabs = [
    {
      key: "1",
      label: "Pet List",
    },
    {
      key: "2",
      label: "Vaccinations",
    },
    {
      key: "3",
      label: "Vet List",
    },
  ];
  return (
    <>
      <NavigationHeading>Pets Management</NavigationHeading>
      <AntDesignNavTabs navTabs={tabs} onChange={(e) => setActiveTab(e)} />
      {/* <ActionButtons>
        <TabContainer>
          <TabButton onClick={() => setActiveTab("pet-list")} active={activeTab === "pet-list"}>
            Pet List
          </TabButton>
          <TabButton onClick={() => setActiveTab("vaccinations")} active={activeTab === "vaccinations"} marginLeft="30px">
            Vaccinations
          </TabButton>
          {!isClientPortal && (
            <TabButton onClick={() => setActiveTab("vets-list")} active={activeTab === "vets-list"} marginLeft="30px">
              Vets List
            </TabButton>
          )}
        </TabContainer>
      </ActionButtons> */}
      {activeTab == 1 && (
        <PetListTab
          petList={props.petList}
          vetList={props.vetList}
          loadPetList={props.loadPetList}
          loadVetList={props.loadVetList}
          loadPetDetail={props.loadPetDetail}
          openDetailModal={props.openDetailModal}
          searchPetList={props.searchPetList}
          clientList={clientList}
          getManagementClients={props.getManagementClients}
          clientId={clientId}
          isClientPortal={isClientPortal}
          setSinglePetDetails={props?.setSinglePetDetails}
        />
      )}
      {activeTab == 2 && (
        <VaccinationTab
          petList={props.petList}
          loadPetList={props.loadPetList}
          vaccination={props.vaccination}
          loadVaccinationsList={props.loadVaccinationsList}
          searchVaccinationList={props.searchVaccinationList}
          clientList={clientList}
          getManagementClients={props.getManagementClients}
          clientId={clientId}
          isClientPortal={isClientPortal}
        />
      )}
      {activeTab == 3 && (
        <VetListTab vetList={props.vetList} loadVetList={props.loadVetList} searchVetList={props.searchVetList} />
      )}
    </>
  );
}
const ActionButtons = styled.div`
  display: flex;
  margin-bottom: 27px;
  border-bottom: 1px solid rgb(228, 234, 240);
`;

const TabContainer = styled.div`
  padding: 16px 16px 0px 16px;
  height: 54px;
  box-sizing: border-box;
  border-radius: 6px;
  display: flex;
`;
const TabButton = styled.button`
  background-color: transparent;
  padding: 9px 8px 29px 8px;
  height: 34px;
  border: none;
  margin-left: ${(props) => props.marginLeft ?? "0"};
  cursor: pointer;
  border-bottom: 2px solid ${(props) => (props.active ? "#FBCF34" : "transparent")};
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: ${(props) => (props.active ? "#000000" : "#757575")};
`;
const VaccinationTabContainer = styled.div`
  height: 24px;
  width: 274px;
  display: flex;
  box-sizing: border-box;
  background: #f2f6f9;
  border: 1px solid #d8e2e8;
  border-radius: 6px;
  margin-bottom: 20px;
  justify-content: space-between;
`;

const VaccinationButton = styled.button`
  background-color: ${(props) => (props.active ? "#FFFFFF" : "transparent")};
  padding: 0 16px;
  height: 34px;
  cursor: pointer;
  // width: 123px;
  height: 24px;
  border: ${(props) => (props.active ? "1px solid #d8e2e8" : "none")};
  border-radius: 6px;

  font-weight: 400;
  font-size: 12px;
  line-height: 11px;
  color: ${(props) => (props.active ? "#1B2126" : "#9FA9B2")};

  &:hover {
    background-color: lightblue;
  }
`;

const SelectContainer = styled.div``;

const ActionButton = styled.button`
  background-color: #e9bd5a;
  padding: 6px 14px;
  height: 28px;
  border-radius: 4px;
  border: none;
  margin-left: ${(props) => props.marginLeft ?? "0"};
  cursor: pointer;
  align-self: center;

  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  color: #ffffff;

  &:hover {
    background-color: lightblue;
  }
`;

export default PetManagement;
