import React, { useEffect, useState } from "react"
import AntDesignModal from "../../../components/Modals/AntDesignModal/AntDesignModal";
import { Divider, Input } from "antd";
import CustomBtnWithIcon from "../../../components/buttonWithIcon";


function ReportUpdate({currentReport, setCurrentReport, setUpdateReport, generateReport, setSaveReport}) {

  const [reportName, setReportName] = useState(currentReport.name)

  return (
    <>
        <AntDesignModal
            closeModal={()=>{setUpdateReport(false); setSaveReport(false)}}
            open={true}
            title={"Report"}
            width={"450px"}
            component={
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '5px'
                    }}
                >
                    <label>Report Name</label>
                    <Input defaultValue={reportName} onChange={e => setReportName(e.target.value)} placeholder="Report Name" ></Input>
                </div>
            }
            footer={
                <>
                    <Divider />
                    <div className="d-flex justify-content-end ">
                        <div>
                            <CustomBtnWithIcon btnText={"Update Existing"} margin="0px 5px" hideIcon={true} onClick={()=>{
                                setCurrentReport(
                                    {
                                        ...currentReport,
                                        name: reportName
                                    }
                                )
                                generateReport(
                                    {
                                        ...currentReport,
                                        name: reportName
                                    }
                                )
                                setUpdateReport(false)
                            }} />
                        </div>
                        <div className="d-flex">
                            <CustomBtnWithIcon
                                btnText={"New Report"}
                                type="button"
                                hideIcon={true}
                                onClick={() => {
                                    setCurrentReport({name: reportName})
                                    generateReport(
                                        {
                                            ...currentReport,
                                            name: reportName,
                                            id: null
                                        }
                                    )
                                    setUpdateReport(false)
                                }}
                            />
                        </div>
                    </div>
                </>
            }
        />

    </>
  )
}

export default ReportUpdate;
