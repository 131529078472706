import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import Tabs from "../../../../../components/Tabs";

import { Skeleton } from "antd";

import CustomBtnWithIcon from "../../../../../components/buttonWithIcon";
import ClientFieldsCard from "./fieldCard";
import AddFieldModal from "./modals/addFieldModal";
import AddGroupModal from "./modals/addGroupModal";
import { useDispatch } from "react-redux";
import { loadCompanyDetail } from "../../../../../redux/platformSlices/CompanyDetail";
import { useSelector } from "react-redux";
import { Divider } from "antd";
import { ClientOnboarding } from "./clientOnboardingSettings";
import { ServiceRequest } from "./serviceRequest";
import { Messaging } from "./messaging";

import ClientField from "./clientField";
import SideBar from "../../../../../components/settingsSideBar/sideBar";

export default function Client({ companyClientSettings, handleFetchClientOnBoardSettings, company_id }) {
  const [isFieldModalOpen, setIsFieldModalOpen] = useState(false);
  const [isGroupModalOpen, setIsGroupModalOpen] = useState(false);
  const [selectSidebarNavItem, setSelectSidebarNavItem] = useState("1");

  const auth = useSelector((state) => state.auth);
  const loading = useSelector((state) => state.companyDetail.isLoading);
  const details = useSelector((state) => state.companyDetail.companyDetailResponse);

  const clientGroups = details?.result?.client_custom_fields?.groups || [];
  const petGroups = details?.result?.pet_custom_fields?.groups || [];

  const clientNonCustomFields = details?.result?.client_non_custom_fields || [];
  const petNonCustomFields = details?.result?.pet_non_custom_fields || [];

  const companyId = auth.loginResponse?.selectCompany?.[0]?.company_id || null;

  const [selectedTab, setSelectTab] = useState("ClientField");

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadCompanyDetail(companyId));
  }, [dispatch, companyId]);

  const menuItemsForClient = [
    { key: "1", label: "Customer Fields" },
    { key: "2", label: "Client Onboarding" },
    { key: "3", label: "Service Requests" },
    { key: "4", label: "Messaging" },
  ];

  const tabs = [
    { label: "Client Field", name: "ClientField" },
    {
      label: "Pet Field",
      name: "PetField",
    },
  ];

  const renderFieldsAndGroups = (nonCustomFields, groups) => {
    return (
      <Skeleton rows={50} loading={loading} active>
        {nonCustomFields?.map((field) => (
          <ClientField key={field.id} selectedTab={selectedTab} field={field} isNonCustomField />
        ))}
        {groups?.map((group, index) => (
          <ClientFieldsCard key={`group-${group?.name || index}`} selectedTab={selectedTab} group={group} groupIndex={index} />
        ))}
      </Skeleton>
    );
  };
  const formikRefClientSettings = useRef();

  return (
    <div>
      <Container>
        <NavContainer>
          <SideBar
            menuItems={menuItemsForClient}
            handleMenuSelect={(e) => {
              setSelectSidebarNavItem(e.key);
            }}
          />
        </NavContainer>
        {selectSidebarNavItem === "2" ? (
          <ComponentContainer>
            <Wrapper>
              <ClientOnboarding
                companyClientSettings={companyClientSettings}
                handleFetchClientOnBoardSettings={handleFetchClientOnBoardSettings}
                formikRefClientSettings={formikRefClientSettings}
                company_id={company_id}
              />
              <SaveContainer className="d-flex justify-content-end">
                <CustomBtnWithIcon
                  btnText="Save Changes"
                  hideIcon={true}
                  type="button"
                  onClick={() => {
                    if (formikRefClientSettings?.current) {
                      formikRefClientSettings?.current.handleSubmit();
                    }
                    console.log("Save settings");
                  }}
                />
              </SaveContainer>
            </Wrapper>
          </ComponentContainer>
        ) : selectSidebarNavItem === "1" ? (
          <>
            <ComponentContainer>
              <Wrapper>
                <Tabs tabs={tabs} selectTab={selectedTab} setSelectTab={setSelectTab} />
                <ActionsWrapper>
                  <ButtonWrapper>
                    <CustomBtnWithIcon btnText="Add new Group" noBackground onClick={() => setIsGroupModalOpen(true)} />
                    <CustomBtnWithIcon btnText="Add new Field" onClick={() => setIsFieldModalOpen(true)} />
                  </ButtonWrapper>
                </ActionsWrapper>
                {selectedTab === "ClientField"
                  ? renderFieldsAndGroups(clientNonCustomFields, clientGroups)
                  : renderFieldsAndGroups(petNonCustomFields, petGroups)}
              </Wrapper>
              <Divider />
              <SaveContainer className="d-flex justify-content-end">
                <CustomBtnWithIcon btnText="Save Changes" type="submit" hideIcon={true} />
              </SaveContainer>
            </ComponentContainer>
            <AddFieldModal selectedTab={selectedTab} isModalOpen={isFieldModalOpen} setModalOpen={setIsFieldModalOpen} />
            <AddGroupModal
              selectedTab={selectedTab}
              isModalOpen={isGroupModalOpen}
              groups={clientGroups}
              setModalOpen={setIsGroupModalOpen}
            />
          </>
        ) : selectSidebarNavItem === "3" ? (
          <ComponentContainer>
            <Wrapper>
              <ServiceRequest />
              <SaveContainer className="d-flex justify-content-end">
                <CustomBtnWithIcon
                  btnText="Save Changes"
                  hideIcon={true}
                  onClick={() => {
                    console.log("Save ServiceRequest");
                  }}
                />
              </SaveContainer>
            </Wrapper>
          </ComponentContainer>
        ) : selectSidebarNavItem === "4" ? (
          <ComponentContainer>
            <Wrapper>
              <Messaging />
            </Wrapper>
          </ComponentContainer>
        ) : null}
      </Container>
    </div>
  );
}

export const Container = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const Wrapper = styled.div``;
export const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

export const SaveContainer = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  border-top: 1px solid #e0e0e0;
  background-color: white;
  padding: 10px 20px;
  margin-top: 30px;
`;

export const NavContainer = styled.nav`
  width: 200px;
  min-width: 200px;
  margin-right: 2px;
  color: black;
`;

export const ComponentContainer = styled.nav`
  width: 88%;
  margin-left: 2px;
`;
