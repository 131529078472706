import React, { useEffect, useState } from "react";
import { CustomModal } from "../../../components/Modals/ModalStyles";
import styled from "styled-components";
import { Button, DatePicker, Divider, Flex, Form, Input, Radio, Select, Space, Steps, Row, Col, Checkbox, Collapse } from "antd";
import CustomBtnWithIcon from "../../../components/buttonWithIcon";
import { useForm } from "antd/es/form/Form";
import { PlusCircleOutlined } from "@ant-design/icons";
import { getPetListForClient, getServices, getTimeBlocks, getKeysListForClient } from "../../../util/DataService";
import CommonUtil from "../../../util/commonUtil";
import { useSelector } from "react-redux";
import axiosInstance from "../../../util/AxiosConfig";
import { companyAPIsEndPoints } from "../../../constants/ApiEndPoints";
import { pushNotification } from "../../../util/Notification";
import dayjs from "dayjs";
import { TagInputs } from "../../../components/TagInputs";
import "./scheduler.css";
import moment from "moment";
import { ReactComponent as DeleteIcon } from "../../../assets/rawSvgs/tableDeleteIcon.svg";

const { TextArea } = Input;
const ServiceRequestModal = ({ isModalOpen, setIsModalOpen, handleDataChange }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [modalCurrentScreen, setModalCurrentScreen] = useState("schedule_dates");
  return (
    <CustomModal
      open={isModalOpen}
      title="Request new Service"
      width="800px"
      heigth="583px"
      onCancel={() => {
        setModalCurrentScreen("schedule_dates");
        setIsModalOpen(false);
      }}
      footer={null}
      maskClosable={false}
    >
      <Steps
        className="service-stepper"
        current={modalCurrentScreen === "schedule_dates" ? 0 : 1}
        type="navigation"
        items={[
          {
            title: "Schedule Dates",
          },
          {
            title: "Select Services",
          },
        ]}
      />

      {modalCurrentScreen === "schedule_dates" && (
        <ScheduleDatesScreen
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          setIsModalOpen={setIsModalOpen}
          setModalCurrentScreen={setModalCurrentScreen}
        />
      )}
      {modalCurrentScreen === "select_services" && (
        <SelectServicesScreen
          handleDataChange={handleDataChange}
          startDate={startDate}
          endDate={endDate}
          setIsModalOpen={setIsModalOpen}
          setModalCurrentScreen={setModalCurrentScreen}
        />
      )}
    </CustomModal>
  );
};

const ScheduleDatesScreen = ({ startDate, endDate, setStartDate, setEndDate, setIsModalOpen, setModalCurrentScreen }) => {
  const [dayRange, setDayRange] = useState("single");
  const [form] = Form.useForm();
  const handleSubmit = (formValues) => {
    setStartDate(formValues.startDate);
    setEndDate(formValues.endDate);
    setModalCurrentScreen("select_services");
  };

  form.setFieldsValue({
    startDate: startDate ? dayjs(startDate) : null,
    endDate: endDate ? dayjs(endDate) : null,
  });

  const validateDate = (rule, value, callback) => {
    const { getFieldValue } = form;

    const startDate = getFieldValue("startDate");
    const endDate = getFieldValue("endDate");

    if (startDate && endDate) {
      if (dayjs(endDate).isBefore(startDate)) {
        callback("Start date should be before the End date");
        return;
      } else if (dayjs(startDate).isSame(endDate, "day")) {
        callback("Start and End Date should not be same");
        return;
      }
      form.setFields([
        {
          name: "startDate",
          errors: [],
        },
        {
          name: "endDate",
          errors: [],
        },
      ]);
    }
    callback();
  };

  return (
    <div style={{ marginTop: "20px" }}>
      <FieldHeading>Select Days</FieldHeading>
      <Space direction="vertical" className="w-100">
        <Radio.Group onChange={(e) => setDayRange(e.target.value)} value={dayRange}>
          <Space direction="vertical">
            <Radio value={"multiple"}>Multiple Days</Radio>
            <Radio value={"single"}>Single Days</Radio>
          </Space>
        </Radio.Group>
        <Form name="scheduleDateModal" onFinish={handleSubmit} form={form} layout="vertical">
          <>
            {dayRange === "single" ? (
              <>
                <Form.Item
                  name="startDate"
                  label="Pick Date"
                  rules={[
                    {
                      required: true,
                      message: "Please select Date",
                    },
                  ]}
                >
                  <DatePicker
                    disabledDate={(current) => current && current < moment().startOf("day")}
                    className="w-50"
                    placeholder="Select Date"
                    type="date"
                  />
                </Form.Item>
              </>
            ) : (
              <Flex gap={10} style={{ width: "100%" }}>
                <Form.Item
                  className="w-50"
                  name="startDate"
                  label="Start Date"
                  rules={[
                    {
                      required: true,
                      message: "Please select Start Date",
                    },
                    { validator: validateDate },
                  ]}
                >
                  <DatePicker
                    disabledDate={(current) => current && current < moment().startOf("day")}
                    style={{ width: "100%" }}
                    placeholder="Select Start Date"
                    type="date"
                  />
                </Form.Item>

                <Form.Item
                  className="w-50"
                  name="endDate"
                  label="End Date"
                  rules={[
                    {
                      required: true,
                      message: "Please select End Date",
                    },
                    { validator: validateDate },
                  ]}
                >
                  <DatePicker
                    disabledDate={(current) => current && current < moment().startOf("day")}
                    style={{ width: "100%" }}
                    placeholder="Select End Date"
                    type="date"
                  />
                </Form.Item>
              </Flex>
            )}
            <Divider />
            <Flex justify="flex-end" gap="middle">
              <CustomBtnWithIcon
                width="86px"
                noBackground={true}
                hideIcon={true}
                btnText={"Cancel"}
                onClick={() => setIsModalOpen(false)}
              />
              <CustomBtnWithIcon type="submit" width="86px" hideIcon={true} btnText={"Next"} />
            </Flex>
          </>
        </Form>
      </Space>
    </div>
  );
};

export const SelectServicesScreen = ({
  startDate,
  endDate,
  setIsModalOpen,
  setModalCurrentScreen,
  selectedEvent,
  handleDataChange,
}) => {
  const [form] = useForm();
  const [serviceList, setServiceList] = useState([]);
  const [timeBlocks, setTimeBlocks] = useState([]);
  const [petList, setPetList] = useState([]);
  const [keyList, setKeyList] = useState([]);
  const [initialValues, setInitialValues] = useState([]);
  const [allServices, setAllServices] = useState([]);

  const auth = useSelector((state) => state.auth);
  const company_id = auth.loginResponse?.selectCompany[0].company_id;
  const client_id = auth.loginResponse?.selectCompany?.filter((item) => item.client_id !== null)[0].client_id;

  const [keyRequired, setKeyRequired] = useState(false);

  const createItems = (startDate, endDate) => {
    let currentDate = dayjs(startDate);
    const newItems = [];

    if (endDate) {
      while (currentDate.isBefore(endDate) || currentDate.isSame(endDate)) {
        newItems.push(currentDate.format("YYYY-MM-DD"));
        currentDate = currentDate.add(1, "day");
      }
    } else {
      newItems.push(currentDate.format("YYYY-MM-DD"));
    }

    let initialValues = [];

    if (selectedEvent) {
      const petIds = selectedEvent.pets.map((pet) => pet.id);
      const time_block_id = selectedEvent.time_block ? selectedEvent.time_block.id : null;
      const key = selectedEvent.key ? selectedEvent.key.id : null;
      const task_name = selectedEvent.service_request_task.map((obj) => obj.name);
      initialValues = newItems.map((date) => ({
        date,
        services: [{ service: selectedEvent.service.id, time_block: time_block_id, pets: petIds, tasks: task_name }],
      }));
      if (newItems.length) {
        form.setFieldsValue({ date: dayjs(newItems[0]), key: key, note: selectedEvent.note || "" });
      }
      if (selectedEvent.key) {
        setKeyRequired(true);
      }
    } else {
      initialValues = newItems.map((date) => ({
        date,
        services: [{ service: null, time_block: null, pets: null }],
      }));
    }

    setInitialValues(initialValues);

    form.setFieldsValue({ items: initialValues });
  };

  useEffect(() => {
    getServices(company_id).then(({ data }) => {
      setAllServices(data.result);
      setServiceList(CommonUtil.convertObjToSelectOptionsAntd(data.result));
    });

    getTimeBlocks().then(({ data }) => {
      setTimeBlocks(CommonUtil.formattedTimeSlots(data.result));
    });

    getPetListForClient(client_id).then(({ data }) => {
      setPetList(CommonUtil.convertObjToSelectOptionsAntd(data.result) || []);
    });

    getKeysListForClient(client_id).then(({ data }) => {
      setKeyList(CommonUtil.convertObjToSelectOptionsAntd(data.result) || []);
    });
  }, []);

  useEffect(() => {
    createItems(startDate, endDate);
  }, [startDate, endDate]);

  const onFinish = (formValues) => {
    if (selectedEvent) {
      //update
      let data = {
        date: formValues.date.format("YYYY-MM-DD"),
        id: selectedEvent.id,
        change_request_id: selectedEvent.change_request_id,
        client: client_id,
        one_day_service_request: selectedEvent.one_day_service_request.id,
        key: keyRequired ? formValues.key : null,
        note: formValues.note || "",
      };
      formValues.items.map((serviceRequest) => {
        serviceRequest.services.map((request) => {
          const { pets, service, time_block, tasks, timeSlotList } = request;
          data["pets"] = Array.isArray(pets) ? pets : [pets];
          data["service"] = service;
          data["time_block"] = time_block;
          const taskObjects = tasks.map((task) => {
            return {
              name: task,
              company: company_id,
            };
          });
          data["tasks"] = taskObjects;
        });
      });

      axiosInstance
        .post(companyAPIsEndPoints.CHANGE_SERVICE_REQUEST, data)
        .then(({ data }) => {
          if (data.message) {
            pushNotification(data.message, "success");
            setIsModalOpen(false);
            handleDataChange();
          }
        })
        .catch((error) => {
          console.log(error);
          pushNotification("Something Went Wrong!", "error");
        });
    } else {
      //create
      const updatedValues = formValues.items.map((serviceRequest) => {
        return {
          ...serviceRequest,
          services: serviceRequest.services.map((request) => {
            const { pets, tasks = [], timeSlotList, ...rest } = request;
            const taskObjects = tasks.map((task) => {
              return {
                name: task,
                company: company_id,
              };
            });
            return {
              ...rest,
              pets: Array.isArray(pets) ? pets : [pets],
              tasks: taskObjects,
            };
          }),
        };
      });

      const request = {
        client: client_id,
        company: company_id,
        service_requests: updatedValues,
        note: formValues.note || "",
        key: keyRequired ? formValues.key : null,
        scheduled_by: "client",
      };

      axiosInstance
        .post(companyAPIsEndPoints.SERVICE_REQUEST_GROUP, request)
        .then(({ data }) => {
          if (data.isSuccess) {
            pushNotification(data.message, "success");
            setIsModalOpen(false);
            handleDataChange();
            setModalCurrentScreen("schedule_dates");
            form.resetFields();
          }
        })
        .catch((error) => {
          pushNotification("Something Went Wrong!", "error");
        });
    }
  };

  return (
    <div>
      <Form form={form} onFinish={onFinish}>
        <FromContaner>
          {initialValues.map((item, index) => (
            <Collapse className="request-serivice-collapse" key={index} bordered={false} defaultActiveKey={["0"]}>
              <Collapse.Panel
                header={<DateHeading>{dayjs(item.date, "YYYY-MM-DD").format("DD MMMM, YYYY")}</DateHeading>}
                key={index}
              >
                <div key={index}>
                  {/* {!selectedEvent &&<DateHeading>{dayjs(item.date,"YYYY-MM-DD").format("DD MMMM, YYYY")}</DateHeading>} */}
                  {selectedEvent && (
                    <Form.Item
                      name="date"
                      label=""
                      rules={[
                        {
                          required: true,
                          message: "Date is Required",
                        },
                      ]}
                    >
                      <DatePicker
                        disabledDate={(current) => current && current < moment().startOf("day")}
                        placeholder="Select Date"
                        style={{ width: "100%" }}
                        type="date"
                        format={"DD/MM/YYYY"}
                      />
                    </Form.Item>
                  )}
                  <Form.Item name={["items", index, "date"]} initialValue={item.date} hidden />
                  <Form.List name={["items", index, "services"]}>
                    {(fields, { add, remove }, { errors }) => (
                      <>
                        {fields.map((field, fieldIndex) => (
                          <Container>
                            <div key={field.key}>
                              <ItemHeader>
                                <FieldHeading>Select Services for pets and time block against it</FieldHeading>
                                {fields.length > 1 && (
                                  <DeleteButton
                                    onClick={() => {
                                      remove(fieldIndex);
                                    }}
                                  >
                                    <DeleteIcon />
                                  </DeleteButton>
                                )}
                              </ItemHeader>
                              <Row gutter={16} className="w-100">
                                <Col span={8}>
                                  <Form.Item
                                    name={[field.name, "service"]}
                                    rules={[
                                      {
                                        required: true,
                                        message: <div style={{ marginTop: "10px" }}>Please select Service</div>,
                                      },
                                    ]}
                                  >
                                    <Select
                                      placeholder="Select Service"
                                      options={serviceList}
                                      onChange={(e) => {
                                        const filterServicToGetTimeBlocks =
                                          allServices?.filter((items) => {
                                            return items.id === e;
                                          })[0] || [];
                                        // Assuming filterService.time_blocks is an array of numbers
                                        const timeBlocksFilter = filterServicToGetTimeBlocks?.time_blocks || [];

                                        //  TIME BLOCK FROM SERVICCe
                                        const filteredTimeBlocks =
                                          timeBlocks.filter((item) => {
                                            return timeBlocksFilter.includes(item.value);
                                          }) || [];

                                        const items = form.getFieldValue("items");
                                        items[index].services[fieldIndex].timeSlotList = filteredTimeBlocks;
                                        form.setFieldsValue({ items });
                                      }}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col span={8}>
                                  <Form.Item
                                    name={[field.name, "time_block"]}
                                    rules={[
                                      {
                                        required: true,
                                        message: <div style={{ marginTop: "10px" }}>Please select Time Block</div>,
                                      },
                                    ]}
                                  >
                                    <Select
                                      placeholder="Select Time Blocks"
                                      options={
                                        form.getFieldValue(["items", index, "services", fieldIndex, "timeSlotList"]) || timeBlocks
                                      }
                                      // options={timeBlocks}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col span={6}>
                                  <Form.Item
                                    name={[field.name, "pets"]}
                                    rules={[
                                      {
                                        required: true,
                                        message: <div style={{ marginTop: "10px" }}>Please select Pets</div>,
                                      },
                                    ]}
                                  >
                                    <Select mode="multiple" placeholder="Select Pet" options={petList} />
                                  </Form.Item>
                                </Col>
                              </Row>
                              <Row>
                                <TaskLabel>Tasks</TaskLabel>
                                <Form.Item style={{ width: "100%" }} label="" name={[field.name, "tasks"]}>
                                  <TagInputs onChange={() => {}} />
                                </Form.Item>
                              </Row>
                            </div>
                          </Container>
                        ))}
                        {!selectedEvent && (
                          <Button
                            style={{
                              border: "none",
                              outline: "none",
                              marginTop: "10px",
                              marginBottom: "10px",
                              fontWeight: 700,
                              padding: 0,
                              color: "black",
                            }}
                            onClick={() => add()}
                            icon={<PlusCircleOutlined style={{ color: "#ffe25e" }} />}
                          >
                            Add more
                          </Button>
                        )}
                      </>
                    )}
                  </Form.List>
                </div>
              </Collapse.Panel>
            </Collapse>
          ))}

          <div style={{ paddingRight: "16px" }}>
            <FormLabel>Comments</FormLabel>

            <Form.Item name="note" label="">
              <TextArea rows={3} placeholder="Type notes here..." />
            </Form.Item>
            <Checkbox
              style={{ fontWeight: 500, marginBottom: "10px" }}
              checked={keyRequired}
              onChange={(e) => setKeyRequired(e.target.checked)}
              value={keyRequired}
            >
              Key Required?
            </Checkbox>
            {keyRequired && (
              <Form.Item
                name="key"
                rules={[
                  {
                    required: true,
                    message: <div style={{ marginTop: "10px" }}>Please select Key</div>,
                  },
                ]}
              >
                <Select placeholder="Select Key" options={keyList} />
              </Form.Item>
            )}
          </div>
        </FromContaner>
        <Divider />
        <Flex justify="space-between">
          {!selectedEvent && (
            <CustomBtnWithIcon
              width="86px"
              noBackground={true}
              hideIcon={true}
              btnText={"Back"}
              onClick={() => setModalCurrentScreen("schedule_dates")}
            />
          )}
          <Flex className="ms-auto" gap="middle">
            <CustomBtnWithIcon
              width="86px"
              noBackground={true}
              hideIcon={true}
              btnText={"Cancel"}
              onClick={() => setIsModalOpen(false)}
            />

            {selectedEvent ? (
              <CustomBtnWithIcon hideIcon={true} htmlType="submit" btnText={"Submit for Approval"} onClick={() => {}} />
            ) : (
              <CustomBtnWithIcon
                hideIcon={true}
                htmlType="submit"
                btnText={"Submit Request"}
                onClick={() => setModalCurrentScreen("select_services")}
              />
            )}
          </Flex>
        </Flex>
      </Form>
    </div>
  );
};

const ItemHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const DeleteButton = styled.button`
  border: none;
  background: none;
`;

const FieldHeading = styled.div`
  font-family: Helvetica Neue;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  margin: 5px 0px;
`;

const Container = styled.div`
  background-color: #eeeeee;
  padding: 12px 16px 12px 16px;
  margin-top: 10px;
  border-radius: 8px;
`;

const FormLabel = styled.div`
  font-weight: 700;
`;

const DateHeading = styled.div`
  font-weight: 700;
  font-size: 16px;
`;

const TaskLabel = styled.div`
  width: 100%;
  font-weight: 500;
  font-size: 12px;
  margin-bottom: 5px;
`;

const FromContaner = styled.div`
  max-height: 500px;
  overflow-y: scroll;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none;
  / &::-webkit-scrollbar {
    display: none;
  }
`;
export default ServiceRequestModal;
