import styled from "styled-components";

const HeaderContainer = styled.div`
  display: flex;
  min-height: 132px;
  background: #eeeeee;
  padding: 16px 24px;
  width: 100%;
  flex-direction: column;
`;
const UserNameWithIconWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 25px;
  padding: 5px;
  width: 100%;

  justify-content: space-between;
`;

const UserDetailIconTextWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 70%;
`;
const NavigationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-bextween;
  width: 100%;
  padding: 0px 0px;
`;

export { HeaderContainer, UserNameWithIconWrapper, UserDetailIconTextWrapper, NavigationWrapper };
