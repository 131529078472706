

//-------------------------------------------




// // RouteMaps.jsx

// import React, { useEffect, useMemo, useState } from 'react';
// import styled from 'styled-components';
// import { Button, Drawer, DatePicker } from 'antd';
// import { CloseIcon, FilterIcon } from '../../assets/rawSvgs';
// import SearchInput from '../SearchInput';
// import HeadingComponent from '../Heading';
// import AntDesignDropDownSelect from '../AntDesignDropDownSelect/AntDesignDropDownSelect';
// import CustomGoogleMap from '../MapComponent/customGoogleMap';
// import StaffRoutes from './staffRoutes';
// import { basic_api, main_api } from '../../api/axios_helper';
// import CustomBtnWithIcon from '../buttonWithIcon';
// import { useSelector } from 'react-redux';
// import { routeOptimizerAPIsEndPoints } from '../../constants/ApiEndPoints';
// import dummyData from '../../util/routeOptimizerDummyData';
// import { pushNotification } from '../../util/Notification';
// import moment from 'moment';
// import dayjs from 'dayjs';
// const { RangePicker } = DatePicker;

// const RouteMaps = () => {
//   const { auth } = useSelector((state) => state);
//   const [isPanelOpen, setIsPanelOpen] = useState(false);
//   const [position, setPosition] = useState({ lat: 31.5438232421875, lng: 74.32034375 });
//   const [address, setAddress] = useState('');
//   const [routeData, setRouteData] = useState(null);
//   const [route, setRoute] = useState(null);
//   const [filteredRoutes, setFilteredRoutes] = useState(null);
//   const [filteredStaffs, setFilteredStaffs] = useState(null);
//   const [selectedDate, setSelectedDate] = useState('Today');
//   const [staffs, setStaffs] = useState([]);
//   const [staffChange, setStaffChange] = useState();
//   const [selectedStaff, setSelectedStaff] = useState(null);
//   const [filterDrawer, setFilterDrawer] = useState(false);
//   const [markers, setMarkers] = useState([]);
//   const [directionsResponse, setDirectionsResponse] = useState(null);
//   const [objectId, setObjectId] = useState(null);
//   const [customDate, setCustomDate] = useState(false);
//   const today = dayjs();
//   const startDate = today.clone().subtract(5, 'days');
//   const endDate = today.clone().add(5, 'days');
//   const [dateRange, setDateRange] = useState([startDate, endDate]);

//   const key = process.env.REACT_APP_GOOGLE_MAP_API_KEY;

  
// const fetchRouteData = async () => {
//   try {
//     const companyId = auth?.loginResponse?.selectCompany?.[0]?.company_id;
//     const response = await main_api.get(routeOptimizerAPIsEndPoints.GET_STAFF_ROUTES(69));
//     if (response.data.isSuccess && response.data.result.length > 0 ) {
//       setRouteData(response.data.result);
//     } else{
//       pushNotification("No Route Data Found!", "error");
//       // setRouteData(dummyData.result);
//     }

//   } catch (error) {
//     pushNotification("Something went wrong!", "error");
//     // console.error('Error fetching route data:', error);
//   }
// };

//   //   // Time Formater to local time
//   // function formatTime(timestamp) {
//   //     return moment.unix(timestamp).format('hh:mm A');
//   // }

//    // Time Formater stop
//    function formatTime(timestamp) {
//     return moment.unix(timestamp).utc().format('hh:mm A');
// }

//   const handleGeocode = async () => {
//     try {
//       const response = await basic_api.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${key}`);
//       const location = response.data.results[0].geometry.location;
//       setPosition({ lat: location.lat, lng: location.lng });
//     } catch (error) {
//       console.error("Error in forward geocoding:", error);
//     }
//   };

//   const handleRoute = async (start, end) => {
//     try {
//       const response = await fetch(`https://maps.googleapis.com/maps/api/directions/json?origin=${start[0]},${start[1]}&destination=${end[0]},${end[1]}&key=${key}`);
//       const data = await response.json();
//       if (data.routes && data.routes.length > 0) {
//         setRoute(data.routes[0].overview_polyline.points);
//       }
//     } catch (error) {
//       console.error("Error in fetching route:", error);
//     }
//   };

//   const filterRoute = () => {
//     const filteredRoutes = routeData?.filter(route => route?.staff?.id === selectedStaff.id);
//     setFilteredRoutes(filteredRoutes);
//   };

//   const handleReverseGeocode = async (lat, lon) => {
//     try {
//       const response = await basic_api.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lon}&key=${key}`);
//       return response.data.results[0].formatted_address;
//     } catch (error) {
//       console.error("Error in reverse geocoding:", error);
//       return "Unknown Location";
//     }
//   };

//   const extractLocations = (routeData) => {
//     const extractedMarkers = [];
//     routeData?.forEach((route) => {
//       route.route.steps.forEach((step) => {
//         if (step.location && step.detail) {
//           step.detail = {
//             ...step.detail,
//             stepType: step.type,
//             arrival: formatTime(step.arrival),
//           };
//           extractedMarkers.push({
//             lat: step.location[1],
//             lng: step.location[0],
//             // popupText: step.description || step.type || 'Marker',
//             staffId: route?.staff?.id,
//             // serviceId: step?.id,
//             id: route.id,
//             details: step.detail,
//           });
//         }
//       });
//     });
//     return extractedMarkers;
//   };

//   // const extractStaffs = (routeData) => {
//   //   const extractedStaffs = [];
//   //   routeData?.map((route) => {
//   //     const obj = { 
//   //       value: route?.staff?.id, 
//   //       label: route?.staff?.name.charAt(0).toUpperCase() + route?.staff?.name.slice(1) 
//   //     };
//   //     extractedStaffs.push(obj);
//   //   });

//   //   return extractedStaffs;
//   // };
//   const extractStaffs = (routeData) => {
//     const extractedStaffs = [];
//     const staffIds = new Set();
  
//     routeData?.forEach((route) => {
//       const staffId = route?.staff?.id;
//       if (staffId && !staffIds.has(staffId)) {
//         staffIds.add(staffId);
//         const obj = { 
//           value: staffId, 
//           label: route?.staff?.name.charAt(0).toUpperCase() + route?.staff?.name.slice(1) 
//         };
//         extractedStaffs.push(obj);
//       }
//     });
  
//     return extractedStaffs;
//   };
  

//   const filteredMarkers = useMemo(() => {
//     if (!selectedStaff && !isPanelOpen) {
//       return markers;
//     }
//     console.log("selectedStaff", selectedStaff);
//     console.log("specific markers", markers.filter((marker) => marker.staffId === selectedStaff?.id && marker.id === objectId));
//     return markers.filter((marker) => marker.staffId === selectedStaff?.id && marker.id === objectId);
//   }, [markers, selectedStaff, objectId, isPanelOpen]);

//   useEffect(() => {
//     filterRoute();
//   }, [selectedStaff]);


//   useEffect(() => {
//     fetchRouteData();
//   }, []);


//   useEffect(() => {
//     const extractedMarkers = extractLocations(routeData);
//     console.log("extractedMarkers", extractedMarkers);
//     setMarkers(extractedMarkers);
//     setFilteredStaffs(routeData);
//     const extractedStaffs = extractStaffs(routeData);
//     setStaffs(extractedStaffs);
//   }, [routeData]);

//   const handleSelectStaff = (staffId) => {
//     setSelectedStaff(staffId);
//   };

//   const handleDrawer = () => {
//     setFilterDrawer(true);
//   };

//   const filterDataByDateRange = () => {
//     const [start, end] = dateRange;
//     const filteredDataByDate = routeData.filter(route => {
//       const itemDate = dayjs(route.date);
//       return itemDate.isAfter(start) && itemDate.isBefore(end);
//     });
//     return filteredDataByDate;
//   };


//   const handleDrawerFilter = () => {
//     const today = new Date();
//     const startOfToday = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0, 0);
//     const startOfWeek = new Date(today.setDate(today.getDate() - today.getDay()));
//     const endOfWeek = new Date(startOfWeek);
//     endOfWeek.setDate(startOfWeek.getDate() + 6);
//     const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
//     const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  
//     const filteredStaffData = routeData?.filter(route => {
//       const routeDate = new Date(route.date);
//       let matchesDate = false;
  
//       if (selectedDate === 'today') {
//         matchesDate = routeDate.toLocaleDateString() === startOfToday.toLocaleDateString();
//       } else if (selectedDate === 'this-week') {
//         matchesDate = routeDate >= startOfWeek && routeDate <= endOfWeek;
//       } else if (selectedDate === 'this-month') {
//         matchesDate = routeDate >= startOfMonth && routeDate <= endOfMonth;
//       } else if (selectedDate === 'custom') {
//         setCustomDate(true);
//       }
  
//       const matchesStaff = staffChange ? route.staff.id === staffChange : true;
//       return matchesDate && matchesStaff;
//     });
  
//     setFilteredStaffs(filteredStaffData);
//     setFilterDrawer(false);
//   };
  
  

//   const handleSearchStaff = (value) => {
//     const filteredStaffData = routeData?.filter(route => {
//       const matchesStaff = value ? route.name.toLowerCase().includes(value.toLowerCase()) : true;
//       return matchesStaff;
//     });
//     setFilteredStaffs(filteredStaffData);
//   };

//   const handleStaffChange = (selectedStaff) => {
//     setStaffChange(selectedStaff);
//   };

//   const handleDateChange = (selectedDate) => {
//     setSelectedDate(selectedDate);
//   };

//   // for date range
//   const onDateRangeChange = (dates) => {
//     setDateRange(dates);
//   };

//   const disabledDate = (current) => {
//     return current && current > endDate.endOf('day');
//   };


//   return (
//     <>
//       <Container>
//         <PanelContainer>
//           <div className="d-flex align-items-center">
//             <SearchInputWrapper>
//               <SearchInput onChange={(e) => handleSearchStaff(e)} placeholder="Type to search..." />
//             </SearchInputWrapper>
//             <div className="w-25 ms-2">
//               <CustomBtnWithIcon
//                 btnText={
//                   <div className="d-flex align-content-center">
//                     <div>
//                       <FilterIcon />
//                     </div>
//                     <span className="ms-2">Filters</span>
//                   </div>
//                 }
//                 hideIcon
//                 noBackground
//                 margin="0px 5px"
//                 padding="10px 8px 10px 13px"
//                 onClick={handleDrawer}
//               />
//             </div>
//           </div>
//           {
//             filteredStaffs && (
//               <StaffRoutes routeData={filteredStaffs} handleReverseGeocode={handleReverseGeocode} onSelectStaff={setSelectedStaff} setObjectId={setObjectId} isPanelOpen={isPanelOpen} setIsPanelOpen={setIsPanelOpen} />
//             )
//           }
//         </PanelContainer>

//         <MapContainerStyled>
//           <CustomGoogleMap markers={filteredMarkers} position={position} setPosition={setPosition} directionsResponse={directionsResponse} setDirectionsResponse={setDirectionsResponse} filteredRoutes={filteredRoutes} handleReverseGeocode={handleReverseGeocode} mapHeight={"686px"} mapWidth={"100%"} />
//         </MapContainerStyled>
//       </Container>

//       <Drawer
//         placement={"right"}
//         title={
//           <div className="d-flex justify-content-between align-items-center">
//             <div>
//               <h5>Filters</h5>
//             </div>
//             <div className="cursorPointer" onClick={() => setFilterDrawer(false)}>
//               <CloseIcon />
//             </div>
//           </div>
//         }
//         closable={false}
//         onClose={() => setFilterDrawer(false)}
//         open={filterDrawer}
//         key={"right"}
//         footer={
//           <div className="d-flex justify-content-end">
//             <CustomBtnWithIcon btnText={"Cancel"} hideIcon noBackground className="me-2" onClick={() => setFilterDrawer(false)} />
//             <CustomBtnWithIcon btnText={"Apply Filters"} hideIcon noBackground={false} onClick={handleDrawerFilter} />
//           </div>
//         }
//       >
//         <div className="my-3">
//           <HeadingComponent text="Date" fontSize={"12px"} />
//           <AntDesignDropDownSelect
//             placeholder="Today"
//             options={[
//               { value: 'today', label: 'Today' },
//               { value: 'this-week', label: 'This Week' },
//               { value: 'this-month', label: 'This Month' },
//               { value: 'custom', label: 'Custom' },
//             ]}
//             defaultValue="Select Date"
//             onChange={handleDateChange}
//             allowClear
//           />
//           {
//             customDate && (
//               <RangePicker 
//                 style={{ marginTop: "20px" }} 
//                 disabledDate={disabledDate}  
//                 onChange={onDateRangeChange} 
//                 defaultValue={dateRange}
//                 value={dateRange}
//               />
//             )
//           }
//         </div>
//         <div className="my-3">
//           <HeadingComponent text="Staff" fontSize={"12px"} />
//           <AntDesignDropDownSelect
//             placeholder="Select Staff"
//             defaultValue='Select Staff'
//             options={staffs}
//             onChange={handleStaffChange}
//             allowClear
//           />
//         </div>
//       </Drawer>
//     </>
//   );
// };


// const Container = styled.div`
//   margin-top: 30px;
//   display: flex;
//   flex-direction: row;
// `;

// const PanelContainer = styled.div`
//   width: 600px;
//   height: 100%;
//   max-height: 670px;
//   overflow-y: scroll;
//   margin-top: 5px;
//   margin-right: 5px;
//   &::-webkit-scrollbar {
//     width: 0;
//   }
// `;

// const SearchInputWrapper = styled.div`
//   width: 100%;
// `;

// const MapContainerStyled = styled.div`
//   margin-left: 15px;
//   width: 100%;
//   height: 600px;
// `;

// export default RouteMaps;

////=====================================



// RouteMaps.jsx

import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Button, Drawer, DatePicker } from 'antd';
import { CloseIcon, FilterIcon } from '../../assets/rawSvgs';
import SearchInput from '../SearchInput';
import HeadingComponent from '../Heading';
import AntDesignDropDownSelect from '../AntDesignDropDownSelect/AntDesignDropDownSelect';
import CustomGoogleMap from '../MapComponent/customGoogleMap';
import StaffRoutes from './staffRoutes';
import { basic_api, main_api } from '../../api/axios_helper';
import CustomBtnWithIcon from '../buttonWithIcon';
import { useSelector } from 'react-redux';
import { routeOptimizerAPIsEndPoints } from '../../constants/ApiEndPoints';
import { pushNotification } from '../../util/Notification';
import moment from 'moment';
import dayjs from 'dayjs';
import NoDataScreen from '../NoDataScreen';
const { RangePicker } = DatePicker;

const RouteMaps = () => {
  const { auth } = useSelector((state) => state);
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [position, setPosition] = useState({ lat: 31.5438232421875, lng: 74.32034375 });
  const [address, setAddress] = useState('');
  const [routeData, setRouteData] = useState(null);
  const [route, setRoute] = useState(null);
  const [filteredRoutes, setFilteredRoutes] = useState(null);
  const [filteredStaffs, setFilteredStaffs] = useState(null);
  const [selectedDate, setSelectedDate] = useState('Today');
  const [staffs, setStaffs] = useState([]);
  const [staffChange, setStaffChange] = useState();
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [filterDrawer, setFilterDrawer] = useState(false);
  const [markers, setMarkers] = useState([]);
  const [directionsResponse, setDirectionsResponse] = useState(null);
  const [objectId, setObjectId] = useState(null);
  const [customDate, setCustomDate] = useState(false);
  const today = dayjs();
  const startDate = today.clone().subtract(5, 'days');
  const endDate = today.clone().add(5, 'days');
  const [dateRange, setDateRange] = useState([startDate, endDate]);

  const key = process.env.REACT_APP_GOOGLE_MAP_API_KEY;

  const fetchRouteData = async () => {
    try {
      const companyId = auth?.loginResponse?.selectCompany?.[0]?.company_id;
      const response = await main_api.get(routeOptimizerAPIsEndPoints.GET_STAFF_ROUTES(companyId));
      if (response.data.isSuccess && response.data.result.length > 0) {
        setRouteData(response.data.result);
      } else {
        pushNotification("No Route Data Found!", "error");
        // setRouteData(dummyData.result);
      }
    } catch (error) {
      pushNotification("Something went wrong!", "error");
      // console.error('Error fetching route data:', error);
    }
  };

  // Time Formatter stop
  function formatTime(timestamp) {
    return moment.unix(timestamp).utc().format('hh:mm A');
  }

  const handleGeocode = async () => {
    try {
      const response = await basic_api.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${key}`);
      const location = response.data.results[0].geometry.location;
      setPosition({ lat: location.lat, lng: location.lng });
    } catch (error) {
      console.error("Error in forward geocoding:", error);
    }
  };

  const handleRoute = async (start, end) => {
    try {
      const response = await fetch(`https://maps.googleapis.com/maps/api/directions/json?origin=${start[0]},${start[1]}&destination=${end[0]},${end[1]}&key=${key}`);
      const data = await response.json();
      if (data.routes && data.routes.length > 0) {
        setRoute(data.routes[0].overview_polyline.points);
      }
    } catch (error) {
      console.error("Error in fetching route:", error);
    }
  };

  const filterRoute = () => {
    const filteredRoutes = routeData?.filter(route => route?.staff?.id === selectedStaff.id);
    setFilteredRoutes(filteredRoutes);
  };

  const handleReverseGeocode = async (lat, lon) => {
    try {
      const response = await basic_api.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lon}&key=${key}`);
      return response.data.results[0].formatted_address;
    } catch (error) {
      console.error("Error in reverse geocoding:", error);
      return "Unknown Location";
    }
  };

  // const extractLocations = (routeData) => {
  //   const extractedMarkers = [];
  //   routeData?.forEach((route) => {
  //     console.log("route.route.steps", route.route.steps);
  //     route.route.steps.forEach((step) => {
  //       if (step.location && step.detail) {
  //         step.detail = {
  //           ...step.detail,
  //           stepType: step.type,
  //           arrival: formatTime(step.arrival),
  //         };
  //         extractedMarkers.push({
  //           lat: step.location[1],
  //           lng: step.location[0],
  //           staffId: route?.staff?.id,
  //           id: route.id,
  //           details: step.detail,
  //         });
  //       }
  //     });
  //   });
  //   return extractedMarkers;
  // };

  //new logic

  const extractLocations = (routeData) => {
    const extractedMarkers = [];
    const locationMap = new Map();
  
    routeData?.forEach((route) => {
      route.route.steps.forEach((step) => {
        if (step.location && step.detail) {
          step.detail = {
            ...step.detail,
            stepType: step.type,
            arrival: formatTime(step.arrival),
          };
  
          const locationKey = `${step.location[1]},${step.location[0]}`;
          if (locationMap.has(locationKey)) {
            const existingMarker = locationMap.get(locationKey);
            existingMarker.details.push(step.detail);
            // Sort the details based on date and time
            existingMarker.details.sort((a, b) => {
              const dateA = new Date(a.date + ' ' + a.start_time || '');
              const dateB = new Date(b.date + ' ' + b.start_time || '');
              return dateA - dateB;
            });
          } else {
            const newMarker = {
              lat: step.location[1],
              lng: step.location[0],
              staffId: route?.staff?.id,
              id: route.id,
              details: [step.detail],
            };
            locationMap.set(locationKey, newMarker);
          }
        }
      });
    });
  
    locationMap.forEach((marker) => {
      extractedMarkers.push(marker);
    });
    return extractedMarkers;
  };
  
  

  const extractStaffs = (routeData) => {
    const extractedStaffs = [];
    const staffIds = new Set();

    routeData?.forEach((route) => {
      const staffId = route?.staff?.id;
      if (staffId && !staffIds.has(staffId)) {
        staffIds.add(staffId);
        const obj = {
          value: staffId,
          label: route?.staff?.name.charAt(0).toUpperCase() + route?.staff?.name.slice(1),
        };
        extractedStaffs.push(obj);
      }
    });

    return extractedStaffs;
  };

  const filteredMarkers = useMemo(() => {
  
    if (!selectedStaff && !isPanelOpen) {
      return markers;
    }

    return markers.filter((marker) => marker.staffId === selectedStaff?.id || marker.id === objectId);
  }, [markers, selectedStaff, objectId, isPanelOpen]);

 


  useEffect(() => {
    filterRoute();
  }, [selectedStaff]);

  useEffect(() => {
    fetchRouteData();
  }, []);

  useEffect(() => {
    const extractedMarkers = extractLocations(routeData);
    setMarkers(extractedMarkers);
    setFilteredStaffs(routeData);
    const extractedStaffs = extractStaffs(routeData);
    setStaffs(extractedStaffs);
  }, [routeData]);

  const handleSelectStaff = (staffId) => {
    setSelectedStaff(staffId);
  };

  const handleDrawer = () => {
    setFilterDrawer(true);
  };

  const filterDataByDateRange = () => {
    const [start, end] = dateRange;
    const filteredDataByDate = routeData.filter(route => {
      const itemDate = dayjs(route.date);
      return itemDate.isAfter(start) && itemDate.isBefore(end);
    });
    return filteredDataByDate;
  };

  const handleDrawerFilter = () => {
    const today = new Date();
    const startOfToday = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0, 0);
    const startOfWeek = new Date(today.setDate(today.getDate() - today.getDay()));
    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 6);
    const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

    const filteredStaffData = routeData?.filter(route => {
      const routeDate = new Date(route.date);
      let matchesDate = false;

      if (selectedDate === 'today') {
        matchesDate = routeDate.toLocaleDateString() === startOfToday.toLocaleDateString();
      } else if (selectedDate === 'this-week') {
        matchesDate = routeDate >= startOfWeek && routeDate <= endOfWeek;
      } else if (selectedDate === 'this-month') {
        matchesDate = routeDate >= startOfMonth && routeDate <= endOfMonth;
      } else if (selectedDate === 'custom') {
        const [start, end] = dateRange;
        matchesDate = routeDate >= start && routeDate <= end;
      }

      const matchesStaff = staffChange ? route.staff.id === staffChange : true;
      return matchesDate && matchesStaff;
    });

    setFilteredStaffs(filteredStaffData);
    setFilterDrawer(false);
  };

  const handleSearchStaff = (value) => {
    const filteredStaffData = routeData?.filter(route => {
      const matchesStaff = value ? route.name.toLowerCase().includes(value.toLowerCase()) : true;
      return matchesStaff;
    });
    setFilteredStaffs(filteredStaffData);
  };

  const handleStaffChange = (selectedStaff) => {
    setStaffChange(selectedStaff);
  };

  const handleDateChange = (selectedDate) => {
    setSelectedDate(selectedDate);
    if (selectedDate === 'custom') {
      setCustomDate(true);
    } else {
      setCustomDate(false);
    }
  };

  const onDateRangeChange = (dates) => {
    setDateRange(dates);
  };

  const disabledDate = (current) => {
    return current && current > endDate.endOf('day');
  };

  return (
    <>
      <Container>
        <PanelContainer>
          {
            routeData ? (
          <>
          
          <div className="d-flex align-items-center">
            <SearchInputWrapper>
              <SearchInput onChange={(e) => handleSearchStaff(e)} placeholder="Type to search..." />
            </SearchInputWrapper>
            <div className="w-25 ms-2">
              <CustomBtnWithIcon
                btnText={
                  <div className="d-flex align-content-center">
                    <div>
                      <FilterIcon />
                    </div>
                    <span className="ms-2">Filters</span>
                  </div>
                }
                hideIcon
                noBackground
                margin="0px 5px"
                padding="10px 8px 10px 13px"
                onClick={handleDrawer}
              />
            </div>
          </div>
          {
            filteredStaffs && (
              <StaffRoutes routeData={filteredStaffs} handleReverseGeocode={handleReverseGeocode} onSelectStaff={setSelectedStaff} setObjectId={setObjectId} isPanelOpen={isPanelOpen} setIsPanelOpen={setIsPanelOpen} />
            )
          }
          </>

            ) :(
              
              <NoDataScreen heading="No Routes to show from Route Optimizer!" showBtn={false} height='680px'/>
             
            )
          }
        </PanelContainer>

        <MapContainerStyled>
          <CustomGoogleMap markers={filteredMarkers} position={position} setPosition={setPosition} directionsResponse={directionsResponse} setDirectionsResponse={setDirectionsResponse} filteredRoutes={filteredRoutes} handleReverseGeocode={handleReverseGeocode} mapHeight={"686px"} mapWidth={"100%"} />
        </MapContainerStyled>
      </Container>

      <Drawer
        placement={"right"}
        title={
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <h5>Filters</h5>
            </div>
            <div className="cursorPointer" onClick={() => setFilterDrawer(false)}>
              <CloseIcon />
            </div>
          </div>
        }
        closable={false}
        onClose={() => setFilterDrawer(false)}
        open={filterDrawer}
        key={"right"}
        footer={
          <div className="d-flex justify-content-end">
            <CustomBtnWithIcon btnText={"Cancel"} hideIcon noBackground className="me-2" onClick={() => setFilterDrawer(false)} />
            <CustomBtnWithIcon btnText={"Apply Filters"} hideIcon noBackground={false} onClick={handleDrawerFilter} />
          </div>
        }
      >
        <div className="my-3">
          <HeadingComponent text="Date" fontSize={"12px"} />
          <AntDesignDropDownSelect
            placeholder="Today"
            options={[
              { value: 'today', label: 'Today' },
              { value: 'this-week', label: 'This Week' },
              { value: 'this-month', label: 'This Month' },
              { value: 'custom', label: 'Custom' },
            ]}
            defaultValue="Select Date"
            onChange={handleDateChange}
            allowClear
          />
          {
            customDate && (
              <RangePicker 
                style={{ marginTop: "20px" }} 
                disabledDate={disabledDate}  
                onChange={onDateRangeChange} 
                defaultValue={dateRange}
                value={dateRange}
              />
            )
          }
        </div>
        <div className="my-3">
          <HeadingComponent text="Staff" fontSize={"12px"} />
          <AntDesignDropDownSelect
            placeholder="Select Staff"
            defaultValue='Select Staff'
            options={staffs}
            onChange={handleStaffChange}
            allowClear
          />
        </div>
      </Drawer>
    </>
  );
};

const Container = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: row;
`;

const PanelContainer = styled.div`
  width: 600px;
  height: 100%;
  max-height: 670px;
  min-height: 688px;
  overflow-y: scroll;
  margin-top: 5px;
  margin-right: 5px;
  &::-webkit-scrollbar {
    width: 0;
  }
`;

const SearchInputWrapper = styled.div`
  width: 100%;
`;

const MapContainerStyled = styled.div`
  margin-left: 15px;
  width: 100%;
  height: 600px;
`;

export default RouteMaps;
