import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useSpring, animated } from "react-spring";
import { Formik } from "formik";
import * as Yup from "yup";
import toaster from "toasted-notes";
import { setJWTToken, setUserId } from "../../../api/auth";
import { basic_api } from "../../../api/axios_helper";
import CustomInput from "../../../components/CustomInput";

import { Col, Divider, Image, Row, Radio } from "antd";
import companyLogo from "../../../assets/images/furgis-logo.svg";
import ArrowBack from "../../../assets/images/arrow-back.svg";

import { ReactComponent as OrganSelcIcon } from "../../../assets/images/orgIcon.svg";
import FormikInputFloating from "../../../components/formikInputs/TextInput";
import axiosInstance from "../../../util/AxiosConfig";
import { companyAPIsEndPoints } from "../../../constants/ApiEndPoints";
import { pushNotification } from "../../../util/Notification";
import { loginFormSchema } from "../../../constants/CompanyFormFieldsSchema/FormSchema";
import AuthButton from "../../../components/authButton";
import HeadingComponent from "../../../components/Heading";
import AuthPetsScreen from "../../../components/AuthPetsScreen/AuthPetsScreen";
import CommonUtil from "../../../util/commonUtil";
import { useDispatch } from "react-redux";
import { isAuthenticationEnabled, fetchSignRoles } from "../../../util/DataService";
import { fetchSingleRole } from "../../../redux/slices/permissionSlice";

//
function Signin(props) {
  let navigate = useNavigate();
  const [selectedRole, setSelectedRole] = useState({ role_name: "", role_id: "", is_company_type: false, user_company_id: null });
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loggedInData, setLoggedInData] = useState(undefined);
  const dispatch = useDispatch();
  const [loader, setLoginLoader] = useState(false);
  const [isSingleCompany, setIsSingleCompany] = useState(false);

  useEffect(() => {
    function deleteAllCookies() {
      var cookies = document.cookie.split(";");

      for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        var eqPos = cookie.indexOf("=");
        var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
      }
    }

    deleteAllCookies();
  }, []);

  const signin = async (values) => {
    try {
      setLoginLoader(true);
      const updatedValues = {
        is_company_login: true,
        ...values,
      };
      setIsSingleCompany(false);
      const { data } = await axiosInstance.post(companyAPIsEndPoints.COMPANY_LOGIN, updatedValues);

      if (data.isSuccess) {
        if (data.result?.user?.company_roles?.length === 1) {
          let compDetails = data?.result?.user?.company_roles[0];

          setIsSingleCompany(true);

          setLoginLoader(false);
          let compSpeificDetails = {
            role_name: compDetails.role,
            role_id: compDetails.role_id,
            is_company_type: compDetails.is_company_type,
            company_user_id: compDetails.company_user_id,
          };

          handleSetCompanyLogin(compDetails.company_id, compDetails.company, data?.result, compSpeificDetails, true);

          // setSelectedRole(compSpeificDetails);

          return;
        }
        localStorage.setItem("company", data?.result?.user?.company_roles[0]?.company_id);
        setLoggedInData(data?.result);
        setIsLoggedIn(true);
      }
      setLoginLoader(false);
    } catch (error) {
      setLoginLoader(false);

      setIsLoggedIn(false);
      if (error.message === "Invalid Login Credentials") {
        pushNotification(error?.message, "error");
        return;
      }
      if (error.message) {
        pushNotification(error.message, "error");
      }
    }
  };

  const handleForgotPasswordClick = () => {
    props.setIsAnimating(true);
    props.onForgotPasswordClick();
  };

  const userSchema = Yup.object().shape({
    username: Yup.string().required("Email is required"),
    password: Yup.string().required("Password is required"),
  });
  const handleBacktoLogin = () => {
    setLoggedInData("");
    setIsLoggedIn(false);
  };

  const getRolePermissions = (roleId) => {
    fetchSignRoles(roleId).then(({ data }) => {
      dispatch(fetchSingleRole(data));
    });
  };

  const token = (token, roleId) => {
    setJWTToken(token);
    getRolePermissions(roleId);
  };

  const handleSetCompanyLogin = (selectedComid, companyName, logginDataOneComp, loginSelctCmp, singleCmp) => {
    let loginData = singleCmp ? logginDataOneComp : loggedInData;
    let compSelectDetails = singleCmp ? loginSelctCmp : selectedRole;

    const companyFilter = loginData?.user?.company_roles?.filter((items) => items.company_id === selectedComid);

    let selectedRoleType = null;

    if (compSelectDetails.role_name === "Company Admin") {
      selectedRoleType = "company_type";
      localStorage.setItem("loggedAs", "company_dashboard");
    } else if (compSelectDetails.role_name === "Client") {
      selectedRoleType = "client_type";
      localStorage.setItem("loggedAs", "client_dashboard");
    } else if (compSelectDetails.is_company_type) {
      selectedRoleType = "company_user_type";
      localStorage.setItem("loggedAs", "company_dashboard");
    }

    const reduxObject = {
      selectCompany: companyFilter,
      LoggedInUserData: loginData?.user,
      selectedRoleId: compSelectDetails.role_id || null,
      selectedRoleType: selectedRoleType,
      companyUserId: compSelectDetails.company_user_id,
    };

    props.loginUserSlice(reduxObject);
    setUserId(loginData?.user?.id);

    // return;
    switch (compSelectDetails.role_name) {
      case "Company Admin":
        if (companyFilter && companyFilter[0] && companyFilter[0].company_onboarding_seen) {
          setJWTToken(loginData.token);
          navigate(`/company/scheduler`);
        } else {
          setJWTToken(loginData.token);
          navigate(`/company/onboarding`, {
            state: { token: loginData.token, selectedComid: selectedComid, companyName: companyName },
          });
        }
        break;
      case "Client":
        isAuthenticationEnabled(loginData.token)
          .then(({ data }) => {
            if (data.method) {
              navigate(`/client/verification`, { state: { token: loginData.token } });
            } else {
              let filterClient = companyFilter.filter((item) => item.client_id !== null);
              if (filterClient?.[0]?.client_onboarding_seen) {
                token(loginData.token, compSelectDetails.role_id);

                navigate(`/client/scheduler`);
              } else {
                token(loginData.token, compSelectDetails.role_id);

                navigate(`/client/onboarding`);
              }
            }
          })
          .catch((error) => {
            let filterClient = companyFilter.filter((item) => item.client_id !== null);
            if (filterClient[0]?.client_onboarding_seen) {
              token(loginData.token, compSelectDetails.role_id);

              navigate(`/client/scheduler`);
            } else {
              token(loginData.token, compSelectDetails.role_id);

              navigate(`/client/onboarding`);
            }
          });

        break;
      default:
        if (compSelectDetails.is_company_type) {
          if (companyFilter && companyFilter[0] && companyFilter[0].company_onboarding_seen) {
            setJWTToken(loginData.token);
            navigate(`/company/`);
          } else {
            setJWTToken(loginData.token);
            navigate(`/company/onboarding`, {
              state: { token: loginData.token, selectedComid: selectedComid, companyName: companyName },
            });
          }
        }

        break;
    }
  };
  return (
    <Row style={{ height: "100vh", background: "#F9F9F9" }}>
      <Col md={12}>
        <AuthPetsScreen />
      </Col>

      <Col md={12} style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
        {loggedInData && (
          <div className="text-start w-50 mb-1 cursorPointer" onClick={handleBacktoLogin} style={{ marginRight: "50px" }}>
            <h4 className="text-start">
              <BackIcon src={ArrowBack} alt="Arrow Down Icon" /> Back to Log in
            </h4>
          </div>
        )}

        <FormContainer>
          <FormList>
            <Formik
              initialValues={{
                email: "",
                password: "",
              }}
              onSubmit={(values) => {
                signin(values);
              }}
              validationSchema={loginFormSchema}
              enableReinitialize
              validateOnChange={false}
            >
              {({ handleSubmit, error, values }) => (
                <Form onSubmit={handleSubmit} autoComplete="new-password">
                  <Image src={companyLogo} preview={false} />
                  {loggedInData && (
                    <CompanySelectComponent
                      handleSetCompanyLogin={handleSetCompanyLogin}
                      userCompanies={loggedInData?.user?.company_roles}
                      setSelectedRole={setSelectedRole}
                      selectedRole={selectedRole}
                    />
                  )}

                  {!loggedInData && (
                    <>
                      <FormHeader>Log In</FormHeader>
                      <FormDescriptionText>Welcome Back! Please enter your credentials.</FormDescriptionText>
                      <P20 />
                      <FormikInputFloating
                        label="Email ID"
                        name="email"
                        type="text"
                        className="w-100 mb-2"
                        autoComplete="new-password"
                      />
                      <FormikInputFloating
                        label="Password"
                        name="password"
                        type="password"
                        className="w-100"
                        autoComplete="off"
                        passwordField={true}
                      />
                      <FormSupportingText
                        onClick={handleForgotPasswordClick}
                        style={{ marginLeft: "auto", marginTop: "10px", cursor: "pointer" }}
                      >
                        Forgot password?
                      </FormSupportingText>
                      <Flex flexDirection={"column"}>
                        <FlexedInput>
                          <AuthButton btnText={"Login"} type="submit" disabled={loader} />
                        </FlexedInput>
                        {/* 
                    <Divider plain>OR</Divider>
                    <FlexedInput>
                      <SubmitButton
                        onClick={() => navigate(`/company/forget-password`)}
                        type="button"
                        height="44px"
                        width="100%"
                        margin="10px 0px 0px 0px"
                      >
                        <Image src={googleIcon} preview={false} /> Sign in with Google
                      </SubmitButton>
                    </FlexedInput>
                    <FlexedInput>
                      <SubmitButton
                        onClick={() => navigate(`/company/forget-password`)}
                        type="button"
                        height="44px"
                        width="100%"
                        margin="10px 0px 0px 0px"
                      >
                        <Image src={facebookIcon} preview={false} /> Sign in with Facebook
                      </SubmitButton>
                    </FlexedInput> */}
                      </Flex>
                    </>
                  )}
                </Form>
              )}
            </Formik>
          </FormList>
        </FormContainer>
      </Col>
    </Row>
  );
}

const dunnmyCom = [
  {
    id: 20,
    user_id: 11,
    company_id: 1,
    role_id: 1,
    role: "Client",
    company: "Company one",
  },
  {
    id: 22,
    user_id: 12,
    company_id: 2,
    role_id: 1,
    role: "Client",
    company: "Australia",
  },
];
const CompanySelectComponent = ({ handleSetCompanyLogin, userCompanies = [], selectedRole, setSelectedRole }) => {
  const [selectedCompany, setSelectedCompany] = React.useState(null);
  const [companyName, setCompanyName] = useState("");
  const handleWrapperClick = (companyId, role_name, role_id, companyName, is_company_type, company_user_id) => {
    setSelectedCompany(companyId);
    setSelectedRole({ role_name, role_id, is_company_type, company_user_id });
    setCompanyName(companyName);
    localStorage.setItem("company", companyId);
  };

  let filterStaffRole = userCompanies?.filter((items) => items?.role !== "Staff") || [];
  return (
    <>
      <FormHeader>Choose Organization</FormHeader>
      <FormSupportingText>Please choose organziation you want to join </FormSupportingText>
      <CompanyCardWrapper>
        {filterStaffRole?.map((items, index) => {
          return (
            <WrapperDiv
              className="cursorPointer"
              key={index}
              onClick={() =>
                handleWrapperClick(
                  items.company_id,
                  items.role,
                  items.role_id,
                  items.company,
                  items.is_company_type,
                  items.company_user_id,
                )
              }
            >
              <div className="d-flex align-items-center ">
                <OrganSelcIcon width={"43px"} height="43px" />
                <HeadingComponent
                  color="#273343"
                  text={items.company}
                  fontSize="16px"
                  fontWeight={700}
                  margin="2px 0px 0px 10px "
                />
                <HeadingComponent
                  color="#273343"
                  text={` (${CommonUtil.convertToTitleCaseWithSpaces(items.role)})`}
                  fontSize="16px"
                  fontWeight={700}
                  margin="2px 0px 0px 10px "
                />
              </div>
              <Radio checked={selectedCompany === items.company_id && selectedRole.role_id === items.role_id} />
            </WrapperDiv>
          );
        })}
      </CompanyCardWrapper>
      <br />
      <FlexedInput>
        <AuthButton
          btnText={"Continue"}
          onClick={(e) => {
            e.preventDefault();
            if (selectedCompany === null) {
              pushNotification("Please select a company to continue", "error");
              return;
            }
            handleSetCompanyLogin(selectedCompany, companyName);
          }}
        />
      </FlexedInput>
    </>
  );
};

const WrapperDiv = styled.div`
  height: 55px;
  background: #f9f9f9;
  padding: 15px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  margin: 5px 0px;
`;
const CompanyCardWrapper = styled.div`
  max-height: 190px;

  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
`;

const BackIcon = styled.img`
  width: 20px;
  height: 16px;
`;

const FormContainer = styled(animated.div)`
  width: 448px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  background: #ffffff;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 36px 24px;
`;

const FormList = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
`;

const Flex = styled.div`
  display: flex;
  flex-direction: ${(props) => props.flexDirection ?? "row"};
  justify-content: space-between;
  width: 100%;
`;

const FlexedInput = styled.div`
  width: 100%;
`;

const P20 = styled.div`
  height: 20px;
`;

const FormHeader = styled.h1`
  font-family:
    Helvetica Neue,
    Arial;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #000000;
  margin-top: 24px;
`;

const FormDescriptionText = styled.p`
  font-family: "Helvetica Neue", Arial;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #212121;
`;

const FormSupportingText = styled.p`
  font-family:
    Helvetica Neue,
    Arial;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  color: #424242;
  letter-spacing: 0em;
  text-align: center;
`;

const Form = styled.form`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export default Signin;
