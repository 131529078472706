import React from "react";

import CustomBtnWithIcon from "../../../components/buttonWithIcon";

import { Col, Row, Drawer, Input, Switch } from "antd";

import HeadingComponent from "../../../components/Heading";

import "./service.css";

import { ReactComponent as ServiceNameIcon } from "../../../assets/rawSvgs/serviceModuleIcon.svg";
import { ReactComponent as ServiceCostIcon } from "../../../assets/rawSvgs/costIcon.svg";
import { ReactComponent as PromoCode } from "../../../assets/rawSvgs/promoCode.svg";
import { ReactComponent as LimitTOTimeIcon } from "../../../assets/rawSvgs/limitToTimes.svg";
import { ReactComponent as TaxableIcon } from "../../../assets/rawSvgs/treasureIcon.svg";

import { CloseIcon } from "../../../assets/rawSvgs/index";

import truncateString from "../../../util/truncate";
import moment from "moment";

const { TextArea } = Input;

const ServiceDrawer = ({
  promoCodeValues,
  deleteService,
  closeDrawer,
  openDetailsDrawer,
  onClose,
  serviceValues,
  openEditService,
  handleUpdatePromoCode,

  setPromoCheckbox,
}) => {
  const detailObj = [
    {
      key: "Service Name",
      value: serviceValues?.name,
      icon: <ServiceNameIcon />,
    },
    {
      key: "Promo Code",
      value: promoCodeValues ? (
        <div className="d-flex flex-column gap-1 align-items-end pb-1">
          <span>{promoCodeValues?.code}</span>
          <div className="d-flex justify-content-between align-items-center">
            <span className="me-2">
              <Switch
                size="small"
                onChange={(e) => {
                  setPromoCheckbox((prev) => ({ ...prev, status: !prev?.status }));

                  handleUpdatePromoCode(e);
                }}
                checked={promoCodeValues?.status}
              />
            </span>
            {moment(promoCodeValues?.start_date).format("DD/MM/YY")} - {moment(promoCodeValues?.end_date).format("DD/MM/YY")}
          </div>
        </div>
      ) : (
        "---"
      ),
      icon: <PromoCode />,
    },
    {
      key: "Cost",
      value: serviceValues?.cost || "---",
      icon: <ServiceCostIcon />,
    },
    {
      key: "Staff Rate",
      value: serviceValues?.default_staff_rate || "---",
      icon: <ServiceNameIcon />,
    },
    {
      key: "Taxable",

      value: serviceValues?.taxable ? "Yes" : "No",
      icon: <TaxableIcon />,
    },
    // {
    //   key: "Limit to Times",
    //   value: serviceValues?.name,
    //   icon: <LimitTOTimeIcon />,
    // },
  ];
  return (
    <div>
      <Drawer
        placement={"right"}
        title={
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <h5>Service Details</h5>
            </div>
            <div className="cursorPointer" onClick={onClose}>
              <CloseIcon />
            </div>
          </div>
        }
        closable={false}
        onClose={closeDrawer}
        open={openDetailsDrawer}
        key={"right"}
        header={null}
        footer={
          <div className="d-flex justify-content-center ">
            <CustomBtnWithIcon
              btnText={"Edit"}
              hideIcon
              noBackground
              className="me-2"
              width={"160px"}
              onClick={openEditService}
            />
            <CustomBtnWithIcon
              btnText={"Delete"}
              hideIcon
              noBackground
              color="red"
              width={"160px"}
              onClick={() => deleteService(serviceValues.id)}
            />
          </div>
        }
      >
        <Row>
          {detailObj?.map((items, index) => {
            return (
              <Col md={23} className="d-flex justify-content-between align-items-baseline my-2 border-bottom" key={index}>
                <div className="d-flex align-items-center">
                  <span className="me-2 d-flex mb-2">{items.icon}</span>
                  <HeadingComponent text={items.key} fontSize="14px" fontWeight={400} />
                </div>

                <div>
                  {" "}
                  <HeadingComponent text={truncateString(items.value, 20)} fontSize="14px" fontWeight={400} />
                </div>
              </Col>
            );
          })}

          {/* <Col lg={24}>
            <HeadingComponent text={"Tasks"} fontSize="18px" fontWeight={500} margin="10px 0px" />
            <div className="my-3">
              <TextArea
                placeholder="Tasks Module not Developed yet"
                autoSize={{
                  minRows: 3,
                  maxRows: 5,
                }}
                value="Review last months invoice and provide an explanation of charges"
              />
            </div>
            <div className="my-3">
              <TextArea
                placeholder="Tasks Module not Developed yet"
                autoSize={{
                  minRows: 3,
                  maxRows: 5,
                }}
                value="Review last months invoice and provide an explanation of charges"
              />
            </div>
          </Col> */}
        </Row>
      </Drawer>
    </div>
  );
};

export default ServiceDrawer;
