import React, { useEffect, useState } from "react";
import { Row, Col, Space, DatePicker } from "antd";
import { PetsContainer, PetsHeaderSection, PetsCardWrapperDiv } from "./PetsStyle";
import SearchInput from "../../../../../components/SearchInput";
import CustomBtnWithIcon from "../../../../../components/buttonWithIcon";
import PetCardComponent from "../../../../../components/ClientPetsCard/PetCardComponent";
import CatImage from "../../../../../assets/images/dog6.png";
import CatImage2 from "../../../../../assets/images/dog7.png";
import { useParams } from "react-router-dom";
import { companyAPIsEndPoints } from "../../../../../constants/ApiEndPoints";
import { main_api } from "../../../../../api/axios_helper";
import AddPetModal from "../../../../../components/Modals/PetManagementModal/AddPetModal";
import moment from "moment";
import { pushNotification } from "../../../../../util/Notification";
import dayjs from "dayjs";
import DeleteConfirmationRowModal from "../../../../../components/Modals/DeleteConfirmationRowModal";
import EditPetModal from "../../../../../components/Modals/PetManagementModal/EditPetModal";
import NoDataScreen from "../../../../../components/NoDataScreen";
import { checkPermission } from "../../../../../util/commonUtil";
import { useSelector } from "react-redux";
import PermissionModal from "../../../../../components/Modals/PermissionModal";

const { RangePicker } = DatePicker;

const PetsComponent = (props) => {
  const param = useParams();
  const [search, setSearch] = useState("");
  const [date, setDate] = useState("");
  const [clientPets, setClientPets] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isAddPetOpen, setIsAddPetOpen] = useState(false);
  const [isEditPetOpen, setIsEditPetOpen] = useState(false);
  const [isDeletePetOpen, setIsDeletePetOpen] = useState(false);
  const [editPetValues, setEditPetValues] = useState({});
  const [deletePetValues, setDeletePetValues] = useState({});
  const permissionManagement = useSelector((state) => state.permissionManagement);
  const clientsResponse = useSelector((state) => state?.clientManangement?.clientResponse?.responseData?.result);
  const [imageFromComp, setImageFromComp] = useState(undefined);
  const params = useParams();
  const [permissionPopUp, setPermissionPopUp] = useState(false);

  useEffect(() => {
    if (checkPermission(permissionManagement.permissions, "view_pets")) {
      fetchClientRelatedPet("", "", param.clientId);
    }
    if (checkPermission(permissionManagement.permissions, "view_vets")) {
      props.loadVetList();
    }
  }, []);

  const fetchClientRelatedPet = (search, date, clientId) => {
    setClientPets([]);
    main_api
      .get(`${companyAPIsEndPoints.PET_LIST_FILTER(search, date, clientId)}`)
      .then(({ data }) => {
        setClientPets(data.result);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const addPet = (formValues) => {
    const formattedDate = formValues.birth_date.format("YYYY-MM-DD");

    const updatedValues = {
      ...formValues,
      client: params.clientId,
      birth_date: formattedDate,
    };

    setIsLoading(true);
    main_api
      .post(companyAPIsEndPoints.POST_PET_LIST_PETS, updatedValues)
      .then(({ data }) => {
        setIsLoading(false);
        if (data?.isSuccess) {
          pushNotification(data?.message, "success");
          setIsAddPetOpen(false);
        }
        fetchClientRelatedPet("", "", param.clientId);
      })
      .catch((error) => {
        pushNotification("Something went wrong!", "error");
        console.log(error);
      });
  };

  const uploadToS3 = async (file, type) => {
    const formData = new FormData();
    formData.append(type, file);
    const actualFile = file.originFileObj || file;

    formData.append(type, actualFile);
    try {
      const response = await main_api.post(companyAPIsEndPoints.UPLOAD_OBJECT, formData);

      if (response.data.isSuccess) {
        return response;
      }
    } catch (error) {
      console.error(`Error uploading ${type} to S3`, error);
    }
  };
  const editPet = async (formValues) => {
    const formattedDate = formValues.birth_date.format("YYYY-MM-DD");

    let fileResponse;
    if (imageFromComp) {
      fileResponse = await uploadToS3(imageFromComp?.[0], "image");
    }

    const updatedValues = {
      ...formValues,
      birth_date: formattedDate,
      logo: fileResponse?.data?.url || editPetValues?.logo,
    };

    setIsLoading(true);

    main_api
      .put(`${companyAPIsEndPoints.PUT_SINGLE_PET_LIST_PETS(editPetValues.id)}`, updatedValues)
      .then(({ data }) => {
        setIsLoading(false);
        pushNotification("Pet Detail Successfully Updated!", "success");
        setIsEditPetOpen(false);
        fetchClientRelatedPet("", "", param.clientId);
      })
      .catch((error) => {
        pushNotification("Something went wrong!", "error");
        console.log(error);
      });
  };

  const deletePet = () => {
    setIsLoading(true);

    main_api
      .delete(companyAPIsEndPoints.DELETE_SINGLE_PET_LIST_PETS(deletePetValues))
      .then(({ data }) => {
        setIsLoading(false);
        pushNotification(data.message, "success");
        setIsDeletePetOpen(false);
        fetchClientRelatedPet("", "", param.clientId);
      })
      .catch((error) => {
        pushNotification("Something went wrong!", "error");
        console.log(error);
      });
  };

  const onRangeChange = (dates, dateStrings) => {
    setDate(dateStrings);
    fetchClientRelatedPet(search, dateStrings, param.clientId);
  };

  const searchBlurHandler = () => {
    fetchClientRelatedPet(search, date, param.clientId);
  };

  const rangePresets = [
    {
      label: "Last 7 Days",
      value: [dayjs().add(-7, "d"), dayjs()],
    },
    {
      label: "Last 14 Days",
      value: [dayjs().add(-14, "d"), dayjs()],
    },
    {
      label: "Last 30 Days",
      value: [dayjs().add(-30, "d"), dayjs()],
    },
    {
      label: "Last 90 Days",
      value: [dayjs().add(-90, "d"), dayjs()],
    },
  ];

  const handleEditRow = (record) => {
    if (checkPermission(permissionManagement.permissions, "edit_pet")) {
      if (record) {
        setEditPetValues(record);
      }
      setIsEditPetOpen(true);
    } else {
      setPermissionPopUp(true);
    }
  };

  const handleDelete = (record) => {
    if (checkPermission(permissionManagement.permissions, "delete_pet")) {
      if (record) {
        setDeletePetValues(record.id);
      }
      setIsDeletePetOpen(true);
    } else {
      setPermissionPopUp(true);
    }
  };

  const handleAddRow = () => {
    if (checkPermission(permissionManagement.permissions, "create_pet")) {
      setIsAddPetOpen(true);
    } else {
      setPermissionPopUp(true);
    }
  };

  return (
    <>
      {isAddPetOpen && (
        <AddPetModal
          isLoading={isLoading}
          isModalOpen={isAddPetOpen}
          setModalOpen={setIsAddPetOpen}
          onFinish={addPet}
          vetList={props.vetList}
          clientId={params.clientId}
          showClientSelect={false}
          clientList={clientsResponse}
          singleClientresponseData={props?.singleClientresponseData}
        />
      )}
      {isEditPetOpen && (
        <EditPetModal
          isLoading={isLoading}
          isModalOpen={isEditPetOpen}
          setModalOpen={setIsEditPetOpen}
          editPetValues={editPetValues}
          onFinish={editPet}
          vetList={props.vetList}
          getImageFromEditPet={setImageFromComp}
        />
      )}
      <DeleteConfirmationRowModal
        onclose={() => setIsDeletePetOpen(false)}
        handleConfirmDeleteRows={deletePet}
        openConfimrationDeleteModal={isDeletePetOpen}
      />
      <PetsContainer>
        {clientPets.length > 0 && (
          <Row style={{ alignItems: "center" }}>
            <Col lg={12}>
              <PetsHeaderSection>
                <SearchInput
                  placeholder="Type to search.."
                  search={search}
                  onChange={(value) => setSearch(value)}
                  onBlur={searchBlurHandler}
                />
                <RangePicker style={{ height: "40px" }} presets={rangePresets} onChange={onRangeChange} />
              </PetsHeaderSection>
            </Col>
            <Col lg={12} style={{ display: "flex", justifyContent: "end" }}>
              <CustomBtnWithIcon btnText={"Add new Pet"} onClick={() => handleAddRow()} />
            </Col>
          </Row>
        )}
        {clientPets.length > 0 ? (
          <PetsCardWrapperDiv>
            <Space size={16} className="flex-wrap">
              {clientPets.map((pet, index) => (
                <div key={index}>
                  <PetCardComponent
                    clientName={pet.name}
                    tagNumber={pet.micro_chip}
                    chipsArray={[pet.color, pet.breed, pet.neutered ? "Neutered" : "Not Spayed"]}
                    paraGraph={pet.notes}
                    clientAvatar={CatImage}
                    petType={pet.type}
                    vaccinated={pet.vaccination_current}
                    sex={pet.sex}
                    AvatarName={"oswald"}
                    EditOnClick={() => handleEditRow(pet)}
                    DeleteOnClick={() => handleDelete(pet)}
                    petImg={pet?.logo}
                  />
                </div>
              ))}
            </Space>
          </PetsCardWrapperDiv>
        ) : (
          <NoDataScreen
            heading="You don’t have any Pets in your list. Let’s get started by adding a new Pet"
            btnText={"Add new Pet"}
            onClick={() => handleAddRow()}
          />
        )}
      </PetsContainer>
      <PermissionModal onclose={() => setPermissionPopUp(false)} openModal={permissionPopUp} />
    </>
  );
};

export default React.memo(PetsComponent);

const Pets = () => {
  return <div>Pets</div>;
};
