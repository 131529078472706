import React from 'react'
import "./styles.css"

function Chip({Icon, text, classes, onClick}) {
  return (
    <button className={classes} onClick={onClick}>
        {Icon && <img src={Icon}/>}
        <span>{text}</span>
    </button>
  )
}

export default Chip