import { Checkbox, Col, Drawer, Row, Segmented, Select, Radio, TimePicker, DatePicker, Divider } from "antd";
import React, { useState } from "react";
import locale from "antd/es/date-picker/locale/de_DE";
import { Formik, Field, Form, FieldArray, useFormikContext } from "formik";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
//

import HeadingComponent from "../../../../../components/Heading";

//
import { DeleteIcon } from "../../../../../assets/rawSvgs/index";
import { ReactComponent as PlusYellowIcon } from "../../../../../assets/rawSvgs/yelowplus.svg";

import { pushNotification } from "../../../../../util/Notification";
import { DifferntTimeEachDayForm } from "./DifferentDayForm";
import { daysArray } from "./AvailabilityComponent";
import { staffAPIsEndPoints } from "../../../../../constants/ApiEndPoints";
import { main_api } from "../../../../../api/axios_helper";
import { validationSchemaSameDay } from "./helpers";
import moment from "moment";
import { RequiredSignComponent } from "../../addNewStaffModal";

const { RangePicker } = DatePicker;

export const ConfigureStaffAvailComponent = ({
  handleCloseModal,
  updateForm,
  close,

  createStaffValues,
  innerRef,
  fetchAllStaff,
  addStaffSetps,
  formikRefSecondStep,
  setAddStaffSetps,
  setSubmitFormLoader,
}) => {
  const [SelectTab, setSelectTab] = React.useState("Same time for each day");

  const [dateValidationError, setdateValidationError] = useState(false);

  const handleSubmit = async (values) => {
    if (!values?.forever) {
      if (validateDate(values?.selectedDays, values.start_date, values.end_date)) return;
    }

    if (SelectTab === "Different time for each day") {
      const allTimes = getAllTimes(values?.days);

      const timeCondiDiff = checkTimeValidity(allTimes);
      if (!timeCondiDiff) {
        return pushNotification(
          "Time Range is not valid. Start time cannot be greater, undefined or equal to End time",
          "error",
          5000,
        );
      }
      handleUpdateDifferntDaysTab(values);
      return;
    }

    const { days_time, selectedDays, ...rest } = values;

    if (selectedDays?.length === 0) {
      values.days_time = [];
    }
    const timeCondi = checkTimeValidity(days_time);

    if (!timeCondi) {
      return pushNotification(
        "Time Range is not valid. Start time cannot be greater, undefined or equal to End time",
        "error",
        5000,
      );
    }

    let availabilities = [];

    if (rest.forever) {
      const today = dayjs();
      rest["start_date"] = null;
      rest["end_date"] = null;
    }

    selectedDays.forEach((day) => {
      availabilities[day] = days_time.map((items) => {
        return {
          end_time: moment(items.end_time, "hh:mm A").format("HH:mm"),
          start_time: moment(items.start_time, "hh:mm A").format("HH:mm"),
        };
      });
    });

    try {
      setSubmitFormLoader(true);

      const { data: StaffCreateRes } = await main_api.post(staffAPIsEndPoints.ADD_NEW_STAFF, createStaffValues);

      let objSend = {
        staff: StaffCreateRes.result.id,
        availabilities: { ...availabilities },
        same_time: SelectTab === "Different time for each day" ? false : true,

        ...rest,
      };

      const { data } = await main_api.post(staffAPIsEndPoints.STAFF_CONFIGURE_AVAIL, objSend);
      if (data.isSuccess) {
        pushNotification("Staff created with availability saved successfully.");
        fetchAllStaff();
        handleCloseModal();
        close();
      }
      setSubmitFormLoader(false);
    } catch (error) {
      setSubmitFormLoader(false);

      if (!rest.forever && error?.response?.data.error) {
        pushNotification("End time must be greater than current time", "error");
      }

      if (error?.response?.data.error === "User can only be a staff member in a single company") {
        pushNotification("This email address is already registered", "error");
        return;
      }
      if (error?.response?.data.error) {
        pushNotification(error?.response?.data?.error, "error");
      }
    }
  };

  const handleUpdateDifferntDaysTab = async (values) => {
    const { days, selectedDays, ...rest } = values;

    try {
      setSubmitFormLoader(true);
      if (validateDate(selectedDays)) return;

      const convertedTimes = Object.keys(days).reduce((result, key) => {
        const items = days[key];
        const convertedItems = items.map((item) => ({
          start_time: moment(item.start_time, "hh:mm A").format("HH:mm"),
          end_time: moment(item.end_time, "hh:mm A").format("HH:mm"),
        }));
        result[key] = convertedItems;
        return result;
      }, {});
      // create staff aou

      const { data: StaffCreateRes } = await main_api.post(staffAPIsEndPoints.ADD_NEW_STAFF, createStaffValues);
      const objSend = {
        staff: StaffCreateRes.result.id,
        availabilities: { ...convertedTimes },
        same_time: SelectTab === "Different time for each day" ? false : true,
        ...rest,
      };

      if (rest.forever) {
        rest["start_date"] = null;
        rest["end_date"] = null;
      }

      const { data } = await main_api.post(staffAPIsEndPoints.STAFF_CONFIGURE_AVAIL, objSend);
      if (data.isSuccess) {
        pushNotification("Staff created with availability saved successfully.");
        fetchAllStaff();
        handleCloseModal();
        close();
      }

      setSubmitFormLoader(false);
    } catch (error) {
      setSubmitFormLoader(false);

      if (!rest.forever && error?.response?.data.error) {
        pushNotification("End time must be greater than current time", "error");
      }
      if (error?.response?.data.error) {
        pushNotification(error?.response?.data?.error, "error");
      }
    }
  };

  const validateDate = (selcteddays, startDate, endDate) => {
    const recurringDays = selcteddays;

    if (startDate && endDate) {
      if (recurringDays.length) {
        const exists = checkDaysInRange(startDate, endDate, recurringDays);

        if (!exists) {
          setdateValidationError(true);
          return true;
        }
        setdateValidationError(false);
        return false;
      }
    }
  };
  function checkDaysInRange(start_date, end_date, daysArray) {
    const startDate = new Date(start_date);
    const endDate = new Date(end_date);

    // Loop through the daysArray
    for (let i = 0; i < daysArray.length; i++) {
      const day = daysArray[i];
      const currentDate = new Date(startDate);

      // Set the currentDate to the next occurrence of the day in the future
      currentDate.setDate(currentDate.getDate() + ((day + 7 - currentDate.getDay()) % 7));

      // Check if the currentDate is within the range of start_date and end_date
      if (currentDate >= startDate && currentDate <= endDate) {
        return true;
        console.log(`Day ${day} falls between ${start_date} and ${end_date}`);
      } else {
        return false;
        console.log(`Day ${day} does not fall between ${start_date} and ${end_date}`);
      }
    }
  }

  function checkTimeValidity(timeArray) {
    for (let i = 0; i < timeArray.length; i++) {
      const startTime = parseTime(timeArray[i].start_time);
      const endTime = parseTime(timeArray[i].end_time);

      if (!startTime || !endTime || startTime === null || endTime === null) {
        // Invalid time format
        return false;
      }

      if (startTime >= endTime) {
        return false; // Invalid time range found
      }
    }
    return true; // All time ranges are valid
  }

  function parseTime(timeString) {
    const match = timeString?.match(/^(\d+):(\d+) (am|pm)$/i);
    if (!match) return null;

    let [_, hours, minutes, period] = match;
    hours = parseInt(hours);
    minutes = parseInt(minutes);
    if (period.toLowerCase() === "pm" && hours !== 12) {
      hours += 12;
    } else if (period.toLowerCase() === "am" && hours === 12) {
      hours = 0;
    }
    return hours * 60 + minutes;
  }

  function getAllTimes(daysObject) {
    let allTimes = [];
    for (const day in daysObject) {
      allTimes = allTimes.concat(daysObject[day]);
    }
    return allTimes;
  }
  const dateFormat = "YYYY-MM-DD";

  return (
    <div>
      <div className="my-2">
        <Segmented
          style={{ marginBottom: 8 }}
          onChange={(value) => {
            setSelectTab(value);
            setdateValidationError(false);
          }}
          options={["Same time for each day", "Different time for each day"]}
          className=" p-1 "
          defaultValue="Same time for each day"
          size="medium"
        />
      </div>
      {SelectTab === "Same time for each day" && (
        <Formik
          initialValues={{
            selectedDays: [],
            forever: true,
            days_time: [],
            start_date: "",
            end_date: "",
          }}
          validationSchema={validationSchemaSameDay}
          onSubmit={handleSubmit}
          enableReinitialize
          innerRef={formikRefSecondStep}
        >
          {({ values, setFieldValue, errors }) => (
            <Form>
              <>
                <HeadingComponent text="Select Day & Time of the week" fontSize={"0.75rem"} margin="5px 0px" />
                <FieldArray
                  name="days_time"
                  render={(arrayHelpers) => (
                    <div>
                      <div className="d-flex  flex-wrap my-2">
                        <Checkbox.Group
                          options={daysArray.map((items) => {
                            return {
                              label: items.label.slice(0, 3),
                              value: items.value,
                            };
                          })}
                          onChange={(e) => {
                            setFieldValue("selectedDays", e);

                            if (values.selectedDays?.length === 0 && values?.days_time?.length > 0) return;
                            if (values.selectedDays?.length === 0) {
                              setFieldValue("days_time", [...values.days_time, { start_time: null, end_time: null }]);
                            }
                          }}
                        />
                      </div>
                      {errors?.selectedDays && <div className="input-error-style">{errors?.selectedDays}</div>}
                      {values?.days_time?.map((time, index) => (
                        <div key={index} className={`${values.selectedDays?.length === 0 ? "d-none" : "d-block"}`}>
                          <Row align={"bottom"}>
                            <Col lg={10} style={{ position: "relative" }}>
                              <div className="d-flex">
                                <RequiredSignComponent />
                                <HeadingComponent text="Start Time" fontSize={"12px"} margin="7px 0px" />
                              </div>

                              <TimePicker
                                // value={dayjs(time?.start_time, "h:mm a")}

                                {...(time?.start_time !== null
                                  ? {
                                      value: dayjs(time?.start_time, "h:mm a"),
                                    }
                                  : { defaultOpenValue: moment().hour(0).minute(0) })}
                                onChange={(time, timeString) => {
                                  setFieldValue(`days_time.${[index]}.start_time`, timeString);
                                }}
                                format="h:mm a"
                                className="w-100"
                                placeholder="Select Start Time"
                                allowClear={false}
                              />
                              {errors?.days_time?.[index]?.start_time && (
                                <div className="input-error-style" style={{ position: "absolute", top: "100%", left: 0 }}>
                                  {errors?.days_time?.[index]?.start_time}
                                </div>
                              )}
                            </Col>
                            <Col md={2} className="d-flex justify-content-center">
                              <span>-</span>
                            </Col>

                            <Col md={10} className="d-flex justify-content-end flex-column" style={{ position: "relative" }}>
                              <div className="d-flex">
                                <RequiredSignComponent />
                                <HeadingComponent text="End Time" fontSize={"12px"} margin="7px 0px" />
                              </div>

                              <TimePicker
                                // value={dayjs(time?.end_time, "h:mm a")}
                                onChange={(time, timeString) => {
                                  setFieldValue(`days_time.${[index]}.end_time`, timeString);
                                }}
                                format="h:mm a"
                                allowClear={false}
                                className="w-100"
                                {...(time?.end_time !== null
                                  ? {
                                      value: dayjs(time?.end_time, "h:mm a"),
                                    }
                                  : { defaultOpenValue: moment().hour(0).minute(0) })}
                                placeholder="Select End Time"
                              />
                              {errors?.days_time?.[index]?.end_time && (
                                <div className="input-error-style" style={{ position: "absolute", top: "100%", left: 0 }}>
                                  {errors?.days_time?.[index]?.end_time}
                                </div>
                              )}
                            </Col>
                          </Row>

                          <div className={`d-flex align-items-center my-2 mt-3`}>
                            {index === values?.days_time?.length - 1 && (
                              <button
                                type="button"
                                className={`mt-2 fw-bolder align-items-center d-flex`}
                                onClick={() => arrayHelpers.push({ start_time: null, end_time: null })}
                                disabled={values?.days_time?.length === 4}
                              >
                                <div
                                  style={{
                                    width: "13px",
                                    height: "13px",
                                    background: "rgb(251, 207, 52)",
                                    borderRadius: "50%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    padding: "2px",
                                  }}
                                >
                                  <PlusYellowIcon />
                                </div>
                                <span style={{ fontSize: "12px", marginTop: "1px" }} className="ms-2">
                                  Add More
                                </span>
                              </button>
                            )}

                            <button
                              type="button"
                              className="mt-2 fw-bolder d-flex align-items-center"
                              onClick={() => arrayHelpers.remove(index)}
                              disabled={values?.days_time?.length === 1}
                            >
                              <div
                                style={{
                                  borderRadius: "50%",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "baseline",
                                  padding: "2px",
                                }}
                              >
                                <DeleteIcon height={"14px"} width="14px" className="mb-1" />
                              </div>
                              <span style={{ fontSize: "12px" }} className="ms-2">
                                Remove
                              </span>
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                />
                <Divider />
                <div className="my-2 mt-3 ">
                  <HeadingComponent text="Set this availability for" fontSize={"0.75rem"} margin="5px 0px" />
                  <Radio.Group
                    defaultValue={values.forever}
                    onChange={(e) => {
                      if (e.target.value) {
                        setFieldValue("start_date", "");
                        setFieldValue("end_date", "");
                      }

                      setFieldValue("forever", e.target.value);
                    }}
                  >
                    <Radio value={true}>Forever</Radio>
                    <Radio value={false}>Temporary</Radio>
                  </Radio.Group>
                </div>

                {!values.forever && (
                  <Row align={"bottom"}>
                    <Col md={24}>
                      <RangePicker
                        onChange={(e, dateString, extra) => {
                          const dayid = dayjs(dateString, dateFormat);

                          if (dayid) {
                            setFieldValue("start_date", dateString[0]);
                            setFieldValue("end_date", dateString[1]);
                          }
                        }}
                        format="YYYY-MM-DD"
                        placeholder={["Start Date", "End Date"]}
                        label="When"
                        name="dates"
                        type="text"
                        {...(updateForm
                          ? {
                              defaultValue: [dayjs(values?.start_date, dateFormat), dayjs(values?.end_date, dateFormat)],
                            }
                          : {})}
                        className=" mb-2 w-100"
                        // value={values?.holiday_date}
                      />
                      {(errors?.end_date || errors?.start_date) && (
                        <div className="input-error-style">Please select start and end date</div>
                      )}
                      {dateValidationError && (
                        <div className="input-error-style">Recurring days must be in the specified date range</div>
                      )}
                    </Col>
                  </Row>
                )}
              </>

              {/* Diff time each day frm */}
            </Form>
          )}
        </Formik>
      )}
      {SelectTab === "Different time for each day" && (
        <DifferntTimeEachDayForm
          handleSubmit={handleSubmit}
          innerRef={formikRefSecondStep}
          dateValidationError={dateValidationError}
        />
      )}
    </div>
  );
};
