import React, { useEffect, useState } from "react";
import { Col, Form, Input, Row } from "antd";
import styled from "styled-components";
import CustomBtnWithIcon from "../../components/buttonWithIcon";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import ColorPicker from "../ColorPicker/colorpicker";
import FontPicker from "../FontPicker/fontPicker";
import { availableFonts } from "../../util/colorAndFonts";
export default function ClientPortalTheme({ clientPortalData, themeData, handleNext, handleBack }) {
  const [initialColors, setInitialColors] = useState([
    "#FF5733",
    "#33FF57",
    "#3357FF",
    "#F1C40F",
    "#8E44AD",
    "#2C3E50",
    "#ffffff",
  ]);

  const [selectedHeaderBackgroundColor, setSelectedHeaderBackgroundColor] = useState("");
  const [selectedFooterBackgroundColor, setSelectedFooterBackgroundColor] = useState("");
  const [selectedHeaderTextColor, setSelectedHeaderTextColor] = useState("");
  const [selectedHeaderSecondaryFontColor, setSelectedHeaderSecondaryFontColor] = useState("");
  const [selectedFooterTextFontColor, setSelectedFooterTextFontColor] = useState("");

  const [form] = Form.useForm();

  const onFinish = (formValues) => {
    // setJWTToken(state.token);
    // getRolePermissions()
    // formValues['is_seen_onboarding'] = true
    // updateCompanyDetails(state.selectedComid, formValues)
    // .then(({data}) => {
    //   if(data.code == 200) {
    //     navigate("/company/");
    //   }
    // })
    // .catch((error) => {
    //   pushNotification("Something Went Wrong!", "error");
    // })
    formValues.headerBackgroundColor = selectedHeaderBackgroundColor || "";
    formValues.headerTextColor = selectedHeaderTextColor || "";
    formValues.headerSecondaryFontColor = selectedHeaderSecondaryFontColor || "";
    formValues.footerBackgroundColor = selectedFooterBackgroundColor || "";
    formValues.footerTextFontColor = selectedFooterTextFontColor || "";

    const data = {
      clientportal_header_background: formValues.headerBackgroundColor,
      clientportal_header_text: formValues.headerTextColor,
      clientportal_header_secondary: formValues.headerSecondaryFontColor,
      clientportal_footer_background: formValues.footerBackgroundColor,
      clientportal_footer_text: formValues.footerTextFontColor,
    };

    handleNext(data);
  };

  return (
    <>
      <FormHeader>Client Portal Theme</FormHeader>
      <FormDescriptionText>Set your client portal theme</FormDescriptionText>
      <Form form={form} name="clientProtal" onFinish={onFinish} layout="vertical">
        {clientPortalData?.clientportal_header_background && (
          <Form.Item name="headerBackgroundColor" label="Header Background Color">
            <ColorPicker
              initialColors={initialColors}
              setInitialColors={setInitialColors}
              selectedColorData={themeData?.clientportal_header_background}
              setValue={setSelectedHeaderBackgroundColor}
              disabled={!clientPortalData?.clientportal_header_background}
            />
            <FormDescriptionText>Choose color theme to be shown on the client web</FormDescriptionText>
          </Form.Item>
        )}

        {clientPortalData?.clientportal_header_text && (
          <FormItem name="headerTextColor" label="Header Text Color">
            <ColorPicker
              initialColors={initialColors}
              setInitialColors={setInitialColors}
              selectedColorData={themeData?.clientportal_header_text}
              setValue={setSelectedHeaderTextColor}
              disabled={!clientPortalData?.clientportal_header_text}
            />
            <FormDescriptionText>Choose color theme to be shown on the client web</FormDescriptionText>

            {/* <FontPicker fonts={availableFonts} selectedFont={selectedHeaderTextFont} setSelectedFont={setSelectedHeaderTextFont} disabled={!clientPortalData?.clientportal_header_text}/> */}
          </FormItem>
        )}
        {clientPortalData?.clientportal_header_secondary && (
          <FormItem name="headerSecondaryFontColor" label="Header Secondary Text Color">
            <ColorPicker
              initialColors={initialColors}
              setInitialColors={setInitialColors}
              selectedColorData={themeData?.clientportal_header_secondary}
              setValue={setSelectedHeaderSecondaryFontColor}
              disabled={!clientPortalData?.clientportal_header_secondary}
            />
            <FormDescriptionText>Choose color theme to be shown on the client web</FormDescriptionText>

            {/* <FontPicker fonts={availableFonts} selectedFont={selectedHeaderSecondaryFont} setSelectedFont={setSelectedHeaderSecondaryFont}disabled={!clientPortalData?.clientportal_header_secondary}/> */}
          </FormItem>
        )}

        {clientPortalData?.clientportal_footer_background && (
          <Form.Item name="footerBackgroundColor" label="Footer Background Color">
            <ColorPicker
              initialColors={initialColors}
              setInitialColors={setInitialColors}
              selectedColorData={themeData?.clientportal_footer_background}
              setValue={setSelectedFooterBackgroundColor}
              disabled={!clientPortalData?.clientportal_footer_background}
            />

            <FormDescriptionText>Choose color theme to be shown on the client web</FormDescriptionText>
          </Form.Item>
        )}

        {clientPortalData?.clientportal_footer_text && (
          <FormItem name="footerTextFontColor" label="Footer Text Color">
            <ColorPicker
              initialColors={initialColors}
              setInitialColors={setInitialColors}
              selectedColorData={themeData?.clientportal_footer_text}
              setValue={setSelectedFooterTextFontColor}
              disabled={!clientPortalData?.clientportal_footer_text}
            />

            <FormDescriptionText>Choose color theme to be shown on the client web</FormDescriptionText>

            {/* <FontPicker fonts={availableFonts} selectedFont={selectedFooterTextFont} setSelectedFont={setSelectedFooterTextFont} disabled={!clientPortalData?.clientportal_footer_text}/> */}
          </FormItem>
        )}
        <CustomBtnWithIcon btnText={"Next"} type="submit" style={{ width: "100%" }} hideIcon={true} />
        <CustomBtnWithIcon
          btnText={"Back"}
          margin="10px 0px"
          style={{ width: "100%" }}
          noBackground
          hideIcon={true}
          onClick={() => handleBack()}
        />
      </Form>
    </>
  );
}

const FormHeader = styled.h1`
  font-family:
    Helvetica Neue,
    Arial;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #000000;
  margin-top: 24px;
`;

const FormDescriptionText = styled.p`
  font-family: "Helvetica Neue", Arial;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #757575;
`;

const FieldWraper = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
`;

const FormWraper = styled.div`
  height: 556px;
  overflow: auto;

  ::-webkit-scrollbar {
    width: 0px;
  }
`;

const FurtherDetails = styled.div`
  span {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: #757575;
  }
`;

const FormItem = styled(Form.Item)`
  font-family: Helvetica Neue;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #3b3b3b;
  width: 100%;
`;
