const HomePageStatusCodes = {
  FETCH_ALL_API_ERROR: 403,
};

export const clientCreationFormErrorCodes = {
  COMPANY_NOT_FOUND_ERROR: 404,
  CLIENT_CREATED_SUCCESSFULLY: 201,
  CLIENT_EDIT_CREATED_SUCCESSFULLY: 201,
  CLIENT_UPDATE_SUCCESSFULLY: 200,
};
