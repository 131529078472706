import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { AntdesignTable } from "../../../../components/antDesignTable/AntdesignTable";
import SearchInput from "../../../../components/SearchInput";
import { scheduleRequestColumens } from "../../../../constants/CompanyTableHeaders/SchedulerTableHeaders";

import { ReactComponent as DropDownIcon } from "../../../../assets/rawSvgs/selectDropDownIcon.svg";
import DayViewWithResources from "./PendingRequestCalender";
import AntDesignSegment from "../../../../components/antDesignSegment/Index";
import { main_api } from "../../../../api/axios_helper";

import { useSelector } from "react-redux";
import { fetchSchedulerPendingREquestApi } from "../../../../redux/slices/schedulerSlice";
import { useDispatch } from "react-redux";

import EditReqestCompoennt from "./EditRequest";
import DeleteConfirmationRowModal from "../../../../components/Modals/DeleteConfirmationRowModal";
import { pushNotification } from "../../../../util/Notification";
import { checkPermission } from "../../../../util/commonUtil";
import PermissionModal from "../../../../components/Modals/PermissionModal";
import NoDataScreen from "../../../../components/NoDataScreen";
//
const ScheduledRequest = ({ fetchAllSchedulerService, schedulerState }) => {
  const [SelectTab, setSelectTab] = React.useState("pendingRequest");
  const dispatch = useDispatch();
  const compId = useSelector((state) => state.auth.loginResponse);
  const statePermision = useSelector((state) => state.permissionManagement);
  const pendingReqState = useSelector((state) => state.scheduler.schedulerPendingRequestResponse);
  const staffState = useSelector((state) => state.staffManagement.staffAllDetails);
  const [confirmationDeleteModal, setOpenConfimrationDeleteModal] = useState(false);
  const [rowValues, setRowValues] = useState(undefined);
  const [formloader, setformloader] = useState(false);
  const [permissionPopUp, setPermissionPopUp] = useState(false);

  useEffect(() => {
    handleFetchPendingReq();
  }, [compId?.compId?.selectCompany?.[0]?.company_id]);

  useEffect(() => {
    if (checkPermission(statePermision.permissions, "view_service_requests")) {
      fetchAllSchedulerService("", "status=scheduled&status=in_progress&status=completed");
    }
  }, []);

  const handleFetchPendingReq = async () => {
    try {
      if (checkPermission(statePermision.permissions, "view_service_requests")) {
        dispatch(fetchSchedulerPendingREquestApi());
      }
    } catch (error) {}
  };

  const handleDeleteRow = (values) => {
    if (checkPermission(statePermision.permissions, "delete_service_request")) {
      setRowValues(values);
      setOpenConfimrationDeleteModal(true);
    } else {
      setPermissionPopUp(true);
    }
  };

  const handleDeleteConfirm = async () => {
    try {
      setformloader(true);
      const { data } = await main_api.delete(`/api/v1/service_request_group/${rowValues.id}/`);

      if (data.isSuccess) {
        pushNotification("Service group deleted Successfully");
        handleFetchPendingReq();
      }
      setOpenConfimrationDeleteModal(false);
      setformloader(false);
    } catch (error) {
      setformloader(false);
    }
  };
  const handleEditRow = () => {};

  const handleSearch = (rows) => {
    console.log();
  };

  const expandedRowRender = (record) => (
    <div style={{ margin: 0, textAlign: "center" }}>
      {" "}
      <DayViewWithResources
        staffState={staffState?.responseData}
        calenderData={record}
        handleFetchPendingReq={handleFetchPendingReq}
        schedulerState={schedulerState}
        fetchAllSchedulerService={fetchAllSchedulerService}
      />
    </div>
  );

  const getInsideObject = pendingReqState.responseData?.result
    ?.flatMap(({ service_request_group_one_day_requests, ...rest }) => {
      let insideService = [];
      let services = [];
      let v2 = undefined;
      let dates = [];
      let serviceStatus = [];
      let scheduledCount = 0;
      let unassignedCount = 0;

      service_request_group_one_day_requests.forEach((elements) => {
        v2 = Array.from(new Set(elements?.one_day_service_request_single_services?.map((request) => request.client.name)));
        insideService.push(elements);

        elements?.one_day_service_request_single_services.forEach((oneDayElem) => {
          services.push(oneDayElem.service);
          dates.push(oneDayElem.date);
          serviceStatus.push({ status: oneDayElem.status, id: oneDayElem.id });

          // Update counts based on status
          if (oneDayElem.status === "scheduled") {
            scheduledCount++;
          } else if (oneDayElem.status === "unassigned") {
            unassignedCount++;
          }
        });
      });

      // Convert dates array to Set to make it unique, then back to an array
      const uniqueDates = [...new Set(dates)];

      return {
        service_request_group_one_day_requests,
        ...rest,
        groupOneDay: insideService,
        clientName: v2,
        services,
        dates: uniqueDates, // Using uniqueDates array
        serviceStatus,
        scheduledCount,
        unassignedCount,
      };
    })

    .filter((items) => {
      return items?.clientName?.length > 0;
    });

  function addAllAssignedKey(originalData) {
    for (let i = 0; i < originalData?.length; i++) {
      const serviceStatus = originalData[i].serviceStatus;
      if (!serviceStatus || !Array.isArray(serviceStatus)) {
        originalData[i].allAssigned = false;
        continue;
      }
      let allAssigned = true;
      for (let j = 0; j < serviceStatus?.length; j++) {
        if (serviceStatus[j]?.status !== "scheduled") {
          allAssigned = false;
          break;
        }
      }
      originalData[i].allAssigned = allAssigned;
    }
    return originalData;
  }

  const filteredObjects = addAllAssignedKey(getInsideObject || [])?.filter(
    (items) =>
      !items.allAssigned &&
      items.scheduled_by !== "template" &&
      !items.serviceStatus?.some((obj) => obj.status === "change_requested"),
  );

  return (
    <div>
      <div className="d-flex align-items-center ">
        <SearchInputWrapper>
          <SearchInput onChange={handleSearch} placeholder="Type to search..." />
        </SearchInputWrapper>
        <div className="w-25 ms-2">
          <AntDesignSegment
            options={["Pending Request", "Edit Request"]}
            onChange={(e) => setSelectTab(e)}
            defaultValue="Pending Request"
            size="large"
          />
        </div>
      </div>
      {SelectTab === "Edit Request" ? (
        <EditReqestCompoennt
          allStates={schedulerState}
          staffState={staffState?.responseData}
          fetchAllSchedulerService={fetchAllSchedulerService}
        />
      ) : (
        <>
          {filteredObjects?.length === 0 ? (
            <NoDataScreen heading="You don’t have any pending services in your list" showBtn={false} />
          ) : (
            <>
              <AntdesignTable
                columns={scheduleRequestColumens(handleEditRow, handleDeleteRow)}
                data={filteredObjects || []}
                selectedRows={() => console.log("selectedRows")}
                allowMultieSelectRows={true}
                expandable={{
                  expandedRowRender: expandedRowRender,

                  expandIcon: ({ expanded, onExpand, record }) =>
                    expanded ? (
                      <ExpandableTableIcon
                        expanded="180deg"
                        onClick={(e) => {
                          if (checkPermission(statePermision.permissions, "view_service_request")) {
                            onExpand(record, e);
                          } else {
                            setPermissionPopUp(true);
                          }
                        }}
                      >
                        <DropDownIcon />
                      </ExpandableTableIcon>
                    ) : (
                      <ExpandableTableIcon
                        expanded="360deg"
                        onClick={(e) => {
                          if (checkPermission(statePermision.permissions, "view_service_request")) {
                            onExpand(record, e);
                          } else {
                            setPermissionPopUp(true);
                          }
                        }}
                      >
                        <DropDownIcon
                          onClick={(e) => {
                            if (checkPermission(statePermision.permissions, "view_service_request")) {
                              onExpand(record, e);
                            } else {
                              setPermissionPopUp(true);
                            }
                          }}
                        />
                      </ExpandableTableIcon>
                    ),
                }}
                rowKey="id"
                loading={pendingReqState.isLoading}

                // selectedRows={handleSelectDeleteMultiple}
              />
            </>
          )}
        </>
      )}
      {confirmationDeleteModal && (
        <DeleteConfirmationRowModal
          onclose={() => setOpenConfimrationDeleteModal(false)}
          handleConfirmDeleteRows={handleDeleteConfirm}
          openConfimrationDeleteModal={confirmationDeleteModal}
          BtnloadingState={formloader}
        />
      )}

      <PermissionModal onclose={() => setPermissionPopUp(false)} openModal={permissionPopUp} />
    </div>
  );
};

export default ScheduledRequest;

const SearchInputWrapper = styled.div`
  width: 15%;
  margin-left: 10px;
`;

const ExpandableTableIcon = styled.button`
  rotate: ${(props) => props.expanded};
  transition: all 0.4s;
`;

export const TabsWrapper = styled.div`
  height: ${(props) => props.height ?? "40px"};
  background: #f5f5f5;
  padding: ${(props) => props.padding ?? "5px 4px"};
  display: flex;
  align-items: center;
  width: ${(props) => props.width ?? "25%"};
  justify-content: space-between;
  border-radius: 6px;
  margin-right: 1px;
`;

export const TabPill = styled.button`
  background-color: ${(props) => (props.selected ? "white" : "")};
  display: flex;
  align-items: center;
  height: ${(props) => props.height ?? "29px"};
  border: ${(props) => (props.selected ? "1px solid rgb(0 0 0 / 0%)" : "1px solid transparent")};
  border-radius: 6px;
  padding: ${(props) => props.padding ?? "5px 10px 0px 10px"};
  margin: 0px 5px;
  line-height: 30px;
  box-shadow: ${(props) => (props.selected ? "rgb(0 0 0 / 0%) 0px 0px 5px 0px" : "")};
`;
