


import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import { Checkbox, Divider, Form, TimePicker } from 'antd';
import CustomBtnWithIcon from '../../../../../components/buttonWithIcon';
import HeadingComponent from '../../../../../components/Heading';
import { editNotificationSettings } from '../../../../../util/DataService';
import { pushNotification } from '../../../../../util/Notification';
import dayjs from 'dayjs';
import moment from "moment";
export default function MissingKeyNotifications({ company_id, notificationsData, setNotificationsData }) {
  const [notificationOptions, setNotificationOptions] = useState({
    missingkey_email_alert: false,
    missingkey_push_alert: false,
    missingkey_msg_alert: false,
  });

  const [sendNotificationsOptions, setSendNotificationsOptions] = useState({
    missingkey_alerts_to_staff: false,
    missingkey_alerts_to_manager: false,
  });

  const [notificationTime, setNotificationTime] = useState(null);

  useEffect(() => {
    if (notificationsData) {
      setNotificationOptions({
        missingkey_email_alert: notificationsData?.missingkey_email_alert || false,
        missingkey_push_alert: notificationsData?.missingkey_push_alert || false,
        missingkey_msg_alert: notificationsData?.missingkey_msg_alert || false,
      });
      setSendNotificationsOptions({
        missingkey_alerts_to_staff: notificationsData?.missingkey_alerts_to_staff || false,
        missingkey_alerts_to_manager: notificationsData?.missingkey_alerts_to_manager || false,
      });
      setNotificationTime(notificationsData?.missingkey_alerts_time ? moment(notificationsData.missingkey_alerts_time, "h:mm a") : null);
    }
  }, [notificationsData]);

  const handleNotificationOptionChange = (option) => (e) => {
    setNotificationOptions({
      ...notificationOptions,
      [option]: e.target.checked,
    });
  };

  const handleSendNotificationsOptionChange = (option) => (e) => {
    setSendNotificationsOptions({
      ...sendNotificationsOptions,
      [option]: e.target.checked,
    });
  };

  const handleSaveChanges = () => {
    let data = {
      company_id,
      missingkey_email_alert: notificationOptions.missingkey_email_alert,
      missingkey_push_alert: notificationOptions.missingkey_push_alert,
      missingkey_msg_alert: notificationOptions.missingkey_msg_alert,
      missingkey_alerts_to_staff: sendNotificationsOptions.missingkey_alerts_to_staff,
      missingkey_alerts_to_manager: sendNotificationsOptions.missingkey_alerts_to_manager,
      missingkey_alerts_time: notificationTime ? notificationTime.format("h:mm a") : null,
    };
    editNotificationSettings(data)
      .then(({ data }) => {
        if (data.isSuccess) {
          setNotificationsData(data.result);
          pushNotification(data.message, "success");
        }
      })
      .catch((error) => {
        console.log("API error:", error);
      });
  };

  return (
    <MainContainer>
      <div className="d-flex flex-row" style={{ marginBottom: '10%', justifyContent: "space-between" }}>
        <div style={{ width: '60%', marginLeft: "24px" }}>
          <Heading text="Notification Alerts" fontSize="1rem" color="black" />
        </div>
        <div className="d-flex" style={{ flexDirection: 'column', position: "relative", top: "0px", left: "190px" }}>
          <Checkbox onChange={handleNotificationOptionChange("missingkey_email_alert")} checked={notificationOptions.missingkey_email_alert}>
            Email
          </Checkbox>
          <Checkbox onChange={handleNotificationOptionChange("missingkey_push_alert")} checked={notificationOptions.missingkey_push_alert}>
            Push
          </Checkbox>
          <Checkbox onChange={handleNotificationOptionChange("missingkey_msg_alert")} checked={notificationOptions.missingkey_msg_alert}>
            Message
          </Checkbox>
        </div>
      </div>
      <Divider />
      <div className="d-flex flex-row" style={{ marginBottom: '10%' }}>
        <div style={{ width: '60%', marginLeft: "24px" }}>
          <Heading text="Send Notifications" fontSize="1rem" color="black" />
        </div>
        <div className="d-flex" style={{ flexDirection: 'column', position: "relative", top: "0px", left: "190px" }}>
          <Checkbox onChange={handleSendNotificationsOptionChange("missingkey_alerts_to_staff")} checked={sendNotificationsOptions.missingkey_alerts_to_staff}>
            Staff
          </Checkbox>
          <Checkbox onChange={handleSendNotificationsOptionChange("missingkey_alerts_to_manager")} checked={sendNotificationsOptions.missingkey_alerts_to_manager}>
            Manager
          </Checkbox>
        </div>
      </div>
      <Divider />
      <div className="d-flex flex-row" style={{ marginBottom: '10%' }}>
        <div style={{ width: '60%', marginLeft: "24px" }}>
          <Heading text="Time of Notification" fontSize="1rem" color="black" />
        </div>
        <div className="d-flex" style={{ flexDirection: 'column', position: "relative", top: "0px", left: "157px" }}>
          <TimePicker
            style={{ width: "100%" }}
            use12Hours
            format="h:mm a"
            placeholder="Select Time"
            value={notificationTime}
            onChange={(time) => setNotificationTime(time)}
          />
        </div>
      </div>
      <Divider />
      <SaveContainer className="d-flex justify-content-end">
        <CustomBtnWithIcon btnText="Save Changes" type="submit" hideIcon={true} onClick={handleSaveChanges} />
      </SaveContainer>
    </MainContainer>
  );
}

const Heading = ({ text = "", margin, fontSize = "0.75rem", color = "#3B3B3B" }) => {
  return <HeadingComponent text={text} fontSize={fontSize} color={color} fontWeight={700} margin={margin} />;
};

const FormItem = styled(Form.Item)`
  width: 100%;
  margin-left: 80%;
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
`;

const SaveContainer = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  border-top: 1px solid #E0E0E0;
  background-color: white;
  padding: 10px 20px;
`;
