import React, { useEffect, useState } from "react";
import { Row, Col, DatePicker, Collapse } from "antd";
import dayjs from "dayjs";
import moment from "moment";
import styled from "styled-components";
import { Formik, Form, FieldArray } from "formik";
import { useSelector } from "react-redux";
import * as Yup from "yup";

import { ReactComponent as FirstStep } from "../../../../assets/rawSvgs/Frame1000005140(2).svg";
import { ReactComponent as FirstStepGreenChecked } from "../../../../assets/rawSvgs/frame1000005142.svg";
import { ReactComponent as GreaterThanSign } from "../../../../assets/rawSvgs/greaterThanIcon.svg";
import { ReactComponent as SecondStepChecked } from "../../../../assets/rawSvgs/Frame1000005140.svg";
import { ReactComponent as SecondStepTransparent } from "../../../../assets/rawSvgs/Frame000005140Transparnet.svg";
import { ReactComponent as PlusYellowIcon } from "../../../../assets/rawSvgs/yelowplus.svg";
import { DeleteIcon } from "../../../../assets/rawSvgs/index";

import HeadingComponent from "../../../../components/Heading";
import CustomBtnWithIcon from "../../../../components/buttonWithIcon";
import AntDesignModal from "../../../../components/Modals/AntDesignModal/AntDesignModal";
import FormikTextArea from "../../../../components/formikInputs/textArea";

import { StepsContainer } from "../../staff/StaffStyles";

import FormikDateMonthInput from "../../../../components/formikInputs/DateMonth";
import FormikCheckboxInput from "../../../../components/formikInputs/Checkbox";
import FormikSelectInput from "../../../../components/formikInputs/SelectInput";
import { pushNotification } from "../../../../util/Notification";
import { main_api } from "../../../../api/axios_helper";
import { companyAPIsEndPoints } from "../../../../constants/ApiEndPoints";
import { convertTo12HourFormat } from "../../../../util/formatTime";
import { convertTo24HourFormat, generateAvailableTimeSlots } from "../pendingRequest/utilsFunctions";

const ScheduleTemplate = ({ updateValues, addNewEventModal, filterUpdateService, updateForm, requiredDropdowns, closeAll }) => {
  const [addStaffSetps, setAddStaffSetps] = useState(1);
  const [tempFormDetails, setTempFormDetails] = useState({});
  const [submitFormLoader, setSubmitFormLoader] = useState(false);
  const [keyList, setKeyList] = useState([]);
  const [keyOptions, setKeyOptions] = useState([]);
  const state = useSelector((state) => state.auth.loginResponse);
  const clientStatePets = useSelector((state) => state?.clientManangement?.clientResponse.responseData.result);
  let company_id = state?.selectCompany?.[0]?.company_id || null;
  const formikRef = React.useRef();
  const formikRefSecondStep = React.useRef();
  const handleSubmit = async (values) => {
    setTempFormDetails(values);

    setAddStaffSetps(2);
  };
  const getKeys = async (queryParams = "", search = "") => {
    try {
      const { data } = await main_api.get(companyAPIsEndPoints.FETCH_MANAGEMENT_KEYS(company_id, queryParams, search));

      setKeyList(data.result);
    } catch (error) {
      pushNotification("Something went wrong while getting keys!", "error");
    }
  };
  useEffect(() => {
    getKeys();
  }, []);

  const filterKeysByClient = (clientId) => {
    const filteredKeys = keyList
      .filter((key) => key.client === clientId)
      .map((item) => ({
        label: item.name,
        value: item.id,
      }));
    setKeyOptions(filteredKeys);
  };

  const validationSchema = Yup.object().shape({
    client: Yup.string().required("Client is required"), // `client` field should be a non-empty string.
    // staff: Yup.string().required("Staff is required"), // `staff` field should be a non-empty string.
    start_date: Yup.date()
      .required("Start date is required")
      .test("is-valid-date", "Start date is not valid", (value) => !isNaN(Date.parse(value))),
    end_date: Yup.date()
      .required("End date is required")
      .test("is-valid-date", "End date is not valid", (value) => !isNaN(Date.parse(value)))
      .min(Yup.ref("start_date"), "End date must not be before the start date"),
    key_required: Yup.boolean(),
    key: Yup.string().when("key_required", {
      is: true,
      then: Yup.string().required("Key is required"),
    }),
    note: Yup.string(),
  });

  return (
    <>
      <AntDesignModal
        width={700}
        title={updateValues?.title ? "Update a Scheduled Trip" : "Schedule a Trip"}
        style={{ top: 20 }}
        openModal={addNewEventModal}
        closeModal={closeAll}
        component={
          <>
            <div
              style={{
                height: "75vh",
                overflowY: "auto",
                overflowX: "hidden",
                borderBottom: "0.5px solid rgb(144 144 144 / 43%)",
                padding: " 10px 0px",
              }}
            >
              <StepsContainer className={`${updateForm ? "d-none" : "d-flex"}`}>
                <div className="d-flex align-items-center ms-2 cursorPointer">
                  {addStaffSetps === 1 ? <FirstStep /> : <FirstStepGreenChecked />}
                  <HeadingComponent text={"Trip Details"} fontSize="14px" color="#616161" margin={"0px 12px"} />
                </div>
                <div className="mx-3">
                  <GreaterThanSign />
                </div>
                <div className="d-flex align-items-center">
                  {addStaffSetps === 1 ? <SecondStepTransparent /> : <SecondStepChecked />}

                  <HeadingComponent text={"Time For Each Day"} fontSize="14px" color="#616161" margin={"0px 7px"} />
                </div>
              </StepsContainer>

              {/* FORM ======= */}

              <Formik
                initialValues={{
                  client: "",
                  staff: "",
                  start_date: "",
                  end_date: "",
                  key_required: false,
                  key: "",
                  note: "",
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                enableReinitialize
                innerRef={formikRef}
              >
                {({ values, setFieldValue, errors }) => (
                  <Form>
                    <div style={{ display: addStaffSetps === 1 ? "block" : "none" }}>
                      <Row justify={"space-between"} gutter={15}>
                        {/* ====================================== */}

                        <Col md={12} className="">
                          <FormikDateMonthInput
                            format={"YYYY-MM-DD"}
                            // defaultValue={dayjs(dayjs(), "YYYY-MM-DD")}
                            className="w-100"
                            style={{ height: "40px" }}
                            disabledDate={(current) => current && current < moment().startOf("day")}
                            allowClear={false}
                            name={"start_date"}
                            label="Start Date"
                            onChange={(e, text) => {
                              setFieldValue("start_date", text);
                            }}
                          />
                        </Col>
                        <Col md={12} className="">
                          <FormikDateMonthInput
                            format={"YYYY-MM-DD"}
                            // defaultValue={dayjs(dayjs(), "YYYY-MM-DD")}
                            disabledDate={(current) => current && current < moment().startOf("day")}
                            className="w-100"
                            style={{ height: "40px" }}
                            allowClear={false}
                            name={"end_date"}
                            label="End Date"
                            onChange={(e, text) => {
                              setFieldValue("end_date", text);
                            }}
                          />
                        </Col>

                        {/* ====================================== */}

                        <Col md={12} className="my-2">
                          <FormikSelectInput
                            label="Staff"
                            placeholder="Select Staff"
                            defaultValue={"Select Staff"}
                            name="staff"
                            type="text"
                            className="w-100 mb-2"
                            autoComplete="new-password"
                            //   value={values?.service_requests[0]?.services?.[0]?.staff}
                            options={requiredDropdowns?.staffState}
                            onChange={(e) => {
                              setFieldValue("staff", e);
                            }}
                            width="100%"
                            noBr
                            allowClear={true}
                            // disabled={!values.extra_pet_enabled}
                          />
                        </Col>
                        <Col md={12} className="my-2">
                          <FormikSelectInput
                            label="Client"
                            placeholder="Select Client"
                            name="client"
                            type="text"
                            className="w-100 mb-2"
                            autoComplete="new-password"
                            defaultValue={filterUpdateService?.client?.id ? filterUpdateService?.client?.id : "Select Client"}
                            options={requiredDropdowns?.clientState}
                            disabled={updateValues?.title ? true : false}
                            onChange={(e) => {
                              setFieldValue("client", e);
                              filterKeysByClient(e);
                            }}
                            width="100%"
                            noBr
                            // disabled={!values.extra_pet_enabled}
                          />
                        </Col>

                        <Col md={12} className="mt-2">
                          <div className="d-flex  align-items-center">
                            <FormikCheckboxInput
                              label=""
                              placeholder="One Per Event"
                              name="key_required"
                              type="text"
                              // className=" mb-2"
                              size="small"
                              checked={values.key_required}
                              onChange={(e) => {
                                setFieldValue("key_required", e.target.checked);
                              }}
                            />

                            <HeadingComponent text={"Key Required?"} fontSize="14px" margin="10px" padding="0px" />
                          </div>
                        </Col>
                        <Col md={24} className="my-2">
                          {values.key_required && (
                            <FormikSelectInput
                              label="Keys"
                              placeholder="Select Key"
                              defaultValue={"Select Key"}
                              name="key"
                              type="text"
                              className="w-100 mb-2"
                              autoComplete="new-password"
                              //   value={values?.service_requests[0]?.services?.[0]?.staff}
                              options={keyOptions}
                              onChange={(e) => {
                                setFieldValue("key", e);
                              }}
                              width="100%"
                              noBr
                              // disabled={!values.extra_pet_enabled}
                            />
                          )}
                        </Col>
                        <Col md={24} className="">
                          <FormikTextArea
                            label="Notes"
                            placeHolder="Type Notes Here..."
                            name="note"
                            type="text"
                            className="w-100 mb-2"
                            autoComplete="new-password"
                            width="100%"
                            onChange={(e) => {
                              setFieldValue("note", e);
                            }}
                          />
                        </Col>
                      </Row>
                    </div>
                  </Form>
                )}
              </Formik>

              <div style={{ display: addStaffSetps === 1 ? "none" : "block" }}>
                <TimeForEachDayComponent
                  tempFormDetails={tempFormDetails}
                  requiredDropdowns={requiredDropdowns}
                  company_id={company_id}
                  setSubmitFormLoader={setSubmitFormLoader}
                  closeAll={closeAll}
                  formikRefSecondStep={formikRefSecondStep}
                />
              </div>
            </div>
          </>
        }
        footer={
          <div className="d-flex justify-content-between">
            <div>
              {addStaffSetps === 2 && (
                <CustomBtnWithIcon
                  btnText={"Back"}
                  hideIcon
                  noBackground
                  className="me-2"
                  width="90px"
                  onClick={() => {
                    setAddStaffSetps(1);
                  }}
                />
              )}
            </div>

            <div className="d-flex">
              <CustomBtnWithIcon btnText={"Cancel"} hideIcon noBackground className="me-2" width="90px" onClick={closeAll} />
              {addStaffSetps === 2 ? (
                <CustomBtnWithIcon
                  btnText={"Save"}
                  hideIcon
                  className="me-2"
                  width="90px"
                  type="submit"
                  // disabled={submitFormLoader}
                  onClick={() => {
                    if (formikRefSecondStep?.current) {
                      formikRefSecondStep?.current?.handleSubmit();
                    }
                  }}
                />
              ) : (
                <>
                  {updateForm ? (
                    <CustomBtnWithIcon
                      btnText={"Save"}
                      hideIcon
                      className="me-2"
                      width="90px"
                      type="submit"
                      // disabled={staffAddloader}
                      onClick={() => {
                        if (addStaffSetps === 1) {
                          if (formikRef?.current) {
                            formikRef?.current?.handleSubmit();
                            return;
                          }
                        }
                      }}
                    />
                  ) : (
                    <CustomBtnWithIcon
                      btnText={"Next"}
                      hideIcon
                      className="me-2"
                      width="90px"
                      type="submit"
                      onClick={() => {
                        if (formikRef?.current) {
                          formikRef?.current?.handleSubmit();
                        }

                        // setAddStaffSetps(2);
                      }}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        }
      />
    </>
  );
};

export default ScheduleTemplate;

const TimeForEachDayComponent = ({
  tempFormDetails,
  requiredDropdowns,
  company_id,
  setSubmitFormLoader,
  closeAll,
  formikRefSecondStep,
}) => {
  const { start_date, end_date, staff } = tempFormDetails;

  const [initialValues, setInitialValues] = useState([]);
  const [petsAccordingtoClient, petsAccordingtoClientSetSelectAllPets] = useState([]);

  const createItems = () => {
    // Convert string dates to Date objects
    const start = new Date(start_date);
    const end = new Date(end_date);

    // Create an array to hold the list of dates
    const dateList = [];

    // Generate the list of dates between start and end dates
    for (let d = start; d <= end; d.setDate(d.getDate() + 1)) {
      dateList.push({
        date: moment(d).format("YYYY-MM-DD"),
        services: [
          {
            service: "",
            start_time: "",
            end_time: "",
            pets: [],
            staff: staff,
            status: "",
            tasks: [],
            time_block: "",
          },
        ],
      });
    }

    // Update the state with the list of dates

    // form.setFieldsValue({ items: initialValues });
    setInitialValues(dateList);
  };

  useEffect(() => {
    createItems(start_date, end_date);

    // filter pets
    const filterUpdateService =
      requiredDropdowns?.clientAllState?.responseData?.result?.filter((items) => {
        return items.id === tempFormDetails?.client;
      })[0] || [];

    petsAccordingtoClientSetSelectAllPets(
      filterUpdateService?.clientpet?.map((items) => {
        return {
          label: items.name,
          value: items.id,
        };
      }),
    );
  }, [start_date, end_date, tempFormDetails]);

  function calculateUpdatedTime(initialTime, filterService) {
    // Extract hours and minutes from the initialTime string
    const [hoursStr, minutesStr] = initialTime.split(":");
    let hours = parseInt(hoursStr, 10);
    const minutes = parseInt(minutesStr, 10);

    // Convert initialTime to total minutes
    const initialTimeTotalMinutes = hours * 60 + minutes;

    // Add filterService minutes to initialTime
    const updatedTimeTotalMinutes = initialTimeTotalMinutes + filterService;

    // Calculate new hours and minutes
    let updatedHours = Math.floor(updatedTimeTotalMinutes / 60);
    let updatedMinutes = updatedTimeTotalMinutes % 60;

    // Adjust hours if necessary
    updatedHours = updatedHours % 24; // Ensure it stays within 24-hour range

    // Format updated time
    let updatedHoursStr;
    if (updatedHours === 0) {
      updatedHoursStr = "00";
    } else {
      updatedHoursStr = updatedHours < 10 ? String(updatedHours) : String(updatedHours);
    }
    const updatedMinutesStr = String(updatedMinutes).padStart(2, "0");
    const updatedTime = `${updatedHoursStr}:${updatedMinutesStr}`;

    return updatedTime;
  }
  // dayjs(item.date, "YYYY-MM-DD")

  const handleSubmitForm = async (values) => {
    try {
      setSubmitFormLoader(true);

      let removedExtraFields = values.service_requests?.map((day) => ({
        ...day,
        services: day?.services?.map((service) => {
          const { timeBlockList, timeSlotList, ...remainingService } = service;
          return {
            ...remainingService,
            start_time: !remainingService.staff ? null : remainingService.start_time,
            end_time: !remainingService.staff ? null : remainingService.end_time,
          };
        }),
      }));

      let objSend = {
        ...values,
        service_requests: removedExtraFields,
      };

      const { data } = await main_api.post(companyAPIsEndPoints.POST_SCHEDULER_LIST, objSend);

      setSubmitFormLoader(false);
      if (data.isSuccess) {
        pushNotification("Trip Scheduled Successfully!.", "success");
        requiredDropdowns?.fetchAllSchedulerService();
        closeAll();
      }
    } catch (error) {
      setSubmitFormLoader(false);
      if (error.response?.data?.message) {
        pushNotification("Please Fill All Fields", "error");
      }
      console.log("error :", error);
    }
  };

  // Define the schema for each service object
  const serviceSchema = Yup.object().shape({
    service: Yup.number().integer().required("Service is required"),
    // start_time: Yup.string().required("Start time is required"),
    start_time: Yup.string().when("staff", {
      is: (staff) => staff !== null && staff !== undefined && staff !== "",
      then: Yup.string().required("Start time is required"),
      otherwise: Yup.string(),
    }),

    pets: Yup.array().of(Yup.number().integer()).required("Pets array is required"),

    time_block: Yup.number().integer().required("Time block is required"),
    // tasks: Yup.array().of(taskSchema).required("Tasks array is required"),
  });

  // Define the schema for each service request object
  const serviceRequestSchema = Yup.object().shape({
    date: Yup.date().required("Date is required"),
    services: Yup.array().of(serviceSchema).required("Services array is required"),
  });

  // Define the main schema
  const schema = Yup.object().shape({
    client: Yup.number().integer().required("Client ID is required"),
    note: Yup.string().nullable(),
    company: Yup.number().integer().required("Company ID is required"),
    scheduled_by: Yup.string().required("Scheduled by is required"),
    service_requests: Yup.array().of(serviceRequestSchema).required("Service requests are required"),
  });

  const makeTimeSlots = (e) => {
    const filterTimeBlock =
      requiredDropdowns.timeBlockCompList?.filter((items) => {
        return items.id === e;
      })[0] || [];

    let startTime = filterTimeBlock?.start_time;
    let endTime = filterTimeBlock?.end_time;

    // TIME SLOT FROM TIME BLOCK
    let slots = generateAvailableTimeSlots(startTime || "", endTime || "");

    return slots;
  };

  return (
    <>
      <Formik
        initialValues={{
          client: tempFormDetails?.client,
          note: tempFormDetails?.note,
          company: company_id,
          scheduled_by: "company",
          service_requests: [...initialValues],
        }}
        innerRef={formikRefSecondStep}
        onSubmit={handleSubmitForm}
        enableReinitialize
        validationSchema={schema}
      >
        {({ handleSubmit, setFieldValue, values, errors }) => (
          <Form onSubmit={handleSubmit} className="w-100">
            <FieldArray name="service_requests">
              {({ push, remove }) => (
                <Row>
                  {values.service_requests.map((item, index) => (
                    <Collapse className="request-service-collapse w-100" key={index} bordered={false} defaultActiveKey={["0"]}>
                      <Collapse.Panel
                        header={<DateHeading>{dayjs(item.date, "YYYY-MM-DD").format("DD MMMM, YYYY")}</DateHeading>}
                        key={index}
                      >
                        <FieldArray name={`service_requests.${index}.services`}>
                          {({ push: pushService, remove: removeService }) => (
                            <>
                              {item.services.map((items, serviceIndex) => (
                                <div key={serviceIndex}>
                                  <div className="d-flex flex-wrap" key={serviceIndex}>
                                    <Col md={10}>
                                      <FormikSelectInput
                                        label="Service"
                                        placeholder="Select Service"
                                        name={`service_requests.${index}.services.${serviceIndex}.service`}
                                        type="text"
                                        className="w-100"
                                        value={values.service_requests[index].services[serviceIndex].service || "Select service"}
                                        autoComplete="new-password"
                                        options={requiredDropdowns?.serviceState}
                                        onChange={(e) => {
                                          setFieldValue(`service_requests.${index}.services.${serviceIndex}.end_time`, "");
                                          setFieldValue(`service_requests.${index}.services.${serviceIndex}.timeBlockList`, "");
                                          setFieldValue(`service_requests.${index}.services.${serviceIndex}.timeSlotList`, "");
                                          setFieldValue(`service_requests.${index}.services.${serviceIndex}.start_time`, "");
                                          setFieldValue(`service_requests.${index}.services.${serviceIndex}.time_block`, "");

                                          const filterService =
                                            requiredDropdowns?.allServices?.responseData?.result?.find(
                                              (items) => items.id === e,
                                            ) || [];

                                          const timeBlocks = filterService?.time_blocks || [];

                                          const filteredTimeBlocks =
                                            requiredDropdowns?.blockTimeDropDown?.filter((item) => {
                                              return timeBlocks.includes(item.value);
                                            }) || [];

                                          setFieldValue(
                                            `service_requests.${index}.services.${serviceIndex}.timeBlockList`,
                                            filteredTimeBlocks,
                                          );

                                          const initialTime =
                                            values?.service_requests[index]?.services?.[serviceIndex]?.start_time || "00:00";
                                          const updatedTime = calculateUpdatedTime(initialTime, filterService?.default_duration);
                                          setFieldValue(
                                            `service_requests.${index}.services.${serviceIndex}.end_time`,
                                            updatedTime,
                                          );
                                          setFieldValue(`service_requests.${index}.services.${serviceIndex}.service`, e);
                                        }}
                                        width="100%"
                                        noBr
                                      />
                                    </Col>
                                    {/* SELECT TIEMBLOCK */}
                                    <Col md={10} className="ms-2">
                                      <FormikSelectInput
                                        label="Time Block"
                                        placeholder="Select Time Block"
                                        // defaultValue={"Select time block"}
                                        // value={
                                        //   values.service_requests[index].services[serviceIndex].time_block || "Select service"
                                        // }

                                        {...(values.service_requests[index].services[serviceIndex].time_block
                                          ? {
                                              value:
                                                values.service_requests[index].services[serviceIndex].time_block ||
                                                "Select service",
                                            }
                                          : { defaultValue: requiredDropdowns?.time_block?.id || "Select time block" })}
                                        name={`service_requests.${index}.services.${serviceIndex}.time_block`}
                                        type="text"
                                        className="w-100 mb-2"
                                        autoComplete="new-password"
                                        allowClear
                                        // options={requiredDropdowns?.blockTimeDropDown}
                                        options={values.service_requests[index]?.services[serviceIndex]?.timeBlockList}
                                        onChange={(e) => {
                                          setFieldValue(`service_requests.${index}.services.${serviceIndex}.end_time`, "");
                                          setFieldValue(`service_requests.${index}.services.${serviceIndex}.start_time`, "");
                                          let timeSlots = makeTimeSlots(e);

                                          setFieldValue(`service_requests.${index}.services.${serviceIndex}.time_block`, e);
                                          setFieldValue(
                                            `service_requests.${index}.services.${serviceIndex}.timeSlotList`,
                                            timeSlots,
                                          );
                                        }}
                                        width="100%"
                                        noBr
                                        // disabled={!values.extra_pet_enabled}
                                      />
                                    </Col>
                                    {/* SELECT SLOT */}
                                    <Col md={10} className="me-2">
                                      <FormikSelectInput
                                        // options={Hours24Time}

                                        options={values?.service_requests[index]?.services?.[serviceIndex]?.timeSlotList}
                                        placeholder="Select Slot"
                                        className=" w-100"
                                        selectClassName="w-100"
                                        label="Select Slot"
                                        defaultValue={"Select Slot"}
                                        name={`service_requests.${index}.services.${serviceIndex}.start_time`}
                                        value={convertTo12HourFormat(items.start_time)}
                                        onChange={(e) => {
                                          setFieldValue(`service_requests.${index}.services.${serviceIndex}.start_time`, "");
                                          let startTime = convertTo24HourFormat(e);
                                          if (values?.service_requests[index]?.services?.[serviceIndex]?.service) {
                                            const filterService =
                                              requiredDropdowns?.allServices?.responseData?.result?.find(
                                                (items) =>
                                                  items.id === values?.service_requests[index]?.services?.[serviceIndex]?.service,
                                              ) || [];
                                            const initialTime = startTime || "00:00";

                                            const updatedTime = calculateUpdatedTime(
                                              initialTime,
                                              filterService?.default_duration,
                                            );
                                            // console.log("initialTime", initialTime);
                                            setFieldValue(
                                              `service_requests.${index}.services.${serviceIndex}.end_time`,
                                              updatedTime,
                                            );
                                          }
                                          setFieldValue(
                                            `service_requests.${index}.services.${serviceIndex}.start_time`,
                                            startTime,
                                          );
                                        }}
                                        disabled={!values.service_requests[index].services[serviceIndex].staff}
                                      />
                                    </Col>

                                    <Col md={10}>
                                      <FormikSelectInput
                                        options={petsAccordingtoClient}
                                        className="w-100"
                                        selectClassName="w-100"
                                        label="Select Pets"
                                        mode="multiple"
                                        value={values.service_requests[index].services[serviceIndex].pets || "Select Pets"}
                                        name={`service_requests.${index}.services.${serviceIndex}.pets`}
                                        placeholder="Select Pets"
                                        onChange={(value) => {
                                          setFieldValue(`service_requests.${index}.services.${serviceIndex}.pets`, value);
                                        }}
                                      />
                                    </Col>
                                    <Col md={10}>
                                      <FormikSelectInput
                                        mode="tags"
                                        label="Tasks"
                                        notFoundContent={null}
                                        style={{ width: "100%", maxHeight: "50px", overflow: "auto" }}
                                        placeholder="Enter tasks"
                                        value={values.service_requests[index].services[serviceIndex]?.tasks?.map(
                                          (items) => items.name,
                                        )}
                                        name={`service_requests.${index}.services.${serviceIndex}.tasks`}
                                        onChange={(option) => {
                                          const tags = option?.map((items) => {
                                            return {
                                              name: items,
                                              company: company_id,
                                            };
                                          });

                                          setFieldValue(`service_requests.${index}.services.${serviceIndex}.tasks`, tags);
                                        }}
                                        // value={values?.service_requests[0]?.services?.[0]?.tasks}
                                        className=" w-100"
                                      />
                                    </Col>
                                  </div>

                                  <Col md={24} className="mt-3">
                                    <div className={`d-flex align-items-center my-2 mt-3`}>
                                      <button
                                        type="button"
                                        className={`mt-2 fw-bolder align-items-center d-flex`}
                                        onClick={() =>
                                          pushService({
                                            service: "",
                                            start_time: "",
                                            end_time: "",
                                            pets: [],
                                            staff: "",
                                            tasks: [],
                                            time_block: "",
                                          })
                                        }
                                        // disabled={values.service_requests[index].services?.length === 4}
                                      >
                                        <div
                                          style={{
                                            width: "13px",
                                            height: "13px",
                                            background: "rgb(251, 207, 52)",
                                            borderRadius: "50%",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            padding: "2px",
                                          }}
                                        >
                                          <PlusYellowIcon />
                                        </div>
                                        <span style={{ fontSize: "12px", marginTop: "1px" }} className="ms-2">
                                          Add More
                                        </span>
                                      </button>

                                      <button
                                        type="button"
                                        className="mt-2 fw-bolder d-flex align-items-center"
                                        onClick={() => removeService(serviceIndex)}
                                        disabled={values.service_requests[index].services?.length === 1}
                                      >
                                        <div
                                          style={{
                                            borderRadius: "50%",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "baseline",
                                            padding: "2px",
                                          }}
                                        >
                                          <DeleteIcon height={"14px"} width="14px" className="mb-1" />
                                        </div>
                                        <span style={{ fontSize: "12px" }} className="ms-2">
                                          Remove
                                        </span>
                                      </button>
                                    </div>
                                  </Col>
                                </div>
                              ))}
                            </>
                          )}
                        </FieldArray>
                      </Collapse.Panel>
                    </Collapse>
                  ))}
                </Row>
              )}
            </FieldArray>
          </Form>
        )}
      </Formik>
    </>
  );
};

const DateHeading = styled.div`
  font-weight: 700;
  font-size: 16px;
`;
