import { Checkbox, Col, Drawer, Row, Segmented, Select, Radio, TimePicker, DatePicker, Divider } from "antd";
import React, { useEffect, useState } from "react";
import locale from "antd/es/date-picker/locale/de_DE";
import { Formik, Field, Form, FieldArray, useFormikContext } from "formik";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
//

import HeadingComponent from "../../../../../../components/Heading";

//
import { DeleteIcon } from "../../../../../../assets/rawSvgs/index";
import { ReactComponent as PlusYellowIcon } from "../../../../../../assets/rawSvgs/yelowplus.svg";

import { pushNotification } from "../../../../../../util/Notification";
import { DifferntTimeEachDayForm } from "./UpdateDiffDay";
import { daysArray } from "../AvailabilityComponent";
import { staffAPIsEndPoints } from "../../../../../../constants/ApiEndPoints";
import { main_api } from "../../../../../../api/axios_helper";
import { validationSchemaSameDay, validationSchemaSameDayv2 } from "../helpers";
import moment from "moment";
import { convertTo12HourFormat } from "../../../../../../util/formatTime";
import { RequiredSignComponent } from "../../../addNewStaffModal";

const { RangePicker } = DatePicker;

const ConfigureStaffAvailComponent = ({
  handleCloseModal,
  updateForm,
  onSuccess,
  formikRefSecondStep,
  updateConfivalues,
  staffId,
  setSubmitFormLoader,
}) => {
  const [SelectTab, setSelectTab] = React.useState();

  const [dateValidationError, setdateValidationError] = useState(false);

  useEffect(() => {
    setSelectTab(updateConfivalues?.same_time ? "Same time for each day" : "Different time for each day");
  }, [updateConfivalues?.same_time]);

  const handleSubmit = async (values) => {
    if (!values?.forever) {
      if (validateDate(values?.selectedDays, values.start_date, values.end_date)) return;
    }

    if (SelectTab === "Different time for each day") {
      handleUpdateDifferntDaysTab(values);
      return;
    }

    const { days_time, selectedDays, ...rest } = values;

    if (selectedDays?.length === 0) {
      values.days_time = [];
    }

    let availabilities = [];
    if (rest.forever) {
      rest["start_date"] = null;
      rest["end_date"] = null;
    }
    selectedDays.forEach((day) => {
      availabilities[day] = days_time.map((items) => {
        return {
          end_time: moment(items.end_time, "hh:mm A").format("HH:mm"),
          start_time: moment(items.start_time, "hh:mm A").format("HH:mm"),
        };
      });
    });

    try {
      setSubmitFormLoader(true);

      let objSend = {
        staff: staffId,
        availabilities: { ...availabilities },
        same_time: SelectTab === "Different time for each day" ? false : true,
        ...rest,
      };

      const { data } = await main_api.post("/api/v1/staff_availability/update_availabilities/", objSend);
      if (data.isSuccess) {
        pushNotification("Staff Configuration updated successfully.");
        onSuccess();
        handleCloseModal();
      }
      setSubmitFormLoader(false);
    } catch (error) {
      setSubmitFormLoader(false);

      if (!rest.forever && error?.response?.data.error) {
        pushNotification("End time must be greater than current time", "error");
      }
      if (error?.response?.data.error) {
        pushNotification(error?.response?.data?.error, "error");
      }
    }
  };
  const handleUpdateDifferntDaysTab = async (values) => {
    const { days, selectedDays, ...rest } = values;

    try {
      setSubmitFormLoader(true);
      if (validateDate(selectedDays)) return;

      const convertedTimes = Object.keys(days).reduce((result, key) => {
        const items = days[key];
        const convertedItems = items.map((item) => ({
          start_time: moment(item.start_time, "hh:mm A").format("HH:mm"),
          end_time: moment(item.end_time, "hh:mm A").format("HH:mm"),
        }));
        result[key] = convertedItems;
        return result;
      }, {});

      if (rest.forever) {
        const today = dayjs();
        rest["start_date"] = null;
        rest["end_date"] = null;
      }

      const objSend = {
        staff: staffId,
        availabilities: { ...convertedTimes },
        ...rest,
        same_time: SelectTab === "Different time for each day" ? false : true,
      };

      const { data } = await main_api.post("/api/v1/staff_availability/update_availabilities/", objSend);
      if (data.isSuccess) {
        pushNotification("Staff Configuration updated successfully.");
        onSuccess();
        handleCloseModal();
      }

      setSubmitFormLoader(false);
    } catch (error) {
      setSubmitFormLoader(false);

      if (!rest.forever && error?.response?.data.error) {
        pushNotification("End time must be greater than current time", "error");
      }
      if (error?.response?.data.error) {
        pushNotification(error?.response?.data?.error, "error");
      }
    }
  };

  const validateDate = (selcteddays, startDate, endDate) => {
    const recurringDays = selcteddays;

    if (startDate && endDate) {
      if (recurringDays.length) {
        const exists = checkDaysInRange(startDate, endDate, recurringDays);

        if (!exists) {
          setdateValidationError(true);
          return true;
        }
        setdateValidationError(false);
        return false;
      }
    }
  };
  function checkDaysInRange(start_date, end_date, daysArray) {
    const startDate = new Date(start_date);
    const endDate = new Date(end_date);

    // Loop through the daysArray
    for (let i = 0; i < daysArray.length; i++) {
      const day = daysArray[i];
      const currentDate = new Date(startDate);

      // Set the currentDate to the next occurrence of the day in the future
      currentDate.setDate(currentDate.getDate() + ((day + 7 - currentDate.getDay()) % 7));

      // Check if the currentDate is within the range of start_date and end_date
      if (currentDate >= startDate && currentDate <= endDate) {
        return true;
      } else {
        return false;
      }
    }
  }

  const dateFormat = "YYYY-MM-DD";
  const { end_date, start_date, forever, same_time, ...rest } = updateConfivalues;

  const daysKeys = Object.keys(rest).map(Number);

  function extractSameTimesFromIndex(data) {
    const extractedObjects = [];
    const seenTimes = {};

    for (const key in data) {
      const indexArray = data[key];
      indexArray.forEach((obj) => {
        const { start_time, end_time } = obj;
        const timeKey = `${start_time}-${end_time}`;

        if (!seenTimes[timeKey]) {
          seenTimes[timeKey] = true;
          extractedObjects.push(obj);
        }
      });
    }

    return extractedObjects?.map((items) => {
      return {
        start_time: dayjs(convertTo12HourFormat(items.start_time), "h:mm A"),
        end_time: dayjs(convertTo12HourFormat(items.end_time), "h:mm A"),
      };
    });
  }

  const extractedObjects = extractSameTimesFromIndex(rest);

  let objday = extractedObjects.map((items) => {
    return {
      start_time: dayjs(items.start_time, "ddd, DD MMM YYYY HH:mm:ss [GMT]").format("h:mm a"),
      end_time: dayjs(items.end_time, "ddd, DD MMM YYYY HH:mm:ss [GMT]").format("h:mm a"),
    };
  });

  const is1990 = (date) => dayjs(date).year() === 1990;

  let isForever = start_date && end_date ? is1990(start_date) && is1990(end_date) : true;

  return (
    <div>
      <div className="my-2">
        <Segmented
          style={{ marginBottom: 8 }}
          onChange={(value) => {
            setSelectTab(value);
            setdateValidationError(false);
          }}
          options={["Same time for each day", "Different time for each day"]}
          className=" p-1 "
          value={SelectTab}
          size="medium"
        />
      </div>
      {SelectTab === "Same time for each day" && (
        <Formik
          initialValues={{
            selectedDays: updateConfivalues?.same_time ? daysKeys : [],
            forever: isForever,
            days_time: updateConfivalues?.same_time ? objday : [],
            start_date: start_date && !isForever ? start_date : dayjs().format("YYYY-MM-DD"),
            end_date: start_date && !isForever ? end_date : dayjs().format("YYYY-MM-DD"),
          }}
          validationSchema={validationSchemaSameDayv2}
          onSubmit={handleSubmit}
          enableReinitialize
          innerRef={formikRefSecondStep}
        >
          {({ values, setFieldValue, errors }) => (
            <Form>
              <>
                <HeadingComponent text="Select Day & Time of the Week" fontSize={"0.75rem"} margin="5px 0px" />
                <FieldArray
                  name="days_time"
                  render={(arrayHelpers) => (
                    <div>
                      <div className="d-flex  flex-wrap my-2">
                        <Checkbox.Group
                          options={daysArray.map((items) => {
                            return {
                              label: items.label.slice(0, 3),
                              value: items.value,
                            };
                          })}
                          onChange={(e) => {
                            setFieldValue("selectedDays", e);

                            if (values.selectedDays?.length === 0 && values?.days_time?.length > 0) return;
                            if (values.selectedDays?.length === 0) {
                              setFieldValue("days_time", [...values.days_time, { start_time: null, end_time: null }]);
                            }
                          }}
                          defaultValue={values.selectedDays}
                        />
                      </div>
                      {errors?.selectedDays && <div className="input-error-style">{errors?.selectedDays}</div>}
                      {values?.days_time?.map((time, index) => (
                        <div key={index} className={`${values.selectedDays?.length === 0 ? "d-none" : "d-block"}`}>
                          <Row align={"bottom"}>
                            <Col lg={10} style={{ position: "relative" }}>
                              <div className="d-flex">
                                <RequiredSignComponent />
                                <HeadingComponent text="Start Time" fontSize={"12px"} margin="7px 0px" />
                              </div>

                              <TimePicker
                                {...(time?.start_time !== null
                                  ? {
                                      value: dayjs(time?.start_time, "h:mm a"),
                                    }
                                  : { defaultOpenValue: moment().hour(0).minute(0) })}
                                onChange={(time, timeString) => {
                                  setFieldValue(`days_time.${[index]}.start_time`, timeString);
                                }}
                                format="h:mm a"
                                className="w-100"
                                placeholder="Select Start Time"
                                allowClear={false}
                                use12Hours
                              />
                              {errors?.days_time?.[index]?.start_time && (
                                <div className="input-error-style" style={{ position: "absolute", top: "100%", left: 0 }}>
                                  {errors?.days_time?.[index]?.start_time}
                                </div>
                              )}
                            </Col>
                            <Col md={2} className="d-flex justify-content-center">
                              <span>-</span>
                            </Col>

                            <Col md={10} className="d-flex justify-content-end flex-column" style={{ position: "relative" }}>
                              <div className="d-flex">
                                <RequiredSignComponent />
                                <HeadingComponent text="End Time" fontSize={"12px"} margin="7px 0px" />
                              </div>
                              <TimePicker
                                {...(time?.end_time !== null
                                  ? {
                                      value: dayjs(time?.end_time, "h:mm a"),
                                    }
                                  : { defaultOpenValue: moment().hour(0).minute(0) })}
                                onChange={(time, timeString) => {
                                  setFieldValue(`days_time.${[index]}.end_time`, timeString);
                                }}
                                format="h:mm a"
                                allowClear={false}
                                className="w-100"
                                placeholder="Select End Time"
                              />
                              {errors?.days_time?.[index]?.end_time && (
                                <div className="input-error-style" style={{ position: "absolute", top: "100%", left: 0 }}>
                                  {errors?.days_time?.[index]?.end_time}
                                </div>
                              )}
                            </Col>
                          </Row>

                          <div className={`d-flex align-items-center my-2 mt-3`}>
                            {index === values?.days_time?.length - 1 && (
                              <button
                                type="button"
                                className={`mt-2 fw-bolder align-items-center d-flex`}
                                onClick={() => arrayHelpers.push({ start_time: null, end_time: null })}
                                disabled={values?.days_time?.length === 4}
                              >
                                <div
                                  style={{
                                    width: "13px",
                                    height: "13px",
                                    background: "rgb(251, 207, 52)",
                                    borderRadius: "50%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    padding: "2px",
                                  }}
                                >
                                  <PlusYellowIcon />
                                </div>
                                <span style={{ fontSize: "12px", marginTop: "1px" }} className="ms-2">
                                  Add More
                                </span>
                              </button>
                            )}

                            <button
                              type="button"
                              className="mt-2 fw-bolder d-flex align-items-center"
                              onClick={() => arrayHelpers.remove(index)}
                              disabled={values?.days_time?.length === 1}
                            >
                              <div
                                style={{
                                  borderRadius: "50%",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "baseline",
                                  padding: "2px",
                                }}
                              >
                                <DeleteIcon height={"14px"} width="14px" className="mb-1" />
                              </div>
                              <span style={{ fontSize: "12px" }} className="ms-2">
                                Remove
                              </span>
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                />
                <Divider />
                <div className="my-2 mt-3 ">
                  <HeadingComponent text="Set this availability for" fontSize={"0.75rem"} margin="5px 0px" />
                  <Radio.Group
                    defaultValue={values.forever}
                    onChange={(e) => {
                      if (e.target.value) {
                        setFieldValue("start_date", dayjs().format("YYYY-MM-DD"));
                        setFieldValue("end_date", dayjs().format("YYYY-MM-DD"));
                      }
                      setFieldValue("forever", e.target.value);
                    }}
                  >
                    <Radio value={true}>Forever</Radio>
                    <Radio value={false}>Temporary</Radio>
                  </Radio.Group>
                </div>

                {!values.forever && (
                  <Row align={"bottom"}>
                    <Col md={24}>
                      <RangePicker
                        onChange={(e, dateString, extra) => {
                          const dayid = dayjs(dateString, dateFormat);

                          if (dayid) {
                            setFieldValue("start_date", dateString[0]);
                            setFieldValue("end_date", dateString[1]);
                          }
                        }}
                        format="YYYY-MM-DD"
                        placeholder={["Start Date", "End Date"]}
                        label="When"
                        name="dates"
                        type="text"
                        {...(updateForm
                          ? {
                              defaultValue: [dayjs(values?.start_date, dateFormat), dayjs(values?.end_date, dateFormat)],
                            }
                          : {})}
                        className=" mb-2 w-100"
                        // value={values?.holiday_date}
                      />
                      {(errors?.end_date || errors?.start_date) && (
                        <div className="input-error-style">Please select start and end date</div>
                      )}
                      {dateValidationError && (
                        <div className="input-error-style">Recurring days must be in the specified date range</div>
                      )}
                    </Col>
                  </Row>
                )}
              </>

              {/* Diff time each day frm */}
            </Form>
          )}
        </Formik>
      )}
      {SelectTab === "Different time for each day" && (
        <>
          <DifferntTimeEachDayForm
            handleSubmit={handleSubmit}
            innerRef={formikRefSecondStep}
            dateValidationError={dateValidationError}
            updateConfivalues={updateConfivalues}
            updateForm={true}
          />
        </>
      )}
    </div>
  );
};

export default ConfigureStaffAvailComponent;
