import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { AntdesignTable } from "../../../../components/antDesignTable/AntdesignTable";
import SearchInput from "../../../../components/SearchInput";
import { scheduleRequestColumens, schedulerRequest } from "../../../../constants/CompanyTableHeaders/SchedulerTableHeaders";

import { ReactComponent as DropDownIcon } from "../../../../assets/rawSvgs/selectDropDownIcon.svg";

import { main_api } from "../../../../api/axios_helper";
import { companyAPIsEndPoints } from "../../../../constants/ApiEndPoints";
import { useSelector } from "react-redux";

import HeadingComponent from "../../../../components/Heading";

import AntDesignModal from "../../../../components/Modals/AntDesignModal/AntDesignModal";
import { pushNotification } from "../../../../util/Notification";
import { ClientSchedulerListColumens } from "../../../../constants/ClientTableHeader/schedulerListHeader";
import DeleteConfirmationRowModal from "../../../../components/Modals/DeleteConfirmationRowModal";

import { replaceDateFormat } from "../../../../util/formatDate";
import { Tag, Space } from "antd";

import { ReactComponent as DeleteIcon } from "../../../../assets/rawSvgs/tableDeleteIcon.svg";
import { ReactComponent as TickIcon } from "../../../../assets/rawSvgs/tickIcon.svg";
import { schedulerBackground, schedulerColor, schedulerStatus } from "../../../../components/SchedulerCalender/helpers";
import CustomBtnWithIcon from "../../../../components/buttonWithIcon";
import { AddNewEventModal } from "../SchedulerEvents";
import { convertTo12HourFormat } from "../../../../util/formatTime";
import dayjs from "dayjs";
import { checkPermission } from "../../../../util/commonUtil";
import PermissionModal from "../../../../components/Modals/PermissionModal";
import OneDayResourcesVerticalCalender from "../../../../components/CompanyOneDayVerticalScheduler/Index";
import { tableCols } from "./PendingRequestCalender";
import moment from "moment";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { convertTo24HourFormat, dateFormatEndDate, dateFormatStartDate } from "./utilsFunctions";
import NoDataScreen from "../../../../components/NoDataScreen";
//
const localizer = momentLocalizer(moment);
//
const EditReqestCompoennt = ({ allStates, staffState, fetchAllSchedulerService }) => {
  const [editReqData, setEditReqData] = React.useState([]);
  const [apiLoader, setApiLoader] = React.useState([]);
  const [addNewEventSlotModal, setAddNewEventSlotModall] = useState({ details: "", condition: false });
  const statePermision = useSelector((state) => state.permissionManagement);
  const compId = useSelector((state) => state.auth.loginResponse.selectCompany);

  const permissionManagement = useSelector((state) => state.permissionManagement);

  const [deleteEvent, setDeleteEvent] = useState(null);
  const [viewCalender, setViewCalender] = useState(false);
  const [permissionPopUp, setPermissionPopUp] = useState(false);

  const expandedRowRender = (record) => (
    <div>
      {" "}
      <EditRequest
        staffState={staffState}
        calenderData={record}
        permissionManagement={permissionManagement}
        setPermissionPopUp={setPermissionPopUp}
        viewCalender={viewCalender}
        handleFetchAllSchedulerServices={handleFetchAllSchedulerServices}
      />
    </div>
  );
  useEffect(() => {
    handleFetchAllSchedulerServices();
  }, []);

  const handleFetchAllSchedulerServices = async () => {
    try {
      setApiLoader(false);
      if (checkPermission(permissionManagement.permissions, "view_service_requests")) {
        setApiLoader(true);
        const { data } = await main_api.get(
          `/api/v1/service_request/?status=change_requested&service__company=${compId?.[0]?.company_id}`,
        );
        setApiLoader(false);

        setEditReqData(data.result);
      }
    } catch (error) {
      setApiLoader(false);
    }
  };

  const handleEditRow = (row) => {
    const dateTime = dayjs(row.date);
    const formattedDate = dateTime.format("YYYY-MM-DD");

    setAddNewEventSlotModall({
      details: {
        staffId: row?.staff?.id || "",
        slotTime: convertTo12HourFormat(row.start_time) || dayjs().format("hh:mm a"),
        date: formattedDate,
        serviceID: row?.id,
        title: row?.service?.name,
      },
      condition: true,
    });
  };

  const handleDelete = (service) => {
    if (checkPermission(permissionManagement.permissions, "delete_service_request")) {
      setDeleteEvent(service);
    } else {
      setPermissionPopUp(true);
    }
  };

  const deleteService = () => {
    main_api
      .delete(companyAPIsEndPoints.DELETE_SERVICE_REQUEST(deleteEvent.id))
      .then(({ data }) => {
        setDeleteEvent(null);
        handleFetchAllSchedulerServices();
        pushNotification("Service Deleted Successfully!", "success");
      })
      .catch((error) => {
        setDeleteEvent(null);
        pushNotification("Something went Wrong!", "error");
      });
  };

  const handleApprovedBtn = async (row) => {
    try {
      const { data } = await main_api.delete(`/api/v1/service_request/${row.id}/`);

      if (data.isSuccess) {
        pushNotification("Service request approved!");
        handleFetchAllSchedulerServices();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const expandIcon = ({ expanded, onExpand, record }) => {
    if (record.delete_request) {
      return null; // Hide expand button if record is deleted
    }
    return expanded ? (
      <ExpandableTableIcon
        expanded="180deg"
        onClick={(e) => {
          if (checkPermission(statePermision.permissions, "view_service_request")) {
            onExpand(record, e);
          } else {
            setPermissionPopUp(true);
          }
        }}
      >
        <DropDownIcon />
      </ExpandableTableIcon>
    ) : (
      <ExpandableTableIcon
        expanded="360deg"
        onClick={(e) => {
          if (checkPermission(statePermision.permissions, "view_service_request")) {
            onExpand(record, e);
            setViewCalender(true);
          } else {
            setPermissionPopUp(true);
          }
        }}
      >
        <DropDownIcon
          onClick={(e) => {
            if (checkPermission(statePermision.permissions, "view_service_request")) {
              onExpand(record, e);
              setViewCalender(false);
            } else {
              setPermissionPopUp(true);
            }
          }}
        />
      </ExpandableTableIcon>
    );
  };

  return (
    <div>
      {editReqData?.length === 0 ? (
        <NoDataScreen heading="You don’t have any change requested services in your list" showBtn={false} />
      ) : (
        <>
          <AntdesignTable
            columns={editReqColumens({ handleDelete, handleEditRow, handleApprovedBtn })}
            data={editReqData}
            rowKey="id"
            allowMultieSelectRows={false}
            loading={apiLoader}
            expandable={{ expandedRowRender, expandIcon }}
            // selectedRows={handleSelectDeleteMultiple}
          />
        </>
      )}
      {addNewEventSlotModal.condition && (
        <AddNewEventModal
          clientPetServiceDropdowns={allStates}
          addNewEventModal={addNewEventSlotModal.condition}
          updateValues={addNewEventSlotModal.details}
          oneDayVerticalFrom={true}
          // updateForm={true}
          closeModal={() => setAddNewEventSlotModall({ ...addNewEventSlotModal, condition: false })}
        />
      )}

      <DeleteConfirmationRowModal
        onclose={() => setDeleteEvent(null)}
        handleConfirmDeleteRows={deleteService}
        openConfimrationDeleteModal={deleteEvent ? true : false}
      />
      <PermissionModal onclose={() => setPermissionPopUp(false)} openModal={permissionPopUp} />
    </div>
  );
};

export default EditReqestCompoennt;

const editReqColumens = ({ handleDelete, handleEditRow, handleApprovedBtn }) => {
  let columns = [
    {
      title: "Service",
      dataIndex: "service",
      key: "service",

      render: (text, record) => (
        <>{record.service && record.service.name ? <Tag style={tagStyle}>{record.service.name}</Tag> : ""}</>
      ),
    },
    {
      title: "Dates",
      dataIndex: "date",
      key: "date",

      render: (text, record) => <>{record?.date && replaceDateFormat(record?.date)}</>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => (
        <>
          <Tag style={{ ...tagStyle, backgroundColor: schedulerBackground(record), color: schedulerColor(record) }}>
            {record.delete_request ? "Delete Requested" : schedulerStatus(record)}
          </Tag>
        </>
      ),
    },
    {
      title: "",
      key: "action",
      render: (_, record) => (
        <Space size="middle" className="d-flex justify-content-end">
          {record.delete_request && (
            <Button
              onClick={(event) => {
                event.stopPropagation();
                handleApprovedBtn(record);
              }}
            >
              <TickIcon /> &nbsp; Accept
            </Button>
          )}
          <Button
            onClick={(event) => {
              event.stopPropagation();
              handleDelete(record);
            }}
          >
            <DeleteIcon />
          </Button>
        </Space>
      ),
    },
  ];

  return columns;
};

const EditRequest = ({
  calenderData,
  staffState,
  permissionManagement,
  setPermissionPopUp,
  viewCalender,
  handleFetchAllSchedulerServices,
}) => {
  const [minTimeState, setMinTimeState] = useState("");
  const [maxTimeState, setMaxTimeState] = useState("");
  const [staffIdFromCalender, setStaffIdFromCalender] = useState("");
  const [serviceASignedModel, setServiceASignedModel] = useState(false);
  const [apiLoader, setApiLoader] = React.useState(false);

  const [calenderEventsData, setcalenderEventsData] = React.useState([]);
  const [collectServiceFromTable, setCollectServiceFromTable] = useState([]);
  const compId = useSelector((state) => state.auth.loginResponse.selectCompany);

  // STATES //

  useEffect(() => {
    checkMinMaxTime();
    handleFetchDateWiseService();
  }, [calenderData]);

  const handleFetchDateWiseService = async () => {
    try {
      setApiLoader(true);

      const { data } = await main_api.get(
        `/api/v1/service_request/?service__company=${compId?.[0]?.company_id}&status=scheduled&status=in_progress&start_date=${calenderData?.date}&end_date=${calenderData?.date}`,
      );

      // get schee
      const calenderMappedData = data.result
        ?.filter((items) => items.status === "scheduled")
        ?.map((items) => {
          return {
            id: items.id,
            title: items?.service.name,
            start: dateFormatStartDate(items?.start_time, items.date),
            end: dateFormatEndDate(items?.end_time, items.date),
            resourceId: items?.staff.id,
            clientName: items?.client.name,
            status: items.status,
          };
        });

      setcalenderEventsData(calenderMappedData);

      setApiLoader(false);
    } catch (error) {
      setApiLoader(false);
    }
  };

  function checkMinMaxTime(data) {
    if (calenderData?.time_block.name === "AnyTime") return;
    const timeToMinutes = (timeString) => {
      const [hours, minutes] = timeString.split(":").map(Number);
      return hours * 60 + minutes;
    };

    const startTime = timeToMinutes(calenderData?.time_block.start_time || "00:00:00");
    const endTime = timeToMinutes(calenderData?.time_block.end_time || "23:00:00");

    let minTime = Infinity; // Initialize with a very large value
    let maxTime = 0; // Initialize with 0

    if (startTime < minTime) {
      minTime = startTime;
    }

    if (endTime > maxTime) {
      maxTime = endTime;
    }
    // Convert minutes back to time strings
    const formatTime = (minutes) => {
      const hours = Math.floor(minutes / 60);
      const mins = minutes % 60;
      return `${hours.toString().padStart(2, "0")}:${mins.toString().padStart(2, "0")}:00`;
    };
    if (minTime !== 0) {
      minTime -= 60; // Subtract 60 minutes (1 hour) from minTime
    }
    if (maxTime !== 1380) {
      maxTime += 60; // Add 60 minutes (1 hour) to maxTime
    }
    // Convert minimum and maximum times back to time strings
    minTime = formatTime(minTime);
    maxTime = formatTime(maxTime);

    setMinTimeState(minTime);
    setMaxTimeState(maxTime);
  }

  const handleSelectSlot = (slotInfo) => {
    if (checkPermission(permissionManagement.permissions, "assign_service_request_staff")) {
      setStaffIdFromCalender(slotInfo.resourceId);

      setServiceASignedModel(true);
    } else {
      setPermissionPopUp(true);
    }
  };

  const handleSelectServToAssign = (rowData) => {
    let checkIfNotAddedTime = false;
  };

  const convertTo24HourFormatv2 = (time12h) => {
    const parsedTime = moment(time12h, "h:mm A");

    const time24h = parsedTime.format("HH:mm");

    return time24h;
  };

  const addMinutesToTimev2 = (time24h, minutesToAdd) => {
    const [hours, minutes] = time24h.split(":").map(Number);
    let totalMinutes = hours * 60 + minutes + minutesToAdd;

    // Calculate new hour and minute
    const newHours = Math.floor(totalMinutes / 60) % 24; // Ensure 24-hour cycle
    const newMinutes = totalMinutes % 60;

    // Format the result
    const newTime = `${newHours.toString().padStart(2, "0")}:${newMinutes.toString().padStart(2, "0")}`;

    return newTime;
  };

  const handleAddStartTime = (record, val1) => {
    if (collectServiceFromTable?.length === 0) {
      pushNotification("Select Service first then assign time", "error");
      return;
    }
    setCollectServiceFromTable((prevState) => {
      return prevState?.map((element) => {
        if (element.id === record.id) {
          return {
            ...element,
            start_time: val1,
            checkboxCondition: !element.checkboxCondition,
            end_time: addMinutesToTimev2(convertTo24HourFormatv2(val1), record?.service?.default_duration),
          };
        }
        return { ...element };
      });
    });
  };

  const handleAssignService = async () => {
    try {
      setApiLoader(true);
      const convertedData = collectServiceFromTable?.map((item) => ({
        ...item,
        start_time: convertTo24HourFormat(item?.start_time),
        end_time: convertTo24HourFormat(item?.end_time), // Convert end time as well
      }));

      // final obect

      let incomData = convertedData
        ?.map((elements) => {
          return {
            staff: staffIdFromCalender,
            start_time: elements.start_time,
            end_time: elements.end_time,
            id: elements?.id,
          };
        })
        .filter((items) => items);

      const areTimesValid = (services) => {
        function isValidTime(time) {
          return time !== "Invalid Date" && !/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(time);
        }

        for (const service of services) {
          if (isValidTime(service.start_time) || isValidTime(service.end_time)) {
            return false;
          }
        }
        return true;
      };

      if (!areTimesValid(incomData)) {
        pushNotification("Please select valid time before assigning service", "error");
        setApiLoader(false);
        return;
      }

      if (incomData?.length > 0) {
        const { data } = await main_api.post(companyAPIsEndPoints.APPROVE_PENDING_REQ, incomData[0]);

        if (data.isSuccess) {
          pushNotification("Change request approved successfully", "success");
          handleFetchAllSchedulerServices();
          setServiceASignedModel(false);

          setCollectServiceFromTable([]);
        }
      }
      setApiLoader(false);
    } catch (error) {
      setApiLoader(false);
      console.log("Error", error);
    }
  };

  const staffResource = staffState?.map(({ id, name, ...rest }) => {
    return {
      ...rest,
      id: id,
      title: name,
    };
  });

  const assignServObject = {
    handleAddStartTime: handleAddStartTime,
    serviceData: [calenderData],
    handleAssignService,
    setCollectServiceFromTable,
    collectServiceFromTable,
    staffName: staffResource?.filter((items) => items.id === staffIdFromCalender),
    // apiLoader,
  };

  let minTimeSplit = minTimeState?.split(":");
  let maxTimeSplit = maxTimeState?.split(":");
  let dateSplit = calenderData?.date?.split("-");

  return (
    <>
      <OneDayResourcesVerticalCalender
        staffResource={staffResource}
        events={calenderEventsData || []}
        // handleEventClick={handleEventClick}
        handleSelectSlot={handleSelectSlot}
        isFiltering={apiLoader}
        date={calenderData?.date ? moment(calenderData?.date).toDate() : new Date()}
        startAccessor="start"
        endAccessor="end"
        resourceIdAccessor="id"
        resourceTitleAccessor="title"
        max={new Date(dateSplit[0], dateSplit[1] - 1, dateSplit[2], maxTimeSplit[0], 59)}
        min={new Date(dateSplit[0], dateSplit[1] - 1, dateSplit[2], minTimeSplit[0], minTimeSplit[1])}
        onNavigate={(date) => {
          console.log("Navigated to:", date);
        }}
        // date={AllStates.dayChangeState?.dayRange.start}
      />

      {serviceASignedModel && (
        <ServiceAssignedModal
          handleSelectServToAssign={handleSelectServToAssign}
          open={serviceASignedModel}
          handleClose={() => {
            setCollectServiceFromTable([]);
            setServiceASignedModel(false);
          }}
          {...assignServObject}
        />
      )}

      {/* perform aciton modal */}
    </>
  );
};

const ServiceAssignedModal = ({
  open,
  handleClose,
  serviceData,
  handleSelectServToAssign,
  handleAddStartTime,
  handleAssignService,
  setCollectServiceFromTable,
  collectServiceFromTable,
  apiLoader,
  staffName,
}) => {
  const handleCollectService = ({ rowKeys, SelctRows }) => {
    setCollectServiceFromTable((prevSt) => {
      // Filter out the rows that are unchecked (removed)
      const updatedRows = prevSt.filter((row) => rowKeys.includes(row.id));

      // Filter out the rows that are already present in the state
      const newRows = SelctRows.filter((newRow) => !updatedRows.some((row) => row.id === newRow.id));

      // Combine the updated rows with the new selected rows
      return [...updatedRows, ...newRows];
    });
  };

  return (
    <>
      <AntDesignModal
        title={"Edit Change Request Details"}
        open={open}
        width="800px"
        onCancel={handleClose}
        style={{ top: 20 }}
        component={
          <>
            <HeadingComponent
              text={`Select the services you want to assign to ${staffName?.[0].title || "---"}`}
              fontSize="14px"
              fontWeight={400}
              fontColor="#212121"
              margin={"15px 0px"}
            />

            <AntdesignTable
              dataSource={serviceData || []}
              columns={tableCols(handleSelectServToAssign, handleAddStartTime, collectServiceFromTable)}
              pagination={false}
              rowKey="id"
              bordered
              selectedRows={({ rowKeys, SelctRows }) => handleCollectService({ rowKeys, SelctRows })}
              allowMultieSelectRows={true}
            />
          </>
        }
        footer={
          <>
            <div className="d-flex justify-content-end mt-4">
              <CustomBtnWithIcon
                btnText={"Cancel"}
                margin="0px 5px"
                noBackground
                hideIcon={true}
                width="100px"
                onClick={(e) => {
                  e.preventDefault();
                  handleClose();
                }}
              />
              <CustomBtnWithIcon
                btnText={"Assign"}
                hideIcon={true}
                onClick={(e) => {
                  e.preventDefault();
                  handleAssignService();
                }}
                className="ms-3"
                width="100px"
                disabled={apiLoader || collectServiceFromTable?.length === 0}
              />
            </div>
          </>
        }
      />
    </>
  );
};

const formats = {
  eventTimeRangeFormat: () => {
    return "";
  },
};
const tagStyle = {
  backgroundColor: "#EFF1F5",
  fontWeight: 500,
  border: "none",
  borderRadius: "28px",
  padding: "2px 8px 2px 6px",
  color: "#023047",
};

const Button = styled.button`
  border: none;
  background: none;
`;

const ExpandableTableIcon = styled.button`
  rotate: ${(props) => props.expanded};
  transition: all 0.4s;
`;
