import styled from "styled-components";

const PetsContainer = styled.div`
  width: 100%;
`;
const PetsHeaderSection = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 10px;
`;
const PetsCardWrapperDiv = styled.div`
  width: 100%;
  margin-top: 15px;
`;

export { PetsContainer, PetsHeaderSection, PetsCardWrapperDiv };
