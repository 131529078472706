import React, { useEffect, useState } from 'react'
import { CustomModal } from './ModalStyles'
import { Checkbox, Divider } from 'antd'
import AntDesignNavTabs from '../antDesignNaviTabs/AntDesignNavTabs'
import CustomBtnWithIcon from '../buttonWithIcon'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { companyAPIsEndPoints } from '../../constants/ApiEndPoints'
import { main_api } from '../../api/axios_helper'
import { pushNotification } from '../../util/Notification'

const LegalAgreementModal = ({isModalOpen, setModalOpen}) => {
  const [activeTabNavigation, setActiveTabNavigation] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [aggreed, setIsAggreed] = useState(false);
  const { auth } = useSelector((state) => state);

  useEffect(()=>{
    if(auth?.loginResponse?.selectedRoleType ==='company_type' || auth?.loginResponse?.selectedRoleType ==='company_user_type'){
      const companyId = auth?.loginResponse?.selectCompany?.[0]?.company_id;
      main_api.get(companyAPIsEndPoints.FETCH_SINGLE_COMPANY(companyId))
      .then(({ data }) => {
        if(data.result?.privacy_policy_viewed && data.result?.terms_and_conditions_viewed){
          setIsAggreed(true)
          setIsChecked(true)
        }
      })
      .catch((error) => {
        pushNotification("Something went wrong!", "error");
        // console.log(error);
      });
    } else if(auth?.loginResponse?.selectedRoleType ==='client_type'){
      const client_id = auth.loginResponse?.selectCompany?.filter((item) => item.client_id !== null)[0].client_id;
      main_api.get(companyAPIsEndPoints.FETCH_SINGLE_CLIENT(client_id))
      .then(({ data }) => {
        if(data.privacy_policy_viewed && data.terms_and_conditions_viewed){
          setIsAggreed(true)
          setIsChecked(true)
        }
      })
      .catch((error) => {
        pushNotification("Something went wrong!", "error");
        // console.log(error);
      });
      
    }
  },[])

  const handleChanetabs = (key) => {
    setActiveTabNavigation(parseInt(key));
  };

  const save = () => {
    if(!aggreed && isChecked){
      if(auth?.loginResponse?.selectedRoleType ==='company_type' || auth?.loginResponse?.selectedRoleType ==='company_user_type'){
        const companyId = auth?.loginResponse?.selectCompany?.[0]?.company_id;
        main_api
        .put(companyAPIsEndPoints.UPDATE_SINGLE_COMPANY(companyId), {privacy_policy_viewed: true, terms_and_conditions_viewed: true})
        .then(({ data }) => {
          setModalOpen(false)
        })
        .catch((error) => {
          pushNotification("Something went wrong!", "error");
          // console.log(error);
        });
      } else if(auth?.loginResponse?.selectedRoleType ==='client_type'){
        const client_id = auth.loginResponse?.selectCompany?.filter((item) => item.client_id !== null)[0].client_id;
        main_api
        .put(companyAPIsEndPoints.EDIT_SINGLE_MANAGEMENT_CLIENTS(client_id), {privacy_policy_viewed: true, terms_and_conditions_viewed: true})
        .then(({ data }) => {
          setModalOpen(false)
        })
        .catch((error) => {
          pushNotification("Something went wrong!", "error");
          // console.log(error);
        });

      } else{
        setModalOpen(false)
      }


    } else{
      setModalOpen(false)
    }
  };

  const navigationTabs = (PrivacyPolicy, TermsAndConditions) => {
    return [
      {
        key: "1",
        label: "Privacy Policy",
        children: PrivacyPolicy,
      },
      {
        key: "2",
        label: "Terms and Conditions",
        children: TermsAndConditions,
      },
    ];
  };
  
  const navTabs = navigationTabs(
    <TabContainer>
      <PrivacyPolicy />
    </TabContainer>,
    <TabContainer>
      <TermsAndConditions />,

    </TabContainer>
  );


  return (
    <CustomModal
      open={isModalOpen}
      title="Legal Agreement"
      width="880px"
      heigth="728px"
      onCancel={() => {setModalOpen(false)}}
      footer={null}
    >
      <Divider/>
      <AntDesignNavTabs navTabs={navTabs} onChange={handleChanetabs} />
      {aggreed 
      ? 
      <Checkbox checked={true} disabled size="small"/>:
      <Checkbox size="small" value={isChecked} onChange={(e) => setIsChecked(e.target.checked)}/>}
      <span className='text-bold'> I Agree to all the terms and conditions</span>
      <Divider/>
        <div className="d-flex justify-content-end">
          <CustomBtnWithIcon
            btnText={"Cancel"}
            margin="0px 5px"
            noBackground
            hideIcon={true}
            onClick={() => setModalOpen(false)}
          />
          <CustomBtnWithIcon disabled={aggreed || !isChecked} btnText={"Save"} type="submit" hideIcon={true} onClick={() => save()}/>
        </div>
    </CustomModal>
  )
}

export const PrivacyPolicy = () => {
  return (
    <>
      <LegalHeading>Privacy Policy</LegalHeading>
      <LegalText>Lorem ipsum dolor sit amet consectetur adipisicing elit. At quam in culpa consequuntur voluptates vitae tempora, fuga voluptatibus esse facilis labore! Maiores distinctio nostrum maxime aperiam reprehenderit aut ea blanditiis, excepturi, totam magni repudiandae explicabo doloribus ipsam error sapiente. Itaque blanditiis placeat, nisi eveniet voluptate dolores minima quaerat laborum, eos aspernatur incidunt omnis est ullam dolor? Natus beatae deserunt corporis fugiat praesentium neque cupiditate optio magni suscipit quos ducimus similique porro facere, quia provident at quod, consequuntur ipsum? Rerum, quaerat earum enim repudiandae ducimus exercitationem autem similique optio ex impedit quod dolore veniam! Quisquam quod eos suscipit praesentium. Necessitatibus, inventore.</LegalText>
    </>
  )
}

export const TermsAndConditions = () => {
  return (
    <>
      <LegalHeading>Terms and Conditions</LegalHeading>
      <LegalText>Lorem ipsum dolor sit amet consectetur adipisicing elit. Perspiciatis, libero unde deleniti, id consequuntur voluptatem dicta magnam omnis facilis soluta accusantium inventore numquam neque. Atque nam sunt in molestiae quia!</LegalText>
    </>
  )
}

const TabContainer = styled.div`
  min-height:240px;
  height:240px;
  overflow-y:auto;
  margin-bottom:15px;
`;
const LegalHeading = styled.div`
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
`;

const LegalText = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding: 20px 0px;
`;

export default LegalAgreementModal