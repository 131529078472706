import React, { useEffect, useState } from "react";
import { Button, Popover } from "antd";
import { SketchPicker } from "react-color";
import { CheckOutlined, PlusOutlined } from "@ant-design/icons";

export default function ColorPicker({ initialColors, setInitialColors, selectedColorData, setValue, disabled }) {
  const [colors, setColors] = useState(initialColors);
  const [selectedColor, setSelectedColor] = useState("");
  const [color, setColor] = useState("#DADEDF");

  useEffect(() => {
    if (selectedColorData) {
      let updatedColors = [...initialColors];
      const colorIndex = updatedColors.indexOf(selectedColorData);

      if (colorIndex === -1) {
        updatedColors[5] = selectedColorData;
      }

      setColors(updatedColors);
      setInitialColors(updatedColors);
      setSelectedColor(selectedColorData);
      setValue(selectedColorData);
    }
  }, [selectedColorData]);

  const addColorToList = (newColor) => {
    // Replace the sixth color (index 5) with the new color
    const updatedColors = [...colors];
    updatedColors[5] = newColor;
    setColors(updatedColors);
    setInitialColors(updatedColors);
  };

  const handleColorSelect = (selectedColor) => {
    setSelectedColor(selectedColor);
    setValue(selectedColor);
  };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {colors.map((col, index) => (
        <Button
          disabled={disabled}
          key={index}
          style={{
            background: col,
            // border: "none",
            borderRadius: "50%",
            border: selectedColor === col ? "2px solid #FBCF34" : "none",
            width: "27px",
            height: "26px",
            marginBottom: "8px",
            marginRight: "3px",
            padding: 0, // Ensure no padding affects the circular shape
            lineHeight: "26px", // Center icon vertically if needed
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClick={() => handleColorSelect(col)}
          icon={
            selectedColor === col ? <CheckOutlined style={{ color: selectedColor === "#ffffff" ? "black" : "white" }} /> : null
          }
        />
      ))}
      <Popover
        trigger="click"
        content={
          <SketchPicker
            color={color}
            onChange={(color) => setColor(color.hex)}
            onChangeComplete={(color) => {
              setColor(color.hex);
              addColorToList(color.hex);
            }}
          />
        }
      >
        <Button
          disabled={disabled}
          icon={<PlusOutlined />}
          style={{
            background: "#DADEDF",
            border: "none",
            borderRadius: "50%",
            width: "26px",
            height: "26px",
            marginBottom: "8px",
          }}
        />
      </Popover>
    </div>
  );
}
