import React from "react";
import styled from "styled-components";
import { ReactComponent as DefaultImageIcon } from "../../../assets/images/uploadImage.svg";
import InputField from "../../../components/Form/Input";
import SelectField from "../../../components/Form/Select";
import ImageUploader from "../../../components/Form/ImageUploader";
import FormDetail from "./FormDetail";
import {Form, Input, Select, DatePicker} from 'antd'

function PetInfo({image, setImage}) {
  const genderOptions = [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
  ];
  
  return (
    <>
      <FormDetail title="Pet Information" description="We will take some info to customize your setup experience" />
        <UploadWraper>
          <FormItem name="pet_photo">
            <ImageUploader DefaultImageIcon={DefaultImageIcon} label="Pet Picture"  image={image} setImage={setImage}/>
          </FormItem>
        </UploadWraper>
        <FieldWraper>
          <Form.Item name="name" label="Name"  className="w-100"
            rules={[
              {
                required: true,
                message: "Please Enter Name",
              },
            ]}
          >
            <Input label="Name" placeholder="Enter Name" inputSize="large" />
          </Form.Item>
        </FieldWraper>
        <FieldWraper>
          <Form.Item name="type" label="Type" className="w-100">
            <Select
              placeholder="Select Type"
              options={[
                { value: "Cat", label: "Cat" },
                { value: "Dog", label: "Dog" },
              ]}
            />
          </Form.Item>
        </FieldWraper>
        <FieldWraper>
          <Form.Item
            className="w-100"
            name="birth_date"
            label="Date of Birth"
            rules={[
              {
                required: true,
                message: "Please select Date of Birth",
              },
            ]}
          >
            <DatePicker className="w-100" label="Date of Birth" placeholder="Select Date of Birth" type="date" />
          </Form.Item>
        </FieldWraper>
        <FieldWraper>
          <Form.Item label="Gender" className="w-100" name="sex">
            <Select placeholder="choose your Gender" options={genderOptions} />
          </Form.Item>
        </FieldWraper>
        <FieldWraper>
          <Form.Item name="breed" label="Breed" className="w-100">
            <InputField placeholder="Enter Breed"/>
          </Form.Item>
        </FieldWraper>
        <FurtherDetails>
          <span className="text-gray">You can further add pet details in Pets section of platform.</span>
        </FurtherDetails>
    </>
  );
}

export default PetInfo;


const FieldWraper = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
`;
const UploadWraper = styled.div`
  display: inline-block;
  gap: 16px;
  width: 100%;
`;

const FurtherDetails = styled.div`
  span {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: #757575;
  }
`;

const FormItem = styled.div`
  width: 100%;
  Tags {
    display: flex;
  }
`;
