import { Divider } from "antd";
import * as Yup from "yup";
import { Formik, Form as FormikForm } from "formik";

import { CustomModal } from "../../../components/Modals/ModalStyles";
import CustomBtnWithIcon from "../../../components/buttonWithIcon";

import FormikSelectInput from "../../../components/formikInputs/SelectInput";
import { main_api } from "../../../api/axios_helper";
import { companyAPIsEndPoints } from "../../../constants/ApiEndPoints";
import { pushNotification } from "../../../util/Notification";

//
const AddNewClientForm = ({
  resetClientResponse,
  visible,
  handleClose,
  clientId,
  fromClientManagement,
  updateClient = false,
  fetchAllInvoice,
  clientList,
  companyId,
}) => {
  const handleSubmit = async (values, actions) => {
    try {
      const { data } = await main_api.post(companyAPIsEndPoints.CREATE_INVOICE, values);
      if (data.isSuccess) {
        fetchAllInvoice();
        pushNotification("Invoice generated successfully!");
        handleClose();
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <CustomModal
      title={updateClient ? "Edit Client Details" : "Add a new invoice"}
      open={visible}
      onCancel={handleClose}
      maskClosable={false}
      footer={null}
      isScrollable
    >
      <Divider />
      <Formik
        initialValues={{
          client: clientId,
          company: companyId,
        }}
        validationSchema={Yup.object().shape({
          client: Yup.string().required("Client is required"),
        })}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ setFieldValue }) => (
          <FormikForm>
            <FormikSelectInput
              label="Client"
              placeholder="Select client"
              name="client"
              type="text"
              defaultValue={fromClientManagement ? parseInt(clientId) : "Select Client"}
              className="w-100 mb-2"
              autoComplete="new-password"
              options={clientList}
              width={"100%"}
              onChange={(e) => {
                setFieldValue("client", e);
              }}
              disabled={fromClientManagement}
            />

            <div className="d-flex justify-content-end gap-2">
              <CustomBtnWithIcon btnText={"Cancel"} noBackground hideIcon={true} onClick={handleClose} />
              <CustomBtnWithIcon
                btnText={"Save Invoice"}
                type="submit"
                hideIcon={true}
                // disabled={clientPostState?.isLoading || clientEditState?.isLoading}
              />
            </div>
          </FormikForm>
        )}
      </Formik>
    </CustomModal>
  );
};

export default AddNewClientForm;
