import React, { useState } from "react";
import styled from "styled-components";
import { Hours24Time } from "../../../../util/24HourtimeList";

import { hours_of_day_12_hour_format } from "../../SchedulerCalenderDataFile";
import "./style.css";
import SearchIcon from "../../../../assets/icons/SearchIcon";

import truncateString from "../../../../util/truncate";

import dayjs from "dayjs";
import { ShowEventModal } from "../../OneDayVertical";

import { AddNewEventModal } from "../../../../pages/company/sheduler/SchedulerEvents";

import { useSelector } from "react-redux";
import { Tooltip } from "antd";

import { checkPermission } from "../../../../util/commonUtil";
import PermissionModal from "../../../Modals/PermissionModal";
import { schedulerBackground, schedulerColor } from "../../helpers";

//
const OneDayHorizontalV2 = ({ realData, AllStates }) => {
  const { dayRange } = AllStates?.dayChangeState;
  const [openDetailSlotModal, setOpenDetailSlotModal] = useState(false);
  const [addNewEventSlotModal, setAddNewEventSlotModall] = useState(false);
  const [AddNewEventSlotModalDetails, setAddNewEventSlotModalDetails] = useState(false);
  const [permissionPopUp, setPermissionPopUp] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const [eventDetails, setEventDetails] = useState({});
  const [eventDetailmodal, setEventDetailModal] = useState(false);
  const permissionManagement = useSelector((state) => state.permissionManagement);

  const handleSelectSlot = (slotInfo, resource) => {
    if (checkPermission(permissionManagement.permissions, "assign_service_request_staff")) {
      const dateTime = dayjs(dayRange.start);
      const formattedDate = dateTime.format("YYYY-MM-DD");
      setAddNewEventSlotModalDetails({
        staffId: resource.staffId,
        // slotTime: slotInfo.label,
        date: formattedDate,
        serviceDetails: slotInfo.service,
        timeblock: slotInfo.time_block,
      });
      setAddNewEventSlotModall(true);
    } else {
      setPermissionPopUp(true);
    }
  };

  const handleEventClick = (slotInfo) => {
    if (checkPermission(permissionManagement.permissions, "view_assign_service_request_staff")) {
      const dateTime = dayjs(slotInfo.date);
      const formattedDate = dateTime.format("YYYY-MM-DD");

      const formattedTime = slotInfo.start_time.toLocaleTimeString("en-US", { hour: "numeric", minute: "numeric", hour12: true });

      setEventDetails({
        staffId: slotInfo.staff.id,
        slotTime: formattedTime,
        date: formattedDate,
        serviceID: slotInfo.id,
        title: slotInfo.service.name,
        serviceDetails: slotInfo.service,
        timeblock: slotInfo.time_block,
      });
      setEventDetailModal(true);
    } else {
      setPermissionPopUp(true);
    }
  };

  const filterUserBySearch = realData?.filter((item) => item?.name.toLowerCase().includes(searchQuery.toLowerCase()));

  return (
    <div>
      <div className={AllStates.schedulerLoader ? "parentTablediv" : null}>
        <table id="RBS-Scheduler-root" className="scheduler">
          <tbody>
            <tr>
              <td style={{ width: "160px", verticalAlign: "top" }}>
                <div className="resource-view">
                  <div style={{ overflow: "hidden", borderBottom: "1px solid rgb(233, 233, 233)", height: "40px" }}>
                    <div style={{ overflow: "scroll hidden", margin: "0px 0px -14px" }}>
                      <table className="resource-table">
                        <thead>
                          <tr>
                            <th className="hxt">
                              <SearchBar>
                                <SearchContainer>
                                  <LogoSearch>{SearchIcon}</LogoSearch>

                                  <Inputv2 placeholder={"Search..."} onChange={(e) => setSearchQuery(e.target.value)} />
                                </SearchContainer>
                              </SearchBar>
                            </th>
                          </tr>
                        </thead>
                      </table>
                    </div>
                  </div>
                  {/* Resourcesss ==== */}
                  <div style={{ overflow: "auto", width: "160px", margin: "0px -14px 0px 0px", background: "#F9F9F9" }}>
                    <div style={{ paddingBottom: "0px" }}>
                      <table className="resource-table">
                        <tbody>
                          {filterUserBySearch?.map((items) => {
                            return (
                              <>
                                <tr>
                                  <td data-resource-id="r0" style={{ height: "35px" }}>
                                    <div
                                      title="Resource0"
                                      className="overflow-text header2-text pl-2"
                                      style={{ textAlign: "left" }}
                                    >
                                      <span className="slot-cell">
                                        <span className="slot-text">{items.name}</span>
                                      </span>
                                    </div>
                                  </td>
                                </tr>
                              </>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {/* ==== */}
                </div>
              </td>
              <td>
                <div className="scheduler-view" style={{ width: "90vw", verticalAlign: "top" }}>
                  {/* timing header */}
                  <div style={{ overflow: "hidden", borderBottom: "1px solid rgb(233, 233, 233)", height: "40px" }}>
                    <div style={{ overflow: "hidden", margin: "0px 0px -14px" }}>
                      <div style={{ width: "90vw", overflow: "hidden" }}>
                        <table className="scheduler-bg-table">
                          <thead>
                            <tr style={{ height: "40px" }}>
                              {hours_of_day_12_hour_format.map((items) => {
                                return (
                                  <th
                                    className="header3-text"
                                    style={{ width: "60px", fontSize: "12px", fontWeight: 400, color: "#667085" }}
                                  >
                                    <div>
                                      <div>{items}</div>
                                    </div>
                                  </th>
                                );
                              })}
                            </tr>
                          </thead>
                        </table>
                      </div>
                    </div>
                  </div>
                  {/*  */}
                  <div style={{ overflow: "hidden", margin: "0px", position: "relative", paddingBottom: "0px" }}>
                    <div style={{ width: "90vw", height: "auto" }}>
                      <div className="scheduler-content">
                        <table className="scheduler-content-table">
                          <tbody>
                            {filterUserBySearch?.map((items) => {
                              return (
                                <>
                                  <tr>
                                    <td style={{ width: "1440px" }}>
                                      <div className="event-container" style={{ height: "35px" }}>
                                        <div />
                                      </div>
                                    </td>
                                  </tr>
                                </>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                      <div className="scheduler-bg" style={{ zIndex: 99 }}>
                        {/* timing boxes rows */}
                        <table className="scheduler-bg-table" style={{ width: "90vw" }}>
                          <tbody>
                            {filterUserBySearch?.map((resource, index) => (
                              <tr key={index} style={{ height: "35px" }} data-resource={resource.name}>
                                {Hours24Time?.map((hour, hourIndex) => {
                                  const eventStartHour = hourIndex;
                                  const matchingEvents = resource?.events.filter((event) => {
                                    const eventStartHourOfDay = event?.start_time?.getHours();
                                    return eventStartHourOfDay === eventStartHour;
                                  });

                                  const topPosition = index * 35;

                                  return (
                                    <td
                                      key={hourIndex}
                                      className="cursorPointer"
                                      style={{ width: "30px" }}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        handleSelectSlot(hour, resource);
                                      }}
                                    >
                                      {matchingEvents?.map((event) => (
                                        <a
                                          key={event.id}
                                          className="timeline-event"
                                          style={{
                                            width: `${event.eventWidth}px`,
                                            left: `${event.leftPosition}px`,
                                            top: `${topPosition}px`,
                                          }}
                                        >
                                          <div
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              !event.unavailableSlot && handleEventClick(event);
                                            }}
                                          >
                                            {event.unavailableSlot ? (
                                              <ClientUnavailableComponent title={event} />
                                            ) : event.commuteEvent ? (
                                              <CommuteEvent title={event} />
                                            ) : (
                                              <ClientEventComponent title={event} />
                                            )}
                                          </div>
                                        </a>
                                      ))}
                                    </td>
                                  );
                                })}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {/* MODALSS */}
      <div>
        {addNewEventSlotModal && (
          <AddNewEventModal
            clientPetServiceDropdowns={AllStates}
            addNewEventModal={addNewEventSlotModal}
            updateValues={AddNewEventSlotModalDetails}
            handleSuccessFunc={() => {
              setOpenDetailSlotModal(false);
            }}
            oneDayVerticalFrom={true}
            updateForm={true}
            closeModal={() => setAddNewEventSlotModall(false)}
          />
        )}
        {eventDetailmodal && (
          <ShowEventModal
            openDetailSlotModal={eventDetailmodal}
            handleCancel={() => {
              setEventDetailModal(false);
            }}
            eventDetails={eventDetails}
            serviceState={AllStates?.schedulerState?.result}
            handleEditEvent={() => {
              if (checkPermission(permissionManagement.permissions, "edit_assign_service_request_staff")) {
                setAddNewEventSlotModalDetails({
                  staffId: eventDetails.staffId,
                  slotTime: eventDetails.slotTime,
                  date: eventDetails.date,
                  serviceID: eventDetails.serviceID,
                  title: eventDetails.title,
                  serviceDetails: eventDetails.serviceDetails,
                  timeblock: eventDetails.timeblock,
                });

                setAddNewEventSlotModall(true);
              } else {
                setPermissionPopUp(true);
              }
            }}
            fetchServices={() => {
              AllStates?.dayFetchAllServices();
              setEventDetailModal(false);
              setAddNewEventSlotModall(false);
            }}
          />
        )}
        <PermissionModal onclose={() => setPermissionPopUp(false)} openModal={permissionPopUp} />
      </div>
    </div>
  );
};

export default OneDayHorizontalV2;

const ClientEventComponent = ({ title }) => {
  const titleWidth = title?.eventWidth < 18 ? 3 : title?.eventWidth < 50 ? 5 : 100;
  return (
    <div
      style={{
        backgroundColor: schedulerBackground(title),
        color: schedulerColor(title),
        borderRadius: "5px",
        cursor: "pointer",
        border: "1px solid #187BEF",
        height: "35px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <span style={{ textOverflow: "ellipsis", overflow: "hidden", fontSize: "12px" }}>
        {" "}
        {truncateString(title?.service?.name, titleWidth)}
      </span>
    </div>
  );
};

const ClientUnavailableComponent = () => {
  const styles = {
    stripeBox: {
      height: "35px",
      border: "2px solid #f5f5f5",
      borderRadius: "5px",
      background:
        "repeating-linear-gradient(45deg, rgb(224, 224, 224), rgb(224, 224, 224) 3px, rgb(245, 245, 245) 3px, rgb(245, 245, 245) 5px)",
      position: "relative",
      boxSizing: "border-box",
    },
    stripeBoxContent: {
      padding: "10px",
      position: "relative",
      zIndex: 1,
    },
  };
  return (
    <Tooltip title="This time slot is blocked">
      <div style={styles.stripeBox}>
        <div style={styles.stripeBoxContent}></div>
      </div>
    </Tooltip>
  );
};

const CommuteEvent = () => {
  const styles = {
    stripeBox: {
      height: "35px",
      border: "2px solid #f5f5f5",
      borderRadius: "5px",
      background: `repeating-linear-gradient(299deg, rgb(224, 224, 224), rgb(224, 224, 224) 3px, rgb(245, 245, 245) 3px, rgb(245, 245, 245) 5px)`,
      position: "relative",
      boxSizing: "border-box",
    },
    stripeBoxContent: {
      padding: "10px",
      position: "relative",
      zIndex: 1,
    },
  };
  return (
    <Tooltip title="Commute Duration">
      <div style={styles.stripeBox}>
        <div style={styles.stripeBoxContent}></div>
      </div>
    </Tooltip>
  );
};

// height: 35px;
// border: 2px solid rgb(245, 245, 245);
// border-radius: 5px;
// background: repeating-linear-gradient(299deg, rgb(224, 224, 224), rgb(224, 224, 224) 3px, rgb(245, 245, 245) 3px, rgb(245, 245, 245) 5px);
// position: relative;
// box-sizing: border-box;

const Inputv2 = styled.input`
  width: ${(props) => props?.width || "100%"};
  height: ${(props) => (props?.height ? props?.height : "40px")};
  border: ${(props) => props.border ?? "1px solid rgb(214, 217, 225)"};
  border-radius: 8px;
  margin: ${(props) => props?.margin ?? "0"};
  padding: 12px 10px 8px 37px;
  box-sizing: border-box;
  outline: none;
  font-family:
    Helvetica Neue,
    Arial;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 57px;
  color: #000;
  ::placeholder {
    padding: 10px 0px;
    color: #bdbdbd;
  }
`;

const SearchBar = styled.div`
  ${"" /* height: 64px; */}
  border-radius: 4px;
  ${"" /* padding: 12px 0px 12px 0px; */}
  box-sizing: border-box;
  transform: translateY(-2px);
`;
const SearchContainer = styled.div`
  height: 100%;
  border-radius: 8px;

  box-sizing: border-box;

  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
`;

const LogoSearch = styled.svg`
  height: 24px;
  width: 24px;
  margin-left: 8px;
  object-fit: contain;
  fill: transparent;
  position: absolute;
`;
