import styled from "styled-components";
import { Space, Tag, Tooltip } from "antd";
import { ReactComponent as EditIcon } from "../../assets/rawSvgs/tableEditIcon.svg";
import { ReactComponent as DeleteIcon } from "../../assets/rawSvgs/tableDeleteIcon.svg";
import { ReactComponent as HistoryIcon } from "../../assets/rawSvgs/HistoryAction.svg";
import { DownloadOutlined, MoreOutlined } from "@ant-design/icons";
import CustomBtnWithIcon from "../../components/buttonWithIcon";
import { ReactComponent as DownloadIcon } from "../../assets/rawSvgs/download.svg";
//{ handleDelete = "", handleEditRow = "" }
const DocumentsTableActions = ({ handleDownload = "", handleSignature = "", clientSignAgreement = false }) => {
  return [
    {
      title: "",
      key: "action",
      render: (_, record) => (
        <Space size="middle" style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            onClick={(event) => {
              event.stopPropagation();
              handleDownload && handleDownload(record);
            }}
          >
            <DownloadIcon />
          </Button>
          {clientSignAgreement ? (
            <Tooltip title={"You are not allowed to sign a document. Contact admin for support"}>
              <CustomBtnWithIcon
                btnText={"Sign Document"}
                hideIcon={true}
                disabled={clientSignAgreement}
                onClick={(event) => {
                  event.stopPropagation();
                  handleSignature && handleSignature(record);
                }}
              />
            </Tooltip>
          ) : (
            <CustomBtnWithIcon
              btnText={"Sign Document"}
              hideIcon={true}
              onClick={(event) => {
                event.stopPropagation();
                handleSignature && handleSignature(record);
              }}
            />
          )}
        </Space>
      ),
    },
  ];
};

export { DocumentsTableActions };

const Button = styled.button`
  border: none;
  background: none;
`;
