import React from "react";
import styled from "styled-components";
import InputField from "../../../components/Form/Input";
import SelectField from "../../../components/Form/Select";
import FormDetail from "./FormDetail";
import { Input, Form } from "antd";
import LocationSelector from "../../../components/CustomAddressInput/locationSelector";


function AddressDetail({ address, setAddress, setAddressLatAndLong, form, routeOptimizorChecked }) {

  return (
    <>
      <FormDetail title="Address Details" description="We will take some info to customize your setup experience" />
      <FieldWraper>
        {/* <Form.Item name="address" label="Street Address" className="w-100 mb-3">
          <Input
            placeholder="Enter Address"
          />
        </Form.Item> */}
        <LocationSelector address={address} setAddress={setAddress} setAddressLatAndLong={setAddressLatAndLong} form={form} checked={routeOptimizorChecked}/>

      </FieldWraper>
      <FieldWraper>
        <Form.Item name="city" label="City" className="w-100 mb-3">
          <Input placeholder="Enter City" />
        </Form.Item>
      </FieldWraper>
      <FieldWraper>
        <Form.Item name="zip" label="Zip Code" className="w-100 mb-3">
          <Input placeholder="Enter Zip code" />
        </Form.Item>
      </FieldWraper>
      <FieldWraper>
        <Form.Item name="state" label="State" className="w-100 mb-3">
          <Input placeholder="Enter State" />
        </Form.Item>
      </FieldWraper>
      <FieldWraper>
        <Form.Item name="country" label="Country" className="w-100 mb-3">
          <Input placeholder="Enter Country" />
        </Form.Item>
      </FieldWraper>
    </>
  );
}

export default AddressDetail;

const FieldWraper = styled.div`
  display: flex;
  width: 100%;
`;

const FormItem = styled.div`
  width: 100%;
  Tags {
    display: flex;
  }
`;
