import { Checkbox, Col, Row, Switch, Input } from "antd";
import React from "react";
import HeadingComponent from "../../../../../components/Heading";
import { main_api } from "../../../../../api/axios_helper";
import { companyAPIsEndPoints } from "../../../../../constants/ApiEndPoints";
import { Field, Formik, Form } from "formik";
import { pushNotification } from "../../../../../util/Notification";

export const ClientOnboarding = ({
  company_id,
  formikRefClientSettings,
  companyClientSettings,
  handleFetchClientOnBoardSettings,
}) => {
  const handleSaveClientSettings = async (values) => {
    try {
      const { data } = await main_api.post(companyAPIsEndPoints.ADD_CLIENT_SETTINGS, values);

      if (data.isSuccess) {
        pushNotification("Client onboarding settings saved");
        handleFetchClientOnBoardSettings();
      }
    } catch (error) {
      // setIsRotating(false);
      console.log("error:", error);
    }
  };

  return (
    <Formik
      initialValues={{
        sign_agreement: companyClientSettings?.sign_agreement,
        update_info: companyClientSettings?.update_info,
        add_pet: companyClientSettings?.add_pet,
        payment_method_enabled: companyClientSettings?.payment_method_enabled,
        upload_documents_enabled: companyClientSettings?.upload_documents_enabled,
        upload_documents_button: companyClientSettings?.upload_documents_button,
        document_title_field: companyClientSettings?.document_title_field,
        document_remove_button: companyClientSettings?.document_remove_button,
        company_id: company_id,
      }}
      innerRef={formikRefClientSettings}
      onSubmit={(values) => {
        handleSaveClientSettings(values);
      }}
    >
      {({ setFieldValue, values }) => (
        <Form>
          <Row>
            <Col md={10}>
              <HeadingComponent text="Sign Agreement" fontSize="16px" fontWeight={500} color="#212121" />
            </Col>
            <Col md={10}>
              <Field name="sign_agreement">
                {({ field }) => (
                  <Switch size="small" checked={field.value} onChange={(checked) => setFieldValue(field.name, checked)} />
                )}
              </Field>
              <span className="ms-2">{values?.sign_agreement ? "Enabled" : "Disabled"}</span>
            </Col>

            {/* ======================================================== */}

            <Col md={10} className="my-4">
              <HeadingComponent color="#212121" text="Upload a Document" fontSize="16px" fontWeight={500} />
            </Col>
            <Col md={10} className="my-4">
              <Field name="upload_documents_enabled">
                {({ field }) => (
                  <Switch size="small" checked={field.value} onChange={(checked) => setFieldValue(field.name, checked)} />
                )}
              </Field>
              <span className="ms-2 mb-5">{values?.upload_documents_enabled ? "Enabled" : "Disabled"}</span>
              <HeadingComponent
                text="Check options you want to display to client"
                fontSize="14px"
                fontWeight={500}
                color="#757575"
                margin="10px 0px"
              />
              <div className="d-flex flex-column">
                <Field name="upload_documents_button">
                  {({ field }) => (
                    <Checkbox checked={field.value} onChange={(e) => setFieldValue(field.name, e.target.checked)}>
                      Upload document button
                    </Checkbox>
                  )}
                </Field>
                {/* <Field name="document_title_field">
                  {({ field }) => (
                    <Checkbox
                      checked={field.value}
                      className="my-3"
                      onChange={(e) => setFieldValue(field.name, e.target.checked)}
                    >
                      Document Title Field
                    </Checkbox>
                  )}
                </Field> */}
                {/* <Field name="document_remove_button">
                  {({ field }) => (
                    <Checkbox checked={field.value} onChange={(e) => setFieldValue(field.name, e.target.checked)}>
                      Remove button
                    </Checkbox>
                  )}
                </Field> */}
              </div>
            </Col>

            {/* =================================== */}
            <Col md={10} className="my-4">
              <HeadingComponent color="#212121" text="Update Info" fontSize="16px" fontWeight={500} />
            </Col>
            <Col md={10} className="my-4">
              <Field name="update_info">
                {({ field }) => (
                  <Switch size="small" checked={field.value} onChange={(checked) => setFieldValue(field.name, checked)} />
                )}
              </Field>
              <span className="ms-2 mb-5">{values?.update_info ? "Enabled" : "Disabled"}</span>
            </Col>

            {/* =================================== */}
            <Col md={10} className="my-4">
              <HeadingComponent color="#212121" text="Add Pet" fontSize="16px" fontWeight={500} />
            </Col>
            <Col md={10} className="my-4">
              <Field name="add_pet">
                {({ field }) => (
                  <Switch size="small" checked={field.value} onChange={(checked) => setFieldValue(field.name, checked)} />
                )}
              </Field>
              <span className="ms-2 mb-5">{values?.add_pet ? "Enabled" : "Disabled"} </span>
            </Col>

            {/* =================================== */}
            <Col md={10} className="my-4">
              <HeadingComponent color="#212121" text="Payment Method" fontSize="16px" fontWeight={500} />
            </Col>
            <Col md={10} className="my-4">
              <Field name="payment_method_enabled">
                {({ field }) => (
                  <Switch size="small" checked={field.value} onChange={(checked) => setFieldValue(field.name, checked)} />
                )}
              </Field>
              <span className="ms-2 mb-5">{values?.payment_method_enabled ? "Enabled" : "Disabled"} </span>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};
