import React, { useEffect, useState } from "react";
import { DatePicker, TimePicker, Divider, Checkbox, Form, Radio, Space, Col, Row, Select } from "antd";
import styled from "styled-components";
import CustomBtnWithIcon from "../../buttonWithIcon";
import { CustomModal } from "../ModalStyles";
import { StaffTimeOffType } from "../../../enums/staff";
import { main_api } from "../../../api/axios_helper";
import { staffAPIsEndPoints } from "../../../constants/ApiEndPoints";
import { useSelector } from "react-redux";
import { ReactComponent as DropDownIcon } from "../../../assets/rawSvgs/selectDropDownIcon.svg";

import dayjs from "dayjs";
import moment from "moment";
import { pushNotification } from "../../../util/Notification";

const ManageTimeOffModal = ({ isModalOpen, setModalOpen, selectedTimeOff, handleTimeOff, showStaffList = false }) => {
  const [type, setType] = useState(StaffTimeOffType.SINGLE);
  const [form] = Form.useForm();
  form.setFieldsValue({
    start_date: selectedTimeOff && selectedTimeOff.start_date ? dayjs(selectedTimeOff.start_date) : null,
    end_date: selectedTimeOff && selectedTimeOff.end_date ? dayjs(selectedTimeOff.end_date) : null,
    start_time: selectedTimeOff && selectedTimeOff.start_time ? dayjs(selectedTimeOff.start_time, "HH:mm:ss") : null,
    end_time: selectedTimeOff && selectedTimeOff.end_time ? dayjs(selectedTimeOff.end_time, "HH:mm:ss") : null,
    recurring_days: selectedTimeOff && selectedTimeOff.recurring_days ? selectedTimeOff.recurring_days : [],
    type: selectedTimeOff && selectedTimeOff.type ? selectedTimeOff.type : StaffTimeOffType.SINGLE,
  });
  const auth = useSelector((state) => state.auth);
  const [staffList, setStaffList] = useState([]);

  useEffect(() => {
    if (isModalOpen && showStaffList) {
      let company_id = auth.loginResponse?.selectCompany?.[0]?.company_id || null;
      if (company_id) {
        main_api
          .get(`${staffAPIsEndPoints.LIST_STAFF(`usercompanyrole__company=${company_id}`)}`)
          .then(({ data }) => {
            setStaffList(data);
          })
          .catch((error) => {
          pushNotification("Something went wrong!", "error");

            // console.log(error);
          });
      }
    }
  }, [isModalOpen]);

  const hasRecurringDayInRange = (startDate, endDate, days) => {
    const occurrences = new Array(7).fill(0);
    let currentDate = dayjs(startDate);
    while (currentDate.isBefore(endDate) || currentDate.isSame(endDate)) {
      const dayOfWeek = (currentDate.day() + 6) % 7;
      occurrences[dayOfWeek]++; // Increment occurrence count for the current day

      // Check if all specified days have occurred at least once
      const allDaysFound = days.every((day) => occurrences[day] > 0);
      if (allDaysFound) {
        return true; // Exit the loop and return true if all days are found
      }

      currentDate = currentDate.add(1, "day");
    }

    return false;
  };

  const handleSubmit = async (values) => {
    let data = {
      start_date: values.start_date ? dayjs(values.start_date).format("YYYY-MM-DD") : null,
      end_date: values.end_date ? dayjs(values.end_date).format("YYYY-MM-DD") : null,
      start_time: values.start_time ? dayjs(values.start_time).format("HH:mm:ss") : null,
      end_time: values.end_time ? dayjs(values.end_time).format("HH:mm:ss") : null,
      type: type,
    };
    if (type === StaffTimeOffType.SINGLE) {
      data["end_date"] = null;
      data["recurring_days"] = [];
    } else if (type === StaffTimeOffType.MULTI) {
      data["recurring_days"] = [];
    } else if (type === StaffTimeOffType.RECURRING) {
      data["recurring_days"] = values.recurring_days;
    }
    if (values.staff) {
      data["staff"] = values.staff;
    }
    handleTimeOff(data);
  };

  useEffect(() => {
    if (selectedTimeOff) {
      setType(selectedTimeOff.type);
    }
  }, [selectedTimeOff]);

  const onChange = (e) => {
    setType(e.target.value);
  };

  const options = [
    { label: "Mon", value: 0 },
    { label: "Tue", value: 1 },
    { label: "Wed", value: 2 },
    { label: "Thu", value: 3 },
    { label: "Fri", value: 4 },
    { label: "Sat", value: 5 },
    { label: "Sun", value: 6 },
  ];

  const validateDate = (rule, value, callback) => {
    const { getFieldValue } = form;

    const startDate = getFieldValue("start_date");
    const endDate = getFieldValue("end_date");
    const recurringDays = getFieldValue("recurring_days");
    console.log("recurringDays", recurringDays);
    if (startDate && endDate) {
      if (dayjs(endDate).isBefore(startDate)) {
        callback("Start date should be before the End date");
        return;
      } else if (dayjs(startDate).isSame(endDate, "day")) {
        callback("Start and End Date should not be same");
        return;
      }
      form.setFields([
        {
          name: "start_date",
          errors: [],
        },
        {
          name: "end_date",
          errors: [],
        },
      ]);
      if (recurringDays.length) {
        const exists = hasRecurringDayInRange(startDate, endDate, recurringDays);
        if (!exists) {
          form.setFields([
            {
              name: "recurring_days",
              errors: ["Recurring days must be in the specified date range"],
            },
          ]);
        } else {
          form.setFields([
            {
              name: "recurring_days",
              errors: [],
            },
          ]);
        }
      }
    }
    callback();
  };

  const validateTime = (rule, value, callback) => {
    const { getFieldValue } = form;
    // Get the value of the start date field
    const startTime = getFieldValue("start_time");
    const endTime = getFieldValue("end_time");
    if (startTime && endTime) {
      if (dayjs(startTime).isAfter(endTime)) {
        callback("Start time must be before end time");
      } else if (dayjs(startTime).isSame(endTime, "minute")) {
        callback("Start and End Time should not be same");
      } else {
        form.setFields([
          {
            name: "start_time",
            errors: [],
          },
          {
            name: "end_time",
            errors: [],
          },
        ]);
        callback();
      }
      return;
    }
    callback();
  };

  const validateRecurringDays = (rule, value, callback) => {
    const { getFieldValue } = form;

    const startDate = getFieldValue("start_date");
    const endDate = getFieldValue("end_date");

    if (startDate && endDate && value.length) {
      const exists = hasRecurringDayInRange(startDate, endDate, value);
      if (!exists) {
        callback("Recurring days must be in the specified date range");
        return;
      }
    }
    callback();
  };

  const onCancel = () => {
    setModalOpen(false);
    setType(StaffTimeOffType.SINGLE);
  };

  return (
    <CustomModal
      open={isModalOpen}
      title={selectedTimeOff ? "Edit Time Off" : "Add Time Off"}
      onCancel={() => onCancel()}
      footer={null}
      maskClosable={false}
    >
      <Divider style={{ margin: "12px 0" }} />
      <Form name="timeOffModal" onFinish={handleSubmit} form={form} layout="vertical">
        {showStaffList && (
          <FormItem
            name="staff"
            required
            label="Staff"
            rules={[
              {
                required: true,
                message: "Please select Staff",
              },
            ]}
          >
            <Select
              suffixIcon={<DropDownIcon />}
              placeholder="Select Staff"
              style={{
                width: "100%",
                minHeight: "40px",
              }}
              options={staffList.map((item, index) => ({
                value: item.id,
                label: <span key={index}>{item.name}</span>,
              }))}
              className="mb-1"
            />
          </FormItem>
        )}
        <FormItem required label="Select the Days for Time Off">
          <Radio.Group
            defaultValue={selectedTimeOff ? selectedTimeOff.type : StaffTimeOffType.SINGLE}
            value={type}
            onChange={onChange}
          >
            <Space direction="vertical">
              <Radio value={StaffTimeOffType.SINGLE}>Single Day</Radio>
              <Radio value={StaffTimeOffType.MULTI}>Multi Day</Radio>
              <Radio value={StaffTimeOffType.RECURRING}>Recurring</Radio>
            </Space>
          </Radio.Group>
        </FormItem>
        {type === StaffTimeOffType.RECURRING && (
          <FormItem
            required
            label="Select Day of Week"
            name="recurring_days"
            rules={[
              {
                required: true,
                message: "Please select Day Of Week",
              },
              { validator: validateRecurringDays },
            ]}
          >
            <Checkbox.Group options={options} />
          </FormItem>
        )}
        {type === StaffTimeOffType.SINGLE ? (
          <Row>
            <Col xs={24}>
              <FormItem
                name="start_date"
                label="Date"
                rules={[
                  {
                    required: true,
                    message: "Please enter Date",
                  },
                ]}
              >
                <DatePicker placeholder="Select Date" style={{ width: "100%" }} type="date" format={"DD/MM/YYYY"} />
              </FormItem>
            </Col>
          </Row>
        ) : (
          <Row gutter={15}>
            <Col xs={12}>
              <FormItem
                name="start_date"
                label="Start Date"
                rules={[
                  {
                    required: true,
                    message: "Please enter Start Date",
                  },
                  { validator: validateDate },
                ]}
              >
                <DatePicker placeholder="Select Start Date" style={{ width: "100%" }} type="date" format={"DD/MM/YYYY"} />
              </FormItem>
            </Col>
            <Col xs={12}>
              <FormItem
                name="end_date"
                label="End Date"
                rules={[
                  {
                    required: true,
                    message: "Please enter End Date",
                  },
                  { validator: validateDate },
                ]}
              >
                <DatePicker placeholder="Select End Date" style={{ width: "100%" }} type="date" format={"DD/MM/YYYY"} />
              </FormItem>
            </Col>
          </Row>
        )}
        <Row gutter={15}>
          <Col xs={12}>
            <FormItem
              name="start_time"
              label="Start Time"
              rules={[
                {
                  required: true,
                  message: "Please enter Start Time",
                },
                { validator: validateTime },
              ]}
            >
              <TimePicker
                style={{ width: "100%" }}
                use12Hours
                format="h:mm a"
                placeholder="Select Start Time"
                defaultOpenValue={moment().hour(0).minute(0)}
              />
            </FormItem>
          </Col>
          <Col xs={12}>
            <FormItem
              name="end_time"
              label="End Time"
              rules={[
                {
                  required: true,
                  message: "Please enter End Time",
                },
                { validator: validateTime },
              ]}
            >
              <TimePicker
                style={{ width: "100%" }}
                use12Hours
                format="h:mm a"
                placeholder="Select End Time"
                defaultOpenValue={moment().hour(0).minute(0)}
              />
            </FormItem>
          </Col>
        </Row>
        <Divider />
        <div className="d-flex justify-content-end">
          <CustomBtnWithIcon
            btnText={"Cancel"}
            type="button"
            margin="0px 5px"
            noBackground
            hideIcon={true}
            onClick={() => onCancel()}
          />
          <CustomBtnWithIcon btnText={selectedTimeOff ? "Save Changes" : "Save Time Off"} type="submit" hideIcon={true} />
        </div>
      </Form>
    </CustomModal>
  );
};

const FormItem = styled(Form.Item)`
  width: 100%;
`;

export default ManageTimeOffModal;
