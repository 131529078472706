//
export const navigationTabs = (availability, StaffTimeOff, timeTracking, mileageTracking, configurations) => {
  return [
    {
      key: "1",
      label: "Schedule & Availability",
      children: availability,
    },
    {
      key: "2",
      label: "Time Off",
      children: StaffTimeOff,
    },
    {
      key: "3",
      label: "Time Tracking",
      children: timeTracking,
    },
    {
      key: "4",
      label: "Mileage Tracking",
      children: mileageTracking,
    },
    {
      key: "5",
      label: "Configurations",
      children: configurations,
    },
  ];
};
