// import { createSlice } from "@reduxjs/toolkit";
// import { platformAPIsEndPoints } from "../../constants/ApiEndPoints";
// import { main_api } from "../../api/axios_helper";


// const initialThemeState = {
//   isLoading: false,
//   error: "",
//   themeSettings: {},
// };

// const themeSlice = createSlice({
//   name: "theme",
//   initialState: initialThemeState,
//   reducers: {
//     fetchThemeSettingsStart(state) {
//       state.isLoading = true;
//       state.error = "";
//     },
//     fetchThemeSettingsSuccess(state, action) {
//       state.isLoading = false;
//       state.themeSettings = action.payload;
//     },
//     fetchThemeSettingsFailure(state, action) {
//       state.isLoading = false;
//       state.error = action.payload;
//     },
//   },
// });

// export const { fetchThemeSettingsStart, fetchThemeSettingsSuccess, fetchThemeSettingsFailure } = themeSlice.actions;
// // Reducer
// export default themeSlice.reducer;

// export function fetchThemeSettings() {
//   return async (dispatch) => {
//     const id = 1
//     try {
//       dispatch(fetchThemeSettingsStart());
//       const { data } = await main_api.get(platformAPIsEndPoints.FETCH_ONBOARDING_THEME_SETTINGS(id));
//       dispatch(fetchThemeSettingsSuccess(data.result));
//     } catch (error) {
//       dispatch(fetchThemeSettingsFailure(error.message));
//     }
//   };
// }



import { createSlice } from "@reduxjs/toolkit";
import { platformAPIsEndPoints } from "../../constants/ApiEndPoints";
import { main_api } from "../../api/axios_helper";

const initialThemeState = {
  isLoading: false,
  error: "",
  themeSettings: {},
};

const themeSlice = createSlice({
  name: "theme",
  initialState: initialThemeState,
  reducers: {
    fetchThemeSettingsStart(state) {
      state.isLoading = true;
      state.error = "";
    },
    fetchThemeSettingsSuccess(state, action) {
      state.isLoading = false;
      state.themeSettings = action.payload;
    },
    fetchThemeSettingsFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const { fetchThemeSettingsStart, fetchThemeSettingsSuccess, fetchThemeSettingsFailure } = themeSlice.actions;
// Reducer
export default themeSlice.reducer;

export function fetchThemeSettings() {
  return async (dispatch) => {
    const id = 1;
    try {
      dispatch(fetchThemeSettingsStart());
      const { data } = await main_api.get(platformAPIsEndPoints.FETCH_ONBOARDING_THEME_SETTINGS(id));

      if (data && data.result && Object.keys(data.result).length > 0) {
        dispatch(fetchThemeSettingsSuccess(data.result));
      } else {
        dispatch(fetchThemeSettingsFailure("No theme settings available"));
      }
    } catch (error) {
      dispatch(fetchThemeSettingsFailure(error.message));
    }
  };
}
