import styled from "styled-components";

const WrapperCard = styled.div`
  width: 356px;
  min-height: 234px;
  border: 1px solid rgba(224, 224, 224, 1);
  padding: 14px;
  border-radius: 8px;
`;

const AvatarHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const HashAvatarNumber = styled.h2`
  margin-top: 5px;
  font-size: 0.875rem;
  color: rgba(66, 66, 66, 1);
  font-weight: 500;
`;

const NameTagsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 15px;
`;

const ClientName = styled.h2`
  font-size: 1.25rem;
  font-weight: 700;
  margin-right: 7px;
`;

const PillsWrapper = styled.div`
  font-size: 1.25rem;
  font-weight: 700;
`;

export { WrapperCard, AvatarHeaderWrapper, HashAvatarNumber, NameTagsWrapper, ClientName, PillsWrapper };
