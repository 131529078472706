const EditIconAlt = (
  <>
    <path
      d="M11.5107 21H22.0217"
      stroke="black"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16.7664 1.72563C17.231 1.26102 17.8612 1 18.5182 1C18.8436 1 19.1657 1.06408 19.4663 1.18858C19.7669 1.31309 20.04 1.49558 20.2701 1.72563C20.5001 1.95568 20.6826 2.22879 20.8071 2.52937C20.9316 2.82995 20.9957 3.15211 20.9957 3.47745C20.9957 3.8028 20.9316 4.12496 20.8071 4.42554C20.6826 4.72611 20.5001 4.99923 20.2701 5.22928L5.67153 19.8278L1 20.9957L2.16788 16.3242L16.7664 1.72563Z"
      stroke="black"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </>
)

export default EditIconAlt
