import React, { useEffect, useState } from "react";
import AuthPetsScreen from "../../components/AuthPetsScreen/AuthPetsScreen";
import Steper from "../client/onboarding/Steper";

import { Col, Form, Input, Row } from "antd";
import styled from "styled-components";
import CustomBtnWithIcon from "../../components/buttonWithIcon";
import { useLocation, useNavigate } from "react-router-dom";
import { setJWTToken } from "../../api/auth";
import { fetchSignRoles, updateCompanyDetails } from "../../util/DataService";
import { pushNotification } from "../../util/Notification";
import SkippedButton from "../../components/skippedButton/SkippedButton";
import { companyAPIsEndPoints, platformAPIsEndPoints } from "../../constants/ApiEndPoints";
import { useSelector, useDispatch } from "react-redux";
import { main_api, baseURL } from "../../api/axios_helper";
import { fetchSingleRole } from "../../redux/slices/permissionSlice";
import CompanyDetails from "../../components/CompanyOnboarding/companyDetails";
import DashboardTheme from "../../components/CompanyOnboarding/dashboardTheme";
import CompanyVideos from "../../components/CompanyOnboarding/companyVideos";
import CompanyLogos from "../../components/CompanyOnboarding/companyLogos";
import ClientPortalTheme from "../../components/CompanyOnboarding/clientPortalTheme";
import EmailTheme from "../../components/CompanyOnboarding/emailTheme";

const CompanyOnboarding = () => {
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  let company_id = auth?.loginResponse?.selectCompany?.[0]?.company_id || null;
  let compOnBoardingSeen = auth?.loginResponse?.selectCompany?.[0]?.company_onboarding_seen;

  const [themeData, setThemeData] = useState();
  const [activeScreen, setActiveScreen] = useState("company_details");
  const [logoData, setLogoData] = useState({
    company_logo: false,
    mobile_app_logo: false,
    email_logo: false,
  });
  const [dashboardData, setDashboardData] = useState({
    dashboard_header: false,
    dashboard_header_font: false,
    dashboard_header_separator: false,
    dashboard_header_title: false,
  });
  const [clientPortalData, setClientPortalData] = useState({
    clientportal_footer_background: false,
    clientportal_footer_text: false,
    clientportal_header_background: false,
    clientportal_header_secondary: false,
    clientportal_header_text: false,
  });
  const [emailLayoutData, setEmailLayoutData] = useState({
    email_background: false,
    email_excerpt_background: false,
    email_excerpt_separator: false,
    email_footer_link: false,
    email_header: false,
    email_header_text: false,
  });
  useEffect(() => {
    if (compOnBoardingSeen) {
      navigate("/company/scheduler");
    }
  }, [compOnBoardingSeen]);

  const getThemeSettings = () => {
    const id = 1;
    main_api
      .get(platformAPIsEndPoints.FETCH_ONBOARDING_THEME_SETTINGS(id))
      .then(({ data }) => {
        setThemeData(data?.result);

        setLogoData({
          company_logo: data?.result?.company_logo_enabled,
          mobile_app_logo: data?.result?.mobile_app_logo_enabled,
          email_logo: data?.result?.email_logo_enabled,
          //
          email_logo_link: data?.result?.email_logo || undefined,
          mobile_logo_link: data?.result?.mobile_app_logo || undefined,
          company_logo_link: data?.result?.company_logo || undefined,
        });
        setDashboardData({
          dashboard_header: data?.result?.dashboard_header_enabled,
          dashboard_header_font: data?.result?.dashboard_header_font_enabled,
          dashboard_header_separator: data?.result?.dashboard_header_separator_enabled,
          dashboard_header_title: data?.result?.dashboard_header_title_enabled,
        });
        setClientPortalData({
          clientportal_footer_background: data?.result?.clientportal_footer_background_enabled,
          clientportal_footer_text: data?.result?.clientportal_footer_text_enabled,
          clientportal_header_background: data?.result?.clientportal_header_background_enabled,
          clientportal_header_secondary: data?.result?.clientportal_header_secondary_enabled,
          clientportal_header_text: data?.result?.clientportal_header_text_enabled,
        });
        setEmailLayoutData({
          email_background: data?.result?.email_background_enabled,
          email_excerpt_background: data?.result?.email_excerpt_background_enabled,
          email_excerpt_separator: data?.result?.email_excerpt_separator_enabled,
          email_footer_link: data?.result?.email_footer_link_enabled,
          email_header: data?.result?.email_header_enabled,
          email_header_text: data?.result?.email_header_text_enabled,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getThemeSettings();
  }, []);

  const getAvailableScreens = () => {
    const screens = [
      "company_details",
      Object.values(logoData).some(Boolean) && "company_logos",
      Object.values(dashboardData).some(Boolean) && "dashboard_theme",
      Object.values(clientPortalData).some(Boolean) && "client_portal_theme",
      Object.values(emailLayoutData).some(Boolean) && "email_theme",
    ].filter(Boolean);
    return screens;
  };

  const availableScreens = getAvailableScreens();
  const currentStepIndex = availableScreens.indexOf(activeScreen);

  const handleNext = (data) => {
    if (data) {
      const updatedData = {
        ...themeData,
        ...data,
      };

      const id = 1;

      main_api
        .put(platformAPIsEndPoints.UPDATE_ONBOARDING_THEME_SETTINGS(id), updatedData)
        .then(({ data }) => {
          if (data.isSuccess) {
            pushNotification(data.message, "success");
            const currentIndex = availableScreens.indexOf(activeScreen);
            if (currentIndex < availableScreens.length - 1) {
              setActiveScreen(availableScreens[currentIndex + 1]);
            }
          }
        })
        .catch((error) => {
          pushNotification("Something went wrong!", "error");
          console.error("API Error:", error);
        });
    } else {
      const currentIndex = availableScreens.indexOf(activeScreen);
      if (currentIndex < availableScreens.length - 1) {
        setActiveScreen(availableScreens[currentIndex + 1]);
      }
    }
  };

  const handleBack = () => {
    const currentIndex = availableScreens.indexOf(activeScreen);
    if (currentIndex > 0) {
      setActiveScreen(availableScreens[currentIndex - 1]);
    }
  };

  const skipOnboarding = () => {
    main_api
      .post(companyAPIsEndPoints.USER_ONBOARDING, { company: company_id, is_seen_onboarding: false })
      .then(({ data }) => {
        if (data.isSuccess) {
          pushNotification(data.message, "success");
          navigate("/company/scheduler");
        }
      })
      .catch((error) => {
        pushNotification("Something went wrong!", "error");
        console.error("API Error:", error);
      });
  };

  const finishOnboarding = (data) => {
    let company_id = auth?.loginResponse?.selectCompany?.[0]?.company_id || null;
    if (themeData) {
      const updatedData = {
        ...themeData,
        ...data,
      };

      const id = 1;
      main_api
        .put(platformAPIsEndPoints.UPDATE_ONBOARDING_THEME_SETTINGS(id), updatedData)
        .then(({ data }) => {
          if (data.isSuccess) {
            pushNotification(data.message, "success");

            // New API call to update the onboarding status
            main_api
              .post(companyAPIsEndPoints.USER_ONBOARDING, { company: company_id, is_seen_onboarding: true })
              .then(({ data }) => {
                if (data.isSuccess) {
                  pushNotification(data.message, "success");
                  navigate("/company/scheduler"); // Navigate to a different route
                }
              })
              .catch((error) => {
                pushNotification("Something went wrong!", "error");
                console.error("API Error:", error);
              });
          }
        })
        .catch((error) => {
          pushNotification("Something went wrong!", "error");
          console.error("API Error:", error);
        });
    } else {
      main_api
        .post(companyAPIsEndPoints.USER_ONBOARDING, { company: company_id, is_seen_onboarding: true })
        .then(({ data }) => {
          if (data.isSuccess) {
            pushNotification(data.message, "success");
            navigate("/company/scheduler");
          }
        })
        .catch((error) => {
          pushNotification("Something went wrong!", "error");
          console.error("API Error:", error);
        });
    }
  };

  return (
    <Row style={{ background: "#F9F9F9", height: "100%" }}>
      <Col md={12}>
        <AuthPetsScreen />
      </Col>
      <Col
        md={12}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div className="d-flex justify-content-end w-100 px-4 mt-4" style={{}}>
          <SkippedButton onClick={() => skipOnboarding()} />
        </div>
        {themeData ? (
          <SteperContainer>
            <div style={{ marginRight: "2px" }}>
              <StepContainer>
                {availableScreens.map((screen, index) => (
                  <Step
                    key={index}
                    completed={index < currentStepIndex}
                    active={index === currentStepIndex}
                    marginLeft={index !== 0 ? "8px" : "0"} // Add margin to all except the first step
                  />
                ))}
              </StepContainer>
            </div>
            <div style={{ marginTop: "28px", marginBottom: "70px" }}>
              {activeScreen === "company_details" && (
                <CompanyDetails handleNext={handleNext} handleBack={handleBack} themeData={themeData} />
              )}
              {activeScreen === "company_logos" && Object.values(logoData).some(Boolean) && (
                <CompanyLogos logoData={logoData} handleNext={handleNext} handleBack={handleBack} />
              )}
              {activeScreen === "dashboard_theme" && Object.values(dashboardData).some(Boolean) && (
                <DashboardTheme
                  dashboardData={dashboardData}
                  themeData={themeData}
                  handleNext={handleNext}
                  handleBack={handleBack}
                />
              )}
              {activeScreen === "client_portal_theme" && Object.values(clientPortalData).some(Boolean) && (
                <ClientPortalTheme
                  clientPortalData={clientPortalData}
                  themeData={themeData}
                  handleNext={handleNext}
                  handleBack={handleBack}
                />
              )}
              {activeScreen === "email_theme" && Object.values(emailLayoutData).some(Boolean) && (
                <EmailTheme
                  emailLayoutData={emailLayoutData}
                  themeData={themeData}
                  handleNext={handleNext}
                  handleBack={handleBack}
                  finishOnboarding={finishOnboarding}
                />
              )}
            </div>
          </SteperContainer>
        ) : (
          <SteperContainer>
            <CompanyDetails handleNext={handleNext} finishOnboarding={finishOnboarding} themeData={themeData} />
          </SteperContainer>
        )}
      </Col>
    </Row>
  );
};

const SteperContainer = styled.div`
  padding: 59px 0 36px 0;
  width: 330px;
  margin: 0 auto;
`;

const StepContainer = styled.div`
  padding: 16px 16px 0px 16px;
  height: 54px;
  box-sizing: border-box;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Step = styled.div`
  width: 68px;
  height: 5px;
  border-radius: 50px;
  margin-left: ${(props) => props.marginLeft ?? "0"};
  background-color: ${(props) => (props.completed || props.active ? "#FBCF34" : "lightgray")};
  transition: background-color 0.3s ease-in-out;
`;

export default CompanyOnboarding;
