import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  DatePicker as DatePickerv2,
  Row,
  Select,
  Checkbox,
  Space,
  Upload,
  Dropdown,
} from "antd";
import styled from "styled-components";
import CustomBtnWithIcon from "../../buttonWithIcon";
import { CustomModal } from "../ModalStyles";
import { AssigneeType } from "../../../constants/TasksManagement";
import dayjs from "dayjs";
import { createWorkQueue } from "../../../util/DataService";
import { pushNotification } from "../../../util/Notification";
import { FormItem } from "../../../pages/company/tasks/tasksManagementStyles";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { BirthDate } from "../../../assets/rawSvgs";
import CustomDropdown from "../../CustomDropdown/customMultiSelectDropdown";

const { Dragger } = Upload;

const CreateWorkQueue = ({
  isLoading,
  isModalOpen,
  setModalOpen,
  staffList,
  clientList,
  generalList,
  loadWorkQueue,
  tasksList,
}) => {
  const [form] = Form.useForm();
  const [addTask, setAddTask] = useState(false);
  const [assigneeList, setAssigneeList] = useState();
  const [addClicked, setAddClicked] = useState(false);

  useEffect(() => {
    form.setFieldsValue({
      tasks: [{ id: undefined }],
    });
  }, [addClicked]);

  const handleChange = (value) => {};

  const onFinish = (formValues) => {
    setAddClicked(true);
    const updatedFormValues = {
      ...formValues,
      tasks: formValues?.tasks?.map((task) => ({
        ...task,
        type: "staff",
        due_date: formValues?.due_date.toISOString().split("T")[0],
      })),
    };

    updatedFormValues.due_date = updatedFormValues.due_date.toISOString().split("T")[0];

    createWorkQueue(updatedFormValues)
      .then(({ data }) => {
        if (data.status == 201) {
          pushNotification(data.message, "success");
          setModalOpen(false);
          form.resetFields();
          loadWorkQueue();
          setAddClicked(false);
        }
      })
      .catch((error) => {
        console.error("error ", error);
      });
  };

  return (
    <CustomModal
      open={isModalOpen}
      title="Add New Work Queue"
      width="600px"
      heigth="500px"
      onCancel={() => {
        setModalOpen(false);
        form.resetFields();
      }}
      footer={null}
      maskClosable={false}
      styles={{ padding: 0 }}
    >
      <ModalContentWrapper>
        <Divider />
        <Form name="createWorkQueue" onFinish={onFinish} form={form} layout="vertical">
          <FormItem
            name="name"
            label="Title"
            rules={[
              {
                required: true,
                message: "Title is required",
              },
            ]}
          >
            <Input placeholder="Write title here" />
          </FormItem>
          <FormItem
            name="description"
            label="Description"
            rules={[
              {
                required: true,
                message: "Description is required",
              },
            ]}
          >
            <Input.TextArea placeholder="Type description here..." />
          </FormItem>
          <FormItem
            name="assign_to_staffs"
            label="Assignees"
            rules={[
              {
                required: true,
                message: "Assignees are required",
              },
            ]}
          >
            <CustomDropdown options={staffList} onChange={handleChange} />
          </FormItem>
          <FormItem
            name="due_date"
            label="Due Date"
            rules={[
              {
                required: true,
                message: "Due date is required",
              },
            ]}
          >
            <DatePickerv2
              placeholder="Select Date"
              suffixIcon={<BirthDate stroke={"#424242"} />}
              format="YYYY-MM-DD"
              style={{ height: "40px", width: "100% " }}
              onChange={(e, dateString) => {}}
            />
          </FormItem>
          <Form.List name="tasks">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }, index) => (
                  <div className="d-flex gap-3" key={key}>
                    <FormItem
                      name={[name, "id"]}
                      label={"Task"}
                      {...restField}
                      rules={[
                        {
                          required: true,
                          message: "Please select a task",
                        },
                        {
                          validator: (_, value, callback) => {
                            const allTasks = form.getFieldValue("tasks");
                            const duplicateTasks = allTasks.filter((task) => task.id === value);
                            if (duplicateTasks.length > 1) {
                              callback("Task already selected");
                            } else {
                              callback();
                            }
                          },
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select Task"
                        options={tasksList}
                        style={{ marginBottom: "10px" }}
                        onChange={() => form.validateFields(["tasks"])} // Validate on change
                      />
                    </FormItem>
                    {fields.length > 1 && <MinusCircleOutlined onClick={() => remove(name)} />}
                  </div>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={async () => {
                      try {
                        await form.validateFields(["tasks"]);
                        add();
                      } catch (error) {
                        console.log("Validation failed:", error);
                      }
                    }}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add Task
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>

          <Divider />
          <SaveContainer className="d-flex justify-content-end">
            <CustomBtnWithIcon
              btnText={"Cancel"}
              margin="0px 5px"
              noBackground
              hideIcon={true}
              onClick={() => {
                setModalOpen(false);
                form.resetFields();
              }}
            />
            <CustomBtnWithIcon btnText={"Add Work Queue"} type="submit" hideIcon={true} disabled={addClicked} />
          </SaveContainer>
        </Form>
      </ModalContentWrapper>
    </CustomModal>
  );
};

export default CreateWorkQueue;

const ModalContentWrapper = styled.div`
  min-height: 550px;
  height: 340px;
  overflow-y: auto;
  margin-bottom: 15px;
`;

const SaveContainer = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  border-top: 1px solid #e0e0e0;
  background-color: white;
  padding: 10px 20px;
`;
