import { DatePicker, Divider, Form, Input, Select } from "antd";
import React, { useEffect, useState } from "react";
import { CustomModal } from "../ModalStyles";
import { AssigneeType } from "../../../constants/TasksManagement";
import { FormItem } from "../../../pages/company/tasks/tasksManagementStyles";
import CustomBtnWithIcon from "../../buttonWithIcon";
import { editTask } from "../../../util/DataService";
import { pushNotification } from "../../../util/Notification";
import dayjs from "dayjs";

const EditTaskModal = ({
  isLoading,

  isModalOpen,
  setModalOpen,
  staffList,
  clientList,
  generalList,
  editModalValues,
  loadTasksList,
}) => {
  const [form] = Form.useForm();
  const [assigneeList, setAssigneeList] = useState();
  const [submitLoader, setSubmitLoader] = useState(false);

  useEffect(() => {
    handleTypeChange(editModalValues?.type);
    form.setFieldsValue({
      name: editModalValues?.name,
      due_date: dayjs(editModalValues?.due_date),
      type: editModalValues?.type,
      assignee:
        editModalValues?.assign_to_staff?.id || editModalValues?.assign_to_client?.id || editModalValues?.assign_to_manager?.id,
      description: editModalValues?.description,
    });
  }, [isModalOpen, editModalValues]);

  const handleTypeChange = (value) => {
    form.resetFields(["assignee"]);

    switch (value) {
      case "staff":
        setAssigneeList(staffList);
        break;
      case "client":
        setAssigneeList(clientList);
        break;
      case "general":
        setAssigneeList(generalList);
        break;
      default:
        setAssigneeList("");
        break;
    }
  };
  const onFinish = async (formValues) => {
    try {
      const formattedDate = formValues.due_date.format("YYYY-MM-DD");
      const updatedFormValues = {
        assign_to_manager: formValues.assignee,
        assign_to_client: formValues.assignee,
        assign_to_staff: formValues.assignee,
        ...formValues,
        due_date: formattedDate,
      };

      setSubmitLoader(true);

      const { data } = await editTask(editModalValues.id, updatedFormValues);

      if (data.isSuccess) {
        pushNotification(data.message, "success");
        setModalOpen(false);
        form.resetFields();
        loadTasksList();
      } else {
        // Handle unsuccessful response
        pushNotification(data.message, "error");
      }
    } catch (error) {
      console.error("error = ", error);
      // Optionally, display an error notification
      pushNotification("An error occurred while editing the task.", "error");
    } finally {
      setSubmitLoader(false);
    }
  };

  return (
    <CustomModal
      open={isModalOpen}
      title="Edit a Task"
      width="600px"
      heigth="340px"
      onCancel={() => {
        setModalOpen(false);
        form.resetFields();
      }}
      footer={null}
      maskClosable={false}
    >
      {isLoading ? (
        "Loading ..."
      ) : (
        <>
          <Divider />
          <Form name="editTask" onFinish={onFinish} form={form} layout="vertical">
            <FormItem
              name="name"
              label="Title"
              rules={[
                {
                  required: true,
                  message: "Title is required",
                },
              ]}
            >
              <Input placeholder="Enter Title" />
            </FormItem>
            <FormItem
              name="due_date"
              label="Due Date"
              rules={[
                {
                  required: true,
                  message: "Due Date is required",
                },
              ]}
            >
              <DatePicker style={{ width: "100%" }} type="date" placeholder="Select Due Date" format={"YYYY-MM-DD"} />
            </FormItem>
            <FormItem name="type" label="Type">
              <Select
                placeholder="Select Assignee Type"
                onChange={(val) => {
                  form.setFieldsValue({ assignee: undefined });
                  handleTypeChange(val);
                }}
                options={AssigneeType}
              />
            </FormItem>
            <FormItem
              name="assignee"
              label="Assigned To"
              rules={[
                {
                  required: false,
                  message: "Assignee is required",
                },
              ]}
            >
              <Select placeholder="Select Assignee" options={assigneeList} allowClear />
            </FormItem>
            <FormItem
              name="description"
              label="Description"
              rules={[
                {
                  required: true,
                  message: "Description is required",
                },
              ]}
            >
              <Input.TextArea placeholder="Type notes here..." />
            </FormItem>
            <Divider />
            <div className="d-flex justify-content-end">
              <CustomBtnWithIcon
                type="button"
                btnText={"Cancel"}
                margin="0px 5px"
                noBackground
                hideIcon={true}
                onClick={() => {
                  setModalOpen(false);
                  form.resetFields();
                }}
              />
              <CustomBtnWithIcon btnText={"Save Task"} type="submit" hideIcon={true} disabled={submitLoader} />
            </div>
          </Form>
        </>
      )}
    </CustomModal>
  );
};

export default EditTaskModal;
