import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import axiosInstance from "../../util/AxiosConfig";
import { companyAPIsEndPoints } from "../../constants/ApiEndPoints";
import { main_api } from "../../api/axios_helper";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: "",
  loginResponse: {},
  companyDetails: {
    stripe_details_submitted: "",
    stripe_customer_id: "",
    stripe_account_id: "",
  },
  companyInformation: {},
};

const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginUserSlice(state, action) {
      state.isLoading = action.payload.isLoading;
      state.error = action.payload.error;
      state.loginResponse = action.payload;
    },
    companyDetailSlice(state, action) {
      state.isLoading = action.payload.isLoading;

      if (action.payload.compDetails) {
        state.companyDetails.stripe_details_submitted = action.payload.compDetails.stripe_details_submitted;
        state.companyDetails.stripe_customer_id = action.payload.compDetails.stripe_customer_id;
        state.companyDetails.stripe_account_id = action.payload.compDetails.stripe_account_id;
      }
    },
    companyStripeInfoSlice(state, action) {
      state.isLoading = action.payload.isLoading;

      if (action.payload.compDetails) {
        state.companyInformation = action.payload.compDetails;
      }
    },
  },
});

export const { loginUserSlice } = slice.actions;
// Reducer
export default slice.reducer;

export function loginUser(formValues) {
  return async (dispatch, getState) => {
    try {
      dispatch(slice.actions.loginUserSlice({ isLoading: true, error: "", loginResponse: {} }));
      const { data } = await main_api.post(companyAPIsEndPoints.COMPANY_LOGIN, formValues);
      dispatch(slice.actions.loginUserSlice({ isLoading: false, error: "", loginResponse: data.result }));
    } catch (error) {
      dispatch(slice.actions.loginUserSlice({ isLoading: false, error: error.message, loginResponse: {} }));
    }
  };
}
export function fetchCompDetailsApi() {
  return async (dispatch, getState) => {
    try {
      const companyId = getState().auth?.loginResponse?.selectCompany?.[0]?.company_id;
      dispatch(slice.actions.companyDetailSlice({ isLoading: true, error: "" }));
      const { data } = await main_api.get(companyAPIsEndPoints.FETCH_SINGLE_COMPANY(companyId));

      dispatch(slice.actions.companyDetailSlice({ isLoading: false, error: "", compDetails: data.result }));
    } catch (error) {
      dispatch(slice.actions.companyDetailSlice({ isLoading: false, error: error.message, compDetails: {} }));
    }
  };
}
export function fetchCompStripeDetailsApi() {
  return async (dispatch, getState) => {
    try {
      const companyId = getState().auth?.loginResponse?.selectCompany?.[0]?.stripe_account_id;

      dispatch(slice.actions.companyStripeInfoSlice({ isLoading: true }));
      const { data } = await main_api.get(companyAPIsEndPoints.GET_COMPANY_STRIPE_INFORMATION(companyId));
      dispatch(slice.actions.companyStripeInfoSlice({ isLoading: false, compDetails: data }));
    } catch (error) {
      dispatch(slice.actions.companyStripeInfoSlice({ isLoading: false, error: error.message, compDetails: {} }));
    }
  };
}
