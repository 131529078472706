import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { AntdesignTablePagination } from "../../../components/antDesignTable/AntdesignTablePagination";
import SearchInput from "../../../components/SearchInput";
import { scheduleTimeOffColumens } from "../../../constants/CompanyTableHeaders/SchedulerTableHeaders";
import { staffAPIsEndPoints } from "../../../constants/ApiEndPoints";
import { useSelector } from "react-redux";
import { main_api } from "../../../api/axios_helper";
import CustomBtnWithIcon from "../../../components/buttonWithIcon";
import ManageTimeOffModal from "../../../components/Modals/StaffManagementModal/ManageTimeOffModal";
import { pushNotification } from "../../../util/Notification";
import NoDataScreen from "../../../components/NoDataScreen";
import { checkPermission } from "../../../util/commonUtil";
import PermissionModal from "../../../components/Modals/PermissionModal";

const ScheduledTimeOff = () => {
  const [selectTab, setSelectTab] = useState("requests");
  const [addTimeOff, setAddTimeOff] = useState(false);
  const [staffTimeOff, setStaffTimeOff] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [permissionPopUp, setPermissionPopUp] = useState(false);

  const auth = useSelector((state) => state.auth);
  const permissionManagement = useSelector((state) => state.permissionManagement);

  const pageSize = 10;

  useEffect(() => {
    if (checkPermission(permissionManagement.permissions, "view_timeoffs")) {
      fetchStaffRelatedTimeOff();
    }
  }, [selectTab, searchTerm, permissionManagement.permissions]);

  const fetchStaffRelatedTimeOff = (page = 1) => {
    let company_id = auth.loginResponse?.selectCompany?.[0]?.company_id || null;
    if (company_id) {
      setStaffTimeOff([]);
      main_api
        .get(
          `${staffAPIsEndPoints.LIST_STAFF_TIMEOFF(`staff__usercompanyrole__company_id=${company_id}&page=${page}&is_approved=${selectTab === "lists" ? true : false}&is_decline=${selectTab === "lists" ? true : false}&search=${searchTerm}`)}`,
        )
        .then(({ data }) => {
          setStaffTimeOff(data.results);
          setTotalCount(data.count);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleTimeOff = async (api_data, timeOff = null, remove = false) => {
    if (timeOff && !checkPermission(permissionManagement.permissions, "edit_timeoff")) {
      setPermissionPopUp(true);
      return;
    }
    let company_id = auth.loginResponse?.selectCompany?.[0]?.company_id || null;
    try {
      api_data["company_id"] = company_id;
      if (!api_data.staff && timeOff) {
        api_data["staff"] = timeOff.staff;
      }
      let id = timeOff ? timeOff.id : null;
      if (id) {
        var { data } = await main_api.put(`${staffAPIsEndPoints.STAFF_TIMEOFF}${id}/`, api_data);
      } else {
        var { data } = await main_api.post(staffAPIsEndPoints.STAFF_TIMEOFF, api_data);
      }
      if (data.isSuccess) {
        setAddTimeOff(false);
        if (remove) {
          // remove row
          const updatedStaffTimeOff = staffTimeOff.filter((item) => item.id !== id);
          setStaffTimeOff(updatedStaffTimeOff);
        } else {
          // new timeOff
          if (staffTimeOff.length === pageSize) {
            const updatedStaffTimeOff = [data.result, ...staffTimeOff];
            updatedStaffTimeOff.pop();
            setStaffTimeOff(updatedStaffTimeOff);
          } else {
            setStaffTimeOff([data.result, ...staffTimeOff]);
          }
          if (selectTab !== "lists") {
            setSelectTab("lists");
          }
          setTotalCount(totalCount + 1);
        }
        pushNotification(data.message);
      } else {
        pushNotification(data.message, "error");
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        pushNotification(error.response.data.message, "error");
      }
      console.log("errr", error);
    }
  };

  const createTimeOff = () => {
    if (checkPermission(permissionManagement.permissions, "create_timeoff")) {
      setAddTimeOff(true);
    } else {
      setPermissionPopUp(true);
    }
  };

  return (
    <div>
      <FilterWrapper>
        <ActionButtons>
          <TabContainer>
            <TabButton onClick={() => setSelectTab("requests")} active={selectTab === "requests"} marginLeft="30px">
              Time Off Requests
            </TabButton>
            <TabButton onClick={() => setSelectTab("lists")} active={selectTab === "lists"}>
              Time Off Lists
            </TabButton>
          </TabContainer>
        </ActionButtons>
        {(staffTimeOff.length > 0 || !(staffTimeOff.length === 0 && searchTerm === "")) && (
          <SearchInputWrapper>
            <SearchInput placeholder="Search Requests" onChange={(value) => setSearchTerm(value)} />
          </SearchInputWrapper>
        )}
        {selectTab === "lists" && (
          <CustomBtnWithIcon className="ms-auto" btnText={"Add Time Off"} hideIcon onClick={() => createTimeOff()} />
        )}
      </FilterWrapper>
      {staffTimeOff.length > 0 ? (
        <AntdesignTablePagination
          columns={scheduleTimeOffColumens({ activeTab: selectTab, handleTimeOff })}
          data={staffTimeOff}
          totalCount={totalCount}
          loadPaginatedData={fetchStaffRelatedTimeOff}
          pageSize={pageSize}
        />
      ) : (
        <NoDataScreen
          heading={
            selectTab === "lists"
              ? "You don’t have any Time Offs in your list. Let’s get started by adding a new Time Off"
              : "You don’t have any Time Offs in your list."
          }
          btnText={"Add new Time Off"}
          onClick={() => createTimeOff()}
          showBtn={selectTab === "lists" ? true : false}
        />
      )}
      <ManageTimeOffModal
        showStaffList={true}
        isModalOpen={addTimeOff}
        setModalOpen={setAddTimeOff}
        selectedTimeOff={null}
        handleTimeOff={handleTimeOff}
      />
      <PermissionModal onclose={() => setPermissionPopUp(false)} openModal={permissionPopUp} />
    </div>
  );
};

export default ScheduledTimeOff;

const ActionButtons = styled.div`
  display: flex;
  background-color: #eeeeee;
  max-width: fit-content;
  border-radius: 8px;
`;

const TabContainer = styled.div`
  padding: 4px;
  box-sizing: border-box;
  box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
  display: flex;
  gap: 10px;
`;
const TabButton = styled.button`
  background-color: ${(props) => (props.active ? "white" : "")};
  padding: 8px 16px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
`;

const SearchInputWrapper = styled.div`
  width: 15%;
`;

const FilterWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
`;
